import React, { useRef, useState } from 'react'
import { Eye, EyeSlash } from 'react-bootstrap-icons'
import { useParams } from 'react-router-dom'
import Authentication from '../../libs/api/services/Authentication'
import RegisterSectionLayout from './RegisterSectionLayout'

export default function ForgotPasswordLayout() {
    const [error, setError] = useState()
    const [isPw, setIsPW] = useState(true)
    const pwRef = useRef()
    const { id } = useParams()
    const _handleRest = (e) => {
        console.log({ IDNumber: '', password: pwRef.current.value })
        Authentication
            .DoResetPassword({ IDNumber: id, password: pwRef.current.value })
            .then((r) => {
                // console.log(`Reset ${id} to ${pwRef.current.value}`)
                window.location = '/logout'
            }).catch((e) => setError(e.message))

    }
    return (
        <>
            <div className='col-12 col-md-6 p-3 shadow shadow-4 rounded-md-end rounded-5 text-center'>
                <div className=' Wizard center-all d-flex flex-column'>
                    <div className='head'>
                        <h1>Reset your password</h1>
                        {error ? <div className="error-details">{error}</div> : <p>Please enter your new password</p>}
                    </div>
                    <div className='body'>

                        <label>New Password</label>
                        <div className='d-flex'>
                            <input type={isPw ? 'password' : 'text'} ref={pwRef} className='form-control' placeholder='New Password' />
                            <button onClick={() => setIsPW(!isPw)}>{isPw ? <Eye /> : <EyeSlash />}</button>
                        </div>
                    </div>
                    <div className='tail'>
                        <button onClick={_handleRest} className='btn bg-secondary-btn '>Reset</button>
                    </div>
                </div>
            </div>
            <div className='col-12 col-md-6 p-3 tail'>
                <RegisterSectionLayout />
            </div>
        </>
    )
}

// import React, { Component } from 'react'
// import RegisterSectionLayout from './RegisterSectionLayout'

// export default class ForgotPasswordLayout extends Component {
//     const pw = useRef()
//     render() {
//         return (
//             <>
//                 <div className='col-12 col-md-6 p-3 shadow shadow-4 rounded-md-end rounded-5 text-center'>
//                     <div className=' Wizard center-all d-flex flex-column'>
//                         <div className='head'>
//                             <h1>Reset your password</h1>
//                             <p>Please enter your new password</p>
//                         </div>
//                         <div className='body'>

//                             <label>New Password</label>
//                             <input className='form-control' placeholder='New Password' />
//                         </div>
//                         <div className='tail'>
//                             <button className='btn bg-secondary-btn '>Reset</button>
//                         </div>
//                     </div>
//                 </div>
//                 <div className='col-12 col-md-6 p-3 tail'>
//                     <RegisterSectionLayout />
//                 </div>
//             </>
//         )
//     }
// }

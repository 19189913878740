import React, { useState } from 'react'

interface IThemeContext {
  dark: boolean
  toggleDark?: () => void
}
interface Props {
  children: React.ReactNode
}
const defaultState = {
  dark: false,
}

const ThemeContext = React.createContext<Partial<IThemeContext>>(defaultState)

export const ThemeProvider: React.FC<Props> = ({ children }) => {
  const [dark, setDark] = useState(defaultState.dark)

  const toggleDark = () => {
    setDark(!dark)
  }

  return (
    <ThemeContext.Provider
      value={{
        dark,
        toggleDark,
      }}
    >
      {children}
    </ThemeContext.Provider>
  )
}

export default ThemeContext

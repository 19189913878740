import { faSpinner } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useContext, useEffect, useState } from 'react'
import ToastDialog from '../../../../components/dialogs/toast_dialog/ToastDialog'
import FileUpload from '../../../../components/ui/files/FileUpload'
import FileUploadGrid from '../../../../components/ui/files/FileUploadGrid'
import PatientHistoryContext from '../history/PatientHistoryProvider'
import './PatientDMS.css'

export default function PatientDMSComponent({ id, event }) {
    const { patientHistoryState, CLEAR_ERRORS, ADD_PATIENT_DOCUMENT, LIST_PATIENNT_HISTORY_DOCUMENTS, REMOVE_DOCUMENT, patientDMSFiles, GET_DOCUMENT, GET_EVENT_DETAILS, REMOVE_NOTE } = useContext(PatientHistoryContext)
    const [uploadedFiles, setUploadedFiles] = useState([])
    const [confirmFiles, setConfirmFiles] = useState([])
    const [preFiles, setPreFiles] = useState([])
    const [status, setStatus] = useState({})
    const [confirmDelete, setConfirmDelete] = useState({ title: '', description: '', show: false, action: undefined })

    const _loadCurrentDocs = async () => {
        var file = await LIST_PATIENNT_HISTORY_DOCUMENTS(event)
    }
    function openBase64InNewTab(data, mimeType, filename) {
        var byteCharacters = atob(data.substr(data.indexOf(';base64,') + 8));
        // var byteCharacters = data.substr(data.indexOf(';base64,') + 8);
        var byteNumbers = new Array(byteCharacters.length);
        for (var i = 0; i < byteCharacters.length; i++) {
            byteNumbers[i] = byteCharacters.charCodeAt(i);
        }

        var byteArray = new Uint8Array(byteNumbers);
        var file = new Blob([byteArray], { type: mimeType + ';base64' });
        // var file = new Blob([byteArray]);
        var fileURL = URL.createObjectURL(file);

        var anchor = document.createElement('a');
        anchor.href = fileURL;
        anchor.download = filename;
        anchor.click();
        window.URL.revokeObjectURL(fileURL);

        // window.open(fileURL);
    }
    const _handleDownload = async (id) => {
        var file = await GET_DOCUMENT(id)
        //window.location = 'vault/patientDMS/' + id + '/' + file.fileName
        // window.open('vault/patientDMS/' + id + '/' + file.fileName, '_BLANK');

        openBase64InNewTab(file.data, file.mimeType, file.fileName)
    }

    useEffect(() => {
        // Load Patient Documents
        _loadCurrentDocs();
        _loadEventDetails()
    }, [])

    const _renderUploadFiles = (files) => {
        var Ret = []
        if (files) {
            for (var file of files) {
                Ret.push(
                    <div className='d-flex bg-secondary-container rounded m-2 p-3'>
                        <div className='flex-grow-1'>{file.name}</div>
                        <div className='flex-shrink-1'>
                            <FontAwesomeIcon icon={faSpinner} spin />
                            {/* <TrashFill size={22} /> */}
                        </div>
                    </div>
                )
            }
        }
        return Ret
    }
    async function getBase64(_file) {
        return new Promise((resolve) => {
            //   let baseURL = ''
            // Make new FileReader
            const reader = new FileReader()

            // Convert the file to base64 text
            reader.readAsDataURL(_file)
            // on reader load somthing...
            reader.onload = async () => {
                // Make a fileInfo Object
                // baseURL = reader.result
                resolve(reader.result)
            }
        })
    }

    const _loadEventDetails = () => GET_EVENT_DETAILS(event)

    const _handleConfirmFiles = async (files) => {
        setPreFiles(files)
        var _files = []
        for (var file of files) {
            _files.push(file.name + '')
        }
        setConfirmFiles(_files)
        // _handleFilesUploaded
    }

    const _handleFilesUploaded = async (files) => {
        // const _processUploadFiles = async () => {
        var _files = []
        if (files) {
            for (var file of files) {
                const result = await getBase64(file)
                _files.push({
                    id: file.name,
                    name: file.name,
                    status: 'uploading',
                    mimeType: file.type,
                    date: '',
                    uploadedUser: '',
                    base64: result
                })
            }
            // setFiles(_files)

            // var i = 0
            for (var _file of _files) {
                await ADD_PATIENT_DOCUMENT(event, _file)
                setUploadedFiles(_files)
            }
            _loadCurrentDocs();
        }
    }
    return (
        <>
            {
                confirmFiles.length > 0
                    ?
                    <div
                        className='position-absolute d-flex align-items-center justify-content-center'
                        style={{ left: 0, right: 0, top: 0, bottom: 0, zIndex: 100 }}
                    >
                        <div
                            className='bg-secondary-container rounded shadow p-3 text-center'
                            style={{ maxWidth: '450px' }}
                        >
                            <h3>Confirm</h3>
                            <p>Please confirm you would like to attach the following {confirmFiles.length == 1 ? 'file' : 'files'} to <b>{patientHistoryState.event.patient ? patientHistoryState.event.patient.userProfile.firstName : ''} {patientHistoryState.event.patient ? patientHistoryState.event.patient.userProfile.lastName : ''}</b> for event <b>{patientHistoryState.event.event ? patientHistoryState.event.event.event : ''}</b> on <b>{patientHistoryState.event.eventDateTime ? patientHistoryState.event.eventDateTime.substr(0, 19) : ''}</b></p>
                            {
                                confirmFiles.map((file, i) => {
                                    return (<li className='text-start'><small>{file}</small></li>)
                                })
                            }
                            <button
                                onClick={(e) => { setConfirmFiles([]); _handleFilesUploaded(preFiles) }}
                                className='btn'>Yes</button>
                            <button
                                onClick={(e) => { setConfirmFiles([]); setPreFiles([]) }}
                                className='btn'>No</button>
                        </div>
                    </div>
                    :
                    <></>
            }
            {

                confirmDelete.show === true
                    ?
                    <div
                        className='position-absolute d-flex align-items-center justify-content-center'
                        style={{ left: 0, right: 0, top: 0, bottom: 0, zIndex: 100 }}
                    >
                        <div
                            className='bg-secondary-container rounded shadow p-3 text-center'
                            style={{ maxWidth: '450px' }}
                        >
                            <h3>{confirmDelete.title}</h3>
                            <p>{confirmDelete.description}</p>
                            <button
                                onClick={(e) => { confirmDelete.action(); setConfirmDelete({ ...confirmDelete, show: false }); }}
                                className='btn'>Yes</button>
                            <button
                                onClick={(e) => { setConfirmDelete({ ...confirmDelete, show: false }) }}
                                className='btn'>No</button>
                        </div>
                    </div>
                    :
                    <></>
            }
            <ToastDialog
                onTimeOut={() => setStatus({ message: '' })}
                {...status}
                //message={status} 
                time={8} />
            {/* <ErrorDialog onClose={() => CLEAR_ERRORS()} error={patientHistoryState.error} /> */}
            <div className='h-100 d-block d-lg-none'>

                <div className='flex-grow-1'>
                    <div className='flex-grow-1 flex h-100'>
                        <div className='flex-shrink-1'>
                            <FileUpload onFilesSelected={(f) => _handleConfirmFiles(f)} />
                        </div>
                        <div className='flex-grow-1' style={{ overflowY: 'auto', height: '30vh' }}>
                            <FileUploadGrid
                                currentFiles={patientDMSFiles}
                                onDownload={(e) => _handleDownload(e)}
                                onRemoveDocument={(i, n) => setConfirmDelete({ title: 'Remove File?', description: 'Are you sure you would like to remove ' + n + '?', show: true, action: () => REMOVE_DOCUMENT(event, i).then(() => setStatus({ message: 'File removed', key: new Date(), time: 3 })) })}
                                processFile={(f) => ADD_PATIENT_DOCUMENT(event, f)}
                                onUploaded={() => _loadCurrentDocs()}
                                uploadedFiles={uploadedFiles}
                            />
                        </div>
                    </div>
                </div>

            </div>
            <div className='h-100 d-none d-lg-block'>

                {/* Split */}
                <div className='d-flex h-100 text-center'>
                    <div className='bg-primary-container flex-grow-1 m-3 rounded d-flex flex-column'>

                        <div className='flex-grow-1 flex h-100'>
                            <div className='flex-shrink-1'>
                                <FileUpload onFilesSelected={(f) => _handleConfirmFiles(f)} />
                            </div>
                            <div className='flex-grow-1' style={{ overflowY: 'scroll', height: '20%' }}>
                                <FileUploadGrid
                                    currentFiles={patientDMSFiles}
                                    onDownload={(e) => _handleDownload(e)}
                                    onRemoveDocument={(i, n) => setConfirmDelete({ title: 'Remove File?', description: 'Are you sure you would like to remove ' + n + '?', show: true, action: () => REMOVE_DOCUMENT(event, i).then(() => setStatus({ message: 'File removed', key: new Date(), time: 3 })) })}
                                    processFile={(f) => ADD_PATIENT_DOCUMENT(event, f)}
                                    onUploaded={() => _loadCurrentDocs()}
                                    uploadedFiles={uploadedFiles}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

import React, { useEffect, useState } from 'react'
import MessagingService from '../../../../../../../libs/api/services/MessagingService'
import MessageTemple from '../../../../../../../libs/api/services/MessageTemple'
import { Bullseye, ChatFill, Cursor, Whatsapp } from 'react-bootstrap-icons'

export default function MessageTemplateWizardServiceType({ title, description, navigation, errors, setValue, values }) {

    const [serviceType, setServiceType] = useState('')
    useEffect(() => {
        title('Messaging Platform')
        description('Please select the messaging platform for this message will be sent to')
        // Load Navigation if session started
        if (Object.keys(values).includes('configurationId'))
            navigation([
                { text: 'Back', type: 'Back', action: async () => { } },
                { text: 'Next', type: 'Next', action: async () => { } },
            ])
        else
            navigation([
                { text: 'Back', type: 'Back', action: async () => { } },
            ])
    }, [])

    const _handleSetPlatformType = (type) => {

        setValue({ 'serviceType': type })
        MessageTemple
            .SetTemplateMessagingPlatform(values.templateId, type)
            .then((r) => {
                setServiceType(type)
                navigation([
                    { text: 'Back', type: 'Back', action: async () => { } },
                    { text: 'Next', type: 'Next', action: async () => { } },
                ])
            }).catch((e) => { if (e.errors) errors(JSON.stringify(e.errors)); else errors(e.message) })

    }
    const loadConfigs = () => {
        MessageTemple.
            GetMessageTemplates(values.templateId)
            .then((r) => {
                setValue({ 'serviceType': r.serviceType.type })
                if (r.serviceType) setServiceType(r.serviceType.type); navigation([
                    { text: 'Back', type: 'Back', action: async () => { } },
                    { text: 'Next', type: 'Next', action: async () => { } },
                ])
            }
            ).catch((e) => { })
    }

    useEffect(() => {
        loadConfigs()
    }, [])

    // const _handleSetConfigrationName = (c) => {
    //     setConfigurationName(c)
    //     if (c)
    //         navigation([{
    //             text: 'Next', type: 'Next', action: async () => {
    //                 return await MessageTemple
    //                     .Create({
    //                         name: c
    //                     }).then((r) => {
    //                         setValue({ 'templateId': r.id })
    //                     })
    //             }
    //         }])
    //     else
    //         navigation([])
    //     setConfiguration()
    // }

    // const _handleRemoveTemplate = async (id) => {
    //     await MessageTemple
    //         .RemoveTemplate(id)
    // }

    // const _handleSelectConfiguration = (c) => {
    //     if (c)
    //         navigation([
    //             { text: 'Remove', type: 'Action', action: async () => { _handleRemoveTemplate(c.id).then((r) => { navigation([]); loadConfigs() }) } },
    //             { text: 'Manage', type: 'Next', action: async () => { setValue({ 'configurationId': c.id }) } },
    //         ])
    //     setConfigurationName('')
    //     setConfiguration(c)
    // }

    return (
        <div
            style={{ cursor: 'pointer' }}
            className='d-flex flex-row justify-content-evenly align-items-evenly'>

            <div
                onClick={(e) => _handleSetPlatformType('Internal Messaging Service')}
                className={'w-100 m-3 p-2 rounded '.concat(serviceType === 'Internal Messaging Service' ? 'bg-primary-container shadow ' : 'bg-secondary-container')}>
                <div className='p-3'>
                    <Bullseye size={52} />
                </div>
                <div>
                    <h3>Internal Messaging Service</h3>
                </div>
            </div>
            <div
                onClick={(e) => _handleSetPlatformType('SMS')}
                className={'w-100 m-3 p-2 rounded '.concat(serviceType === 'SMS' ? 'bg-primary-container shadow ' : 'bg-secondary-container')}>
                <div className='p-3'>
                    <ChatFill size={52} />
                </div>
                <div>
                    <h3>SMS</h3>
                </div>
            </div>
            <div
                onClick={(e) => _handleSetPlatformType('WhatsApp')}
                className={'w-100 m-3 p-2 rounded '.concat(serviceType === 'WhatsApp' ? 'bg-primary-container shadow ' : 'bg-secondary-container')}>
                <div className='p-3'>
                    <Whatsapp size={52} />
                </div>
                <div>
                    <h3>WhatsApp</h3>
                </div>
            </div>
            <div
                onClick={(e) => _handleSetPlatformType('Email')}
                className={'w-100 m-3 p-2 rounded '.concat(serviceType === 'Email' ? 'bg-primary-container shadow ' : 'bg-secondary-container')}>
                <div className='p-3'>
                    <Cursor size={52} />
                </div>
                <div>
                    <h3>Email</h3>
                </div>
            </div>

        </div>
    )
}

import Authentication from "./libs/api/services/Authentication";
import ClinicianManagementLayout from "./screens/layout/ClinicianManagementLayout";
import PatientLoginLayout from "./screens/layout/PatientLoginLayout";
import PatientManagementLayout from "./screens/layout/PatientManagementLayout";
import { AdminLayout } from "./screens/layout/adminLayout";
import { ConfigLayout } from "./screens/layout/configLayout";
import { MenuContainer } from "./screens/menu/MenuContainer";
import FacilityManagementSearchBar from "./screens/search/FacilityManagementSearchBar";
import FilteredSearchBar from "./screens/search/FilteredSearchBar";
import PatientearchBar from "./screens/search/PatientearchBar";
import UserSearchBar from "./screens/search/UserSearchBar";
import SysAdmin from "./screens/section/SysAdmin";
import UISectionLayout from "./screens/section/UISectionLayout";
import AlertsOverview from "./screens/section/alerts/AlertsOverview";
import CalendarSection from "./screens/section/calendar/CalendarSection";
import CalendarFacilityAdmin from "./screens/section/calendar/facility/CalendarFacilityAdmin";
import CalendarSyncConfiguration from "./screens/section/calendar/sync/CalendarSyncConfiguration";
import FacilityUserManagement from "./screens/section/configuration/FacilityUserManagement";
import Dashboard from "./screens/section/dashboards/Dashboard";
import FacilityManagementScreen from "./screens/section/facility/management/FacilityManagementScreen";
import LogsSectionLayout from "./screens/section/logs/LogsSectionLayout";
import ServiceProviderAdmin from "./screens/section/messaging/ServiceProviderAdmin";
import MessagingAudienceAdmin from "./screens/section/messaging/admin/MessagingAudienceAdmin";
import MessagingTemplateAdmin from "./screens/section/messaging/admin/MessagingTemplateAdmin";
import SystemMessagingAccounts from "./screens/section/messaging/admin/SystemMessagingAccounts";
import UserEmailAccountConfiguration from "./screens/section/messaging/admin/facility/UserEmailAccountConfiguration";
import FacilityMessagingConfigurationWizard from "./screens/section/messaging/configuration/facility/screens/FacilityMessagingConfigurationWizard";
import MessageManagement from "./screens/section/messaging/management/MessageManagement";
import PatientProfileAdmin from "./screens/section/patient/admin/PatientProfileAdmin";
import PatientDMS from "./screens/section/patient/dms/PatientDMS";
import WorkFlowPatientHistory from "./screens/section/patient/history/WorkFlowPatientHistory";
import PatientProfileDashboard from "./screens/section/patient/management/PatientProfileDashboard";
import PatientProfileManagement from "./screens/section/patient/management/PatientProfileManagement";
import QuestionnairesAdmin from "./screens/section/questionnaires/QuestionnairesAdmin";
import FormBuilder from "./screens/section/questionnaires/builder/FormBuilder";
import QuestionnaireBuilder from "./screens/section/questionnaires/builder/QuestionnaireBuilder";
import QCapture from "./screens/section/questionnaires/capture/QCapture";
import PatientFormComponent from "./screens/section/questionnaires/patient/PatientFormComponent";
import PatientForms from "./screens/section/questionnaires/patient/PatientForms";
import PatientQuestionnaireResults from "./screens/section/questionnaires/patient/PatientQuestionnaireResults";
import PatientQuestionnaires from "./screens/section/questionnaires/patient/PatientQuestionnaires";
import PatientQuestionnairesDashboard from "./screens/section/questionnaires/patient/PatientQuestionnairesDashboard";
import ManageTerms from "./screens/section/tncs/ManageTerms";
import ManageFacilityTnCs from "./screens/section/tncs/facility/ManageFacilityTnCs";
import ToDoListScreen from "./screens/section/todo/ToDoListScreen";
import ToDoListAdmin from "./screens/section/todo/facility/ToDoListAdmin";
import RegisterUser from "./screens/section/user/RegisterUser";
import SysAdminUserDashboard from "./screens/section/user/SysAdminUserDashboard";
import UserFacilities from "./screens/section/user/UserFacilities";
import UserPersmissions from "./screens/section/user/UserPermissions";
import UserRequests from "./screens/section/user/UserRequests";
import UserAdminEdit from "./screens/section/user/admin_edit_user/UserAdminEdit";
import WorkflowWizard from "./screens/section/workflow/configuration/facility/wizard/WorkflowWizard";

export const UserMenuAccess = {
  'Patient': {
    "My Profile": { users: ['Patient', 'Client'], descr: 'View / Update Your Profile' },
    "Questionnaires": { users: ['Patient', 'Client'], descr: 'Fill out questionnnaires' },
    "Calendar": { users: ['Patient', 'Client'], descr: 'Manage Calendar' },
    "Messages": { users: ['Patient', 'Client'], descr: 'View / Send Messages' },
    "To Do": { users: ['Patient', 'Client'], descr: 'Complete To Do List Tasks' }
  },
  "ClinicianPatient": {
    "Profile": { users: ['Clinician', 'Clinical User', 'Admin', 'Office Admin', 'System Admin', 'DrData Tech', 'DrData Sales', 'DrData Compliance'], descr: 'Manage a patients profile' },
    "History": { users: ['Clinician', 'Clinical User', 'Admin', 'Office Admin', 'System Admin', 'DrData Tech', 'DrData Sales', 'DrData Compliance'], descr: 'View patients history' },
    "Messages": { users: ['Clinician', 'Clinical User', 'Admin', 'Office Admin', 'System Admin', 'DrData Tech', 'DrData Sales', 'DrData Compliance'], descr: 'View messages relating to a patient' },
    "Questionnaires": { users: ['Clinician', 'Clinical User', 'Admin', 'Office Admin', 'System Admin', 'DrData Tech', 'DrData Sales', 'DrData Compliance'], descr: 'View patients questionnaire results' },
    "Calendar": { users: ['Clinician', 'Clinical User', 'Admin', 'Office Admin', 'System Admin', 'DrData Tech', 'DrData Sales', 'DrData Compliance'], descr: 'View patients calendar' },
    "Back": { users: ['Clinician', 'Clinical User', 'Admin', 'Office Admin', 'System Admin', 'DrData Tech', 'DrData Sales', 'DrData Compliance'], descr: 'Navigate back to dashboard' },
  },
  "Clinician": {
    "Dashboard": { users: ['Clinician', 'Clinical User', 'Admin', 'Office Admin', 'System Admin', 'DrData Tech', 'DrData Sales', 'DrData Compliance'], descr: 'View Dashboard' },
    "Messages": { users: ['Clinician', 'Clinical User', 'Admin', 'Office Admin', 'System Admin', 'DrData Tech', 'DrData Sales', 'DrData Compliance'], descr: 'View / Send messages' },
    "Calendar": { users: ['Clinician', 'Clinical User', 'Admin', 'Office Admin', 'System Admin', 'DrData Tech', 'DrData Sales', 'DrData Compliance'], descr: 'Manage calendars' },
    "To Do": { users: ['Clinician', 'Clinical User', 'Admin', 'Office Admin', 'System Admin', 'DrData Tech', 'DrData Sales', 'DrData Compliance'], descr: 'Complete To Do List items' },
  },
  "UserConfiguration": {
    "Email": { users: ['Clinician', 'Clinical User', 'Admin', 'Office Admin', 'System Admin', 'DrData Tech', 'DrData Sales', 'DrData Compliance'], descr: 'Setup your email account' },
    "User Requests": { users: ['Clinician', 'Clinical User', 'Admin', 'Office Admin', 'System Admin', 'DrData Tech', 'DrData Sales', 'DrData Compliance'], descr: 'Accept / Decline user requests' },
    "Facility Configurations": { users: ['Clinician', 'Clinical User', 'Admin', 'Office Admin', 'System Admin', 'DrData Tech', 'DrData Sales', 'DrData Compliance'], descr: 'Configure the facility' },
    "Dashboard": { users: ['Clinician', 'Clinical User', 'Admin', 'Office Admin', 'System Admin', 'DrData Tech', 'DrData Sales', 'DrData Compliance'], descr: 'View Dashboard' },
  },
  // "Receptionist": { // Moved to clinician
  //   "Dashboard": { users: ['Admin', 'Office Admin'] },
  //   "Messages": { users: ['Admin', 'Office Admin'] },
  //   "Calendar": { users: ['Admin', 'Office Admin'] },
  //   "To Do": { users: ['Admin', 'Office Admin'] },
  // }
  //  "Receptionist"
  // "Admin"
  // "Patient-Admin"
  // "UserManagement"
  //  "Messaging"
  // "Questionaire"
  //  "ReceptionistPatient"
}

const DashboardMapping = {
  "Clinician": {
    main: "CLINICIAN_DASHBOARD",
    calendar: "CLINICIAN"
  },
  "Clinical User": {
    main: "CLINICIAN_DASHBOARD",
    calendar: "CLINICIAN"
  },
  "Patient": {
    main: "PATIENT_DASHBOARD",
    calendar: "PATIENT"
  },
  "Client": {
    main: "PATIENT_DASHBOARD",
    calendar: "PATIENT"
  },
  "Admin": {
    main: "ADMIN_DASHBOARD",
    calendar: "ADMIN"
  },
  "Office Admin": {
    main: "ADMIN_DASHBOARD",
    calendar: "ADMIN"
  },
}

const PatientManageMent = [
  {
    route: '/[UserType]/Patient',
    component: <ClinicianManagementLayout
      search={<FilteredSearchBar section={'Patient Management'} />}
      menu={<MenuContainer key={"menu_cnt" + new Date().getTime()} menu="ClinicianPatient" activeSection="Profile" />}
      container={<PatientProfileManagement />}
      breadcrums={[{ name: 'Home', location: '/' + Authentication.getFacilityRole() }
        , { name: 'New Patient', location: '/' + Authentication.getFacilityRole() + '/Patient', active: true }]} />,
    users: ['Clinician', 'Clinical User', 'Admin', 'Office Admin', 'System Admin', 'DrData Tech', 'DrData Sales', 'DrData Compliance'],
    authenticate: true,
    descr: 'Add new patient',
  },
  {
    route: '/[UserType]/Patient/:id',
    component: <ClinicianManagementLayout
      search={<FilteredSearchBar section={'Patient Management'} />}
      menu={<MenuContainer key={"menu_cnt" + new Date().getTime()} menu="ClinicianPatient" activeSection="Profile" />}
      container={<PatientProfileManagement />}
      breadcrums={[
        { name: 'Home', location: '/' + Authentication.getFacilityRole() },
        { name: 'Patient', location: '/' + Authentication.getFacilityRole() + '/Patient/[PatientID]' },
        { name: '[PatientName]', location: '/' + Authentication.getFacilityRole() + '/Patient/[PatientID]/Dashboard' },
        { name: 'Profile', location: '/' + Authentication.getFacilityRole() + '/Patient', active: true }
      ]} />,
    users: ['Clinician', 'Clinical User', 'Admin', 'Office Admin', 'System Admin', 'DrData Tech', 'DrData Sales', 'DrData Compliance'],
    authenticate: true,
    descr: 'Manage patients profile',
  },
  {
    route: '/[UserType]/Patient/:id/Dashboard',
    component: <ClinicianManagementLayout
      search={<FilteredSearchBar section={'Patient Management'} />}
      menu={<MenuContainer key={"menu_cnt" + new Date().getTime()} menu="ClinicianPatient" activeSection="Profile" />}
      container={<PatientProfileDashboard
        type="CLINICIAN_PATIENT_DASHBOARD" />}
      breadcrums={[
        { name: 'Home', location: '/' + Authentication.getFacilityRole() },
        { name: 'Patient', location: '/' + Authentication.getFacilityRole() + '/Patient/[PatientID]' },
        { name: '[PatientName]', location: '/' + Authentication.getFacilityRole() + '/Patient/[PatientID]/Dashboard' },
        { name: 'Profile', location: '/' + Authentication.getFacilityRole() + '/Patient', active: true }
      ]} />,
    users: ['Clinician', 'Clinical User', 'Admin', 'Office Admin', 'System Admin', 'DrData Tech', 'DrData Sales', 'DrData Compliance'],
    authenticate: true,
    descr: 'View Patient Management Dashboard',
  },
  {
    route: '/[UserType]/Patient/:id/History',
    component: <ClinicianManagementLayout
      search={<FilteredSearchBar section={'Patient History'} />}
      menu={<MenuContainer key={"menu_cnt" + new Date().getTime()} menu="ClinicianPatient" activeSection="History" />}
      container={<WorkFlowPatientHistory />}
      breadcrums={[
        { name: 'Home', location: '/' + Authentication.getFacilityRole() },
        { name: 'Patient', location: '/' + Authentication.getFacilityRole() + '/Patient/[PatientID]' },
        { name: '[PatientName]', location: '/' + Authentication.getFacilityRole() + '/Patient/[PatientID]/Dashboard' },
        { name: 'History', location: '/' + Authentication.getFacilityRole() + '/Patient', active: true }
      ]} />,
    users: ['Clinician', 'Clinical User', 'Admin', 'Office Admin', 'System Admin', 'DrData Tech', 'DrData Sales', 'DrData Compliance'],
    authenticate: true,
    descr: 'View patient history',
  },
  {
    route: '/[UserType]/Patient/:id/:event/Files',
    component: <ClinicianManagementLayout menu={<MenuContainer key={"menu_cnt" + new Date().getTime()} menu="ClinicianPatient" activeSection="History" />}
      container={<PatientDMS />}
      breadcrums={[
        { name: 'Home', location: '/' + Authentication.getFacilityRole() },
        { name: 'Patient', location: '/' + Authentication.getFacilityRole() + '/Patient/[PatientID]' },
        { name: '[PatientName]', location: '/' + Authentication.getFacilityRole() + '/Patient/[PatientID]/Dashboard' },
        { name: 'History', location: '/' + Authentication.getFacilityRole() + '/Patient/[PatientID]/History', active: false },
        { name: 'Event', location: '/' + Authentication.getFacilityRole() + '/Patient', active: true }
      ]} />,
    users: ['Clinician', 'Clinical User', 'Admin', 'Office Admin', 'System Admin', 'DrData Tech', 'DrData Sales', 'DrData Compliance'],
    authenticate: true,
    descr: 'View / Import patient documents',
  },
]

const MessageManagementItems = [
  {
    route: '/[UserType]/Messages',
    component: <PatientLoginLayout menu={<MenuContainer key={"menu_cnt" + new Date().getTime()} menu="Patient" activeSection="Messages" />} container={<MessageManagement />} search={<PatientearchBar />} breadcrums={[{ name: 'Home', location: '/PAtient' }, { name: 'Messages', location: '/PAtient/Messages', active: true }]} />,
    users: ['Patient', 'Client'],
    authenticate: true,
    descr: 'View / Send Messages (Internal Messages only)',
  },
  {
    route: '/[UserType]/Messages',
    component: <ClinicianManagementLayout
      search={<FilteredSearchBar section={'Messages'} />}
      menu={<MenuContainer key={"menu_cnt" + new Date().getTime()} menu="Clinician" activeSection="Messages" />}
      container={<MessageManagement />}
      breadcrums={[
        { name: 'Home', location: '/' + Authentication.getFacilityRole() },
        { name: 'Messages', location: '/' + Authentication.getFacilityRole() + '/Messages', active: true }
      ]} />,
    users: ['Clinician', 'Clinical User', 'Admin', 'Office Admin', 'System Admin', 'DrData Tech', 'DrData Sales', 'DrData Compliance'],
    authenticate: true,
    descr: 'View / Send Messages',
  },
  {
    route: '/[UserType]/Messages/Patient/:id',
    component: <ClinicianManagementLayout
      search={<FilteredSearchBar section={'Patient Messages'} />}
      menu={<MenuContainer key={"menu_cnt" + new Date().getTime()} menu="ClinicianPatient" activeSection="Messages" />}
      container={<MessageManagement />}
      breadcrums={[
        { name: 'Home', location: '/' + Authentication.getFacilityRole() },
        { name: 'Patient', location: '/' + Authentication.getFacilityRole() + '/Patient/[PatientID]' },
        { name: '[PatientName]', location: '/' + Authentication.getFacilityRole() + '/Patient/[PatientID]/Dashboard' },
        { name: 'Messages', location: '/' + Authentication.getFacilityRole() + '/Patient', active: true }
      ]} />,
    users: ['Clinician', 'Clinical User', 'Admin', 'Office Admin', 'System Admin', 'DrData Tech', 'DrData Sales', 'DrData Compliance'],
    authenticate: true,
    descr: 'View Messages relating to patient',
  },
  // Messaging Service Providers
  {
    route: '/Facility/Configuration/Messaging',
    component: <PatientManagementLayout nofilter
      menu={<MenuContainer key={"menu_cnt" + new Date().getTime()} menu="UserConfiguration" activeSection="Facility Configurations" />}
      container={<FacilityMessagingConfigurationWizard />} />,
    users: ['Super', 'Facility Manager','Technical Support', 'System Admin', 'DrData Tech', 'DrData Sales', 'DrData Compliance'],
    authenticate: true,
    descr: 'Setup messaging service providers',
  },
  {
    route: '/Facility/Configuration/Templates',
    component: <ConfigLayout
      search={<FacilityManagementSearchBar />} nofilter
      menu={<MenuContainer key={"menu_cnt" + new Date().getTime()}
        menu="UserConfiguration" activeSection="Facility Configurations" />}
      breadcrums={[
        { name: 'Facility Administration', location: '/Facility/Configuration' },
        { name: 'Facility', location: '/Facility/Configuration', active: false },
        { name: 'Message Templates', location: '/Facility/Configuration/Templates', active: true },
      ]}
      container={<MessagingTemplateAdmin startStep={1} />} />,
    users: ['Super', 'Facility Manager','Technical Support', 'System Admin', 'DrData Tech', 'DrData Sales', 'DrData Compliance'],
    authenticate: true,
    descr: 'Setup facility message templates',
  },
  // {
  //   route: '/Facility/Messages',
  //   component: <ConfigLayout menu={<MenuContainer key={"menu_cnt" + new Date().getTime()} menu="Clinician" activeSection="Messages" />}
  //     container={<MessageManagement />}
  //     breadcrums={[
  //       { name: 'Home', location: '/Clinician' },
  //       { name: 'Messages', location: '/Clinician/Messages', active: true }
  //     ]} />,
  //   users: ['Super', 'Facility Manager','Technical Support',],
  //   authenticate: true,
  //   descr: 'Setup facility message templates',
  // },
]

const UserConfigurationManagementItems = [
  // {
  //   route: '/[UserType]/Configuration',
  //   component: <AdminLayout
  //     menu={<MenuContainer key={"menu_cnt" + new Date().getTime()} menu="UserConfiguration" activeSection="" />}
  //     breadcrums={[
  //       { name: 'Facility Administration', location: "/" + Authentication.getFacilityRole() + '/Configuration' },
  //     ]}
  //     container={<UserConfiguration />} />,
  //   users: ['Clinician', 'Clinical User', 'Admin', 'Office Admin', 'System Admin', 'DrData Tech', 'DrData Sales', 'DrData Compliance'],
  //   authenticate: true,
  //   descr: 'Setup own calendars / email account',
  // },
   {
    route: '/[UserType]/Configuration/Email',
    component: <AdminLayout menu={<MenuContainer key={"menu_cnt" + new Date().getTime()}
      menu="UserConfiguration" activeSection="Email" />}
      search={<FacilityManagementSearchBar section={`Email configuration for ${JSON.parse(Authentication.UserName() && Authentication.UserName())}`} />}
      breadcrums={[
        { name: 'Facility Administration', location: "/" + Authentication.getFacilityRole() + '/Configuration' },
        { name: 'Email Account', location: '/Facility/Configuration', active: true },
      ]}
      container={<UserEmailAccountConfiguration user={{ id: Authentication.UserId(true) }} />} />,
    users: ['Clinician', 'Clinical User', 'Admin', 'Office Admin', 'System Admin', 'DrData Tech', 'DrData Sales', 'DrData Compliance'],
    authenticate: true,
    descr: 'Configure own email accounts',
  }, {
    route: '/[UserType]/Configuration/Email/:state',
    component: <AdminLayout menu={<MenuContainer key={"menu_cnt" + new Date().getTime()}
      menu="UserConfiguration" activeSection="Email" />}
      breadcrums={[
        { name: 'Facility Administration', location: "/" + Authentication.getFacilityRole() + '/Configuration' },
        { name: 'Email Account', location: '/Facility/Configuration', active: true },
      ]}
      container={<UserEmailAccountConfiguration user={{ id: Authentication.UserId(true) }} />} />,
    users: ['Clinician', 'Clinical User', 'Admin', 'Office Admin', 'System Admin', 'DrData Tech', 'DrData Sales', 'DrData Compliance'],
    authenticate: true,
    descr: 'Verify own email account with OAuth',
  },
]

const UserManagementItems = [
  {
    route: '/[UserType]/Configuration/UserRequests',
    component: <AdminLayout
      search={<UserSearchBar facility={Authentication.getFacilityRole()} />}
      menu={<MenuContainer key={"menu_cnt" + new Date().getTime()}
        menu="UserConfiguration" activeSection="User Requests" />} container={<UserRequests />}
      breadcrums={[
        { name: 'Facility Administration', location: '/Facility/Configuration' },
        { name: 'Users', location: '/Facility/Configuration/users', active: false },
        { name: 'User Requests', location: Authentication.getFacilityRole() + '/Configuration', active: true }
      ]} />,
    users: ['Clinician', 'Clinical User', 'Admin', 'Office Admin', 'Super', 'Facility Manager','Technical Support', 'System Admin', 'DrData Tech', 'DrData Sales', 'DrData Compliance'],
    authenticate: true,
    descr: 'Approve / Decline User requests',
  },
  {
    route: '/[UserType]/Configuration',
    component: <ConfigLayout
      search={<FacilityManagementSearchBar section={`${Authentication.getFacilityName()} Configuration`} />}
      breadcrums={[
        { name: 'Facility Administration', location: "/" + Authentication.getFacilityRole() + '/Configuration' },]}
      nofilter menu={<MenuContainer key={"menu_cnt" + new Date().getTime()} menu="UserConfiguration" activeSection="Facility Configurations" />} container={<FacilityManagementScreen />} />,
    users: ['Clinician', 'Clinical User', 'Admin', 'Office Admin', 'Super', 'Facility Manager','Technical Support', 'System Admin', 'DrData Tech', 'DrData Sales', 'DrData Compliance'],
    authenticate: true,
    descr: 'Manage facility configuration',
  },
  // TODO: Compare with Facility/Configuration/users for redundancies
  // {
  //   route: '/[UserType]/Configuration/Users',
  //   component: <AdminLayout key={"AL_" + new Date().getTime()} menu={<MenuContainer key={"menu_cnt" + new Date().getTime()}
  //     menu="UserConfiguration" activeSection="Users" />}
  //     search={<UserSearchBar facility={Authentication.getFacilityRole()} />}
  //     breadcrums={[
  //       { name: 'Facility Administration', location: "/" + Authentication.getFacilityRole() + '/Configuration' },
  //       { name: 'Users', location: Authentication.getFacilityRole() + '/Configuration', active: true }]}
  //     container={<FacilityUserManagement />} />,
  //   users: ['Clinician', 'Clinical User'],
  //   authenticate: true,
  //   descr: 'Manage users for a facility',
  // },
  {
    route: '/Facility/Configuration/Users',
    component: <AdminLayout key={"AL_" + new Date().getTime()}
      menu={<MenuContainer key={"menu_cnt" + new Date().getTime()} menu="UserConfiguration" activeSection="Users" />}
      search={<UserSearchBar noAdd facility={Authentication.getFacility()} />}
      breadcrums={[
        { name: 'Facility Administration', location: '/Facility/Configuration' },
        { name: Authentication.getFacilityName(), location: '/Facility/Configuration' },
        { name: 'Users', location: 'Facility/Configuration', active: true }]}
      container={<FacilityUserManagement />} />,
    users: ['Super', 'Facility Manager','Technical Support', 'System Admin', 'DrData Tech', 'DrData Sales', 'DrData Compliance'],
    authenticate: true,
    descr: 'Manage users for a facility',
  },
  {
    route: '/Facility/Configuration/Users/Add',
    component: <ConfigLayout key={"AL_" + new Date().getTime()}
      menu={<MenuContainer key={"menu_cnt" + new Date().getTime()}
        menu="UserConfiguration" activeSection="Users" />}
      search={<FacilityManagementSearchBar />}
      breadcrums={[
        { name: 'Facility Administration', location: '/Facility/Configuration' },
        { name: 'Users', location: '/Facility/Configuration/Users', active: false },
        { name: 'Add', location: '/Facility/Configuration', active: true }
      ]}

      container={<RegisterUser facility={Authentication.getFacility()} />} />,
    users: ['Super', 'Facility Manager','Technical Support', 'System Admin', 'DrData Tech', 'DrData Sales', 'DrData Compliance'],
    authenticate: true,
    descr: 'Add users to the facility',
  },
  {
    route: '/Facility/Configuration/users/:id',
    component: <ConfigLayout key={"AL_" + new Date().getTime()}
      menu={<MenuContainer key={"menu_cnt" + new Date().getTime()} menu="UserConfiguration" activeSection="Users" />}
      // search={<UserSearchBar facility={Authentication.getFacility()} />}
      search={<FacilityManagementSearchBar section={'         '} />}
      container={<UserAdminEdit />}
      breadcrums={[
        { name: 'Facility Administration', location: '/Facility/Configuration' },
        { name: 'Users', location: '/Facility/Configuration/Users', active: false },
        { name: '[UserName]', location: '/Facility/Configuration/users/[UserID]' }, { name: 'Manage', location: '/Facility/Configuration/users/add', active: true }]}
    />,
    users: ['Super', 'Facility Manager','Technical Support', 'System Admin', 'DrData Tech', 'DrData Sales', 'DrData Compliance'],
    authenticate: true,
    descr: 'Manage a user in the facility',
  },
]

const QuestionnaireManagementItems = [
  {
    route: '/[UserType]/Questionnaires',
    component: <PatientLoginLayout container={<PatientQuestionnairesDashboard />} menu={<MenuContainer key={"menu_cnt" + new Date().getTime()} menu="Patient" activeSection="Questionnaires" />} search={<PatientearchBar />} breadcrums={[{ name: 'Home', location: '/Patient' }, { name: 'Questionnaires', location: '/Patient/Questionnaires', active: true }]} />,
    users: ['Patient', 'Client'],
    authenticate: true,
    descr: 'View assigned questionnaires',
  },
  {
    route: '/[UserType]/Questionnaire/:questionnaireId/:QuestionnaireAssignmentId',
    component: <PatientLoginLayout container={<QCapture />} menu={<MenuContainer key={"menu_cnt" + new Date().getTime()} menu="Patient" activeSection="Questionnaires" />} search={<PatientearchBar />} breadcrums={[{ name: 'Home', location: '/Patient' }, { name: 'Questionnaires', location: '/Patient/Questionnaires', active: true }]} />,
    users: ['Patient', 'Client'],
    authenticate: true,
    descr: 'Complete a questionnaire',
  },
  {
    route: '/[UserType]/Patient/:id/Questionnaires',
    component: <ClinicianManagementLayout
      search={<FilteredSearchBar section={'Questionnaires'} />}
      menu={<MenuContainer key={"menu_cnt" + new Date().getTime()} menu="ClinicianPatient" activeSection="Questionnaires" />}
      container={<PatientQuestionnaires />}
      breadcrums={[
        { name: 'Home', location: '/' + Authentication.getFacilityRole() },
        { name: 'Patient', location: '/' + Authentication.getFacilityRole() + '/Patient/[PatientID]' },
        { name: '[PatientName]', location: '/' + Authentication.getFacilityRole() + '/Patient/[PatientID]/Dashboard' },
        { name: 'Questionnaires', location: '/' + Authentication.getFacilityRole() + '/Patient', active: true }
      ]} />,
    users: ['Clinician', 'Clinical User', 'Admin', 'Office Admin', 'System Admin', 'DrData Tech', 'DrData Sales', 'DrData Compliance'],
    authenticate: true,
    descr: 'Assign a questionnare',
  },
  {
    route: '/[UserType]/Patient/:id/Questionanire/:questionair/:QuestionnaireAssignmentId',
    component: <PatientLoginLayout menu={<MenuContainer key={"menu_cnt" + new Date().getTime()}
      menu="ClinicianPatient" activeSection="Questionnaires" />} container={<PatientQuestionnaireResults />}
      search={<PatientearchBar />}
      breadcrums={[
        { name: 'Home', location: '/' + Authentication.getFacilityRole() },
        { name: 'Patient', location: '/' + Authentication.getFacilityRole() + '/Patient/[PatientID]' },
        { name: '[PatientName]', location: '/' + Authentication.getFacilityRole() + '/Patient/[PatientID]/Dashboard' },
        { name: 'Questionnaires', location: '/' + Authentication.getFacilityRole() + '/Patient/[PatientID]/Questionnaires' },
        { name: 'Questionnaire', location: '/' + Authentication.getFacilityRole() + '/Patient', active: true }
      ]} />,
    users: ['Clinician', 'Clinical User', 'Admin', 'Office Admin', 'System Admin', 'DrData Tech', 'DrData Sales', 'DrData Compliance'],
    authenticate: true,
    descr: 'View questionnaire results',
  },
  {
    route: '/[UserType]/Patient/:id/Forms',
    component: <ClinicianManagementLayout
      search={<FilteredSearchBar section={'Forms'} />}
      menu={<MenuContainer key={"menu_cnt" + new Date().getTime()} menu="ClinicianPatient" activeSection="Questionnaires" />}
      container={<PatientForms />}
      breadcrums={[
        { name: 'Home', location: '/' + Authentication.getFacilityRole() },
        { name: 'Patient', location: '/' + Authentication.getFacilityRole() + '/Patient/[PatientID]' },
        { name: '[PatientName]', location: '/' + Authentication.getFacilityRole() + '/Patient/[PatientID]/Dashboard' },
        { name: 'Forms', location: '/' + Authentication.getFacilityRole() + '/Patient', active: true }
      ]} />,
    users: ['Clinician', 'Clinical User', 'Admin', 'Office Admin', 'System Admin', 'DrData Tech', 'DrData Sales', 'DrData Compliance'],
    authenticate: true,
    descr: 'Start a form',
  },
  {
    route: '/[UserType]/Patient/:id/Form/:questionair/:QuestionnaireAssignmentId',
    component: <PatientLoginLayout menu={<MenuContainer key={"menu_cnt" + new Date().getTime()} menu="ClinicianPatient" activeSection="Questionnaires" />}
      container={<PatientFormComponent />}
      search={<PatientearchBar />}
      breadcrums={[
        { name: 'Home', location: '/' + Authentication.getFacilityRole() },
        { name: 'Patient', location: '/' + Authentication.getFacilityRole() + '/Patient/[PatientID]' },
        { name: '[PatientName]', location: '/' + Authentication.getFacilityRole() + '/Patient/[PatientID]/Dashboard' },
        { name: 'Forms', location: '/' + Authentication.getFacilityRole() + '/Patient/[PatientID]/Forms' },
        { name: 'Form', location: '/' + Authentication.getFacilityRole() + '/Patient', active: true }
      ]} />,
    users: ['Clinician', 'Clinical User', 'Admin', 'Office Admin', 'System Admin', 'DrData Tech', 'DrData Sales', 'DrData Compliance'],
    authenticate: true,
    descr: 'Complete a form',
  },
]

const FacilityManagementItems = [
  {
    route: '/[UserType]',
    component: <PatientManagementLayout nofilter menu={<MenuContainer key={"menu_cnt" + new Date().getTime()} menu="UserConfiguration" activeSection="Facility Configurations" />}
      container={<FacilityManagementScreen />} />,
    users: ['Super', 'Facility Manager','Technical Support', 'System Admin', 'DrData Tech', 'DrData Sales', 'DrData Compliance'],
    authenticate: true,
    descr: 'View facility management dashboard',
  },
  {
    route: '/Facility/Configuration',
    component: <ConfigLayout
      search={<FacilityManagementSearchBar />}
      menu={<MenuContainer key={"menu_cnt" + new Date().getTime()}
        menu="UserConfiguration" activeSection="Facility Configurations"
      />}
      breadcrums={[
        { name: 'Facility Administration', location: "/" + Authentication.getFacilityRole() + '/Configuration' },
        { name: 'Facility', location: '/Facility/Configuration/Users', active: true },
      ]}
      container={<FacilityManagementScreen />} />,
    users: ['Super', 'Facility Manager','Technical Support', 'System Admin', 'DrData Tech', 'DrData Sales', 'DrData Compliance'],
    authenticate: true,
    descr: 'Configure a facility',
  },
  // TODO: Check for breadcrums elsewhere
  {
    route: '/Facility/Configuration/Events',
    component: <PatientManagementLayout nofilter
      menu={<MenuContainer key={"menu_cnt" + new Date().getTime()} menu="UserConfiguration" activeSection="Facility Configurations" />}
      container={<WorkflowWizard />} />,
    users: ['Super', 'Facility Manager','Technical Support', 'System Admin', 'DrData Tech', 'DrData Sales', 'DrData Compliance'],
    authenticate: true,
    descr: 'Setup facility work flows',
  },
  {
    route: '/Facility/Configuration/TNC',
    component: <AdminLayout key={"AL_" + new Date().getTime()}
      menu={<MenuContainer key={"menu_cnt" + new Date().getTime()}
        menu="UserConfiguration" activeSection="Users" />}
      search={<FacilityManagementSearchBar section={`Managing terms and conditions for ${Authentication.getFacilityName()}`} />}
      breadcrums={[
        { name: 'Facility Administration', location: "/" + Authentication.getFacilityRole() + '/Configuration' },
        { name: Authentication.getFacilityName(), location: '/Facility/Configuration' },
        { name: 'Terms and conditions', location: 'Facility/Configuration', active: true }]}
      container={<ManageFacilityTnCs facility={Authentication.getFacility()} />} />,
    users: ['Super', 'Facility Manager','Technical Support', 'System Admin', 'DrData Tech', 'DrData Sales', 'DrData Compliance'],
    authenticate: true,
    descr: 'Manage facilities terms and conditions',
  },
]

const AlertManagementItems = [
  {
    route: '/[UserType]/Alerts',
    component: <ClinicianManagementLayout menu={<MenuContainer key={"menu_cnt" + new Date().getTime()} menu="Clinician" activeSection="Alerts" />}
      container={<AlertsOverview />}
      search={undefined} breadcrums={[{ name: 'Home', location: '/' + Authentication.getFacilityRole() },
      { name: 'Alerts', active: true }]}
    />,
    users: ['Clinician', 'Clinical User', 'Admin', 'Office Admin', 'System Admin', 'DrData Tech', 'DrData Sales', 'DrData Compliance'],
    authenticate: true,
    descr: 'View Alerts',
  },
]

const CalendarManagementItems = [
  {
    route: '/[UserType]/Calendar',
    component: <PatientLoginLayout menu={<MenuContainer key={"menu_cnt" + new Date().getTime()} menu="Patient" activeSection="Calendar" />} container={<CalendarSection filter={'Patient'} calendarType={'PATIENT'} />} search={undefined} breadcrums={[{ name: 'Home', location: '/Patient' }, { name: 'Calendar', active: true }]} />,
    users: ['Patient', 'Client'],
    authenticate: true,
    descr: 'View Own Calendar',
  },
  // TODO : double check all calendar dashbaords
  {
    route: '/[UserType]/Calendar',
    component: <ClinicianManagementLayout
      menu={<MenuContainer key={"menu_cnt" + new Date().getTime()} menu="Clinician" activeSection="Calendar" />}
      container={<CalendarSection calendarType={Object.keys(DashboardMapping).includes(Authentication.getFacilityRole()) && DashboardMapping[Authentication.getFacilityRole()].calendar} />}
      search={<FilteredSearchBar hideIcon section={'  '} />}
      breadcrums={[{ name: 'Home', location: '/' + Authentication.getFacilityRole() }, { name: 'Calendar', active: true }]} />,
    users: ['Clinician', 'Clinical User', 'Admin', 'Office Admin', 'System Admin', 'DrData Tech', 'DrData Sales', 'DrData Compliance'],
    authenticate: true,
    descr: 'View / Manage calendar',
  },
  {
    route: '/[UserType]/Patient/:id/Calendar',
    component: <ClinicianManagementLayout menu={<MenuContainer key={"menu_cnt" + new Date().getTime()} menu="ClinicianPatient" activeSection="Calendar" />}
      container={<CalendarSection calendarType={Object.keys(DashboardMapping).includes(Authentication.getFacilityRole()) && `${DashboardMapping[Authentication.getFacilityRole()].calendar}_PATIENT`} filter='Patient' />}
      breadcrums={[
        { name: 'Home', location: '/' + Authentication.getFacilityRole() },
        { name: 'Patient', location: '/' + Authentication.getFacilityRole() + '/Patient/[PatientID]' },
        { name: '[PatientName]', location: '/' + Authentication.getFacilityRole() + '/Patient/[PatientID]/Dashboard' },
        { name: 'Calendar', location: '/' + Authentication.getFacilityRole() + '/Patient', active: true }
      ]} />,
    users: ['Clinician', 'Clinical User', 'Admin', 'Office Admin', 'System Admin', 'DrData Tech', 'DrData Sales', 'DrData Compliance'],
    authenticate: true,
    descr: 'View patients calendar',
  },
  {
    route: '/[UserType]/configuration/Calendar',
    component: <AdminLayout menu={<MenuContainer key={"menu_cnt" + new Date().getTime()}
      menu="UserConfiguration" activeSection="Calendar" />}
      search={<FacilityManagementSearchBar section={`Calendar configuration for ${Authentication.UserName() && JSON.parse(Authentication.UserName())}`} />}
      breadcrums={[
        { name: 'Facility Administration', location: "/" + Authentication.getFacilityRole() + '/Configuration' },
        { name: 'Calendars', location: '/Facility/Configuration', active: true },
      ]}
      container={<CalendarSyncConfiguration />} />,
    users: ['Clinician', 'Clinical User', 'Admin', 'Office Admin', 'System Admin', 'DrData Tech', 'DrData Sales', 'DrData Compliance'],
    authenticate: true,
    descr: 'Setup calendar events for a facility',
  },
  {
    route: '/[UserType]/configuration/Calendar/:id',
    component: <AdminLayout menu={<MenuContainer key={"menu_cnt" + new Date().getTime()}
      menu="UserConfiguration" activeSection="Calendar" />}
      breadcrums={[{ name: 'Facility Administration', location: "/" + Authentication.getFacilityRole() + '/Configuration' },
      { name: 'Calendars', location: '/Facility/Configuration', active: true },
      ]}
      container={<CalendarSyncConfiguration />} />,
    users: ['Clinician', 'Clinical User', 'Admin', 'Office Admin', 'System Admin', 'DrData Tech', 'DrData Sales', 'DrData Compliance'],
    authenticate: true,
    descr: 'Setup a synched calendar',
  },
  {
    route: '/Facility/Configuration/Calendar',
    component: <ConfigLayout
      search={<FacilityManagementSearchBar />} menu={<MenuContainer key={"menu_cnt" + new Date().getTime()} menu="UserConfiguration" activeSection="Facility Configurations" />}
      container={<CalendarFacilityAdmin />}
      breadcrums={[
        { name: 'Facility Administration', location: '/Facility/Configuration' },
        { name: 'Facility', location: '/Facility/Configuration', active: false },
        { name: 'Calendar', location: '/Facility/Configuration/Calendar', active: true },
      ]}
    />,
    users: ['Super', 'Facility Manager','Technical Support', 'System Admin', 'DrData Tech', 'DrData Sales', 'DrData Compliance'],
    authenticate: true,
    descr: 'Setup calendar events for a facility',
  },
]

const ToDoManagementItems = [
  {
    route: '/[UserType]/ToDo',
    component: <PatientLoginLayout menu={<MenuContainer key={"menu_cnt" + new Date().getTime()} menu="Patient" activeSection="To Do" />} breadcrums={[{ name: 'Home', location: "/" + Authentication.getFacilityRole() + '/' }, { name: 'To Do List', location: '/ToDo', active: true },]} container={<ToDoListScreen />} />,
    users: ['Patient', 'Client'],
    authenticate: true,
    descr: 'Complete a to do list',
  },
  {
    route: '/[UserType]/ToDo',
    component: <ClinicianManagementLayout menu={<MenuContainer key={"menu_cnt" + new Date().getTime()}
      menu="Clinician" activeSection="To Do" />}
      search={<FilteredSearchBar section={'To Do'} />}
      breadcrums={[
        { name: 'Home', location: '/' + Authentication.getFacilityRole() },
        { name: 'To Do List', location: '/' + Authentication.getFacilityRole() + '/ToDo', active: true },
      ]}
      container={<ToDoListScreen />} />,
    users: ['Clinician', 'Clinical User', 'Admin', 'Office Admin', 'System Admin', 'DrData Tech', 'DrData Sales', 'DrData Compliance'],
    authenticate: true,
    descr: 'Start / Complete a to do list',
  },
  {
    route: '/Facility/Configuration/todo',
    component: <ConfigLayout
      search={<FacilityManagementSearchBar />} menu={<MenuContainer key={"menu_cnt" + new Date().getTime()} menu="UserConfiguration" activeSection="Facility Configurations" />}
      container={<ToDoListAdmin />}
      breadcrums={[
        { name: 'Facility Administration', location: '/Facility/Configuration' },
        { name: 'Facility', location: '/Facility/Configuration', active: false },
        { name: 'To Do List', location: '/Facility/Configuration/todo', active: true },
      ]}
    />,
    users: ['Super', 'Facility Manager','Technical Support', 'System Admin', 'DrData Tech', 'DrData Sales', 'DrData Compliance'],
    authenticate: true,
    descr: 'Setup to do events for a facility',
  },
]

const SystemAdminManagementItems = [
  {
    route: '/sys-admin',
    component:
      <AdminLayout key={"AL_" + new Date().getTime()}
        menu={<MenuContainer key={"menu_cnt" + new Date().getTime()} menu="Admin" activeSection="" />}
        breadcrums={[{ name: 'System Administration', location: '/sys-admin' }]} container={<SysAdmin />} />,
    users: ['System Admin', 'DrData Tech', 'DrData Sales', 'DrData Compliance'],
    authenticate: true,
    descr: 'View system admin dashboard',
  },
  {
    route: '/sys-admin/tncs',
    component:
      <AdminLayout key={"AL_" + new Date().getTime()} menu={<MenuContainer key={"menu_cnt" + new Date().getTime()}
        menu="Admin" activeSection="" />}
        breadcrums={[{ name: 'System Administration', location: '/sys-admin' }]} container={<ManageTerms />} />,
    users: ['System Admin', 'DrData Tech', 'DrData Sales', 'DrData Compliance'],
    authenticate: true,
    descr: 'Manage systems terms and conditions',
  },
  {
    route: '/sys-admin/users',
    component:
      <AdminLayout key={"AL_" + new Date().getTime()}
        container={<SysAdminUserDashboard />}
        menu={<MenuContainer key={"menu_cnt" + new Date().getTime()} menu="Admin" activeSection="Users" />}
        search={<UserSearchBar />}
        breadcrums={[
          { name: 'System Administration', location: '/sys-admin' },
          { name: 'Users', location: '/sys-admin/users', active: true }
        ]} />,
    users: ['System Admin', 'DrData Tech', 'DrData Sales', 'DrData Compliance'],
    authenticate: true,
    descr: 'View systems users',
  },
  {
    route: '/sys-admin/users/add',
    component:
      <AdminLayout key={"AL_" + new Date().getTime()} menu={<MenuContainer key={"menu_cnt" + new Date().getTime()} menu="Admin" activeSection="Users" />}
        container={<RegisterUser />}
        search={<UserSearchBar />}
        breadcrums={[
          { name: 'System Administration', location: '/sys-admin' },
          { name: 'Users', location: '/sys-admin/users' },
          { name: 'Add New User', location: '/sys-admin/users/add', active: true }
        ]} />,
    users: ['System Admin', 'DrData Tech', 'DrData Sales', 'DrData Compliance'],
    authenticate: true,
    descr: 'Create system users',
  },
  {
    route: '/sys-admin/user/manage/:id',
    component:
      <AdminLayout key={"AL_" + new Date().getTime()} menu={<MenuContainer key={"menu_cnt" + new Date().getTime()} menu="UserManagement" activeSection="Users" />}
        search={<UserSearchBar />}
        container={<UserAdminEdit />}
        breadcrums={[
          { name: 'System Administration', location: '/sys-admin' },
          { name: 'Users', location: '/sys-admin/users' },
          { name: '[UserName]', location: '/sys-admin/user/manage/[UserID]' },
          { name: 'Manage', location: '/sys-admin/users/add', active: true }
        ]} />,
    users: ['System Admin', 'DrData Tech', 'DrData Sales', 'DrData Compliance'],
    authenticate: true,
    descr: 'Manage systems users',
  },
  {
    route: '/sys-admin/user/manage/:id/permissions',
    component:
      <AdminLayout key={"AL_" + new Date().getTime()} menu={<MenuContainer key={"menu_cnt" + new Date().getTime()} menu="UserManagement" activeSection="Permissions" />}
        container={<UserPersmissions />}
        search={<UserSearchBar />}
        breadcrums={[
          { name: 'System Administration', location: '/sys-admin' },
          { name: 'Users', location: '/sys-admin/users' },
          { name: '[UserName]', location: '/sys-admin/user/manage/[UserID]' },
          { name: 'Permissions', location: '/sys-admin/users/[UserID]/permissions', active: true }
        ]} />,
    users: ['System Admin', 'DrData Tech', 'DrData Sales', 'DrData Compliance'],
    authenticate: true,
    descr: 'Manage systems user permissions',
  },
  {
    route: '/sys-admin/user/manage/:id/facilities',
    component:
      <AdminLayout key={"AL_" + new Date().getTime()} menu={<MenuContainer key={"menu_cnt" + new Date().getTime()} menu="UserManagement" activeSection="Facilities" />}
        container={<UserFacilities />}
        search={<UserSearchBar />}
        breadcrums={[
          { name: 'System Administration', location: '/sys-admin' },
          { name: 'Users', location: '/sys-admin/users' },
          { name: '[UserName]', location: '/sys-admin/user/manage/[UserID]' },
          { name: 'Facilities', location: '/sys-admin/users/[UserID]/facilities', active: true }
        ]} />,
    users: ['System Admin', 'DrData Tech', 'DrData Sales', 'DrData Compliance'],
    authenticate: true,
    descr: 'Manage systems user facility roles',
  },
  {
    route: '/sys-admin/ux/:id',
    component:
      <AdminLayout key={"AL_" + new Date().getTime()} menu={<MenuContainer key={"menu_cnt" + new Date().getTime()} menu="Admin" activeSection="UI/UX Customise" />}
        container={<UISectionLayout />}
        breadcrums={[
          { name: 'System Administration', location: '/sys-admin' },
          { name: 'UI/UX Customise', location: '/sys-admin/ux/0', active: true }
        ]} />,
    users: ['System Admin', 'DrData Tech', 'DrData Sales', 'DrData Compliance'],
    authenticate: true,
    descr: 'Manage systems UI',
  },
  {
    route: '/sys-admin/logs',
    component:
      <AdminLayout key={"AL_" + new Date().getTime()} menu={<MenuContainer key={"menu_cnt" + new Date().getTime()} menu="Admin" activeSection="Logs" />}
        container={<LogsSectionLayout />}
        breadcrums={[
          { name: 'System Administration', location: '/sys-admin' },
          { name: 'Logs', location: '/sys-admin/logs', active: true }
        ]} />,
    users: ['System Admin', 'DrData Tech', 'DrData Sales', 'DrData Compliance'],
    authenticate: true,
    descr: 'View system logs',
  },
  {
    route: '/sys-admin/event/new',
    component:
      <AdminLayout key={"AL_" + new Date().getTime()} menu={<MenuContainer key={"menu_cnt" + new Date().getTime()} menu="Admin" activeSection="Logs" />}
        container={<WorkflowWizard />}
        breadcrums={[
          { name: 'System Administration', location: '/sys-admin' },
          { name: 'Create New Event', location: '/sys-admin/Event', active: true }
        ]} />,
    users: ['System Admin', 'DrData Tech', 'DrData Sales', 'DrData Compliance'],
    authenticate: true,
    descr: 'Create new system work flow',
  },
  {
    route: '/sys-admin/questionnaires',
    component:
      <AdminLayout key={"AL_" + new Date().getTime()} menu={<MenuContainer key={"menu_cnt" + new Date().getTime()} menu="Questionaire" activeSection="" />}
        container={<QuestionnairesAdmin />}
        breadcrums={[
          { name: 'System Administration', location: '/sys-admin' },
          { name: 'Questionnaires', location: '/sys-admin/questionnaires', active: true }
        ]} />,
    users: ['System Admin', 'DrData Tech', 'DrData Sales', 'DrData Compliance'],
    authenticate: true,
    descr: 'Manage system questionnaires / forms',
  },
  {
    route: '/sys-admin/questionnaires/builder',
    component:
      <AdminLayout key={"AL_" + new Date().getTime()} menu={<MenuContainer key={"menu_cnt" + new Date().getTime()} menu="Questionaire" activeSection="Questionnaire Builder" />}
        container={<QuestionnaireBuilder />}
        breadcrums={[
          { name: 'System Administration', location: '/sys-admin' },
          { name: 'Questionnaires', location: '/sys-admin/questionnaires', active: false },
          { name: 'Questionnaire Builder', location: '/sys-admin/questionnaires', active: true }
        ]} />,
    users: ['System Admin', 'DrData Tech', 'DrData Sales', 'DrData Compliance'],
    authenticate: true,
    descr: 'Manage system questionnaires',
  },
  {
    route: '/sys-admin/form/builder',
    component:
      <AdminLayout key={"AL_" + new Date().getTime()} menu={<MenuContainer key={"menu_cnt" + new Date().getTime()} menu="Questionaire" activeSection="Form Builder" />}
        container={<FormBuilder />}
        breadcrums={[
          { name: 'System Administration', location: '/sys-admin' },
          { name: 'Questionnaires', location: '/sys-admin/questionnaires', active: false },
          { name: 'Form Builder', location: '/sys-admin/questionnaires', active: true }
        ]} />,
    users: ['System Admin', 'DrData Tech', 'DrData Sales', 'DrData Compliance'],
    authenticate: true,
    descr: 'Manage system forms',
  },
  {
    route: '/sys-admin/patient',
    component:
      <AdminLayout key={"AL_" + new Date().getTime()} menu={<MenuContainer key={"menu_cnt" + new Date().getTime()} menu="Patient-Admin" activeSection="Patient Profile" />}
        container={<PatientProfileAdmin />}
        breadcrums={[
          { name: 'System Administration', location: '/sys-admin' },
          { name: 'Patient', location: '/sys-admin/patient', },
          { name: 'Profile Configuration', location: '/sys-admin/patient/profile', active: true }
        ]} />,
    users: ['System Admin', 'DrData Tech', 'DrData Sales', 'DrData Compliance'],
    authenticate: true,
    descr: 'Manage system user setups',
  },
  {
    route: '/sys-admin/patient/profile',
    component:
      <AdminLayout key={"AL_" + new Date().getTime()} menu={<MenuContainer key={"menu_cnt" + new Date().getTime()} menu="Patient-Admin" activeSection="Patient Profile" />}
        container={<PatientProfileAdmin />}
        breadcrums={[
          { name: 'System Administration', location: '/sys-admin' },
          { name: 'Patient', location: '/sys-admin/patient', },
          { name: 'Profile Configuration', location: '/sys-admin/patient/profile', active: true }
        ]} />,
    users: ['System Admin', 'DrData Tech', 'DrData Sales', 'DrData Compliance'],
    authenticate: true,
    descr: 'Manage system patient profile fields',
  },
  {
    route: '/sys-admin/patient/events',
    component:
      <AdminLayout key={"AL_" + new Date().getTime()} menu={<MenuContainer key={"menu_cnt" + new Date().getTime()} menu="Patient-Admin" activeSection="Patient Events" />}
        container={<WorkflowWizard />}
        breadcrums={[
          { name: 'System Administration', location: '/sys-admin' },
          { name: 'Patient', location: '/sys-admin/patient', },
          { name: 'Event Configuration', location: '/sys-admin/patient/events', active: true }
        ]} />,
    users: ['System Admin', 'DrData Tech', 'DrData Sales', 'DrData Compliance'],
    authenticate: true,
    descr: 'Manage system patient profile work flow events',
  },
  {
    route: '/sys-admin/messaging',
    component:
      <AdminLayout key={"AL_" + new Date().getTime()} menu={<MenuContainer key={"menu_cnt" + new Date().getTime()} menu="Messaging" activeSection="Messaging Template" />}
        container={<MessagingTemplateAdmin startStep={1} />}
        breadcrums={[
          { name: 'System Administration', location: '/sys-admin' },
          { name: 'Messaging', location: '/sys-admin/messaging' },
          { name: 'Messaging Templates', location: '/sys-admin/messaging/temlate', active: true }
        ]} />,
    users: ['System Admin', 'DrData Tech', 'DrData Sales', 'DrData Compliance'],
    authenticate: true,
    descr: 'Manage system messaging template',
  },
  {
    route: '/sys-admin/messaging/providers',
    component:
      <AdminLayout key={"AL_" + new Date().getTime()} menu={<MenuContainer key={"menu_cnt" + new Date().getTime()} menu="Messaging" activeSection="Messaging Template" />}
        container={<ServiceProviderAdmin startStep={1} />}
        breadcrums={[
          { name: 'System Administration', location: '/sys-admin' },
          { name: 'Messaging', location: '/sys-admin/messaging' },
          { name: 'Messaging Templates', location: '/sys-admin/messaging/temlate', active: true }
        ]} />,
    users: ['System Admin', 'DrData Tech', 'DrData Sales', 'DrData Compliance'],
    authenticate: true,
    descr: 'Manage system messaging service providers',
  },
  {
    route: '/sys-admin/messaging/config',
    component:
      <AdminLayout key={"AL_" + new Date().getTime()} menu={<MenuContainer key={"menu_cnt" + new Date().getTime()} menu="Messaging" activeSection="System Messaging Accounts Setup" />}
        container={<SystemMessagingAccounts startStep={1} />}
        breadcrums={[
          { name: 'System Administration', location: '/sys-admin' },
          { name: 'Messaging', location: '/sys-admin/messaging' },
          { name: 'Messaging Templates', location: '/sys-admin/messaging/temlate', active: true }
        ]} />,
    users: ['System Admin', 'DrData Tech', 'DrData Sales', 'DrData Compliance'],
    authenticate: true,
    descr: 'Manage system messaging accounts',
  },
  {
    route: '/sys-admin/messaging/template',
    component:
      <AdminLayout key={"AL_" + new Date().getTime()} menu={<MenuContainer key={"menu_cnt" + new Date().getTime()} menu="Messaging" activeSection="Messaging Template" />}
        container={<MessagingTemplateAdmin />}
        breadcrums={[
          { name: 'System Administration', location: '/sys-admin' },
          { name: 'Messaging', location: '/sys-admin/messaging' },
          { name: 'Messaging Templates', location: '/sys-admin/messaging/temlate', active: true }
        ]} />,
    users: ['System Admin', 'DrData Tech', 'DrData Sales', 'DrData Compliance'],
    authenticate: true,
    descr: 'Manage system messaging templates',
  },
  // 
  {
    route: '/sys-admin/messaging/audience',
    component:
      <AdminLayout key={"AL_" + new Date().getTime()} menu={<MenuContainer key={"menu_cnt" + new Date().getTime()} menu="Messaging" activeSection="Audience Groups" />}
        container={<MessagingAudienceAdmin />}
        breadcrums={[
          { name: 'System Administration', location: '/sys-admin' },
          { name: 'Messaging', location: '/sys-admin/messaging' },
          { name: 'Audience Group', location: '/sys-admin/messaging/temlate', active: true }
        ]} />,
    users: ['System Admin', 'DrData Tech', 'DrData Sales', 'DrData Compliance'],
    authenticate: true,
    descr: 'Manage system messaging audience',
  },
]
export const UserComponents = [
  // Dashboards
  {
    route: '/[UserType]',
    component: <PatientLoginLayout menu={<MenuContainer key={"menu_cnt" + new Date().getTime()} menu="Patient" activeSection="" />} search={<PatientearchBar />} container={<Dashboard type={Object.keys(DashboardMapping).includes(Authentication.getFacilityRole()) && DashboardMapping[Authentication.getFacilityRole()].main} />} breadcrums={[{ name: 'Home', location: '/Patient' }]} />,
    users: ['Patient', 'Client'],
    descr: 'View Dashboard (Patient Locked)',
    authenticate: true
  },
  {
    route: '/[UserType]',
    component: <ClinicianManagementLayout
      menu={<MenuContainer key={"menu_cnt" + new Date().getTime()} menu="Clinician" activeSection="Dashboard" />}
      search={<PatientearchBar />}
      breadcrums={[{ name: 'Home', location: '/Clinician' }]}
      container={<Dashboard type={Object.keys(DashboardMapping).includes(Authentication.getFacilityRole()) && DashboardMapping[Authentication.getFacilityRole()].main} />} />,
    users: ['Clinician', 'Clinical User', 'Admin', 'Office Admin', 'System Admin', 'DrData Tech', 'DrData Sales', 'DrData Compliance'],
    descr: 'View Dashboard',
    authenticate: true
  },
  ...PatientManageMent,
  ...UserManagementItems,
  // Questionnaires
  ...QuestionnaireManagementItems,
  // Messages
  ...MessageManagementItems,
  // Profiles
  {
    route: '/[UserType]/Profile',
    component: <PatientLoginLayout menu={<MenuContainer key={"menu_cnt" + new Date().getTime()} menu="Patient" activeSection="My Profile" />} container={<PatientProfileManagement patientUserID={Authentication.PID()} />} breadcrums={[{ name: 'Home', location: '/PAtient' }, { name: 'Patient', location: '/PAtient/Patient/[PatientID]' }, { name: 'Profile', location: '/PAtient/Patient', active: true }]} />,
    users: ['Patient', 'Client'],
    descr: 'Manage own profile',
    authenticate: false
  },
  // Calendars
  ...CalendarManagementItems,
  //To Do List
  ...ToDoManagementItems,

  // Alerts
  ...AlertManagementItems,
  // Configurations
  ...UserConfigurationManagementItems,
  ...FacilityManagementItems,
  ...SystemAdminManagementItems
]

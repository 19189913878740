import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useContext, useEffect, useState } from 'react';
import { CheckCircleFill } from 'react-bootstrap-icons';
import CalendarSync from '../../../../../../libs/api/services/CalendarSync';
import CalendarSyncContext from '../../CalendarSyncProvider';

export default function GoogleCalendarAuthenticationCheckOAth({ onComplete }) {
    const { activeCalendar, setError } = useContext(CalendarSyncContext)
    const [status, setStatus] = useState({ status: '', loading: false, extra: undefined, icon: undefined })
    useEffect(() => { _process() }, [])


    const _process = async () => {
        /**
         *  1. Check with API if a key exists for this email address
         */
        setStatus({ status: 'Verifying your authentication status with Google', loading: true, extra: undefined })
        const _apiToken = await CalendarSync.GetOAuthTokenAsync(activeCalendar).catch(setError)
        if (!_apiToken.accessToken)
            return setStatus({

                /*  2. If not generate the redirect URL*/
                status: 'Your account has not yet been authenticated', loading: false,
                /*  3. Send User to redirect URL*/
                extra: <button
                    onClick={() => window.location = _apiToken.requestURL}
                    className='btn'>Authenticate with Google</button>
            })

        /*  4. Bring them back here*/
        /*  5. Show final status  */
        setStatus({ status: 'Your account has been authenticated', loading: false, icon: <CheckCircleFill size={30} className='me-2' />, extra: undefined })
    }

    return (
        <div className='mt-2'>
            {status.icon ? status.icon : ''}
            {status.loading ? <FontAwesomeIcon icon={faSpinner} className='me-2' spin /> : <></>}{status.status}
            <div className='text-center p-2'>{status.extra ? status.extra : <></>}</div>
        </div >
    )
}

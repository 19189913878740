import React, { useEffect, useState } from 'react'
import Messaging from '../../../../../../../libs/api/services/Messaging'
import MessagingService from '../../../../../../../libs/api/services/MessagingService'

export default function EmailWizardConnectionInfo({ title, description, navigation, errors, setValue, values }) {

    const [properties, setProperties] = useState([])

    useEffect(() => {
        title('Email Account Connection Details')
        description('Enter the connection details for this service provider')
        navigation([
            {
                text: 'Back', type: 'Back', action: async () => { }
            },
            {
                text: 'Next', type: 'Next', action: async () => {
                    MessagingService
                        .SetMessagingServiceProperty(values.configurationId, "EMAIL_INCOMING_MAILBOX_STATUS", "Mailbox configured. Waiting to sync messages")
                }
            }
        ])
        _loadSettings()
    }, [])

    const getSetting = (type) => {
        var property = { value: '' }
        var _property = undefined
        if (properties && properties.length > 0)
            _property = properties.find(f => f.propertyType.type === type)
        return _property ? _property : property
    }

    const _loadSettings = () => {
        MessagingService
            .GetMessagingServiceProperties(values.configurationId)
            .then((r) => {
                setProperties(r)
            })
            .catch((e) => {
                errors(e.message)
                // setValue({ 'serviceProviderId': undefined })
            })
    }

    const _handleSetValue = (id, value) => {
        MessagingService
            .SetMessagingServiceProperty(values.configurationId, id, value)
            .catch((e) => errors(e.message))
    }

    const _handleTestConnection = (type) => {
        // setTesting(true)
        Messaging
            //
            .TestConnection(-1, type)
            .then((r) => {
                // setTesting(false)
                // if (r === true)
                //     setNotice('Connection Ok')
            })
            .catch((e) => {
                // setTesting(false)
                errors(e.message)
            })
    }


    return (
        <div className='d-flex justify-content-evenly m-3'>
            <div className='bg-primary-container w-50 p-3 rounded rounded-3 shadow'>
                <h3>Incoming</h3>
                <label>Email Connection Type</label>
                <select
                    defaultValue={getSetting('EMAIL_INCOMING_SERVER_TYPE').value}
                    onChange={(e) => _handleSetValue('EMAIL_INCOMING_SERVER_TYPE', e.target.value)}
                    className='form-control'>
                    <option></option>
                    <option selected={getSetting('EMAIL_INCOMING_SERVER_TYPE').value === 'pop' ? 'selected' : ''} value={'pop'}>POP</option>
                    <option selected={getSetting('EMAIL_INCOMING_SERVER_TYPE').value === 'imap' ? 'selected' : ''} value={'imap'}>IMAP</option>
                    <option selected={getSetting('EMAIL_INCOMING_SERVER_TYPE').value === 'GMail' ? 'selected' : ''} value={'GMail'}>GMail</option>
                    <option selected={getSetting('EMAIL_INCOMING_SERVER_TYPE').value === 'office' ? 'selected' : ''} value={'office'}>Office 365</option>
                </select>
                <div>
                    Host
                    <input
                        defaultValue={getSetting('EMAIL_INCOMING_SERVER_DOMAIN').value}
                        onBlur={(e) => _handleSetValue('EMAIL_INCOMING_SERVER_DOMAIN', e.target.value)}
                        className='form-control' placeholder='Host' />
                </div>
                <div>
                    Port
                    <input
                        defaultValue={getSetting('EMAIL_INCOMING_SERVER_PORT').value}
                        onBlur={(e) => _handleSetValue('EMAIL_INCOMING_SERVER_PORT', e.target.value)}
                        className='form-control' placeholder='Port' />
                </div>
                <div>
                    Email Address
                    <input
                        defaultValue={getSetting('EMAIL_INCOMING_SERVER_USERNAME').value}
                        onBlur={(e) => _handleSetValue('EMAIL_INCOMING_SERVER_USERNAME', e.target.value)}
                        className='form-control' placeholder='UserName' />
                </div>
                <div>
                    Password
                    <input
                        //defaultValue={getSetting('EMAIL_INCOMING_SERVER_PASSWORD').value}
                        onBlur={(e) => _handleSetValue('EMAIL_INCOMING_SERVER_PASSWORD', e.target.value)}
                        className='form-control' placeholder='Password' />
                </div>
                <div>
                    Security
                    <select
                        onChange={(e) => _handleSetValue('EMAIL_INCOMING_SERVER_AUTH_TYPE', e.target.value)}
                        defaultValue={getSetting('EMAIL_INCOMING_SERVER_AUTH_TYPE').value}
                        className='form-control'>
                        <option></option>
                        <option
                            selected={getSetting('EMAIL_INCOMING_SERVER_AUTH_TYPE').value === 'tls' ? 'selected' : ''}
                            value='tls'
                        >TLS</option>
                        <option
                            selected={getSetting('EMAIL_INCOMING_SERVER_AUTH_TYPE').value === 'ssl' ? 'selected' : ''}
                            value='ssl'
                        >SSL</option>
                        <option
                            selected={getSetting('EMAIL_INCOMING_SERVER_AUTH_TYPE').value === 'OAuth' ? 'selected' : ''}
                            value='OAuth'
                        >OAuth</option>
                        <option
                            selected={getSetting('EMAIL_INCOMING_SERVER_AUTH_TYPE').value === 'none' ? 'selected' : ''}
                            value='none'>None</option>
                    </select>
                </div>

                <button
                    onClick={(e) => _handleTestConnection('Incoming')}
                    className='btn'>
                    {/* {
                        testing
                            ?
                            <FontAwesomeIcon icon={faSpinner} spinPulse />
                            :
                            <>Test Connection</>
                    } */}
                </button>

            </div>
            <div className='bg-secondary-container w-50 p-3 rounded rounded-3 shadow'>
                <h3>Outgoing</h3>
                <label>Email Connection Type</label>
                <select
                    defaultValue={getSetting('EMAIL_OUTGOING_SERVER_TYPE').value}
                    onChange={(e) => _handleSetValue('EMAIL_OUTGOING_SERVER_TYPE', e.target.value)}
                    className='form-control'>
                    <option></option>
                    <option
                        selected={getSetting('EMAIL_OUTGOING_SERVER_TYPE').value === 'smtp' ? 'selected' : ''}
                        value='smtp'>SMTP</option>
                    <option
                        selected={getSetting('EMAIL_OUTGOING_SERVER_TYPE').value === 'GMail' ? 'selected' : ''}
                        value='GMail'>GMail</option>
                    <option
                        selected={getSetting('EMAIL_OUTGOING_SERVER_TYPE').value === 'office' ? 'selected' : ''}
                        value='office'>Office 365</option>
                </select>
                <div>
                    Host
                    <input
                        defaultValue={getSetting('EMAIL_OUTGOING_SERVER_DOMAIN').value}
                        onBlur={(e) => _handleSetValue('EMAIL_OUTGOING_SERVER_DOMAIN', e.target.value)}
                        className='form-control' placeholder='Host' />
                </div>
                <div>
                    Port
                    <input
                        defaultValue={getSetting('EMAIL_OUTGOING_SERVER_PORT').value}
                        onBlur={(e) => _handleSetValue('EMAIL_OUTGOING_SERVER_PORT', e.target.value)}
                        className='form-control' placeholder='Port' />
                </div>
                <div>
                    Email Address
                    <input
                        defaultValue={getSetting('EMAIL_OUTGOING_SERVER_USERNAME').value}
                        onBlur={(e) => _handleSetValue('EMAIL_OUTGOING_SERVER_USERNAME', e.target.value)}
                        className='form-control' placeholder='UserName' />
                </div>
                <div>
                    Password
                    <input
                        //defaultValue={getSetting('EMAIL_OUTGOING_SERVER_PASSWORD').value}
                        onBlur={(e) => _handleSetValue('EMAIL_OUTGOING_SERVER_PASSWORD', e.target.value)}
                        className='form-control' placeholder='Password' />
                </div>
                <div>
                    Security
                    <select
                        onChange={(e) => _handleSetValue('EMAIL_OUTGOING_SERVER_AUTH_TYPE', e.target.value)}
                        defaultValue={getSetting('EMAIL_OUTGOING_SERVER_AUTH_TYPE').value}
                        className='form-control'>
                        <option></option>
                        <option
                            selected={getSetting('EMAIL_OUTGOING_SERVER_AUTH_TYPE').value === 'ssl' ? 'selected' : ''}
                            value='ssl'
                        >SSL</option>
                        <option
                            selected={getSetting('EMAIL_OUTGOING_SERVER_AUTH_TYPE').value === 'tls' ? 'selected' : ''}
                            value='tls'
                        >TLS</option>
                        <option
                            selected={getSetting('EMAIL_OUTGOING_SERVER_AUTH_TYPE').value === 'none' ? 'selected' : ''}
                            value='none'
                        >None</option>
                    </select>
                </div>

                <button
                    onClick={(e) => _handleTestConnection('Outgoing')}
                    className='btn'>
                    {/* {
                        testing
                            ?
                            <FontAwesomeIcon icon={faSpinner} spinPulse />
                            :
                            <>Test Connection</>
                    }
                     */}
                </button>

            </div>

        </div>
    )
}

import React, { useEffect, useState } from 'react'
import { Person } from 'react-bootstrap-icons'
import ToggleButton from '../../../../../../../../../components/ui/toggle_button/ToggleButton'

export default function WorkFlowPeopleFilter({ onChange, value }) {
    const [options, setOptions] = useState([])

    useEffect(() => { if (value && Array.isArray(value)) setOptions(value) }, [value])
    const _setValue = (o) => {
        var _options = options
        if (_options.includes(o)) _options.splice(_options.indexOf(o), 1)
        else _options.push(o)
        if (onChange) onChange(_options)
        setOptions(_options)
    }
    return (
        <div className='d-flex'>

            <ToggleButton className='d-flex align-items-center' style={'tick'}
                defaultValue={options && options.includes('Patients')} onToggle={() => _setValue('Patients')}>
                <div className='d-flex align-items-center'><Person />Patients </div>
            </ToggleButton>

            <ToggleButton
                className='d-flex align-items-center' style={'tick'}
                defaultValue={options && options.includes('Clinicians')}
                onToggle={() => _setValue('Clinicians')}>
                <div className='d-flex align-items-center'><Person />Clinicians </div>
            </ToggleButton>

            <ToggleButton className='d-flex align-items-center' style={'tick'}
                defaultValue={options && options.includes('Other')}
                onToggle={() => _setValue('Other')}>
                <div className='d-flex align-items-center'><Person />Other </div>
            </ToggleButton>
        </div >
    )
}

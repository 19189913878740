import React, { useContext, useEffect, useState } from 'react'
import { Calendar2Check, Calendar2X } from 'react-bootstrap-icons'
import Calendars from '../../../../../../libs/api/services/Calendars'
import CalendarFacilityContext from '../../CalendarFacilityProvider'

export default function CelendarTypeSelection({ filterAvailable, onSelected }) {
    const { activeCalendarEvent, setEventActiveOn } = useContext(CalendarFacilityContext)
    const [calendarTypes, setCalendarTypes] = useState()
    // const [active, setActive] = useState(defaultValue ? defaultValue : [])
    // const CALENDAR_TYPES = [
    //     { key: 'ADMIN', type: "Administrator's calendar" },
    //     { key: 'ADMIN_CLINICIAN', type: "Administrator's clinician management calendar" },
    //     { key: 'ADMIN_PATIENT', type: "Administrator's patient management calendar" },
    //     { key: 'CLINICIAN', type: "Clinicians calendar" },
    //     { key: 'CLINICIAN_PATIENT', type: "Clinicians's patient management calendar" },
    // ]

    const _handleSelected = (t) => {
        if (onSelected) onSelected(t); else setEventActiveOn(t)
    }
    useEffect(() => { _loadCalendarTypes() }, [])

    const _loadCalendarTypes = () => {
        Calendars
            .ListCalendarTypes()
            .then((r) => setCalendarTypes(r))
            .catch(() => { })
    }
    
    if (!activeCalendarEvent || Object.keys(activeCalendarEvent).length === 0) return

    return (
        <div style={{ cursor: 'pointer' }}>
            {calendarTypes && calendarTypes.map((t) =>
                <div
                    key={t.id}
                    onClick={() => _handleSelected(t)}
                    className={'rounded p-2 m-1 '
                        .concat(filterAvailable && filterAvailable.includes(t.type) ?
                            'bg-secondary opacity-25' :
                            (activeCalendarEvent && activeCalendarEvent.availableCalendars)
                                ? activeCalendarEvent.availableCalendars
                                    .find(a => a.calendarType.id === t.id) != null
                                    ? ' bg-primary-container' : ' bg-secondary-container' : 'bg-secondary-container')
                    }
                >
                    {activeCalendarEvent.availableCalendars && activeCalendarEvent.availableCalendars.find(a => a.calendarType.id === t.id) != null ? <Calendar2Check className='me-1' /> : <Calendar2X className='me-1' />}
                    {t.description}</div>
            )}
        </div>
    )
}

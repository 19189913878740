import React, { useState } from 'react'
import CalendarGenerics from '../../CalendarGenerics'

export default function DOWSelectorComponent({ onChange, multi,defaultValue }) {
    const [active, setActive] = useState(defaultValue ? defaultValue : [])
    const _handleSetDay = (d) => {
        var _active = [...active]
        if (!multi) {
            _active[0] = d.short
        } else {
            if (_active.includes(d.short))
                _active.splice(_active.indexOf(d.short), 1)
            else
                _active.push(d.short)
        }
        if (onChange) onChange(_active)
        setActive([..._active])
    }

    return (<div className={'d-flex justify-content-evenly '}>{CalendarGenerics.daysOfWeek.map((d) => <button onClick={() => _handleSetDay(d)} className={'btn btn-xs '.concat(active.includes(d.short) ? 'bg-primary-container ' : ' opacity-75 ')}>{d.short}</button>)}</div>
    )
}

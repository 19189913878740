import React, { useContext, useEffect, useState } from 'react'
import { Clipboard2PlusFill } from 'react-bootstrap-icons'
import Authentication from '../../../../libs/api/services/Authentication'
import ToDoListWorkFlows from '../../../../libs/api/services/ToDoListWorkFlows'
import CommonFunctionsContext from '../../../common/CommonFunctionsProvider'
import ToDoListContext from '../ToDoListProvider'
import ToDoListGroupContainer from './Groups/ToDoListGroupContainer'
import StartNewToDoListWizard from './Wizards/StartNewToDoListWizard'

export default function ToDoListContainer() {
    const { setSectionInfo } = useContext(CommonFunctionsContext)
    const { ToDoLists, LoadToDoLists, showError } = useContext(ToDoListContext)
    const [mode, setMode] = useState()

    const GroupDate = () => {
        var res = []
        if (ToDoLists)
            for (const l of ToDoLists) {
                var x = res.find(a => a.date === l.assignedDate.substring(0, 10))
                if (x)
                    x.items.push(l)
                else
                    res.push({
                        date: l.assignedDate.substring(0, 10),
                        items: [l]
                    })
            }
        return res
    }

    useEffect(() => {
        setSectionInfo(`${JSON.parse(Authentication.UserName())} To Do List`)
        return () => { setSectionInfo() }
    }, [])
    switch (mode) {
        case 'Start New': return <StartNewToDoListWizard onCancel={() => setMode()} onComplete={(props) => {
            // Populate User List
            var users = [{ user: { id: Authentication.UserId() } }]
            props.assisted.assisted && users.push({ user: { id: props.assisted.user } })
            // Send to API
            ToDoListWorkFlows
                .StartToDoList({
                    listItemGroup: props.list,
                    toDoListAssignmentUsers: users
                })
                .then((r) => {
                    // Reload lists
                    LoadToDoLists()
                    setMode()
                }).catch((e) => showError(e))

        }} />
        default: return (
            <div className='d-flex flex-column mh-100'>
                {
                    Authentication.getFacilityRole() === 'Patient'
                        ?
                        <></>
                        :
                        <div className='bg-primary-container p-3'>
                            <button onClick={() => setMode('Start New')} className='btn'><Clipboard2PlusFill /> Start New List</button>
                        </div>
                }
                <div className='flex-grow-1' style={{ overflow: 'auto' }}>
                    {GroupDate().map((t, x) => <>
                        <div key={`DateLI-${x}`} className='text-center opacity-50 m-2'>
                            <h2>{t.date}</h2>
                        </div>
                        {t.items.map((i) => <ToDoListGroupContainer key={`GC-${Math.random() * 300}-${x}`} item={i} />)}
                    </>)}
                </div>
            </div>)
    }

}

import React, { useEffect, useState } from 'react'
import { Calendar2, Calendar2CheckFill, Link } from 'react-bootstrap-icons'
import { NavLink } from 'react-router-dom'
import Authentication from '../../../../libs/api/services/Authentication'
import Dashboards from '../../../../libs/api/services/Dashboards'

export default function DashboardWidgetCalendar() {
  const [evntDetails, setEventDetails] = useState()
  useEffect(() => { _getUnreadMessageCount() }, [])
  const _getUnreadMessageCount = async () => {
    // No unread
    setEventDetails(await Dashboards.CalendarStatus().catch((e) => { console.error(e) }))
  }

  return (
    <div className='col-lg-4 col-md-12 dashboard-card'>
      <div className='text-center bg-secondary-container p-3 rounded h-100 d-flex flex-column'>
        <div className='flex-shrink-1'>
          <h3><Calendar2CheckFill className='me-2' />Calender</h3>
        </div>
        <div className='flex-grow-1 d-flex flex-column justify-content-center align-items-center '>{evntDetails ? evntDetails.status : 'Checking your calendar'}</div>
        <div className='flex-shrink-1'>
          <NavLink
            key={'nav-cals'}
            className={"btn btn-sm"} tag={Link}
            //   to={menuItems[i].location}
            to={'/' + Authentication.getFacilityRole() + '/Calendar'}
          // onClick={(e) => _navigateTo(_menu.location)}
          >
            <button className='btn btn-sm'><Calendar2 className='me-2' />View My Calendar</button>
          </NavLink>
        </div>
      </div>
    </div>
  )
}

import React, { useEffect, useState } from 'react';
import { PlusCircle, Trash2 } from 'react-bootstrap-icons';

export default function IntegrationHeaders({ defaultValue, onChange }) {
    const [headers, setHeaders] = useState(defaultValue ? defaultValue : [])

    useEffect(() => { if (onChange) onChange(headers) }, [headers])

    const _handleRemove = (i) => { var _headers = [...headers]; _headers.splice(i, 1); setHeaders(_headers); }

    const _handleSetValue = (t, v, i) => { var _headers = [...headers]; _headers[i][t] = v; setHeaders(_headers) }

    return (
        <div className='row'>
            {
                headers.map((h, i) =>
                    <div key={'head=' + i} className='row'>
                        <div className='col-5'><input onChange={(e) => _handleSetValue('name', e.target.value, i)} value={h.name} className='form-control form-control-sm' placeholder='Header' /></div>
                        <div className='col-5'><input onChange={(e) => _handleSetValue('value', e.target.value, i)} value={h.value} className='form-control form-control-sm' placeholder='Value' /></div>
                        <div className='col-2' onClick={() => _handleRemove(i)}><Trash2 /></div>
                    </div>
                )
            }
            <div className='col-12 text-center'><button onClick={() => setHeaders([...headers, { name: '', value: '' }])} className='btn btn-xs'><PlusCircle className='me-1' />Add Header</button></div>

        </div>
    )
}

import { faSpinner } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useEffect, useState } from 'react'
import { NodeMinus, PlusCircleDotted } from 'react-bootstrap-icons'
import QCaptureComponentWrapper from '../../../../capture/components/Layouts/QCaptureComponentWrapper'
import QBuilderGenerics from '../../../QBuilderGenerics'

export default function QBuilderComponentDynamicComponentTableComponent({ items, onResepone, responses, activeQuestionnaireAssignment, state, mode }) {
    const [selections, setSelections] = useState(responses ? responses : [])
    const [loading, setLoading] = useState(true)
    const [propsCache, setPropsCache] = useState([])

    function generateUUID() { // Public Domain/MIT
        var d = new Date().getTime();//Timestamp
        var d2 = ((typeof performance !== 'undefined') && performance.now && (performance.now() * 1000)) || 0;//Time in microseconds since page-load or 0 if unsupported
        return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
            var r = Math.random() * 16;//random number between 0 and 16
            if (d > 0) {//Use timestamp until depleted
                r = (d + r) % 16 | 0;
                d = Math.floor(d / 16);
            } else {//Use microseconds since page-load if supported
                r = (d2 + r) % 16 | 0;
                d2 = Math.floor(d2 / 16);
            }
            return (c === 'x' ? r : (r & 0x3 | 0x8)).toString(16);
        });
    }
    useEffect(() => {
        _loadCachedProperties()
    }, [])
    // Load component properties into memory
    const _loadCachedProperties = async () => {
        var _cache = []
        for (const item of items)
            _cache.push({ component: item.component.id, props: await QBuilderGenerics.loadComponentProperties(item.component.id) })
        setPropsCache(_cache)
        setLoading(false)
    }

    const _findCache = (rid, cid) => {
        var _cache = {
            properties: undefined,
            response: { answer: '' },
            details: {
                assignment: activeQuestionnaireAssignment
            }
        }
        try {
            _cache.properties = [...(propsCache.find(p => p.component === cid).props), { force: true }]
        } catch (e) {
            console.error('_findCache', e)
            _cache.properties = [{ force: true }]
        }
        try {
            const _row = selections.find(s => s.rid === rid)
            if (!_row) throw Error('Row Data Not Found')
            // Find component data
            if (!_row.cols) throw Error('Row Column Data Not Found')
            const _col = _row.cols.find(c => c.componentId === cid)
            if (!_col) throw Error('Column Data Not Found')
            _cache.response.answer = _col.value
            return _cache
        } catch (e) {
            //  Row data not found
            // console.error('_findCache', e)
        }

        return _cache
    }

    const _generateLine = (_lineItem, rid, index) => {
        return <tr>
            {
                _lineItem
                    .map((lio) =>
                        <td key={lio.component.id + index}>
                            <QCaptureComponentWrapper
                                cache={_findCache(rid, lio.component.id)}
                                component={{ ...lio.component, componentType: { ...lio.type }, properties: [] }}
                                mode={mode}
                                edit={false}
                                onSetData={(c, v, u) => u ? _handleSetData(c, v, rid, index) : {}}
                                onAction={() => { }}
                                activeSubsections={[]}
                                defaultMode={'Overview'}
                                view={'Overview'}
                                state={state}
                            />
                        </td>
                    )
            }
           {mode !== 'Discover' ? <td className='text-center'><button onClick={() => _handleRemoveLineItem(index)} className='btn btn-xs'><NodeMinus /> Remove</button></td> : <></>}
        </tr>
    }
    const _generateHeading = (_lineItem) => {
        return <thead>
            <tr>{
                _lineItem
                    .map((lio) =>
                        <th>{lio.name ? lio.name : ''}</th>
                    )
            }{mode !== 'Discover' ? <th></th> : <></>}
            </tr>
        </thead>
    }
    const _handleRemoveLineItem = (index) => {
        var _selections = [...selections]
        _selections.splice(index, 1)
        setSelections(_selections)
        onResepone(_selections)
        // alert('Remove')
    }
    const _handleAddLineItem = () => {
        const rid = generateUUID()
        // Casting errors for change component
        if (!Array.isArray(selections)) {
            onResepone([{ rid: rid }])
            setSelections([{ rid: rid }])
            return
        }
        onResepone([...selections, { rid: rid }])
        setSelections([...selections, { rid: rid }])
    }


    const _handleSetData = (cid, v, rid, colidx) => {
        var _selections = [...selections]
        // Try find row
        try {
            var _found = false
            for (var i = 0; i < _selections.length; i++) {
                if (_selections[i].rid === rid) {
                    _found = true
                    var _cols = undefined
                    if (Object.keys(_selections[i]).includes('cols') === true) {
                        // Has Cols Data
                        var _doAdd = true
                        for (var x = 0; x < _selections[i].cols.length; x++) {
                            if (_selections[i].cols[x].componentId === cid) {
                                // Update
                                _doAdd = false
                                _selections[i].cols[x] = { componentId: cid, value: v }
                            }
                        }
                        if (_doAdd) {
                            // Update
                            _selections[i].cols.push({ componentId: cid, value: v })
                        }
                        _cols = []
                    } else {
                        // Init Cols Data
                        _selections[i].cols = [{ componentId: cid, value: v }]
                    }
                }
            }
            // var _row = _selections.find(r => r.rid === rid)
            if (!_found) throw Error('Invalid Row ID')
        } catch (e) {
            // Row ID Not Found
            // console.error('_handleSetData', e)

        }
        onResepone(JSON.parse(JSON.stringify(_selections)))
        setSelections(_selections)
    }
    const _rendenderMain = () => {
        var _ret = []
        var _table = []
        //<QBuilderComponentDynamicComponentTableComponent />
        try {
            if (selections) {
                selections.map((_sel, i) => {
                    _table.push(_generateLine(items, _sel.rid, i))
                })
            }
        } catch (e) {
            //_ret = <div className='text-center text-danger'><ExclamationDiamondFill /> Component Details Not Available</div>
            //return <div>{JSON.stringify(e)}</div>
        }
        // Add
        _ret.push(
            <>
                <table key={'qbc' + generateUUID} className='rounded table-transparent-bg'>{_generateHeading(items)}<tbody>{_table ? _table : <></>}
                    <tr onClick={() => _handleAddLineItem()} style={{ cursor: 'pointer' }}>{mode !== 'Discover' ? <td className='text-center' colSpan={items.length + 1}><PlusCircleDotted /> Add</td> : <></>}</tr>
                </tbody></table>
            </>
        )
        return _ret
    }
    return loading ? <div className='text-center'><FontAwesomeIcon icon={faSpinner} spin /></div> : _rendenderMain()
}

import React from 'react'
import Authentication from '../../../../libs/api/services/Authentication'
import { WorkFlowEnvronmentProvider } from '../../workflow/common/WorkFlowEnvironment/WorkFlowEnvronmentProvider'
import '../Calendar.css'
import CalendarDetailsView from './CalendarDetailsView'
import { CalendarProvider } from './CalendarProvider'
import CalendarFilterBar from './calendar/filters/CalendarFilterBar'
import CalendarAllSelection from './calendar/user/CalendarAllSelection'
export default function CalendarComponent({ filter, calendarType, patient }) {
    // const [view, setView] = useState('Month')
    const _renderView = () => {
        /*switch (filters.view) {
            case 'Day': return <CalendarDayView />
            case 'Week': return <CalendarWeekView />
            case 'Month':
            default: return <CalendarMonthView />
        }*/
    }
    return (
        <WorkFlowEnvronmentProvider
            environment={{
                patient: patient,
                requestUser: { id: Authentication.UserId() },
                facility: { id: Authentication.getFacility(), role: Authentication.getFacilityRole() },
            }}
        >
            <CalendarProvider filterMode={filter} calendarType={calendarType} patient={patient}>
                <div className='h-100 d-flex flex-column position-relative' style={{ maxHeight: '100%' }}>
                    <div className='d-flex justify-content-evenly flex-shrink-1 align-items-center p-1 bg-primary-container' style={{ zIndex: 998 }}>
                        <CalendarFilterBar />
                    </div>
                    <div>
                        <CalendarAllSelection calendarType={calendarType} patient={patient} />
                    </div>
                    <div className='flex-grow-1 ' style={{ overflow: 'auto' }}>
                        <CalendarDetailsView />
                    </div>
                </div>
            </CalendarProvider>
        </WorkFlowEnvronmentProvider>
    )
}

import React, { useEffect, useRef, useState } from 'react'
import { PlusSquare } from 'react-bootstrap-icons'
import { useClickOutside } from '../Hooks/useClickOutside'

export default function SearchComponent({ details, onsBeforeSearch, onAfterSearch, onSelected, placeholder, response, defaultValue, focusSearch }) {
    const containerRef = useRef()
    const resultsRef = useRef()
    const searchRef = useRef()
    const [isOpen, setIsOpen] = useState(false)
    const [results, setResults] = useState([])
    const [result, setResult] = useState([])
    const [highlightedIndex, setHighlightedIndex] = useState(-1)
    // const highlightedIndexRef = useRef(0)
    const [options, setOptions] = useState(typeof details === 'undefined' ? {
        data: [{ id: 1, name: 'tom', surname: 'harry' }],
        options: {
            reRefKey: 'id',
            template: [{
                refKey: 'name',
                descr: '',
            }]
        }
    } : details)

    useClickOutside(containerRef, (() => { setIsOpen(false); setResult() }))

    useEffect(() => {
        setOptions(details)
        // if (results.length === 0)
        //   setResults(options.data)
    }, [details])

    /// Add Keyboard Events
    useEffect(() => {
        const _handleKeyboardEvents = (e) => {
            switch (e.key) {
                case 'Escape': setResult(); setIsOpen(false); break;
                case 'ArrowDown': setHighlightedIndex((highlightedIndex) => highlightedIndex < results.length - 1 ? highlightedIndex + 1 : results.length - 1); break;
                case 'ArrowUp': setHighlightedIndex((highlightedIndex) => highlightedIndex > 0 ? highlightedIndex - 1 : 0); break;
                case 'Enter':
                    _handleSetSelected(results[highlightedIndex][options.options['reRefKey']], results[highlightedIndex]);
                    break;
                default: break;
            }
        }

        if (isOpen && results)
            document.addEventListener('keydown', _handleKeyboardEvents);
        else
            document.removeEventListener('keydown', _handleKeyboardEvents);
        return () => {
            document.removeEventListener('keydown', _handleKeyboardEvents);
        };
    }, [isOpen, results, highlightedIndex])

    const _renderDefault = () => {
        if (defaultValue)
            for (const item of options.data) {
                if (item[options.options.reRefKey] === defaultValue[options.options.reRefKey]) {
                    var entries =
                        options.options.template
                            .map((t) => { return item[t.refKey] })
                    if (entries)
                        resultsRef.current.value = entries.join(' ')
                }
            }
        else resultsRef.current.value = null
    }

    useEffect(() => {
        // if (results.length === 0)
        setResults(options.data)
        _renderDefault()
    }, [options])
    useEffect(() => {
        // if (results.length === 0)
        _renderDefault()
        // setResults(options.data)
    }, [defaultValue])
    /*
      Handle Events
    */

    const _handleSearch = (search) => {
        var res = []

        if (!search || search === '')
            return setResults(options.data)
        else {
            for (const item of options.data) {
                var found = false
                for (const k of Object.keys(item))
                    try {
                        for (const word of item[k].toLowerCase().split(' ')) {
                            if (word.length > 0)
                                if (search.toLowerCase().indexOf(word.toLowerCase()) > -1) {
                                    found = true
                                    res.push(item)
                                    break;
                                }
                        }
                        if (found) break;
                    } catch (e) { }
            }

        }
        setResults(res)
    }

    // Toggle component open/close
    const _handleResultsClick = () => {
        if (focusSearch)
            setTimeout(() => { searchRef.current.select() }, 300)

        setIsOpen(true)
        resultsRef.current.blur()
    }

    // Handle selected
    const _handleSetSelected = (res_id, _result) => {
        setResult(_result)
        if (options.options.returns === 'object' && onSelected)
            onSelected(_result)
        else if (onSelected) onSelected(res_id)

        var dtls = options.options.template
            .map((t) => { return _result[t.refKey] + "" })
        resultsRef.current.value = dtls.join(' ')

        setIsOpen(false)
    }
    /*
      Render line item
    */

    const _renderResultItem = (r, i) => {
        const res_id = r[options.options['reRefKey']]
        if (!res_id) throw Error('Invalid reRefKey set in options')
        if (!options.options.template) throw Error('No template specified in options')
        const _node_value = options.options.template
            .map((t,i) => <span key={`Search-Item-Result-${i}-${r[options.options['reRefKey']]}-${i}`}>{t.descr ? <b>{t.descr}</b> : <></>} {r[t.refKey]}</span>)
        return <li key={`Search-Item-Result-li-${i}-${r[options.options['reRefKey']]}`} className={`m-1 p-1  ${i === highlightedIndex && 'bg-secondary-container'}`} onClick={() => _handleSetSelected(res_id, r)} >{_node_value}</li >
    }
    const _renderAdd = () => {
        if (!options.options.onAdd) return
        return options.options.onAdd ? <li key='Render-LI' className={`m-1 p-1 d-flex align-items-center`} onClick={() => options.options.onAdd.action(searchRef.current.value)}><PlusSquare className='me-1' />{options.options.onAdd.text ? options.options.onAdd.text : 'Add New'}</li> : undefined
    }

    // This will activate the dropdown when a user clicks on it
    // or uses the keyboard navigation like using Tab
    const _handleComponentFocus = (e) => {
        if (isOpen) return
        _handleResultsClick()
    }

    const _handleUnfocus = (e) => {
        if (e.relatedTarget != null && !e.currentTarget.contains(e.relatedTarget)) {
            setResult(); setIsOpen(false);
            return
        }
    }

    return (
        <div ref={containerRef} onBlur={_handleUnfocus} className='position-relative'>
            <input onFocus={_handleComponentFocus} style={{ cursor: 'pointer' }} onClick={() => _handleResultsClick()} ref={resultsRef} className='form-control form-control-sm' placeholder={placeholder ? placeholder : ''} />
            <div className={'position-absolute p-3 bg-primary-container rounded shadow w-100 '.concat(isOpen ? 'd-block' : 'd-none')} style={{ zIndex: 999 }}>
                <input ref={searchRef} onChange={(e) => _handleSearch(e.target.value)} className='form-control form-control-sm' placeholder='Search' />
                <ul className='list-unstyled' style={{ maxHeight: '250px', overflow: 'auto', cursor: 'pointer' }}>
                    {_renderAdd()}
                    {results.map((res, index) => _renderResultItem(res, index))}
                </ul>
            </div>
        </div>
    )
}

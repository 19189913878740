import { faSpinner } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useContext, useEffect, useState } from 'react'
import { Clipboard2HeartFill, EyeFill, Flag, FlagFill, Link, PinAngleFill, PinFill, PlusCircleDotted } from 'react-bootstrap-icons'
import { NavLink, useParams } from 'react-router-dom'
import Authentication from '../../../../libs/api/services/Authentication'
import Patient from '../../../../libs/api/services/Patient'
import QuestionnaireAssignment from '../../../../libs/api/services/QuestionnaireAssignment'
import CommonFunctionsContext from '../../../common/CommonFunctionsProvider'
import PatientContext from '../../patient/management/PatientProvider'
import QuestionnaireAssignmentAdmin from '../assignment/QuestionnaireAssignmentAdmin'

export default function PatientQuestionnaires() {
    const { id, questionair } = useParams()
    const { setSectionInfo } = useContext(CommonFunctionsContext)
    const { activePatient } = useContext(PatientContext)
    const [mode, setMode] = useState('')
    const [patient, setPatient] = useState()
    const [questionnaires, setQestionnaires] = useState([])
    const [loading, setLoading] = useState(true)

    const _handleOnAdd = (v) => {
        load()
        setTimeout(setMode, 300, '')
    }

    const _setupAdd = () => {
        Patient.GetById(id)
            .then((r) => {
                setPatient(r)
                setMode('Add')
            })

    }

    useEffect(() => {
        if (activePatient)
            setSectionInfo(`Questionnaires for ${activePatient.userProfile.firstName} ${activePatient.userProfile.lastName} at ${Authentication.getFacilityName()}`);
        else
            setSectionInfo(`Questionnaires`);
    }, [activePatient])

    const _checkPinned = (id) => {

        try {
            var quest = questionnaires.find(r => r.id === id)
            const isPinned = JSON.parse(quest.options.find(r => r.property === "PIN_TO_DASH").value)
            if (isPinned)
                return <button onClick={(e) => _handlePin(id, false)} className='btn btn-xs w-100 text-start text-primary'><PinFill />Unpin from patient dashboard </button>
        } catch (e) { }

        return <button onClick={(e) => _handlePin(id, true)} className='btn btn-xs w-100 text-start text-secondary'><PinAngleFill />Pin to patient dashboard </button>
    }

    const load = () => {
        QuestionnaireAssignment
            .GetPatientQuestionnairesByType(id, 'Questionnaire')
            .then((r) => {
                setQestionnaires(r)
                setLoading(false)
            })
    }

    useEffect(() => {
        load()
    }, [])

    const _handlePin = (qid, val) => {
        QuestionnaireAssignment
            .SetAssignmentOption(qid, 'PIN_TO_DASH', val)
            .then((r) => {
                load()
            })
    }

    if (mode === "Add" && patient)
        return (<QuestionnaireAssignmentAdmin
            onDone={(v) => _handleOnAdd(v)}
            onCancel={(e) => setMode('')}
            skip={[2]}
            startAt={1}
            defaultValues={{ user: patient }}
        />)
    else
        return (
            <div className='d-flex flex-column h-100'>
                <div className='d-flex flex-row text-center p-3 bg-secondary-container flex-shrink-1'>
                    <div
                        onClick={(e) => _setupAdd()}
                        style={{ cursor: 'pointer' }} className=' bg-primary-container m-2 p-2 rounded-3'>
                        <PlusCircleDotted
                        /> Assign a questionnaire
                    </div>
                    {/* <div style={{ cursor: 'pointer' }} className=' bg-primary-container m-2 p-2 shadow '>
                        Assigned (100)
                    </div>
                    <div style={{ cursor: 'pointer' }} className=' bg-secondary-container m-2 p-2'>
                        Complete (99)
                    </div>
                    <div style={{ cursor: 'pointer' }} className=' bg-secondary-container m-2 p-2'>
                        Incomplete (1)
                    </div> */}
                </div>
                <div className='row text-center mb-2 bg-primary-container flex-shrink-1' >
                    <div className='col-1'>
                        Alerts
                    </div>
                    <div className='col-3'>
                        <b>Name</b>
                    </div>
                    <div className='col-3'>
                        <b>Assigned On</b>
                    </div>
                    <div className='col-3'>
                        <b>Completed On</b>
                    </div>
                    <div className='col-2'>
                        <b>Options</b>
                    </div>
                </div>
                <div style={{ overflow: 'auto', position: 'relative' }} className=' flex-grow-1'>
                    <div className={'justify-content-center align-items-center position-absolute bg-primary-container '.concat(loading ? 'show-loading' : 'hide-loading')} style={{ left: '0px', top: '0px', right: '0px', bottom: '0px', zIndex: 1000 }}>
                        <div className='text-center' >

                            {/* <div>{itemsLoaded}/{totalItems}</div> */}
                            <Clipboard2HeartFill size={75} />
                            <div>
                                <FontAwesomeIcon className='me-2' size='2x' icon={faSpinner} spinPulse />
                                Loading patient questionnaires
                            </div>
                        </div>
                    </div>
                    {
                        questionnaires.map((q) =>
                            <div className='row mb-1 text-center'>
                                <div className='col-1'>
                                    {
                                        q.alerts.length > 0
                                            ?
                                            <FlagFill className='text-danger me-2' />
                                            :
                                            <Flag className='text-secondary me-2' />
                                    }

                                </div>
                                <div className='col-3 text-start'>

                                    {q.questionnaire.name}
                                </div>
                                <div className='col-3'>
                                    {q.assignedDate.substring(0, 19)}
                                </div>
                                <div className='col-3'>
                                    {q.completedDate ? q.completedDate.substring(0, 19) : ''}
                                </div>
                                <div className='col-2 text-center d-flex flex-column align-items-start '>
                                    <NavLink
                                        key={q.id}
                                        className={'btn btn-sm w-100 text-start text-primary'} tag={Link}
                                        //   to={menuItems[i].location}
                                        to={'/' + Authentication.getFacilityRole() + '/Patient/' + id + '/Questionanire/' + q.questionnaire.id + "/" + q.id}
                                    >
                                        <EyeFill />View Questionnaire
                                    </NavLink>
                                    {_checkPinned(q.id)}
                                </div>
                            </div>
                        )
                    }
                </div>
            </div>
        )
}

import React, { useContext } from 'react'
import ThemeContext from '../context/ThemeContext'

interface Props {
  name: string
  onChange: (n: string, v: any) => void
}

const ThemeComponent = ({ name, onChange }: Props) => {
  const { dark, toggleDark } = useContext(ThemeContext)
  const handleOnClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault()
    toggleDark!()
    onChange(name, dark)
  }
  return <button onClick={handleOnClick}>THEME : {dark ? 'Dark' : 'Light'}</button>
}

export default ThemeComponent

import React, { useEffect, useState } from 'react'
import { Clipboard2Check, Tools, Trash, XCircleFill } from 'react-bootstrap-icons'
import SearchComponent from '../../../../../components/search/SearchComponent'
import FlowAssistant from '../../../../../libs/api/services/FlowAssistant'
import Questionnaire from '../../../../../libs/api/services/Questionnaire'
import WorkFlow from '../../../../../libs/api/services/WorkFlow'

export default function FormTaskLineItem({ onChanged, task, facility, user, onDelete, onSetProperty, config, errors, values, setValue }) {
    const [active, setActive] = useState([])
    const [mode, setMode] = useState('Configured')
    const [forms, setForms] = useState([])
    const [form, setForm] = useState()

    useEffect(() => { _loadForms() }, [])

    const _loadForms = () => {
        Questionnaire
            .ListQuestionnairesByType('form')
            .then((r) => {
                var _configred = false
                try {
                    const FormId = JSON.parse(config.properties.find(f => f.type.type === 'Form').value)
                    if (FormId) {
                        setForm({ ...r.find(rs => rs.id === FormId) })
                        _configred = true
                    }
                } catch (e) { _configred = false }
                setMode(_configred ? 'Configured' : 'Edit')
                setForms(r)
            })
    }

    const _handleCreateForm = () => {
        const _values = { ...values, task: task }
        FlowAssistant
            .BookmarkFlowAndGoTo('/Facility/Configuration/Events', 'NEW_EVENT_WORKFLOW_ADD_EVENT', _values, '/sys-admin/form/builder')
    }
    const _handleSetName = (form) => {
        var name = 'Generate Form'
        if (form) {
            if (form.name) name = 'Generate ' + form.name + ' form'
        }
        return name

    }
    const _handleSetForm = (form) => {
        if (!form) return

        WorkFlow
            .SetWorkFlowEventTaskProperty(values.event.id, 'Form', {
                id: task.id,
                taskName: _handleSetName(form),
                taskType: task.taskType,
                properties: [{
                    value: JSON.stringify(form.id)
                }]
            })
            .then((r) => {
                setForm(form)
                if (onChanged) onChanged()
            }).catch((e) => { if (e.errors) errors(e.errors); else errors(e.message); })

    }

    return (
        <div>
            <div className='row text-start p-1'>
                <div className='col-1'>
                    <Clipboard2Check size={16} className='me-2' />
                </div>
                <div style={{ cursor: 'pointer' }} onClick={() => setMode('Edit')} className='col-10 text-center'>
                    {config.taskName}
                </div>
                <div

                    style={{ cursor: 'pointer' }} className='col-1 text-end'>
                    <Tools onClick={() => setMode('Update')} className='me-2' />
                    <Trash onClick={(e) => onDelete(config.id)} size={21} />
                </div>


                {
                    mode !== 'Configured'
                        ?
                        <div className='shadow rounded p-2'>
                            <div style={{ cursor: 'pointer' }} className='col-12 text-end' onClick={() => setMode('Configured')}><XCircleFill size={20} /></div>
                            <b>Select the type of form to be generate</b>
                            <SearchComponent
                                placeholder={'Form to be generated'}
                                defaultValue={form ? form : undefined}
                                focusSearch={true}
                                onSelected={_handleSetForm}
                                details={{
                                    data: forms,
                                    options: {
                                        returns: 'object',
                                        reRefKey: 'id',
                                        onAdd: {
                                            action: (s) => _handleCreateForm(s),
                                            text: 'Create a new form'
                                        },
                                        template: [{
                                            refKey: 'name',
                                            descr: '',
                                        }]
                                    }
                                }} />
                            <div className='text-center'>
                                <button className='btn btn-xs' onClick={() => setMode('Configured')}>Done</button>
                            </div>
                        </div>
                        :
                        <></>
                }
            </div>
        </div>
    )
}

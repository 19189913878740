import React from 'react'
import { BuildingDown, Calendar2DateFill, Clipboard2Check, LayoutTextWindow, Mailbox, MenuUp, NodePlus, PenFill, People, PersonFill } from 'react-bootstrap-icons'
import { NavLink } from 'react-router-dom'

export default function DashboardContainer({ Icon, title, description, location }) {
    const ICON = (icon) => {
        switch (icon) {
            case 'messaging': return <MenuUp size={50} />
            case 'automation': return <NodePlus size={50} />
            case 'user': return <People size={50} />
            case 'add-user': return <PersonFill size={50} />
            case 'facility': return <BuildingDown size={50} />
            case 'email': return <Mailbox size={50} />
            case 'template': return <LayoutTextWindow size={50} />
            case 'calendar': return <Calendar2DateFill size={50} />
            case 'todo': return <Clipboard2Check size={50} />
            case 'tnc': return <PenFill size={50} />
            default: return <></>
        }
    }
    return (
        <NavLink
            to={location}
            className='col-12 col-lg-4 bg-primary-container p-3 rounded '>
            <div>{ICON(Icon)}</div>
            <div><b>{title}</b></div>
            <div><p className='pt-2'>{description}</p></div>
        </NavLink>
    )
}

import React, { useEffect, useState } from 'react'
import { ChatDots, Link, MenuUp } from 'react-bootstrap-icons'
import { NavLink } from 'react-router-dom'
import Authentication from '../../../../libs/api/services/Authentication'
import Dashboards from '../../../../libs/api/services/Dashboards'

export default function DashboardWidgetMessages() {
  const [messages, setMessages] = useState()
  useEffect(() => { _getUnreadMessageCount() }, [])
  const _getUnreadMessageCount = async () => {
    // No unread
    setMessages(await Dashboards.UnreadMessageCount().catch((e) => { }))
  }

  return (
    <div className='col-lg-4 col-md-12 dashboard-card'>
      <div className='text-center bg-secondary-container p-3 rounded h-100 d-flex flex-column'>
        <div className='flex-shrink-1'>
          <h3><ChatDots /> Messages</h3>
        </div>
        <div className='flex-grow-1 d-flex flex-column justify-content-center align-items-center '>{messages ? `You have ${JSON.stringify(messages)} unread messages` : 'You have no new messages'} </div>
        <div className='flex-shrink-1'>
          <NavLink
            key={'nav-msgs'}
            className={"btn btn-sm"} tag={Link}
            //   to={menuItems[i].location}
            to={'/' + Authentication.getFacilityRole() + '/Messages'}
          // onClick={(e) => _navigateTo(_menu.location)}
          >
            <button className='btn btn-sm'><MenuUp className='me-2' />View Messages</button>
          </NavLink>
        </div>
      </div>
    </div>
  )
}

import API from "../API";
import Authentication from "./Authentication";

var NotificationsAPI = {
    async GetNotifications() {
        var result = await API.process(
            `api/Notifications/${Authentication.UserId()}`,
            'GET',
            null
        ).catch((err) => {
            throw err;
        })

        return result
    },
    async RemoveNotification(id) {
        var result = await API.process(
            `api/Notifications/${id}`,
            'DELETE',
            null
        ).catch((err) => {
            throw err;
        })

        return result
    },
}

export default NotificationsAPI;
import React, { useEffect, useState } from 'react'
import LogsTableComponent from '../../../../../../../components/ui/table/LogsTableComponent'
import MessagingServiceProvider from '../../../../../../../libs/api/services/MessagingServiceProvider'

export default function ServiceProviderLogs({ title, description, navigation, errors, setValue, values }) {
    const [mode, setMode] = useState()
    const [logs, setLogs] = useState()
    const [loaded, setLoaded] = useState(false)

    useEffect(() => { _loadLogs() }, [])

    const _loadLogs = () => {
        MessagingServiceProvider
            .GetServiceLogs(values.serviceProviderService.id)
            .then((r) => {
                setLogs(r)
            }).catch((e) => errors(e))
        //values.serviceProviderService
    }

    useEffect(() => {
        title('Service Provider Logs')
        description('')
        navigation([
            // {
            //     text: 'Back', type: 'Back', action: async () => { }
            // },
            { text: 'Done', type: 'Done', action: async () => { } }
        ])


        // _loadSettings()
    }, [])
    console.log(logs)
    return (
        <div className='text-start rounded shadow'>
            {
                logs
                    ?
                    <LogsTableComponent
                        logs={logs} options={{
                            columns: [
                                {
                                    name: 'id',
                                    type: 'GUID',
                                    human: '#',
                                },
                                {
                                    name: 'initiatedDateTime',
                                    type: 'DATETIME',
                                    human: 'Initiated',
                                },
                                {
                                    name: 'processingStatus',
                                    type: 'STATUS',
                                    human: 'status',
                                },
                                {
                                    name: 'processingStatusDescription',
                                    type: 'STRING',
                                    human: 'Description',
                                },
                                {
                                    name: 'processingRequest',
                                    type: 'JSON',
                                    human: 'Sent',
                                    preparse: (r) => {

                                        if (r) {
                                            const dec = JSON.parse(r)
                                            var verb = undefined
                                            var endPoint = undefined
                                            var headers = undefined
                                            var body = undefined
                                            try {
                                                verb = JSON.parse(dec.verb)
                                            } catch { }
                                            try {
                                                endPoint = JSON.parse(dec.endPoint)
                                            } catch { }
                                            try {
                                                headers = JSON.parse(dec.headers)
                                            } catch { }
                                            try {
                                                body = JSON.parse(dec.body)
                                            } catch { }
                                            try {
                                                body = JSON.parse(JSON.parse(dec.body))
                                            } catch { }
                                            if(
                                                !verb &&
                                                !endPoint &&
                                                !headers &&
                                                !body
                                            ) return dec
                                            return {
                                                verb: verb,
                                                endPoint: endPoint,
                                                headers: headers,
                                                body: body
                                            }
                                        }
                                        return r
                                    }
                                },
                                {
                                    name: 'processingResponse',
                                    type: 'JSON',
                                    human: 'Received',
                                    preparse: (r) => {
                                        if (r) {
                                            const dec = JSON.parse(r)
                                            var status = undefined
                                            var reason = undefined
                                            var headers = undefined
                                            var body = undefined
                                            try {
                                                status = JSON.parse(dec.status)
                                            } catch { }
                                            try {
                                                reason = JSON.parse(dec.reason)
                                            } catch { }

                                            try {
                                                headers = JSON.parse(dec.headers)
                                            } catch { }
                                            try {
                                                body = JSON.parse(dec.body)
                                            } catch { }
                                            try {
                                                body = JSON.parse(JSON.parse(dec.body))
                                            } catch { }
                                            if(
                                                !status &&
                                                !reason &&
                                                !headers &&
                                                !body 
                                            ) return dec
                                            return {
                                                status: status,
                                                reason: reason,
                                                headers: headers,
                                                body: body
                                            }
                                        }
                                        return r
                                    }
                                }
                            ]
                        }} />
                    :
                    <></>
            }
        </div>
    )
}

import React, { ReactNode, useState } from 'react'
import { LayoutInterface } from '../interfaces/LayoutInterface'
import { ExplinationInterface } from '../interfaces/explination/ExplinationInterface'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faQuestionCircle } from '@fortawesome/free-solid-svg-icons'

type Props = {
  children: ReactNode
  layout: LayoutInterface
}

export default function ExplinationComponent({ layout, children }: Props) {
  const [showHint, setShowHint] = useState(false)
  const toggleHint = () => setShowHint(!showHint)

  const ExplinationWrapper = (explination: ExplinationInterface) => {
    switch (explination.position) {
      case 'HintButton':
        return (
          <div key={'explination-container-' + layout.name} style={{ position: 'relative' }}>
            <div
              style={{ position: 'absolute', bottom: '0px', right: '0px', zIndex: 999 }}
              onClick={() => toggleHint()}
            >
              <FontAwesomeIcon icon={faQuestionCircle} />
            </div>
            <div style={{ position: 'relative' }}>
              {children}
              <div
                className={showHint ? 'd-block bg-dark text-light rounded rounded-3 p-3 shadow shadow-3' : 'd-none'}
                onClick={() => toggleHint()}
                style={{ position: 'absolute', top: '0px', right: '0px', zIndex: 999 }}
              >
                {explination.details}
              </div>
            </div>
          </div>
        )
      default:
        break
    }
    return <>{children}</>
  }
  return layout.explination! ? ExplinationWrapper(layout.explination!) : children
}

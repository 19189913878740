import React, { useContext, useEffect, useRef, useState } from 'react'
import { ICONS } from '../../../../../components/icons/GenericIconPicker'
import CalendarGenerics from '../../CalendarGenerics'
import CalendarContext from '../CalendarProvider'
import CalendarEventPopup from './events/CalendarEventPopup'
const INTERVAL_SIZE = 30
export default function CelendarDayColumnComponent({ date, dow }) {
    const { filters, actieveTimeSlot, events, findEventForDate, setDate } = useContext(CalendarContext)
    // const [date, setDate] = useState(filters ? { ...filters } : { year: undefined, month: undefined, day: undefined })
    const [daysEvts, setDaysEvts] = useState([])

    const [celendar, setCalendar] = useState([])

    const maxEventRef = useRef(1)
    const activeTimeRef = useRef()
    const [currentEvent, setCurrentEvent] = useState()

    const _cellHeight = 25;

    const _handleScrollCurrentTime = (e) => {
        if (activeTimeRef.current)
            activeTimeRef.current.scrollIntoView({ behavior: "smooth", block: "center", inline: "nearest" })
    }

    useEffect(() => {
        _handleScrollCurrentTime()
    }, [activeTimeRef.current])
    useEffect(() => {
        _handleScrollCurrentTime()
        _buildWithEvents()
    }, [])

    useEffect(() => {
        // setDate(filters)
        _handleScrollCurrentTime()
        _buildWithEvents()
    }, [filters])

    useEffect(() => {
        _handleLoadEvents()
    }, [events])


    useEffect(() => {
        _buildWithEvents()
    }, [daysEvts])

    useEffect(() => {
        // alert(date)
        _buildWithEvents()
    }, [date])
    const _handleTSClick = (ts) => {
        if (!date) return
        const hm = ts.split(':')
        //setDate(CalendarGenerics.AdMinutes(date, ((hm[0] * 60)) + parseInt(hm[1])), 'Week')
        //+ ((hm[0] * 60)) + parseInt(hm[1]) * 60000 
        setDate(CalendarGenerics.AdMinutes(date, ((hm[0] * 60)) + parseInt(hm[1])), 'Week')
    }
    const _handleEvtTSClick = (_date, ts, ev) => {
        const hm = ts.split(':')
        //setDate(CalendarGenerics.AdMinutes(date, ((hm[0] * 60)) + parseInt(hm[1])), 'Week')
        //+ ((hm[0] * 60)) + parseInt(hm[1]) * 60000 
        setDate(CalendarGenerics.AdMinutes(_date, ((hm[0] * 60)) + parseInt(hm[1])), 'Week', ev)
    }
    const _handleLoadEvents = async () => {
        //setDaysEvts(await findEventForDate(filters.year + '-' + String(filters.month + 1).padStart(2, '0') + '-' + String(filters.day).padStart(2, '0')))
        if (date)
            setDaysEvts(await findEventForDate(date))

    }

    const _buildWithEvents = async () => {
        var _tevts = []
        var _active_events = 0
        // 1. Generate Time Slots
        var _heading = <div style={{ height: _cellHeight, maxHeight: _cellHeight, zIndex: 1099 }} className='text-center'><small>{dow ? dow.short : ''} {date ? date.getDate() : ''} {date ? CalendarGenerics.months[date.getMonth()].short : ''}</small></div>
        var _timeSlots = []
        // const _daysEvts = await findEventForDate(date)
        var _daysEvts = []
        // console.log(date && DateTimePickerFunctions.LocalISO(date))
        // console.log(date && (date.getFullYear() + '-' + String(date.getMonth() + 1).padStart(2, '0') + '-' + String(date.getDate()).padStart(2, '0')))
        if (date)
            _daysEvts = await findEventForDate(date.getFullYear() + '-' + String(date.getMonth() + 1).padStart(2, '0') + '-' + String(date.getDate()).padStart(2, '0'))

        // await _handleLoadEvents()
        for (const ts of CalendarGenerics.timeIntervals) {
            _tevts = []
            for (const d of _daysEvts) {
                if (d && ts === d.startDateTime.substring(11, 13) + ':' + String((Math.floor(d.startDateTime.substring(14, 16) / INTERVAL_SIZE) * INTERVAL_SIZE)).padStart(2, '0')) {
                    // _cnt_events++
                    _active_events++
                }
                if (d && ts === d.endDateTime.substring(11, 13) + ':' + String((Math.floor(d.endDateTime.substring(14, 16) / INTERVAL_SIZE) * INTERVAL_SIZE)).padStart(2, '0')) {
                    _active_events--
                }
                // If starts on this TS to the nearest 15
                var _etst = (Math.ceil(d.endDateTime.substring(14, 16) / INTERVAL_SIZE) * INTERVAL_SIZE)
                var _etsh = d.endDateTime.substring(11, 13)
                if (_etst >= 60) {

                    _etsh = String((parseInt(_etsh) + 1)).padStart(2, "0")
                    _etst = '00'
                }
               
                var _bgc = undefined
                var _icos = []

                if (d && ts === d.startDateTime.substring(11, 13) + ':' + String((Math.floor(d.startDateTime.substring(14, 16) / INTERVAL_SIZE) * INTERVAL_SIZE)).padStart(2, '0')) {
                    // calc to 15 min slots || 1hr == 4 | min == Floor(mins / 15)
                    // _active_events++
                    // hours 
                    // TODO : Calc days
                    const oneDay = 24 * 60 * 60 * 1000; // hours*minutes*seconds*milliseconds
                    const firstDate = new Date(d.endDateTime);
                    const secondDate = new Date(d.startDateTime);

                    const diffDays = Math.round(Math.abs((firstDate - secondDate) / oneDay));
                   
                    const et = d.endDateTime.substring(11, 16)
                    const st = d.startDateTime.substring(11, 16)
                    var etmn
                    if (st === et) {
                        etmn = ((parseInt(et.split(':')[0]) * (60 / INTERVAL_SIZE)) * INTERVAL_SIZE + (Math.ceil((parseInt(et.split(':')[1]) + 1) / INTERVAL_SIZE) * INTERVAL_SIZE) + ((diffDays * 24) * (INTERVAL_SIZE * (60 / INTERVAL_SIZE)))) //((diffDays * 24 ) * 15) )
                    } else {
                        etmn = ((parseInt(et.split(':')[0]) * (60 / INTERVAL_SIZE)) * INTERVAL_SIZE + (Math.ceil(et.split(':')[1] / INTERVAL_SIZE) * INTERVAL_SIZE) + ((diffDays * 24) * (INTERVAL_SIZE * (60 / INTERVAL_SIZE))))//((diffDays * 24 ) * 15) )
                    }
                    const stmn = ((st.split(':')[0] * (60 / INTERVAL_SIZE)) * INTERVAL_SIZE) + (Math.floor(st.split(':')[1] / INTERVAL_SIZE) * INTERVAL_SIZE)

                     //height:40px; overflow:hidden
                    if (d.action && d.action.properties) {
                        /**
                         * Check for a background colour
                         */
                        try {
                            _bgc = d.action.properties.find(f => f.name === "BACKGROUND_COLOUR")
                            if (_bgc)
                                _bgc = JSON.parse(_bgc.value)
                        } catch (e) { }
                        /**
                         * Check for icons
                         */
                        try {
                            _icos = d.action.properties.find(f => f.name === "ICONS")
                            if (_icos)
                                _icos = JSON.parse(_icos.value)
                        } catch (e) { }
                    }

                    // Custom colour takes pref
                    try {
                        if (Object.keys(d).includes('additionalOptions')) {
                            var _prop = d.additionalOptions.find(p => p.option === "COLOUR")
                            if (_prop) {
                                _bgc = JSON.parse(_prop.value)
                            }
                        }
                    } catch (e) { }
                    try {
                        if (Object.keys(d).includes('additionalOptions')) {
                            var _prop = d.additionalOptions.find(p => p.option === "ICONS")
                            if (_prop) {
                                _icos.push(...JSON.parse(_prop.value))
                            }
                        }
                    } catch (e) { }

                    _tevts.push({ s: d.summary, l: ((etmn - stmn) / INTERVAL_SIZE), st: d.status, bgc: _bgc, icos: _icos, i: d.id })

                }
                // TODO : Acomodate for dates
            }

            if (_tevts.length > 0) {
                var _tDiv = []
                var oset = 0
                var _bgc = '#ccc'
                var _icos = []
                
                for (const _tv of _tevts) {
                    _bgc = undefined
                    _icos = []
                    var _bg = 'bg-primary-container'
                    if (_tv.icos)
                        for (const i of _tv.icos)
                            _icos.push(ICONS.find(s => s.name === i))
                    if (_tv.bgc)
                        _bgc = _tv.bgc + 'ee'
                    else {
                        switch (_tv.st) {
                            case 'CANCELLED': _bg = 'bg-danger-container'; break;
                            case 'TENTATIVE': _bg = 'bg-warning-container'; break;
                            case 'CONFIRMED': _bg = 'bg-success-container'; break;
                            default: _bg = 'bg-primary-container'
                        }
                    }
                    _tDiv.push(<div onClick={() => { _handleEvtTSClick(date, ts, _tv.i) }} style={{ cursor: 'pointer', background: _bgc, overflow: 'hidden', position: 'absolute', height: (_cellHeight * _tv.l) + 'px', maxWidth: (100 / _tevts.length) + '%', left: ((100 / _tevts.length) * oset) + '%', opacity: '0.95' }} className={'w-100 d-flex shadow rounded '.concat(_bg)}>
                        <div className='ps-1 pe-2'>
                            <small >{_icos && _icos.map((i) => i && i.icon)}</small>
                        </div>
                        <small className='text-start'>{_tv.s}</small>
                    </div>)
                    oset++
                }
                _timeSlots.push(<div className='d-flex position-relative'>{_tDiv}</div>)
            }
            // else
            _timeSlots.push(<div
                ref={ts === actieveTimeSlot ? activeTimeRef : undefined}
                onClick={() => _handleTSClick(ts)}
                style={{ cursor: 'pointer', borderBottom: 'solid rgba(53,114,161,.3) 1px', height: _cellHeight, maxHeight: _cellHeight }} className='w-100'>{(!date) ? ts : ''}</div>)
            // _timeSlots.push(<div style={{ height: _cellHeight, maxHeight: _cellHeight }} className='w-100'>{ts}</div>)
        }
        setCalendar(<div style={{ display: 'flex', flexDirection: 'column', width: '100%', textAlign: 'center', height: _cellHeight, maxHeight: _cellHeight }}>
            <div style={{ width: 'calc(100% / 8)', zIndex: 99 }} className='bg-primary-container fw-bold shadow p-2 position-fixed'>{_heading}</div>
            <div style={{ borderBottom: 'solid grey 1px' }} className='mt-5'>{_timeSlots}</div>
        </div>)
    }
    return (
        <>
            <CalendarEventPopup event={currentEvent} />
            {celendar ? celendar : <></>}

        </>

    )
}

import React, { useContext, useEffect } from 'react';
import CalendarSyncContext from '../../CalendarSyncProvider';

export default function GoogleCalendarDetails({ onChange }) {
    const { property } = useContext(CalendarSyncContext)

    useEffect(() => { onChange('Provider', 'Google'); }, [])
    return (
        <>
            <div>
                <b>Name of account</b>
                <input
                    defaultValue={property('Email Address')}
                    onChange={(e) => onChange('Email Address', e.target.value)}
                    className='form-control' placeholder='Name of account eg: Personal Google calendar' />
            </div>
        </>
    )
}

import React, { useContext } from 'react'
import { PlusCircle } from 'react-bootstrap-icons'
import CommonFunctionsContext from '../../../../../common/CommonFunctionsProvider'
import MessagesContainer from './Common/MessagesContainer'
import MesagesContext from './MessagesProvider'

export default function MessagesNewLink() {
    const { createModal } = useContext(CommonFunctionsContext)
    const { linkMessage, messages } = useContext(MesagesContext)
    return (
        <div onClick={() => createModal(<MessagesContainer onLink={linkMessage}
            messages={messages}
        />, 'Message To Link', undefined)}><PlusCircle /> Link Message</div>
    )
}

import { APIFunctions } from '../common/APIFunctions'
import { APIActionInterface } from '../components/interfaces/APIActionInterface'

export abstract class AuthenticationService {
  public static async login(data: APIActionInterface): Promise<any> {
    const result = await APIFunctions.MakeCall(data.url, data.method, data.body!.toString(), data.encryption).catch(
      (err) => {
        throw err
      },
    )

    const token = result.token
    localStorage.setItem('token', token)
    return result
  }

  public static async token(): Promise<any> {
    return localStorage.getItem('token')
  }

  public static async logout(): Promise<any> {
    localStorage.removeItem('token')
  }
}

import React from 'react';
import MessageTemple from '../../../../../../libs/api/services/MessageTemple';
import './RTESuggestions.css';
export default class Suggestions extends React.Component {
    constructor() {
        super()
        this.state = {
            categories: []
        }
    }

    // useEffect(() => {
    //     const handleKeyDown = (e) => {
    //          const key = e.key;
    //          setGrid((state) => {
    //              let { row, col } = result;

    //              // make new array pointers
    //              const grid = [ …state.grid ];
    //              grid[row] = [ …grid[row] ];
    //              grid[row][col] = key;

    //              // i think you would do this before setting the grid??
    //              if (col > grid[0].length) {
    //                  row++;
    //                  col = 0;
    //              } else {
    //                  col++;
    //              }

    //              return {
    //                   …state,
    //                   grid,
    //                   col,
    //                   row
    //               };
    //          });

    //     };
    //     document.addEventListener('keydown', handleKeyDown, true);

    //     return () => {
    //         document.removeEventListener('keydown', handleKeyDown);
    //     };

    // }, [setGrid]);

    componentDidMount = () => {
        MessageTemple
            .ListVariableCategories()
            .then((r) => {
                // this.setState({ categories: r })
                var _orderedItems = []
                var _categories = []
                var _sorted = []
                for (const cat of r) {
                    for (const _field of cat.fields) {
                        //_sorted.push(cat.moduleName + "." + _field.fieldName)
                        //_sorted.push(JSON.stringify(cat) + "." + _field.fieldName)
                        _sorted.push(_field.fieldClass + "." + _field.fieldName)
                    }
                }
                this.setState({ categories: _sorted })
                // for (const cat of r) {
                //     for (const _field of cat.fields) {
                //         if (_field.fieldName.includes(':')) {
                //             var _exists = _categories.find(c => c.subcat === _field.fieldName.split(':')[0])
                //             if (_exists)
                //                 _exists.fields.push({ ..._field, fieldDescription: _field.fieldDescription.split(':')[1] })
                //             else
                //                 _categories.push({
                //                     subcat: _field.fieldName.split(':')[0],
                //                     fields: [{ ..._field, fieldDescription: _field.fieldDescription.split(':')[1] }]
                //                 })
                //         } else {
                //             var _exists = _categories.find(c => c.subcat === '')
                //             if (_exists)
                //                 _exists.fields.push(_field)
                //             else
                //                 _categories.push({
                //                     subcat: '',
                //                     fields: [_field]
                //                 })
                //         }
                //     }

                //     _orderedItems.push({
                //         moduleName: cat.moduleName,
                //         fields: [..._categories]
                //     });
                //     _categories = []
                // }

                // this.listItem = r.map(item => (
                // this.listItem = _orderedItems.map(item => (
                //     <li
                //         key={item.id + 'A' + Math.random()}
                //         className="placeholder-li-category"
                //     >
                //         <div className='bg-primary-container text-center p-1 shadow rouned'>
                //             <div>
                //                 {item.moduleName}
                //             </div>
                //         </div>
                //         {
                //             // TODO : Map categotry to sub section
                //             // item.fields.map((field) => {
                //             item.fields.map((subCat) => {
                //                 return <li>{subCat.subcat === '' ? '' : <div className='bg-secondary-container'>{subCat.subcat}</div>}
                //                     <ul >
                //                         {
                //                             subCat.fields.map((field) => {

                //                                 const select = () => this.props.renderSuggestion(field.fieldClass + '.' + field.fieldName);
                //                                 return (<li
                //                                     key={field.fieldClass + Math.random() * 10}
                //                                     className="placeholder-li text-start"
                //                                     onMouseDown={select}
                //                                 >{field.fieldDescription}</li>)
                //                             }
                //                             )
                //                         }
                //                     </ul>
                //                 </li>
                //                 // subCat.fields.map((field) => {

                //                 //     return (<li
                //                 //         key={field.fieldClass + Math.random() * 10}
                //                 //         className="placeholder-li text-start"
                //                 //         onClick={
                //                 //             this.addPlaceholder.bind(this, field.fieldClass + '.' + field.fieldName)
                //                 //         }
                //                 //     >{field.fieldDescription}</li>)
                //                 // })
                //                 // return (<li
                //                 //     key={field.fieldClass + Math.random() * 10}
                //                 //     className="placeholder-li text-start"
                //                 //     onClick={
                //                 //         this.addPlaceholder.bind(this, field.fieldClass + '.' + field.fieldName)
                //                 //     }
                //                 // >{field.fieldDescription}</li>)
                //             })
                //         }
                //     </li>
                // ))
            })
    }

    _filterResult(results, search) {
        var reslts = []
        const srch_spl = search.split(/[^A-Za-z]/)
        var _found = false
        for (const res of results) {
            for (const srch of srch_spl) {
                if (res.toLowerCase().indexOf(srch.toLowerCase()) > -1) {
                    _found = true
                    break;
                }
            }
            if (_found) {
                reslts.push(res)
                _found = false
                // break;
            }
        }

        if (search)
            if (reslts.length === 0)
                return results
            else
                return reslts
        return []
    }
    render() {
        const { autocompleteState } = this.props;
        if (!autocompleteState || !autocompleteState.searchText) return null;
        const { searchText } = autocompleteState;
        return (
            // <ul className='suggestions-list'>
            //     {this.listItem}
            // </ul>
            <div className='suggestions'>
                <ul className='suggestions-list'>
                    {
                        // [
                        //     "Jaervinen",
                        //     "Nieminen",
                        //     "Lamminpaeae",
                        //     "Kallio",
                        //     "Jokinen",
                        //     "Niittukari",
                        //     "Li"
                        // ]
                        this._filterResult(this.state.categories, searchText)
                            // .filter(item => item.substring(0, searchText.length) === searchText)
                            .map(result => {
                                const select = () => this.props.renderSuggestion(result);
                                return <li onMouseDown={select}>{result}</li>
                            })
                    }
                </ul>
            </div>
        )
    }
}
import React, { useEffect } from 'react'
import { Clipboard2DataFill } from 'react-bootstrap-icons'

export default function QBuilderWelcome({ title, description, navigation, errors, setValue, values }) {

    useEffect(() => {
        title('Questionnaire Builder')
        description('Welcome to the questionnaire bulder')
        // Load Navigation if session started
        // if (Object.keys(values).includes('templateId'))
            navigation([
                { text: 'Begin', type: 'Next', action: async () => {  } },
            ])
    }, [])

    return (
        <div className='h-100 d-flex flex-column align-items-center m-3'>
            <Clipboard2DataFill size={80} />
            <p className='m-3'>The questionnaire builder allows you to create questionnaires and surveys</p>
        </div>
    )
}

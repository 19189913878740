import React, { useEffect, useState } from 'react'
import { Clipboard, People, Person } from 'react-bootstrap-icons'
import Authentication from '../../../../../../../libs/api/services/Authentication'
import MessagingService from '../../../../../../../libs/api/services/MessagingService'
import MessagingServiceProvider from '../../../../../../../libs/api/services/MessagingServiceProvider'
import AudienceGroupComponent from '../../../../admin/components/UserSelection/AudienceGroupComponent'
import UserSelectionComponentNoRef from '../../../../admin/components/UserSelection/UserSelectionComponentNoRef'

export default function FacilityMessagingAudienceGroup({ title, description, navigation, errors, setValue, values, messageId, onError, onChange }) {

    const [audienceType, setAudienceType] = useState()
    const [configuration, setConfiguration] = useState()
    const [loaded, setLoaded] = useState(false)
    const [properties, setProperties] = useState()

    useEffect(() => {
        _save()

    }, [values])
    const _save = async () => {
        if (Object.keys(values).includes('serviceProviderService'))
            await MessagingServiceProvider
                .UpdateService(values.serviceProvider.id, values.serviceProviderService).catch((e) => errors(e))
    }

    const loadState = () => {
        try {
            if (values.serviceProviderService)
                setConfiguration({ ...values.serviceProviderService })
            if (Object.keys(values.serviceProviderService).includes("audenceType"))
                setAudienceType(values.serviceProviderService.audenceType)

        } catch (e) {

        }
    }

    useEffect(() => {
        title('Audience')
        description('Select the type of person/group type that will be sending messages using this service')
        navigation([])
        // _loadSettings()
    }, [])


    const _handleSetAudienceType = (type) => {
        if (type === 'Facility')
            setValue({ serviceProviderService: { ...values.serviceProviderService, audenceType: type, audienceFacility: { ...Authentication.getFacilityDetails() } } })
        else
            setValue({ serviceProviderService: { ...values.serviceProviderService, audenceType: type } })

        setAudienceType(type)
        setLoaded(true)
    }
    const loadAudienceType = () => {
        MessagingService
            .GetAudienceType(values.configurationId)
            .then((r) => {
                setAudienceType(r.type)
            }).catch((e) => { if (e.message !== 'Malformed UTF-8 data') errors(e.message) })

        setLoaded(true)
    }
    const loadAudiences = () => {
        setLoaded(false)
        MessagingService
            .GetConfiguration(values.configurationId)
            .then((r) => {
                setConfiguration(r)
                setLoaded(true)
            }).catch((e) => errors(e.message))
    }
    useEffect(() => {
        loadState()
        loadAudiences()
        loadAudienceType()
    }, [])

    const _handleSelectUser = (userId) => {
        setValue({ serviceProviderService: { ...values.serviceProviderService, audienceUser: userId } })
        setProperties({ ...properties, audienceUser: userId })
        setConfiguration({ ...configuration, audienceUser: userId })
    }
    // Facility the active logged in facility
    // const _handleSelecFacility = (facilityId) => {
    //     setValue({serviceProviderService: {...values.serviceProviderService, audienceFacility :facilityId }})
    //     setProperties({ ...properties, audienceFacility: facilityId })
    // }
    const _handleSelectAudience = (audienceGroupId) => {
        setValue({ serviceProviderService: { ...values.serviceProviderService, audienceGroups: audienceGroupId } })
        setProperties({ ...properties, audienceGroups: audienceGroupId })
    }
    return (
        <>
            <div
                style={{ cursor: 'pointer' }}
                className='d-flex flex-row justify-content-evenly align-items-evenly'>
                <div
                    onClick={(e) => _handleSetAudienceType('SingleUser')}
                    className={'w-100 m-3 p-2 rounded '.concat(audienceType === 'SingleUser' ? 'bg-primary-container shadow ' : 'bg-secondary-container')}>
                    <div>
                        <Person size={52} />
                    </div>
                    <div>
                        <h3>Single User</h3>
                    </div>
                    <div>
                        <p>Messages can be sent by a specified user on the system</p>
                    </div>
                </div>
                <div
                    onClick={(e) => _handleSetAudienceType('Facility')}
                    className={'w-100 m-3 p-2 rounded '.concat(audienceType === 'Facility' ? 'bg-primary-container shadow ' : 'bg-secondary-container')}
                >
                    <div>
                        <People size={52} />
                    </div>
                    <div>
                        <h3>Facility</h3>
                    </div>
                    <div>
                        <p>Messages can be sent by all Administrators / Clinicians linked to a facility</p>
                    </div>
                </div>
                <div
                    onClick={(e) => _handleSetAudienceType('AudienceGroup')}
                    className={'w-100 m-3 p-2 rounded '.concat(audienceType === 'AudienceGroup' ? 'bg-primary-container shadow ' : 'bg-secondary-container')}
                >
                    <div>
                        <Clipboard size={52} />
                    </div>
                    <div>
                        <h3>Audience Group</h3>
                    </div>
                    <div>
                        <p>Messages can be sent by pre selected list of users on the system</p>
                    </div>
                </div>
            </div>
            <div className='m-3'>
                {
                    !loaded
                        ?
                        <div>Loading</div>
                        :
                        audienceType === 'SingleUser'
                            ?
                            <div>
                                <b>Select the user that will be sending messages with this configuration</b>
                                <UserSelectionComponentNoRef
                                    facilityId={Authentication.getFacility()}
                                    clearOnSelect={false}
                                    defaultValue={configuration && Object.keys(configuration).includes('audienceUser') ? configuration.audienceUser.firstName + ' ' + configuration.audienceUser.lastName : ''}
                                    key={new Date()}
                                    onUserSelectedDetails={(u) => _handleSelectUser(u)}
                                />
                            </div>
                            :
                            audienceType === 'AudienceGroup'
                                ?
                                <div>
                                    <AudienceGroupComponent
                                        defaultValue={configuration && Object.keys(configuration).includes('audienceGroups') ? configuration.audienceGroups : ''}
                                        onAudienceSelectedObj={(u) => _handleSelectAudience(u)}
                                        errors={(e) => errors(e)} />
                                </div>
                                :
                                // audienceType === 'Facility'
                                //     ?
                                //     <div>
                                //         <b>Select the facility that will be sending messages with this configuration</b>
                                //         <FacilitySelectionComponent
                                //             defaultValue={configuration && Object.keys(configuration).includes('audienceFacility') ? configuration.audienceFacility.facility : ''}
                                //             onFacilitySelected={(i) => _handleSelecFacility(i)} />
                                //     </div>
                                //     :
                                <></>
                }
            </div>
        </>
    )
}

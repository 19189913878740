import API from "../API";

var MessagingService = {
    /////////////////////////
    async GetConfiguration(ConfigurationId) {
        var result = await API.process(
            `api/MessagingService/Configuration/${ConfigurationId}`,
            'GET',
            null
        ).catch((err) => {
            throw err;
        })

        return result
    }, async SetConfiguration(conf) {
        var result = await API.process(
            'api/MessagingService/Configuration',
            'PUT',
            conf
        ).catch((err) => {
            throw err;
        })

        return result
    },
    async ListMessageConfigurationByServiceType(type) {
        var result = await API.process(
            `api/MessagingService/Configurations/${type}`,
            'GET',
            null
        ).catch((err) => {
            throw err;
        })

        return result
    },
    async RemoveMessageConfiguration(id) {
        var result = await API.process(
            `api/MessagingService/Configuration/${id}`,
            'DELETE',
            null
        ).catch((err) => {
            throw err;
        })

        return result
    },
    async SetMessagingServiceProperty(ConfigurationId, PropertyType, value) {
        if (value !== "") {
            var result = await API.process(
                `api/MessagingService/Configuration/Property/${ConfigurationId}/${PropertyType}/${value}`,
                'PUT',
                null
            ).catch((err) => {
                throw err;
            })

            return result
        }
    },
    async GetMessagingServiceProperty(ConfigurationId, PropertyType) {
        var result = await API.process(
            `api/MessagingService/Configuration/Property/${ConfigurationId}/${PropertyType}`,
            'GET',
            null
        ).catch((err) => {
            throw err;
        })

        return result
    },
    async GetMessagingServiceProperties(ConfigurationId) {
        var result = await API.process(
            `api/MessagingService/Configuration/Properties/${ConfigurationId}`,
            'GET',
            null
        ).catch((err) => {
            throw err;
        })

        return result
    },
    async SetAudienceType(ConfigurationId, AudienceType) {
        var result = await API.process(
            `api/MessagingService/Configuration/Audience/${ConfigurationId}/${AudienceType}`,
            'PUT',
            null
        ).catch((err) => {
            throw err;
        })

        return result
    },
    async GetAudienceType(ConfigurationId) {
        var result = await API.process(
            `api/MessagingService/Configuration/Audience/${ConfigurationId}/AudienceType`,
            'GET',
            null
        ).catch((err) => {
            throw err;
        })

        return result
    },
    async SetConfigurationAudienceUser(ConfigurationId, UserId) {
        var result = await API.process(
            `api/MessagingService/Configuration/Audience/${ConfigurationId}/User/${UserId}`,
            'PATCH',
            null
        ).catch((err) => {
            throw err;
        })

        return result
    },
    async SetConfigurationAudienceFacility(ConfigurationId, FacilityId) {
        var result = await API.process(
            `api/MessagingService/Configuration/Audience/${ConfigurationId}/Facility/${FacilityId}`,
            'PATCH',
            null
        ).catch((err) => {
            throw err;
        })

        return result
    },
    async SetConfigurationAudienceGroup(ConfigurationId, audienceGroupId) {
        var result = await API.process(
            `api/MessagingService/Configuration/Audience/${ConfigurationId}/Group/${audienceGroupId}`,
            'PATCH',
            null
        ).catch((err) => {
            throw err;
        })

        return result
    },

    ////////////////
    async SetAudienceGroup(group) {
        var result = await API.process(
            `api/MessagingService/Configuration/Audience/Group`,
            'PUT',
            group
        ).catch((err) => {
            throw err;
        })

        return result
    },
    async RemoveAudienceGroup(id) {
        var result = await API.process(
            `api/MessagingService/Configuration/Audience/Group/${id}`,
            'DELETE',
            null
        ).catch((err) => {
            throw err;
        })

        return result
    },
    async LoadAudienceGroupsAudienceGroups() {
        var result = await API.process(
            `api/MessagingService/Configuration/Audience/Groups`,
            'GET',
            null
        ).catch((err) => {
            throw err;
        })

        return result
    },
    //// Connection Tests

    async DiscoverEmailAccount(ConfigurationId, step) {
        var result = await API.process(
            `api/MessagingService/Configuration/${ConfigurationId}/Email/Discover/${step}`,
            'OPTIONS',
            null
        ).catch((err) => {
            throw err;
        })

        return result
    },
    async DiscoverServiceProvider(ServiceProviderServiceId, step) {
        var result = await API.process(
            `api/MessagingService/Configuration/${ServiceProviderServiceId}/SP/Discover/${step}`,
            'OPTIONS',
            null
        ).catch((err) => {
            throw err;
        })

        return result
    },
    async LookupUserLinkedEmailAccount(UserId) {
        var result = await API.process(
            `api/MessagingService/user/${UserId}/Email/Account`,
            'OPTIONS',
            null
        ).catch((err) => {
            throw err;
        })

        return result
    },
    async OAuthTokent(token, type) {
        var result = {}
        switch (type) {
            case 'Mail':
                result = await API.process(
                    `api/MessagingService/OAuth`,
                    'POST',
                    token
                ).catch((err) => {
                    throw err;
                })
                break;
            case 'Calendar':
                result = await API.process(
                    `api/CalendarService/OAuth`,
                    'POST',
                    token
                ).catch((err) => {
                    throw err;
                })
                break;
            default:
                throw Error('Unsupported Service Type')
        }

        return result
    },
    async ListAvailableRecipients(UserId, FacilityId, Role, ServiceType) {
        var result = await API.process(
            `api/MessagingService/AvailableAudience/User/${UserId}/Facility/${FacilityId}/Role/${Role}/Type/${ServiceType}`,
            'GET',
            null
        ).catch((err) => {
            throw err;
        })

        return result
    },
    async SendMessage(Message) {
        var result = await API.process(
            `api/MessagingService/SendMessage`,
            'PUT',
            Message
        ).catch((err) => {
            throw err;
        })

        return result
    },
}

export default MessagingService;
import React, { useEffect, useState } from 'react';
import ServiveProviderJSON from './ServiveProviderJSON';

export default function IntegrationAuthenticationRecvMessageBody({ defaultValue, values, onChange }) {
    const [authDetails, setAuthDetails] = useState(defaultValue ? defaultValue : {
        requestBody: {},
        requestResponse: {},

    })

    useEffect(() => { setAuthDetails(defaultValue) }, [defaultValue])
    const _getEndPoint = () => {
        return `https://${window.location.hostname}${window.location.port ? ':' + window.location.port : ''}/api/Messaging/ServiceProvider/${values.serviceProviderService.id}/Message`;
    }

    useEffect(() => {
        if (onChange && authDetails && (
            authDetails.epVerb ||
            Object.keys(authDetails.requestBody).length > 0 ||
            Object.keys(authDetails.requestResponse).length > 0
        )
        )
            onChange(authDetails)
    }, [authDetails])
    return (
        <div className='text-start'>
            <div className='d-flex p-2 bg-secondary-container rounded shadow'>
                <select
                    onChange={(e) => setAuthDetails({ ...authDetails, epVerb: e.target.value })}
                    defaultValue={defaultValue && defaultValue.epVerb ? defaultValue.epVerb : undefined}
                    className='form-control form-control-sm flex-shrink-1 w-25'>
                    <option>HTTP Verb</option>
                    <option selected={defaultValue && defaultValue.epVerb && defaultValue.epVerb === 'POST' ? 'selected' : ''}>POST</option>
                    <option selected={defaultValue && defaultValue.epVerb && defaultValue.epVerb === 'GET' ? 'selected' : ''}>GET</option>
                    <option selected={defaultValue && defaultValue.epVerb && defaultValue.epVerb === 'PUT' ? 'selected' : ''}>PUT</option>
                    <option selected={defaultValue && defaultValue.epVerb && defaultValue.epVerb === 'PATCH' ? 'selected' : ''}>PATCH</option>
                    <option selected={defaultValue && defaultValue.epVerb && defaultValue.epVerb === 'DELETE' ? 'selected' : ''}>DELETE</option>
                    <option selected={defaultValue && defaultValue.epVerb && defaultValue.epVerb === 'OPTIONS' ? 'selected' : ''}>OPTIONS</option>
                    <option selected={defaultValue && defaultValue.epVerb && defaultValue.epVerb === 'TRACE' ? 'selected' : ''}>TRACE</option>
                </select>
                <input
                    value={_getEndPoint()}
                    disabled={'disabled'}
                    // onChange={(e) => setAuthDetails({ ...authDetails, endPoint: e.target.value })}
                    // defaultValue={defaultValue && defaultValue.endPoint ? defaultValue.endPoint : undefined}
                    className='form-control form-control-sm flex-grow-1' placeholder='Full URL and endpoint' />
            </div>
            <div className='d-flex'>
                <div className='d-block w-50'>
                    <div><b>JSON Request</b></div>
                    <ServiveProviderJSON defaultValue={defaultValue && defaultValue.requestBody ? defaultValue.requestBody : undefined} onChange={(r) => setAuthDetails({ ...authDetails, requestBody: r })} />
                </div>
                <div className='d-block w-50'>
                    <div><b>JSON Response</b></div>
                    <ServiveProviderJSON defaultValue={defaultValue && defaultValue.requestResponse ? defaultValue.requestResponse : undefined} onChange={(r) => setAuthDetails({ ...authDetails, requestResponse: r })} />
                </div>
            </div>

        </div>
    )
}

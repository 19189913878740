import React, { useCallback, useContext, useEffect, useState } from 'react'
import JSONPath from 'jsonpath'
import { LayoutInterface } from '../interfaces/LayoutInterface'
import { ActionInterface } from '../interfaces/ActionInterface'
import Component from '../Component'
import VariablesContext from '../context/VariablesContext'

type SectionIdentifier = {
  section: string
  container: string
  // layout: string
  index?: number
  item?: string
  value?: any
}

/*
$	The root object/element
@	The current object/element
.	Child member operator
..	Recursive descendant operator; JSONPath borrows this syntax from E4X
*	Wildcard matching all objects/elements regardless their names
[]	Subscript operator
[,]	Union operator for alternate names or array indices as a set
[start:end:step]	Array slice operator borrowed from ES4 / Python
?()	Applies a filter (script) expression via static evaluation
()	Script expression via static evaluation
*/
type Props = {
  name: string
  section: string
  layout: LayoutInterface
  onChange: (n: string, v: any) => void
  onClick: (n: string) => void
  onLoadData: (n: string) => Promise<any>
  variables: Map<string, any>
  actionRunner: (s: string, n: string, v?: string, a?: ActionInterface[]) => Promise<any>
}

const _stringToSectionId = (s: string, n: string, v?: any) => {
  const identifier: SectionIdentifier = {
    section: '',
    container: '',
  }

  const [_g, _gi] = n.split('.')
  const [_gin, idxarr] = _gi.split('[')
  const idx: number = parseInt(idxarr.substring(0, idxarr.length - 1))

  identifier.section = s
  identifier.container = _g
  // identifier.layout = _g
  identifier.index = idx
  identifier.item = _gin
  identifier.value = v

  return identifier
}

export default function SourceGroup(props: Props) {
  const [group, setGroup] = useState<string>()
  const [updateState, setUpdateState] = useState<string>('init')
  const [scopeVariables, setScopeVariables] = useState<Map<string, any>>(new Map<string, any>())
  const { variables } = useContext(VariablesContext)

  const _setScopeVariable = (indentifier: SectionIdentifier) => {
    if (!scopeVariables.has(indentifier.section)) scopeVariables.set(indentifier.section, new Map<string, any>())
    if (!scopeVariables.get(indentifier.section).has(indentifier.container))
      scopeVariables.get(indentifier.section).set(indentifier.container, new Map<number, any>())
    if (!scopeVariables.get(indentifier.section).get(indentifier.container).has(indentifier.index))
      scopeVariables.get(indentifier.section).get(indentifier.container).set(indentifier.index, new Map<string, any>())
    if (
      !scopeVariables.get(indentifier.section).get(indentifier.container).get(indentifier.index).has(indentifier.item)
    )
      scopeVariables
        .get(indentifier.section)
        .get(indentifier.container)
        .get(indentifier.index)
        .set(indentifier.item, new Map<string, any>())

    scopeVariables
      .get(indentifier.section)
      .get(indentifier.container)
      .get(indentifier.index)
      .set(indentifier.item, indentifier.value)
    //setScopeVariables(scopeVariables)
    return scopeVariables
  }

  // @ts-ignore
  const _setAndBuildScopeVariable = (indentifier: SectionIdentifier) => {
    setScopeVariables(_setScopeVariable(indentifier))
  }

  const _handleOnChange = (s: string, n: string, v: any) => {
    const _identity = _stringToSectionId(s, n, v)
    _setScopeVariable(_identity)
    //console.log(_identity)
    // Splt name
    // const [_g, _gi] = n.split('.')
    // const [_gin, idxarr] = _gi.split('[')
    // const idx: number = parseInt(idxarr.substring(0, idxarr.length - 1))
    // Read group as returned data
    /*
    const data: any = JSON.parse(group!)
    // Using the reference paramater, use JSONPath to extract loop array
    const referenceArray = JSONPath.query(data, props.layout.source![0].reference!)
    // Vraiblaes
    // { section.name { section: [ { layout.name = value  } ]}  }

    // Loop main loop
    if (props.layout.layout![0].actions) {
      const actions: any = JSON.parse(JSON.stringify(props.layout.layout![0].actions))
      // Parse idx of x
      const ref = referenceArray[0][idx]
      // Loop and replace action
      const _actions = SetJSONPathVars(ref, actions, v)
      // Handle with actionRunner
      props.actionRunner(s, n, v, _actions)
    }*/
    props.onChange(_identity.container, v)
  }

  const _findActionByName = (s: string, n: string, l: LayoutInterface): ActionInterface[] | undefined => {
    let action: ActionInterface[] | undefined
    if (Array.isArray(l.layout)) {
      for (const lo of l.layout) {
        action = _findActionByName(s, n, lo)
      }
    } else if (typeof l.layout === 'object') {
      for (const lo of Object.keys(l.layout)) {
        action = _findActionByName(s, n, l.layout[lo])
      }
    }
    if (l.name === n) {
      action = l.actions
    }
    return action
  }

  // const _handleVariablesArray = (_a: any) => {
  //   //
  // }

  // a = actions in string
  // _dict = variables to search through
  const _handleVariableFormatType = (
    _a: any,
    _dict:
      | Map<string, any>
      | Map<string, Map<string, any>>
      | Map<string, Map<string, any>>
      | Map<string, Map<number, Map<string, any>>>,
    return_value: any,
    _idx?: number,
  ) => {
    let s = false
    let n = false
    let l = false
    let p = false
    let a = false
    let section = ''
    let name = ''
    let path = ''
    let layout = ''
    let array = ''
    // const variables: any = []
    for (let i = 0; i < _a.length; i++) {
      const c = i === _a.length - 1 ? _a.substring(i) : _a.substring(i, i + 1)
      if (s && c != '$' && c != '}' && c != '.' && c != '[') section += c
      if (n && c != '$' && c != '}' && c != '.' && c != '[') name += c
      if (p && c != '}') path += c
      if (l && c != '$' && c != '}' && c != '.' && c != '[') layout += c
      if (a && c != ']') array += c
      switch (c) {
        case '{':
          s = true
          break
        case '$':
          path += '$'
          s = false
          n = false
          l = false
          p = true
          break
        case '.':
          if (!p) {
            if (s) {
              s = false
              n = true
            } else if (n) {
              s = false
              n = false
              l = true
            }
          }
          break
        case '[':
          {
            if (_a.substring(i + 1, i + 2) === ']') {
              array = '-1'
              i++
            } else {
              a = true
              s = false
              n = false
              l = false
            }
          }
          break
        case ']':
          a = false
          break
        case '}':
          s = false
          n = false
          l = false
          p = false
          // section = section.substring(0, section.length - 1)
          // path = path.substring(0, path.length - 1)
          // array = array.substring(0, array.length - 1)
          // if (layout.length > 0) layout = layout.substring(0, layout.length - 1)
          try {
            if (section.length > 0 && name.length > 0 && layout.length > 0 && array.length > 0 && path.length > 0) {
              // Format 6 : {section.name.layout[]$path}
              if (
                _dict.has(section) &&
                _dict.get(section).has(name) &&
                _dict
                  .get(section)
                  .get(name)
                  .has(array === '-1' ? _idx : parseInt(array))
              )
                _a = _a.replaceAll(
                  '{' +
                    section +
                    '.' +
                    name +
                    '.' +
                    layout +
                    '[' +
                    (array === '-1' ? '' : array === '-1' ? '' : parseInt(array)) +
                    ']' +
                    path +
                    '}',
                  JSONPath.query(
                    _dict
                      .get(section)
                      .get(name)
                      .get(array === '-1' ? _idx : parseInt(array))
                      .get(layout),
                    path,
                  ),
                )
            } else if (section.length > 0 && name.length > 0 && layout.length > 0 && array.length > 0) {
              // Format 5 : {section.name.layout[]}
              if (
                _dict.has(section) &&
                _dict.get(section).has(name) &&
                _dict
                  .get(section)
                  .get(name)
                  .has(array === '-1' ? _idx : parseInt(array)) &&
                _dict
                  .get(section)
                  .get(name)
                  .get(array === '-1' ? _idx : parseInt(array))
                  .has(layout)
              )
                _a = _a.replaceAll(
                  '{' + section + '.' + name + '.' + layout + '[' + (array === '-1' ? '' : parseInt(array)) + ']}',
                  _dict
                    .get(section)
                    .get(name)
                    .get(array === '-1' ? _idx : parseInt(array))
                    .get(layout),
                )
              //_a = _a.replaceAll('{' + section + '.' + name + '.' + layout + '[' + array + ']}', 'FORMAT 5')
            } else if (section.length > 0 && name.length > 0 && layout.length > 0 && path.length > 0) {
              // Format 4 : {section.name.layout$path}
              if (_dict.has(section) && _dict.get(section).has(name) && _dict.get(section).get(name).has(layout))
                _a = _a.replaceAll(
                  '{' + section + '.' + name + '.' + layout + path + '}',
                  JSONPath.query(_dict.get(section).get(name).get(layout), path),
                )
              //_a = _a.replaceAll('{' + section + '.' + name + '.' + layout + path + '}', 'FORMAT 4')
            } else if (section.length > 0 && name.length > 0 && layout.length > 0) {
              // Format 3 : {section.name.layout}
              if (_dict.has(section) && _dict.get(section).has(name) && _dict.get(section).get(name).has(layout))
                _a = _a.replaceAll(
                  '{' + section + '.' + name + '.' + layout + '}',
                  _dict.get(section).get(name).get(layout),
                )
              // _a = _a.replaceAll('{' + section + '.' + name + '.' + layout + '}', 'FORMAT 3')
            } else if (section.length > 0 && name.length > 0 && array.length > 0 && path.length > 0) {
              // Format 2 : {section.name$path}

              if (
                _dict.has(section) &&
                _dict.get(section).has(name) &&
                _dict
                  .get(section)
                  .get(name)
                  .has(array === '-1' ? _idx : parseInt(array))
              )
                _a = _a.replaceAll(
                  '{' + section + '.' + name + '[' + (array === '-1' ? '' : parseInt(array)) + ']' + path + '}',
                  JSONPath.query(
                    _dict
                      .get(section)
                      .get(name)
                      .get(array === '-1' ? _idx : parseInt(array))
                      .get(),
                    path,
                  ),
                )
              // _a = _a.replaceAll('{' + section + '.' + name + '' + path + '}', 'FORMAT 2')
            } else if (section.length > 0 && name.length > 0 && path.length > 0) {
              // Format 2 : {section.name$path}
              if (_dict.has(section) && _dict.get(section).has(name))
                _a = _a.replaceAll(
                  '{' + section + '.' + name + '.' + path + '}',
                  JSONPath.query(_dict.get(section).get(name), path),
                )
              // _a = _a.replaceAll('{' + section + '.' + name + '' + path + '}', 'FORMAT 2')
            } else if (section.length > 0 && name.length > 0) {
              // Format 1 : {section.name}
              if (_dict.has(section) && _dict.get(section).has(name))
                _a = _a.replaceAll('{' + section + '.' + name + '.' + '}', _dict.get(section).get(name))
              // _a = _a.replaceAll('{' + section + '.' + name + '}', 'FORMAT 1')
            } else if (path.length > 0) {
              _a = _a.replaceAll('{' + path + '}', JSONPath.query(return_value, path))
              // Format 1 : {section.name}
              // if (_dict.has(section) && _dict.get(section).has(name))
              // _a = _a.replaceAll('{' + section + '.' + name + '.' + '}', _dict.get(section).get(name))
              // _a = _a.replaceAll('{' + section + '.' + name + '}', 'FORMAT 1')
            }
          } catch (e) {
            console.error(e)
          }
          // Format 3 : {section.container.layout[ idx ]$path}
          // _handleVariablesArray({
          //   segment: '{' + section + '.' + name + '' + path + '}',
          //   section: section,
          //   name: name,
          //   layout: layout,
          //   array: array,
          //   path: path,
          // })
          break
        default:
          break
      }
    }
    return _a
  }

  // const _handleScopeVariable = (_a: any) => {
  //   return _a
  // }

  const _loopActions = (_a: any, idx: number, return_value: any) => {
    if (!_a) return _a
    if (_a.layout && Array.isArray(_a.layout)) {
      for (const lo of _a.layout) {
        _a[lo] = _loopActions(_a[lo], idx, return_value)
      }
    } else if (typeof _a === 'object') {
      for (const lo of Object.keys(_a)) {
        _a[lo] = _loopActions(_a[lo], idx, return_value)
      }
    } else {
      if (_a.includes('{') && _a.includes('}') && _a.includes('.'))
        return _handleVariableFormatType(_a, scopeVariables, return_value, idx)
      return _a
    }
    return _a
  }

  const _handleOnClick = (s: string, n: string) => {
    const _eventIdentity = _stringToSectionId(s, n)
    console.log(_eventIdentity)
    console.log(group)
    //JSON.parse(group)[(_eventIdentity.index -1)]
    // Find All Variables
    try {
      // Copy template then filter variables
      const _layout = JSON.parse(JSON.stringify(props.layout.layout![0]))
      let _a = _findActionByName(_eventIdentity.section, _eventIdentity.item!, _layout)
      // Look for variblaes in scope variables
      try {
        _a = _loopActions(_a, _eventIdentity.index!, JSON.parse(group!)[_eventIdentity.index! - 1])
      } catch (e) {
        _a = _loopActions(_a, _eventIdentity.index!, null)
      }
      props.actionRunner(_eventIdentity.section, _eventIdentity.item!, '', _a)
      // Look for variables in source group variables
      console.log(_a)
    } finally {
      props.onClick(_eventIdentity.container)
    }

    // Splt name
    // const [_g, _gi] = n.split('.')
    // const [_gin, idxarr] = _gi.split('[')
    // const idx: number = parseInt(idxarr.substring(0, idxarr.length - 1))
    // Read group as returned data
    // const data: any = JSON.parse(group!)
    // Using the reference paramater, use JSONPath to extract loop array
    // const referenceArray = JSONPath.query(data, props.layout.source![0].reference!)
    // Loop main loop
    // if (props.layout.layout![0].name === s || props.layout.name === _g) {
    //   try {
    //     const _a = _findActionByName(s, _gin, props.layout.layout![0])
    //     const actions: any = JSON.parse(JSON.stringify(_a))
    //     // Parse idx of x
    //     const ref = referenceArray[0][idx]
    //     // Loop and replace action
    //     let _actions = SetJSONPathVars(ref, actions, null)
    //     _actions = SetJSONPathVars(scopeVariables, actions, null)
    //     // Handle with actionRunner
    //     props.actionRunner(s, n, '', _actions)
    //   } catch (e) {
    //     console.error('Invalid Action', e)
    //   }
    // }
    // if (props.layout.layout![0].actions) {
    //   const actions: any = JSON.parse(JSON.stringify(props.layout.layout![0].actions))
    //   // Parse idx of x
    //   const ref = referenceArray[0][idx]
    //   // Loop and replace action
    //   const _actions = SetJSONPathVars(ref, actions, null)
    //   // Handle with actionRunner
    //   props.actionRunner(s, n, '', _actions)
    // }
  }

  /*
      JSON Path replace
   */

  // @ts-ignore
  // const LoopReplaceJSONPathJSON = (json: { [key: string]: any }, ref: any, return_value: any): object => {
  //   if (!json) return {}
  //   // try {
  //   //   for (const j of Object.keys(json)) {
  //   //     // if value is key LoopReplaceJSONPathJSON
  //   //     if (typeof json[j] === 'object') json[j] = LoopReplaceJSONPathJSON(json[j], ref, return_value)
  //   //     // if value is array loop LoopReplaceJSONPathJSON
  //   //     else if (Array.isArray(json[j]))
  //   //       for (let i = 0; i < json[j].length; i++) json[j][i] = LoopReplaceJSONPathJSON(json[j][i], ref, return_value)
  //   //     // else if value starts with {$ json[k] = JSONPath.query(ref, json[k]
  //   //     // else if (json[j].substring(0, 2) === '{$')
  //   //     //   json[j] = JSONPath.query(ref, json[j].substring(1, json[j].length - 1))[0]
  //   //     else {
  //   //       let name = props.name
  //   //       if (name.includes('[')) {
  //   //         name = props.name.substring(props.name.indexOf('.') + 1, props.name.indexOf('['))
  //   //       }
  //   //       if (json[j].includes('{' + props.section + '.' + name)) {
  //   //         while (json[j].includes('{' + props.section + '.' + name)) {
  //   //           //
  //   //           const s = json[j].indexOf('{' + props.section + '.' + name)
  //   //           let v = json[j].substring(
  //   //             json[j].indexOf('{' + props.section + '.' + name) + name.length + props.section.length + 2,
  //   //           )
  //   //           v = v.substring(0, v.indexOf('}'))
  //   //           json[j] = json[j].replaceAll(
  //   //             json[j].substring(s, s + 1 + props.section.length + 1 + name.length + v.length + 1),
  //   //             JSONPath.query(ref, v)[0],
  //   //           )
  //   //         }
  //   //       } else if (json[j].includes('{$')) {
  //   //         while (json[j].includes('{$')) {
  //   //           //
  //   //           let v = json[j].substring(json[j].indexOf('{$'))
  //   //           v = v.substring(0, v.indexOf('}') + 1)
  //   //           json[j] = json[j].replaceAll(v, JSONPath.query(ref, v.substring(1, v.length - 1))[0])
  //   //         }
  //   //       }
  //   //       if (json[j].includes('<<VALUE>>')) {
  //   //         json[j] = json[j].replaceAll('<<VALUE>>', return_value)
  //   //       }
  //   //     }
  //   //   }
  //   // } finally {
  //   //   console.error('Invalid Template Format')
  //   // }
  //   return json
  // }
  const LoopReplaceJSONPathJSON = (json: { [key: string]: any }, ref: any, return_value: any, idx?: number): object => {
    if (!json) return {}
    try {
      for (const j of Object.keys(json)) {
        // if value is key LoopReplaceJSONPathJSON
        if (typeof json[j] === 'object') json[j] = LoopReplaceJSONPathJSON(json[j], ref, return_value, idx)
        // if value is array loop LoopReplaceJSONPathJSON
        else if (Array.isArray(json[j]))
          for (let i = 0; i < json[j].length; i++)
            json[j][i] = LoopReplaceJSONPathJSON(json[j][i], ref, return_value, idx)
        // else if value starts with {$ json[k] = JSONPath.query(ref, json[k]
        // else if (json[j].substring(0, 2) === '{$')
        //   json[j] = JSONPath.query(ref, json[j].substring(1, json[j].length - 1))[0]
        else {
          json[j] = _handleVariableFormatType(json[j], ref, return_value, idx)
          // let name = props.name
          // if (name.includes('[')) {
          //   name = props.name.substring(props.name.indexOf('.') + 1, props.name.indexOf('['))
          // }
          // if (json[j].includes('{' + props.section + '.' + name)) {
          //   while (json[j].includes('{' + props.section + '.' + name)) {
          //     //
          //     const s = json[j].indexOf('{' + props.section + '.' + name)
          //     let v = json[j].substring(
          //       json[j].indexOf('{' + props.section + '.' + name) + name.length + props.section.length + 2,
          //     )
          //     v = v.substring(0, v.indexOf('}'))
          //     json[j] = json[j].replaceAll(
          //       json[j].substring(s, s + 1 + props.section.length + 1 + name.length + v.length + 1),
          //       JSONPath.query(ref, v)[0],
          //     )
          //   }
          // } else if (json[j].includes('{$')) {
          //   while (json[j].includes('{$')) {
          //     //
          //     let v = json[j].substring(json[j].indexOf('{$'))
          //     v = v.substring(0, v.indexOf('}') + 1)
          //     json[j] = json[j].replaceAll(v, JSONPath.query(ref, v.substring(1, v.length - 1))[0])
          //   }
          // }
          // if (json[j].includes('<<VALUE>>')) {
          //   json[j] = json[j].replaceAll('<<VALUE>>', return_value)
          // }
        }
      }
    } catch (e) {
      console.error('Invalid Template Format', e)
    }
    return json
  }
  const SetJSONPathVars = (vars: any, body: object, return_value: any, idx?: number): any =>
    JSON.parse(JSON.stringify(LoopReplaceJSONPathJSON(JSON.parse(JSON.stringify(body)), vars, return_value, idx)))

  const load = useCallback(() => {
    let state = ''
    //  || updateState !== 'init'
    if (Object.keys(props.layout).includes('dependencies'))
      for (const dependency of props.layout.dependencies!) {
        const [_s, _n]: string[] = dependency.component.split('.')
        if (variables!.has(_s) && variables!.get(_s).has(_n)) {
          const _state = Object.fromEntries(variables!.get(_s).get(_n))
          state = JSON.stringify(_state)
        }
      }
    try {
      if (state !== updateState) {
        const readData = async () => {
          // Use the actions to pull the data
          //props.layout.dependencies[0].component
          try {
            //setGroup(JSON.stringify(await props.onLoadData(props.name)))
            setGroup(JSON.stringify(await props.actionRunner(props.section, props.name, '', props.layout.source)))
          } catch (e) {
            console.error(e)
          }
        }
        readData()
        setUpdateState(state)
      }
    } catch (e) {
      console.error('Unable to load component data : ', e)
    }
  }, [props, updateState, variables])

  useEffect(() => {
    load()
  }, [load, variables])

  const _appendSectionName = (n: string, i: number, l: LayoutInterface) => {
    //
    if (l.layout) for (let lc of l.layout) lc = _appendSectionName(n, i, lc)
    // const name = n + '.' + l.name + '[' + i + ']'
    l.name = n + '.' + l.name + '[' + i + ']'
    return l
    // return {
    //   ...l,
    //   props: {
    //     ...l.props,
    //     name: name,
    //   },
    // }
  }

  const render = (res: any) => {
    if (!res || res === 'null') return <></>
    try {
      // 1. Get Component from API
      const data: any = JSON.parse(res)
      // Using the reference paramater, use JSONPath to extract loop array
      const referenceArray = JSONPath.query(data, props.layout.source![0].reference!)

      // Check if template layouot defined
      if (props.layout.layout) {
        // Get array result
        let layoutTemplate: any = JSON.parse(JSON.stringify(props.layout.layout[0]))
        // Jpath seems to return an array of results
        // Loop through results
        const _groups: any[] = []
        let i = 0
        // 2. Loop through results
        for (const ref of referenceArray[0]) {
          // 3. Set Variables
          const _map = new Map<string, any>()
          _map.set(props.section, new Map<string, any>())
          _map.get(props.section).set(props.name, new Map<string, number>())
          _map.get(props.section).get(props.name).set(i, new Map<string, any>())
          _map.get(props.section).get(props.name).get(i).set(layoutTemplate.name, ref)
          console.log(props.section, '>', props.name, '>', layoutTemplate.name, '[', i, ']')
          // const _item = {
          //   section: props.section,
          //   container: props.name,
          //   item: layoutTemplate.name,
          //   index: i,
          //   value: ref,
          // }
          // Get object and loop
          layoutTemplate = JSON.parse(JSON.stringify(props.layout.layout[0]))
          // const _a = _loopActions(layoutTemplate)
          // console.log(_a)
          //
          _setScopeVariable({
            section: props.section,
            container: props.name,
            item: layoutTemplate.name,
            index: i,
            value: ref,
          })

          const layout = SetJSONPathVars(_map, layoutTemplate, ref, i) as LayoutInterface
          // layout.name = props.name + '.' + layout.name + '[' + i + ']'
          // layout = _appendSectionName(props.name, i, layout)
          layout.index = i
          _groups.push(
            <Component
              key={props.name + i++}
              section={props.section}
              layout={_appendSectionName(props.name, i, layout)}
              actionRunner={props.actionRunner}
              onClick={_handleOnClick}
              variables={props.variables}
              onChange={_handleOnChange}
              onLoadData={props.onLoadData}
            />,
          )
        }
        return [..._groups]
      }
    } catch (e) {
      console.error('Failed to build component group :', e)
    }
    return <></>
  }

  return <>{group ? render(group) : <></>}</>
}

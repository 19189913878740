import React, { useContext } from 'react'
import MessageEntry from './MessageEntry'
import MesagesContext from './MessagesProvider'

export default function MessageEntries() {
    const { messages } = useContext(MesagesContext)

    return (
        <div>{
        messages && messages
            .map((q) => <MessageEntry item={q} />)
        }
            </div>
    )
}

import React, { useEffect, useState } from 'react'
import SearchComponent from '../../../../../../components/search/SearchComponent'
import Authentication from '../../../../../../libs/api/services/Authentication'
import MessagingService from '../../../../../../libs/api/services/MessagingService'

export default function SendTo({ onChange, ServiceType, errors }) {
    const [loading, setLoading] = useState(false)
    const [availableRecipients, setAvailableRecipients] = useState([])

    useEffect(() => { _loadAvailableRecipients() }, [])

    const _loadAvailableRecipients = () => {
        MessagingService
            .ListAvailableRecipients(Authentication.UserId(), Authentication.getFacility(), Authentication.getFacilityRole(), ServiceType)
            .then((r) => {
                setAvailableRecipients(r)
            }).catch((e) => errors('Unable to find any message recipients to send a message to :' + JSON.stringify(e)))
    }

    return (
        <div>
            <div>
                <b>To:</b>
                <SearchComponent focusSearch={true} details={{
                    options: {
                        reRefKey: 'id',
                        seprKey: '',
                        template: [{
                            refKey: 'name',
                            descr: '',
                        }, {
                            refKey: 'surname',
                            descr: '',
                        },
                            // {
                            //     refKey: 'email',
                            //     descr: '',
                            // },{
                            //     refKey: 'mobile',
                            //     descr: '',
                            // }
                        ]
                    }, data: availableRecipients
                }}
                    onSelected={(r) => onChange([{id: r}])}
                />
            </div>
        </div>
    )
}

import React from 'react'
import Authentication from '../../../libs/api/services/Authentication'
import DashboardContainer from '../facility/management/components/DashboardContainer'

export default function FacilityUserManagement() {
    return (
        <div className='m-3 row text-center'>
          <h1>Facility User Management</h1>
          <DashboardContainer Icon={'user'} title={'Review User Requests'} description={'Accept or Decline new patient/clinician requests to join your facility'} location={'/' + Authentication.getFacilityRole() + '/Configuration/UserRequests'} />
          <DashboardContainer Icon={'add-user'} title={'Add New User'} description={'Add a new user to the facility'} location={'/Facility/Configuration/Users/Add'} />
        </div>
      )
}

import { faBookMedical } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useEffect, useState } from 'react'
import { Building, Calendar2, FileText, List, ListCheck, PencilSquare, PlusCircle, Table } from 'react-bootstrap-icons'
import Questionnaire from '../../../../../libs/api/services/Questionnaire'
import QBuilderComponentDatePicker from './QBuilderComponentDatePicker'
import QBuilderComponentDisplayText from './QBuilderComponentDisplayText'
import QBuilderComponentDynamicComponentTable from './QBuilderComponentDynamicComponentTable'
import QBuilderComponentFacilities from './QBuilderComponentFacilities'
import QBuilderComponentMultiSelct from './QBuilderComponentMultiSelct'
import QBuilderComponentOptions from './QBuilderComponentOptions'
import QBuilderComponentSingleSelct from './QBuilderComponentSingleSelct'
import QBuilderComponentSubSection from './QBuilderComponentSubSection'
import QBuilderComponentTextInout from './QBuilderComponentTextInout'
import './QEditor.css'
import QBuilderICDCodesItem from './widgets/QBuilderICDCodesItem'
import QBuilderPMBCodesItem from './widgets/QBuilderPMBCodesItem'
export default function QBuilderComponent({ onRerender, onChangedOrder, dragOrder, setDragOrder, order, ComponentId, idx, type, onAction, mode, className, values, SectionComponents }) {
    const [componentTypes, setComponentTypes] = useState([])
    const [dragId, setDragId] = useState('')
    const [activeMode, setActiveMode] = useState(mode)

    const loadComponentTypes = () => {
        Questionnaire
            .ListComponentsTypes()
            .then((r) => {
                setComponentTypes(r)
            })
    }
    useEffect(() => {
        loadComponentTypes()
    }, [])

    const reload = () => {
        loadComponentTypes()
    }

    const Icon = (type) => {
        switch (type) {
            case 'Display Text':
                return <FileText className='me-2' />
            case 'Get Input text':
                return <PencilSquare className='me-2' />
            case 'Multiple Selection':
                return <ListCheck className='me-2' />
            case 'Single Selection':
                return <List className='me-2' />
            case 'Date Picker':
                return <Calendar2 className='me-2' />
            case 'Dynamic Component Table':
                return <Table className='me-2' />
            case 'Sub Section':
                return <Calendar2 className='me-2' />
            case 'Facilities':
                return <Building className='me-2' />
            case 'ICD10':
                return <FontAwesomeIcon icon={faBookMedical} className='me-2' />
            case 'PMB':
                return <FontAwesomeIcon icon={faBookMedical} className='me-2' />
        }
    }

    const _render = () => {
        switch (type) {

            case 'New':
                var _sections = [];
                for (var componentType of componentTypes) {
                    if (componentType.type === 'Sub Section') continue /// These are sub components and are added elsewhere
                    const _idx = idx
                    const _type = componentType.type
                    var _section = _sections.find(s => s.section === componentType.category)
                    if (_section) {
                        //
                    } else {
                        _sections.push({
                            section: componentType.category,
                            components: []
                        })
                        _section = _sections.find(s => s.section === componentType.category)
                    }
                    _section.components.push(<div
                        className='p-0'
                        key={componentType.id}
                        onClick={(e) => onAction({ action: 'Set Type', type: _type, idx: _idx })}
                    >
                        {Icon(_type)}{_type}
                    </div>

                    )
                    //_sections.push(<div><b></b></div>)
                }
                return <div
                    // style={{ cursor: 'pointer', zIndex: 999, width: 'calc(100% - 177px )', left: '177px', bottom: '0px' }}
                    className='bg-light shadow rounded text-start p-2 position-fixed w-50 slide-in'
                >
                    <div className=' '>

                        <QBuilderComponentOptions isNew={true} onAction={(e) => onAction(e)} idx={idx} />
                    </div>
                    {

                        // JSON.stringify(_sections)
                    }
                    <div style={{ cursor: 'pointer' }} className='row m-1 text-center'>
                        <b >Select the question type</b>
                        {
                            _sections.map((s) => {
                                return (<div className='col-6 text-start'>
                                    <div className='text-center bg-primary-container'>
                                        <b>{s.section}</b>
                                    </div>
                                    <div className=''>
                                        {
                                            s.components.map((c) => c)
                                        }
                                    </div>
                                </div>)
                            })
                        }
                    </div>
                </div>
            case 'Display Text':
                return (<QBuilderComponentDisplayText
                    onRerender={() => onRerender()}
                    SectionComponents={SectionComponents}
                    onChangeMode={(mode) => setActiveMode(mode)} onSetData={() => { }} onLoadComplete={() => { }} ComponentId={ComponentId} defaultMode={mode} edit={true} onAction={onAction} idx={idx} onIssue={() => { }}
                    values={values} />)
            case 'Get Input text':
                return (<QBuilderComponentTextInout
                    onRerender={() => onRerender()}
                    SectionComponents={SectionComponents}
                    onChangeMode={(mode) => setActiveMode(mode)} onSetData={() => { }} onLoadComplete={() => { }} ComponentId={ComponentId} defaultMode={mode} edit={true} onAction={onAction} idx={idx} onIssue={() => { }}
                    values={values} />)
            case 'Multiple Selection': {
                return (<QBuilderComponentMultiSelct
                    onRerender={() => onRerender()}
                    SectionComponents={SectionComponents}
                    onChangeMode={(mode) => setActiveMode(mode)} onSetData={() => { }} onLoadComplete={() => { }} ComponentId={ComponentId} defaultMode={mode} edit={true} onAction={onAction} idx={idx} onIssue={() => { }}
                    values={values} />)
            }
            case 'Single Selection': {
                return (<QBuilderComponentSingleSelct
                    onRerender={() => onRerender()}
                    SectionComponents={SectionComponents}
                    onChangeMode={(mode) => setActiveMode(mode)} onSetData={() => { }} onLoadComplete={() => { }} ComponentId={ComponentId} defaultMode={mode} edit={true} onAction={onAction} idx={idx} onIssue={() => { }}
                    values={values} />)
            }
            case 'Date Picker':
                return (<QBuilderComponentDatePicker
                    onRerender={() => onRerender()}
                    SectionComponents={SectionComponents}
                    onChangeMode={(mode) => setActiveMode(mode)} onSetData={() => { }} onLoadComplete={() => { }} ComponentId={ComponentId} defaultMode={mode} edit={true} onAction={onAction} idx={idx} onIssue={() => { }}
                    values={values} />)
            case 'Facilities':
                return (<QBuilderComponentFacilities
                    onRerender={() => onRerender()}
                    SectionComponents={SectionComponents}
                    onChangeMode={(mode) => setActiveMode(mode)} onSetData={() => { }} onLoadComplete={() => { }} ComponentId={ComponentId} defaultMode={mode} edit={true} onAction={onAction} idx={idx} onIssue={() => { }}
                    values={values} />)
            case 'Sub Section':
                return (<QBuilderComponentSubSection
                    onRerender={() => onRerender()}
                    SectionComponents={SectionComponents}
                    onChangeMode={(mode) => setActiveMode(mode)} onSetData={() => { }} onLoadComplete={() => { }} ComponentId={ComponentId} defaultMode={mode} edit={true} onAction={onAction} idx={idx} onIssue={() => { }}
                    values={values}
                    state={{ details: [] }}
                // subsectionId={undefined}
                />)
            case 'Dynamic Component Table':
                return (<QBuilderComponentDynamicComponentTable
                    onRerender={() => onRerender()}
                    SectionComponents={SectionComponents}
                    onChangeMode={(mode) => setActiveMode(mode)} onSetData={() => { }} onLoadComplete={() => { }} ComponentId={ComponentId} defaultMode={mode} edit={true} onAction={onAction} idx={idx} onIssue={() => { }}
                    values={values}
                    state={{ details: [] }}
                // subsectionId={undefined}
                />)
            case 'ICD10':
                return (<QBuilderICDCodesItem
                    onRerender={() => onRerender()}
                    SectionComponents={SectionComponents}
                    onChangeMode={(mode) => setActiveMode(mode)} onSetData={() => { }} onLoadComplete={() => { }} ComponentId={ComponentId} defaultMode={mode} edit={true} onAction={onAction} idx={idx} onIssue={() => { }}
                    values={values}
                    state={{ details: [] }}
                />)
            case 'PMB':
                return (<QBuilderPMBCodesItem
                    onRerender={() => onRerender()}
                    SectionComponents={SectionComponents}
                    onChangeMode={(mode) => setActiveMode(mode)} onSetData={() => { }} onLoadComplete={() => { }} ComponentId={ComponentId} defaultMode={mode} edit={true} onAction={onAction} idx={idx} onIssue={() => { }}
                    values={values}
                    state={{ details: [] }}
                />)
            default:
                return (
                    <div style={{ cursor: 'pointer' }} className='bg-light rounded text-start p-2 opacity-75'>
                        <div onClick={(e) => onAction({ action: 'Add', type: 'New' })}><PlusCircle /> Add new question</div>
                    </div>)
        }
    }
    return (
        <div
            draggable={activeMode === ''}
            onDragStart={(e) => { e.dataTransfer.effectAllowed = "copyMove"; setDragId(ComponentId) }}
            onDragEnter={(e) => { e.preventDefault(); e.dataTransfer.effectAllowed = "move"; setDragOrder(order) }}
            onDragOver={(e) => { e.preventDefault(); e.dataTransfer.effectAllowed = "move"; }}
            onDragEnd={(e) => { onChangedOrder(ComponentId); }}
            className={' '.concat(className)}>
            {_render()}
        </div>
    )
}

import API from "../API";

var Questionnaire = {
    async CreateQuestionnaire(questionnaire) {
        var result = await API.process(
            'api/Questionnaire',
            'PUT',
            questionnaire
        ).catch((err) => {
            throw err;
        })

        return result
    },
    async GetQuestionnaire(questionnaireId) {
        var result = await API.process(
            `api/Questionnaire/${questionnaireId}`,
            'GET',
            null
        ).catch((err) => {
            throw err;
        })

        return result
    },
    async RemoveQuestionnaire(questionnaireId) {
        var result = await API.process(
            `api/Questionnaire/${questionnaireId}`,
            'DELETE',
            null
        ).catch((err) => {
            throw err;
        })

        return result
    },
    async ListQuestionnaires() {
        var result = await API.process(
            'api/Questionnaire',
            'GET',
            null
        ).catch((err) => {
            throw err;
        })

        return result
    },
    async ListQuestionnairesByType(type) {
        var result = await API.process(
            'api/Questionnaire/type/' + type,
            'GET',
            null
        ).catch((err) => {
            throw err;
        })

        return result
    },
    async ListQuestionnaireLayouts() {
        var result = await API.process(
            'api/Questionnaire/Layouts',
            'GET',
            null
        ).catch((err) => {
            throw err;
        })

        return result
    },
    async GetQuestionnaireLayout(questionnaireId) {
        var result = await API.process(
            `api/Questionnaire/${questionnaireId}/Layout`,
            'GET',
            null
        ).catch((err) => {
            throw err;
        })

        return result
    },
    async SetQuestionnaireLayout(questionnaireId, LayoutId) {
        var result = await API.process(
            `api/Questionnaire/${questionnaireId}/Layout/${LayoutId}`,
            'PATCH',
            null
        ).catch((err) => {
            throw err;
        })

        return result
    },
    async CreateQuestionnaireSection(questionnaireId, section) {
        var result = await API.process(
            `api/Questionnaire/${questionnaireId}/Section`,
            'POST',
            section
        ).catch((err) => {
            throw err;
        })

        return result
    },
    async ListQuestionnaireSections(questionnaireId) {
        var result = await API.process(
            `api/Questionnaire/${questionnaireId}/Sections`,
            'GET',
            null
        ).catch((err) => {
            throw err;
        })

        return result
    },
    async SetSectionOrder(sectionid, order) {
        var result = await API.process(
            `api/Questionnaire/section/${sectionid}/order/${order}`,
            'PATCH',
            null
        ).catch((err) => {
            throw err;
        })

        return result
    },
    async UpdateQuestionnaireSection(sectionId, section) {
        var result = await API.process(
            `api/Questionnaire/Section/${sectionId}`,
            'PUT',
            section
        ).catch((err) => {
            throw err;
        })

        return result
    },
    async RemoveQuestionnaireSection(sectionId) {
        var result = await API.process(
            `api/Questionnaire/Section/${sectionId}`,
            'DELETE',
            null
        ).catch((err) => {
            throw err;
        })

        return result
    },
    // Component Tyes
    async ListComponentsTypes() {
        var result = await API.process(
            `api/Questionnaire/Component/Types`,
            'GET',
            null
        ).catch((err) => {
            throw err;
        })

        return result
    },
    async ListSectionComponents(sectionId) {
        var result = await API.process(
            `api/Questionnaire/Section/${sectionId}/Components`,
            'GET',
            null
        ).catch((err) => {
            throw err;
        })

        return result
    },
    async AddComponentToSection(sectionId, Component) {
        var result = await API.process(
            `api/Questionnaire/Section/${sectionId}/Component`,
            'PUT',
            Component
        ).catch((err) => {
            throw err;
        })

        return result
    },
    async AddComponentWithoutSection( Component) {
        var result = await API.process(
            `api/Questionnaire/NoSection/Component`,
            'PUT',
            Component
        ).catch((err) => {
            throw err;
        })

        return result
    },
    async SetComponentType(ComponentId, Type) {
        var result = await API.process(
            `api/Questionnaire/Component/${ComponentId}/Type`,
            'PUT',
            Type
        ).catch((err) => {
            throw err;
        })

        return result
    },
    async RemoveComponent(ComponentId) {
        var result = await API.process(
            `api/Questionnaire/Component/${ComponentId}`,
            'DELETE',
            null
        ).catch((err) => {
            throw err;
        })

        return result
    },
    // Properties

    async CloneComponent(ComponentId) {
        var result = await API.process(
            `api/Questionnaire/Component/${ComponentId}/Clone`,
            'PUT',
            null
        ).catch((err) => {
            throw err;
        })

        return result
    },
    async SetComponentProperty(ComponentId, Property) {
        var result = await API.process(
            `api/Questionnaire/Component/${ComponentId}/Property`,
            'PATCH',
            Property
        ).catch((err) => {
            throw err;
        })

        return result
    },
    async GetPropertyValue(ComponentId, Property) {
        var result = await API.process(
            `api/Questionnaire/Component/${ComponentId}/Property/${Property}`,
            'GET',
            Property
        ).catch((err) => {
            throw err;
        })

        return result
    },
    async GetComponentProperties(ComponentId) {
        var result = await API.process(
            `api/Questionnaire/Component/${ComponentId}/Properties`,
            'GET',
            null
        ).catch((err) => {
            throw err;
        })

        return result
    },
    async QuestionnaireResponse(QuestionnaireAssignmentId, Response) {
        var result = await API.process(
            `api/Questionnaire/${QuestionnaireAssignmentId}/Response`,
            'PATCH',
            Response
        ).catch((err) => {
            throw err;
        })

        return result
    },
    async GetQuestionnaireResponse(QuestionnaireAssignmentId, ComponentId) {
        var result = await API.process(
            `api/Questionnaire/${QuestionnaireAssignmentId}/Response/${ComponentId}`,
            'GET',
            null
        ).catch((err) => {
            throw err;
        })

        return result
    },
    async GetQuestionnaireResults(QuestionnaireAssignmentId) {
        var result = await API.process(
            `api/Questionnaire/${QuestionnaireAssignmentId}/Results`,
            'GET',
            null
        ).catch((err) => {
            throw err;
        })

        return result
    },
    async GetQuestionnaireResultsFull(QuestionnaireAssignmentId) {
        var result = await API.process(
            `api/Questionnaire/${QuestionnaireAssignmentId}/Results/Full`,
            'GET',
            null
        ).catch((err) => {
            throw err;
        })
        return result
    },
    async GetQuestionnaireResponseAlert(QuestionnaireAssignmentId, ComponentId) {
        var result = await API.process(
            `api/Questionnaire/${QuestionnaireAssignmentId}/Response/${ComponentId}/Alerts`,
            'GET',
            null
        ).catch((err) => {
            throw err;
        })

        return result
    },
    async SetComponentOrder(ComponentId, dragOrder) {
        var result = await API.process(
            `api/Questionnaire/${ComponentId}/Order/${dragOrder}`,
            'PATCH',
            null
        ).catch((err) => {
            throw err;
        })

        return result
    },
    async LinkPatientProfile(ComponentId, categoryId, fieldId, options) {
        var result = await API.process(
            `api/Questionnaire/${ComponentId}/Link/${fieldId}`,
            'PUT',
            options
        ).catch((err) => {
            throw err;
        })

        return result
    },
    async GetLinkPatientProfile(ComponentId) {
        var result = await API.process(
            `api/Questionnaire/${ComponentId}/Link`,
            'GET',
            null
        ).catch((err) => {
            throw err;
        })

        return result
    },
    async UnLinkPatientProfile(ComponentId) {
        var result = await API.process(
            `api/Questionnaire/${ComponentId}/Link`,
            'DELETE',
            null
        ).catch((err) => {
            throw err;
        })

        return result
    },
    async Report(QuestionnaireAssignmentId) {
        var result = await API.process(
            `api/Questionnaire/${QuestionnaireAssignmentId}/Export/Excel`,
            'GET',
            null
        ).catch((err) => {
            throw err;
        })

        return result
    },
    async SetOption(QuestionnaireAssignmentId, Option) {
        var result = await API.process(
            `api/Questionnaire/${QuestionnaireAssignmentId}/option`,
            'PUT',
            Option
        ).catch((err) => {
            throw err;
        })

        return result
    },
    async GetOption(QuestionnaireAssignmentId, Option) {
        var result = await API.process(
            `api/Questionnaire/${QuestionnaireAssignmentId}/option/${Option}`,
            'GET',
            null
        ).catch((err) => {
            throw err;
        })

        return result
    },
    async GetOptions(QuestionnaireId) {
        var result = await API.process(
            `api/Questionnaire/${QuestionnaireId}/options`,
            'GET',
            null
        ).catch((err) => {
            throw err;
        })

        return result
    },
    async Stats(QuestionnaireId) {
        var result = await API.process(
            `api/Questionnaire/${QuestionnaireId}/stats`,
            'GET',
            null
        ).catch((err) => {
            throw err;
        })

        return result
    },
    async StatsHistory(QuestionnaireId,QuestionnaireAssignmentId) {
        var result = await API.process(
            `api/Questionnaire/${QuestionnaireId}/stats/History/${QuestionnaireAssignmentId}`,
            'GET',
            null
        ).catch((err) => {
            throw err;
        })

        return result
    },
    async CreateQuestionnaireSubsection(SectionId, Section) {
        var result = await API.process(
            `api/Questionnaire/section/${SectionId}/subsection`,
            'POST',
            Section
        ).catch((err) => {
            throw err;
        })

        return result
    },
    async GetSubSection(subsectionId) {
        var result = await API.process(
            `api/Questionnaire/SubSection/${subsectionId}`,
            'GET',
            null
        ).catch((err) => {
            throw err;
        })

        return result
    },
}

export default Questionnaire;
import React, { useContext } from 'react'
import VariablesContext from '../context/VariablesContext'
import { StringFunctions } from '../../common/StringFunctions'

const DebugComponent = () => {
  const { variables } = useContext(VariablesContext)
  return <div>DEBUG:{StringFunctions.MapToJSON(variables!)}</div>
}

export default DebugComponent

import React from 'react'
import { CheckCircleFill, ClockFill, ExclamationCircleFill, ExclamationDiamondFill } from 'react-bootstrap-icons'

export default function WorkFlowLogs({ logs }) {
    const _statusIcon = (status) => {
        switch (status) {
            case 'Success':
                return <CheckCircleFill className='text-success' />
            case 'Warning':
                return <ExclamationDiamondFill className='text-warning' />
            case 'Error':
            case 'Critical':
                return <ExclamationCircleFill className='text-danger' />
            default:
                return <></>
        }
    }
    return (
        <div className='text-start'>
            {
                logs ?
                    logs.map((log) =>
                        <div className='d-flex justify-content-between align-items-center ' key={log.id}>
                            <div>{_statusIcon(log.status)} {log.description}</div>
                            <div><ClockFill className='me-2' />{log.executedDateTime.substr(0,19)}</div></div>
                    )
                    :
                    <></>
            }
        </div>
    )
}

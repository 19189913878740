import React, { useContext, useEffect, useState } from 'react'
import ErrorDialog from '../../../../../../components/dialogs/ErrorDialog'
import FlowAssistant from '../../../../../../libs/api/services/FlowAssistant'
import StickyNavigationContext from '../../../../../../libs/providers/StickyNavigationProvider'
import FacilityMessagingAudienceGroup from '../components/wizard/FacilityMessagingAudienceGroup'
import FacilityMessagingEmailConf from '../components/wizard/FacilityMessagingEmailConf'
import FacilityMessagingReceiving from '../components/wizard/FacilityMessagingReceiving'
import FacilityMessagingSending from '../components/wizard/FacilityMessagingSending'
import FacilityMessagingSetupType from '../components/wizard/FacilityMessagingSetupType'
import FacilityMessagingSummary from '../components/wizard/FacilityMessagingSummary'
import ServiceProviderLogs from '../components/wizard/ServiceProviderLogs'

export default function FacilityMessagingConfigurationWizard({ startStep }) {
    const [step, setStep] = useState(1)
    const [title, setTitle] = useState('')
    const [description, setDescription] = useState('')
    const [navigation, setNavigation] = useState([])
    const [errors, setErrors] = useState('')
    const [values, setValues] = useState({})
    const { setSticky, setPreventMessage } = useContext(StickyNavigationContext)
    const [navigationContext, setNavigationContext] = useState('Start')
    const [history, setHistory] = useState({})
    const _handleSetNavigation = async (n) => {
        //alert(JSON.stringify(n))
        var _nav = []
        var history = FlowAssistant
            .GetLast()
        if (step === 0) {
            if (startStep)
                setStep(startStep)

            if (history) {
                setNavigationContext(history.section)
                var vs = {}
                for (const k of Object.keys(history.details)) {
                    // const parsed = JSON.parse(history.details[k])
                    try {
                        vs[k] = JSON.parse(history.details[k])
                    } catch (e) {
                        vs[k] = history.details[k]
                    }
                    setValues({ ...values, ...vs })
                }
            }
        }
        if (history) {
            _nav.push({ type: 'Action', action: () => FlowAssistant.Goto(history.section), text: 'Cancel' })
        }

        _nav = [..._nav, ...n]
        // In flow jumping
        if (values.action && values.action.from) {
            const _from = values.action.from
            setValues({ ...values, action: {} })
            _nav = [{
                text: 'Done', type: 'Step', step: _from,
                // action: aw() => { }
            }
            ]
            // setValues({ ...values, action: {} })
        }
        setNavigation(_nav)
    }
    useEffect(() => {
        setHistory(FlowAssistant.GetLast())
    }, [])

    useEffect(() => {
        if (step != 1 && step != 5) {
            setSticky(true)
            setPreventMessage('Configuration Incomplete', 'You have not completed the configuration process. <br/><br/>This can be completed later, however service will not be active until completed. <br/><br/>Are you sure you would like to cancel?')
        } else {
            setSticky(false)
        }
    }, [step])

    useEffect(() => {
        if (values.action && values.action.to) {
            try { setStep(values.action.to) } catch (e) { }
        }
    },
        [values]
    )
    const _getStepSection = () => {
        switch (step) {
            case 1:
                return <FacilityMessagingSetupType
                    title={(t) => setTitle(t)}
                    description={(t) => setDescription(t)}
                    navigation={(t) => _handleSetNavigation(t)}
                    navigator={{
                        goto: (s) => setStep(s)
                    }}
                    errors={(e) => setErrors(e)}
                    setValue={(v) => setValues({ ...values, ...v })}
                    values={values}
                />
            case 2:
                return <FacilityMessagingSending
                    title={(t) => setTitle(t)}
                    description={(t) => setDescription(t)}
                    navigation={(t) => _handleSetNavigation(t)}
                    navigator={{
                        goto: (s) => setStep(s)
                    }}
                    errors={(e) => setErrors(e)}
                    setValue={(v) => setValues({ ...values, ...v })}
                    values={values}
                />
            case 3:
                return <FacilityMessagingReceiving
                    title={(t) => setTitle(t)}
                    description={(t) => setDescription(t)}
                    navigation={(t) => _handleSetNavigation(t)}
                    navigator={{
                        goto: (s) => setStep(s)
                    }}
                    errors={(e) => setErrors(e)}
                    setValue={(v) => setValues({ ...values, ...v })}
                    values={values}
                />
            case 4:
                return <FacilityMessagingAudienceGroup
                    title={(t) => setTitle(t)}
                    description={(t) => setDescription(t)}
                    navigation={(t) => _handleSetNavigation(t)}
                    navigator={{
                        goto: (s) => setStep(s)
                    }}
                    errors={(e) => setErrors(e)}
                    setValue={(v) => setValues({ ...values, ...v })}
                    values={values}
                />
            case 5:
                return <FacilityMessagingSummary
                    title={(t) => setTitle(t)}
                    description={(t) => setDescription(t)}
                    navigation={(t) => _handleSetNavigation(t)}
                    navigator={{
                        goto: (s) => setStep(s)
                    }}
                    errors={(e) => setErrors(e)}
                    setValue={(v) => setValues({ ...values, ...v })}
                    values={values}
                />
            case 6:
                return <ServiceProviderLogs
                    title={(t) => setTitle(t)}
                    description={(t) => setDescription(t)}
                    navigation={(t) => _handleSetNavigation(t)}
                    navigator={{
                        goto: (s) => setStep(s)
                    }}
                    errors={(e) => setErrors(e)}
                    setValue={(v) => setValues({ ...values, ...v })}
                    values={values}
                />
            case 7:
                return <FacilityMessagingEmailConf
                    title={(t) => setTitle(t)}
                    description={(t) => setDescription(t)}
                    navigation={(t) => _handleSetNavigation(t)}
                    navigator={{
                        goto: (s) => setStep(s)
                    }}
                    errors={(e) => setErrors(e)}
                    setValue={(v) => setValues({ ...values, ...v })}
                    values={values}
                />
            default:
                return <></>
        }
    }

    return (
        <>
            <ErrorDialog humanize={true} error={errors} onClose={() => setErrors('')} />
            <div
                className="Wizard top-center position-relative m-3">
                <div className="head"><h1>{title}</h1>
                    <p>{description}</p>

                    <div
                        className="error-details"></div>
                </div>
                <div className="body">
                    <div
                        className=".section d-flex flex-column align-items-stretch h-100 w-100">
                        {_getStepSection()}
                    </div>
                </div>
                <div classname="tail">
                    {
                        navigation.map((n) => {
                            if (n.type === 'Next')
                                return <button
                                    key={n.text}
                                    onClick={async (e) => {
                                        if (n.action) {
                                            try {
                                                await n.action()
                                                    .catch(() => {
                                                        setErrors(e.message)
                                                        return
                                                    })
                                            } catch (e) {
                                                return
                                            }
                                        }
                                        setStep(step + 1)
                                    }} className="btn ng-buttons">{n.text}</button>
                            else if (n.type === 'Back')
                                return <button
                                    key={n.text}
                                    onClick={async (e) => {
                                        if (n.action) {
                                            await n.action().catch((e) => setErrors(e.message))
                                        }
                                        setStep(step - 1)
                                    }} className="btn ng-buttons">{n.text}</button>
                            else if (n.type === 'Action')
                                return <button
                                    key={n.text}
                                    onClick={async (e) => {
                                        if (n.action)
                                            await n.action()
                                                .catch((e) => setErrors(e.message))
                                    }} className="btn ng-buttons">{n.text}</button>
                            else if (n.type === 'Done')
                                return <button
                                    key={n.text}
                                    onClick={async (e) => {
                                        if (n.action)
                                            await n.action()
                                                .catch((e) => setErrors(e.message))
                                        setStep(1)
                                    }} className="btn ng-buttons">{n.text}</button>
                            else if (n.type === 'Step')
                                return <button
                                    key={n.text}
                                    onClick={async (e) => {
                                        if (n.action)
                                            await n.action()
                                                .then(() => { setStep(n.step) })
                                                .catch((e) => setErrors(e.message))
                                        else
                                            setStep(n.step)
                                    }} className="btn ng-buttons">{n.text}</button>
                        })
                    }
                </div>
            </div>
        </>

        // <div>
        //   <div>Select new / mange provider</div>
        //   <div>Select provider service type</div>
        //   <div>Setup Connection details</div>
        //   <div>REview</div>
        //   <div>Link to facility</div>
        // </div>
    )
}
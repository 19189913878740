import React, { useEffect, useRef, useState } from 'react';

export default function InputWithKeyFunctions({ onCancel, onSubmit, ref, defaultValue, value, selected, className, placeholder, onBlur, onFocus, onChange }) {
    const [val, setVal] = useState(defaultValue ? defaultValue : '')
    // const [isElected, setIsSlecte] = useState(selected)
    const editNameRef = useRef()

    useEffect(() => {
        if (!defaultValue) editNameRef.current.value = ''; else setVal(defaultValue)
    }, [defaultValue])
    useEffect(() => { if (onChange) onChange(val) }, [val])


    const _setActive = () => {
        if (selected)
            setTimeout(() => { if (editNameRef.current) editNameRef.current.select(); selected = false }, 100)
    }


    const _handleOnCancel = () => {
        if (editNameRef.current) editNameRef.current.blur()
        if (onCancel) onCancel()

    }
    const _handleOnSubmit = () => { if (onSubmit) onSubmit(editNameRef.current.value) }

    const _handleKeyEvent = (ev) => {
        switch (ev.keyCode) {
            case 13:
                return _handleOnSubmit()
            case 27:
                return _handleOnCancel()
            default: break;
        }
    }
    // if (selected) {_setActive();setIsSlecte(false) }

    useEffect(() => { _setActive() }, [selected])
    return <input type='text' onFocus={onFocus} onClick={(e) => { e.stopPropagation(); }}
        onBlur={() => onBlur ? onBlur() : _handleOnCancel()}
        ref={editNameRef}
        onChange={(e) => setVal(e.target.value)}
        value={val}
        onKeyDown={_handleKeyEvent}
        className={className ? className : ''}
        placeholder={placeholder ? placeholder : ''}
    // defaultValue={defaultValue} 
    />
}

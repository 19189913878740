import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useRef, useState } from 'react';
import { ArrowRightSquare, BorderWidth, Bullseye, Clock, EnvelopeAt, Eye, EyeSlash, Person, PersonFill, PhoneVibrate, ReplyAll, Whatsapp, XSquareFill } from 'react-bootstrap-icons';
import Authentication from '../../../../libs/api/services/Authentication';
import Messaging from '../../../../libs/api/services/Messaging';
import RTEMessageBuilderComponent from '../admin/components/RTEMessageBuilderComponent';
import './MessageTab.css';
import MessageTabFunction from './MessageTabFunction';
export default function MessageTab({ loadMessages, message, extended, onError }) {
    const [status, setStatus] = useState()
    const [show, setShow] = useState(false)
    const [showAll, setShowAll] = useState(true)
    const [showReply, setShowReply] = useState(false)
    const [reply, setReply] = useState({})
    const [msg, setMsg] = useState('')
    const [showStickyHide, setShowStickyHide] = useState(false)
    const scrollRef = useRef();
    const replyRef = useRef();
    const _handleArchiveMessage = (id) => {
        Messaging
            .ArchiveMessage(id)
            .then((r) => {
                loadMessages()
            });
    }

    const _handleGetMessage = async (messageId) => {
        var m = await Messaging
            .GetMessage(messageId)
        return m.messageBody
    }
    const _handleMessageStatus = (id, status) => {
        Messaging
            .SetMessageStatus(id, status)
            .then((r) => {
                if (status === "Deleted" || status === "Archived")
                    setShowAll(false)
            });
    }

    const _handleReply = () => {
        setStatus("SENDING REPLY")
        Messaging
            .SendReply(message.id, {
                Template: reply.a,
                TemplateHTML: reply.b,
                TemplateMD: reply.c
            }).then((r) => {
                setTimeout(loadMessages, 3000, )
                setShowReply(false)
                setStatus("REPLY SENT")
                setTimeout(setStatus, 2000,  'REPLY')
            }).catch((e) => { 
                if (e.errors) onError(e.errors); else onError(e.message); });
    }

    const _handleRead = (messageId) => {
        //if (!show) {
        setMsg('LOADING')
        _handleGetMessage(messageId)
            .then((m) => {
                setMsg(m)
                setShow(!show)
            }).catch((e) => onError(e.message))

        if (message.status.status !== "Archived")
            _handleMessageStatus(message.id, "Read")
        // }
        // else
        // {
        //document.getElementById(msg.id).scrollIntoView();
        // scrollRef.current.scrollIntoView({ behavior: "smooth" });
        // }
    }

    //scrollRef.current.getBoundingClientRect()
    const _handleRefreshStatus = (messageid) => {
        Messaging
            .RefreshStatus(messageid)
            .then((r) => {
                loadMessages()
            });
    }

    // const _handleStickyScroll = () => {
    //     if (scrollRef && scrollRef.current && scrollRef.current.getBoundingClientRect && scrollRef.current.getBoundingClientRect().bottom > 500)
    //         setShowStickyHide(true)
    //     else
    //         setShowStickyHide(false)
    // }

    useEffect(() => {
        if (showReply) {
            replyRef.current.scrollIntoView({ behavior: "smooth" })
            // replyRef.current.select()
        }
    }, [showReply])

    // const _handleShowReply = () => {
    //     // scrollRef.current.scrollIntoView({ behavior: "smooth" });
    //     // scrollRef.current.querySelector(':scope > :last-child').scrollIntoView()
    //     setShowReply(true)
    //     replyRef.current.scrollIntoView()
    //     //scrollRef.current.scrollTop = scrollRef.current.scrollHeight;
    //     // document.current.scrollTop = scrollRef.current.scrollHeight;
    //     // scrollRef.current.animate({
    //     //     scrollTop: scrollRef.current.prop("scrollHeight")
    //     // }, 500);

    //     //        scrollRef.current.scroll({ behavior: "smooth" });
    // }

    const _handleGetTo = (to) => {
        if (!to) return []
        var _to = []
        for (var t of to.split(';'))
            if (t !== "")
                _to.push(t)

        return _to
    }
    if (!showAll)
        return <></>
    return (
        <div ref={scrollRef}
            className={'d-flex position-relative '
                .concat(!show && (message.fromUser && message.fromUser.id && (parseInt(Authentication.UserId()) === parseInt(message.fromUser.id) && message.messageType === 'Outgoing')) ? ' out-message' : 'in-message')}>
            <div className={'bg-light m-3 rounded rounded-3 shadow p-0 '.concat(show && msg !== "LOADING" ? 'message-container-show' : 'message-container-hide')} >
                <div className='d-flex m-1 justify-content-between'>
                    <div
                        onClick={(e) => _handleRefreshStatus(message.id)}
                        className=''>
                        {
                            message.serviceType.type === "Internal Messaging Service"
                                ?
                                <div><Bullseye className='me-2' />{message.status.status}</div>
                                :
                                message.serviceType.type === "Email"
                                    ?
                                    <div><EnvelopeAt className='me-2' />{message.status.status}</div>
                                    :
                                    message.serviceType.type === "SMS"
                                        ?
                                        <div><PhoneVibrate className='me-2' />{message.status.status}</div>
                                        :
                                        message.serviceType.type === "WhatsApp"
                                            ?
                                            <div><Whatsapp className='me-2' />{message.status.status}</div>
                                            :
                                            <div>{message.serviceType.type}</div>
                        }

                    </div>
                    {
                        Authentication.getFacilityRole() !== 'Patient'
                            ?
                            <MessageTabFunction onStatusChanged={(e) => setShowAll(e)} message={message} loadMessages={() => loadMessages()} />
                            :
                            <></>
                    }

                </div>
                <div className='m-2'>
                    <div className=''><b><Person className='me-2' />{message.from}</b></div>
                    <div className=''>{
                        _handleGetTo(message.to).map((to) =>
                            <b><br /><PersonFill className='me-2' />{to}</b>
                        )
                    }</div>
                    <div className=''><small><Clock className='me-2' /><i>{message.sentDate.substr(0, 19)}</i></small></div>
                    <div className=''><p><BorderWidth className='me-2' />{message.subject}</p></div>
                    <div className={'m-2 position-relative  '.concat(show ? ' show-message ' : 'hide-message')}><p dangerouslySetInnerHTML={{ __html: msg.body ? msg.body.replaceAll('!important', '') : '' }}></p>
                        <div
                            className={'bg-primary-container position-fixed rounded rounded-5 p-3 opacity-75 d-flex '
                                // .concat(showStickyHide ? 'd-block' : 'd-none')
                            }
                            style={{ top: '50vh', right: '50vw', cursor: 'pointer', zIndex: 100 }}>

                            <div className='p-2' onClick={(e) => _handleRead(message.id)} >Hide</div>
                            <div className='p-2' onClick={(e) => setShowReply(!showReply)}>Reply</div>
                        </div>
                    </div>
                </div>

                <div onClick={(e) => _handleRead(message.id)} style={{ cursor: 'pointer', fontSize: '1rem' }} className='text-center'>
                    {
                        msg === "LOADING"
                            ?
                            <FontAwesomeIcon icon={faSpinner} spin />
                            :
                            !show
                                ?
                                <>
                                    <Eye className='me-2 mb-1' />
                                    READ
                                </>
                                :
                                <>
                                    <EyeSlash className='me-2 mb-1' />
                                    HIDE
                                </>
                    }
                </div>
                <div
                    onClick={(e) => setShowReply(!showReply)}
                    style={{ cursor: 'pointer', fontSize: '1rem' }} className='text-center'>
                    {
                        (message.fromUser && message.fromUser.id && (parseInt(Authentication.UserId()) === parseInt(message.fromUser.id) && message.messageType === 'Outgoing'))
                            ?
                            <></>
                            :
                            message.serviceType.type === "SMS"
                                ?
                                <></>
                                :
                                !showReply
                                    ?
                                    <>
                                        <ReplyAll className='me-2 mb-1' />
                                        {status ? status : 'REPLY'}
                                    </>
                                    :
                                    <>
                                        <XSquareFill className='me-2 mb-1' />
                                        DISCARD
                                    </>


                    }
                </div>
                {
                    showReply
                        ?
                        <div ref={replyRef} className='text-end'>
                            <RTEMessageBuilderComponent
                                options={[]}
                                placeholders={false}
                                onSetTemplate={(a, b, c) => setReply({ a: a, b: b, c: c })}
                            />
                            <button
                                onClick={(e) => _handleReply()}
                                className='btn'>Send <ArrowRightSquare className='me-2 mb-1' /></button>
                        </div>
                        :
                        <></>
                }


            </div>
        </div>
    )
}

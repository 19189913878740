import React, { useContext, useState } from 'react'
import ConfirmDialog from '../../../../../../components/dialogs/ConfirmDialog'
import GenericFileUpload from '../../../../../../components/files/GenericFileUpload'
import ConfirmUploadDetails from './ConfirmUploadDetails'
import DocumentsGrid from './DocumentsGrid'
import DocumentsContext from './DocumentsProvider'

export default function DocumentAdd() {
    const [files, setFiles] = useState([])
    const { uploadFiles } = useContext(DocumentsContext)
    return <>
        <ConfirmDialog
            title={'confirm'}
            open={files.length > 0}
            onCancel={() => setFiles([])} onConfirm={() => uploadFiles(files)} ><ConfirmUploadDetails files={files} /></ConfirmDialog>
        <GenericFileUpload
            onConfirm={setFiles}
        />
        <DocumentsGrid />
    </>
}
{/*  */ }
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import ErrorDialog from '../../../../../../../components/dialogs/ErrorDialog'
import MessagingServiceProvider from '../../../../../../../libs/api/services/MessagingServiceProvider'
import UserEmailDiscoverComponent from '../../../../admin/facility/wizard/UserEmailDiscoverComponent'
import EmailConfigurationComponentCombined from './Email/EmailConfigurationComponentCombined'
export default function FacilityMessagingEmailConf({ title, description, navigation, errors, setValue, values }) {
  const { state } = useParams()

  const [error, setError] = useState()

  const [mode, setMode] = useState('')
  const [serviceProviderService, setServiceProviderService] = useState()
  const [properties, setProperties] = useState([])

  useEffect(() => {
    title('Configuration')
    description('Please enter the account details for this email address')
    if (state) {
      setServiceProviderService({ id: state })
      setMode('Discover')
    }
    _loadSettings()
    navigation([{
      text: 'Save', type: 'Done', action: async () => { }
    }
    ])
    // if (Object.keys(values).includes('oauth') && values.oauth === 'recheck')
    //     _handleLookupEmailAccount()
  }, [])
  useEffect(() => {
    if (properties && Object.keys(properties).length > 0)
      _save()

  }, [properties])
  const _save = async () => {
    if (Object.keys(values).includes('serviceProviderService')) {
      // Save to API values.serviceProviderService || values.serviceProviderService.properties
      var props = values.serviceProviderService.properties

      var _sendingProperties = props.find(p => p.name === "mailBoxConfig")
      if (_sendingProperties) {
        props.find(p => p.name === 'mailBoxConfig').property = JSON.stringify(properties)
      } else {
        props.push({ name: 'mailBoxConfig', property: JSON.stringify(properties) })
      }
      const _req = await MessagingServiceProvider
        .UpdateService(values.serviceProvider.id, values.serviceProviderService).catch((e) => errors(e))
      _sendingProperties = _req
      setValue({ serviceProviderService: _req })
    }
  }


  const _loadSettings = () => {
    // Find service where name = audience | {"audienceType":"SingleUser"} | and user is {"audienceType":"SingleUser","audienceUser":{"id":2044,"title":"","firstName":"Patient","lastName":"Patient","username":"PAT","password":"PAT","emailAddress":"","mobileNumber":"","userRoles":[],"userFacilitieRoles":[]}}
    if (values && values.serviceProviderService) {
      //var _props = values.serviceProviderService.properties.find(p => p.name === 'mailBoxConfig');
      try {
        setProperties({ mailBoxConfig: JSON.parse(values.serviceProviderService.properties.find(p => p.name === 'mailBoxConfig').property) })
      } catch (e) { }
    }
  
  }
  const _setProperties = () => {

    // MessagingServiceProvider
    //   .UpdateUserEmailAddcount(Authentication.UserId(), Authentication.getFacility(), properties)
    _save()
      .then((r) => {
        title('Configuration')
        description('Verifying your email details')
        navigation([{
          text: 'Back to service provider configuration', type: 'Done', action: async () => { }
        }])
        setServiceProviderService(r)
        setMode('Discover')
      })
      .catch((e) => { setError(e.message) })
  }

  useEffect(() => {
    // incomming_domain
    // incomming_encryption
    // incomming_port
    // incomming_security
    // name
    // outgoing_domain
    // outgoing_encryption
    // outgoing_port
    // outgoing_security
    // password
    // port
    // provider
    // security
    if (properties.mailBoxConfig && (
      properties.mailBoxConfig.incomming_type &&
      properties.mailBoxConfig.incomming_domain &&
      properties.mailBoxConfig.incomming_port &&
      properties.mailBoxConfig.outgoing_domain &&
      properties.mailBoxConfig.outgoing_port &&
      properties.mailBoxConfig.emailAddress &&
      properties.mailBoxConfig.password
    ))
      navigation([{
        text: 'Back', type: 'Done', action: async () => { }
      },
        {
          text: 'Test & Continue', type: 'Action', action: async () => { _setProperties() }
        },
      ])
    else navigation([{
      text: 'Back', type: 'Done', action: async () => { }
    },])
  }, [properties])

  switch (mode) {
    case 'Discover': return (<UserEmailDiscoverComponent service={values.serviceProviderService} />)
    default:
      return (
        <>
          <ErrorDialog error={error} humanize={true} onClose={() => setError()} />
          <div>

            <EmailConfigurationComponentCombined
              onChange={(r) => Object.keys(r).length > 0 ? setProperties({ ...properties, mailBoxConfig: r }) : undefined}
              defaultValue={properties && properties.mailBoxConfig ? properties.mailBoxConfig : undefined}
              type={'both'} />
          </div>
        </>
      )
  }

}

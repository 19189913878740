import { faArrowLeft, faArrowRight } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { Children, useEffect, useState } from 'react'
import { BoxArrowInDownRight, X } from 'react-bootstrap-icons'

export default function QBuilderLayoutComponentWrapper({ className, defaultLayout, children, onUpdateLayout, currentMode }) {
    const [mode, setMode] = useState(currentMode ? currentMode : 'view')
    const [layout, setLayout] = useState(defaultLayout ? defaultLayout : { width: 12, offset: 0, isSet: false })

    useEffect(() => {
        if (layout.isSet)
            onUpdateLayout(layout)
    }, [layout])

    const _handleSetWidth = (type) => {
        if (type === 'dec') {
            if (layout.width - 1 > 0)
                setLayout({ ...layout, isSet: true, width: layout.width - 1 })
        }
        else if (type === 'inc')
            if (layout.width + 1 < 13)
                setLayout({ ...layout, isSet: true, width: layout.width + 1 })
    }

    const _handleSetOffset = (type) => {
        if (type === 'dec') {
            if (layout.offset - 1 > -1)
                setLayout({ ...layout, isSet: true, offset: layout.offset - 1 })
        }
        else if (type === 'inc')
            if (layout.offset + 1 < 13)
                setLayout({ ...layout, isSet: true, offset: layout.offset + 1 })
    }
    return (
        <>
            <div className={'position-relative '.concat(className).concat(mode === 'Layout' ? ' bg-primary-container' : '')}>
                {
                    mode === 'Capture'
                        ?
                        <></>
                        :
                        <div onClick={() => setMode('Layout')} className='position-absolute' style={{ right: '0px' }}><BoxArrowInDownRight className='me-3' /></div>
                }

                {children}

            </div>
            {
                mode === 'Layout'
                    ?
                    <div className='bg-secondary-container w-100 position-absolute opacity- shadow p-2' style={{ left: '0px', marginTop: '57px', zIndex: 99 }}>
                        <div>
                            <div className='text-end'>
                                <button onClick={() => setMode('View')} className='btn btn-xs '><X /></button>
                            </div>
                            <div><b>Width</b> <FontAwesomeIcon onClick={() => _handleSetWidth('dec')} icon={faArrowLeft} /> {layout.width} / 12 <FontAwesomeIcon onClick={() => _handleSetWidth('inc')} icon={faArrowRight} /></div>
                            <div><b>Offset</b> <FontAwesomeIcon onClick={() => _handleSetOffset('dec')} icon={faArrowLeft} /> {layout.offset} / 12 <FontAwesomeIcon onClick={() => _handleSetOffset('inc')} icon={faArrowRight} /></div>
                            {/* <button onClick={() => _handleSaveLayout()} className='btn btn-xs'>Save And Close</button></div> */}
                        </div>
                    </div>
                    :
                    <></>
            }
        </>
    )
}

import React, { useContext, useEffect, useState } from 'react'
import { Clipboard, Clipboard2, PersonCheckFill, PersonPlusFill, PersonXFill, XCircle } from 'react-bootstrap-icons'
import { BsFillPersonFill } from 'react-icons/bs'
import { ICONS } from '../../../../components/icons/GenericIconPicker'
import { PRIORITIES } from '../../../../components/priority/PriorityPicker'
import SearchComponent from '../../../../components/search/SearchComponent'
import DateTimeFormatter from '../../../../components/ui/calendar/date_picket/DateTimeFormatter'
import ToDoListService from '../../../../libs/api/services/ToDoListService'
import ToDoListWorkFlows from '../../../../libs/api/services/ToDoListWorkFlows'
import WorkFlowDetailsModal from '../../workflow/management/WorkFlowDetailsModal'
import ToDoListContext from '../ToDoListProvider'

export default function ToDoListLineItemPopup({ defaultValues, assignmentId, item, onChange }) {

    const { GetItemDetails, showToast } = useContext(ToDoListContext)
    const [taskDetails, setTaskDetails] = useState()
    const [details, setDetails] = useState(defaultValues ? defaultValues : {
        background: 'transparent',
        color: undefined
    })

    const load = async () => setTaskDetails(await GetItemDetails(assignmentId))
    useEffect(() => {
        load()
        return () => { setTaskDetails() }
    }, [])

    const [additionalUsers, setAdditionalUsers] = useState({
        reAssign: {
            active: false,
            user: undefined
        },
        assistedBy: {
            active: false,
            users: undefined
        },
    })

    if (!taskDetails) return

    return (
        <div className='d-flex flex-column flex-grow-1 m-3'>

            <DatesBar start={taskDetails.assignedDate} end={taskDetails.completeDate} />
            <div className='d-flex flex-column flex-lg-row justify-content-around flex-grow-1' style={{ overflow: 'hidden', maxHeight: 'fit-content' }}>
                <div className='d-flex flex-column flex-grow-1'>

                    <div className='flex-grow-1 d-flex flex-column '>
                        <WorkFlowDetailsModal toDoListId={assignmentId}>
                            <div style={{ background: details.background, color: details.color }}
                                className='rounded p-2 shadow'
                            >
                                {details.icons.map((i) => ICONS.find((x) => x.name === i) && ICONS.find((x) => x.name === i).icon)}
                                {details.severity ? PRIORITIES.find((p) => p.proprity === details.severity).icon : undefined}
                                {item.taskItem.list}
                            </div>
                        </WorkFlowDetailsModal>
                    </div>
                </div>
                <div className='flex-grow-1 '>
                    <AssignmentsPanel assignmentId={assignmentId} item={item} details={taskDetails} onUpdate={(t) => { load(); showToast(t, <PersonPlusFill className='me-2' />, 3) }} />
                </div>
            </div>
        </div>
    )
}

const DatesBar = ({ start, end }) => {
    return (<div className='d-flex justify-content-between flex-shrink-1'>
        <div><b>Assigned</b>
            <div>{DateTimeFormatter.format(start)}</div>
        </div>
        {/* <div><b>Completed</b>
            <div>{DateTimeFormatter.format(end, 'Ongoing')}</div>
        </div> */}
    </div>)
}

const UsersWorkingOnTask = (users) => {
    return (<><b>Who's Working on this task</b>
        <div className='m-2 ms-3'>
            {users.users.map((u) => <div className='d-flex w-100'>
                {u.currentStatus === "Transfer" ? <PersonXFill className='me-2' /> : <PersonCheckFill className='me-2' />}
                <div className='flex-grow-1'>{`${u.user.firstName} ${u.user.lastName}`} </div>
                <small className='flex-shrink-1'><i>{`${u.currentStatus}`}</i></small>
            </div>)}
        </div></>)
}
const AssignmentsPanelRequestAssistance = ({ text, description, onSelect }) => {
    const [show, setShow] = useState(false)
    const [users, setUsers] = useState([])
    const [user, setUser] = useState()
    const [status, setStatus] = useState()
    const _loadUsers = () => {
        ToDoListService
            .FindAvailableUsers()
            .then((r) => setUsers(r))
    }
    useEffect(() => { _loadUsers(); return () => { setUsers() } }, [])
    const _handleSelectUser = async (u) => {
        setUser(u)
    }
    const _handleSelectType = async (type) => {
        setStatus('Processing')
        if (onSelect)
            await onSelect(user, type).catch((e) => setStatus(e.message))
        setStatus()
        setUser()
        setShow(false)
    }
    return (
        <div className={show && ' shadow p-2 rounded'}>
            <button
                onClick={(e) => setShow(!show)}
                className='btn d-flex align-items-center w-100'>
                <div className='flex-grow-1 text-start'>
                    <BsFillPersonFill className='me-2' />{text}
                </div>
                <div className={`flex-shrink-1 ${!show && 'd-none'}`}>
                    <XCircle />
                </div>
            </button>
            {
                status ? <div className='text-center p-1'>{status}</div> :
                    show && user ?
                        <div className='d-flex flex-column'>
                            <button onClick={() => _handleSelectType('Current Item')} className='btn btn-xs text-start'><Clipboard /> Current Item</button>
                            <button onClick={() => _handleSelectType('Entire List')} className='btn btn-xs text-start'><Clipboard2 /> Entire List</button>
                        </div>
                        : show &&
                        <div className='px-3'>
                            <p>{description}</p>
                            <div>
                                <SearchComponent
                                    focusSearch={true}
                                    defaultValue={user}
                                    onSelected={_handleSelectUser}
                                    details={{
                                        data: users,
                                        options: {
                                            reRefKey: 'id',
                                            template: [{
                                                refKey: 'firstName',
                                                descr: '',
                                            }, {
                                                refKey: 'lastName',
                                                descr: '',
                                            }]
                                        }
                                    }} />
                            </div>
                        </div>
            }
        </div>
    )
}

const AssignmentsPanel = ({ onUpdate, assignmentId, item, details }) => {
    //t=== 'Current Item'
    //t=== 'Entire List'
    console.log('------', item, details)
    return (<>
        <UsersWorkingOnTask users={details.usersWorkingOnTask} />

        <AssignmentsPanelRequestAssistance
            onSelect={async (u, t) =>
                t === 'Entire List' ?
                    ToDoListWorkFlows.RequestAssistanceList(assignmentId, item.taskItem.id, u).then(() => onUpdate('Assistance request has been sent'))
                    :
                    ToDoListWorkFlows.RequestAssistanceItem(assignmentId, item.taskItem.id, u).then(() => onUpdate('Assistance request has been sent'))
            }
            text={'Request assistance with this task'}
            description={'Select who you would like to request assistance from?'}
        />
        <AssignmentsPanelRequestAssistance
            onSelect={async (u, t) =>
                t === 'Entire List' ?
                    ToDoListWorkFlows.TransferToList(assignmentId, item.taskItem.id, u).then(() => onUpdate('Item has been re-Assigned'))
                    :
                    ToDoListWorkFlows.TransferToItem(assignmentId, item.taskItem.id, u).then(() => onUpdate('Item has been re-Assigned'))
            }
            text={'Re-assign task'}
            description={'Select who you would like to assign this task to?'}
        />
    </>)
}
import React, { useEffect, useState } from 'react';
import WorkFlow from '../../../../../libs/api/services/WorkFlow';
import CalendarEventStatus from '../../components/EventStatus/CalendarEventStatus';
import CelendarTypeSelectionComponent from '../../facility/components/EventDetails/CelendarTypeSelectionComponent';

export default function EventTriggerCalendarEventStatusChanged({ link, type, values, eventId, filterTrigger }) {
    const PROPERTY_TYPE = 'calendar_status_change';
    const [options, setOptions] = useState({ eventTypes: [], calendars: {}, status: { from: '', to: '' } })
    const [availableCalendars, setAvailableCalendars] = useState([])

    useEffect(() => { _loadProperties() }, [])
    useEffect(() => { _handleSetProperty(options) }, [options])

    const _loadProperties = () => {
        try {
            WorkFlow
                .GetTriggerPropertyByType(typeof values.event === 'object' ? values.event.id : JSON.parse(values.event).id, link[0].id, PROPERTY_TYPE)
                .then((r) => {
                    setOptions(JSON.parse(r.value))
                }).catch((e) => { /* Doesnt exist yet */ })
        } catch (e) {
            // Not yet linked
        }
    }

    const _handleSetProperty = (v) => {
        try {
            WorkFlow
                .SetTriggerProperty(typeof values.event === 'object' ? values.event.id : JSON.parse(values.event).id, link[0].id, {
                    property: PROPERTY_TYPE,//'questionnaire_complete',
                    value: JSON.stringify(v),
                })
                .then((r) => {
                    // Log as property
                    // setLinked(_linked)
                })
        } catch (E) { }
    }

    const _handleSelectEvents = (c) => {
        setAvailableCalendars(c)
        setOptions({ ...options, eventTypes: c })

    }
    const _handleSelectCalendars = (c) => {
        // setAvailableCalendars(c)
        setOptions({ ...options, calendars: c })
    }
    const _handleSelectStatus = (f, c) => {
        // setAvailableCalendars(c)
        var _status = options.status
        _status[f] = c
        setOptions({ ...options, status: _status })
    }
    return (
        <div className=''>
            <div>
                <div className='row g-2'>
                    {/* <div className='col-4'>
                        <div className='bg-light h-100 rounded p-2'>
                            <b className='text-dark'>For event type</b>
                            <CalendarSelectorComponent
                                filterTrigger={filterTrigger}
                                onSelected={(r) => _handleSelectEvents(r.availableCalendars)} />
                        </div>
                    </div> */}
                    <div className='col-6'>
                        <div className='bg-light rounded p-2'>
                            <b className='text-dark'>On calendar</b>
                            <CelendarTypeSelectionComponent defaultValue={options.calendars} onSelected={(c) => _handleSelectCalendars(c)} filterAvailable={availableCalendars} />
                        </div>
                    </div>
                    <div className='col-6'>
                        <div className='bg-light h-100 rounded p-2'>
                            <div>
                                <b className='text-dark'>From Status</b>
                                <CalendarEventStatus
                                    onChange={(e) => { _handleSelectStatus('from', e) }}
                                    defaultValue={options.status.from} />
                            </div>
                            <div>
                                <b className='text-dark'>To Status</b>
                                <CalendarEventStatus
                                    onChange={(e) => { _handleSelectStatus('to', e) }}
                                    defaultValue={options.status.to} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

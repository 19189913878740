import WorkFlow from "../../../../../../../../libs/api/services/WorkFlow"

export default class WFEventProviderFunctions {
    static loadEvents = async (category) =>
        await WorkFlow.ListCategoryEvents(category)
    static createEvent = async (GroupID, name) =>
        await WorkFlow.UpdateEvent({
            group: { id: GroupID },
            name: name
        })
}

import React from 'react'
import DOWSelectorComponent from '../../calendar/components/calendar_events/DOWSelectorComponent'

export default function CronDayOptions({ properties, setProperties }) {
    return (
        <>
            <div className='col-3'>
                <select
                    defaultValue={properties && properties.dayOccourance}
                    onChange={(e) => setProperties({ ...properties, dayOccourance: e.target.value })
                    }
                    className='form-control form-control-sm'>
                    <option></option>
                    <option selected={properties.dayOccourance === 'Every Day'}>Every Day</option>
                    <option selected={properties.dayOccourance === 'Specific Day'}>Specific Day</option>
                </select>
            </div >
            {
                properties.dayOccourance === 'Specific Day'
                    ?
                    <div className='col-3'>
                        <select
                            defaultValue={properties && properties.dayType}
                            onChange={(e) => setProperties({ ...properties, dayType: e.target.value })}
                            className='form-control form-control-sm'><option></option>
                            <option selected={properties.dayType === 'Day of month'}>Day of month</option>
                            <option selected={properties.dayType === 'Day of week'}>Day of week</option>
                            <option selected={properties.dayType === 'First day of week'}>First day of week</option>
                            <option selected={properties.dayType === 'Last day of week'}>Last day of week</option>
                        </select>
                    </div>
                    :
                    <></>
            }
            {

                <div className='col-3'>
                    {
                        (
                            properties.dayOccourance === 'Specific Day' && (
                                properties.dayType === 'Day of week' ||
                                properties.dayType === 'First day of week' ||
                                properties.dayType === 'Last day of week'
                            )
                        )
                            ?
                            <DOWSelectorComponent
                                defaultValue={properties && properties.dayWhen}
                                onChange={(v) => setProperties({ ...properties, dayWhen: v[0] })} />
                            :
                            properties.dayType === 'Day of month' ?
                                <div className='col-3'>
                                    <input
                                        defaultValue={properties && properties.dayWhen}
                                        onChange={(e) => setProperties({ ...properties, dayWhen: e.target.value })}
                                        className='flex-grow-1 form-control form-control-sm' type='number' />
                                </div>
                                : <></>
                    }
                </div>
            }
        </>
    )
}

import React, { useCallback, useEffect, useState } from 'react'
import { TableInterface } from './interfaces/TableInterface'
import { TableRowInterface } from './interfaces/TableRowInterface'
import TableComponent from './TableComponent'
import { ArrowLeft, ArrowRight } from 'react-bootstrap-icons'

type Props = {
  name: string
  onChange: (name: string, value: any) => void
  onLoadData: (name: string) => void
  variables: Map<string, any>
}

export default function DynamicTableComponent(props: Props) {
  const [table, setTable] = useState<TableInterface | undefined>()
  const [count, setCount] = useState<number>(0)
  const [page, setPage] = useState<number>(1)
  const [limit, setLimit] = useState<number>(25)
  // @ts-ignore : Will implement later
  const _hadleTableChange = (name: string, value: any) => {}

  const _castResult = (data: any) => {
    const _table: TableInterface = { rows: [] }
    setCount(Object.keys(data).includes('count') ? data.count : data.results.length)
    for (const _results of data!.results) {
      const _row: TableRowInterface = { columns: [] }
      for (const val of Object.keys(_results)) _row.columns.push({ feild: { format: 'string', value: _results[val] } })
      _table.rows.push(_row)
    }
    return _table
  }

  const load = useCallback(async () => {
    const data: any = await props.onLoadData(props.name)
    // if (data) setTable(SpreadsheetFunctions.csvToTable(new FileFunctions().ReadMimeFile(data.fileData).contents))
    if (data) setTable(_castResult(data))
  }, [props])

  useEffect(() => {
    load()
  }, [load, props.variables])

  const _setLimits = (v: any) => {
    setLimit(v)
    props.onChange(props.name + '>limit', v)
  }

  const _limits = () => {
    return (
      <select onChange={(e) => _setLimits(e.target.value)} style={{ width: 'auto' }}>
        {[10, 25, 50, 100, 500].map((l) => (
          <option key={l} value={l} selected={l === limit}>
            {l}
          </option>
        ))}
      </select>
    )
  }
  const _page = (_page: number) => {
    props.onChange(props.name + '>page', _page)
    setPage(_page)
  }

  return (
    <div>
      <div className='responsive-table-filter pt-2 d-flex flex-row justify-content-between align-items-baseline'>
        <div className='col-auto'>
          <button
            className='btn col-auto'
            onClick={() => {
              page > 1 ? _page(page - 1) : _page(page)
            }}
          >
            <ArrowLeft />
          </button>
        </div>
        <div className='col-auto'>
          <div className='row d-flex align-items-center'>
            Showing {(page - 1) * limit + page} to{' '}
            {page * limit + page > count ? page * limit + page - count : page * limit + page} of {count} results{' '}
          </div>
          <div className='row d-flex align-items-center justify-content-evenly'>{_limits()}</div>
        </div>
        <div className='col-auto'>
          <button
            onClick={() => {
              page < count / limit ? _page(page + 1) : _page(page)
            }}
            className='btn col-auto'
          >
            <ArrowRight />
          </button>
        </div>
      </div>
      <TableComponent
        key={props.name.concat('Table')}
        name={props.name.concat('Table')}
        onChange={_hadleTableChange}
        data={table}
      />
    </div>
  )
}

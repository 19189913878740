import React, { useContext, useState } from 'react'
import LoaderComponent from '../../../../components/loader/LoaderComponent'
import WorkFlow from '../../../../libs/api/services/WorkFlow'
import CommonFunctionsContext from '../../../common/CommonFunctionsProvider'
import WFCategoryProviderFunctions from './facility/wizard/Commons/ProviderHelpers/WFCategoryProviderFunctions'
import WFEventProviderFunctions from './facility/wizard/Commons/ProviderHelpers/WFEventProviderFunctions'
const WorkFlowConfigurationContext = React.createContext()

export const WorkFlowConfigurationProvider = ({ children }) => {
    const { showError } = useContext(CommonFunctionsContext)
    const [loading, setLoading] = useState(false)
    const [tasks, setTasks] = useState([])
    const [state, setState] = useState({
        categories: [],
        category: undefined,
        event: undefined,
        events: [],
        triggers: [],
        // tasks: []
    })
    const _startLoadingActivity = async (f) => {
        setLoading(true)
        var res = await f()
        setLoading(false)
        return res
    }
    /*
     Category
     */
    const loadCategories = async () =>
        _startLoadingActivity(() => WFCategoryProviderFunctions.loadCategories()
            .then((r) => setState({ ...state, categories: r, category: undefined, event: undefined }))
            .catch((e) => showError(e)))

    const addCategory = (res, v) =>
        WFCategoryProviderFunctions.createCategory(v)
            .then((r) => setState({ ...state, categories: [...state.categories, r], category: { object: r } }))
            .catch((e) => showError(e))

    const deleteCategory = (res) =>
        WFCategoryProviderFunctions.removeCategory(res)
            .then((r) => {
                var _cats = state.categories
                _cats.splice(_cats.indexOf(_cats.find((i) => i.id === res.object.id)), 1)
                setState({ ...state, categories: [..._cats], category: undefined, event: undefined })
            })
            .catch((e) => showError(e))

    const renameCategory = (res, name) =>
        WFCategoryProviderFunctions.renameCategory(res, name)
            .then((r) => {
                var _cats = state.categories
                _cats.find((i) => i.id === res.object.id).name = name
                setState({ ...state, categories: [..._cats] })
            })
            .catch((e) => showError(e))

    const selectCategory = (res) =>
        setState({ ...state, category: res })

    /*
     Events
     */
    const loadEvents = () =>
        _startLoadingActivity(() => WFEventProviderFunctions.loadEvents(state.category.object.id)
            .then((r) => setState({ ...state, events: r, event: undefined }))
            .catch((e) => showError(e)))

    const setEvent = (e) => setState({ ...state, event: e, triggers: [] })
    const createEvent = (Name) =>
        _startLoadingActivity(() => WFEventProviderFunctions.createEvent(state.category.object.id, Name)
            .then((r) => loadEvents())
            .catch((e) => showError(e)))

    /*
    Triggers
    */
    const loadTriggers = async () => _startLoadingActivity(async () => {
        if (!state.event) return showError('Please select an event to manage')
        var res = []
        for (const type of await WorkFlow.ListTriggerTypesLinked(state.event.id)) {
            var r = res.find(r => r.category === type.type.group.group)
            if (r) {
                r.triggers.push({
                    ...type.type,
                    link: type.trigger ? type.trigger : []
                })
            } else {
                res.push({
                    category: type.type.group.group, triggers: [{
                        ...type.type,
                        link: type.trigger ? type.trigger : []
                    }]
                })
            }
        }

        setState({
            ...state,
            triggers: res
        })
    })

    const toggleTriggerActive = async (tid) =>
        await WorkFlow
            .ToggleEventTaskType(state.event.id, tid)
            .then((r) => {
                // To Do update toggled Id 
                return r
            })

    const saveProperties = (PROPERTY_TYPE, link, v) => {
        try {
            v && WorkFlow
                .SetTriggerProperty(typeof state.event === 'object' ? state.event.id : JSON.parse(state.event).id, link[0].id, {
                    property: PROPERTY_TYPE,//'questionnaire_complete',
                    value: JSON.stringify(v),
                }).catch((e) => showError(e))
        } catch (e) { }
    }
    const loadProperties = async (PROPERTY_TYPE, link, defaults) =>
        _startLoadingActivity(async () => {
            try {
                const r = await WorkFlow
                    .GetTriggerPropertyByType(typeof state.event === 'object' ? state.event.id : JSON.parse(state.event).id, link[0].id, PROPERTY_TYPE)
                    .catch((e) => { /* Doesnt exist yet */ })
                return JSON.parse(r.value)
            } catch (e) {
                // Not yet linked
            }
            return defaults
        })


    /*
    Tasks
    */
    const loadTasks = async (eventId) => {
        setTasks(await WorkFlow
            .ListTask(eventId ? eventId : state.event.id)
        )
        if (eventId) setState({ ...state, event: { id: eventId } })
    }

    const removeTask = async (id) =>
        WorkFlow
            .RemoveTask(id)
            .then(() => loadTasks())

    const addTask = async (taskName, taskType) =>
        await WorkFlow
            .SetWorkFlowEventTaskProperty(state.event.id, 'Task Details', {
                taskName: taskName,
                taskType: taskType,
                properties: [{
                    value: JSON.stringify({})
                }]
            }).catch((e) => showError(e))

    const setTaskProperty = async (Properties, TaskName, Task, Property, Value) => {
        if (!Value) return
        const updatedTask = await WorkFlow
            .SetWorkFlowEventTaskProperty(state.event.id, Property, {
                id: Task.id,
                taskName: TaskName,//_handleSetName(P),
                taskType: Task.taskType,
                properties: [{
                    value: JSON.stringify(Value)
                }]
            })
            .catch((e) => showError(e))

        // Find event task by id
        // find it's properties with the property name
        // Set the Properties value to Value
        // const _tasks = state.event.tasks
        var _tasks = [...tasks]
        if (updatedTask) {
            _tasks[_tasks.indexOf(_tasks.find((t) => t.id === Task.id))] = updatedTask
        }

        if (_tasks) {
            setState({
                ...state,
                event: { ...state.event, tasks: _tasks },
                tasks: _tasks
            })
            setTasks(_tasks)
        }
        return updatedTask
    }

    // Currently these values are from cache at run time
    // Call is preped to allow for API Update at a later stage
    // if need be
    const loadTaskProperties = (Task, Property) => {
        var _prop = {}
        try {
            for (const v of Task.properties.filter(f => f.type.type === Property)) {
                const val = JSON.parse(v.value)
                for (const kv of Object.keys(val))
                    try { _prop[kv] = val[kv] } catch (e) { }
            }
        } catch (e) {
            console.error('WorkFlowConfigurationProvider:loadTaskProperties', e, Task.properties)
        }
        return _prop
    }

    const allocateMessagingTask = async (EventID, TemplateID) => {
        await WorkFlow.allocateMessagingTask(EventID, TemplateID)
        await loadTasks()
    }

    return <WorkFlowConfigurationContext.Provider value={{
        // Commons
        showError,
        // Categories
        loadCategories,
        addCategory,
        deleteCategory,
        renameCategory,
        selectCategory,
        categories: state.categories,
        category: state.category,
        // EVents
        loadEvents,
        setEvent,
        events: state.events,
        event: state.event,
        createEvent,
        // Triggers
        loadTriggers,
        triggers: state.triggers,
        toggleTriggerActive,
        saveProperties,
        loadProperties,
        // Tasks
        loadTasks,
        removeTask,
        addTask,
        tasks: tasks,
        setTaskProperty,
        loadTaskProperties,
        props: (Task, Property) => loadTaskProperties(Task, Property),
        loading,
        allocateMessagingTask
    }}>
        {loading && <LoaderComponent />}
        {children}
    </WorkFlowConfigurationContext.Provider>
}

export default WorkFlowConfigurationContext
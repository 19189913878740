import React, { useEffect } from 'react'
import { Clipboard2Pulse, Person } from 'react-bootstrap-icons'
import PatientearchBar from '../../../../search/PatientearchBar'

export default function QuestionnaireAssignmentRecipients({ title, description, navigation, errors, setValue, values }) {

  useEffect(() => {
    title('Questionnaire Recipient')
    description('Select who you would like to send the questionnaire to')
    // Load Navigation if session started
    // if (Object.keys(values).includes('templateId'))
    navigation([
      { text: 'Back', type: 'Back', action: async () => { } },
      { text: 'Next', type: 'Next', action: async () => { } },
    ])
  }, [])

  return (
    <div>
      <div
        style={{ cursor: 'pointer' }}
        className='d-flex flex-row justify-content-evenly align-items-evenly'>
        <div className='h-100 d-flex flex-column align-items-center m-3'>
          <div
            // onClick={(e) => _handleSetAudienceType(t.id)}
            className={'w-100 m-3 p-2 rounded '
              // .concat(audienceType === t.id ? 'bg-primary-container shadow ' : 'bg-secondary-container')
            }
          >
            <div>
              <Person size={52} />
            </div>
            <div>
              <h3>Patient</h3>
            </div>
            <div>
              {/* <p>{t.description}</p> */}
            </div>
          </div>
        </div>

      </div>
      <PatientearchBar searchOnly={true} />
    </div>
  )
}

import React, { useRef, useState } from 'react'
import { useEffect } from 'react'
import { Clock, EyeSlashFill, Person, TrashFill } from 'react-bootstrap-icons'
import PatientHistoryContext from '../history/PatientHistoryProvider'
import { useContext } from 'react'
import ToastDialog from '../../../../components/dialogs/toast_dialog/ToastDialog'

export default function PatientDMSNotes({ PatientHistoryId, onRemoveNote }) {
    const { LIST_NOTES, REMOVE_NOTE, notes } = useContext(PatientHistoryContext)
    const [confirmDelete, setConfirmDelete] = useState({ title: '', description: '', show: false, action: undefined })
    const [status, setStatus] = useState({})

    useEffect(() => {
        LIST_NOTES(PatientHistoryId)
    }, [])

    const _handleOnDeleteNote = (PatientHistoryId, noteId) => {
        if (onRemoveNote) {
            onRemoveNote(PatientHistoryId, noteId)
        } else {
            setConfirmDelete({
                title: 'Remove Note?', description: 'Are you sure you would like to remove note?', show: true,
                action: () => REMOVE_NOTE(PatientHistoryId, noteId)
                    .then(() => setStatus({ message: 'Note removed', key: new Date(), time: 3 }))
            })
        }
    }

    return (
        < >
            <ToastDialog
                onTimeOut={() => setStatus({ message: '' })}
                {...status}
                time={8} />
            {

                confirmDelete.show === true
                    ?
                    <div
                        className='position-absolute d-flex align-items-center justify-content-center'
                        style={{ left: 0, right: 0, top: 0, bottom: 0, zIndex: 100 }}
                    >
                        <div
                            className='bg-secondary-container rounded shadow p-3 text-center'
                            style={{ maxWidth: '450px' }}
                        >
                            <h3>{confirmDelete.title}</h3>
                            <p>{confirmDelete.description}</p>
                            <button
                                onClick={(e) => { confirmDelete.action(); setConfirmDelete({ ...confirmDelete, show: false }); }}
                                className='btn'>Yes</button>
                            <button
                                onClick={(e) => { setConfirmDelete({ ...confirmDelete, show: false }) }}
                                className='btn'>No</button>
                        </div>
                    </div>
                    :
                    <></>
            }
            {
                notes.map((note) =>
                    <div className='row border-bottom py-2'>
                        <div className='text-start col-10'>
                            {
                                note.isPrivate ? <div><EyeSlashFill /> <i>Private</i> </div> : <></>
                            }
                            <div><Clock className='me-2' /><small>{note.createdDate.substr(note.createdDate, note.createdDate.indexOf('.'))}</small></div>
                            <div><Person className='me-2' />{note.createdUser ? note.createdUser.firstName + ' ' + note.createdUser.lastName : ''} </div>
                            <div className='text-center'>{note.note}</div>
                        </div>
                        {/* <div className='btn col-2'>{note.isPrivate ? 'Private' : ''}</div> */}
                        <div onClick={(e) => _handleOnDeleteNote(PatientHistoryId, note.id)} className='btn col-1' style={{ backgroundColor: 'transparent' }}><TrashFill size={22} /></div>
                    </div>
                )
            }

        </>
    )
}

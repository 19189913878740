import { faPersonCirclePlus } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useContext, useEffect, useRef, useState } from 'react'
import SearchComponent from '../../../../../components/search/SearchComponent'
import Authentication from '../../../../../libs/api/services/Authentication'
import User from '../../../../../libs/api/services/User'
import CommonFunctionsContext from '../../../../common/CommonFunctionsProvider'
import PatientProfileManagement from '../../../patient/management/PatientProfileManagement'
import { PatientProvider } from '../../../patient/management/PatientProvider'
import CalendarContext from '../CalendarProvider'


export default function CalendarUserComponent({ type, onSelected, defaultValue, disabled }) {
  const { filters, patient, setPatient } = useContext(CalendarContext)
  const { createModal } = useContext(CommonFunctionsContext)
  const editMode = useRef(disabled)
  const _handleFindDefault = () => {
    // if (!defaultValue) {
    //   if (type === 'clinician' && Authentication.getFacilityRole() === "Clinician") {
    //     return { id: parseInt(Authentication.UserId()) }
    //   }
    // }
  }
  const [users, setUsers] = useState([])
  const [user, setUser] = useState(defaultValue ? defaultValue : _handleFindDefault())

  useEffect(() => { if (user) onSelected(user) }, [user])

  useEffect(() => { _loadUsers() }, [])

  const _handleNewPatient = async (p) => {
    console.log(p)
    setPatient(p);
    await _loadUsers()
    setUser(p.userProfile)
    // editMode.current = true
  }


  const _loadUsers = async () =>
    User
      .GetAccessableUsersByType(type)
      .then(async (r) => {
        setUsers(r)
        const _patient = await patient
        if (type === 'patient' && _patient) {
          if (parseInt(Authentication.UserId()) !== parseInt(_patient)) {
            setUser(r.find(d => d.id === parseInt(_patient)))
            editMode.current = true
          }

        } else if (type === 'clinician') {
          if (!defaultValue) {
            if (type === 'clinician' && Authentication.getFacilityRole() === "Clinician") {
              setUser(r.find(d => d.id === parseInt(Authentication.UserId())))
              editMode.current = true
            }
          }

        }
      })


  if (user && (type === 'clinician' || type === 'patient') && editMode.current)
    return <div>{user.firstName} {user.lastName}</div>

  return (
    <SearchComponent
      focusSearch={true}
      defaultValue={user}
      onSelected={setUser}
      details={{
        data: users,
        options: {
          returns: 'object',
          reRefKey: 'id',
          template: [{
            refKey: 'firstName',
            descr: '',
          }, {
            refKey: 'lastName',
            descr: '',
          }],
          onAdd: type === 'patient' ? {
            action: (t) => {
              createModal(
                <PatientProvider><PatientProfileManagement className='text-dark' onSaved={(p) => _handleNewPatient(p)} /></PatientProvider>,
                'New Patient Details',
                <FontAwesomeIcon icon={faPersonCirclePlus} size='2x' />
              )
            },
            text: 'New Patient'
          } : undefined
        }
      }} />
  )
}

import React, { useEffect, useState } from 'react'
import { Clipboard2Check, Clipboard2CheckFill, Link } from 'react-bootstrap-icons'
import { NavLink } from 'react-router-dom'
import Authentication from '../../../../libs/api/services/Authentication'
import Dashboards from '../../../../libs/api/services/Dashboards'

export default function DashboardWidgetToDo() {
  const [evntDetails, setEventDetails] = useState()
  useEffect(() => { _getUnreadMessageCount() }, [])
  const _getUnreadMessageCount = async () => {
    // No unread
    setEventDetails(await Dashboards.ToDoListStatus().catch((e) => { console.error(e) }))
  }

  return (
    <div className='col-lg-4 col-md-12 dashboard-card'>
      <div className='text-center bg-secondary-container p-3 rounded h-100 d-flex flex-column'>
        <div className='flex-shrink-1'>
          <h3><Clipboard2CheckFill className='me-2' />To Do List</h3>
        </div>
        <div className='flex-grow-1 d-flex flex-column justify-content-center align-items-center '>
          {evntDetails ? evntDetails.status : 'Checking your to do list'}
        </div>
        <div className='flex-shrink-1'>
          <NavLink
            tag={Link}
            to={'/' + Authentication.getFacilityRole() + '/todo'
            }
            className={'text-light m-1'}
            style={{ cursor: 'pointer' }}><button className='btn btn-sm'><Clipboard2Check className='me-2' />Go to my To Do list</button></NavLink>
        </div>
      </div>
    </div>
  )
}

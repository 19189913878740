import React, { useEffect, useState } from 'react'
import WorkFlow from '../../../../../../../../../libs/api/services/WorkFlow'

export default function WorkFlowMHDWidget({ eventId, link, propName }) {
    const [time, setTime] = useState({ hours: 0, mins: 0, days: 0 })
    useEffect(() => { _loadTime() }, [])
    const _loadTime = () => {
        try {
            WorkFlow
                .GetTriggerPropertyByType(eventId, link[0].id, propName)
                .then((r) => {
                    setTime(JSON.parse(r.value))
                }).catch((e) => { /* Doesnt exist yet */ })
        } catch (e) {
            // Not yet linked 
        }
    }

    const _setTimeProperty = (p, v) => {
        if (p === 'mins' && v > 59) return false;
        if (p === 'hours' && v > 23) return false;
        var _time = time
        time[p] = v
        WorkFlow.SetTriggerProperty(eventId, link[0].id, {
            property: propName,
            value: JSON.stringify(_time),
        })
        setTime({..._time})
    }

    return (
        <div className='d-flex'>
            <div className='text-center w-100'>
                <b>Minutes</b>
                <input value={time.mins} onChange={(e) => { return _setTimeProperty('mins', e.target.value) }} className='form-control form-control-sm' min="0" max="59" type='Number' placeholder='minuts' />
            </div>
            <div className='text-center w-100'>
                <b>Hours</b>
                <input value={time.hours} onChange={(e) => { _setTimeProperty('hours', e.target.value) }} className='form-control form-control-sm' type='Number' placeholder='hours' />
            </div>
            <div className='text-center w-100'>
                <b>Days</b>
                <input value={time.days} onChange={(e) => { _setTimeProperty('days', e.target.value) }} className='form-control form-control-sm' type='Number' placeholder='days' />
            </div>
        </div>
    )
}

import React, { useEffect, useState } from 'react';
import Messaging from '../../../../../../../libs/api/services/Messaging';
import MessagesLinkLineItem from './MessagesLinkLineItem';
import MessagesSearchBar from './MessagesSearchBar';

export default function MessagesContainer({ onLink, messages }) {
    const [allMessages, setAllMessages] = useState([]);
    const [filters, setFilters] = useState({ search: '', page: 1, dateFrom: undefined, dateTo: undefined, messageType: '' });


    useEffect(() => { _loadMessages() }, [])

    const _loadMessages = (force) => {
        Messaging
            .GetMessages(filters)
            .then((r) => {
                if (filters.page !== 1 && !force) {
                    setAllMessages([...allMessages, ...r.messages])
                }
                else {
                    setAllMessages([...r.messages])
                }
            }).catch((e) => {
            })
    }
    return (
        <div className='text-dark'>
            <MessagesSearchBar onFilter={setFilters} />
            <div className='pt-5'>
                {
                    allMessages.map((m) => {
                        // m.messageBody = m.messageBody.replaceAll('\n', "<br />")
                        var ret = (
                            <>
                                <MessagesLinkLineItem
                                    onLink={onLink}
                                    isActive={messages.find(x => x.message.id === m.id) !== undefined}
                                    message={m} />
                            </>
                        )
                        return ret;
                    })
                }
            </div>
        </div>
    )
}

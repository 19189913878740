import { faSpinner } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useContext, useEffect, useState } from 'react'
import { CloudUploadFill, EyeFill, VectorPen } from 'react-bootstrap-icons'
import ConfirmDialog from '../../../components/dialogs/ConfirmDialog'
import FileUpload, { decodeB64String, getBase64 } from '../../../components/ui/files/FileUpload'
import ToggleButton from '../../../components/ui/toggle_button/ToggleButton'
import TNC from '../../../libs/api/services/TNC'
import CommonFunctionsContext from '../../common/CommonFunctionsProvider'

export default function ManageTerms({ facility }) {
    const { showError, createModal } = useContext(CommonFunctionsContext)
    const [mode, setMode] = useState()
    const [tncs, setTncs] = useState()
    const [current, setCurrent] = useState()

    const _handleSelectFile = (e) => {
        // console.log(e[0])
        /**
         * name
         * lastModified
         * lastModifiedDate
         * size
         * type
         */
        // Validate Extenstion

        // Submit to API
        if (e[0].type !== 'application/pdf')
            return showError('Terms and conditions need to be in PDF format')

        setMode('Uploading')

        getBase64(e[0])
            .then((result) => {
                const details = decodeB64String(result)

                TNC.UploadTermsAndConditionsForSystem({
                    data: details.data,
                    fileName: e[0].name,
                    mimeType: details.mime,
                    facility:
                        facility ?
                            { id: parseInt(facility) }
                            : undefined
                }).catch(e => showError(e)).then((r) => {
                    setCurrent(r)
                    setMode('SetDefault')
                    // _handleSeletView()
                })
            })
    }

    const setDefaultTNC = (tnc) => {
        //Default
        setMode('Uploading')
        TNC.setDefaultTNC(tnc).catch(e => showError(e))
            .then(() => _handleSeletView())
    }

    const _handleSeletView = async (e) => {
        // Load tnc
        const t = facility ?
            await TNC.GetTermsAndConditionsForFacility(facility).catch(e => showError(e))
            : await TNC.GetTermsAndConditions().catch(e => showError(e))
        if (t)
            setTncs(t)
        // Set Mode
        setMode('View')
        // setMode('SetDefault')

        // Convert the Base64 string back into binary data
        // const binaryData = atob(t.data);

        // Create a Blob from the binary data
        // const blob = new Blob([binaryData], { type: 'application/pdf' });

        // Generate a Blob-URI
        // const url = URL.createObjectURL(blob);

        // Open a new tab and set the iframe's src to the Blob-URI
        // setTnc(url)
        // const pdfWindow = window.open(url, '_blank');
    }

    // useEffect(() => {
    //     const _handleGetTnC = async () =>
    //         console.log()
    //     _handleGetTnC()
    // }, [])
    useEffect(() => { _handleSeletView() }, [])
    return (
        <div className=" h-100 d-flex flex-column text-center d-flex">
            <div className="head flex-shrink-1">
                <h1>Terms and conditions</h1>
                <div className="error-details"></div>
            </div>
            <div className=' flex-grow-1'>
                <div className='d-flex flex-shrink-1'>
                    <button className={`w-50 btn ${mode === 'View' ? 'bg-primary-container' : ''}`} onClick={_handleSeletView}>
                        <EyeFill /> View
                    </button>
                    <button className={`w-50 btn ${mode === 'Upload' ? 'bg-primary-container' : ''}`} onClick={() => setMode('Upload')}>
                        <CloudUploadFill /> Upload
                    </button>
                    <button className={`w-50 btn ${mode === 'Version' ? 'bg-primary-container' : ''}`} onClick={() => setMode('Version')}>
                        <VectorPen /> Version
                    </button>
                </div>
                <div className='d-flex flex-column flex-grow-1 h-100 p-5'>
                    {mode === 'View' && tncs && tncs.length > 0 &&
                        // <object className='w-100 h-75' data="data:application/pdf;base64," >
                        <embed
                            className='flex-grow-1'
                            src={`data:application/pdf;base64,${tncs.find(x => x.isDeleted === false).data}`} type='application/pdf' />
                        // </object>
                    }
                    {
                        mode === 'Version' && tncs && tncs.length > 0 &&
                        <>
                            <table>
                                <thead>
                                    <tr>
                                        <th>Name</th>
                                        <th>Date</th>
                                        <th>Preview</th>
                                        <th>Current Version</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {tncs.map((t) => <tr key={`TNCV-${t.id}`}>
                                        <td>{`${t.fileName}`}</td>
                                        <td>{`${t.createdDate}`}</td>
                                        <td><button
                                            className='btn'
                                            onClick={() => {
                                                createModal(<embed
                                                    className='w-100 h-100'
                                                    src={`data:application/pdf;base64,${t.data}`} type='application/pdf' />, t.fileName, null)
                                            }}
                                        ><EyeFill /></button></td>
                                        <td><ToggleButton onToggle={(e) => e && setDefaultTNC(t)} style={'tick'} defaultValue={!t.isDeleted} /></td>
                                    </tr>)}
                                </tbody>
                            </table>
                        </>
                    }
                    {mode === 'Upload' && <FileUpload onFilesSelected={_handleSelectFile} />}
                    {mode === 'SetDefault' &&
                        <ConfirmDialog
                            open={true}
                            title={'Set as current Terms and Conditions'}
                            onConfirm={() => setDefaultTNC(current)}
                            onCancel={() => setMode('View')}
                        >
                            Would you like to set as default?
                        </ConfirmDialog>

                    }
                    {mode === 'Uploading' && <div className='bg-secondary-container p-3 rounded shadow'><FontAwesomeIcon icon={faSpinner} spin /> Updating terms and conditions</div>}
                </div>

            </div>
        </div>
    )
}

import React, { Component } from 'react';
import Authentication from '../../libs/api/services/Authentication';

export default class LoginLayoutCenter extends Component {

    render() {
        return (
            <>
                {/* <div className="row d-flex h-100 d-md-flex flex-md-column" style={(this.props.direction && this.props.direction === "row") ? {flexDirection:"row"} : {flexDirection:"column"}}> */}
                <div className={(this.props.direction && this.props.direction === "row") ? "row d-flex h-100 d-md-flex flex-sm-column" : "row h-100 d-flex d-md-flex flex-md-column"} style={{ flexFlow: "column" }}>
                    <div className='row flex-shrink-1 align-items-center'>
                        <div className='col-auto m-3'>
                            <img alt="logo" src='style/logo' height={137} />
                        </div>
                        <div className='flex-grow-1 col-auto text-center justify-content-center'>
                            <h1 className='title'>{this.props.title}</h1>
                        </div>
                    </div>

                    <div className='row flex-grow-1'>
                        <div className="col-12 d-flex justify-content-center h-100 px-0 px-md-5">
                            <div className={(this.props.direction && this.props.direction === "row") ? 'bg-content-area-login flex-column d-flex flex-column justify-content-evenly  m-3 p-4 shadow shadow-2 rounded rounded-5 mb-5' : 'bg-content-area-login flex-column flex-md-row m-3 shadow shadow-2 rounded rounded-5 row mb-5'}>
                                {this.props.children}
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

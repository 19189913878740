import { EditorState, Modifier } from 'draft-js';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import MessageTemple from '../../../../../libs/api/services/MessageTemple';
import './RTEPlaceHolderMenu.css';
export default class RTEPlaceHolderMenu extends Component {
    constructor() {
        super()
        this.state = {
            categories: []
        }
    }
    static propTypes = {
        onChange: PropTypes.func,
        editorState: PropTypes.object,
    }

    state = {
        open: false
    }

    componentDidMount = () => {
        MessageTemple
            .ListVariableCategories()
            .then((r) => {
                this.setState({ categories: r })
                var _orderedItems = []
                var _categories = []
                for (const cat of r) {
                    for (const _field of cat.fields) {
                        if (_field.fieldName.includes(':')) {
                            var _exists = _categories.find(c => c.subcat === _field.fieldName.split(':')[0])
                            if (_exists)
                                _exists.fields.push({ ..._field, fieldDescription: _field.fieldDescription.split(':')[1] })
                            else
                                _categories.push({
                                    subcat: _field.fieldName.split(':')[0],
                                    fields: [{ ..._field, fieldDescription: _field.fieldDescription.split(':')[1] }]
                                })
                        } else {
                            var _exists = _categories.find(c => c.subcat === '')
                            if (_exists)
                                _exists.fields.push(_field)
                            else
                                _categories.push({
                                    subcat: '',
                                    fields: [_field]
                                })
                        }
                    }

                    _orderedItems.push({
                        moduleName: cat.moduleName,
                        fields: [..._categories]
                    });
                    _categories = []
                }

                // this.listItem = r.map(item => (
                this.listItem = _orderedItems.map(item => (
                    <li
                        key={item.id + 'A' + Math.random()}
                        className="placeholder-li-category"
                    >
                        <div className='bg-primary-container text-center p-1 shadow rouned'>
                            <div>
                                {item.moduleName}
                            </div>
                        </div>
                        <ul>
                            {
                                // TODO : Map categotry to sub section
                                // item.fields.map((field) => {
                                item.fields.map((subCat, i) => {
                                    return <li key={`RTESc-${subCat.subcat}-${i}`}>{subCat.subcat === '' ? '' : <div className='bg-secondary-container'>{subCat.subcat}</div>}
                                        <ul >
                                            {
                                                subCat.fields.map((field, i2) =>
                                                    <li
                                                        key={`${field.fieldClass}-${Math.random() * 10}`}
                                                        className="placeholder-li text-start"
                                                        onClick={
                                                            this.addPlaceholder.bind(this, field.fieldClass + '.' + field.fieldName)
                                                        }
                                                    >{field.fieldDescription}</li>
                                                )
                                            }
                                        </ul>
                                    </li>
                                })
                            }
                        </ul>
                    </li>
                ))

            })
    }

    openPlaceholderDropdown = () => this.setState({ open: !this.state.open })

    addPlaceholder = (placeholder) => {
        const { editorState, onChange } = this.props;
        const contentState = Modifier.replaceText(
            editorState.getCurrentContent(),
            editorState.getSelection(),
            "{{" + placeholder + "}}",
            editorState.getCurrentInlineStyle(),
        );
        onChange(EditorState.push(editorState, contentState, 'insert-characters'));
    }

    placeholderOptions = [
        { key: "firstName", value: "{{firstName}}", text: "First Name" },
        { key: "lastName", value: "{{lastName}}", text: "Last name" },
        { key: "company", value: "{{company}}", text: "Company" },
        { key: "address", value: "{{address}}", text: "Address" },
        { key: "zip", value: "{{zip}}", text: "Zip" },
        { key: "city", value: "{{city}}", text: "City" }
    ]
    listItem = []

    render() {
        return (
            <div onClick={this.openPlaceholderDropdown} className="rdw-block-wrapper w-100" aria-label="rdw-block-control">
                <div className="rdw-dropdown-wrapper rdw-block-dropdown" aria-label="rdw-dropdown">
                    <div className="rdw-dropdown-selectedtext" title="Placeholders">
                        <span>Placeholder</span>
                        <div className={`rdw-dropdown-caretto${this.state.open ? "close" : "open"}`}></div>
                    </div>
                    <ul className={`rdw-dropdown-optionwrapper w-100 ${this.state.open ? "" : "placeholder-ul"}`}>
                        {this.listItem}
                    </ul>
                </div>
            </div>
        );
    }
}

import React, { useState } from 'react'

export default function InlineWizardComponent({ className, children }) {
    const [step, setStep] = useState(0)
    function triggerNext() {
        setStep((step >= children.length - 1) ? 0 : step + 1)
    }

    function triggerBack() {
        setStep((step < 1) ? 0 : step - 1)
    }

    function renderSection() {
        var x = 0
        return children.map((child) => {
            if (x++ === step)
                return {
                    ...child,
                    props: {
                        ...child.props,
                        triggerNext: () => triggerNext(),
                        triggerBack: () => triggerBack()
                    }
                }
        })
    }

    return (
        <div className={className}>
            {renderSection()}
        </div>
    )
}

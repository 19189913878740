import { faCalendar, faClock } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useEffect, useRef, useState } from 'react'
import { ClockFill } from 'react-bootstrap-icons'
import { useClickOutside } from '../../../Hooks/useClickOutside'
import './DatePicker.css'
import DatePickerDay from './DatePickerDay'
import DatePickerHour from './DatePickerHour'
import DatePickerMonth from './DatePickerMonth'
import DatePickerYear from './DatePickerYear'

export default function DatePicker({ onChange, defaultValue, className, type, small }) {
    const dateSelectRef = useRef()
    const [show, setShow] = useState(false)
    const [mode, setMode] = useState(type === 'Time' ? 'Hour' : 'Year')
    const [pickerType, setPickerType] = useState(type ? type : 'DateTime')
    useEffect(() => { if (type) setPickerType(type) }, [type])
    /// Date

    const [date, setDate] = useState(defaultValue ? defaultValue : 'Date')
    const [year, setYear] = useState()
    const [month, setMonth] = useState()
    const [day, setDay] = useState()
    const [time, setTime] = useState({
        hour: 0,
        minute: 0,
        second: 0
    })

    useClickOutside(dateSelectRef, () => { setShow(false) })

    useEffect(() => {
        // defaultValue
        //2024/03/18, 02:00:00
        if (defaultValue) {
            setDate(defaultValue.replace(', ', 'T').replaceAll('/', '-'))
            try {
                const date = new Date(defaultValue.replace(', ', 'T').replaceAll('/', '-'))
                setYear(date.getFullYear())
                setMonth(date.getMonth() + 1)
                setDay(date.getDate())
                setTime({
                    hour: date.getHours(),
                    minute: date.getMinutes(),
                    second: date.getSeconds()
                })
                setMode('Day')
            } catch (e) {
                console.error('Invalid Date Format')
            }
        }
    }, [defaultValue])

    function lpad(str, padString, length) {
        if (str === 'undefined')
            return '';
        str = '' + str
        while (str.length < length)
            str = padString + str;
        return str;
    }

    const _handleDateChanged = (year, month, day, hour, minute, second) => {
        //setDate(year + '-' + month + '-' + day + ' ' + time.hour + ':' + time.month + ':' + time.second);
        var _year, _month, _day, _hour, _minute, _second = '';
        var _dateStr = '';
        if (pickerType === 'Date' || pickerType === 'DateTime') {
            if (year)
                _dateStr += year
            if (month)
                _dateStr += '-' + lpad(month, "0", 2)
            if (day)
                _dateStr += '-' + lpad(day, "0", 2)
        }
        if (pickerType === 'DateTime') {
            _dateStr += 'T'
        }
        if (pickerType === 'Time' || pickerType === 'DateTime') {
            if (hour)
                _dateStr += lpad(hour, "0", 2)
            else
                _dateStr += '00'
            if (minute)
                _dateStr += ':' + lpad(minute, "0", 2)
            else
                _dateStr += ':00'
            if (second)
                _dateStr += ':' + lpad(second, "0", 2)
            else
                _dateStr += ':00'
        }
        // setDate(year + '-' + month + '-' + day + ' ' + hour + ':' + minute + ':' + second);
        setDate(_dateStr);
        return _dateStr
    }

    const _handleYearSet = (year) => {
        setYear(year)
        setMode('Month')
        _handleDateChanged(year, month, day, time.hour, time.minute, time.second);
    }
    const _handleMonthSet = (month) => {
        setMonth(month + 1)
        setMode('Day')
        _handleDateChanged();
        _handleDateChanged(year, month + 1, day, time.hour, time.minute, time.second);
        _handleDateChanged(year, month + 1, day, time.hour, time.minute, time.second);
    }
    const _handleDaySet = (day) => {
        setDay(day)
        setMode('Hour')
        _handleDateChanged();
        _handleDateChanged(year, month, day, time.hour, time.minute, time.second);
    }
    const _handleTimeSet = (time) => {
        // setDay(day)
        // setMode('Hour')
        // _handleDateChanged();
        if (!time.hour)
            time.hour = "00"
        if (!time.minute)
            time.minute = "00"
        if (!time.second)
            time.second = "00"
        _handleDateChanged(year, month, day, time.hour, time.minute, time.second);
        setTime(time)
    }
    const _handleClear = (time) => {
        if (pickerType === 'DateTime' || pickerType === 'Date')
            setMode('Year')
        else
            setMode('Hour')
        setYear()
        setMonth()
        setDay()
        setTime({ hour: '0', minute: '0', second: '0' })
        setDate('Date')
    }
    const _handleSetNow = () => {
        var currentTime = new Date()
        var month = currentTime.getMonth() + 1
        var day = currentTime.getDate()
        var year = currentTime.getFullYear()
        var hour = currentTime.getUTCHours()
        var minute = currentTime.getMinutes()
        var second = currentTime.getSeconds()
        setYear(year)
        setMonth(month)
        setDay(day)
        setTime({ hour: hour, minute: minute, second: second })

        _handleDone(_handleDateChanged(year, month, day, hour, minute, second))
        // // write output MM/dd/yyyy
        // document.write(month + "/" + day + "/" + year)
    }
    const _handleDone = (date) => {
        setMode('Year')
        setShow(false)
        if (onChange)
            if (date !== 'Date')
                onChange(date)
            else
                onChange(undefined)
    }

    const _showAboveOrBelow = () => {
        var style = {}
        style['position'] = 'absolute'
        style['minWidth'] = '350px'
        style['maxHeight'] = '450px'
        style['zIndex'] = 100

        /*
            Window height: 958
            Component is at : 907
            offset from bottom: 958 - 907 ~ 51
            Components Height: 400px 
            ~~ if(Component + Components Height > Window height) at the bottom
        */
        if (
            dateSelectRef.current.getBoundingClientRect().bottom + 400 >= document.documentElement.clientHeight
        )
            style['bottom'] = '35px'
        return style
    }
    return (
        <div ref={dateSelectRef} style={{ cursor: 'pointer' }} className={'date-picker position-relative '.concat(className)}>
            <div
                onClick={(e) => setShow(!show)} className={'form-control date-picker-date-field '.concat(className).concat(small ? ' form-control-sm' : '')} style={{ position: 'relative', minWidth: '200px' }}>{date}</div>
            {
                show
                    ?
                    <div

                        className='text-dark rounded shadow date-picker-body ' style={_showAboveOrBelow()}>
                        <div className='text-center'>
                            {
                                (pickerType === 'DateTime' || pickerType === 'Time')
                                    ?
                                    <div style={{ borderTopRightRadius: '10px', borderTopLeftRadius: '10px', }} className='d-flex justify-content-evenly p-2 bg-secondary-container'>
                                        <div onClick={() => setMode('Year')}><FontAwesomeIcon icon={faCalendar} /></div>
                                        <div onClick={() => setMode('Hour')}><FontAwesomeIcon icon={faClock} /></div>
                                    </div>
                                    :
                                    <></>
                            }
                            {
                                mode === 'Year' && (pickerType === 'DateTime' || pickerType === 'Date')
                                    ?
                                    <DatePickerYear onChange={(year) => _handleYearSet(year)} />
                                    :
                                    mode === 'Month' && (pickerType === 'DateTime' || pickerType === 'Date')
                                        ?
                                        <DatePickerMonth onChange={(m) => _handleMonthSet(m)} year={year} onYearClick={(e) => setMode('Year')} />
                                        : mode === 'Day' && (pickerType === 'DateTime' || pickerType === 'Date')
                                            ?
                                            <DatePickerDay onChange={(d) => _handleDaySet(d)} year={year} month={month} onMonthClick={(e) => setMode('Month')} />
                                            :
                                            mode === 'Hour' && (pickerType === 'DateTime' || pickerType === 'Time')
                                                ?
                                                <DatePickerHour
                                                    // onChange={(m) => _handleDaySet(m)}
                                                    year={year}
                                                    month={month}
                                                    day={day}
                                                    time={time}
                                                    onTimeChange={(t) => _handleTimeSet(t)}
                                                    onDateClick={(e) => setMode('Day')}
                                                />
                                                :
                                                <div className='p-3'><b><ClockFill className='me-2' />{date}</b></div>
                            }

                        </div>
                        <div className='m-3 text-center'>
                            <button onClick={(e) => _handleClear()} className='btn'>Clear</button>
                            <button onClick={(e) => _handleSetNow()} className='btn'>Now</button>
                            <button onClick={(e) => _handleDone(date)} className='btn'>Done</button>
                        </div>
                    </div>
                    :
                    <></>
            }
        </div>
    )
}

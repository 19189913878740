import API from "../API";

var Patient = {
    async UpdateCategory(data) {
        var result = await API.process(
            'api/patients/category',
            'PUT',
            data
        ).catch((err) => {
            throw err;
        })

        return result
    },
    async Categories() {
        var result = await API.process(
            'api/patients/categories',
            'GET',
            null
        ).catch((err) => {
            throw err;
        })

        return result
    },
    async LoadFields(categoryId) {
        var result = await API.process(
            `api/patients/categories/${categoryId}/fields`,
            'GET',
            null
        ).catch((err) => {
            throw err;
        })

        return result
    },
    async SaveDetails(c, v) {
        var result = await API.process(
            `api/patients/categories/${c}/field`,
            'PUT',
            v
        ).catch((err) => {
            throw err;
        })

        return result
    },
    async FieldTypes() {
        var result = await API.process(
            `api/patients/detail/types`,
            'GET',
            null
        ).catch((err) => {
            throw err;
        })

        return result
    },
    async RemoveCategory(id) {
        var result = await API.process(
            `api/patients/categories/${id}`,
            'DELETE',
            null
        ).catch((err) => {
            throw err;
        })

        return result
    },
    async RemoveCategoryField(id) {
        var result = await API.process(
            `api/patients/categories/field/${id}`,
            'DELETE',
            null
        ).catch((err) => {
            throw err;
        })

        return result
    },
    async search(data, facilityId) {
        var result = await API.process(
            `api/Patients/search/${facilityId}`,
            'POST',
            data
        ).catch((err) => {
            throw err;
        })

        return result
    },
    async InitNewPatient(facilityId) {
        var result = await API.process(
            'api/Patients/init/' + facilityId,
            'POST',
            {}
        ).catch((err) => {
            throw err;
        })

        return result
    },
    async SetPatientProfileDetail(DetailsTypeId, Value, PatientId) {
        if (!PatientId)
            throw Error('Patient ID has not been specified')
        var result = await API.process(
            'api/Patients/' + PatientId + '/detail',
            'PUT',
            {
                Detail: Value,
                DetailsType: {
                    Id: DetailsTypeId
                }
            }
        ).catch((err) => {
            throw err;
        })

        return result
    },
    async SetPatientProfileDetailBulk(Details, PatientId) {
        if (!PatientId)
            throw Error('Patient ID has not been specified')
        var result = await API.process(
            'api/Patients/' + PatientId + '/details',
            'PUT',
            Details
            // {
            //     details : Details
            // }
        ).catch((err) => {
            throw err;
        })

        return result
    }, async Find(PatientId) {
        if (!PatientId)
            throw Error('Patient ID has not been specified')
        var result = await API.process(
            'api/Patients/patient/' + PatientId,
            'GET',
            null
        ).catch((err) => {
            throw err;
        })

        return result
    }, async ChangeFieldOrder(fieldId, order) {
        var result = await API.process(
            'api/Patients/details/order/' + fieldId + '/' + order,
            'PATCH',
            null
        ).catch((err) => {
            throw err;
        })

        return result
    }, async ChangeCategoryOrder(categoryId, order) {
        var result = await API.process(
            'api/Patients/category/order/' + categoryId + '/' + order,
            'PATCH',
            null
        ).catch((err) => {
            throw err;
        })

        return result
    }, async FieldTypesOptions(fieldTypeId) {
        var result = await API.process(
            'api/Patients/patient/detailType/' + fieldTypeId + '/options',
            'GET',
            null
        ).catch((err) => {
            throw err;
        })

        return result
    }, async FieldTypesOptionsDefaults(detailId, optionsId) {
        var result = await API.process(
            `api/Patients/patient/detailType/${detailId}/${optionsId}/defaults`,
            'GET',
            null
        ).catch((err) => {
            throw err;
        })

        return result
    }, async SaveComponentDefaultValues(optionId, detailId, list) {
        var result = await API.process(
            `api/Patients/patient/detailType/${optionId}/${detailId}/defaults`,
            'PATCH',
            {
                OptionId: optionId,
                DetailId: detailId,
                List: [...list]
            }
        ).catch((err) => {
            throw err;
        })

        return result
    }, TriggerEvent(eventId, patientId) {
        var result = API.process(
            `api/Patients/trigger/${eventId}/${patientId}`,
            'PUT',
            null
        ).catch((err) => {
            throw err;
        })

        return result
    }, TriggerUserEvent(eventId, patientId) {
        var result = API.process(
            `api/Patients/trigger/${eventId}/User/${patientId}`,
            'PUT',
            null
        ).catch((err) => {
            throw err;
        })

        return result
    }, GetById(patientId) {
        var result = API.process(
            `api/Patients/ById/${patientId}`,
            'GET',
            null
        ).catch((err) => {
            throw err;
        })

        return result
    }, GetPatientProfileDetail(userId, detailId) {
        var result = API.process(
            `api/Patients/${userId}/detail/${detailId}/value`,
            'GET',
            null
        ).catch((err) => {
            throw err;
        })

        return result
    }, LoadPatient(PatientId) {
        var result = API.process(
            `api/Patients/LoadPatient/${PatientId}`,
            'GET',
            null
        ).catch((err) => {
            throw err;
        })

        return result
    }

}

export default Patient;
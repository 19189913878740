import React from 'react'
import { Check2, X } from 'react-bootstrap-icons'
import { UserComponents, UserMenuAccess } from '../../../UserComponents'

export default function SysAdminUserMapping() {

    const _mapMenuToTable = () => {
        var r = []
        var _userTypes = []
        // Get all menus
        // Get all Sections
        // Find User Types Y / N
        for (const m of Object.keys(UserMenuAccess))
            for (const s of Object.keys(UserMenuAccess[m]))
                for (const u of UserMenuAccess[m][s].users)
                    if (!_userTypes.includes(u)) _userTypes.push(u)

        for (const m of Object.keys(UserMenuAccess)) {
            r.push(<thead><tr><th>{m}</th></tr></thead>)
            var r2 = []
            for (const s of Object.keys(UserMenuAccess[m])) {
                r2.push(<td>{UserMenuAccess[m][s].descr}</td>)
                // r2.push(r3)
            }
            var r3 = []
            for (const ut of _userTypes) {
                r3.push(<tr>
                    <td>{ut}</td>
                    {Object.keys(UserMenuAccess[m]).map((s) =>
                        <td>{UserMenuAccess[m][s].users.includes(ut) ? <Check2 /> : <X className='text-danger' />}</td>
                    )}
                </tr>)
            }
            r.push(<><tr><td></td>{r2}</tr>{r3}</>)
        }
        return <table className='w-100'>{r}</table>
    }
    const _mapRoutesToTable = () => {
        var r = []
        var _userTypes = []
        // Get all menus
        // Get all Sections
        // Find User Types Y / N
        for (const m of UserComponents)
            for (const u of m.users)
                if (!_userTypes.includes(u)) _userTypes.push(u)

        var r2 = []
        var r3 = []
        for (const m of UserComponents)
            if (m.descr) {
                r2.push(<td><b>{m.descr}</b></td>)
            }
        for (const ut of _userTypes) {
            r3.push(<tr>
                <td><b>{ut}</b></td>
                {UserComponents.map((s) =>
                    <td>{s.users.includes(ut) ? <Check2 /> : <X className='text-danger' />}</td>
                )}
            </tr>)
        }
        r.push(<><tr><td></td>{r2}</tr>{r3}</>)
        // for (const m of Object.keys(UserMenuAccess)) {
        //     r.push(<thead><tr><th>{m}</th></tr></thead>)
        //     var r2 = []
        //     for (const s of Object.keys(UserMenuAccess[m])) {
        //         r2.push(<td>{UserMenuAccess[m][s].descr}</td>)
        //         // r2.push(r3)
        //     }
        //     var r3 = []
        //     for (const ut of _userTypes) {
        //         r3.push(<tr>
        //             <td>{ut}</td>
        //             {Object.keys(UserMenuAccess[m]).map((s) =>
        //                 <td>{UserMenuAccess[m][s].users.includes(ut) ? 'Yes' : 'No'}</td>
        //             )}
        //         </tr>)
        //     }
        //     r.push(<><tr><td></td>{r2}</tr>{r3}</>)
        // }
        return <table className='w-100 text-center'>{r}</table>
    }
    return (
        <div style={{ overflow: 'auto' }}>
            <div>
                <h3>Menu Access</h3>
                {_mapMenuToTable()}
            </div>
            <div>
                <h3>Journey Access</h3>
                {_mapRoutesToTable()}
            </div>
        </div>
    )
}

import { faArrowRightRotate } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useContext, useState } from 'react'
import CalendarContext from '../../CalendarProvider'

export default function CalendarSyncFilter() {
    const [loading, setLoading] = useState(false)
    const { isSyncable, triggerSync } = useContext(CalendarContext)
    if (!isSyncable) return
    return (
        <div onClick={() => { setLoading(true); triggerSync().then((r) => setLoading(false)) }} className='text-center'>
            <b>Sync</b>
            <div><FontAwesomeIcon icon={faArrowRightRotate} spinPulse={loading} /></div>
        </div>
    )
}

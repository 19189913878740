import React, { useContext, useEffect, useRef, useState } from 'react'
import { Calendar2PlusFill } from 'react-bootstrap-icons'
import CalendarGenerics from '../../CalendarGenerics'
import CalendarContext from '../CalendarProvider'
import CelendarDayColumnComponent from './CelendarDayColumnComponent'

export default function CalendarWeekView() {
  const { filters, actieveTimeSlot, setDate } = useContext(CalendarContext)
  const [renderFilters, setRenderFilters] = useState(filters)
  const [woyDates, setWOYDates] = useState([])
  const activeTimeRef = useRef()
  const [daysEvts, setDaysEvts] = useState([])
  const [calendar, setCalendar] = useState([])

  const [currentEvent, setCurrentEvent] = useState()
  const INTERVAL_SIZE = 30
  const maxEventRef = useRef(1)

  const _handleScrollCurrentTime = (e) => {
    if (activeTimeRef.current)
      activeTimeRef.current.scrollIntoView({ behavior: "smooth", block: "center", inline: "nearest" })
  }

  const _handleGetFullWOY = () => {
    setWOYDates(CalendarGenerics.getWOYArray(filters.year, filters.month, filters.weekOfYear - 1))
  }

  useEffect(() => {
    _handleScrollCurrentTime()
  }, [])

  useEffect(() => {
    _handleGetFullWOY()
  }, [filters])

  // TODO : Get events for dow

  const _handleTSClick = (ts, day) => {
    const hm = ts.split(':')
    // setCurrentEvent({ date: { start: CalendarGenerics.AdMinutes(CalendarGenerics.GetDateFromDOW(filters.year, filters.month, filters.weekOfYear - 1, day), ((hm[0] * 60)) + parseInt(hm[1])), end: undefined }, event: undefined })
    setDate(CalendarGenerics.AdMinutes(CalendarGenerics.GetDateFromDOW(filters.year, filters.month, filters.weekOfYear - 1, day), ((hm[0] * 60)) + parseInt(hm[1])), 'Week')
    // Do something with date
    // alert(
    //   CalendarGenerics.AdMinutes(CalendarGenerics.GetDateFromDOW(filters.year, filters.month, filters.weekOfYear - 1, day), ((hm[0] * 60)) + parseInt(hm[1]))
    // )
  }

  const _buildWithEvents = () => {
    maxEventRef.current = 1
    var _active_events = 1
    // Pre Calc max events
    for (const ts of CalendarGenerics.timeIntervals) {
      for (const d of daysEvts) {
        if (d && ts === d.startDateTime.substring(11, 13) + ':' + String((Math.floor(d.startDateTime.substring(14, 16) / INTERVAL_SIZE) * INTERVAL_SIZE)).padStart(2, '0')) {
          // _cnt_events++
          _active_events++
        }
        if (d && ts === d.endDateTime.substring(11, 13) + ':' + String((Math.floor(d.endDateTime.substring(14, 16) / INTERVAL_SIZE) * INTERVAL_SIZE)).padStart(2, '0')) {
          _active_events--
        }
      }
      maxEventRef.current = maxEventRef.current > _active_events ? maxEventRef.current : _active_events
    }
    var _cal = []
    var _evts = []
    _active_events = 0
    for (const ts of CalendarGenerics.timeIntervals) {
      // Pre render events
      // _cnt_events = 0
      _evts = []
      for (const d of daysEvts) {
        // If starts on this TS to the nearest 15
        var _etst = (Math.ceil(d.endDateTime.substring(14, 16) / INTERVAL_SIZE) * INTERVAL_SIZE)
        var _etsh = d.endDateTime.substring(11, 13)
        if (_etst >= 60) {

          _etsh = String((parseInt(_etsh) + 1)).padStart(2, "0")
          _etst = '00'
        }

        if (d && ts === d.startDateTime.substring(11, 13) + ':' + String((Math.floor(d.startDateTime.substring(14, 16) / INTERVAL_SIZE) * INTERVAL_SIZE)).padStart(2, '0')) {
          // calc to 15 min slots || 1hr == 4 | min == Floor(mins / 15)
          _active_events++
          // hours 
          // TODO : Calc days
          const oneDay = 24 * 60 * 60 * 1000; // hours*minutes*seconds*milliseconds
          const firstDate = new Date(d.endDateTime);
          const secondDate = new Date(d.startDateTime);

          const diffDays = Math.round(Math.abs((firstDate - secondDate) / oneDay));

          const et = d.endDateTime.substring(11, 16)
          const st = d.startDateTime.substring(11, 16)
          var etmn
          if (st === et) {
            etmn = ((parseInt(et.split(':')[0]) * 4) * INTERVAL_SIZE + (Math.ceil((parseInt(et.split(':')[1]) + 1) / INTERVAL_SIZE) * INTERVAL_SIZE) + ((diffDays * 24) * (INTERVAL_SIZE * 4))) //((diffDays * 24 ) * 15) )
          } else {
            etmn = ((parseInt(et.split(':')[0]) * 4) * INTERVAL_SIZE + (Math.ceil(et.split(':')[1] / INTERVAL_SIZE) * INTERVAL_SIZE) + ((diffDays * 24) * (INTERVAL_SIZE * 4)))//((diffDays * 24 ) * 15) )
          }
          const stmn = ((st.split(':')[0] * 4) * INTERVAL_SIZE) + (Math.floor(st.split(':')[1] / INTERVAL_SIZE) * INTERVAL_SIZE)

          // _cnt_events++
          _evts.push(<td rowSpan={((etmn - stmn) / INTERVAL_SIZE)} onClick={() => _handleTSClick(ts)} className='item'>{d.summary}</td>)
        }
        // TODO : Acomodate for dates
        if (ts === _etsh + ':' + _etst) {
          _active_events--
        }

      }
      // _max_events = _max_events > _cnt_events ? _max_events : _cnt_events
      _cal.push(<tr className='item-row' ref={ts === actieveTimeSlot ? activeTimeRef : undefined}>
        <td onClick={() => _handleTSClick(ts)} className={'time-slot  '.concat(actieveTimeSlot === ts ? ' active py-1 ' : '')}><span className='d-flex justify-content-between align-items-center px-2'>{ts} <Calendar2PlusFill /></span></td>
        {_evts}
      </tr>)
    }

    setCalendar(<table className='calendar-table day'>
      <colgroup>
        <col span="1" style={{ width: "10%", minWidth: '50px' }} />
        {/* <col span="1" style={{ width: "95%" }} /> */}
      </colgroup>
      <thead className='header'>
        <tr className='shadow'>
          <th colSpan={maxEventRef.current + 2}><span ></span>{`${filters.year}-${String(filters.month + 1).padStart(2, '0')}-${String(filters.day).padStart(2, '0')}`}</th>
        </tr>
      </thead>
      <tbody>
        {
          _cal
        }
      </tbody>
    </table>)
  }

  const _findByDay = (ts, day) => {
    const hm = ts.split(':')
    const _day = CalendarGenerics.AdMinutes(CalendarGenerics.GetDateFromDOW(filters.year, filters.month, filters.weekOfYear - 1, day), ((hm[0] * 60)) + parseInt(hm[1]))
    return (<div>{_day.toISOString()}</div>)
  }

  const _generateDaysColumns = () => {
    var cols = []
    var matrix = []
    for (var a = 0; a < CalendarGenerics.daysOfWeek.length; a++) {
      // for (const dow of CalendarGenerics.daysOfWeek) {
      const d = CalendarGenerics.daysOfWeek[a].short
      matrix[a] = []
      // matrix[a][0] = CalendarGenerics.daysOfWeek[a].short
      for (var b = 0; b < CalendarGenerics.timeIntervals.length; b++) {
        matrix[a][b] = CalendarGenerics.timeIntervals[b]
      }

    }

    return cols
  }

  useEffect(() => { setRenderFilters(filters) }, [filters])

  return (
    <div className='d-flex justify-content-evenly'>
      <div style={{ maxWidth: '60px' }}>
        <CelendarDayColumnComponent
          dow={-1}
          date={undefined} />
      </div>
      {
        CalendarGenerics
          .daysOfWeek
          .map((dow, i) =>
            <CelendarDayColumnComponent
              dow={dow}
              date={CalendarGenerics.GetDateFromDOW(renderFilters.year, renderFilters.month, renderFilters.weekOfYear - 1, i)} />
          )
      }

    </div>
  )
  //   <>

  //     {/* <CalendarEventPopup event={currentEvent} /> */}
  //     {/* <table className='calendar-table week'>
  //       <thead className='header'>
  //         <tr>
  //           <th></th>
  //           {
  //             CalendarGenerics
  //               .daysOfWeek
  //               .map((dow, i) =>
  //                 // woyDates[i].getMonth()
  //                 // <th>{dow.short} {woyDates[i].getDate() + ' ' + CalendarGenerics.months(0).short }</th>
  //                 <th>{dow.short} {woyDates.length > 0 ? woyDates[i].getDate() + ' ' + CalendarGenerics.months[woyDates[i].getMonth()].short : ''}</th>
  //               )
  //           }
  //         </tr>
  //       </thead>
  //       <tbody>
  //         {_generateDaysColumns()}
  //         {/* {
  //           CalendarGenerics
  //             .daysOfWeek
  //             .map((dow, idx) =>
  //               <CelendarDayColumnComponent dow={dow} />
  //             )
  //         } */}

  //         {/* {
  //           CalendarGenerics
  //             .timeIntervals
  //             .map((t) =>
  //               <tr ref={actieveTimeSlot === t ? activeTimeRef : undefined} className='week'>
  //                 <td className={'time-slot'.concat(actieveTimeSlot === t ? ' active ' : '')}>{t}</td>
  //                 {
  //                   CalendarGenerics
  //                     .daysOfWeek
  //                     .map((dow, idx) =>
  //                       <td
  //                         draggable
  //                         onClick={() => _handleTSClick(t, idx)}
  //                         className='item'>{_findByDay(t, idx)}</td>
  //                     )
  //                 }
  //               </tr>
  //             )
  //         } */}
  //       </tbody>
  //     </table> */}
  //   </>

  // )
}

import Questionnaire from '../../../../libs/api/services/Questionnaire'

export default class QBuilderGenerics {
    static debug = true
    constructor() {
        this.debug = false
    }
    static async loadData(c, cid, qaid) {
        var data = { properties: [], reponse: [] }
        if (c)
            try {
                // First try load from cache
                if (c && c.properties && c.properties.length > 0) {
                    data.properties = c.properties
                } else {
                    throw Error('Cache too small')
                }
                if (c && c.response) {
                    data.response = c.response
                }
                return data
            } catch (e) {
            }

        // Alternativly read from server

        try {
            data.properties = await QBuilderGenerics.loadComponentProperties(cid)
        } catch (e) {
            if (QBuilderGenerics.debug) console.error('QBuilderGenerics::loadData : api error ', e)
        }
        if (qaid) {
            try {
                data.response = await QBuilderGenerics.loadComponentReponses(qaid, cid)
            } catch (e) {
                if (QBuilderGenerics.debug) console.error('QBuilderGenerics::loadData : api error ', e)
            }
        }
        return data
    }
    static async setProperty(c, k, v) {
        if (k && v)
            await Questionnaire
                .SetComponentProperty(c, {
                    property: k,
                    values: JSON.stringify(v),
                    component: {
                        id: c
                    }
                })
        return QBuilderGenerics.loadComponentProperties(c)
    }
    static getProperty(properties, k) {
        if (properties)
            try {
                const ret = properties
                const res = ret.find(p => p.property === k)
                try {
                    return res && res.values ? JSON.parse(res.values) : undefined
                } catch (e) {
                    // If not JSON property
                    return res && res.values ? res.values : undefined
                }
            } catch (e) {
                if (QBuilderGenerics.debug) console.error('QBuilderGenerics::getProperty : Unable to find property')
                throw Error('Unable to find property')
            }
        else
            throw Error('No Properties Data Available')
    }
    static async loadComponentProperties(ComponentId) {
        try {
            return await Questionnaire
                .GetComponentProperties(ComponentId)
                .catch((e) => { console.error('Failed to load properties for ' + ComponentId) })
        } catch (e) { if (QBuilderGenerics.debug) console.error('General failure to load properties for ' + ComponentId) }
        return undefined;
    }
    static async loadComponentReponses(qaid, cid) {
        try {
            return await Questionnaire
                .GetQuestionnaireResponse(qaid, cid)
                .catch((e) => { console.error('Failed to load properties for ' + cid) })
        } catch (e) { if (QBuilderGenerics.debug) console.error('General failure to load properties for ' + cid) }
        return undefined;
    }
}

import React, { Component } from 'react'
import QCaptureEmbeded from './questionnaires/capture/QCaptureEmbeded'

export default class RegisterSectionLayout extends Component {
    render() {
        return (
            <div className='d-flex h-100 flex-column align-items-center justify-content-around text-center position-relative'>
                <QCaptureEmbeded className="bg-primary-container wizard-embeded-light text-light" questionnaireId={"718389BF-E1D4-47A8-B660-1B8287E40611"} QuestionnaireAssignmentId={"New"} />
            </div>
        )
    }
}

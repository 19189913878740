// import { EditorState, Modifier, convertFromRaw, convertToRaw } from 'draft-js';
import { EditorState, Modifier, convertFromRaw, convertToRaw } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import draftToMarkdown from 'draftjs-to-markdown';
import React, { useEffect, useRef, useState } from 'react';
import { Editor } from 'react-draft-wysiwyg';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import RTEPlaceHolderMenu from './RTEPlaceHolderMenu';
import RTESuggestions from './rte/RTESuggestions';

// export default class RTEMessageBuilderComponent extends Component {
//     constructor(props) {
//         super(props);
//         this.state = {
//             editorState: EditorState.createEmpty(),
//         };
//         this.loadTemplate(props.template)
//     }
//     loadTemplate(template) {
//         try {
//             var convertedState = undefined
//             if (typeof template === 'string') {
//                 convertedState = convertFromRaw(JSON.parse(template))
//             } else {
//                 convertedState = convertFromRaw(template)
//             }
//             const editorValue = EditorState.createWithContent(convertedState);

//             this.setState({
//                 editorState: (template) ? editorValue : EditorState.createEmpty(),
//             })

//         } catch (e) {
//             this.setState({
//                 // if(props.template)
//                 // editorState: (props.template) ? editorValue : EditorState.createEmpty(),
//                 editorState: EditorState.createEmpty(),
//                 autocompleteState: null
//             })
//         }
//     }
//     // getSnapshotBeforeUpdate(prevProps, prevState) {
//     //     // if(!this.props.template) return 
//     //     // return this.props.template;
//     // }
//     // componentDidUpdate(prevProps, prevState, snapshot) {
//     //     if (prevProps.template === "undefined" || typeof prevProps.template === "undefined") {
//     //         console.warn(prevProps.template)
//     //         // this.loadTemplate(snapshot)
//     //     }
//     // }
//     onEditorStateChange = (editorState) => {
//         const triggerRange = this.getTriggerRange("{{");
//         if (!triggerRange) {
//             this.setState({ editorState, autocompleteState: null });
//             this.props.onSetTemplate(
//                 JSON.stringify(convertToRaw(editorState.getCurrentContent())),
//                 draftToHtml(convertToRaw(editorState.getCurrentContent())),
//                 draftToMarkdown(convertToRaw(editorState.getCurrentContent()))
//             )
//             return;
//         }
//         this.setState({
//             editorState,
//             autocompleteState: {
//                 searchText: triggerRange.text.slice(2, triggerRange.text.length),
//             },
//         })
//         this.props.onSetTemplate(
//             JSON.stringify(convertToRaw(editorState.getCurrentContent())),
//             draftToHtml(convertToRaw(editorState.getCurrentContent())),
//             draftToMarkdown(convertToRaw(editorState.getCurrentContent()))
//         )
//     };
//     /*
//         OnChanged
//     */
//     // Check for hash
//     getTriggerRange(trigger) {
//         const selection = window.getSelection();
//         if (selection.rangeCount === 0) return null;
//         const range = selection.getRangeAt(0);
//         const text = range.startContainer.textContent.substring(0, range.startOffset);

//         if (/s+$/.test(text)) return null;
//         const index = text.lastIndexOf(trigger);
//         if (text.substring(index).indexOf("}}") > -1) return null;

//         //alert(index)
//         if (index === -1) return null;

//         return {
//             text: text.substring(index),
//             start: index,
//             end: range.startOffset,
//         };
//     };
//     renderSuggestion(text) {
//         const { editorState, autocompleteState } = this.state;

//         // this.onChange(
//         this.addHashTag(editorState, autocompleteState, text)
//         // );

//         this.setState({ autocompleteState: null });
//     }

//     getInsertRange(activeSuggestion, editorState) {

//         var CaretCoordinates = {
//             x: 0,
//             y: 0
//         };
//         const selection = editorState.getSelection();
//         const content = editorState.getCurrentContent();
//         const anchorKey = selection.getAnchorKey();
//         const end = selection.getAnchorOffset();
//         const block = content.getBlockForKey(anchorKey);
//         const text = block.getText();
//         const start = text.substring(0, end).lastIndexOf("{{");

//         return {
//             start,
//             end
//         };
//     }

//     addHashTag(editorState, autocompleteState, hashtag) {
//         /* 1 */
//         const { start, end } = this.getInsertRange(autocompleteState, editorState);

//         /* 2 */
//         const currentSelectionState = editorState.getSelection();
//         const selection = currentSelectionState.merge({
//             anchorOffset: start,
//             focusOffset: end,
//         });

//         /* 3 */
//         const contentState = editorState.getCurrentContent();
//         const contentStateWithEntity = contentState.createEntity(
//             'HASHTAG',
//             'IMMUTABLE',
//             {
//                 hashtag,
//             },
//         );
//         const entityKey = contentStateWithEntity.getLastCreatedEntityKey();

//         /* 4 */
//         let newContentState = Modifier.replaceText(
//             contentStateWithEntity,
//             selection,
//             `{{${hashtag}}}`,
//             null,
//             entityKey,
//         );

//         /* 5 */
//         const newEditorState = EditorState.push(
//             editorState,
//             newContentState,
//             `insert-hashtag`,
//         );

//         this.setState({ editorState: newEditorState });
//         return EditorState.forceSelection(
//             newEditorState,
//             newContentState.getSelectionAfter(),
//         );
//     };

//     render() {
//         const { editorState } = this.state;
//         return (
//             <>
//                 <div className='editor'>
//                     {
//                         this.props.viewOnly
//                             ?
//                             <>
//                                 <Editor
//                                     // initialEditorState={editorState}
//                                     readOnly={this.props.readOnly}
//                                     editorState={editorState}
//                                     // onChange={this.onChange.bind(this)}
//                                     // onEditorStateChange={editorState => {
//                                     //     this.setState({editorState : editorState});
//                                     //     this.onChange.bind(this)
//                                     //   }}
//                                     wrapperClassName="demo-wrapper"
//                                     editorClassName="demo-editor"
//                                     toolbarHidden={true}
//                                     allowUndo={false}
//                                     onEditorStateChange={this.onEditorStateChange}
//                                     //options= []
//                                     toolbar={{
//                                         options: ['inline', 'fontSize', 'list', 'textAlign'],
//                                         inline: { inDropdown: true },
//                                         list: { inDropdown: true },
//                                         textAlign: { inDropdown: true },
//                                         link: { inDropdown: true },
//                                         history: { inDropdown: true },
//                                     }}
//                                     toolbarCustomButtons={this.props.placeholders === false ? [] : [<RTEPlaceHolderMenu key={'RTEPlaceHolderMenu' + Math.random() * 10} />]}
//                                 />
//                                 {
//                                     this.props.disablePlaceholders
//                                         ?
//                                         <></>
//                                         :
//                                         <RTESuggestions
//                                             renderSuggestion={(text) => this.renderSuggestion(text)}
//                                             autocompleteState={this.state.autocompleteState} />
//                                 }
//                             </>
//                             :
//                             <>
//                                 <Editor
//                                     // initialEditorState={editorState}
//                                     editorState={editorState}
//                                     // onChange={this.onChange.bind(this)}
//                                     wrapperClassName="demo-wrapper"
//                                     editorClassName="demo-editor"
//                                     allowUndo={false}
//                                     onEditorStateChange={this.onEditorStateChange}
//                                     //options= []
//                                     toolbar={{
//                                         // options: ['inline', 'blockType', 'fontSize', 'fontFamily', 'list', 'textAlign', 'colorPicker', 'link', 'embedded', 'emoji', 'image', 'remove', 'history']
//                                         // options: ['emoji'],
//                                         options: this.props.options,
//                                         inline: { inDropdown: true },
//                                         list: { inDropdown: true },
//                                         textAlign: { inDropdown: true },
//                                         // link: { inDropdown: true },
//                                         history: { options: [] },
//                                     }}
//                                     toolbarCustomButtons={this.props.placeholders === false ? [] : [<RTEPlaceHolderMenu key={'RTEPlaceHolderMenu' + Math.random() * 10} />]}
//                                 />
//                                 {
//                                     !this.props.disablePlaceholders
//                                         ?
//                                         <RTESuggestions
//                                             renderSuggestion={(text) => this.renderSuggestion(text)}
//                                             autocompleteState={this.state.autocompleteState} />
//                                         :
//                                         <></>
//                                 }
//                             </>
//                     }
//                 </div>
//                 {/* <textarea
//                     disabled
//                     value={draftToHtml(convertToRaw(editorState.getCurrentContent()))}
//                 /> */}
//             </>
//         )
//     }
// }

export default function RTEMessageBuilderComponent({
    viewOnly,
    readOnly,
    placeholders,
    disablePlaceholders,
    options,
    onSetTemplate,
    template
}) {
    const [state, setState] = useState({
        editorState: EditorState.createEmpty(),
        autocompleteState: {
            searchText: undefined
        }
    })

    const loaded = useRef(false)

    /*
    * Main update events
    */

    useEffect(() => {

        const load = (template) => {
            // Added because state is always reloaded from params
            // being sent to the component
            if (loaded.current) return
            loaded.current = true
            try {
                console.log('Loading Template')
                var convertedState = undefined
                if (typeof template === 'string') {
                    convertedState = convertFromRaw(JSON.parse(template))
                } else {
                    convertedState = convertFromRaw(template)
                }
                const editorValue = EditorState.createWithContent(convertedState);

                setState({
                    ...state,
                    editorState: (template) ? editorValue : EditorState.createEmpty(),
                })

            } catch (e) {
                setState({
                    ...state,
                    // if(props.template)
                    // editorState: (props.template) ? editorValue : EditorState.createEmpty(),
                    editorState: EditorState.createEmpty(),
                    autocompleteState: null
                })
            }
        }
        if (template) load(template)
    }, [template, state])

    const onEditorStateChange = (editorState) => {
        //?????????????
        const triggerRange = getTriggerRange("{{");
        if (!triggerRange) {
            setState({ editorState, autocompleteState: null });
            onSetTemplate(
                JSON.stringify(convertToRaw(editorState.getCurrentContent())),
                draftToHtml(convertToRaw(editorState.getCurrentContent())),
                draftToMarkdown(convertToRaw(editorState.getCurrentContent()))
            )
            return;
        }
        setState({
            editorState,
            autocompleteState: {
                searchText: triggerRange.text.slice(2, triggerRange.text.length),
            },
        })
        //?????????????
        onSetTemplate(
            JSON.stringify(convertToRaw(editorState.getCurrentContent())),
            draftToHtml(convertToRaw(editorState.getCurrentContent())),
            draftToMarkdown(convertToRaw(editorState.getCurrentContent()))
        )
    };

    /**
     * Placeholder events
     */

    // Get the current typing position
    const getTriggerRange = (trigger) => {
        const selection = window.getSelection();
        if (selection.rangeCount === 0) return null;
        const range = selection.getRangeAt(0);
        const text = range.startContainer.textContent.substring(0, range.startOffset);

        if (/s+$/.test(text)) return null;
        const index = text.lastIndexOf(trigger);
        if (text.substring(index).indexOf("}}") > -1) return null;

        //alert(index)
        if (index === -1) return null;

        return {
            text: text.substring(index),
            start: index,
            end: range.startOffset,
        };
    };

    // Events that get called to render suggestions
    const renderSuggestion = (text) => {
        addHashTag(state.editorState, state.autocompleteState, text)
    }

    // Get range of {{
    const getInsertRange = (activeSuggestion, editorState) => {
        const selection = editorState.getSelection();
        const content = editorState.getCurrentContent();
        const anchorKey = selection.getAnchorKey();
        const end = selection.getAnchorOffset();
        const block = content.getBlockForKey(anchorKey);
        const text = block.getText();
        const start = text.substring(0, end).lastIndexOf("{{");

        return {
            start,
            end
        };
    }

    // Add the placeholder value to the message
    const addHashTag = (editorState, autocompleteState, hashtag) => {
        /* 1 */
        const { start, end } = getInsertRange(state.autocompleteState, state.editorState);

        /* 2 */
        const currentSelectionState = editorState.getSelection();
        const selection = currentSelectionState.merge({
            anchorOffset: start,
            focusOffset: end,
        });

        /* 3 */
        const contentState = editorState.getCurrentContent();
        const contentStateWithEntity = contentState.createEntity(
            'HASHTAG',
            'IMMUTABLE',
            {
                hashtag,
            },
        );
        const entityKey = contentStateWithEntity.getLastCreatedEntityKey();

        /* 4 */
        let newContentState = Modifier.replaceText(
            contentStateWithEntity,
            selection,
            `{{${hashtag}}}`,
            null,
            entityKey,
        );

        /* 5 */
        const newEditorState = EditorState.push(
            editorState,
            newContentState,
            `insert-hashtag`,
        );


        setState({ ...state, editorState: newEditorState, autocompleteState: null })
        return EditorState.forceSelection(
            newEditorState,
            newContentState.getSelectionAfter(),
        );
    };

    return (
        <>
            <div className='editor'>
                {
                    viewOnly
                        ?
                        <>
                            <Editor
                                // initialEditorState={editorState}
                                readOnly={readOnly}
                                editorState={state.editorState}
                                // onChange={this.onChange.bind(this)}
                                // onEditorStateChange={editorState => {
                                //     this.setState({editorState : editorState});
                                //     this.onChange.bind(this)
                                //   }}
                                wrapperClassName="demo-wrapper"
                                editorClassName="demo-editor"
                                toolbarHidden={true}
                                allowUndo={false}
                                onEditorStateChange={onEditorStateChange}
                                //options= []
                                toolbar={{
                                    options: ['inline', 'fontSize', 'list', 'textAlign'],
                                    inline: { inDropdown: true },
                                    list: { inDropdown: true },
                                    textAlign: { inDropdown: true },
                                    link: { inDropdown: true },
                                    history: { inDropdown: true },
                                }}
                                toolbarCustomButtons={placeholders === false ? [] : [<RTEPlaceHolderMenu key={'RTEPlaceHolderMenu' + Math.random() * 10} />]}
                            />
                            {
                                disablePlaceholders
                                    ?
                                    <></>
                                    :
                                    <RTESuggestions
                                        renderSuggestion={(text) => renderSuggestion(text)}
                                        autocompleteState={state.autocompleteState} />
                            }
                        </>
                        :
                        <>
                            <Editor
                                // initialEditorState={editorState}
                                editorState={state.editorState}
                                // onChange={this.onChange.bind(this)}
                                wrapperClassName="demo-wrapper"
                                editorClassName="demo-editor"
                                allowUndo={false}
                                onEditorStateChange={onEditorStateChange}
                                //options= []
                                toolbar={{
                                    // options: ['inline', 'blockType', 'fontSize', 'fontFamily', 'list', 'textAlign', 'colorPicker', 'link', 'embedded', 'emoji', 'image', 'remove', 'history']
                                    // options: ['emoji'],
                                    options: options,
                                    inline: { inDropdown: true },
                                    list: { inDropdown: true },
                                    textAlign: { inDropdown: true },
                                    // link: { inDropdown: true },
                                    history: { options: [] },
                                }}
                                toolbarCustomButtons={placeholders === false ? [] : [<RTEPlaceHolderMenu key={'RTEPlaceHolderMenu' + Math.random() * 10} />]}
                            />
                            {
                                !disablePlaceholders
                                    ?
                                    <RTESuggestions
                                        renderSuggestion={(text) => renderSuggestion(text)}
                                        autocompleteState={state.autocompleteState} />
                                    :
                                    <></>
                            }
                        </>
                }
            </div>
            {/* <textarea
            disabled
            value={draftToHtml(convertToRaw(editorState.getCurrentContent()))}
        /> */}
        </>
    )
}

import React, { useEffect, useState } from 'react'
import MessagingService from '../../../../../../../libs/api/services/MessagingService'

export default function WhatsAppWizardSetupType({ title, description, navigation, errors, setValue, values }) {

    const [configurationName, setConfigurationName] = useState('')
    const [configurations, setConfigurations] = useState([])
    const [mode, setMode] = useState('')
    const [configuration, setConfiguration] = useState(Object.keys(values).includes('configurationId') ? { id: values.configurationId } : undefined)
    useEffect(() => {
        title('WhatsApp Configuration')
        description('Would you like to setup a new account or manage an existing WhatsApp configuration')
        // Load Navigation if session started
        if (Object.keys(values).includes('configurationId'))
            navigation([
                { text: 'Remove', type: 'Action', action: async () => { _handleRemoveConfig(values.configurationId).then((r) => { navigation([]); loadConfigs() }) } },
                { text: 'Manage', type: 'Next', action: async () => { setValue({ 'configurationId': values.configurationId }) } },
            ])
    }, [])

    const loadConfigs = () => {
        MessagingService.
            ListMessageConfigurationByServiceType('WhatsApp')
            .then((r) =>
                setConfigurations(r)).catch((e) => errors(e.message))
    }

    useEffect(() => {
        loadConfigs()
    }, [])

    const _handleSetConfigrationName = (c) => {
        setConfigurationName(c)
        if (c)
            navigation([{
                text: 'Next', type: 'Next', action: async () => {
                    return await MessagingService
                        .SetConfiguration({
                            configurationName: c,
                            serviceType: {
                                type: 'WhatsApp'
                            }
                        }).then((r) => {
                            setValue({ 'configurationId': r.id })
                        })
                }
            }])
        else
            navigation([])
        setConfiguration()
    }

    const _handleRemoveConfig = async (id) => {
        return await MessagingService
            .RemoveMessageConfiguration(id)
    }

    const _handleSelectConfiguration = (c) => {
        if (c)
            navigation([
                { text: 'Remove', type: 'Action', action: async () => { _handleRemoveConfig(c.id).then((r) => { navigation([]); loadConfigs() }) } },
                { text: 'Manage', type: 'Next', action: async () => { setValue({ 'configurationId': c.id }) } },
            ])
        setConfigurationName('')
        setConfiguration(c)
    }

    return (
        <div className='border border-1 rounded m-3'>

            <div className={'row p-3 shadow rounded bg-primary-container '.concat(mode !== 'New' && configurationName === '' ? 'opacity-75' : '')} >
                <div className='col-12'>
                    <h3>New</h3>
                    <p>Enter a name for the configuration eg: Demo Hospital WhatsApp Help Line</p>
                    <input
                        onFocus={(e) => setMode('New')}
                        onBlur={(e) => setMode('')}
                        onChange={(e) => _handleSetConfigrationName(e.target.value)}
                        value={configurationName}
                        className='form-control' placeholder='Configuration name eg: Demo Hospital WhatsApp Help Line' />
                </div>
            </div>
            <div className={'row p-3 shadow rounded bg-secondary-container '.concat(mode !== 'New' && configuration ? '' : ' opacity-75')}>
                <div className='col-12'>
                    <h3>Manage Existing</h3>
                    <p>Select the configuration you would like to manage</p>
                    <div
                        style={{ cursor: 'pointer' }}
                        className='text-start'>
                        {
                            configurations.map((c) =>
                                <div
                                    className={'p-2 rounded '.concat(configuration && c.id === configuration.id ? ' font-weight-bold active ' : ' font-weight-light ')}
                                    onClick={() => _handleSelectConfiguration({ id: c.id })} >{c.configurationName}</div>
                            )
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}

import React, { useState } from 'react'
import { EyeFill, EyeSlashFill } from 'react-bootstrap-icons'
import { Bar } from 'react-chartjs-2'
import StatsSectionContainer from './StatsSectionContainer'

export default function StatsWeightingGraph({ o, isVisible, onItemSeletected }) {
    const _min = o.total - (o.total * 2)
    const _max = o.total
    const _handleItemSelectected = (datasetIdx, itemIdx, labels) => {
        onItemSeletected([{ source: 'weighting-graph', key: o.name }, { source: 'labels', keys: labels }])
    }
    return (
        <div className={''.concat(isVisible ? '' : 'd-none')}>
            <>
                <div className='d-flex flex-column align-items-center'>
                    <Bar className='align-items-center' options={{
                        indexAxis: 'y',
                        scale: {
                            ticks: {
                                display: false
                            }
                        },
                        scales: {
                            x: {
                                ticks: {
                                    beginAtZero: true,
                                    steps: 1,
                                    stepSize: 1,
                                    max: _max,
                                    min: _min
                                }
                            }
                        },
                        elements: {
                            bar: {
                                borderWidth: 0,
                            },
                        },
                        responsive: false,
                        plugins: {
                            legend: {
                                display: false
                            }
                        },
                        onClick: function (event, elements) { _handleItemSelectected(elements[0].datasetIndex, elements[0].index, o.labels) },
                    }} data={{
                        type: 'bar',
                        labels: ['Weight'],
                        datasets: [{
                            type: 'bar',
                            label: 'Selected',
                            backgroundColor: '#22aa99',
                            data: o.score >= 0 ? [o.score, _min, _max,] : [o.score, _min, _max,],
                        },
                        ]
                    }} />
                </div>
                <div className={' '} ><small>{o.labels ? o.labels.map((l) => <span key={'StatsWGraph-' + l} className='label label-info bg-secondary-container rounded rounded-4 px-1'>{l}</span>) : <></>}</small></div>
            </>

        </div>

    )
}
import React from 'react'

export default function ConfirmUploadDetails({ files }) {
    const _mapFiles = () => {
        var f = []
        for (const file of files)
            f.push(file.name)
        return f
    }
    return (
        <div>
            Are your sure you would like to upload {files.length} files
            {
                _mapFiles().map((f) => <div>{f}</div>)
            }
        </div>
    )
}

/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'
import { CardChecklist, Circle, CircleFill, QuestionSquare } from 'react-bootstrap-icons'
import Questionnaire from '../../../../../libs/api/services/Questionnaire'
import WorkFlow from '../../../../../libs/api/services/WorkFlow'

export default function EventTriggerQuestionnaireStarted({ link, values }) {
    const [questionnaires, setQuestionnaires] = useState([])
    const [linked, setLinked] = useState([])

    useEffect(() => { _loadQuestionnaires() }, [])

    const _loadLinks = () => {
        try{
        WorkFlow
            .GetTriggerPropertyByType(typeof values.event === 'object' ? values.event.id : JSON.parse(values.event).id, link[0].id, 'questionnaire_started')
            .then((r) => {
                setLinked(JSON.parse(r.value))
            }).catch((e) => { /* Doesnt exist yet */ })
        }catch(e){
            // Not yet linked
        }
    }

    const _loadQuestionnaires = () => {
        Questionnaire
            .ListQuestionnaires()
            .then((r) => {
                setQuestionnaires(r)
                _loadLinks()
            })
    }
    const setQuestionnaireLink = (cat) => {
        var _linked = []
        var _add = true
        for (const _l of linked)
            if (_l.id === cat.id)
                _add = false
            else
                _linked.push(_l)
        if (_add)
            _linked.push(cat)
        WorkFlow
            .SetTriggerProperty(typeof values.event === 'object' ? values.event.id : JSON.parse(values.event).id, link[0].id, {
                property: 'questionnaire_started',
                value: JSON.stringify(_linked),
            })
            .then((r) => {
                // Log as property
                setLinked(_linked)
            })
    }

    const _isLinekd = (id) => {
        for (const l of linked)
            if (l.id === id) return true
        return false
    }

    return (
        <div>
            <b>Select the questionnaire you would like to add this event to</b>
            {
                questionnaires.map((c) =>
                    <div onClick={(e) => setQuestionnaireLink(c)} key={c.id} className='ms-2'>{_isLinekd(c.id) ? <CircleFill className='me-2' /> : <Circle className='me-2' />} {c.type === 'Questionnaire' ? <QuestionSquare /> : <CardChecklist />} {c.name}</div>
                )
            }
        </div>
    )
}

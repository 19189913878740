import React, { useState } from 'react'

interface IErrorContext {
  error: string
  errorsMap: { [key: string]: string }
  invokeError?: (error: string) => void
  invokeFieldError?: (field: string, error?: string) => void
  clearErrors?: () => void
}
interface Props {
  children: React.ReactNode
}
const defaultState = {
  error: '',
  errorsMap: {},
}

const ErrorContext = React.createContext<Partial<IErrorContext>>(defaultState)

export const ErrorProvider: React.FC<Props> = ({ children }) => {
  const [errors, setErrors] = useState(defaultState.error)
  const [errorsMap, setErrorsMap] = useState(defaultState.errorsMap)

  const invokeFieldError = (field: string, error?: string) => setErrorsMap({ ...errorsMap, [field]: error })

  const clearErrors = () => {
    setErrorsMap(defaultState.errorsMap)
    setErrors(defaultState.error)
  }

  const invokeError = (error: string) => {
    const _pError = error ? JSON.parse(JSON.stringify(error)) : ''
    const _eMap: { [key: string]: string } = {}
    if (_pError.errors) {
      for (const k of _pError.errors) _eMap[k] = JSON.stringify(_pError.errors[k])
      setErrorsMap(_pError.errors)
      return
    }
    setErrors(error)
  }

  return (
    <ErrorContext.Provider
      value={{
        error: errors,
        errorsMap: errorsMap,
        invokeError: invokeError,
        invokeFieldError: invokeFieldError,
        clearErrors: clearErrors,
      }}
    >
      {children}
    </ErrorContext.Provider>
  )
}

export default ErrorContext

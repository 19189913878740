import React, { useEffect, useState } from 'react'
import OnScreenAssist from '../../../../../../../components/ui/hint/OnScreenAssist'
import ServiceProviders from './setupType/ServiceProviders'

export default function FacilityMessagingSetupType({ title, description, navigation, errors, setValue, values }) {
    const [mode, setMode] = useState()
    const [name, setName] = useState()
    const [loaded, setLoaded] = useState(false)

    useEffect(() => {
        title('Messaging Service Providers')
        description('Select or create a new service provider')
        navigation([
            // {
            //     text: 'Back', type: 'Back', action: async () => { }
            // },
            // { text: 'Next', disabled: true, type: 'Next', action: async () => { } }
        ])


        // _loadSettings()
    }, [])

    useEffect(() => {
        // if (values.name && values.serviceType) {
        //     navigation([{ text: 'Next', type: 'Next', action: async () => { } }]);
        //     return;
        // }

        // navigation([{ text: 'Next', type: 'Next', disabled: true }])
    },
        [values]
    )
    // useEffect(() => { navigation([{ text: 'Next', type: 'Next', disabled: true }]) }, [mode])

    return (
        <>
            <div>
                <ServiceProviders errors={errors} setValue={setValue} values={values} />
            </div>
            <OnScreenAssist items={[{
                heading: 'How do i change the name of a service provider',
                description: 'To change a service providers name, click the current name. This will allow you to enter the new name.<br/> Press <b>enter</b> when done to confirm new name'
            }]} />
        </>
    )
}

import Calendars from "../../../../../../../libs/api/services/Calendars"
import EventDetails from "../OptionModal/CREATE_CALENDAR_EVENTComponents/EventDetails"

export const MOVE_CALENDAR_EVENT = {
    "MOVE_CALENDAR_EVENT": {
        title: '',
        onComplete: async (p) => {
            const _entry = await Calendars.CreateCalendarEntry({
                action: p['0'].entry.action,
                calendar: p['0'].entry.calendar,
                startDateTime: p['0'].entry.startDateTime,
                endDateTime: p['0'].entry.endDateTime,
                patient: p['0'].entry.patient,
                clinician: p['0'].entry.clinician,
                location: p['0'].entry.location,
                status: p['0'].entry.status,
                description: p['0'].entry.description,
                summary: p['0'].entry.summary,
            })
            return true
        },
        flows: [
            {
                onBeforeNext: async (p) => {
                    if (new Date(p['0'].entry.startDateTime) > new Date(p['0'].entry.endDateTime))
                        throw Error("Starting date / time cannot be after end date / time")
                    return { patient: { id: 1 } }
                },
                component: <EventDetails />
            },
        ]
    }
}
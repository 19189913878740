import React, { useEffect, useRef, useState } from 'react'
import Facility from '../../../../../libs/api/services/Facility'
import FacilitySearchBar from './FacilitySearchBar'
import ErrorDialog from '../../../../../components/dialogs/ErrorDialog'

export default function FacilityManagementComponent({ onError, existing, onAddFacility, onSelectFacility }) {
    const [error, setError] = useState()
    const [facaiities, setFacilities] = useState([])
    const [facility, setFacility] = useState([])
    const [showSaveAdd, setShowSaveAdd] = useState(false)
    const [mode, setMode] = useState('Search')
    const inputRef = useRef()

    const _filterExisting = (res) => {
        if (!existing)
            return res
        const _filtered = []
        const _exist_ids = []

        for (const userFacility of existing) {
            _exist_ids.push(userFacility.facility.id)
        }
        for (const facilty of res) {
            if (!_exist_ids.includes(facilty.id))
                _filtered.push(facilty)
        }
        return _filtered
    }

    const _loadFaciilties = async () => {
        Facility.list()
            .then((r) => {
                const res = r
                setFacilities(_filterExisting(r))
            })
            .catch((e) => {
                if (onError) onError(e.message)
            })
            .catch((e) => {
                setError(e.message)
                if (onError) onError(e.message)
            })
    }

    useEffect(() => {
        _loadFaciilties()
    }, [])


    useEffect(() => {
        if (inputRef.current != null)
            inputRef.current.select()
    }, [mode])

    const _searchFacility = (search) => {
        Facility.search({
            search: search
        })
            .then((r) => {
                setFacilities(_filterExisting(r))
            })
            .catch((e) => {
                setError(e.message)
                if (onError) onError(e.message)
            })
    }

    const _handleAddFacilityName = (name) => {
        if (name === "")
            setShowSaveAdd(false)
        else
            setShowSaveAdd(true)
        setFacility(name)
    }

    const _addFaciity = () => {
        Facility
            .create({
                facility: facility
            })
            .then((r) => {
                // _loadFaciilties()
                setMode('Search')
                if (onAddFacility) onAddFacility(r.id)
            })
            .catch((e) => {
                setError(e.message)
                if (onError) onError(e.message)
            })

    }

    if (mode === 'Search')
        return (
            <>
                <div className="error-details">{error}</div>
                <div className='row'>
                    <FacilitySearchBar onSearchValue={(r) => _searchFacility(r)} />
                </div>
                <div style={{ cursor: 'pointer' }} className='row text-center row card-options'>
                    <div className='col-12 col-md-4 p-3 border border-1 card-option active' key={'Facility-1'} onClick={(e) => setMode('Add')}>Add a new faciity</div>
                    {
                        facaiities.map((Facility) => {
                            return <div className='col-12 col-md-4 p-3 border border-1 card-option' onClick={(e) => onSelectFacility(Facility.id)} key={Facility.id}>{Facility.facility}</div>
                        })
                    }

                </div>
            </>
        )
    else if (mode === 'Add')
        return (
            <>
                <ErrorDialog error={error} onClose={(e) => setError('')} />
                <b>Please enter the new facilities name</b>
                <div className='row'>
                    <input ref={inputRef} value={facility} onChange={(e) => _handleAddFacilityName(e.target.value)} className='form-control form-control-lg' placeholder='Facility Name' />
                </div>
                <div className='row card-options'>
                    <div className='col-6'><div onClick={(e) => setMode('Search')} className="btn border-0 p-2 card-option w-100">Cancel</div></div>
                    <div className='col-6'><div onClick={(e) => _addFaciity()} className={"btn border-0 p-2 card-option active w-100 ".concat(showSaveAdd ? 'd-block' : 'd-none')}>Add Facility</div></div>
                </div>

            </>
        )
}

import React from 'react'
import { DayOverViewModalProvider } from './DayOverview/DayOverViewModalProvider'
import DayOverviewContainer from './DayOverview/DayOverviewContainer'

export default function DayOverviewCalendarModal({ calendarType, details, onDone }) {
  return (
    <DayOverViewModalProvider calendarType={calendarType} defaultDetails={details}>
      <DayOverviewContainer />
    </DayOverViewModalProvider>
  )
}

import React, { useState } from 'react'
import ConfirmDialog from '../../../components/dialogs/ConfirmDialog'
import ToggleButton from '../../../components/ui/toggle_button/ToggleButton'
import Authentication from '../../../libs/api/services/Authentication'

export default function AcceptTnC() {
    const [mode, setMode] = useState()

    const _handleAccept = () => {
        Authentication
            .AcceptTnC()
            .then((r) => window.location = '/' + Authentication.getFacilityRole())
    }
    const _handleDecline = () => { window.location = '/logout' }
    const [accpeted, setAccepted] = useState({
        main: false,
        facility: false
    })

    if (mode === 'CANCELLED') {
        return (
            <div>
                <ConfirmDialog type='Yes' onCancel={() => setMode(1)} onConfirm={_handleDecline} heading={'Welcome to DrData'} open={true} >
                    <p>Please note that declining use of the system will make communication, treatment plan and document keeping complex and you will miss out on features like giving your outcome evaluation and input on treatment.</p>
                    <p>Are you sure?</p>
                </ConfirmDialog>
            </div>
        )
    }
    else
        return (
            <ConfirmDialog type='Accept' onCancel={() => setMode('CANCELLED')} onConfirm={
                accpeted.main && accpeted.facility ? () => _handleAccept() : undefined
            } heading={'Welcome to DrData'} open={true} >
                <b>By continuing with this process, you agree to the terms and conditions of usage of this system and of the facility.</b>
                <div className='p-2 d-flex flex-column justify-content-center align-items-center'>
                    <div className='d-flex p-0 m-0'>
                        <span>
                            <a href="/tnc" target='_blank'>Click here to view the Terms and conditions</a>
                            <ToggleButton style={'tick'} onToggle={(x) => setAccepted({ ...accpeted, main: x })}> I accept the terms and conditions of the DrData system</ToggleButton>
                        </span>
                    </div>
                    <br />
                    <div className='d-flex'>
                        <div>
                            <a href={`/tnc/${Authentication.getFacility()}`} target='_blank' rel="noreferrer">Click here to view the Facility Terms and conditions</a>
                            <ToggleButton style={'tick'} onToggle={(x) => setAccepted({ ...accpeted, facility: x })} >I accept the terms and conditions of the facility</ToggleButton>
                        </div>
                    </div>
                </div>
            </ConfirmDialog>
        )
}

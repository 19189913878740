import React, { useContext } from 'react'
import NoteLineItem from './NoteLineItem'
import NotesContext from './NotesProvider'

export default function NoteEntries() {
    const { notes } = useContext(NotesContext)
    return (
        <div className='flex-grow-1' style={{overflow: 'auto',maxHeight:'calc(100% - 250px)'}}>
            {notes.map((n) => <NoteLineItem note={n.note} />)}
        </div>
    )
}

import React from 'react'

type Props = {
  name: string
  text: string
  style?: string
  onClick: (n: string) => void
}

const ButtonComponent = ({ name, text, style, onClick }: Props) => {
  const className = ['wizard-button']

  if (style) className.push(...style!.split(' '))

  return (
    <button className={className.join(' ')} onClick={() => onClick(name)}>
      {text}
    </button>
  )
}

export default ButtonComponent

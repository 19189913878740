import CryptoJS from 'crypto-js'

export abstract class AesEncryption {
  static AesKey: string = 'ABCDEFGHIJKLMNOPQRSTUVWXYZABCDEF'
  static AesIV: string = 'ABCDEFGHIJKLMNOP'
  static DEBUG: boolean = true
  // @ts-ignore
  public static encrypt(str: string, method: string, key: string): string | undefined {
    // return StringFunctions.encrypt(StringFunctions.ReadKeyValue(vars!), body!)
    if (this.DEBUG) console.log(str, method, key)
    const cipher = CryptoJS.AES.encrypt(str, CryptoJS.enc.Utf8.parse(this.AesKey), {
      iv: CryptoJS.enc.Utf8.parse(this.AesIV), // parse the IV
      padding: CryptoJS.pad.Pkcs7,
      mode: CryptoJS.mode.CBC,
    })
    return cipher.toString()
  }

  // @ts-ignore
  public static decrypt(str: string, method: string, key: string): string | undefined {
    // return StringFunctions.encrypt(StringFunctions.ReadKeyValue(vars!), body!)
    // console.log(str, method, key)
    const _str: string = JSON.parse(str)
    const cipher = CryptoJS.AES.decrypt(_str, CryptoJS.enc.Utf8.parse(this.AesKey), {
      iv: CryptoJS.enc.Utf8.parse(this.AesIV), // parse the IV
      padding: CryptoJS.pad.Pkcs7,
      mode: CryptoJS.mode.CBC,
    })
    const decryptedData = cipher.toString(CryptoJS.enc.Utf8)

    if (this.DEBUG) console.log(decryptedData)
    return JSON.parse(decryptedData)
  }
}

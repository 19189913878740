import React, { useState } from 'react'
import { EyeFill, EyeSlashFill } from 'react-bootstrap-icons'
import ErrorDialog from '../../../../components/dialogs/ErrorDialog'
import User from '../../../../libs/api/services/User'

export default function NewUserDetails({ changeStep, user, onChangeUser, defaultFacility }) {
    const [showPW, setShowPW] = useState(false)
    // const [user, setUser] = useState({})
    const [error, setError] = useState()
    const _handleAddUser = async () => {
        if (!user || !user.id) {
            User
                .add(user)
                .then((r) => {
                    onChangeUser(r)
                    // setUser(r)
                    if (!defaultFacility)
                        changeStep(2)
                    else
                        changeStep(4)
                })
                .catch((e) => {
                    setError(e)
                })
        } else {
            await User.edit(user.id, user)
                .catch((e) => {
                    setError(e)
                })
            if (!defaultFacility)
                changeStep(2)
            else
                changeStep(4)
        }
    }

    const _handleChangeValue = (key, value) => {
        let _user = user
        if (!_user)
            _user = {}
        _user[key] = value
        onChangeUser(_user)
    }
    return (
        <>
            <div className="head">
                <h1>Add User</h1>
                <p>Please sepecify user details below</p>
                <div className="wizard-navigation-steps">
                    <span className="wizard-navigation-step in-progress"></span>
                    <span className="wizard-navigation-step "></span>
                </div>
                <ErrorDialog error={error} humanize={true} onClose={() => setError()} />
            </div>
            <div className="body">
                <div className=".section undefined">
                    <div className="row">
                        <div className="validation-container">
                            <div className="row">
                                <div className="col-sm-2 col-md-4">
                                    <div className="validation-container">
                                        <label>Title</label>
                                        <select defaultValue={user?.title} onChange={(e) => _handleChangeValue('Title', e.target.value)} className="form-control">
                                            <option selected={user?.title === "Mr" ? '"selected"' : ''} value="Mr">Mr</option>
                                            <option selected={user?.title === "Mrs" ? '"selected"' : ''} value="Mrs">Mrs</option>
                                            <option selected={user?.title === "Miss" ? '"selected"' : ''} value="Miss">Miss</option>
                                            <option selected={user?.title === "Ms" ? '"selected"' : ''} value="Ms">Ms</option>
                                            <option selected={user?.title === "Dr" ? '"selected"' : ''} value="Dr">Dr</option>
                                            <option selected={user?.title === "Prof" ? '"selected"' : ''} value="Prof">Prof</option>
                                            <option selected={user?.title === "Other" ? '"selected"' : ''} value="Other">Other</option>
                                        </select>
                                        <div className="validation-details"></div>
                                    </div>
                                </div>
                                <div className="col-sm-10 col-md-4">
                                    <div className="validation-container">
                                        <label>First Name</label>
                                        <input defaultValue={user?.firstName} onChange={(e) => _handleChangeValue('firstName', e.target.value)} className="wizard-input form-control" placeholder="First Name" />
                                        <div className="validation-details"></div>
                                    </div>
                                </div>
                                <div className="col-sm-12 col-md-4">
                                    <div className="validation-container">
                                        <label>Last Name</label>
                                        <input defaultValue={user?.lastName} onChange={(e) => _handleChangeValue('lastName', e.target.value)} className="wizard-input form-control" placeholder="Last Name" />
                                        <div className="validation-details"></div>
                                    </div>
                                </div>
                                {/* <div className="col-6">
                                    <div className="validation-container">
                                        <label>Username</label>
                                        <input defaultValue={user?.username} onChange={(e) => _handleChangeValue('username', e.target.value)} className="wizard-input form-control" placeholder="Username" />
                                        <div className="validation-details"></div>
                                    </div>
                                </div> */}
                                
                                <div className="col-6">
                                    <div className="validation-container">
                                        <label>ID/Passport Number</label>
                                        <input defaultValue={user?.IDNumber} onChange={(e) => _handleChangeValue('IDNumber', e.target.value)} className="wizard-input form-control" placeholder="ID Number" />
                                        <div className="validation-details"></div>
                                    </div>
                                </div>
                                <div className="col-6"><div className="validation-container">
                                    <label>Password</label>
                                    <div className='d-flex position-relative'>
                                        <input
                                            defaultValue={user?.password} onChange={(e) => _handleChangeValue('password', e.target.value)}
                                            type={showPW ? "text" : "password"} className="wizard-input form-control"
                                            placeholder="Password" />
                                        <button className='btn position-absolute' style={{ zIndex: 2, right: '0px' }} onClick={() => setShowPW(!showPW)}>
                                            {showPW ? <EyeSlashFill /> : <EyeFill />}
                                        </button>
                                    </div>
                                    <div className="validation-details"></div>
                                </div>
                                </div>
                                <div className="col-6">
                                    <div className="validation-container">
                                        <label>Email Address</label>
                                        <input defaultValue={user?.emailAddress} onChange={(e) => _handleChangeValue('emailAddress', e.target.value)} className="wizard-input form-control" placeholder="Email Address" />
                                        <div className="validation-details"></div>
                                    </div>
                                </div>
                                <div className="col-6"><div className="validation-container">
                                    <label>Mobile Number</label>
                                    <input defaultValue={user?.mobileNumber} onChange={(e) => _handleChangeValue('mobileNumber', e.target.value)} className="wizard-input form-control" placeholder="Mobile Number" />
                                    <div className="validation-details"></div>
                                </div>
                                </div>
                            </div>
                            <div className="validation-details"></div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="tail"><button onClick={(e) => _handleAddUser()} className="btn ng-buttons">Next</button></div>
        </>
    )
}

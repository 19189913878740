import React from 'react'
import ToDoListItemContainer from '../Items/ToDoListItemContainer'

export default function ToDoListGroupContainer({ item }) {

    return (
        <>
            <div className='m-2 shadow rounded p-2 text-center bg-light'>
                <b>{item.taskGroup}</b>
                {item.taskItems.map((i, x) => <ToDoListItemContainer key={`ItemsC-${item.assignmentId}-${x}`} assignmentId={item.assignmentId} item={i} />)}
            </div>
        </>

    )
}

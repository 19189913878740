import React, { useRef, useState } from 'react'
import './FileComponent.css'

// type Props = {
//   name: string
//   onChange: (name: string, value: any) => void
// }

export function decodeB64String(str) {
  return {
    type: str.split(':', 1)[0],
    mime: str.substring(str.indexOf(':') + 1, str.indexOf(';')),
    base64: str.substring(str.indexOf(';'), str.indexOf(',')) === ';base64',
    data: str.substring(str.indexOf(',') + 1)
  }
}
export async function getBase64(file) {
  return new Promise((resolve) => {
    //   let baseURL = ''
    // Make new FileReader
    const reader = new FileReader()

    // Convert the file to base64 text
    reader.readAsDataURL(file)

    // on reader load somthing...
    reader.onload = async () => {
      // Make a fileInfo Object
      // baseURL = reader.result
      resolve(reader.result)
    }
  })
}

export default function FileUpload({ onFilesSelected }) {
  const [processingFile, setProcessingFile] = useState(false)

  const [dragActive, setDragActive] = useState(false)
  const inputRef = useRef(null)

  // handle drag events
  const handleDrag = function (e) {
    e.preventDefault()
    e.stopPropagation()
    if (e.type === 'dragenter' || e.type === 'dragover') {
      setDragActive(true)
    } else if (e.type === 'dragleave') {
      setDragActive(false)
    }
  }

  // triggers when file is selected with click
  const handleChange = function (e) {
    onFilesSelected(e.target.files)
    if (e.target.files && e.target.files) {
      // at least one file has been selected so do something
      // handleFiles(e.target.files);
      handleFileInputChange(e.target.files[0])
    }
  }
  // // triggers the input when the button is clicked
  const onButtonClick = () => {
    inputRef.current.click()
  }
  // // triggers when file is dropped
  const handleDrop = function (e) {
    e.preventDefault()
    e.stopPropagation()
    setDragActive(false)
    if (e.dataTransfer.files && e.dataTransfer.files) {
      onFilesSelected(e.dataTransfer.files)
      // handleFileInputChange(e.dataTransfer.files[0])
      // at least one file has been dropped so do something
      // handleFiles(e.dataTransfer.files);
    }
  }



  function validateFileFormat(format) {
    // let formats: string = ''
    // if (!formats) {
    //   formats = 'application/json,application/pdf,image/jpeg'
    // }
    // if (!formats.includes(format)) {
    //   // toast.error(Intl("claims.proces.upload.formatnotsupported.message"), {
    //   //     position: "top-right",
    //   //     autoClose: 5000,
    //   //     hideProgressBar: true,
    //   //     closeOnClick: true,
    //   //     pauseOnHover: true,
    //   //     draggable: true,
    //   //     progress: undefined,
    //   // });
    //   return false
    // }
    return true
  }
  function handleFileInputChange(file) {
    // setProcessingFile(true)
    // if (validateFileFormat(file.type)) {
    //   getBase64(file)
    //     .then((result) => {
    //       props.onChange(props.name, { fileName: file.name, fileType: file.type, fileData: result })

    //       setProcessingFile(false)
    //     })
    //     .catch((err) => {
    //       setProcessingFile(false)
    //     })
    // } else {
    //   setProcessingFile(false)
    // }
  }

  return (
    <>
      <form id='form-file-upload'
        className='m-3'
        onClick={(e) => onButtonClick()}
      // onDragEnter={handleDrag} onSubmit={(e) => e.preventDefault()}
      >

        <input
          ref={inputRef}
          type='file'
          id='input-file-upload' multiple={true}
          onChange={(e) => handleChange(e)}
        />
        <label id='label-file-upload' htmlFor='input-file-upload'
        //  className={dragActive ? 'drag-active' : ''}
        >
          <div>
            <p>Drag and drop your file here or</p>

            <button className='upload-button'
              onClick={(e) => onButtonClick()}
            >
              Upload a file
            </button>
          </div>
        </label>
        {/* {dragActive && ( */}
        <div
          id='drag-file-element'
          onDragEnter={handleDrag}
          onDragLeave={handleDrag}
          onDragOver={handleDrag}
          onDrop={handleDrop}
        ></div>
        {/* )} */}

      </form>
    </>
  )
}

import API from "../API";

var WorkFlow = {
    async ListGroups(FacilityId) {
        var result = await API.process(
            FacilityId === null ? `api/WorkFlow/Categories` : `api/WorkFlow/Categories/${FacilityId}`,
            'GET',
            null
        ).catch((err) => {
            throw err;
        })

        return result
    },
    async UpdateGroup(g) {
        var result = await API.process(
            'api/WorkFlow/Category',
            'PUT',
            g
        ).catch((err) => {
            throw err;
        })

        return result
    },
    async RemoveGroup(id) {
        var result = await API.process(
            `api/WorkFlow/Category/${id}`,
            'DELETE',
            null
        ).catch((err) => {
            throw err;
        })

        return result
    },
    async UpdateEvent(g) {
        var result = await API.process(
            'api/WorkFlow/Event',
            'PUT',
            g
        ).catch((err) => {
            throw err;
        })

        return result
    },
    async ListCategoryEvents(CategoryId) {
        var result = await API.process(
            `api/WorkFlow/Category/${CategoryId}/Events`,
            'GET',
            null
        ).catch((err) => {
            throw err;
        })

        return result
    },
    /*
    Linked
    */
    async ListTriggerTypesByType(EventId, Type) {
        var result = await API.process(
            `api/WorkFlow/Event/${EventId}/Trigger/Type/${Type}`,
            'GET',
            null
        ).catch((err) => {
            throw err;
        })

        return result
    },

    async ListEventsByTriggerGroup(Group) {
        var result = await API.process(
            `api/WorkFlow/Events/ByTriggerGroup/${Group}`,
            'GET',
            null
        ).catch((err) => {
            throw err;
        })

        return result
    },
    async ToggleEventTaskType(EventId, Type) {
        var result = await API.process(
            `api/WorkFlow/Event/${EventId}/Trigger/Type/${Type}`,
            'PATCH',
            null
        ).catch((err) => {
            throw err;
        })

        return result
    },
    /*
    Triggers
    */
    // Depricated to types linked for performance
    async ListTriggerTypes() {
        var result = await API.process(
            'api/WorkFlow/Trigger/Types',
            'GET',
            null
        ).catch((err) => {
            throw err;
        })

        return result
    },
    async ListTriggerTypesLinked(EventId) {
        var result = await API.process(
            `api/WorkFlow/Trigger/Types/Linked/${EventId}`,
            'GET',
            null
        ).catch((err) => {
            throw err;
        })

        return result
    },
    /*
    Properties
    */
    async SetTriggerProperty(EventId, TriggerId, Property) {
        var result = await API.process(
            `api/WorkFlow/Event/${EventId}/Trigger/${TriggerId}/Property`,
            'PATCH',
            Property
        ).catch((err) => {
            throw err;
        })

        return result
    },
    async GetTriggerPropertyByType(EventId, TriggerId, Property) {
        var result = await API.process(
            `api/WorkFlow/Event/${EventId}/Trigger/${TriggerId}/Properties?Property=${Property}`,
            // `api/WorkFlow/Event/${EventId}/Trigger/${TriggerId}/Properties`,
            'GET',
            null
        ).catch((err) => {
            throw err;
        })

        return result
    },
    async UpdateEventDetails(EventId, Event) {
        var result = await API.process(
            `api/WorkFlow/Event/${EventId}`,
            // `api/WorkFlow/Event/${EventId}/Trigger/${TriggerId}/Properties`,
            'PATCH',
            Event
        ).catch((err) => {
            throw err;
        })

        return result
    },
    /*
    Tasks
    */

    async ListTask(EventId) {
        var result = await API.process(
            `api/WorkFlow/Event/${EventId}/Tasks`,
            'GET',
            null
        ).catch((err) => {
            throw err;
        })

        return result
    },

    async ListTaskFilterUser(EventId, PatientId) {
        var result = await API.process(
            `api/WorkFlow/Event/${EventId}/Tasks/Patient/${PatientId}`,
            'GET',
            null
        ).catch((err) => {
            throw err;
        })

        return result
    },
    async RemoveTask(TaskId) {
        var result = await API.process(
            `api/WorkFlow/Task/${TaskId}`,
            'DELETE',
            null
        ).catch((err) => {
            throw err;
        })

        return result
    },

    /*
        Task Types
        ListTaskTypes
    */

    async ListTaskTypes() {
        var result = await API.process(
            `api/WorkFlow/Task/Types`,
            'GET',
            null
        ).catch((err) => {
            throw err;
        })

        return result
    },

    async SetWorkFlowEventTaskProperty(EventId, PropertyName, property) {
        var result = await API.process(
            `api/WorkFlow/Event/${EventId}/Task/Property/${PropertyName}`,
            'PUT',
            property
        ).catch((err) => {
            throw err;
        })

        return result
    },
    /*
    Assistant 
    */
    async GetPreConfiguration(TaskId, Request) {
        var result = await API.process(
            `api/WorkFlow/Assistant/PreConfig/Task/${TaskId}`,
            'PATCH',
            Request
        ).catch((err) => {
            throw err;
        })

        return result
    },
    async ProcessTask(TaskId, Request) {
        var result = await API.process(
            `api/WorkFlow/Assistant/Process/Task/${TaskId}`,
            'PATCH',
            Request
        ).catch((err) => {
            throw err;
        })

        return result
    },

    /*
     * Automation
     */
    async TriggerAutomation(Module, Trigger, Payload) {
        var result = await API.process(
            `api/WorkFlow/Automate/Trigger/${Module}/${Trigger}`,
            'POST',
            Payload
        ).catch((err) => {
            throw err;
        })

        return result
    },
    /*
    * Logs
    */
    async GetWorkFlowLogs(EventId) {
        var result = await API.process(
            `api/WorkFlow/Logs/${EventId}`,
            'GET',
            null
        ).catch((err) => {
            throw err;
        })

        return result
    },
    async GetWorkFlowEventHistory(EventId) {
        var result = await API.process(
            `api/WorkFlow/Event/Logs/${EventId}`,
            'GET',
            null
        ).catch((err) => {
            throw err;
        })

        return result
    },
    async GetWorkFlowCalendarEventHistory(CalendarEntryId) {
        var result = await API.process(
            `api/WorkFlow/Event/Logs/Caelndar/${CalendarEntryId}`,
            'GET',
            null
        ).catch((err) => {
            throw err;
        })

        return result
    },
    async GetWorkFlowToDoListHistory(toDoListId) {
        var result = await API.process(
            `api/WorkFlow/Event/Logs/ToDoList/${toDoListId}`,
            'GET',
            null
        ).catch((err) => {
            throw err;
        })

        return result
    },
    async GetWorkFlowPatientEventHistory(PatientId) {
        var result = await API.process(
            `api/WorkFlow/Event/Logs/Patient/${PatientId}`,
            'GET',
            null
        ).catch((err) => {
            throw err;
        })

        return result
    },
    async LinkMessageToWorkFlow(WorkFlowId, MessageId) {
        var result = await API.process(
            `api/WorkFlow/${WorkFlowId}/Message/Link/${MessageId}`,
            'PATCH',
            null
        ).catch((err) => {
            throw err;
        })

        return result
    },
    async allocateMessagingTask(EventID, TemplateID) {
        var result = await API.process(
            `api/WorkFlow/allocateMessagingTask/${EventID}/${TemplateID}`,
            'PATCH',
            null
        ).catch((err) => {
            throw err;
        })

        return result
    },
    async UploadDocument(EventID, ufile) {
        var result = await API.process(
            `api/WorkFlow/Document/${EventID}`,
            'POST',
            ufile
        ).catch((err) => {
            throw err;
        })

        return result
    },

    async RemoveFile(FileID) {
        var result = await API.process(
            `api/WorkFlow/Document/${FileID}`,
            'DELETE',
            null
        ).catch((err) => {
            throw err;
        })

        return result
    },
    async GetDocument(FileID) {
        var result = await API.process(
            `api/WorkFlow/Document/${FileID}`,
            'GET',
            null
        ).catch((err) => {
            throw err;
        })

        return result
    },

}

export default WorkFlow;
import React, { useCallback, useEffect, useState } from 'react'
import './WizardTabls.css'
// import SpreadsheetFunctions from '../../common/SpreadsheetFunctions'
// import FileFunctions from '../../common/FileFunctions'
import { TableInterface } from './interfaces/TableInterface'
import { StringFunctions } from '../../common/StringFunctions'

type Props = {
  name: string
  onChange: (name: string, value: any) => void
  data: TableInterface | undefined
}

export default function TableComponent(props: Props) {
  const [table, setTable] = useState<TableInterface>()
  const _format = (val: any) => {
    let _val
    if (!val) _val = ''
    else if (typeof val === 'object')
      _val = Object.keys(val).map((n) => {
        return typeof val[n] === 'undefined' ? (
          ''
        ) : !val[n] || val[n] === '' ? (
          ''
        ) : (
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <span style={{ fontWeight: '700' }}>{StringFunctions.Humanize(n)}</span>
            <span>{StringFunctions.Humanize(val[n])}</span>
          </div>
        )
      })
    else if (Array.isArray(val)) _val = '[]'
    else if (typeof val === 'undefined') _val = ''
    else _val = val
    return _val
  }
  const _render = () => {
    const _table = []
    if (table)
      for (const row of table.rows) {
        const _row = []
        for (const column of row.columns) {
          _row.push(<td>{_format(column.feild.value)}</td>)
        }
        _table.push(<tr>{_row}</tr>)
      }
    return (
      <table key={props.name.concat('Table')} className='wizard-table'>
        <tbody>{_table}</tbody>
      </table>
    )
  }

  const loadData = useCallback(() => {
    if (props.data) setTable(props.data)
    // if (data) setTable(SpreadsheetFunctions.csvToTable(new FileFunctions().ReadMimeFile(data.fileData).contents))
  }, [props])
  useEffect(() => {
    loadData()
  }, [props.data, loadData])

  return <>{table! ? _render() : <div>No Data Loaded</div>}</>
}

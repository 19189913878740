import React, { useEffect, useState } from 'react'
import WorkFlow from '../../../../../../libs/api/services/WorkFlow'

const DocumentsContext = React.createContext()

export const DocumentsProvider = ({ event, defaultValue, children }) => {
    const [docs, setDocs] = useState(defaultValue ? defaultValue : [])
    useEffect(() => { setDocs(defaultValue) }, [defaultValue])

    const _handleSendToServer = async (file) => {
        // var res = { ...file, fileName: file.name, status: 'Uploading' }
        // console.log('event',event.id)
        var res = await WorkFlow.UploadDocument(event.id, {
            document: {
                fileName: file.fileName,
                mimeType: file.mimeType,
                data: file.data
            }
        })
        return res ? 'Uploaded' : 'Failed'
    }

    const _handleBase64 = async (file) => {
        return await await new Promise((resolve) => {
            const reader = new FileReader()
            reader.readAsDataURL(file)
            reader.onload = async () => {
                resolve(reader.result)
            }
        })
    }

    const _handleUploadFiles = async (files) => {
        var _docs = docs
        var _newdocs = []

        // Send to front end
        for (const file of files) {
            _newdocs.push({ document: { ...file, mimeType: file.type, fileName: file.name, status: 'Uploading', data: await _handleBase64(file) } })
            setDocs([..._docs, ..._newdocs])
        }
        // console.log([..._docs,..._newdocs])
        // Get base 64 / update status
        for (var _doc of _newdocs) {
            _doc.document.status = await _handleSendToServer(_doc.document)
            setDocs([..._docs, ..._newdocs])
        }
    }

    const _handleRemove = async (filesId) => {
        setDocs(docs.filter(f => f.document.id !== filesId))
        WorkFlow
            .RemoveFile(filesId)
    }
    const _handleGetDocument = async (filesId) => await WorkFlow
        .GetDocument(filesId)

    return (
        <DocumentsContext.Provider value={{
            documents: docs,
            uploadFiles: (files) => _handleUploadFiles(files),
            remove: _handleRemove,
            getFile: (id) => _handleGetDocument(id)
        }}>
            {children}
        </DocumentsContext.Provider>
    )
}

export default DocumentsContext
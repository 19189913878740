import API from "../API";

var TNC = {
    async UploadTermsAndConditionsForFacility(ID, data) {
        var result = await API.process(
            `api/TNC/Facility/${ID}`,
            'POST',
            data
        ).catch((err) => {
            throw err;
        })

        return result
    },
    async UploadTermsAndConditionsForSystem(data) {
        var result = await API.process(
            `api/TNC/System`,
            'POST',
            data
        ).catch((err) => {
            throw err;
        })

        return result
    },
    async GetTermsAndConditions() {
        var result = await API.process(
            `api/TNC`,
            'GET',
            null
        ).catch((err) => {
            throw err;
        })

        return result
    },
    async GetTermsAndConditionsForFacility(facilityId) {
        var result = await API.process(
            `api/TNC/${facilityId}`,
            'GET',
            null
        ).catch((err) => {
            throw err;
        })

        return result
    },
    async setDefaultTNC(tnc) {
        var result = await API.process(
            `api/TNC/Default`,
            'POST',
            tnc
        ).catch((err) => {
            throw err;
        })

        return result
    },
}

export default TNC;
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from 'react';
import { ExclamationCircle } from 'react-bootstrap-icons';
import WorkFlowConfigurationContext from '../../../workflow/configuration/WorkFlowConfigurationProvider';
import ToDoSelector from './Components/ToDoSelector';

export default function EventTriggerToDoListStarted({ link, type, values, eventId, filterTrigger }) {
    const { saveProperties, loadProperties } = useContext(WorkFlowConfigurationContext)
    const PROPERTY_TYPE = 'to_do_list_started';
    const [properties, setProperties] = useState()

    useEffect(() => {
        const _load = async () => setProperties(await loadProperties(PROPERTY_TYPE, link))
        _load()
    }, [])
    useEffect(() => { if (properties) saveProperties(PROPERTY_TYPE, link, properties) }, [properties])

    return (
        <div>
            <ToDoSelector
                mode={'no-status'}
                defaultValue={properties && properties.listDetails}
                onChange={(e) => setProperties({ ...properties, listDetails: e })}
            />
            {properties && properties.listDetails && !properties.listDetails.list &&
                <div className='bg-warning-container p-1 text-center rounded'>
                    <ExclamationCircle className='me-2' />
                    Note that by not selecting an item each item will be triggered in this list
                </div>
            }
        </div>
    )
}

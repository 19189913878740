/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'
// import { ToDoLists } from '../../../config/ToDoLists'
import ToDoListService from '../../../../../../libs/api/services/ToDoListService'

export default function ToDoSelector({ onChange, defaultValue, mode }) {

    const [todos, setTodos] = useState()
    const [properties, setProperties] = useState(defaultValue ? defaultValue : undefined)
    const [cMode, setCMode] = useState(mode ? mode : 'All')

    const LoadToDoLists = async () => setTodos(await ToDoListService.ListToDoLists())

    useEffect(() => { LoadToDoLists() }, [])

    const _handleSetGroup = (g) => {
        if (onChange) onChange({ ...properties, group: g, item: undefined, list: undefined })
        setProperties({ ...properties, group: g, item: undefined, list: undefined })
    }

    const _handleSetItem = (i) => {
        if (onChange) onChange({ ...properties, list: i, item: undefined })
        setProperties({ ...properties, list: i, item: undefined })
    }

    const _handleSetStatus = (s) => {
        if (onChange) onChange({ ...properties, item: s })
        setProperties({ ...properties, item: s })
    }

    // useEffect(() => {
    //     if (onChange && properties) onChange(properties)
    // }, [properties])
    useEffect(() => {
        if (defaultValue) setProperties(defaultValue)
    }, [defaultValue])
    // if (!todos) return
    return (
        <div>
            {
                cMode === 'All' || cMode === 'no-status' || cMode === 'list'
                    ?
                    <div>
                        <b>List</b>
                        <select
                            onChange={(e) => _handleSetGroup(e.target.value)}
                            className='form-control form-control-sm'>
                            <option></option>
                            {todos && todos.map((g) => <option selected={properties && properties.group && properties.group === g.id} value={g.id} key={g.id}>{g.group}</option>)}
                        </select>
                    </div>
                    :
                    <></>
            }
            {
                cMode === 'All' || cMode === 'no-status'
                    ?
                    <div>
                        <b>Item</b>
                        <select
                            onChange={(e) => _handleSetItem(e.target.value)}
                            className='form-control form-control-sm'>
                            <option></option>
                            {todos && properties && properties.group ? todos.find((x) => x.id === properties.group).lists
                                .map((i) => <option selected={properties && properties.list && properties.list === i.id} value={i.id} key={i.id}>{i.list}</option>) : undefined}
                        </select>
                    </div>
                    :
                    <></>
            }
            {
                cMode === 'All' || cMode === 'status'
                    ?
                    <div>
                        <b>Status</b>
                        <select
                            onChange={(e) => _handleSetStatus(e.target.value)}
                            className='form-control form-control-sm'>
                            <option></option>
                            {todos && properties && properties.group && properties.list && todos.find((x) => x.id === properties.group).lists
                                .find((x) => x.id === properties.list).items
                                .map((i) => <option selected={properties && properties.item && properties.item === i.id} value={i.id} key={i.id}>{i.item}</option>)
                            }
                        </select>
                    </div>
                    :
                    <></>
            }

        </div>
    )
}

import React, { useContext } from 'react'
import { Calendar2, ClockFill } from 'react-bootstrap-icons'
import { ICONS } from '../../../../../../components/icons/GenericIconPicker'
import WorkFlowManagementContext from '../../WorkFlowManagementProvider'
import CalendarEntryDetails from './CalendarEntryDetails'

export default function CalendarEntry({ item }) {

    const { extendedModal } = useContext(WorkFlowManagementContext)
    const _findColour = () => {
        // calendarEntry > action > properties > [name=BACKGROUND_COLOUR] > value
        if (item.calendarEntry && item.calendarEntry.action && item.calendarEntry.action.properties) {
            const _PROP = item.calendarEntry.action.properties.find(f => f.name === 'BACKGROUND_COLOUR')
            if (_PROP)
                try { return JSON.parse(_PROP.value) } catch (e) { }
        }
        return undefined
    }
    const _findIcons = () => {
        // calendarEntry >action > properties > [name=ICONS] > value
        if (item.calendarEntry && item.calendarEntry.action && item.calendarEntry.action.properties) {
            const _PROP = item.calendarEntry.action.properties.find(f => f.name === 'ICONS')
            if (_PROP)
                try {
                    var res = []
                    for (const i of JSON.parse(_PROP.value))
                        res.push(<div className='pe-2'>{ICONS.find(x => x.name === i).icon}</div>)
                    return res
                } catch (e) { }
        }
    }
    
    return (
        <div>
            <div
                style={{ cursor: 'pointer', background: _findColour() }}
                onClick={() => extendedModal({
                    show: true, icon: <Calendar2 size={25} />, title: `Calendar Event ${item.calendarEntry.startDateTime}`, 
                    component: <CalendarEntryDetails item={item} />
                })}
                className='shadow rounded p-2 bg-secondary-container' >
                <div className='d-flex text-end justify-content-between'>
                    <div>
                        <b>{item.calendarEntry.summary && item.calendarEntry.summary}</b>
                    </div>
                    <div className='d-flex'>
                        {_findIcons()}
                    </div>
                </div>
                <div><ClockFill className='me-2' />{item.calendarEntry.startDateTime} - {item.calendarEntry.endDateTime}</div>

                <div><div dangerouslySetInnerHTML={{ __html: item.calendarEntry.description }} /></div>
            </div>
        </div>
    )
}

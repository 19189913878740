import { faCheck, faDownload, faExclamationCircle, faSpinner, faTrash } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useContext } from 'react'
import DocumentsContext from './DocumentsProvider'

export default function DocumentLineItem({ item, hideRemove }) {
    const { remove, getFile } = useContext(DocumentsContext)

    async function openBase64InNewTab(response) {
        try {
            //data, mimeType, filename
            var byteCharacters = atob(response.data.substr(response.data.indexOf(';base64,') + 8));
            // var byteCharacters = data.substr(data.indexOf(';base64,') + 8);
            var byteNumbers = new Array(byteCharacters.length);
            for (var i = 0; i < byteCharacters.length; i++) {
                byteNumbers[i] = byteCharacters.charCodeAt(i);
            }
            var byteArray = new Uint8Array(byteNumbers);
            var file = new Blob([byteArray], { type: response.mimeType + ';base64' });
            // var file = new Blob([byteArray]);
            var fileURL = URL.createObjectURL(file);

            var anchor = document.createElement('a');
            anchor.href = fileURL;
            anchor.download = response.fileName;
            anchor.click();
            window.URL.revokeObjectURL(fileURL);

            window.open(fileURL);
        } catch (e) { }
    }
    const _status = (status) => {
        switch (status) {
            case 'Uploading': return <FontAwesomeIcon icon={faSpinner} spin />
            case 'Uploaded': return <FontAwesomeIcon icon={faCheck} />
            case 'Failed': return <FontAwesomeIcon icon={faExclamationCircle} />
            default: return <></>
        }
    }
    return (item ? <div className='d-flex bg-secondary-container justify-content-between m-1 rounded p-2 shadow'>
        <div className='flex-grow-1 text-start'>
            <div>{item.fileName}</div>
        </div>
        <div className='d-flex'>
            <div className='me-2'>{_status(item.status)}</div>
            {
                !item.status &&
                <>
                    {!hideRemove && <div onClick={() => remove(item.id)} className='me-2'><FontAwesomeIcon icon={faTrash} /></div>}
                    <div onClick={async () => openBase64InNewTab(await getFile(item.id))} className='me-2'><FontAwesomeIcon icon={faDownload} /></div>
                </>
            }
        </div>
    </div>
        :
        <div>Processing</div>
    )
}

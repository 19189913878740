import API from "../API";

var Dashboards = {
    async Patient() {
        var result = await API.process(
            'api/Dashboard/Patient',
            'GET',
            null
        ).catch((err) => {
            throw err;
        })

        return result
    },
    async ClinicianAlerts() {
        var result = await API.process(
            'api/Dashboard/Clinician/Alerts',
            'GET',
            null
        ).catch((err) => {
            throw err;
        })

        return result
    },
    async SetAlertStatus(id, status) {
        var result = await API.process(
            `api/Dashboard/Clinician/Alert/${id}/${status}`,
            'PUT',
            null
        ).catch((err) => {
            throw err;
        })

        return result
    },
    async UnreadMessageCount() {
        var result = await API.process(
            `api/Dashboard/UnreadMessages`,
            'GET',
            null
        ).catch((err) => {
            throw err;
        })

        return result
    },
    async CalendarStatus() {
        var result = await API.process(
            `api/Dashboard/Calendar`,
            'GET',
            null
        ).catch((err) => {
            throw err;
        })

        return result
    },
    async GetDashboardWorkflowCards(type) {
        var result = await API.process(
            `api/Dashboard/WorkFlowCards/${type}`,
            'GET',
            null
        ).catch((err) => {
            throw err;
        })

        return result
    },
    async ToDoListStatus(type) {
        var result = await API.process(
            `api/Dashboard/ToDoList`,
            'GET',
            null
        ).catch((err) => {
            throw err;
        })

        return result
    },
}

export default Dashboards;
import React, { useState } from 'react'
import ErrorDialog from '../../../../components/dialogs/ErrorDialog'
import AudienceGroupComponent from './components/UserSelection/AudienceGroupComponent'
import { useEffect } from 'react'
import MessagingService from '../../../../libs/api/services/MessagingService'
import { Bullseye, ChatFill, Cursor, Pencil, PeopleFill, PersonPlusFill, Trash, Whatsapp } from 'react-bootstrap-icons'
import { faTrash } from '@fortawesome/free-solid-svg-icons'
import UserSelectionComponent from './components/UserSelection/UserSelectionComponent'
import ToastDialog from '../../../../components/dialogs/toast_dialog/ToastDialog'

export default function MessagingAudienceAdmin() {
    const [errors, setErrors] = useState('')
    const [groups, setGroups] = useState([])
    const [group, setGroup] = useState({})
    const [users, setUsers] = useState([])
    const [showConfirm, setShowConfirm] = useState(false)
    const [mode, setMode] = useState('')
    const [status, setStatus] = useState({ key: '', message: '', icon: '', className: '', time: 3, onTimeOut: () => { } })

    const _handleSetGroup = (id) => {
        if (id === "-1") {
            setUsers([])
            setGroup({})
        } else {
            const _grp = groups.find(i => i.id === id)
            setUsers(_grp.messageGroupAudience)
            setGroup(_grp)
        }
    }
    const loadAudienceGroups = async () => {
        return await MessagingService
            .LoadAudienceGroupsAudienceGroups()
            .then((r) => setGroups(r))
            .catch((e) => { if (e.errors) setErrors(JSON.stringify(e.errors)); else setErrors(e.message); })
    }
    useEffect(() => {
        loadAudienceGroups()
    }, [])

    const _handleAddUser = (user) => {
        var _users = [...users]
        _users.push({ user: user })
        setUsers(_users)
    }
    const _handleRemoveUser = (idx) => {
        var _users = [...users]
        _users.splice(idx, 1)
        setUsers(_users)
    }
    const _handleRemoveGroup = () => {
        MessagingService
            .RemoveAudienceGroup(group.id)
            .then((r) => {
                loadAudienceGroups()
                setGroup({})
                setUsers([])
                setStatus({ ...status, key: new Date(), message: 'Group removed', time: 5 })
            })
            .catch((e) => { if (e.errors) setErrors(JSON.stringify(e.errors)); else setErrors(e.message); })
    }
    const _handleSetGroupName = () => {
        var _users = [];
        if (users) {
            for (var usr of users) {
                _users.push({
                    User: usr.user
                })
            }
        }
        MessagingService
            .SetAudienceGroup({
                id: group.id,
                name: group.name,
                MessageGroupAudience: _users
            })
            .then((r) => {
                loadAudienceGroups()
                setStatus({ ...status, key: new Date(), message: 'Group updated', time: 5 })
            })
            .catch((e) => { if (e.errors) setErrors(JSON.stringify(e.errors)); else setErrors(e.message); })

    }

    const _handleAddGroup = () => {
        var _users = [];
        if (users) {
            for (var usr of users) {
                _users.push({
                    User: usr.user
                })
            }
        }
        MessagingService
            .SetAudienceGroup({
                id: group.id,
                name: group.name,
                MessageGroupAudience: _users
            })
            .then((r) => {
                setGroup(r)
                loadAudienceGroups()
                    .then((e) => {
                        setMode('Existing')
                        setStatus({ ...status, key: new Date(), message: 'Group created', time: 5 })
                    })
            })
            .catch((e) => { if (e.errors) setErrors(JSON.stringify(e.errors)); else setErrors(e.message); })
    }

    return (
        <>
            <ErrorDialog error={errors} onClose={() => setErrors('')} />
            <ToastDialog {...status} />
            {
                showConfirm ?
                    <div
                        className='position-absolute d-flex align-items-center justify-content-center'
                        style={{ left: 0, right: 0, top: 0, bottom: 0, zIndex: 102 }}
                    >
                        <div
                            className='bg-secondary-container rounded shadow p-3 text-center'
                            style={{ maxWidth: '450px', zIndex: 102 }}
                        >
                            <h3>Confirm Remove</h3>
                            <div>Removing a group will no longer allow sending / receiving messages using accounts linked to the group. <br /><br />Are you sure you want to remove this audience group.</div>
                            <button
                                onClick={(e) => { setShowConfirm(false); _handleRemoveGroup() }}
                                className='btn'>Yes</button>
                            <button
                                onClick={(e) => { setShowConfirm(false) }}
                                className='btn'>No</button>
                        </div>
                    </div>
                    :
                    <></>
            }
            <div
                className="Wizard top-center position-relative">
                <div className="head"><h1>Message Audience</h1>
                    <p>Message audience groups allows you to create a group of pre-defined users to send/receive messages</p>

                    <div
                        className="error-details"></div>
                </div>
                <div className="body">
                    <div
                        className=".section d-flex flex-column align-items-stretch h-100 w-100">
                        <div className="border border-1 rounded m-3">
                            <div
                                className="row p-3 shadow rounded bg-primary-container">
                                <div
                                    className="col-12">
                                    <div className='d-flex justify-content-center' style={{ cursor: 'pointer' }}>
                                        <div onClick={(e) => { setGroup({}); setUsers([]); setMode('New') }} className={'m-3 p-2 rounded '.concat(mode === 'New' ? 'active ' : '')}>
                                            <PersonPlusFill size={50} />
                                            <div>
                                                Create a new audience group
                                            </div>
                                        </div>
                                        <div onClick={(e) => setMode('Existing')} className={'m-3 p-2 rounded '.concat(mode === 'Existing' ? 'active ' : '')}>
                                            <PeopleFill size={50} />
                                            <div>
                                                Manage an existing audience group
                                            </div>
                                        </div>
                                    </div>
                                    {
                                        mode === 'New'
                                            ?
                                            <div className="text-start">
                                                {
                                                    <div>
                                                        <label>Audience group name eg: Demo Clinic clinicians contact list</label>
                                                        <input
                                                            defaultValue={group.name}
                                                            onChange={(e) => setGroup({ ...group, name: e.target.value })}
                                                            className='form-control'
                                                            placeholder='Group Name eg: Demo Clinic clinicians contact list' />
                                                        <div className='m-2'>
                                                            {/* <h3>{name} <Pencil onClick={(e) => setStep('GROUP_NAME')} /></h3> */}
                                                        </div>
                                                        <label>Start typing the users name to add to the list of recipients to send and receive messages</label>
                                                        <UserSelectionComponent className={"m-0 p-0"} clearOnSelect={true} onUserSelectedDetails={(i) => _handleAddUser(i)} />
                                                        {
                                                            users.map((u, idx) =>
                                                                <div className='d-flex m-3 row'>
                                                                    <div className='col-4'>{u.user.firstName} {u.user.lastName}</div>
                                                                    <div className='col-3'>{u.user.mobileNumber}</div>
                                                                    <div className='col-3'>{u.user.emailAddress}</div>
                                                                    <div className='flex-shrink-1 col-lg-2 col-md-12 d-flex justify-content-end'>
                                                                        <Whatsapp className={'me-2 '.concat(u.user.mobileNumber === "" ? ' opacity-25' : '')} size={24} />
                                                                        <Bullseye className='me-2' size={24} />
                                                                        <Cursor className={'me-2 '.concat(u.user.emailAddress === "" ? ' opacity-25' : '')} size={24} />
                                                                        <ChatFill className={'me-2 '.concat(u.user.mobileNumber === "" ? ' opacity-25' : '')} size={24} />
                                                                        <Trash style={{ cursor: "pointer" }} onClick={(e) => _handleRemoveUser(idx)} size={24} icon={faTrash} />
                                                                    </div>
                                                                </div>
                                                            )
                                                        }
                                                        <div className='text-center'>
                                                            <button onClick={(e) => _handleAddGroup()} className='btn text-center'>Save</button>
                                                        </div>
                                                    </div>
                                                }
                                            </div>
                                            :
                                            mode === 'Existing'
                                                ?
                                                <div className="text-start">
                                                    <label>Select the group you would like to manage</label>
                                                    <select
                                                        onChange={(e) => _handleSetGroup(e.target.value)}
                                                        className='form-control '>
                                                        <option value={-1}></option>
                                                        {
                                                            groups.map((g) =>
                                                                <option
                                                                    //                                                        selected={(((defaultValue && defaultValue.id === g.id) || group.id === g.id) ? 'selected' : '')}
                                                                    selected={group.id === g.id ? 'selected' : ''}
                                                                    key={g.id} value={g.id}>{g.name}</option>
                                                            )
                                                        }
                                                    </select>
                                                    {
                                                        group.id
                                                            ?
                                                            <div>
                                                                <label>Audience Group Name</label>
                                                                <input
                                                                    value={group.name}
                                                                    onChange={(e) => setGroup({ ...group, name: e.target.value })}
                                                                    className='form-control'
                                                                    placeholder='Group Name' />
                                                                <div className='m-2'>
                                                                    {/* <h3>{name} <Pencil onClick={(e) => setStep('GROUP_NAME')} /></h3> */}
                                                                </div>
                                                                <label>Start typing the users name to add to the list of recipients to send and receive messages</label>
                                                                <UserSelectionComponent className={"m-0 p-0"} clearOnSelect={true} onUserSelectedDetails={(i) => _handleAddUser(i)} />
                                                                {
                                                                    users.map((u, idx) =>
                                                                        <div className='d-flex m-3 row'>
                                                                            <div className='col-lg-4 col-md-12'>{u.user.firstName} {u.user.lastName}</div>
                                                                            <div className='col-lg-3 col-md-12'>{u.user.mobileNumber}</div>
                                                                            <div className='col-lg-3 col-md-12'>{u.user.emailAddress}</div>
                                                                            <div className='flex-shrink-1 col-lg-2 col-md-12 d-flex justify-content-end'>
                                                                                <Whatsapp className={'me-2 '.concat(u.user.mobileNumber === "" ? ' opacity-25' : '')} size={24} />
                                                                                <Bullseye className='me-2' size={24} />
                                                                                <Cursor className={'me-2 '.concat(u.user.emailAddress === "" ? ' opacity-25' : '')} size={24} />
                                                                                <ChatFill className={'me-2 '.concat(u.user.mobileNumber === "" ? ' opacity-25' : '')} size={24} />
                                                                                <Trash style={{ cursor: "pointer" }} onClick={(e) => _handleRemoveUser(idx)} size={24} icon={faTrash} />
                                                                            </div>
                                                                        </div>
                                                                    )
                                                                }
                                                                <div className='text-center'>
                                                                    <button onClick={(e) => setShowConfirm(true)} className='btn text-center'>Remove</button>
                                                                    <button onClick={(e) => _handleSetGroupName()} className='btn text-center'>Save</button>
                                                                </div>
                                                            </div>
                                                            :
                                                            <></>
                                                    }
                                                </div>
                                                :
                                                <></>
                                    }

                                </div>
                            </div>

                        </div>
                    </div>
                </div>
                <div classname="tail">
                </div>
            </div>
        </>
    )
}

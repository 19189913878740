// import { useEffect, useState } from "react"
// import User from "../../../libs/api/services/User"
// import { useParams } from "react-router-dom"
// import ToggleButton from "../../../components/ui/toggle_button/ToggleButton";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import ToggleButton from "../../../components/ui/toggle_button/ToggleButton";
import User from "../../../libs/api/services/User";

export default function UserPersmissions() {

    const { id } = useParams();
    const [permissions, setPermissions] = useState([])
    const [usersRoles, setUsersRoles] = useState([])
    // // const [init, setInit] = useState(false)

    useEffect(() => {
        User.permissions(id)
            .then((res) => {
                // Permissions
                setPermissions(res)
            }).catch((err) => {
            })

        User.get(id)
            .then((res) => {
                // Permissions
                setUsersRoles(res.userRoles)
            }).catch((err) => {
            })
    }, [id])

    const hasRole = (role) => {
        let has_role = false;
        usersRoles.map((user_role) => {
            if (user_role.id === role) {
                has_role = true;
                return has_role;
            }
            return false;
        })
        return has_role;
    }

    const toggleUserPermission = (permission, value) => {
        if (value) {
            User.addPermissions(id, {
                Id: permission,
                Role: ""
            }).then((r) => {

            })
        }else{
            User.removePermissions(id, {
                Id: permission,
                Role: ""
            }).then((r) => {

            })
        }
    }

    return (
        <div>
            <div className="d-flex flex-column h-100 p-3">
                <div className="flex-shrink-1 text-center">
                    <h1>User Permission</h1>
                    <p>Please specify which functions should be accessible to this user</p>
                </div>
                <div className="flex-grow-1">
                    {
                        permissions.map((permission) =>
                            <ToggleButton
                                key={permission.id}
                                style={'tick'}
                                defaultValue={hasRole(permission.id)}
                                onToggle={(v) => toggleUserPermission(permission.id, v)}
                            >
                                <div key={permission.id}>{permission.role}</div>
                            </ToggleButton>
                        )
                    }
                </div>
            </div>
        </div>
    )
}
import React, { useEffect, useState } from 'react'
import { CheckCircle, EyeFill, Journal, Pencil, People, PlusCircleDotted, Tools, Trash, XCircle } from 'react-bootstrap-icons'
import InputWithKeyFunctions from '../../../../../../../../components/inputs/InputWithKeyFunctions'
import MessagingServiceProvider from '../../../../../../../../libs/api/services/MessagingServiceProvider'
import ServiceProviderType from '../setupType/ServiceProviderType'

export default function ServiceProviderServices({ errors, provider, setValue, values, active }) {
    const [mode, setMode] = useState('')
    const [service, setService] = useState()
    const [services, setServices] = useState([])

    useEffect(() => {

        // return 
        if (Object.keys(values).includes('serviceProviderService') && values.serviceProviderService && mode === '')
            setService(values.serviceProviderService);
    }, [values])

    useEffect(() => { _loadServices() }, [])
    const _loadServices = () => {

        if (provider && provider.id && provider.id !== -1)
            MessagingServiceProvider
                .GetServices(provider.id)
                .then((r) => { setServices(r) })
                .catch((e) => errors(e))
    }
    const _handleSelectService = (s) => {
        s = services.find(x => x.id === s)
        setValue({ serviceProviderService: s })
        setService(s)
    }

    const _handleRemoveService = () => {
        service.isDeleted = true
        MessagingServiceProvider
            .UpdateService(provider.id, service)
            .then((r) => {
                _loadServices()
                setService({})
                setMode('')
            }).catch((e) => errors(e))
    }

    const _handleAddService = (s, m) => {
        setService(s)
        if (m === 'Create') {
            setValue({ serviceProviderService: s })
            setMode('Add')
        } else
            if (m === 'Done') {
                if (!service.name) return false
                MessagingServiceProvider
                    .UpdateService(provider.id, service)
                    .then((r) => {
                        _loadServices()
                        setMode('Configure')
                        _handleSelectService(r)
                        setValue({ serviceProviderService: r })
                        setService(r)
                    }).catch((e) => errors(e))

            }
            else
                setMode(m)

    }

    const _handleConfiguration = (type) => {
        switch (type) {
            case 'INCOME': setValue({ action: { from: 1, to: 2 } }); break;
            case 'OUTGOING': setValue({ action: { from: 1, to: 3 } }); break;
            case 'PERMISSIONS': return setValue({ action: { from: 1, to: 4 } });
            case 'OVERVIEW': return setValue({ action: { from: 1, to: 5 } });
            case 'LOGS': return setValue({ action: { from: 1, to: 6 } });
            case 'EMAIL': return setValue({ action: { from: 1, to: 7 } });
            default: break;
        }
    }

    return (
        <div>
            <b>Services</b>
            {
                mode === 'Add'
                    ?
                    <div>
                        <ServiceProviderType showNav={true} onCancel={() => setMode('')} onSelected={(s) => { _handleAddService({ ...service, serviceType: { type: s } }, 'Add Service Name') }} />
                    </div>
                    : mode === 'RemoveService'
                        ?
                        <div>
                            <div>Are you sure you want to remove service {service.name}?
                                <div>
                                    <button onClick={() => _handleRemoveService()} className='btn btn-xs'>Yes</button>
                                    <button onClick={() => setMode('')} className='btn btn-xs'>No</button>
                                </div>
                            </div>
                        </div>
                        :
                        mode === 'Add Service Name'
                            ?
                            <div>
                                <b>Please enter a name to further reference this service</b>
                                <InputWithKeyFunctions selected={true} className='form-control form-control-sm' placeholder='Descriptive name'
                                    onChange={(e) => setService({ ...service, name: e })}
                                    onCancel={() => setMode('')}
                                    onSubmit={() => _handleAddService(service, 'Done')}
                                    onBlur={() => { }}
                                />
                                <div>
                                    <button onClick={() => setMode('')} className='btn btn-xs'><XCircle />Cancel</button>
                                    <button onClick={() => _handleAddService(service, 'Done')} className={'btn btn-xs '.concat(service.name ? '' : 'disabled')}>Next</button>
                                </div>

                            </div>
                            :
                            mode === 'Configure' || mode === 'Configure-Rename'
                                ?
                                <>
                                    <div onClick={() => setMode('Configure-Rename')}> {mode === 'Configure-Rename' ?
                                        <span className='d-flex'><InputWithKeyFunctions selected={true} className='form-control form-control-sm' placeholder='Descriptive name'
                                            onChange={(e) => setService({ ...service, name: e })}
                                            onCancel={() => setMode('Configure')}
                                            onSubmit={() => _handleAddService(service, 'Done')}
                                            onBlur={() => { }}
                                            defaultValue={service.name}
                                        />
                                            <button onClick={() => _handleAddService(service, 'Done')} className='btn btn-xs'><CheckCircle /></button>
                                            <button onClick={(e) => { e.stopPropagation(); setMode('Configure') }} className='btn btn-xs'><XCircle /></button>
                                        </span>
                                        : <span>Configure {service.name}<Pencil className='ms-1' /></span>
                                    } </div>
                                    <div>
                                        <button onClick={() => _handleConfiguration('OVERVIEW')} className='w-100 btn btn-xs d-flex align-items-center text-start '><EyeFill className='flex-shrink-1 me-2' />Overview</button>
                                        <button onClick={() => _handleConfiguration('PERMISSIONS')} className='w-100 btn btn-xs d-flex align-items-center text-start '><People className='flex-shrink-1 me-2' />Assign Access</button>
                                        {
                                            service.serviceType.type === 'Email'
                                                ?
                                                <button onClick={() => _handleConfiguration('EMAIL')} className='w-100 btn btn-xs d-flex align-items-center text-start '><Tools className='flex-shrink-1 me-2' />Configure Email Account</button>
                                                :
                                                <>
                                                    <button onClick={() => _handleConfiguration('INCOME')} className='w-100 btn btn-xs d-flex align-items-center text-start '><Tools className='flex-shrink-1 me-2' />Outgoing messaging configuration</button>
                                                    <button onClick={() => _handleConfiguration('OUTGOING')} className='w-100 btn btn-xs d-flex align-items-center text-start '><Tools className='flex-shrink-1 me-2' />Incomming messaging configuration</button>
                                                </>
                                        }


                                        <button onClick={() => _handleConfiguration('LOGS')} className='w-100 btn btn-xs d-flex align-items-center text-start '><Journal className='flex-shrink-1 me-2' />Logs</button>
                                        <button onClick={() => setMode('')} className='w-100 btn btn-xs d-flex align-items-center '><XCircle className='flex-shrink-1 me-2' />Cancel</button>
                                    </div>
                                </>
                                :
                                <div>
                                    <div className='d-flex'>
                                        <select onChange={(e) => _handleSelectService(e.target.value)} className='form-control form-control-sm'>
                                            <option></option>
                                            {services.map((s) =>
                                                <option selected={service && service.id === s.id ? 'selected' : ''} key={s.id} value={s.id}>{s.name}</option>
                                            )}

                                        </select>
                                        <div><button onClick={() => setMode('RemoveService')} className={'btn btn-sm '.concat(!service ? 'disabled' : '')}><Trash /></button></div>
                                    </div>
                                    <div className='d-flex justify-content-center'>
                                        <button onClick={() => _handleAddService({}, 'Create')} className='btn btn-xs'><div><PlusCircleDotted /><div>Add a service</div></div></button>
                                        <button onClick={() => setMode('Configure')} className={'btn btn-xs '.concat(!service ? 'disabled' : '')}><div><div><Tools /></div><div>Configure Service</div></div></button>
                                    </div>
                                </div>
            }
        </div>
    )
}

import React, { useEffect, useState } from 'react'
import ServiveProviderJSON from './ServiveProviderJSON'

export default function IntegrationAuthenticationSendMessageBody({ defaultValue, onChange }) {
    const [authDetails, setAuthDetails] = useState(defaultValue ? defaultValue : {
        requestBody: {},
        requestResponse: {},

    })
    useEffect(() => { if (onChange && (Object.keys(authDetails.requestBody).length > 0 || Object.keys(authDetails.requestResponse).length > 0)) onChange(authDetails) }, [authDetails])
    return (
        <div className='text-start'>
            <div className='d-flex p-2 bg-secondary-container rounded shadow'>
                <select
                    onChange={(e) => setAuthDetails({ ...authDetails, endPointVerb: e.target.value })}
                    defaultValue={defaultValue && defaultValue.endPointVerb ? defaultValue.endPointVerb : undefined}
                    className='form-control form-control-sm flex-shrink-1 w-25'>
                    <option>HTTP Verb</option>
                    <option>POST</option>
                    <option>GET</option>
                    <option>PUT</option>
                    <option>PATCH</option>
                    <option>DELETE</option>
                    <option>OPTIONS</option>
                    <option>TRACE</option>
                </select>
                <input
                    onChange={(e) => setAuthDetails({ ...authDetails, endPoint: e.target.value })}
                    defaultValue={defaultValue && defaultValue.endPoint ? defaultValue.endPoint : undefined}
                    className='form-control form-control-sm flex-grow-1' placeholder='Full URL and endpoint' />
            </div>
            <div className='d-flex'>
                <div className='d-block w-50'>
                    <div><b>JSON Request</b></div>
                    <ServiveProviderJSON defaultValue={defaultValue && defaultValue.requestBody ? defaultValue.requestBody : undefined} onChange={(r) => setAuthDetails({ ...authDetails, requestBody: r })} />
                </div>
                <div className='d-block w-50'>
                    <div><b>JSON Response</b></div>
                    <ServiveProviderJSON defaultValue={defaultValue && defaultValue.requestResponse ? defaultValue.requestResponse : undefined} onChange={(r) => setAuthDetails({ ...authDetails, requestResponse: r })} />
                </div>
            </div>

        </div>
    )
}

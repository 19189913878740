import React, { useEffect, useState } from 'react'

export default function ValidationWidget({ errors, rules, onValid, onInvalid, children, custom }) {
    const [error, setError] = useState(errors)

    const _validate = (onChange, v) => {
        onChange(v)
        for (const r of rules)
            if (!new RegExp(r.rule).test(v)) {
                if (onInvalid) onInvalid(v)
                return setError(r.error)
            }

        setError(undefined)
        if (onValid)
            onValid(v)
    }

    const _validationWrapper = (element) => {
        return {
            ...element,
            props: {
                ...element.props,
                onChange: (e) => _validate(element.props.onChange, e.target.value),
                // onChange: (e) => _validate(null, e.target.value),
            },
        }
    }

    useEffect(()=>{
        setError(custom)
    },[custom])

    return (
        <div className='validation-container'>
            {_validationWrapper(children)}
            <div className='validation-details'>{error}</div>
        </div>
    )
}

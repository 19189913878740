import React from 'react'
import CalendarEntriesEntries from './CalendarEntriesEntries'
import { CalendarEntriesProvider } from './CalendarEntriesProvider'

export default function WorkFlowCalendarContainer({ calendars }) {

    return (
        <CalendarEntriesProvider defaultValue={calendars}>
            <CalendarEntriesEntries />
        </CalendarEntriesProvider>
    )
}

import React, { useEffect, useState } from 'react'
import { XCircleFill } from 'react-bootstrap-icons'
import ErrorDialog from '../../../../../../components/dialogs/ErrorDialog'
import Authentication from '../../../../../../libs/api/services/Authentication'
import MessagingService from '../../../../../../libs/api/services/MessagingService'
import RTEMessageBuilderComponent from '../../../admin/components/RTEMessageBuilderComponent'
import './NewMessageComponent.css'
import SendTo from './SendTo'
import SendUsingOptions from './SendUsingOptions'

export default function NewMessageComponent({ onClose, errors }) {
    const [error, setError] = useState()
    const [messageOptions, setMEssageOptions] = useState([])
    const [message, setMessage] = useState(
        {
            userType: undefined,
            serviceType: undefined,
            serviceProvider: undefined,
            recipients: [],
            messageBody: undefined,
            status: 'Send',
            subject: undefined
        })

    useEffect(() => {
        const _userType = Authentication.getFacilityRole();
        const _serviceType = _userType === 'Patient' ? 'Internal Messaging Service' : undefined;
        setMessage({ ...message, userType: _userType, serviceType: _serviceType, status: 'Send' })
    }, [])

    useEffect(() => {
        setMEssageOptions(_getServiceTypeOptions(message.serviceType))
    }, [message.serviceType])

    const _getServiceTypeOptions = (serviceType) => {
        switch (serviceType) {
            case 'Internal Messaging Service': return ['fontSize', 'inline', 'textAlign', 'emoji'];
            case 'SMS': return ['emoji'];
            case 'WhatsApp': return ['emoji'];
            case 'Email': return ['fontSize', 'inline', 'textAlign', 'emoji'];
            default: return []
        }
    }

    const _handleSendMessage = () => {
        setMessage({ ...message, status: 'Sending' })
        MessagingService
            .SendMessage(message)
            .then((r) => {
                setMessage({ ...message, status: 'Sent' })
            }).catch((e) => {
                setMessage({ ...message, status: 'Failed' })
                setError(e)
            })
    }

    if (message.userType === undefined) return <></>

    return (
        <>
            <ErrorDialog humanize={true} error={error} onClose={() => setError()} />
            <div className='position-fixed d-flex align-items-center justify-content-center ' style={{ zIndex: 70, left: '0px', right: '0px', top: '0px', bottom: '0px' }}>
                <div className='bg-light shadow d-flex flex-column rounded p-2 h-75  w-50 m-2'>
                    <div className='d-flex'>
                        <div className='flex-grow-1 text-center'>
                            <h3>New Message</h3>
                        </div>
                        <div onClick={() => onClose(false)}><XCircleFill size={25} /></div>
                    </div>

                    <div className='flex-shrink-1'>
                        {
                            message.userType === 'Patient'
                                ?
                                <></>
                                :
                                <SendUsingOptions onChange={(d) => setMessage({ ...message, ...d, recipients: [] })} />
                        }

                    </div>
                    <div className='flex-shrink-1'>
                        {
                            message.serviceType
                                ?
                                <SendTo ServiceType={message.serviceType} errors={errors} onChange={(r) => setMessage({ ...message, recipients: r })} />
                                :
                                <></>
                        }

                    </div>
                    <div className='flex-shrink-1'>
                        {
                            message.recipients.length > 0
                                ?
                                <>
                                    <b>Subject</b>
                                    <input onChange={(e) => setMessage({ ...message, subject: e.target.value })} className='form-control form-control-sm' placeholder='Subject' />
                                </>
                                :
                                <></>
                        }

                    </div>
                    <div className='flex-grow-1 h-100' style={{ overflow: 'auto' }}>
                        {
                            message.recipients.length === 0 && !message.serviceProvider
                                ?
                                <></>
                                :
                                <>
                                    <b>Message</b>
                                    <RTEMessageBuilderComponent
                                        disablePlaceholders={true}
                                        placeholders={false}
                                        onSetTemplate={(t, h, m) => setMessage({
                                            ...message, messageBody: {
                                                template: t,
                                                html: h,
                                                markdown: m.replaceAll('**','')
                                            }
                                        })}
                                        options={messageOptions}
                                        viewOnly={false}
                                    />
                                </>
                        }

                    </div>
                    <div className='flex-shrink-1 d-flex justify-content-end'>
                        <btton onClick={() => _handleSendMessage()} className={'btn bg-secondary-container '.concat(message.recipients.length === 0 ? 'disabled' : '')}>{message.status}</btton>
                    </div>
                </div>
            </div>
        </>
    )
}

import React, { useEffect, useState } from 'react'
import { Clipboard2Heart, Link, PinAngleFill } from 'react-bootstrap-icons'
import { NavLink } from 'react-router-dom'
import Authentication from '../../../../libs/api/services/Authentication'
import Dashboards from '../../../../libs/api/services/Dashboards'

export default function DashboardWidgetQuestionnaires() {
  const [questionnaires, setQuestionnaires] = useState([])
  useEffect(() => { _loadPinned() }, [])
  const _loadPinned = () => {
    Dashboards
      .Patient()
      .then((r) => setQuestionnaires(r.questionnaires))
  }
  return (
    <div className='col-lg-4 col-md-12 dashboard-card'>
      <div className='text-center bg-secondary-container p-3 rounded h-100 '>
        <h3><Clipboard2Heart className='me-2' />Questionnaires</h3>
        {
          questionnaires.map((q) =>
            <NavLink
              tag={Link} key={q.id}
              to={'/' + Authentication.getFacilityRole() + '/Questionnaire/' + q.questionnaire.id + '/' + q.id
              }
              className={'text-light'}
              style={{ cursor: 'pointer' }}><div className='bg-primary-container position-relative p-2 rounded'>{q.questionnaire.name}<PinAngleFill style={{ right: '-10px', top: '-10px' }} className='position-absolute' /></div></NavLink>
          )
        }

        <NavLink
          tag={Link}
          to={'/' + Authentication.getFacilityRole() + '/Questionnaires'
          }
          className={'text-light m-1'}
          style={{ cursor: 'pointer' }}><button className='btn m-1'>Go to my Questionnaire</button></NavLink>
      </div>
    </div>
  )
}

import { faSpinner } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useEffect, useState } from 'react'
import Facility from '../../../../../../../libs/api/services/Facility'
import QBuilderSearch from '../../Generic/QBuilderSearch'

export default function QBuilderFacilities({ onSelected, view, response }) {
    const [facilities, setFacilities] = useState([])
    const [loading, setLoading] = useState(true)

    // OnChangedState
    useEffect(() => {
        // Load facilities 
        Facility.list().then((f) => {
            setFacilities(f)
            setLoading(false)
        })
    }, [])

    if (loading) return <div className='text-center'><FontAwesomeIcon icon={faSpinner} spin /></div>

    if (view === 'Discover') {
        try {

            return <div>{facilities.find((f) => f.id === response).facility}</div>
        } catch (e) {
            return <div>{response}</div>
        }
    }

    return (
        <div>
            <QBuilderSearch
                response={response}
                placeholder={'Facility'}
                details={{
                    data: facilities,
                    options: {
                        reRefKey: 'id',
                        template: [{
                            refKey: 'facility',
                            descr: '',
                        }]
                    }
                }}
                onsBeforeSearch={() => { }}
                onAfterSearch={() => { }}
                onSelected={(r) => onSelected(r)}
            />
            {/* <input className='form-control form-control-sm' /> */}
        </div>
    )
}

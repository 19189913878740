import API from "../API";

var MessageTemple = {
    async Create(data) {
        var result = await API.process(
            'api/Message/Template',
            'POST',
            data
        ).catch((err) => {
            throw err;
        })

        return result
    },
    async CreateWithFacility(facilityId, data) {
        var result = await API.process(
            `api/Message/Template/facility/${facilityId}`,
            'POST',
            data
        ).catch((err) => {
            throw err;
        })

        return result
    },
    async ListServiceTypes() {
        var result = await API.process(
            'api/Message/Template/Service/Types',
            'GET',
            null
        ).catch((err) => {
            throw err;
        })

        return result
    },
    async Templates() {
        var result = await API.process(
            'api/Message/Template/Templates',
            'GET',
            null
        ).catch((err) => {
            throw err;
        })

        return result
    },
    async TemplatesFacility(FacilityId) {
        var result = await API.process(
            `api/Message/Template/Templates/Facility/${FacilityId}`,
            'GET',
            null
        ).catch((err) => {
            throw err;
        })

        return result
    },
    async TemplatesFacilityOrAll(FacilityId) {
        var result = await API.process(
            `api/Message/Template/Templates/Facility/or/${FacilityId === null ? -1 : FacilityId}`,
            'GET',
            null
        ).catch((err) => {
            throw err;
        })

        return result
    },
    async RemoveTemplate(templateId) {
        var result = await API.process(
            `api/Message/Template/${templateId}`,
            'DELETE',
            null
        ).catch((err) => {
            throw err;
        })

        return result
    },
    async ListAudienceTypes() {
        var result = await API.process(
            `api/Message/Template/audience/types`,
            'GET',
            null
        ).catch((err) => {
            throw err;
        })

        return result
    },
    async AddAudience(messageId, audienceTypeId) {
        var result = await API.process(
            `api/Message/Template/audience/${messageId}/${audienceTypeId}`,
            'PUT',
            null
        ).catch((err) => {
            throw err;
        })

        return result
    },
    async GetAudience(messageId) {
        var result = await API.process(
            `api/Message/Template/audience/${messageId}`,
            'GET',
            null
        ).catch((err) => {
            throw err;
        })

        return result
    },
    async RemoveAudience(audienceId) {
        var result = await API.process(
            `api/Message/Template/audience/${audienceId}`,
            'DELETE',
            null
        ).catch((err) => {
            throw err;
        })

        return result
    },
    async SetTaskProperty(EventTaskId, value, property) {
        var result = await API.process(
            `api/Events/${EventTaskId}/Property`,
            'PUT',
            {
                value: value,
                type: {
                    type: property
                }
            }
        ).catch((err) => {
            throw err;
        })

        return result
    },
    async GetTemplateProperty(taskId, type) {
        var result = await API.process(
            `api/Events/${taskId}/Property/${type}`,
            'GET',
            null
        ).catch((err) => {
            throw err;
        })

        return result
    },
    async ListVariableCategories() {
        var result = await API.process(
            `api/Message/Template/Variables/Modules`,
            'GET',
            null
        ).catch((err) => {
            throw err;
        })

        return result
    },
    async ListAudienceList(templateId) {
        var result = await API.process(
            `api/Message/Template/Audience/List/${templateId}`,
            'GET',
            null
        ).catch((err) => {
            throw err;
        })

        return result
    },
    async SetTemplateMessagingPlatform(templateId, platform) {
        var result = await API.process(
            `api/Message/Template/${templateId}/MessagePlatform/${platform}`,
            'PUT',
            null
        ).catch((err) => {
            throw err;
        })

        return result
    },
    async GetMessageTemplates(templateId) {
        var result = await API.process(
            `api/Message/Template/template/${templateId}`,
            'GET',
            null
        ).catch((err) => {
            throw err;
        })

        return result
    },
    async setContent(templateId, subject, body) {
        var result = await API.process(
            `api/Message/Template/${templateId}/Content`,
            'POST',
            {
                subject: subject,
                template: body.template,
                html: body.html,
                md: body.md
            }
        ).catch((err) => {
            throw err;
        })

        return result
    },
    async GetContent(templateId) {
        var result = await API.process(
            `api/Message/Template/${templateId}/Content`,
            'GET',
            null
        ).catch((err) => {
            throw err;
        })

        return result
    },
    async SetAudienceGroup(templateId, audienceGroupId) {
        var result = await API.process(
            `api/Message/Template/audience/${templateId}/group/${audienceGroupId}`,
            'PUT',
            null
        ).catch((err) => {
            throw err;
        })

        return result
    },
    async SetAudiencePatientProfileField(templateId, audienceGroupId) {
        var result = await API.process(
            `api/Message/Template/audience/${templateId}/patientProfile/${audienceGroupId}`,
            'PUT',
            null
        ).catch((err) => {
            throw err;
        })

        return result
    },
    // async GetTaskProperty(audienceId) {
    //     var result = await API.process(
    //         `api/Message/Template/audience/${audienceId}`,
    //         'DELETE',
    //         null
    //     ).catch((err) => {
    //         throw err;
    //     })

    //     return result
    // },
}

export default MessageTemple;
import JSONPath from 'jsonpath'
export abstract class StringFunctions {
  public static LoopReplaceJSON(json: { [key: string]: any }, k: string, v: any, ref: any): object {
    if (!json) return {}
    for (const j of Object.keys(json))
      if (typeof json[j] === 'object') json[j] = StringFunctions.LoopReplaceJSON(json[j], k, v, ref)
      else if (typeof json[j] === 'undefined') json[j]
      else {
        try {
          if (typeof json[j] === 'string' && json[j].includes('{') && json[j].includes('$.') && json[j].includes('}')) {
            if (k.length < json[j].length) {
              const kc = json[j].substr(1, k.length)
              if (k === kc) {
                let xp = json[j].substr(json[j].indexOf('$.'))
                xp = xp.substr(0, xp.length - 1)
                if (typeof v === 'object') {
                  // Do Xpath ::
                  json[j] = JSONPath.query(v, xp)[0]
                }
              }
            }
            //if(k ===  kc = json[j].substr(1,k.length))
            // xp split
            // split substr(body[k] on $. till length -1 of body[k]
            // { k $. }
            // length of k :
          }
          if (typeof json[j] === 'string' && json[j].includes('{' + k + '}')) {
            if (typeof v === 'object' && json[j] === '{' + k + '}') {
              // json[j] = json[j].replaceAll('{' + k + '}', JSON.stringify(val))
              json[j] = JSON.parse(JSON.stringify(v))
            } else if (json[j] === '{' + k + '}') {
              json[j] = json[j].replaceAll('{' + k + '}', v)
            }
            //   json[j] = json[j] === '{' + k + '}' ? json[j].replaceAll('{' + k + '}', JSON.parse(v)) : json[j]
            // else json[j] = json[j] === '{' + k + '}' ? json[j].replaceAll('{' + k + '}', v) : json[j]
          }
        } catch (e) {
          console.error(e)
        }
      }
    return json
  }
  public static LoopClearJSON(json: { [key: string]: any }): object {
    if (!json) return {}
    for (const j of Object.keys(json))
      if (typeof json[j] === 'object') json[j] = StringFunctions.LoopClearJSON(json[j])
      else if (typeof json[j] === 'undefined') json[j]
      else if (typeof json[j] === 'string')
        json[j] = json[j].indexOf('{') > -1 && json[j].indexOf('}') > -1 ? (json[j] = undefined) : json[j]
    return json
  }
  public static SetJSONVars(vars: { [key: string]: any }, body: object): string {
    let _body = JSON.parse(JSON.stringify(body))
    for (const k of Object.keys(vars)) {
      if (typeof vars[k] === 'string') _body = StringFunctions.LoopReplaceJSON(_body, k, vars[k], vars)
      else
        for (const e of Object.keys(vars[k]))
          _body = StringFunctions.LoopReplaceJSON(_body, k + '.' + e, vars[k][e], vars)
    }
    return JSON.stringify(StringFunctions.LoopClearJSON(_body))
  }

  public static replaceURL(url: string, _sec: string, _name: string, value: string, ref: any) {
    let s = false
    let n = false
    let p = false
    let section = ''
    let name = ''
    let path = ''
    const variables: any = []
    for (let i = 0; i < url.length; i++) {
      const c = i === url.length - 1 ? url.substring(i) : url.substring(i, i + 1)
      if (s) section += c
      if (n) name += c
      if (p) path += c
      switch (c) {
        case '{':
          s = true
          break
        case '.':
          if (s) {
            s = false
            n = true
          }
          break
        case '$':
          if (n) {
            n = false
            p = true
            path += '$'
          }
          break
        case '}':
          s = false
          n = false
          p = false
          section = section.substring(0, section.length - 1)
          name = name.substring(0, name.length - 1)
          path = path.substring(0, path.length - 1)
          variables.push({
            segment: '{' + section + '.' + name + '' + path + '}',
            section: section,
            name: name,
            path: path,
          })
          break
        default:
          break
      }
    }

    for (const v of variables) {
      if (v.section === _sec && v.name === _name)
        if (v.path)
          url = url.replaceAll('{' + v.section + '.' + v.name + '' + v.path + '}', JSONPath.query(ref, v.path)[0])
        else if (v.name) url = url.replaceAll('{' + v.section + '.' + v.name + '}', value)
    }
    return url
  }

  public static SetStringVars(vars: { [key: string]: any }, url: string): string {
    let _url = url
    // Rebuild
    // Split out all { }
    // forrach {}
    //  if($_. includes($))
    //    split {s.n.x}
    // replace {} with xpath value
    //  else replace {} with {' + s + '.' + k + '} = vars[s][k])
    for (const s of Object.keys(vars))
      if (typeof vars[s] === 'object')
        for (const k of Object.keys(vars[s])) _url = StringFunctions.replaceURL(_url, s, k, vars[s][k], vars[s][k])
      else _url = StringFunctions.replaceURL(_url, s, '', vars[s], vars[s])
    // for (const s of Object.keys(vars))
    //   if (typeof vars[s] === 'object') {
    //     // if (_url.includes('$')) {
    //     //   //
    //     // }
    //     for (const k of Object.keys(vars[s])) _url = _url.replaceAll('{' + s + '.' + k + '}', vars[s][k])
    //   } else _url = _url.replaceAll('{' + s + '}', vars[s])
    return _url
  }

  public static ReadKeyValue(map: Map<string, any>) {
    if (!map) return {}
    const obj: { [key: string]: any } = {}
    if (typeof map.forEach === 'undefined') {
      for (const key of Object.keys(map)) {
        const value = map instanceof Map ? map.get(key) : map[key]
        if (typeof value === 'object') obj[key] = StringFunctions.ReadKeyValue(value)
        else obj[key] = value
      }
    } else {
      map?.forEach(function (value, key) {
        if (typeof value === 'object') obj[key] = StringFunctions.ReadKeyValue(value)
        else obj[key] = value
      })
    }
    return obj
  }

  public static MapToJSON(map: Map<string, any>): string {
    return JSON.stringify(StringFunctions.ReadKeyValue(map!))
  }

  public static Humanize(str: string) {
    if (!str) return null
    let _str = ''
    let _start = true
    for (let i = 0; i < str.length; i++) {
      const c = i === str.length - 1 ? str.substring(i) : str.substring(i, i + 1)
      if (_start) {
        if (c.toLowerCase() === c) _str += c.toUpperCase()
        else _str += c
        _start = false
      } else {
        if (c.toUpperCase() === c && str.substring(i - 1, 1) !== ' ') _str += ' ' + c.toLowerCase()
        else if (c === ' ' || c === '.') _start = true
        else _str += c
      }
    }
    return _str
  }

  /*
    JSON Path replace
  */
  public static LoopReplaceJSONPathJSON(json: { [key: string]: any }, ref: any): object {
    if (!json) return {}
    for (const j of Object.keys(json)) {
      // if value is key LoopReplaceJSONPathJSON
      if (typeof json[j] === 'object') json[j] = StringFunctions.LoopReplaceJSONPathJSON(json[j], ref)
      // if value is array loop LoopReplaceJSONPathJSON
      else if (Array.isArray(json[j]))
        for (let i = 0; i < json[j].length; i++) json[j][i] = StringFunctions.LoopReplaceJSONPathJSON(json[j][i], ref)
      // else if value starts with {$ json[k] = JSONPath.query(ref, json[k]
      else if (json[j].substring(0, 2) === '{$')
        json[j] = JSONPath.query(ref, json[j].substring(1, json[j].length - 1))[0]
    }
    return json
  }

  public static SetJSONPathVars(vars: any, body: object): string {
    return JSON.stringify(StringFunctions.LoopReplaceJSONPathJSON(JSON.parse(JSON.stringify(body)), vars))
  }
}


import React, { useEffect, useState } from 'react'
import { XCircle } from 'react-bootstrap-icons'
import { GiHamburgerMenu } from 'react-icons/gi'
import './animations.css'

export default function SlideNav({ select, children, menu }) {
    const [active, setActive] = useState(1)
    const [prev, setPrev] = useState(0)
    const [show, setShow] = useState(0)

    const _change = (c) => {
        setShow(false)
        setPrev(active)
        setActive(c)

    }

    // useEffect(() => {
    //     if (parseInt(select) > -1) {
    //         console.log('ACT', active)
    //         setPrev(parseInt(active))
    //         setActive(parseInt(select))
    //     }
    // }, [select])
    // useEffect(() => {
    //     if (parseInt(select) > -1) {
    //         console.log('ACT', active)
    //         setPrev(parseInt(active))
    //         setActive(parseInt(select))
    //     }
    // }, [select])
    const _setAnimation = (child) => {
        if (!child) return
        const n = {
            ...child,
            props: {
                ...child.props,
                className: (child.props && child.props.className ? child.props.className : '') + ' test  '
            }
        }
        return n
    }
    useEffect(() => { if (show) setActive(999) }, [show])
    return <>
        <div className='d-flex justify-content-between align-items-center p-1' style={{ height: '20px' }}>
            <div><b>{active ? active.label : ''}</b></div>
            <div className='btn d-flex align-items-center text-secondary' onClick={() => setShow(true)}><GiHamburgerMenu className='me-2' /><small>MORE OPTIONS</small></div>
        </div>
        <div
            className={'pt-2 bg-primary-container rounded shadow text-start w-100  flex-column justify-content-between '.concat(show ? ' an-slide-in d-flex ' : 'an-slide-out')}
            style={{ cursor: 'pointer', zIndex: 999, maxHeight: 'calc(80vh - 120px)', overflow: 'auto' }}>
            {
                menu.map((m, i) => <div
                    className={'w-100 p-2 rounded d-flex align-items-center '.concat(active && active.label === m.label ? ' bg-secondary-container shadow ' : '  ')}
                    onClick={() =>
                        _change(i + 2)}
                    key={'MNU-' + m.label}>{m.icon ? m.icon : ''}<div className='flex-grow-1'>{m.label}</div><div className='badge '>{m.extra ? m.extra : undefined}</div></div>)
            }
            <div
                className={'w-100 p-2 rounded '}
                onClick={() => { _change(1); }}><XCircle className='me-2' />Close</div>
        </div>

        {React.Children.map(children, (c, i) =>
            <div
                // style={{ maxHeight: 'calc(80vh - 120px)', height: 'calc(80vh - 120px)', overflow: 'auto' }}
                className={`${parseInt(active) === parseInt(i + 1) ? ' an-slide-in ' : parseInt(prev) === parseInt(i + 1) ? ' an-slide-out ' : ' d-none '} d-flex flex-column flex-grow-1 mh-100`}>
                {c}
                {/* //     {_setAnimation(c)} */}
            </div>
        )
        }</>
}

import React, { useEffect, useState } from 'react'
import { BellFill, Calendar2CheckFill, CalendarPlusFill, CardChecklist, CardList, ChatHeartFill, Clipboard2XFill, ClipboardPlusFill, ClockFill } from 'react-bootstrap-icons'
import FlowAssistant from '../../../../../../../../../libs/api/services/FlowAssistant'
import WorkFlow from '../../../../../../../../../libs/api/services/WorkFlow'

export default function EventTaskTypesSelection({ values, onSelected }) {

    const [taskTypes, setTaskTypes] = useState([])

    useEffect(() => { _loadTaskTypes() }, [])

    const _loadTaskTypes = () => {
        WorkFlow
            .ListTaskTypes()
            .then((r) => {
                // r.push({ type: 'Send a notification', description: 'Sends out a notification to specified user or group', disabled: true })
                // r.push({ type: 'Create a new calendar event', description: 'Adds an event to a users calendar', disabled: true })
                // r.push({ type: 'Update a calendar event', description: 'Updates a calendar event', disabled: true })
                // r.push({ type: 'Add a todo list item', description: 'Adds an item to a users to do list', disabled: true })
                // r.push({ type: 'Auto complete to do list item', description: 'This will update the status of a todo list item', disabled: true })
                setTaskTypes(r)
            })
    }

    const _handleAddTask = (t) => {
        var _values = values
        if (onSelected) return onSelected(t)
        if (Object.keys(_values).includes('action'))
            delete _values.action
        switch (t.type) {
            case 'Messaging':
                FlowAssistant
                    .BookmarkFlowAndGoTo('/Facility/Configuration/Events', 'NEW_EVENT_WORKFLOW_ADD_EVENT', _values, '/sys-admin/messaging/template')
                break;
            case 'Generate A Form':
                FlowAssistant
                    .BookmarkFlowAndGoTo('/Facility/Configuration/Events', 'NEW_EVENT_WORKFLOW_GENERATE_FORM', _values, '/Facility/Automation/Forms')
                break;
            case 'Send Questionnaire':
                FlowAssistant
                    .BookmarkFlowAndGoTo('/Facility/Configuration/Events', 'NEW_EVENT_WORKFLOW_SEND_QUESTIONNAIRE', _values, '/Facility/Automation/Questionnaires')
                break;
            case 'Send a notification':
                FlowAssistant
                    .BookmarkFlowAndGoTo('/Facility/Configuration/Events', 'NEW_EVENT_WORKFLOW_SEND_QUESTIONNAIRE', _values, '/Facility/Automation/Questionnaires')
                break;
            case 'Create a new calendar event':
                FlowAssistant
                    .BookmarkFlowAndGoTo('/Facility/Configuration/Events', 'NEW_EVENT_WORKFLOW_SEND_QUESTIONNAIRE', _values, '/Facility/Automation/Questionnaires')
                break;
            case 'Update a calendar event':
                FlowAssistant
                    .BookmarkFlowAndGoTo('/Facility/Configuration/Events', 'NEW_EVENT_WORKFLOW_SEND_QUESTIONNAIRE', _values, '/Facility/Automation/Questionnaires')
                break;
            default:
                break;
        }
    }

    const _renderTaskType = (t) => {
        var _icon = []
        var _type = t.type
        var _description = t.description
        switch (t.type) {
            case 'Messaging':
                _icon = <ChatHeartFill size={30} />
                break;
            case 'Generate A Form':
                _icon = <ClipboardPlusFill size={30} />
                break;
            case 'Send Questionnaire':
                _icon = <Clipboard2XFill size={30} />
                break;
            case 'Send a notification':
                _icon = <BellFill size={30} />
                break;
            case 'Create a new calendar event':
                _icon = <CalendarPlusFill size={30} />
                break;
            case 'Update a calendar event':
                _icon = <Calendar2CheckFill size={30} />
                break;
            case 'Allocate a To Do list':
                _icon = <CardList size={30} />
                break;
            case 'Set the status of a To Do list item':
                _icon = <CardChecklist size={30} />
                break;
            case 'Log to patient history':
                _icon = <ClockFill size={30} />
                break;
            default:
                break;
        }
        return (
            <div
                onClick={(e) => _handleAddTask(t)}
                style={{ cursor: 'pointer' }}
                className={'col-4 d-flex g-2 flex-column justify-content-between align-items-center '}>
                <div className={'w-100 h-100 p-2 rounded shadow '.concat(t.disabled ? ' bg-secondary opacity-75 text-dark ' : 'bg-secondary-container')}>
                    <div >{_icon}</div>
                    <b>{_type}</b>
                    <p className='h-100'>{_description}</p>
                </div>
            </div>
        )
    }
    return (
        taskTypes.length > 0 ? <div className='row p-3'>{taskTypes.map((t) => _renderTaskType(t))}</div> : <></>
    )
}

import React, { useEffect, useState } from 'react'
import WorkFlow from '../../../../../libs/api/services/WorkFlow'
import WorkFlowAssistantPopup from './WorkFlowAssistant/WorkFlowAssistantPopup'
import WorkFlowQuickTasksItem from './WorkFlowQuickTasksItem'

export default function WorkFlowQuickTasks({ group, filters,patientId, category }) {
    const [events, setEvents] = useState([])
    const [event, setEvent] = useState()
    useEffect(() => {
        _loadEvents()
    }, [group, category])

    /*
        TODO: Handle the event popup 
        <QuickTaskEventsComponent
                onClose={() => setEventId()}
                SetError={(e) => SetError(e)}
                eventId={eventId} patientId={id} />
    */
    const _handleEventSelect = (eventId) => {
        setEvent(eventId)
    }

    const _handlePatientProfileFilters = async (WFID, trigger, evt) => {
        // Load extra details for this
        try {
            // const categoryId = filters.find(f => f.type === 'category').value.id
            const categoryId = category.id
            const props = trigger.properties.find(p => p.property === "categories")
            if (props) {
                const _props = JSON.parse(props.value)
                for (const cat of _props) {
                    if (cat.id === categoryId) {
                        return { category: cat, event: evt }
                    }
                }
            }
        } catch (e) { }
    }

    const _loadEvents = async () => {

        const events = await WorkFlow
            .ListEventsByTriggerGroup(group)
            .catch((e) => {
                //"Quick link item on patient profile"
            })

        var _evts = []
        for (const evt of events) {
            for (const trgr of evt.triggers) {
                switch (trgr.type.type) {
                    case 'Quick link item on patient profile':
                        const ret = await _handlePatientProfileFilters(evt.id, trgr, evt)
                        if (ret) {
                            _evts.push(ret)
                        }
                        break;
                    default:
                        break;
                }
            }
        }
        var _fltr = []
        for (const e of _evts) {
            var _exists = _fltr.find(f => f.group === e.category.groupNme)
            if (_exists)
                _exists.events.push(e.event)
            else
                _fltr.push({
                    group: e.category.groupNme,
                    events: [
                        e.event
                    ]
                })
        }
        setEvents(_fltr)
    }
    return (
        <>
            {
                event ?
                    <WorkFlowAssistantPopup patientId={patientId} eventId={event} onClose={() => setEvent()} />
                    : <></>
            }

            {
                events && events.length > 0 ?
                    <div className='flex-shrink-1 text-start  d-flex align-items-center bg-secondary-container px-2'>
                        <b className='pe-2'>Quick Actions</b>
                        {
                            events.map((e) =>
                                <WorkFlowQuickTasksItem onTriggered={(ev) => _handleEventSelect(ev)} evt={e} />
                            )
                        }
                    </div>
                    : <></>
            }
        </>
    )
}

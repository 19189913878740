import React, { useEffect } from 'react'
import { Clipboard2Pulse, ClockFill, ClockHistory, PersonCheckFill, PersonHeart, PersonHearts } from 'react-bootstrap-icons'
import QuestionnaireAssignment from '../../../../../libs/api/services/QuestionnaireAssignment'

export default function QuestionnaireAssignmentDone({ title, description, navigation, errors, setValue, values }) {

  useEffect(() => {
    title('Confirm and complete')
    description('Please confirm the following assignment details')
    // Load Navigation if session started
    // if (Object.keys(values).includes('templateId'))
    navigation([
      { text: 'Back', type: 'Back', action: async () => { } },
      {
        text: 'Complete', type: 'Done', action: async () => {
          await QuestionnaireAssignment
            .AssignQuestionnaire(values.questionnaire.id, values.user.userProfile.id,{
              assignedDate : values.scheduleDateTime,
              questionnaire : {
                name : ''
              },
              Responses: []
            });
        }
      },
    ])
  }, [])

  return (
    <div className='h-100 d-flex flex-column align-items-start m-3 bg-secondary-container rounded  p-2'>
      <div className='m-2'><Clipboard2Pulse size={20} className='me-2' /><b>Questionnaire</b>: {values.questionnaire.name}</div>
      <div className='m-2'><ClockHistory size={20} className='me-2' /><b>Scheduled to send</b> : {values.schedule} {values.scheduleDateTime ? values.scheduleDateTime : ''}</div>
      <div className='m-2'><PersonHearts size={20} className='me-2' /><b>Sending To</b> : {values.user.userProfile.firstName} {values.user.userProfile.lastName}</div>
    </div>
  )
}

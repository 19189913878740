import EventTriggerCalendarEventCreated from "../../calendar/workflow/triggers/EventTriggerCalendarEventCreated";
import EventTriggerCalendarEventCron from "../../calendar/workflow/triggers/EventTriggerCalendarEventCron";
import EventTriggerCalendarEventStatusChanged from "../../calendar/workflow/triggers/EventTriggerCalendarEventStatusChanged";
import EventTriggerCalendarEventTriggered from "../../calendar/workflow/triggers/EventTriggerCalendarEventTriggered";
import EventTriggerCron from "../../cron/workflow/triggers/EventTriggerCron";
import EventTriggerDashboardCard from "../../dashboards/workflow/triggers/EventTriggerDashboardCard";
import EventTriggerMessageReceived from "../../messaging/workflow/triggers/EventTriggerMessageReceived";
import EventTriggerPatientProfileOptions from "../../patient/workflow/triggers/EventTriggerPatientProfileOptions";
import EventTriggerQuestionnaireAlertTriggered from "../../questionnaires/workflow/triggers/EventTriggerQuestionnaireAlertTriggered";
import EventTriggerQuestionnaireComplete from "../../questionnaires/workflow/triggers/EventTriggerQuestionnaireComplete";
import EventTriggerQuestionnaireStarted from "../../questionnaires/workflow/triggers/EventTriggerQuestionnaireStarted";
import EventTriggerQuestionnaireTimePeriod from "../../questionnaires/workflow/triggers/EventTriggerQuestionnaireTimePeriod";
import EventTriggerToDoListCompleted from "../../todo/workflow/triggers/EventTriggerToDoListCompleted";
import EventTriggerToDoListIncompletedForX from "../../todo/workflow/triggers/EventTriggerToDoListIncompletedForX";
import EventTriggerToDoListReAssigned from "../../todo/workflow/triggers/EventTriggerToDoListReAssigned";
import EventTriggerToDoListRequestedAssistance from "../../todo/workflow/triggers/EventTriggerToDoListRequestedAssistance";
import EventTriggerToDoListStarted from "../../todo/workflow/triggers/EventTriggerToDoListStarted";
import EventTriggerToDoListStatusChange from "../../todo/workflow/triggers/EventTriggerToDoListStatusChange";


export const TriggerTypeMapping = {
    "PATIENT_PROFILE_QUICK_TASK": {
        component: <EventTriggerPatientProfileOptions />
    },

    "QUESIONNAIRE_STARTED": {
        component: <EventTriggerQuestionnaireStarted />
    },
    "QUESIONNAIRE_COMPLETE": {
        component: <EventTriggerQuestionnaireComplete />
    },
    "QUESIONNAIRE_ALERT_TRIGGERED": {
        component: <EventTriggerQuestionnaireAlertTriggered />
    },
    "QUESIONNAIRE_INCOMPLETE_FOR_A_PERIOD": {
        component: <EventTriggerQuestionnaireTimePeriod />
    },
    "MESSAGE_RECEIVED": {
        component: <EventTriggerMessageReceived />
    },
    "CALENDAR_EVENT_CREATE": {
        component: <EventTriggerCalendarEventCreated />
    },
    "CALENDAR_EVENT_STATUS_CHANGE": {
        component: <EventTriggerCalendarEventStatusChanged />
    },
    "CALENDAR_EVENT_DATE_CHANGE": {
        component: <EventTriggerCalendarEventCreated />
    },
    "CALENDAR_EVENT_EVENT": {
        component: <EventTriggerCalendarEventTriggered />
    },
    "CALENDAR_EVENT_CRON": {
        component: <EventTriggerCalendarEventCron />
    },
    "CRON": {
        component: <EventTriggerCron />
    },
    "DASHBOARD_CARD": {
        component: <EventTriggerDashboardCard />
    },
    "TODO_LIST_STATUS_CHANGE_TRIGGER": {
        component: <EventTriggerToDoListStatusChange />
    },
    "TODO_LIST_STARTED_TRIGGER": {
        component: <EventTriggerToDoListStarted />
    },
    "TODO_LIST_INCOMPLETE_FOR_TRIGGER": {
        component: <EventTriggerToDoListIncompletedForX />
    },
    "TODO_LIST_REASSIGNED_TRIGGER": {
        component: <EventTriggerToDoListReAssigned />
    },
    "TODO_LIST_ASISTANCE_REQUESTED_TRIGGER": {
        component: <EventTriggerToDoListRequestedAssistance />,
        description: "A request for assistance on a to do list is made on {to_do_list_requested_assistance.whichList} "
    },
    "TODO_LIST_COMPLETED_TRIGGER": {
        component: <EventTriggerToDoListCompleted />,
        description: "A to do list is completed on {to_do_list_requested_assistance.whichList} "
    },

}
// "TODO_LIST_CREATED_TRIGGER": <EventTriggerToDoListRequestedAssistance/>


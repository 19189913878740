import React, { useEffect, useState } from 'react'
import QCaptureComponent from './components/QCaptureComponent'
import { useParams } from 'react-router-dom'
import QuestionnaireAssignment from '../../../../libs/api/services/QuestionnaireAssignment'
import Questionnaire from '../../../../libs/api/services/Questionnaire'

export default function QCaptureEmbeded({ questionnaireId, QuestionnaireAssignmentId, className }) {
    const [qaid, setQaid] = useState(QuestionnaireAssignmentId)
    const [details, setDetails] = useState()

    const loadDetails = async () => {

        const res = await Questionnaire
            .GetQuestionnaireResultsFull(QuestionnaireAssignmentId)
        setDetails(res)
    }

    useEffect(() => {
        if (QuestionnaireAssignmentId === "New") {
            var QAID = localStorage.getItem("QAID")
            if (QAID != null) {
                // Remove Previous Data on load
                QuestionnaireAssignment
                    .RemoveColdRequest(questionnaireId, QAID)
                    .then((r) => {
                        if (r === false)
                            QuestionnaireAssignment
                                .NewColdRequest(questionnaireId)
                                .then((r) => {
                                    localStorage.setItem("QAID", r.id)
                                    setQaid(r.id)
                                })
                        else
                            setQaid(QAID)
                    })

            } else {
                QuestionnaireAssignment
                    .NewColdRequest(questionnaireId)
                    .then((r) => {
                        localStorage.setItem("QAID", r.id)
                        setQaid(r.id)
                    }).catch((e)=> console.error('Failed to create request', e))
            }
        }
    }, [])


    return (
        qaid === "New"
            ?
            <></>
            :
            <div className={'w-100 '.concat(className)}>
                <QCaptureComponent
                    view="New"
                    cacheddetails={details}
                    QuestionnaireAssignmentId={qaid}
                    QuestionnaireId={questionnaireId} />
            </div>
    )
}
// 718389BF-E1D4-47A8-B660-1B8287E40611
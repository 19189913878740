import React, { useEffect, useState } from 'react'
import { ClipboardHeart, Flag, PlusCircleDotted } from 'react-bootstrap-icons'
import { NavLink, useParams } from 'react-router-dom'
import Authentication from '../../../../libs/api/services/Authentication'
import QuestionnaireAssignmentAdmin from '../assignment/QuestionnaireAssignmentAdmin'
import Patient from '../../../../libs/api/services/Patient'
import QuestionnaireAssignment from '../../../../libs/api/services/QuestionnaireAssignment'
import Questionnaire from '../../../../libs/api/services/Questionnaire'
import { NavItem } from 'reactstrap'
import { Link } from 'react-router-dom';

export default function PatientQuestionnairesDashboard() {
    const [questionnaires, setQuestionnaires] = useState([])
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        QuestionnaireAssignment
            .GetUserQuestionnairesByType(Authentication.UserId(), 'Questionnaire')
            .then((r) => {
                setQuestionnaires(r)
                setLoading(false)
            })
    }, [])

    const _transitionToQuestionnaire = (loc) => {
        window.location = loc
    }

    return (
        <div className='text-center h-100'>
            <h1>Questionnaires</h1>
            <>
                <>
                    {
                        loading
                            ?
                            <div className='d-flex justify-content-center align-items-center h-75 flex-column'>
                                <ClipboardHeart className='m-2' size={50} />
                                Loading your questionnaires
                            </div>
                            :
                            questionnaires && questionnaires.length === 0
                                ?
                                <div className='d-flex justify-content-center align-items-center h-75 flex-column'>
                                    <ClipboardHeart  className='m-2' size={50} />
                                    Currently there are no questionnaires for you to complete
                                </div>
                                :
                                <div className='h-100'>
                                    <p>Please complete these questionnaires</p>
                                    <div style={{overflow: 'auto',height:'calc(100% - 150px)'}}>
                                    {
                                        questionnaires.map((q) => {
                                            return (
                                                <NavItem className='list-unstyled '>
                                                    <NavLink tag={Link} className="list-unstyled text-decoration-none " to={"/" + Authentication.getFacilityRole() + '/Questionnaire/' + q.questionnaire.id + "/" + q.id}>
                                                        <div
                                                            // onClick={(e) => _transitionToQuestionnaire(Authentication.getFacilityRole() + '/Questionnaire/' + q.id)}
                                                            style={{ cursor: 'pointer' }} className='bg-secondary-container m-2 p-2 rounded'>
                                                            <div><ClipboardHeart className='m-2' size={30} /></div>
                                                            {q.questionnaire.name}
                                                        </div>
                                                    </NavLink>
                                                </NavItem>
                                            )
                                        })

                                    }
                                    </div>
                                </div>
                    }
                </>

            </>
        </div>
    )
}

import React, { useEffect, useState } from 'react'
import { ExclamationTriangleFill, Link } from 'react-bootstrap-icons'
import { NavLink } from 'react-router-dom'
import Authentication from '../../../../libs/api/services/Authentication'
import Dashboards from '../../../../libs/api/services/Dashboards'

export default function DashboardWidgetAlert() {
    const [alerts, setAlerts] = useState([])
    useEffect(() => { _loadAlert() }, [])
    const _loadAlert = () => {
        Dashboards
            .ClinicianAlerts()
            .then((r) => setAlerts(r.filter(s => s.status === 'Alert')))
    }
    return (
        <div className='col-lg-4 col-md-12 dashboard-card'>
            <div className={'text-center p-3 rounded h-100 d-flex flex-column '.concat(alerts.length === 0 ? 'bg-success-container' : 'bg-danger-container')}>
                <div className='flex-shrink-1'>
                    <h3><ExclamationTriangleFill className='me-2' />Alerts</h3>
                </div>
                <div className='flex-grow-1 d-flex flex-column justify-content-center align-items-center'>
                    <div>{alerts.length === 0 ? 'There are no alerts that require your attention' : `You have ${alerts.length} ${alerts.length === 1 ? 'alert' : 'alerts'} that require your attention`}</div>
                </div>
                <div className='flex-shrink-1'>
                    <NavLink
                        key={'nav-msgs'}
                        className={"btn btn-sm"} tag={Link}
                        //   to={menuItems[i].location}
                        to={'/' + Authentication.getFacilityRole() + '/Alerts'}
                    // onClick={(e) => _navigateTo(_menu.location)}
                    >
                        <button className='btn btn-sm bg-light'><ExclamationTriangleFill className='me-2' />View Alerts</button>
                    </NavLink>

                </div>
            </div>
        </div>
    )
}

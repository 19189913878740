import React, { useEffect, useState } from 'react'
import { Clipboard2, Clock, ExclamationTriangle, Link } from 'react-bootstrap-icons'
import { NavLink } from 'react-router-dom'
import Authentication from '../../../libs/api/services/Authentication'
import Dashboards from '../../../libs/api/services/Dashboards'

export default function AlertsOverview() {
    const [alerts, setAlerts] = useState([])
    useEffect(() => { _loadAlert() }, [])
    const _loadAlert = () => {
        Dashboards
            .ClinicianAlerts()
            .then((r) => setAlerts(r))
    }
    const _findQuestion = (r) => {
        const q = r.response.answerType.properties.find(a => a.property === "Question")
        return q ? q.values : ''
    }

    const _setStatus = (id, s) => {
        Dashboards
            .SetAlertStatus(id, s)
            .then((r) => _loadAlert())
    }
    return (
        <div className='p-3'>
            {alerts.map((r) => {
                return (<div className={'rounded p-2 '.concat(r.status === 'Alert' ? 'bg-danger-container' : 'bg-warning-container')}>
                    <div><b><ExclamationTriangle /> {r.status}</b> - {r.questionnaireAssignment.assignedTo.firstName} {r.questionnaireAssignment.assignedTo.lastName}</div>
                    <div><Clock /> {r.response.answerDate}</div>
                    <div>
                        <NavLink
                            key={'nav-msgs'}
                            className={"btn btn-sm text-light"} tag={Link}
                            to={'/' + Authentication.getFacilityRole() + '/Patient/' + r.patient.id + '/Questionanire/' + r.questionnaireAssignment.questionnaire.id + '/' + r.questionnaireAssignment.id}
                        ><Clipboard2 /> View Questionnaire</NavLink>
                    </div>
                    <div>{r.questionnaireAssignment.assignedTo.firstName} {r.questionnaireAssignment.assignedTo.lastName} responded with {r.response.answer} {_findQuestion(r) !== '' ? ' when answering question ' + _findQuestion(r) : ''} on the {r.questionnaireAssignment.questionnaire.name} questionnaire</div>
                    <div className='text-center'>
                        <button onClick={() => _setStatus(r.id, 'Acknowldged')} className='btn btn-xs bg-warning-container'>Acknowldge</button>
                        <button onClick={() => _setStatus(r.id, 'Monitoring')} className='btn btn-xs bg-warning-container'>Monitoring</button>
                    </div>

                </div>)
            })}

        </div>
    )
}

import React from 'react'
import MessageEntries from './MessageEntries'
import MessagesNewLink from './MessagesNewLink'
import { MessagesProvider } from './MessagesProvider'

export default function WorkFlowMessagesContainer({ messages, event }) {
    return (
        <MessagesProvider defaultValue={messages} event={event}>
            <MessagesNewLink />
            <MessageEntries />
        </MessagesProvider>
    )
}

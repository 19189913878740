import RedirectFragmentOAuth from "./libs/RedirectFragmentOAuth";

const AppRoutesOAuth = [
  {
    path: '/OAuth',
    element: <RedirectFragmentOAuth type='Mail' />
  },
  {
    path: '/Calendar/OAuth',
    element: <RedirectFragmentOAuth type='Calendar' />
  },
];

export default AppRoutesOAuth;

import React from 'react'
import { useEffect } from 'react'
import { useState } from 'react'
import { SquareFill } from 'react-bootstrap-icons'
import Patient from '../../../../../libs/api/services/Patient'
import { ChevronBarLeft, ChevronBarRight } from 'react-bootstrap-icons'

export default function PatientProfileDetailTypeGrid({ optionId, fieldId, onSave, }) {
    const [grid, setGrid] = useState({ lg: 0, md: 0, sm: 0 })

    useEffect(() => {
        if (fieldId)
            Patient
                .FieldTypesOptionsDefaults(fieldId, optionId)
                .then((r) => {
                    if (r.length > 0 && r[0].value && r[0].value.length > 0)
                        setGrid(JSON.parse(r[0].value[0].property))
                })
    }, [])

    // Return Full List of Data
    const _handleSave = (_grid) => {
        const props = [{
            property: JSON.stringify(_grid)
        }]
        onSave(props)
    }

    const _handleSetGrid = (type, size) => {
        let _grid = { ...grid };
        _grid[type] = size
        _handleSave(_grid)
        setGrid(_grid)
    }
    return (
        <div>
            <div>Large Screen</div>
            <div className='layout-grid-selection d-flex'>
                <div className='col-2'>
                    <div
                        onContextMenu={(e) => e.preventDefault()}
                        onClick={(e) => grid.lg > 0 ? _handleSetGrid('lg', grid.lg - 1) : ''}>
                        <ChevronBarLeft fontSize={20} />
                    </div>
                </div>
                <div className='col-8'>
                    <div className={'col-' + grid.lg}><input placeholder='Sample Size' disabled='disabled' className='form-control form-control-sm' /></div>
                </div>
                <div className='col-2'>
                    <div
                        onContextMenu={(e) => e.preventDefault()}
                        onClick={(e) => grid.lg < 12 ? _handleSetGrid('lg', grid.lg + 1) : ''}>
                        <ChevronBarRight fontSize={20} />
                    </div>
                </div>
            </div>
            <div>Medium Screen</div>
            <div className='layout-grid-selection d-flex'>
                <div className='col-2'>
                    <div
                        onContextMenu={(e) => e.preventDefault()}
                        onClick={(e) => grid.md > 0 ? _handleSetGrid('md', grid.md - 1) : ''}>
                        <ChevronBarLeft fontSize={20} />
                    </div>
                </div>
                <div className='col-8'>
                    <div className={'col-' + grid.md}><input placeholder='Sample Size' disabled='disabled' className='form-control form-control-sm' /></div>
                </div>
                <div className='col-2'>
                    <div
                        onContextMenu={(e) => e.preventDefault()}
                        onClick={(e) => grid.md < 12 ? _handleSetGrid('md', grid.md + 1) : ''}>
                        <ChevronBarRight fontSize={20} />
                    </div>
                </div>
            </div>
            <div>Small Screen</div>
            <div className='layout-grid-selection d-flex'>
                <div className='col-2'>
                    <div
                        onContextMenu={(e) => e.preventDefault()}
                        onClick={(e) => grid.sm > 0 ? _handleSetGrid('sm', grid.sm - 1) : ''}>
                        <ChevronBarLeft fontSize={20} />
                    </div>
                </div>
                <div className='col-8'>
                    <div className={'col-' + grid.sm}><input placeholder='Sample Size' disabled='disabled' className='form-control form-control-sm' /></div>
                </div>
                <div className='col-2'>
                    <div
                        onContextMenu={(e) => e.preventDefault()}
                        onClick={(e) => grid.sm < 12 ? _handleSetGrid('sm', grid.sm + 1) : ''}>
                        <ChevronBarRight fontSize={20} />
                    </div>
                </div>
            </div>
        </div>
    )
}

import { faQuestionCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react"
import './Hint.css'

export default function HintWidget({ hint, children }) {
    const [showHint, setShowHint] = useState(false);
    const toggleHint = () => {
        // alert();
        //setShowHint(!setShowHint);
        setShowHint(!showHint);
    }

    //return (<><div onFocus={(e) => setShowHint(true)}>{children}<div className={(showHint) ? "d-block" : "d-none"}>{hint}</div></div></>)
    return (
        <>{children}</>
    // <div style={{ position: "relative" }}><div className="hint-button opacity-75 " style={{ position: "absolute", bottom: "0px", right: "0px", zIndex: 999 }} onClick={(e) => toggleHint()}><FontAwesomeIcon size="1x" icon={faQuestionCircle} /></div><div style={{ position: "relative" }}>{children}<div className={(showHint) ? "d-block hint-background text-light rounded rounded-3 p-3 shadow shadow-3" : "d-none"} onClick={(e) => toggleHint()} style={{ position: "absolute", top: "0px", right: "0px", zIndex: 999 }}>{hint}</div></div></div>
    
    )
}
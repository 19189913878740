import React, { useContext, useState } from 'react'
import { PersonRolodex, Search } from 'react-bootstrap-icons'
import Authentication from '../../libs/api/services/Authentication'
import CommonFunctionsContext from '../common/CommonFunctionsProvider'
import PatientearchBar from './PatientearchBar'

export default function FilteredSearchBar({ section }) {
    const { sectionInfo } = useContext(CommonFunctionsContext)
    const [mode, setMode] = useState()
    const _handleTitleBar = () => {
        switch (section) {
            case 'To Do': return <b>{`To do list for ${JSON.parse(Authentication.UserName())}`}</b>
            default: return JSON.parse(Authentication.UserName())
        }
    }
    return (
        <div className='d-flex align-items-center text-light'>
            <div className={`flex-grow-1 ${mode === 'Search' ? '' : ' m-md-2 '} text-center`}>
                {
                    mode === 'Search'
                        ?
                        <PatientearchBar />
                        :
                        sectionInfo ? <b>{sectionInfo}</b> : _handleTitleBar()
                }
            </div>
            <div className='text-end'>
                {mode !== 'Search' ?
                    <Search onClick={() => setMode(mode === 'Search' ? undefined : 'Search')} size={22} />
                    :
                    <PersonRolodex onClick={() => setMode(mode === 'Search' ? undefined : 'Search')} size={22} />
                }
            </div>
        </div>
    )
}

import EventDetails from "../OptionModal/CREATE_CALENDAR_EVENTComponents/EventDetails"

export const INITIATE_SEQUENCE = {
    "INITIATE_SEQUENCE": {
        title: '',
        onComplete: async (p) => {

            return true
        },
        flows: [
            {
                onBeforeNext: async (p) => {
                    if (new Date(p['0'].entry.startDateTime) > new Date(p['0'].entry.endDateTime))
                        throw Error("Starting date / time cannot be after end date / time")
                    return { patient: { id: 1 } }
                },
                component: <EventDetails />
            },
        ]
    }
}
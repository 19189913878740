import React, { useContext, useState } from 'react'
import HintWidget from '../../../../../components/ui/hint/HintWidget'
import SlideContainerWidget from '../../../../../components/ui/slide_container/SlideContainerWidget'
import ToggleButton from '../../../../../components/ui/toggle_button/ToggleButton'
import Patient from '../../../../../libs/api/services/Patient'
import PatientProfileContext from '../PatientProfileProvider'
import PatientProfileAdminCatgoryLink from '../category/PatientProfileAdminCatgoryLink'
import PatientProfileCategoryFieldType from './PatientProfileCategoryFieldType'
import PatientProfileCategoryFieldTypeOptions from './PatientProfileCategoryFieldTypeOptions'

export default function PatientProfileCategoryField({ categoryID, field, isNew }) {
    const [stateField, setStateField] = useState(field)
    const { execute } = useContext(PatientProfileContext)

    const _handleSetFieldName = (name) => {
        setStateField({ ...stateField, value: name })
    }

    const _handleSaveFieldName = (name) => {
        var _stateField = { ...stateField, value: name, type: { id: stateField.type.id } }
        if (stateField.type.id)
            execute('SAVE_NEW_CATEGORY_FIELD_TO_SERVER', { categoryID: categoryID, details: _stateField })
        setStateField({ ...stateField, value: name })
    }
    const _handleSetFieldType = async (id) => {
        //1. Save To DB
        var _stateField = { ...stateField, type: { id: id } }
        var r = await Patient.SaveDetails(categoryID, _stateField)
        _stateField.id = r.id;
        setStateField(_stateField)

        //        execute('SET_CATEGORY_FIELD_TYPE', { categoryID: categoryID, details: _stateField })
        //2. Pull Options with defaults
        //patientProfileDetailTypeOptions
    }
    const _handleSetFieldSearchable = (searchable) => {
        var _stateField = { ...stateField, value: stateField.value, type: { id: stateField.type.id }, searchable: searchable }
        if (stateField.type.id)
            execute('SAVE_NEW_CATEGORY_FIELD_TO_SERVER', { categoryID: categoryID, details: _stateField })
        setStateField({ ...stateField, searchable: searchable })
    }

    const _handleCreateField = async () => await execute('SAVE_NEW_CATEGORY_FIELD_TO_SERVER', { categoryID: categoryID, details: stateField })
    return !categoryID ? <></>
        :
        <div>
                <label>Field Name</label>
            {/* <div className='small text-start'><Key />{stateField.profileDetailKey} </div> */}
            <HintWidget hint={'This is the name of the field for the patient profile information eg: Patient Name, Patient Surname'}>
                <div className='row'>
                    <input onBlur={(e) => _handleSaveFieldName(e.target.value)} onChange={(e) => _handleSetFieldName(e.target.value)} value={stateField.value} className='form-control form-control-sm' placeholder="Field Name eg: First Name" />
                </div>
            </HintWidget>
            <div>
                <label>Field Type</label>
                <PatientProfileCategoryFieldType onChange={(id) => _handleSetFieldType(id)} field={stateField} />
            </div>
            <div>
                <HintWidget hint={'This allows you to set the default values and the layout of the form component'}>
                    <SlideContainerWidget
                        title={'Component Options'}
                        icon={'pencil'}>
                        <PatientProfileCategoryFieldTypeOptions isNew={isNew} fieldId={stateField.id} fieldTypeId={stateField.type.id} />
                    </SlideContainerWidget>
                </HintWidget>

            </div>
            <div className='text-start'>
                <ToggleButton style={'tick'} onToggle={(t) => _handleSetFieldSearchable(t)} defaultValue={stateField.searchable}>
                    Field to be included in searches
                </ToggleButton>
            </div>
            <div className='text-start'>
                {stateField.id ?
                    <HintWidget hint={"This allows you to link related data to avoid it being asked repeatedly"}>
                        <PatientProfileAdminCatgoryLink className="pe-3" sourceComponent={'Patient'} fieldId={stateField.id} />
                    </HintWidget>

                    : <></>}
            </div>
            <div className='row '>
                {/* <button onClick={(e) => _handleCreateField()} className='btn'>{stateField.id ? 'Update Field' : 'Add Field'}</button> */}
                {isNew ? <button onClick={(e) => _handleCreateField().then(() => setStateField({ value: '', type: { id: '', type: '' } }))} className='btn'>Done</button> : <></>}

            </div>
        </div>
}

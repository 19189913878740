import React from 'react'
import PatientearchBar from '../search/PatientearchBar'
import { PatientProfileProvider } from '../section/patient/admin/PatientProfileProvider'
import { PatientHistoryProvider } from '../section/patient/history/PatientHistoryProvider'
import { PatientProvider } from '../section/patient/management/PatientProvider'
import { Patientayout } from './PatientLayout'


export default function ClinicianManagementLayout({ menu, search, container, breadcrums }) {
    return (
        // TODO : Implment Provider Here
        //        Load Menu items from API
        //        Render fields for category
        <PatientProfileProvider>
            <PatientProvider>
                <PatientHistoryProvider>
                    <Patientayout key={"LO_Patient_" + new Date().getTime()} menu={menu} search={search ? search : <PatientearchBar />} container={container} breadcrums={breadcrums} />
                </PatientHistoryProvider>
            </PatientProvider>
        </PatientProfileProvider>
    )
}

import React, { useEffect, useState } from 'react'
import CalendarGenerics from '../../../CalendarGenerics'
import { Calendar2, XCircle } from 'react-bootstrap-icons'

export default function CalendarEventPopup({ event }) {
    const [visible, setVisble] = useState(false)

    useEffect(() => {
        if (typeof event !== 'undefined')
            setVisble(true)
    }, [event])

    return (
        <div className={'position-absolute d-flex align-items-center justify-content-center '.concat(visible ? 'd-block' : 'd-none')} style={{ zIndex: 999, top: '0px', left: '0px', right: '0px', bottom: '0px', visibility: visible ? 'visible' : 'hidden' }}>
            {
                event ?
                    <div className='bg-primary-container w-75 h-75 shadow rounded rounded-4'>
                        <div className='d-flex justify-content-between p-1'>
                            <h2><Calendar2 className='me-2' />{CalendarGenerics.daysOfWeek[event.date.start.getDay()].short} {event.date.start.getDate()} {CalendarGenerics.months[event.date.start.getMonth()].short} {event.date.start.getFullYear()} {String(event.date.start.getHours()).padStart(2, '0')}:{String(event.date.start.getMinutes()).padStart(2, '0')}</h2>
                            <div style={{ cursor: 'pointer' }} onClick={() => setVisble(false)}><XCircle size={30} /></div>
                        </div>
                    </div>
                    :
                    <></>
            }

        </div>
    )
}

import React, { useContext, useState } from 'react'
import ToggleButton from '../../../../../../../components/ui/toggle_button/ToggleButton'
import CalendarEventStatus from '../../../../components/EventStatus/CalendarEventStatus'
import CalendarFacilityContext from '../../../CalendarFacilityProvider'

export default function CalendarEventMoveEvent() {
  const { calendarEvent, setCalendarEventProperty } = useContext(CalendarFacilityContext)
  const [keep, setKeep] = useState(false)
  const _findProp = (name) => {
    if (!calendarEvent || !calendarEvent.properties) return ""
    const _val = calendarEvent.properties.find(n => n.name === name)
    return _val ? _val.value : ""
  }
  const _handleChange = (k, v) =>
    setCalendarEventProperty(k, v)

  return (
    <div className='p-2'>
      <ToggleButton onToggle={(f) => _handleChange('KEEP_ORIGINAL', JSON.stringify(f))} defaultValue={_findProp('KEEP_ORIGINAL') && JSON.parse(_findProp('KEEP_ORIGINAL'))} style={'tick'}>Keep existing</ToggleButton>
      {
        _findProp('KEEP_ORIGINAL') && JSON.parse(_findProp('KEEP_ORIGINAL')) ?
          <div>
            <label>Change original status to</label>
            <CalendarEventStatus onChange={(v) => _handleChange('DEFAULT_STATUS_ORIGINAL', v)} defaultValue={_findProp('DEFAULT_STATUS_ORIGINAL')} />
            <label>Update original event details to</label>
            <input
              onChange={(e) => _handleChange('DEFAULT_SUMMARY_ORIGINAL', e.target.value)}
              defaultValue={_findProp('DEFAULT_SUMMARY_ORIGINAL')}
              className='form-control form-control-sm' placeholder='Default Updated Summary' />
            <textarea
              onChange={(e) => _handleChange('DEFAULT_DETAILS_ORIGINAL', e.target.value)}
              defaultValue={_findProp('DEFAULT_DETAILS_ORIGINAL')}
              className='form-control form-control-sm' placeholder='Default updated event details' ></textarea>
          </div>
          :
          <></>
      }

      <div>
        <label>New events default status</label>
        <CalendarEventStatus onChange={(v) => _handleChange('DEFAULT_STATUS_NEW', v)} defaultValue={_findProp('DEFAULT_STATUS_NEW')} />
        <label>Update original event details to</label>
        <input
          onChange={(e) => _handleChange('DEFAULT_SUMMARY_NEW', e.target.value)}
          defaultValue={_findProp('DEFAULT_SUMMARY_NEW')}
          className='form-control form-control-sm' placeholder='Default Updated Summary' />
        <textarea
          onChange={(e) => _handleChange('DEFAULT_DETAILS_NEW', e.target.value)}
          defaultValue={_findProp('DEFAULT_DETAILS_NEW')}
          className='form-control form-control-sm' placeholder='Default updated event details' ></textarea>
      </div>
    </div>
  )
}

import React from 'react'

export default function DateTimeContainer({ date, children }) {
    return (
        <div>
            <div className='bg-primary-container text-center'><h3>{date}</h3></div>
            {children}
        </div>
    )
}

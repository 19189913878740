import React, { useEffect, useState } from 'react'
import Facility from '../../../../libs/api/services/Facility'
import User from '../../../../libs/api/services/User'

export default function NewUserRole({ changeStep, UserID, FacilityID, defaultFacility, rolesActive, setRolesActive }) {
    const [roles, setRoles] = useState([])
    // const [rolesActive, setRolesActive] = useState([])
    const [error, setError] = useState()

    useEffect(() => {
        const _loadRoles = async () => {
            Facility.roles()
                .then((r) => {
                    setRoles(r)
                })
        }
        _loadRoles()
    }, [])

    const _selectRole = (role) => {
        var _rolesActive = rolesActive
        if (!rolesActive.includes(role)) {
            _rolesActive.push(role)
        } else {
            _rolesActive.splice(_rolesActive.indexOf(parseInt(role, 10)), 1)
        }
        setRolesActive([..._rolesActive])
        User
            .addFacility(UserID, FacilityID, role)
            .then((r) => {
                // changeStep(5)
            }).catch((e) => {
                setError(e.message)
            })
    }


    return (
        <>
            <div className="head">
                <h1>User Role</h1>
                <p>Please select the role this user will play in this facility</p>
                <div className="wizard-navigation-steps">
                    <span className="wizard-navigation-step in-progress"></span>
                    <span className="wizard-navigation-step "></span>
                </div>
                <div className="error-details">{error}</div>
            </div>
            <div className="body">
                <div className='row text-center row card-options'>
                    {
                        roles.map((role) => {
                            return <div key={role.id} className={'col-12 col-md-4 p-3 border border-1 card-option '.concat(rolesActive.includes(role.id) ? 'active' : '')} onClick={(e) => _selectRole(role.id)} >{role.role}</div>
                        })
                    }

                </div>
            </div>
            <div className="tail">
                {
                    rolesActive.length > 0
                        ?
                        <>
                            <button onClick={(e) => defaultFacility ? changeStep(1) : changeStep(4)} className="btn ng-buttons">Back</button>
                            <button onClick={(e) => changeStep(5)} className="btn ng-buttons">Next</button>
                        </>
                        :
                        <button onClick={(e) => defaultFacility ? changeStep(1) : changeStep(2)} className="btn ng-buttons">Back</button>
                }

            </div >
        </>
    )
}

import React, { useContext } from 'react'
import { BiMessageAltDetail } from 'react-icons/bi'
import { FaCalendar, FaClipboardList, FaFile } from 'react-icons/fa'
import CommonFunctionsContext from '../../../../../common/CommonFunctionsProvider'
import CalendarEntry from '../../../../workflow/management/Sections/Calendars/CalendarEntry'
import DocumentLineItem from '../../../../workflow/management/Sections/Documents/DocumentLineItem'
import { DocumentsProvider } from '../../../../workflow/management/Sections/Documents/DocumentsProvider'
import MessageEntry from '../../../../workflow/management/Sections/Messages/MessageEntry'
import NoteLineItem from '../../../../workflow/management/Sections/Notes/NoteLineItem'
import QuestionnaireLineItem from '../../../../workflow/management/Sections/Questionnaires/QuestionnaireLineItem'
import WorkFlowDetailsScreen from '../../../../workflow/management/WorkFlowDetailsScreen'
import WorkFlowManagementContext from '../../../../workflow/management/WorkFlowManagementProvider'

export default function WorkFlowHistoryEntryLineItem({ item }) {
    const { createModal } = useContext(CommonFunctionsContext)
    const { load } = useContext(WorkFlowManagementContext)
    const _handleItems = () => {
        var items = []
        //documents
        if (item.documents) { 
            var cont = []
            for (const n of item.documents) {
                cont.push(<DocumentLineItem hideRemove item={n.document} />)
            }
            if (cont.length > 0)
                items.push(<div>
                    <b><FaFile /> Documents</b>
                    {cont}
                </div>)
        }
        // notes
        if (item.notes) {
            var cont = []
            for (const n of item.notes) {
                cont.push(<NoteLineItem hideRemove note={n.note} />)
            }
            if (cont.length > 0)
                items.push(<div>
                    <b><FaClipboardList /> Notes</b>
                    {cont}
                </div>)
            // items.push(<div><b>Quesionnaires / Forms</b>{cont}</div>)
        }
        //questionnaireAssignments
        if (item.questionnaireAssignments) {
            var cont = []
            for (const assign of item.questionnaireAssignments) {
                cont.push(<QuestionnaireLineItem item={assign} />)
            }
            if (cont.length > 0)
                items.push(<div><b><FaClipboardList /> Questionnaires / Forms</b>{cont}</div>)
            // items.push(<div><b>Quesionnaires / Forms</b>{cont}</div>)
        }
        //messages
        if (item.messages) {
            var cont = []
            for (const msg of item.messages) {
                cont.push(<MessageEntry item={msg} />)
            }
            if (cont.length > 0)
                items.push(<div><b><BiMessageAltDetail /> Messages</b>{cont}</div>)
            // items.push(<div><b>Quesionnaires / Forms</b>{cont}</div>)
        }
        // calendarEntries
        if (item.calendarEntries) {
            var cont = []
            for (const cal of item.calendarEntries) {
                cont.push(<CalendarEntry item={cal} />)
            }
            if (cont.length > 0)
                items.push(<div><b><FaCalendar /> Calendar Events</b>{cont}</div>)
            // items.push(<div><b>Quesionnaires / Forms</b>{cont}</div>)
        }
        return <DocumentsProvider defaultValue={[item.documents]}>{items}</DocumentsProvider>
    }

    return (
        <div>
            <div
                style={{ cursor: 'pointer' }}
                onClick={() => createModal(<WorkFlowDetailsScreen id={item.id} />, null, null,
                    () => load()
                )
                    //<WorkFlowDetailsScreen />
                    // window.location = 'workflow/' + item.id
                }
                className='bg-primary-container p-2 ms-1 shadow rounded d-flex justify-content-between'>
                <b>{item.wfEvent && item.wfEvent.name}</b>
                <i className='small'>{item.triggeredDateTime.substring(item.triggeredDateTime, 16)}</i>
            </div>
            <div className='ms-3 small'>
                {_handleItems()}
            </div>
            {/* {item.id} */}
            <pre>
                {/* {JSON.stringify(item, '\t', 2)} */}
            </pre>
        </div>
    )
}

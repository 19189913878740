import React, { useEffect, useState } from 'react'
import Questionnaire from '../../../../../libs/api/services/Questionnaire'
import QBuilderComponent from '../components/QBuilderComponent'
import QBuilderSectionSelection from '../components/widgets/QBuilderSections/QBuilderSectionSelection'
import QBuilderLayoutComponentWrapper from './Layouts/QBuilderLayoutComponentWrapper'

export default function QBuilderLayoutQuestions({ title, description, navigation, errors, setValue, values }) {
    const [questionnair, setQuestionnaire] = useState([])
    const [mode, setMode] = useState('')
    const [dragOrder, setDragOrder] = useState(-10)

    const _handleAddComponent = (e, i) => {

        switch (e.action) {
            case 'Add':
                setMode('')
                var _questionnair = questionnair
                Questionnaire
                    .AddComponentToSection(e.subseciontId ? e.subseciontId : values.section.id, {
                        question: ''
                    }).then((r) => {
                        //_questionnair.push({ id: r.id, componentType: e.type })
                        _questionnair.push({ id: r.id, componentType: { type: 'New' } })
                        setQuestionnaire([..._questionnair])
                    }).catch((e) => { if (e.errors) errors(e.errors); else errors(e.message); })
                break;
            case 'Remove':
                var _questionnair = [...questionnair]
                // //_questionnair.push({ type: e.type })
                // setQuestionnaire([..._questionnair])
                Questionnaire
                    .RemoveComponent(i ? i : _questionnair[e.idx].id).then((r) => {
                        //alert(e.idx)
                        if (i)
                            _handleReload()
                        else
                            _questionnair.splice(e.idx, 1)
                        // alert(JSON.stringify(_questionnair))
                        setQuestionnaire([..._questionnair])
                    }).catch((e) => { if (e.errors) errors(e.errors); else errors(e.message); })
                break;
            case 'Clone':
                var _questionnair = [...questionnair]
                // //_questionnair.push({ type: e.type })
                // setQuestionnaire([..._questionnair])
                Questionnaire
                    .CloneComponent(i ? i : _questionnair[e.idx].id).then((r) => {
                        //alert(e.idx)
                        //if (i)
                        _handleReload()
                        // _questionnair.push(_questionnair[e.idx])
                        // alert(JSON.stringify(_questionnair))
                        // setQuestionnaire([..._questionnair])
                    }).catch((e) => { if (e.errors) errors(e.errors); else errors(e.message); })
                break;
            case 'Set Type':
                var _questionnair = questionnair
                // //_questionnair.push({ type: e.type })
                // setQuestionnaire([..._questionnair])
                Questionnaire
                    .SetComponentType(_questionnair[e.idx].id, {
                        type: e.type
                    }).then((r) => {
                        _questionnair[e.idx].componentType.type = e.type
                        // _questionnair[e.idx].id = r.id
                        setQuestionnaire([..._questionnair])
                    }).catch((e) => { if (e.errors) errors(e.errors); else errors(e.message); })
                break;
            case 'Sub Section':
                var _questionnair = questionnair
                // //_questionnair.push({ type: e.type })
                // setQuestionnaire([..._questionnair])
                Questionnaire
                    .SetComponentType(_questionnair[e.idx].id, {
                        type: e.type
                    }).then((r) => {
                        _questionnair[e.idx].componentType.type = e.type
                        // _questionnair[e.idx].id = r.id
                        setQuestionnaire([..._questionnair])
                    }).catch((e) => { if (e.errors) errors(e.errors); else errors(e.message); })
                break;
            case 'Reload':
                _handleReload()
                break;
            default:
                break;
        }
    }

    const _handleChangeSection = (section) => {
        setValue({ section: section })
        description('Setup the questions and answer types ')
    }

    useEffect(() => { load() }, [values])
    const load = () => {
        if (values.section)
            Questionnaire
                .ListSectionComponents(values.section.id)
                .then((r) => {
                    // alert(JSON.stringify(r))
                    setQuestionnaire([...r])
                    setMode('Overview')
                }).catch((e) => { if (e.errors) errors(e.errors); else errors(e.message); })
    }

    useEffect(() => {
        title('Questions')
        if (!values.section)
            description('Create or select a section')
        else
            description('Setup the questions and answer types ')
        // Load Navigation if session started
        // if (Object.keys(values).includes('templateId'))
        navigation([
            // { text: 'Remove', type: 'Action', action: async () => { _handleRemoveTemplate(values.templateId).then((r) => { navigation([]); loadConfigs() }) } },
            {
                text: 'Done', type: 'Done', action: async () => {
                    // setValue({ 'templateId': values.templateId })
                }
            },
            // {
            //     text: 'Back', type: 'Back', action: async () => {
            //         // setValue({ 'templateId': values.templateId })
            //     }
            // },
            // {
            //     text: 'Sections', type: 'Step', step: 3, action: async () => {
            //         // setValue({ 'templateId': values.templateId })
            //     },
            // },
            // {
            //     text: 'Next', type: 'Next', action: async () => {
            //         // setValue({ 'templateId': values.templateId })
            //     }
            // }
        ])
        // else
        //     navigation([])
        // load()
    }, [])

    const _handleChangeOrder = (ComponentId) => {
        if (dragOrder && dragOrder > -2)
            Questionnaire
                .SetComponentOrder(ComponentId, dragOrder)
                .then((r) => {
                    load()
                })
    }

    const _handleChangeLayout = (id, style) => {
        Questionnaire
            .SetComponentProperty(id, {
                property: 'Layout',
                values: JSON.stringify(style),
                component: {
                    id: id
                }
            }).then((r) => {
                load()
            })
    }

    const _handleReload = (id, style) => load()

    const _handleLayout = () => {
        var ret = []
        var row_comps = []
        var _cur_row_width = 0;
        for (var i = 0; i < questionnair.length; i++) {
            var _width = 12
            var _offset = 0
            var layout = undefined;
            var _layout = undefined;
            try {
                layout = questionnair[i].properties.find(p => p.property === 'Layout').values
                if (layout) {
                    _layout = JSON.parse(layout)
                    _width = _layout.width
                    _offset = _layout.offset
                }
            } catch (e) { layout = undefined }
            const id = questionnair[i].id
            _cur_row_width += _width + _offset
            var cn = 'col-' + _width
            cn += ' offset-' + _offset
            row_comps.push(
                <QBuilderLayoutComponentWrapper
                    onUpdateLayout={(layout) => _handleChangeLayout(id, layout)}
                    defaultLayout={_layout}
                    className={cn}
                ><QBuilderComponent
                        idx={i}
                        // className={cn}
                        key={questionnair[i].id}
                        onAction={(e, i) => _handleAddComponent(e, i)}
                        onRerender={() => _handleReload()}
                        type={questionnair[i].componentType ? questionnair[i].componentType.type : 'New'}
                        mode={mode}
                        order={questionnair[i].order}
                        dragOrder={dragOrder}
                        onChangedOrder={(ComponentId) => _handleChangeOrder(ComponentId)}
                        setDragOrder={(o) => setDragOrder(o)}
                        ComponentId={questionnair[i].id}
                        SectionComponents={questionnair}
                        values={values}
                    /></QBuilderLayoutComponentWrapper>
            )
            if (_cur_row_width >= 12) {
                ret.push(<div className='row'>{row_comps}</div>)
                row_comps = []
                _cur_row_width = 0
            }

        }
        ret.push(<div className='row'>{row_comps}</div>)
        row_comps = []
        return ret;
    }
    return (
        <div className=''>
            <div className='bg-secondary-container p-2 rounded m-2 shadow '>
                <b>Section</b>
                <QBuilderSectionSelection values={values} onChange={(sid) => _handleChangeSection(sid)} id={values.questionnaireId} />
            </div>
            <div className=''>
                <>
                    {values.section ? _handleLayout() : <></>}
                    {
                        values.section ?
                            <QBuilderComponent
                                dragOrder={dragOrder}
                                setDragOrder={() => { }}
                                onAction={(e) => _handleAddComponent(e)} type={''} />
                            : <></>
                    }

                </>
            </div>
        </div>
    )
}
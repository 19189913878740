import API from "../API";
import jwt_decode from "jwt-decode";

var FlowAssistant = {
    async BookmarkFlowAndGoTo(location, section, details, destination, step) {
        // var result = await API.process(
        //     'Authentication/authenticate',
        //     'POST',
        //     data
        // ).catch((err) => {
        //     throw err;
        // })

        // const token = result.token;
        // localStorage.setItem('token', token);


        // // if has admin or has facilities facility screen
        // window.location = '/facilities';
        // // If patient only go to main screen
        // return result
        var flow = []
        try {
            flow = JSON.parse(localStorage.getItem('flow'));
            if (!flow) flow = []
        } catch (e) {
        }
        flow.push({ section: section, details: details, location: location, step: step })
        localStorage.setItem('flow', JSON.stringify(flow));

        return window.location = destination;

    },
    GetLast() {
        try {
            const flows = JSON.parse(localStorage.getItem('flow'));
            if (flows.length > 0)
                return flows[flows.length - 1]
        } catch (e) {
        }
        return undefined
    },
    async CheckNextBookmarked(section) {

    },
    async Goto(section) {
        try {
            const flows = JSON.parse(localStorage.getItem('flow'));
            if (flows.length > 0)
                window.location = flows.find(f => f.section === section).location
        } catch (e) {
        }
        return {}
    },
    async CheckNextBookmarked(section) {

    },
    async HasRedirected(section) {
        try {
            const flows = JSON.parse(localStorage.getItem('flow'));
            flows.pop()
            localStorage.setItem('flow', JSON.stringify(flows));
            // if (flows.length > 0)
            //     window.location = flows.find(f => f.section === section).location
        } catch (e) {
        }
    }
}

export default FlowAssistant;
import { faClose, faPencil, faPlus } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useState } from 'react'

import './animation.css'

export default function SlideContainerWidget({ state, title, className, icon, options, children }) {
    const [open, setOpen] = useState(state)
    const _toggleOpen = () =>
    {
        setOpen(!open)
        if (state) state(open)
    }
    return (
        <div className='container-fluid'>
            <div className='d-flex justify-content-between align-items-center'>
                <div className={'expandable '.concat(!open ? 'slide-down' : 'slide-up')}
                    onClick={(e) => _toggleOpen()}>{title}</div>
                <div className={'expandable '.concat(open ? 'slide-down' : 'slide-up')} >{options}</div>
                <div className='btn btn-sm ' onClick={(e) => _toggleOpen()}>{(icon === 'plus') ?  <FontAwesomeIcon icon={!open ? faPlus : faClose} /> :<FontAwesomeIcon icon={!open ? faPencil : faClose} />}</div>
            </div>
            <div className={'expandable bellow p-2 rounded '.concat(open ? 'slide-down' : 'slide-up').concat(' ' + className)}>
                {children}
            </div>
        </div>
    )
}

// import { jwtDecode } from "jwt-decode";
import { jwtDecode } from "jwt-decode";
import API from "../API";

var Authentication = {
    async login(data) {
        var result = await API.process(
            'Authentication/authenticate',
            'POST',
            data
        ).catch((err) => {
            throw err;
        })

        const token = result.token;
        localStorage.setItem('token', token);

        // if has admin or has facilities facility screen
        window.location = '/facilities';
        // If patient only go to main screen
        return result
    },
    async token() {
        return localStorage.getItem('token');
    },
    async refresh() {
        if (jwtDecode(localStorage.getItem('token')).exp * 1000 > Date.now())
            return jwtDecode(localStorage.getItem('token')).sub;
    },
    async refreshToken(token) {
        localStorage.setItem('token', token);
    },
    async logout() {
        localStorage.removeItem('AcceptedTNC')
        await API.process(
            'Authentication/logout',
            'POST',
            {
                token: localStorage.getItem('token')
            }
        ).catch((err) => {
            // throw err;
        }).then((r) => {
            try {
                localStorage.removeItem('token');
                localStorage.removeItem('facility')
            } catch (e) {

            }
        })
    },
    async facilities() {
        try {
            const user = jwtDecode(localStorage.getItem('token'));
            return JSON.parse(user.faclities)
        } catch (e) {
            if (e.message === "Invalid token specified")
                return (window.location.href.indexOf('/logout') === -1) ? window.location = '/expired' : undefined;
        }
    },
    facilitiesCnt() {
        try {
            const user = jwtDecode(localStorage.getItem('token'));
            return JSON.parse(user.faclities).length
        } catch (e) {
            if (e.message === "Invalid token specified")
                return (!window.location.href.indexOf('/logout')) ? window.location = '/logout' : undefined;
        }
    },
    isClaimValid(claim) {
        var roles = {};
        if (!localStorage.getItem('token') || localStorage.getItem('token') === "") {
            return (!window.location.href.indexOf('/logout')) ? window.location = '/logout' : undefined;
        }
        try {
            const user = jwtDecode(localStorage.getItem('token'));
            roles = JSON.parse(user.roles)

            if (claim === "System Admin")
                return (roles.find(r => r.Role === claim));

            let facilityId = localStorage.getItem('facility');
            let roleId = localStorage.getItem('role');
            let faclities = JSON.parse(user.faclities)
            for (var i = 0; i < faclities.length; i++) {
                if (claim === "Super" && faclities[i].Facility.Id === parseInt(facilityId)) {
                    if (faclities[i].Roles.Role === claim) return true;
                }

                if (faclities[i].Facility.Id === parseInt(facilityId) && faclities[i].Roles.Id === parseInt(roleId)) {
                    // return faclities[i].Roles.Role === claim;
                    if (faclities[i].Roles.Role === claim) return true;
                }
            }

        } catch (e) {
            if (e.message === "Invalid token specified")
                return (!window.location.href.indexOf('/logout')) ? window.location = '/logout' : undefined;
        }
        return (roles.find(r => r.Role === claim));
        // Check expired
        // Read Token
        // Get Claims
        // Check claim in list
        // return true;
    },
    setFacility(id, roleId) {
        localStorage.setItem('facility', id)
        localStorage.setItem('role', roleId)
        if (
            String(jwtDecode(localStorage.getItem('token')).tnc) === "false" &&
            (!localStorage.getItem('AcceptedTNC') || localStorage.getItem('AcceptedTNC') !== 'true')
        ) {
            window.location = '/atnc'
            return false
        }
        return true
        // alert(String(jwtDecode(localStorage.getItem('token')).tnc))
        // return window.location = '/atnc'

    },
    getFacilityName() {
        if (localStorage.getItem('token')) {
            const user = jwtDecode(localStorage.getItem('token'));
            if (user.faclities) {
                const facilities = JSON.parse(user.faclities)
                for (var facility of facilities)
                    if (facility.Facility.Id === parseInt(localStorage.getItem('facility')))
                        return facility.Facility.facility
            }
        }
        return ''
    },
    getFacilityDetails() {
        if (localStorage.getItem('token')) {
            const user = jwtDecode(localStorage.getItem('token'));
            if (user.faclities) {
                const facilities = JSON.parse(user.faclities)
                for (var facility of facilities)
                    if (facility.Facility.Id === parseInt(localStorage.getItem('facility')))
                        return facility.Facility
            }
        }
        return ''
    },
    getFacility() {
        return localStorage.getItem('facility')
    },
    getFacilityRole() {
        var roles = {};
        if (!localStorage.getItem('token') || localStorage.getItem('token') === "") {
            return (!window.location.href.indexOf('/logout')) ? window.location = '/logout' : undefined;
        }
        try {
            const user = jwtDecode(localStorage.getItem('token'));
            let facilityId = localStorage.getItem('facility');
            let roleId = localStorage.getItem('role');
            let faclities = JSON.parse(user.faclities)
            for (var i = 0; i < faclities.length; i++) {
                if (faclities[i].Facility.Id === parseInt(facilityId) && faclities[i].Roles.Id === parseInt(roleId)) {
                    return faclities[i].Roles.Role;
                }
            }
            roles = JSON.parse(user.roles)
        } catch (e) {
            if (e.message === "Invalid token specified")
                return (!window.location.href.indexOf('/logout')) ? window.location = '/logout' : undefined;
        }
        return (!window.location.href.indexOf('/logout')) ? 'logout' : undefined;
    },
    UserId(ignoreRedirect) {

        var roles = {};
        if ((!localStorage.getItem('token') || localStorage.getItem('token') === "") && !ignoreRedirect) {
            return (!window.location.href.indexOf('/logout')) ? window.location = '/logout' : undefined;
        }
        try {
            const user = jwtDecode(localStorage.getItem('token'));
            return user.id
            // let facilityId = localStorage.getItem('facility');
            // let faclities = JSON.parse(user.faclities)
            // for (var i = 0; i < faclities.length; i++) {
            //     if (faclities[i].Facility.Id === parseInt(facilityId)) {
            //         return faclities[i].Roles.Role;
            //     }
            // }
            // roles = JSON.parse(user.roles)
        } catch (e) {
            if (e.message === "Invalid token specified" && !ignoreRedirect)
                return (!window.location.href.indexOf('/logout')) ? window.location = '/logout' : undefined;
        }
        return (!window.location.href.indexOf('/logout')) ? 'logout' : undefined;
    },
    PID() {


        try {
            const user = jwtDecode(localStorage.getItem('token'));
            try {
                return JSON.parse(user.pid)
            } catch (e) {
                return;
            }
            // let facilityId = localStorage.getItem('facility');
            // let faclities = JSON.parse(user.faclities)
            // for (var i = 0; i < faclities.length; i++) {
            //     if (faclities[i].Facility.Id === parseInt(facilityId)) {
            //         return faclities[i].Roles.Role;
            //     }
            // }
            // roles = JSON.parse(user.roles)
        } catch (e) {

        }

    },
    UserName() {
        // if (!localStorage.getItem('token') || localStorage.getItem('token') === "") {
        //     return window.location = '/logout';
        // }
        try {
            const user = jwtDecode(localStorage.getItem('token'));
            return user.name
        } catch (e) {
            // if (e.message === "Invalid token specified")
            //     return (!window.location.href.indexOf('/logout')) ? window.location = '/logout' : undefined;
        }
        return null
        // return (!window.location.href.indexOf('/logout')) ? 'logout' : undefined;
    },
    async verifyToken() {
        if (localStorage.getItem('token') && jwtDecode(localStorage.getItem('token')).exp * 1000 < Date.now()) // Expired
        {
            if (window.location.pathname !== "/logout") {
                localStorage.removeItem('token');
                localStorage.removeItem('facility')
                localStorage.removeItem('role')
                if (window.location.href.indexOf('/logout') === -1) window.location = '/expired'
            }
        }
    },
    async logoutFacility() {

        if (localStorage.getItem('token')) // Expired
        {
            localStorage.removeItem('facility')
            localStorage.removeItem('role')
        }
    },
    async ResetPasswordRequest(username) {

        var result = await API.process(
            'Authentication/password',
            'PUT',
            // undefined
            { token: username }
        ).catch((err) => {
            throw err;
        })


        return result
    },
    async DoResetPassword(req) {

        var result = await API.process(
            `Authentication/password`,
            'PATCH',
            req
        ).catch((err) => {
            throw err;
        })


        return result
    },
    async AcceptTnC() {

        localStorage.setItem('AcceptedTNC', true)
        var result = await API.process(
            `Authentication/AcceptTnC/${Authentication.UserId()}`,
            'PUT',
            null
        ).catch((err) => {
            throw err;
        })


        return result
    },
}

export default Authentication;
import React, { useState } from 'react'
import { PlusCircle, XCircle } from 'react-bootstrap-icons'
import EventTaskTypesSelection from '../EventTaskTypesSelection'
// import WorkFlow from '../../../../../../../../libs/api/services/WorkFlow'
// import EventTaskTypesSelection from '../EventTaskTypesSelection'

export default function NewTaskLineItem({ onAdded, errors, values, setValue }) {
    const [mode, setMode] = useState()

    const _handleSelectTaskType = (type) => {
        var details = ''
        switch (type.type) {
            case 'Messaging':
                details = 'Send Message '
            default: details = 'New Task'
        }
        onAdded('New Task Details', type)
    }

    const _TaskTypeSelection = () => {
        return <div className='p-2'>
            <div className='d-flex align-items-center'>
                <div className='flex-grow-1'>
                    <b>Select the type of task you would like to create</b>
                </div>
                <div className='flex-shrink-1'>
                    <XCircle className='me-3' onClick={() => setMode()} />
                </div>
            </div>

            <EventTaskTypesSelection onSelected={_handleSelectTaskType} values={values} />
        </div>
    }

    switch (mode) {
        case 'Add':
            return _TaskTypeSelection()
        default:
            return <div className='p-2' style={{ cursor: 'pointer' }} onClick={() => setMode('Add')}><b><PlusCircle className='me-1' />Add a new task</b></div>
    }
}

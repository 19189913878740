import React from 'react'

export default function CalendarEventStatus({ defaultValue, onChange }) {
  return (
    <select onChange={(e) => onChange(e.target.value)} className='form-control form-control-sm'>
      <option></option>
      <option selected={defaultValue === 'TENTATIVE'}>TENTATIVE</option>
      <option selected={defaultValue === 'CONFIRMED'}>CONFIRMED</option>
      <option selected={defaultValue === 'CANCELLED'}>CANCELLED</option>
    </select>
  )
}

import React from 'react'
import CalendarMonthFilter from './CalendarMonthFilter'
import CalendarSyncFilter from './CalendarSyncFilter'
import CalendarViewFilter from './CalendarViewFilter'
import CalendarWeekFilter from './CalendarWeekFilter'
import CalendarYearFilter from './CalendarYearFilter'

export default function CalendarFilterBar() {
    return (
        <>
            <CalendarYearFilter />
            <CalendarMonthFilter />
            <CalendarWeekFilter />
            <CalendarViewFilter />
            <CalendarSyncFilter />
        </>
    )
}

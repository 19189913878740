import React, { useEffect, useState } from 'react'

export default function FacilityMessagingSummary({ title, description, navigation, errors, setValue, values }) {
    const [mode, setMode] = useState()
    const [name, setName] = useState()
    const [loaded, setLoaded] = useState(false)

    useEffect(() => {
        title('Summary')
        description('')
        navigation([
            // {
            //     text: 'Back', type: 'Back', action: async () => { }
            // },
            { text: 'Save', type: 'Done', action: async () => { } }
        ])


        // _loadSettings()
    }, [])

    const tryGetValue = (v) => {
        try {
            if(v.substring(0,"sendingProperties.auth".length) === "sendingProperties.auth"){
                const _props = JSON.parse(values.serviceProviderService.properties.find(p => p.name === 'sendingProperties').property)
                
                switch (v) {
                    case 'sendingProperties.auth.authRequired': return JSON.stringify(_props.auth.authRequired);
                    case 'sendingProperties.auth.endPoint': return JSON.stringify(_props.auth.endPoint);
                    case 'sendingProperties.auth.epVerb': return _props.auth.epVerb;
                    case 'sendingProperties.auth.usesBearer': return JSON.stringify(_props.auth.usesBearer);
                    case 'sendingProperties.auth.usesBasic': return JSON.stringify(_props.auth.usesBasic);
                    case 'sendingProperties.auth.basicAuthKey': return _props.auth.basicAuthKey;
                    case 'sendingProperties.auth.usesBasicB64': return JSON.stringify(_props.auth.usesBasicB64);
                    case 'sendingProperties.auth.usesSessionToken': return JSON.stringify(_props.auth.usesSessionToken);
                    case 'sendingProperties.auth.sessionTokenHeaders': return _props.auth.sessionTokenHeaders;
                    case 'sendingProperties.auth.hasHeaders': return JSON.stringify(_props.auth.hasHeaders);
                    case 'sendingProperties.auth.sessionHeaders': return _props.auth.sessionHeaders;
                    case 'sendingProperties.requst.requestBody': return JSON.stringify(_props.requst.requestBody);
                    default: return <></>
                }
            }
            if(v.substring(0,"sendingProperties.requst".length) === "sendingProperties.requst"){
                const _props = JSON.parse(values.serviceProviderService.properties.find(p => p.name === 'sendingProperties').property)
                
                switch (v) {
                    case 'sendingProperties.requst.requestBody': return JSON.stringify(_props.requst.requestBody,null,'\t');
                    case 'sendingProperties.requst.requestResponse': return JSON.stringify(_props.requst.requestResponse,null,'\t');
                    default: return <></>
                }
            }
            if(v.substring(0,"receiveProperties.requst".length) === "receiveProperties.requst"){
                const _props = JSON.parse(values.serviceProviderService.properties.find(p => p.name === 'receiveProperties',null,'\t').property)
                
                switch (v) {
                    case 'receiveProperties.requst.requestBody': return JSON.stringify(_props.requst.requestBody,null,'\t');
                    // case 'sendingProperties.requst.requestResponse': return JSON.stringify(_props.requst.requestResponse,null,'\t');
                    default: return <></>
                }
            }
            switch (v) {
                case 'sendingProperties.requst.authRequired': return JSON.stringify(values.serviceProviderService.sendingProperties.property.auth.authRequired);
                case 'values.sendProperties.auth.epVerb': return values.sendProperties.auth.epVerb;
                case 'values.sendProperties.auth.usesBearer': return values.sendProperties.auth.usesBearer;
                case 'values.sendProperties.auth.usesBasic': return values.sendProperties.auth.usesBasic;
                case 'values.sendProperties.auth.basicAuthKey': return values.sendProperties.auth.basicAuthKey;
                case 'values.sendProperties.auth.usesBasicB64': return values.sendProperties.auth.usesBasicB64;
                case 'values.sendProperties.auth.usesSessionToken': return values.sendProperties.auth.usesSessionToken;
                case 'values.sendProperties.auth.sessionTokenHeaders': return values.sendProperties.auth.sessionTokenHeaders;
                case 'values.sendProperties.auth.hasHeaders': return values.sendProperties.auth.hasHeaders;
                case 'values.sendProperties.auth.sessionHeaders': return values.sendProperties.auth.sessionHeaders;
                case 'values.sendProperties.requst.requestBody': return JSON.stringify(values.sendProperties.requst.requestBody);
                case 'values.sendProperties.request.requestResponse': return JSON.stringify(values.sendProperties.request.requestResponse);
                case 'values.receiveProperties.auth.authRequired': return values.receiveProperties.auth.authRequired;
                case 'values.receiveProperties.auth.endPoint': return values.receiveProperties.auth.endPoint;
                case 'values.receiveProperties.auth.epVerb': return values.receiveProperties.auth.epVerb;
                case 'values.receiveProperties.auth.usesBearer': return values.receiveProperties.auth.usesBearer;
                case 'values.receiveProperties.auth.usesBasic': return values.receiveProperties.auth.usesBasic;
                case 'values.receiveProperties.auth.basicAuthKey': return values.receiveProperties.auth.basicAuthKey;
                case 'values.receiveProperties.auth.usesBasicB64': return values.receiveProperties.auth.usesBasicB64;
                case 'values.receiveProperties.auth.usesSessionToken': return values.receiveProperties.auth.usesSessionToken;
                case 'values.receiveProperties.auth.sessionTokenHeaders': return values.receiveProperties.auth.sessionTokenHeaders;
                case 'values.receiveProperties.auth.hasHeaders': return values.receiveProperties.auth.hasHeaders;
                case 'values.receiveProperties.auth.sessionHeaders': return values.receiveProperties.auth.sessionHeaders;
                case 'values.receiveProperties.requst.requestBody': return JSON.stringify(values.receiveProperties.requst.requestBody);
                case 'values.receiveProperties.request.requestResponse': return JSON.stringify(values.receiveProperties.request.requestResponse);
            }
            return JSON.stringify(v)
        } catch (e) {
         }
        return ''
    }

    return (
        <div className='text-start'>
            {/* {JSON.stringify(values.serviceProviderService.properties)} */}
            <div><b>Service Provider :</b> {values.serviceProviderService.name}</div>
            <div><b>Service :</b> {values.serviceProviderService.serviceType.type}</div>
            <div><b>Sending Details :</b>
                <div className='ms-2'>
                    <div><b>Requires Authentication: </b>{tryGetValue('sendingProperties.auth.authRequired')}</div>
                    <div><b>Auth End Point: </b>{tryGetValue('sendingProperties.auth.endPoint')}</div>
                    <div><b>Auth End Point Verb: </b>{tryGetValue('sendingProperties.auth.epVerb')}</div>
                    <div><b>Auth Uses Bearer: </b>{tryGetValue('sendingProperties.auth.usesBearer')}</div>
                    <div><b>Auth Uses Basic Authentication: </b>{tryGetValue('sendingProperties.auth.usesBasic')}</div>
                    <div><b>Auth Uses Basic Authentication Key: </b>{tryGetValue('sendingProperties.auth.basicAuthKey')}</div>
                    <div><b>Auth Uses Basic Authentication Base 64 Encoding: </b>{tryGetValue('sendingProperties.auth.usesBasicB64')}</div>
                    <div><b>Auth Uses Session Token: </b>{tryGetValue('sendingProperties.auth.usesSessionToken')}</div>
                    <div><b>Auth Session Token Headers: </b>{tryGetValue('sendingProperties.auth.sessionTokenHeaders')}</div>
                    <div><b>Auth Requires Custom Headers: </b>{tryGetValue('sendingProperties.auth.hasHeaders')}</div>
                    <div><b>Auth Custom Headers: </b>{tryGetValue('sendingProperties.auth.sessionHeaders')}</div>
                    <div><b>Request Send: </b><pre>{tryGetValue('sendingProperties.requst.requestBody')}</pre></div>
                    <div><b>Request Receive: </b><pre>{tryGetValue('sendingProperties.requst.requestResponse')}</pre></div>
                </div>
            </div>
            <div><b>Receiving Details :</b>
                <div className='ms-2'>
                    <div><b>Request: </b><pre>{tryGetValue('receiveProperties.requst.requestBody')}</pre></div>
                    {/* <div><b>Auth End Point: </b>{tryGetValue('values.receiveProperties.auth.endPoint')}</div>
                    <div><b>Auth End Point Verb: </b>{tryGetValue('values.receiveProperties.auth.epVerb')}</div>
                    <div><b>Auth Uses Bearer: </b>{tryGetValue('values.receiveProperties.auth.usesBearer')}</div>
                    <div><b>Auth Uses Basic Authentication: </b>{tryGetValue('values.receiveProperties.auth.usesBasic')}</div>
                    <div><b>Auth Uses Basic Authentication Key: </b>{tryGetValue('values.receiveProperties.auth.basicAuthKey')}</div>
                    <div><b>Auth Uses Basic Authentication Base 64 Encoding: </b>{tryGetValue('values.receiveProperties.auth.usesBasicB64')}</div>
                    <div><b>Auth Uses Session Token: </b>{tryGetValue('values.receiveProperties.auth.usesSessionToken')}</div>
                    <div><b>Auth Session Token Headers: </b>{tryGetValue('values.receiveProperties.auth.sessionTokenHeaders')}</div>
                    <div><b>Auth Requires Custom Headers: </b>{tryGetValue('values.receiveProperties.auth.hasHeaders')}</div>
                    <div><b>Auth Custom Headers: </b>{tryGetValue('values.receiveProperties.auth.sessionHeaders')}</div>
                    <div><b>Request Send: </b>{tryGetValue('values.receiveProperties.requst.requestBody')}</div>
                    <div><b>Request Receive: </b>{tryGetValue('values.receiveProperties.request.requestResponse')}</div> */}
                </div>
            </div>
        </div>
    )
}

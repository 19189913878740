import { faSpinner } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useEffect, useRef, useState } from 'react'
import Questionnaire from '../../../../../../../libs/api/services/Questionnaire'

export default function QBuilderSectionSelection({ id, onChange, values }) {
    const [sections, setSections] = useState([])
    const [activeSection, setActiveSection] = useState()
    const [loading, setLoading] = useState(true)
    const [mode, setMode] = useState('')
    const [error, setError] = useState()
    const newSectionNameRef = useRef()
    const [dragOrder, setDragOrder] = useState(-10)
    const [dragId, setDragId] = useState(-10)

    useEffect(() => { load() }, [])

    const load = async () => {
        setLoading(true)
        var r = await Questionnaire.ListQuestionnaireSections(id)
        if (r) {
            setSections(r.filter(s => s.isSubsection === false))
            if (values.section)
                setActiveSection(values.section)
        }
        setLoading(false)
    }

    const _handleSave = () => {
        var _activeSection = activeSection
        setLoading(true)
        if (mode === 'Add')
            Questionnaire
                .CreateQuestionnaireSection(id, {
                    name: newSectionNameRef.current.value
                })
                .then((r) => {
                    setLoading(true)
                    setLoading(false)
                    load()
                    setActiveSection(r)
                    setMode('')
                    onChange(r)
                }).catch((e) => {
                    if (e.errors) {
                        setError(e.errors)
                    } else {
                        setError(e.message)
                    }
                    setLoading(false)

                })
        else if (mode === 'Rename') {
            _activeSection.name = newSectionNameRef.current.value
            Questionnaire
                .UpdateQuestionnaireSection(_activeSection.id, _activeSection)
                .then((r) => {
                    setLoading(true)
                    setLoading(false)
                    setMode('')
                    setTimeout((() => { setActiveSection(r) }, 200))
                    load()
                }).catch((e) => {
                    if (e.errors) {
                        setError(e.errors)
                    } else {
                        setError(e.message)
                    }
                    setLoading(false)

                })
        }
        else if (mode === 'Remove')
            Questionnaire
                .RemoveQuestionnaireSection(activeSection.id)
                .then((r) => {
                    setLoading(false)
                    setMode('')
                    setActiveSection()
                    load()
                }).catch((e) => {
                    if (e.errors) {
                        setError(e.errors)
                    } else {
                        setError(e.message)
                    }
                    setLoading(false)

                })
        else
            setLoading(false)
    }

    const _selectNameRef = () => { newSectionNameRef.current.select() }

    const _handleEnterSave = (e) => {
        if (e.keyCode === 13) {
            newSectionNameRef.current.blur()
            _handleSave()
        }
        else if (e.keyCode === 27) {
            setMode('')
        }
    }

    const _handleStartNewSection = () => {
        setMode('Add')
        newSectionNameRef.current.value = ''
        setTimeout(_selectNameRef, 200)
    }

    const _handleRenameSection = () => {
        setMode('Rename')
        newSectionNameRef.current.value = activeSection.name
        setTimeout(_selectNameRef, 200)
    }

    const _handleRemoveSection = () => {
        setMode('Remove')
    }

    const _handleChangeOrder = (sectionid, order) => {
        Questionnaire
            .SetSectionOrder(sectionid, order)
            .then((r) => {
                load()
            })
            setDragOrder(-1)
    }
    useEffect(() => { setError() }, [mode])
    useEffect(() => { try { if (newSectionNameRef.current) newSectionNameRef.current.value = activeSection.name } catch (e) { } }, [activeSection])

    const _handleSelectSection = (s) => {
        setActiveSection(sections.find(d => d.id === s))
        try {
            newSectionNameRef.current.value = sections.find(d => d.id === s).name
        } catch (e) { newSectionNameRef.current.value = '' }
        if (onChange) onChange(sections.find(d => d.id === s))
    }
    if (loading) return <div><FontAwesomeIcon spin icon={faSpinner} /></div>

    return (
        <>
            {
                mode === 'Remove'
                    ?
                    <div className='p-2 rounded'>
                        <div>Are you sure you would like to remove section {activeSection.name}?</div>
                        <div>
                            <button className='btn btn-xs' onClick={() => _handleSave()}>Yes</button>
                            <button className='btn btn-xs' onClick={() => setMode('')}>No</button>
                        </div>
                    </div>
                    :
                    mode !== 'Re-Order'
                        ?
                        <div className='d-flex'>
                            <div className='flex-grow-1'>
                                <input onKeyDown={_handleEnterSave} ref={newSectionNameRef} className={'form-control form-control-sm '.concat(mode === 'Add' || mode === 'Rename' ? '' : 'd-none')} placeholder={mode === 'Add' ? 'New Section Name' : 'Section Name'} />
                                <select onChange={(e) => _handleSelectSection((e.target.value))}
                                    className={'form-control form-control-sm '.concat(mode !== 'Add' && mode !== 'Rename' ? '' : 'd-none')}>
                                    <option ></option>
                                    {sections.map((s) =>
                                        <option selected={activeSection && activeSection.id === s.id ? 'selected' : ''} value={s.id} key={s.id}>{s.name}</option>
                                    )}
                                </select>
                            </div>
                            <div className='flex-shrink-1'>
                                {
                                    mode === 'Add' || mode === 'Rename'
                                        ?
                                        <>
                                            <button className='btn btn-xs' onClick={() => setMode('')}>Cancel</button>
                                            <button className='btn btn-xs' onClick={() => _handleSave()}>Save</button>
                                        </>
                                        :
                                        <>
                                            <button className='btn  btn-xs' onClick={() => _handleStartNewSection()}>Add New Section</button>
                                            <button className={'btn btn-xs '.concat(activeSection ? '' : 'disabled')} onClick={() => _handleRenameSection()}>Rename</button>
                                            <button className={'btn btn-xs '.concat(activeSection ? '' : 'disabled')} onClick={() => _handleRemoveSection()}>Remove</button>
                                            <button className={'btn btn-xs '.concat()} onClick={() => setMode('Re-Order')}>Re-Order</button>
                                        </>
                                }


                            </div>
                        </div>
                        :
                        <div>
                            {sections.map((s,i) =>
                                <div draggable
                                    onDragStart={(e) => { e.dataTransfer.effectAllowed = "copyMove"; setDragId(s.id) }}
                                    onDragEnter={(e) => { e.preventDefault(); e.dataTransfer.effectAllowed = "move"; setDragOrder(i  + 1) }}
                                    onDragOver={(e) => { e.preventDefault(); e.dataTransfer.effectAllowed = "move"; }}
                                    onDragEnd={(e) => { _handleChangeOrder(s.id,dragOrder) }}
                                    className={'p-2 text-start '.concat(i == dragOrder - 1 ? ' bg-primary-container opacity-50  ' : '') } key={'re-order-' + s.id}>{s.name}</div>
                            )}
                            <button onClick={() => setMode('')} className='btn btn-xs'>Done</button>
                        </div>
            }
            {
                error
                    ?
                    <div className='text-danger'>{error}</div>
                    :
                    <></>
            }
        </>
    )
}

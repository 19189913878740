import React, { useState } from 'react'

interface Props {
  name: string
  style?: string
  onChange: (n: string, v: string) => void
  placeholder?: string
  defaultValue?: string
}

const InputComponent = (props: Props) => {
  const [value, setValue] = useState<string>(props.defaultValue!)
  const className = ['wizard-input']

  if (props.style) className.push(...props.style!.split(' '))
  const _handleOnChange = (v: string) => {
    setValue(v)
    props.onChange(props.name, v)
  }

  return (
    <input
      className={className.join(' ')}
      value={value}
      onChange={(e) => _handleOnChange(e.target.value)}
      placeholder={props.placeholder ? props.placeholder : ''}
    />
  )
}

export default InputComponent

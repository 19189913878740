import React, { useEffect, useState } from 'react'
import { Clipboard2Pulse, Clock, ClockHistory } from 'react-bootstrap-icons'
import DatePicker from '../../../../../components/ui/calendar/date_picket/DatePicker'

export default function QuestionnaireAssignmentSchedule({ title, description, navigation, errors, setValue, values }) {
  const [schedule, setSchedule] = useState()
  useEffect(() => {
    title('Schedule')
    description('Select when the questionnaire is to be sent out')
    // Load Navigation if session started
    // if (Object.keys(values).includes('templateId'))
    navigation([
      { text: 'Back', type: 'Back', action: async () => { } },
      // { text: 'Next', type: 'Next', action: async () => { } },
    ])

    if (Object.keys(values).includes('schedule'))
      setSchedule(values.schedule)

  }, [])
 
  const _handleSetDTime = (s) => {
    setValue({ scheduleDateTime: s }) 
  }
  const _handleSetSchdule = (s) => {
    setSchedule(s)
    setValue({ schedule: s })
    navigation([
      { text: 'Back', type: 'Back', action: async () => { } },
      { text: 'Next', type: 'Next', action: async () => { } },
    ])
  }

  return (
    <div className='h-100 d-flex flex-row justify-content-center m-3'>
      <div
        style={{ cursor: 'pointer' }}
        className='d-flex flex-row justify-content-evenly align-items-evenly'>
        <div className='h-100 d-flex flex-column align-items-center m-3'>
          <div
            onClick={(e) => _handleSetSchdule('Now')}
            className={'w-100 m-3 p-2 rounded '
              .concat(schedule === 'Now' ? 'bg-primary-container shadow ' : 'bg-secondary-container')
            }
          >
            <div>
              <Clock size={52} />
            </div>
            <div>
              <h3>Send Now</h3>
            </div>
            <div>
              <p>This will assign the questionnaire as soon as assignmnet details are complete</p>
            </div>
          </div>
        </div>

      </div>

      <div
        style={{ cursor: 'pointer' }}
        className='d-flex flex-row justify-content-evenly align-items-evenly'>
        <div className='h-100 d-flex flex-column align-items-center m-3'>
          <div
            className={'w-100 m-3 p-2 rounded '
              .concat(schedule === 'Scheduled' ? 'bg-primary-container shadow ' : 'bg-secondary-container')
            }
          >
            <div onClick={(e) => _handleSetSchdule('Scheduled')}>
              <ClockHistory size={52} />
            </div>
            <div onClick={(e) => _handleSetSchdule('Scheduled')}>
              <h3>Schedule</h3>
            </div>
            <div onClick={(e) => _handleSetSchdule('Scheduled')}>
              <p>Schedule a date and time for this questionnaire to be available</p>
            </div>
            {
              schedule === "Scheduled"
                ?
                <DatePicker onChange={(r) => _handleSetDTime(r)} />
                :
                <></>

            }
          </div>
        </div>

      </div>
    </div>
  )
}

import React, { useState } from 'react'

export default function WorkFlowQuickTasksItem({ onTriggered, evt }) {
    const [show, setShow] = useState(false)
    return (
        <div className='' key={evt.id}><button className='btn' onClick={(e) => setShow(!show)} >{evt.group}</button>

            {
                show
                    ?
                    <div className='position-absolute bg-secondary-container d-flex flex-column p-2 shadow rounded' style={{ bottom: '35px' }}>
                        {
                            evt.events.map((ev) => {
                                return <button className='btn' onClick={(c) => { setShow(false); onTriggered(ev.id) }} key={ev.id}><small>{ev.name}</small></button>
                            })
                        }
                    </div>
                    :
                    <></>
            }

        </div>
    )
}

import React, { useState } from 'react'
import { DoubleClick } from '../Hooks/DoubleClick'
import { ICONS } from './GenericIconPicker'

export default function IconPickerGrid({ defaultValue, onSelect, onClose }) {
    const [active, setActive] = useState(defaultValue ? defaultValue : [])
    const isActive = (i) => {
        return active.includes(i)
    }

    const _handleSelet = (i) => {
        setActive([i])
        onSelect(i)
    }

    const _handleDBLClick = (i) => {
        setActive([i])
        onSelect(i)
        onClose()
    }

    const Generate = () => {
        const res = []
        for (const i of ICONS) {
            var _g = res.find(g => g.group === i.group)
            if (_g)
                _g.icons.push(i)
            else
                res.push({
                    group: i.group,
                    icons: [i]
                })
        }
        return res
    }

    return (
        <div className='p-2 text-dark w-100' style={{ overflow: 'auto' }}>
            {
                Generate().map((g) =>
                    <div key={`icon-group-${g.group}`} className='text-start'>
                        <b>{g.group}</b>
                        <div className='d-grid'
                            style={
                                {
                                    gridTemplateColumns: 'repeat(auto-fill,50px)',
                                    gridTemplateRows: 'auto',
                                    gap: '10px'
                                }
                            }
                        >
                            {
                                g.icons.map((i) =>
                                    <DoubleClick onClick={() => _handleSelet(i)} onDoubleClick={() => _handleDBLClick(i)}>
                                        <div
                                            key={`icon-ico-${i.name}`}
                                            onClick={() => _handleSelet(i)}
                                            className={`d-flex m-1 justify-content-center align-items-center rounded ${isActive(i) && ' bg-primary-container shadow'}`}
                                            style={{ border: 'solid #aaa 1px', width: '50px', height: '50px', }}>{i.icon}</div></DoubleClick>
                                )
                            }
                        </div>
                    </div>
                )
                // ICONS.map((i) =>
                //     <div
                //         onClick={() => onSelect(i)}
                //         className={'m-2 rounded p-2 '.concat(isActive(i) ? ' bg-primary-container shadow' : '')}
                //         style={{ border: 'solid #aaa 1px' }}>{i.icon}</div>
                // )
            }

        </div>
    )
}

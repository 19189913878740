import React, { useContext, useEffect, useState } from 'react';
import { AiOutlineCar, AiOutlineMail } from 'react-icons/ai';
import { BiBus, BiBusSchool, BiCycling, BiMailSend, BiMessageAdd, BiMessageDetail, BiMessageEdit, BiMessageError, BiMessageX, BiPhone, BiPhoneCall, BiPhoneIncoming, BiPhoneOff, BiPhoneOutgoing, BiTrain, BiVoicemail, BiWalk } from 'react-icons/bi';
import { BsCalendar2, BsCalendar2Check, BsCalendar2Date, BsCalendar2Day, BsCalendar2Event, BsCalendar2Heart, BsCalendar2Minus, BsCalendar2Month, BsCalendar2Plus, BsCalendar2Range, BsCalendar2Week, BsCalendar2X, BsClock, BsClockHistory } from 'react-icons/bs';
import { FaAmbulance, FaBaby, FaBabyCarriage, FaBlind, FaBookMedical, FaBriefcaseMedical, FaCarCrash, FaCashRegister, FaChurch, FaClinicMedical, FaCommentMedical, FaCreditCard, FaFemale, FaFileInvoice, FaFileMedical, FaHandHoldingMedical, FaHeadSideCough, FaHeadSideCoughSlash, FaHeadSideMask, FaHeadSideVirus, FaHeart, FaHeartBroken, FaHeartbeat, FaHelicopter, FaHiking, FaLaptopMedical, FaMale, FaMars, FaMarsDouble, FaMoneyBill, FaMoneyBillWave, FaNotesMedical, FaPeace, FaPeopleArrows, FaPeopleCarry, FaPersonBooth, FaPlane, FaPlaneArrival, FaPlaneDeparture, FaPlaneSlash, FaPray, FaPrayingHands, FaPrescription, FaPrescriptionBottle, FaProcedures, FaPumpMedical, FaRadiation, FaRoad, FaSignLanguage, FaSkullCrossbones, FaSmoking, FaSmokingBan, FaSms, FaSynagogue, FaSyringe, FaTablets, FaTeeth, FaTeethOpen, FaTemperatureHigh, FaTemperatureLow, FaThermometer, FaThermometerEmpty, FaThermometerFull, FaThermometerHalf, FaThermometerQuarter, FaThermometerThreeQuarters, FaToilet, FaToiletPaper, FaToiletPaperSlash, FaTooth, FaTractor, FaTrailer, FaTransgender, FaTransgenderAlt, FaTruck, FaUserGraduate, FaUserInjured, FaUserMd, FaUserNurse, FaUserSecret, FaUserTie, FaVenus, FaVenusDouble, FaVenusMars, FaVial, FaVials, FaWhatsapp, FaWheelchair, FaWineBottle, FaWineGlass, FaYinYang } from "react-icons/fa";
import { GiAlarmClock } from 'react-icons/gi';
import { RiMotorbikeFill } from "react-icons/ri";
import CommonFunctionsContext from '../../screens/common/CommonFunctionsProvider';
import IconPickerGrid from './IconPickerGrid';

const DATTIMEICON = [

    { name: 'BsCalendar2', group: 'Date / Time', description: '', icon: <BsCalendar2 /> },
    { name: 'BsCalendar2Check', group: 'Date / Time', description: '', icon: <BsCalendar2Check /> },
    { name: 'BsCalendar2Date', group: 'Date / Time', description: '', icon: <BsCalendar2Date /> },
    { name: 'BsCalendar2Day', group: 'Date / Time', description: '', icon: <BsCalendar2Day /> },
    { name: 'BsCalendar2Event', group: 'Date / Time', description: '', icon: <BsCalendar2Event /> },
    { name: 'BsCalendar2Heart', group: 'Date / Time', description: '', icon: <BsCalendar2Heart /> },
    { name: 'BsCalendar2Minus', group: 'Date / Time', description: '', icon: <BsCalendar2Minus /> },
    { name: 'BsCalendar2Plus', group: 'Date / Time', description: '', icon: <BsCalendar2Plus /> },
    { name: 'BsCalendar2Month', group: 'Date / Time', description: '', icon: <BsCalendar2Month /> },
    { name: 'BsCalendar2Range', group: 'Date / Time', description: '', icon: <BsCalendar2Range /> },
    { name: 'BsCalendar2Week', group: 'Date / Time', description: '', icon: <BsCalendar2Week /> },
    { name: 'BsCalendar2X', group: 'Date / Time', description: '', icon: <BsCalendar2X /> },
    { name: 'BsClock', group: 'Date / Time', description: '', icon: <BsClock /> },
    { name: 'BsClockHistory', group: 'Date / Time', description: '', icon: <BsClockHistory /> },
    { name: 'GiAlarmClock', group: 'Date / Time', description: '', icon: <GiAlarmClock /> },
]

const COMMSICON = [

    { name: 'AiOutlineMail', group: 'Communications', description: '', icon: <AiOutlineMail /> },
    { name: 'BiVoicemail', group: 'Communications', description: '', icon: <BiVoicemail /> },
    { name: 'FaWhatsapp', group: 'Communications', description: '', icon: <FaWhatsapp /> },
    { name: 'FaSms', group: 'Communications', description: '', icon: <FaSms /> },
    { name: 'BiPhone', group: 'Communications', description: '', icon: <BiPhone /> },
    { name: 'BiPhoneCall', group: 'Communications', description: '', icon: <BiPhoneCall /> },
    { name: 'BiPhoneIncoming', group: 'Communications', description: '', icon: <BiPhoneIncoming /> },
    { name: 'BiPhoneOff', group: 'Communications', description: '', icon: <BiPhoneOff /> },
    { name: 'BiPhoneOutgoing', group: 'Communications', description: '', icon: <BiPhoneOutgoing /> },
    { name: 'BiMailSend', group: 'Communications', description: '', icon: <BiMailSend /> },
    { name: 'BiMessageDetail', group: 'Communications', description: '', icon: <BiMessageDetail /> },
    { name: 'BiMessageAdd', group: 'Communications', description: '', icon: <BiMessageAdd /> },
    { name: 'BiMessageEdit', group: 'Communications', description: '', icon: <BiMessageEdit /> },
    { name: 'BiMessageError', group: 'Communications', description: '', icon: <BiMessageError /> },
    { name: 'BiMessageX', group: 'Communications', description: '', icon: <BiMessageX /> },
]

const TRANSPORT = [

    { name: 'AiOutlineCar', group: 'Transport', description: '', icon: <AiOutlineCar /> },
    { name: 'FaCarCrash', group: 'Transport', description: '', icon: <FaCarCrash /> },
    { name: 'BiCycling', group: 'Transport', description: '', icon: <BiCycling /> },
    { name: 'RiMotorbikeFill', group: 'Transport', description: '', icon: <RiMotorbikeFill /> },
    { name: 'FaPlane', group: 'Transport', description: '', icon: <FaPlane /> },
    { name: 'FaPlaneArrival', group: 'Transport', description: '', icon: <FaPlaneArrival /> },
    { name: 'FaPlaneDeparture', group: 'Transport', description: '', icon: <FaPlaneDeparture /> },
    { name: 'FaPlaneSlash', group: 'Transport', description: '', icon: <FaPlaneSlash /> },
    { name: 'BiTrain', group: 'Transport', description: '', icon: <BiTrain /> },
    { name: 'BiBus', group: 'Transport', description: '', icon: <BiBus /> },
    { name: 'FaHelicopter', group: 'Transport', description: '', icon: <FaHelicopter /> },
    { name: 'BiBusSchool', group: 'Transport', description: '', icon: <BiBusSchool /> },
    { name: 'FaHiking', group: 'Transport', description: '', icon: <FaHiking /> },
    { name: 'BiWalk', group: 'Transport', description: '', icon: <BiWalk /> },
    { name: 'FaRoad', group: 'Transport', description: '', icon: <FaRoad /> },
    { name: 'FaTractor', group: 'Transport', description: '', icon: <FaTractor /> },
    { name: 'FaTrailer', group: 'Transport', description: '', icon: <FaTrailer /> },
    { name: 'FaTruck', group: 'Transport', description: '', icon: <FaTruck /> },
]
const SPRITUAL = [

    { name: 'FaPray', group: 'Spirituality / Religion', description: '', icon: <FaPray /> },
    { name: 'FaPrayingHands', group: 'Spirituality / Religion', description: '', icon: <FaPrayingHands /> },
    { name: 'FaPeace', group: 'Spirituality / Religion', description: '', icon: <FaPeace /> },
    { name: 'FaSynagogue', group: 'Spirituality / Religion', description: '', icon: <FaSynagogue /> },
    { name: 'FaChurch', group: 'Spirituality / Religion', description: '', icon: <FaChurch /> },
    { name: 'FaYinYang', group: 'Spirituality / Religion', description: '', icon: <FaYinYang /> },
]
const GENDERS = [

    { name: 'FaMale', group: 'Gender', description: '', icon: <FaMale /> },
    { name: 'FaFemale', group: 'Gender', description: '', icon: <FaFemale /> },
    { name: 'FaTransgender', group: 'Gender', description: '', icon: <FaTransgender /> },
    { name: 'FaTransgenderAlt', group: 'Gender', description: '', icon: <FaTransgenderAlt /> },
    { name: 'FaVenus', group: 'Gender', description: '', icon: <FaVenus /> },
    { name: 'FaVenusDouble', group: 'Gender', description: '', icon: <FaVenusDouble /> },
    { name: 'FaVenusMars', group: 'Gender', description: '', icon: <FaVenusMars /> },
    { name: 'FaMars', group: 'Gender', description: '', icon: <FaMars /> },
    { name: 'FaMarsDouble', group: 'Gender', description: '', icon: <FaMarsDouble /> },
]


const FIN = [

    { name: 'FaMoneyBill', group: 'Finance', description: '', icon: <FaMoneyBill /> },
    { name: 'FaMoneyBillWave', group: 'Finance', description: '', icon: <FaMoneyBillWave /> },
    { name: 'FaCreditCard', group: 'Finance', description: '', icon: <FaCreditCard /> },
    { name: 'FaCashRegister', group: 'Finance', description: '', icon: <FaCashRegister /> },
    { name: 'FaFileInvoice', group: 'Finance', description: '', icon: <FaFileInvoice /> },
]

const MED = [

    { name: 'FaBookMedical', group: 'Medical', description: '', icon: <FaBookMedical /> },
    { name: 'FaFileMedical', group: 'Medical', description: '', icon: <FaFileMedical /> },
    { name: 'FaPumpMedical', group: 'Medical', description: '', icon: <FaPumpMedical /> },
    { name: 'FaNotesMedical', group: 'Medical', description: '', icon: <FaNotesMedical /> },
    { name: 'FaClinicMedical', group: 'Medical', description: '', icon: <FaClinicMedical /> },
    { name: 'FaLaptopMedical', group: 'Medical', description: '', icon: <FaLaptopMedical /> },
    { name: 'FaCommentMedical', group: 'Medical', description: '', icon: <FaCommentMedical /> },
    { name: 'FaBriefcaseMedical', group: 'Medical', description: '', icon: <FaBriefcaseMedical /> },
    { name: 'FaHandHoldingMedical', group: 'Medical', description: '', icon: <FaHandHoldingMedical /> },
    { name: 'FaAmbulance', group: 'Medical', description: '', icon: <FaAmbulance /> },
    { name: 'FaBaby', group: 'Medical', description: '', icon: <FaBaby /> },
    { name: 'FaBabyCarriage', group: 'Medical', description: '', icon: <FaBabyCarriage /> },
    { name: 'FaHeadSideCough', group: 'Medical', description: '', icon: <FaHeadSideCough /> },
    { name: 'FaHeadSideCoughSlash', group: 'Medical', description: '', icon: <FaHeadSideCoughSlash /> },
    { name: 'FaHeadSideMask', group: 'Medical', description: '', icon: <FaHeadSideMask /> },
    { name: 'FaHeadSideVirus', group: 'Medical', description: '', icon: <FaHeadSideVirus /> },
    { name: 'FaHeart', group: 'Medical', description: '', icon: <FaHeart /> },
    { name: 'FaHeartbeat', group: 'Medical', description: '', icon: <FaHeartbeat /> },
    { name: 'FaHeartBroken', group: 'Medical', description: '', icon: <FaHeartBroken /> },
    { name: 'FaPeopleCarry', group: 'Medical', description: '', icon: <FaPeopleCarry /> },
    { name: 'FaPeopleArrows', group: 'Medical', description: '', icon: <FaPeopleArrows /> },
    { name: 'FaPersonBooth', group: 'Medical', description: '', icon: <FaPersonBooth /> },
    { name: 'FaPrescription', group: 'Medical', description: '', icon: <FaPrescription /> },
    { name: 'FaPrescriptionBottle', group: 'Medical', description: '', icon: <FaPrescriptionBottle /> },
    { name: 'FaProcedures', group: 'Medical', description: '', icon: <FaProcedures /> },
    { name: 'FaRadiation', group: 'Medical', description: '', icon: <FaRadiation /> },
    { name: 'FaSkullCrossbones', group: 'Medical', description: '', icon: <FaSkullCrossbones /> },
    { name: 'FaSyringe', group: 'Medical', description: '', icon: <FaSyringe /> },
    { name: 'FaTablets', group: 'Medical', description: '', icon: <FaTablets /> },
    { name: 'FaTemperatureHigh', group: 'Medical', description: '', icon: <FaTemperatureHigh /> },
    { name: 'FaTemperatureLow', group: 'Medical', description: '', icon: <FaTemperatureLow /> },
    { name: 'FaThermometer', group: 'Medical', description: '', icon: <FaThermometer /> },
    { name: 'FaThermometerEmpty', group: 'Medical', description: '', icon: <FaThermometerEmpty /> },
    { name: 'FaThermometerQuarter', group: 'Medical', description: '', icon: <FaThermometerQuarter /> },
    { name: 'FaThermometerHalf', group: 'Medical', description: '', icon: <FaThermometerHalf /> },
    { name: 'FaThermometerThreeQuarters', group: 'Medical', description: '', icon: <FaThermometerThreeQuarters /> },
    { name: 'FaThermometerFull', group: 'Medical', description: '', icon: <FaThermometerFull /> },
    { name: 'FaTeeth', group: 'Medical', description: '', icon: <FaTeeth /> },
    { name: 'FaTeethOpen', group: 'Medical', description: '', icon: <FaTeethOpen /> },
    { name: 'FaTooth', group: 'Medical', description: '', icon: <FaTooth /> },
    { name: 'FaUserInjured', group: 'Medical', description: '', icon: <FaUserInjured /> },
    { name: 'FaUserMd', group: 'Medical', description: '', icon: <FaUserMd /> },
    { name: 'FaUserNurse', group: 'Medical', description: '', icon: <FaUserNurse /> },
    { name: 'FaVial', group: 'Medical', description: '', icon: <FaVial /> },
    { name: 'FaVials', group: 'Medical', description: '', icon: <FaVials /> },
    { name: 'FaWheelchair', group: 'Medical', description: '', icon: <FaWheelchair /> },
    { name: 'FaBlind', group: 'Medical', description: '', icon: <FaBlind /> },
]
export const ICONS =
    [
        ...DATTIMEICON,
        ...COMMSICON,
        ...FIN,
        ...TRANSPORT,
        ...MED,
        ...GENDERS,
        ...SPRITUAL,
        { name: 'FaSmoking', group: 'Other', description: '', icon: <FaSmoking /> },
        { name: 'FaSmokingBan', group: 'Other', description: '', icon: <FaSmokingBan /> },
        { name: 'FaSignLanguage', group: 'Other', description: '', icon: <FaSignLanguage /> },
        { name: 'FaToilet', group: 'Other', description: '', icon: <FaToilet /> },
        { name: 'FaToiletPaper', group: 'Other', description: '', icon: <FaToiletPaper /> },
        { name: 'FaToiletPaperSlash', group: 'Other', description: '', icon: <FaToiletPaperSlash /> },
        { name: 'FaUserGraduate', group: 'Other', description: '', icon: <FaUserGraduate /> },
        { name: 'FaUserSecret', group: 'Other', description: '', icon: <FaUserSecret /> },
        { name: 'FaUserTie', group: 'Other', description: '', icon: <FaUserTie /> },
        { name: 'FaWineBottle', group: 'Other', description: '', icon: <FaWineBottle /> },
        { name: 'FaWineGlass', group: 'Other', description: '', icon: <FaWineGlass /> },
    ]

export default function GenericIconPicker({ onChange, defaultValue, dropdown }) {
    const [active, setActive] = useState(defaultValue ? defaultValue : [])
    const [show, setShow] = useState(false)

    const { createModal } = useContext(CommonFunctionsContext)

    useEffect(() => {
        if (defaultValue) {
            var _act = []
            for (const i of defaultValue)
                if (ICONS.find(c => c.name === i))
                    _act.push(ICONS.find(c => c.name === i))
            setActive(_act)
        }
    }, [defaultValue])

    const _handleToggleSelect = (i) => {
        var _active = active

        if (dropdown) {

            // Is the same
            if (_active.icon === i.icon) {
                // remove 
                if (_active.includes(i)) _active.splice(_active.indexOf(i), 1)
            } else {
                _active[0] = i
            }
        }
        else {
            if (_active.includes(i))
                _active.splice(_active.indexOf(i), 1)
            else
                _active.push(i)
        }
        setActive([..._active])
        var _names = []
        for (const i of _active)
            _names.push(i.name)
        if (onChange) onChange(_names)
    }

    const isActive = (i) => {
        return active.includes(i)
    }
    const Generate = () => {
        const res = []
        for (const i of ICONS) {
            var _g = res.find(g => g.group === i.group)
            if (_g)
                _g.icons.push(i)
            else
                res.push({
                    group: i.group,
                    icons: [i]
                })
        }
        return res
    }

    return (
        dropdown
            ?
            <div
                className='position-relative'>
                <div
                    onClick={() =>
                        createModal(<IconPickerGrid onClose onSelect={(i) => { _handleToggleSelect(i); }} defaultValue={active} />)
                        // setShow(!show)
                    }
                    className={'m-2 rounded text-center text-dark d-flex align-items-center justify-content-center '}
                    style={{ border: 'solid #E1E1E1 2px', width: '30px', height: '30px' }}>{active.length > 0 ? active[0].icon : <></>}
                </div>
                {
                    // createModal(<IconPickerGrid onSelect={(i) => { _handleToggleSelect(i); setShow(false) }} defaultValue={defaultValue} />)
                    // <GeneralModal show={show}>
                    //     <IconPickerGrid onSelect={(i) => { _handleToggleSelect(i); setShow(false) }} defaultValue={defaultValue} />
                    // </GeneralModal>
                    // <div className='position-absolute bg-light shadow rounded text-primary' style={{ maxHeight: '300px', maxWidth: '300px', overflow: 'auto' }}>
                    //     <div
                    //         className='w-100 p-1 text-end'
                    //     ><XCircleFill onClick={() => setShow(false)} size={20} />
                    //     </div>
                    //     <div className='d-grid text-primary'
                    //         style={{ gridTemplateColumns: 'repeat(4, 1fr)', gap: '2px' }}
                    //     >
                    //         {
                    //             ICONS.map((i) =>
                    //                 <div
                    //                     onClick={() => _handleToggleSelect(i)}
                    //                     className={'m-2 rounded p-2 '.concat(isActive(i) ? ' bg-primary-container shadow' : '')}
                    //                     style={{ border: 'solid #aaa 1px' }}>{i.icon}</div>
                    //             )
                    //         }
                    //     </div >
                    // </div >
                }
            </div>

            :
            <div className='p-3 text-dark m-3' style={{ overflow: 'auto' }}>
                {
                    Generate().map((g) =>
                        <div key={`icon-group-${g.group}`} className='text-start'>
                            <b>{g.group}</b>
                            <div className='d-grid'
                                style={
                                    {
                                        gridTemplateColumns: 'repeat(auto-fill,50px)',
                                        gridTemplateRows: 'auto',
                                        gap: '10px'
                                    }
                                }
                            >
                                {
                                    g.icons.map((i) =>
                                        <div
                                            key={`icon-ico-${i.name}`}
                                            onClick={() => _handleToggleSelect(i)}
                                            className={'d-flex m-1 justify-content-center align-items-center rounded '.concat(isActive(i) ? ' bg-primary-container shadow' : '')}
                                            style={{ border: 'solid #aaa 1px', width: '50px', height: '50px', }}>{i.icon}</div>
                                    )
                                }
                            </div>
                        </div>
                    )
                    // ICONS.map((i) =>
                    //     <div
                    //         onClick={() => onSelect(i)}
                    //         className={'m-2 rounded p-2 '.concat(isActive(i) ? ' bg-primary-container shadow' : '')}
                    //         style={{ border: 'solid #aaa 1px' }}>{i.icon}</div>
                    // )
                }

            </div>
    )
}
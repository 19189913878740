import React, { useContext, useRef } from 'react'
import DayOverViewModalContext from './DayOverViewModalProvider'

export default function DayOverviewContainer() {
    const {
        eventTitle,
        optionsTitle,
        eventDetails,
        options

    } = useContext(DayOverViewModalContext)
    const optionsRef = useRef()
    return (
        <>
            {/* <div><button
                onClick={() => {
                    if (optionsRef.current.className.includes('collapse'))
                        optionsRef.current.className = optionsRef.current.className.replace('collapse', '')
                    else
                        optionsRef.current.className = optionsRef.current.className + ' collapse'
                }}

                data-toggle="collapse" className='btn d-block d-lg-none'>Options</button></div> */}
            <div className='d-flex flex-grow-1 flex-column-reverse flex-lg-row text-center p-2' style={{ maxHeight: 'calc(80vh)', overflow: 'auto' }}>
                <div className='flex-grow-1 d-flex flex-column'>
                    <div className='flex-shrink-1'>
                        <h3>{eventTitle}</h3>
                    </div>
                    <div className='d-flex flex-column flex-grow-1' style={{ overflow: 'auto' }}>
                        {eventDetails}
                    </div>
                </div>
                <div ref={optionsRef} id="Options" className='d-lg-flex flex-column flex-grow-1 collapses' style={{ overflow: 'auto', minHeight: '200px' }}>
                    <h3>{optionsTitle}</h3>
                    <div className='d-flex flex-column flex-grow-1'>
                        {options}
                    </div>

                </div>
            </div>
        </>
    )
}

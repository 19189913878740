import { Wizard } from "@rootbranch/wizard";
import FacilityUserWidget from "../../../components/facility/FacilityUserWidget";
import { useParams } from "react-router-dom"

export default function UserFacilities() {
    const {id} = useParams()
    return <FacilityUserWidget id={id} />
    // return (
    //     <Wizard template={{
    //         flow: "Wizard",
    //         grid: "top-center",
    //         sections: [
    //             {
    //                 name: "Facilities",
    //                 title: "Facility",
    //                 description: "",
    //                 actions: [
    //                     {
    //                         action: "API",
    //                         params: {
    //                             url: 'api/Users/facility/{Add User.id}/{Facilities.Facility}/{Facilities.FacilityRole}',
    //                             method: 'PUT',
    //                             body: null,
    //                             encryption: {
    //                                 method: 'aes256',
    //                                 key: 'key'
    //                             }
    //                         },
    //                     },
    //                     {
    //                         action: "redirect",
    //                         params:
    //                         {
    //                             url: '/admin/user/manage/{Add User.id}'
    //                         }
    //                     }
    //                 ],
    //                 layout:
    //                     [
    //                         {
    //                             placeholder: "Link to an additional facility",
    //                             type: "Search",
    //                             grid: "col-12",
    //                             class: "form-control",
    //                             name: "Facility",
    //                             results: {
    //                                 method: "API",
    //                                 reference: 'facility',
    //                                 params: {
    //                                     url: 'api/facilities/search',
    //                                     method: 'POST',
    //                                     body: {
    //                                         search: '{Facility}'
    //                                     },
    //                                     encryption: {
    //                                         method: 'aes256',
    //                                         key: 'key'
    //                                     }
    //                                 }
    //                             },
    //                             addAction: [
    //                                 {
    //                                     action: "API",
    //                                     params: {
    //                                         url: 'api/facilities',
    //                                         method: 'POST',
    //                                         body: {
    //                                             facility: '{Facilities.Facility}'
    //                                         },
    //                                         encryption: {
    //                                             method: 'aes256',
    //                                             key: 'key'
    //                                         }
    //                                     }
    //                                 }
    //                             ],
    //                         },
    //                         {
    //                             name: 'FacilityRole',
    //                             type: 'OptionsGrid',
    //                             grid: 'debug',
    //                             dependencies: {
    //                                 'Facilities.Facility': ''
    //                             },
    //                             options: {
    //                                 method: "API",
    //                                 reference: 'role',
    //                                 params: {
    //                                     url: 'api/Facilities/Roles',
    //                                     method: 'GET',
    //                                     body: null,
    //                                     encryption: {
    //                                         method: 'aes256',
    //                                         key: 'key'
    //                                     }
    //                                 }
    //                             },
    //                             actions: [
    //                                 {
    //                                     action: "API",
    //                                     params: {
    //                                         url: 'api/Users/facility/{URL.id}/{Facilities.Facility}/{Facilities.FacilityRole}',
    //                                         method: 'PUT',
    //                                         body: null,
    //                                         encryption: {
    //                                             method: 'aes256',
    //                                             key: 'key'
    //                                         }
    //                                     },
    //                                 },
    //                             ]
    //                         },
    //                         {
    //                             name: 'CurrentFacilities',
    //                             type: 'SourceGroup',
    //                             source: [{
    //                                 action: "API",
    //                                 reference: '$.userFacilitieRoles',
    //                                 params: {
    //                                     url: 'api/Users/{URL.id}',
    //                                     method: 'GET',
    //                                     body: null,
    //                                     encryption: {
    //                                         method: 'aes256',
    //                                         key: 'key'
    //                                     }
    //                                 }
    //                             }],
    //                             placeholder: 'Current Facilities and Roles',
    //                             grid: 'debug',
    //                             layout: [
    //                                 {
    //                                     name: 'FacilityRoles',
    //                                     type: 'OptionsGrid',
    //                                     placeholder: '{$.facility.facility}',
    //                                     grid: 'debug',
    //                                     defaultValue:'{$.roles.id}',
    //                                     options: {
    //                                         method: "API",
    //                                         reference: 'role',
    //                                         params: {
    //                                             url: 'api/Facilities/Roles',
    //                                             method: 'GET',
    //                                             body: null,
    //                                             encryption: {
    //                                                 method: 'aes256',
    //                                                 key: 'key'
    //                                             }
    //                                         }
    //                                     },
    //                                     actions: [
    //                                         {
    //                                             action: "API",
    //                                             params: {
    //                                                 url: 'api/Users/facility/{URL.id}/{$.facility.id}/<<VALUE>>',
    //                                                 method: 'PUT',
    //                                                 body: null,
    //                                                 encryption: {
    //                                                     method: 'aes256',
    //                                                     key: 'key'
    //                                                 }
    //                                             },
    //                                         },
    //                                     ]
    //                                 },
    //                             ]
    //                         }
    //                     ]
    //             }
    //         ]
    //     }} />
    // )
}
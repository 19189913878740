import React from 'react'
import { ToDoListProvider } from './ToDoListProvider'
import ToDoListContainer from './components/ToDoListContainer'

export default function ToDoListScreen() {
    return (
        <ToDoListProvider>
            <ToDoListContainer />
        </ToDoListProvider>
    )
}

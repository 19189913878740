import React, { useReducer } from 'react'
import PatientHistory from '../../../../libs/api/services/PatientHistory'
import PatientHistoryEvent from '../../../../libs/api/services/PatientHistoryEvent'
import { useState } from 'react'

const PatientHistoryContext = React.createContext()

const _handleError = (e) => {
    if (e.message)
        return e.message
    else if (e.errors)
        return JSON.stringify(e.errors)
}

export const PatientHistoryProvider = ({ children }) => {
    const [patientState, dispatchPatient] = useReducer(reducer, { loading: false, state: 'READY', error: '', event: {} })
    const [history, setHistory] = useState([])
    const [notes, setNotes] = useState([])
    const [patientDMSFiles, setPatientDMSFiles] = useState([])
    function reducer(state, action) {
        switch (action.type) {
            // List
            case 'LIST_PATIENT_HISTORY': return { ...state, state: 'LIST_PATIENT_HISTORY_FROM_SERVER' }
            case 'LIST_PATIENT_HISTORY_FROM_SERVER': return { ...state, state: 'READY' }
            case 'LIST_PATIENT_HISTORY_ERROR': return { ...state, state: 'ERROR', error: action.error }
            // Add
            case 'ADD_PATIENT_HISTORY': return { ...state, state: 'SAVING_TO_SERVER' }
            case 'ADD_PATIENT_HISTORY_FROM_SERVER': return { ...state, state: 'READY' }
            case 'ADD_PATIENT_HISTORY_ERROR': return { ...state, state: 'ERROR', error: action.error }

            // Events
            case 'LIST_PATIENT_EVENTS': return { ...state, state: 'LIST_PATIENT_EVENTS' }
            case 'LIST_PATIENT_EVENTS_ERROR': return { ...state, state: 'ERROR', error: action.error }

            // Notes
            case 'ADD_NOTE': return { ...state, state: 'ADD_NOTE' }
            case 'ADD_NOTE_ERROR': return { ...state, state: 'ERROR', error: action.error }

            case 'LIST_NOTES': return { ...state, state: 'LIST_NOTES' }
            case 'LIST_NOTES_ERROR': return { ...state, state: 'ERROR', error: action.error }

            case 'REMOVE_NOTE': return { ...state, state: 'REMOVE_NOTE' }
            case 'REMOVE_NOTE_ERROR': return { ...state, state: 'ERROR', error: action.error }

            case 'REMOVE_DOCUMENT': return { ...state, state: 'REMOVE_DOCUMENT' }
            case 'REMOVE_DOCUMENT_ERROR': return { ...state, state: 'ERROR', error: action.error }


            case 'GET_EVENT_DETAILS': return { ...state, state: 'GET_EVENT_DETAILS', }
            case 'GET_EVENT_DETAILS_SERVER': return { ...state, state: 'READY', event: action.event }
            case 'GET_EVENT_DETAILS_ERROR': return { ...state, state: 'ERROR', error: action.error }
            // Errors
            case 'CLEAR_ERRORS': return { ...state, state: 'READY', error: '' }
            default:
                throw Error('Functionality not available')
        }
    }

    const ListPatientHistory = async (patientId) => {
        dispatchPatient({ type: 'LIST_PATIENT_HISTORY' })
        var history = await PatientHistory
            .GetPatientHistory(patientId)
            .catch((e) => {
                dispatchPatient({ type: 'LIST_PATIENT_HISTORY_ERROR', error: _handleError(e) })
            })
        dispatchPatient({ type: 'LIST_PATIENT_HISTORY_FROM_SERVER' })
        setHistory(history)
        return history
    }
    const AddPatientHistory = (patientId, event, date) => {
        dispatchPatient({ type: 'ADD_PATIENT_HISTORY' })
        PatientHistory
            .AddPatientHistory(patientId, {
                Event: {
                    id: event
                },
                EventDateTime: date
            })
            .then((r) => {
                dispatchPatient({ type: 'ADD_PATIENT_HISTORY_FROM_SERVER' })
                ListPatientHistory(patientId)
            })
            .catch((e) => {
                dispatchPatient({ type: 'ADD_PATIENT_HISTORY_ERROR', error: _handleError(e) })
            })
    }
    const LIST_PATIENT_EVENTS = async () => {
        dispatchPatient({ type: 'LIST_PATIENT_EVENTS' })
        return await PatientHistoryEvent
            .ListCategories()
            .catch((e) => {
                dispatchPatient({ type: 'LIST_PATIENT_EVENTS_ERROR', error: _handleError(e) })
                return []
            });

    }

    // Notes
    const ADD_NOTE = async (historyId, note, prvate, refKey) => {
        dispatchPatient({ type: 'ADD_NOTE' })
        var _refKey = []
        /// try sort multi keys if available
        try {
            for (const rf of refKey) {
                if (Object.keys(rf).includes('key')) {
                    _refKey.push(rf)
                }
                else if (Object.keys(rf).includes('keys')) {
                    for (const rfk of rf.keys) {
                        _refKey.push({ source: rf.source, key: rfk })
                    }
                }
            }
        } catch (e) {
            _refKey = refKey
        }

        await PatientHistory
            .AddNote(historyId, note, prvate, _refKey)
            .catch((e) => {
                dispatchPatient({ type: 'LIST_PATIENT_EVENTS_ERROR', error: _handleError(e) })
            })
        LIST_NOTES(historyId);
    }

    const LIST_NOTES = async (historyId) => {
        dispatchPatient({ type: 'LIST_NOTES' })
        var _notes = await PatientHistory
            .ListNotes(historyId)
            .catch((e) => {
                dispatchPatient({ type: 'LIST_NOTES_ERROR', error: _handleError(e) })
                return []
            })
        setNotes(_notes)

    }
    const REMOVE_NOTE = async (historyId, noteId) => {
        dispatchPatient({ type: 'REMOVE_NOTE' })
        await PatientHistory
            .RemoveNote(noteId)
            .catch((e) => {
                dispatchPatient({ type: 'REMOVE_NOTE_ERROR', error: _handleError(e) })
            })
        LIST_NOTES(historyId);
    }

    const ADD_PATIENT_DOCUMENT = async (historyId, file) => {
        try {
            await PatientHistory.AddDocument(historyId, file)
                .catch((e) => {
                    dispatchPatient({ type: 'REMOVE_NOTE_ERROR', error: _handleError(e) })
                    throw e
                }).then(async (r) => {
                    file.id = r.id
                    file.status = 'uploaded'
                    dispatchPatient({ type: 'LIST_NOTES' })
                    return
                })
        }
        catch (e) {
            return file.status = 'Failed' + _handleError(e)
        }
    }

    const LIST_PATIENNT_HISTORY_DOCUMENTS = async (historyId) => {
        var docs = await PatientHistory.ListDocuments(historyId)
            .catch((e) => {
                dispatchPatient({ type: 'LIST_PATIENNT_HISTORY_DOCUMENTS_ERROR', error: _handleError(e) })
                throw e
            })
        setPatientDMSFiles(docs)
    }

    const REMOVE_DOCUMENT = async (historyId, documentId) => {
        PatientHistory.RemoveDocument(documentId)
            .catch((e) => {
                dispatchPatient({ type: 'REMOVE_DOCUMENT_ERROR', error: _handleError(e) })
                throw e
            })
            .then((r) => {
                LIST_PATIENNT_HISTORY_DOCUMENTS(historyId)
            })
    }

    const GET_DOCUMENT = async (documentId) => {
        return await PatientHistory.GetDocument(documentId)
            .catch((e) => {
                dispatchPatient({ type: 'REMOVE_DOCUMENT_ERROR', error: _handleError(e) })
                throw e
            })
    }

    const GET_EVENT_DETAILS = async (eventId) => {
        dispatchPatient({ type: 'GET_EVENT_DETAILS' })
        PatientHistory.GetEventDetails(eventId)
            .then((r) => {
                dispatchPatient({ type: 'GET_EVENT_DETAILS_SERVER', event: r })
            })
            .catch((e) => {
                dispatchPatient({ type: 'GET_EVENT_DETAILS_ERROR', error: _handleError(e) })
                throw e
            })
    }
    const CLEAR_ERRORS = async () => {
        dispatchPatient({ type: 'CLEAR_ERRORS' });
    }
    return (
        <PatientHistoryContext.Provider
            value={{
                CLEAR_ERRORS,
                ListPatientHistory,
                AddPatientHistory,
                LIST_PATIENT_EVENTS,
                ADD_NOTE,
                LIST_NOTES,
                REMOVE_NOTE,
                ADD_PATIENT_DOCUMENT,
                LIST_PATIENNT_HISTORY_DOCUMENTS,
                REMOVE_DOCUMENT,
                GET_DOCUMENT,
                GET_EVENT_DETAILS,
                patientDMSFiles,
                patientHistoryState: patientState,
                history,
                notes
            }}
        >{children}</PatientHistoryContext.Provider>
    )

}

export default PatientHistoryContext
import React, { useEffect, useState } from 'react'
import { PersonFillExclamation, PersonPlusFill } from 'react-bootstrap-icons'
import Authentication from '../../../libs/api/services/Authentication'
import QuestionnaireAssignment from '../../../libs/api/services/QuestionnaireAssignment'
import QCaptureComponent from '../questionnaires/capture/components/QCaptureComponent'

export default function UserRequests() {
    const [questionnaires, setQuestionnnaires] = useState([])
    const _load = () => {
        QuestionnaireAssignment
            .GetQuestionnaireByType("Login - Registration Form", Authentication.getFacility())
            .then((r) => {
                setQuestionnnaires(r)
            })
    }
    useEffect(() => {
        _load()
    }, [])

    const _handleAccept = (id) => {
        QuestionnaireAssignment
            .AcceptColdRequest(id)
            .then((r) => {
                _load()
            })
    }
    const _handleDecline = (id) => {
        QuestionnaireAssignment
            .DeclineColdRequest(id)
            .then((r) => {
                _load()
            })
    }

    return (
        <div style={{ overflow: 'auto' }} className='p-2'>
            <div className='text-center'>
                <h2>User Requests</h2>
            </div>
            {
                questionnaires.map((q) => {
                    return <div className='bg-primary-container m-2 p-2 rounded shadow'>
                        <div className='d-flex justify-content-end'>
                            {/* <div className='m-1 p-2 d-flex align-items-center justify-content-center'>
                                <PersonPlusFill size={20} />
                            </div> */}
                        </div>
                        <div className='text-center'>
                            <QCaptureComponent
                                mode={'Discover'}
                                // cacheddetails={details}
                                QuestionnaireAssignmentId={q.id}
                                QuestionnaireId={q.questionnaire.id}
                            />
                            {/* {
                                q.responses && q.responses.length > 0
                                    ?
                                    q.responses.map((res) => {
                                        return <div className='d-flex'><div className='me-2'><b>{res.answerType.properties.find(a => a.property === 'Question').values}</b></div>{res.answer}</div>
                                    })
                                    :
                                    <></>
                            } */}
                            <button onClick={(e) => _handleAccept(q.id)} className='btn btn-xs '><PersonPlusFill />Accept</button>
                            <button onClick={(e) => _handleDecline(q.id)} className='btn btn-xs '><PersonFillExclamation />Decline</button>
                        </div>
                    </div>
                })
            }
        </div>
    )
}

const DashboardLayouts = []
DashboardLayouts['CLINICIAN_DASHBOARD'] = {
    title: '',
    widgets: [{
        type: 'Alerts'
    }, {
        type: 'Calendar'
    }, {
        type: 'Messages'
    }, {
        type: 'ToDo'
    }]
}
DashboardLayouts['PATIENT_DASHBOARD'] = {
    title: '',
    widgets: [{
        type: 'Questionnaires'
    }, {
        type: 'Calendar'
    }, {
        type: 'Messages'
    }, {
        type: 'ToDo'
    }]
}
DashboardLayouts['ADMIN_DASHBOARD'] = {
    title: '',
    widgets: [ {
        type: 'Calendar'
    }, {
        type: 'Messages'
    }, {
        type: 'ToDo'
    }]
}
DashboardLayouts['SYS_ADMIN_DASHBAORD'] = {
    title: '',
    widgets: [ {
        type: 'Calendar'
    }, {
        type: 'Messages'
    }, {
        type: 'ToDo'
    }]
}
export default DashboardLayouts
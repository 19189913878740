import React, { useEffect, useRef, useState } from 'react';
import Patient from '../../../../../libs/api/services/Patient';
import './SingleSelection.css';

export default function SingleSelection({ defaultValue, onChanged, placeholder, fieldId, optionsId }) {
    const inputRef = useRef()
    const [options, setOptions] = useState([])
    const [search, setSearch] = useState([])
    const [filter, setSFilter] = useState('')
    const [option, setOption] = useState(defaultValue)
    const [show, setShow] = useState(false)
    
    useEffect(() => {
        Patient
            .FieldTypesOptionsDefaults(fieldId, optionsId)
            .then((r) => {
                setOptions(r)
                if (r && r.length > 0)
                    setSearch(r[0].value)

            })
    }, [option])

    const _handleOptionSelected = (option) => {
        setOption(option)
        onChanged(option)
        setShow(false)
        setSFilter('')
    }
    const _handleSearch = (_filter) => {
        setSFilter(_filter)
        const _result = options[0].value.filter(f => f.property.includes(_filter))
        setSearch(_result)
    }

    const _handleOpen = () => {
        if (!show) {
            setShow(true)
            new Promise(resolve => {
                setTimeout(() => {
                    resolve( inputRef.current.select());
                }, 100);
            })
            // inputRef.current.select();
        } else {
            inputRef.current.value = '';
            _handleSearch('')
            setShow(false)
        }
    }

    return (
        <div className='position-relative single-selection'>
            <div onClick={(e) => _handleOpen()} className='form-control selected-option ' >{option}</div>
            <div className={'bg-secondary p-3 rounded position-absolute w-100 options-container '.concat(show ? 'd-block' : 'd-none')}>
                <input
                    ref={inputRef}
                    value={filter} onChange={(e) => _handleSearch(e.target.value)} className='form-control single-option-search ' placeholder={"Search"} />
                {
                    search.map((property) =>
                        <div
                            key={property.id} value={property.property}
                            onClick={() => _handleOptionSelected(property.property)}
                            className={option === property.property ? 'options selected' : 'options'}
                        >{property.property}</div>
                    )
                }
            </div>
        </div>
    )
}

/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from 'react'
import { CaretDownFill, CaretUpFill } from 'react-bootstrap-icons'
import WorkFlowConfigurationContext from '../../../WorkFlowConfigurationProvider'
import EventTriggerTypes from '../EventType/EventTriggerTypes'
import EventTrigger from '../components/EventTrigger'


/**
 * NOTE : Please configure new trigger items using Wizard > Config > TriggerTypeMapping
 */
export default function AddEventWizardEventTriggeredBy({ title, description, navigation, values }) {

    const { loadTriggers, triggers, toggleTriggerActive } = useContext(WorkFlowConfigurationContext)

    useEffect(() => {
        title('Event Trigger')
        description('Select when this event should be triggered?')
        navigation([
            {
                text: 'Back', type: 'Back',
            },
            {
                text: 'Next', type: 'Next'
            },
        ])
        loadTriggers()
    }, [])

    return (
        <div className='text-start m-2'>
            {
                triggers.length > 0
                    ?
                    triggers.map((g, i) => {
                        return (
                            <DropDownContainer
                                key={`AEWTB-${i}`}
                                title={<div><b>{g.category}</b> ({g.triggers.filter(x => x.link && x.link.length > 0).length} / {g.triggers.length})</div>}
                                triggers={g}
                                toggleTriggerActive={toggleTriggerActive}
                                values={values}
                            />
                        )
                    })
                    :
                    <></>
            }
        </div>
    )
}


const DropDownContainer = ({ title, triggers, toggleTriggerActive, values }) => {
    const [isOpen, setIsOpen] = useState(false)

    return (<div>
        <div style={{ cursor: 'pointer' }} className='d-flex' onClick={() => setIsOpen(!isOpen)}>{isOpen ? <CaretDownFill /> : <CaretUpFill />} {title}</div>
        {isOpen &&
            triggers.triggers && triggers.triggers.length > 0
            ?
            triggers.triggers.map((t) =>
                <EventTrigger
                    key={`AEWTBC-${t.id}`}
                    title={t.type}
                    description={t.description}
                    link={t.link}
                    onChange={() => toggleTriggerActive(t.id)}
                >
                    <EventTriggerTypes type={t.key} values={values} />
                </EventTrigger>
            )
            : <></>
        }
    </div>)
}
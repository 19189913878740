import { useEffect, useState } from "react";
import Facility from "../../libs/api/services/Facility";
import User from "../../libs/api/services/User";
import CardOptions from "../ui/card_options/CardOptions";

export default function FacilityUserRole({ userId, facilityId, defaultValue, activeroles, onSelected, onSelectItem }) {
    // const { id } = useParams();
    const [roles, setRoles] = useState([]);

    useEffect(() => {
        // alert(JSON.stringify(activeroles))
        Facility.roles()
            .then((res) => {
                setRoles(res);
            })
    }, []);

    const onSelect = (option, seld) => {
        var isAdd = true
        for(const role of activeroles){
            if(role.id === option) isAdd = false
        }
        // var isAdd = activeroles === option
        if (onSelectItem)
            //setTimeout(onSelectItem, 300, userId, facilityId, option)
            onSelectItem(userId, facilityId, option, seld, isAdd)
        else
            User.addFacility(userId, facilityId, option)
                .then((r) => {
                    if (onSelected)
                        onSelected(r)
                })
    }

    return (
        <CardOptions
            useCallBack={true}
            key={new Date()} defaultValues={activeroles} onChange={(e,v) => onSelect(e, v)}>
            {
                roles.map((role) =>
                    <option key={role.id} value={role.id}>{role.preferedName}</option>
                )
            }
        </CardOptions>
    )
}
import API from "../API";

var Email = {
    async ConfigurationSet() {
        var result = await API.process(
            `api/MessagingService/connection/set`,
            'OPTIONS',
            null
        ).catch((err) => {
            throw err;
        })

        return result
    },
}

export default Email;
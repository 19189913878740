import { TriggerTypeMapping } from "../../../../Config/TriggerTypeMapping";


export default function EventTriggerTypes({ link, type, values }) {

    // This is configured under Wizard > Config > TriggerTypeMapping
    if (type && TriggerTypeMapping[type])
        return ({
            //obj
            ...TriggerTypeMapping[type].component,
            props: {
                ...TriggerTypeMapping[type].component.props,
                link: link,
                values: values,
            }
        })
}

import { faClipboardList } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useEffect, useState } from 'react'
import { Calendar2, MenuUp } from 'react-bootstrap-icons'
import { GrDocumentUser, GrNotes } from 'react-icons/gr'
import SlideNav from '../../../../components/animate/SlideNav'
import WorkFlow from '../../../../libs/api/services/WorkFlow'
import WorkFlowCalendarSection from './Sections/WorkFlowCalendarSection'
import WorkFlowDMSSection from './Sections/WorkFlowDMSSection'
import WorkFlowMessagesSection from './Sections/WorkFlowMessagesSection'
import WorkFlowNotesSection from './Sections/WorkFlowNotesSection'
import WorkFlowQuestionnaireSection from './Sections/WorkFlowQuestionnaireSection'
import { WorkFlowManagementProvider } from './WorkFlowManagementProvider'
export default function WorkFlowDetailsModal({ CalendarEntryId, toDoListId, children }) {

    const [event, setEvent] = useState()
    const [active, setActive] = useState()
    const [show, setShow] = useState(false)

    const _loadWFHistoryFrom = (entryId, type) => {
        switch (type) {
            case 'CalendarEvent': return WorkFlow
                .GetWorkFlowCalendarEventHistory(entryId)
                .then((r) => setEvent(r)).catch((e) => { })
            case 'ToDoList': return WorkFlow
                .GetWorkFlowToDoListHistory(entryId)
                .then((r) => setEvent(r)).catch((e) => { })
            default: return;
        }


    }
    useEffect(() => {
        if (CalendarEntryId) _loadWFHistoryFrom(CalendarEntryId, 'CalendarEvent')
        if (toDoListId) _loadWFHistoryFrom(toDoListId, 'ToDoList')
    },
        [CalendarEntryId, toDoListId])

    const MENU = [
        {
            "label": 'Notes',
            icon: <GrNotes className='me-2' />,
            extra: event && event.notes ? event.notes.length : undefined,
            component: <WorkFlowNotesSection event={event} notes={event && event.notes} />
        }, {
            "label": 'Forms / Questionnaires',
            icon: <FontAwesomeIcon className='me-2' icon={faClipboardList} />,
            component: <WorkFlowQuestionnaireSection event={event} questionnaireAssignments={event && event.questionnaireAssignments} />
        }, {
            "label": 'Documents',
            icon: <GrDocumentUser className='me-2' />,
            component: <WorkFlowDMSSection event={event} documents={event && event.documents} />
        }, {
            "label": 'Calendar Events',
            icon: <Calendar2 className='me-2' />,
            component: <WorkFlowCalendarSection event={event} calendars={event && event.calendarEntries} />
        }, {
            "label": 'Messages',
            icon: <MenuUp className='me-2' />,
            component: <WorkFlowMessagesSection event={event} messages={event && event.messages} />
        },
    ]


    const _load = () => { }
    useEffect(() => { _load() }, [])
    useEffect(() => { setShow(false) }, [active])
    // if (!event) return <LoaderComponent />
    if (!event) return children
    return (
        <WorkFlowManagementProvider>
            <div className='text-center flex-grow-1 d-flex flex-column position-relative'
                event={event}
            // style={{ overflow: 'auto', height: 'calc(80vh - 150px)', maxHeight: 'calc(80vh - 150px)' }}
            >
                {/* {active && active.component} */}
                <>
                    {/* {
                        show
                            ?
                            <div
                                className={'mt-4 bg-primary-container rounded shadow text-start w-100 d-flex flex-column position-absolute justify-content-between '}
                                style={{ cursor: 'pointer',zIndex: 999 }}>
                                {
                                    MENU.map((m, i) => <div
                                        className={'w-100 p-2 rounded '.concat(active && active.label === m.label ? ' bg-secondary-container shadow ' : '  ')}
                                        onClick={() =>
                                            // setActive(m)}
                                            setActive(i + 1)}
                                        key={'MNU-' + m.label}>{m.icon ? m.icon : ''}{m.label}</div>)
                                }
                                <div
                                    className={'w-100 p-2 rounded '}
                                    onClick={() => { setActive(0); setShow(false); }}><XCircle className='me-2' />Close</div>
                            </div>
                            :
                            <></>
                        //         active
                        //             ?
                        //             active.component
                        //             :
                        //             children
                    } */}
                    <SlideNav select={active ? active : 0} menu={MENU}>
                        {[children, ...MENU.map((m) => m.component)].map((c) => c)}
                    </SlideNav>
                </>
            </div>

        </WorkFlowManagementProvider>
    )
}

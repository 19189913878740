import React, { useEffect, useState } from 'react'
import { InfoCircle } from 'react-bootstrap-icons'
import SearchComponent from '../../../../../../../../../components/search/SearchComponent'
import MessagingServiceProvider from '../../../../../../../../../libs/api/services/MessagingServiceProvider'
export default function ServiceProviderSelectionComponent({ description, defaultValue, onSelected, onAdd, task, facility, user, errors, serviceType, values, setValue }) {
    // const [template, setTemplate] = useState()
    const [loading, setLoadng] = useState(true)
    const [messagingAccounts, setMessagingAccounts] = useState()

    useEffect(() => { _loadAuthorizedMessagingAccounts() }, [serviceType])

    const _loadAuthorizedMessagingAccounts = async () => {
        setLoadng(true)
        try {
            var r = await MessagingServiceProvider
                .ListAvailableServiceProviders(user, facility, serviceType)
                .catch((e) => errors(e))
            setMessagingAccounts(r)
            setLoadng(false)
        } catch (e) { console.error(">>>>>>>>>>", e) }
    }

    return (
        <div>

            {
                loading ? <></> :
                    serviceType && serviceType === "Internal Messaging Service"
                        ?
                        <></>
                        :
                        messagingAccounts && messagingAccounts.length === 0 ?
                            <div className='text-center p-2'>
                                <div>
                                    <InfoCircle /> There are no message service providers available to process this message
                                </div>
                                <div>
                                    <button className='btn btn-xs'>Setup a new service provider</button>
                                </div>
                            </div>
                            :
                            messagingAccounts
                                ?
                                <>
                                    {description ? <div>{description}</div> : ''}

                                    <div className='flex-grow-1'>
                                        <SearchComponent
                                            placeholder={'Service provider to process the message'}
                                            defaultValue={defaultValue ? messagingAccounts.find(a => a.id === defaultValue.id) : undefined}
                                            focusSearch={true}
                                            onSelected={onSelected}
                                            details={{
                                                data: messagingAccounts,
                                                options: {
                                                    returns: 'object',
                                                    reRefKey: 'id',
                                                    onAdd: {
                                                        action: (s) => onAdd(s),
                                                        text: 'Setup a new service provider'
                                                    },
                                                    template: [{
                                                        refKey: 'name',
                                                        descr: '',
                                                    }]
                                                }
                                            }} />
                                    </div>
                                </>
                                : <></>
            }
        </div>
    )
}

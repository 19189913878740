import React, { useContext } from 'react'
import PatientProfileCategoryField from './PatientProfileCategoryField'
import SlideContainerWidget from '../../../../../components/ui/slide_container/SlideContainerWidget'
import PatientProfileContext from '../PatientProfileProvider'

export default function PatientProfileCategoryFieldsContainer({ isNew, categoryID }) {
    const { state } = useContext(PatientProfileContext)


    return (
        <div className='rounded'>
            <PatientProfileCategoryField key={'New Field'} field={{ type: '' }} categoryID={state.category.id} isNew={true} />
            {
                state.state === 'READY'
                    ?
                    <>
                        {
                            Object.keys(state.categories).map((idx) => {
                                if (state.categories[idx].id === categoryID)
                                    if (!state.categories[idx].details || !Array.isArray(state.categories[idx].details))
                                        console.error(state.categories[idx])
                                    else
                                        return state.categories[idx].details
                                            ?
                                            state.categories[idx].details.map((f) => {
                                                return (
                                                    <SlideContainerWidget
                                                        title={f.value}
                                                        icon={'pencil'}>
                                                        <PatientProfileCategoryField field={f} key={f.id} categoryID={categoryID} />
                                                    </SlideContainerWidget>
                                                )
                                            })
                                            :
                                            <></>
                            })
                        }
                    </>
                    :
                    <></>

            }
        </div>
    )
}

import React, { useEffect, useState } from 'react';
import ScrollerComponent from './ScrollerComponent';
String.prototype.lpad = function (padString, length) {
    var str = this;
    while (str.length < length)
        str = padString + str;
    return str;
}
export default function TimeOptions({ value, onChange }) {
    const [time, setTime] = useState(value ? value : { hours: 0, minutes: 0, seconds: 0 })
    useEffect(() => { if (onChange) onChange(time) }, [time])
    useEffect(() => { setTime(value) }, [value])

    return (
        <div className='d-flex text-center'>
            <div className='w-50'>
                <div className='p-2'>
                    <b>Hour</b>
                </div>
                <div>
                    {/* <div
                        style={{ height: '50px', overflow: 'auto' }}> */}
                    <ScrollerComponent
                        defaultValue={String(time.hours).lpad('0', 2)}
                        onChange={(v) => setTime({ ...time, hours: v })}
                        options={Array.from({ length: 24 }, (_, i) => String(i).lpad('0', 2))} />
                    {/* </div> */}
                </div>
            </div>
            <div className='w-50'>
                <div className='p-2'>
                    <b>Minute</b>
                </div>
                <ScrollerComponent
                    defaultValue={String(time.minutes).lpad('0', 2)}
                    onChange={(v) => setTime({ ...time, minutes: v })}
                    options={Array.from({ length: 60 }, (_, i) => String(i).lpad('0', 2))} />
            </div>
        </div>
    )
}

import React, { useEffect, useRef, useState } from 'react'
import { Check2Square, LockFill, Square } from 'react-bootstrap-icons'
import Questionnaire from '../../../../../libs/api/services/Questionnaire'

export default function QBuilderSetupType({ title, description, navigation, navigator, errors, setValue, values }) {

    const [configurationName, setConfigurationName] = useState('')
    const [configurations, setConfigurations] = useState([])
    const [mode, setMode] = useState('')
    const newSectionNameRef = useRef()
    const [showSystem, setShowSystem] = useState(false)
    const [configuration, setConfiguration] = useState(Object.keys(values).includes('questionnaireId') ? { id: values.questionnaireId } : undefined)
    useEffect(() => {
        title('Questionnaire Builder')
        description('Would you like to create a new questionnaire or update an existing one')
        // Load Navigation if session started
        if (Object.keys(values).includes('questionnaireId')) {
            navigation([
                { text: 'Remove', type: 'Action', action: async () => { _handleRemoveTemplate(values.questionnaireId).then((r) => { navigation([]); loadConfigs() }) } },
                { text: 'Update', type: 'Next', action: async () => { setValue({ 'questionnaireId': values.questionnaireId }) } },
            ])
            setValue({ section: undefined })
        }
        else {
            navigation([])
            setValue({ section: undefined })
        }
    }, [])

    const loadConfigs = (_showSystem) => {
        Questionnaire
            .ListQuestionnairesByType(_showSystem ? 'System' : showSystem ? 'System' : 'Questionnaire')
            .then((r) =>
                setConfigurations(r)).catch((e) => errors(e.message))
    }

    useEffect(() => {
        loadConfigs(showSystem)
    }, [showSystem])

    useEffect(() => {
        loadConfigs()
    }, [])

    const _handleSetConfigrationNameEvents = (e) => {
        if (e.keyCode === 13) {
            Questionnaire
                .CreateQuestionnaire({
                    name: configurationName,
                    type: 'Questionnaire'
                }).then((r) => {
                    setValue({ 'questionnaireId': r.id, 'questionnaire': configurationName })
                    navigator.goto(2)
                })
        }
        else if (e.keyCode === 27) {
            newSectionNameRef.current.blur()
            _handleSetConfigrationName('')
        }
    }
    const _handleSetConfigrationName = (c) => {
        setConfigurationName(c)
        if (c)
            navigation([{
                text: 'Next', type: 'Next', action: async () => {
                    return await Questionnaire
                        .CreateQuestionnaire({
                            name: c,
                            type: 'Questionnaire'
                        }).then((r) => {
                            setValue({ 'questionnaireId': r.id, 'questionnaire': c })
                        })
                }
            }])
        else
            navigation([])
        setConfiguration()
    }

    const _handleRemoveTemplate = async (id) => {
        await Questionnaire
            .RemoveQuestionnaire(id)
    }

    const _handleSelectConfiguration = (c) => {
        if (c)
            if (c.isLocked)
                navigation([
                    //   { text: 'Remove', type: 'Action', action: async () => { _handleRemoveTemplate(c.id).then((r) => { navigation([]); loadConfigs() }) } },
                    { text: 'Update', type: 'Next', action: async () => { setValue({ 'questionnaireId': c.id, 'templateName': c.name }) } },
                ])
            else
                navigation([
                    { text: 'Remove', type: 'Action', action: async () => { _handleRemoveTemplate(c.id).then((r) => { navigation([]); loadConfigs() }) } },
                    { text: 'Update', type: 'Next', action: async () => { setValue({ 'questionnaireId': c.id, 'templateName': c.name }) } },
                ])
        setConfigurationName('')
        setConfiguration(c)
    }

    const _handleToggleSystem = () => {
        const _showSystem = !showSystem;
        setShowSystem(_showSystem);
        setMode('Update')
    }

    return (
        <div className='config-setup-type'>

            <div className={'row top p-3 shadow rounded bg-primary-container '.concat(mode !== 'New' && configurationName === '' ? 'opacity-75' : '')} >
                <div className='col-12'>
                    <h3>New?</h3>
                    <p>Enter a name for the Questionnaire eg:  Patient Discharge Questionnaire</p>
                    <input
                        ref={newSectionNameRef}
                        onFocus={(e) => setMode('New')}
                        onBlur={(e) => setMode('')}
                        onChange={(e) => _handleSetConfigrationName(e.target.value)}
                        onKeyDown={(e) => _handleSetConfigrationNameEvents(e)}
                        value={configurationName}
                        className='form-control' placeholder='Name of the new survey eg: Patient Discharge Survey' />
                </div>
            </div>
            <div className={'row p-3 bottom shadow rounded bg-secondary-container '.concat(((mode !== 'New' && configuration) || mode === 'Update') ? '' : ' opacity-75')}>
                <div className='col-12' style={{ height: 'calc(100% - 30px)' }}>
                    <h3>Update Existing?</h3>
                    <p>Select the survey you would like to update</p>
                    <div style={{ cursor: 'pointer' }} className='text-end' onClick={() => _handleToggleSystem()}>
                        {
                            showSystem
                                ?
                                <Check2Square className='me-1' />
                                :
                                <Square className='me-1' />
                        }
                        Show System Surveys
                    </div>
                    <div
                        style={{ cursor: 'pointer', maxHeight: '75%', overflow: 'auto' }}
                        className='text-start'>
                        {
                            configurations.map((c) =>
                                <div
                                    className={'p-2 rounded '.concat(configuration && c.id === configuration.id ? ' font-weight-bold active ' : ' font-weight-light ')}
                                    onClick={() => _handleSelectConfiguration(c)} >{c.isLocked ? <LockFill className='me-2' /> : <></>}{c.name}</div>
                            )
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}

import React, { useEffect, useRef, useState } from 'react'
import { useClickOutside } from '../Hooks/useClickOutside'

export const COLOURS = [
    { name: '', descr: '', color: 'transparent', secondary: '#333' },
    { name: '', descr: '', color: '#AD4545', secondary: '#ccc' },
    { name: '', descr: '', color: '#D14D4D', secondary: '#ccc' },
    { name: '', descr: '', color: '#3572A1', secondary: '#ccc' },
    { name: '', descr: '', color: '#5380A1', secondary: '#ccc' },
    { name: '', descr: '', color: '#35A187', secondary: '#ccc' },
    { name: '', descr: '', color: '#31B15C', secondary: '#ccc' },
    { name: '', descr: '', color: '#9AA316', secondary: '#444' },
    { name: '', descr: '', color: '#BAC701', secondary: '#444' },
    { name: '', descr: '', color: '#B73169', secondary: '#ccc' },
    { name: '', descr: '', color: '#932553', secondary: '#ccc' },
    { name: '', descr: '', color: '#C07138', secondary: '#ccc' },
    { name: '', descr: '', color: '#845C40', secondary: '#ccc' },
    { name: '', descr: '', color: '#AAAAAA', secondary: '#444' },
    { name: '', descr: '', color: '#333333', secondary: '#ccc' },
]

export default function SimpleColourPicker({ onChange, defaultValue, popup, extended }) {
    const [show, setShow] = useState(false)
    const [active, setActive] = useState(defaultValue ? defaultValue : undefined)
    const ref = useRef()
    // eslint-disable-next-line react-hooks/exhaustive-deps
    // useEffect(() => { if (active && onChange) onChange(extended ? active : active.color) }, [active])
    useEffect(() => { if (defaultValue) setActive(COLOURS.find(c => c.color === defaultValue)) }, [defaultValue])

    const _handleSetColour = (colour) => {
        if (colour && onChange) onChange(extended ? colour : colour.color)
        setActive(colour)
    }
    useClickOutside(ref, () => setShow(false))
    return (
        <div ref={ref} className='row'>
            {
                popup
                    ?
                    <>

                        <div
                            style={{ zIndex: 999, width: '265px' }}
                            className={'position-absolute row bg-light p-2 shadow rounded '.concat(!show ? 'd-none' : '')}>{
                                COLOURS.map((c) =>
                                    <div
                                        key={'CLR' + c.color}
                                        onClick={() => { _handleSetColour(c); setShow(false) }}
                                        className={'col-auto m-2  '.concat(active && active.color === c.color ? 'shadow' : '')} style={{ cursor: 'pointer', background: c.color, border: active && active.color === c.color ? 'solid black 2px' : 'solid transparent 2px' }}>&nbsp;</div>
                                )
                            }
                        </div>
                        <div
                            key={'CLR'}
                            onClick={() => setShow(!show)}
                            className={'col-auto m-2 rounded pt-1 '} style={{ cursor: 'pointer', background: active && active.color ? active.color : 'tranparent', border: 'solid #E1E1E1 2px', width: '30px', height: '30px' }}>&nbsp;</div>
                    </>
                    :
                    COLOURS.map((c) =>
                        <div
                            key={'CLR' + c.color}
                            onClick={() => _handleSetColour(c)}
                            className={'col-auto m-2  '.concat(active && active.color === c.color ? 'shadow' : '')} 
                            style={{ cursor: 'pointer', background: c.color, border: active && active.color === c.color ? 'solid white 2px' : 'solid white 2px', width: '32px', height: '32px' }}>&nbsp;</div>
                    )
            }
        </div>
    )
}


import React, { useEffect, useState } from 'react'
import Questionnaire from '../../../../../../libs/api/services/Questionnaire'
import StatsLabelsGraphComponent from './StatsLabelsGraphComponent'

export default function StatsLabelsGraph({ QuestionnaireId, QuestionnaireAssignmentId, activeLabels, weighting, labels, onItemSeletected }) {
    const [options, setOptions] = useState([])
    const [graphData, setGraphData] = useState({ weighting: weighting, multi: false })

    const _handleLoadHistoric = () => {
        // setGraphData([])
        Questionnaire
            .StatsHistory(QuestionnaireId,QuestionnaireAssignmentId)
            .then((data) => {
                try {
                    var _stats = []
                    for (const stat of data) {
                        _stats.push(stat.weighting)
                    }
                    setGraphData({ weighting: _stats, multi: true })
                } catch (e) { }
            })
    }

    useEffect(() => {
        if (options.includes('historic')) { _handleLoadHistoric() } else { setGraphData({ weighting: weighting, multi: false }) }
    }, [options])

    if (!labels) return <></>


    const _toggleOption = (opt) => {
        if (!options.includes('historic'))
            setOptions([...options, 'historic'])
        else {
            options.splice(options.indexOf('historic', 1))
            setOptions([...options])
        }
    }

    return (
        <>
            <StatsLabelsGraphComponent activeLabels={activeLabels} weighting={graphData} labels={labels} onItemSeletected={onItemSeletected} />
            <div>
                <button
                    onClick={() => _toggleOption('historic')}
                    className={'btn '.concat(options && options.includes('historic') ? '' : 'opacity-75')}>Include Historic Data</button>
            </div>
        </>
    )
}
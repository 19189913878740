import { faCross, faSpinner, faUserCheck } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useEffect, useState } from 'react'
import { InboxFill } from 'react-bootstrap-icons'
import MessagingService from '../../../../../../libs/api/services/MessagingService'

export default function UserEmailDiscoverComponent({ service }) {

    const [status, setStatus] = useState({ status: '', sucess: '', nextCheck: -1, total: '', details: '', action: {} })

    useEffect(() => { _check() }, [])

    const _check = async () => {
        console.log(service.id, step)
        var step = 0
        while (step !== -1) {
            const r = await MessagingService
                .DiscoverServiceProvider(service.id, step)
            setStatus(r)
            step = r.nextCheck
        }
    }
 
    const Icon = (status) => {
        switch (status) {
            case 'Running': return <FontAwesomeIcon icon={faSpinner} spin />
            case 'Done': return <FontAwesomeIcon icon={faUserCheck} />
            case 'Error': return <FontAwesomeIcon icon={faCross} />
        }
    }
 
    return (
        <div className='bg-secondary-container p-3 rounded shadow'>
            <h3><InboxFill />Testing your mailbox configuration</h3>
            <div>
                <div>{status.status}</div>
                <div>{
                    status.action
                        ?
                        <button onClick={() => window.location = status.action.url}>{status.action.action}</button>
                        :
                        <></>
                }
                </div>
            </div>
        </div>
    )
}

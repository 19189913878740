import React, { useEffect, useRef, useState } from 'react'
import { LockFill } from 'react-bootstrap-icons'
import Questionnaire from '../../../../../libs/api/services/Questionnaire'

export default function FBuilderSetupType({ title, description, navigation, errors, setValue, values }) {

    const [configurationName, setConfigurationName] = useState('')
    const [configurations, setConfigurations] = useState([])
    const [mode, setMode] = useState('')
    const newSectionNameRef = useRef()
    const [configuration, setConfiguration] = useState(Object.keys(values).includes('questionnaireId') ? { id: values.questionnaireId } : undefined)
    useEffect(() => {
        title('Form Builder')
        description('Would you like to create a new form or update an existing one')
        // Load Navigation if session started
        if (Object.keys(values).includes('questionnaireId'))
            navigation([
                { text: 'Remove', type: 'Action', action: async () => { _handleRemoveTemplate(values.questionnaireId).then((r) => { navigation([]); loadConfigs() }) } },
                { text: 'Update', type: 'Next', action: async () => { setValue({ 'questionnaireId': values.questionnaireId }) } },
            ])
        else
            navigation([])

        setValue({ section: undefined })
    }, [])

    const loadConfigs = () => {
        Questionnaire
            .ListQuestionnairesByType('Form')
            .then((r) =>
                setConfigurations(r)).catch((e) => errors(e.message))
    }

    useEffect(() => {
        loadConfigs()
    }, [])

    const _handleSetConfigrationName = (c) => {
        setConfigurationName(c)
        if (c)
            navigation([{
                text: 'Next', type: 'Next', action: async () => {
                    return await Questionnaire
                        .CreateQuestionnaire({
                            name: c,
                            type: "Form"
                        }).then((r) => {
                            setValue({ 'questionnaireId': r.id, 'questionnaire': c })
                        })
                }
            }])
        else
            navigation([])
        setConfiguration()
    }

    const _handleSetConfigrationNameEvents = (e) => {
        if (e.keyCode === 13) {
            newSectionNameRef.current.blur()
        }
        else if (e.keyCode === 27) {
            newSectionNameRef.current.blur()
            setMode('')
        }
    }

    const _handleRemoveTemplate = async (id) => {
        await Questionnaire
            .RemoveQuestionnaire(id)
    }

    const _handleSelectConfiguration = (c) => {
        if (c)
            if (c.isLocked)
                navigation([
                    //   { text: 'Remove', type: 'Action', action: async () => { _handleRemoveTemplate(c.id).then((r) => { navigation([]); loadConfigs() }) } },
                    { text: 'Update', type: 'Next', action: async () => { setValue({ 'questionnaireId': c.id, 'templateName': c.name }) } },
                ])
            else
                navigation([
                    { text: 'Remove', type: 'Action', action: async () => { _handleRemoveTemplate(c.id).then((r) => { navigation([]); loadConfigs() }) } },
                    { text: 'Update', type: 'Next', action: async () => { setValue({ 'questionnaireId': c.id, 'templateName': c.name }) } },
                ])
        setConfigurationName('')
        setConfiguration(c)
    }

    return (
        <div className='config-setup-type'>
            <div className={'row top p-3 flex-shrink-1 shadow rounded bg-primary-container '.concat(mode !== 'New' && configurationName === '' ? 'opacity-75' : '')} >
                <div className='col-12'>
                    <h3>New?</h3>
                    <p>Enter a name for the form eg:  Chronic Medication Form</p>
                    <input
                        ref={newSectionNameRef}
                        onFocus={(e) => setMode('New')}
                        onBlur={(e) => setMode('')}
                        onChange={(e) => _handleSetConfigrationName(e.target.value)}
                        onKeyDown={(e) => _handleSetConfigrationNameEvents(e)}
                        value={configurationName}
                        className='form-control' placeholder='Name of the new form eg: Chronic Medication Form' />
                </div>
            </div>
            <div className={'row bottom p-3 flex-grow-1 shadow mb-3 rounded bg-secondary-container '.concat(mode !== 'New' && configuration ? '' : ' opacity-75')}>
                <div className='col-12 h-100'>
                    <div className='flex-shrink-1' style={{ maxHeight: '25%' }}>
                        <h3>Update Existing?</h3>
                        <p>Select the form you would like to update</p>
                    </div>
                    <div
                        style={{ cursor: 'pointer', maxHeight: '75%', overflow: 'auto' }}
                        className='text-start flex-grow-1'>
                        {
                            configurations.map((c) =>
                                <div
                                    className={'p-2 rounded '.concat(configuration && c.id === configuration.id ? ' font-weight-bold active ' : ' font-weight-light ')}
                                    onClick={() => _handleSelectConfiguration(c)} >{c.isLocked ? <LockFill className='me-2' /> : <></>}{c.name}</div>
                            )
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}

import React from 'react'
// import VariablesContext from '../context/VariablesContext'

interface Props {
  name: string
  options: string[] | undefined
  className?: string
  onChange: (name: string, value: any) => void
}

const OptionsComponent = (props: Props) => {
  return (
    <select onChange={(e) => props.onChange(props.name, e.target.value)} className={props.className} key={props.name}>
      {props.options!.map((e) => (
        <option key={e} value={e}>
          {e}
        </option>
      ))}
    </select>
  )
}

export default OptionsComponent

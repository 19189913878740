import React, { useEffect, useState } from 'react'
import { Clipboard2CheckFill } from 'react-bootstrap-icons'
import Questionnaire from '../../../../../libs/api/services/Questionnaire'

export default function QuestionnaireAssignmentSelection({ title, description, navigation, errors, setValue, values }) {

  const [questionnaires, setQuestionnaires] = useState([])
  const [questionnaire, setQuestionnaire] = useState({})

  useEffect(() => {
    title('Questionnaire')
    description('Please select the Questionnaire you would like to assign')
    // Load Navigation if session started
    // if (Object.keys(values).includes('templateId'))
    navigation([
      { text: 'Next', type: 'Next', action: async () => { } },
    ])

    if (Object.keys(values).includes('questionnaire'))
      setQuestionnaire(values.questionnaire)

    load();
  }, [])

  const load = () => {
    Questionnaire
      .ListQuestionnairesByType('Questionnaire')
      .then((r) => {
        setQuestionnaires(r)
      })
  }

  const _handleSetQuestionnaire = (q) => {
    setValue({ questionnaire: q })
    setQuestionnaire(q)
  }

  return (
    <div className='h-100 d-flex flex-column bg-primary-container rounded m-3' style={{ overflow: 'auto' }}>
      {
        questionnaires.map((q) =>
          <div
            key={q.id}
            style={{ cursor: 'pointer' }}
            className={'m-1 p-1 text-start  '.concat(questionnaire.id === q.id ? ' active ' : '')}
            onClick={(e) => _handleSetQuestionnaire(q)}
          >
            <Clipboard2CheckFill className='me-2' size={20} />{q.name}</div>
        )
      }

    </div>
  )
}

import React, { useEffect, useState } from 'react'
import { Calendar2Check, Calendar2X } from 'react-bootstrap-icons'
import Calendars from '../../../../../../libs/api/services/Calendars'

export default function CelendarTypeSelectionComponent({ filterAvailable, defaultValue, onSelected }) {

    const [calendarTypes, setCalendarTypes] = useState()
    const [active, setActive] = useState([])
    // const CALENDAR_TYPES = [
    //     { key: 'ADMIN', type: "Administrator's calendar" },
    //     { key: 'ADMIN_CLINICIAN', type: "Administrator's clinician management calendar" },
    //     { key: 'ADMIN_PATIENT', type: "Administrator's patient management calendar" },
    //     { key: 'CLINICIAN', type: "Clinicians calendar" },
    //     { key: 'CLINICIAN_PATIENT', type: "Clinicians's patient management calendar" },
    // ]
    useEffect(() => { if (defaultValue && Array.isArray(defaultValue)) setActive([...defaultValue]) }, [defaultValue])
    const _handleSelected = (t) => {
        var _activeCalendarEvent = active

        var _c = _activeCalendarEvent ? _activeCalendarEvent.find(c => c.id === t.id) : undefined
        if (_c)
            _activeCalendarEvent.splice(_activeCalendarEvent.indexOf(_c), 1)
        else
            _activeCalendarEvent.push(t)
        if (onSelected) onSelected(_activeCalendarEvent)
        setActive([..._activeCalendarEvent])
    }
    useEffect(() => { _loadCalendarTypes() }, [])

    const _loadCalendarTypes = () => {
        Calendars
            .ListCalendarTypes()
            .then((r) => setCalendarTypes(r))
            .catch(() => { })
    }

    return (
        <div style={{ cursor: 'pointer' }}>
            {calendarTypes && calendarTypes.map((t) =>
                <div
                    key={t.id}
                    onClick={() => _handleSelected(t)}
                    className={'rounded p-2 m-1 '
                        .concat(filterAvailable && filterAvailable.includes(t.type) ?
                            'bg-secondary opacity-25' :
                            active
                                ? active
                                    .find(a => a.id === t.id) != null
                                    ? ' bg-primary-container' : ' bg-secondary-container' : 'bg-secondary-container')
                    }
                >
                    {active && active.find(a => a.id === t.id) != null ? <Calendar2Check className='me-1' /> : <Calendar2X className='me-1' />}
                    {t.description}</div>
            )}
        </div>
    )
}

import React, { useRef, useState } from 'react'
import { FcHighPriority, FcLowPriority, FcMediumPriority } from 'react-icons/fc'
import { useClickOutside } from '../Hooks/useClickOutside'

export const PRIORITIES =
    [
        {
            proprity: 'High',
            icon: <FcHighPriority />
        },
        {
            proprity: 'Medium',
            icon: <FcMediumPriority />
        },
        {
            proprity: 'Low',
            icon: <FcLowPriority />
        },
    ]

export default function PriorityPicker({ defaultValue, onChange }) {
    const [state, setState] = useState({
        // icon: <MdPriorityHigh />,
        icon: defaultValue && PRIORITIES.find(p => p.proprity === defaultValue) && PRIORITIES.find(p => p.proprity === defaultValue).icon,
        show: false,
        priority: defaultValue ? defaultValue : undefined
    })
    const ref = useRef()

    const _handleSetPriority = (priority) => {
        if (onChange) onChange(priority);
        setState({
            ...state,
            icon: PRIORITIES.find(p => p.proprity === priority) && PRIORITIES.find(p => p.proprity === priority).icon,
            priority: priority,
            show: false
        })
    }
    useClickOutside(ref, () => setState({ ...state, show: false }))
    return (
        <div ref={ref} style={{ zIndex: 10000 }}>
            {
                state.show ?
                    <div
                        style={{ cursor: 'pointer', zIndex: 9999 }}
                        className='position-absolute text-start bg-light rounded shadow p-2'>
                        {
                            PRIORITIES.map((p) =>
                                <div key={`PriorityPick-${p.proprity}`}
                                    onClick={() => {
                                        _handleSetPriority(p.proprity);
                                        // setState({ ...state, show: false, priority: p.proprity })
                                    }}>{p.icon} {p.proprity}</div>
                            )
                        }
                    </div>
                    :
                    <></>
            }
            <div
                key={'CLR'}
                onClick={() => setState({ ...state, show: !state.show })}
                className={'col-auto m-2 rounded d-flex align-items-center justify-content-center'}
                style={{ cursor: 'pointer', border: 'solid #E1E1E1 2px', width: '30px', height: '30px', zIndex: 9999 }}>
                {state.icon}
            </div>
        </div>
    )
}

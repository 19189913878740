export default class CalendarGenerics {
    static debug = false
    static months =
        [{
            short: 'Jan',
            full: 'January'
        }, {
            short: 'Feb',
            full: 'February'
        }, {
            short: 'Mar',
            full: 'March'
        }, {
            short: 'Apr',
            full: 'April'
        }, {
            short: 'May',
            full: 'May'
        }, {
            short: 'Jun',
            full: 'June'
        }, {
            short: 'Jul',
            full: 'July'
        }, {
            short: 'Aug',
            full: 'August'
        }, {
            short: 'Sep',
            full: 'September'
        }, {
            short: 'Oct',
            full: 'October'
        }, {
            short: 'Nov',
            full: 'November'
        }, {
            short: 'Dec',
            full: 'December'
        }
        ]
    static daysOfWeek =
        [{
            short: 'Sun',
            full: 'Sunday'
        }, {
            short: 'Mon',
            full: 'Monday'
        }, {
            short: 'Tues',
            full: 'Tuesday'
        }, {
            short: 'Wed',
            full: 'Wednesday'
        }, {
            short: 'Thu',
            full: 'Thursday'
        }, {
            short: 'Fri',
            full: 'Friday'
        }, {
            short: 'Sat',
            full: 'Saturday'
        }]
    static timeIntervals = [
        '00:00',
        '00:30',
        '01:00',
        '01:30',
        '02:00',
        '02:30',
        '03:00',
        '03:30',
        '04:00',
        '04:30',
        '05:00',
        '05:30',
        '06:00',
        '06:30',
        '07:00',
        '07:30',
        '08:00',
        '08:30',
        '09:00',
        '09:30',
        '10:00',
        '10:30',
        '11:00',
        '11:30',
        '12:00',
        '12:30',
        '13:00',
        '13:30',
        '14:00',
        '14:30',
        '15:00',
        '15:30',
        '16:00',
        '16:30',
        '17:00',
        '17:30',
        '18:00',
        '18:30',
        '19:00',
        '19:30',
        '20:00',
        '20:30',
        '21:00',
        '21:30',
        '22:00',
        '22:30',
        '23:00',
        '23:30',
    ]
    // static timeIntervals = [
    //     '00:00',
    //     '00:15',
    //     '00:30',
    //     '00:45',
    //     '01:00',
    //     '01:15',
    //     '01:30',
    //     '01:45',
    //     '02:00',
    //     '02:15',
    //     '02:30',
    //     '02:45',
    //     '03:00',
    //     '03:15',
    //     '03:30',
    //     '03:45',
    //     '04:00',
    //     '04:15',
    //     '04:30',
    //     '04:45',
    //     '05:00',
    //     '05:15',
    //     '05:30',
    //     '05:45',
    //     '06:00',
    //     '06:15',
    //     '06:30',
    //     '06:45',
    //     '07:00',
    //     '07:15',
    //     '07:30',
    //     '07:45',
    //     '08:00',
    //     '08:15',
    //     '08:30',
    //     '08:45',
    //     '09:00',
    //     '09:15',
    //     '09:30',
    //     '09:45',
    //     '10:00',
    //     '10:15',
    //     '10:30',
    //     '10:45',
    //     '11:00',
    //     '11:15',
    //     '11:30',
    //     '11:45',
    //     '12:00',
    //     '12:15',
    //     '12:30',
    //     '12:45',
    //     '13:00',
    //     '13:15',
    //     '13:30',
    //     '13:45',
    //     '14:00',
    //     '14:15',
    //     '14:30',
    //     '14:45',
    //     '15:00',
    //     '15:15',
    //     '15:30',
    //     '15:45',
    //     '16:00',
    //     '16:15',
    //     '16:30',
    //     '16:45',
    //     '17:00',
    //     '17:15',
    //     '17:30',
    //     '17:45',
    //     '18:00',
    //     '18:15',
    //     '18:30',
    //     '18:45',
    //     '19:00',
    //     '19:15',
    //     '19:30',
    //     '19:45',
    //     '20:00',
    //     '20:15',
    //     '20:30',
    //     '20:45',
    //     '21:00',
    //     '21:15',
    //     '21:30',
    //     '21:45',
    //     '22:00',
    //     '22:15',
    //     '22:30',
    //     '22:45',
    //     '23:00',
    //     '23:15',
    //     '23:30',
    //     '23:45',
    // ]
    static getWOY(date) {
        // 0 = Sun
        // Next Sun = 6 - (day 0-6) 
        var _start = new Date(date)
        _start.setDate(date.getDate() - date.getDay())
        var _end = new Date(date)
        _end.setDate(date.getDate() + (6 - date.getDay()))
        const ret = { start: JSON.stringify(_start), end: JSON.stringify(_end), woy: CalendarGenerics.GetWeekNumber(date) }
        return ret
    }
    static getWOYArray(year, month, weekOfYear) {
        var _woy_dates = []
        var _start = new Date(JSON.parse(CalendarGenerics.getWOY(CalendarGenerics.GetDateFromDOW(year, month, (weekOfYear - 1), 0)).start))
        _woy_dates.push(_start)
        for (var d = 0; d < 6; d++) {
            _start = CalendarGenerics.AddDays(_start, 1)
            _woy_dates.push(_start)
        }
        return _woy_dates
    }
    static AddDays(date, days) {
        var result = new Date(date)
        result.setDate(result.getDate() + days)
        return result
    }
    static AdMinutes(date, minutes) {
        return new Date(date.getTime() + minutes * 60000)
    }
    static GetWeekNumber(date) {
        const currentdate = new Date(date);
        var oneJan = new Date(currentdate.getFullYear(), 0, 1);
        var numberOfDays = Math.floor((currentdate - oneJan) / (24 * 60 * 60 * 1000));
        return (Math.ceil((currentdate.getDay() + 1 + numberOfDays) / 7) - 1);
    }
    static GetFirstWeekInMonth(year, monthNumber) {
        // const currentdate = new Date(year + '-' + monthNumber + 1, 1);
        const currentdate = new Date(year + "-" + String(monthNumber + 1).padStart(2, '0') + "-" + String(1).padStart(2, '0') + "T00:00:00.000Z");
        var oneJan = new Date(currentdate.getFullYear(), 0, 1);
        var numberOfDays = Math.floor((currentdate - oneJan) / (24 * 60 * 60 * 1000));
        return Math.ceil((currentdate.getDay() + 1 + numberOfDays) / 7);
    }
    static GetMonthFromWOY(year, woy) {

        // Get sunday for woy
        var oneJan = new Date(year, 0, 1);
        var _start = CalendarGenerics.getWOY(oneJan);
        for (var i = 1; i <= woy; i++) {
            oneJan = _start.end
            _start = CalendarGenerics.getWOY(CalendarGenerics.AddDays(JSON.parse(oneJan), 1))
        }
        return new Date(JSON.parse(_start.end)).getMonth()
    }
    /*
        Function to compare dates by day
    */
    static isSameDay = (d1, d2) => {
        return d1.toISOString().substring(0, 10) === d2.toISOString().substring(0, 10)
    }

    static GetDateFromDOW = (year, month, week, dow) => {
        //return d1.toISOString().substring(0, 10) === d2.toISOString().substring(0, 10)
        var oneJan = new Date(year, 0, 1)
        var _start = CalendarGenerics.getWOY(oneJan);
        for (var i = 1; i <= week; i++) {
            _start = CalendarGenerics.getWOY(CalendarGenerics.AddDays(JSON.parse(_start.start), 7))
        }

        return CalendarGenerics.AddDays(JSON.parse(_start.start), dow)
    }

    static clock = () => {
        return String(new Date().getHours()).padStart(2, "0") + ':' + String((Math.floor(new Date().getMinutes() / 15) * 15)).padStart(2, '0')
    }
}

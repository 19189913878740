import { faSpinner } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useEffect, useState } from 'react'
import { Check2Circle, FilterCircle, Layers, Trash } from 'react-bootstrap-icons'

export default function QBuilderComponentOptions({ onAction, idx, isNew, onClose, busy }) {
    const [mode, setMode] = useState('')

    const _handleClose = () => {
        if (busy) {
            setMode('loading')
            onClose()
        } else {
            onClose()
        }
    }

    useEffect(() => {
        if(busy === false){
            setMode('')
            onClose()
        }
        // loaded()
    }, [busy])

    if (mode === 'loading') {
        return <div className='text-center'><FontAwesomeIcon icon={faSpinner} spin /> Saving changes, please wait . . .</div>
    }

    return (
        <div className='d-flex justify-content-end'>
            {
                !isNew
                    ?
                    <>
                        <div
                            onClick={(e) => onAction({ action: 'Clone', type: 'Clone', idx: idx })}
                            className='d-flex flex-column align-items-center me-2'>
                            <Layers />
                            <small>Clone</small>
                        </div>
                        <div
                            onClick={(e) => onAction({ action: 'Set Type', type: 'New', idx: idx })}
                            className='d-flex flex-column align-items-center me-2'>
                            <FilterCircle />
                            <small>Change component type</small>
                        </div>
                    </>
                    :
                    <></>
            }

            <div
                onClick={(e) => onAction({ action: 'Remove', idx: idx })}
                className='d-flex flex-column align-items-center me-2'>
                <Trash />
                <small>Remove Component</small>
            </div>
            {
                !isNew
                    ?

                    <div
                        onClick={(e) => _handleClose()}
                        className='d-flex flex-column align-items-center me-2  ' style={{fontWeight: 'bold'}} >
                        <Check2Circle/>
                        <small>Done</small>
                    </div>
                    :
                    <></>
            }
        </div>
    )
}

import API from "../API";

var Data = {
    async ListAllDataLinkTypes() {
        var result = await API.process(
            'api/Data/Links',
            'GET',
            null
        ).catch((err) => {
            throw err;
        })

        return result
    },
    async ListDataLinkTypes(module) {
        var result = await API.process(
            'api/Data/Links/' + module,
            'GET',
            null
        ).catch((err) => {
            throw err;
        })

        return result
    },
    async SetLink(module, fieldClass, link, sourceComponent, sourceId) {
        // throw Error('Implement API PUT api/Data/Link/{sourceId} : ', sourceId, module, link)
        var result = await API.process(
            'api/Data/Link/' + sourceComponent + '/' + sourceId,
            'PUT',
            {
                ModuleClass: module,
                Fields: [
                    {
                        FieldName: link,
                        FieldClass: fieldClass
                    }
                ]

            }
        ).catch((err) => {
            throw err;
        })

        return result
    },
    async IsFieldLinked(profileId, module, linkId) {
        var result = await API.process(
            'api/Data/Link/' + profileId + '/' + module + '/' + linkId + '/linked',
            'GET',
            null
        ).catch((err) => {
            throw err;
        })

        return result
    },
    async ListProfileLinks(profileId) {
        var result = await API.process(
            'api/Data/Link/' + profileId + '/profile',
            'GET',
            null
        ).catch((err) => {
            throw err;
        })

        return result
    },
    async RenderText(details) {
        var result = await API.process(
            'api/Data/Render',
            'PATCH',
            details
        ).catch((err) => {
            throw err;
        })

        return result
    }
}

export default Data;
import React, { useEffect, useRef, useState } from 'react';
import { Search, XCircle } from 'react-bootstrap-icons';
// import Facility from '../../../../../../libs/api/services/Facility';
import { faClose } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useParams } from 'react-router-dom';
import Facility from '../../../../../../libs/api/services/Facility';

export default function FacilitySelectionComponent({ onFacilitySelected, defaultValue }) {
    const { id } = useParams();
    const refSearch = useRef()
    const [Facilitys, setFacilitys] = useState([]);
    const [details, setDetails] = useState(defaultValue ? defaultValue : '');
    const [error, setError] = useState("");
    const search = (s) => {
        // Search API
        Facility.search({
            search: s
        })
            .then((result) => setFacilitys(result))
            .catch((err) => setError(err.message))
    }

    useEffect(() => {
        if (id) {
            Facility.get(id)
                .then((res) => {
                    setDetails(res.facility)
                })
                .catch((err) => {
                    console.err(err)
                    setError(err.message)
                })
        }
    }, [id]);

    const navigateFacility = (id) => {
        const Facility = Facilitys.find(u => u.id === id)
        refSearch.current.value = Facility.facility
        setDetails(Facility.facility)
        setFacilitys([])
        // onFacilitySelected(id)
        onFacilitySelected(Facility)
    }

    const _handleClear = () => {
        setDetails('')
        refSearch.current.value = ''
        setFacilitys([])
    }

    return (
        <div className="row">
            <div className="col-12">
                {
                    error
                        ?
                        <div className="text-danger">{error}</div>
                        :
                        <></>
                }
                <div className="d-flex position-relative">
                    <input
                        ref={refSearch}
                        onChange={(e) => search(e.target.value)} defaultValue={details}
                        className='form-control bg-search-bar rounded-3 text-primary'
                        placeholder='Facility Search' />
                    {
                        !(refSearch.current && refSearch.current.value !== "")
                            ?
                            <div
                                onClick={(e) => refSearch.current.select()}
                                className="d-flex align-items-center bg-buttons px-3 position-absolute rounded" style={{ right: '0px', top: '0px', bottom: '0px', fontWeight: 'bold' }}>
                                <Search />
                            </div>
                            :
                            <div
                                onClick={(e) => _handleClear()}
                                className="d-flex align-items-center bg-buttons px-3 position-absolute rounded" style={{ right: '0px', top: '0px', bottom: '0px', fontWeight: 'bold' }}>
                                <XCircle />
                            </div>
                    }

                </div>
                {
                    Facilitys.length > 0
                        ?
                        <div className='bg-search-bar-result rounded-3 text-dark position-absolute w-50' >
                            <FontAwesomeIcon onClick={(e) => setFacilitys([])} style={{ position: "absolute", right: 0, padding: "8px", zIndex: 99 }} fontSize={15} icon={faClose} />
                            <ul className='position-relative'>
                                {
                                    Facilitys.map((result) =>
                                        <li onClick={(e) => navigateFacility(result.id)} key={result.id}>{result.facility}</li>
                                    )
                                }
                            </ul>
                        </div>
                        :
                        <></>
                }
            </div>
        </div>
    )
}

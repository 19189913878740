import React, { useContext, useEffect, useState } from 'react'
import { QuestionCircle } from 'react-bootstrap-icons'
import Authentication from '../../../../../../../libs/api/services/Authentication'
import { TaskTypeMapping } from '../../../../Config/TaskTypeMapping'
import WorkFlowConfigurationContext from '../../../WorkFlowConfigurationProvider'
import EventTaskTypesSelection from './Components/EventTasks/EventTaskTypesSelection'
import NewTaskLineItem from './Components/EventTasks/TaskTypes/NewTaskLineItem'
export default function AddEventWizardEventTasks({ title, description, navigation, errors, setValue, values, navigator, subsection }) {
    const { loadTasks, tasks, removeTask, addTask, allocateMessagingTask } = useContext(WorkFlowConfigurationContext)
    const [mode, setMode] = useState('View All')

    useEffect(() => {
        title('Event Tasks')
        description('An event task is an automated task that is triggered once an event has happened eg:- Send out email to clinicians with patients details')
        navigation([{
            text: 'Back', type: 'Back', action: async () => { }
        },
        {
            text: 'Done', type: 'Done', action: async () => { }
        },
        ])
        // console.log(values)
        if (Object.keys(values).includes('event'))
            loadTasks(values.event.id)
        else
            loadTasks()

        if (subsection && subsection.values && subsection.values.templateId) {
            allocateMessagingTask(values.event.id, subsection.values.templateId)
            // Allocate or update template
            console.log(values.event.id)
        }
        //console.log(subsection.values.templateId)
    }, [])

    const _handleAddEvent = async (task, type) => {
        const res = await addTask(task, type)
        if (res) {
            if (Object.keys(values).includes('event'))
                loadTasks(values.event.id)
            else
                loadTasks()
            setMode('View All')
        }
    }

    return (
        <div>
            <div>
                {
                    mode === 'Add Task'
                        ?
                        <div>
                            <b>Select the type of task you would like to create</b>
                            <EventTaskTypesSelection values={values} />
                        </div>
                        :
                        <div className='bg-primary-container m-3 p-2 rounded whoadow'>
                            <b className=''>Task List</b>
                            <NewTaskLineItem onAdded={_handleAddEvent} values={values} setValue={setValue} errors={errors} />
                            {
                                tasks.length === 0
                                    ?
                                    <div className='p-3'>No tasks Linked</div>
                                    :
                                    tasks.map((r) => {
                                        try {
                                            if (r && r.taskType)
                                                return {
                                                    ...TaskTypeMapping[r.taskType.type],
                                                    key: `TskC-${r.id}`,
                                                    props: {
                                                        ...TaskTypeMapping[r.taskType.type].props,
                                                        task: { ...r },
                                                        setValue: setValue,
                                                        values: values,
                                                        errors: errors,
                                                        facility: Authentication.getFacility(),
                                                        user: Authentication.UserId(),
                                                        onChanged: () => Object.keys(values).includes('event') ? loadTasks(values.event.id) : loadTasks(),
                                                        onDelete: removeTask,
                                                        onSetProperty: () => { },
                                                        config: r
                                                    },
                                                }
                                        } catch (e) {
                                            console.warn('Please ensure you have connfigured your component in the TaskTypeMapping config')
                                        }
                                        return <div><QuestionCircle /></div>
                                    }
                                    )
                            }
                        </div>
                }
            </div>

        </div>
    )
}

import React, { useEffect, useState } from 'react'
import OptionsGridComponent from '../../../../../components/ui/options_grid/OptionsGridComponent'
import Patient from '../../../../../libs/api/services/Patient'

export default function PatientProfileCategoryFieldType({ onChange, field }) {
    const [options, setOptions] = useState([])
    useEffect(() => {
        Patient
            .FieldTypes()
            .then((r) => {
                setOptions(r)
            })
    }, [])

    return (
        <OptionsGridComponent defaultValue={field.type.id} onChange={(v) => onChange(v)} valueKey={'type'} options={options} />
    )
}

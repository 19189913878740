import React, { useContext, useEffect, useState } from 'react'
import SimpleColourPicker from '../../../../../../components/colour/SimpleColourPicker'
import GenericIconPicker from '../../../../../../components/icons/GenericIconPicker'
import CalendarFacilityContext from '../../CalendarFacilityProvider'

export default function CalendarEventDetailsOverview() {
  const [active, setActive] = useState({ id: -1 })
  const { activeCalendarEvent, setCalendarEventProperty } = useContext(CalendarFacilityContext)
  const [properties, setProperties] = useState({
    trigger: {},
    properties: [
      { name: 'ICONS', value: undefined },
      { name: 'BACKGROUND_COLOUR', value: undefined },
    ]
  })
  const _handleSetProperty = (n, v) => {
    var _props = properties
    var _p = _props.properties.find(p => p.name === n);
    if (_p)
      _p.value = JSON.stringify(v)
    else
      _props.properties.push({ name: n, value: JSON.stringify(v) })
    setCalendarEventProperty(n, JSON.stringify(v))
    setProperties({ ..._props })
  }

  const _findValue = (n) => {
    var _p = properties.properties.find(p => p.name === n);
    console.log(_p)
    if (_p && _p.value)
      return JSON.parse(_p.value)
    return undefined
  }

  useEffect(() => {
    if (activeCalendarEvent) {
      setActive(activeCalendarEvent.triggeredWhen)
      if (activeCalendarEvent.properties) setProperties({ ...properties, properties: activeCalendarEvent.properties })
    }
  }, [activeCalendarEvent])
  // useEffect(() => { setProperties({ ...properties, trigger: active }) }, [active])


  return (
    <div className='d-flex justify-content-evenly h-100'>
      <div className='w-25 p-2'>
        <b>Event Colour :</b>
        <SimpleColourPicker onChange={(v) => _handleSetProperty('BACKGROUND_COLOUR', v)} defaultValue={_findValue('BACKGROUND_COLOUR')}  />
      </div>
      <div className='w-75 p-2'>
        <b>Status Icons :</b>
        <div className='row'>
          <GenericIconPicker onChange={(v) => _handleSetProperty('ICONS', v)} dropdown={true} defaultValue={_findValue('ICONS')} />
        </div>
      </div>
    </div>
  )
}

import { faSpinner } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useState } from 'react'

export default function InlineWizardItemComponent({ showBack, backButtonText, nextButtonText, action, triggerNext, triggerBack, children }) {
    const [loading, setLoading] = useState(false)
    const _handleNext = async () => {
        setLoading(true)
        if (action) {
            if (await action())
                triggerNext()
        }else{
            triggerNext()
        }
        setLoading(false)
    }
    const _handleBack = () => {
        triggerBack()
    }
    return (
        <div>
            {children}
            {showBack ? <button onClick={_handleBack} className='btn m-2'>{backButtonText ? backButtonText : 'Back'}</button> : <></>}
            {
                loading ? <FontAwesomeIcon icon={faSpinner} spin />
                    :
                    <button onClick={_handleNext} className='btn m-2'>{nextButtonText ? nextButtonText : 'Next'}</button>
            }

        </div>
    )
}

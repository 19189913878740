import { faSpinner } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useState } from 'react'
import ErrorDialog from '../../../../../components/dialogs/ErrorDialog'
import UserEmailDetails from './wizard/UserEmailDetails'

export default function UserEmailAccountConfiguration({ user }) {
    const [step, setStep] = useState(1)
    const [title, setTitle] = useState('')
    const [description, setDescription] = useState('')
    const [navigation, setNavigation] = useState([])
    const [errors, setErrors] = useState('')
    const [values, setValues] = useState({})
    // const { setSticky, setPreventMessage } = useContext(StickyNavigationContext)

    // useEffect(() => {
    //     // Look for configuration
    //     MessagingService
    //         .LookupUserLinkedEmailAccount(Authentication.UserId())
    //         .then((r) => {
    //             setValues({ ...values, configurationId: r.id })
    //             // alert(JSON.stringify(r))
    //             setStep(2)
    //         }).catch((e) => {
    //             if (e.message === 'No Linked Accounts') {
    //                 // Create configuration
    //                 MessagingService
    //                     .SetConfiguration({
    //                         configurationName: JSON.parse(Authentication.UserName()) + " email account",
    //                         serviceType: {
    //                             type: 'Email'
    //                         },
    //                         // audienceUser:{
    //                         //     id : Authentication.UserId()
    //                         // }
    //                     })
    //                     .then((rb) => {
    //                         // Link conf to AudienceUser
    //                         MessagingService
    //                             .SetAudienceType(rb.id, "SingleUser")
    //                             .then((rc) => {
    //                                 MessagingService
    //                                     .SetConfigurationAudienceUser(rb.id, Authentication.UserId())
    //                                     .then((u) => {
    //                                         setValues({ ...values, configurationId: rb.id })
    //                                         MessagingService
    //                                             .SetMessagingServiceProperty(rb.id, "EMAIL_INCOMING_MAILBOX_STATUS", "Mailbox configuration incomplete")
    //                                             .then(() => {
    //                                                 setStep(1)
    //                                             })
    //                                     })
    //                             })
    //                     })

    //             }
    //             else
    //                 setErrors(e.message)
    //         })

    // }, [])

    const _getStepSection = () => {
        switch (step) {
            case 0:
                return <div><FontAwesomeIcon icon={faSpinner} spin /> Looking up your account details</div>
            case 1:
                return <UserEmailDetails
                    title={(t) => setTitle(t)}
                    description={(t) => setDescription(t)}
                    navigation={(t) => setNavigation(t)}
                    errors={(e) => setErrors(e)}
                    setValue={(v) => setValues({ ...values, ...v })}
                    values={values}
                />
            // case 3:
            //     return <EmailWizardWebhook
            //         title={(t) => setTitle(t)}
            //         description={(t) => setDescription(t)}
            //         navigation={(t) => setNavigation(t)}
            //         errors={(e) => setErrors(e)}
            //         setValue={(v) => setValues({ ...values, ...v })}
            //         values={values}
            //     />
            // case 2:
            // case 4:
            //     return <EmailWizardConnectionInfo
            //         title={(t) => setTitle(t)}
            //         description={(t) => setDescription(t)}
            //         navigation={(t) => setNavigation(t)}
            //         errors={(e) => setErrors(e)}
            //         setValue={(v) => setValues({ ...values, ...v })}
            //         values={values}
            //     />
            default:
                return <></>
        }
    }
    return (
        <>
            <ErrorDialog error={errors} onClose={() => setErrors('')} />
            <div
                className="Wizard top-center position-relative m-3">
                <div className="head"><h1>{title}</h1>
                    <p>{description}</p>

                    <div
                        className="error-details"></div>
                </div>
                <div className="body">
                    <div
                        className=".section d-flex flex-column align-items-stretch h-100 w-100">
                        {_getStepSection()}
                    </div>
                </div>
                <div classname="tail">
                    {
                        navigation.map((n) => {
                            if (n.type === 'Next')
                                return <button
                                    key={n.text}
                                    onClick={async (e) => {
                                        if (n.action) {
                                            await n.action().catch((e) => setErrors(e.message))
                                        }
                                        setStep(step + 1)
                                    }} className="btn ng-buttons">{n.text}</button>
                            else if (n.type === 'Back')
                                return step === 1 ? <></> : <button
                                    key={n.text}
                                    onClick={async (e) => {
                                        if (n.action) {
                                            await n.action().catch((e) => setErrors(e.message))
                                        }
                                        setStep(step - 1)
                                    }} className="btn ng-buttons">{n.text}</button>
                            else if (n.type === 'Action')
                                return <button
                                    key={n.text}
                                    onClick={async (e) => {
                                        if (n.action)
                                            await n.action()
                                                .catch((e) => setErrors(e.message))
                                    }} className="btn ng-buttons">{n.text}</button>
                            else if (n.type === 'Done')
                                return <button
                                    key={n.text}
                                    onClick={async (e) => {
                                        if (n.action)
                                            await n.action()
                                                .catch((e) => setErrors(e.message))
                                        setStep(1)
                                    }} className="btn ng-buttons">{n.text}</button>
                            else if (n.type === 'Step')
                                return <button
                                    key={n.text}
                                    onClick={async (e) => {
                                        if (n.action)
                                            await n.action()
                                                .catch((e) => setErrors(e.message))
                                        setStep(n.step)
                                    }} className="btn ng-buttons">{n.text}</button>
                        })
                    }
                </div>
            </div>
        </>
    )
}

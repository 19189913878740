import React, { useEffect, useState } from 'react'
import ErrorDialog from '../../../../../components/dialogs/ErrorDialog'

const WorkFlowEnvronmentContext = React.createContext()

export const WorkFlowEnvronmentProvider = ({ environment, children }) => {
    const [filters, setFilters] = useState()
    const [environmentVariables, setEnvironmentVariables] = useState({

    })
    const [error, setError] = useState()

    useEffect(() => { if (environment) setEnvironmentVariables(environment) }, [environment])

    return (
        <>
            <ErrorDialog error={error} humanize={true} onClose={() => setError()} />
            <WorkFlowEnvronmentContext.Provider
                value={{
                    error: (e) => setError(e)
                }}
            >
                {children}
            </WorkFlowEnvronmentContext.Provider>
        </>
    )
}

export default WorkFlowEnvronmentContext
import React, { useState } from 'react'
import { useEffect } from 'react'
import Patient from '../../../../../../libs/api/services/Patient'

export default function AudiencePatientProfileComponent({ errors, onAudienceSelected, defaultValue }) {
    const [categories, setCategories] = useState([])
    const [category, setCategory] = useState([])
    const [fields, setFields] = useState([])
    const [field, setField] = useState(defaultValue ? defaultValue : undefined)

    const _checkDefault = async () => {
        const _categories = await Patient.Categories();
        for (const categoryId of _categories) {
            var _fields = await Patient.LoadFields(categoryId.id)
            for (const fieldId of _fields)
                if (fieldId.id === defaultValue.id) { _handleGetFields(categoryId.id); setField(fieldId); setCategory(categoryId) }
        }
    }

    useEffect(() => {
        if (defaultValue)
            _checkDefault()
    }, [defaultValue])

    useEffect(() => {
        loadAudiencCategories()
    }, [])

    const loadAudiencCategories = async () => {
        // alert(defaultValue)
        return await Patient
            .Categories()
            .then((r) => setCategories(r))
            .catch((e) => { if (e.errors) errors(JSON.stringify(e.errors)); else errors(e.message); })
    }

    const _handleGetFields = (categoryId) => {
        if (categoryId !== "")
            Patient
                .LoadFields(categoryId)
                .then((r) => setFields(r))
        else
            setFields([])
    }
    const _handleSetField = (f) => {
        onAudienceSelected(f.id)
            .then((r) => {
                setField(f)
            })
    }

    return (
        <div>
            <div>
                <label><b>Select the patients profile category</b></label>
                <select onChange={(e) => _handleGetFields(e.target.value)} className='form-control'>
                    <option value=""></option>
                    {
                        categories.map((c) => {
                            return (<option selected={category && category.id === c.id ? 'selected' : ''} key={c.id} value={c.id}>{c.groupNme}</option>)
                        })
                    }
                </select>
                <div>
                    <label><b>Select the patients profile field to use for sending the message</b></label>
                    {
                        fields.map((f) => {
                            return (<div onClick={(e) => _handleSetField(f)} className='text-start rounded bg-secondary-container m-3 '><div className={'p-2 '.concat(field && f.id === field.id ? 'active' : '')}>{f.value}</div></div>)
                        })
                    }
                </div>
            </div>
        </div>
    )
}


export const ICDCODES =
    [
        { pmbCode: 1, ICD10Code: 'F10.1', description: 'Mental and behavioural disorders due to use of alcohol, harmful use' },
        { pmbCode: 1, ICD10Code: 'F10.2', description: 'Mental and behavioural disorders due to use of alcohol, dependence syndrome' },
        { pmbCode: 1, ICD10Code: 'F11.1', description: 'Mental and behavioural disorders due to use of opioids, harmful use' },
        { pmbCode: 1, ICD10Code: 'F11.2', description: 'Mental and behavioural disorders due to use of opioids, dependence syndrome' },
        { pmbCode: 1, ICD10Code: 'F12.1', description: 'Mental and behavioural disorders due to use of cannabinoids, harmful use' },
        { pmbCode: 1, ICD10Code: 'F12.2', description: 'Mental and behavioural disorders due to use of cannabinoids, dependence syndrome' },
        { pmbCode: 1, ICD10Code: 'F13.1', description: 'Mental and behavioural disorders due to use of sedatives or hypnotics, harmful use' },
        { pmbCode: 1, ICD10Code: 'F13.2', description: 'Mental and behavioural disorders due to use of sedatives or hypnotics, dependence syndrome' },
        { pmbCode: 1, ICD10Code: 'F14.1', description: 'Mental and behavioural disorders due to use of cocaine, harmful use' },
        { pmbCode: 1, ICD10Code: 'F14.2', description: 'Mental and behavioural disorders due to use of cocaine, dependence syndrome' },
        { pmbCode: 1, ICD10Code: 'F15.1', description: 'Mental and behavioural disorders due to use of other stimulants including caffeine, harmful use' },
        { pmbCode: 1, ICD10Code: 'F15.2', description: 'Mental and behavioural disorders due to use of other stimulants including caffeine, dependence syndrome' },
        { pmbCode: 1, ICD10Code: 'F16.1', description: 'Mental and behavioural disorders due to use of hallucinogens, harmful use' },
        { pmbCode: 1, ICD10Code: 'F16.2', description: 'Mental and behavioural disorders due to use of hallucinogens, dependence syndrome' },
        { pmbCode: 1, ICD10Code: 'F16.9', description: 'Mental and behavioural disorders due to use of hallucinogens, unspecified mental and behavioural disorder' },
        { pmbCode: 1, ICD10Code: 'F17.0', description: 'Mental and behavioural disorders due to use of tobacco, acute intoxication' },
        { pmbCode: 1, ICD10Code: 'F18.1', description: 'Mental and behavioural disorders due to use of volatile solvents, harmful use' },
        { pmbCode: 1, ICD10Code: 'F18.2', description: 'Mental and behavioural disorders due to use of volatile solvents, dependence syndrome' },
        { pmbCode: 1, ICD10Code: 'F19.1', description: 'Mental and behavioural disorders due to multiple drug use and use of psychoactive substances, harmful use' },
        { pmbCode: 1, ICD10Code: 'F19.2', description: 'Mental and behavioural disorders due to multiple drug use and use of psychoactive substances, dependence syndrome' },
        { pmbCode: 2, ICD10Code: 'F06.0', description: 'Organic hallucinosis' },
        { pmbCode: 2, ICD10Code: 'F06.1', description: 'Organic catatonic disorder' },
        { pmbCode: 2, ICD10Code: 'F06.2', description: 'Organic delusional [schizophrenia-like] disorder' },
        { pmbCode: 2, ICD10Code: 'F06.3', description: 'Organic mood [affective] disorders' },
        { pmbCode: 2, ICD10Code: 'F06.4', description: 'Organic anxiety disorder' },
        { pmbCode: 2, ICD10Code: 'F06.5', description: 'Organic dissociative disorder' },
        { pmbCode: 2, ICD10Code: 'F06.6', description: 'Organic emotionally labile [asthenic] disorder' },
        { pmbCode: 2, ICD10Code: 'F06.7', description: 'Mild cognitive disorder' },
        { pmbCode: 2, ICD10Code: 'F06.9', description: 'Unspecified mental disorder due to brain damage and dysfunction and to physical disease' },
        { pmbCode: 2, ICD10Code: 'F07.0', description: 'Organic personality disorder' },
        { pmbCode: 2, ICD10Code: 'F07.1', description: 'Postencephalitic syndrome' },
        { pmbCode: 2, ICD10Code: 'F07.2', description: 'Postconcussional syndrome' },
        { pmbCode: 2, ICD10Code: 'F07.8', description: 'Other organic personality and behavioural disorders due to brain disease, damage and dysfunction' },
        { pmbCode: 2, ICD10Code: 'F07.9', description: 'Unspecified organic personality and behavioural disorder due to brain disease, damage and dysfunction' },
        { pmbCode: 2, ICD10Code: 'F09', description: 'Unspecified organic or symptomatic mental disorder' },
        { pmbCode: 2, ICD10Code: 'F10', description: 'Mental and behavioural disorders due to use of alcohol' },
        { pmbCode: 2, ICD10Code: 'F10.0', description: 'Mental and behavioural disorders due to use of alcohol, acute intoxication' },
        { pmbCode: 2, ICD10Code: 'F10.8', description: 'Mental and behavioural disorders due to use of alcohol, other mental and behavioural disorders' },
        { pmbCode: 2, ICD10Code: 'F10.9', description: 'Mental and behavioural disorders due to use of alcohol, unspecified mental and behavioural disorder' },
        { pmbCode: 2, ICD10Code: 'F11.0', description: 'Mental and behavioural disorders due to use of opioids, acute intoxication' },
        { pmbCode: 2, ICD10Code: 'F11.3', description: 'Mental and behavioural disorders due to use of opioids, withdrawal state' },
        { pmbCode: 2, ICD10Code: 'F11.4', description: 'Mental and behavioural disorders due to use of opioids, withdrawal state with delirium' },
        { pmbCode: 2, ICD10Code: 'F11.5', description: 'Mental and behavioural disorders due to use of opioids, psychotic disorder' },
        { pmbCode: 2, ICD10Code: 'F11.6', description: 'Mental and behavioural disorders due to use of opioids, amnesic syndrome' },
        { pmbCode: 2, ICD10Code: 'F11.7', description: 'Mental and behavioural disorders due to use of opioids, residual and late-onset psychotic disorder' },
        { pmbCode: 2, ICD10Code: 'F11.8', description: 'Mental and behavioural disorders due to use of opioids, other mental and behavioural disorders' },
        { pmbCode: 2, ICD10Code: 'F11.9', description: 'Mental and behavioural disorders due to use of opioids, unspecified mental and behavioural disorder' },
        { pmbCode: 2, ICD10Code: 'F12.0', description: 'Mental and behavioural disorders due to use of cannabinoids, acute intoxication' },
        { pmbCode: 2, ICD10Code: 'F12.3', description: 'Mental and behavioural disorders due to use of cannabinoids, withdrawal state' },
        { pmbCode: 2, ICD10Code: 'F12.4', description: 'Mental and behavioural disorders due to use of cannabinoids, withdrawal state with delirium' },
        { pmbCode: 2, ICD10Code: 'F12.5', description: 'Mental and behavioural disorders due to use of cannabinoids, psychotic disorder' },
        { pmbCode: 2, ICD10Code: 'F12.6', description: 'Mental and behavioural disorders due to use of cannabionoids, amnesic syndrome' },
        { pmbCode: 2, ICD10Code: 'F12.7', description: 'Mental and behavioural disorders due to use of cannabinoids, residual and late-onset psychotic disorder' },
        { pmbCode: 2, ICD10Code: 'F12.8', description: 'Mental and behavioural disorders due to use of cannabinoids, other mental and behavioural disorders' },
        { pmbCode: 2, ICD10Code: 'F12.9', description: 'Mental and behavioural disorders due to use of cannabinoids, unspecified mental and behavioural disorder' },
        { pmbCode: 2, ICD10Code: 'F13.0', description: 'Mental and behavioural disorders due to use of sedatives or hypnotics, acute intoxication' },
        { pmbCode: 2, ICD10Code: 'F13.3', description: 'Mental and behavioural disorders due to use of sedatives or hypnotics, withdrawal state' },
        { pmbCode: 2, ICD10Code: 'F13.4', description: 'Mental and behavioural disorders due to use of sedatives or hypnotics, withdrawal state with delirium' },
        { pmbCode: 2, ICD10Code: 'F13.5', description: 'Mental and behavioural disorders due to use of sedatives or hypnotics, psychotic disorder' },
        { pmbCode: 2, ICD10Code: 'F13.6', description: 'Mental and behavioural disorders due to use of sedatives or hypnotics, amnesic syndrome' },
        { pmbCode: 2, ICD10Code: 'F13.7', description: 'Mental and behavioural disorders due to use of sedatives or hypnotics, residual and late-onset psychotic disorder' },
        { pmbCode: 2, ICD10Code: 'F13.8', description: 'Mental and behavioural disorders due to use of sedatives or hypnotics, other mental and behavioural disorders' },
        { pmbCode: 2, ICD10Code: 'F13.9', description: 'Mental and behavioural disorders due to use of sedatives or hypnotics, unspecified mental and behavioural disorder' },
        { pmbCode: 2, ICD10Code: 'F14.0', description: 'Mental and behavioural disorders due to use of cocaine, acute intoxication' },
        { pmbCode: 2, ICD10Code: 'F14.3', description: 'Mental and behavioural disorders due to use of cocaine, withdrawal state' },
        { pmbCode: 2, ICD10Code: 'F14.4', description: 'Mental and behavioural disorders due to use of cocaine, withdrawal state with delirium' },
        { pmbCode: 2, ICD10Code: 'F14.5', description: 'Mental and behavioural disorders due to use of cocaine, psychotic disorder' },
        { pmbCode: 2, ICD10Code: 'F14.6', description: 'Mental and behavioural disorders due to use of cocaine, amnesic syndrome' },
        { pmbCode: 2, ICD10Code: 'F14.7', description: 'Mental and behavioural disorders due to use of cocaine, residual and late-onset psychotic disorder' },
        { pmbCode: 2, ICD10Code: 'F14.8', description: 'Mental and behavioural disorders due to use of cocaine, other mental and behavioural disorders' },
        { pmbCode: 2, ICD10Code: 'F14.9', description: 'Mental and behavioural disorders due to use of cocaine, unspecified mental and behavioural disorder' },
        { pmbCode: 2, ICD10Code: 'F15.0', description: 'Mental and behavioural disorders due to use of other stimulants including caffeine, acute intoxication' },
        { pmbCode: 2, ICD10Code: 'F15.3', description: 'Mental and behavioural disorders due to use of other stimulants including caffeine, withdrawal state' },
        { pmbCode: 2, ICD10Code: 'F15.4', description: 'Mental and behavioural disorders due to use of other stimulants including caffeine, withdrawal state with delirium' },
        { pmbCode: 2, ICD10Code: 'F15.5', description: 'Mental and behavioural disorders due to use of other stimulants including caffeine, psychotic disorder' },
        { pmbCode: 2, ICD10Code: 'F15.6', description: 'Mental and behavioural disorders due to use of other stimulants including caffeine, amnesic syndrome' },
        { pmbCode: 2, ICD10Code: 'F15.7', description: 'Mental and behavioural disorders due to use of other stimulants, including caffeine, residual and late-onset psychotic disorder' },
        { pmbCode: 2, ICD10Code: 'F15.8', description: 'Mental and behavioural disorders due to use of other stimulants, including caffeine, other mental and behavioural disorders' },
        { pmbCode: 2, ICD10Code: 'F15.9', description: 'Mental and behavioural disorders due to use of other stimulants including caffeine, unspecified mental and behavioural disorder' },
        { pmbCode: 2, ICD10Code: 'F16.0', description: 'Mental and behavioural disorders due to use of hallucinogens, acute intoxication' },
        { pmbCode: 2, ICD10Code: 'F16.3', description: 'Mental and behavioural disorders due to use of hallucinogens, withdrawal state' },
        { pmbCode: 2, ICD10Code: 'F16.4', description: 'Mental and behavioural disorders due to use of hallucinogens, withdrawal state with delirium' },
        { pmbCode: 2, ICD10Code: 'F16.5', description: 'Mental and behavioural disorders due to use of hallucinogens, psychotic disorder' },
        { pmbCode: 2, ICD10Code: 'F16.6', description: 'Mental and behavioural disorders due to use of hallucinogens, amnesic syndrome' },
        { pmbCode: 2, ICD10Code: 'F16.7', description: 'Mental and behavioural disorders due to use of hallucinogens, residual and late-onset psychotic disorder' },
        { pmbCode: 2, ICD10Code: 'F16.8', description: 'Mental and behavioural disorders due to use of hallucinogens, other mental and behavioural disorders' },
        { pmbCode: 2, ICD10Code: 'F16.9', description: 'Mental and behavioural disorders due to use of hallucinogens, unspecified mental and behavioural disorder' },
        { pmbCode: 2, ICD10Code: 'F17.0', description: 'Mental and behavioural disorders due to use of tobacco, acute intoxication' },
        { pmbCode: 2, ICD10Code: 'F17.1', description: 'Mental and behavioural disorders due to use of tobacco, harmful use' },
        { pmbCode: 2, ICD10Code: 'F17.2', description: 'Mental and behavioural disorders due to use of tobacco, dependence syndrome' },
        { pmbCode: 2, ICD10Code: 'F17.3', description: 'Mental and behavioural disorders due to use of tobacco, withdrawal state' },
        { pmbCode: 2, ICD10Code: 'F17.4', description: 'Mental and behavioural disorders due to use of tobacco, withdrawal state with delirium' },
        { pmbCode: 2, ICD10Code: 'F17.6', description: 'Mental and behavioural disorders due to use of tobacco, amnesic syndrome' },
        { pmbCode: 2, ICD10Code: 'F17.7', description: 'Mental and behavioural disorders due to use of tobacco, residual and late-onset psychotic disorder' },
        { pmbCode: 2, ICD10Code: 'F17.8', description: 'Mental and behavioural disorders due to use of tobacco, other mental and behavioural disorders' },
        { pmbCode: 2, ICD10Code: 'F17.9', description: 'Mental and behavioural disorders due to use of tobacco, unspecified mental and behavioural disorder' },
        { pmbCode: 2, ICD10Code: 'F18.0', description: 'Mental and behavioural disorders due to use of volatile solvents, acute intoxication' },
        { pmbCode: 2, ICD10Code: 'F18.3', description: 'Mental and behavioural disorders due to use of volatile solvents, withdrawal state' },
        { pmbCode: 2, ICD10Code: 'F18.4', description: 'Mental and behavioural disorders due to use of volatile solvents, withdrawal state with delirium' },
        { pmbCode: 2, ICD10Code: 'F18.5', description: 'Mental and behavioural disorders due to use of volatile solvents, psychotic disorder' },
        { pmbCode: 2, ICD10Code: 'F18.6', description: 'Mental and behavioural disorders due to use of volatile solvents, amnesic syndrome' },
        { pmbCode: 2, ICD10Code: 'F18.8', description: 'Mental and behavioural disorders due to use of volatile solvents, other mental and behavioural disorders' },
        { pmbCode: 2, ICD10Code: 'F18.9', description: 'Mental and behavioural disorders due to use of volatile solvents, unspecified mental and behavioural disorder' },
        { pmbCode: 2, ICD10Code: 'F19.0', description: 'Mental and behavioural disorders due to multiple drug use and use of psychoactive substances, acute intoxication' },
        { pmbCode: 2, ICD10Code: 'F19.3', description: 'Mental and behavioural disorders due to multiple drug use and use of psychoactive substances, withdrawal state' },
        { pmbCode: 2, ICD10Code: 'F19.4', description: 'Mental and behavioural disorders due to multiple drug sue and use of psychoactive substances, withdrawal state with delirium' },
        { pmbCode: 2, ICD10Code: 'F19.5', description: 'Mental and behavioural disorders due to multiple drug use and use of psychoactive substances, psychotic disorder' },
        { pmbCode: 2, ICD10Code: 'F19.6', description: 'Mental and behavioural disorders due to multiple drug use and use of psychoactive substances, amnesic syndrome' },
        { pmbCode: 2, ICD10Code: 'F19.8', description: 'Mental and behavioural disorders due to multiple drug use and use of psychoactive substances, other mental and behavioural disorders' },
        { pmbCode: 2, ICD10Code: 'F19.9', description: 'Mental and behavioural disorders due to multiple drug use and use of psychoactive substances, unspecified mental and behavioural disorder' },
        { pmbCode: 2, ICD10Code: 'F24', description: 'Induced delusional disorder' },
        { pmbCode: 3, ICD10Code: 'F43.0', description: 'Acute stress reaction' },
        { pmbCode: 4, ICD10Code: 'F43.8', description: 'Other reactions to severe stress' },
        { pmbCode: 4, ICD10Code: 'F43.9', description: 'Reaction to severe stress, unspecified' },
        { pmbCode: 4, ICD10Code: 'T74.1', description: 'Physical abuse' },
        { pmbCode: 4, ICD10Code: 'T74.2', description: 'Sexual abuse' },
        { pmbCode: 4, ICD10Code: 'F50.0', description: 'Anorexia nervosa' },
        { pmbCode: 4, ICD10Code: 'F50.1', description: 'Atypical anorexia nervosa' },
        { pmbCode: 4, ICD10Code: 'F50.2', description: 'Bulimia nervosa' },
        { pmbCode: 4, ICD10Code: 'F50.3', description: 'Atypical bulimia nervosa' },
        { pmbCode: 5, ICD10Code: 'F10.5', description: 'Mental and behavioural disorders due to use of alcohol, psychotic disorder' },
        { pmbCode: 5, ICD10Code: 'F11.5', description: 'Mental and behavioural disorders due to use of opioids, psychotic disorder' },
        { pmbCode: 5, ICD10Code: 'F12.5', description: 'Mental and behavioural disorders due to use of cannabinoids, psychotic disorder' },
        { pmbCode: 5, ICD10Code: 'F13.5', description: 'Mental and behavioural disorders due to use of sedatives or hypnotics, psychotic disorder' },
        { pmbCode: 5, ICD10Code: 'F14.5', description: 'Mental and behavioural disorders due to use of cocaine, psychotic disorder' },
        { pmbCode: 5, ICD10Code: 'F15.5', description: 'Mental and behavioural disorders due to use of other stimulants including caffeine, psychotic disorder' },
        { pmbCode: 5, ICD10Code: 'F16.5', description: 'Mental and behavioural disorders due to use of hallucinogens, psychotic disorder' },
        { pmbCode: 5, ICD10Code: 'F17.5', description: 'Mental and behavioural disorders due to use of tobacco, psychotic disorder' },
        { pmbCode: 5, ICD10Code: 'F17.7', description: 'Mental and behavioural disorders due to use of tobacco, residual and late-onset psychotic disorder' },
        { pmbCode: 5, ICD10Code: 'F18.5', description: 'Mental and behavioural disorders due to use of volatile solvents, psychotic disorder' },
        { pmbCode: 5, ICD10Code: 'F18.7', description: 'Mental and behavioural disorders due to use of volatile solvents, residual and late-onset psychotic disorder' },
        { pmbCode: 5, ICD10Code: 'F19.5', description: 'Mental and behavioural disorders due to multiple drug use and use of psychoactive substances, psychotic disorder' },
        { pmbCode: 5, ICD10Code: 'F19.7', description: 'Mental and behavioural disorders due to multiple drug use and use of other psychoactive substances, residual and late-onset other psychotic disorder' },
        { pmbCode: 5, ICD10Code: 'F23.0', description: 'Acute polymorphic psychotic disorder without symptoms of schizophrenia' },
        { pmbCode: 5, ICD10Code: 'F23.1', description: 'Acute polymorphic psychotic disorder with symptoms of schizophrenia' },
        { pmbCode: 5, ICD10Code: 'F23.2', description: 'Acute schizophrenia-like psychotic disorder' },
        { pmbCode: 5, ICD10Code: 'F23.8', description: 'Other acute and transient psychotic disorders' },
        { pmbCode: 5, ICD10Code: 'F23.9', description: 'Acute and transient psychotic disorder, unspecified' },
        { pmbCode: 5, ICD10Code: 'R44.0', description: 'Auditory hallucinations' },
        { pmbCode: 5, ICD10Code: 'R44.1', description: 'Visual hallucinations' },
        { pmbCode: 5, ICD10Code: 'R44.2', description: 'Other hallucinations' },
        { pmbCode: 5, ICD10Code: 'R44.3', description: 'Hallucinations, unspecified' },
        { pmbCode: 6, ICD10Code: 'F20.4', description: 'Post-schizophrenic depression' },
        { pmbCode: 6, ICD10Code: 'F25.0', description: 'Schizoaffective disorder, manic type' },
        { pmbCode: 6, ICD10Code: 'F25.1', description: 'Schizoaffective disorder, depressive type' },
        { pmbCode: 6, ICD10Code: 'F25.2', description: 'Schizoaffective disorder, mixed type' },
        { pmbCode: 6, ICD10Code: 'F25.8', description: 'Other schizoaffective disorders' },
        { pmbCode: 6, ICD10Code: 'F25.9', description: 'Schizoaffective disorder, unspecified' },
        { pmbCode: 6, ICD10Code: 'F30.1', description: 'Mania without psychotic symptoms' },
        { pmbCode: 6, ICD10Code: 'F30.2', description: 'Mania with psychotic symptoms' },
        { pmbCode: 6, ICD10Code: 'F31.0', description: 'Bipolar affective disorder, current episode hypomanic' },
        { pmbCode: 6, ICD10Code: 'F31.1', description: 'Bipolar affective disorder, current episode manic without psychotic symptoms' },
        { pmbCode: 6, ICD10Code: 'F31.2', description: 'Bipolar affective disorder, current episode manic with psychotic symptoms' },
        { pmbCode: 6, ICD10Code: 'F31.3', description: 'Bipolar affective disorder, current episode mild or moderate depression' },
        { pmbCode: 6, ICD10Code: 'F31.4', description: 'Bipolar affective disorder, current episode severe depression without psychotic symptoms' },
        { pmbCode: 6, ICD10Code: 'F31.5', description: 'Bipolar affective disorder, current episode severe depression with psychotic symptoms' },
        { pmbCode: 6, ICD10Code: 'F31.6', description: 'Bipolar affective disorder, current episode mixed' },
        { pmbCode: 6, ICD10Code: 'F31.7', description: 'Bipolar affective disorder, currently in remission' },
        { pmbCode: 6, ICD10Code: 'F31.8', description: 'Other bipolar affective disorders' },
        { pmbCode: 6, ICD10Code: 'F31.9', description: 'Bipolar affective disorder, unspecified' },
        { pmbCode: 6, ICD10Code: 'F32.2', description: 'Severe depressive episode without psychotic symptoms' },
        { pmbCode: 6, ICD10Code: 'F32.3', description: 'Severe depressive episode with psychotic symptoms' },
        { pmbCode: 6, ICD10Code: 'F32.8', description: 'Other depressive episodes' },
        { pmbCode: 6, ICD10Code: 'F32.9', description: 'Depressive episode, unspecified' },
        { pmbCode: 6, ICD10Code: 'F33.1', description: 'Recurrent depressive disorder, current episode moderate' },
        { pmbCode: 6, ICD10Code: 'F33.2', description: 'Recurrent depressive disorder, current episode severe without psychotic symptoms' },
        { pmbCode: 6, ICD10Code: 'F33.3', description: 'Recurrent depressive disorder, current episode severe with psychotic symptoms' },
        { pmbCode: 6, ICD10Code: 'F33.4', description: 'Recurrent depressive disorder, currently in remission' },
        { pmbCode: 6, ICD10Code: 'F33.8', description: 'Other recurrent depressive disorders' },
        { pmbCode: 6, ICD10Code: 'F33.9', description: 'Recurrent depressive disorder, unspecified' },
        { pmbCode: 6, ICD10Code: 'F53.1', description: 'Severe mental and behavioural disorders associated with the puerperium, not elsewhere classified' },
        { pmbCode: 6, ICD10Code: 'F53.8', description: 'Other mental and behavioural disorders associated with the puerperium, not elsewhere classified' },
        { pmbCode: 6, ICD10Code: 'F53.9', description: 'Puerperal mental disorder, unspecified' },
        { pmbCode: 7, ICD10Code: 'F20.0', description: 'Paranoid schizophrenia' },
        { pmbCode: 7, ICD10Code: 'F20.1', description: 'Hebephrenic schizophrenia' },
        { pmbCode: 7, ICD10Code: 'F20.2', description: 'Catatonic schizophrenia' },
        { pmbCode: 7, ICD10Code: 'F20.3', description: 'Undifferentiated schizophrenia' },
        { pmbCode: 7, ICD10Code: 'F20.4', description: 'Post-schizophrenic depression' },
        { pmbCode: 7, ICD10Code: 'F20.5', description: 'Residual schizophrenia' },
        { pmbCode: 7, ICD10Code: 'F20.6', description: 'Simple schizophrenia' },
        { pmbCode: 7, ICD10Code: 'F20.8', description: 'Other schizophrenia' },
        { pmbCode: 7, ICD10Code: 'F20.9', description: 'Schizophrenia, unspecified' },
        { pmbCode: 7, ICD10Code: 'F22.0', description: 'Delusional disorder' },
        { pmbCode: 7, ICD10Code: 'F22.8', description: 'Other persistent delusional disorders' },
        { pmbCode: 7, ICD10Code: 'F22.9', description: 'Persistent delusional disorder, unspecified' },
        { pmbCode: 7, ICD10Code: 'F23.1', description: 'Acute polymorphic psychotic disorder with symptoms of schizophrenia' },
        { pmbCode: 7, ICD10Code: 'F23.2', description: 'Acute schizophrenia-like psychotic disorder' },
        { pmbCode: 7, ICD10Code: 'F23.3', description: 'Other acute predominantly delusional psychotic disorders' },
        { pmbCode: 7, ICD10Code: 'F25.0', description: 'Schizoaffective disorder, manic type' },
        { pmbCode: 7, ICD10Code: 'F25.1', description: 'Schizoaffective disorder, depressive type' },
        { pmbCode: 7, ICD10Code: 'F25.2', description: 'Schizoaffective disorder, mixed type' },
        { pmbCode: 7, ICD10Code: 'F25.8', description: 'Other schizoaffective disorders' },
        { pmbCode: 7, ICD10Code: 'F25.9', description: 'Schizoaffective disorder, unspecified' },
        { pmbCode: 7, ICD10Code: 'F28', description: 'Other nonorganic psychotic disorders' },
        { pmbCode: 7, ICD10Code: 'F29', description: 'Unspecified nonorganic psychosis' },
        { pmbCode: 8, ICD10Code: 'B22.0 + F02.4', description: 'HIV disease resulting in encephalopathy/Dementia in human immunodeficiency virus [HIV] disease (B22.0+)' },
        { pmbCode: 8, ICD10Code: 'E52 + F02.8', description: 'Niacin deficiency [pellagra] / Dementia in other specified diseases classified elsewhere' },
        { pmbCode: 8, ICD10Code: 'E53.8 + F02.8', description: 'Deficiency of other specified B group vitamins / Dementia in other specified diseases classified elsewhere' },
        { pmbCode: 8, ICD10Code: 'E83.3 + F02.8', description: 'Disorders of phosphorus metabolism and phosphatases/Dementia in other specified diseases classified elsewhere' },
        { pmbCode: 8, ICD10Code: 'E83.5 + F02.8', description: 'Disorders of calcium metabolism/Dementia in other specified diseases classified elsewhere' },
        { pmbCode: 8, ICD10Code: 'M32.1 + F02.8', description: 'Systemic lupus erythematosus with organ or system involvement/Dementia in other specified diseases classified elsewhere' },
        { pmbCode: 8, ICD10Code: 'F01.0', description: 'Vascular dementia of acute onset' },
        { pmbCode: 8, ICD10Code: 'F01.1', description: 'Multi-infarct dementia' },
        { pmbCode: 8, ICD10Code: 'F01.2', description: 'Subcortical vascular dementia' },
        { pmbCode: 8, ICD10Code: 'F01.3', description: 'Mixed cortical and subcortical vascular dementia' },
        { pmbCode: 8, ICD10Code: 'F01.8', description: 'Other vascular dementia' },
        { pmbCode: 8, ICD10Code: 'F01.9', description: 'Vascular dementia, unspecified' },
        { pmbCode: 8, ICD10Code: 'F02.0', description: 'Dementia in Picks disease (G31.0+)' },
        { pmbCode: 8, ICD10Code: 'F02.1', description: 'Dementia in Creutzfeldt-Jakob disease (A81.0+)' },
        { pmbCode: 8, ICD10Code: 'F02.2', description: 'Dementia in Huntingtons disease (G10+)' },
        { pmbCode: 8, ICD10Code: 'F02.3', description: 'Dementia in Parkinsons disease (G20+)' },
        { pmbCode: 8, ICD10Code: 'F02.4', description: 'Dementia in human immunodeficiency virus [HIV] disease (B22.0+)' },
        { pmbCode: 8, ICD10Code: 'F02.8', description: 'Dementia in other specified diseases classified elsewhere' },
        { pmbCode: 8, ICD10Code: 'F06.8', description: 'Other specified mental disorders due to brain damage and dysfunction and to physical disease' },
        { pmbCode: 9, ICD10Code: 'G20', description: 'Parkinsons disease' },
        { pmbCode: 9, ICD10Code: 'G21.1', description: 'Other drug-induced secondary parkinsonism' },
        { pmbCode: 9, ICD10Code: 'G21.2', description: 'Secondary parkinsonism due to other external agents' },
        { pmbCode: 9, ICD10Code: 'G21.3', description: 'Postencephalitic parkinsonism' },
        { pmbCode: 9, ICD10Code: 'G21.4', description: 'Vascular parkinsonism ' },
        { pmbCode: 9, ICD10Code: 'G21.8', description: 'Other secondary parkinsonism' },
        { pmbCode: 9, ICD10Code: 'G21.9', description: 'Secondary parkinsonism, unspecified' },
        { pmbCode: 9, ICD10Code: 'G22', description: 'Parkinsonism in diseases classified elsewhere' },
        { pmbCode: 9, ICD10Code: 'G23.0', description: 'Hallervorden-Spatz disease' },
        { pmbCode: 9, ICD10Code: 'G23.1', description: 'Progressive supranuclear ophthalmoplegia [Steele-Richardson-Olszewski]' },
        { pmbCode: 9, ICD10Code: 'G23.2', description: 'Striatonigral degeneration' },
        { pmbCode: 9, ICD10Code: 'G23.8', description: 'Other specified degenerative diseases of basal ganglia' },
        { pmbCode: 9, ICD10Code: 'G23.9', description: 'Degenerative disease of basal ganglia, unspecified' },
        { pmbCode: 9, ICD10Code: 'G24.0', description: 'Drug-induced dystonia' },
        { pmbCode: 9, ICD10Code: 'G24.1', description: 'Idiopathic familial dystonia' },
        { pmbCode: 9, ICD10Code: 'G24.2', description: 'Idiopathic nonfamilial dystonia' },
        { pmbCode: 9, ICD10Code: 'G24.3', description: 'Spasmodic torticollis' },
        { pmbCode: 9, ICD10Code: 'G24.4', description: 'Idiopathic orofacial dystonia' },
        { pmbCode: 9, ICD10Code: 'G24.8', description: 'Other dystonia' },
        { pmbCode: 9, ICD10Code: 'G24.9', description: 'Dystonia unspecified' },
        { pmbCode: 9, ICD10Code: 'G25.0', description: 'Essential tremor' },
        { pmbCode: 9, ICD10Code: 'G25.1', description: 'Drug-induced tremor' },
        { pmbCode: 9, ICD10Code: 'G25.2', description: 'Other specified forms of tremor' },
        { pmbCode: 9, ICD10Code: 'G25.3', description: 'Myoclonus' },
        { pmbCode: 9, ICD10Code: 'G25.4', description: 'Drug-induced chorea' },
        { pmbCode: 9, ICD10Code: 'G25.5', description: 'Other chorea' },
        { pmbCode: 9, ICD10Code: 'G25.6', description: 'Drug-induced tics and other tics of organic origin' },
        { pmbCode: 9, ICD10Code: 'G25.8', description: 'Other extrapyramidal and movement disorders - Other specified extrapyramidal and movement disorders' },
        { pmbCode: 9, ICD10Code: 'G25.9', description: 'Extrapyramidal and movement disorder unspecified' },
        { pmbCode: 9, ICD10Code: 'G26', description: 'Extrapyramidal and movement disorders in diseases classified elsewhere' },
        { pmbCode: 10, ICD10Code: 'G40.0', description: 'Localization-related (focal)(partial) idiopathic epilepsy and epileptic syndromes with seizures of localized onset' },
        { pmbCode: 10, ICD10Code: 'G40.0 + F02.8', description: 'Localization-related (focal)(partial) idiopathic epilepsy and epileptic syndromes with seizures of localized onset/Dementia in other specified diseases classified elsewhere' },
        { pmbCode: 10, ICD10Code: 'G40.1', description: 'Localization-related (focal)(partial) symptomatic epilepsy and epileptic syndromes with simple partial seizures' },
        { pmbCode: 10, ICD10Code: 'G40.1 + F02.8', description: 'Localization-related (focal)(partial) symptomatic epilepsy and epileptic syndromes with simple partial seizures/Dementia in other specified diseases classified elsewhere' },
        { pmbCode: 10, ICD10Code: 'G40.2', description: 'Localization-related (focal)(partial) symptomatic epilepsy and epileptic syndromes with complex partial seizures' },
        { pmbCode: 10, ICD10Code: 'G40.2 + F02.8', description: 'Localization-related (focal)(partial) symptomatic epilepsy and epileptic syndromes with complex partial seizures/Dementia in other specified diseases classified elsewhere' },
        { pmbCode: 10, ICD10Code: 'G40.3', description: 'Generalized idiopathic epilepsy and epileptic syndromes' },
        { pmbCode: 10, ICD10Code: 'G40.3 + F02.8', description: 'Generalized idiopathic epilepsy and epileptic syndromes/Dementia in other specified diseases classified elsewhere' },
        { pmbCode: 10, ICD10Code: 'G40.4', description: 'Other generalized epilepsy and epileptic syndromes' },
        { pmbCode: 10, ICD10Code: 'G40.4 + F02.8', description: 'Other generalized epilepsy and epileptic syndromes/Dementia in other specified diseases classified elsewhere' },
        { pmbCode: 10, ICD10Code: 'G40.5', description: 'Special epileptic syndromes' },
        { pmbCode: 10, ICD10Code: 'G40.5 + F02.8', description: 'Special epileptic syndromes/Dementia in other specified diseases classified elsewhere' },
        { pmbCode: 10, ICD10Code: 'G40.6', description: 'Grand mal seizures, unspecified (with or without petit mal)' },
        { pmbCode: 10, ICD10Code: 'G40.6 + F02.8', description: 'Grand mal seizures, unspecified (with or without petit mal)/Dementia in other specified diseases classified elsewhere' },
        { pmbCode: 10, ICD10Code: 'G40.7', description: 'Petit mal, unspecified, without grand mal seizures' },
        { pmbCode: 10, ICD10Code: 'G40.7 + F02.8', description: 'Petit mal, unspecified, without grand mal seizures/Dementia in other specified diseases classified elsewhere' },
        { pmbCode: 10, ICD10Code: 'G40.8', description: 'Other epilepsy' },
        { pmbCode: 10, ICD10Code: 'G40.8 + F02.8', description: 'Other epilepsy/Dementia in other specified diseases classified elsewhere' },
        { pmbCode: 10, ICD10Code: 'G40.9', description: 'Epilepsy, unspecified' },
        { pmbCode: 10, ICD10Code: 'G40.9 + F02.8', description: 'Epilepsy, unspecified/Dementia in other specified diseases classified elsewhere' },
        { pmbCode: 10, ICD10Code: 'G41.0', description: 'Grand mal status epilepticus' },
        { pmbCode: 10, ICD10Code: 'G41.1', description: 'Petit mal status epilepticus' },
        { pmbCode: 10, ICD10Code: 'G41.2', description: 'Complex partial status epilepticus' },
        { pmbCode: 10, ICD10Code: 'G41.8', description: 'Other status epilepticus' },
        { pmbCode: 10, ICD10Code: 'G41.9', description: 'Status epilepticus, unspecified' },
        { pmbCode: 11, ICD10Code: 'F10.3', description: 'Mental and behavioural disorders due to use of alcohol, withdrawal state' },
        { pmbCode: 11, ICD10Code: 'F10.4', description: 'Mental and behavioural disorders due to use of alcohol, withdrawal state with delirium' },
        { pmbCode: 11, ICD10Code: 'F10.5', description: 'Mental and behavioural disorders due to use of alcohol, psychotic disorder' },
        { pmbCode: 12, ICD10Code: 'F11.4', description: 'Mental and behavioural disorders due to use of opioids, withdrawal state with delirium' },
        { pmbCode: 12, ICD10Code: 'F11.5', description: 'Mental and behavioural disorders due to use of opioids, psychotic disorder' },
        { pmbCode: 12, ICD10Code: 'F12.4', description: 'Mental and behavioural disorders due to use of cannabinoids, withdrawal state with delirium' },
        { pmbCode: 12, ICD10Code: 'F12.5', description: 'Mental and behavioural disorders due to use of cannabinoids, psychotic disorder' },
        { pmbCode: 12, ICD10Code: 'F13.4', description: 'Mental and behavioural disorders due to use of sedatives or hypnotics, withdrawal state with delirium' },
        { pmbCode: 12, ICD10Code: 'F13.5', description: 'Mental and behavioural disorders due to use of sedatives or hypnotics, psychotic disorder' },
        { pmbCode: 12, ICD10Code: 'F14.4', description: 'Mental and behavioural disorders due to use of cocaine, withdrawal state with delirium' },
        { pmbCode: 12, ICD10Code: 'F14.5', description: 'Mental and behavioural disorders due to use of cocaine, psychotic disorder' },
        { pmbCode: 12, ICD10Code: 'F15.4', description: 'Mental and behavioural disorders due to use of other stimulants including caffeine, withdrawal state with delirium' },
        { pmbCode: 12, ICD10Code: 'F15.5', description: 'Mental and behavioural disorders due to use of other stimulants including caffeine, psychotic disorder' },
        { pmbCode: 12, ICD10Code: 'F16.4', description: 'Mental and behavioural disorders due to use of hallucinogens, withdrawal state with delirium' },
        { pmbCode: 12, ICD10Code: 'F16.5', description: 'Mental and behavioural disorders due to use of hallucinogens, psychotic disorder' },
        { pmbCode: 12, ICD10Code: 'F17.4', description: 'Mental and behavioural disorders due to use of tobacco, withdrawal state with delirium' },
        { pmbCode: 12, ICD10Code: 'F18.4', description: 'Mental and behavioural disorders due to use of volatile solvents, withdrawal state with delirium' },
        { pmbCode: 12, ICD10Code: 'F18.5', description: 'Mental and behavioural disorders due to use of volatile solvents, psychotic disorder' },
        { pmbCode: 12, ICD10Code: 'F19.4', description: 'Mental and behavioural disorders due to multiple drug sue and use of psychoactive substances, withdrawal state with delirium' },
        { pmbCode: 12, ICD10Code: 'F19.5', description: 'Mental and behavioural disorders due to multiple drug use and use of psychoactive substances, psychotic disorder' },
    ]
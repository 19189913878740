import React, { useContext, useEffect, useState } from 'react'
import CalendarContext from '../CalendarProvider'

export default function CalendarEventCreate({ calendarType, activeAction, setActiveAction }) {
  // const [activeAction, setActiveAction] = useState()  const [options, setOptions] = useState([])
  const [options, setOptions] = useState([])
  const { calendarOptions } = useContext(CalendarContext)

  const _findActionsToView = async () => {
    const res = await calendarOptions()

    setOptions(res.filter((f) => (f.whenTriggered.trigger === 'CREATE_CALENDAR_EVENT' && f.whenTriggered.trigger === 'CLONE_CALENDAR_EVENT')
      && f.availableCalendars.filter((d) => d.calendarType.type === calendarType)
    ))
    // return await calendarOptions().filter((f) => (f.triggeredWhen.trigger !== 'CREATE_CALENDAR_EVENT' && f.triggeredWhen.trigger !== 'INITIATE_SEQUENCE') && f.availableCalendars && f.availableCalendars.includes(calendarType))

  }

  useEffect(() => { _findActionsToView() }, [])

  return (
    <div>
      {
        // activeAction
        //   ?
        //   <div className='bg-secondary-container p-2 m-2 rounded small'>
        //     <div className='d-flex mb-2'>
        //       <div className='flex-grow-1'>{activeAction.eventName}</div>
        //       <div><XCircleFill onClick={() => setActiveAction()} /></div>
        //     </div>
        //     <div className='d-flex justify-content-evenly align-items-center mb-2'>
        //       <div>From</div> <DatePicker small={true} />
        //       <div>Till</div> <DatePicker small={true} />
        //     </div>
        //     <div className='mb-2'>
        //       <lebel>Attendees</lebel>
        //       <MultiSelectUsersWidget />
        //     </div>
        //     <input className='form-control form-control-sm mb-2' placeholder='Sumary' />
        //     <textarea className='form-control form-control-sm mb-2' placeholder='Details'></textarea>
        //     <button className='btn'>Create</button>
        //   </div>
        //   :
        <div className='d-flex flex-column justify-content-start align-items-start p-2 rounded h-100 small d-flex w-100'>
          {
            // MockCalendarEvents.filter((f) => (f.triggeredWhen.trigger === 'CREATE_CALENDAR_EVENT' || f.triggeredWhen.trigger === 'CLONE_CALENDAR_EVENT') && f.availableCalendars && f.availableCalendars.includes(calendarType))
            options
              .map((e) =>
                <button onClick={() => setActiveAction(e)} className={'btn btn-xs text-start w-100 mb-1 '.concat(activeAction && activeAction.id === e.id ? 'fw-bold' : '')}>{e.eventName}</button>
              )
          }
        </div>
      }

    </div>
  )
}

import React from 'react';

export default function DatePickerDay({ onChange, onMonthClick, year, month, hideMonth, highlight }) {

    const Months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
    //return new Date(year, month, 0).getDate();
    const _generateDays = () => {
        const DaysInMonth = new Date(year, month + 1, 0).getDate();
        var rows = []
        var cols = []
        var c = 0;
        for (var i = 1; i <= DaysInMonth; i++, c++) {
            if (c % 5 === 0) {
                rows.push(<tr>{cols}</tr>)
                cols = []
            }
            const x = i
            cols.push(<td style={{ cursor: 'pointer' }} key={x} onClick={(e) => onChange(x)} className={'col-auto m-3 date-picker-items '.concat(highlight && highlight === x ? 'active' : '')}><div>{x}</div></td>)
        }
        rows.push(<tr>{cols}</tr>)
        return <table className='table'>{rows}</table>
    }
    return (
        <div>
            {
                !hideMonth
                    ?
                    <div style={{ cursor: 'pointer' }} onClick={(e) => onMonthClick()} className='text-center p-2 date-picker-head'>
                        {Months[month - 1]}
                    </div>
                    : <></>
            }

            {
                _generateDays()
            }
        </div>
    )
}

import React, { useState } from 'react'
import { Check2Square, Square } from 'react-bootstrap-icons'
import DateTimeFormatter from '../../../../../../../components/ui/calendar/date_picket/DateTimeFormatter'
import MessagesPlatformIcon from './MessagesPlatformIcon'

export default function MessagesLinkLineItem({ message, onLink, isActive }) {
    const [active, setActive] = useState(isActive)

    const _handleToggle = async (e) => {
        await onLink(message); setActive(!active)
    }

    return (
        <div className=' p-1 m-2 rounded small shadow p-1'>
            <div className='d-flex justify-content-between -1'>
                <MessagesPlatformIcon service={message.serviceType.type} />
                <div className='flex-grow-1'>{message.subject}</div>
                <div onClick={_handleToggle} >{active ? <Check2Square size={20} /> : <Square size={20} />}</div>
            </div>
            <div className='d-flex justify-content-start'>
                <div>From: {message.from}</div>
            </div>
            <div className='d-flex justify-content-start'>
                <div>To: {message.to}</div>
            </div>
            <div className='d-flex justify-content-between'>
                <div>Sent: {DateTimeFormatter.format(message.sentDate)}</div>
                <div>Received: {DateTimeFormatter.format(message.receviedDate)}</div>
            </div>
            {/* <pre>
                {JSON.stringify(message, '\t', 2)}
            </pre> */}
            <div style={{ cursor: 'pointer' }} className=''>
                Show Message
            </div>
        </div>
    )
}

import React, { useContext, useEffect, useRef, useState } from 'react';
import ErrorDialog from '../../../../../../components/dialogs/ErrorDialog';
import Authentication from '../../../../../../libs/api/services/Authentication';
import WorkFlowConfigurationContext from '../../WorkFlowConfigurationProvider';
import FolderComponent from './FolderComponent';

export default function MessageCategoryFolderComponent({ readonly }) {
    // Load Category info
    const { loadCategories, categories, addCategory, deleteCategory, renameCategory, selectCategory } = useContext(WorkFlowConfigurationContext)

    const searchRef = useRef()
    const [error, setError] = useState()
    const [activeResult, setActiveResult] = useState()
    const [searchResults, setSearchResults] = useState([])
    const options = {
        primaryField: 'name',
    }

    useEffect(() => { loadCategories() }, [])

    useEffect(() => {
        _filterResult()
    }, [categories])


    String.prototype.replaceJSX = function (find, replace) {
        return this.toLowerCase().split(find.toLowerCase()).flatMap((item) => [item, replace]).slice(0, -1);
    }

    const _filterResult = (search) => {
        var _results = []
        for (const res of categories) {
            const _tResName = res[options.primaryField]
            if (search && _tResName) {
                if (_tResName.toLowerCase() === search.toLowerCase()) {

                    _results.push({
                        result: _tResName.replaceJSX(search, <span className='bg-secondary-container rounded'>{search}</span>),
                        asText: _tResName,
                        exact: true,
                        object: res
                    })
                }
                else if (_tResName.toLowerCase().indexOf(search.toLowerCase()) > -1) {

                    _results.push({
                        result: _tResName.replaceJSX(search, <span className='bg-secondary-container rounded'>{search}</span>),
                        asText: _tResName,
                        exact: false,
                        object: res
                    })
                }
            } else {
                // All results
                _results.push({
                    result: <div className=''>{res[options.primaryField]}</div>,
                    asText: _tResName,
                    isNew: false,
                    object: res
                })
            }
        }
        if
            (
            !(_results.length === 1 && _results[0].exact) &&
            !readonly
        )
            _results.push({
                result: <div className=''>Create {search}</div>,
                asText: search ? search : '',
                isNew: true,
                object: { id: -1, facility: { id: Authentication.getFacility() } }
            })
        setSearchResults(_results)
    }

    const _handleSearchKeyEvents = (ev) => {
        switch (ev.keyCode) {
            case 13:
                if (searchResults.length === 1) {
                    if (searchResults[0].isNew)
                        addCategory(searchResults[0], searchResults[0].asText)
                            .then((r) => { try { if (r) setActiveResult({ ...activeResult, object: r }) } catch (e) { } })
                    else {
                        setActiveResult(searchResults[0])
                        selectCategory(searchResults[0])
                    }
                }
                return
            // return _handleOnSubmit()
            case 27:
                searchRef.current.value = ''
                return _filterResult();
            default: break;
        }
    }
    return (
        <div onClick={() => setActiveResult()} className='h-100'>
            <input onKeyDown={(e) => _handleSearchKeyEvents(e)} ref={searchRef} onChange={(e) => _filterResult(e.target.value)} className='form-control' placeholder='Search' />
            <div onClick={(e) => e.preventDefault()} style={{ cursor: 'pointer' }} className='row'>
                <ErrorDialog humanize={true} error={error} onClose={() => setError()} />
                {
                    searchResults.map((res) =>
                        <FolderComponent
                            key={`WFX-${res.object.id}`}
                            onCreate={addCategory}
                            onRename={renameCategory}
                            onDelete={deleteCategory}
                            onSelect={selectCategory}
                            active={activeResult && activeResult.object.id === res.object.id} onSelected={(res) => setActiveResult(res)} className={'col-3 d-flex flex-column align-items-center '} res={res} />
                    )
                }
            </div>
        </div>
    )
}

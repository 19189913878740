import React from 'react'
import DocumentEntries from './DocumentEntries'
import { DocumentsProvider } from './DocumentsProvider'

export default function WorkFlowDocumentContainer({ event, docs }) {
    return (
        <DocumentsProvider event={event} defaultValue={docs}>
            <DocumentEntries />
        </DocumentsProvider>
    )
}

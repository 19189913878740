import React, { useEffect, useState } from 'react'
import ToggleButton from '../../../../../../../../components/ui/toggle_button/ToggleButton'
import ExternalCalendarListComponent from '../../../../common/ExternalCalendarListComponent'

export default function SyncToCalendar({ evt, onSetProperty, properties }) {
    const [sync, setSync] = useState(false)
    useEffect(() => { onSetProperty('active', sync) }, [sync])
    return (
        <div>
            <div>
                <ToggleButton onToggle={setSync} style={'tick'}><div>Synchronize with another calendar?</div></ToggleButton>
            </div>
            {sync ? <ExternalCalendarListComponent
                multi={false}
                onSelected={(c) => onSetProperty('calendar', c)}
                selectable={true}
                hideInactive={true} /> : <></>}
        </div>
    )
}

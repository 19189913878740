import React, { useEffect } from 'react'
import { Clipboard2DataFill } from 'react-bootstrap-icons'

export default function FBuilderWelcome({ title, description, navigation, errors, setValue, values }) {

    useEffect(() => {
        title('Form Builder')
        description('Welcome to the form bulder')
        // Load Navigation if session started
        // if (Object.keys(values).includes('templateId'))
            navigation([
                { text: 'Begin', type: 'Next', action: async () => {  } },
            ])
    }, [])

    return (
        <div className='d-flex flex-column align-items-center m-3'>
            <Clipboard2DataFill size={80} />
            <p className='m-3'>The form builder allows you to create form templates to assign / capture patients information</p>
        </div>
    )
}

import { useState } from "react";

export default function CardOptions({ onChange, children, defaultValue, defaultValues, useCallBack }) {
    const [active, setActive] = useState(defaultValue);
    const handleChange = (value, cv) => {
        if (!useCallBack) setActive(value)
        if (onChange)
            onChange(value, cv)
    }

    const handleActive = (val) => {
        // alert(defaultValues)
        if (defaultValues)
            for (const items of defaultValues)
                if (items.id === val) return true
        return active === val
    }
    const childrenToCard = (children) => {
        let _ret = [];
        children.map((child) => {
            return _ret.push(<div
                value={child.props.value}
                key={child.key}
                onClick={(e) => handleChange(child.props.value, child.props.children)}
                className={handleActive(child.props.value) ? "col-4 p-3 border border-1 card-option active" : "col-4 p-3 border border-1 card-option"}>{child.props.children}</div>)
        })
        return _ret;
    }
    return (
        <div style={{ cursor: 'pointer' }} className="row card-options">
            {childrenToCard(children)}
        </div>
    )
}

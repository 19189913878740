import React, { useEffect, useState } from 'react'
import ToggleButton from '../../../../../../../../components/ui/toggle_button/ToggleButton'
import IntegrationHeaders from './IntegrationHeaders'
import ServiveProviderJSON from './ServiveProviderJSON'

export default function IntegrationAuthentication({ defaultValue, onChange }) {
    const [authDetails, setAuthDetails] = useState(defaultValue ? defaultValue :
        {
            authRequired: false,
            endPoint: '',
            epVerb: '',
            usesBearer: false,
            basicAuthKey: '',
            usesBasic: false,
            usesBasicB64: false,
            usesSessionToken: false,
            hasHeaders: false,
            sessionHeaders: [],
            sessionTokenHeaders: [],
            sessionTokenBodyRequest: undefined,
            sessionTokenBodyResponse: undefined,
            bearerTokenBodyRequest: undefined,
            bearerTokenBodyResponse: undefined
        })
    useEffect(() => { if (onChange) onChange(authDetails) }, [authDetails])
    return (
        <div className='text-start p-1'>
            <ToggleButton defaultValue={authDetails.authRequired} onToggle={(v) => setAuthDetails({ ...authDetails, authRequired: v })} style={'tick'}>Providers requires authentication</ToggleButton>
            {
                authDetails.authRequired
                    ?
                    <>
                        <div className='p-2'>
                            <input value={authDetails.endPoint} onChange={(e) => setAuthDetails({ ...authDetails, endPoint: e.target.value })} className='form-control form-control-sm' placeholder='Authentication endpoint full URL' />
                            <select
                                onChange={(e) => setAuthDetails({ ...authDetails, epVerb: e.target.value })}
                                className='form-control form-control-sm'>
                                <option value={''} selected={authDetails.epVerb === '' ? 'selected' : ''}>HTTP Verb</option>
                                <option value={'GET'} selected={authDetails.epVerb === 'GET' ? 'selected' : ''}>GET</option>
                                <option value={'POST'} selected={authDetails.epVerb === 'POST' ? 'selected' : ''}>POST</option>
                                <option value={'PUT'} selected={authDetails.epVerb === 'PUT' ? 'selected' : ''}>PUT</option>
                                <option value={'PATCH'} selected={authDetails.epVerb === 'PATCH' ? 'selected' : ''}>PATCH</option>
                                <option value={'DELETE'} selected={authDetails.epVerb === 'DELETE' ? 'selected' : ''}>DELETE</option>
                                <option value={'OPTIONS'} selected={authDetails.epVerb === 'OPTIONS' ? 'selected' : ''}>OPTIONS</option>
                                <option value={'TRACE'} selected={authDetails.epVerb === 'TRACE' ? 'selected' : ''}>TRACE</option>
                            </select>
                        </div>
                        <ToggleButton defaultValue={authDetails.usesBasic} onToggle={(v) => setAuthDetails({ ...authDetails, usesBasic: v })} style={'tick'}>Provider uses basic authentication tokens</ToggleButton>
                        {
                            authDetails.usesBasic
                                ?
                                <div className='p-2'>
                                    <input value={authDetails.basicAuthKey} onChange={(e) => setAuthDetails({ ...authDetails, basicAuthKey: e.target.value })} className='form-control form-control-sm' placeholder='Basic Authentication Key' />
                                    <ToggleButton defaultValue={authDetails.usesBasicB64} onToggle={(v) => setAuthDetails({ ...authDetails, usesBasicB64: v })} style={'tick'}>Encode to base64</ToggleButton>
                                </div>
                                :
                                <></>
                        }
                        <ToggleButton defaultValue={authDetails.usesBearer} onToggle={(v) => setAuthDetails({ ...authDetails, usesBearer: v })} style={'tick'}>Provider uses Bearer tokens</ToggleButton>
                        {
                            authDetails.usesBearer
                                ?
                                <div className='d-flex'>
                                    <div className='d-block w-50'>
                                        <div><b>JSON Request to obtain token</b></div>
                                        <ServiveProviderJSON defaultValue={authDetails.bearerTokenBodyRequest} onChange={(r) => setAuthDetails({ ...authDetails, bearerTokenBodyRequest: r })} />
                                    </div>
                                    <div className='d-block w-50'>
                                        <div><b>JSON Token Response</b></div>
                                        <ServiveProviderJSON defaultValue={authDetails.bearerTokenBodyResponse} onChange={(r) => setAuthDetails({ ...authDetails, bearerTokenBodyResponse: r })} />
                                    </div>
                                </div>
                                :
                                <></>
                        }
                        <ToggleButton defaultValue={authDetails.usesSessionToken} onToggle={(v) => setAuthDetails({ ...authDetails, usesSessionToken: v })} style={'tick'}>Provider uses Session tokens</ToggleButton>
                        {
                            authDetails.usesSessionToken
                                ?
                                <div className='p-2'>
                                    <ToggleButton defaultValue={authDetails.usesSessionTokenHeader} onToggle={(v) => setAuthDetails({ ...authDetails, usesSessionTokenHeader: v })} style={'tick'}>Use token in header</ToggleButton>
                                    {
                                        authDetails.usesSessionTokenHeader
                                            ?
                                            <IntegrationHeaders defaultValue={[...authDetails.sessionTokenHeaders, { name: '', value: '<"SESSION_TOKEN">' }]} onChange={(v) => setAuthDetails({ ...authDetails, sessionTokenHeaders: v })} />
                                            :
                                            <></>
                                    }
                                    <ToggleButton defaultValue={authDetails.usesSessionTokenBody} onToggle={(v) => setAuthDetails({ ...authDetails, usesSessionTokenBody: v })} style={'tick'}>Use token in request body</ToggleButton>
                                    {
                                        authDetails.usesSessionTokenBody
                                            ?
                                            <div className='bg-secondary-container p-1 rounded'>Please set the <b>SESSION_TOKEN</b> variable when setting up the request body</div>
                                            :
                                            <></>
                                    }
                                    <div className='d-flex'>
                                        <div className='d-block w-50'>
                                            <div><b>JSON Request to obtain token</b></div>
                                            <ServiveProviderJSON defaultValue={authDetails.sessionTokenBodyRequest} onChange={(r) => setAuthDetails({ ...authDetails, sessionTokenBodyRequest: r })} />
                                        </div>
                                        <div className='d-block w-50'>
                                            <div><b>JSON Token Response</b></div>
                                            <ServiveProviderJSON defaultValue={authDetails.sessionTokenBodyResponse} onChange={(r) => setAuthDetails({ ...authDetails, sessionTokenBodyResponse: r })} />
                                        </div>
                                    </div>
                                </div>
                                :
                                <></>
                        }
                        <ToggleButton onToggle={(v) => setAuthDetails({ ...authDetails, hasHeaders: v })} style={'tick'}>Set custom request headers</ToggleButton>
                        {
                            authDetails.hasHeaders
                                ?
                                <IntegrationHeaders defaultValue={authDetails.sessionHeaders} onChange={(v) => setAuthDetails({ ...authDetails, sessionHeaders: v })} />
                                :
                                <></>
                        }
                    </>
                    :
                    <></>
            }
        </div>
    )
}

import React from 'react';
import { Route, Routes } from 'react-router-dom';
import AppRoutes from '../../AppRoutes';

const SessionScopeContext = React.createContext()

export const SessionScopeProvider = ({ children }) => {
    return <SessionScopeContext.Provider value={{}}>
        {/* {children} */}
        <Routes>
            {AppRoutes.map((route, index) => {
                const { element, ...rest } = route;
                return <Route key={index} {...rest} element={element} />;
            })}
        </Routes>
    </SessionScopeContext.Provider>

}
export default SessionScopeContext
import React, { useEffect } from 'react';
import Authentication from './api/services/Authentication';
import MessagingService from './api/services/MessagingService';

export default function RedirectFragmentOAuth({ type }) {
    useEffect(() => {
        var location = '';
        if (window.location.toString().includes('#'))
            location = window.location.toString().split('#')
        if (window.location.toString().includes('?'))
            location = window.location.toString().split('?')
        var params = {}
        if (location.length > 0) {
            for (const entry of location[1].split('&')) {
                params[entry.split('=')[0]] = entry.split('=')[1]
            }
        }
        MessagingService
            .OAuthTokent(params, type)
            .then((r) => {
                switch (type) {
                    case 'Mail':
                        if (Authentication.getFacility())
                            window.location = '/' + Authentication.getFacilityRole() + '/Configuration/email/' + params.state;
                        else
                            window.location = '/sys-admin/messaging/email/' + params.state;

                        break;
                    case 'Calendar':
                        if (Authentication.getFacility())
                            window.location = '/' + Authentication.getFacilityRole() + '/Configuration/calendar/' + params.state;
                        else
                            window.location = '/sys-admin/messaging/email/' + params.state;
                        break;
                    default:
                }

                //alert('token sent')
            }).catch((e) => { })
    }, [])
    return (
        <div>
Processing ...
        </div>
    )
}

import { faSpinner } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useEffect, useState } from 'react'
import { ArrowLeft, ArrowRight, Calendar2DateFill, Clipboard2CheckFill, Filter, PersonFill } from 'react-bootstrap-icons'
import DateTimePicker from '../../../components/ui/calendar/date_picket/DateTimePicker'
import Logs from '../../../libs/api/services/Logs'

export default function LogsSectionLayout() {
    const [table, setTable] = useState()
    const [count, setCount] = useState(0)
    const [startType, setStartType] = useState(new Date().getTime())
    const [typing, setTyping] = useState(false)
    const [loading, setLoading] = useState(false)
    // const [page, setPage] = useState(1)
    // const [limit, setLimit] = useState(25)
    const [filters, setFilters] = useState({ page: 1, limit: 25 })
    const _setLimits = (v) => {
        setFilters({ ...filters, update: true, limit: v, page: 1 })
        // props.onChange(props.name + '>limit', v)
    }

    const _castResult = (data) => {
        const _table = { rows: [] }
        setCount(Object.keys(data).includes('count') ? data.count : data.results.length)
        for (const _results of data.results) {
            const _row = { columns: [] }
            for (const val of Object.keys(_results)) _row.columns.push({ feild: { format: 'string', value: _results[val] } })
            _table.rows.push(_row)
        }
        return _table
    }

    const _limits = () => {
        return (
            <select className='form-control form-control-sm' onChange={(e) => _setLimits(e.target.value)} style={{ width: 'auto' }}>
                {[10, 25, 50, 100, 500].map((l) => (
                    <option key={l} value={l} selected={parseInt(l) === parseInt(filters.limit)}>
                        {l}
                    </option>
                ))}
            </select>
        )
    }
    const _page = (_page) => {
        // props.onChange(props.name + '>page', _page)
        setFilters({ ...filters, page: _page, update: true })
        //setPage(_page)
    }

    const _handleLoadLogs = () => {
        setLoading(true)
        Logs
            .Search(filters)
            .then((data) => {
                setLoading(false)
                if (data) setTable(_castResult(data))
            }).catch((e) => {
                setLoading(false)
            })
    }

    const Humanize = (str) => {
        if (!str) return null
        let _str = ''
        let _start = true
        for (let i = 0; i < str.length; i++) {
            const c = i === str.length - 1 ? str.substring(i) : str.substring(i, i + 1)
            if (_start) {
                if (c.toLowerCase() === c) _str += c.toUpperCase()
                else _str += c
                _start = false
            } else {
                if (c.toUpperCase() === c && str.substring(i - 1, 1) !== ' ' && isNaN(c)) _str += ' ' + c.toLowerCase()
                else if (c === ' ' || c === '.') _start = true
                else _str += c
            }
        }
        return _str
    }

    const _format = (val) => {
        let _val
        if (!val) _val = ''
        else if (typeof val === 'object') {
            if (Object.keys(val).includes('user')) {
                _val = Object.keys(val.user).map((n) => {
                    return typeof val.user[n] === 'undefined' ? (
                        ''
                    ) : !val.user[n] || val.user[n] === null || n === "id" ? (
                        ''
                    ) : typeof (val.user[n]) === 'object' ? (
                        ''
                    ) : (
                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                            <span style={{ fontWeight: '700' }}>{Humanize(n)}</span>
                            <span>{Humanize(val.user[n])}</span>
                        </div>
                    )
                })
            } else {
                _val = Object.keys(val).map((n) => {
                    return typeof val[n] === 'undefined' ? (
                        ''
                    ) : !val[n] || val[n] === '' ? (
                        ''
                    ) : (
                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                            <span style={{ fontWeight: '700' }}>{Humanize(n)}</span>
                            <span>{Humanize(val[n])}</span>
                        </div>
                    )
                })
            }
        }
        else if (Array.isArray(val)) _val = '[]'
        else if (typeof val === 'undefined') _val = ''
        else _val = val
        return _val
    }

    const _render = () => {
        const _table = []
        if (table)
            for (const row of table.rows) {
                const _row = []
                for (const column of row.columns) {
                    _row.push(<td>{_format(column.feild.value)}</td>)
                }
                _table.push(<tr>{_row}</tr>)
            }
        return (
            <table
                className='wizard-table'>
                <tbody>{_table}</tbody>
            </table>
        )
    }

    const _handleTypingDelay = () => {
        //if (!setTyping)
        if (startType + 1000 > new Date().getTime()) {
            _handleLoadLogs()
        }
    }

    useEffect(() => {
        // setStartType(new Date().getTime())
        // const timeOutId = setTimeout(() => _handleTypingDelay(), 500);
        // return () => clearTimeout(timeOutId);
        // if (!setTyping)
        // {
        //     setTyping(true)
        // }
        //     const timeOutId = setTimeout(() => setDisplayMessage(query), 500);
        // return () => clearTimeout(timeOutId);

        // if (startType > new Date().getTime() + 1000)
        //     _handleLoadLogs()
        // else
        //     setStartType(new Date().getTime())
        if (filters.update) {
            setFilters({ ...filters, update: false })
            _handleLoadLogs()
        }
    }, [filters])

    return (
        <>
            <div className=" h-100 d-flex flex-column text-center">
                <div className="head flex-shrink-1">
                    <h1>Logs</h1>
                    <div className="error-details"></div>
                </div>


                <FilterBar setFilters={setFilters} filters={filters} onFilter={_handleLoadLogs} />
                {/* <div className="filter flex-shrink-1 bg-secondary-container p-2 rounded d-flex">
                    <FilterBar setFilters={setFilters} filters={filters} />
                    
                    <div className="col-2">
                        <div
                            className="validation-container">
                            <label>Person Name</label>
                            <input
                                onChange={(e) => setFilters({
                                    ...filters,
                                    page: 1,
                                    filters: {
                                        ...filters.filters,
                                        initiateUser: { name: e.target.value },
                                        recipientUser: { name: e.target.value }
                                    }
                                })}
                                defaultValue={filters.filters && filters.filters.initiateUser && filters.filters.initiateUser.name ? filters.filters.initiateUser.name : ''}
                                className="wizard-input form-control form-control-sm" placeholder="Person Name"
                            />
                            <div className="validation-details"></div>
                        </div>
                    </div>
                    <div
                        className="col-2">
                        <div className="validation-container">
                            <label>Person Surname</label>
                            <input
                                onChange={(e) => setFilters({
                                    ...filters,
                                    page: 1,
                                    filters: {
                                        ...filters.filters,
                                        initiateUser: { ...filters.filters.initiateUser, surname: e.target.value },
                                        recipientUser: { ...filters.filters.recipientUser, surname: e.target.value }
                                    }
                                })}
                                defaultValue={filters.filters && filters.filters.initiateUser && filters.filters.initiateUser.name ? filters.filters.initiateUser.surname : ''}
                                className="wizard-input form-control form-control-sm" placeholder="Person Surname"
                            />
                            <div className="validation-details"></div>
                        </div>
                    </div>
                    <div
                        className="col-2">
                        <div className="validation-container">
                            <label>Person Email</label>
                            <input
                                onChange={(e) => setFilters({
                                    ...filters,
                                    page: 1,
                                    filters: {
                                        ...filters.filters,
                                        initiateUser: { ...filters.filters.initiateUser, email: e.target.value },
                                        recipientUser: { ...filters.filters.recipientUser, email: e.target.value }
                                    }
                                })}
                                defaultValue={filters.filters && filters.filters.initiateUser && filters.filters.initiateUser.name ? filters.filters.initiateUser.email : ''}
                                className="wizard-input form-control form-control-sm" placeholder="Person Email"
                            />
                            <div className="validation-details"></div>
                        </div>
                    </div>
                    <div
                        className="col-2"><div className="validation-container">
                            <label>Person Contact Number</label>
                            <input
                                onChange={(e) => setFilters({
                                    ...filters,
                                    page: 1,
                                    filters: {
                                        ...filters.filters,
                                        initiateUser: { ...filters.filters.initiateUser, mobile: e.target.value },
                                        recipientUser: { ...filters.filters.recipientUser, mobile: e.target.value }
                                    }
                                })}
                                defaultValue={filters.filters && filters.filters.initiateUser && filters.filters.initiateUser.name ? filters.filters.initiateUser.mobile : ''}
                                className="wizard-input form-control form-control-sm"
                                placeholder="Person Contact Number" />
                            <div className="validation-details">
                            </div>
                        </div>
                    </div>
                    <button className='btn m-3' onClick={(e) => { _handleLoadLogs(); }}>{'Search'}</button>
                </div> */}
                <PaginationBar loading={loading} filters={filters} _page={_page} count={count} _limits={_limits} />
                <div className="flex-grow-1 h-100" style={{ overflow: 'auto' }}>
                    <div className="col-12 h-75">
                        <div>

                            {table ? _render() : <div className='alert alert-secondary shadow m-3'>Please select a search criteria</div>}
                        </div>

                    </div>

                </div>
            </div>
            {/* <Wizard template={{
            flow: "Wizard",
            grid: "top-center",
            sections: [
                {
                    name: "Logs",
                    title: "Logs",
                    description: "",
                    layout: [
                        {
                            "placeholder": "",
                            "icon": "Search",
                            "text": "Search",
                            "type": "MenuBar",
                            "grid": "col-auto",
                            "class": "",
                            "name": "SearchMenu",
                            "menuitems": [
                                {
                                    "icon": "User",
                                    "name": "UserMenu",
                                    "explination": "Search by name of person involved",
                                    "menu": [{
                                        "placeholder": "Person Name",
                                        "type": "input",
                                        "grid": "col-12",
                                        "class": "form-control",
                                        "name": "SearchPersonName"
                                    },
                                    {
                                        "placeholder": "Person Surname",
                                        "type": "input",
                                        "grid": "col-12",
                                        "class": "form-control",
                                        "name": "SearchPersonSurname"
                                    },
                                    {
                                        "placeholder": "Person Email",
                                        "type": "input",
                                        "grid": "col-12",
                                        "class": "form-control",
                                        "name": "SearchPersonEmail"
                                    }, {
                                        "placeholder": "Person Contact Number",
                                        "type": "input",
                                        "grid": "col-12",
                                        "class": "form-control",
                                        "name": "SearchPersonMobile"
                                    }
                                    ]
                                }, {
                                    "icon": "Date",
                                    "explination": "Date Of Events",
                                    "name": "DateMenu",
                                    "menu": [{
                                        "placeholder": "Date Of event",
                                        "type": "Date",
                                        "grid": "col-12",
                                        "class": "form-control",
                                        "name": "SearchDate"
                                    }],
                                },
                                {
                                    "icon": "Eye",
                                    "explination": "Search for event",
                                    "name": "EventMenu",
                                    "menu": [{
                                        "placeholder": "Event",
                                        "type": "input",
                                        "grid": "col-12",
                                        "class": "form-control",
                                        "name": "SearchEvent"
                                    }],
                                }
                            ]
                        },
                        {
                            type: "DynamicTable",
                            grid: "col-12",
                            class: "form-control",
                            name: "Logs Results",
                            explination: "",
                            source: [{
                                action: 'api',
                                format: 'json',
                                params: {
                                    url: "api/logs/search",
                                    method: "POST",
                                    body: {
                                        page: '{Logs.Logs Results>page}',
                                        limit: '{Logs.Logs Results>limit}',
                                        filters: {
                                            InitiateUser: {
                                                name: "{Logs.SearchMenu.SearchPersonName}",
                                                surname: "{Logs.SearchMenu.SearchPersonSurname}",
                                                email: "{Logs.SearchMenu.SearchPersonEmail}",
                                                mobile: "{Logs.SearchMenu.SearchPersonMobile}",
                                            },
                                            RecipientUser: {
                                                name: "{Logs.SearchMenu.SearchPersonName}",
                                                surname: "{Logs.SearchMenu.SearchPersonSurname}",
                                                email: "{Logs.SearchMenu.SearchPersonEmail}",
                                                mobile: "{Logs.SearchMenu.SearchPersonMobile}",
                                            },
                                            what: "{Logs.SearchMenu.SearchEvent}",
                                            InitiateDate: "{Logs.SearchMenu.SearchDate}",
                                            RecepientDate: "{Logs.SearchMenu.SearchDate}"
                                        }
                                    }, encryption: {
                                        method: 'aes256',
                                        key: 'key'
                                    }
                                }
                            }]
                        }






                    ]
                }
            ]
        }} /> */}
        </>
    )
}

const FilterBarItem = ({ setFilters, filters, label, field }) => {

    const _handleChange = (e) => {
        var _filters = filters.filters
        switch (field) {
            case 'first name': _filters.initiateUser = { ..._filters.initiateUser, name: e.target.value }; break;
            case 'last name': _filters.initiateUser = { ..._filters.initiateUser, surname: e.target.value }; break;
            case 'mobile': _filters.initiateUser = { ..._filters.initiateUser, mobile: e.target.value }; break;
            case 'email': _filters.initiateUser = { ..._filters.initiateUser, email: e.target.value }; break;
            case 'event': _filters = { ..._filters, what: e.target.value }; break;
            // case 'date': _filters = { ..._filters, what: e.target.value }; break;
            default: break;
        }
        setFilters({
            ...filters,
            page: 1,
            filters: _filters
        })
    }

    return (<div
        className="w-100 px-2"><div className="validation-container">
            <label>{label}</label>
            {
                field === 'date'
                    ?
                    <DateTimePicker pos='bottom' small dateOnly optional
                        onChange={(v) => setFilters({
                            ...filters,
                            page: 1,
                            filters: { ...filters.filters, initiateDate: v }
                        })}
                    />
                    :
                    <input
                        onChange={_handleChange}
                        className="wizard-input form-control form-control-sm"
                        placeholder={label} />
            }

            <div className="validation-details">
            </div>
        </div>
    </div>)
}

const FilterBar = ({ setFilters, filters, onFilter }) => {
    const [eventTypes, setEventTypes] = useState([])

    useEffect(() => {
        const load = async () => setEventTypes(await Logs.ListEventTypes())
        load()
    }, [])

    return (<div className="filter flex-shrink-1 bg-secondary-container p-2 rounded d-flex flex-column text-start mb-2 shadow">
        <b><Filter /> Filters</b>
        <div className='mb-1'>
            <b><PersonFill /> User Details</b>
            <div className='d-flex justify-content-between'>
                <FilterBarItem setFilters={setFilters} filters={filters} label={'First Name'} field={'first name'} />
                <FilterBarItem setFilters={setFilters} filters={filters} label={'First Name'} field={'last name'} />
                <FilterBarItem setFilters={setFilters} filters={filters} label={'Email Address'} field={'email'} />
                <FilterBarItem setFilters={setFilters} filters={filters} label={'Mobile Number'} field={'mobile'} />
            </div>
        </div>
        <div className='d-flex w-100 '>
            <div className='w-50'>
                <b><Calendar2DateFill /> Date</b>
                <FilterBarItem setFilters={setFilters} filters={filters} label={'Date'} field={'date'} />
                {/* <DateTimePicker onChange={} /> */}
            </div>
            <div className='w-50'>
                <b><Clipboard2CheckFill /> Events</b>
                <div>
                    <label>Event Type</label>
                </div>
                <select
                    onChange={(e) => {
                        setFilters({
                            ...filters,
                            page: 1,
                            filters: { ...filters, eventType: e.target.value }
                        })
                    }}
                    className='form-control form-control-sm'>
                    <option></option>
                    {eventTypes.map((evt) => <option>{evt}</option>)}
                </select>
                {/* <FilterBarItem setFilters={setFilters} filters={filters} label={'Event'} field={'event'} /> */}
            </div>
        </div>
        <div className='text-center p-2'>
            <button onClick={() => onFilter()} className='btn btn-sm'>Filter</button>
        </div>
    </div>)
}

const PaginationBar = ({ loading, filters, _page, count, _limits }) => {
    return (<div className=" flex-shrink-1">
        {
            loading
                ?
                <div className="row d-flex align-items-center bg-primary-container p-2 shadow rounded">
                    <center><FontAwesomeIcon icon={faSpinner} spin /> Searching</center>
                </div>
                :

                <div className="responsive-table-filter pt-2 d-flex flex-row justify-content-between align-items-center bg-primary-container p-2 shadow rounded">
                    <div className="col-auto">
                        <button
                            className='btn col-auto btn-xs'
                            onClick={() => {
                                filters.page > 1 ? _page(filters.page - 1) : _page(filters.page)
                            }}
                        >
                            <ArrowLeft />
                        </button>
                    </div>
                    <div className="col-auto">
                        <div className="row d-flex align-items-center">Showing {(filters.page - 1) * filters.limit + filters.page} to{' '}
                            {filters.page * filters.limit + filters.page > count ? count : filters.page * filters.limit + filters.page} of {count} results{' '}
                        </div>
                        <div
                            className="row d-flex align-items-center justify-content-evenly">
                            {_limits()}
                        </div>
                    </div>
                    <div className="col-auto d-flex align-items-center justify-content-center">
                        <button
                            onClick={() => {
                                filters.page < count / filters.limit ? _page(filters.page + 1) : _page(filters.page)
                            }}
                            className='btn col-auto btn-xs'
                        >
                            <ArrowRight />
                        </button>
                    </div>
                </div>
        }
    </div>)
}
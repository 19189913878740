import React, { useState } from 'react'
import { PlusCircleDotted, XCircle } from 'react-bootstrap-icons'
import Questionnaire from '../../../../../../libs/api/services/Questionnaire'

export default function QBuilderComponentSingleSelctSubSection({ onRerender, section, onChanged, SectionId, options, SectionComponents }) {
    const [mode, setMode] = useState()
    const [sectionDetails, setSectionDetails] = useState({})
    
    const _setValue = (v) => {
        if (v === 'New')
            setMode('Add')
        else
            onChanged(_findSectionById(v))
    }
    const _handleAdd = () => {
        Questionnaire
            .CreateQuestionnaireSubsection(SectionId, {
                name: sectionDetails.name
            }).then((r) => {
                // onRerender()
                setMode('')
                onChanged(r.id)
            })
    }

    const _findSectionById = (componentId) => {
        try {
            return SectionComponents.find(c => c.id === componentId).properties.find(p => p.property === "subsection").values
        } catch (e) { }
        return null;
    }

    const _checkLinkedSubSection = (section) => {
        const _subsection = section.properties.find(p => p.property === "subsection")
        if (_subsection && _subsection.values === options.subsection)
            return true
        return false
    }

    return (
        <div>
            {
                mode === 'Add'
                    ?
                    <div>
                        <input onChange={(e) => setSectionDetails({ ...section, name: e.target.value })} className='form-control form-control-sm' placeholder='New section name' />
                        <div className='d-flex'>
                            <button onClick={(e) => setMode('')} className='btn btn-xs w-100'><XCircle /> Cancel</button>
                            <button onClick={(e) => _handleAdd('')} className='btn btn-xs w-100'><PlusCircleDotted /> Create</button>
                        </div>
                    </div>
                    :
                    <select
                        onChange={(e) => _setValue(e.target.value)}
                        className='form-control form-control-sm'>
                        <option>None</option>
                        {
                            SectionComponents ?
                                SectionComponents.map((s) => { return s.componentType.type === 'Sub Section' ? <option selected={_checkLinkedSubSection(s) ? 'selected' : ''} key={s.id} value={s.id}>{s.question}</option> : <></> })
                                : <></>
                        }
                        <option value={'New'}>New Section</option>
                    </select>
            }

        </div>
    )
}

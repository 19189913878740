import React, { useContext } from 'react'
import { LayoutInterface } from './interfaces/LayoutInterface'
import ValidationWrapper from './validation/ValidationWrapper'
import ErrorContext from './context/ErrorContext'
import DependenciesWrapper from './validation/DependenciesWrapper'

type Props = {
  layout: LayoutInterface
  children: React.ReactNode
}

const ComponentContainer = ({ layout, children }: Props) => {
  const { errorsMap } = useContext(ErrorContext)

  return (
    <div className={layout.grid}>
      <DependenciesWrapper key={'DependenciesWrapper-' + layout.name} layout={layout}>
        <div className='validation-container'>
          {layout.placeholder ? <label>{layout.placeholder}</label> : <></>}
          <ValidationWrapper key={'ValidationWrapper-' + layout.name} layout={layout}>
            {children}
          </ValidationWrapper>
          <div className='validation-details'>{errorsMap![layout.name]}</div>
        </div>
      </DependenciesWrapper>
    </div>
  )
}
export default ComponentContainer

import React, { useContext, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import Authentication from '../../../../libs/api/services/Authentication';
import CommonFunctionsContext from '../../../common/CommonFunctionsProvider';
import { WorkFlowManagementProvider } from '../../workflow/management/WorkFlowManagementProvider';
import PatientContext from '../management/PatientProvider';
import WorkFlowPatientHistoryEntries from './components/WorkFlow/WorkFlowPatientHistoryEntries';

export default function WorkFlowPatientHistory({ event }) {
    const { id } = useParams();
    const { setSectionInfo } = useContext(CommonFunctionsContext)
    const { activePatient } = useContext(PatientContext)

    useEffect(() => {
        if (activePatient)
            setSectionInfo(`Patient history for ${activePatient.userProfile.firstName} ${activePatient.userProfile.lastName} at ${Authentication.getFacilityName()}`);
        else
            setSectionInfo(`Patient history`);
    }, [activePatient])


    return (
        <WorkFlowManagementProvider id={id}>
            <div style={{ overflow: 'auto' }}>
                <WorkFlowPatientHistoryEntries />
            </div>
        </WorkFlowManagementProvider>
    )
}

import React, { useContext } from 'react'
import { ChatDots, Clock, ClockHistory, Person, PersonFill } from 'react-bootstrap-icons'
import WorkFlowManagementContext from '../../WorkFlowManagementProvider'
import MessageEntryDetails from './MessageEntryDetails'

export default function MessageEntry({ item }) {
    const { extendedModal } = useContext(WorkFlowManagementContext)
    if (!item) return
    return (
        <div
            style={{ cursor: 'pointer' }}
            onClick={() => extendedModal({ show: true, icon: <ChatDots size={25} />, title: item.message.subject, component: <MessageEntryDetails item={item} /> })}
            className='rounded bg-secondary-container p-2'>
            <div className='d-flex justify-content-between'>
                <div className='d-flex flex-column'>
                    <div className='text-start'><Person className='me-2' /><b>To</b> {item.message.to}</div>
                    <div className='text-start'><Clock className='me-2' /><b>Sent</b> {item.message.sentDate}</div>
                </div>

                <div className='d-flex flex-column'>
                    <div className='text-start'><PersonFill className='me-2' /><b>From</b> {item.message.from}</div>
                    <div className='text-start'><ClockHistory className='me-2' /><b>Received</b> {item.message.receviedDate}</div>
                </div>
            </div>
            <div className='d-flex'>
                <div className='text-start'><b>Subject</b> {item.message.subject}</div>
            </div>
        </div>
    )
}

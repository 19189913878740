import React from 'react'
import NoteEntries from './NoteEntries'
import NoteEntry from './NoteEntry'
import { NotesProvider } from './NotesProvider'

const HEADER_SIZE = 72 + 24 + 48 + 30 // 72 Menitems 24 = Details 48 = Heading 30 = additional padding
// const HEADER_SIZE = 0 // 72 Menitems 24 = Details 48 = Heading 30 = additional padding
export default function NotesContainer({ defaultValue, event, className }) {
    return (
        <NotesProvider
            defaultValues={defaultValue}
            event={event}
        >
            <div className={'d-flex flex-column flex-grow-1 mt-3 '.concat(className)}
            style={{maxHeight: '100%',height:'100%'}}
            // style={{ maxHeight: 'calc(75% - ' + HEADER_SIZE + 'px)', height: 'calc(75vh - ' + HEADER_SIZE + 'px)',overflow:'auto' }}
            // style={{ maxHeight: 'calc(100% - ' + HEADER_SIZE + 'px)', height: 'calc(100% - ' + HEADER_SIZE + 'px)' }}
            >
                <NoteEntries />
                <NoteEntry />
            </div>
        </NotesProvider>
    )
}

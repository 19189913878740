import React, { useState } from 'react'
import { Clipboard2PlusFill, Tools, Trash, XCircleFill } from 'react-bootstrap-icons'

export default function ManageTaskContainer({ Task, onDelete, children }) {
    const [toggle, seToggle] = useState(true)

    return (
        <div>
            <div className='row text-start p-1'>
                <div className='col-1'>
                    <Clipboard2PlusFill size={16} className='me-2' />
                </div>
                <div style={{ cursor: 'pointer' }} onClick={() => seToggle(!toggle)} className='col-10 text-center'>
                    {Task.taskName}
                </div>
                <div
                    style={{ cursor: 'pointer' }} className='col-1 text-end'>
                    <Tools onClick={() => seToggle(!toggle)} className='me-2' />
                    <Trash onClick={(e) => onDelete(Task.id)} size={21} />
                </div>
            </div>
            {
                toggle &&
                <div className='shadow rounded p-2'>
                    <div style={{ cursor: 'pointer' }} className='col-12 text-end' onClick={() => seToggle(false)}><XCircleFill size={20} /></div>
                    {children}
                </div>
            }
        </div>
    )
}

/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react'
import OnScreenAssist from '../../../../../../../components/ui/hint/OnScreenAssist'
import NewWorkFlowComponent from '../components/new_workflow/NewWorkFlowComponent'

export default function WorkFlowSetupType({ title, description, navigation, navigator, errors, setValue, values, subsection }) {

    useEffect(() => {
        title('Work Flow')
        description('Please select/create the Category for this event')
        navigation([])
    }, [])

    useEffect(() => {
        if (values.name && values.serviceType) {
            navigation([{ text: 'Next', type: 'Next', action: async () => { } }]);
            return;
        }
        else if (values.event && values.event.id) {
            navigation([{ text: 'Next', type: 'Next', }])
            return;
        } else {
            navigation([])
        }
    }, [values])

    const _handleSelectCategory = (c) => {
        if (!c)
            navigation([])
        if (!c) description('Please select/create the Category for this event')
        else description('Please select/create an event')
        // navigation([{ text: 'Next', type: 'Next' }])

    }

    return (
        <div>
            <NewWorkFlowComponent values={values} setValue={setValue} errors={errors} navigation={navigation} onCategorySelected={(c) => _handleSelectCategory(c)} />
            {!subsection && <OnScreenAssist items={[
                {
                    heading: 'What are categories',
                    description: 'A category is used to group events together to make working with large amounts of events easier'
                },
                {
                    heading: 'How can I remove / rename a category',
                    description: '<ol><li> Either <b>right click</b> or <b>long press</b> the category</li><li> This will open the context menu where you can choose to <b>remove or rename</b> the category</li></ol>'
                },
            ]} />}
        </div>
    )
}

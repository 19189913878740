import React, { useEffect } from 'react'
import { Clipboard2Heart } from 'react-bootstrap-icons'
export default function MessageTemplateWizardWelcome({ title, description, navigation, errors, setValue, values }) {

    useEffect(() => {
        title('Messaging Template')
        description('Welcome to the message template setup wizard')
        // Load Navigation if session started
        // if (Object.keys(values).includes('templateId'))
            navigation([
                { text: 'Begin', type: 'Next', action: async () => {  } },
            ])
    }, [])

    return (
        <div className='h-100 d-flex flex-column align-items-center m-3'>
            <Clipboard2Heart size={80} />
            <p className='m-3'>A message template allows you to create a dynamic message to streamline the message sending process</p>
            <p className='m-3'>Example: A template can be created to SMS a patient with thier appointment details</p>
        </div>
    )
}

import { faSpinner } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useContext } from 'react'
import { Calendar2 } from 'react-bootstrap-icons'
import CalendarContext from './CalendarProvider'
import CalendarDayView from './calendar/CalendarDayView'
import CalendarMonthView from './calendar/CalendarMonthView'
import CalendarWeekView from './calendar/CalendarWeekView'

export default function CalendarDetailsView() {
    const { filters, loading } = useContext(CalendarContext)

    // if(loading) return <div>Loading</div>
    // useEffect(() => { if (patient) setPatient(patient) }, [])
    const _handleLoader = (children) => {
        return <>{loading ? <div style={{zIndex: 9999}} className='position-absolute bg-light p-2 m-2 rounded shadow opacity-75'><FontAwesomeIcon icon={faSpinner} spin /> Loading</div> : <></>}{children}</>
        // return <>{<div className='position-absolute bg-light p-2 m-2 rounded shadow opacity-75'><FontAwesomeIcon icon={faSpinner} spin /> Loading</div>}{children}</>
    }

    if (!filters.calendar || filters.calendar.id === 0) return <div className='d-flex justify-content-center align-items-center h-100'>
        <div><div className='text-center m-2'><Calendar2 size={50} /></div> Please select a calendar</div>
    </div>

    switch (filters.view) {
        case 'Day': return _handleLoader(<CalendarDayView />)
        case 'Week': return _handleLoader(<CalendarWeekView />)
        case 'Month':
        default: return _handleLoader(<CalendarMonthView />)
    }
}

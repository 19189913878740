import React, { useEffect, useState } from 'react'
import { Pencil, PlusCircle } from 'react-bootstrap-icons'
import Questionnaire from '../../../../../libs/api/services/Questionnaire'
import QCaptureComponentWrapper from '../../capture/components/Layouts/QCaptureComponentWrapper'
import QBuilderLayoutComponentWrapper from '../Wizard/Layouts/QBuilderLayoutComponentWrapper'
import QBuilderComponent from './QBuilderComponent'
import QBuilderComponentOptions from './QBuilderComponentOptions'

export default function QBuilderComponentSubSection({ state, stateMode, visible, subsectionId, onChangeMode, gloabloptions, view, activeQuestionnaireAssignment, onLoadComplete, ComponentId, onAction, idx, edit, defaultMode, validate, setValidate, onSetData }) {
    const [mode, setMode] = useState(edit ? 'Edit Text' : stateMode ? stateMode : '')
    const [details, setDetails] = useState({ text: '' })
    const [questionnair, setQuestionnaire] = useState([])
    // const [options, setOptions] = useState([])
    // const [option, setOption] = useState({ option: '' })
    const [properties, setProperties] = useState([])
    // const [loading, setLoading] = useState(true)
    // const [responseValue, setResponseValue] = useState()
    // const [issues, setIssues] = useState({})
    // const [alignment, setAlignmnet] = useState('text-start')
    // const [componentTypes, setComponentTypes] = useState([])
    // const [dragId, setDragId] = useState('')
    // const [activeMode, setActiveMode] = useState(mode)
    const [formData, setFormData] = useState([])
    
    const _loadFormData = (_subsectionId) => {
        Questionnaire
            .GetSubSection(_subsectionId)
            .then((r) => {
                setFormData(r.components)
            })
    }
    const loadProperties = async () => {
        var properties = await Questionnaire
            .GetComponentProperties(ComponentId)
        _loadFormData(properties.find(p => p.property === 'subsection').values)
        setProperties(properties)
    }
    useEffect(() => {
        if (subsectionId)
            _loadFormData(subsectionId)
        else
            loadProperties()
    }, [subsectionId])

    useEffect(() => {
        onChangeMode(mode)
    }, [mode])

    useEffect(() => {
        if (defaultMode && defaultMode === 'Overview')
            setMode('')
    }, [defaultMode])

    const errors = () => { }
    const _handleReload = () => { loadProperties(); onAction({ action: 'Reload' }) }

    const _handleOnAction = (e, i) => {
        // onAction(e, i)
        switch (e.action) {
            case 'Add':
                var _questionnair = formData
                Questionnaire
                    .AddComponentToSection(e.subseciontId ? e.subseciontId : _loadProperty('subsection'), {
                        question: ''
                    }).then((r) => {
                        //_questionnair.push({ id: r.id, componentType: e.type })
                        _questionnair.push({ id: r.id, componentType: { type: 'New' } })
                        setFormData([..._questionnair])
                    }).catch((e) => { if (e.errors) errors(e.errors); else errors(e.message); })
                break;
            case 'Remove':
                // var _questionnair = [...questionnair]
                // //_questionnair.push({ type: e.type })
                // setQuestionnaire([..._questionnair])
                i = i === null ? ComponentId : i
                Questionnaire
                    .RemoveComponent(i).then((r) => {
                        _handleReload()
                    }).catch((e) => { if (e.errors) errors(e.errors); else errors(e.message); })
                break;
            case 'Set Type':
                // //_questionnair.push({ type: e.type })
                // setQuestionnaire([..._questionnair])
                Questionnaire
                    .SetComponentType(i, {
                        type: e.type
                    }).then((r) => {
                        _handleReload()
                    }).catch((e) => { if (e.errors) errors(e.errors); else errors(e.message); })
                break;
            // case 'Sub Section':
            //     var _questionnair = questionnair
            //     // //_questionnair.push({ type: e.type })
            //     // setQuestionnaire([..._questionnair])
            //     Questionnaire
            //         .SetComponentType(_questionnair[e.idx].id, {
            //             type: e.type
            //         }).then((r) => {
            //             _questionnair[e.idx].componentType.type = e.type
            //             // _questionnair[e.idx].id = r.id
            //             setQuestionnaire([..._questionnair])
            //         }).catch((e) => { if (e.errors) errors(e.errors); else errors(e.message); })
            //     break;
            default:
                break;
        }
    }
    // const loadComponentTypes = () => {
    //     Questionnaire
    //         .ListComponentsTypes()
    //         .then((r) => {
    //             setComponentTypes(r)
    //         })
    // }
    // useEffect(() => {
    //     loadComponentTypes()
    // }, [])

    // useEffect(() => {
    //     if (validate === "Validate Next") {
    //         if (details.Required) {
    //             if (_validate())
    //                 setValidate('Ok Next')
    //             else
    //                 setValidate('Issue')
    //         } else {
    //             // setValidate('Ok Next')
    //         }
    //         // 
    //     }
    //     if (validate === "Validate Back") {
    //         setValidate('Ok Back')
    //     }
    //     //alert(validate)
    // }, [validate])

    // const _hadleAddOption = () => {
    //     const _options = [...options, option]
    //     setOptions(_options)
    //     _setProperty('Options', JSON.stringify(_options))
    // }
    // const _LoadResponse = async (res) => {

    //     // if (!edit) {


    //     try {
    //         const responses = await Questionnaire
    //             .GetQuestionnaireResponse(activeQuestionnaireAssignment.id, ComponentId)
    //         // Check Alerts
    //         const isAlertedResp
    //             = await Questionnaire.GetQuestionnaireResponseAlert(activeQuestionnaireAssignment.id, ComponentId);

    //         setResponseValue(responses.answer)
    //         if (responses.answer)
    //             onSetData(responses.answer)
    //         const opts = res.find(p => p.property === "Options")
    //         var _opts = JSON.parse(opts.values)
    //         for (var o = 0; o < _opts.length; o++) {
    //             const _answr = _opts[o].option
    //             if (_opts[o].option === responses.answer) {
    //                 _opts[o].active = true
    //             }
    //             if (isAlertedResp && isAlertedResp.find(r => r.response.answer === _answr))
    //                 _opts[o].alert = true
    //         }
    //         setOptions(_opts)
    //         onSetData(responses.answer)
    //     } catch (e) { }


    //     //responseValue

    // }

    // const _alignment = () => {
    //     try {
    //         const HEADINGS_ALIGN = gloabloptions.find(r => r.name === 'HEADINGS_ALIGN')
    //         if (HEADINGS_ALIGN)
    //             setAlignmnet(JSON.parse(HEADINGS_ALIGN.value).align)
    //     } catch (e) { }
    // }

    // const init = (r) => {
    //     var _details = { ...details }
    //     try {
    //         _details.label = r.find(p => p.property === "Question").values
    //     } catch (e) { }
    //     try {
    //         _details.Required = JSON.parse(r.find(p => p.property === "Required").values)
    //     } catch (e) { }
    //     try {
    //         _details.displayAs = r.find(p => p.property === "displayAs").values
    //     } catch (e) { }
    //     setDetails({ ..._details })
    //     _alignment()
    //     setProperties(r)
    // }
    // const _loadProperties = async (prop) => {
    //     var res = await Questionnaire
    //         .GetComponentProperties(ComponentId)
    //         .then((r) => {
    //             setProperties(r)
    //             init(r)
    //             // var _details = { ...details }
    //             // _details.label = _loadProperty("Question", r)
    //             // _details.align = _loadProperty("Alignment")
    //             // _details.fontWeight = _loadProperty("FontWeight")
    //             // _details.fontStyle = _loadProperty("FontStyle")
    //             // _details.textDecoration = _loadProperty("TextDecoration")
    //             // setDetails(_details)
    //             setLoading(false)

    //             /*
    //                 DO AUTOFILL
    //             */

    //             try {
    //                 const opts = r.find(p => p.property === "Options")
    //                 if (opts)
    //                     setOptions(JSON.parse(opts.values))
    //                 _LoadResponse(r)
    //             } catch (e) { }
    //             onLoadComplete()
    //         }).catch((e) => {
    //             setLoading(false)
    //             onLoadComplete()
    //         });;
    // }

    // useEffect(() => {
    //     if (!edit && view !== "New") {
    //         _loadProperties()
    //     } else {
    //         // onLoadComplete()
    //         _loadProperties()
    //     }
    // }, [mode])

    const _loadProperty = (prop, cache) => {
        var ret = {}
        if (cache)
            ret = cache.find(p => p.property === prop)
        else
            ret = properties.find(p => p.property === prop)
        return ret ? ret.values : '';
    }

    const _setProperty = (prop, value) => {
        Questionnaire
            .SetComponentProperty(ComponentId, {
                property: prop,
                values: value,
                component: {
                    id: ComponentId
                }
            })

        /*
            DO UPDATE RECORDS
        */

    }

    // useEffect(() => {
    //     _loadProperties();
    // }, [])

    const _handleSetFieldName = (t) => {
        _setProperty("Question", t)
        var _details = { ...details }
        _details.label = t
        setDetails(_details)
    }

    // const _handleRemoveOption = (i) => {
    //     var _options = options
    //     _options.splice(i, 1)
    //     setOptions([..._options])
    //     _setProperty('Options', JSON.stringify(_options))
    // }
    // const _handleEditOption = (i, v) => {
    //     var _options = options
    //     options[i] = v
    //     setOptions([..._options])
    //     _setProperty('Options', JSON.stringify(_options))
    // }
    // const _handleSave = () => {
    //     setMode('')
    // }

    // useEffect(() => {
    //     if (defaultMode && defaultMode === 'Overview')
    //         setMode('')
    // }, [defaultMode])

    // // const _handleSetValue = (v) => {
    // //     if (!edit) {
    // //         _validate(v)
    // //         Questionnaire
    // //             .QuestionnaireResponse(activeQuestionnaireAssignment.id, {
    // //                 // questionnaire: {
    // //                 //     id: QuestionnaireAssignmentId
    // //                 // },
    // //                 answerType: {
    // //                     id: ComponentId
    // //                 },
    // //                 answer: v
    // //             })
    // //             .then((r) => {

    // //             })
    // //     }
    // // }

    // const _handleOptionSelected = (i) => {
    //     var _options = options

    //     for (var o of _options) {
    //         o.active = false
    //     }
    //     _options[i].active = true
    //     setOptions([..._options])
    //     onSetData(_options[i].option, true)
    // }

    // const _handleSetRequired = () => {
    //     var _required = details.Required
    //     if (_required === null)
    //         _required = false
    //     var _details = { ...details, Required: !_required }
    //     _setProperty("Required", JSON.stringify(!_required))
    //     setDetails(_details)
    // }

    // const _validate = () => {
    //     var _isValid = false
    //     for (const o of options) {
    //         if (o.active)
    //             _isValid = true
    //     }
    //     return true;
    // }

    // // useEffect(() => {
    // //     _validate()
    // // }, [options])

    // const _showScore = () => {
    //     var max_weight = 0;
    //     var weight = 0;
    //     for (const o of options) {
    //         if (Object.keys(o).includes('weight')) {
    //             max_weight = parseInt(o.weight) > max_weight ? parseInt(o.weight) : max_weight
    //             if (Object.keys(o).includes('active') && o.active === true)
    //                 weight = o.weight

    //         }
    //     }
    //     return <div><small>Weighting : {weight} / {max_weight} </small></div>

    // }

    // const _renderOptions = () => {
    //     var res = []
    //     if (!details.displayAs || details.displayAs === '' || details.displayAs === 'Checkbox') {
    //         for (var i = 0; i < options.length; i++) {
    //             const _i = i;
    //             res.push(
    //                 view && view === 'Discover'
    //                     ?
    //                     <div
    //                         className={' m-2 '.concat(options[_i].alert ? 'text-danger' : '')}>
    //                         {
    //                             options[_i].active
    //                                 ?
    //                                 <div>
    //                                     <CheckSquare className='me-2' />
    //                                     {options[_i].option}
    //                                 </div>
    //                                 :
    //                                 <div className=' opacity-25'>
    //                                     <Square className='me-2' />
    //                                     {options[_i].option}
    //                                 </div>
    //                         }
    //                     </div>
    //                     :
    //                     <div
    //                         style={{ cursor: 'pointer' }}
    //                         onClick={(e) => _handleOptionSelected(_i)}
    //                         className=' m-2'>
    //                         {
    //                             options[_i].active
    //                                 ?
    //                                 <CheckSquare className='me-2' />
    //                                 :
    //                                 <Square className='me-2' />
    //                         }

    //                         {options[_i].option}
    //                     </div>
    //             )
    //         }
    //         // res.push(options.map((o, i) =>
    //         //     view && view === 'Discover'
    //         //         ?
    //         //         <div
    //         //             className=' m-2'>
    //         //             {
    //         //                 o.active
    //         //                     ?
    //         //                     <div>
    //         //                         <CheckSquare className='me-2' />
    //         //                         {o.option}
    //         //                     </div>
    //         //                     :
    //         //                     <div className=' opacity-25'>
    //         //                         <Square className='me-2' />
    //         //                         {o.option}
    //         //                     </div>
    //         //             }

    //         //         </div>
    //         //         :
    //         //         <div
    //         //             style={{ cursor: 'pointer' }}
    //         //             onClick={(e) => _handleOptionSelected(i)}
    //         //             className=' m-2'>
    //         //             {
    //         //                 opt.active
    //         //                     ?
    //         //                     <CheckSquare className='me-2' />
    //         //                     :
    //         //                     <Square className='me-2' />
    //         //             }

    //         //             {opt.option}
    //         //         </div>
    //         // ))
    //     }
    //     else if (details.displayAs === 'Buttons') {
    //         for (var i = 0; i < options.length; i++) {
    //             const _i = i;
    //             res.push(
    //                 view && view === 'Discover'
    //                     ?
    //                     <div
    //                         onClick={(e) => _handleOptionSelected(_i)}
    //                         className={' btn '.concat(options[_i].active ? 'active' : '')} >
    //                         {options[i].option}
    //                     </div>
    //                     :
    //                     <div
    //                         onClick={(e) => _handleOptionSelected(_i)}
    //                         className={' btn text-light  '.concat(options[_i].active ? ' shadow bg-secondary-container' : ' bg-secondary-container opacity-50 ')} >
    //                         {options[i].option}
    //                     </div>
    //             )

    //         }
    //         res = <div className='d-flex flex-column'>{res}</div>
    //     }
    //     else if (details.displayAs === 'Dropdown') {
    //         res.push(<option></option>)
    //         for (var i = 0; i < options.length; i++) {
    //             const _i = i;
    //             res.push(
    //                 view && view === 'Discover'
    //                     ?
    //                     <option
    //                         value={_i}
    //                     // onClick={(e) => _handleOptionSelected(i)}
    //                     // className={' btn '.concat(options[i].active ? 'active' : '')} 
    //                     >
    //                         {options[_i].option}
    //                     </option>
    //                     :
    //                     <option
    //                         value={_i}
    //                         selected={(options[_i].active) ? 'selected' : ''}
    //                     // onClick={(e) => _handleOptionSelected(i)}
    //                     // className={' btn '.concat(options[i].active ? 'active' : '')} 
    //                     >
    //                         {options[_i].option}
    //                     </option>
    //             )

    //         }
    //         if (view && view === 'Discover')
    //             <div>{res}</div>
    //         else
    //             res = <select className='form-control' onChange={(e) => _handleOptionSelected(e.target.value)}>{res}</select>
    //     }
    //     // var res = []
    //     // //option.displayAs === '' || option.displayAs === 'Checkbox'
    //     // if (!details.displayAs || details.displayAs === '' || details.displayAs === 'Checkbox') {
    //     //     res.push(<div
    //     //         style={{ cursor: 'pointer' }}
    //     //         onClick={(e) => _handleOptionSelected(i)}
    //     //         className=' m-2'>
    //     //         {
    //     //             opt.active
    //     //                 ?
    //     //                 <CheckSquare className='me-2' />
    //     //                 :
    //     //                 <Square className='me-2' />
    //     //         }

    //     //         {opt.option}
    //     //     </div>)
    //     // }
    //     // else if (details.displayAs === 'Buttons') {
    //     //     res.push(<div
    //     //         onClick={(e) => _handleOptionSelected(i)}
    //     //         className={' btn '.concat(opt.active ? 'active' : '')} >
    //     //         {opt.option}
    //     //     </div>)
    //     // }
    //     // else if (details.displayAs === 'Dropdown') {
    //     //     res.push(<option
    //     //         onClick={(e) => _handleOptionSelected(i)}
    //     //         className={' '.concat(opt.active ? 'active' : '')} >
    //     //         {opt.option}
    //     //     </option>)
    //     // }

    //     return res;
    // }

    // const _handleSetStyle = (i, v) => {
    //     // var _details = details
    //     // _details._displayAs = v
    //     var _details = { ...details, displayAs: v }
    //     _setProperty("displayAs", v)
    //     setDetails(_details)
    // }

    // const Icon = (type) => {
    //     switch (type) {
    //         case 'Display Text':
    //             return <FileText className='me-2' />
    //         case 'Get Input text':
    //             return <PencilSquare className='me-2' />
    //         case 'Multiple Selection':
    //             return <ListCheck className='me-2' />
    //         case 'Single Selection':
    //             return <List className='me-2' />
    //         case 'Date Picker':
    //             return <Calendar2 className='me-2' />
    //         case 'Sub Section':
    //             return <Calendar2 className='me-2' />
    //     }
    // }

    // const _render_component = (type) => {
    //     switch (type) {

    //         case 'New':
    //             var _sections = [];
    //             for (var componentType of componentTypes) {
    //                 const _idx = idx
    //                 const _type = componentType.type
    //                 var _section = _sections.find(s => s.section === componentType.category)
    //                 if (_section) {
    //                     //
    //                 } else {
    //                     _sections.push({
    //                         section: componentType.category,
    //                         components: []
    //                     })
    //                     _section = _sections.find(s => s.section === componentType.category)
    //                 }
    //                 _section.components.push(<div
    //                     className='p-0'
    //                     key={componentType.id}
    //                     onClick={(e) => onAction({ action: 'Set Type', type: _type, idx: _idx })}
    //                 >
    //                     {Icon(_type)}{_type}
    //                 </div>

    //                 )
    //                 //_sections.push(<div><b></b></div>)
    //             }
    //             return <div style={{ cursor: 'pointer', zIndex: 999, width: 'calc(100% - 177px )', left: '177px' }} className='bg-light rounded text-start p-2 position-fixed'>
    //                 <div className='d-flex justify-content-between'>
    //                     <b>Select the component type</b>
    //                     <QBuilderComponentOptions onAction={(e) => onAction(e)} idx={idx} />
    //                 </div>
    //                 {

    //                     // JSON.stringify(_sections)
    //                 }
    //                 <div style={{ cursor: 'pointer' }} className='row m-1'>
    //                     {
    //                         _sections.map((s) => {
    //                             return (<div className='col-3'>
    //                                 <div className='text-center bg-primary-container'>
    //                                     <b>{s.section}</b>
    //                                 </div>
    //                                 <div className=''>
    //                                     {
    //                                         s.components.map((c) => c)
    //                                     }
    //                                 </div>
    //                             </div>)
    //                         })
    //                     }
    //                 </div>
    //             </div>
    //         case 'Display Text':
    //             return (<QBuilderComponentDisplayText onChangeMode={(mode) => setActiveMode(mode)} onSetData={() => { }} onLoadComplete={() => { }} ComponentId={ComponentId} defaultMode={mode} edit={true} onAction={onAction} idx={idx} onIssue={() => { }} />)
    //         case 'Get Input text':
    //             return (<QBuilderComponentTextInout onChangeMode={(mode) => setActiveMode(mode)} onSetData={() => { }} onLoadComplete={() => { }} ComponentId={ComponentId} defaultMode={mode} edit={true} onAction={onAction} idx={idx} onIssue={() => { }} />)
    //         case 'Multiple Selection':
    //             return (<QBuilderComponentMultiSelct onChangeMode={(mode) => setActiveMode(mode)} onSetData={() => { }} onLoadComplete={() => { }} ComponentId={ComponentId} defaultMode={mode} edit={true} onAction={onAction} idx={idx} onIssue={() => { }} />)
    //         case 'Single Selection':
    //             return (<QBuilderComponentSingleSelct onChangeMode={(mode) => setActiveMode(mode)} onSetData={() => { }} onLoadComplete={() => { }} ComponentId={ComponentId} defaultMode={mode} edit={true} onAction={onAction} idx={idx} onIssue={() => { }} />)
    //         case 'Date Picker':
    //             return (<QBuilderComponentDatePicker onChangeMode={(mode) => setActiveMode(mode)} onSetData={() => { }} onLoadComplete={() => { }} ComponentId={ComponentId} defaultMode={mode} edit={true} onAction={onAction} idx={idx} onIssue={() => { }} />)
    //         case 'Sub Section':
    //             return (<QBuilderComponentSubSection onChangeMode={(mode) => setActiveMode(mode)} onSetData={() => { }} onLoadComplete={() => { }} ComponentId={ComponentId} defaultMode={mode} edit={true} onAction={onAction} idx={idx} onIssue={() => { }} />)
    //         default:
    //             return (
    //                 <div style={{ cursor: 'pointer' }} className='bg-light rounded text-start p-2 opacity-75'>
    //                     <div onClick={(e) => onAction({ action: 'Add', type: 'New' })}><PlusCircle /> Add new element to the form</div>
    //                 </div>)
    //     }
    // }

    // const _render = () => {
    //     var _rent = []
    //     switch (mode) {
    //         case 'Edit Text':
    //             _rent = (
    //                 <div style={{ cursor: 'pointer' }} className='bg-light rounded text-start p-2 opacity-75'>
    //                     <div className='text-center'>
    //                         <div className='d-flex justify-content-between'>
    //                             <b>Enter a name for this section</b>
    //                         </div>
    //                         <input
    //                             onChange={(e) => _handleSetFieldName(e.target.value)}
    //                             defaultValue={_loadProperty("Question")}
    //                             className='form-control form-control-sm' placeholder='Field Name' />
    //                     </div>
    //                 </div>
    //             )
    //             break;
    //         default:
    //             break;
    //     }

    //     return (
    //         <>
    //             {_rent}
    //         </>
    //     );
    // }
    // if (!visible)
    //     return <></>
    // if (mode !== '')
    //     return (
    //         <div style={{ cursor: 'pointer', zIndex: 999, width: 'calc(100% - 177px)', left: '177px' }} className='bg-light rounded text-start p-2 position-fixed'>
    //             <QBuilderComponentOptions onAction={(e) => onAction(e)} idx={idx} />
    //             <div className='d-flex rounded bg-primary-container m-2'>
    //                 <div className={'p-2 '.concat(mode === 'Edit Text' ? 'active' : '')} onClick={() => setMode('Edit Text')} >Field Name</div>
    //                 <div className={'p-2 '.concat(mode === '' ? 'active' : '')} onClick={() => setMode('')} >Close</div>
    //             </div>
    //             {_render()}
    //         </div>
    //     )
    // else
    //     return (<div className='text-start'>
    //         {
    //             edit
    //                 ?
    //                 <Pencil className='me-2' onClick={(e) => setMode('Edit Text')} />
    //                 :
    //                 <></>
    //         }
    //         <>
    //             {view && view === 'Discover'
    //                 ?
    //                 <div className='d-flex justify-content-between'>
    //                     <label><b>{loading ? '' : details.label}</b></label>
    //                     <div className='mx-3 d-flex align-items-center '><GraphUpArrow className='me-2' />{_showScore()}</div>
    //                 </div>
    //                 :
    //                 loading ? '' : <div>

    //                     {edit ? <small className='badge  bg-secondary text-light shadow '>{_loadProperty("Question")}</small> : <></>}
    //                     <small className='badge  bg-secondary text-light shadow '>{_loadProperty("Question")}</small>
    //                     {
    //                         <QBuilderComponent
    //                             dragOrder={0}
    //                             setDragOrder={() => { }}
    //                             onAction={(e) => () => { }} type={''} />
    //                     }</div>
    //             }
    //             <div>
    //                 {

    //                     _renderOptions(options)
    //                 }
    //             </div>
    //             {
    //                 issues.issue
    //                     ?
    //                     <div className='text-secondary text-center bg-secondary-container text-light rounded p-1 shadow'>
    //                         <Pencil />{issues.issue}
    //                     </div>
    //                     :
    //                     <></>
    //             }
    //         </>
    //     </div>
    // const _renderSection = (section) => {
    //     const SHOW_TITLE = this.state.details.options.find(o => o.name === "SHOW_SECTION_TITLE")
    //     var section_title = section.name
    //     if (SHOW_TITLE) {
    //         var SHOW_TITLE_parsed = JSON.parse(SHOW_TITLE.value)
    //         if (SHOW_TITLE_parsed.type === "Hide") {
    //             section_title = "";
    //         }
    //     }

    //     var _section_details = section_title ? <h3 className='bg-secondary-container text-center p-2 shadow'>{section_title}</h3> : <></>
    //     var _section_components = this._renderComponents(section.components)

    //     return (<div className='text-start'>{_section_details}{_section_components}</div>)
    // }
    /*
        Edit mode
    */
    const _render = () => {
        var _rent = []
        switch (mode) {
            case 'Edit Text':
                _rent = (
                    <div style={{ cursor: 'pointer' }} className='bg-light rounded text-start p-2 opacity-75'>
                        <div className='text-center'>
                            <div className='d-flex justify-content-between'>
                                <b>Enter a name for this section</b>
                            </div>
                            <input
                                onChange={(e) => _handleSetFieldName(e.target.value)}
                                defaultValue={_loadProperty("Question")}
                                className='form-control form-control-sm' placeholder='Field Name' />
                        </div>
                    </div>
                )
                break;
            default:
                _rent = <div>fsadfdsa</div>
                break;
        }
        return _rent
    }
    /*
        Components
    */
    const _checkLayout = (component) => {
        var _width = 12
        var _offset = 0
        var _layout = []
        try {
            var layout = component.properties.find(p => p.property === 'Layout').values
            if (layout) {
                _layout = JSON.parse(layout)
                _width = _layout.width
                _offset = _layout.offset
            }
        } catch (e) { layout = undefined }
        return { width: _width, offset: _offset, layout: _layout }
    }

    const _renderComponents = (components) => {
        var ret = []
        var comps = []
        var _cur_row_width = 0;

        for (const component of components) {
            const layout = _checkLayout(component)

            _cur_row_width += layout.width + layout.offset
            const _componentid = component.id
            if (!component.componentType || component.componentType.type === 'New') {
                comps.push(<QBuilderComponent
                    idx={0}
                    // className={cn}
                    key={component.id}
                    onAction={(e, i) => _handleOnAction(e, component.id)}
                    onRerender={() => _handleReload()}
                    type={'New'}
                    mode={mode}
                    // order={questionnair[i].order}
                    // dragOrder={dragOrder}
                    // onChangedOrder={(ComponentId) => _handleChangeOrder(ComponentId)}
                    // setDragOrder={(o) => setDragOrder(o)}
                    ComponentId={component.id}
                    SectionComponents={questionnair}
                // values={values}
                />)
            } else {
                comps.push(<QBuilderLayoutComponentWrapper
                    currentMode={'Capture'}
                    onUpdateLayout={(layout) => { }}
                    defaultLayout={layout.layout}
                    className={'col-' + layout.width + ' offset-' + layout.offset}
                >
                    <QCaptureComponentWrapper
                        state={state}
                        mode={mode}
                        view={view}
                        edit={edit}
                        component={component}
                        defaultMode={defaultMode}
                        onSetData={(_componentid, value, update) => onSetData(_componentid, value, update)}
                        onAction={(a) => _handleOnAction(a, _componentid)}
                        activeSubsections={[]}
                    />
                </QBuilderLayoutComponentWrapper>)
            }
            if (_cur_row_width >= 12) {
                ret.push(<div className='row'>{comps}</div>)
                comps = []
                _cur_row_width = 0
            }
        }
        ret.push(<div className='row'>{comps}</div>)
        return ret
    }

    // if (mode !== '')
    //     return (

    //     )
    // else
    return (<>
        {
            mode !== '' && view !== 'Discover'
                ?
                <div style={{ cursor: 'pointer' }} className={'bg-light shadow rounded text-start p-2 position-fixed '.concat(' slide-in')}>
                    <QBuilderComponentOptions onClose={() => setMode('')} onAction={(e) => _handleOnAction(e, null)} idx={idx} />
                    <div className='d-flex rounded bg-primary-container m-2'>
                        <div className={'p-2 '.concat(mode === 'Edit Text' ? 'active' : '')} onClick={() => setMode('Edit Text')} >Field Name</div>
                    </div>
                    {_render()}
                </div>
                :
                <></>
        }
        <div className={'text-start '.concat(mode !== '' && view !== 'Discover' ? 'bg-primary-container opacity-75 shadow' : '')}>
            {
                edit
                    ?
                    <Pencil className='me-2' onClick={(e) => setMode('Edit Text')} />
                    :
                    <></>
            }
        </div>
        <div className={'flex-grow-1 '.concat(visible || edit ? 'd-block' : 'd-none').concat(mode !== '' && view !== 'Discover'  ? ' bg-primary-container opacity-75 shadow' : '')} style={{ overflow: 'auto' }}>
            {
                formData ?
                    _renderComponents(formData)
                    : <></>
            }
            {
                edit
                    ?
                    <div style={{ cursor: 'pointer' }} className='bg-light rounded text-start p-2 opacity-75 w-100'>
                        <div onClick={(e) => _handleOnAction({ action: 'Add', type: 'New', subseciontId: _loadProperty('subsection') })}><PlusCircle /> Add new element to sub section</div>
                    </div>
                    :
                    <></>
            }
        </div>
    </>)
    // return <></>
}

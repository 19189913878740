/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import Authentication from '../../../../../libs/api/services/Authentication';
import WorkFlow from '../../../../../libs/api/services/WorkFlow';
import CronDayOptions from '../../CronWidgets/CronDayOptions';
import CronHourOptions from '../../CronWidgets/CronHourOptions';
import CronMinuteOptions from '../../CronWidgets/CronMinuteOptions';
import CronMonthOptions from '../../CronWidgets/CronMonthOptions';
import CronYearOptions from '../../CronWidgets/CronYearOptions';
export default function EventTriggerCron({ link, type, values, eventId, filterTrigger }) {
    const PROPERTY_TYPE = 'cron';
    // const [options, setOptions] = useState({ messageTypes: [], receivedFrom: {}, subject: '' })
    const [availableCalendars, setAvailableCalendars] = useState([])
    const [properties, setProperties] = useState({})

    useEffect(() => { _loadProperties(); if (!properties.facility) setProperties({ ...properties, facility: Authentication.getFacility() }) }, [])
    useEffect(() => { if (properties && Object.keys(properties).length > 0) _handleSetProperty(properties) }, [properties])

    const _loadProperties = () => {
        try {
            WorkFlow
                .GetTriggerPropertyByType(typeof values.event === 'object' ? values.event.id : JSON.parse(values.event).id, link[0].id, PROPERTY_TYPE)
                .then((r) => {
                    var res = JSON.parse(r.value)
                    if (!res.facility)
                        res.facility = Authentication.getFacility()
                    setProperties(res)
                }).catch((e) => { /* Doesnt exist yet */ })
        } catch (e) {
            // Not yet linked
        }
    }

    const _handleSetProperty = (v) => {
        try {
            WorkFlow
                .SetTriggerProperty(typeof values.event === 'object' ? values.event.id : JSON.parse(values.event).id, link[0].id, {
                    property: PROPERTY_TYPE,//'questionnaire_complete',
                    value: JSON.stringify(v),
                })
                .then((r) => {
                    // Log as property
                    // setLinked(_linked)
                })
        } catch (E) { }
    }
    return (
        <div>
            <div className='row'>
                <div className='col-3'>Year</div>
                <CronYearOptions properties={properties} setProperties={setProperties} />
            </div>
            <div className='row'>
                <div className='col-3'>Month</div>
                <CronMonthOptions properties={properties} setProperties={setProperties} />
            </div>
            <div className='row'>
                <div className='col-3'>Day</div>
                <CronDayOptions properties={properties} setProperties={setProperties} />
            </div>
            <div className='row'>
                <div className='col-3'>Hour</div>
                <CronHourOptions properties={properties} setProperties={setProperties} />
            </div>
            <div className='row'>
                <div className='col-3'>Minute</div>
                <CronMinuteOptions properties={properties} setProperties={setProperties} />
            </div>
        </div>
    )
}

import SingleSelection from "./components/SingleSelection"
import TextInput from "./components/TextInput"

export default function PatientManagementDetailsComponent({ id, value, type, options, onChanged, profile }) {
    // const { SetPatientProfileDetail, patientState } = useContext(PatientContext)
    // const [layout, setLayout] = useState('col-12')
    // useEffect(() => {
    //     if (patientState.state === 'SAVING')
    //         onbeforeunload = (event) => { event.preventDefault(); return false; }
    //     else
    //         onbeforeunload = (event) => { return }
    //     // _handleLayoutSize()
    // }, [patientState.state])

    const RenderCommponent = () => {
        // const defaultValue = undefined
        const defaultValue = profile.profiles && typeof (profile.profiles) === 'object' &&
        profile.profiles.find(p => p.detailsType && p.detailsType.id === id) ? profile.profiles.find(p => p.detailsType && p.detailsType.id === id).detail
            : ''
        switch (type) {
            case 'Drop Down':
                {
                    const _options = options.find(f => f.category === 'Options')
                    return (<SingleSelection
                        // onChanged={(e) => { SetPatientProfileDetail(id, e) ; onChanged('Profile Updated') }}
                        onChanged={(v) => onChanged(id, v)}
                        defaultValue={defaultValue && defaultValue !== '' ? defaultValue : value}
                        options={options}
                        placeholder={value}
                        fieldId={id}
                        optionsId={_options.options[0].id}
                    />
                    )
                }
            case 'Multi Selection':
                break;
            case 'Text Input':
                return (
                    <TextInput
                        onChanged={(v) => onChanged(id, v)}
                        // onChanged={(e) => { SetPatientProfileDetail(id, e) ; onChanged('Profile Updated')}}
                        defaultValue={defaultValue}
                        placeholder={value}
                    />
                )
            default:
                return <></>
        }
        return <></>
    }

    return (
        <>
            <label>{value}</label>
            {RenderCommponent()}
        </>
    )
}

import { faSpinner } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useEffect, useState } from 'react'
import Authentication from '../../../../../../../libs/api/services/Authentication'
import WorkFlow from '../../../../../../../libs/api/services/WorkFlow'
import RunTaskButtons from './RunTask/RunTaskButtons'
import RunTaskSection from './RunTask/RunTaskSection'

export default function WorkFlowRunTask({ onCancel, onComplete, patientId, task, onSetParam }) {
    const [configuration, setConfiguration] = useState()
    const [currentIndex, setCurrentIndex] = useState(0)
    const [validation, setValidation] = useState([])
    const [loading, setLoading] = useState(true)
    const _demo = {
        sections: [{
            title: 'Account',
            description: 'Please select the account you would like to send this message from',
            layout: [
                {
                    type: 'text',
                    text: 'Some Pre Text'
                },
                {
                    type: 'option',
                    style: 'button',
                    variableName: 'accountId',
                    // issues: {
                    //     severity: 'Critical',
                    //     issue: 'DrData.Services.Messaging.SMS.Exceptions.InvalidSMSAppAccountDetailsException',
                    //     icon: 'headset'
                    // },
                    options: [
                        {
                            name: 'Main Account',
                            value: '1',
                        },
                        {
                            name: 'Another Account',
                            value: '2',
                        }
                    ]
                }
            ]
        }, {
            title: 'Done',
            description: 'You are done now',

        }
        ]
    }
    /*
        Load config
    */
    useEffect(() => { _loadTaskConfiguration() }, [])

    const _loadTaskConfiguration = () => {
        WorkFlow.GetPreConfiguration(task.id, {
            user: Authentication.UserId(),
            patient: patientId,
            facility: Authentication.getFacility()
        }).then((r) => { setConfiguration(r); setLoading(false) }).catch((e) => { setConfiguration({ sections: [{ layout: [{ issues: { severity: 'Critical', issue: e, icon: 'system' } }] }] }) })
    }
    /*
        Validation
    */
    useEffect(() => {
        if (!configuration || !configuration.sections[currentIndex].layout) return
        var _validation = []
        for (var l = 0; l < configuration.sections[currentIndex].layout.length; l++)
            if (configuration.sections[currentIndex].layout[l].type !== 'text')
                _validation.push(false)
            else
                _validation.push(true)
        setValidation(_validation)
    }, [currentIndex, configuration])

    const _setValid = (idx, val) => { var _validation = [...validation]; _validation[idx] = val; setValidation(_validation) }
    const _checkValid = () => { for (var l of Object.keys(validation)) if (validation[l] === false) return false; return true }
    /*
        Render
    */
    if (loading) return <div><FontAwesomeIcon icon={faSpinner} spin className='me-2' />Loading Task Details</div>
    return (
        <>
            {
                configuration ? <>{configuration.sections.map((s, i) =>
                    <RunTaskSection setValid={_setValid} show={currentIndex === i} section={s} index={i} length={configuration.sections.length} setParameters={(p) => onSetParam(p)} />
                )}
                    <RunTaskButtons onBack={() => { setCurrentIndex(currentIndex - 1) }} onNext={() => { setCurrentIndex(currentIndex + 1) }}
                        isValid={_checkValid()}
                        onComplete={() => onComplete()}
                        onCancel={() => onCancel()}
                        CompletedActionText={configuration.completedActionText}
                        index={currentIndex} length={configuration.sections.length} />
                </> : <></>
            }

        </>
    )
}

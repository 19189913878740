import React, { useEffect, useState } from 'react'
import Messaging from '../../../../libs/api/services/Messaging'
import RecipientGroup from './components/WhatsApp/RecipientGroup'
import WhatsAppWizardSetupType from './components/WhatsApp/wizard/WhatsAppWizardSetupType'
import ErrorDialog from '../../../../components/dialogs/ErrorDialog'
import WhatsAppWizardServiceProvider from './components/WhatsApp/wizard/WhatsAppWizardServiceProvider'
import WhatsAppWizardConnectionInfo from './components/WhatsApp/wizard/WhatsAppWizardConnectionInfo'
import WhatsAppWizardAudience from './components/WhatsApp/wizard/WhatsAppWizardAudience'
import WhatsAppWizardWebhook from './components/WhatsApp/wizard/WhatsAppWizardWebhook'
import { useContext } from 'react'
import StickyNavigationContext from '../../../../libs/providers/StickyNavigationProvider'

export default function WhatsAppAdmin() {
    const [properties, setProperties] = useState({})
    const [step, setStep] = useState(1)
    const [title, setTitle] = useState('')
    const [description, setDescription] = useState('')
    const [navigation, setNavigation] = useState([])
    const [errors, setErrors] = useState('')
    const [values, setValues] = useState({})
    const { setSticky, setPreventMessage } = useContext(StickyNavigationContext)

    useEffect(() => {
        if (step != 1 && step != 5) {
            setSticky(true)
            setPreventMessage('Configuration Incomplete', 'You have not completed the configuration process. <br/><br/>This can be completed later, however service will not be active until completed. <br/><br/>Are you sure you would like to cancel?')
        } else {
            setSticky(false)
        }
    }, [step])

    const _getStepSection = () => {
        switch (step) {
            case 1:
                return <WhatsAppWizardSetupType
                    title={(t) => setTitle(t)}
                    description={(t) => setDescription(t)}
                    navigation={(t) => setNavigation(t)}
                    errors={(e) => setErrors(e)}
                    setValue={(v) => setValues({ ...values, ...v })}
                    values={values}
                />
            case 2:
                return <WhatsAppWizardServiceProvider
                    title={(t) => setTitle(t)}
                    description={(t) => setDescription(t)}
                    navigation={(t) => setNavigation(t)}
                    errors={(e) => setErrors(e)}
                    setValue={(v) => setValues({ ...values, ...v })}
                    values={values}
                />
            case 3:
                return <WhatsAppWizardConnectionInfo
                    title={(t) => setTitle(t)}
                    description={(t) => setDescription(t)}
                    navigation={(t) => setNavigation(t)}
                    errors={(e) => setErrors(e)}
                    setValue={(v) => setValues({ ...values, ...v })}
                    values={values}
                />
            case 4:
                return <WhatsAppWizardAudience
                    title={(t) => setTitle(t)}
                    description={(t) => setDescription(t)}
                    navigation={(t) => setNavigation(t)}
                    errors={(e) => setErrors(e)}
                    setValue={(v) => setValues({ ...values, ...v })}
                    values={values}
                />
            case 5:
                return <WhatsAppWizardWebhook
                    title={(t) => setTitle(t)}
                    description={(t) => setDescription(t)}
                    navigation={(t) => setNavigation(t)}
                    errors={(e) => setErrors(e)}
                    setValue={(v) => setValues({ ...values, ...v })}
                    values={values}
                />
            default:
                return <></>
        }
    }

    return (
        <>
            <ErrorDialog error={errors} onClose={() => setErrors('')} />
            <div
                className="Wizard top-center position-relative">
                <div className="head"><h1>{title}</h1>
                    <p>{description}</p>

                    <div
                        className="error-details"></div>
                </div>
                <div className="body">
                    <div
                        className=".section d-flex flex-column align-items-stretch h-100 w-100">
                        {_getStepSection()}
                    </div>
                </div>
                <div classname="tail">
                    {
                        navigation.map((n) => {
                            if (n.type === 'Next')
                                return <button
                                    key={n.text}
                                    onClick={async (e) => {
                                        if (n.action) {
                                            await n.action().catch((e) => setErrors(e.message))
                                        }
                                        setStep(step + 1)
                                    }} className="btn ng-buttons">{n.text}</button>
                            else if (n.type === 'Back')
                                return <button
                                    key={n.text}
                                    onClick={async (e) => {
                                        if (n.action) {
                                            await n.action().catch((e) => setErrors(e.message))
                                        }
                                        setStep(step - 1)
                                    }} className="btn ng-buttons">{n.text}</button>
                            else if (n.type === 'Action')
                                return <button
                                    key={n.text}
                                    onClick={async (e) => {
                                        if (n.action)
                                            await n.action()
                                                .catch((e) => setErrors(e.message))
                                    }} className="btn ng-buttons">{n.text}</button>
                            else if (n.type === 'Done')
                                return <button
                                    key={n.text}
                                    onClick={async (e) => {
                                        if (n.action)
                                            await n.action()
                                                .catch((e) => setErrors(e.message))
                                        setStep(1)
                                    }} className="btn ng-buttons">{n.text}</button>
                        })
                    }
                </div>
            </div>
        </>
        // <div className='text-center d-flex flex-column align-items-center'>
        //     <h1>WhatsApp Configuration</h1>
        //     <div className="bg-primary-container w-50 p-3 rounded rounded-3 shadow">
        //         <div className='m-2'>
        //             <h3>Configuration</h3>
        //             <select className='form-control'>
        //                 <option></option>
        //                 <option>Create New Configuration</option>
        //             </select>
        //         </div>
        //         <div className='m-2'>
        //             <h3>WhatsApp Provider</h3>
        //             <label>Provider Name</label>
        //             <select
        //                 onChange={(e) => _handleSetValue(getSetting('WHATSAPP_PROVIDER').id, e.target.value)}
        //                 className='form-control'>
        //                 <option></option>
        //                 <option
        //                     selected={getSetting('WHATSAPP_PROVIDER').value === 'InfoBip' ? 'selected' : ''}
        //                     value={'InfoBip'}
        //                 >InfoBip</option>
        //             </select>


        //             <div>
        //                 API Key
        //                 <input
        //                     defaultValue={getSetting('WHATSAPP_API_KEY').value}
        //                     onChange={(e) => _handleSetValue(getSetting('WHATSAPP_API_KEY').id, e.target.value)}
        //                     className='form-control' placeholder='API Key' />
        //             </div>
        //             <div>
        //                 API Base URL
        //                 <input
        //                     defaultValue={getSetting('WHATSAPP_BASE_URL').value}
        //                     onChange={(e) => _handleSetValue(getSetting('WHATSAPP_BASE_URL').id, e.target.value)}
        //                     className='form-control' placeholder='API Base URL' />
        //             </div>
        //             <div>
        //                 Sender
        //                 <input
        //                     defaultValue={getSetting('WHATSAPP_SENDER').value}
        //                     onChange={(e) => _handleSetValue(getSetting('WHATSAPP_SENDER').id, e.target.value)}
        //                     className='form-control' placeholder='Sender' />
        //             </div>
        //         </div>
        //         <div className='m-2'>
        //             <h3>Recipients</h3>
        //             <RecipientGroup />
        //         </div>
        //     </div>
        // </div>
    )
}

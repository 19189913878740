import { faSpinner } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useEffect, useState } from 'react'
import Patient from '../../../../../libs/api/services/Patient'
import PatientManagementDetailsComponent from '../PatientManagementDetailsComponent'

export default function PatientProfileDetailsContainer({ category, onSave, profile, onSetValue,saving }) {
    const [loading, setLoading] = useState(false)
    const [layout, setLayout] = useState([])
    useEffect(() => { if (category.id !== -1) _loadFields() }, [category])

    const _loadFields = () => {
        setLoading(true)
        Patient.LoadFields(category.id)
            .then((r) => {
                _renderComonents(r)
            })
    }

    const _handleSetValue = (i, v) => {
        onSetValue(i, v)
    }

    var row_cntr = 0;
    const _handleLayoutSize = async (_id, options) => {
        const _layout = options.find(f => f.category === 'Layout')
        if (typeof _layout !== 'undefined') {
            const _grid = _layout.options.find(f => f.option === 'Grid')
            if (typeof _grid !== 'undefined') {
                const r = await Patient
                    .FieldTypesOptionsDefaults(_id, _grid.id)
                if (r && r.length > 0) {
                    if (r[0].value && r[0].value.length > 0) {
                        var cols = JSON.parse(r[0].value[0].property)
                        row_cntr += cols.lg;
                        if (row_cntr > 12)
                            row_cntr = 0;
                        return 'col-lg-' + cols.lg + ' col-md-' + cols.md + ' col-sm-' + cols.sm + ' '
                    }
                }
                else {
                    row_cntr = 0
                }
            } else {
                row_cntr = 0
            }
        } else {
            row_cntr = 0
        }
        return 'col-lg-12 col-md-12 col-sm-12 '
    }
    const _renderComonents = async (fields) => {
        var ret_r = []
        var ret_c = []
        if (fields) {
            for (var i = 0; i < Object.keys(fields).length; i++) {
                const lo = await _handleLayoutSize(fields[i].id, fields[i].type.patientProfileDetailTypeOptions)
                if (row_cntr === 0) {
                    ret_r.push(<div className='row'>{ret_c}</div>)
                    ret_c = []
                }
                ret_c.push(
                    <div key={fields[i].id} className={lo}>
                        <PatientManagementDetailsComponent
                            id={fields[i].id}
                            value={fields[i].value}
                            type={fields[i].type.type}
                            options={fields[i].type.patientProfileDetailTypeOptions}
                            profile={profile}
                            onChanged={(prop, val) => _handleSetValue(prop, val)}
                        />
                    </div>
                )
            }
            ret_r.push(<div className='row'>{ret_c}</div>)
        }
        setLoading(false)
        setLayout(ret_r);
    }

    if (loading) return <div className='h-100 w-100 d-flex align-items-center justify-content-center'><div><FontAwesomeIcon icon={faSpinner} spin /> Loading Patient Details</div></div>
    return (
        <>
            <div className='flex-grow-1 p-2' style={{ overflow: 'auto' }}>
                {layout.map((l) => l)}
            </div>
            <div className='flex-shrink-1 justify-content-center d-flex align-items-center px-2'>
                {
                    saving
                    ?
                    <div className='m-3'><b><FontAwesomeIcon icon={faSpinner} spin /></b></div>
                    :
                    category.id !== -1 ?
                        <button
                            //  className={'btn bg-primary-btn m-3 '.concat(changes.length > 0 ? '' : ' disabled ')}
                            className={'btn bg-primary-btn m-3 '}
                            onClick={(e) => onSave()}
                        >Save Changes</button> : <></>
                }
            </div>
        </>
    )
}

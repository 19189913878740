import React, { useEffect, useState } from 'react';

export default function ServiveProviderJSONItem({ id, field, type, option, value, onChange }) {
    const [result, setResult] = useState({ field: field, type: type, option: option, value: value })

    const [dropDown, setDropDown] = useState(false)
    const [valueOption, setValueOption] = useState('exclude')
    useEffect(() => {
        if (onChange) onChange(id,result)
    }, [result])

    useEffect(() => {
        setResult({ ...result, option: valueOption })
    }, [valueOption])

    const _handleTypeOptions = (type) => {
        var ret = []
        switch (type) {
            case 'boolean': { return <input onClick={(e) => setResult({ ...result, value: e.target.checked })} type='checkbox' /> }
            case 'string': return <input onChange={(e) => setResult({ ...result, value: e.target.value })} className='form-control form-control-sm' />
            case 'number': return <input onChange={(e) => setResult({ ...result, value: e.target.value })} type='number' className='form-control form-control-sm' />
            case 'object': break;
            case 'array': break;
            default: return <div>Unkown</div>
        }
        return ret
    }
    const _handleSendingVars = () => {
        const _VARS = [
            { name: 'RECIPIENTS_MOBILE_NUMBER', type: ['string', 'number'] },
            { name: 'RECIPIENTS_NAME', type: ['string', 'number'] },
            { name: 'MESSAGE_TO_BE_SENT', type: ['string', 'number'] },
            { name: 'SESSION_TOKEN', type: ['string', 'number'] },
            { name: 'SENDERS_NUMBER', type: ['string', 'number'] },
            { name: 'SENDERS_NAME', type: ['string', 'number'] },
            { name: 'MESSAGE_SUBJECT', type: ['string', 'number'] },
            { name: 'DATE_TIME_SENT', type: ['string', 'number'] },
            { name: 'MESSAGE_RECEIVED', type: ['string', 'number'] },
        
        ]
        var ret = []
        const _value = result.value
        for (const _v of _VARS) {
            if (_v.type.includes(type))
                ret.push(<option selected={result.value === _v.name} >{_v.name}</option>)
        }
        return <select onChange={(e) => setResult({ ...result, value: e.target.value })} className='form-control form-control-sm'><option></option>{ret}</select>
    }

    const _handleUseOptions = (type) => {
        return (
            <div style={{ zIndex: 99 }} className={'position-  bg-light p-1 shadow rounded '.concat(dropDown ? '' : 'd-none')}>
                <div onClick={() => { setValueOption('variable'); setDropDown(false) }}>Use message sending variable</div>
                <div onClick={() => { setValueOption('constant'); setDropDown(false) }}>Use a set value</div>
                <div onClick={() => { setValueOption('log'); setDropDown(false) }}>Log Only</div>
                <div onClick={() => { setValueOption('exclude'); setDropDown(false) }}>Exclude this from the request</div>
            </div>)
    }
    return (
        <div className='row text-center small'>
            <div className='col-4 text-start'><b>{result.field}</b></div>

            {/* <div className='col-4'><div>Remove from request</div> <input type='checkbox' /></div> */}
            <div style={{ cursor: 'pointer' }} className='col-4'>
                <div onClick={() => setDropDown(!dropDown)}>
                    {
                        valueOption
                    }
                </div>
            </div>
            <div style={{ cursor: 'pointer' }} className='col-4'>
                <div>
                    {
                        valueOption === 'variable' ? _handleSendingVars(type) :
                            valueOption === 'constant' ? _handleTypeOptions(type) :
                                valueOption === 'exclude' ? <></> :
                                    <></>
                    }
                </div>
                {_handleUseOptions()}</div>
        </div>
    )
}

import React, { useContext } from 'react'
import CalendarSyncContext from './CalendarSyncProvider'
import { PROVIDERS } from './config/CalendarSyncProviders'

export default function UserExternalCalendars() {
    const { userExternalCalendars, setActiveCalendar } = useContext(CalendarSyncContext)
    return (
        <div style={{ height: 'calc(100vh - 300px)', overflow: 'auto', cursor: 'pointer' }}>
            {userExternalCalendars.map((cal) =>
                <div className='m-1 text-start' onClick={() => setActiveCalendar(cal)} key={cal.id}>
                    {PROVIDERS.find(x => x.name === cal.provider) ? PROVIDERS.find(x => x.name === cal.provider).icon : <></>}
                    {cal.name}</div>
            )}
        </div>
    )
}

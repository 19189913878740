import React from 'react'
import { Calendar, Eye, Justify, PeopleFill, Search } from 'react-bootstrap-icons'

export default function Icons(icon: string): React.ReactNode {
  switch (icon?.toLowerCase()) {
    case 'user':
      return <PeopleFill />
    case 'date':
      return <Calendar />
    case 'eye':
      return <Eye />
    case 'search':
      return <Search className='me-2' />
    default:
      return <Justify />
  }
  return <></>
}

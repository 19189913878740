import React, { useContext, useState } from 'react'
import { Calendar2, Calendar2EventFill, Calendar2HeartFill } from 'react-bootstrap-icons'
import OnScreenAssist from '../../../../components/ui/hint/OnScreenAssist'
import CalendarFacilityContext, { CalendarFacilityProvider } from './CalendarFacilityProvider'
import CalendarSelectorComponent from './components/CalendarSelectorComponent'
import CalendarEventDetailsOverview from './components/EventDetails/CalendarEventDetailsOverview'
import CalendarEventDetailsProperties from './components/EventDetails/CalendarEventDetailsProperties'

export default function CalendarFacilityAdmin() {

    return (
        <CalendarFacilityProvider>
            <div className='d-flex flex-column h-100 m-3' style={{ overflow: 'auto' }}>
                <h1 className='text-center'>Calendar Event Management</h1>
                <CalendarEventDetails >
                    <AllCalendarEvents />
                </CalendarEventDetails>

                {/* <CalendarEventProperties>

                </CalendarEventProperties> */}
                <OnScreenAssist items={[
                    {
                        'heading': 'What are calendar events',
                        'description': 'Calendar events are calendar options that can be used when managing calendar tasks. eg: Create a new patient appointment'
                    }
                ]} />
            </div>
        </CalendarFacilityProvider>
    )
}

function CalendarEventDetails({ onSelected, children }) {
    const { selectCalendarEvent, LoadCalendarEvents } = useContext(CalendarFacilityContext)
    return (
        <div className='flex-shrink-1'>
            <div className='bg-primary-container p-2 rounded my-2 shadow'>
                <label className='pb-2'><Calendar2 className='me-2' /><b>Event</b></label>
                <CalendarSelectorComponent allowAdd={true} onSelected={selectCalendarEvent} onAdd={() => { LoadCalendarEvents() }} />
                {children}
            </div>
        </div>
    )
}

const AllCalendarEvents = () => {
    const { listCalendarEvents } = useContext(CalendarFacilityContext)

    return <div>{listCalendarEvents && listCalendarEvents.map((e) =>
        <CalendarEventPropertyCard key={e.id} event={e} />)}
    </div>
}

const CalendarEventPropertyCard = ({ event }) => {

    const { selectCalendarEvent, activeCalendarEvent } = useContext(CalendarFacilityContext)
    return (
        <>
            <div
                className={`${activeCalendarEvent && event.id === activeCalendarEvent.id ? 'bg-secondary-container rounded shadow' : ''}`}
                onClick={(e) => { e.preventDefault(); e.stopPropagation(); selectCalendarEvent(event) }}
            >
                <div className='p-2'>{event.eventName}</div>
            </div>
            {activeCalendarEvent && event.id === activeCalendarEvent.id && <ActiveCalendarEventProperties activeCalendarEvent={activeCalendarEvent} />}
        </>)
}


function ActiveCalendarEventProperties({ activeCalendarEvent }) {
    const [mode, setMode] = useState('calendar')
    const _renderModeProperties = () => {
        switch (mode) {
            // case 'workflow': return <WorkFlowEventDetails calendarEvent={calendarEvent} />
            case 'overview': return <CalendarEventDetailsOverview />
            case 'calendar': return <CalendarEventDetailsProperties />
            default: return <></>
        }
    }
    return (
        <div className={'bg-light text-dark rouneded shadow h-100 flex-grow-1 rounded '.concat(activeCalendarEvent ? '' : 'opacity-25')}>
            <div style={{ cursor: 'pointer' }} className='d-flex justify-content-evenly'>
                <div onClick={() => setMode('calendar')} className={'shadow w-100 p-2 rounded-start '.concat(mode === 'calendar' ? 'bg-primary-container shadow' : 'bg-secondary-container')}><Calendar2EventFill className='me-2' />Calendar Event Triggers</div>
                <div onClick={() => setMode('overview')} className={'shadow w-100 p-2 rounded-end '.concat(mode === 'overview' ? 'bg-primary-container shadow' : 'bg-secondary-container')}><Calendar2HeartFill className='me-2' />Calendar Overview Details</div>
                {/* <div onClick={() => setMode('workflow')} className={'shadow w-100 p-2 rounded-end '.concat(mode === 'workflow' ? 'bg-primary-container shadow' : 'bg-secondary-container')}><NodePlusFill className='me-2' />Work Flows / Automation</div> */}
            </div>
            <div className='m-2 ' style={{ height: 'calc(100% - 60px)' }}
            // maxHeight: 'calc(100% - 60px)', overflow: 'auto' }
            >
                {_renderModeProperties()}
            </div>
        </div >
    )
}

// function CalendarEventProperties({ }) {
//     const [mode, setMode] = useState('calendar')
//     const { activeCalendarEvent } = useContext(CalendarFacilityContext)

//     if (!activeCalendarEvent || Object.keys(activeCalendarEvent).length === 0) return

//     const _renderModeProperties = () => {
//         switch (mode) {
//             // case 'workflow': return <WorkFlowEventDetails calendarEvent={calendarEvent} />
//             case 'overview': return <CalendarEventDetailsOverview />
//             case 'calendar': return <CalendarEventDetailsProperties />
//             default: return <></>
//         }
//     }

//     return (
//         <div className={'bg--container rouneded shadow h-100 flex-grow-1 rounded rounded-4 '.concat(activeCalendarEvent ? '' : 'opacity-25')}>
//             <div style={{ cursor: 'pointer' }} className='d-flex justify-content-evenly'>
//                 <div onClick={() => setMode('calendar')} className={'shadow w-100 p-2 rounded-start '.concat(mode === 'calendar' ? 'bg-primary-container shadow' : 'bg-secondary-container')}><Calendar2EventFill className='me-2' />Calendar Event Triggers</div>
//                 <div onClick={() => setMode('overview')} className={'shadow w-100 p-2 rounded-start '.concat(mode === 'overview' ? 'bg-primary-container shadow' : 'bg-secondary-container')}><Calendar2HeartFill className='me-2' />Calendar Overview Details</div>
//                 {/* <div onClick={() => setMode('workflow')} className={'shadow w-100 p-2 rounded-end '.concat(mode === 'workflow' ? 'bg-primary-container shadow' : 'bg-secondary-container')}><NodePlusFill className='me-2' />Work Flows / Automation</div> */}
//             </div>
//             <div className='m-2 ' style={{ height: 'calc(100% - 60px)', maxHeight: 'calc(100% - 60px)', overflow: 'auto' }}>
//                 {_renderModeProperties()}
//             </div>
//         </div>
//     )
// }

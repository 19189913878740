import React, { useEffect, useState } from 'react'
import Questionnaire from '../../../../../../libs/api/services/Questionnaire'
import { Calendar2, FileText, List, ListCheck, PencilSquare, Table } from 'react-bootstrap-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'

export default function QBuilderNewComponent({ onSelected }) {
    const [componentTypes, setComponentTypes] = useState([])
    const [loading, setLoading] = useState(false)

    const loadComponentTypes = () => {
        setLoading(true)
        Questionnaire
            .ListComponentsTypes()
            .then((r) => {
                setComponentTypes(r)
                setLoading(false)
            })
    }
    useEffect(() => {
        loadComponentTypes()
    }, [])

    // TODO : Makes static with QBuilder Component
    const Icon = (type) => {
        switch (type) {
            case 'Display Text':
                return <FileText className='me-2' />
            case 'Get Input text':
                return <PencilSquare className='me-2' />
            case 'Multiple Selection':
                return <ListCheck className='me-2' />
            case 'Single Selection':
                return <List className='me-2' />
            case 'Date Picker':
                return <Calendar2 className='me-2' />
            case 'Dynamic Component Table':
                return <Table className='me-2' />
            case 'Sub Section':
                return <Calendar2 className='me-2' />
            default:
                return <></>
        }
    }

    const _render = () => {
        var _sections = [];
        for (var componentType of componentTypes) {
            const _type = componentType
            var _section = _sections.find(s => s.section === _type.category)
            if (_section) {
                //
            } else {
                _sections.push({
                    section: componentType.category,
                    components: []
                })
                _section = _sections.find(s => s.section === _type.category)
            }
            _section.components.push(<div
                className='p-0'
                key={componentType.id}
                onClick={() => onSelected(_type)}
            >
                {Icon(_type.type)}{_type.type}
            </div>

            )
        }
        return _sections
    }
    if (loading) return <div className='text-center'><FontAwesomeIcon icon={faSpinner} spin /> Populating Component List</div>
    return (
        <div>
            {
                _render().map((s) => {
                    return (<div className='row'>
                        <div className='text-center bg-primary-container p-1 rounded'>
                            <b className=''>{s.section}</b>
                        </div>
                        <div className='row shadow rounded-bottom p-2'>
                            {
                                s.components.map((c) => <div className='col-6 p-1'>{c}</div>)
                            }
                        </div>
                    </div>)
                })
            }
        </div>
    )
}

import React, { useContext } from 'react'
import CalendarFacilityContext from '../../../CalendarFacilityProvider'

export default function CalendarEventCreateEvent({ properties, onChange }) {
  const { calendarEvent, setCalendarEventProperty } = useContext(CalendarFacilityContext)

  const _findProp = (name) => {
    if (!calendarEvent || !calendarEvent.properties) return undefined
    const _val = calendarEvent.properties.find(n => n.name === name)
    return _val ? _val.value : undefined
  }
  const _handleChange = (k, v) =>
    setCalendarEventProperty(k, v)

  return (
    <div>
      <div>
        <label><b>Default Summary</b> </label>
        <input
          onBlur={(e) => _handleChange('DEFAULT_SUMMARY_ORIGINAL', e.target.value)}
          defaultValue={_findProp('DEFAULT_SUMMARY_ORIGINAL')}
          className='form-control form-control-sm' placeholder='Default summary eg: Appointment with patient' />
        <label><b>Default Details</b> </label>
        <textarea
          onBlur={(e) => _handleChange('DEFAULT_DETAILS_ORIGINAL', e.target.value)}
          defaultValue={_findProp('DEFAULT_DETAILS_ORIGINAL')}
          className='form-control form-control-sm' placeholder='Default event details' ></textarea>
      </div>
    </div>
  )
}

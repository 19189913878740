import React, { useContext, useEffect, useState } from 'react'
import { Calendar2, Calendar2DateFill, Calendar2DayFill, Calendar2PlusFill, Calendar2Range, Calendar4Range } from 'react-bootstrap-icons'
import Calendars from '../../../../../../../libs/api/services/Calendars'
import CalendarFacilityContext from '../../../CalendarFacilityProvider'
import CalendarEventCloneEvent from './CalendarEventCloneEvent'
import CalendarEventCreateEvent from './CalendarEventCreateEvent'
import CalendarEventMoveEvent from './CalendarEventMoveEvent'
import CalendarEventUpdateEvent from './CalendarEventUpdateEvent'

export default function CalenderEventDetailsDropDown({ properties, onChange }) {
    const { activeCalendarEvent, setWhenTriggered } = useContext(CalendarFacilityContext)
    const [options, setOptions] = useState([])

    useEffect(() => { _loadTriggers() }, [])

    const _loadTriggers = () => {
        Calendars
            .ListCalendarEventTriggers()
            .then((r) => setOptions(r))
    }

    const [open, setOpen] = useState(false)

    const icon = (trigger) => {
        switch (trigger) {
            case 'CREATE_CALENDAR_EVENT': return <Calendar2PlusFill className='me-2' />
            case 'UPDATE_CALENDAR_EVENT': return <Calendar2DateFill className='me-2' />
            case 'MOVE_CALENDAR_EVENT': return <Calendar2DayFill className='me-2' />
            case 'CLONE_CALENDAR_EVENT': return <Calendar2Range className='me-2' />
            case 'INITIATE_SEQUENCE': return <Calendar4Range className='me-2' />
            default: return <Calendar2 className='me-2' />
        }
    }
    const _getExtendedProps = (trigger) => {
        switch (trigger) {
            case 'CREATE_CALENDAR_EVENT': return <CalendarEventCreateEvent properties={properties.properties} onChange={onChange} />
            case 'UPDATE_CALENDAR_EVENT': return <CalendarEventUpdateEvent properties={properties.properties} onChange={onChange} />
            case 'MOVE_CALENDAR_EVENT': return <CalendarEventMoveEvent properties={properties.properties} onChange={onChange} />
            case 'CLONE_CALENDAR_EVENT': return <CalendarEventCloneEvent properties={properties.properties} onChange={onChange} />
            case 'INITIATE_SEQUENCE': return <CalendarEventCloneEvent properties={properties.properties} onChange={onChange} />
            default: return <></>
        }
    }
    if (!activeCalendarEvent) return
    return (
        <div className=' rounded position-relative '>
            <div style={{ cursor: 'pointer' }}>
                <div 
                onClick={(e) => { e.preventDefault(); e.stopPropagation(); setOpen(!open) }}
                 className='p-2 bg-primary-container rounded ' >
                    {icon(activeCalendarEvent && activeCalendarEvent.whenTriggered && activeCalendarEvent.whenTriggered.trigger)}{activeCalendarEvent && activeCalendarEvent.whenTriggered && activeCalendarEvent.whenTriggered.description}
                </div>
                {
                    open ?
                        <div className='position-absolute bg-secondary-container rounded shadow '>
                            {options.map((e) =>
                                <div key={e.id}
                                    onClick={(x) => { x.preventDefault(); x.stopPropagation(); setOpen(false); setWhenTriggered(e) }}
                                    className={'p-2 rounded m-2 shadow '.concat(activeCalendarEvent && activeCalendarEvent.whenTriggered && activeCalendarEvent.whenTriggered.trigger === e.trigger ? ' bg-primary-container  ' : ' bg-secondary-container')}
                                >{icon(e.trigger)}{e.description}</div>
                            )}
                        </div>
                        :
                        <></>
                }
            </div>
            {activeCalendarEvent && activeCalendarEvent.whenTriggered && _getExtendedProps(activeCalendarEvent.whenTriggered.trigger)}
        </div>
    )
}

import React, { useEffect, useState } from 'react'
import { Calendar2 } from 'react-bootstrap-icons'
import DateTimePickerFunctions from './DateTimePickerComponents/DateTimePickerFunctions'

const functions = new DateTimePickerFunctions()
export default function DatePickerInput({ small, onPicker, onChange, dateOnly, value }) {
  const [date, setDate] = useState(value ? value.getFullYear() + "/" + (value.getMonth() + 1) + "/" + value.getDate() : undefined)

  useEffect(() => { setDate(value.getFullYear() + "/" + (value.getMonth() + 1) + "/" + value.getDate()) }, [value.getFullYear()])
  useEffect(() => { setDate(value.getFullYear() + "/" + (value.getMonth() + 1) + "/" + value.getDate()) }, [value.getMonth()])
  useEffect(() => { setDate(value.getFullYear() + "/" + (value.getMonth() + 1) + "/" + value.getDate()) }, [value.getDate()])

  const _handleValidate = () => {
    const _date = functions.formatDateFinal(date, value)
    onChange(_date)
    setDate(_date.getFullYear() + "/" + (_date.getMonth() + 1) + "/" + _date.getDate())
  }


  return (
    <div className={`d-flex control-group rounded ${dateOnly && 'flex-grow-1'}`} style={{ background: "#d6d7d7" }}>
      <input
        value={functions.formatDate(date)}
        onChange={(e) => setDate(e.target.value)}
        onBlur={_handleValidate}
        onFocus={() => setDate('')}
        className={'form-control '.concat(small ? 'form-control-sm' : '')} placeholder='Date' />
      <Calendar2 onClick={onPicker} className='p-1 rounded text-primary' size={30} />
    </div>
  )
}

import React, { Component } from 'react'
import QBuilderLayoutComponentWrapper from '../../../builder/Wizard/Layouts/QBuilderLayoutComponentWrapper'
import QCaptureComponentWrapper from './QCaptureComponentWrapper'

export default class QWizardLayout extends Component {
    constructor(props) {
        super(props)
        this.state = {
            details: { ...props.details },
            formData: props.formData,
            navigation: [],
            step: 0
        }

    }

    /*
        Section
    */
    _renderSections(sections) {
        var ret = []
        for (var i = 0; i < sections.length; i++) {
            ret.push(
                <div
                    className={"section flex-column align-items-stretch h-100 w-100 ".concat(this.state.step === i ? 'd-flex' : 'd-none')}>{this._renderSection(sections[i])}</div>)
        }
        return ret
    }

    _renderSection(section) {
        var _section_components = this._renderComponents(section.components, section)

        return (<div className='text-start'>{_section_components}</div>)
    }
    /*
        Components
    */
    _checkLayout(component) {
        var _width = 12
        var _offset = 0
        var _layout = []
        try {
            var layout = component.properties.find(p => p.property === 'Layout').values
            if (layout) {
                _layout = JSON.parse(layout)
                _width = _layout.width
                _offset = _layout.offset
            }
        } catch (e) { layout = undefined }
        return { width: _width, offset: _offset, layout: _layout }
    }

    _renderComponents(components, section) {
        var ret = []
        var comps = []
        var _cur_row_width = 0;
        for (const component of components) {
            var _cache = undefined
            if (this.props.cacheddetails) {
                var _component_cached = undefined
                try {

                    const _sections_filter = JSON.parse(JSON.stringify(this.props.cacheddetails.questionnaire.sections));
                    const _section_cache = _sections_filter
                        .find(s => s.id === section.id)
                    _component_cached =
                        _section_cache.components.find(c => c.id === component.id)
                } catch (e) { }

                var _component_responses = { answer: '' }
                try {
                    var _response = this.props.cacheddetails.responses.find(r => r.answerType.id === component.id)
                    _component_responses = _response ? _response : { answer: '' }
                } catch (e) {
                    _component_responses = { answer: '' }
                }
                var _component_alerts = undefined
                try {
                    _component_alerts = this.props.cacheddetails.alerts
                } catch (e) { }

                _cache = {
                    properties: _component_cached && Object.keys(_component_cached).includes('properties') ? _component_cached.properties : undefined,
                    response: _component_responses,
                    alerts: _component_alerts
                }
            }

            const layout = this._checkLayout(component)
            _cur_row_width += layout.width + layout.offset
            comps.push(<QBuilderLayoutComponentWrapper
                currentMode={'Capture'}
                onUpdateLayout={(layout) => { }}
                defaultLayout={layout.layout}
                className={'col-' + layout.width + ' offset-' + layout.offset}
            >
                <QCaptureComponentWrapper
                    state={this.state}
                    mode={this.props.mode}
                    view={this.props.view}
                    cache={_cache}
                    component={component}
                    onSetData={(componentId, value, update) => this.props.onSetData(componentId, value, update)}
                    activeSubsections={this.props.activeSubsections}
                />
            </QBuilderLayoutComponentWrapper>)

            if (_cur_row_width >= 12) {
                ret.push(<div className='row'>{comps}</div>)
                comps = []
                _cur_row_width = 0
            }
        }
        ret.push(<div className='row'>{comps}</div>)
        return ret
    }

    /*
        Navigation
    */
    _handleValidateEvent() {
        var res = true
        for (const fd of this.state.formData) {
            if (fd.section === this.state.step)
                if (fd.validate && !fd.valid)
                    res = false
        }
        return res
    }

    _handleNavigation() {
        // Dont show in discover mode
        if (this.props.mode === 'Discover')
            return <></>
        // Check Validation

        if (this.state.step === 0 && this.state.details.questionnaire.sections.length === 1) {
            // Done
            // if (this._handleValidateEvent())
            return (<button disabled={!this._handleValidateEvent()} type='button' onClick={(e) => this.props.onComplete()} className={"btn ng-buttons".concat(this._handleValidateEvent() ? '' : 'disabled')}>Done</button>)
        }
        else if (this.state.step + 1 === this.state.details.questionnaire.sections.length) {
            // Done
            return (
                <>
                    <button onClick={(e) => this.setState({ step: this.state.step - 1 })} className="btn ng-buttons" >Back</button>
                    <button disabled={!this._handleValidateEvent()} type='button' onClick={(e) => this.props.onComplete()} className={"btn ng-buttons".concat(this._handleValidateEvent() ? '' : 'disabled')}  >Done</button>
                </>
            )
        }
        else if (this.state.step > 0) {
            // Done
            return (
                <>
                    <button onClick={(e) => this.setState({ step: this.state.step - 1 })} className="btn ng-buttons" >Back</button>
                    <button disabled={!this._handleValidateEvent()} onClick={(e) => this.setState({ step: this.state.step + 1 })} className={"btn ng-buttons ".concat(this._handleValidateEvent() ? '' : 'disabled')} >Next</button>
                </>
            )
        } else {
            // Next
            // if (this._handleValidateEvent())
            return (<button disabled={!this._handleValidateEvent()} onClick={(e) => this.setState({ step: this.state.step + 1 })} className={"btn ng-buttons ".concat(this._handleValidateEvent() ? '' : 'disabled')}  >Next</button>)
        }
    }

    _handleTitle() {
        var section_title = ''
        try {
            const SHOW_TITLE = this.state.details.options.find(o => o.name === "SHOW_TITLE")
            section_title = this.state.details.questionnaire.name
            if (SHOW_TITLE) {
                var SHOW_TITLE_parsed = JSON.parse(SHOW_TITLE.value)
                if (SHOW_TITLE_parsed.type === "None") {
                    section_title = "";
                }
                else if (SHOW_TITLE_parsed.type === "Custom Title") {
                    section_title = SHOW_TITLE_parsed.title;
                }
            }
        } catch (e) { }
        return section_title
    }

    _handleDescription() {
        var section_title = ''
        try {
            const SHOW_TITLE = this.state.details.options.find(o => o.name === "SHOW_SECTION_TITLE")
            var section_title = this.state.details.questionnaire.sections[this.state.step].name
            if (SHOW_TITLE) {
                var SHOW_TITLE_parsed = JSON.parse(SHOW_TITLE.value)
                if (SHOW_TITLE_parsed.type === "Hide") {
                    section_title = "";
                }
            }
        } catch (e) { }
        return section_title
    }

    render() {
        return (
            <>
                {/* <ToastDialog {...status} />
                <ErrorDialog error={errors} onClose={() => setErrors('')} /> */}
                <div
                    className="Wizard top-center position-relative">
                    <div className="head"><h1>{this._handleTitle()}</h1>
                        <p dangerouslySetInnerHTML={{ __html: this._handleDescription() }}></p>
                        <div
                            className="error-details"></div>
                    </div>
                    <div className="body">
                        {
                            this._renderSections(this.state.details.questionnaire.sections.filter(s => s.isSubsection === false))
                        }

                    </div>
                    <div classname="tail" style={{ minHeight: '38px' }}>
                        {
                            this._handleNavigation()
                        }
                    </div>
                </div>
            </>
        )
    }
}

import axios from 'axios';
import AesEncryption from "../authentication/AesEncryption";
import Authentication from "./services/Authentication";
const debug = true
var API = {
    async process(url, method, req) {
        //const baseURL = 'https://localhost:44458/';
        const baseURL = '';
        debug && console.log(url, JSON.stringify(req))
        var _req = (req) ? AesEncryption.encrypt(JSON.stringify(req)) : null;
        // token
        const token = Authentication.token;
        let customHeaders = {
            "Content-Type": "application/encrypted",
            "Accept": "application/encrypted",
        }
        if (await token() != null) {
            customHeaders = {
                "Content-Type": "application/encrypted",
                "Accept": "application/encrypted",
                "Authorization": "Bearer " + await token(),
                "Token": await Authentication.refresh(),
                "Accessibility": AesEncryption.encrypt(Authentication.getFacility() + "-" + Authentication.getFacilityRole())
            }
        }

        const requestConfig = {
            method: method,
            url: baseURL + url,
            headers: customHeaders,
        }
        requestConfig.data = _req ? _req : null
        try {
            const response = await axios(requestConfig)
            if (response.headers.get("Sync"))
                return response.headers.get("Sync")
            if (response.headers.get("Token")) {
                let rtoken = response.headers.get("Token")
                Authentication.refreshToken(rtoken)
            }
            if (response.status === 204) // No Content
                return true
            return AesEncryption.decrypt(response.data, debug)
        } catch (err) {
            if (axios.isAxiosError(err)) {
                if (err.response?.status === 401) {
                    if (
                        window.location.href.indexOf('/logout') === -1 &&
                        window.location.href.indexOf('/expired') === -1
                    ) {
                        window.location.assign('/expired')
                    }
                }
                else {
                    const _err = err
                    throw AesEncryption.decrypt(_err.response.data, url, debug)
                }
            } else throw err
        }
    }
}

export default API;
import React, { useContext, useEffect, useState } from 'react'
import DOWSelectorComponent from '../../../calendar/components/calendar_events/DOWSelectorComponent'
import WorkFlowConfigurationContext from '../../../workflow/configuration/WorkFlowConfigurationProvider'
import ManageTaskContainer from '../../../workflow/configuration/facility/wizard/Commons/ManageTaskContainer'
import ToDoSelector from '../triggers/Components/ToDoSelector'

export default function ToDoListAllocateEventTaskLineItem({ onChanged, task, facility, user, onDelete, onSetProperty, config, errors, values, setValue }) {
    const PROP_NAME = "ALLOCATE_TO_DO"
    const _handleCreateName = (P) => {
        var name = 'Allocate a to do list'
        if (P.event)
            name = 'Log ' + P.event + ' to patients history'
        return name

    }
    const [props, setProps] = useState({})
    const { setTaskProperty, loadTaskProperties } = useContext(WorkFlowConfigurationContext)

    const _handleSetProperty = (k, value) => //{ console.log('Set Props'); setProps({ ...props, [k]: value }) }
        setTaskProperty(props, _handleCreateName({ ...props, [k]: value }), task, PROP_NAME, { ...props, [k]: value })
            .then((r) => setProps({ ...props, [k]: value }))

    useEffect(() => { _handleLoadProps() }, [])

    const _handleLoadProps = async () =>
        setProps(await loadTaskProperties(task, PROP_NAME))

    return (
        <ManageTaskContainer Task={task} onDelete={onDelete}>
            <>
                <b>Allocate List</b>
                <ToDoSelector
                    mode='list'
                    defaultValue={props.listDetails}
                    onChange={(e) => _handleSetProperty('listDetails', e)}
                />
                <div className='row'>
                    <div className='col-6'>
                        <b>For the</b>
                        <select
                            onChange={(e) => _handleSetProperty('when', e.target.value)}
                            className='form-control form-control-sm'>
                            <option></option>
                            <option selected={props.when === 'Same Day'}>Same Day</option>
                            <option selected={props.when === 'Next Day'}>Next Day</option>
                            <option selected={props.when === 'Next Day Of Week'}>Next Day Of Week</option>
                        </select>
                        {
                            props.when && props.when === 'Next Day Of Week'
                                ?
                                <DOWSelectorComponent
                                    multi={false}
                                    defaultValue={props.CreateForWhenDOW}
                                    onChange={(e) => _handleSetProperty('CreateForWhenDOW', e)}
                                />
                                :
                                <></>
                        }

                    </div>
                    <div className='col-6'>
                        <div>
                            <b>On who's to do list</b>
                            <select
                                onChange={(e) => _handleSetProperty('recipient', e.target.value)}
                                className='form-control form-control-sm'>
                                <option></option>
                                <option selected={props.recipient && props.recipient === "Clinician" ? "selected" : ""}>Clinician</option>
                                <option selected={props.recipient && props.recipient === "Administrator" ? "selected" : ""}>Administrator</option>
                                <option selected={props.recipient && props.recipient === "All Clinicians in Facility" ? "selected" : ""}>All Clinicians in Facility</option>
                                <option selected={props.recipient && props.recipient === "All Administrators in Facility" ? "selected" : ""}>All Administrators in Facility</option>
                            </select>
                        </div>
                    </div>
                </div>
            </>
        </ManageTaskContainer>
    )
}

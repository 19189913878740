import React, { useState } from 'react'
import { Clipboard2, EyeFill, EyeSlashFill, Pen } from 'react-bootstrap-icons'
import StatsNotes from './StatsNotes'

export default function StatsSectionContainer({ label, children, eventId, isVisible }) {
    const [visible, setVisible] = useState(false)
    const [notes, setNotes] = useState([])
    const [newRef, setNewRef] = useState([])

    const _handleItemSeletecteed = (key) => {
        // setNewRef([...notes, { refKey: key }])
        setNewRef(key)
    }

    const _addEvents = (cmp) => {
        return {
            ...cmp,
            props: {
                ...cmp.props,
                onItemSeletected: (k) => _handleItemSeletecteed(k),
            },
        }
    }
    return (
        <div className={''.concat(isVisible ? 'show' : 'hide')}>
            <div className='d-flex justify-content-between align-items-center'>
                <div>{label}</div>
                <div className='d-flex align-items-center'>
                    <div onClick={() => setVisible(!visible)}>{visible ? <EyeFill className='ms-2' /> : <EyeSlashFill className='ms-2' />}</div>
                    <Clipboard2 onClick={() => setNewRef([{ source: 'label', key: label }])} className='ms-1' />
                </div>
            </div>
            <StatsNotes eventId={eventId} onChange={() => { }} notes={notes} newRef={newRef} />
            <div className={''.concat(visible ? 'd-block' : 'd-none')}>

                {_addEvents(children)}

            </div>
        </div>
    )
}

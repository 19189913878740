import React from 'react'

export default function RunTaskButtons({ isValid, index, length, onBack, onNext, onComplete, onCancel, CompletedActionText }) {
    return (
        <div className='flex-shrink-1' style={{ height: '25px' }}>
            <button className='btn' onClick={() => onCancel()}>Cancel</button>
            {index > 0 ? <button className='btn' onClick={() => onBack()}>Back</button> : <></>}
            {index + 1 === length
                ?
                <button onClick={() => onComplete()} className={'btn '.concat(!isValid ? 'disabled' : '')}>{CompletedActionText ? CompletedActionText : 'Done'}</button>
                :
                <button onClick={() => onNext()} className={'btn '.concat(!isValid ? 'disabled' : '')}>Next</button>
            }

        </div>
    )
}

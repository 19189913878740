import React, { useContext, useState } from 'react'
import { Files, FilesAlt } from 'react-bootstrap-icons'
import SimpleColourPicker from '../../../../../../components/colour/SimpleColourPicker'
import GenericIconPicker from '../../../../../../components/icons/GenericIconPicker'
import PriorityPicker from '../../../../../../components/priority/PriorityPicker'
import NotesContext from './NotesProvider'

export default function NoteEntry() {
    const { addNote } = useContext(NotesContext)
    const [note, setNote] = useState({})

    const _handleAddNote = (isPvt) => {
        var _note = note
        _note['isPrivate'] = isPvt
        addNote(_note)
        setNote({ note: '' })
    }
    return (
        <div>
            <b>Note:</b>
            <div className='d-flex'>
                <div>
                    <SimpleColourPicker popup={true} onChange={(e) => setNote({ ...note, colour: e })} />
                </div>
                <div>
                    <GenericIconPicker dropdown={true} onChange={(e) => setNote({ ...note, icons: JSON.stringify(e) })} />
                </div>
                <div>
                    <PriorityPicker onChange={(e) => setNote({ ...note, priority: e })} />
                </div>
            </div>
            <textarea value={note.note} onChange={(e) => setNote({ ...note, note: e.target.value })} className='form-control'></textarea>
            <button onClick={() => _handleAddNote(false)} className='btn m-2 shadow'><FilesAlt size={22} className='me-2' />Add Note</button>
            <button onClick={() => _handleAddNote(true)} className='btn m-2 shadow'><Files size={22} className='me-2' />Add Private Note</button>
        </div>
    )
}

import React, { Component } from 'react';
// import './custom.css';

import { TokenValidation } from './libs/authentication/TokenValidation';
import { StickyNavigationProvider } from './libs/providers/StickyNavigationProvider';
import { CommonFunctionsProvider } from './screens/common/CommonFunctionsProvider';
import { SessionScopeProvider } from './screens/common/SessionScopeProvider';
import './styles/default.scss';

export default class App extends Component {
  static displayName = App.name;

  render() {
    return (
      <TokenValidation>
        <StickyNavigationProvider>
          <CommonFunctionsProvider>
            <SessionScopeProvider>
 
            </SessionScopeProvider>
          </CommonFunctionsProvider>
        </StickyNavigationProvider>
      </TokenValidation>
    );
  }
}

import React, { useState } from 'react'
import { useParams } from 'react-router-dom'
import { CalendarSyncProvider } from './CalendarSyncProvider'
import ExternalCalendarManagement from './ExternalCalendarManagement'
import { PROVIDERS } from './config/CalendarSyncProviders'

export default function CalendarSyncConfiguration() {
    const [active, setActive] = useState()

    const { id } = useParams()

    return (
        <CalendarSyncProvider defaultConfiguration={id}>
            <div>
                <h1 className='text-center'>Calendar Configuration</h1>
                <p></p>
                <div className='d-flex justify-content-evently h-100'>
                    <div className='w-100 text-center bg-primary-container m-3 rounded '>
                        <ExternalCalendarManagement screen={active} />

                    </div>
                    <div style={{ cursor: 'pointer' }} className='w-100 bg-secondary-container rounded shadow m-3'>
                        {
                            PROVIDERS.map((p) =>
                                <div onClick={() => setActive(p.config)}>
                                    {p.icon}
                                    {p.description}
                                </div>
                            )
                        }
                    </div>
                </div>
            </div>
        </CalendarSyncProvider>
    )
}

import React, { useEffect, useState } from 'react'
import MessagingService from '../../../../../../../libs/api/services/MessagingService'

export default function WhatsAppWizardConnectionInfo({ title, description, navigation, errors, setValue, values }) {

    const [properties, setProperties] = useState([])

    useEffect(() => {
        title('Connection Info')
        description('Enter the connection details for this service provider')
        navigation([
            {
                text: 'Back', type: 'Back', action: async () => { }
            },
            { text: 'Next', type: 'Next', action: async () => { } }
        ])
        _loadSettings()
    }, [])

    const getSetting = (type) => {
        var property = { value: '' }
        var _property = undefined
        if (properties && properties.length > 0)
            _property = properties.find(f => f.propertyType.type === type)
        return _property ? _property : property
    }

    const _loadSettings = () => {
        MessagingService
            .GetMessagingServiceProperties(values.configurationId)
            .then((r) => {
                setProperties(r)
            })
            .catch((e) => {
                errors(e.message)
                // setValue({ 'serviceProviderId': undefined })
            })
    }

    const _handleSetValue = (id, value) => {
        MessagingService
            .SetMessagingServiceProperty(values.configurationId, id, value)
            .catch((e) => errors(e.message))
    }

    return (
        <div className='p-3'>
            <div className='row bg-primary-container shadow p-3 rounded'>
                <div>
                    API Key
                    <input
                        defaultValue={getSetting('WHATSAPP_API_KEY').value}
                        onChange={(e) => _handleSetValue('WHATSAPP_API_KEY', e.target.value)}
                        className='form-control' placeholder='API Key' />
                </div>
                <div>
                    API Base URL
                    <input
                        defaultValue={getSetting('WHATSAPP_BASE_URL').value}
                        onChange={(e) => _handleSetValue('WHATSAPP_BASE_URL', e.target.value)}
                        className='form-control' placeholder='API Base URL' />
                </div>
                <div>
                    Sender
                    <input
                        defaultValue={getSetting('WHATSAPP_SENDER').value}
                        onChange={(e) => _handleSetValue('WHATSAPP_SENDER', e.target.value)}
                        className='form-control' placeholder='Sender' />
                </div>
            </div>
        </div>
    )
}

import React, { useEffect, useState } from 'react'
import { Check2Square, Square } from 'react-bootstrap-icons'
import Questionnaire from '../../../../../../libs/api/services/Questionnaire'

export default function QBuilderSectionTitleOptions({ questionnaireId }) {
    const [titleMode, setTitleMode] = useState({ type: 'Show', details: '' })

    const _handleSetOption = (option) => {
        Questionnaire
            .SetOption(questionnaireId, {
                name: 'SHOW_SECTION_TITLE',
                value: JSON.stringify(option)
            })
            .then((r) => {
                //setTitleMode(r)
                setTitleMode(option)
            }).catch(() =>{})
    }
    const _handleGetOption = () => {
        Questionnaire
            .GetOption(questionnaireId, 'SHOW_SECTION_TITLE')
            .then((r) => {
                setTitleMode(JSON.parse(r.value))
            }).catch(() =>{})
    }

    useEffect(() => {
        _handleGetOption()
    }, [])
    return (
        <div className='text-start m-3'>
            <div className='p-2 rounded '>
                <label><b>Section Titles</b></label>
                <div onClick={(e) => _handleSetOption({ type: 'Show' })} className={' p-2 rounded m-2  '.concat(titleMode.type === 'Show' ? 'shadow bg-secondary-container ' : 'opacity-75 ')}>
                    {
                        titleMode.type === 'Show'
                            ?
                            <Check2Square className='me-2' />
                            :
                            <Square className='me-2' />
                    }
                    Show section titles</div>
                <div onClick={(e) => _handleSetOption({ type: 'Hide' })} className={' p-2 rounded m-2  '.concat(titleMode.type === 'Hide' ? 'shadow bg-secondary-container ' : 'opacity-75 ')}>
                    {
                        titleMode.type === 'Hide'
                            ?
                            <Check2Square className='me-2' />
                            :
                            <Square className='me-2' />
                    }
                    Don't show section titles</div>
            </div>
        </div>
    )
}

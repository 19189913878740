import React, { useContext, useState } from 'react'
import CalendarContext from '../../CalendarProvider'

export default function CalendarMonthWeekDay({ dow }) {
    const { events, _findEventForWeek } = useContext(CalendarContext)
    const [eventsForDay, setEventsForDay] = useState([])

    return (
        <td onClick={() => {}} key={dow} className='calendar-dow-header-day'>{dow}</td>
    )
}

import React from 'react'
import WorkFlowDashboardLink from '../../workflow/common/WorkFlowDashboardLink'

export default function DashboardWorkFlowCard({ title, links, patient }) {
    return (
        <div className='col-lg-4 col-md-12 dashboard-card'>
            <div className='text-center bg-secondary text-light p-3 rounded h-100'>
                <h3>{title}</h3>
                <div className='text-start'>
                    {
                        // links && links.map((r) => <div className='p-1 rounded d-flex align-items-center' style={{ cursor: 'pointer', background: r.colour }}>{r.icon && r.icon.length > 0 && ICONS.find((c) => c.name === r.icon[0]) ? ICONS.find((c) => c.name === r.icon[0]).icon : <></>}<div className='ms-2'>{r.link}</div></div>)
                        links && links.map((r) => <WorkFlowDashboardLink r={r} options={{
                            patient: patient
                        }} />)
                    }
                </div>
            </div>
        </div>
    )
}

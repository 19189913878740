import React, { useContext, useState } from 'react'
import { SectionInterface } from '../interfaces/SectionsInterface'
import VariablesContext from '../context/VariablesContext'
import ErrorContext from '../context/ErrorContext'
import { LayoutInterface } from '../interfaces/LayoutInterface'
type Props = {
  flow?: 'Wizard' | 'Form' | 'ConfigForm' | undefined
  onNavigate: (element: string | null) => void
  defaultSection: string
  sections: SectionInterface[] | undefined
  // children: React.ReactNode
}

const NavigationBar = (props: Props) => {
  const [section, setSection] = useState<string | null>(props.defaultSection)
  const { variables } = useContext(VariablesContext)
  const { invokeError, errorsMap } = useContext(ErrorContext)
  if (props.sections?.length == 1) return <></>
  const _findNext = () => {
    let next: string | null = ''
    for (let i = 0; i < props.sections!.length - 1; i++) {
      next =
        i === props.sections!.length ? null : props.sections![i].name === section ? props.sections![i + 1].name : next
    }
    return next
  }
  const _validateAll = (_section: SectionInterface, _layout: LayoutInterface[]) => {
    for (const m of Object.keys(errorsMap!)) if (!(typeof errorsMap![m] === 'undefined')) throw ''

    for (const _item of _layout)
      if (Object.keys(_item).includes('layout')) _validateAll(_section, _item.layout!)
      else if (Object.keys(_item).includes('validation')) {
        for (const r of _item.validation!)
          if (variables!.size == 0) throw { message: { errors: { [_item.name]: r.error } } }
          else if (typeof variables!.get(_section.name).get(_item.name) === 'undefined')
            throw { message: { errors: { [_item.name]: r.error } } }
          else if (!new RegExp(r.rule).test(variables!.get(_section.name).get(_item.name)))
            throw { message: { errors: { [_item.name]: r.error } } }
      }
  }
  const _handleNavigate = (_section: SectionInterface) => {
    const _next = _findNext()
    try {
      // Validation
      _validateAll(_section!, _section!.layout!)

      props.onNavigate(_next)
      setSection(_next)
    } catch (err: any) {
      invokeError!(err.message ? err.message : err)
    }
  }
  switch (props.flow) {
    case 'Wizard': {
      let i: number = 0
      for (const o of props.sections!) {
        i++

        if (i === props.sections?.length)
          if (props.sections![i - 1].navigation! === 'hide') {
            return <></>
          } else {
            return (
              <button onClick={() => _handleNavigate(props.sections![i - 1])} className='btn ng-buttons'>
                Finish
              </button>
            )
          }
        else if (o.name === section) {
          if (props.sections![i - 1].navigation! === 'hide') {
            return <></>
          } else {
            return (
              <button className='btn ng-buttons' onClick={() => _handleNavigate(props.sections![i - 1])}>
                Next
              </button>
            )
          }
        }
      }
      return (
        <button className='btn bg-buttons' onClick={() => _handleNavigate(props.sections![i - 1])}>
          Next
        </button>
      )
    }
  }
  return <></>
}
export default NavigationBar

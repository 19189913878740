import React, { useEffect, useState } from 'react'
import MessageTab from '../../../messaging/management/MessageTab'
import Messaging from '../../../../../libs/api/services/Messaging'

export default function PatientDMSMessages({ eventId }) {
    const [messages, setMessages] = useState([])

    const _handleGetLinkedMessages = () => {
        Messaging
            .ListLinkedMessages(eventId)
            .then((r) => {
                setMessages(r)
            })
    }

    useEffect(() => {
        _handleGetLinkedMessages()
    }, [])

    return (


        <ul className='list-unstyled text-primary' style={{ zIndex: 100, overflow: 'auto', height: '75vh' }}>
            {
                messages.map((m) => {
                    return (
                        <li>
                            <MessageTab
                                extended={true}
                                message={m.message}
                            />
                        </li>
                    )
                })
            }
        </ul>

    )
}


const AppRoutesFacilityConfiguration = [

  /*
  Super Faclity 
  */
  /*
  Super Faclity 
  */
  // {
  //   path: '/Facility/Configuration',
  //   element: <ClaimsFilter enforce={true} claim={"Super"}><PatientManagementLayout menu={<MenuContainer key={"menu_cnt" + new Date().getTime()} menu="UserConfiguration" activeSection="Facility Configurations" />} container={<FacilityManagementScreen />} /></ClaimsFilter>
  // },
  // {
  //   path: '/Super',
  //   element: <ClaimsFilter enforce={true} claim={"Super"}><PatientManagementLayout nofilter menu={<MenuContainer key={"menu_cnt" + new Date().getTime()} menu="UserConfiguration" activeSection="Facility Configurations" />} container={<FacilityManagementScreen />} /></ClaimsFilter>
  // },
  // {
  //   path: '/Facility/Configuration/Events',
  //   element: <ClaimsFilter enforce={true} claim={"Super"}><PatientManagementLayout nofilter menu={<MenuContainer key={"menu_cnt" + new Date().getTime()} menu="UserConfiguration" activeSection="Facility Configurations" />} container={<WorkflowWizard />} /></ClaimsFilter>
  // },
  // {
  //   path: '/Facility/Configuration/Messaging',
  //   element: <ClaimsFilter enforce={true} claim={"Super"}><PatientManagementLayout nofilter menu={<MenuContainer key={"menu_cnt" + new Date().getTime()} menu="UserConfiguration" activeSection="Facility Configurations" />} container={<FacilityMessagingConfigurationWizard />} /></ClaimsFilter>
  // },
  // {
  //   path: '/Facility/Configuration',
  //   element: <ClaimsFilter
  //     enforce={true} claim={"Super"}><ConfigLayout
  //       search={<FacilityManagementSearchBar />}
  //       menu={<MenuContainer key={"menu_cnt" + new Date().getTime()}
  //         menu="UserConfiguration" activeSection="Facility Configurations"
  //       />}
  //       breadcrums={[{ name: 'Facility Administration', location: "/" + Authentication.getFacilityRole() + '/Configuration' },
  //       { name: 'Facility', location: '/Facility/Configuration/Users', active: true },
  //       ]}
  //       container={<FacilityManagementScreen />} />
  //   </ClaimsFilter>
  // },
  // Enable this for new user management flow
  // {
  //   path: '/Facility/Configuration/Users',
  //   element: <ClaimsFilter
  //     enforce={true} claim={"Super"}><ConfigLayout
  //       search={<FacilityManagementSearchBar section={`User Management for ${Authentication.getFacilityName()}`} />}
  //       menu={<MenuContainer key={"menu_cnt" + new Date().getTime()}
  //         menu="UserConfiguration" activeSection="Facility Configurations"
  //       />}
  //       breadcrums={[{ name: 'Facility Administration', location: "/" + Authentication.getFacilityRole() + '/Configuration' },

  //       { name: 'Users', location: '/Facility/Configuration/Users', active: true },
  //       ]}
  //       container={
  //         // <FacilityManagementScreen />
  //         <UserManagementScreen />
  //       } />
  //   </ClaimsFilter>
  // },

  // {
  //   path: '/Facility/Configuration/TNC',
  //   // element: <ClaimsFilter enforce={true} claim={"System Admin,Clinician"}><ClinicianManagementLayout menu={<MenuContainer key={"menu_cnt" + new Date().getTime()} menu="UserConfiguration" activeSection="" />} container={<FacilityUserManagement />} /></ClaimsFilter>
  //   element: <ClaimsFilter enforce={true} claim={"System Admin, Super"}>
  //     <AdminLayout key={"AL_" + new Date().getTime()}
  //       menu={<MenuContainer key={"menu_cnt" + new Date().getTime()}
  //         menu="UserConfiguration" activeSection="Users" />}
  //       search={<FacilityManagementSearchBar section={`Managing terms and conditions for ${Authentication.getFacilityName()}`} />}
  //       breadcrums={[

  //         { name: 'Facility Administration', location: "/" + Authentication.getFacilityRole() + '/Configuration' },
  //         { name: Authentication.getFacilityName(), location: '/Facility/Configuration' },
  //         { name: 'Terms and conditions', location: 'Facility/Configuration', active: true }]}
  //       container={<ManageFacilityTnCs facility={Authentication.getFacility()} />} /></ClaimsFilter>
  // },

  // {
  //   path: '/Facility/Configuration/Users',
  //   // element: <ClaimsFilter enforce={true} claim={"System Admin,Clinician"}><ClinicianManagementLayout menu={<MenuContainer key={"menu_cnt" + new Date().getTime()} menu="UserConfiguration" activeSection="" />} container={<FacilityUserManagement />} /></ClaimsFilter>
  //   element: <ClaimsFilter enforce={true} claim={"System Admin, Super"}>
  //     <AdminLayout key={"AL_" + new Date().getTime()}
  //       menu={<MenuContainer key={"menu_cnt" + new Date().getTime()}
  //         menu="UserConfiguration" activeSection="Users" />}
  //       search={<UserSearchBar noAdd facility={Authentication.getFacility()} />}
  //       breadcrums={[

  //         { name: 'Facility Administration', location: "/" + Authentication.getFacilityRole() + '/Configuration' },
  //         { name: Authentication.getFacilityName(), location: '/Facility/Configuration' },
  //         { name: 'Users', location: 'Facility/Configuration', active: true }]}
  //       container={<FacilityUserManagement />} /></ClaimsFilter>
  // },
  // {
  //   path: '/Facility/Configuration/Users/Add',
  //   // element: <ClaimsFilter enforce={true} claim={"Super"}><PatientManagementLayout nofilter menu={<MenuContainer key={"menu_cnt" + new Date().getTime()} menu="UserConfiguration" activeSection="Facility Configurations" />} container={<RegisterUser />} /></ClaimsFilter>
  //   element: <ClaimsFilter
  //     enforce={true}
  //     claim={"System Admin,Clinician,Super"}>
  //     <ConfigLayout key={"AL_" + new Date().getTime()}
  //       menu={<MenuContainer key={"menu_cnt" + new Date().getTime()}
  //         menu="UserConfiguration" activeSection="Users" />}
  //       search={<FacilityManagementSearchBar />}
  //       breadcrums={[{ name: 'Facility Administration', location: "/" + Authentication.getFacilityRole() + '/Configuration' },
  //       { name: 'Users', location: '/' + Authentication.getFacilityRole() + '/Configuration/Users', active: false },
  //       { name: 'Add', location: '/' + Authentication.getFacilityRole() + '/Configuration', active: true }
  //       ]}

  //       container={<RegisterUser facility={Authentication.getFacility()} />} />
  //   </ClaimsFilter>
  // },
  // {
  //   path: '/Facility/Configuration/users/:id',
  //   element: <ClaimsFilter
  //     enforce={true} claim={"ystem Admin,Clinician,Admin,Super"}>
  //     <ConfigLayout key={"AL_" + new Date().getTime()}
  //       menu={<MenuContainer key={"menu_cnt" + new Date().getTime()} menu="UserConfiguration" activeSection="Users" />}
  //       // search={<UserSearchBar facility={Authentication.getFacility()} />}
  //       search={<FacilityManagementSearchBar section={'         '} />}
  //       container={<UserAdminEdit />}
  //       breadcrums={[{ name: 'Facility Administration', location: "/" + Authentication.getFacilityRole() + '/Configuration' },
  //       { name: 'Users', location: "/" + Authentication.getFacilityRole() + '/Configuration/Users', active: false },
  //       { name: '[UserName]', location: '/Facility/Configuration/users/[UserID]' }, { name: 'Manage', location: '/Facility/Configuration/users/add', active: true }]}
  //     /></ClaimsFilter>
  // },
  // {
  //   path: '/Super',
  //   element: <ClaimsFilter enforce={true} claim={"Super"}>
  //     <ConfigLayout
  //       breadcrums={[{ name: 'Facility Administration', location: "/" + Authentication.getFacilityRole() + '/Configuration' },]}
  //       nofilter
  //       menu={<MenuContainer key={"menu_cnt" + new Date().getTime()} menu="UserConfiguration" activeSection="Facility Configurations" />}

  //       container={<FacilityManagementScreen />}

  //     /></ClaimsFilter>
  // },
  // {
  //   path: '/Super/Configuration',
  //   element: <ClaimsFilter enforce={true} claim={"Super"}>
  //     <ConfigLayout
  //       search={<FacilityManagementSearchBar section={`${Authentication.getFacilityName()} Configuration`} />}
  //       breadcrums={[{ name: 'Facility Administration', location: "/" + Authentication.getFacilityRole() + '/Configuration' },]}
  //       nofilter menu={<MenuContainer key={"menu_cnt" + new Date().getTime()} menu="UserConfiguration" activeSection="Facility Configurations" />} container={<FacilityManagementScreen />} />
  //   </ClaimsFilter>
  // },
  // {
  //   path: '/Facility/Configuration/Events',
  //   element: <ClaimsFilter enforce={true} claim={"Super"}>
  //     <ConfigLayout
  //       search={<FacilityManagementSearchBar />}

  //       nofilter menu={<MenuContainer key={"menu_cnt" + new Date().getTime()} menu="UserConfiguration"
  //         activeSection="Facility Configurations"
  //       />}
  //       breadcrums={[{ name: 'Facility Administration', location: "/" + Authentication.getFacilityRole() + '/Configuration' },
  //       { name: 'Facility', location: '/Facility/Configuration', active: false },
  //       { name: 'Work Flows', location: '/Facility/Configuration/Messaging', active: true },
  //       ]}
  //       container={<WorkflowWizard />} /></ClaimsFilter>
  // },
  // {
  //   path: '/Facility/Configuration/Messaging',
  //   element: <ClaimsFilter enforce={true} claim={"Super"}>
  //     <ConfigLayout
  //       search={<FacilityManagementSearchBar />} nofilter
  //       menu={<MenuContainer key={"menu_cnt" + new Date().getTime()}
  //         menu="UserConfiguration" activeSection="Facility Configurations" />}
  //       breadcrums={[{ name: 'Facility Administration', location: "/" + Authentication.getFacilityRole() + '/Configuration' },
  //       { name: 'Facility', location: '/Facility/Configuration', active: false },
  //       { name: 'Message Service Providers', location: '/Facility/Configuration/Messaging', active: true },
  //       ]}
  //       container={<FacilityMessagingConfigurationWizard

  //       />} /></ClaimsFilter>
  // },
  // {
  //   path: '/Facility/Configuration/Templates',
  //   element: <ClaimsFilter enforce={true} claim={"Super"}>
  //     <ConfigLayout
  //       search={<FacilityManagementSearchBar />} nofilter
  //       menu={<MenuContainer key={"menu_cnt" + new Date().getTime()}
  //         menu="UserConfiguration" activeSection="Facility Configurations" />}
  //       breadcrums={[{ name: 'Facility Administration', location: "/" + Authentication.getFacilityRole() + '/Configuration' },
  //       { name: 'Facility', location: '/Facility/Configuration', active: false },
  //       { name: 'Message Templates', location: '/Facility/Configuration/Templates', active: true },
  //       ]}
  //       container={<MessagingTemplateAdmin startStep={1} />} /></ClaimsFilter>
  // },
  // {
  //   path: '/Facility/Messages',
  //   element: <ClaimsFilter enforce={true} claim={"System Admin,Clinician,Admin"}>
  //     <ConfigLayout menu={<MenuContainer key={"menu_cnt" + new Date().getTime()} menu="Clinician" activeSection="Messages" />} container={<MessageManagement />} breadcrums={[{ name: 'Home', location: '/Clinician' }, { name: 'Messages', location: '/Clinician/Messages', active: true }]} />
  //     </ClaimsFilter>
  // },
  // {
  //   path: '/Facility/Configuration/Templates',
  //   element: <ClaimsFilter enforce={true} claim={"Super"}>
  //     <ConfigLayout
  //       search={<FacilityManagementSearchBar />} nofilter
  //       menu={<MenuContainer key={"menu_cnt" + new Date().getTime()}
  //         menu="UserConfiguration" activeSection="Facility Configurations" />}
  //       breadcrums={[{ name: 'Facility Administration', location: "/" + Authentication.getFacilityRole() + '/Configuration' },
  //       { name: 'Facility', location: '/Facility/Configuration', active: false },
  //       { name: 'Message Templates', location: '/Facility/Configuration/Templates', active: true },
  //       ]}
  //       container={<MessagingTemplateAdmin startStep={1} />} /></ClaimsFilter>
  // },
  // {
  //   path: '/Facility/Configuration/Calendar',
  //   element: <ClaimsFilter enforce={true} claim={"System Admin,Clinician,Admin,Super"}><ConfigLayout
  //     search={<FacilityManagementSearchBar />} menu={<MenuContainer key={"menu_cnt" + new Date().getTime()} menu="UserConfiguration" activeSection="Facility Configurations" />} container={<CalendarFacilityAdmin />}
  //     breadcrums={[{ name: 'Facility Administration', location: "/" + Authentication.getFacilityRole() + '/Configuration' },
  //     { name: 'Facility', location: '/Facility/Configuration', active: false },
  //     { name: 'Calendar', location: '/Facility/Configuration/Calendar', active: true },]}
  //   /></ClaimsFilter >
  // },
  // {
  //   path: '/Facility/Configuration/todo',
  //   element: <ClaimsFilter enforce={true} claim={"System Admin,Clinician,Admin,Super"}>
  //     <ConfigLayout
  //     search={<FacilityManagementSearchBar />} menu={<MenuContainer key={"menu_cnt" + new Date().getTime()} menu="UserConfiguration" activeSection="Facility Configurations" />} container={<ToDoListAdmin />}
  //     breadcrums={[{ name: 'Facility Administration', location: "/" + Authentication.getFacilityRole() + '/Configuration' },
  //     { name: 'Facility', location: '/Facility/Configuration', active: false },
  //     { name: 'To Do List', location: '/Facility/Configuration/todo', active: true },]}
  //   /></ClaimsFilter >
  // },
];

export default AppRoutesFacilityConfiguration;

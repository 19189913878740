import { faSpinner } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useState } from "react"

export default function ToDoStatusDropDown({ statuses, active, onChange }) {
  const [processing, setProcessing] = useState(false)
console.log('ToDoStatusDropDown',statuses,active)
  const _handleChangeStatus = async (e) => {
    e.stopPropagation();
    e.preventDefault();
    setProcessing(true)
    await onChange(e)
    setProcessing(false)
  }
  if (!statuses) return
  return (
    <>
      <b className="small">Current Status</b>
      {processing ? <div><FontAwesomeIcon icon={faSpinner} spin /></div> :
        <select
          onChange={_handleChangeStatus}
          className='form-control form-control-sm'>
          {statuses.map((s) =>
            <option selected={active && active.status && active.status.id === s.id ? 'selected' : ((!(active && active.status && active.status.id)) && s.isDefault)} key={'status' + s.id} value={s.id}>{s.item}</option>)
          }
        </select>
      }
    </>
  )
}

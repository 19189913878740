import React from 'react'
import SimpleColourPicker from '../../../../../../../../components/colour/SimpleColourPicker'
import GenericIconPicker from '../../../../../../../../components/icons/GenericIconPicker'

export default function CustomizeEvent({ event, option, onSetProperty, properties }) {

    const _findValue = (v) => {
        if (event && event.additionalOptions)
            try { return JSON.parse(event.additionalOptions.find(o => o.option === v).value) } catch (e) { }
        return undefined
    }
    return (
        <div>
            <b>Additional Options</b>
            <div className='d-flex justify-content-between align-items-center'>
                <b>Colour: </b>
                <SimpleColourPicker
                    defaultValue={_findValue('COLOUR')}
                    popup={true} onChange={(c) => onSetProperty('colour', c)} />
            </div>
            <div className='d-flex justify-content-between align-items-center'>
                <b>Icon: </b>
                <GenericIconPicker
                    defaultValue={_findValue('ICONS')}
                    dropdown={true} onChange={(i) => onSetProperty('icons', i)} />
            </div>
        </div>
    )
}

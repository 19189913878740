/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'
import { Check2Square, InfoCircleFill, Journal, Pencil, PlusCircle, Square, Trash, UiChecksGrid } from 'react-bootstrap-icons'
import InputWithKeyFunctions from '../../../../../../components/inputs/InputWithKeyFunctions'
import FlowAssistant from '../../../../../../libs/api/services/FlowAssistant'
import WorkFlow from '../../../../../../libs/api/services/WorkFlow'
import QuickFlowTriggerItem from './workflow_item/QuickFlowTriggerItem'
import QuickFlowTriggerTasks from './workflow_item/QuickFlowTriggerTasks'

export default function WorkFlowEventItemComponent({ event, values, onReload, onShowLogs, onCreateWorkFlow, onSelected, onChangeTask, onChangeTrigger, active, selectOnly }) {
    const [showDetails, setShowDetails] = useState(false)
    const [newWorkFlow, setNewWorkFlow] = useState()
    useEffect(() => { if (showDetails) onSelected(event.id) }, [showDetails])

    const _handleChangeTask = (taskId) => { onChangeTask(event, taskId) }
    const _handleChangeTaskDetails = (task) => {
        try {
            const _values = { ...values, task: task }
            switch (task.taskType.type) {
                case 'Messaging': FlowAssistant.BookmarkFlowAndGoTo('/Facility/Configuration/Events', 'MANAGE_WORKFLOW_EVENT', _values, '/sys-admin/messaging/template'); break;
                case 'Generate A Form': FlowAssistant.BookmarkFlowAndGoTo('/Facility/Configuration/Events', 'MANAGE_WORKFLOW_EVENT', _values, 'sys-admin/form/builder'); break;
                case 'Send Questionnaire': FlowAssistant.BookmarkFlowAndGoTo('/Facility/Configuration/Events', 'MANAGE_WORKFLOW_EVENT', _values, 'sys-admin/questionnaires/builder'); break;
                case 'Create a new calendar event': _handleChangeTask(undefined); break;
                case 'Update a calendar event': _handleChangeTask(undefined); break;
                case 'Send a notification': _handleChangeTask(undefined); break;
                default: _handleChangeTask(undefined); break;
            }
        } catch (e) { }
        // onChangeTask(event, taskId)
    }
    const _handleChangeTrigger = (taskId) => { onChangeTrigger(event, taskId) }
    const _select = () => {
        onSelected(event)
    }
    const _handleShowLogs = (e) => {
        e.stopPropagation()
        return onShowLogs(event)
    }
    const _handleRemove = () => {
        WorkFlow
            .UpdateEventDetails(event.object.id, { ...event.object, isDeleted: true })
            .then((r) => {
                onReload()
            })
    }

    return (

        event.isNew
            ?
            <div key='fdas fdsafsa' onClick={(e) => { e.stopPropagation(); onSelected(event) }} style={{ cursor: 'pointer' }} className={'text-start p-2 rounded '.concat(active ? 'bg-primary-container' : 'bg-secondary-container')}>
                <b><PlusCircle /> Create a new work flow</b>
                <InputWithKeyFunctions onChange={(v) => setNewWorkFlow(v)} key={`Input-${event.object.rand}`} className='form-control form-control-sm ' defaultValue={event.asText} onCancel={() => { }} onSubmit={() => { _select() }} placeholder='Descriptive name for new event eg: Send patient appointment reminder' />
                <div className='text-center'>
                    <button onClick={() => onCreateWorkFlow(newWorkFlow)} className='btn btn-xs'>Create</button>
                </div>
            </div>
            :
            <div onClick={(e) => { e.stopPropagation();; onSelected(event) }} style={{ cursor: 'pointer' }} className={'text-start p-2 rounded '.concat(active ? 'bg-primary-container' : 'bg-secondary-container')}>
                <div className='d-flex' onClick={() => setShowDetails(!showDetails)}>
                    {
                        selectOnly
                            ?
                            <div><b className='d-flex align-items-center'>
                                {
                                    active
                                        ?
                                        <Check2Square className='me-1' />
                                        :
                                        <Square className='me-1' />
                                }
                                {event.result}</b></div>
                            :
                            <>
                                <div className='flex-grow-1'><b className='d-flex align-items-center'>
                                    <UiChecksGrid className='me-1' />{event.result}</b></div>
                                <div className='flex-shrink-1'><InfoCircleFill /></div>
                            </>
                    }

                </div>
                {
                    showDetails && !selectOnly
                        ?
                        <div className='p-1'>
                            <div>
                                <div className='d-flex'>
                                    <div className='flex-grow-1'><b>Event is triggered when : </b></div>
                                    <button onClick={(e) => { e.stopPropagation(); _handleChangeTrigger(undefined) }} className='flex-shrink-1 bg-primary-container p-1 rounded'>Change event triggers <Pencil /></button>
                                </div>
                                {
                                    event.object.triggers.map((t) =>
                                        <QuickFlowTriggerItem key={`WFEIC-${t.id}`}
                                            onChange={(p) => _handleChangeTrigger(p)}
                                            trigger={t} 
                                            />
                                    )
                                }
                            </div>
                            <div>
                                <div className='d-flex'>
                                    <div className='flex-grow-1'><b>When triggered, event will : </b></div>
                                    <button onClick={(e) => { e.stopPropagation(); _handleChangeTask(undefined) }} className='flex-shrink-1 bg-primary-container p-1 rounded'>Change event tasks <Pencil /></button>
                                </div>
                                <div className='m-1'>
                                    {
                                        event.object.tasks.map((t) =>
                                            <QuickFlowTriggerTasks key={`WFEICB-${t.id}`} onChange={(p) => _handleChangeTaskDetails(p)} task={t} />
                                        )
                                    }
                                </div>
                                <div className='text-center'>
                                    <button onClick={(e) => _handleShowLogs(e)} className='btn btn-xs'><Journal /> View logs</button>
                                    <button onClick={(() => _handleRemove())} className='btn btn-xs'><Trash /> Remove this workflow event</button>
                                </div>
                            </div>
                        </div>
                        :
                        <></>
                }

            </div>

    )
}

import API from "../API";

var PatientHistoryEvent = {
    async UpdateCategory(category) {
        var result = await API.process(
            `api/Patient/Event/Category`,
            'PUT',
            category
        ).catch((err) => {
            throw err;
        })

        return result
    },
    async ListCategories() {
        var result = await API.process(
            `api/Patient/Event/Categories`,
            'GET',
            null
        ).catch((err) => {
            throw err;
        })

        return result
    },
    async ListAllCategoriesEvents() {
        var result = await API.process(
            `api/Patient/Event/Profile/Category/Events/All`,
            'GET',
            null
        ).catch((err) => {
            throw err;
        })

        return result
    },
    async ListAllCategoriesEventsLinked(templateId) {
        var result = await API.process(
            `api/Patient/Event/Events/Linked/All/${templateId}`,
            // `api/Patient/Event/Profile/Category/Events/All`,
            'GET',
            null
        ).catch((err) => {
            throw err;
        })

        return result
    },
    async RemoveCategory(id) {
        var result = await API.process(
            `api/Patient/Event/Category/${id}`,
            'DELETE',
            null
        ).catch((err) => {
            throw err;
        })

        return result
    },
    //
    async AddEvent(categoryId, event) {
        var result = await API.process(
            `api/Patient/Event/Event/${categoryId}`,
            'PUT',
            {
                event: event
            }
        ).catch((err) => {
            throw err;
        })

        return result
    },
    async UpdateEvent(categoryId, eventId, event) {
        var result = await API.process(
            `api/Patient/Event/Event/${categoryId}`,
            'PUT',
            {
                id: eventId,
                event: event
            }
        ).catch((err) => {
            throw err;
        })

        return result
    },
    async ListPatientHistoryEvents(categoryId) {
        var result = await API.process(
            `api/Patient/Event/${categoryId}/Events`,
            'GET',
            null
        ).catch((err) => {
            throw err;
        })

        return result
    },
    async RemoveEvent(id) {
        var result = await API.process(
            `api/Patient/Event/Event/${id}`,
            'DELETE',
            null
        ).catch((err) => {
            throw err;
        })

        return result
    },
    async LinkCategoryEvent(categoryId, eventId) {
        var result = await API.process(
            `api/Patient/Event/Profile/Category/${categoryId}`,
            'POST',
            {
                id: eventId
            }
        ).catch((err) => {
            throw err;
        })

        return result
    },
    async ListCategoryEventLinks(categoryId) {
        var result = await API.process(
            `api/Patient/Event/Profile/Category/${categoryId}`,
            'GET',
            null
        ).catch((err) => {
            throw err;
        })

        return result
    },
    async ListCategoryEventLinksByEventId(eventId) {
        var result = await API.process(
            `api/Patient/Event/Category/Linked/Event/${eventId}`,
            'GET',
            null
        ).catch((err) => {
            throw err;
        })

        return result
    },
    async ListCategoryEventLinksGrouped(categoryId) {
        var result = await API.process(
            `api/Patient/Event/Profile/Category/grouped/${categoryId}`,
            'GET',
            null
        ).catch((err) => {
            throw err;
        })

        return result
    },
    async ListCategoryEventLinksGroupedWF(categoryId,WFID) {
        var result = await API.process(
            //`api/Patient/Event/Profile/Category/groupedWF/${categoryId}/${WFID}`,
            `api/Patient/Event/Profile/Category/groupedWF/${categoryId}/wf/${WFID}`,
            'GET',
            null
        ).catch((err) => {
            throw err;
        })

        return result
    },
    async RemoveCategoryEventLinks(linkId) {
        var result = await API.process(
            `api/Patient/Event/Profile/Category/Link/${linkId}`,
            'DELETE',
            null
        ).catch((err) => {
            throw err;
        })

        return result
    },
    async RemoveProfileEventLinkByCategory(CategoryId,EventId) {
        var result = await API.process(
            `api/Patient/Event/Profile/Link/${CategoryId}/${EventId}`,
            'DELETE',
            null
        ).catch((err) => {
            throw err;
        })

        return result
    },
}

export default PatientHistoryEvent;
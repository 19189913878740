import React, { useEffect, useRef, useState } from 'react'
import { Clipboard2PlusFill, PersonPlusFill } from 'react-bootstrap-icons'
import ToDoListAsistantSelection from './StartNewToDoList/ToDoListAsistantSelection'
import ToDoListSelection from './StartNewToDoList/ToDoListSelection'


export default function StartNewToDoListWizard({ onCancel, onComplete }) {
  const [step, setStep] = useState(0)
  const [properties, setProperties] = useState([])
  const nextRef = useRef()

  useEffect(() => {
    const _handleKeyDown = (e) => {
      switch (e.key) {
        case 'Enter': if (_handleValidateNext())
          _handleNext(); break;
        case 'Escape': if (onCancel) onCancel(); break;
        default: break;
      }
    }

    document.addEventListener('keydown', _handleKeyDown)
    return () => { document.removeEventListener('keydown', _handleKeyDown) }
  }, [properties])

  const SCREENS = [
    {
      icon: <Clipboard2PlusFill size={50} />,
      description: 'Select the to do list you would like to complete',
      component: <ToDoListSelection onSubmit={(i) => {
        if (_handleValidateNext())
          _handleNext();
      }} onSelected={(e) => {
        setProperties({ ...properties, list: e });
        if (nextRef.current) nextRef.current.focus()
      }} />
    },
    {
      icon: <PersonPlusFill size={50} />,
      description: 'Is someone else assisting you with this to do list?',
      component: <ToDoListAsistantSelection
        onSubmit={(e) => {
          setProperties({ ...properties, assisted: e });
          if (_handleValidateNext()) _handleNext()
        }}

        onSelected={(e) => { setProperties({ ...properties, assisted: e }); }} />
    }
  ]
  const _handleValidateNext = () => {
    console.log(properties)
    if (step === 0 && Object.keys(properties).includes('list')) return true
    if (step === 1 && Object.keys(properties).includes('assisted'))
      if (properties.assisted.assisted === false)
        return true
      else if (properties.assisted.user)
        return true

    return false
  }
  const _handleComplete = () => onComplete && onComplete(properties)
  const _handleNext = () => step < SCREENS.length - 1 ? setStep(step + 1) : step === SCREENS.length - 1 ? _handleComplete() : undefined

  const _handleValidateBack = () => step > 0
  const _handleBack = () => step > 0 && setStep(step - 1)

  return (
    <div className='text-center p-3 h-100 d-flex flex-column' style={{ maxHeight: '100%' }}>
      <div className='flex-grow-1 d-flex flex-column' style={{ overflow: 'auto', maxHeight: 'calc(100% - 50px)' }}>
        <div className='m-3'>
          {SCREENS[step].icon}
        </div>
        <div>
          <b>{SCREENS[step].description}</b>
        </div>
        <div style={{ overflow: 'auto' }} className='flex-grow-1'>
          {SCREENS[step].component}
        </div>
      </div>
      <div className='flex-shrink-1'>
        {_handleValidateBack() ? <button onClick={() => _handleBack()} className='btn bg-secondary-container'>Back</button> : <></>}
        <button onClick={(() => onCancel())} className='btn bg-secondary text-light'>Cancel</button>
        {_handleValidateNext() ? <button ref={nextRef} onClick={() => _handleNext()} className='btn bg-primary-container'>Next</button> : <></>}
      </div>
    </div>
  )
}

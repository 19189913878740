import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useRef, useState } from 'react';
import { EyeFill, EyeSlashFill } from 'react-bootstrap-icons';
import Authentication from '../../../libs/api/services/Authentication';
import ForgotPassword from './ForgotPassword';

export default function LoginUser({ expired }) {
    const [showPW, setShowPW] = useState(false)
    const [details, setDetails] = useState({})
    const [error, setError] = useState('')
    const [processing, setProcessing] = useState(false)
    const [mode, setMode] = useState('Login')
    const usernameRef = useRef()

    const _handleLogin = async () => {

        if (!processing) {
            setProcessing(true)
            await Authentication
                .login(details)
                .then((r) => {
                    setProcessing(false)
                }).catch((e) => {
                    setError(e.message)
                    setProcessing(false)
                })
        }
    }
    const _handleReset = async () => {
        setMode('SendReset')
        if (!processing) {
            setProcessing(true)
            await Authentication
                .ResetPasswordRequest(usernameRef.current.value)
                .then((r) => {
                    setProcessing(false)
                }).catch((e) => {
                    setError(e.message)
                    setProcessing(false)
                })
        }
    }

    const _handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            _handleLogin()
        }
    }

    // if(mode === 'SendReset')
    //     return(<div>
    //         <p>An email will be sent to you shortly with further details to reset your password</p>
    //     </div>)
    return (
        <div onKeyDown={(e) => _handleKeyDown(e)} className="Wizard center-all">
            <div className="head">
                <h1>{expired ? <div>Your session has expired</div> : 'Existing Profile ?'}</h1>
                {mode === 'SendReset' && !error && !processing && <p>An email will be sent to you shortly with further details to reset your password</p>}
                {/* <p>If you have already been registered on system please login below</p> */}
                <div className="error-details">{error}</div>
            </div>
            <div className="body"><div
                className=".section undefined">
                {mode !== 'SendReset' && <div className="col-12">
                    <div className="validation-container">
                        <label>User ID</label>
                        <input
                            ref={usernameRef}
                            onChange={(e) => setDetails({ ...details, IDNumber: e.target.value })}
                            className="wizard-input form-control"
                            placeholder="ID Number"
                        />
                        <div className="validation-details"></div>
                    </div>
                </div>}
                {
                    mode === 'Login' && mode !== 'SendReset'
                        ?
                        <div className="col-12">
                            <div className="validation-container">
                                <label>Password</label>
                                <div className='d-flex position-relative'>
                                    <input
                                        onChange={(e) => setDetails({ ...details, password: e.target.value })}
                                        type={showPW ? "text" : "password"} className="wizard-input form-control"
                                        placeholder="Password" />
                                    <button className='btn position-absolute' style={{ zIndex: 2, right: '0px' }} onClick={() => setShowPW(!showPW)}>
                                        {showPW ? <EyeSlashFill /> : <EyeFill />}
                                    </button>
                                </div>
                                <div className="validation-details">
                                </div>
                            </div>
                        </div>
                        :
                        <></>
                }

                <div className='col-12'>
                    {mode !== 'SendReset' && <ForgotPassword mode={mode} setMode={setMode} />}
                </div>
                {/* <div className="col-12">
                    <div className="validation-container">
                        <label>Alternative Login</label>
                        <input
                            className="wizard-input form-control"
                            placeholder="Alternative Login" />
                        <div className="validation-details">

                        </div>
                    </div>
                </div> */}
                <div className="navigation">
                    <div className="validation-container">
                        <div className="validation-details"></div>
                    </div>
                </div>
                <a className='text-light' target='_blank' href='/tnc'>DrData Ts & Cs</a>
            </div>
            </div>
            <div className="tail">
                {
                    processing
                        ?
                        <FontAwesomeIcon icon={faSpinner} spin />
                        :
                        <>

                            {mode === 'ForgotPW' && <button
                                onClick={(e) => setMode('Login')}
                                className="btn bg-secondary-btn">Cancel</button>}
                            {mode !== 'SendReset' ? <button
                                onClick={(e) => mode === 'ForgotPW' ? _handleReset() : _handleLogin()}
                                className="btn bg-secondary-btn">Next</button> :
                                <button
                                    onClick={(e) => {
                                        setMode('Login');
                                        setError()
                                    }}
                                    className="btn bg-secondary-btn">Ok</button>
                            }
                        </>
                }
            </div>
        </div>
    )

}

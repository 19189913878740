/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState, useTransition } from 'react'
import ErrorDialog from '../../../../../../components/dialogs/ErrorDialog'
import FlowAssistant from '../../../../../../libs/api/services/FlowAssistant'
import StickyNavigationContext from '../../../../../../libs/providers/StickyNavigationProvider'
import { CalendarFacilityProvider } from '../../../../calendar/facility/CalendarFacilityProvider'
import { WorkFlowConfigurationProvider } from '../../WorkFlowConfigurationProvider'
import WorkFlowLogs from './components/WorkFlowLogs'
import AddEventWizardEventTasks from './screens/AddEventWizardEventTasks'
import AddEventWizardEventTriggeredBy from './screens/AddEventWizardEventTriggeredBy'
import WorkFlowSetupType from './screens/WorkFlowSetupType'


export default function WorkflowWizard({ startStep, subsection }) {

    const [step, setStep] = useState(1)
    const [title, setTitle] = useState('')
    const [description, setDescription] = useState('')
    const [navigation, setNavigation] = useState([])
    const [errors, setErrors] = useState('')
    const [values, setValues] = useState({})
    const { setSticky, setPreventMessage } = useContext(StickyNavigationContext)
    const [isPending, startTransiion] = useTransition()


    //subsection
    const _handleSetTitle = (s) =>
        !subsection ? setTitle(s) : subsection.title(s)

    const _handleSetDescription = (s) =>
        !subsection ? setDescription(s) : subsection.description(s)

    const _handleSetError = (s) =>
        !subsection ? setErrors(s) : subsection.setErrors(s)

    const _handleSetValues = (s) => {
        setValues(s)
        if (subsection) {
            subsection.setValues(s)
        }
    }
    useEffect(() => {
        if(subsection)
            setValues({...values, ...subsection.values})
    },[subsection])
    const _handleSetNavigation = async (n) => {
        //alert(JSON.stringify(n))
        if (subsection) {
            subsection.navigation(n)
        } else {
            var _nav = []
            var history = FlowAssistant
                .GetLast()
            if (step === 0) {
                if (startStep)
                    setStep(startStep)

                if (history) {
                    var vs = {}
                    for (const k of Object.keys(history.details)) {
                        // const parsed = JSON.parse(history.details[k])
                        try {
                            vs[k] = JSON.parse(history.details[k])
                        } catch (e) {
                            vs[k] = history.details[k]
                        }
                        // setValues({ ...values, ...vs })
                    }
                }
            }
            if (history) {
                _nav.push({ type: 'Action', action: () => FlowAssistant.Goto(history.section), text: 'Cancel' })
            }

            _nav = [..._nav, ...n]
            // In flow jumping
            if (values.action && values.action.from) {
                // _nav = [{ text: 'Done', type: 'Step', step: values.action.from, action: async () => { _handleSetValues({ ...values, action: {} }) } }]
                _nav = [{ text: 'Back', type: 'Step', step: values.action.from, action: async () => { _handleSetValues({ ...values, action: {} }) } }]
                // setValues({ ...values, action: {} })
            }
            setNavigation(_nav)
        }
    }

    const _handleChangeStep = (s) => {
        startTransiion(() => {

            if (values.action) {

                setValues({ ...values, action: undefined })
            }
            setStep(s)
        })
    }

    useEffect(() => {
        if (step !== 1 && step !== 5) {
            setSticky(true)
            setPreventMessage('Configuration Incomplete', 'You have not completed the configuration process. <br/><br/>This can be completed later, however service will not be active until completed. <br/><br/>Are you sure you would like to cancel?')
        } else {
            setSticky(false)
        }
    }, [step])

    useEffect(() => {
        if (values.action && values.action.to) {
            try { setStep(values.action.to) } catch (e) { }
        }
    },
        [values]
    )

    const _getStepSection = () => {
        switch (subsection ? subsection.step : step) {
            case 1:
                return <WorkFlowSetupType
                    subsection={subsection}
                    title={(t) => _handleSetTitle(t)}
                    description={(t) => _handleSetDescription(t)}
                    navigation={(t) => _handleSetNavigation(t)}
                    navigator={{
                        goto: (s) => _handleChangeStep(s)
                    }}
                    errors={(e) => _handleSetError(e)}
                    setValue={(v) => _handleSetValues({ ...values, ...v })}
                    values={values}
                />
            case 2:
                return <AddEventWizardEventTriggeredBy
                    title={(t) => _handleSetTitle(t)}
                    description={(t) => _handleSetDescription(t)}
                    navigation={(t) => _handleSetNavigation(t)}
                    navigator={{
                        goto: (s) => _handleChangeStep(s)
                    }}
                    errors={(e) => _handleSetError(e)}
                    setValue={(v) => _handleSetValues({ ...values, ...v })}
                    values={values}
                />
            case 3:
                return <AddEventWizardEventTasks
                    subsection={subsection}
                    title={(t) => _handleSetTitle(t)}
                    description={(t) => _handleSetDescription(t)}
                    navigation={(t) => _handleSetNavigation(t)}
                    navigator={{
                        goto: (s) => _handleChangeStep(s)
                    }}
                    errors={(e) => _handleSetError(e)}
                    setValue={(v) => _handleSetValues({ ...values, ...v })}
                    values={values}
                />
            case 6:
                return <WorkFlowLogs
                    title={(t) => _handleSetTitle(t)}
                    description={(t) => _handleSetDescription(t)}
                    navigation={(t) => _handleSetNavigation(t)}
                    navigator={{
                        goto: (s) => _handleChangeStep(s)
                    }}
                    errors={(e) => _handleSetError(e)}
                    setValue={(v) => _handleSetValues({ ...values, ...v })}
                    values={values}
                />
            default:
                return <></>
        }
    }

    const _hasLast = async () => {
        const Last = await FlowAssistant
            .GetLast()
        if (Last) {
            switch (Last.section) {
                case 'NEW_EVENT_WORKFLOW_ADD_EVENT':
                case 'NEW_EVENT_WORKFLOW_GENERATE_FORM':
                case 'NEW_EVENT_WORKFLOW_SEND_QUESTIONNAIRE':
                    FlowAssistant.HasRedirected(Last)
                    _handleSetValues(Last.details)
                    setStep(3)
                    break;
                case 'MANAGE_WORKFLOW_EVENT':
                    FlowAssistant.HasRedirected(Last)
                    _handleSetValues(Last.details)
                    setStep(1)
                    break;
                default:
                    break;
            }
        }
    }

    useEffect(() => {
        _hasLast()
    }, [])
    return (
        <WorkFlowConfigurationProvider>
            <CalendarFacilityProvider>
                <ErrorDialog humanize={true} error={errors} onClose={() => setErrors('')} />
                <div
                    className="Wizard top-center position-relative m-3">
                    <div className="head"><h1>{!subsection ? title : ''}</h1>
                        <p>{!subsection ? description : ''}</p>

                        <div
                            className="error-details"></div>
                    </div>
                    <div className="body" style={{ overflow: subsection ? 'visible' : 'auto' }}>
                        <div
                            className=".section d-flex flex-column align-items-stretch h-100 w-100">
                            {_getStepSection()}
                        </div>
                    </div>
                    <div classname="tail">
                        {
                            navigation.map((n) => {
                                if (n.type === 'Next')
                                    return <button
                                        key={n.text}
                                        onClick={async (e) => {
                                            if (n.action) {
                                                try {
                                                    await n.action()
                                                        .catch(() => {
                                                            setErrors(e.message)
                                                            return
                                                        })
                                                } catch (e) {
                                                    return
                                                }
                                            }
                                            _handleChangeStep(step + 1)
                                        }} className="btn ng-buttons">{n.text}</button>
                                else if (n.type === 'Back')
                                    return <button
                                        key={n.text}
                                        onClick={async (e) => {
                                            if (n.action) {
                                                await n.action().catch((e) => setErrors(e.message))
                                            }
                                            if (values.action) {
                                                _handleChangeStep(values.action.from)
                                            } else {
                                                _handleChangeStep(step - 1)
                                            }
                                        }} className="btn ng-buttons">{n.text}</button>
                                else if (n.type === 'Action')
                                    return <button
                                        key={n.text}
                                        onClick={async (e) => {
                                            if (n.action)
                                                await n.action()
                                                    .catch((e) => setErrors(e.message))
                                        }} className="btn ng-buttons">{n.text}</button>
                                else if (n.type === 'Done')
                                    return <button
                                        key={n.text}
                                        onClick={async (e) => {
                                            if (n.action)
                                                await n.action()
                                                    .catch((e) => setErrors(e.message))
                                            _handleChangeStep(1)
                                        }} className="btn ng-buttons">{n.text}</button>
                                else if (n.type === 'Step')
                                    return <button
                                        key={n.text}
                                        onClick={async (e) => {
                                            if (n.action)
                                                await n.action()
                                                    .catch((e) => setErrors(e.message))
                                            _handleChangeStep(n.step)
                                        }} className="btn ng-buttons">{n.text}</button>
                                else return undefined
                            })
                        }
                    </div>
                </div>
            </CalendarFacilityProvider>
        </WorkFlowConfigurationProvider>
        // <div>
        //   <div>Select new / mange provider</div>
        //   <div>Select provider service type</div>
        //   <div>Setup Connection details</div>
        //   <div>REview</div>
        //   <div>Link to facility</div>
        // </div>
    )
}

import React from 'react'

export default function CronHourOptions({ properties, setProperties }) {
    return (
        <>
            <div className='col-3'>
                <select
                    defaultValue={properties && properties.hourOccourance}
                    onChange={(e) => setProperties({ ...properties, hourOccourance: e.target.value })
                    }
                    className='form-control form-control-sm'><option></option>
                    <option selected={properties.hourOccourance === 'Every Hour'}>Every Hour</option>
                    <option selected={properties.hourOccourance === 'Specific Hour'}>Specific Hour</option>
                </select>
            </div>
            <div className='col-3'>
                {
                    properties.hourOccourance === 'Specific Hour'
                        ?
                        <input
                            defaultValue={properties && properties.hourWhen}
                            onChange={(e) => setProperties({ ...properties, hourWhen: e.target.value })}
                            className='flex-grow-1 form-control form-control-sm' type='number'
                        />
                        : <></>
                }

            </div>
        </>
    )
}

import React, { useState } from 'react'
import RunTaskSectionOptionComponentWrapper from './RunTaskSectionOptionComponentWrapper'

export default function RunTaskSectionOptionComponent({ onValidate, section, index, length, onComplete, layout, setParameters }) {
    const [selected, setSelected] = useState()
    const _validate = (v) => { if (v !== '') onValidate(true) }
    /*
    {
            "type":"option",
            "style":"button",
            "options":
                [
                    {"name":"Main Account","value":"1","variableName":"accountId"},{"name":"Another Account","value":"2","variableName":"accountId"}
                ]
            }
    */
    const _handleSelectOption = (value) => {
        _validate(value)
        const _res = {}
        _res[layout.variableName] = value
        setParameters({ ..._res })
        setSelected(value)
    }

    return (
        <div>
            {
                <RunTaskSectionOptionComponentWrapper layout={layout} onChanged={(e) => _handleSelectOption(e)}>
                    <option></option>
                    {
                        layout.options ?
                            layout.options.map((o) => {
                                switch (layout.style) {
                                    case 'button':
                                        return <button className={'btn p-1 m-1 '.concat(selected === o.value ? 'shadow' : 'opacity-75')} key={o.name} onClick={() => _handleSelectOption(o.value)}>{o.name}</button>
                                    default:
                                        return <option selected={selected === o.value} key={o.name}>{o.name}</option>
                                }
                            })
                            : <></>
                    }
                </RunTaskSectionOptionComponentWrapper>
            }
        </div>
    )
}

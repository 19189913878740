import React from 'react'

export default function ForgotPassword({ mode, setMode }) {

    return (
        <div className='py-2'>

            {
                mode === 'ForgotPW' ?
                    <div>
                        <p>
                            <small>
                                To reset your password, please enter your ID Number above and click next
                            </small>
                        </p>
                    </div>
                    :
                    <button onClick={() => setMode('ForgotPW')} className='btn'>Forgot Password?</button>
            }
        </div>
    )
}

import React, { useContext, useEffect, useState } from 'react'
import { EyeFill, EyeSlashFill } from 'react-bootstrap-icons'
import { useParams } from 'react-router-dom'
import ToastDialog from '../../../../components/dialogs/toast_dialog/ToastDialog'
import FacilityUserInlineWidget from '../../../../components/facility/FacilityUserInlineWidget'
import Authentication from '../../../../libs/api/services/Authentication'
import User from '../../../../libs/api/services/User'
import CommonFunctionsContext from '../../../common/CommonFunctionsProvider'

export default function UserAdminEdit() {
    const { setSectionInfo } = useContext(CommonFunctionsContext)
    const [showPW, setShowPW] = useState(false)
    const [user, setUser] = useState([])
    const [error, setError] = useState([])
    const [status, setStatus] = useState('Update Details')
    const { id } = useParams();

    useEffect(() => {
        User
            .get(id)
            .then((r) => {
                setUser(r)
                setSectionInfo(`Managing ${r.firstName} ${r.lastName}`)
            })
            .catch((e) => {
                setError(e.message)
            })
        return (() => { setSectionInfo() })
    }, [])

    const _handleChangeValue = (key, value) => {
        let _user = user
        // setStatus('Save')
        setTimeout(setStatus, 3000, 'Update Details')
        if (!_user)
            _user = {}
        _user[key] = value
        //   onChangeUser(_user)
    }
    const _handleAddUser = async () => {
        setStatus('Saving')
        var result = await User.edit(id, user).catch((e) => console.error(e))
        if (result) {
            setStatus('Saved')
            setTimeout(setStatus, 3000, 'Update Details')
        }
        else
            setStatus('Save')
    }
    return (
        <div className='Wizard top-center position-relative'>
            {
                status === "Saved"
                    ?
                    <ToastDialog
                        time={10}
                        icon={'check'}
                        message={'User Details Updated'}
                        className='text-success' />
                    :
                    <></>
            }
            <div className="head">
                <h1>User Details</h1>
                <p></p>
                <div className="wizard-navigation-steps">
                    <span className="wizard-navigation-step in-progress"></span>
                    <span className="wizard-navigation-step "></span>
                </div>
                <div className="error-details">{error}</div>
            </div>
            <div className="body">
                <div className=".section undefined">
                    <div className="row">
                        <div className="validation-container">
                            <div className="row">
                                <div className="col-sm-2 col-md-4">
                                    <div className="validation-container">
                                        <label>Title</label>
                                        <select defaultValue={user?.title} onChange={(e) => _handleChangeValue('title', e.target.value)} className="form-control">
                                            <option selected={user?.title === "Mr" ? '"selected"' : ''} value="Mr">Mr</option>
                                            <option selected={user?.title === "Mrs" ? '"selected"' : ''} value="Mrs">Mrs</option>
                                            <option selected={user?.title === "Miss" ? '"selected"' : ''} value="Miss">Miss</option>
                                            <option selected={user?.title === "Dr" ? '"selected"' : ''} value="Dr">Dr</option>
                                            <option selected={user?.title === "Prof" ? '"selected"' : ''} value="Prof">Prof</option>
                                            <option selected={user?.title === "Other" ? '"selected"' : ''} value="Other">Other</option>
                                        </select>
                                        <div className="validation-details"></div>
                                    </div>
                                </div>
                                <div className="col-sm-10 col-md-4">
                                    <div className="validation-container">
                                        <label>First Name</label>
                                        <input defaultValue={user?.firstName} onChange={(e) => _handleChangeValue('firstName', e.target.value)} className="wizard-input form-control" placeholder="First Name" />
                                        <div className="validation-details"></div>
                                    </div>
                                </div>
                                <div className="col-sm-12 col-md-4">
                                    <div className="validation-container">
                                        <label>Last Name</label>
                                        <input defaultValue={user?.lastName} onChange={(e) => _handleChangeValue('lastName', e.target.value)} className="wizard-input form-control" placeholder="Last Name" />
                                        <div className="validation-details"></div>
                                    </div>
                                </div>
                                {/* <div className="col-6">
                                    <div className="validation-container">
                                        <label>Username</label>
                                        <input defaultValue={user?.username} onChange={(e) => _handleChangeValue('username', e.target.value)} className="wizard-input form-control" placeholder="Username" />
                                        <div className="validation-details"></div>
                                    </div>
                                </div> */}
                                <div className="col-6">
                                    <div className="validation-container">
                                        <label>ID / Passport Number</label>
                                        <input defaultValue={user?.idNumber} onChange={(e) => _handleChangeValue('IDNumber', e.target.value)} className="wizard-input form-control" placeholder="ID Number" />
                                        <div className="validation-details"></div>
                                    </div>
                                </div>
                                <div className="col-6"><div className="validation-container">
                                    <label>Password</label>
                                    <div className='d-flex position-relative'>
                                        <input
                                            defaultValue={user?.password} onChange={(e) => _handleChangeValue('password', e.target.value)}
                                            type={showPW ? "text" : "password"} className="wizard-input form-control"
                                            placeholder="Password" />
                                        <button className='btn position-absolute' style={{ zIndex: 2, right: '0px' }} onClick={() => setShowPW(!showPW)}>
                                            {showPW ? <EyeSlashFill /> : <EyeFill />}
                                        </button>
                                    </div>
                                    <div className="validation-details"></div>
                                </div>
                                </div>
                                <div className="col-6">
                                    <div className="validation-container">
                                        <label>Email Address</label>
                                        <input defaultValue={user?.emailAddress} onChange={(e) => _handleChangeValue('emailAddress', e.target.value)} className="wizard-input form-control" placeholder="Email Address" />
                                        <div className="validation-details"></div>
                                    </div>
                                </div>
                                <div className="col-6"><div className="validation-container">
                                    <label>Mobile Number</label>
                                    <input defaultValue={user?.mobileNumber} onChange={(e) => _handleChangeValue('mobileNumber', e.target.value)} className="wizard-input form-control" placeholder="Mobile Number" />
                                    <div className="validation-details"></div>
                                </div>
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-12 mt-3'>
                                    {
                                        Authentication.getFacility()
                                            ?
                                            <>
                                                <h3>Users roles in {Authentication.getFacilityName()}</h3>
                                                <FacilityUserInlineWidget id={id} facility={Authentication.getFacility()} />
                                            </>
                                            :
                                            <></>
                                    }

                                </div>
                            </div>
                            <div className="validation-details"></div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="tail"><button onClick={(e) => _handleAddUser()} className="btn ng-buttons">{status}</button></div>
        </div>
    )
}

import React, { Component } from 'react'
import LoginSectionLayout from './LoginSectionLayout'
import RegisterSectionLayout from './RegisterSectionLayout'

export default class HomeSectionLayout extends Component {
    render() {
        return (
            <>
                <div className='col-12 col-md-6 p-3 shadow shadow-4 rounded-md-end rounded-5'>
                    <LoginSectionLayout expired={this.props.expired} />
                </div>
                <div className='col-12 col-md-6 p-3'>
                    <RegisterSectionLayout />
                </div>
            </>
        )
    }
}

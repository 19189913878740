import React from 'react'
import Authentication from '../../../../libs/api/services/Authentication'
import DashboardContainer from '../../facility/management/components/DashboardContainer'

export default function NewUserComplete({ UserId, changeStep, defaultFacility }) {
    const _handleComplete = () => {
        if (defaultFacility)
            window.location = '/' + Authentication.getFacilityRole() + '/Configuration/users'
        else
            window.location = 'sys-admin/user/manage/' + UserId
    }
    return (
        <>
            <div className="head">
                <h1>New User Added</h1>
                {/* <p>New user has been added. Please click Finish to continue to the new users profile</p> */}
                <div className="wizard-navigation-steps">
                    <span className="wizard-navigation-step in-progress"></span>
                    <span className="wizard-navigation-step "></span>
                </div>
                <div className="error-details"></div>
            </div>
            <div className="body">
                <div className='row'>
                    <DashboardContainer Icon='add-user' title={'Add another user'} location={defaultFacility ? '/Facility/Configuration/Users/Add' : '/sys-admin/users/add'} />
                    <DashboardContainer Icon='user' title={'Go to new users profile'} location={defaultFacility ? '/Facility/Configuration/users/' + UserId : '/sys-admin/user/manage/' + UserId} />
                    {
                        !defaultFacility
                            ?
                            <DashboardContainer Icon='facility' title={'Manage users facilities and roles'} location={'/sys-admin/user/manage/' + UserId + '/facilities'} />
                            :
                            <></>
                    }

                </div>
            </div>
            <div className="tail"><button onClick={(e) => changeStep(4)} className="btn ng-buttons">Back</button>
            {/* <button onClick={(e) => _handleComplete()} className="btn ng-buttons">Finish</button> */}
            </div>
        </>
    )
}

import { faSpinner } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useEffect, useState } from 'react'
import { Clipboard2Check, InfoCircleFill } from 'react-bootstrap-icons'
import WorkFlow from '../../../../../../../libs/api/services/WorkFlow'
import WorkFlowTask from './WorkFlowTask'

export default function WorkFlowTasks({ eventId, onTaskSelected, patientId }) {
    const [tasks, setTasks] = useState([])
    const [loading, setLoading] = useState(true)

    const _loadTaskList = () => {
        WorkFlow
            .ListTaskFilterUser(eventId, patientId)
            .then((r) => { setTasks(r); setLoading(false) })
            .catch((e) => { })
    }

    useEffect(() => { _loadTaskList() }, [eventId])

    if (loading) return <div><FontAwesomeIcon icon={faSpinner} spin className='me-2' />Loading Task List</div>
    return (
        tasks.length > 0 ?
            <div className='h-100 w-100'>
                <div className='text-center'>
                    <Clipboard2Check size={45} />
                    <p>Please select a task you would like to complete</p>
                </div>
                <div className='' style={{ overflow: 'auto',height: 'calc(100% - 75px)' }}>
                    {
                        tasks.map((t) =>
                            <WorkFlowTask eventId={eventId} task={t} onTaskSelected={onTaskSelected} />
                        )
                    }
                </div>
            </div>
            :
            <div><InfoCircleFill className='m-1' />There are no tasks linked to this event</div>
    )
}

import {
    BarElement,
    CategoryScale,
    Chart as ChartJS,
    Legend,
    LineElement,
    LinearScale,
    PointElement,
    Tooltip,
} from 'chart.js';
import React, { useEffect, useState } from 'react';
import { ClockFill, ClockHistory } from 'react-bootstrap-icons';
import StatsLabelsComponent from './StatsComponents/StatsLabelsComponent';
import StatsLabelsGraph from './StatsComponents/StatsLabelsGraph';
import StatsSectionContainer from './StatsComponents/StatsSectionContainer';
import StatsSectionMeta from './StatsComponents/StatsSectionMeta';
import StatsWeightingGraph from './StatsComponents/StatsWeightingGraph';

ChartJS.register(
    LinearScale,
    CategoryScale,
    BarElement,
    PointElement,
    LineElement,
    Legend,
    Tooltip
);
ChartJS.defaults.color = '#fff';
export default function QuestionnaireStats({ QuestionnaireAssignmentId, QuestionnaireId, stats, eventId }) {
    const [activeLabels, setActiveLabels] = useState([])

    useEffect(() => {
        if (stats && stats.labels)
            setActiveLabels([...stats.labels])
    }, [stats])

    const _toggleLabels = (label) => {
        if (activeLabels.includes(label)) {
            var _activeLabels = [...activeLabels]
            _activeLabels.splice(_activeLabels.indexOf(label), 1)
            setActiveLabels(_activeLabels)
        } else {
            setActiveLabels([...activeLabels, label])
        }
    }

    const _checkLabelClass = (labels) => {
        if (stats.labels.length === 0 || activeLabels.length === stats.labels.length)
            return true;

        for (const l of labels)
            if (activeLabels.includes(l))
                return true;
        return false
    }

    return (
        stats
            ?
            <>
                <div className='m-2' > <h3>Capture Time</h3></div >
                <div className='row'>
                    <div className='col-4'><ClockFill /> Started: <div><small>{stats.startDateTime ? stats.startDateTime.substr(0, 16) : 'Not yet started'}</small></div></div>
                    <div className='col-4'><ClockFill /> Completed:<div><small>{stats.completeDateTime ? stats.completeDateTime.substr(0, 16) : 'Not yet completed'}</small></div></div>
                    <div className='col-4'><ClockHistory /> Time: <div><small>{stats.elappsedTime ? stats.elappsedTime : 'Still in progress'}</small></div></div>

                </div>
                <StatsSectionMeta meta={stats.meta} />
                <div className='m-2'><h3>Weighting</h3>
                    <StatsLabelsComponent meta={stats.meta} labels={stats.labels} activeLabels={activeLabels} onChange={(l) => _toggleLabels(l)} />
                    <StatsSectionContainer isVisible={true} eventId={eventId} label={'Sum by label'} >
                        <StatsLabelsGraph QuestionnaireAssignmentId={QuestionnaireAssignmentId} QuestionnaireId={QuestionnaireId} key={'StatsLabelsGraph1'} weighting={stats.weighting} activeLabels={activeLabels} labels={stats.labels} />
                    </StatsSectionContainer>
                    {
                        stats.weighting
                            ?
                            stats.weighting.map((o) =>
                                <StatsSectionContainer
                                    isVisible={_checkLabelClass(o.labels)}
                                    key={o.name} eventId={eventId} label={o.name} >
                                    <StatsWeightingGraph
                                        key={'StatsWeightingGraph' + o.name}
                                        onCheck={() => { }}
                                        isVisible={_checkLabelClass(o.labels)}
                                        o={o} />
                                </StatsSectionContainer>
                            )
                            :
                            <></>
                    }
                </div></>
            :
            <div>Loading Stats</div>

    )
}

import React, { useContext, useEffect, useState } from 'react'
import { OptionsGridOptionsInterface } from '../interfaces/opens/OptionsGridOptionsInterface'
import { APIFunctions } from '../../common/APIFunctions'
import ErrorContext from '../context/ErrorContext'

type Option = {
  id: string
  value: string
}

type Props = {
  options: string[] | OptionsGridOptionsInterface
  name: string
  defaultValue?: string
  onChange: (name: string, value: any) => void
  placeholder?: string
}

export default function OptionsGridComponent(props: Props) {
  const [active, setActive] = useState(props.defaultValue)
  const [options, setOptions] = useState<Option[]>([])
  const { invokeError } = useContext(ErrorContext)
  // const { variables } = useContext(VariablesContext)

  useEffect(() => {
    const _renderOptionsArray = () => {
      setOptions(
        (props.options as Array<string>).map(function (option) {
          return { id: option, value: option }
        }),
      )
    }

    const _options = async () => {
      const _details = props.options! as OptionsGridOptionsInterface
      switch (_details.method) {
        case 'API': {
          await APIFunctions.MakeCall(
            _details.params.url,
            _details.params.method,
            // APIFunctions.SetBody(vars, JSON.parse(JSON.stringify((_details.params! as APIActionInterface).body))),
            //APIFunctions.SetBody(vars, (props.results! as APIActionInterface).body), // Use local results only for this version
            undefined,
            _details.params.encryption,
          )
            .catch((err) => invokeError!(err.message ? err.message : err))
            .then((res) => {
              setOptions(
                res.map(function (item: any) {
                  return { id: item.id, value: item[_details.reference] }
                }),
              )
            })
          break
        }
      }
      return <div></div>
    }

    Array.isArray(props.options) ? _renderOptionsArray() : _options()
  }, [props.options])

  const _onOptionSeleceted = (value: any) => {
    setActive(value)
    props.onChange(props.name, value)
  }

  return (
    <>
      <div className='wizard-options-grid-container'>
        {options.map((option) => (
          <div
            className={'wizard-options-grid-container-option '.concat(active === option.id ? 'active' : 'inactive')}
            onClick={() => _onOptionSeleceted(option.id)}
            key={option.id}
          >
            {option.value}
          </div>
        ))}
      </div>
    </>
  )
}

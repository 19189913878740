import React, { useState } from 'react'
import { Square, SquareFill } from 'react-bootstrap-icons'
import './ToggleButton.css'

type Props = {
  name: string
  text?: string
  style?: string
  onChange: (n: string, v: any) => void
  defaultValue?: string
}

const ToggleButtonComponent = ({ name, text, defaultValue, style, onChange }: Props) => {
  const className = ['wizard-toggle-button']
  const [active, setActive] = useState<boolean>(String(defaultValue) == 'true')
  if (style) className.push(...style!.split(' '))

  const _handleToggleValue = () => {
    onChange(name, !active)
    setActive(!active)
  }

  return (
    <div onClick={() => _handleToggleValue()} className={className.join(' ').concat(active ? ' active' : ' inactive')}>
      <div className='holder'>
        {active ? <SquareFill size='30' /> : <Square size='30' />}
        {text}
      </div>
    </div>
  )
}

export default ToggleButtonComponent

import API from "../API";

var ToDoListService = {
    async ListToDoLists() {
        var result = await API.process(
            `api/ToDoList`,
            'GET',
            null
        ).catch((err) => {
            throw err;
        })

        return result
    },
    async CreateToDoList(Group) {
        var result = await API.process(
            `api/ToDoList`,
            'POST',
            Group
        ).catch((err) => {
            throw err;
        })

        return result
    },
    async RemoveToDoList(Group) {
        var result = await API.process(
            `api/ToDoList/${Group}`,
            'DELETE',
            null
        ).catch((err) => {
            throw err;
        })

        return result
    },
    async renameToDoList(ID, Group) {
        var result = await API.process(
            `api/ToDoList/Group/${ID}`,
            'PATCH',
            Group
        ).catch((err) => {
            throw err;
        })

        return result
    },
    async UpsertToDoListItem(GroupId, Item) {
        var result = await API.process(
            `api/ToDoList/${GroupId}/Item`,
            'PATCH',
            Item
        ).catch((err) => {
            throw err;
        })

        return result
    },
    async RemoveToDoListItem(ItemId) {
        var result = await API.process(
            `api/ToDoList/Item/${ItemId}`,
            'DELETE',
            null
        ).catch((err) => {
            throw err;
        })

        return result
    },
    async RenameToDoListItem(ItemId,item) {
        var result = await API.process(
            `api/ToDoList/Item/${ItemId}`,
            'PATCH',
            item
        ).catch((err) => {
            throw err;
        })

        return result
    },
    async UpsertToDoListItemStatus(ToDoList, Status) {
        var result = await API.process(
            `api/ToDoList/${ToDoList}/status`,
            'PATCH',
            Status
        ).catch((err) => {
            throw err;
        })

        return result
    },
    async RemoveToDoListStatus(ToDoList, Status) {
        var result = await API.process(
            `api/ToDoList/${ToDoList}/status/${Status}`,
            'DELETE',
            null
        ).catch((err) => {
            throw err;
        })

        return result
    },
    async RenameToDoListStatus(StatusId, Status) {
        var result = await API.process(
            `api/ToDoList/status/${StatusId}`,
            'PATCH',
            Status
        ).catch((err) => {
            throw err;
        })

        return result
    },
    async setToDoListItemDefaultStatus(StatusId) {
        var result = await API.process(
            `api/ToDoList/status/${StatusId}/Default`,
            'PUT',
            null
        ).catch((err) => {
            throw err;
        })

        return result
    },
    async SetStatusProperty(ToDoListItem, Property) {
        var result = await API.process(
            `api/ToDoList/${ToDoListItem}/Property`,
            'PATCH',
            Property
        ).catch((err) => {
            throw err;
        })

        return result
    },
    async GetToDoListItemById(ToDoListItemId) {
        var result = await API.process(
            `api/ToDoList/Item/${ToDoListItemId}`,
            'GET',
            null
        ).catch((err) => {
            throw err;
        })

        return result
    },
    async FindAvailableUsers() {
        var result = await API.process(
            `api/ToDoList/accessibility/users`,
            'GET',
            null
        ).catch((err) => {
            throw err;
        })

        return result
    },
    async ListAllToDoListsForUser() {
        var result = await API.process(
            `api/ToDoList/assignments`,
            'GET',
            null
        ).catch((err) => {
            throw err;
        })

        return result
    },
    async GetItemDetails(ItemId) {
        var result = await API.process(
            `api/ToDoList/assignment/details/${ItemId}`,
            'GET',
            null
        ).catch((err) => {
            throw err;
        })

        return result
    },
}

export default ToDoListService;
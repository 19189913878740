import React, { useEffect, useState } from 'react'
import ErrorDialog from '../../../../components/dialogs/ErrorDialog'
import LoaderComponent from '../../../../components/loader/LoaderComponent'
import Authentication from '../../../../libs/api/services/Authentication'
import CalendarSync from '../../../../libs/api/services/CalendarSync'

const CalendarSyncContext = React.createContext()

export const CalendarSyncProvider = ({ defaultConfiguration, children }) => {
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState()
    const [activeCalendar, setActiveCalendar] = useState({ properties: [] })
    const [userCalendars, setUserCalendars] = useState([])

    const _handleSetProperties = async (p) => {
        var cal = activeCalendar
        for (const e of Object.keys(p))
            cal = await _handleSetProperty(e, p[e], cal)
    }
    const _handleSetProperty = async (k, v, cal) => {
        var _activeCalendar = cal ? cal : activeCalendar
        var _prop = _activeCalendar.properties.find(p => p.property === k)
        if (_prop)
            _prop.values = JSON.stringify(v)
        else
            _activeCalendar.properties.push({ property: k, values: JSON.stringify(v) })

        if (k === 'Provider')
            _activeCalendar.provider = v
        if (k === 'Email Address')
            _activeCalendar.name = v

        if (!_activeCalendar.calendarUser)
            _activeCalendar = { ..._activeCalendar, calendarUser: { id: Authentication.UserId() } }
        var r = await CalendarSync
            .UpdateExternalCalendars(_activeCalendar)
            .catch((e) => setError(e))
        // .then((r) => {
        setActiveCalendar(r)
        return r
        // })
    }

    const _handleFindProperty = (p) => {
        var _prop = activeCalendar.properties.find(x => x.property === p)
        if (_prop)
            return JSON.parse(_prop.values)
        else
            return undefined
    }

    const _handleCheckUserCalendars = async () => {
        setLoading(true);
        const _cals = await CalendarSync.GetExternalCalendars()
        setUserCalendars(_cals);
        if (defaultConfiguration)
            setActiveCalendar(_cals.find(c => c.id === defaultConfiguration))
        setLoading(false);
    }


    /**
     * OnLoaded
     */

    useEffect(() => { _handleCheckUserCalendars() }, [])

    return (
        <>
            <ErrorDialog error={error} humanize={true} onClose={() => setError()} />
            {loading ? <LoaderComponent /> : ''}
            <CalendarSyncContext.Provider
                value={{
                    setError: setError,
                    setProperty: (k, v) => _handleSetProperty(k, v),
                    properties: activeCalendar.properties,
                    property: (p) => _handleFindProperty(p),
                    setProperties: (p) => _handleSetProperties(p),
                    userExternalCalendars: userCalendars,
                    setActiveCalendar: (c) => { c ? setActiveCalendar(c) : setActiveCalendar({ properties: [] }); _handleCheckUserCalendars() },
                    activeCalendar: activeCalendar,
              //      loading: loading
                }}
            >
                {children}
            </CalendarSyncContext.Provider>
        </>
    )
}

export default CalendarSyncContext
import React, { useEffect } from 'react'
export default function SMSWizardDone({ title, description, navigation, errors, setValue, values }) {

    useEffect(() => {
        title('SMS Service')
        description('SMS service setup complete')
        navigation([
            {
                text: 'Back', type: 'Back', action: async () => { }
            },
            { text: 'Done', type: 'Done', action: async () => { } }
        ])
        // _loadSettings()
    }, [])

    return (
        <>
            <div>

            </div>
        </>
    )
}

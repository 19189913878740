import { faSpinner } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { Component } from 'react'
import { Clipboard2HeartFill } from 'react-bootstrap-icons'
import Questionnaire from '../../../../../libs/api/services/Questionnaire'
import QuestionnaireAssignment from '../../../../../libs/api/services/QuestionnaireAssignment'

import LoaderComponent from '../../../../../components/loader/LoaderComponent'
import Authentication from '../../../../../libs/api/services/Authentication'
import Patient from '../../../../../libs/api/services/Patient'
import TaskEvents from '../../../../../libs/api/services/TaskEvents'
import WorkFlow from '../../../../../libs/api/services/WorkFlow'
import QFormLayout from './Layouts/QFormLayout'
import QWizardLayout from './Layouts/QWizardLayout'
import './QCapture.css'

export default class QCaptureComponent extends Component {
    constructor(props) {
        super(props)
        this.state = {
            loading: true,
            details: null,
            formData: [],
            activeSubsections: [],
            error: ''
        }
        // this._load()
    } 
    _findComponentOptionProperty(componentId, value, property) {
        const _options = this._findComponentProperty(componentId, 'Options')
        if (_options) {
            const _optionValues = JSON.parse(_options.values)
            //     // Check if array
            try {
                //  If array
                var _activeSubsections = this.state.activeSubsections
                _activeSubsections[componentId] = undefined
                for (const _opt of _optionValues) {
                    // 
                    const _vals = JSON.parse(value)
                    for (const _v of _vals) {
                        if (_v.option === _opt.option && _v.active && _v.subsection) {
                            _activeSubsections[componentId] = _v.subsection
                        }
                    }
                    this.setState({ activeSubsections: _activeSubsections })
                    return 'Arr';
                }
            } catch (e) {
            }


            /// Original
            for (const _opt of _optionValues) {
                if (_opt.option === value && Object.keys(_opt).includes(property))
                    return _opt[property]
            }

        }
    }
    //5bebe9f9-68a6-438f-4d3c-08dc0efe3560 
    _findComponentProperty(componentId, property) {
        for (const section of this.state.details.questionnaire.sections) {
            try {
                const _component = section.components.find(i => i.id === componentId)

                if (_component) {
                    return _component.properties.find(p => p.property === property)
                }
            } catch (e) {
                // console.error('_findComponentOptionProperty E', e)
            }
        }
    }
    _toggleActiveSection(componentId, value) {
        var _activeSubsections = this.state.activeSubsections
        _activeSubsections[componentId] = value
        this.setState({ activeSubsections: _activeSubsections })
    }

    _loadSubsectionState = () => {
        var _activeSubsections = this.state.activeSubsections
        for (const section of this.props.cacheddetails.questionnaire.sections) {
            for (const component of section.components) {
                var _options = undefined
                try {
                    const opts = component.properties.find(p => p.property === 'Options').values

                    _options = JSON.parse(opts)
                    const _subsection = _options.find(o => o.subsection)

                    try {
                        //  If array
                        const _answers = JSON.parse(this.props.cacheddetails.responses.find(o => o.answerType.id === component.id).answer)
                        for (const _a of _answers) {
                            if (_a.active)
                                if (_subsection.subsection) {
                                    if (_subsection.option === _a.option)
                                        _activeSubsections[component.id] = _subsection.subsection
                                }
                        }
                    } catch (e) {
                    }

                    // Need to also compare values selected
                    if (_subsection.subsection) {
                        if (_subsection.option === this.props.cacheddetails.responses.find(o => o.answerType.id === component.id).answer)
                            _activeSubsections[component.id] = _subsection.subsection
                    }
                } catch (e) {
                    // console.error('_loadSubsectionState',e)
                }
            }
        }
        return _activeSubsections
    }

    _onSetData(componentId, value, update) {

        var _formData = this.state.formData
        // Find component types for dynamic sub sections
        try {

            // Find component types for dynamic sub sections
            try {
                const _subSection = this._findComponentOptionProperty(componentId, value, 'subsection')

                if (_subSection !== 'Arr') {
                    if (_subSection)
                        this._toggleActiveSection(componentId, _subSection)
                    else
                        this._toggleActiveSection(componentId, undefined)
                }
            } catch (e) { }

            try {
                var el = _formData.find(c => c.componentId === componentId)
                el.value = value
                if (value && value !== "") {
                    el.valid = true
                } else {
                    el.valid = false
                }
            } catch (e) { }

            if (update)
                Questionnaire
                    .QuestionnaireResponse(this.state.details.assignment.id, {
                        answerType: {
                            id: componentId
                        },
                        answer: value
                    })
                    .then((r) => {
                    })
        } catch (e) { }
        this.setState({ formData: _formData })
    }

    async _load() {
        // Trigger Opend Event
        await this._handleOnOpen()
        if (this.props.cacheddetails) {
            var _formData = []
            var _section_idx = 0
            for (const section of this.props.cacheddetails.questionnaire.sections) {
                for (const component of section.components) {
                    var _required = false
                    try {
                        const required = component.properties.find(p => p.property === 'Required').values
                        _required = JSON.parse(required)
                    } catch (e) { }
                    var _options = undefined
                    try {
                        const opts = component.properties.find(p => p.property === 'Options').values
                        _options = JSON.parse(opts)
                    } catch (e) { }
                    _formData.push({
                        componentId: component.id,
                        section: _section_idx,
                        validate: _required,
                        valid: false,
                        value: ''
                    })
                }
                _section_idx++
            }

            const _newState = {
                details: {
                    assignment: this.props.cacheddetails,
                    options: this.props.cacheddetails.questionnaire.options,
                    questionnaire: this.props.cacheddetails.questionnaire
                },
                formData: _formData
            }
            
            this.setState({
                details: {
                    assignment: this.props.cacheddetails,
                    options: this.props.cacheddetails.questionnaire.options,
                    questionnaire: this.props.cacheddetails.questionnaire
                },
                formData: _formData,
                activeSubsections: this._loadSubsectionState(),
                loading: false
            })
            return;
        } else {
            // Get Assignment details
            const activeQuestionnaireAssignment = await QuestionnaireAssignment
                .GetQuestionnairesAssignmentDetails(this.props.QuestionnaireAssignmentId).catch((e) => this.setState({ error: 'Questionnaire Currently Unavailable' }))

            const options = await Questionnaire
                .GetOptions(this.props.QuestionnaireId)

            // Get Layout
            const questionnaire = await Questionnaire
                .GetQuestionnaire(this.props.QuestionnaireId)

            // Init form data
            var _formData = []
            var _section_idx = 0
            for (const section of questionnaire.sections.filter(s => s.isSubsection === false)) {
                for (const component of section.components) {
                    var _required = false
                    try {
                        const required = component.properties.find(p => p.property === 'Required').values
                        _required = JSON.parse(required)
                    } catch (e) { }
                    _formData.push({
                        componentId: component.id,
                        section: _section_idx,
                        validate: _required,
                        valid: false,
                        value: ''
                    })
                }
                _section_idx++
            }
            const _newState = {
                details: {
                    assignment: activeQuestionnaireAssignment,
                    options: options,
                    questionnaire: questionnaire
                },
                formData: _formData,
                loading: false
            }
            this.setState(_newState)
        }

    }
    componentDidMount() {
        this._load()
    }
    async _handleTriggerStartEvent() {
        /*
            - Module: Questionnaire
            - Trigger: Completed
            - payload: {}
            - questionnaire_complete
        */
          try {
             await WorkFlow
                  .TriggerAutomation('Questionnaire', 'QUESIONNAIRE_STARTED', {
                      userId: Authentication.UserId() ? Authentication.UserId() : '',
                      facility: Authentication.getFacility() ? Authentication.getFacility() : '',
                      questionnaire: this.props.cacheddetails.questionnaire.id,
                      assignment: this.props.cacheddetails.id,
                      patient: JSON.stringify(this.props.cacheddetails.assignedTo.id)
                  }).catch((e) => {
                      // Logging result to system logs in API
                  })
          } catch (e) {
            //   alert(e)
          }
        /*  try {
              const ON_COMPLETE_TRIGGER_EVENT_EVENT = this.state.details.options.find(r => r.name === 'ON_COMPLETE_TRIGGER_EVENT_EVENT');
              if (ON_COMPLETE_TRIGGER_EVENT_EVENT) {
                  const _ON_COMPLETE_TRIGGER_EVENT_EVENT_PARSED = JSON.parse(ON_COMPLETE_TRIGGER_EVENT_EVENT.value)
                  var tasks = await Patient
                      .TriggerUserEvent(_ON_COMPLETE_TRIGGER_EVENT_EVENT_PARSED.id, this.state.details.assignment.assignedTo.id)
                  for (const task of tasks) {
                      try {
                          //Trigger each task by id task.id
                          await TaskEvents.TriggerTaskUser(task.id, this.state.details.assignment.assignedTo.id, {}, Authentication.getFacility())
                      } catch (e) { }
                  }
              }
          } catch (e) {
          }*/
    }

    async _handleTriggerPostEvent() {
        /*
            - Module: Questionnaire
            - Trigger: Completed
            - payload: {}
            - questionnaire_complete
        */
        try {
            await WorkFlow
                .TriggerAutomation('Questionnaire', 'QUESIONNAIRE_COMPLETE', {
                    userId: Authentication.UserId() ? Authentication.UserId() : '',
                    facility: Authentication.getFacility() ? Authentication.getFacility() : '',
                    questionnaire: this.props.cacheddetails && this.props.cacheddetails.questionnaire ? this.props.cacheddetails.questionnaire.id : this.props.QuestionnaireId,
                    assignment: this.props.cacheddetails ? this.props.cacheddetails.id : this.props.QuestionnaireAssignmentId,
                    patient: JSON.stringify(this.state.details.assignment.assignedTo.id)
                }).catch((e) => {
                    // Logging result to system logs in API
                })
        } catch (e) {
            // alert(e)
            console.error(e)
        }
        try {
            const ON_COMPLETE_TRIGGER_EVENT_EVENT = this.state.details.options.find(r => r.name === 'ON_COMPLETE_TRIGGER_EVENT_EVENT');
            if (ON_COMPLETE_TRIGGER_EVENT_EVENT) {
                const _ON_COMPLETE_TRIGGER_EVENT_EVENT_PARSED = JSON.parse(ON_COMPLETE_TRIGGER_EVENT_EVENT.value)
                var tasks = await Patient
                    .TriggerUserEvent(_ON_COMPLETE_TRIGGER_EVENT_EVENT_PARSED.id, this.state.details.assignment.assignedTo.id)
                for (const task of tasks) {
                    try {
                        //Trigger each task by id task.id
                        await TaskEvents.TriggerTaskUser(task.id, this.state.details.assignment.assignedTo.id, {}, Authentication.getFacility())
                    } catch (e) { }
                }
            }
        } catch (e) {
        }
    }
    async _handleOnOpen() {
       await this._handleTriggerStartEvent()
    }

    async _handleOnComplete() {
        await QuestionnaireAssignment
            .CompleteQuestionnaire(
                this.state.details.assignment.id)
        await this._handleTriggerPostEvent()
        try {
            if (Authentication.getFacilityRole() && Authentication.getFacilityRole() !== '/logout')
                window.location = '/' + Authentication.getFacilityRole()
            else
                window.location = '/'
        } catch (e) {

        }
    }

    _render() {

        const _layout = this.props.mode === 'Discover' ? 'Form' : this.state.details.questionnaire.layout ?
            this.state.details.questionnaire.layout.layout
            :
            this.state.details.questionnaire.type === "Form" ? "Form" : ""
        const _details = {
            ...this.state.details,
            options: this.state.details.questionnaire.type === "Form" ? [
                ...this.state.details.options,
                {
                    name: 'SHOW_TITLE',
                    value: JSON.stringify({
                        type: 'None'
                    })
                }, {
                    name: 'SHOW_SECTION_TITLE',
                    value: JSON.stringify({
                        type: 'Hide'
                    })
                }
            ] : this.state.details.options,
        }

        switch (_layout) {
            case 'Wizard Layout':
                return (<QWizardLayout
                    mode={this.props.mode}
                    view={this.props.view}
                    details={_details}
                    cacheddetails={this.props.cacheddetails ? this.props.cacheddetails : undefined}
                    formData={this.state.formData}
                    onSetData={(componentId, value, update) => this._onSetData(componentId, value, update)}
                    onComplete={() => this._handleOnComplete()}
                    activeSubsections={this.state.activeSubsections}
                />)
            case 'Form Layout':
            default:
                return (<QFormLayout
                    mode={this.props.mode}
                    view={this.props.view}
                    details={_details}
                    cacheddetails={this.props.cacheddetails ? this.props.cacheddetails : undefined}
                    formData={this.state.formData}
                    onSetData={(componentId, value, update) => this._onSetData(componentId, value, update)}
                    onComplete={() => this._handleOnComplete()}
                    activeSubsections={this.state.activeSubsections}
                />)
        }
        // this.state.details
        // .questionnaire
        // .sections
    }

    render() {
        if (this.state.loading) {
            return <LoaderComponent />
        } else if (this.state.error) {
            return <div>{this.state.error}</div>
        }
        else if (this.state.details)
            return (
                <>
                    <div className={'justify-content-center align-items-center position-absolute bg-primary-container '.concat(this.state.loading ? 'show-loading' : 'hide-loading')} style={{ left: '0px', top: '0px', right: '0px', bottom: '0px', zIndex: 1000 }}>
                        <div className='text-center' style={{ position: 'absolute' }}>

                            {/* <div>{itemsLoaded}/{totalItems}</div> */}
                            <Clipboard2HeartFill size={75} />
                            <div>
                                <FontAwesomeIcon className='me-2' size='2x' icon={faSpinner} spinPulse />
                                Getting Questionnaire Ready
                            </div>
                        </div>
                    </div>
                    <>
                        {
                            this.state.details ? this._render() : <></>
                        }
                    </>

                </>
            )
        else return (<div>LOADING . . .</div>)
    }
}

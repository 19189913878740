import { faSpinner } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { Component } from 'react'
import FacilityCardButton from '../../../components/facility/FacilityCardButton'
import Authentication from '../../../libs/api/services/Authentication'
import CardGridLayout from '../../layout/CardGridLayout'

export default class FacilitySectionLayout extends Component {
    constructor() {
        super()
        this.state = {
            facilities: [],
            els: [],
            loading: true,
            error: undefined
        }
    }
    // preferedName
    _groupCards = (facilities) => {
        var cards = []
        var facilityRoles = {}
        // alert(JSON.stringify(facilities))
        for (const facility of facilities) {
            if (!facilityRoles[facility.Facility.Id]) {
                facilityRoles[facility.Facility.Id] = { id: facility.Facility.Id, facility: facility.Facility.facility, roles: [] }
            }

            facility.Roles && facilityRoles[facility.Facility.Id].roles.push({
                id: facility.Roles.Id,
                role: facility.Roles.Role === "System Admin" ? "System Admin" : facility.Roles.PreferedName
            })
        }
        for (var facilityId of Object.keys(facilityRoles)) {
            cards.push(<FacilityCardButton roles={facilityRoles[facilityId].roles} id={facilityRoles[facilityId].id} >{facilityRoles[facilityId].facility}</FacilityCardButton>)
        }
        return cards
    }

    getUsersFacilities = () => {
        Authentication
            .facilities()
            .then((facilities) => {
                if (!facilities) return window.location = '/logout'
                if (facilities.length === 1 && !Authentication.isClaimValid("System Admin")) {
                    if (Authentication
                        .setFacility(facilities[0].Facility.Id, facilities[0].Roles.Id))
                        return window.location = '/' + Authentication.getFacilityRole();
                    else return
                } else {
                    if (facilities.length === 0)
                        window.location = '/logout';
                    let _els = [];
                    if (Authentication.isClaimValid("System Admin")) {
                        _els.push(<FacilityCardButton id={-1} onClick={(e) => window.location = '/sys-admin'}>System Administration</FacilityCardButton>)
                    }
                    // for (var facility of ) {
                    _els.push(...this._groupCards(facilities))
                    // }
                    this.setState({
                        els: _els
                    })
                    this.setState({
                        facilities: facilities,
                        loading: false
                    })
                }
            }).catch((e) => {
                ///
                this.setState({ loading: false, error: e.message })
            })
    }

    componentDidMount() {
        // Remove Facility
        Authentication.logoutFacility();
        this.getUsersFacilities();
    }

    render() {
        return (
            <>
                {
                    this.state.error ?
                        <div className='text-center bg-danger p-3 m3 rounded shadow'>{this.state.error}</div>
                        :
                        (this.state.loading)
                            ?
                            <div className='text-center'>
                                <FontAwesomeIcon className='me-3' icon={faSpinner} spin={true} />
                                Loading
                            </div>
                            :
                            <>
                                <div className='d-flex justify-content-center align-items-center text-center'>
                                    <h2>Please choose your role</h2>
                                </div>
                                <CardGridLayout>
                                    {this.state.els}
                                </CardGridLayout>
                            </>
                }
            </>
        );
    }
}

import React from 'react'
import '../QEditor.css'
import QBuilderComponentOptions from '../QBuilderComponentOptions'
import QBuilderSideMenuSubMenu from '../QBuilderSideMenuSubMenu'
export default function QBuilderSideMenuContainer({ onAction, idx, isNew, onClose, busy, subMenus, children, mode, setMode }) {
    return (
        <div style={{ cursor: 'pointer' }} className={'bg-light shadow rounded text-start p-2 position-fixed '.concat(' slide-in')}>
            <QBuilderComponentOptions onAction={onAction} idx={idx} isNew={isNew} onClose={onClose} busy={busy} />
            {
                subMenus ?
                    <div  className='d-flex rounded bg-primary-container m-2'>{
                        subMenus.map((m) =>
                            <QBuilderSideMenuSubMenu key={m} menu={m} isAcive={mode === m} onSelect={(m) => setMode(m)} />
                        )
                    }
                    </div>
                    : <></>
            }
            <div  style={{overflow: 'auto', height: 'calc(100% - 120px )'}}>
            {children}
            </div>
        </div>
    )
}

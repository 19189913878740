import React, { useState } from 'react'
import './MenuBar.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faClose } from '@fortawesome/free-solid-svg-icons'
import Icons from '../../common/Icons'
import { LayoutMenuItemLayout } from '../interfaces/menu/LayoutMenuItemLayout'
// import MenuBarContainer from './MenuBarContainer'
import { LayoutInterface } from '../interfaces/LayoutInterface'
// import Component from '../Component'
import Layout from '../Layout'
import { ActionInterface } from '../interfaces/ActionInterface'
// import MenuBarContainer from './MenuBarContainer'

type Props = {
  name: string
  icon?: string
  text?: string
  layout: LayoutInterface | LayoutInterface[]
  onChange: (name: string, value: any) => void
  onClick: (name: string) => void
  onLoadData: (name: string, value: string) => Promise<any>
  variables: Map<string, any>
  menuitems?: LayoutMenuItemLayout[]
  actionRunner: (s: string, n: string, v?: string, a?: ActionInterface[]) => Promise<any>
}

export default function MenuBarWidget(props: Props) {
  const [show, setShow] = useState(false)
  const [active, setActive] = useState<string>('')

  const _handleToggle = (e: any) => {
    const className = 'd-flex align-items-center'.split(' ')
    className.push(show ? 'active' : 'inactive')
    return (
      <div onClick={() => setShow(!show)} className={className.join(' ')}>
        {e} {props.text}
      </div>
    )
  }
  const _SubMenuClassName = show ? 'open' : 'close'
  return (
    <>
      <div className='wizard-menu-bar'>
        <button className='btn'>{_handleToggle(Icons(props.icon!))}</button>
        <div className={'wizard-menu-bar-menu-container '.concat(_SubMenuClassName).concat(' slide')}>
          <div className={'wizard-menu-bar-menu-container-title '}>
            {Icons(props.icon!)}
            {props.text}
            <FontAwesomeIcon
              className='wizard-menu-bar-menu-container-close'
              onClick={() => setShow(!show)}
              fontSize={15}
              icon={faClose}
            />
          </div>

          <div className={'wizard-menu-bar-menu-container-body'}>
            <div className={'wizard-menu-bar-menu-container-items '}>
              {props.menuitems!.map((menuitem) => (
                <div
                  key={menuitem.name}
                  onClick={() => setActive(menuitem.name)}
                  className={'wizard-menu-bar-menu-container-items-tab '.concat(
                    active === menuitem.name ? 'active' : '',
                  )}
                >
                  {Icons(menuitem.icon!)}
                  {menuitem.name}
                </div>
              ))}
            </div>
            <div className={'wizard-menu-bar-menu-container-body-contents'}>
              {props.menuitems!.map(function (menuitem) {
                return menuitem.name === active ? (
                  <Layout
                    key={menuitem.name}
                    actionRunner={props.actionRunner}
                    // name={menuitem.name}
                    layouts={menuitem.menu}
                    // layouts={{
                    //   type: 'group',
                    //   grid: 'menu',
                    //   // layout: menuitem.menu,
                    //   layout: [
                    //     {
                    //       name: 'test',
                    //       type: 'button',
                    //       text: 'only 1',
                    //     },
                    //   ],

                    //   name: menuitem.name,
                    // }}
                    onClick={props.onClick}
                    onChange={props.onChange}
                    onLoadData={props.onLoadData}
                    section={props.name}
                    variables={props.variables}
                  />
                ) : (
                  <></>
                )
                // return menuitem.name === active ? (
                //   <div style={{ width: '100%', textAlign: 'center' }}>
                //     <MenuBarContainer
                //       key={menuitem.name}
                //       menu={menuitem}
                //       onChange={props.onChange}
                //       onClick={props.onClick}
                //       onLoadData={props.onLoadData}
                //     />
                //   </div>
                // ) : (
                //   <></>
                // )
              })}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

import React from 'react'
import { SectionInterface } from '../interfaces/SectionsInterface'
import NavigationWizardSteps from './NavigationWizardSteps'
type Props = {
  value: string
  sections: SectionInterface[] | undefined
}

const NavigationHeader = (props: Props) => {
  // NavigationWizardSteps
  const _progress = <NavigationWizardSteps section={props.value} sections={props.sections} />
  for (const o of props.sections!) {
    if (o.name === props.value) {
      return (
        <>
          {o.title ? <h1>{o.title}</h1> : <></>}
          {o.description ? <p>{o.description}</p> : <></>}
          {_progress}
        </>
      )
    }
  }
  return <></>
}
export default NavigationHeader

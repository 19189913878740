import React, { useContext, useState } from 'react'
import CalendarGenerics from '../../../CalendarGenerics'
import CalendarContext from '../../CalendarProvider'

export default function CalendarMonthFilter({ view, onChangeView }) {
    const _POPUP_KEY = 'CalendarMonthFilter'
    const { setFilter, filters, setActivePopup, activePopup } = useContext(CalendarContext)

    const [exapnd, setExpand] = useState(false)

    const _handleSetValue = (month) => {
        // setExpand(false)
        setFilter({ month: month })
        setActivePopup(undefined)
    }
    return (
        <div style={{ cursor: 'pointer' }} className='position-relative calendar-filter text-center'>
            <div onClick={() => setActivePopup(activePopup !== _POPUP_KEY ? _POPUP_KEY : undefined)}>
                <div><b>Month</b></div>
                <div>{CalendarGenerics.months[filters.month].full}</div>
            </div>

            <div className={'position-absolute bg-secondary-container rounded shadow  '.concat(activePopup === _POPUP_KEY ? 'show' : 'hide')}>
                <div className='row'>
                    {
                        CalendarGenerics
                            .months
                            .map((m, idx) =>
                                <div className={'col-3 p-3 text-center '.concat(filters.month === idx ? ' active rounded ' : '')} key={'fltr-mnth-' + idx} onClick={() => _handleSetValue(idx)}>{m.short}</div>
                            )
                    }
                </div>
            </div>
        </div>
    )
}

import React from 'react'

export default function TextInput({ defaultValue, onChanged, placeholder }) {
    return (
        <input
            onChange={(e) => onChanged(e.target.value)}
            defaultValue={defaultValue}
            className='form-control'
            placeholder={placeholder} />
    )
}

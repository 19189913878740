import React, { useEffect, useState } from 'react'
import EventCategorySearch from '../../../patient/admin/events/category/EventCategorySearch'
import PatientCategorySearch from '../../../patient/admin/category/PatientCategorySearch'
import PatientCategoryFieldSearch from '../../../patient/admin/category/PatientCategoryFieldSearch'
import { CheckSquare, Link, Link45deg, Square } from 'react-bootstrap-icons'
import Questionnaire from '../../../../../libs/api/services/Questionnaire'

export default function QBuilderFieldLink({ ComponentId }) {
    const [categoryId, setCategoryId] = useState()
    const [fieldId, setFieldId] = useState()
    const [linked, setLinked] = useState({})
    const [event, setEvent] = useState()

    const [autofill, setAutofill] = useState(false)
    const [updateRecords, setUpdateRecords] = useState(false)

    const load = () => {
        Questionnaire
            .GetLinkPatientProfile(ComponentId)
            .then((r) => {
                setLinked(r)
                setAutofill(r.autoFill)
                setUpdateRecords(r.updateRecords)
                if (r)
                    setFieldId(r.linkedValue.id)
            }).catch((e) => {
                setLinked({})
            })
    }

    useEffect(() => {
        load()
    }, [])

    const link = () => {
        Questionnaire
            .LinkPatientProfile(ComponentId, categoryId, fieldId, {})
            .then((r) => {
                load()
            })
    }

    const _handleSetAutoFill = () => {
        const _u = !autofill
        Questionnaire
            .LinkPatientProfile(ComponentId, categoryId, fieldId, {
                autoFill: _u,
                updateRecords: updateRecords
            })
            .then((r) => {
                load()
                setAutofill(_u)
            })

    }

    const _handleSetUpdateRecords = () => {
        const _u = !updateRecords
        Questionnaire
            .LinkPatientProfile(ComponentId, categoryId, fieldId, {
                autoFill: autofill,
                updateRecords: _u
            })
            .then((r) => {
                load()
                setUpdateRecords(_u)
            })

    }
    const unlink = () => {
        Questionnaire
            .UnLinkPatientProfile(ComponentId)
            .then((r) => {
                load()
            })
    }
    return (
        <div className='text-center'>
            <h3>Link Field</h3>
            {
                linked.linkedValue
                    ?
                    <div>This field is currently linked to : <b>{linked.linkedValue.value}</b>
                        <div className='text-start my-2'>
                            <div onClick={() => _handleSetAutoFill()}>
                                {
                                    autofill
                                        ?
                                        <CheckSquare className='me-2' />
                                        :
                                        <Square className='me-2' />
                                }
                                <b>Auto Fill</b> This will use the existing details on the patient profile if not specified or changed</div>
                            <div onClick={() => _handleSetUpdateRecords()}>
                                {
                                    updateRecords
                                        ?
                                        <CheckSquare className='me-2' />
                                        :
                                        <Square className='me-2' />
                                }
                                <b>Update Records</b> This will update the patients profile with the value entered on the questionnaire</div>
                        </div>
                    </div>
                    :
                    <>
                        <div>
                            <p className='text-center'>To link this field to an existing patient profile field select the category and field below</p>
                        </div>
                        <b>Category</b>
                        <div className='position-relative'>
                            <PatientCategorySearch
                                showAny={true}
                                onChanged={(e) => setCategoryId(e.id)}
                                defaultValue={''} />
                            {
                                categoryId
                                    ?
                                    <>
                                        <label><b>Field Name</b></label>
                                        <PatientCategoryFieldSearch
                                            onChanged={(e) => setFieldId(e.id)}
                                            categoryId={categoryId} />
                                    </>
                                    :
                                    <></>
                            }

                        </div>
                    </>
            }
            <div>
                {
                    linked.linkedValue
                        ?
                        <button
                            onClick={(e) => unlink()}
                            className='btn bg-button'><Link /> Unlink</button>
                        :
                        fieldId
                            ?
                            <button
                                onClick={(e) => link()}
                                className='btn bg-button'><Link45deg />Link</button>
                            :
                            <></>
                }
            </div>
        </div >
    )
}

import React, { useEffect, useState } from 'react';
import Data from '../../libs/api/services/Data';

import './DataLink.css';

export default function DataLinkComponent({ sourceComponent, profileFieldId }) {
    const [linkOptions, setLinkOptions] = useState([])

    // Events
    // LIST_FIELDS
    const listProfileLinks = async () => {
        if (!profileFieldId) throw Error('Please specify a source')
        Data.ListProfileLinks(profileFieldId)
            .then((r) => {
                setLinkOptions(r)
            });
    }
    // LINK_FIELD
    const linkField = async (sourceComponent, profileFieldId, module, fieldClass,field) => {
        Data.SetLink(module, fieldClass,field, sourceComponent, profileFieldId)
            .then((e) => {
                // Reload list
                listProfileLinks()
            })
        // set module link to source
        console.error('_toggleLink', module, field, profileFieldId)
        if (!profileFieldId) throw Error('Please specify a source')
    }

    // Initil load fields
    useEffect(() => { listProfileLinks() }, [])

    // Switch field on/off when clicked
    const _toggleLink = (module, fieldClass, link) => linkField(sourceComponent, profileFieldId, module, fieldClass, link)

    return (
        <div className='d-flex flex-column'>
            {
                linkOptions.map((option) => {
                    return <ul key={option.moduleClass} className='list-unstyled data-link-module'><li><b>{option.moduleName}</b></li><ul className='list-unstyled data-link-links'>
                        {
                            option.fields.map((field) =>
                                <li key={field.fieldClass} className={field.isLinked ? 'selected' : ''} onClick={(e) => _toggleLink(option.moduleClass, field.fieldClass, field.fieldName)}>{field.fieldDescription}</li>
                            )
                        }
                    </ul></ul>
                })
            }

        </div >
    )
}

import React, { useContext } from 'react'
import CalendarEventStatus from '../../../../components/EventStatus/CalendarEventStatus'
import CalendarFacilityContext from '../../../CalendarFacilityProvider'

export default function CalendarEventCloneEvent({ properties, onChange }) {
  const { calendarEvent, setCalendarEventProperty } = useContext(CalendarFacilityContext)

  const _findProp = (name) => {
    if (!calendarEvent || !calendarEvent.properties) return undefined
    const _val = calendarEvent.properties.find(n => n.name === name)
    return _val ? _val.value : undefined
  }
  const _handleChange = (k, v) =>
    setCalendarEventProperty(k, v)

  return (
    <div>
      <div>
        <label>Change cloned status to</label>
        <CalendarEventStatus
          onChange={(e) => _handleChange('DEFAULT_STATUS_NEW', e)}
          defaultValue={_findProp('DEFAULT_STATUS_NEW')} />
        <input
          onChange={(e) => _handleChange('DEFAULT_SUMMARY_NEW', e.target.value)}
          defaultValue={_findProp('DEFAULT_SUMMARY_NEW')}
          className='form-control form-control-sm' placeholder='Default cloned Summary' />
        <textarea
          onChange={(e) => _handleChange('DEFAULT_DETAILS_NEW', e.target.value)}
          defaultValue={_findProp('DEFAULT_DETAILS_NEW')}
          className='form-control form-control-sm' placeholder='Default cloned event details' ></textarea>
      </div>
    </div>
  )
}

import React, { useEffect, useState } from 'react'
import ServiveProviderJSONItem from './ServiveProviderJSONItem'
/*
{
  "sendOptions": {
    "allowContentTrimming": true,
    "senderId": "string",
    "startDeliveryUtc": "2024-01-27T14:54:59.318Z",
    "endDeliveryUtc": "2024-01-27T14:54:59.318Z",
    "replyRuleSetName": "string",
    "campaignName": "string",
    "costCentre": "string",
    "checkOptOuts": true,
    "shortenUrls": true,
    "validityPeriod": 0,
    "testMode": true
  },
  "messages": [
    {
      "content": "string",
      "destination": "string",
      "customerId": "string",
      "landingPageVariables": {
        "landingPageId": "string",
        "version": 0,
        "password": "string"
      }
    }
  ]
}
*/
export default function ServiveProviderJSON({ defaultValue, onChange }) {
    const [parsed, setParsed] = useState(defaultValue ? defaultValue : undefined)
    const [reviewed, setReviewed] = useState(defaultValue ? defaultValue : undefined)
    const [error, setError] = useState()
    const [mode, setMode] = useState(defaultValue ? 'Review': '')

    useEffect(() => { if (mode === 'Review' && onChange) onChange(reviewed) }, [mode])
    useEffect(() => { setParsed(defaultValue); setReviewed(defaultValue) }, [defaultValue])

    function generateUUID() { // Public Domain/MIT
        var d = new Date().getTime();//Timestamp
        var d2 = ((typeof performance !== 'undefined') && performance.now && (performance.now() * 1000)) || 0;//Time in microseconds since page-load or 0 if unsupported
        return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
            var r = Math.random() * 16;//random number between 0 and 16
            if (d > 0) {//Use timestamp until depleted
                r = (d + r) % 16 | 0;
                d = Math.floor(d / 16);
            } else {//Use microseconds since page-load if supported
                r = (d2 + r) % 16 | 0;
                d2 = Math.floor(d2 / 16);
            }
            return (c === 'x' ? r : (r & 0x3 | 0x8)).toString(16);
        });
    }

    const _loopJSON = (res, json, lvl) => {
        for (const k of Object.keys(res)) {
            switch (typeof res[k]) {
                case 'object':
                    if (Array.isArray(res[k]))
                        for (const r of res[k])
                            json[k] = { field: k, type: 'array', option: 'exclude', value: _loopJSON(res[k][0], res[k][0], lvl + 1) }
                    else
                        json[k] = { field: k, type: typeof res[k], option: 'exclude', value: _loopJSON(res[k], {}, lvl + 1) }
                    break;
                default:
                    json[k] = { id: generateUUID(), field: k, type: typeof res[k], option: 'exclude', value: undefined }
            }
        }
        return json
    }

    const _handleOnChange = (e) => {
        var json = {}
        try {
            var res = JSON.parse(e)
            var detailedJSON = _loopJSON(res, json, 0)
            setParsed(detailedJSON)
        } catch (e) { setError(JSON.stringify(e)) }
    }
    // useEffect(() => {
    //     _handleOnChange(JSON.stringify({
    //         "sendOptions": {
    //             "allowContentTrimming": true,
    //             "senderId": "string",
    //             "startDeliveryUtc": "2024-01-27T14:54:59.318Z",
    //             "endDeliveryUtc": "2024-01-27T14:54:59.318Z",
    //             "replyRuleSetName": "string",
    //             "campaignName": "string",
    //             "costCentre": "string",
    //             "checkOptOuts": true,
    //             "shortenUrls": true,
    //             "validityPeriod": 0,
    //             "testMode": true
    //         },
    //         "messages": [
    //             {
    //                 "content": "string",
    //                 "destination": "string",
    //                 "customerId": "string",
    //                 "landingPageVariables": {
    //                     "landingPageId": "string",
    //                     "version": 0,
    //                     "password": "string"
    //                 }
    //             }
    //         ]
    //     }))
    // }, [])

    const _findAndUpdateLoop = (res, id, repl) => {
        if (!id) return res
        for (const k of Object.keys(res))
            if (typeof res[k].value === 'object')
                res[k].value = _findAndUpdateLoop(res[k].value, id, repl)
            else
                if (res[k].id && res[k].id === id)
                    res[k] = { ...repl, id: id }

        return res
    }
    const _findAndUpdate = (i, v) => {
        // setParsed(_findAndUpdateLoop(parsed, i, v))
        setReviewed(_findAndUpdateLoop(parsed, i, v))
    }

    const _visJSON = (json, lvl) => {
        var ret = []

        for (var k of Object.keys(json))
            if (json[k].value && typeof json[k].value === 'object')
                ret.push(<div className={'text-start ms-' + lvl}><b>{k + " {"} </b><div>{_visJSON(json[k].value, lvl + 1)}</div>{"}"}</div>)
            else
                ret.push(<ServiveProviderJSONItem onChange={(i, r) => _findAndUpdate(i, r, parsed)} {...json[k]} />)

        return <div className='small'>{ret}</div>
    }

    const _minJSON = (json, lvl) => {
        var ret = []
        for (var k of Object.keys(json))
            try {
                if (json[k].value && typeof json[k].value === 'object')
                    ret.push(<div className={'text-start ms-' + lvl}><b>{k + " {"} </b><div>{_minJSON(json[k].value, lvl + 1)}</div>{"}"}</div>)
                else
                    if (json[k].option === "variable") ret.push(<div>{k}:<i>&lt;{JSON.stringify(json[k].value)}&gt;</i></div>); else if (json[k].option !== "exclude") ret.push(<div>{k}:{JSON.stringify(json[k].value)}</div>)
            } catch (e) { }
        return <div className='small'>{ret}</div>
    }

    return (
        <div className=''>
            {
                mode === 'Review'
                    ?
                    <div className='d-flex '>
                        <div className='flex-grow-1'>
                            <div>{_minJSON(reviewed, 0)}</div>
                        </div>
                        <div className='flex-shrink-1 d-flex flex-column'>
                            <button onClick={() => setParsed('')} className='btn btn-xs'>Change JSON Sample</button>
                            <button onClick={() => setMode('')} className='btn btn-xs'>Change Mapping</button>
                        </div>
                    </div>
                    :
                    parsed
                        ?
                        <div className='d-flex w-100'>
                            <div className='flex-grow-1'>
                                {
                                    _visJSON(parsed, 0)
                                }
                            </div>
                            <div className='d-flex flex-column'>
                                <button onClick={() => setParsed()} className='btn btn-xs'>Change JSON Sample</button>
                                <button onClick={() => setMode('Review')} className='btn btn-xs'>Save And Coninue</button>
                            </div>
                        </div>
                        :
                        <textarea placeholder='Paste the sample JSON from the providers documentation here' onChange={(e) => _handleOnChange(e.target.value)} className='form-control' rows={10}></textarea>
            }
            <div className='small text-danger'>{error}</div>
        </div>
    )
}

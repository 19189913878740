import React, { useRef } from 'react';

export default function DateTimePickerDetails({ visible, reference, children, onHide, pos }) {
    const dateSelectRef = useRef()

    return (
        <div
            ref={dateSelectRef}
            className={'text-dark d-flex flex-column rounded shadow date-picker-body bg-light '.concat(visible ? '' : 'd-none')} style={{
                // _showAboveOrBelow()
                position: 'absolute',
                minWidth: '350px',
                maxHeight: '400px',
                minHeight: '400px',
                zIndex: 100,
                bottom: pos && pos === 'bottom' ? '-400px' : '35px'
            }}>
            {children}
        </div>
    )
}

import React, { useContext, useEffect, useState } from 'react'
import PatientProfileContainer from '../PatientProfileContainer'
import PatientProfileContext from '../PatientProfileProvider'
import ErrorDialog from '../../../../../components/dialogs/ErrorDialog'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowDown, faArrowUp } from '@fortawesome/free-solid-svg-icons'

export default function PatientProfileAdminCategories({ categoryID, onStatusChange }) {
  const { state, execute } = useContext(PatientProfileContext)
  const [dragId, setDragId] = useState(-10)
  const [dragOrder, setDragOrder] = useState(-10)

  useEffect(() => {
    if (state.state === 'RELOAD_CATEGORIES' || state.state === 'READY')
      execute('LOAD_CATEGORIES_FROM_SERVER', null)
  }, [state.state])

  const _handleChangeOrder = (categoryID, order) => {
    if (order < 0) return;
    execute('CHANGE_CATEGORY_ORDER', { categoryID: categoryID, order: order })
    setDragId(-10)
    setDragOrder(-10)
  }
  return (
    <>
      <ErrorDialog onClose={() => execute('CLEAR_ERROR', {})} error={state.error} />
      <h3 className='text-center'>Patient Profile Template</h3>
      <div>
        {
          state.state === 'READY' || state.state === 'RELOAD_CATEGORIES'
            ?
            state.categories.map((c) => {
              return (
                <div className='d-flex'>
                  <div
                    style={(dragOrder === c.order) ? { opacity: '0.5', cursor: 'copyMove' } : { cursor: 'move' }}
                    draggable
                    onDragStart={(e) => { e.dataTransfer.effectAllowed = "copyMove"; setDragId(c.id) }}
                    onDragEnter={(e) => { e.preventDefault(); e.dataTransfer.effectAllowed = "move"; setDragOrder(c.order) }}
                    onDragOver={(e) => { e.preventDefault(); e.dataTransfer.effectAllowed = "move"; }}
                    onDragEnd={(e) => { _handleChangeOrder(c.id, dragOrder); setDragOrder(-1) }}
                    key={c.id}
                  // className='flex-shrink-1'
                  >
                    <FontAwesomeIcon onClick={(e) => _handleChangeOrder(c.id, c.order - 1)} icon={faArrowUp} />
                    <FontAwesomeIcon onClick={(e) => _handleChangeOrder(c.id, c.order + 1)} icon={faArrowDown} />
                  </div>
                  <div className='container-fluid'>
                    <PatientProfileContainer onStatusChange={(m) => onStatusChange(m)} key={c.id} category={c.groupNme} categoryId={c.id} />
                  </div>
                </div>)
            })
            : <></>
        }
      </div >
    </>
  )
}

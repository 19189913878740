import React, { useEffect, useRef, useState } from 'react'
import { useClickOutside } from '../../../../../../components/Hooks/useClickOutside'

export default function QBuilderSearch({ details, onsBeforeSearch, onAfterSearch, onSelected, placeholder, response }) {
  const mainRef = useRef()
  const searchRef = useRef()
  const resultsRef = useRef()
  const [isOpen, setIsOpen] = useState(false)
  const [results, setResults] = useState([])
  const [result, setResult] = useState([])
  const [options, setOptions] = useState(typeof details === 'undefined' ? {
    data: [{ id: 1, name: 'tom', surname: 'harry' }],
    options: {
      reRefKey: 'id',
      template: [{
        refKey: 'name',
        descr: '',
      }]
    }
  } : details)

  useClickOutside(mainRef, () => { setIsOpen(false) })

  useEffect(() => {
    setOptions(details)
    // if (results.length === 0)
    //   setResults(options.data)
  }, [details])

  useEffect(() => {
    // if (results.length === 0)
    setResults(options.data)
  }, [options])

  /*
    Handle Events
  */

  const _handleSearch = (search) => {
    var res = []
    if (!search || search === '')
      return setResults(options.data)
    else {
      for (const item of options.data)
        for (const k of Object.keys(item))
          try {
            for (const word of item[k].toLowerCase().split(' '))
              if (word.length > 0)
                if (word.includes(search.toLowerCase()))
                  res.push(item)
          } catch (e) { }


    }
    setResults(res)
  }

  // Toggle component open/close
  const _handleResultsClick = () => {
    setIsOpen(!isOpen)
    resultsRef.current.blur()
  }

  // Handle selected
  const _handleSetSelected = (res_id, result) => {
    setResult(result)
    if (onSelected) onSelected(res_id)
    resultsRef.current.value =
      options.options.template
        .map((t) => { return result[t.refKey] + ' ' })
    setIsOpen(false)
  }
  /*
    Render line item
  */

  const _renderResultItem = (r) => {
    const res_id = r[options.options['reRefKey']]
    if (!res_id) throw Error('Invalid reRefKey set in options')
    if (!options.options.template) throw Error('No template specified in options')
    const _node_value = options.options.template
      .map((t) => <span>{t.descr ? <b>{t.descr}</b> : <></>} {r[t.refKey]}</span>)
    return <li className='m-1 p-1' onClick={() => _handleSetSelected(res_id, r)} >{_node_value}</li >
  }


  return (
    <div ref={mainRef} className='position-relative'>
      <input style={{ cursor: 'pointer' }} onClick={() => _handleResultsClick()} ref={resultsRef} className='form-control' placeholder={placeholder ? placeholder : ''} />
      <div className={'position-absolute p-3 bg-primary-container rounded shadow w-100 '.concat(isOpen ? 'd-block' : 'd-none')} style={{ zIndex: 999 }}>
        <input ref={searchRef} onChange={(e) => _handleSearch(e.target.value)} className='form-control' placeholder='Search' />
        <ul className='list-unstyled' style={{ maxHeight: '250px', overflow: 'auto', cursor: 'pointer' }}>
          {results.map((res) => _renderResultItem(res))}
        </ul>
      </div>
    </div>
  )
}

import React from 'react'
import { useState } from 'react'
import { useEffect } from 'react'
import { ChevronDoubleLeft, ChevronDoubleRight } from 'react-bootstrap-icons'

export default function DatePickerYear({ onChange }) {
    const [range, setRange] = useState([])

    const GetDefaultYear = () => {
        const YearRange = parseInt(new Date().getFullYear() / 10);
        setRange([YearRange * 10, YearRange * 10 + 10])
        //         // Return today's date and time
        // var currentTime = new Date()

        // // returns the month (from 0 to 11)
        // var month = currentTime.getMonth() + 1

        // // returns the day of the month (from 1 to 31)
        // var day = currentTime.getDate()

        // // returns the year (four digits)
        // var year = currentTime.getFullYear()

        // // write output MM/dd/yyyy
        // document.write(month + "/" + day + "/" + year)
    }

    useEffect(() => {
        GetDefaultYear()
    }, [])

    const _handlePreviousRange = () => setRange([range[0] - 10, range[1] - 10])
    const _handleNextRange = () => setRange([range[0] + 10, range[1] + 10])

    const _generateYears = () => {
        var rows = []
        var cols = []
        var c = 0;
        for (var i = range[0]; i <= range[1]; i++, c++) {
            if (c % 3 == 0) {
                rows.push(<tr>{cols}</tr>)
                cols = []
            }
            const x = i
            cols.push(<td className='date-picker-items' key={x} onClick={(e) => onChange(x)}><div style={{ cursor: 'pointer' }} className='m-1'>{i}</div></td>)
        }
        rows.push(<tr>{cols}</tr>)
        return <table className='table'>{rows}</table>
    }
    return (
        <div>
            <div className='d-flex justify-content-between align-items-center date-picker-head'>
                <button onClick={(e) => _handlePreviousRange()} className='btn'><ChevronDoubleLeft /></button>
                {
                    range.length > 1
                        ?
                        <div>{range[0]} - {range[1]}</div>
                        :
                        <></>
                }
                <button onClick={(e) => _handleNextRange()} className='btn'><ChevronDoubleRight /></button>
            </div>
            {
                _generateYears()
            }
        </div>
    )
}

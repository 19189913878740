import React, { useContext } from 'react'
import { Clipboard2Fill, ClipboardCheckFill, ClipboardPulse, ClockFill, PersonFill } from 'react-bootstrap-icons'
import QCaptureEmbeded from '../../../../questionnaires/capture/QCaptureEmbeded'
import WorkFlowManagementContext from '../../WorkFlowManagementProvider'

export default function QuestionnaireLineItem({ item }) {
    const { extendedModal } = useContext(WorkFlowManagementContext)
    return (
        <div
            style={{ cursor: 'pointer' }}
            onClick={() => extendedModal({
                show: true, icon: <Clipboard2Fill size={'25px'} />,
                title: 'Questionnaire',
                component: <QCaptureEmbeded QuestionnaireAssignmentId={item.questionnaireAssignment.id} questionnaireId={item.questionnaireAssignment.questionnaire.id} />
            })}
            className='shadow p-2 bg-secondary-container rounded'>
            <div className='d-flex justify-content-between'>
                <div className='flex-shrink-1'>{item.questionnaireAssignment.questionnaire && item.questionnaireAssignment.questionnaire.type &&
                    <div>{item.questionnaireAssignment.questionnaire.type === 'Form' ? <ClipboardCheckFill size={30} /> : <ClipboardPulse size={30} />}</div>
                }</div>
                <div className='text-start ms-2 flex-grow-1'><b>{item.questionnaireAssignment.questionnaire && `${item.questionnaireAssignment.questionnaire.name}`}</b></div>
                <div className='text-center flex-grow-1'><ClockFill /> {item.questionnaireAssignment.assignedDate && item.questionnaireAssignment.assignedDate.substring(0, 19)}</div>
                <div className='text-center flex-grow-1'><PersonFill />{item.questionnaireAssignment && item.questionnaireAssignment.assignedTo && `${item.questionnaireAssignment.assignedTo.firstName} ${item.questionnaireAssignment.assignedTo.lastName}`}</div>
            </div>
        </div>
    )
}

import React, { useEffect, useState } from 'react'
import { Check2Square, Square } from 'react-bootstrap-icons'
import Questionnaire from '../../../../../../libs/api/services/Questionnaire'

export default function QBuilderTitleOptions({ questionnaireId }) {
    const [titleMode, setTitleMode] = useState({ type: 'Questionnaire Name', title: '' })
    const _handleSetOption = (option) => {
        Questionnaire
            .SetOption(questionnaireId, {
                name: 'SHOW_TITLE',
                value: JSON.stringify(option)
            })
            .then((r) => {
                //setTitleMode(r)
                setTitleMode(option)
            }).catch(() => { })
    }
    const _handleGetOption = () => {
        Questionnaire
            .GetOption(questionnaireId, 'SHOW_TITLE')
            .then((r) => {
                try {
                    setTitleMode(JSON.parse(r.value))
                } catch (e) { }
            }).catch((e) => { })
    }

    useEffect(() => {
        _handleGetOption()
    }, [])

    return (
        <div className='text-start m-3'>
            <div className='p-2 rounded  '>
                <label><b>Title</b></label>
                <div onClick={(e) => _handleSetOption({ type: 'Questionnaire Name' })} className={' p-2 rounded m-2  '.concat(titleMode.type === 'Questionnaire Name' ? 'shadow bg-secondary-container ' : 'opacity-75 ')}>
                    {
                        titleMode.type === 'Questionnaire Name'
                            ?
                            <Check2Square className='me-2' />
                            :
                            <Square className='me-2' />
                    }

                    Use Questionnaire Name</div>
                <div onClick={(e) => _handleSetOption({ type: 'Custom Title' })} className={' p-2 rounded m-2 '.concat(titleMode.type === 'Custom Title' ? 'shadow bg-secondary-container ' : 'opacity-75 ')}>
                    {
                        titleMode.type === 'Custom Title'
                            ?
                            <Check2Square className='me-2' />
                            :
                            <Square className='me-2' />
                    }Use Custom Title
                    {
                        titleMode.type === 'Custom Title'
                            ?
                            <input
                                value={titleMode.title}
                                onChange={(e) => _handleSetOption({ type: 'Custom Title', title: e.target.value })} className='form-control form-control-sm' placeholder='Custom Title' />
                            :
                            <></>
                    }
                </div>
                <div onClick={(e) => _handleSetOption({ type: 'None' })} className={' p-2 rounded m-2 '.concat(titleMode.type === 'None' ? 'shadow bg-secondary-container ' : 'opacity-75 ')}>
                    {
                        titleMode.type === 'None'
                            ?
                            <Check2Square className='me-2' />
                            :
                            <Square className='me-2' />
                    }
                    Don't show title</div>
            </div>
        </div>
    )
}

export default class DateTimePickerFunctions {
    static months =
        [{
            short: 'Jan',
            full: 'January'
        }, {
            short: 'Feb',
            full: 'February'
        }, {
            short: 'Mar',
            full: 'March'
        }, {
            short: 'Apr',
            full: 'April'
        }, {
            short: 'May',
            full: 'May'
        }, {
            short: 'Jun',
            full: 'June'
        }, {
            short: 'Jul',
            full: 'July'
        }, {
            short: 'Aug',
            full: 'August'
        }, {
            short: 'Sep',
            full: 'September'
        }, {
            short: 'Oct',
            full: 'October'
        }, {
            short: 'Nov',
            full: 'November'
        }, {
            short: 'Dec',
            full: 'December'
        }
        ]
    constructor() {
        this.DateTime = new Date()
    }

    setDateTime(date) {
        this.DateTime = date
    }

    formatTime(t) {
        if (!t) return
        if (t.length === 2 && t.substring(1, 2) === ":") {
            t = "0" + t.substring(0, 2)
        }
        if (t.length > 2 && t.substring(2, 3) !== ":") {
            // t = t.substring(0, 2) + ":" + t.substring(2)
        }

        // if (t.substring(1, 2) === ":") {
        //     t = "0" + t
        // }
        // if (t.length === 4) {
        //     t = t.substring(0, 3) + "0" + t.substring(3, 4)
        // }
        // var isValid = /^(?:[01][0-9]|2[0-3]):[0-5][0-9](?::[0-5][0-9])?$/.test(t);
        // if (isValid === false) {
        //     return
        // }
        var isValid = /^[0-9:]*$/.test(t);
        // const regxr = /^[0-9:]*$/
        if (isValid === false) {
            //return t.substring(0, t.length - 1)
            return t.replace(/[^0-9:]/g, "0").substring(0, 5)
        }

        if (parseInt(t.substring(0, 2)) > 23) {
            t = "23" + t.substring(2)
        }
        if (parseInt(t.substring(3, 5)) > 59) {
            t = t.substring(0, 2) + "59"
        }
        if (t.length > 5)
            return t.substring(0, 5)
        return t
    }
    formatTimeFinal(t) {
        if (!t) return "00:00"

        if (!isNaN(t.substring(0, 1)) && t.substring(1, 2) === ":" && !isNaN(t.substring(2, 3)) && t.length === 3) {
            t = "0" + t.substring(0, 1) + ":0" + t.substring(2, 3)
        }

        if (t.length === 1) {
            t = "0" + t.substring(0, 1) + ":00"
        }
        if (t.length === 2 && t.substring(1, 2) === ":") {
            t = "0" + t.substring(0, 2) + "00"
        }
        if (t.length === 3) {
            t = t.substring(0, 2) + ":00"
        }

        // if(t.substring(2,3)!== ":"){
        //     t = "0" + t.substring(0, 1)
        // }
        if (t.length === 4 && t.substring(2, 3) === ":") {
            t = t.substring(0, 3) + "0" + t.substring(3, 4)
        }
        // if (t.substring(1, 2) === ":") {
        //     t = "0" + t
        // }
        // if (t.substring(3, 4) === ":") {
        //     // t= "123"
        //     t = t.substring(0, 3) + t.substring(4,6)
        // }
        if (t.length > 2 && t.substring(2, 3) !== ":") {
            t = t.substring(0, 2) + ":" + t.substring(2)
        }
        var isValid = /^[0-9:]*$/.test(t);
        // const regxr = /^[0-9:]*$/
        if (isValid === false) {
            //return t.substring(0, t.length - 1)
            return t.replace(/[^0-9:]/g, "0").substring(0, 5)
        }

        if (parseInt(t.substring(0, 2)) > 23) {
            t = "23" + t.substring(2)
        }
        if (parseInt(t.substring(3, 5)) > 59) {
            t = t.substring(0, 2) + "59"
        }
        if (t.length > 5)
            return t.substring(0, 5)
        return t
    }

    formatTimeForDate(t, d) {
        if (!t) return d
        if (t.substring(1, 2) === ":") {
            t = "0" + t
        }
        if (t.length === 4) {
            t = t.substring(0, 3) + "0" + t.substring(3, 4)
        }
        var isValid = /^[0-9:]*$/.test(t);
        if (isValid === false) {
            return d
        }
        var Hours = 0
        var Minutes = 0
        try {
            if (t.length > 2) {
                Hours = parseInt(t.substring(0, 2))
            }
            if (t.length > 3) {
                Minutes = parseInt(t.substring(3, 5))
            }
        } catch (e) {
            console.error(e, Hours, Minutes)
        }
        d.setHours(Hours, Minutes, 0);
        return d
    }
    static LocalISO = (date, format) => (
        date.toLocaleDateString('za', { year: 'numeric' }) + (format && format === 'h' ? '/' : '-') +
        date.toLocaleDateString('za', { month: '2-digit' }) + (format && format === 'h' ? '/' : '-') +
        date.toLocaleDateString('za', { day: '2-digit' }) + (format && format === 'h' ? ' ' : 'T') +
        date.toLocaleTimeString('en-US', { hour: '2-digit', hour12: false }).substring(0, 2) + ':' +
        String(date.toLocaleTimeString('za', { minute: 'numeric' })).padStart(2, '0') + ':' +
        date.toLocaleTimeString('za', { second: '2-digit' }).padStart(2, '0')
    )

    formatDate(t) {
        var isValid = /^[0-9\/]*$/.test(t)
        if (isValid === false) {
            //return t.substring(0, t.length - 1)
            // return t.replace(/^[0-9\/]/g, "0")
            t = t.replace(/[^0-9/]/g, "0")
        }
        if (t.substring(2, 3) === '/')
            t = "20" + t.substring(0, 2) + t.substring(2)

        // if (t.length === 5 && t.substring(2, 3) !== '/')
        //     t = "20" + t.substring(0, 2) + '/' + t.substring(3)
        if (t.length === 5)
            if (t.substring(4, 5) !== '/')
                t = t.substring(0, 4) + "/" + t.substring(4)
        if (t.length > 6)
            // 0123456789 
            // 2023-02-05
            if (t.substring(4, 5) === '/' && t.substring(6, 7) === '/')
                t = t.substring(0, 5) + "0" + t.substring(5)
        if (t.length > 7)
            if (t.substring(7, 8) !== '/')
                t = t.substring(0, 7) + "/" + t.substring(7)
        // if (t.length === 9)
        //     t = t.substring(0, 8) + "0" + t.substring(8)
        t = t.substring(0, 10)
        if (t === "0000000/00")
            return "0000/00/00"

        // alert(new Date("0000/00/00"))
        return t.substring(0, 10)
    }
    formatDateFinal(t, d) {
        // var isValid = /^[0-9\/]*$/.test(d)
        // if (isValid === false) {
        //     return t.substring(0, t.length - 1)
        // }
        if (t.substring(2, 3) === '/')
            t = "20" + t.substring(0, 2) + t.substring(2)

        if (t.length === 5)
            if (t.substring(4, 5) !== '/')
                t = t.substring(0, 4) + "/" + t.substring(4)
        if (t.length > 6)
            if (t.substring(4, 5) === '/' && t.substring(6, 7) === '/')
                t = t.substring(0, 5) + "0" + t.substring(5)
        if (t.length > 7)
            if (t.substring(7, 8) !== '/')
                t = t.substring(0, 7) + "/" + t.substring(7)

        if (t.length !== 10)
            return d

        if (t.length > 10)
            return d

        return this.formatForDate({ year: parseInt(t.substring(0, 4)), month: (parseInt(t.substring(5, 7)) - 1), day: parseInt(t.substring(8, 10)) }, d)
    }
    formatForDate(t, d) {
        if (!t) return d

        if (t.year)
            d.setFullYear(t.year)
        // if (t.month)
        d.setMonth(t.month)
        if (t.day)
            d.setDate(t.day)
        return d
    }
}
import React from 'react'

export default function PatientProfileSideBarItem({ category, active, onSelected }) {
  return (
    <div
      style={{ cursor: 'pointer' }}
      className={'p-3 menu-item '.concat(active ? ' active' : '')}
      onClick={(e) => onSelected(category.id)}>{category.groupNme}</div>
  )
}

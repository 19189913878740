import React, { useContext } from 'react'
import CalendarContext from '../../../../CalendarProvider'
import EventDetailsComponent from '../../../../common/EventDetails/EventDetailsComponent'

export default function EventDetails({ event, option, onSetProperty, properties }) {
  const { filters } = useContext(CalendarContext)

  return (
    properties[0] && properties[0].entry ?
      <EventDetailsComponent evt={{calendar: filters.calendar, ...properties[0].entry }} onSetProperty={onSetProperty} properties={properties} option={option} />
      :
      <EventDetailsComponent evt={{ ...event, calendar: filters.calendar }} onSetProperty={onSetProperty} properties={properties} option={option} />
  )
}

import { faTrash } from '@fortawesome/free-solid-svg-icons'
import React, { useEffect, useState } from 'react'
import { Pencil, Trash } from 'react-bootstrap-icons'
import MessagingService from '../../../../../../libs/api/services/MessagingService'
import UserSelectionComponent from './UserSelectionComponent'

export default function AudienceGroupComponent({ errors, onAudienceSelected, onAudienceSelectedObj, defaultValue }) {
    const [mode, setMode] = useState('NEW')
    const [step, setStep] = useState('GROUP_NAME')
    const [name, setName] = useState()
    const [users, setUsers] = useState([])
    const [group, setGroup] = useState({})
    const [groups, setGroups] = useState([])

    const _handleSetGroup = (id) => {

        if (onAudienceSelectedObj) onAudienceSelectedObj(groups.find(g => g.id === id))
        if (onAudienceSelected) onAudienceSelected(id)
    }
    const _handleShowNewGroup = () => {
        loadAudienceGroups()
            .then(() => {
                setMode('EXISTING')
            })
    }
    const _handleSetGroupName = () => {
        var _users = [];
        if (users) {
            for (var usr of users) {
                _users.push({
                    User: usr
                })
            }
        }
        MessagingService
            .SetAudienceGroup({
                id: group.id,
                name: name,
                MessageGroupAudience: _users
            })
            .then((r) => { if (onAudienceSelected) onAudienceSelected(r.id); setGroup(r); if (step === 'GROUP_NAME') setStep('SET_USER_LIST'); else _handleShowNewGroup(); })
            .catch((e) => { if (e.errors) errors(JSON.stringify(e.errors)); else errors(e.message); })

    }

    useEffect(() => {
        if (defaultValue) {
            setMode('EXISTING')
        }
    }, [defaultValue])

    useEffect(() => {
        loadAudienceGroups()
    }, [])

    const _handleAddUser = (user) => {
        var _users = [...users]
        _users.push(user)
        setUsers(_users)
    }
    const _handleRemoveUser = (idx) => {
        var _users = [...users]
        _users.splice(idx, 1)
        setUsers(_users)
    }
    const loadAudienceGroups = async () => {
        return await MessagingService
            .LoadAudienceGroupsAudienceGroups()
            .then((r) => setGroups(r))
            .catch((e) => { if (e.errors) errors(JSON.stringify(e.errors)); else errors(e.message); })
    }
    return (
        <>
            <div className='d-flex' style={{ cursor: 'pointer' }}>
                <div
                    onClick={(e) => setMode('NEW')}
                    className={'w-100 p-3 '.concat(mode === 'NEW' ? 'bg-primary-container' : 'bg-secondary-container')} > Create new audince group</div>
                <div
                    onClick={(e) => setMode('EXISTING')}
                    className={'w-100 p-3 '.concat(mode === 'EXISTING' ? 'bg-primary-container' : 'bg-secondary-container')} > Use existing audince group</div >
            </div>
            {
                mode === 'NEW'
                    ?
                    <div>
                        {
                            step === 'GROUP_NAME'
                                ?
                                <>
                                    <b>Audience group name eg: Demo Clinic clinicians contact list</b>
                                    <input
                                        value={name}
                                        defaultValue={group ? group.name : ''}
                                        onChange={(e) => setName(e.target.value)}
                                        className='form-control'
                                        placeholder='Audience group name'
                                    />
                                    <button onClick={(e) => _handleSetGroupName()} className='btn'>Create Group</button>
                                </>
                                : step === 'SET_USER_LIST'
                                    ?
                                    <>
                                        <div className='m-2'>
                                            <h3>{name} <Pencil onClick={(e) => setStep('GROUP_NAME')} /></h3>
                                        </div>
                                        <b>Select the users that will be sending and receiving messages with this configuration</b>
                                        <UserSelectionComponent clearOnSelect={true} onUserSelectedDetails={(i) => _handleAddUser(i)} />
                                        {
                                            users.map((u, idx) =>
                                                <div className='d-flex m-3'>
                                                    <div className='flex-grow-1 text-start'  >{u.firstName} {u.lastName}</div>
                                                    <div className='flex-shrink-1' onClick={(e) => _handleRemoveUser(idx)}><Trash size={24} icon={faTrash} /></div>
                                                </div>
                                            )
                                        }
                                        <button onClick={(e) => _handleSetGroupName()} className='btn'>Save User List</button>
                                    </>
                                    :
                                    <></>
                        }
                    </div>
                    :
                    <div>
                        <b>Group Name</b>
                        <select
                            onChange={(e) => _handleSetGroup(e.target.value)}
                            className='form-control '>
                            <option></option>
                            {
                                groups.map((g) =>
                                    <option
                                        selected={(((defaultValue && defaultValue.id === g.id) || group.id === g.id) ? 'selected' : '')}
                                        key={g.id} value={g.id}>{g.name}</option>
                                )
                            }
                        </select>
                    </div>
            }
        </>
    )
}

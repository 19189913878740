import React, { useEffect, useState } from 'react'
import Patient from '../../../../../libs/api/services/Patient'
import PatientProfileDetailTypeGrid from '../fieldTypes/PatientProfileDetailTypeGrid'
import PatientProfileDetailTypeList from '../fieldTypes/PatientProfileDetailTypeList'
import './ProfileProperties.css'

export default function PatientProfileCategoryFieldTypeOptions({ fieldId, fieldTypeId,isNew }) {
    const [options, setOptions] = useState([])
    const [activeCategory, setActiveCategory] = useState('')
    /*
    Sample:
    options [{
        id: b04c9bce-cb29-4ac1-b0e0-470ce86a421d,
        patientProfileDetailTypeOption: [
            {
                id: 33cd40de-e94e-4325-b645-f6e0fddb23ce
                option: List
            }
        ]
    }]
    */
    const loadFieldOptions = () => {
        if (fieldTypeId)
            Patient
                .FieldTypesOptions(fieldTypeId)
                .then((r) => setOptions(r))
    }

    useEffect(() => {
        loadFieldOptions()
    }, [fieldTypeId])


    /*
    @Function Save
    @Sample
    PatientProfileDetailTypeOption
    {
        PatientProfileDetailTypeOptionDefaults
        [
            {
                Id : 12544784844-454-45-516
                Option: 
            }
        ]
    }
    */
    const _handleSaveList = (optionId, detailId, list) => {
        Patient
            .SaveComponentDefaultValues(optionId, detailId, list).then((r) => {
                loadFieldOptions()
            })
        console.error('Need To Save To API', list)
    }

    const _GenerateProperties = (optionId, type) => {
        switch (type) {
            case 'List':
                return <PatientProfileDetailTypeList key={optionId} optionId={optionId} fieldId={fieldId} onSave={(list) => _handleSaveList(optionId, fieldId, list)} />
            case 'Grid':
                return <PatientProfileDetailTypeGrid key={optionId} optionId={optionId} fieldId={fieldId} onSave={(list) => _handleSaveList(optionId, fieldId, list)} />
            default:
                return <></>
        }
    }

    const _GetProperties = () => {
        if (activeCategory === '')
            return <></>
        let propertyComponents = [];
        const categories = options.find(f => f.category === activeCategory);
        if (typeof categories === 'undefined')
            return <></>
        for (var option of categories.options)
            propertyComponents.push(_GenerateProperties(option.id, option.option))

        return propertyComponents
    }

    return (
        <div className={'pb-3 rounded '.concat(isNew === true ? 'bg-secondary-container options-grid-component-primary ' : 'bg-primary-container ' )}>
            <div className='d-flex property-selection-options'>
                {
                    options.map((_options) => {
                        return (<div className={'shadow '.concat(activeCategory === _options.category ? ' active ' : ' opacity-75')}
                            onClick={(e) => setActiveCategory(_options.category)}
                        >{_options.category}</div>)
                    })
                }
            </div>
            <div className='p-3'>
                {_GetProperties()}
            </div>
        </div>
    );
}

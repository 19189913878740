import React from 'react'
import { Calendar2, Calendar2Check, Calendar2X, ClockFill } from 'react-bootstrap-icons'
import { ICONS } from '../../../../../components/icons/GenericIconPicker'
/*
0
: 
endDateTime
: 
"2024-03-10 16:58:00"
eventUID
: 
""
id
: 
""
startDateTime
: 
"2024-03-10 16:15:00"
status
: 
{}
summary
: 
"Event"
timeStamp
: 
""
[[Prototype]]
: 
Object
length
: 
1
[[Prototype]]
: 
Array(0)
mode
: 
"Month"
visible
: 
true
*/
export default function CalendarEventCard({ evt, onClick }) {

    const _statusWithIcon = (status) => {
        const misc = []
        try {
            if (Object.keys(evt).includes('additionalOptions')) {
                var _prop = evt.additionalOptions.find(p => p.option === "ICONS")
                if (_prop)
                    for (const i of JSON.parse(_prop.value))
                        misc.push(ICONS.find(x => x.name === i).icon)
            }
        } catch (e) { }
        switch (status) {
            case 'TENTATIVE': misc.push(<><Calendar2 /> {status}</>); break;
            case 'CONFIRMED': misc.push(<><Calendar2Check /> {status}</>); break;
            case 'CANCELLED': misc.push(<><Calendar2X /> {status}</>); break;
            default: misc.push(<><Calendar2 /> {status}</>); break;
        }
        return <div className='flex-shrink-1'>{misc}</div>
    }
    const _BG = () => {
        try {
            if (Object.keys(evt).includes('additionalOptions')) {
                var _prop = evt.additionalOptions.find(p => p.option === "COLOUR")
                if (_prop)
                    return { background: JSON.parse(_prop.value), color: '#ccc' }
            }
        } catch (e) { }

    }
    const _statusBG = (status) => {
        switch (status) {
            case 'TENTATIVE': return 'bg-warning-container'
            case 'CONFIRMED': return 'bg-success-container'
            case 'CANCELLED': return 'bg-danger-container'
            default: return <div><Calendar2 /> {status}</div>
        }
    }
    return (
        <div
            onClick={() => { if (onClick) onClick(evt) }}
            style={{ cursor: 'pointer', ..._BG() }} className={'rounded p-2 small '.concat(_statusBG(evt.status))}>
            <div className='text-start d-flex justify-content-between'>{_statusWithIcon(evt.status)}<div className='text-center flex-grow-1'><ClockFill className='me-2' />{evt.startDateTime} - {evt.endDateTime}</div></div>

            <div>{evt.summary}</div>

            <small>{evt.description}</small>
        </div>
    )
}

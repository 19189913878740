import React, { useState } from 'react'
import WorkFlow from '../../../../../../libs/api/services/WorkFlow'

const MesagesContext = React.createContext()

export const MessagesProvider = ({ defaultValue, event, children }) => {
    const [messages, setMessages] = useState(defaultValue ? defaultValue : [])

    const linkMessage = async (m) => {
        await WorkFlow
            .LinkMessageToWorkFlow(event.id, m.id)

        var _messages = [...messages]
        var exists = _messages.find(x => x.message.id === m.id)
        if (exists)
            _messages.splice(_messages.indexOf(exists), 1)
        else
            _messages.push({ message: m })
        setMessages([..._messages])
    }
    return (
        <MesagesContext.Provider value={{
            messages: messages,
            linkMessage,
            event
        }}>
            {children}
        </MesagesContext.Provider>
    )
}
export default MesagesContext
import { faSpinner } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useEffect, useState } from 'react'
import { FileCode, FileText } from 'react-bootstrap-icons'
import Questionnaire from '../../../../../libs/api/services/Questionnaire'
import QBuilderHeadingOptions from '../components/widgets/QBuilderHeadingOptions'
import QBuilderSectionTitleOptions from '../components/widgets/QBuilderSectionTitleOptions'
import QBuilderTitleOptions from '../components/widgets/QBuilderTitleOptions'

export default function QBuilderLayout({ title, description, navigation, errors, setValue, values }) {
    const [layouts, setLayouts] = useState([])
    const [template, setTemplate] = useState({})
    const [loading, setLoading] = useState(true)
    useEffect(() => {
        title('Questionnaire Layout')
        description('Select the type of layout you would like the questionnaire to be displayed as')
        // Load Navigation if session started
        if (Object.keys(values).includes('questionnaireId')) {
            navigation([
                // { text: 'Remove', type: 'Action', action: async () => { _handleRemoveTemplate(values.templateId).then((r) => { navigation([]); loadConfigs() }) } },
                {
                    text: 'Back', type: 'Back', action: async () => {
                        // setValue({ 'templateId': values.templateId })
                    }
                },
                {
                    text: 'Next', type: 'Next', action: async () => {
                        // setValue({ 'templateId': values.templateId })
                    }
                },
            ])
        }
        else
            navigation([])
        // try {
        // loadCache()
        // } catch (e) {
        loadConfigs()
        // }
        // loadConfigs()
    }, [])


    const _handleSetTempate = (template) => {
        Questionnaire
            .SetQuestionnaireLayout(values.questionnaireId, template.id)
            .then((e) => {
                setTemplate(template)
            })
    }
    const loadConfigs = async () => {
        try {
            var r = await Questionnaire.ListQuestionnaireLayouts() //.catch((e) => { errors(e.message); setLoading(false) })
            setLayouts(r)
            r = await Questionnaire.GetQuestionnaireLayout(values.questionnaireId) //.catch((e) => { errors(e.message); setLoading(false) })
            setTemplate(r)
            setLoading(false)
        } catch (e) {
            setLoading(false)
            errors(e.message);
        }
    }

    // const _handleSetConfigrationName = (c) => {
    //     setConfigurationName(c)
    //     if (c)
    //         navigation([{
    //             text: 'Next', type: 'Next', action: async () => {
    //                 // return await MessageTemple
    //                 //     .Create({
    //                 //         name: c
    //                 //     }).then((r) => {
    //                 //         setValue({ 'templateId': r.id, 'templateName': c })
    //                 //     })
    //             }
    //         }])
    //     else
    //         navigation([])
    //     setConfiguration()
    // }

    // const _handleRemoveTemplate = async (id) => {
    //     // await MessageTemple
    //     //     .RemoveTemplate(id)
    // }

    // const _handleSelectConfiguration = (c) => {
    //     if (c)
    //         navigation([
    //             { text: 'Remove', type: 'Action', action: async () => { _handleRemoveTemplate(c.id).then((r) => { navigation([]); loadConfigs() }) } },
    //             { text: 'Update', type: 'Next', action: async () => { setValue({ 'templateId': c.id, 'templateName': c.name }) } },
    //         ])
    //     setConfigurationName('')
    //     setConfiguration(c)
    // }

    const icon = (icon) => {
        switch (icon) {
            case 'FileText':
                return <FileCode size={50} className='p-2' />
            case 'FileCode':
                return <FileText size={50} className='p-2' />
            default:
                return <></>
        }
    }

    if (loading) return <div className='text-center'><FontAwesomeIcon className='me-2' icon={faSpinner} spin />Loading Settings</div>

    return (
        <div>
            <div className='d-flex flex-row justify-content-evenly align-items-evenly'>
                <div
                    className={'d-flex rounded flex-row align-items-center w-100 px-3'}
                >
                    {
                        layouts.map((l) =>

                            <div
                                onClick={(e) => _handleSetTempate(l)} style={{ cursor: 'pointer' }}
                                className={'p-3  w-100  '.concat(l.id === template.id ? 'active shadow bg-primary-container ' : '  ')}>
                                {icon(l.icon)}
                                <b>{l.layout}</b>
                                <p>{l.description}</p>
                            </div>
                        )
                    }

                </div>
            </div>
            <div className='bg-primary-container m-3 rounded shadow'>
                <div className='row'>
                    <div className='col-3'>
                        <QBuilderTitleOptions questionnaireId={values.questionnaireId} />
                    </div>
                    <div className='col-3'>
                        <QBuilderSectionTitleOptions questionnaireId={values.questionnaireId} />
                    </div>
                    <div className='col-3'>
                        <QBuilderHeadingOptions questionnaireId={values.questionnaireId} />
                    </div>
                </div>
            </div>
        </div>
    )
}
import React from 'react'
import { SiGooglecalendar, SiMicrosoftoutlook } from 'react-icons/si'
import GoogleCalendarProviderConfig from '../providers/GoogleCalendarProviderConfig'
import OutlookCalendarProviderConfig from '../providers/OutlookCalendarProviderConfig'

export const PROVIDERS = [
    {
        name: 'Google',
        description: 'Sync with a Google Calendar Account',
        icon: <SiGooglecalendar className='m-2' size={30} />,
        config: <GoogleCalendarProviderConfig />
    }, {
        name: 'Microsoft',
        description: 'Sync with a Outlook Calendar Account',
        icon: <SiMicrosoftoutlook className='m-2' size={30} />,
        config: <OutlookCalendarProviderConfig />
    }, 
    // {
    //     name: 'ical',
    //     description: 'Sync with an iCalendar Account',
    //     icon: <GiCalendar className='m-2' size={30} />,
    //     config: <InternetCalendarProviderConfig />
    // },
]
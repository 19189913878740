import { faClipboardList } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useEffect, useState } from 'react'
import { Calendar2, MenuUp } from 'react-bootstrap-icons'
import { GrDocumentUser, GrNotes } from 'react-icons/gr'
import LoaderComponent from '../../../../components/loader/LoaderComponent'
import WorkFlow from '../../../../libs/api/services/WorkFlow'
import WorkFlowCalendarSection from './Sections/WorkFlowCalendarSection'
import WorkFlowDMSSection from './Sections/WorkFlowDMSSection'
import WorkFlowMessagesSection from './Sections/WorkFlowMessagesSection'
import WorkFlowNotesSection from './Sections/WorkFlowNotesSection'
import WorkFlowQuestionnaireSection from './Sections/WorkFlowQuestionnaireSection'
import { WorkFlowManagementProvider } from './WorkFlowManagementProvider'
export default function WorkFlowDetailsScreen({ id }) {
    // const { id } = useParams()
    const [event, setEvent] = useState()
    const [active, setActive] = useState()

    const MENU = [
        {
            "label": 'Notes',
            icon: <GrNotes className='me-2' />,
            component: <WorkFlowNotesSection event={event} notes={event && event.notes} />
        }, {
            "label": 'Forms / Questionnaires',
            icon: <FontAwesomeIcon className='me-2' icon={faClipboardList} />,
            component: <WorkFlowQuestionnaireSection event={event} questionnaireAssignments={event && event.questionnaireAssignments} />
        }, {
            "label": 'Documents',
            icon: <GrDocumentUser className='me-2' />,
            component: <WorkFlowDMSSection event={event} documents={event && event.documents} />
        }, {
            "label": 'Calendar Events',
            icon: <Calendar2 className='me-2' />,
            component: <WorkFlowCalendarSection event={event} calendars={event && event.calendarEntries && event.calendarEntries} />
        }, {
            "label": 'Messages',
            icon: <MenuUp className='me-2' />,
            component: <WorkFlowMessagesSection event={event} messages={event && event.messages} />
        },
    ]
    const _load = () => {
        WorkFlow
            .GetWorkFlowEventHistory(id)
            .then((r) => setEvent(r))
    }
    useEffect(() => { _load() }, [])
    /*
{
    "id": "67574448-dbc6-4c59-b2b4-08dc5b04c76b",
    "wfEvent": {
        "id": "492459f8-503d-4015-1809-08dc5a791f12",
        "name": "Log calling patient",
        "isDeleted": false
    },
    "triggeredFor": {
        "id": 4,
        "title": "",
        "firstName": "test2",
        "lastName": "test2",
        "username": "test2",
        "password": "test2",
        "emailAddress": "",
        "mobileNumber": "",
        "userRoles": [],
        "userFacilitieRoles": []
    },
    "triggeredBy": {
        "id": 2,
        "title": "",
        "firstName": "Patient",
        "lastName": "Admin",
        "username": "patient",
        "password": "admin",
        "emailAddress": "",
        "mobileNumber": "",
        "userRoles": [],
        "userFacilitieRoles": []
    },
    "documents": [],
    "notes": [],
    "questionnaireAssignments": [
        {
            "id": "837df9e8-285a-4f6f-673f-08dc5b04c76f",
            "questionnaireAssignment": {
                "id": "ca3ec423-2829-4eac-cb48-08dc5b04c258",
                "assignedTo": {
                    "id": 4,
                    "title": "",
                    "firstName": "test2",
                    "lastName": "test2",
                    "username": "test2",
                    "password": "test2",
                    "emailAddress": "",
                    "mobileNumber": "",
                    "userRoles": [],
                    "userFacilitieRoles": []
                },
                "createdDate": "2024-04-12T17:25:17.8338136",
                "assignedDate": "2024-04-12T17:25:17.8339905"
            }
        }
    ],
    "messages": [
        {
            "id": "f0d9bd3f-96f6-4a9c-434e-08dc5b04c76e",
            "message": {
                "id": "17b0118d-ad20-4152-f0e7-08dc5b04c4bf",
                "to": "Patient Admin",
                "toUser": {
                    "id": 2,
                    "title": "",
                    "firstName": "Patient",
                    "lastName": "Admin",
                    "username": "patient",
                    "password": "admin",
                    "emailAddress": "",
                    "mobileNumber": "",
                    "userRoles": [],
                    "userFacilitieRoles": []
                },
                "from": "Patient Admin",
                "fromUser": {
                    "id": 2,
                    "title": "",
                    "firstName": "Patient",
                    "lastName": "Admin",
                    "username": "patient",
                    "password": "admin",
                    "emailAddress": "",
                    "mobileNumber": "",
                    "userRoles": [],
                    "userFacilitieRoles": []
                },
                "subject": "test",
                "sentDate": "2024-04-12T17:25:21.8475785",
                "receviedDate": "2024-04-12T17:25:21.8476393",
                "messageType": "Incomming"
            }
        }
    ]
}
    */
    if (!event) return <LoaderComponent />
    return (
        <WorkFlowManagementProvider
            event={event}
        >
            <div className='bg-primary-container m-3 rounded p-2 text-center flex-grow-1 shadow d-flex flex-column'
                style={{ maxHeight: 'calc(100% - 35px)' }}
            >
                <h1>Event Details</h1>
                <div>
                    <div className='details d-flex justify-content-between m-2'>
                        <div><b>Name</b>: {event.wfEvent.name}</div>
                        <div><b>Initiated By</b>: {event.triggeredFor ? `${event.triggeredBy.firstName} ${event.triggeredBy.lastName}` : ''}</div>
                        <div><b>Initiated For</b>: {event.triggeredFor ? `${event.triggeredFor.firstName} ${event.triggeredFor.lastName}` : ''}</div>
                    </div>
                </div>
                <div className='menu d-flex justify-content-between p-3' style={{ cursor: 'pointer' }}>
                    {
                        MENU.map((m) => <div
                            className={'p-2 w-100 rounded '.concat(active && active.label === m.label ? ' bg-secondary-container shadow ' : '  ')}
                            onClick={() => setActive(m)} key={'MNU-' + m.label}>{m.icon ? m.icon : ''}{m.label}</div>)
                    }
                </div>
                {active && active.component}
            </div>
        </WorkFlowManagementProvider>
    )
}

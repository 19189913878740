import React, { useState } from 'react'

const QuestionnaireContexet = React.createContext()

export const QuestionnaireProvider = ({ defaultValue, children }) => {
    const [questionnaires, setQuestionnaires] = useState(defaultValue ? defaultValue : [])
    return (
        <QuestionnaireContexet.Provider value={{
            questionnaires: questionnaires
        }}>
            {children}
        </QuestionnaireContexet.Provider>
    )
}

export default QuestionnaireContexet
import React, { useEffect, useState } from 'react'
import EmailEncryptionOptions from './Misc/EmailEncryptionOptions'
import EmailSecurityOptions from './Misc/EmailSecurityOptions'
import EmailServiceProviders from './Misc/EmailServiceProviders'

export default function EmailConfigurationComponentCombined({ type, onChange, defaultValue }) {
    const [config, setConfig] = useState(defaultValue ? defaultValue : {
        "userId": 0,
        "incomming_domain": "",
        "incomming_encryption": "",
        "incomming_port": 993,
        "incomming_security": "",
        "outgoing_domain": "",
        "outgoing_encryption": "",
        "outgoing_port": 465,
        "outgoing_security": "",
        "name": "",
        "password": "",
        "port": 0,
        "provider": "",
        "security": "",
        "emailAddress": "",
        "incomming_type": ""
    })

    useEffect(() => { onChange(config) }, [config])
    useEffect(() => { if (defaultValue && defaultValue.mailBoxConfig) setConfig(defaultValue.mailBoxConfig) }, [defaultValue])

    const _handleSetEmailProvider = (p) => {
        var _config = { ...config, ...p }
        setConfig({ ..._config })
    }
    const _handleSetValue = (k, v) => {
        var _config = config
        _config[k] = v
        setConfig({ ..._config })
    }
    return (
        <div className='bg-primary-container p-2 rounded shadow'>
            <div className='row'>
                <div className='col-12'><label>Email Service Provider</label></div>
                <div style={{ cursor: 'pointer' }} className='d-flex justify-content-evenly'>
                    {
                        EmailServiceProviders.map((p) =>
                            <div onClick={() => _handleSetEmailProvider(p)} className={'w-100 p-2 '.concat(p.provider === config.provider ? ' bg-secondary-container shadow rounded ' : '')}>
                                {p.provider}
                            </div>
                        )
                    }
                </div>
            </div>
            <div className='row'>
                <div className={''.concat(type === 'both' ? 'd-none' : 'col-6')}>
                    <label>Host</label>
                    <input
                        onChange={(e) => _handleSetValue('domain', e.target.value)}
                        value={config.domain} className='form-control form-control-sm' placeholder='SMTP Host' />
                </div>

            </div>
            {
                type === 'both'
                    ?
                    <>
                        <div className='row'>
                            <div className='col-6'>
                                Incomming Mail Settings
                                <div className='row'>
                                    <div className='col-2'>
                                        <select onChange={(e) => _handleSetValue('incomming_type', e.target.value)} className='form-control form-control-sm'>
                                            <option>Method</option>
                                            <option selected={config.incomming_type === 'pop' ? 'selected' : ''} value={'pop'}>POP</option>
                                            <option selected={config.incomming_type === 'imap' ? 'selected' : ''} value={'imap'}>IMAP</option>
                                        </select>
                                    </div>
                                    <div className='col-10'>
                                        <input
                                            onChange={(e) => _handleSetValue('incomming_domain', e.target.value)}
                                            value={config.incomming_domain}
                                            className='form-control form-control-sm' placeholder='Domain' />
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className='col-4'>
                                        <label>Port</label>
                                        <input
                                            onChange={(e) => _handleSetValue('incomming_port', e.target.value)}
                                            value={config.incomming_port}
                                            type='number'
                                            className='form-control form-control-sm' placeholder='Port' />
                                    </div>
                                    <div className='col-4'>
                                        <label>Security</label>
                                        <select
                                            onChange={(e) => _handleSetValue('incomming_security', e.target.value)}
                                            value={config.incomming_security}
                                            className='form-control form-control-sm'>
                                            {EmailSecurityOptions.map((o) => <option key={o} selected={config.security === o ? 'selected' : ''}>{o}</option>)}
                                        </select>
                                    </div>
                                    <div className='col-4'>
                                        <label>Encryption</label>
                                        <select
                                            onChange={(e) => _handleSetValue('incomming_encryption', e.target.value)}
                                            value={config.incomming_encryption}
                                            className='form-control form-control-sm'>
                                            {EmailEncryptionOptions.map((o) => <option key={o} selected={config.encryption === o ? 'selected' : ''}>{o}</option>)}
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <div className='col-6'>
                                Outgoing Mail Settings
                                <div className='row'>
                                    <div className='row'>
                                        <div className='col-12'>
                                            <input
                                                onChange={(e) => _handleSetValue('outgoing_domain', e.target.value)}
                                                value={config.outgoing_domain}
                                                className='form-control form-control-sm' placeholder='Domain' />
                                        </div>
                                    </div>
                                    <div className='col-4'>
                                        <label>Port</label>
                                        <input
                                            onChange={(e) => _handleSetValue('outgoing_port', e.target.value)}
                                            value={config.outgoing_port}
                                            type='number'
                                            className='form-control form-control-sm' placeholder='Port' />
                                    </div>
                                    <div className='col-4'>
                                        <label>Security</label>
                                        <select
                                            onChange={(e) => _handleSetValue('outgoing_security', e.target.value)}
                                            value={config.outgoing_security}
                                            className='form-control form-control-sm'>
                                            {EmailSecurityOptions.map((o) => <option key={o} selected={config.security === o ? 'selected' : ''}>{o}</option>)}
                                        </select>
                                    </div>
                                    <div className='col-4'>
                                        <label>Encryption</label>
                                        <select
                                            onChange={(e) => _handleSetValue('outgoing_encryption', e.target.value)}
                                            value={config.outgoing_encryption}
                                            className='form-control form-control-sm'>
                                            {EmailEncryptionOptions.map((o) => <option key={o} selected={config.encryption === o ? 'selected' : ''}>{o}</option>)}
                                        </select>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>
                    :
                    <></>
            }
            <div className='row'>
                <div className='col-12'>
                    <label>Your Name</label>
                    <input
                        onChange={(e) => _handleSetValue('name', e.target.value)}
                        value={config.name} className='form-control form-control-sm' placeholder='Your Name' />
                    <p><small>Your name is used when sending emails in order for the recipient to known the actual name to the email address</small></p>
                </div>
            </div>
            <div className='row'>
                <div className='col-6'>
                    <label>Email Address</label>
                    <input
                        onChange={(e) => _handleSetValue('emailAddress', e.target.value)}
                        value={config.emailAddress} className='form-control form-control-sm' placeholder='Email Address' />
                </div>
                <div className='col-6'>
                    <label>Email Password</label>
                    <input
                        onChange={(e) => _handleSetValue('password', e.target.value)}
                        type='password' className='form-control form-control-sm' placeholder='Password' />
                </div>
            </div>
        </div>
    )
}

import React, { useState } from 'react'
import ToastDialog from '../../../../components/dialogs/toast_dialog/ToastDialog'
import OnScreenAssist from '../../../../components/ui/hint/OnScreenAssist'
import PatientProfileAdminNew from './PatientProfileAdminNew'
import { PatientProfileProvider } from './PatientProfileProvider'
import PatientProfileAdminCategories from './category/PatientProfileAdminCategories'

export default function PatientProfileAdmin() {
  const [status, setStatus] = useState({ key: '', message: '', icon: 'check', time: 8, className: 'text-success' })

  return (
    <PatientProfileProvider>
      <ToastDialog
        {...status}
      />
      <div className='d-flex flex-column h-100 text-center'>
        <h1 className='m-0 p-0 '>Patient Profile Configuration</h1>
        <p className='mb-1  '>To setup patient information capture groups, please specify the details below</p>
        <div className='container-fluid shadow p-2 bg-primary-container rounded text-light' style={{overflow:'auto'}}>
          <PatientProfileAdminNew />
        </div>
        <div style={{ overflow: 'auto', height: '1px' }} className='container-fluid  rounded rounded-3 shadow pt-1  flex-grow-1'>
          <PatientProfileAdminCategories
            onStatusChange={(s) => setStatus({ ...status, message: s })}
          />
        </div>
      </div>
      <OnScreenAssist
        items={[
          {
            heading: 'Linked Events',
            description : 'This allows for adding shortcuts to patient events on the patient profile category form'
          },
          {
            heading: 'Field Name',
            description : 'This is the name of the field for the patient profile information eg: Patient Name, Patient Surname'
          },
          {
            heading: 'Component Options',
            description : 'This allows you to set the default values and the layout of the form component'
          },
          {
            heading: 'Link Fields',
            description : 'This allows you to link related data to avoid it being asked repeatedly'
          }
        ]}
      />
    </PatientProfileProvider>
  )
}

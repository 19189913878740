import CryptoJS from "crypto-js";

var AesEncryption = {
    init() {
        this.AesKey = 'ABCDEFGHIJKLMNOPQRSTUVWXYZABCDEF';
        this.AesIV = 'ABCDEFGHIJKLMNOP';
    },
    encrypt(jsonObject) {
        const cipher = CryptoJS.AES.encrypt(jsonObject, CryptoJS.enc.Utf8.parse(this.AesKey), {
            iv: CryptoJS.enc.Utf8.parse(this.AesIV), // parse the IV 
            padding: CryptoJS.pad.Pkcs7,
            mode: CryptoJS.mode.CBC
        })
        return cipher.toString()
    },

    decrypt(base64String, ref,debug) {
        const cipher = CryptoJS.AES.decrypt(base64String, CryptoJS.enc.Utf8.parse(this.AesKey), {
            iv: CryptoJS.enc.Utf8.parse(this.AesIV), // parse the IV 
            padding: CryptoJS.pad.Pkcs7,
            mode: CryptoJS.mode.CBC
        })
        var decryptedData = cipher.toString(CryptoJS.enc.Utf8)

        debug && console.log(decryptedData, ref);
        //console.log(JSON.parse(decryptedData));
        const _parsed = JSON.parse(decryptedData);
        return _parsed;
    }
}
AesEncryption.init();
export default AesEncryption;
import React, { Component } from 'react';
import { Person } from 'react-bootstrap-icons';
import Authentication from '../../libs/api/services/Authentication';
import CardButton from '../ui/card_button/CardButton';

export default class FacilityCardButton extends Component {

    handleSelectFacility = (id, roleId) => {
        if (id === -1)
            return window.location = '/sys-admin'
        if (Authentication
            .setFacility(id, roleId)) {
            // Get users role for the facility
            const role = Authentication.getFacilityRole()
            window.location = '/' + role;
        }
    }

    _handleRoles = () => {
        var ret = []
        if (this.props.roles)
            for (const role of this.props.roles) {
                ret.push(<button onClick={(e) => this.handleSelectFacility(this.props.id, role.id)} className='btn text-light text-start m-1 shadow bg-primary-container'><Person className='me-2' />{role.role}</button>)
            }
        return ret
    }

    render() {
        return (
            <CardButton
                key={this.props.id}
                id={this.props.id}
                onClick={(e) => this.props.id === -1 ? this.handleSelectFacility(-1, -1) : {}}
                tail={this._handleRoles()}
            >
                <div>
                    {this.props.children}
                </div>

                <div className='w-100'><small><a className='text-light' target='_blank' href={`/tnc/${this.props.id}`} rel="noreferrer" >{`${this.props.children === "System Administration" ? "DrData" : this.props.children} Ts & Cs`}</a></small></div>
            </CardButton>
        )
    }
}

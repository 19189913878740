import React from 'react'
import QuestionnaireEntries from './QuestionnaireEntries'
import { QuestionnaireProvider } from './QuestionnaireProvider'

export default function QuestionnaireContainer({ questionnaires }) {
    return (
        <QuestionnaireProvider defaultValue={questionnaires}>
            <QuestionnaireEntries />
        </QuestionnaireProvider>
    )
}

import React from 'react'
import { BellFill, Calendar2Fill, Calendar2PlusFill, ChatHeart, Clipboard2, Clipboard2Check, Clipboard2CheckFill, Clipboard2PlusFill, Pencil, QuestionCircle } from 'react-bootstrap-icons'

export default function QuickFlowTriggerTasks({ task, onChange }) {

  const ICON = (i) => {
    try {
      switch (i) {
        case 'Messaging': return <ChatHeart />
        case 'Generate A Form': return <Clipboard2Check />
        case 'Send Questionnaire': return <Clipboard2 />
        case 'Create a new calendar event': return <Calendar2PlusFill />
        case 'Update a calendar event': return <Calendar2Fill />
        case 'Send a notification': return <BellFill />
        case 'Set the status of a To Do list item': return <Clipboard2CheckFill />
        case 'Allocate a To Do list': return <Clipboard2PlusFill />
        default: return <QuestionCircle />
      }
    } catch (e) { }
  }
  return (
    <div className='d-flex'>
      <div className='flex-grow-1'>
        <b>{task.taskType ? ICON(task.taskType.type) : undefined}</b> {task.taskName}
      </div>
      <button className='flex-shrink-1 btn-xs' onClick={(e) => { e.stopPropagation(); onChange(task) }}>
        Update<Pencil className='ms-1' />
      </button>
    </div>
  )
}

import React, { useContext } from 'react'
import { InfoCircleFill } from 'react-bootstrap-icons'
import CalendarEntriesContext from './CalendarEntriesProvider'
import CalendarEntry from './CalendarEntry'

export default function CalendarEntriesEntries() {
    const { calendarEntries } = useContext(CalendarEntriesContext)

    return (
        <div>
            {
                calendarEntries && calendarEntries.length > 0 ? calendarEntries.map((c) => <CalendarEntry item={c} />)
                    : <div className='align-self-center align-items-center p-2 m-2 d-flex flex-column text-center'><InfoCircleFill className='m-2' size={30} /> There are no calendar entries generated for this item</div>
            }
        </div>
    )
}

import API from "../API";

var TaskEvents = {
    async Types() {
        var result = await API.process(
            'api/Events/Task/Types',
            'GET',
            null
        ).catch((err) => {
            throw err;
        })

        return result
    },
    async AddTask(Task) {
        var result = await API.process(
            'api/Events/Task',
            'POST',
            Task
        ).catch((err) => {
            throw err;
        })

        return result
    },
    /// Call is depricated on API
    async SetTaskEvent(eventId, TaskEvent) {
        var result = await API.process(
            `api/Events/Task/${eventId}`,
            'POST',
            TaskEvent
        ).catch((err) => {
            throw err;
        })

        return result
    },
    async ListTaskEvents(eventId) {
        var result = await API.process(
            `api/Events/${eventId}/Tasks`,
            'GET',
            null
        ).catch((err) => {
            throw err;
        })

        return result
    },
    async RemoveTask(taskId) {
        var result = await API.process(
            `api/Events/Task/${taskId}`,
            'DELETE',
            null
        ).catch((err) => {
            throw err;
        })

        return result
    },
    async RemoveAllTasks(templateId) {
        var result = await API.process(
            `api/Events/Task/${templateId}/All`,
            'DELETE',
            null
        ).catch((err) => {
            throw err;
        })

        return result
    },
    async TriggerTask(taskId, patientId,values,facilityId) {
        var result = await API.process(
            `api/Events/execute/${taskId}/${patientId}/${facilityId}`,
            'POST',
            values
        ).catch((err) => {
            throw err;
        })

        return result
    },
    async TriggerTaskUser(taskId, patientUserId,values,facilityId) {
        var result = await API.process(
            `api/Events/execute/${taskId}/${patientUserId}/${facilityId}/User`,
            'POST',
            values
        ).catch((err) => {
            throw err;
        })

        return result
    },
    async CheckPreConfig(taskId, patientId, facilityId) {
        var result = await API.process(
            `api/Events/execute/preconfig/${taskId}/${patientId}/${facilityId}`,
            'GET',
            null
        ).catch((err) => {
            throw err;
        })

        return result
    },
    async ListAllTasks() {
        var result = await API.process(
            `api/Patient/Event/Tasks`,
            'GET',
            null
        ).catch((err) => {
            throw err;
        })

        return result
    },
    async GetDetails(triggerId) {
        var result = await API.process(
            `api/Events/Event/Trigger/${triggerId}/Logs`,
            'GET',
            null
        ).catch((err) => {
            throw err;
        })

        return result
    },
}

export default TaskEvents;
import React, { useEffect, useState } from 'react'
import Authentication from '../../../../../../../../libs/api/services/Authentication'
import MessagingServiceProvider from '../../../../../../../../libs/api/services/MessagingServiceProvider'
import ServiceProviderPill from './ServiceProviderPill'

export default function ServiceProviders({ errors, setValue, values }) {
    const [providers, setProviders] = useState([])
    const [active, setActive] = useState()

    useEffect(() => {
        if (Object.keys(values).includes('serviceProvider') && values.serviceProvider) setActive(values.serviceProvider)
    }, [values])

    useEffect(() => { setValue({ serviceProvider: active }) }, [active])

    const _load = (activate) => {
        MessagingServiceProvider
            .ListFaciltyProviders(Authentication.getFacility())
            .then((r) => { if (activate) setActive(activate); setProviders(r); })
    }
    const _handleCreate = (sp) => {
        MessagingServiceProvider
            .UpdateProvider({ ...sp, facility: { id: Authentication.getFacility() } }).then((r) => { _load(r); }).catch((e) => errors(e))
    }
    const _handleRemove = async (sp) => {
        var res = await MessagingServiceProvider.RemoveProvider(sp).catch((e) => errors(e))
        if (res)
            _load()
        return true;
    }
    const _handleRename = async (sp) => {
        var res = await MessagingServiceProvider.UpdateProvider(sp).catch((e) => errors(e))
        if (res)
            _load()
        return true;
    }

    const _handleSeleted = (e) => {
        // setValue({ serviceProviderService: undefined })
        // setValue({ serviceProviderService: undefined, serviceProvider: e })
        // setValue({ serviceProvider: e })
        setActive(e)
    }
    useEffect(() => { _load() }, [])
    return (
        <div className='row'>
            {
                providers.map((p) =>
                    <ServiceProviderPill errors={errors} setValue={setValue} values={values} onDelete={(p) => _handleRemove(p)} onRename={(p) => _handleRename(p)} onSelected={(e) => _handleSeleted(e)} active={active && active.id === p.id} key={p.id} provider={p} />
                )
            }
            <ServiceProviderPill errors={errors} setValue={setValue} values={values} onCreate={(sp) => _handleCreate(sp)} onSelected={(e) => _handleSeleted(e)} active={active && active.id === -1} key={-1} provider={{ id: -1, name: 'New Provider' }} />
        </div>
    )
}

import API from "../API";

var User = {
    async search(data) {
        var result = await API.process(
            'api/Users/search',
            'POST',
            data
        ).catch((err) => {
            throw err;
        })

        return result
    },
    async get(id) {
        var result = await API.process(
            'api/Users/' + id,
            'GET',
            null
        ).catch((err) => {
            throw err;
        })

        return result
    },
    async add(user) {
        var result = await API.process(
            'api/Users/',
            'POST',
            user
        ).catch((err) => {
            throw err;
        })

        return result
    },
    async edit(userId, user) {
        var result = await API.process(
            'api/Users/' + userId,
            'PUT',
            user
        ).catch((err) => {
            throw err;
        })

        return result
    },
    async permissions(userId) {
        var result = await API.process(
            'api/Users/permissions',
            'GET',
            null
        ).catch((err) => {
            throw err;
        })

        return result
    },
    async addPermissions(userId, priv) {
        var result = await API.process(
            'api/Users/permissions/' + userId,
            'PATCH',
            priv
        ).catch((err) => {
            throw err;
        })

        return result
    },
    async removePermissions(userId, priv) {
        var result = await API.process(
            'api/Users/permissions/' + userId + "/" + priv.Id,
            'DELETE',
            priv
        ).catch((err) => {
            throw err;
        })

        return result
    },
    async facilities(userId) {
        var result = await API.process(
            'api/Users/facilities',
            'GET',
            null
        ).catch((err) => {
            throw err;
        })

        return result
    },
    async addFacility(userId, facilityId, roleId) {
        await API.process(
            'api/Users/facility/' + userId + '/' + facilityId + '/' + roleId,
            'PUT',
            null
        ).catch((err) => {
            throw err;
        })
    },
    async GetAccessableUsers() {
        var result = await API.process(
            'api/Users/Accessibility',
            'GET',
            null
        ).catch((err) => {
            throw err;
        })
        return result
    },
    async GetAccessableUsersByType(type) {
        var result = await API.process(
            'api/Users/Accessibility/' + type,
            'GET',
            null
        ).catch((err) => {
            throw err;
        })
        return result
    }
}

export default User;
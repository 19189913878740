import React from 'react'
import SearchComponent from '../search/SearchComponent'
import { PMBCODES } from './Config/PMBCODES'


export default function PMBCodeSelectionCompoonent({ onSelected, defaultValue }) {
    return (
        <SearchComponent
            onSelected={(r) => onSelected(r)}
            defaultValue={{ 'PMBCode': defaultValue }}
            details={{
                data: PMBCODES,
                onSelected: (r) => onSelected(r),
                options: {
                    reRefKey: 'PMBCode',
                    template: [{
                        refKey: 'PMBCode',
                        descr: 'Code',
                    }, {
                        refKey: 'description',
                        descr: 'Description',
                    }]
                }
            }}
        />
    )
}

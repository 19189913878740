import React, { useEffect, useState } from 'react'
import QBuilderGenerics from '../QBuilderGenerics'
import QBuilderToggle from './Generic/QBuilderToggle'

export default function QBuilderGeneralQuestionInfo({ onChanged, properties }) {
    const [fields, setFields] = useState({
        question: '',
        isRequired: false
    })

    useEffect(() => {
        var _fields = { isRequired: false, question: '' }

        try {
            _fields.isRequired = QBuilderGenerics.getProperty(properties, 'Required')
        } catch (e) {
            // Not Found
        }
        try {
            _fields.question = QBuilderGenerics.getProperty(properties, 'Question')
        } catch (e) {
            // Not Found
        }
        setFields({
            ...fields,
            ..._fields
        })
    }, [properties])

    const _handleSetFieldName = (v) => onChanged('Question', v)
    const _handleLoadFieldName = (f) => { return fields.question }

    const _handleSetRequired = (v) => onChanged('Required', v)

    return (
        <div style={{ cursor: 'pointer', zIndex: 999 }} className='bg-light rounded text-start p-2 opacity-75'>
            <div className='text-center'>
                <div className='d-flex justify-content-between'>
                    <b>Enter a name for the question</b>
                </div>
                <input
                    // onBlur={(e) => _handleSetFieldName(e.target.value)}
                    onKeyUp={(e) => _handleSetFieldName(e.target.value)}
                    defaultValue={_handleLoadFieldName("Question")}
                    className='form-control form-control-sm' placeholder='Question Name' />
                <div className='d-flex justify-content-between'>
                    <div>
                        <div>
                            <b>Is this field required?</b>
                        </div>
                        <QBuilderToggle onChange={(v) => _handleSetRequired(v)} isActive={fields.isRequired} descr={'Field is required'} />
                    </div>
                </div>
            </div>
        </div>
    )
}

import React, { useContext } from 'react'
import { LayoutInterface } from '../interfaces/LayoutInterface'
import VariablesContext from '../context/VariablesContext'

type Props = {
  layout: LayoutInterface
  children: React.ReactNode
}

type Dependency = {
  component: string
  error?: string
}

const DependenciesWrapper = ({ layout, children }: Props) => {
  const { variables } = useContext(VariablesContext)
  if (layout.dependencies && layout.type.toLowerCase() !== 'sourcegroup')
    for (const dependency of layout.dependencies!)
      if (typeof variables?.get(dependency.component.split('.')[0]) === 'undefined')
        return <div>{dependency.error!}</div>
      else if (
        typeof variables?.get(dependency.component.split('.')[0]).get(dependency.component.split('.')[1]) ===
        'undefined'
      )
        return <div>{dependency.error}</div>
  return children
}
export default DependenciesWrapper

export { Dependency }

import React, { useEffect, useState } from 'react'
import { ChevronDown, ChevronUp } from 'react-bootstrap-icons'
import OnScreenAssist from '../../../../../../../components/ui/hint/OnScreenAssist'
import MessagingServiceProvider from '../../../../../../../libs/api/services/MessagingServiceProvider'
import EmailConfigurationComponent from './Email/EmailConfigurationComponent'
import IntegrationAuthenticationRecvMessageBody from './integration/IntegrationAuthenticationRecvMessageBody'

export default function FacilityMessagingReceiving({ title, description, navigation, errors, setValue, values }) {
  const [loaded, setLoaded] = useState(false)
  const [show, setShow] = useState({ auth: false, req: false })
  const [properties, setProperties] = useState(Object.keys(values).includes('receiveProperties') ? values.receiveProperties : {})
  const [serviceType, setServiceType] = useState()
  useEffect(() => {
    if (properties && Object.keys(properties).length > 0)
      _save()

  }, [properties])

  useEffect(() => {
    // Load props
    if (values.serviceProviderService) {
      try {
        var _receiveProperties = values.serviceProviderService.properties.find(p => p.name === 'receiveProperties')
        if (_receiveProperties) {
          setProperties(JSON.parse(_receiveProperties.property))
        }
        if (values.serviceProviderService)
          setServiceType(values.serviceProviderService.serviceType)
      } catch (e) { }
    }
    // Load type

  }, [])
  const _save = async () => {
    if (Object.keys(values).includes('serviceProviderService')) {
      // Save to API values.serviceProviderService || values.serviceProviderService.properties
      var props = values.serviceProviderService.properties

      var _sendingProperties = props.find(p => p.name === "receiveProperties")
      if (_sendingProperties) {
        props.find(p => p.name === 'receiveProperties').property = JSON.stringify(properties)
      } else {
        props.push({ name: 'receiveProperties', property: JSON.stringify(properties) })
      }
      const _req = await MessagingServiceProvider
        .UpdateService(values.serviceProvider.id, values.serviceProviderService).catch((e) => errors(e))
      _sendingProperties = _req
      setValue({ serviceProviderService: _req })
    }
  }

  useEffect(() => {
    title('Receiving Messages')
    description('Please enter this service providers receiving details and format')
    navigation([
      {
        text: 'Back', type: 'Back', action: async () => { }
      },
      { text: 'Next', type: 'Next', action: async () => { } }
    ])
    // if(Object.keys(values).includes('sendProperties')) setProperties(values.sendProperties)
    // _loadSettings()
  }, [])
  if (!serviceType) return <></>
  if (values.serviceProviderService.serviceType.type === 'Email') {
    return (<EmailConfigurationComponent
      onChange={(r) => Object.keys(r).length > 0 ? setProperties({ ...properties, sendingProperties: r }) : undefined}
      defaultValue={properties && properties.sendingProperties ? properties.sendingProperties : undefined}
      type={'incomming'} />)
  } else {
    return (
      <>
        <div>
          <div>
            <div style={{ cursor: 'pointer' }} onClick={(e) => setShow({ ...show, auth: !show.auth })} className='p-1 rounded d-flex bg-primary-container'>
              <div className='flex-grow-1'><b>Authentication</b></div>
              <div className='flex-shrink-1'> {show.auth ? <ChevronUp /> : <ChevronDown />}</div>
            </div>
            <div className={''.concat(show.auth ? '' : 'd-none')}>
              {/* <IntegrationAuthentication
              defaultValue={properties.auth} onChange={(r) => setProperties({ ...properties, auth: r })} /> */}
            </div>
          </div>
          <div style={{ cursor: 'pointer' }} onClick={(e) => setShow({ ...show, request: !show.request })} className='p-1 rounded d-flex bg-primary-container'>
            <div className='flex-grow-1'><b>Request to send</b></div>
            <div className='flex-shrink-1'> {show.request ? <ChevronUp /> : <ChevronDown />}</div>
          </div>
          <div className={''.concat(show.request ? '' : 'd-none')}>
            <IntegrationAuthenticationRecvMessageBody
              values={values}
              defaultValue={properties.requst} onChange={(r) => setProperties({ ...properties, requst: r })}
            />
          </div>
        </div>
        <OnScreenAssist items={[{
          heading: 'Where can I find these details',
          description: '<ul class="list-unstyled"><li>For <b>Email</b> - These details are sent to you when you setup your email address with the service provider. They can also be found on the cPanel if you provider uses one</li><li><b>For SMS/WhatsApp</b> - These details can be found in the documentation on your service providers website.</li></ul>'
        }]} />
      </>
    )
  }
}

import React, { useContext, useEffect, useRef, useState } from 'react'
import { Calendar2PlusFill } from 'react-bootstrap-icons'
import DatePicker from '../../../../../components/ui/calendar/date_picket/DatePicker'
import CalendarGenerics from '../../CalendarGenerics'
import CalendarContext from '../CalendarProvider'
import CalendarEventPopup from './events/CalendarEventPopup'

export default function CalendarDayView() {
  const { filters, actieveTimeSlot, events, findEventForDate, setDate, } = useContext(CalendarContext)
  // const [date, setDate] = useState(filters ? { ...filters } : { year: undefined, month: undefined, day: undefined })
  const [daysEvts, setDaysEvts] = useState([])
  const [loading, setLoading] = useState(true)

  const [celendar, setCalendar] = useState([])

  const maxEventRef = useRef(1)
  const activeTimeRef = useRef()
  const [currentEvent, setCurrentEvent] = useState()
  const INTERVAL_SIZE = 30
  const _handleScrollCurrentTime = (e) => {
    if (activeTimeRef.current)
      activeTimeRef.current.scrollIntoView({ behavior: "smooth", block: "center", inline: "nearest" })
  }

  useEffect(() => {
    _handleScrollCurrentTime()
    _buildWithEvents()
  }, [])

  useEffect(() => {
    // setDate(filters)
    // _handleScrollCurrentTime()
    _handleLoadEvents()
  }, [filters])

  useEffect(() => {
    _handleLoadEvents()
  }, [events])


  useEffect(() => {
    _buildWithEvents()
  }, [daysEvts])

  const _handleTSClick = (ts, ev) => {
    const hm = ts.split(':')
    // setCurrentEvent({ date: { start: new Date(filters.year + '-' + String(filters.month + 1).padStart(2, '0') + '-' + String(filters.day).padStart(2, '0') + 'T' + String(hm[0]).padStart(2, '0') + ':' + String(hm[1]).padStart(2, '0') + ':00') } })
    setDate(CalendarGenerics.AdMinutes(new Date(filters.year, filters.month, filters.day), ((hm[0] * 60)) + parseInt(hm[1])), 'Day', ev)
  }

  const _handleLoadEvents = async () => {
    setLoading(true)
    setDaysEvts(await findEventForDate(filters.year + '-' + String(filters.month + 1).padStart(2, '0') + '-' + String(filters.day).padStart(2, '0')))
  }

  const _buildWithEvents = () => {
    maxEventRef.current = 1
    var _active_events = 1
    // Pre Calc max events
    for (const ts of CalendarGenerics.timeIntervals) {
      for (const d of daysEvts) {
        if (d && ts === d.startDateTime.substring(11, 13) + ':' + String((Math.floor(d.startDateTime.substring(14, 16) / INTERVAL_SIZE) * INTERVAL_SIZE)).padStart(2, '0')) {
          // _cnt_events++
          _active_events++
        }
        if (d && ts === d.endDateTime.substring(11, 13) + ':' + String((Math.floor(d.endDateTime.substring(14, 16) / INTERVAL_SIZE) * INTERVAL_SIZE)).padStart(2, '0')) {
          _active_events--
        }
      }
      maxEventRef.current = maxEventRef.current > _active_events ? maxEventRef.current : _active_events
    }
    var _cal = []
    var _evts = []
    _active_events = 0
    for (const ts of CalendarGenerics.timeIntervals) {
      // Pre render events
      // _cnt_events = 0
      _evts = []
      for (const d of daysEvts) {
        // If starts on this TS to the nearest INTERVAL_SIZE
        var _etst = (Math.ceil(d.endDateTime.substring(14, 16) / INTERVAL_SIZE) * INTERVAL_SIZE)
        var _etsh = d.endDateTime.substring(11, 13)
        if (_etst >= 60) {

          _etsh = String((parseInt(_etsh) + 1)).padStart(2, "0")
          _etst = '00'
        }


        if (d && ts === d.startDateTime.substring(11, 13) + ':' + String((Math.floor(d.startDateTime.substring(14, 16) / INTERVAL_SIZE) * INTERVAL_SIZE)).padStart(2, '0')) {
          // calc to INTERVAL_SIZE min slots || 1hr == 4 | min == Floor(mins / INTERVAL_SIZE)
          _active_events++
          // hours 
          // TODO : Calc days
          const oneDay = 24 * 60 * 60 * 1000; // hours*minutes*seconds*milliseconds
          const firstDate = new Date(d.endDateTime);
          const secondDate = new Date(d.startDateTime);

          const diffDays = Math.round(Math.abs((firstDate - secondDate) / oneDay));

          const et = d.endDateTime.substring(11, 16)
          const st = d.startDateTime.substring(11, 16)
          var etmn
          if (st === et) {
            etmn = ((parseInt(et.split(':')[0]) * (60 / INTERVAL_SIZE)) * INTERVAL_SIZE + (Math.ceil((parseInt(et.split(':')[1]) + 1) / INTERVAL_SIZE) * INTERVAL_SIZE) + ((diffDays * 24) * (INTERVAL_SIZE * (60 / INTERVAL_SIZE)))) //((diffDays * 24 ) * INTERVAL_SIZE) )
          } else {
            etmn = ((parseInt(et.split(':')[0]) * (60 / INTERVAL_SIZE)) * INTERVAL_SIZE + (Math.ceil(et.split(':')[1] / INTERVAL_SIZE) * INTERVAL_SIZE) + ((diffDays * 24) * (INTERVAL_SIZE * (60 / INTERVAL_SIZE))))//((diffDays * 24 ) * INTERVAL_SIZE) )
          }
          const stmn = ((st.split(':')[0] * (60 / INTERVAL_SIZE)) * INTERVAL_SIZE) + (Math.floor(st.split(':')[1] / INTERVAL_SIZE) * INTERVAL_SIZE)

          // _cnt_events++
          /**
            * Check for a background colour
            */
          var _bgc = undefined
          var _icos = undefined
          try {
            _bgc = d.action.properties.find(f => f.name === "BACKGROUND_COLOUR")
            if (_bgc)
              _bgc = JSON.parse(_bgc.value)
          } catch (e) { }
          // Custom colour takes pref
          try {
            if (Object.keys(d).includes('additionalOptions')) {
              var _prop = d.additionalOptions.find(p => p.option === "COLOUR")
              if (_prop) {
                _bgc = JSON.parse(_prop.value)
              }
            }
          } catch (e) { }

          /**
           * Check for icons
           */
          try {
            _icos = d.action.properties.find(f => f.name === "ICONS")
            if (_icos)
              _icos = JSON.parse(_icos.value)
          } catch (e) { }
          var _bg = ''
          switch (d.status) {
            case 'CANCELLED': _bg = 'bg-danger-container'; break;
            case 'TENTATIVE': _bg = 'bg-warning-container'; break;
            case 'CONFIRMED': _bg = 'bg-success-container'; break;
            default: _bg = 'bg-secondary-container'
          }
          _evts.push(<td style={{ cursor: 'pointer', background: _bgc }} rowSpan={((etmn - stmn) / INTERVAL_SIZE)} onClick={() => _handleTSClick(ts, d.id)} className={' '.concat(_bg)}>
            <div>
              <small>{_icos && _icos.map((i) => i && i.icon)}</small>
            </div>
            {d.summary}</td>)
        }
        // TODO : Acomodate for dates
        if (ts === _etsh + ':' + _etst) {
          _active_events--
        }

      }
      _cal.push(<tr className='item-row' ref={ts === actieveTimeSlot ? activeTimeRef : undefined}>
        <td onClick={() => _handleTSClick(ts)} className={'time-slot  '.concat(actieveTimeSlot === ts ? ' active py-1 ' : '')}><span className='d-flex justify-content-between align-items-center px-2'>{ts} <Calendar2PlusFill /></span></td>
        {_evts}
      </tr>)
    }
    const _handleChangeDate = (ndate) => {
      setDate(ndate)
    }
    setCalendar(<table key={new Date()} className='calendar-table day'>
      <colgroup>
        <col span="1" style={{ width: "10%", minWidth: '50px' }} />
        {/* <col span="1" style={{ width: "95%" }} /> */}
      </colgroup>
      <thead className='header'>
        <tr className='shadow'>
          <th colSpan={maxEventRef.current + 2}>
            <span ></span>
            <DatePicker
              onChange={(d) => _handleChangeDate(new Date(d))}
              small={true}
              type={'Date'}
              defaultValue={`${filters.year}-${String(filters.month + 1).padStart(2, '0')}-${String(filters.day).padStart(2, '0')}`}
            />
            {/* {`${filters.year}-${String(filters.month + 1).padStart(2, '0')}-${String(filters.day).padStart(2, '0')}`} */}

          </th>
        </tr>
      </thead>
      <tbody>
        {
          _cal
        }
      </tbody>
    </table>)
    setLoading(false)
  }
  return (
    <>
      {
        loading ? <></> :
          <>
            <CalendarEventPopup event={currentEvent} />
            {celendar ? celendar : <></>}
          </>
      }


    </>

  )
}

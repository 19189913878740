import { faSpinner } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useContext, useEffect, useState } from 'react'
import CalendarContext from '../CalendarProvider'

export default function CalendarEventActions({ calendarType, activeAction, setActiveAction, evt }) {
  // const [activeAction, setActiveAction] = useState()
  const [options, setOptions] = useState([])
  const [loading, setLoading] = useState(false)
  const { calendarOptions } = useContext(CalendarContext)

  const _findActionsToView = async () => {
    setLoading(true)
    const res = await calendarOptions()
    setLoading(false)
    if (evt && evt.type) // Update
    {
      setOptions(res.filter((f) => f.whenTriggered && (f.whenTriggered.trigger !== 'CREATE_CALENDAR_EVENT' && f.whenTriggered.trigger !== 'INITIATE_SEQUENCE')
        && f.availableCalendars.filter((d) => d.calendarType.type === calendarType)
      ))
      // return await calendarOptions().filter((f) => (f.triggeredWhen.trigger !== 'CREATE_CALENDAR_EVENT' && f.triggeredWhen.trigger !== 'INITIATE_SEQUENCE') && f.availableCalendars && f.availableCalendars.includes(calendarType))
    } else {
      setOptions(res.filter((f) => f.whenTriggered && (f.whenTriggered.trigger === 'CREATE_CALENDAR_EVENT' || f.whenTriggered.trigger === 'INITIATE_SEQUENCE')
        && f.availableCalendars.filter((d) => d.calendarType.type === calendarType)
      ))
      // return await calendarOptions().filter((f) => (f.triggeredWhen.trigger === 'CREATE_CALENDAR_EVENT' || f.triggeredWhen.trigger === 'INITIATE_SEQUENCE') && f.availableCalendars && f.availableCalendars.includes(calendarType))
    }
  }

  useEffect(() => { _findActionsToView() }, [evt])

  return (
    <div>
      {
        <div className='d-flex flex-column justify-content-start align-items-start p-2 rounded h-100 small d-flex w-100'>
          {
            // _findActionsToView()
            loading ?
              <div className='text-start'><FontAwesomeIcon icon={faSpinner} spin /></div>
              :
              options
                .map((e, i) =>
                  <div key={`CalendarEventActions-${i}`} style={{ cursor: 'pointer' }} onClick={() => setActiveAction(e)} className={' mb-1 p-2 w-100 rounded text-start '.concat(activeAction && activeAction.id === e.id ? 'fw-bold shadow bg-primary-container ' : ' bg-secondary-container ')}>
                    {e.eventName}
                  </div>
                )
          }
        </div>
      }

    </div>
  )
}

import React, { useRef, useState } from 'react'
import './FileComponent.css'

type Props = {
  name: string
  onChange: (name: string, value: any) => void
}

export default function FontFileComponent(props: Props) {
  const [processingFile, setProcessingFile] = useState(false)
  const [font, setFont] = useState<any>()

  const [dragActive, setDragActive] = useState(false)
  const inputRef = useRef<HTMLInputElement>(null)

  // handle drag events
  const handleDrag = function (e: any) {
    e.preventDefault()
    e.stopPropagation()
    if (e.type === 'dragenter' || e.type === 'dragover') {
      setDragActive(true)
    } else if (e.type === 'dragleave') {
      setDragActive(false)
    }
  }

  // triggers when file is selected with click
  const handleChange = function (e: any) {
    e.preventDefault()
    if (e.target.files && e.target.files[0]) {
      // at least one file has been selected so do something
      // handleFiles(e.target.files);
      handleFileInputChange(e.target.files[0])
    }
  }
  // triggers the input when the button is clicked
  const onButtonClick = () => {
    inputRef.current!.click()
  }
  // triggers when file is dropped
  const handleDrop = function (e: any) {
    e.preventDefault()
    e.stopPropagation()
    setDragActive(false)
    if (e.dataTransfer.files && e.dataTransfer.files[0]) {
      handleFileInputChange(e.dataTransfer.files[0])
      // at least one file has been dropped so do something
      // handleFiles(e.dataTransfer.files);
    }
  }

  async function getBase64(file: any) {
    return new Promise((resolve) => {
      //   let baseURL = ''
      // Make new FileReader
      const reader = new FileReader()

      // Convert the file to base64 text
      reader.readAsDataURL(file)

      // on reader load somthing...
      reader.onload = async () => {
        // Make a fileInfo Object
        // baseURL = reader.result
        resolve(reader.result)
      }
    })
  }

  function validateFileFormat(format: any) {
    console.log(format)
    // let formats: string = ''
    // if (!formats) {
    //   formats = 'application/json,application/pdf,image/jpeg'
    // }
    // if (!formats.includes(format)) {
    //   // toast.error(Intl("claims.proces.upload.formatnotsupported.message"), {
    //   //     position: "top-right",
    //   //     autoClose: 5000,
    //   //     hideProgressBar: true,
    //   //     closeOnClick: true,
    //   //     pauseOnHover: true,
    //   //     draggable: true,
    //   //     progress: undefined,
    //   // });
    //   return false
    // }
    return true
  }
  function handleFileInputChange(file: any) {
    setProcessingFile(true)
    if (validateFileFormat(file.type)) {
      getBase64(file)
        .then((result) => {
          props.onChange(props.name, { fileName: file.name, fileType: file.type, fileData: result })
          setFont(result)
          setProcessingFile(false)
        })
        .catch((err) => {
          console.log(err)
          setProcessingFile(false)
        })
    } else {
      setProcessingFile(false)
    }
  }

  return (
    <form id='form-file-upload' onDragEnter={handleDrag} onSubmit={(e) => e.preventDefault()}>
      {processingFile ? (
        <div className='wizard-file-processing'>Processing</div>
      ) : font ? (
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            height: '100%',
            justifyContent: 'space-evenly',
          }}
        >
          {/* <img style={{ width: '250px', height: '250px', objectFit: 'contain' }} src={image} alt='Uploaded File' /> */}
          <style>
            @font-face
            {`{
            font-family: '${props.name}';
            src: url(${font});
            }`}
            {/* src: url(data:application/x-font-woff;charset=utf-8;base64,base64_code_here) format('woff'); */}
          </style>
          <div
            style={{
              fontFamily: props.name,
              padding: '8px',
            }}
          >
            <h1>{props.name}</h1>
          </div>
          <button onClick={() => setFont(undefined)}>Discard</button>
        </div>
      ) : (
        <>
          <input ref={inputRef} type='file' id='input-file-upload' multiple={true} onChange={handleChange} />
          <label id='label-file-upload' htmlFor='input-file-upload' className={dragActive ? 'drag-active' : ''}>
            <div>
              <p>Drag and drop your file here or</p>
              <button className='upload-button' onClick={onButtonClick}>
                Upload a file
              </button>
            </div>
          </label>
          {dragActive && (
            <div
              id='drag-file-element'
              onDragEnter={handleDrag}
              onDragLeave={handleDrag}
              onDragOver={handleDrag}
              onDrop={handleDrop}
            ></div>
          )}
        </>
      )}
    </form>
  )
}

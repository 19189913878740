import React from 'react'
import { SectionInterface } from '../interfaces/SectionsInterface'
import Component from '../Component'
import { ActionInterface } from '../interfaces/ActionInterface'
type Props = {
  flow?: 'Wizard' | 'Form' | 'ConfigForm' | undefined
  section: string
  sections: SectionInterface[] | undefined
  onClick: (section: string, name: string) => void
  actionRunner: (s: string, n: string, v?: string, a?: ActionInterface[]) => Promise<any>
  // children: React.ReactNode
}

const ActionsBar = (props: Props) => {
  const _items: React.ReactNode[] = []
  const _findActions = () => {
    for (let i = 0; i < props.sections!.length; i++) {
      if (props.sections![i].name === props.section) {
        for (const layout of props.sections![i].layout) {
          if (layout.grid && layout.grid === 'navigation') {
            _items.push(
              <Component
                variables={new Map<string, any>()}
                key={layout.name}
                layout={layout}
                onClick={props.onClick}
                actionRunner={props.actionRunner}
                section={props.section}
              />,
            )
          }
        }
        // next = props.sections![i + 1].name
      }
    }
    return _items
  }
  return _findActions()
}
export default ActionsBar

import React from 'react'
import { useState } from 'react'
import { useEffect } from 'react'
import { ChevronDoubleLeft, ChevronDoubleRight } from 'react-bootstrap-icons'

export default function DatePickerHour({ onChange, onTimeChange, onDateClick, year, month, day, time }) {
    function lpad(str, padString, length) {
        if (str === 'undefined')
            return '';
        while (str.length < length)
            str = padString + str;
        return str;
    }

    const _generateHours = () => {
        var rows = []
        var cols = []
        var c = 0;
        for (var i = 0; i <= 23; i++, c++) {
            if (c % 2 == 0) {
                rows.push(<tr>{cols}</tr>)
                cols = []
            }
            const x = i
            cols.push(<td className={x == time.hour ? 'date-picker-items active' : 'date-picker-items '} onClick={(e) => onTimeChange({ ...time, hour: ''+x })}><div style={{ cursor: 'pointer' }}  className='m-1'>{lpad('' + i, "0", 2)}</div></td>)
        }
        rows.push(<tr>{cols}</tr>)
        return <table className='table'>{rows}</table>
    }

    const _generateMinutes = () => {
        var rows = []
        var cols = []
        var c = 0;
        for (var i = 0; i <= 59; i++, c++) {
            if (c % 2 == 0) {
                rows.push(<tr>{cols}</tr>)
                cols = []
            }
            const x = i
            cols.push(<td className={x == time.minute ? 'date-picker-items active' : 'date-picker-items'} onClick={(e) => onTimeChange({ ...time, minute: ''+x })}><div style={{ cursor: 'pointer' }}  className='m-1'>{lpad('' + i, "0", 2)}</div></td>)
        }
        rows.push(<tr>{cols}</tr>)
        return <table className='table'>{rows}</table>
    }
    const _generateSeconds = () => {
        var rows = []
        var cols = []
        var c = 0;
        for (var i = 0; i <= 59; i++, c++) {
            if (c % 2 == 0) {
                rows.push(<tr>{cols}</tr>)
                cols = []
            }
            const x = i
            cols.push(<td className={x == time.second ? 'date-picker-items active' : 'date-picker-items'} onClick={(e) => onTimeChange({ ...time, second: ''+x })}><div style={{ cursor: 'pointer' }} className='m-1'>{lpad('' + i, "0", 2)}</div></td>)
        }
        rows.push(<tr>{cols}</tr>)
        return <table className='table'>{rows}</table>
    }
    return (
        <div>
            <div style={{ cursor: 'pointer' }} onClick={(e) => onDateClick()} className='text-center p-2 date-picker-head'>
                {year}-{lpad('' + month, "0", 2)}-{lpad('' + day, "0", 2)} 
            </div>
            {
                <div className='d-flex'>
                    <div>
                        <b>Hour</b>
                        <div style={{ maxHeight: '210px', overflowY: 'scroll' }}>
                            {_generateHours()}
                        </div>
                    </div>
                    <div>
                        <b>Minute</b>
                        <div style={{ maxHeight: '210px', overflowY: 'scroll' }}>
                            {_generateMinutes()}
                        </div>
                    </div>
                    <div>
                        <b>Seconds</b>
                        <div style={{ maxHeight: '210px', overflowY: 'scroll' }}>
                            {_generateSeconds()}
                        </div>
                    </div>
                </div>
            }
        </div>
    )
}

import React, { useContext, useState } from 'react'
import CalendarContext from '../../CalendarProvider'
import { Calendar2DayFill, Calendar2Month, Calendar2MonthFill, Calendar2WeekFill, Clipboard2, Link } from 'react-bootstrap-icons'

export default function CalendarViewFilter({ children }) {
    const { filters, setFilter } = useContext(CalendarContext)
    // const [currentView, setCurrentView] = useState(view ? view : 'Month')
    const [exapnd, setExpand] = useState(false)

    const _handleChangeView = (view) => {
        setExpand(false)
        setFilter({ view: view })
    }
    return (
        <div style={{ cursor: 'pointer' }} className='calendar-filter '>
            <div className='text-center'><b>View</b></div>
            <div className='d-flex align-items-center justify-content-center'>
                <div className={'p-1 rounded '.concat(filters.view === 'Month' || filters.view === 'Celendar' ? 'bg-secondary-container shadow ' : '')} onClick={() => _handleChangeView('Month')}><Calendar2MonthFill /></div>
                <div className={'p-1 rounded '.concat(filters.view === 'Week' ? 'bg-secondary-container shadow ' : '')} onClick={() => _handleChangeView('Week')}><Calendar2WeekFill /></div>
                <div className={'p-1 rounded '.concat(filters.view === 'Day' ? 'bg-secondary-container shadow ' : '')} onClick={() => _handleChangeView('Day')}><Calendar2DayFill /></div>
            </div>
        </div>
    )
}

import API from "../API";
import jwt_decode from "jwt-decode";

var Migration = {
    async ExportProfiles() {
        var result = await API.process(
            'api/Migration/export/patientProfile',
            'GET',
            null
        ).catch((err) => {
            throw err;
        })

        return result
    },
    async ImportPatientProfileDetails(profile) {
        var result = await API.process(
            'api/Migration/import/patientProfileDetails',
            'PUT',
            profile
        ).catch((err) => {
            throw err;
        })

        return result
    },
    async ImportPatientProfile(profile) {
        var result = await API.process(
            'api/Migration/import/patientProfile',
            'PUT',
            profile
        ).catch((err) => {
            throw err;
        })

        return result
    },
    async ImportQuestionniares(profile) {
        var result = await API.process(
            'api/Migration/import/Questionnaires',
            'PUT',
            profile
        ).catch((err) => {
            throw err;
        })

        return result
    },
    async ImportDataLink(profile) {
        var result = await API.process(
            'api/Migration/import/LinkedFields',
            'PUT',
            profile
        ).catch((err) => {
            throw err;
        })

        return result
    },
    async ExportPatientProfile() {
        var result = await API.process(
            'api/Migration/export/patientProfile',
            'GET',
            null
        ).catch((err) => {
            throw err;
        })

        return result
    },
    async ExportPatientProfileDetails() {
        var result = await API.process(
            'api/Migration/export/patientProfileDetails',
            'GET',
            null
        ).catch((err) => {
            throw err;
        })

        return result
    },
    async ExportQuestionnaires() {
        var result = await API.process(
            'api/Migration/export/Questionnaires',
            'GET',
            null
        ).catch((err) => {
            throw err;
        })

        return result
    },
    async ExportDataLinks() {
        var result = await API.process(
            'api/Migration/export/LinkedFields',
            'GET',
            null
        ).catch((err) => {
            throw err;
        })

        return result
    }
}

export default Migration;
import React from 'react'

export default function DatePickerMonth({ onChange, onYearClick, year, hideYear }) {

    const Months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
    const _generateMonths = () => {
        var rows = []
        var cols = []
        var c = 0;
        for (var i = 0; i < Months.length; i++, c++) {
            if (c % 3 == 0) {
                rows.push(<tr>{cols}</tr>)
                cols = []
            }
            const x = i
            cols.push(<td className='date-picker-items' key={x} onClick={(e) => onChange(x)}><div style={{ cursor: 'pointer' }} className='m-1'>{Months[x]}</div></td>)
        }
        rows.push(<tr>{cols}</tr>)
        return <table className='table'>{rows}</table>
    }
    return (
        <div>
            {
                !hideYear
                    ?
                    <div style={{ cursor: 'pointer' }} onClick={(e) => onYearClick()} className='text-center p-2 date-picker-head'>
                        {year}
                    </div>
                    :
                    <></>
            }
            {
                _generateMonths()
            }
        </div>
    )
}

import { faSpinner } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useContext, useEffect, useState } from 'react'
import InlineWizardComponent from '../../../../components/inline_wizard/InlineWizardComponent'
import InlineWizardItemComponent from '../../../../components/inline_wizard/InlineWizardItemComponent'
import ValidationWidget from '../../../../components/validattion/ValidationWidget'
import Patient from '../../../../libs/api/services/Patient'
import PatientProfileContext from './PatientProfileProvider'
import PatientProfileCategoryFieldsContainer from './field/PatientProfileCategoryFieldsContainer'

export default function PatientProfileAdminNew() {
  const { execute, createCategory, saveCategory, state, reload } = useContext(PatientProfileContext)

  const [cats, setCats] = useState([])
  const [catError, setCatError] = useState()

  const loadCategories = () => {
    Patient.Categories().then((r) => {
      setCats(r)
    })
  }

  useEffect(() => {
    loadCategories()
  }, [reload])

  const _handleChangeCategory = (v) => {
    if (cats.find(c => c.groupNme.trim().toLowerCase() === v.trim().toLowerCase()) !== undefined)
      setCatError('Already Exists')
    else {
      setCatError('')
    }
    //execute('START_CREATE_NEW_CATEGORY', v)
    execute('SET_CREATE_NEW_CATEGORY', v)
    //dispatch({ type: 'CHANGE_CATEGORY_ORDER_FROM_SERVER', categories: r })

    //
  }
  const _handleSaveCategory = async () => {
    try {
      await execute('SAVE_NEW_CATEGORY_TO_SERVER', null)
    } catch (e) {
      setCatError(e.message)
      return false
    }
    return true
  }

  return state.loading ?
    <div><FontAwesomeIcon className='me-3' icon={faSpinner} spin={true} />LOADING</div>
    :
    (
      <InlineWizardComponent className={'text-center'}>
        <InlineWizardItemComponent nextButtonText='Create Category' action={catError ? null : _handleSaveCategory}>
          <h3 className='p-0 m-0 '>New Category</h3>
          <p className='mb-1 '>A patient profile category is a group of information relating to a patient. Example: Medical Aid Details, Patients Address, etc</p>
          <ValidationWidget custom={catError} rules={[{ rule: '.+', error: 'Field cannot be empty' }]}>
            <input defaultValue={state.categories.groupNme}
              value={state.category.groupNme}
              onChange={_handleChangeCategory}
              key={'Patient Profile Category'} className='form-control form-control-sm' placeholder='Category Name' />
          </ValidationWidget>
        </InlineWizardItemComponent>
        <InlineWizardItemComponent onError={(e) => setCatError(e)} showBack={true}>
          <h3 className=''>Category Fields</h3>
          <p>A patient profile category field is the information to be captured under this category. Example Patient medical aid name, Medical aid number</p>
          <PatientProfileCategoryFieldsContainer categoryID={state.category.id} isNew={true} />
        </InlineWizardItemComponent>
        <InlineWizardItemComponent nextButtonText='Done' >
          <h3 className=''>Category Created</h3>
          <p>Category has been created. You can change this field under the category management panel below</p>
        </InlineWizardItemComponent>
      </InlineWizardComponent >
    )

  // return (
  //   <div className='rounded rounded-3 shadow p-3'>

  //     {/* <PatientProfileCategory/> */}
  //     {/* <PatientProfileCategoryFieldsContainer/> */}
  //   </div>
  // )
}

import React, { useEffect, useState } from 'react'
import { Bullseye, ChatFill, Cursor, Whatsapp, XCircle } from 'react-bootstrap-icons'

export default function ServiceProviderType({ enableIMS, heading, onChange, defaultValue, showNav, onCancel, onSelected }) {
  const [serviceType, setServiceType] = useState(defaultValue ? defaultValue : '')

  const _handleSetPlatformType = (type) => {
    setServiceType(type)
    if (onChange) onChange(type)
    if (onSelected) onSelected(type)
  }
  const loadConfigs = () => {
    /*MessageTemple.
      GetMessageTemplates(values.templateId)
      .then((r) => {
        setValue({ 'serviceType': r.serviceType.type })
        if (r.serviceType) setServiceType(r.serviceType.type); navigation([
          { text: 'Back', type: 'Back', action: async () => { } },
          { text: 'Next', type: 'Next', action: async () => { } },
        ])
      }
      ).catch((e) => { })*/
  }

  useEffect(() => {
    loadConfigs()
  }, [])
  return (
    <>
      <div>
        <b>{heading ? heading : 'Select the service type'}</b>
      </div>

      <div
        style={{ cursor: 'pointer' }}
        className='d-flex flex-row justify-content-evenly align-items-evenly'>
        {
          enableIMS
            ?
            <div
              onClick={(e) => _handleSetPlatformType('Internal Messaging Service')}
              className={'w-100 p-2 rounded text-center '.concat(serviceType === 'Internal Messaging Service' ? 'bg-primary-container shadow ' : 'bg-secondary-container')}>
              <div className='p-1'>
                <Bullseye size={18} />
              </div>
              <div>
                <b>Internal Message</b>
              </div>
            </div>
            :
            <></>
        }
        <div
          onClick={(e) => _handleSetPlatformType('SMS')}
          className={'w-100 p-2 rounded text-center '.concat(serviceType === 'SMS' ? 'bg-primary-container shadow ' : 'bg-secondary-container')}>
          <div className='p-1'>
            <ChatFill size={18} />
          </div>
          <div>
            <b>SMS</b>
          </div>
        </div>
        <div
          onClick={(e) => _handleSetPlatformType('WhatsApp')}
          className={'w-100 p-2 rounded text-center '.concat(serviceType === 'WhatsApp' ? 'bg-primary-container shadow ' : 'bg-secondary-container')}>
          <div className='p-1'>
            <Whatsapp size={18} />
          </div>
          <div>
            <b>WhatsApp</b>
          </div>
        </div>
        <div
          onClick={(e) => _handleSetPlatformType('Email')}
          className={'w-100  p-2 rounded text-center '.concat(serviceType === 'Email' ? 'bg-primary-container shadow ' : 'bg-secondary-container')}>
          <div className='p-1'>
            <Cursor size={18} />
          </div>
          <div>
            <b>Email</b>
          </div>
        </div>

      </div>
      {
        showNav
          ?
          <div>
            <button onClick={() => onCancel()} className='btn btn-xs'><XCircle />Cancel</button>
            {/* <button className={'btn btn-xs '.concat(serviceType ? '' : 'disabled')}>Next</button> */}
          </div>
          :
          <></>
      }

    </>
  )
}

import React, { useEffect, useState } from 'react'
import { Calendar2Plus } from 'react-bootstrap-icons'
import CalendarEventCard from '../calendar_events/CalendarEventCard'
import CalendarEventCreate from '../calendar_events/CalendarEventCreate'
import CalendarEventUpdate from '../calendar_events/CalendarEventUpdate'

export default function DayViewCalendarModal({ calendarType, details }) {
  const [activeEvent, setActiveEvent] = useState()
  const [mode, setMode] = useState()

  const _renderModeOptions = () => {
    switch (mode) {
      case 'Create': return <CalendarEventCreate calendarType={calendarType}  evt={activeEvent} />
      case 'Update': return <CalendarEventUpdate calendarType={calendarType} evt={activeEvent} />
      default: return <></>
    }
  }

  useEffect(() => { if (activeEvent) setMode('Update'); else setMode('Create') }, [activeEvent])
  return (
    <div>
      <div className='d-flex text-center p-2'>
        <div className='flex-grow-1 w-50'>
          <h3>Events For the day</h3>
          {
            details.events.length === 0
              ?
              <>
                <div className='bg-info-container text-center'>There are no events for this day</div>
                <button onClick={() => setActiveEvent()} className='btn btn-xs bg-primary-container w-100 p-2 my-2 '><Calendar2Plus /> Create a new event</button>
              </>

              :
              <div>
                <button onClick={() => setActiveEvent()} className='btn btn-xs bg-primary-container w-100 p-2 my-2 '><Calendar2Plus /> Create a new event</button>
                {
                  details.events.map((e) =>
                    <CalendarEventCard onClick={(e) => setActiveEvent(e)} key={e.id} evt={e} />
                  )
                }
              </div>
          }

        </div>
        <div className='flex-grow-1 w-50'>
          <h3>Options</h3>
          <div>
            {_renderModeOptions()}
          </div>

        </div>
      </div>


    </div>
  )
}

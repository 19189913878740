import { faPencil, faTimesCircle } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useEffect, useRef, useState } from 'react'
import { ArrowRightCircleFill, Link, PersonCheckFill, PersonXFill } from 'react-bootstrap-icons'
import Authentication from '../../../../libs/api/services/Authentication'
import Messaging from '../../../../libs/api/services/Messaging'
import Patient from '../../../../libs/api/services/Patient'
import PatientHistory from '../../../../libs/api/services/PatientHistory'

export default function PatientMessageLink({ message }) {
    const [patients, setPatients] = useState([])
    const [patientEvents, setPatientEvents] = useState([])
    const [patient, setPatient] = useState({})
    const [event, setEvent] = useState({})
    const [show, setShow] = useState(false)
    const [showSearch, setShowSearch] = useState(false)
    const [linkedEvent, setLinkedEvent] = useState()
    const activeItemRef = useRef()
    const _handleSearch = (search) => {
        Patient
            .search({
                search: search
            }, Authentication.getFacility())
            .then((r) => {
                if (r.length > 0) {
                    setShowSearch(true)
                    setPatients(r)
                } else {
                    setShowSearch(false)
                }
            })
    }

    const _handleSetPatient = (patient) => {
        setShowSearch(false)
        if (patient.userProfile)
            Messaging
                .LinkMessagePatient(message.id, patient.userProfile.id);
        else
            Messaging
                .LinkMessagePatient(message.id, null);
        setPatient(patient)
    }

    const _handleSetPatientEvent = (eventId) => {
        //setLinkedEvent
        setShowSearch(false)
        // if (event)
        Messaging
            .LinkMessagePatientHistory(message.id, eventId)
            .then((r) => {
                loadLinks()
            })
        // else
        //     Messaging
        //         .LinkMessagePatientHistory(message.id, null)
        //         .then((r) => {
        //             loadLinks()
        //         })
        // setPatient(patient)
    }
    const _handleSearchPatientHistory = (patientId) => {
        PatientHistory
            .GetPatientHistory(patientId)
            .then((r) => {
                setPatientEvents(r)
            })
        // setShowSearch(false)
        // setPatient(patient)
    }

    const _handleSearchPatientHistoryByUser = (patientUserId) => {
        PatientHistory
            .GetPatientHistoryByUser(patientUserId)
            .then((r) => {
                setPatientEvents(r)
            })
        // setShowSearch(false)
        // setPatient(patient)
    }

    const loadLinks = () => {
        if (message.id) {
            Messaging
                .ListPatientLinks(message.id)
                .then((r) => {
                    if (r.patient) {
                        if (r.patientHistory)
                            setEvent(r.patientHistory)
                        setPatient({
                            userProfile: r.patient
                        })
                        _handleSearchPatientHistoryByUser(r.patient.id)
                    }
                })
        }
    }

    useEffect(() => {
        loadLinks()
    }, [])

    const _selectActiveEvent = () => {

        if (activeItemRef.current)
            activeItemRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' })
    }

    useEffect(() => {
        // setTimeout(_selectActiveEvent,300)
        _selectActiveEvent()
    }, [event, activeItemRef.current])

    useEffect(() => {
        if (patient.id) {
            _handleSearchPatientHistory(patient.id)
            loadLinks()
        }
    }, [patient])
    return (
        <>
            {
                show
                    ?
                    <div
                        className='position-fixed d-flex justify-content-center align-items-center '
                        style={{ right: '0px', left: '0px', top: '0px', bottom: '0px', zIndex: 100 }}
                    >
                        <div
                            className='bg-light p-3 rounded shadow text-center position-relative'
                            style={{ width: '450px', height: '450px' }}
                        >
                            <FontAwesomeIcon
                                className='position-absolute '
                                style={{ right: '-12px', top: '-12px', zIndex: 100 }}
                                onClick={(e) => setShow(false)}
                                icon={faTimesCircle}
                                size={'2x'} />
                            <h2>Patient Link</h2>
                            {
                                patient.userProfile
                                    ?
                                    <div
                                        className='my-3'
                                    >
                                        {patient.userProfile.firstName} {patient.userProfile.lastName}
                                        <FontAwesomeIcon
                                            onClick={(e) => _handleSetPatient({})}
                                            className='ms-3' icon={faPencil} />
                                    </div>
                                    :
                                    <input
                                        onChange={(e) => _handleSearch(e.target.value)}
                                        className='form-control position-relative' placeholder='Patient Name' />
                            }
                            {
                                showSearch
                                    ?
                                    <ul
                                        style={{ width: '250px' }}
                                        className='position-absolute bg-dark rounded rounded p-2 list-unstyled'>
                                        {
                                            patients.map((r) =>
                                                <li
                                                    onClick={(e) => _handleSetPatient(r)}
                                                    style={{ cursor: 'pointer' }}
                                                >{r.userProfile.firstName} {r.userProfile.lastName}</li>
                                            )
                                        }

                                    </ul>
                                    :
                                    <></>
                            }
                            {
                                patient.userProfile && patient.userProfile.id
                                    ?
                                    <>
                                        <h3>Event</h3>

                                        <div className='row' style={{ overflow: 'auto', height: '280px' }}>
                                            {
                                                patientEvents.map((r) => {
                                                    return <div
                                                        ref={event.id === r.id ? activeItemRef : undefined}
                                                        style={{ cursor: 'pointer' }}
                                                        onLoadedData={() => _selectActiveEvent()}
                                                        className={'row '.concat(event.id === r.id ? 'bg-primary-container' : '')}>
                                                        <div className='col-1 p-0 my-1'>{event.id === r.id ? <Link /> : <></>}</div>
                                                        <div
                                                            onClick={(e) => _handleSetPatientEvent(r.id)} className='col-5 p-0 my-1'>{r.eventDateTime.substr(0, 19)}</div>
                                                        <div
                                                            onClick={(e) => _handleSetPatientEvent(r.id)} className='col-5 p-0 my-1'>{r.event ? r.event.event : ''}</div>
                                                        <div
                                                            onClick={(e) => window.location = Authentication.getFacilityRole() + '/Patient/' + r.patient.id + '/' + r.id + '/Files'}
                                                            className='col-1 p-0 my-1'><ArrowRightCircleFill /></div>
                                                    </div>
                                                })
                                            }
                                            {/* <div className='col-6'>Event</div> */}
                                            {/* <div className='col-6'>Date</div> */}
                                        </div>
                                    </>
                                    :
                                    <></>
                            }
                        </div>
                    </div>
                    :
                    <></>
            }
            {
                (patient.userProfile && patient.userProfile.id) || linkedEvent
                    ?
                    <PersonCheckFill

                        onClick={(e) => setShow(true)}
                        style={{ cursor: 'pointer' }}
                        className='m-1'
                        size={18} />
                    :
                    <PersonXFill
                        onClick={(e) => setShow(true)}
                        style={{ cursor: 'pointer' }}
                        className='m-1'
                        size={18} />
            }
        </>
    )
}

import React, { useEffect, useState } from 'react'
import WorkFlowService from '../../../../../../libs/api/services/WorkFlowService'

const NotesContext = React.createContext()

export const NotesProvider = ({ event, defaultValues, children }) => {

    const [notes, setNotes] = useState(defaultValues ? defaultValues : [])
    useEffect(() => { setNotes(defaultValues) }, [defaultValues])
    // const { event } = useContext(WorkFlowManagementContext)

    // const _handleGetNotes = () => notes
    const _handleSetNotes = (n) => setNotes(n)
    const _handleAddNote = (n) =>
        WorkFlowService
            .CreateWorkFlowNote(event.id, n)
            .then((r) => setNotes([...notes, r]))
    //{ setNotes([...notes, n]) }
    const _handleRemoveNote = (n) =>
        WorkFlowService
            .DeleteWorkFlowNote(event.id, n)
            .then((r) => {
                if (notes) {
                    var _notes = notes; _notes.splice(_notes.indexOf(n), 1); setNotes([..._notes])
                }
            })
    // { var _notes = notes; _notes.splice(_notes.indexOf(n), 1); setNotes([..._notes]) }

    return (
        <NotesContext.Provider
            value={{
                addNote: (n) => _handleAddNote(n),
                initNotes: (n) => _handleSetNotes(n),
                removeNote: (n) => _handleRemoveNote(n),
                notes: notes,
            }}
        >
            {children}
        </NotesContext.Provider>
    )
}

export default NotesContext
import React, { useEffect, useRef, useState } from 'react'
import { PlusCircle } from 'react-bootstrap-icons'
import Questionnaire from '../../../../../../../libs/api/services/Questionnaire'
import QBuilderGenerics from '../../../QBuilderGenerics'
import QBuilderComponentDynamicComponentTableLayoutItem from './QBuilderComponentDynamicComponentTableLayoutItem'
import QBuilderComponentDynamicComponentTableLayoutItemOptions from './QBuilderComponentDynamicComponentTableLayoutItemOptions'

export default function QBuilderComponentDynamicComponentTableLayout({ properties, onChanged, onSetData }) {
    const [items, setItems] = useState([])
    const isMountingRef = useRef(false);

    const _handleAddComponent = async (c) => {
        // const _component = {id: '', question: '', IsDeleted: false,ComponentTypeId: '', QuestionSectionId: '',Order: ''}
        try {
            // Add Component Id
            var _component = await Questionnaire.AddComponentWithoutSection({
                question: '',
                order: items.length
            })
            c.component = { ..._component }
            // Update _item
            const _items = [...items, c]
            onChanged('Items', _items)
            setItems(_items)
        } catch (e) {
            // Failed to create component
        }
    }

    const _handleChangeComponent = async (c, idx) => {
        try {
            var _items = [...items]
            // Check component id
            if (_items[idx].component && _items[idx].component.id) {
                // Check if component type changed
                if (_items[idx].type === 'New' && c.type !== 'New') {
                    await Questionnaire.SetComponentType(_items[idx].component.id, c.type);
                }
                // Update _item
                _items[idx] = c
                onChanged('Items', _items)
                setItems(_items)
            } else {
                throw Error('Component not linked')
            }
        } catch (e) {

        }
    }

    const _handleRemoveComponent = async (idx) => {
        try {
            var _items = [...items]
            // Check component id
            if (_items[idx].component && _items[idx].component.id) {
                // Remove Component
                await Questionnaire.RemoveComponent(_items[idx].component.id);
                // update _items
                _items.splice(idx, 1)

                onChanged('Items', _items)
                setItems(_items)
            } else {
                throw Error('Component not linked')
            }
        } catch (e) {

        }
    }

    useEffect(() => {
        try {
            // Initial load
            // if (!isMountingRef.current) {
            //     _initTable()
            //     isMountingRef.current = true
            // }
            var _props = QBuilderGenerics.getProperty(properties, 'Items')
            if (_props)
                setItems(_props)
        } catch (e) {

        }
    }, [])

    return (
        <div>
            {
                items ? items.map((item, idx) =>
                    <QBuilderComponentDynamicComponentTableLayoutItemOptions
                        onRemove={(i) => _handleRemoveComponent(idx)}
                    >
                        <QBuilderComponentDynamicComponentTableLayoutItem
                            onSetData={(c, v, u) => onSetData(c, v, u)}
                            onAddComponent={(c) => _handleChangeComponent({ ...item, type: c }, idx)}
                            onChangeComponent={(c) => _handleChangeComponent({ ...item, ...c }, idx)}
                            item={item}
                        />
                    </QBuilderComponentDynamicComponentTableLayoutItemOptions>
                ) : <></>
            }
            <div className='text-center' onClick={() => _handleAddComponent({ type: 'New' })}><PlusCircle className='me-2' />Add Component</div>
        </div>
    )
}

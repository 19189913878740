/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from 'react';
import { ExclamationCircle } from 'react-bootstrap-icons';
import WorkFlowConfigurationContext from '../../../workflow/configuration/WorkFlowConfigurationProvider';
import ToDoSelector from './Components/ToDoSelector';

export default function EventTriggerToDoListStatusChange({ link, type, values, eventId, filterTrigger }) {
    const PROPERTY_TYPE = 'to_do_list_status_changed';
    const { saveProperties, loadProperties, loading } = useContext(WorkFlowConfigurationContext)

    const DEFAULTS = { listDetails: undefined }

    const [properties, setProperties] = useState(DEFAULTS)

    useEffect(() => {
        const _load = async () => setProperties(await loadProperties(PROPERTY_TYPE, link, DEFAULTS))
        _load()
    }, [])

    // Note: remove all use effects for easy saving. This does not work well
    // useEffect(() => { if (properties) saveProperties(PROPERTY_TYPE, link, properties) }, [properties])

    const _handleSave = (properties) => {
        saveProperties(PROPERTY_TYPE, link, properties)
        setProperties(properties)
    }

    const _handleSetGroup = (e) => {
        _handleSave({
            ...properties,
            listDetails: e,
            listDetailsChange: { ...e, item: undefined }
        })
    }
    const _handleSetSecondary = (e) => {
        // console.log(e)
        _handleSave({
            ...properties,
            listDetailsChange: e
        })
    }
    if (loading) return <></>
    return (
        <>
            <div className='d-flex'>
                <div className='w-50'>
                    <b>From</b>
                    <ToDoSelector
                        defaultValue={properties && properties.listDetails}
                        onChange={_handleSetGroup}
                    />
                </div>
                <div className='w-50'>
                    <b>To</b>
                    <ToDoSelector
                        mode={'status'}
                        defaultValue={properties && properties.listDetailsChange ?
                            properties.listDetailsChange : undefined}
                        onChange={(e) => _handleSetSecondary(e)}
                    />
                </div>
            </div>
            {properties && properties.listDetails && !properties.listDetails.item && <div className='bg-warning-container p-1 rounded text-center'><ExclamationCircle className='me-2' />Note that if there isn't any <b>status</b> set on the <b>from</b> list. This will trigger when any status changes to the <b>To</b> status.</div>}
            {properties && properties.listDetailsChange && !properties.listDetailsChange.item && <div className='bg-danger-container p-1 rounded text-center'><ExclamationCircle className='me-2' />You have not selected a changed to status. This trigger will not execute</div>}
            {/* {(properties && !properties.listDetails) && <div className='bg-danger-container p-1 rounded text-center'><ExclamationCircle className='me-2' />Please select a status change filter criteria</div>} */}
        </>
    )
}

import React, { useEffect, useState } from 'react'
import OnScreenAssist from '../../../../../../../components/ui/hint/OnScreenAssist'
import Authentication from '../../../../../../../libs/api/services/Authentication'
import MessageTemple from '../../../../../../../libs/api/services/MessageTemple'

export default function MessageTemplateWizardSetupType({ title, description, navigation, errors, setValue, values, navigationContext }) {

    const [configurationName, setConfigurationName] = useState('')
    const [configurations, setConfigurations] = useState([])
    const [mode, setMode] = useState('')
    const [configuration, setConfiguration] = useState(Object.keys(values).includes('templateId') ? { id: values.templateId } : undefined)
    useEffect(() => {
        if (navigationContext === 'NEW_EVENT_ADD_EVENT' || navigationContext === 'NEW_EVENT_WORKFLOW_ADD_EVENT') {
            title('Messaging Templates')
            description('Would you like to create a new template or use an existing template')
            // Load Navigation if session started
            if (Object.keys(values).includes('templateId'))
                navigation([
                    { text: 'Remove', type: 'Action', action: async () => { _handleRemoveTemplate(values.templateId).then((r) => { navigation([]); loadConfigs() }) } },
                    { text: 'Edit Before Using', type: 'Next', action: async () => { setValue({ 'templateId': values.templateId }) } },
                    { text: 'Use Template As Is', type: 'Step', step: 5, action: async () => { setValue({ 'templateId': values.templateId }) } },
                ])
            else
                navigation([])
        } else {
            title('Messaging Templates')
            description('Would you like to create a new template or update an existing template')
            // Load Navigation if session started
            if (Object.keys(values).includes('templateId'))
                navigation([
                    { text: 'Remove', type: 'Action', action: async () => { _handleRemoveTemplate(values.templateId).then((r) => { navigation([]); loadConfigs() }) } },
                    { text: 'Update', type: 'Next', action: async () => { setValue({ 'templateId': values.templateId }) } },
                ])
            else
                navigation([])
        }
    }, [])

    const loadConfigs = () => {
        if (Authentication.getFacility()) {
            MessageTemple
                .TemplatesFacility(Authentication.getFacility())
                .then((r) =>
                    setConfigurations(r)).catch((e) => errors(e.message))

        } else {
            MessageTemple
                .Templates()
                .then((r) =>
                    setConfigurations(r)).catch((e) => errors(e.message))
        }
    }

    useEffect(() => {
        loadConfigs()
    }, [])

    const _handleSetConfigrationName = (c) => {
        setConfigurationName(c)
        if (c)
            navigation([{
                text: 'Next', type: 'Next', action: async () => {
                    if (Authentication.getFacility()) {
                        return await MessageTemple
                            .CreateWithFacility(Authentication.getFacility(), {
                                name: c
                            }).then((r) => {
                                setValue({ 'templateId': r.id, 'templateName': c })
                            })
                    } else {
                        return await MessageTemple
                            .Create({
                                name: c
                            }).then((r) => {
                                setValue({ 'templateId': r.id, 'templateName': c })
                            })
                    }
                }
            }])
        else
            navigation([])
        setConfiguration()
    }

    const _handleRemoveTemplate = async (id) => {
        await MessageTemple
            .RemoveTemplate(id)
    }

    const _handleSelectConfiguration = (c) => {
        if (c) {
            setValue({ 'templateId': c.id, 'templateName': c.name })
            if (navigationContext === 'NEW_EVENT_ADD_EVENT' || navigationContext === 'NEW_EVENT_WORKFLOW_ADD_EVENT') {
                navigation([
                    { text: 'Remove', type: 'Action', action: async () => { _handleRemoveTemplate(values.templateId).then((r) => { navigation([]); loadConfigs() }) } },
                    { text: 'Edit Before Using', type: 'Next', action: async () => { } },
                    { text: 'Use Template as is', type: 'Step', step: 5, action: async () => { } },
                ])
            } else {
                navigation([

                    { text: 'Remove', type: 'Action', action: async () => { _handleRemoveTemplate(c.id).then((r) => { navigation([]); loadConfigs() }) } },
                    { text: 'Update', type: 'Next', action: async () => { setValue({ 'templateId': c.id, 'templateName': c.name }) } },
                ])
            }
        }
        setConfigurationName('')
        setConfiguration(c)
    }

    return (
        <div className='config-setup-type'>

            <div className={'row top p-3 shadow rounded bg-primary-container '.concat(mode !== 'New' && configurationName === '' ? 'opacity-75' : '')} >
                <div className='col-12'>
                    <h3>New?</h3>
                    <p>Enter a name for the template eg:  Patient Reminder SMS</p>
                    <input
                        onFocus={(e) => setMode('New')}
                        onBlur={(e) => setMode('')}
                        onChange={(e) => _handleSetConfigrationName(e.target.value)}
                        value={configurationName}
                        className='form-control' placeholder='Name of the new template eg: Patient Reminder SMS' />
                </div>
            </div>
            <div className={'row bottom p-3 shadow rounded bg-secondary-container '.concat(mode !== 'New' && configuration ? '' : ' opacity-75')}>
                <div className='col-12 h-100'>
                    <h3>{navigationContext === 'NEW_EVENT_ADD_EVENT' || navigationContext === 'NEW_EVENT_WORKFLOW_ADD_EVENT' ? 'Use' : 'Update'} Existing?</h3>
                    {
                        configurations.length === 0
                            ?
                            <div>Currently there are no message templates to {navigationContext === 'NEW_EVENT_ADD_EVENT' || navigationContext === 'NEW_EVENT_WORKFLOW_ADD_EVENT' ? 'use' : 'update'}</div>
                            :
                            <>
                                <p>Select the template you would like to {navigationContext === 'NEW_EVENT_ADD_EVENT' || navigationContext === 'NEW_EVENT_WORKFLOW_ADD_EVENT' ? 'use' : 'update'}</p>
                                <div
                                    style={{ cursor: 'pointer', maxHeight: '75%', overflow: 'auto' }}
                                    className='text-start'>
                                    {
                                        configurations.map((c) =>
                                            <div
                                                className={'p-2 rounded '.concat(configuration && c.id === configuration.id ? ' font-weight-bold active ' : ' font-weight-light ')}
                                                onClick={() => _handleSelectConfiguration({ id: c.id, name: c.name })} >{c.name}</div>
                                        )
                                    }
                                </div>
                            </>
                    }
                </div>
            </div>
            <OnScreenAssist
                items={[
                    {
                        heading: 'What\'s a message template',
                        description: '<p className=\'m-3\'>A message template allows you to create a dynamic message to streamline the message sending process</p><p className=\'m-3\'>Example: A template can be created to SMS a patient with thier appointment details</p>'
                    },
                    {
                        heading: 'How do I remove a template',
                        description: 'To <b>remove a template</b>, select the template from the <b>update existing section</b>. This will activate the <b>remove</b> option on the bottom of the Message template wizard'
                    },
                ]}
            />
        </div>
    )
}

import React, { useEffect, useState } from 'react'
import { Clock, CloudDownloadFill, Download, InfoCircleFill, Person } from 'react-bootstrap-icons'
import { useParams } from 'react-router-dom'
import Questionnaire from '../../../../libs/api/services/Questionnaire'
import QCaptureComponent from '../capture/components/QCaptureComponent'

export default function PatientFormComponent() {
    const { id, questionair, QuestionnaireAssignmentId } = useParams()
    const [results, setResults] = useState({})
    const [details, setDetails] = useState({})
    const [downloading, setDownloading] = useState(false)

    function openBase64InNewTab(data, mimeType, filename) {
        var byteCharacters = atob(data.substr(data.indexOf(';base64,') + 8));
        // var byteCharacters = data.substr(data.indexOf(';base64,') + 8);
        var byteNumbers = new Array(byteCharacters.length);
        for (var i = 0; i < byteCharacters.length; i++) {
            byteNumbers[i] = byteCharacters.charCodeAt(i);
        }

        var byteArray = new Uint8Array(byteNumbers);
        var file = new Blob([byteArray], { type: mimeType + ';base64' });
        // var file = new Blob([byteArray]);
        var fileURL = URL.createObjectURL(file);

        var anchor = document.createElement('a');
        anchor.href = fileURL;
        anchor.download = filename;
        anchor.click();
        window.URL.revokeObjectURL(fileURL);

        setDownloading(false)
        // window.open(fileURL);
    }

    const _handleGetReport = () => {
        setDownloading(true)
        Questionnaire
            .Report(QuestionnaireAssignmentId)
            .then((data) => {
                openBase64InNewTab(data.data, data.mimeType, data.fileName)
            })
    }

    const loadDetails = () => {
        Questionnaire
            .GetQuestionnaireResults(QuestionnaireAssignmentId)
            .then((r) => {
                setDetails(r)
            })
    }

    useEffect(() => {
        loadDetails()
    }, [])

    return (
        <div className='conatiner text-center q-discover-layout flex-column'>
            <div className='flex-shrink-1'>
                {
                    details
                        ?
                        <div className='text-secondary'>
                            <div className='row bg-secondary-container shadow rounded p-2'>
                                <InfoCircleFill size={24} className='text-center m-2' />
                                <div className='col-4 text-center'>
                                    <div><Clock className='me-1' />Requested On : {details.createdDate ? details.createdDate.substring(0, 19) : ''}</div>
                                    {/* <div><Person className='me-1' />Requested By : {details.assigner ? details.assigner.firstName : ''} {details.assigner ? details.assigner.lastName : ''}</div> */}
                                </div>
                                <div className='col-4 text-center'>
                                    <div><Person className='me-1' />Requested By : {details.assigner ? details.assigner.firstName : ''} {details.assigner ? details.assigner.lastName : ''}</div>
                                    {/* <div><Clock className='me-1' />Assigned Date : {details.assignedDate ? details.assignedDate.substring(0, 19) : ''}</div> */}
                                    {/* <div><Clipboard2CheckFill className='me-1' />Questions Answered : {details.responses ? details.responses.length : 0}</div> */}
                                </div>
                                <div className='col-4 text-center'>
                                    {/* <div><Clock className='me-1' />Completed On : {details.completedDate ? details.completedDate.substring(0, 19) : ''}</div> */}
                                    <div style={{ cursor: 'pointer' }} onClick={(e) => _handleGetReport()}>{downloading ? <CloudDownloadFill /> : <Download className='me-1' />}{`${downloading ? 'Generating Report' : 'Download'}`}</div>
                                </div>
                            </div>
                        </div>
                        :
                        <></>
                }
            </div>
            <div className='flex-grow-1 p-1 position-relative' style={{ overflow: 'auto', height: '75vh' }}>
                <QCaptureComponent
                    // mode={'Discover'}
                    mode={'Form'}
                    view={'Paper'}
                    QuestionnaireAssignmentId={QuestionnaireAssignmentId}
                    QuestionnaireId={questionair}
                />
            </div>
        </div>)
}

import React, { useContext, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Authentication from '../../../../libs/api/services/Authentication';
import Patient from '../../../../libs/api/services/Patient';
import StickyNavigationContext from '../../../../libs/providers/StickyNavigationProvider';
import CommonFunctionsContext from '../../../common/CommonFunctionsProvider';
import WorkFlowQuickTasks from '../../workflow/components/widgets/WorkFlowQuickTasks';
import PatientContext from './PatientProvider';
import PatientProfileDetailsContainer from './components/PatientProfileDetailsContainer';
import PatientProfileSideBar from './components/PatientProfileSideBar';
import PatientProfileSideBarItem from './components/PatientProfileSideBarItem';

export default function PatientProfileManagement({ patientUserID, onSaved, type, className }) {
    const { setSectionInfo } = useContext(CommonFunctionsContext)
    const { activePatient, SetActivePatient } = useContext(PatientContext)
    const navigate = useNavigate()

    const { id } = useParams();
    const [categories, setCategories] = useState([])
    const [category, setCategory] = useState({ id: -1 })

    const [profile, setProfile] = useState({ id: id ? id : patientUserID ? patientUserID : undefined, profiles: [] })
    const [loading, setLoading] = useState(false)
    const [version, setVersion] = useState(0)
    const { setSticky, setPreventMessage, setPreventMessageAsync } = useContext(StickyNavigationContext)
    const { showToast, showError } = useContext(CommonFunctionsContext)

    useEffect(() => {
        _load()
    }, [])


    useEffect(() => {
        if (activePatient)
            setSectionInfo(`Managing patient ${activePatient.userProfile.firstName} ${activePatient.userProfile.lastName} at ${Authentication.getFacilityName()}`);
        else {
            setVersion(3)
            setSticky(true)
            setPreventMessageAsync('Unsaved Changes', 'You have unsaved changes. <br/>Would you like to save them?', async () => await _handleSave(), async () => window.location = '/' + Authentication.getFacilityRole())
            setSectionInfo(`Managing a new patient at ${Authentication.getFacilityName()}`);
        }

    }, [activePatient])

    useEffect(() => {
        if (version !== 0) {
            setSticky(true)
            if (version !== 3) {
                setPreventMessage('Unsaved Changes', 'You have unsaved changes. <br/>Would you like to save them?', () => _handleSave(), "Discard")
                onbeforeunload = (event) => { event.preventDefault(); return false; }
            } else {
                // setSticky(false)
                // onbeforeunload = (event) => { return }
            }

        } else {
            setSticky(false)
            onbeforeunload = (event) => { return }
        }
    }, [version])

    const _load = async () => {
        if (profile.id)
            setProfile(await Patient.LoadPatient(profile.id))
        const _categories = await Patient.Categories()
        setCategories(_categories)
    }

    const _reloadProfile = async (newProfileId, redir) => {
        if (!profile.id) {
            onbeforeunload = (event) => { event.preventDefault(); return false; };
            // window.location = Authentication.getFacilityRole() + '/Patient/' + newProfileId;
            if (!onSaved) navigate('/' + Authentication.getFacilityRole() + '/Patient/' + newProfileId)
        } else
            setProfile(await Patient.LoadPatient(newProfileId))
    }

    const _handleSave = async (redir) => {
        setLoading(true)
        // setStatus({ message: 'Saving changes', key: new Date(), time: 3 })
        showToast('Saving changes', undefined, 3)
        var _profile = profile
        if (!_profile.id)
            _profile = await Patient.InitNewPatient(Authentication.getFacility())

        // Update details
        // {
        //     Detail: Value,
        //     DetailsType: {
        //         Id: DetailsTypeId
        //     }
        // }
        var details = []
        for (var d in profile.profiles) {
            details.push(
                {
                    Detail: profile.profiles[d].detail,
                    DetailsType: {
                        Id: profile.profiles[d].detailsType.id
                    }
                }
            )
            // await Patient.SetPatientProfileDetail(profile.profiles[d].detailsType.id, profile.profiles[d].detail, _profile.id)
        }
        if (await Patient.SetPatientProfileDetailBulk(details, _profile.id).catch((e) => showError(e))) {
            SetActivePatient(_profile)
            if (onSaved) onSaved(_profile)
            setVersion(0)
            setSticky(false)
            onbeforeunload = (event) => { return }
            _reloadProfile(_profile.id, redir)
            showToast('Profile changes saved', undefined, 3)

        }
        setLoading(false)
    }

    const _handleSetValue = (i, v) => {
        setVersion(2)
        var _profile = { ...profile }
        var _detail = _profile.profiles.find(p => p.detailsType.id === i)
        if (!_detail) {
            _profile.profiles.push({ detailsType: { id: i }, detail: v })
        } else {
            // Update
            _detail.detail = v
        }
        setProfile({ ..._profile })
    }

    return (
        <>
            <div className={`d-flex flex-grow-1 ${className}`} style={{ maxHeight: '100%', height: '100%' }}>
                <PatientProfileSideBar profile={profile}>
                    {
                        categories && categories.length > 0 && categories.map((c) =>
                            <PatientProfileSideBarItem key={c.id} onSelected={(id) => setCategory({ id: id })} category={c} active={c.id === category.id} />
                        )
                    }
                </PatientProfileSideBar>
                <div className='profile-details'>

                </div>
                <div className='flex-grow-1 d-flex flex-column' style={{ overflow: 'auto' }}>
                    <PatientProfileDetailsContainer saving={loading} category={category} profile={profile} onSetValue={(i, v) => _handleSetValue(i, v)} onSave={_handleSave} />
                    {
                        patientUserID
                            ?
                            <></>
                            :
                            <WorkFlowQuickTasks
                                key={'WorkFlowQuickTasks:Patient Profile Events'}
                                group='Patient Profile Events'
                                patientId={id}
                                category={category}
                                filters={[
                                    {
                                        type: 'category',
                                        value: category
                                    }
                                ]}
                            />
                    }
                </div>
            </div>
        </>
    )
}

import React, { useEffect, useState } from 'react';
import { XCircleFill } from 'react-bootstrap-icons';
import './Modal.css';
export default function GeneralModal({ zIndex, show, icon, title, children, onClose, idx }) {
    const [visible, setVisible] = useState(show)

    useEffect(() => {
        setVisible(show);
    }, [show])

    useEffect(() => {
        if (onClose && !visible) onClose(idx)
    }, [visible])
    // useEffect(() => {
    //     if (details.visible)
    //         document.addEventListener('keyup', (e) => {
    //             if (e.code === 'Escape') setDetails({ ...details, visible: false })
    //         });
    //     else {
    //         document.removeEventListener('keyup', (e) => { })
    //         setDetails({ visible: false })
    //     }
    //     setVisible(details.visible)

    // }, [details.visible])

    return (
        <div
            className={`position-fixed d-flex flex-column justify-content-center align-items-center ${visible ? 'fade-in-modal' : 'hide-modal'}`}
            style={{ top: '0px', bottom: '0px', left: '0px', right: '0px', zIndex: zIndex ? zIndex : 10000, background: 'rgba(0,0,0,0.5)' }}
        >
            <div className='bg-light rounded d-flex flex-column' style={{ minWidth: '80vw', minHeight: '90vh', maxHeight: '90vh',maxWidth: '100vw', overflow: 'auto' }}>
                <div className='d-flex flex-shrink-1 p-2 align-items-center bg-secondary-container rounded-top'>
                    <div className='flex-shrink-1'>{icon ? icon : <></>}</div>
                    <div className='flex-grow-1 text-center'><b>{title}</b></div>
                    <div onClick={() => setVisible(false)} className='flex-shrink-1'><XCircleFill size={30} /></div>
                </div>
                <div
                    className='d-flex flex-column flex-grow-1'
                    style={{ overflow: 'auto', minHeight: 'calc(-50px + 90vh)', maxHeight: 'calc(90vh - 50px)', height: 'calc(90vh - 50px)' }}>
                    {children}
                </div>
            </div>
        </div>
    )
}

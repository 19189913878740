import React, { Component } from 'react';
import { PatientProvider } from '../section/patient/management/PatientProvider';
import SearchBarWrapper from './SearchBarWrapper';

export class ConfigLayout extends Component {
    constructor(props) {
        super(props)
        this.state = {
            expanded: (this.props.container) ? false : true
        }
    }
    toggleMenu() {
        this.setState({ expanded: !this.state.expanded })
    }
    render() {
        return (
            <>
                {/* <div className="row header-bg text-center h-15">
                    <div className="col-2 logo-image"></div>
                    <div className="col-10  d-flex flex-column justify-content-center">
                        <div className="row">
                            <div className="col-12">
                                <h1> Person </h1>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12">
                                <h2> Users Name </h2>
                            </div>
                        </div>
                    </div>
                </div> */}
                <div className="row d-block d-md-flex flex-md-row" style={{ flexFlow: "column", minHeight: '100%', maxHeight: '100%' }}>

                    <div key={new Date()} className="menu col-auto rounded-start d-none d-xl-flex flex-sm-column rounded-5 position-relative p-3 menu-bg text-center flex-shrink-1" >
                        {this.props.menu}
                    </div>
                    <div className="col-lg-12 rounded-top d-flex d-md-none rounded-5 position-relative p-0 menu-bg text-center"></div>
                    <div className="flex-grow-1 d-flex flex-column col-auto p-0 p-md-2 content-container">
                        <PatientProvider>
                            <SearchBarWrapper key={'sbw_' + new Date()} showNotifications={false} search={this.props.search} breadcrums={this.props.breadcrums} />
                        </PatientProvider>
                        {
                            (this.props.container)
                                ?
                                <>
                                    <div className="col-12 d-flex flex-column flex-grow-1">
                                        <div className='content-area bg-content-area d-flex flex-column flex-grow-1 mt-3 shadow shadow-2 rounded rounded-5 text-primary py-3' >
                                            {
                                                (this.props.title)
                                                    ?
                                                    <div className='head text-center'>
                                                        <h1>{this.props.title}</h1>
                                                        {this.props.description ? <p>{this.props.description}</p> : <></>}
                                                    </div>
                                                    :
                                                    <></>
                                            }
                                            {this.props.container}
                                        </div>
                                    </div>

                                </>
                                :
                                <></>
                        }
                        {/* <div className='fixed-bottom justify-content-evenly rounded-bottom d-flex d-md-none rounded-5 p-0 menu-bg text-center'> */}
                        <div onClick={(e) => this.toggleMenu()} className={'d-relative '.concat(this.state.expanded ? 'menu fixed-bottom menu-bg d-xl-none text-center rounded-bottom  rounded-5 text-primary mob-menu open' : 'menu fixed-bottom menu-bg d-xl-none text-center rounded-bottom  rounded-5 text-primary mob-menu close')} >
                            <button className='btn btn-lg text-light'>MENU</button>
                            {/* <div className={(this.state.expanded) ? 'h-100 d-block' : 'h-100 d-none'}> */}
                            <div>
                                <div className='mnu-grid'>
                                    {this.props.menu}
                                </div>
                            </div>
                            <button className='btn btn-lg text-light'></button>
                            {/* <button className='btn btn-lg text-light'>MENU</button> */}
                        </div>
                    </div>

                </div>
            </>
        );
    }
}

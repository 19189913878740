/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import SimpleColourPicker from '../../../../../components/colour/SimpleColourPicker';
import GenericIconPicker from '../../../../../components/icons/GenericIconPicker';
import WorkFlow from '../../../../../libs/api/services/WorkFlow';
import { DASHBAORD_TYPES } from '../../Config/DashboardTypes';

export default function EventTriggerDashboardCard({ link, type, values, eventId, filterTrigger }) {
    const PROPERTY_TYPE = 'dashboard_card';
    // const [options, setOptions] = useState({ messageTypes: [], receivedFrom: {}, subject: '' })
    const [availableCalendars, setAvailableCalendars] = useState([])
    const [properties, setProperties] = useState({})

    useEffect(() => { _loadProperties() }, [])
    useEffect(() => { if (properties && Object.keys(properties).length > 0) _handleSetProperty(properties) }, [properties])

    const _loadProperties = () => {
        try {
            WorkFlow
                .GetTriggerPropertyByType(typeof values.event === 'object' ? values.event.id : JSON.parse(values.event).id, link[0].id, PROPERTY_TYPE)
                .then((r) => {
                    setProperties(JSON.parse(r.value))
                }).catch((e) => { /* Doesnt exist yet */ })
        } catch (e) {
            // Not yet linked
        }
    }

    const _handleSetProperty = (v) => {
        try {
            WorkFlow
                .SetTriggerProperty(typeof values.event === 'object' ? values.event.id : JSON.parse(values.event).id, link[0].id, {
                    property: PROPERTY_TYPE,//'questionnaire_complete',
                    value: JSON.stringify(v),
                })
                .then((r) => {
                    // Log as property
                    // setLinked(_linked)
                })
        } catch (E) { }
    }
    return (
        <div>
            <b>Dashboard Type</b>
            <select
                defaultValue={properties && properties.dashboard}
                onChange={(e) => setProperties({ ...properties, dashboard: e.target.value })
                }
                className='form-control form-control-sm'
            >
                <option></option>
                {
                    DASHBAORD_TYPES.map((d, i) =>
                        <option key={`DBCT-${i}`} value={d.type} selected={d.type === properties.dashboard}>{d.description}</option>
                    )
                }
            </select>
            <div>
                <b>Dashboard Card Title</b>
                <input
                    defaultValue={properties && properties.dashboardCardTitle}
                    onChange={(e) => setProperties({ ...properties, dashboardCardTitle: e.target.value })
                    }
                    className='form-control form-control-sm'
                />
            </div>
            <div>
                <b>Link Description</b>
                <input
                    defaultValue={properties && properties.dashboardLinkDescription}
                    onChange={(e) => setProperties({ ...properties, dashboardLinkDescription: e.target.value })
                    }
                    className='form-control form-control-sm'
                />
            </div>
            <div className='row'>
                <div className='col-6'>
                    <b>Colour</b>
                    <SimpleColourPicker
                        defaultValue={properties && properties.dashboardColour}
                        onChange={(e) => setProperties({ ...properties, dashboardColour: e })}
                    />
                </div>
                <div className='col-6'>
                    <b>Icon</b>
                    <GenericIconPicker
                        defaultValue={properties && properties.dashboardIcon}
                        onChange={(e) => setProperties({ ...properties, dashboardIcon: e })}
                        dropdown={true}
                    />
                </div>
            </div>
        </div>
    )
}

import React, { useContext, useEffect, useState } from 'react';
import WorkFlowConfigurationContext from '../../../workflow/configuration/WorkFlowConfigurationProvider';
import ToDoSelector from './Components/ToDoSelector';

export default function EventTriggerToDoListIncompletedForX({ link, type, values, eventId, filterTrigger }) {
    const PROPERTY_TYPE = 'to_do_list_incomplete_for_x';
    const DEFAULTS = { todo_timelapsed_mins: undefined }
    const { saveProperties, loadProperties } = useContext(WorkFlowConfigurationContext)

    const [properties, setProperties] = useState(DEFAULTS)

    useEffect(() => {
        const _load = async () => setProperties(await loadProperties(PROPERTY_TYPE, link, DEFAULTS))
        _load()
    }, [])
    useEffect(() => { if (properties) saveProperties(PROPERTY_TYPE, link, properties) }, [properties])

    return (
        <div>
            <ToDoSelector
                defaultValue={properties && properties.listDetails}
                onChange={(e) => setProperties({ ...properties, listDetails: e })}
            />
            <b>Time elapsed since assigned</b>
            <div className='d-flex'>
                <div className='text-center w-100'>
                    <b>Minutes</b>
                    <input
                        value={properties.todo_timelapsed_mins} onChange={(e) => { return setProperties({ ...properties, 'todo_timelapsed_mins': e.target.value }) }}
                        className='form-control form-control-sm' min="0" max="59" type='Number' placeholder='minuts' />
                </div>
                <div className='text-center w-100'>
                    <b>Hours</b>
                    <input
                        value={properties.todo_timelapsed_hours} onChange={(e) => { return setProperties({ ...properties, 'todo_timelapsed_hours': e.target.value }) }}
                        className='form-control form-control-sm' type='Number' placeholder='hours' />
                </div>
                <div className='text-center w-100'>
                    <b>Days</b>
                    <input
                        value={properties.todo_timelapsed_days} onChange={(e) => { return setProperties({ ...properties, 'todo_timelapsed_days': e.target.value }) }}
                        className='form-control form-control-sm' type='Number' placeholder='days' />
                </div>
            </div>
        </div>
    )
}

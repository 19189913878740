import React, { useContext, useEffect, useState } from 'react'
import { Compass, GearFill } from 'react-bootstrap-icons'
import { NavLink, Outlet, useNavigate, useParams } from 'react-router-dom'
import Authentication from '../../libs/api/services/Authentication'
import Patient from '../../libs/api/services/Patient'
import User from '../../libs/api/services/User'
import StickyNavigationContext from '../../libs/providers/StickyNavigationProvider'
import PatientContext from '../section/patient/management/PatientProvider'
import Notifications from './components/Notifications'

export default function SearchBarWrapper({ showNotifications, search, breadcrums, settings, options }) {
    const { id } = useParams()
    const [breadCrums, setBreadCrums] = useState([])
    const [toggleBCSmall, setToggleBCSmall] = useState(false)

    const _loadPatientDetails = async (id) => {
        if (id)
            return await Patient.GetById(id)
    }
    const _loadUserDetails = async (id) => {
        if (id)
            return await User.get(id)
    }

    return (
        <div
            key={"SB_CONTAINER"}
            className='d-flex bg-primary-container rounded rounded-4 shadow align-items-center '>
            <Outlet />
            <nav aria-label="breadcrumb">
                <button
                    onClick={(e) => setToggleBCSmall(!toggleBCSmall)}
                    className='d-block d-md-none btn'>
                    <Compass />
                </button>
                <BreadCrumbProvider PatientId={id} UserId={id}>
                    <div className=
                        {'position-absolute breadcrumb-sm rouned shadow '.concat(!toggleBCSmall ? 'hide' : 'show')}>
                        <BreadCrumsBar breadcrums={breadcrums} search={search} />
                    </div>

                    <ol className="breadcrumb breadcrumb-arrow p-0 m-0 d-none d-md-block">
                        <BreadCrumsBar breadcrums={breadcrums} search={search} />
                    </ol>
                </BreadCrumbProvider>
            </nav>
            <div className='flex-grow-1'>
                {
                    (search)
                        ?
                        <div className="row flex-shrink-1">
                            <div className="col-12 mt-1 m-md-1 text-primary">
                                <div className="row">
                                    {search}
                                    {/* <div className="col-6">
                                                    <input className='form-control bg-search-bar rounded-3 text-white' placeholder='Patient Search' />
                                                </div>
                                                <div className="col-6">
                                                    <input className='btn bg-buttons' />
                                                </div> */}

                                </div>
                            </div>
                        </div>
                        :
                        <></>
                }
            </div>
            <div className='flex-shrink-1 text-light d-flex align-items-center'>
                {
                    settings
                        ?
                        <NavLink
                            to={`/${Authentication.getFacilityRole()}/configuration`}
                            className={'text-light'}
                        >
                            <GearFill className='me-2' size={25}
                                style={{ cursor: 'pointer' }}
                            // onClick={(e) => window.location = Authentication.getFacilityRole() + '/configuration'}
                            />
                        </NavLink>
                        :
                        <></>
                }
                {
                    showNotifications
                        ?
                        <>

                            <Notifications options={options} />
                        </>
                        :
                        <></>
                }
            </div>
        </div>
    )
}

const BreadCrumbContext = React.createContext()

const BreadCrumbProvider = ({ PatientId, UserId, children }) => {
    const { activePatient } = useContext(PatientContext)
    const [lastPatientId, setLastPatientId] = useState()
    const [lastUserId, setUserId] = useState()
    const [cache, setCache] = useState({
        '[PatientName]': { lastId: undefined, value: undefined },
        '[PatientID]': { lastId: undefined, value: undefined },
        '[UserName]': { lastId: undefined, value: undefined },
        '[UserID]': { lastId: undefined, value: undefined },
    })

    useEffect(() => {
        if (activePatient) {
            console.log('Changed Active Patient : Search Bar Wrapper', activePatient)
            setCache({
                ...cache,
                '[PatientID]': { lastId: activePatient.id, value: activePatient.id },
                '[PatientName]': { lastId: PatientId, value: `${activePatient.userProfile.firstName} ${activePatient.userProfile.lastName}` },
            })
        }
    }, [activePatient])

    useEffect(() => {
        if (PatientId && lastPatientId !== PatientId) {
            try {
                Patient.GetById(PatientId).then((_patientDetails) => {
                    setCache({
                        ...cache,
                        '[PatientID]': { lastId: PatientId, value: PatientId },
                        '[PatientName]': { lastId: PatientId, value: `${_patientDetails.userProfile.firstName} ${_patientDetails.userProfile.lastName}` },
                    })
                    setLastPatientId(PatientId)
                }).catch((e) => { })
            } catch (e) { }
        }
        if (UserId && lastUserId !== UserId) {
            try {
                User.get(PatientId).then((_userDetails) => {
                    setCache({
                        ...cache,
                        '[UserID]': { lastId: UserId, value: UserId },
                        '[UserName]': { lastId: UserId, value: `${_userDetails.firstName} ${_userDetails.lastName}` },
                    })
                    setUserId(UserId)
                }).catch((e) => { })
            } catch (e) { }
        }
    }, [PatientId, UserId, lastPatientId])

    const findValue = (o) => {

        if (o.includes('[PatientName]')) { return cache['[PatientName]'].value; }
        if (o.includes('[PatientID]')) { return o.replaceAll('[PatientID]', cache['[PatientID]'].value); }
        if (o.includes('[UserName]')) { return cache['[UserName]'].value; }
        if (o.includes('[UserID]')) { return o.replaceAll('[UserID]', cache['[UserID]'].value); }
        else return o
        // switch (o) {
        //     case '[PatientName]': return cache['[PatientName]'].value;
        //     case '[PatientID]': return o.replaceAll('[PatientID]', cache['[PatientID]'].value);
        //     default: return o
        // }
    }
    return <BreadCrumbContext.Provider value={{
        findValue
    }}>
        {children}
    </BreadCrumbContext.Provider>
}


const BreadCrumsBar = ({ breadcrums, search }) => {
    const navigate = useNavigate();
    const { canNavigate } = useContext(StickyNavigationContext)
    // const [holderValues, setHolderValues] = useState(breadcrums ? [...Array.from(Array(breadcrums.length))] : [])
    const { findValue } = useContext(BreadCrumbContext)
    // useEffect(() => {
    //     _handleBreadCrums()
    // }, [])
    const _handleBreadCrumPlaceholders = (i, holder) => {

        return holder
    }
    // console.log(holderValues)
    // const _handleBreadCrumPlaceholders = async (holder) => {
    //     var _holder = holder
    //     if (_holder.includes('[PatientName]')) {
    //         try {
    //             const _patientDetails = await _loadPatientDetails(id)
    //             if (_patientDetails.userProfile.firstName.length == 36 && _patientDetails.userProfile.lastName.length === 36)
    //                 _holder = _holder.replaceAll('[PatientName]', 'New Patient')
    //             else
    //                 _holder = _holder.replaceAll('[PatientName]', _patientDetails.userProfile.firstName + ' ' + _patientDetails.userProfile.lastName)
    //         } catch (e) {
    //             _holder = _holder.replaceAll('[PatientName]', '')
    //         }
    //     }
    //     if (_holder.includes('[PatientID]'))
    //         _holder = _holder.replaceAll('[PatientID]', id)
    //     if (_holder.includes('[UserName]')) {
    //         try {
    //             const _userDetails = await _loadUserDetails(id)
    //             if (_userDetails.firstName.length == 36 && _userDetails.lastName.length === 36)
    //                 _holder = _holder.replaceAll('[UserName]', 'New User')
    //             else
    //                 _holder = _holder.replaceAll('[UserName]', _userDetails.firstName + ' ' + _userDetails.lastName)
    //         } catch (e) {
    //             _holder = _holder.replaceAll('[UserName]', '')
    //         }
    //     }
    //     if (_holder.includes('[UserID]'))
    //         _holder = _holder.replaceAll('[UserID]', id)
    //     return _holder
    // }

    // const _handleBreadCrums = async () => {
    //     var crums = []
    //     if (breadcrums)
    //         for (var i = 0; i < breadcrums.length; i++)
    //             if (i === 0)
    //                 crums.push(<li
    //                     key={"sbw-bc-" + i}
    //                     className={"breadcrumb-item ".concat(breadcrums[i].active ? 'active' : '').concat(!search ? 'ps-0' : 'ps-2')} >
    //                     <NavLink key={"sbw-bc-nl-" + + new Date().getTime()} to={await _handleBreadCrumPlaceholders(breadcrums[i].location)} className={" shadow ".concat(breadcrums[i].active ? 'active' : '')}>{await _handleBreadCrumPlaceholders(breadcrums[i].name)}</NavLink>
    //                 </li>)
    //             else if (breadcrums[i].active)
    //                 crums.push(
    //                     <li
    //                         key={"sbw-bc-" + i}
    //                         className={"breadcrumb-item  rounded rounded-3 ".concat(breadcrums[i].active ? 'active' : '')} >
    //                         {breadcrums[i].name}
    //                     </li>
    //                 )
    //             else
    //                 crums.push(<li
    //                     key={"sbw-bc-" + i}
    //                     className={"breadcrumb-item  ps-0 ".concat(breadcrums[i].active ? 'active' : '')} >
    //                     <NavLink key={"sbw-bc-nl-" + + new Date().getTime()} to={await _handleBreadCrumPlaceholders(breadcrums[i].location)} className={" ".concat(breadcrums[i].active ? 'active' : '')}>{await _handleBreadCrumPlaceholders(breadcrums[i].name)}</NavLink>
    //                 </li>)
    //     setBreadCrums([...crums])
    //     //setBreadCrums([])
    // }

    if (!breadcrums) return
    return (
        breadcrums.map((crumb, i) =>
            i === 0
                ?
                <li
                    key={"sbw-bc-" + i}
                    className={"breadcrumb-item ".concat(crumb.active ? 'active' : '').concat(!search ? 'ps-0' : 'ps-2')} >
                    <a key={"sbw-bc-nl-" + + new Date().getTime()}
                        className={"btn shadow ".concat(crumb.active ? 'active' : '')}
                        onClick={() => canNavigate(() => crumb.location && navigate(findValue(crumb.location)))}
                    >
                        {findValue(crumb.name)}
                    </a>
                    {/* <NavLink key={"sbw-bc-nl-" + + new Date().getTime()}
                        to={crumb.location && findValue(crumb.location)}
                        className={" shadow ".concat(crumb.active ? 'active' : '')}>
                        {findValue(crumb.name)}
                    </NavLink> */}
                </li>
                :
                crumb.active
                    ?
                    <li
                        key={"sbw-bc-" + i}
                        className={"breadcrumb-item  rounded rounded-3 ".concat(crumb.active ? 'active' : '')} >
                        {crumb.name}
                    </li>
                    :
                    <li
                        key={"sbw-bc-" + i}
                        className={"breadcrumb-item  ps-0 ".concat(crumb.active ? 'active' : '')} >
                        <a key={"sbw-bc-nl-" + + new Date().getTime()}
                            className={"btn ".concat(crumb.active ? 'active' : '')}
                            onClick={() => canNavigate(() => crumb.location && navigate(findValue(crumb.location)))}

                        >
                            {findValue(crumb.name)}
                        </a>
                        {/* <NavLink key={"sbw-bc-nl-" + + new Date().getTime()}
                            to={crumb.location && findValue(crumb.location)}
                            className={" ".concat(crumb.active ? 'active' : '')}>
                            {findValue(crumb.name)}
                        </NavLink> */}
                        {/* {crumb[i].location} */}
                    </li>
        )
    )
}
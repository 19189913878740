import { faAdd, faDownload, faSpinner, faTrash, faUpload } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useEffect, useState } from 'react'
import { TrashFill } from 'react-bootstrap-icons'

export default function FileUploadGrid({ uploadedFiles, processFile, currentFiles, onRemoveDocument, onDownload, onUploaded }) {
    const [files, setFiles] = useState([])

    useEffect(() => {
        setFiles(uploadedFiles)
    }, [uploadedFiles])
    useEffect(() => {

        var _files = []
        for (var file of currentFiles) {
            _files.push({
                id: file.id,
                name: file.fileName,
                status: 'uploaded',
                // date: file.createdDate,
                date: file.createdDate.substr(0, file.createdDate.indexOf('.')),
                uploadedUser: file.createdUser.firstName + ' ' + file.createdUser.lastName,
            })
        }
        setFiles(_files)
        // Render files list
    }, [currentFiles])

    return (
        <div>
            {
                files.map((file) =>
                    <div key={file.id} className='d-flex flex-column-reverse flex-lg-row bg-secondary-container row rounded m-2 p-3'>
                        <div className='col-12 col-lg-4'>{file.name}</div>
                        <div className='col-12 col-lg-4'>{file.uploadedUser}</div>
                        <div className='col-12 col-lg-2'>{file.date}</div>
                        <div className='d-flex col-12 col-lg-2 align-items-center justify-content-center'>
                            {
                                file.id
                                    ?
                                    <FontAwesomeIcon style={{cursor:'pointer'}} size='xl' className='m-2' onClick={(e) => onDownload(file.id)} icon={faDownload} />
                                    :
                                    <></>
                            }
                            {
                                file.status === 'uploading'
                                    ?
                                    <FontAwesomeIcon style={{cursor:'pointer'}} size='xl' className='m-2' icon={faSpinner} spin />
                                    :
                                    // file.status === 'uploaded'
                                    //     ?
                                    //     <FontAwesomeIcon icon={faAdd} />
                                    // :
                                    file.status === 'sending'
                                        ?
                                        <FontAwesomeIcon style={{cursor:'pointer'}} size='xl' className='m-2' icon={faUpload} beat />
                                        :
                                        file.id
                                            ?
                                            <TrashFill style={{cursor:'pointer'}} size={26} className='m-2 ms-5 ms-lg-2' onClick={(e) => onRemoveDocument(file.id, file.name)} />
                                            :
                                            <div>{file.status}</div>
                            }
                            {/* <TrashFill size={22} /> */}
                        </div>
                    </div>
                )

            }
        </div>
    )
}

import React, { useContext } from 'react'
import { LayoutInterface } from '../interfaces/LayoutInterface'
import ErrorContext from '../context/ErrorContext'
import { ValidationRule } from '../interfaces/ValidationRuleInterface'

type Props = {
  layout: LayoutInterface
  children: React.ReactNode
}

const ValidationWrapper = ({ layout, children }: Props) => {
  const { invokeFieldError } = useContext(ErrorContext)
  const _validate = (rule: ValidationRule[], onValid: any, s: string, n: string, v: string) => {
    for (const r of rule) {
      if (!new RegExp(r.rule).test(v)) {
        return invokeFieldError!(layout.name, r.error)
      }
    }
    invokeFieldError!(layout.name, undefined)
    onValid(s, n, v)
  }

  const _validationWrapper = (rule: ValidationRule[], element: any) => {
    return {
      ...element,
      props: {
        ...element.props,
        onChange: (s: string, n: string, v: string) => _validate(rule, element.props.onChange, s, n, v),
      },
    }
  }

  return layout.validation ? _validationWrapper(layout.validation, children) : children
}
export default ValidationWrapper

import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Controls,
  MiniMap,
  ReactFlow,
  useEdgesState,
  useNodesState
} from '@xyflow/react';
import '@xyflow/react/dist/style.css';
import React, { useEffect, useState } from 'react';
import SearchComponent from '../../../components/search/SearchComponent';
import ExperimentalAPI from '../../../libs/api/services/Experimental';
import WorkFlow from '../../../libs/api/services/WorkFlow';
import AudienceNode from './WFCards/AudienceNode';
import CaelendarWFNode from './WFCards/CaelendarWFNode';
import CronNode from './WFCards/CronNode';
import DashboardCardNode from './WFCards/DashboardCardNode';
import MessageNode from './WFCards/MessageNode';
import NotificationNode from './WFCards/NotificationNode';
import PatientProfileNode from './WFCards/PatientProfileNode';
import QuestionnaireNode from './WFCards/QuestionnaireNode';
import ToDoListNode from './WFCards/ToDoListNode';
export default function ExperimentalWF() {
  const [filters, setFilters] = useState([])
  const [lists, setLists] = useState({ loading: true })
  const [nodes, setNodes, onNodesChange] = useNodesState([]);
  const [edges, setEdges, onEdgesChange] = useEdgesState([]);
  const nodeTypes = {
    calendarNode: CaelendarWFNode,
    patientProfileNode: PatientProfileNode,
    dashboardCardNode: DashboardCardNode,
    toDoListNode: ToDoListNode,
    cronNode: CronNode,
    questionnaireNode: QuestionnaireNode,
    messageNode: MessageNode,
    notificationNode: NotificationNode,
    audienceNode: AudienceNode,
  };
  useEffect(() => {
    const load = async () => {
      var _events = []
      var _nodes = []
      var idx = 0;

      for (const g of await WorkFlow.ListGroups(null))
        _events = [..._events, ...await WorkFlow.ListCategoryEvents(g.id)]

      const triger_types = await WorkFlow.ListTriggerTypes()
      const task_types = await WorkFlow.ListTaskTypes()

      setLists({
        events: _events,
        triggers: triger_types,
        tasks: task_types,
        loading: false
      })
      var ovrvw = await ExperimentalAPI.WorkFlowsOverView(filters)
      // for (const n of await ExperimentalAPI.WorkFlowsOverView()) 
      //   _nodes.push({
      //     id: `${n.name}`,
      //     // type: 'annotation',
      //     type: 'input',
      //     draggable: true,
      //     selectable: false,
      //     data: {
      //       level: 1,
      //       label:
      //         `${n.name}`,
      //       // arrowStyle: {
      //       //   right: 0,
      //       //   bottom: 0,
      //       //   transform: 'translate(-30px,10px) rotate(-80deg)',
      //       // },
      //     },
      //     position: { x: -80, y: -30 + (idx++ * 50) },
      //   })
      //console.log(await ExperimentalAPI.WorkFlowsOverView())
      // setNodes(_nodes)
      setNodes(ovrvw.nodes)
      setEdges(ovrvw.edges)
    }
    load()


  }, [])

  useEffect(() => {
    const load = async () => {
      setLists({ ...lists, loading: true })
      var ovrvw = await ExperimentalAPI.WorkFlowsOverView(filters)
      setLists({ ...lists, loading: false })
      setNodes(ovrvw.nodes)
      setEdges(ovrvw.edges)
    }
    if (filters)
      load()
  }, [filters])


  return (
    <div className='w-100 h-100 d-flex flex-column'>
      <h1>Experimental Work Flows</h1>
      {lists.loading && <div className='position-absolute bg-light p-2 shadow rounded'><FontAwesomeIcon icon={faSpinner} spin /> Loading</div>}
      
      <div className='shadow m-1 rounded'>
        <div className='p-3 d-flex justify-content-between align-items-center'>
          <div className='w-100'>
            <label>Workflow</label>
            <SearchComponent
              onSelected={(r) => setFilters({ ...filters, 'wf': r })}
              defaultValue={filters.wf && filters.wf}
              focusSearch
              details={{
                data: lists && lists.events ? lists.events : [],
                options: {
                  reRefKey: 'id',
                  template: [{
                    refKey: 'name',
                    descr: 'Name',
                  },
                    // {
                    //   refKey: 'id',
                    //   descr: 'id',
                    // }
                  ]
                }
              }}
            />
          </div>
          <div className='w-100'>
            <label>Trigger Type</label>
            <SearchComponent
              onSelected={(r) => setFilters({ ...filters, 'tr': r })}
              defaultValue={filters.tr && filters.tr}
              focusSearch
              details={{
                data: lists && lists.triggers ? lists.triggers : [],
                options: {
                  reRefKey: 'id',
                  template: [{
                    refKey: 'description',
                    descr: '',
                  },
                    // {
                    //   refKey: 'id',
                    //   descr: 'id',
                    // }
                  ]
                }
              }}
            />
          </div>
          <div className='w-100'>
            <label>Task Type</label>
            <SearchComponent
              onSelected={(r) => setFilters({ ...filters, 'tsk': r })}
              defaultValue={filters.tsk && filters.tsk}
              focusSearch
              details={{
                data: lists && lists.tasks ? lists.tasks : [],
                options: {
                  reRefKey: 'id',
                  template: [{
                    refKey: 'description',
                    descr: '',
                  },
                    // {
                    //   refKey: 'id',
                    //   descr: 'id',
                    // }
                  ]
                }
              }}
            />
          </div>
          <div>
            <button className='btn' onClick={() => setFilters([])}>Clear All Filters</button>
          </div>
        </div>
      </div>
      <ReactFlow
        nodes={nodes}
        edges={edges}
        onNodesChange={onNodesChange}
        onEdgesChange={onEdgesChange}
        // onConnect={onConnect}
        fitView
        // attributionPosition="top-right"
        nodeTypes={nodeTypes}
      // edgeTypes={edgeTypes}
      // className="overview"
      >

        <MiniMap zoomable pannable
        // nodeClassName={nodeClassName}
        />
        <Controls />
      </ReactFlow>
    </div>
  )
}

import React, { useEffect, useRef, useState } from 'react'
import Patient from '../../../../../libs/api/services/Patient'

export default function PatientCategoryFieldSearch({ onChanged, defaultValue, showAny, categoryId }) {
    const [category, setCategory] = useState()
    const [categories, setCategories] = useState([])
    const [categoriesFound, setCategoriesFound] = useState([])
    const searchRef = useRef()
    //Hospital Admission
    const _removeTrailing = (word) => {
        const verbEndings = ['ed', 'ing', '\'s', 'es', 'ie']
        for (var e of verbEndings) {
            if (word.substr(word.length - e.length) === e) {
                word = word.substr(0, word.length - e.length)
            }
        }
        return word
    }
    const _handleSearch = (_filter) => {
        // setSFilter(_filter)
      
        var _result = []
        var found_exact = false
        var _rlist = []
        for (var event of categories) {
            if (event.value.trim().toLowerCase() === _filter.toLowerCase().trim()) {
                found_exact = true
            }
            var _score = 0;
            var _score_filter_length = _filter.length;
            var _score_search_length = categories.length;
            // split words
            for (var wordSearch of _filter.toLowerCase().split(' ')) {
                //  remove ed,ing, er est ile able 
                for (var resultSearch of event.value.toLowerCase().split(' ')) {
                    if (_removeTrailing(resultSearch).includes(_removeTrailing(wordSearch))) {
                        _score++;
                    }
                }
            }
            var final_score = _score === 0 ? 0 : _score_search_length / _score

            if (final_score > 0.7) {
                _rlist.push({
                    id: event.id,
                    value: event.value,
                    score: _score
                })
            }
        }
        // if (!found_exact)
        //     _rlist.push({
        //         id: "New",
        //         value: _filter,
        //         score: -1
        //     })
        // if (_rlist.length > 0)
        //     _result.push({
        //         id: category.id,
        //         category: category.category,
        //         events: _rlist
        //     })
        // }
        var _result_sorted = []
        for (var r of _rlist) {
            var _result_sorted_events = []
            // for (var e of r.category.sort((b, a) => parseFloat(a.score) - parseFloat(b.score))) {
            //     _result_sorted_events.push({
            //         id: e.id,
            //         category: e.category
            //     })
            // }
            _result_sorted.push({
                id: r.id,
                value: r.value,
                // events: []
                events: _result_sorted_events
            })
        }
        if (_rlist.length === 0 && showAny) {
            _rlist = categories
        } else {
        }
        setCategoriesFound(_rlist)
    }
    const _loadCategories = () => {
        Patient
            .LoadFields(categoryId)
            .then((r) => { setCategories(r) })
    }
    useEffect(() => {
        _loadCategories()
        if (defaultValue) {
            searchRef.current.value = defaultValue.value
            //onChanged(defaultValue)
        }
    }, [])

    const _handleSearchCategory = (search) => {

        _handleSearch(search)
        // var _results = []
        // const _result = categories.find(c => c.category === search)
        // // const _result = categories.find(c => c.id !== undefined)
        // if (_result)
        //     _results.push(_result)
        // _results.push({ id: 'New', category: search })
        // setCategoriesFound(_results)
    }

    const _handleSelectCategory = (category) => {
        searchRef.current.value = category.value
        setCategoriesFound([])
        if (category.id === 'New') {
            // PatientHistoryEvent.UpdateCategory({ category: category.category })
            //     .then((r) => {
            //         _loadCategories()
            //         onChanged(r)
            //     })
        } else { onChanged(category) }
    }

    const checkShowAny = () =>
    {
        _handleSearch(searchRef.current.value)
    }

    return (
        <>
            <div className='position-relative'>
                <input 
                    onFocus={(e) => checkShowAny()}
                ref={searchRef} onChange={(e) => _handleSearchCategory(e.target.value)} className='form-control form-control-sm' />
            </div>
            <div className='bg-search-bar-result position-absolute w-50'>
                <ul style={{ cursor: 'pointer' }} className='position-relative'>
                    {
                        categoriesFound.map((c) =>
                            <li onClick={(e) => _handleSelectCategory(c)} className='p-2' >{c.value}</li>
                        )
                    }
                </ul>
            </div>
        </>
    )
}

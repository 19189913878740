import React, { useEffect, useRef, useState } from 'react'
import { CheckSquare, Pencil, Square } from 'react-bootstrap-icons'
import Data from '../../../../../libs/api/services/Data'
import Questionnaire from '../../../../../libs/api/services/Questionnaire'
import RTEMessageBuilderComponent from '../../../messaging/admin/components/RTEMessageBuilderComponent'
import QBuilderGenerics from '../QBuilderGenerics'
import QBuilderComponentOptions from './QBuilderComponentOptions'

export default function QBuilderComponentDisplayText({ cache, onSetData, onChangeMode, activeQuestionnaireAssignment, onLoadComplete, id, ComponentId, onAction, idx, edit, defaultMode }) {
    const [mode, setMode] = useState(edit ? 'Edit Text' : '')
    const [details, setDetails] = useState({ text: '' })
    const [properties, setProperties] = useState([])
    const [loading, setLoading] = useState(true)
    const [responseValue, setResponseValue] = useState()
    const [busy, setBusy] = useState(true)

    const [data, setData] = useState()
    const isMountedRef = useRef(false);

    const _load = async () => {
        try {
            if (!isMountedRef.current)
                if (activeQuestionnaireAssignment)
                    setData(await QBuilderGenerics.loadData(cache, ComponentId, activeQuestionnaireAssignment.id))
                else
                    setData(await QBuilderGenerics.loadData(cache, ComponentId,))
            onLoadComplete()
            isMountedRef.current = true;
        } catch (e) { }
    }

    useEffect(() => {
        // onLoad
        _load()
    }, [])

    useEffect(() => {
        if (data && data.properties) {
            // Rerender after data changed
            setLoading(true)
            _loadProperties(true)
            // Ignoreing null value to initiate RTE
            // if (data.response) _LoadResponse(true)
            _LoadResponse(true)
            setLoading(false)
        }
    }, [data])

    useEffect(() => {
        onChangeMode(mode)
    }, [mode])


    const _loadProperties = async (prop, cached) => {
        setProperties(data.properties)
        var _details = { ...details }

        try {
            _details.text = QBuilderGenerics.getProperty(data.properties, "Question")
            if (!_details.text)
                _details.text = ''
        } catch (e) { _details.text = '' }
        try {
            _details.isDynamic = QBuilderGenerics.getProperty(data.properties, "isDynamic")
        } catch (e) { }


        setDetails(_details)
    }
    const _setProperty = (prop, value) => {
        Questionnaire
            .SetComponentProperty(ComponentId, {
                property: prop,
                values: value,
                component: {
                    id: ComponentId
                }
            })
    }

    const _handleToggleDynamicText = () => {
        var _details = { ...details }

        _details.isDynamic = _details.isDynamic === true ? true : false
        _details.isDynamic = !_details.isDynamic
        _setProperty("isDynamic", JSON.stringify(_details.isDynamic))

        setDetails(_details)
    }

    const _handleSetText = (text) => {
        var _details = { ...details }

        QBuilderGenerics.setProperty(ComponentId, "Question", text)
        _details.text = text

        setDetails(_details)
    }


    useEffect(() => {
        if (defaultMode && defaultMode === 'Overview')
            setMode('')
    }, [defaultMode])

    const _handleClose = () => {
        setBusy(true)
        QBuilderGenerics.loadComponentProperties(ComponentId)
            .then((r) => {
                setData({ properties: r })
                setResponseValue(details.text)
                setMode('')
                setBusy(false); setTimeout(setBusy, 1000, true)
            })
    }
    const _checkLinkedFields = async (answer, properties) => {
        
        if (answer !== undefined && answer !== '' && answer.indexOf("{{") === -1) throw Error('Answer already entered')
        //if (!answer || answer === '') {
        var isDynamic = false
        
        try {

            try {
                const _isDynamic = properties.find(p => p.property === 'isDynamic').values
                if (JSON.parse(_isDynamic) === true) { isDynamic = true }
            } catch (e) { }

            const ret = properties.find(p => p.property === 'Question').values
            // Just set response to placeholder if in edit mode
            if (edit) return setResponseValue(JSON.parse(ret))

            if (ret.indexOf('\{\{') > 0) {
                // Check placeholders
                const r = await Data.RenderText({
                    text: ret,
                    //facilityId: 0,
                    patientUserId: activeQuestionnaireAssignment.assignedTo.id
                }).catch((e) => {
                    console.error('DEBUG::(RenderText::Error)', e)
                    // Faillback to default question values
                    onSetData(ret, isDynamic)
                    setResponseValue(JSON.parse(ret))
                })
                onSetData(r.text, true) // dont check isDynamic because contains palceholders
                setResponseValue(r.text)
            } else {
                onSetData(ret, isDynamic) // onSetData([value]], [Save to db])
                setResponseValue(JSON.parse(ret))
            }
        } catch (e) {
            console.error('DEBUG::(Error)', e)
            throw Error(e)
        }
        // setLoading(false)
    }

    const _LoadResponse = (properties, fromCache) => {
        const _ansewr = data.response ? data.response.answer : ''
        _checkLinkedFields(_ansewr, data.properties)
            .catch((e) => {
                onSetData(_ansewr)
                setResponseValue(_ansewr)
            })
    }

    const _renderMode = () => {
        var _ren = []
        switch (mode) {
            case 'Edit Text':
                _ren = (
                    <div className='text-center'>
                        {
                            details && (details.text || details.text === "") ?
                                <RTEMessageBuilderComponent
                                    key={'ref_3_edit'}
                                    template={details.text}
                                    onSetTemplate={(template, html, md) => _handleSetText(template)}
                                    options={[
                                        'inline', 'blockType', 'fontSize', 'fontFamily', 'list', 'textAlign', 'colorPicker'
                                    ]}
                                />
                                : <></>
                        }

                        {/* <button onClick={(e) => _handleSave()} className='btn btn-sm'>Save</button> */}
                    </div>
                )
                break;
            case 'Dynamic Text':
                _ren = (
                    <div onClick={() => _handleToggleDynamicText()} className='text-start ms-3 d-flex align-items-center'>
                        <div>
                            {
                                details.isDynamic
                                    ?
                                    <CheckSquare className='me-2' size={20} />
                                    :
                                    <Square className='me-2' size={20} />
                            }
                        </div>
                        <div>
                            <b>Dynamic</b>
                            <p className='p-0 m-0'>This will allow the person filling in the form/questionnaire to change this text</p>
                        </div>
                    </div>
                )
                break;
            default:
                break;
        }

        return (
            <div style={{ cursor: 'pointer' }} className={'bg-light w-50 shadow rounded text-start p-2 position-fixed '.concat(' slide-in')}>
                <QBuilderComponentOptions busy={busy} onClose={() => _handleClose()} onAction={(e) => onAction(e)} idx={idx} />
                <div className='d-flex rounded bg-primary-container m-2'>
                    <div className={'p-2 '.concat(mode === 'Edit Text' ? 'active' : '')} onClick={() => setMode('Edit Text')} >Set Text</div>
                    <div className={'p-2 '.concat(mode === 'Dynamic Text' ? 'active' : '')} onClick={() => setMode('Dynamic Text')} >Dynamic Text</div>
                    {/* <div className={'p-2 '.concat(mode === 'Edit Size' ? 'active' : '')} onClick={() => setMode('Edit Size')} >Set Style</div> */}
                    {/* <div className={'p-2 '.concat(mode === '' ? 'active' : '')} onClick={() => _handleClose()} >Close</div> */}
                </div>
                {_ren}
            </div>
        )
    }

    if (loading) return <div></div>
    // else if (mode !== '')
    //     return _renderMode()
    // else
    return (
        <>
            {
                mode !== ''
                    ?
                    _renderMode()
                    :
                    <></>
            }
            <div className={'text-start '.concat(mode !== '' ? 'bg-primary-container opacity-75 shadow' : '')}>
                {
                    edit
                        ?
                        <Pencil className='me-2' onClick={(e) => setMode('Edit Text')} />
                        :
                        <></>
                }
                {
                    responseValue ?
                        mode === '' ?
                            <RTEMessageBuilderComponent
                                key={'rte_edit_1'}
                                viewOnly={true}
                                readOnly={!details.isDynamic}
                                template={responseValue}
                                onSetTemplate={(template, html, md) => onSetData(template, true)}
                            />
                            :
                            <RTEMessageBuilderComponent
                                key={'rte_edit_2'}
                                viewOnly={true}
                                readOnly={!details.isDynamic}
                                template={responseValue}
                                onSetTemplate={(template, html, md) => onSetData(template, true)}
                            />
                        : <></>
                }



            </div>
        </>
    )
}

import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import ErrorDialog from '../../../../../../components/dialogs/ErrorDialog'
import Authentication from '../../../../../../libs/api/services/Authentication'
import MessagingServiceProvider from '../../../../../../libs/api/services/MessagingServiceProvider'
import EmailConfigurationComponentCombined from '../../../configuration/facility/components/wizard/Email/EmailConfigurationComponentCombined'
import UserEmailDiscoverComponent from './UserEmailDiscoverComponent'

export default function UserEmailDetails({ title, description, navigation, errors, setValue, values }) {
    const { state } = useParams()

    const [error, setError] = useState()

    const [mode, setMode] = useState('')
    const [serviceProviderService, setServiceProviderService] = useState()
    const [properties, setProperties] = useState([])

    useEffect(() => {
        title('Configuration')
        description('Please enter the account details for this email address')
        if (state) {
            setServiceProviderService({ id: state })
            setMode('Discover')
        }
        _loadSettings()
        // if (Object.keys(values).includes('oauth') && values.oauth === 'recheck')
        //     _handleLookupEmailAccount()
    }, [])

    const _loadSettings = () => {
        // Find service where name = audience | {"audienceType":"SingleUser"} | and user is {"audienceType":"SingleUser","audienceUser":{"id":2044,"title":"","firstName":"Patient","lastName":"Patient","username":"PAT","password":"PAT","emailAddress":"","mobileNumber":"","userRoles":[],"userFacilitieRoles":[]}}
        MessagingServiceProvider
            .FindServiceProviderAccountForUser(Authentication.UserId(), Authentication.getFacility() ? Authentication.getFacility() : -1,)
            .then((r) => {
                setProperties({ mailBoxConfig: JSON.parse(r.property) })
            }).catch((e) => { })
    }
    const _setProperties = () => {
        // Check Service + Provider exists
        // MessagingServiceProvider
        //     .UpdateProvider
        MessagingServiceProvider
            .UpdateUserEmailAddcount(Authentication.UserId(), Authentication.getFacility() ? Authentication.getFacility() : -1, properties)
            .then((r) => {
                title('Configuration')
                description('Verifying your email details')
                navigation([])
                setServiceProviderService(r)
                setMode('Discover')
            })
            .catch((e) => { setError(e.message) })
    }

    useEffect(() => {
        // incomming_domain
        // incomming_encryption
        // incomming_port
        // incomming_security
        // name
        // outgoing_domain
        // outgoing_encryption
        // outgoing_port
        // outgoing_security
        // password
        // port
        // provider
        // security
        if (properties.mailBoxConfig && (
            properties.mailBoxConfig.incomming_type &&
            properties.mailBoxConfig.incomming_domain &&
            properties.mailBoxConfig.incomming_port &&
            properties.mailBoxConfig.outgoing_domain &&
            properties.mailBoxConfig.outgoing_port &&
            properties.mailBoxConfig.emailAddress &&
            properties.mailBoxConfig.password
        ))
            navigation([
                {
                    text: 'Test & Continue', type: 'Action', action: async () => { _setProperties() }
                }
            ])
        else navigation([])
    }, [properties])

    // const _handleSetCheck = (c) => {
    //     var _checks = checks;
    //     _checks.push(c)
    //     setChecks([..._checks])
    // }

    // const _handleLookupEmailAccount = async () => {
    //     setMode('VERIFYING')
    //     navigation([])
    //     var step = 0;
    //     var failsafe = 0;
    //     while (step !== -1 && failsafe++ < 50) {
    //         var status = await MessagingService.DiscoverEmailAccount(values.configurationId, step);
    //         _handleSetCheck(status)
    //         if (status.success) {
    //             // description("Check : " + step + " - " + status.nextCheck + ") " + status.status)
    //             if (status.nextCheck === -1) {
    //                 setMode('DETAILS_FOUND')
    //                 navigation([
    //                     {
    //                         text: 'Next', type: 'Step', step: 4, action: async () => {
    //                             MessagingService
    //                                 .SetMessagingServiceProperty(values.configurationId, "EMAIL_INCOMING_MAILBOX_STATUS", "Mailbox configured. Waiting to sync messages")
    //                         }
    //                     }
    //                 ])
    //             }
    //         }
    //         step = status.nextCheck;

    //         // .then((r) => {
    //         //     setMode('DETAILS_FOUND')
    //         //     description('Your email connection configuration has automatically been configured')
    //         //     navigation([
    //         //         {
    //         //             text: 'Next', type: 'Step', step: 4, action: async () => {
    //         //                 MessagingService
    //         //                     .SetMessagingServiceProperty(values.configurationId, "EMAIL_INCOMING_MAILBOX_STATUS", "Mailbox configured. Waiting to sync messages")
    //         //             }
    //         //         }
    //         //     ])
    //         // })
    //         // .catch((e) => {
    //         //     errors(e.message);
    //         //     setMode('FAILED');
    //         //     navigation([
    //         //         {
    //         //             text: 'Back', type: 'Back', action: async () => { }
    //         //         },
    //         //         {
    //         //             text: 'Continue with manual configuration', type: 'Next', action: async () => {

    //         //                 _handleSetValue("EMAIL_INCOMING_MAILBOX_STATUS", "")
    //         //             }
    //         //         }
    //         //     ])
    //         // })
    //     }
    //     if (!status.success) {
    //         setMode('FAILED');
    //         navigation([
    //             {
    //                 text: 'Back', type: 'Back', action: async () => { }
    //             },
    //             {
    //                 text: 'Continue with manual configuration', type: 'Next', action: async () => {

    //                     _handleSetValue("EMAIL_INCOMING_MAILBOX_STATUS", "")
    //                 }
    //             }
    //         ])
    //     }
    // }

    // useEffect(() => {
    //     if (email && password && accountType)
    //         navigation([
    //             {
    //                 text: 'Continue with automatic configuration', type: 'Action', action: async () => { _handleLookupEmailAccount() }
    //             },
    //             {
    //                 text: 'Continue with manual configuration', type: 'Next', action: async () => { }
    //             }
    //         ])
    // }, [email, password, accountType])

    // useEffect(() => {
    //     var username = getSetting('EMAIL_INCOMING_SERVER_USERNAME')
    //     var password = getSetting('EMAIL_INCOMING_SERVER_PASSWORD')
    //     if (username.value)
    //         setEmail(username.value)
    //     if (password.value)
    //         setPassword(password.value)
    // }, [properties])

    // const getSetting = (type) => {
    //     var property = { value: '' }
    //     var _property = undefined
    //     if (properties && properties.length > 0)
    //         _property = properties.find(f => f.propertyType.type === type)
    //     return _property ? _property : property
    // }

    // const _loadSettings = () => {
    //     setLoading(true)
    //     MessagingService
    //         .GetMessagingServiceProperties(values.configurationId)
    //         .then((r) => {
    //             setLoading(false)
    //             setProperties(r)
    //         })
    //         .catch((e) => {
    //             errors(e.message)
    //             // setValue({ 'serviceProviderId': undefined })
    //         })
    // }

    // const _handleSetValue = (id, value) => {
    //     MessagingService
    //         .SetMessagingServiceProperty(values.configurationId, id, value)
    //         .catch((e) => errors(e.message))
    // }
    switch (mode) {
        case 'Discover': return (<UserEmailDiscoverComponent service={serviceProviderService} />)
        default:
            return (
                <>
                    <ErrorDialog error={error} humanize={true} onClose={() => setError()} />
                    <div>

                        <EmailConfigurationComponentCombined
                            onChange={(r) => Object.keys(r).length > 0 ? setProperties({ ...properties, mailBoxConfig: r }) : undefined}
                            defaultValue={properties && properties.mailBoxConfig ? properties.mailBoxConfig : undefined}
                            type={'both'} />
                    </div>
                </>
            )
    }

}

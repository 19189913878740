import React, { useContext } from 'react'
import Authentication from '../../libs/api/services/Authentication'
import CommonFunctionsContext from '../common/CommonFunctionsProvider'

export default function FacilityManagementSearchBar({ section, hideIcon }) {
    const { sectionInfo } = useContext(CommonFunctionsContext)
    return (
        <div className='text-light text-end'>
            <b>{sectionInfo ? sectionInfo : section ? section : `${Authentication.getFacilityName()} Configuration`}
                {/* {hideIcon ? undefined : <GearFill className='ms-2' size={20} />} */}
            </b>
        </div>
    )
}

import React from 'react'
import QCaptureComponentWrapper from '../../../../capture/components/Layouts/QCaptureComponentWrapper'
import QBuilderNewComponent from '../../Generic/QBuilderNewComponent'

export default function QBuilderComponentDynamicComponentTableLayoutItem({ onAddComponent, onChangeComponent, item, onSetData }) {
    const _getType = () => {
        switch (item.type) {
            case 'New':
                return <QBuilderNewComponent onSelected={(type) => onAddComponent(type)} />
            default: {
                const _component = { ...item.component, componentType: { ...item.type }, properties: [] }
                return <QCaptureComponentWrapper
                    component={_component}
                    mode={'Overview'}
                    edit={true}
                    onSetData={(value, update) => onSetData(_component.id, value, update)}
                    onAction={() => { }}
                    activeSubsections={[]}
                    defaultMode={'Overview'}
                    view={'Overview'}
                />
            }
        }
    }
    return <div>
        <div>
            Column Name
            <input
                onBlur={(e) => onChangeComponent({ name: e.target.value })}
                defaultValue={item.name ? item.name : ''}
                className='form-control form-control-sm'
                placeholder='Column Name'
            />
        </div>
        <div>
            {_getType()}
        </div>
    </div>
}

import React from 'react'
import DateOptions from './DateOptions'
import DateTimePickerFunctions from './DateTimePickerFunctions'
import TimeOptions from './TimeOptions'

const functions = new DateTimePickerFunctions()

export default function DateTimeOptions({ mode, value, onChange }) {
    switch (mode) {
        case 'Date': return <DateOptions
            onChange={(d) => onChange(functions.formatForDate(d, value))}
            value={{ year: value.getFullYear(), month: value.getMonth(), day: value.getDate() }}
        />
        case 'Time': return <TimeOptions
            value={{ hours: value.getHours(), minutes: value.getMinutes(), seconds: value.getSeconds() }}
            onChange={(v) => { onChange(functions.formatTimeForDate(v.hours + ":" + v.minutes + ":00", value)) }} />
        default: return <></>
    }
}

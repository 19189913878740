import React, { useEffect, useState } from 'react'
import { Calendar2Fill, XCircleFill } from 'react-bootstrap-icons'
import DateTimePickerFunctions from '../../../../../components/ui/calendar/date_picket/DateTimePickerComponents/DateTimePickerFunctions'
import DayOverviewCalendarModal from './DayOverviewCalendarModal'
import DayViewCalendarModal from './DayViewCalendarModal'
import ExternalCalendarsModal from './ExternalCalendarsModal'

export default function ModalContainer({ details, calendarType, setDetails }) {
    const [visible, setVisible] = useState(false)

    useEffect(() => {
        if (details.visible)
            document.addEventListener('keyup', (e) => {
                if (e.code === 'Escape') setDetails({ ...details, visible: false })
            });
        else {
            document.removeEventListener('keyup', (e) => { })
            setDetails({ visible: false })
        }
        setVisible(details.visible)

    }, [details.visible])

    const _renderDate = () => {
        //var dateUTC = details.date.toUTCString()
        if (details.date)
            return DateTimePickerFunctions.LocalISO(details.date,'h') //details.date.toLocaleString().replace(',', '')

        // return details.date.toUTCString()
    }

    const _renderModalByType = () => {
        if (details.mode)
            switch (details.mode) {
                case 'Month': return <DayOverviewCalendarModal onDone={() => setDetails({ visible: false })} calendarType={calendarType} details={details} />
                case 'Day': return <DayOverviewCalendarModal onDone={() => setDetails({ visible: false })} calendarType={calendarType} details={details} />
                case 'Week': return <DayOverviewCalendarModal onDone={() => setDetails({ visible: false })} calendarType={calendarType} details={details} />//////<<<<<<
                case 'AddEvent': return <DayViewCalendarModal calendarType={calendarType} details={details} />
                case 'EditEvent': return <DayViewCalendarModal calendarType={calendarType} details={details} />
                case 'External Calendars': return <ExternalCalendarsModal details={details} />
                default: return 'Unkown Calender'
            }
    }

    return (
        <div
            className={'position-fixed d-flex justify-content-center align-items-center '.concat(visible ? 'fade-in-modal' : 'hide-modal')}
            style={{ top: '0px', bottom: '0px', left: '0px', right: '0px', zIndex: 1000, background: 'rgba(0,0,0,0.5)' }}
        >
            <div className='bg-light rounded d-flex flex-column' style={{ minWidth: '95%', minHeight: '80vh', maxHeight: '80vh' }}>
                <div className='d-flex p-2 align-items-center bg-secondary-container rounded-top'>
                    <div className='flex-shrink-1'><Calendar2Fill size={30} /></div>
                    <div className='flex-grow-1 text-center'><b>{_renderDate()}</b></div>
                    <div onClick={() => setDetails({ ...details, visible: false })} className='flex-shrink-1'><XCircleFill size={30} /></div>
                </div>
                {details.mode && _renderModalByType()}
            </div>
        </div>
    )
}

import React, { useEffect, useRef, useState } from 'react';
import { CheckSquare, Square } from 'react-bootstrap-icons';

export default function QBuilderToggle({ onChange, isActive, descr }) {
    const [active, setActive] = useState(typeof isActive === 'undefined' ? false : isActive)
    const isMountingRef = useRef(false);

    useEffect(() => {
        setActive(isActive)
    }, [isActive])

    useEffect(() => {
        if (onChange)
            onChange(active)
    }, [active])

    return (
        <div onClick={(e) => setActive(!active)}>
            {
                active
                    ?
                    <CheckSquare size={20} className='me-1' />
                    :
                    <Square size={20} className='me-1' />
            }
            {descr}
        </div>
    )
}

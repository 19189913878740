
import React, { useEffect, useState } from 'react'
import { Bar } from 'react-chartjs-2'

export default function StatsLabelsGraphComponent({ activeLabels, weighting, labels, onItemSeletected }) {
    const [dataSets, setDataSet] = useState([])
    const [dataSetsRefs, setDataSetsRefs] = useState([])

    const _calc = () => {
        if (weighting.multi) {
            var refs = []
            var res = []
            for (const w of weighting.weighting) {
                var _entry = _calcSingle(w)
                try {
                    _entry.dataSet[0].label = w[0].caption ? w[0].caption : undefined
                } catch (e) {
                }
                refs.push(_entry.refs)
                res.push(_entry.dataSet[0])
            }
            setDataSetsRefs(refs)
            setDataSet(res)

        } else {
            const _entry = _calcSingle(weighting.weighting)
            setDataSetsRefs(_entry.refs)
            setDataSet(_entry.dataSet)
        }
    }
    const _calcSingle = (weighting) => {
        var _calcs = []
        var _tots = []
        var _refData = []
        var _caption = undefined
        try {
            //for (const label of labels) {
            var x = 0, y = 0
            for (const label of activeLabels) {
                var tot = 0
                try {
                    for (const w of weighting) {
                        y = 0
                        try {
                            if (!_caption && w.caption && w.caption !== '')
                                _caption = w.caption
                        } catch (e) { }
                        if (w.labels.includes(label))
                            tot += w.score
                        y++
                    }
                    //_refData[x][y] = { 'label': label, value: 0 }
                    if (y === 1) _refData[x] = []
                    _refData[x].push({ label: label, value: 0 })
                    x++
                    _tots.push(tot)
                } catch (e) { _tots.push(0) }
            }

            _calcs.push({
                type: 'bar',
                label: _caption,
                backgroundColor: '#22aa99',
                data: [..._tots],
            })
        } catch (e) { }
        return { dataSet: _calcs, refs: _refData }
        // setDataSetsRefs(_refData)
        // setDataSet(_calcs)
    }

    useEffect(() => {
        if (activeLabels)
            _calc()
    }, [weighting, labels])

    useEffect(() => {
        if (activeLabels)
            _calc()
    }, [activeLabels])
    const _handleItemSelectected = (datasetIdx, itemIdx) => {
        if (weighting.multi)
            onItemSeletected([{ source: 'labels-graph', key: dataSetsRefs[datasetIdx][itemIdx][0].label }])
        else
            onItemSeletected([{ source: 'labels-graph', key: dataSetsRefs[itemIdx][0].label }])
    }

    if (!labels) return <></>

    return (
        <div key={'StatsLabelsGraph'} className={'text-center '}>
            <div className='d-flex flex-column align-items-center'>
                {
                    dataSets.length > 0 ?
                        <Bar className='align-items-center' options={{
                            // scales: {
                            //     yAxes: [{
                            //         scaleLabel: {
                            //             display: true,
                            //             labelString: 'Y text'
                            //         }
                            //     }],
                            //     xAxes: [{
                            //         scaleLabel: {
                            //             display: true,
                            //             labelString: 'X text'
                            //         }
                            //     }],
                            // },
                            onClick: function (event, elements) { if (elements && elements.length > 0) _handleItemSelectected(elements[0].datasetIndex, elements[0].index) },
                            // indexAxis: 'y',
                            // scale: {
                            //     ticks: {
                            //         display: false
                            //     }
                            // },
                            // scales: {
                            //     x: {
                            //         ticks: {
                            //             beginAtZero: true,
                            //             steps: 1,
                            //             stepSize: 1,
                            //         }
                            //     }
                            // },
                            // elements: {
                            //     bar: {
                            //         borderWidth: 0,
                            //     },
                            // },
                            responsive: false,
                            plugins: {
                                legend: {
                                    display: false
                                }
                            },

                        }} data={{
                            type: 'bar',
                            labels: activeLabels,
                            datasets: dataSets
                        }} /> : <></>
                }
            </div>
        </div>
    )
}
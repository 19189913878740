import React, { useEffect, useState } from 'react'

export default function QBuilderDone({ title, description, navigation, errors, setValue, values }) {
    useEffect(() => {
        title('Questionnaire Builder')
        description('Questionnaire has been setup')
        // Load Navigation if session started
        // if (Object.keys(values).includes('templateId'))
        navigation([
            // { text: 'Remove', type: 'Action', action: async () => { _handleRemoveTemplate(values.templateId).then((r) => { navigation([]); loadConfigs() }) } },
            {
                text: 'Back', type: 'Back', action: async () => {
                    // setValue({ 'templateId': values.templateId })
                }
            },
            {
                text: 'Done', type: 'Done', action: async () => {
                    // setValue({ 'templateId': values.templateId })
                }
            }
        ])
        // else
        //     navigation([])
    }, [])

    // const loadConfigs = () => {
    //     // MessageTemple.
    //     //     Templates()
    //     //     .then((r) =>
    //     //         setConfigurations(r)).catch((e) => errors(e.message))
    // }

    // useEffect(() => {
    //     loadConfigs()
    // }, [])

    // const _handleSetConfigrationName = (c) => {
    //     setConfigurationName(c)
    //     if (c)
    //         navigation([{
    //             text: 'Next', type: 'Next', action: async () => {
    //                 // return await MessageTemple
    //                 //     .Create({
    //                 //         name: c
    //                 //     }).then((r) => {
    //                 //         setValue({ 'templateId': r.id, 'templateName': c })
    //                 //     })
    //             }
    //         }])
    //     else
    //         navigation([])
    //     setConfiguration()
    // }

    // const _handleRemoveTemplate = async (id) => {
    //     // await MessageTemple
    //     //     .RemoveTemplate(id)
    // }

    // const _handleSelectConfiguration = (c) => {
    //     if (c)
    //         navigation([
    //             { text: 'Remove', type: 'Action', action: async () => { _handleRemoveTemplate(c.id).then((r) => { navigation([]); loadConfigs() }) } },
    //             { text: 'Update', type: 'Next', action: async () => { setValue({ 'templateId': c.id, 'templateName': c.name }) } },
    //         ])
    //     setConfigurationName('')
    //     setConfiguration(c)
    // }

    return (
        <></>
    )
}
import React, { useContext, useEffect, useState } from 'react'
import ErrorDialog from '../../../../components/dialogs/ErrorDialog'
import ToastDialog from '../../../../components/dialogs/toast_dialog/ToastDialog'
import FlowAssistant from '../../../../libs/api/services/FlowAssistant'
import StickyNavigationContext from '../../../../libs/providers/StickyNavigationProvider'
import QBuilderAdvancedOptions from './Wizard/QBuilderAdvancedOptions'
import QBuilderDone from './Wizard/QBuilderDone'
import QBuilderLayout from './Wizard/QBuilderLayout'
import QBuilderLayoutQuestions from './Wizard/QBuilderLayoutQuestions'
import QBuilderSetupType from './Wizard/QBuilderSetupType'
import QBuilderWelcome from './Wizard/QBuilderWelcome'

export default function QuestionnaireBuilder() {
    const [step, setStep] = useState(0)
    const [title, setTitle] = useState('')
    const [description, setDescription] = useState('')
    const [navigation, setNavigation] = useState([])
    const [errors, setErrors] = useState('')
    const [values, setValues] = useState({})
    const [status, setStatus] = useState({})
    const [history, setHistory] = useState({})
    const { setSticky, setPreventMessage } = useContext(StickyNavigationContext)

    const _handleSetNavigation = async (n) => {
        //alert(JSON.stringify(n))
        var _nav = []
        var history = FlowAssistant
            .GetLast()
        if (history && step === 0) {
            var vs = {}
            for (const k of Object.keys(history.details)) {
                // const parsed = JSON.parse(history.details[k])
                try {
                    vs[k] = JSON.parse(history.details[k])
                } catch (e) {
                    vs[k] = history.details[k]
                }
                setValues({ ...values, ...vs })
            }
        }
        if (history) {
            _nav.push({ type: 'Action', action: () => FlowAssistant.Goto(history.section), text: 'Cancel' })
        }
        _nav = [..._nav, ...n]
        setNavigation(_nav)
    }

    useEffect(() => {
        setHistory(FlowAssistant.GetLast())
    }, [])

    useEffect(() => {
        if (step != 1 && step != 5) {
            setSticky(true)
            setPreventMessage('Configuration Incomplete', 'You have not completed the configuration process. <br/><br/>This can be completed later, however the survey will not be active until completed. <br/><br/>Are you sure you would like to cancel?')
        } else {
            setSticky(false)
        }
    }, [step])

    const _hasLast = async () => {
        const Last = await FlowAssistant
            .GetLast()
        if (Last) {
            switch (Last.section) {
                case 'QUESTIONAIRE_BUILDER_OPTIONS':
                    FlowAssistant.HasRedirected(Last)
                    setValues(Last.details)
                    setStep(5)
                    break;
                default:
                    break;
            }
        }
    }

    useEffect(() => {
        _hasLast()
    }, [])

    const _getStepSection = () => {
        switch (step) {
            case 0:
                return <QBuilderWelcome
                    title={(t) => setTitle(t)}
                    description={(t) => setDescription(t)}
                    navigation={(t) => _handleSetNavigation(t)}
                    errors={(e) => setErrors(e)}
                    setValue={(v) => setValues({ ...values, ...v })}
                    values={values}
                />
            case 1:
                return <QBuilderSetupType
                    title={(t) => setTitle(t)}
                    description={(t) => setDescription(t)}
                    navigation={(t) => _handleSetNavigation(t)}
                    navigator={{
                        goto: (s) => setStep(s)
                    }}
                    errors={(e) => setErrors(e)}
                    setValue={(v) => setValues({ ...values, ...v })}
                    values={values}
                />
            case 2:
                return <QBuilderLayout
                    title={(t) => setTitle(t)}
                    description={(t) => setDescription(t)}
                    navigation={(t) => _handleSetNavigation(t)}
                    errors={(e) => setErrors(e)}
                    setValue={(v) => setValues({ ...values, ...v })}
                    values={values}
                />
            case 3:
            // return <QBuilderSectionsSetup
            //     title={(t) => setTitle(t)}
            //     description={(t) => setDescription(t)}
            //     navigation={(t) => _handleSetNavigation(t)}
            //     navigator={{
            //         goto: (s) => setStep(s)
            //     }}
            //     errors={(e) => setErrors(e)}
            //     setValue={(v) => setValues({ ...values, ...v })}
            //     values={values}
            // />
            case 4:
                return <QBuilderLayoutQuestions
                    title={(t) => setTitle(t)}
                    description={(t) => setDescription(t)}
                    navigation={(t) => _handleSetNavigation(t)}
                    errors={(e) => setErrors(e)}
                    setValue={(v) => setValues({ ...values, ...v })}
                    values={values}
                />
            case 5:
                return <QBuilderAdvancedOptions
                    title={(t) => setTitle(t)}
                    description={(t) => setDescription(t)}
                    navigation={(t) => _handleSetNavigation(t)}
                    errors={(e) => setErrors(e)}
                    setValue={(v) => setValues({ ...values, ...v })}
                    values={values}
                />
            case 6:
                return <QBuilderDone
                    title={(t) => setTitle(t)}
                    description={(t) => setDescription(t)}
                    navigation={(t) => _handleSetNavigation(t)}
                    errors={(e) => setErrors(e)}
                    setValue={(v) => setValues({ ...values, ...v })}
                    values={values}
                />
            default:
                return <></>
        }
    }

    return (
        <>
            <ToastDialog {...status} />
            <ErrorDialog error={errors} onClose={() => setErrors('')} />
            <div
                className="Wizard top-center position-relative">
                <div className="head"><h1>{title}</h1>
                    <p dangerouslySetInnerHTML={{ __html: description }}></p>

                    <div
                        className="error-details"></div>
                </div>
                <div className="body">
                    <div
                        className=".section d-flex flex-column align-items-stretch h-100 w-100">
                        {_getStepSection()}
                    </div>
                </div>
                <div classname="tail">
                    {
                        navigation.map((n) => {
                            if (n.type === 'Next')
                                return <button
                                    key={n.text}
                                    onClick={async (e) => {
                                        if (n.action) {
                                            try {
                                                await n.action()
                                                    .catch(() => {
                                                        setErrors(e.message)
                                                        return
                                                    })
                                            } catch (e) {
                                                return
                                            }
                                        }
                                        setStep(step + 1)
                                    }} className="btn ng-buttons">{n.text}</button>
                            else if (n.type === 'Back')
                                return <button
                                    key={n.text}
                                    onClick={async (e) => {
                                        if (n.action) {
                                            await n.action().catch((e) => setErrors(e.message))
                                        }
                                        setStep(step - 1)
                                    }} className="btn ng-buttons">{n.text}</button>
                            else if (n.type === 'Action')
                                return <button
                                    key={n.text}
                                    onClick={async (e) => {
                                        if (n.action)
                                            await n.action()
                                                .catch((e) => setErrors(e.message))
                                    }} className="btn ng-buttons">{n.text}</button>
                            else if (n.type === 'Done')
                                return <button
                                    key={n.text}
                                    onClick={async (e) => {
                                        if (n.action)
                                            await n.action()
                                                .catch((e) => setErrors(e.message))
                                        setStep(1)
                                    }} className="btn ng-buttons">{n.text}</button>
                            else if (n.type === 'Step')
                                return <button
                                    key={n.text}
                                    onClick={async (e) => {
                                        if (n.action)
                                            await n.action()
                                                .catch((e) => setErrors(e.message))
                                        setStep(n.step)
                                    }} className="btn ng-buttons">{n.text}</button>
                        })
                    }
                </div>
            </div>
        </>
    )
}

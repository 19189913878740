import React, { useEffect, useRef, useState } from 'react'
import { Clipboard2, Clipboard2Check } from 'react-bootstrap-icons'
import { DoubleClick } from '../../../../../../components/Hooks/DoubleClick'
import InputWithKeyFunctions from '../../../../../../components/inputs/InputWithKeyFunctions'
import LoaderComponent from '../../../../../../components/loader/LoaderComponent'
import ToDoListService from '../../../../../../libs/api/services/ToDoListService'

export default function ToDoListSelection({ onCancel, onSelected, onSubmit }) {
    const [items, setItems] = useState([])
    const [loading, setLoading] = useState(true)

    const _load = async () =>
        await ToDoListService.ListToDoLists().then((r) => {
            setItems(r); setLoading(false);
        })

    useEffect(() => { _load() }, [])

    return (
        loading ? <LoaderComponent /> :
            <FilterItemsPanel items={items} onCancel={onCancel} onSubmit={onSubmit} onSelected={onSelected} />

    )
}
/**
 * 
 * Generics
 */

const FilterItemsPanel = ({
    items,
    onSelected,
    onSubmit,
    onCancel
}) => {
    const [item, setItem] = useState()
    const [filtered, setFiltered] = useState(items)
    const [activeIndex, setActiveIndex] = useState(-1)

    const activeItemRef = useRef()

    const _handleSelected = (e, c) => {
        setItem(e)
        setActiveIndex((activeIndex) => filtered.indexOf(e));
        onSelected && onSelected(e)
        if (c) onSubmit(e)
    }

    useEffect(() => {
        activeItemRef.current && activeItemRef.current.scrollIntoView({ behavior: "smooth", block: "center", inline: "center" })
    }, [activeIndex, activeItemRef])

    useEffect(() => { if (items) setFiltered(items) }, [items])

    useEffect(() => {

        const _handleKeyDown = (e) => {
            switch (e.key) {
                case 'ArrowDown':
                    _handleSelected(filtered[activeIndex < filtered.length - 1 ? activeIndex + 1 : filtered.length - 1])
                    break;
                case 'ArrowUp':
                    _handleSelected(filtered[activeIndex > 0 ? activeIndex - 1 : 0])
                    break;
                case 'Enter': if (onSubmit && item) onSubmit(item); break;
                case 'Escape': if (onCancel) onCancel(); break;
                default: break;
            }
        }

        document.addEventListener('keydown', _handleKeyDown)
        return () => { document.removeEventListener('keydown', _handleKeyDown) }
    }, [activeIndex, filtered, item])



    const _handleFilter = (e) => {
        if (!e) return
        var _filtered = []
        itz: for (const it of items)
            for (const el of Object.keys(it))
                if (typeof it[el] === 'string' && it[el].toLowerCase().includes(e.toLowerCase())) {
                    _filtered.push(it)
                    continue itz;
                }
        setFiltered(_filtered)
    }

    if (!items) return <></>
    return (<div className='bg-secondary-container rounded d-flex flex-column p-2' style={{ maxHeight: '100%', overflow: 'hidden' }}>
        <div className='p-2'>
            <InputWithKeyFunctions selected className={'form-control'} placeholder={'Search'} onChange={_handleFilter} />
        </div>
        <div style={{ overflow: 'auto' }} >
            {filtered.map((r) =>
                <DoubleClick onClick={() => _handleSelected(r)} onDoubleClick={() => _handleSelected(r, true)}
                    className={'m-2 rounded p-2 d-flex '.concat(item && item.id === r.id ? ' shadow bg-primary-container ' : '')} key={r.id}>
                    <div ref={item && item.id === r.id ? activeItemRef : undefined} id={r.id} className='flex-shrink-1'>

                        {item && item.id === r.id ? <Clipboard2Check /> : <Clipboard2 />}
                    </div>
                    <div className='flex-grow-1 text-start '>{r.group}</div>
                </DoubleClick>
            )}
        </div>
    </div>)
}

import React, { useEffect } from 'react'
export default function WhatsAppWizardWebhook({ title, description, navigation, errors, setValue, values }) {

    useEffect(() => {
        title('Webhook')
        description('To receive WhatsApp messages from your service provider, update the following webhook URL on your service providers portal')
        navigation([
            {
                text: 'Back', type: 'Back', action: async () => { }
            },
            { text: 'Done', type: 'Done', action: async () => { } }
        ])
        // _loadSettings()
    }, [])

    var url = window.location.href
    var arr = url.split("/");
    var result = arr[0] + "//" + arr[2]

    return (
        <>
            <div
                className='d-flex flex-row justify-content-evenly align-items-evenly'>
                <b>{result}/api/messaging/wa/{values.configurationId}/messages</b>
            </div>
        </>
    )
}

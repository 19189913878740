import API from "../API";

var PatientHistory = {
    async GetPatientHistory(PatientID) {
        var result = await API.process(
            `api/patient/history/${PatientID}`,
            'GET',
            null
        ).catch((err) => {
            throw err;
        })

        return result
    },
    async GetPatientHistoryByUser(patientUserId) {
        var result = await API.process(
            `api/patient/history/user/${patientUserId}`,
            'GET',
            null
        ).catch((err) => {
            throw err;
        })

        return result
    },
    async AddPatientHistory(PatientID, History) {
        var result = await API.process(
            `api/patient/history/${PatientID}`,
            'POST',
            History
        ).catch((err) => {
            throw err;
        })

        return result
    },
    async AddNote(historyId, note, pvt, refKey) {
        var result = await API.process(
            `api/patient/history/note/${historyId}`,
            'POST',
            {
                note: note,
                isPrivate: pvt,
                referenceKeys: JSON.stringify(refKey)
            }
        ).catch((err) => {
            throw err;
        })

        return result
    },
    async ListNotes(historyId) {
        var result = await API.process(
            `api/patient/history/notes/${historyId}`,
            'GET',
            null
        ).catch((err) => {
            throw err;
        })

        return result
    },
    async RemoveNote(noteId) {
        var result = await API.process(
            `api/patient/history/note/${noteId}`,
            'DELETE',
            null
        ).catch((err) => {
            throw err;
        })

        return result
    },
    async AddDocument(historyId, file) {
        var result = await API.process(
            `api/patient/history/document/${historyId}`,
            'POST',
            {
                Data: file.base64,
                FileName: file.name,
                MimeType: file.mimeType
            }
        ).catch((err) => {
            throw err;
        })

        return result
    },
    async ListDocuments(historyId) {
        var result = await API.process(
            `api/patient/history/documents/${historyId}`,
            'GET',
            null
        ).catch((err) => {
            throw err;
        })

        return result
    },
    async RemoveDocument(docuemntId) {
        var result = await API.process(
            `api/patient/history/document/${docuemntId}`,
            'DELETE',
            null
        ).catch((err) => {
            throw err;
        })

        return result
    },
    async GetDocument(docuemntId) {
        var result = await API.process(
            `api/patient/history/document/${docuemntId}`,
            'GET',
            null
        ).catch((err) => {
            throw err;
        })

        return result
    },
    async GetEventDetails(eventId) {
        var result = await API.process(
            `api/patients/event/${eventId}/details`,
            'GET',
            null
        ).catch((err) => {
            throw err;
        })

        return result
    },
}

export default PatientHistory;
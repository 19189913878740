import React, { useEffect, useRef, useState } from 'react'
import { CheckSquare, Pencil, Square } from 'react-bootstrap-icons'
import QBuilderComponentOptions from './QBuilderComponentOptions'
import Questionnaire from '../../../../../libs/api/services/Questionnaire'
import QBuilderFieldLink from './QBuilderFieldLink'
import Patient from '../../../../../libs/api/services/Patient'
import Authentication from '../../../../../libs/api/services/Authentication'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowLeft, faArrowRight } from '@fortawesome/free-solid-svg-icons'
import QBuilderGenerics from '../QBuilderGenerics'

export default function QBuilderComponentTextInout({ cache, onChangeMode, onLoadComplete, onSetData, view, activeQuestionnaireAssignment, ComponentId, onAction, idx, edit, defaultMode, onIssue, validate, setValidate }) {
    const [mode, setMode] = useState(edit ? 'Edit Text' : '')
    // const [mode, setMode] = useState('Layout')
    const [details, setDetails] = useState({ text: '' })
    const [busy, setBusy] = useState({ text: '' })
    const [properties, setProperties] = useState([])
    const [loading, setLoading] = useState(true)
    const [responseValue, setResponseValue] = useState()
    const [issues, setIssues] = useState({})
    const [layout, setLayout] = useState({ width: '12', offset: 0 })

    const [linked, setLinked] = useState({})


    const [data, setData] = useState()
    const isMountedRef = useRef(false);

    const _load = async () => {
        try {
            if (!isMountedRef.current)
                if (activeQuestionnaireAssignment)
                    setData(await QBuilderGenerics.loadData(cache, ComponentId, activeQuestionnaireAssignment.id))
                else
                    setData(await QBuilderGenerics.loadData(cache, ComponentId,))
            onLoadComplete()
            isMountedRef.current = true;
        } catch (e) { }
    }

    useEffect(() => {
        // onLoad
        _load()
    }, [])

    useEffect(() => {
        // Rerender after data changed
        if (data && data.properties) {
            // Rerender after data changed
            setLoading(true)
            _loadProperties(true)
            if (data.response) _LoadResponse(true); else _checkLinkedFields(undefined);
            setLoading(false)
        }
    }, [data])

    useEffect(() => {
        onChangeMode(mode)
    }, [mode])

    useEffect(() => {
        if (validate === "Validate Next") {
            _validate()
            setValidate('Ok Next')
            // if (details.Required) {
            //     if (_validate())
            //         setValidate('Ok Next')
            //     else
            //         setValidate('Issue')
            // } else {
            //     // setValidate('Ok Next')
            // }
            // 
        }
        if (validate === "Validate Back") {
            setValidate('Ok Back')
        }
        //alert(validate)
    }, [validate])


    const init = (r) => {
        var _details = { ...details }

        try {
            _details.label = r.find(p => p.property === "Question").values
        } catch (e) { }
        try {
            _details.Required = JSON.parse(r.find(p => p.property === "Required").values)
        } catch (e) { }

        try {
            setLayout(JSON.parse(r.find(p => p.property === "Layout").values))
        } catch (e) { }
        setDetails({ ..._details })
        setProperties(r)
    }

    const _loadProperties = async (cache) => {
        init(data.properties)
        /* if (cache) {
             init(cache.properties)
             setLoading(false)
             onLoadComplete()
             if (!edit) {
                 _LoadResponse(cache, true)
 
             } else {
                 onLoadComplete()
             }
         } else {
             Questionnaire
                 .GetComponentProperties(ComponentId)
                 .then((r) => {
                     if (r)
                         init(r)
                     setLoading(false)
                     onLoadComplete()
                 }).catch((e) => {
                     onLoadComplete()
                 });
             if (!edit) {
                 _LoadResponse()
 
             } else {
                 onLoadComplete()
             }
         }*/
    }
    const _loadProperty = (prop) => {
        const ret = properties.find(p => p.property === prop)
        return ret ? ret.values : '';
    }
    const _setProperty = async (prop, value) => {
        return await Questionnaire
            .SetComponentProperty(ComponentId, {
                property: prop,
                values: value,
                component: {
                    id: ComponentId
                }
            })
    }

    const _handleSetRequired = () => {
        var _required = details.Required
        if (_required === null)
            _required = false
        var _details = { ...details, Required: !_required }
        _setProperty("Required", JSON.stringify(!_required))
        setDetails(_details)
        // var _required = properties.find(p => p.property === "Required");
        // if (!_required)
        //     _required = false;
        // _setProperty("Required", _required)

    }

    const _checkLinkedFields = (answer) => {
        if (!answer || answer === "")
            Questionnaire
                .GetLinkPatientProfile(ComponentId)
                .then((r) => {
                    setLinked(r)
                    if (view !== "New") {
                        Patient
                            .GetPatientProfileDetail(activeQuestionnaireAssignment.assignedTo.id, r.linkedValue.id)
                            .then((d) => {
                                setResponseValue(d.detail)
                                _handleSetValue(d.detail)
                            }).catch((e) => { })
                    }
                }).catch((e) => { })
    }

    /*useEffect(() => {
        if (cache)
            _loadProperties(cache);
        else
            _loadProperties()

    }, [])*/

    /*useEffect(() => {
        _loadProperties()
    }, [mode])*/

    const _handleSetFieldName = (t) => {
        _setProperty("Question", t)
        var _details = { ...details }
        _details.label = t
        setDetails(_details)
    }

    const _LoadResponse = (cache, cached) => {
        try {
            setResponseValue(data.response.answer)
            onSetData(data.response.answer)
        } catch (e) {
            // No Respones Data
        }
        /* if (!edit) {
             if (cached) {
 
                 setResponseValue(cache.response.answer)
                 onSetData(cache.response.answer)
             } else {
                 Questionnaire
                     .GetQuestionnaireResponse(activeQuestionnaireAssignment.id, ComponentId)
                     .then((r) => {
                         onSetData(r.answer)
                         setResponseValue(r.answer)
                         _checkLinkedFields(r.answer)
                     }).catch((e) => { _checkLinkedFields('') })
             }
         } else {
             onLoadComplete()
         }*/
    }
    const _handleSetValue = (v) => {
        onSetData(v, true)
        // if (!edit) {
        //     if (_validate(v))
        //         Questionnaire
        //             .QuestionnaireResponse(activeQuestionnaireAssignment.id, {
        //                 // questionnaire: {
        //                 //     id: QuestionnaireAssignmentId
        //                 // },
        //                 answerType: {
        //                     id: ComponentId
        //                 },
        //                 answer: v
        //             })
        //             .then((r) => {

        //             })
        // }
    }
    // TODO : REENABLE
    useEffect(() => {
        if (defaultMode && defaultMode === 'Overview')
            setMode('')
    }, [defaultMode])
    /* const _handleSaveLayout = () => {
         _setProperty("Layout", JSON.stringify(layout))
             .then((r) => {
                 var _details = { ...details }
                 _details.layout = layout
                 setDetails(_details)
                 setMode('')
                 setBusy(false); setTimeout(setBusy, 1000, true)
             }).catch((e) => { setBusy(false); setTimeout(setBusy, 1000, true) })
 
     }*/
    // OnClose Event
    const _handleSaveLayout = () => {
        setBusy(true)
        QBuilderGenerics.loadComponentProperties(ComponentId)
            .then((r) => {
                setData({ properties: r })
                setResponseValue(details.text)
                setMode('')
                setBusy(false); setTimeout(setBusy, 1000, true)
            })
    }

    const _handleSave = () => {
        setMode('')
    }

    const _validate = (value) => {
        // if (details.Required && (!value || value.trim() === "")) {
        //     onIssue({ issue: 'Field is Required' })
        //     setIssues({ issue: 'This field is required' })
        //     return false;
        // } else {
        //     onIssue({ issue: '' })
        //     setIssues({ issue: '' })
        // }
        return true;
    }

    const _render = () => {
        var _rent = []
        switch (mode) {
            case 'Edit Text':
                _rent = (
                    <div style={{ cursor: 'pointer', zIndex: 999 }} className='bg-light rounded text-start p-2 opacity-75'>
                        <div className='text-center'>
                            <div className='d-flex justify-content-between'>
                                <b>Enter a name for the field</b>
                            </div>
                            <input
                                onChange={(e) => _handleSetFieldName(e.target.value)}
                                defaultValue={_loadProperty("Question")}
                                className='form-control form-control-sm' placeholder='Field Name' />
                            <div className='d-flex justify-content-between'>
                                <div>
                                    <div>
                                        <b>Is this field required?</b>
                                    </div>
                                    <div onClick={(e) => _handleSetRequired()}>
                                        {
                                            details.Required
                                                ?
                                                <CheckSquare size={20} className='me-1' />
                                                :
                                                <Square size={20} className='me-1' />
                                        }
                                        Field is required
                                    </div>
                                </div>
                            </div>

                            {/* <button onClick={(e) => _handleSave()} className='btn btn-sm'>Save</button> */}
                        </div>
                    </div>
                )
                break;
            case 'Link':
                _rent = (
                    <QBuilderFieldLink ComponentId={ComponentId} />
                )
                break;
            default:
                break;
        }

        return (
            <>
                {_rent}
            </>
        );
    }

    if (loading) return <div></div>
    // if (mode !== '' && mode !== 'Layout')
    // return (
    //     <div style={{ cursor: 'pointer' }} className={'bg-light shadow rounded text-start p-2 position-fixed '.concat(' slide-in')}>
    //         <QBuilderComponentOptions onAction={(e) => onAction(e)} idx={idx} onClose={() => _handleSaveLayout()} />
    //         <div className='d-flex rounded bg-primary-container m-2'>
    //             <div className={'p-2 '.concat(mode === 'Edit Text' ? 'active' : '')} onClick={() => setMode('Edit Text')} >Field Name</div>
    //             <div className={'p-2 '.concat(mode === 'Link' ? 'active' : '')} onClick={() => setMode('Link')} >Link Field</div>
    //         </div>
    //         {_render()}
    //     </div>
    // )
    // else if (mode === 'Layout') {
    //     return (
    //         <div className={' w-100 '}>
    //             <div className='position-absolute bg-light p-2 shadow rounded w-100 opacity-75 mt-3 ' style={{ left: '0px', top: '120px' }}>
    //                 <div><b>Width</b> <FontAwesomeIcon onClick={() => setLayout({ ...layout, width: layout.width - 1 })} icon={faArrowLeft} /> {layout.width} / 12 <FontAwesomeIcon onClick={() => setLayout({ ...layout, width: layout.width + 1 })} icon={faArrowRight} /></div>
    //                 <div><b>Offset</b> <FontAwesomeIcon onClick={() => setLayout({ ...layout, offset: layout.offset - 1 })} icon={faArrowLeft} /> {layout.offset} / 12 <FontAwesomeIcon onClick={() => setLayout({ ...layout, offset: layout.offset + 1 })} icon={faArrowRight} /></div>
    //                 <button onClick={() => _handleSaveLayout()} className='btn btn-xs'>Save And Close</button>
    //             </div>
    //             <div>
    //                 <input
    //                     onBlur={(e) => _handleSetValue(e.target.value)}
    //                     onChange={(e) => _validate(e.target.value)}
    //                     defaultValue={responseValue ? responseValue : ''}
    //                     className='form-control form-control-sm' placeholder={loading ? '' : _loadProperty("Question")} />
    //             </div>
    //         </div>
    //     )
    // }
    // else
    return (
        <>
            {
                mode !== ''
                    ?
                    <div style={{ cursor: 'pointer' }} className={'bg-light shadow rounded text-start p-2 position-fixed '.concat(' slide-in')}>
                        <QBuilderComponentOptions busy={busy} onAction={(e) => onAction(e)} idx={idx} onClose={() => _handleSaveLayout()} />
                        <div className='d-flex rounded bg-primary-container m-2'>
                            <div className={'p-2 '.concat(mode === 'Edit Text' ? 'active' : '')} onClick={() => setMode('Edit Text')} >Field Name</div>
                            <div className={'p-2 '.concat(mode === 'Link' ? 'active' : '')} onClick={() => setMode('Link')} >Link Field</div>
                        </div>
                        {_render()}
                    </div>
                    :
                    <></>
            }
            <div className={'text-start '.concat(mode !== '' ? 'bg-primary-container opacity-75 shadow' : '')}>
                {
                    edit
                        ?
                        <Pencil className='me-2' onClick={(e) => setMode('Edit Text')} />
                        :
                        <></>
                }
                <>
                    {view && view === 'Discover'
                        ?
                        <label><b>{loading ? '' : _loadProperty("Question")}</b></label>
                        :
                        loading ? '' : <label>{_loadProperty("Question")}<b className='text-secondary'><small>{_loadProperty("Required") && JSON.parse(_loadProperty("Required")) ? "*" : ''}</small></b></label>
                    }
                    {
                        view === 'Discover'
                            ?
                            <div>{responseValue ? responseValue : ''}</div>
                            :
                            <input
                                onBlur={(e) => _handleSetValue(e.target.value)}
                                onChange={(e) => _validate(e.target.value)}
                                defaultValue={responseValue ? responseValue : ''}
                                className={'form-control form-control-sm '.concat(view === 'Form' ? 'form-view' : '')} style={view === 'Form' ? { backgroundColor: 'transparent' } : {}} placeholder={loading ? '' : (typeof _loadProperty("Question") !== 'undefined' && _loadProperty("Question").trim().slice(-1) === ':' ? _loadProperty("Question").trim().substring(0, _loadProperty("Question").trim().length - 1) : _loadProperty("Question"))} />
                    }
                    {
                        issues.issue
                            ?
                            <div className='text-secondary text-center bg-secondary-container text-light rounded p-1 shadow'>
                                <Pencil />{issues.issue}
                            </div>
                            :
                            <></>
                    }
                </>
            </div>
        </>)

}

import React, { useEffect, useState } from 'react'
import GeneralModal from '../../../../components/dialogs/GeneralModal'
import LoaderComponent from '../../../../components/loader/LoaderComponent'
import WorkFlow from '../../../../libs/api/services/WorkFlow'

const WorkFlowManagementContext = React.createContext()

export const WorkFlowManagementProvider = ({ id, event, children }) => {
    const [modal, setModal] = useState({ show: false, icon: undefined, title: undefined, component: undefined })
    const [evt, setEvt] = useState(event ? event : undefined)
    const [history, setHistory] = useState([])
    const [loading, setLoading] = useState(false)
    const load = () => {
        setLoading(true)
        WorkFlow
            .GetWorkFlowPatientEventHistory(id)
            .then((r) => {
                setLoading(false)
                setHistory(r)
            })
    }
    useEffect(() => {
        if (id) load()
    }, [id])

    return (
        <WorkFlowManagementContext.Provider value={{
            extendedModal: (m) => setModal(m),
            event: evt,
            history,
            load
        }}>
            {loading && <LoaderComponent />}
            <GeneralModal {...modal} onClose={() => setModal({ show: false })}>{modal.component}</GeneralModal>
            {children}
        </WorkFlowManagementContext.Provider>
    )
}
export default WorkFlowManagementContext
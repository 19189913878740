import React from 'react'
import { Bullseye } from 'react-bootstrap-icons'
import { FaSms, FaWhatsapp } from 'react-icons/fa'
import { MdEmail } from 'react-icons/md'

export default function MessagesPlatformIcon({ service }) {
    switch (service) {
        case 'SMS': return <FaSms />
        case 'WhatsApp': return <FaWhatsapp />
        case 'Internal Messaging Service': return <Bullseye />
        case 'Email': return <MdEmail />
        default: return <></>
    }
}

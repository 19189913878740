import React, { useEffect, useRef, useState } from 'react'
import { Download, Upload } from 'react-bootstrap-icons'
import Questionnaire from '../../../libs/api/services/Questionnaire'
import Patient from '../../../libs/api/services/Patient'
import Migration from '../../../libs/api/services/Migration'

export default function ImportExport() {
    const [mode, setMode] = useState()
    const [exported, setExported] = useState()
    const uploadRef = useRef()

    const _loadQS = async () => {

        /*  const qs = await Questionnaire
              .ListQuestionnaires()
  
          var res = []
          for (const questionaire of qs) {
              res.push(await Questionnaire.GetQuestionnaire(questionaire.id))
          }*/
        const res = await Migration
            .ExportQuestionnaires()

        setExported(res)

    }
    const _loadPPDfs = async () => {

        const res = await Migration
            .ExportPatientProfileDetails()

        setExported(res)

    }

    const _loadPP = async () => {

        const qs = await Migration
            .ExportProfiles()

        setExported(qs)

    }

    const _loadDL = async () => {

        const qs = await Migration
            .ExportDataLinks()

        setExported(qs)

    }
    const _handleSave = () => {
        const value = uploadRef.current.value.replaceAll('\n', '').replaceAll('\t', '')
        switch (mode) {
            case 'profile':
                Migration
                    .ImportPatientProfile(value)
                // _loadPP()
                break;
            case 'questionnaire':
                Migration
                    .ImportQuestionniares(value)
                // _loadQS()
                break;
            case 'profile_defaults':
                Migration
                    .ImportPatientProfileDetails(value)
                // _loadPPDfs()
                break;
            case 'data_link':
                Migration
                    .ImportDataLink(value)
                // _loadPPDfs()
                break;
            default:
        }
    }
    useEffect(() => {
        switch (mode) {
            case 'profile':
                _loadPP()
                break;
            case 'questionnaire':
                _loadQS()
                break;
            case 'profile_defaults':
                _loadPPDfs()
                break;
            case 'data_link':
                _loadDL()
                break;
            default:
        }
    }, [mode])

    const _renderIO = () => {
        return <div style={{ overflow: 'auto' }}>
            <div><Download />{exported ? <textarea className='form-control' rows={20}>{JSON.stringify(exported, null, '\t')}</textarea> : ''}</div>
            <div><Upload /><textarea ref={uploadRef} className='form-control'></textarea></div>
            <div><button onClick={() => _handleSave()} className='btn'>SAVE</button></div>
        </div>
    }

    switch (mode) {
        case 'profile':
            return _renderIO()
        case 'questionnaire':
            return _renderIO()
        case 'profile_defaults':
            return _renderIO()
        case 'data_link':
            return _renderIO()
        default:
            return <div className='bg-primary-container m-3'>
                <div className='p-2' style={{ cursor: 'pointer' }} onClick={() => setMode('profile')}>Patient Profile Layout</div>
                <div className='p-2' style={{ cursor: 'pointer' }} onClick={() => setMode('profile_defaults')}>Patient Profile Defaults</div>
                <div className='p-2' style={{ cursor: 'pointer' }} onClick={() => setMode('questionnaire')}>Questionnaires</div>
                <div className='p-2' style={{ cursor: 'pointer' }} onClick={() => setMode('data_link')}>Data Links</div>
            </div>
    }
}

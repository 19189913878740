import React, { useState } from 'react'
import { Check2All, Check2Square, ClipboardPulse, Clock, ClockHistory, DashSquare, InfoCircleFill, Person, PersonAdd, PersonCircle, Square } from 'react-bootstrap-icons'
import WorkFlowLogs from './Logs/WorkFlowLogs'

export default function WorkFlowTask({ eventId, task, onTaskSelected }) {
    const [showLogs, setShowLogs] = useState(false)
    return (
        <div
            style={{ cursor: 'pointer' }}
            className='align-self-start d-flex w-100 text-start'
            key={task.id}>
            <div
                onClick={(e) => onTaskSelected(task.id)}>
                {
                    task.triggerHistory && task.triggerHistory.length > 0 && task.triggerHistory[task.triggerHistory.length - 1].completedDate
                        ?
                        <Check2Square className='me-2' size={30} />
                        :
                        task.triggerHistory && task.triggerHistory.length > 0 && !task.triggerHistory[task.triggerHistory.length - 1].completedDate
                            ?
                            <DashSquare style={{ marginLeft: '3px' }} className='me-2' size={30} />

                            :
                            <Square className='me-2' style={{ marginLeft: '3px' }} size={25} />
                }
            </div>
            <div>
                <b
                    onClick={(e) => onTaskSelected(task.id)}>{task.taskName}</b>
                {
                    task.triggerHistory && task.triggerHistory.length > 0
                        ?
                        <div className='w-100'>
                            <small onClick={(e) => { setShowLogs(!showLogs) }
                                // _handleRunTask(evt.id)
                            }  >
                                <InfoCircleFill />
                                <Person />{task.triggerHistory[task.triggerHistory.length - 1].triggeredBy ? task.triggerHistory[task.triggerHistory.length - 1].triggeredBy.firstName + ' ' + task.triggerHistory[task.triggerHistory.length - 1].triggeredBy.lastName + ' ran this task on ' : 'Task ran on '}
                                {task.triggerHistory[task.triggerHistory.length - 1].triggeredDate.substring(0, 19)
                                }
                            </small>
                            <div className={'d-flex flex-column justify-content-center bg-secondary-container w-100 p-2 rounded '.concat(showLogs ? '' : 'd-none')}>
                                {
                                    task.triggerHistory && task.triggerHistory.length > 0 ?
                                        task.triggerHistory.map((ev) => {
                                            return <div className={'w-100 small '}>
                                                {ev.triggeredDate ? <div><Clock className='me-2' />Started : {ev.triggeredDate.substring(0, 19)}</div> : ''}
                                                {ev.completedDate ? <div><ClockHistory className='me-2' />Completed : {ev.completedDate.substring(0, 19)}</div> : ''}
                                                {ev.triggeredBy ? <div><PersonAdd className='me-2' />Triggered By : {ev.triggeredBy.firstName + ' ' + ev.triggeredBy.lastName}</div> : ''}
                                                {ev.triggeredFor ? <div><PersonCircle className='me-2' />For : {ev.triggeredFor.firstName + ' ' + ev.triggeredFor.lastName}</div> : ''}
                                                {
                                                    ev.logs ?
                                                        <div className='bg-light text-dark p-3 rounded mt-1 w-100'>
                                                            <span><ClipboardPulse /> <b>Logs</b></span>
                                                            <WorkFlowLogs logs={ev.logs} />
                                                        </div>
                                                        :
                                                        <></>
                                                }
                                            </div>
                                        })
                                        :
                                        <></>
                                    //_checkLogs(task.triggerHistory[0], evt.id)
                                }
                            </div>
                        </div>
                        :
                        <div onClick={(e) => onTaskSelected(task.id)} className=''>
                            <small className=''><Check2All /> This task has not yet been run</small>
                        </div>
                }
            </div>
        </div>

    )
}

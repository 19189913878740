import React from 'react'
import RunTaskSectionIssuesComponent from './RunTaskSectionIssuesComponent'
import RunTaskSectionOptionComponent from './RunTaskSectionOptionComponent'
import RunTaskSectionTextComponent from './RunTaskSectionTextComponent'

export default function RunTaskSection({ section, show, index, length, onComplete, setValid, setParameters }) {
    if (!show) return <></>
    return (
        <>
            <div className={' flex-shrink-1 w-100 '.concat(show ? '' : 'd-none')}>
                {section.title ? <h3>{section.title}</h3> : <></>}
                {section.description ? <p>{section.description}</p> : <></>}
            </div>
            <div className={' flex-grow-1 w-100 '.concat(show ? '' : 'd-none')} style={{ overflow: 'auto' }}>

                {
                    section.layout && section.layout.length > 0
                        ?
                        section.layout.map((l, idx) => {
                            if (l.issues)
                                return <RunTaskSectionIssuesComponent layout={l} />
                            else
                                switch (l.type) {
                                    case 'text': return <RunTaskSectionTextComponent onValidate={(r) => setValid(idx, r)} layout={l} />
                                    case 'options':
                                    case 'option': return <RunTaskSectionOptionComponent setParameters={setParameters} onValidate={(r) => setValid(idx, r)} layout={l} />
                                    default: return <></>
                                }
                        })
                        :
                        <div></div>
                }

            </div>
        </>
    )
}

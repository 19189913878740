import React, { useEffect } from 'react'
import { useState } from 'react'
import { ChromePicker, Color, ColorResult } from 'react-color'

type Props = {
  name: string
  onChange: (name: string, hex: any) => void
  defaultValue: string
  //   onClick: (name: string) => void
}

export default function ColourComponent({ name, onChange, defaultValue }: Props) {
  const [color, setColor] = useState<Color>(defaultValue)

  const _handleChangeColor = (c: ColorResult) => {
    // w/o btn
    onChange(name, c.hex)
    // -- end w/o button
    setColor(c.hex)
  }

  useEffect(() => {
    onChange(name, defaultValue)
  }, [defaultValue])

  return (
    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
      <ChromePicker color={color} onChangeComplete={(c) => _handleChangeColor(c)} disableAlpha={true} />
      {/* <button className='btn mt-2' style={{ backgroundColor: color.toString() }} onClick={() => onChange(name, color)}>
        Change
      </button> */}
    </div>
  )
}

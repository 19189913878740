import { faSpinner } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useEffect, useState } from 'react'
import FlowAssistant from '../../../../../../../libs/api/services/FlowAssistant'
import MessageTemple from '../../../../../../../libs/api/services/MessageTemple'
import PatientHistoryEvent from '../../../../../../../libs/api/services/PatientHistoryEvent'
import TaskEvents from '../../../../../../../libs/api/services/TaskEvents'
import WorkFlow from '../../../../../../../libs/api/services/WorkFlow'
import WorkflowWizard from '../../../../../workflow/configuration/facility/wizard/WorkflowWizard'

export default function MessageTemplateWizardDone({ title, description, navigation, navigator, errors, setStatus, setValue, step, values, history }) {
    const [categories, setCategories] = useState([])
    const [linked, setLinked] = useState([])
    const [loading, setLoading] = useState(true)

    const _checkHistoryRedirect = async () => {
        // var history = FlowAssistant.GetLast()
        setLoading(true)
        if (history && Object.keys(history).includes('section') && (history.section === 'NEW_EVENT_ADD_EVENT' || history.section === 'NEW_EVENT_WORKFLOW_ADD_EVENT')) {
            title('')
            description('')
            navigation([])
            // Link and redirect

            WorkFlow
                .SetWorkFlowEventTaskProperty(values.event.id, 'Message Template', {
                    id: values.task.id,
                    taskName: 'Send Message Template : ' + values.templateName,
                    taskType: {
                        type: 'Messaging'
                    },
                    properties: [{
                        value: JSON.stringify(values.templateId)
                    }]
                })
                .then((r) => {
                    return FlowAssistant.Goto(history.section)
                }).catch((e) => { if (e.errors) errors(e.errors); else errors(e.message); })

            // const task = await TaskEvents.SetTaskEvent(values.event.id, {
            //     taskName: 'Send ' + values.templateName,
            //     taskType: {
            //         type: 'Messaging'
            //     }
            // }).catch((e) => { if (e.errors) errors(e.errors); else errors(e.message); })
            // if (task) {
            //     const prop = await MessageTemple.SetTaskProperty(task.id, values.templateId, "Message Template").catch((e) => { if (e.errors) errors(e.errors); else errors(e.message); })
            //     return FlowAssistant.Goto(history.section)
            // }
        } else {
            setTimeout(setLoading, 700, false)
        }
    }
    useEffect(() => {
        title('Next Steps')
        description('')
        // Load Navigation if session started

        navigation([
            { text: 'Back', type: 'Back', action: async () => { } },
            {
                text: 'Done', type: 'Step', step: 1, action: async () => { }
            }
        ])
        loadCategories()
        _checkHistoryRedirect()
    }, [])

    const loadCategories = async () => {
        const evnts = await PatientHistoryEvent.ListAllCategoriesEvents().catch((e) => { if (e.errors) errors(e.errors); else errors(e.message); })
        const _linked = await PatientHistoryEvent.ListAllCategoriesEventsLinked(values.templateId).catch((e) => { if (e.errors) errors(e.errors); else errors(e.message); })
        //     // alert(JSON.stringify(_linked))

        //     // for (var i = 0; i < evnts.length; i++) {
        //     //     const _links = await PatientHistoryEvent.ListCategoryEventLinksGrouped(evnts[i].id).catch((e) => { if (e.errors) errors(e.errors); else errors(e.message); })
        //     //     alert(JSON.stringify(_links))
        //     //     // _linked = [..._linked, ..._links]

        //     //     // if (link.id === event.id)
        //     //     // return true
        //     // }
        //     // .then((r) => {
        setCategories(evnts)
        setLinked(_linked)
        //     // })
    }



    const _hanldeIsLinked = (event) => {
        if (event && event.id) {
            for (var _link = 0; _link < linked.length; _link++) {
                for (var _linkedEvnts = 0; _linkedEvnts < linked[_link].events.length; _linkedEvnts++) {
                    if (linked[_link].events[_linkedEvnts] && linked[_link].events[_linkedEvnts].id === event.id)
                        return true
                }
            }
        }
        return false
    }


    const _handleSelectEvent = async (cat, ev) => {
        // set 
        // reload

        if (_hanldeIsLinked({ id: ev })) {
            const removed = await TaskEvents.RemoveAllTasks(values.templateId).catch((e) => { if (e.errors) errors(e.errors); else errors(e.message); });
            loadCategories()
            return;
        }

        try {
            const removed = await TaskEvents.RemoveAllTasks(values.templateId).catch((e) => { if (e.errors) errors(e.errors); else errors(e.message); });

            const task = await TaskEvents.SetTaskEvent(ev, {
                taskName: 'Send ' + values.templateName,
                taskType: {
                    type: 'Messaging'
                }
            }).catch((e) => { if (e.errors) errors(e.errors); else errors(e.message); })
            if (task) {
                const prop = await MessageTemple.SetTaskProperty(task.id, values.templateId, "Message Template").catch((e) => { if (e.errors) errors(e.errors); else errors(e.message); })
                loadCategories()
            }
        } catch (e) {
            if (e.errors) errors(e.errors); else errors(e.message)
        }
    }

    const _handleAttachrEvent = async (events) => {
        if (!events)
            events = linked
    }

    if (loading)
        return <FontAwesomeIcon icon={faSpinner} spin />
    return (
        <div>
            {/* <div className='m-3'>Now that the message template has been created, it needs to be sent out</div>
            <div className='m-3'>To send out the message, select the event you would like to trigger the message on</div> */}
            <div className='row'>
                <WorkflowWizard subsection={{
                    title: title,
                    values: values,
                    description: description,
                    setValues: setValue,
                    navigation: (n) => navigation([{ text: 'Back', type: 'Back', action: async () => { } }, ...n.filter(r => r.type !== 'Back')]),
                    setErrors: errors,
                    step: step - 4
                }} />
                {/* {
                    categories.map((cat) => {
                        return (<div className='col-4 bg-primary-container p-3 rounded '>
                            <b>{cat.category}</b>
                            <div className='bg-primary-container p-1 text-start' style={{ cursor: 'pointer' }}>
                                {
                                    cat.events.map((e) => {
                                        return (<div
                                            //    className={'p-2 '.concat(event && event === e.id ? 'active' : '')}
                                            className={'p-2 '.concat(_hanldeIsLinked(e) ? 'active' : '')}
                                            onClick={() => _handleSelectEvent(cat.id, e.id)}
                                        >
                                            {
                                                _hanldeIsLinked(e)
                                                    ?
                                                    <Check2Circle className='me-2' />
                                                    :
                                                    <Circle className='me-2' />
                                            }
                                            {e.event}
                                        </div>)
                                    })
                                }
                                <NewEventLineComponent categoryId={cat.id}
                                    onCreated={(r) => _handleSelectEvent(cat.id, r)}
                                />
                            </div>
                        </div>)
                    })
                } */}
                {/* <div className='col-3 bg-primary-container p-3 rounded m-1'>
                    <NewCategoryLineComponent
                        onCreated={(r) => loadCategories()}
                    />
                </div> */}
            </div>
            {/* <div className='text-start'>
                <div onClick={(e) => navigator.goto(6)} className='bg-secondary-container rounded m-3 p-2' style={{cursor:'pointer'}}>
                    <Link className='me-2' size={50} />
                    <b>Link this message template to an event</b>
                    <p>In order for a message template to be sent out, it needs to be triggered by an event. Events can be setup under the patient configuration screen (<b>System Administration &gt; Patient &gt; Patient Events</b>) or by clicking this option </p>
                </div>
            </div> */}
        </div>
    )
}

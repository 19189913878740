import React, { useEffect, useState } from 'react'
import { PeopleFill, Person } from 'react-bootstrap-icons'
import MessageTemple from '../../../../../../../libs/api/services/MessageTemple'
import AudienceGroupComponent from '../../UserSelection/AudienceGroupComponent'
import AudiencePatientProfileComponent from '../../UserSelection/AudiencePatientProfileComponent.'
export default function MessageTemplateWizardAudience({ title, description, navigation, errors, setValue, values }) {

    const [audienceTypes, setAudienceTypes] = useState([])
    const [audienceType, setAudienceType] = useState()
    const [configuration, setConfiguration] = useState()
    useEffect(() => {
        title('Messaging Audience')
        description('Select the reciepts for this messages')
        // Load Navigation if session started
        if (Object.keys(values).includes('templateId'))
            navigation([
                { text: 'Back', type: 'Back', action: async () => { } },
                // { text: 'Next', type: 'Next', action: async () => { } },
                // { text: 'Next', type: 'Next', action: async () => {  } },
            ])
    }, [])

    const _handleSetAudienceType = (type) => {
        MessageTemple
            .AddAudience(values.templateId, type)
            .then((r) => {
                setAudienceType(type)
                navigation([
                    { text: 'Back', type: 'Back', action: async () => { } },
                    { text: 'Next', type: 'Next', action: async () => { } },
                ])
            }).catch((e) => { if (e.errors) errors(JSON.stringify(e.errors)); else errors(e.message); })


    }

    useEffect(() => {
        MessageTemple
            .ListAudienceTypes()
            .then((r) => {
                setAudienceTypes(r)
            }).catch((e) => { if (e.errors) errors(JSON.stringify(e.errors)); else errors(e.message); })
        MessageTemple
            .GetAudience(values.templateId)
            .then((r) => {
                setAudienceType(r.audienceType.id)
                setConfiguration(r)
                navigation([
                    { text: 'Back', type: 'Back', action: async () => { } },
                    { text: 'Next', type: 'Next', action: async () => { } },
                    // { text: 'Next', type: 'Next', action: async () => {  } },
                ])
            }).catch((e) => { })
    }, [])

    const icon = (icon) => {
        switch (icon) {
            case 'User':
                return <Person size={52} />
            case 'Users':
                return <PeopleFill size={52} />
            default:
                return <Person size={52} />
        }
    }

    const _renderCards = () => {
        var cards = []
        var row = []
        var cnt = 1
        for (var aud of audienceTypes) {
            const t = { ...aud }
            if (cnt++ > 4) {
                cnt = 1
                cards.push(<div
                    style={{ cursor: 'pointer' }}
                    className='d-flex flex-row justify-content-evenly align-items-evenly'>{row}</div>)
                row = []
            }
            row.push(
                <div
                    onClick={(e) => _handleSetAudienceType(t.id)}
                    className={'w-100 m-3 p-2 rounded '.concat(audienceType === t.id ? 'bg-primary-container shadow ' : 'bg-secondary-container')}>
                    <div>
                        {icon(t.icon)}
                    </div>
                    <div>
                        <h3>{t.type}</h3>
                    </div>
                    <div>
                        <p>{t.description}</p>
                    </div>
                </div>
            )
        }
        if (row.length > 0)
            cards.push(<div
                style={{ cursor: 'pointer' }}
                className='d-flex flex-row justify-content-evenly align-items-evenly'>{row}</div>)
        return cards
    }

    const _handleSelectAudienceGroup = (audienceGroupId) => {
        MessageTemple
            .SetAudienceGroup(values.templateId, audienceGroupId)
            .then((r) => {
                // setAudienceType(r.audienceType.id)
                // navigation([
                //     { text: 'Back', type: 'Back', action: async () => { } },
                //     { text: 'Next', type: 'Next', action: async () => { } },
                //     // { text: 'Next', type: 'Next', action: async () => {  } },
                // ])
            }).catch((e) => { })
    }
    const _handleSelectAudiencePatientProfileField = async (audienceGroupId) => {
        return await MessageTemple
            .SetAudiencePatientProfileField(values.templateId, audienceGroupId)
            .catch((e) => { if (e.errors) errors(e.errors); else errors(e.message) })
        // .then((r) => {
        //     // setAudienceType(r.audienceType.id)
        //     // navigation([
        //     //     { text: 'Back', type: 'Back', action: async () => { } },
        //     //     { text: 'Next', type: 'Next', action: async () => { } },
        //     //     // { text: 'Next', type: 'Next', action: async () => {  } },
        //     // ])
        // }).catch((e) => { })
    }

    const _renderProperties = () => {
        if (audienceTypes && audienceType)
            switch (audienceTypes.find(i => i.id === audienceType).type) {
                case 'Group':
                    return <div>
                        <AudienceGroupComponent
                            defaultValue={configuration && Object.keys(configuration).includes('audienceGroups') ? configuration.audienceGroups : ''}
                            onAudienceSelected={(u) => _handleSelectAudienceGroup(u)}
                            errors={(e) => errors(e)} />
                    </div>
                case 'Patient Profile Detail':
                    return <div>
                        <AudiencePatientProfileComponent
                            defaultValue={configuration && Object.keys(configuration).includes('audiencePatientProfileField') ? configuration.audiencePatientProfileField : ''}
                            onAudienceSelected={(u) => _handleSelectAudiencePatientProfileField(u)}
                            errors={(e) => errors(e)} />
                    </div>
                default:
                    return <></>
            }
    }

    return (
        <>
            <div className='row'>
                {_renderCards()}
            </div>
            <div className='row'>
                {_renderProperties()}
            </div>

            {/* <div
                style={{ cursor: 'pointer' }}
                className='d-flex flex-row justify-content-evenly align-items-evenly'>
                <div
                    onClick={(e) => _handleSetAudienceType('SingleUser')}
                    className={'w-100 m-3 p-2 rounded '.concat(audienceType === 'SingleUser' ? 'bg-primary-container shadow ' : 'bg-secondary-container')}>
                    <div>
                        <Person size={52} />
                    </div>
                    <div>
                        <h3>Patient</h3>
                    </div>
                    <div>
                        <p>Message will be sent to the patient</p>
                    </div>
                </div>
                <div
                    onClick={(e) => _handleSetAudienceType('Facility')}
                    className={'w-100 m-3 p-2 rounded '.concat(audienceType === 'Facility' ? 'bg-primary-container shadow ' : 'bg-secondary-container')}
                >
                    <div>
                        <People size={52} />
                    </div>
                    <div>
                        <h3>Facility</h3>
                    </div>
                    <div>
                        <p>Message will be sent to all Administrators / Clinicians linked to patients facilities</p>
                    </div>
                </div>
                <div
                    onClick={(e) => _handleSetAudienceType('Facility-Clinician')}
                    className={'w-100 m-3 p-2 rounded '.concat(audienceType === 'Facility-Clinician' ? 'bg-primary-container shadow ' : 'bg-secondary-container')}
                >
                    <div>
                        <People size={52} />
                    </div>
                    <div>
                        <h3>Clinicians</h3>
                    </div>
                    <div>
                        <p>Message will be sent to all patients clinicians</p>
                    </div>
                </div>
                <div
                    onClick={(e) => _handleSetAudienceType('facility-admin')}
                    className={'w-100 m-3 p-2 rounded '.concat(audienceType === 'facility-admin' ? 'bg-primary-container shadow ' : 'bg-secondary-container')}
                >
                    <div>
                        <People size={52} />
                    </div>
                    <div>
                        <h3>Administrators</h3>
                    </div>
                    <div>
                        <p>Message will be sent to all administrators linked to patients facilities</p>
                    </div>
                </div>
            </div>
            <div
                style={{ cursor: 'pointer' }}
                className='d-flex flex-row justify-content-evenly align-items-evenly'>
                <div
                    onClick={(e) => _handleSetAudienceType('AudienceGroup')}
                    className={'w-100 m-3 p-2 rounded '.concat(audienceType === 'AudienceGroup' ? 'bg-primary-container shadow ' : 'bg-secondary-container')}
                >
                    <div>
                        <Clipboard2Heart size={52} />
                    </div>
                    <div>
                        <h3>Patient Profile Details</h3>
                    </div>
                    <div>
                        <p>Messages can be sent using details from a patient profile</p>
                    </div>
                </div>
                <div
                    onClick={(e) => _handleSetAudienceType('Global')}
                    className={'w-100 m-3 p-2 rounded '.concat(audienceType === 'Global' ? 'bg-primary-container shadow ' : 'bg-secondary-container')}
                >
                    <div>
                        <Clipboard size={52} />
                    </div>
                    <div>
                        <h3>Audience Group</h3>
                    </div>
                    <div>
                        <p>Messages can be sent to a predefined list</p>
                    </div>
                </div>
            </div>
            <div className='m-3'>
                {/* {
                    !loaded
                        ?
                        <></>
                        :
                        audienceType === 'SingleUser'
                            ?
                            <div>
                                <b>Select the user that will be sending messages with this configuration</b>
                                <UserSelectionComponent
                                    defaultValue={configuration && Object.keys(configuration).includes('audienceUser') ? configuration.audienceUser.firstName + ' ' + configuration.audienceUser.lastName : ''}
                                    key={new Date()}
                                    onUserSelected={(u) => _handleSelectUser(u)} />
                            </div>
                            :
                            audienceType === 'AudienceGroup'
                                ?
                                <div>
                                    <AudienceGroupComponent
                                        defaultValue={configuration && Object.keys(configuration).includes('audienceGroups') ? configuration.audienceGroups : ''}
                                        onAudienceSelected={(u) => _handleSelectAudience(u)}
                                        errors={(e) => errors(e)} />
                                </div>
                                :
                                audienceType === 'Facility'
                                    ?
                                    <div>
                                        <b>Select the facility that will be sending messages with this configuration</b>
                                        <FacilitySelectionComponent
                                            defaultValue={configuration && Object.keys(configuration).includes('audienceFacility') ? configuration.audienceFacility.facility : ''}
                                            onFacilitySelected={(i) => _handleSelecFacility(i)} />
                                    </div>
                                    :
                                    <></>
                } 
            </div> */}
        </>
    )
}

import { faExclamationCircle, faTimesCircle } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useEffect, useState } from 'react'

import Authentication from '../../libs/api/services/Authentication'
import './Dialogs.css'

export default function ErrorDialog({ error, onClose, humanize }) {
    const [show, setShow] = useState(false)
    const [cachedError, setCachedError] = useState(error)

    const _handlerClearError = () => {
        setShow(false)
        if (onClose) onClose()
    }

    useEffect(() => {
        if (error !== '' && typeof error !== "undefined") {
            setShow(true)
            setCachedError(humanize ? _humanize(error) : error)
        } else {

            setShow(false)
        }
    }, [error, humanize])
    
    const _humanize = (e) => { return _translate(e) }

    const _exceptionToHuman = (_exception, _message) => {
        switch (_exception) {
            case 'DrData.Services.Messaging.WhatsApp.Exceptions.InvalidWhatsAppAccountDetailsException':
                return <div>
                    <div>
                        Unable to send messages due to your WhatsApp account configuration being invalid or incomplete
                    </div>
                    {
                        Authentication.isClaimValid("System Admin")
                            ?
                            <button className='btn btn-sm' onClick={(e) => window.location = '/sys-admin/messaging/wa'}>Go to WhatsApp Configuration</button>
                            :
                            <div className='pt-2'>Please contact your system administrator for assistance</div>
                    }
                </div>
            case 'DrData.Services.Messaging.SMS.Exceptions.InvalidSMSAppAccountDetailsException':
            case 'There are no SMS accounts available to send this message':
                return <div>
                    <div>
                        Unable to send messages due to your SMS account configuration being invalid or incomplete
                    </div>
                    {
                        Authentication.isClaimValid("System Admin")
                            ?
                            <button className='btn btn-sm' onClick={(e) => window.location = '/sys-admin/messaging/sms'}>Go to SMS Configuration</button>
                            :
                            <div className='pt-2'>Please contact your system administrator for assistance</div>
                    }
                </div>
            case 'DrData.Services.Messaging.Email.Exceptions.InvalidEmailAccountDetailsException':
                return <div>
                    <div>
                        Unable to send messages due to your Email account configuration being invalid or incomplete
                    </div>
                    {
                        <button className='btn btn-sm' onClick={(e) => window.location = Authentication.getFacilityRole() + '/Configuration/email'}>Go to your Email Configuration</button>
                    }
                </div>
            case 'System.NotImplementedException':
                return <div>
                    <div>
                        This module has not been installed / activated.
                    </div>
                </div>
            default:
                return <div className='text-start' dangerouslySetInnerHTML={{ __html: _message }} />
        }
    }

    const lastTry = (s) => {
        if (s === ':A non-empty request body is required.,user:The user field is required.') {
            s = 'Please complete all fields'
        } else if (s.includes('$.calendar.id')) {
            s = 'Please select a calendar'
        } else if (s.includes('$.startDateTime')) {
            s = 'Please specify a start date / time'
        }
        return s
    }

    const _translate = (text) => {
        var _message = ''
        var _exception = ''
        if (text instanceof Object) {
            _exception = text.type
            _message = text.message
            if (text.errors) {
                //_message = '<pre>' + JSON.stringify(text.errors, null, '\t').replace('{','').replace('}','').replaceAll('[','').replaceAll(']','').replaceAll('"','') + '</pre>'
                _message = '<pre>' + lastTry(JSON.stringify(text.errors).replace('{', '').replace('}', '').replaceAll('[', '').replaceAll(']', '').replaceAll('"', '')) + '</pre>'
            }
            return _exceptionToHuman(_exception, _message)
        }
        return _exceptionToHuman(text, text)
    }
    return (
        <div className={'error-container '.concat(!show ? 'close' : 'show')}>
            <div className='error-details'>
                <FontAwesomeIcon className='me-2' icon={faExclamationCircle} size={'3x'} /><div className='error-message'>{cachedError}</div>
                <div onClick={(e) => _handlerClearError()} className='close-dialog'>
                    <FontAwesomeIcon icon={faTimesCircle} size={'2x'} />
                </div>
            </div>
        </div>

    )
}

import { Handle, Position } from '@xyflow/react';
import React, { memo } from 'react';
import { People, Person } from 'react-bootstrap-icons';

export default memo(({ data, isConnectable }) => {
    return (
        <>
            <Handle
                type="target"
                position={Position.Top}
                style={{ background: '#555' }}
                onConnect={(params) => console.log('handle onConnect', params)}
                isConnectable={isConnectable}
            />
            <div
                style={{ width: '150px', maxHeight: '300px', background: 'rgba(255,255,255,1)', textAlign: 'center', border: 'solid black 1px', borderRadius: '3px' }}
            >
                <div style={{ position: 'absolute', top: '0px', left: '2px' }}>
                    {data.multi ? <Person /> : <People />}
                </div>
                <small style={{ fontSize: '7pt', padding: '10px', lineHeight: '1pt' }}>{data.label}</small>
            </div>
            <Handle
                type="source"
                position={Position.Bottom}
                id="a"
                style={{ background: '#555' }}
                isConnectable={isConnectable}
            />
        </>
    );
});

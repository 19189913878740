import React, { useEffect, useState } from 'react';
import { Folder, Folder2Open, FolderCheck, FolderPlus, FolderSymlink, FolderX, XCircleFill } from 'react-bootstrap-icons';
import InputWithKeyFunctions from '../../../../../../components/inputs/InputWithKeyFunctions';
import './Folders.css';
import useLongPress from './useLongPress';

export default function FolderComponent({
    onCreate,
    onRename,
    onDelete,
    onSelected,
    onSelect,
    res, className, active }) {
    const onLongPress = useLongPress();
    const [showContext, setShowContext] = useState(false)
    const [showEditName, setShowEditName] = useState(false)

    useEffect(() => { if (!active) { setShowContext(false); setShowEditName(false) } }, [active])

    const _handleClickFolder = (res) => {
        if (res.isNew) {
            if (res.asText === 'undefined' || res.asText === '') {
                setShowEditName(true)
            } else {
                // Just add
                onCreate(res, res.asText)
            }
        } else {
            onSelect(res)
        }
        onSelected(res)
    }

    const _handleContextClickFolder = (e, res) => {
        onSelected(res)
        if (e)
            e.preventDefault()
        setShowContext(true)
    }

    const _handleDelete = () => { setShowContext(false); if (onDelete) onDelete(res) }
    const _handleRename = () => {
        setShowEditName(true);
        setShowContext(false)
    }
    const _handleUse = () => { setShowContext(false); if (onSelect) onSelect(res) }
    const _handleEditValue = (v, m) => {
        setShowEditName(false);
        if (m) {
            if (onCreate) onCreate(res, v)
        } else { if (onRename) onRename(res, v) }
    }

    return (
        <div className={'p-2 folder-container position-relative '.concat(className)}>
            <div
                onClick={(e) => { e.stopPropagation(); _handleClickFolder(res) }}
                {...onLongPress((e) => _handleContextClickFolder(e, res))}
                onContextMenu={(e) => _handleContextClickFolder(e, res)}
                className={'col-3 folder '.concat(active ? 'active' : ' ')}>
                <div>{res.isNew ? <FolderPlus size={80} /> : active ? <Folder2Open size={80} /> : <Folder size={80} />}</div>
                <InputWithKeyFunctions
                    onCancel={() => setShowEditName(false)}
                    onSubmit={(v) => _handleEditValue(v, res.isNew)}
                    defaultValue={res.asText}
                    selected={showEditName}
                    className={'form-control form-control-sm '.concat(!showEditName ? 'd-none' : '')} placeholder="Name" />
                <div className={showEditName ? 'd-none' : ''}>{res.result}</div>
            </div>
            {
                res.isNew
                    ?
                    <></>
                    :
                    <div
                        style={{ top: '100%', zIndex: 99 }}
                        className={'position-absolute folder-context bg-light shadow rounded '.concat(showContext ? '' : 'd-none')}>
                        <div onClick={(e) => { e.stopPropagation(); _handleUse(false) }} className='py-2'><FolderCheck className='me-1' />Use</div>
                        <div onClick={(e) => { e.stopPropagation(); _handleRename(false) }} className='py-2'><FolderSymlink className='me-1' />Rename</div>
                        <div onClick={(e) => { e.stopPropagation(); _handleDelete(false) }} className='py-2'><FolderX className='me-1' />Delete</div>
                        <div onClick={(e) => { e.stopPropagation(); setShowContext(false) }} className='py-2'><XCircleFill className='me-1' />Close</div>
                    </div>
            }

        </div>
    )
}

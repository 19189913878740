import Authentication from "../../../../../../../../libs/api/services/Authentication"
import WorkFlow from "../../../../../../../../libs/api/services/WorkFlow"

export default class WFCategoryProviderFunctions {
    static loadCategories = async () =>
        await WorkFlow.ListGroups(Authentication.getFacility())

    //const _handleAdd = async (res, v) => { var r = await WorkFlow.UpdateGroup({ name: v, facility: Authentication.getFacility() ? { id: Authentication.getFacility() } : undefined }).catch((e) => setError(e)); loadCategories(); return r; }
    static createCategory = async (v) =>
        await WorkFlow.UpdateGroup({ name: v, facility: Authentication.getFacility() ? { id: Authentication.getFacility() } : undefined })

    // const _handleDelete = (res) => { WorkFlow.RemoveGroup(res.object.id).then((r) => { loadCategories() }).catch((e) => setError(e)) }
    static removeCategory = async (res) =>
        await WorkFlow.RemoveGroup(res.object.id)

    // const _handleRename = (res, v) => { WorkFlow.UpdateGroup({ id: res.object.id, name: v }).then((r) => { loadCategories() }).catch((e) => setError(e)) }
    static renameCategory = async (res, v) =>
        await WorkFlow.UpdateGroup({ id: res.object.id, name: v })
}

const MockCalendarEvents = [
    { 
        id: 1,
        eventName: 'Create A New Appointment',
        availableCalendars: ['ADMIN', 'ADMIN_CLINICIAN', 'ADMIN_PATIENT', 'CLINICIAN', 'CLINICIAN_PATIENT'],
        triggeredWhen: {
            id: 1,
            trigger: 'CREATE_CALENDAR_EVENT',
            description: 'Create an event on a calendar'
        },
        properties: [
            { name: 'DEFAULT_SUMMARY_ORIGINAL', value: 'Appointment with patient' }
        ],
        requires: ['Patient', 'Start Date', 'End Date']
    },
    {
        id: 2,
        eventName: 'Create A Block Out Session',
        availableCalendars: ['ADMIN', 'CLINICIAN'],
        properties: [
            { name: 'DEFAULT_SUMMARY_ORIGINAL', value: '' }
        ],
        triggeredWhen: {
            id: 1,
            trigger: 'CREATE_CALENDAR_EVENT',
            description: 'Create an event on a calendar'
        },
        requires: ['Patient', 'Start Date', 'End Date']
    },
    {
        id: 3,
        eventName: 'Reschedule patient appointment',
        availableCalendars: ['ADMIN', 'ADMIN_CLINICIAN', 'ADMIN_PATIENT', 'CLINICIAN', 'CLINICIAN_PATIENT'],
        properties: [
            { name: 'DEFAULT_SUMMARY_ORIGINAL', value: '' }
        ],
        triggeredWhen: {
            id: 3,
            trigger: 'MOVE_CALENDAR_EVENT',
            description: 'Create an event on a calendar'
        },
        requires: ['Patient', 'Start Date', 'End Date']
    },
    {
        id: 4,
        eventName: 'Cancel appointment',
        availableCalendars: ['ADMIN', 'ADMIN_CLINICIAN', 'ADMIN_PATIENT', 'CLINICIAN', 'CLINICIAN_PATIENT'],
        properties: [
            { name: 'DEFAULT_STATUS_ORIGINAL', value: 'CANCELLED' }
        ],
        triggeredWhen: {
            id: 2,
            trigger: 'UPDATE_CALENDAR_EVENT',
            description: 'Update the details / status of an event'
        },
        requires: ['Patient', 'Start Date', 'End Date']
    },
    {
        id: 5,
        eventName: 'Create a follow up appointment',
        availableCalendars: ['ADMIN', 'ADMIN_CLINICIAN', 'ADMIN_PATIENT', 'CLINICIAN', 'CLINICIAN_PATIENT'],
        properties: [
            { name: 'DEFAULT_SUMMARY_ORIGINAL', value: '' }
        ],
        triggeredWhen: {
            id: 4,
            trigger: 'CLONE_CALENDAR_EVENT',
            description: 'Clone / Repeat the event'
        },
        requires: ['Patient', 'Start Date', 'End Date']
    },
    {
        id: 6,
        eventName: 'Medication reminder',
        availableCalendars: ['ADMIN_PATIENT', 'CLINICIAN_PATIENT'],
        properties: [
            { name: 'DEFAULT_SUMMARY_ORIGINAL', value: 'Remember to take your vitimins' },
            { name: 'DEFAULT_DETAILS_ORIGINAL', value: 'Remember to take 2 vitimin C' }
        ],
        triggeredWhen: {
            id: 5,
            trigger: 'INITIATE_SEQUENCE',
            description: 'Create a repeat event'
        },
        requires: ['Patient', 'Start Date', 'End Date']
    },
];
const MockCalendarEventsTriggers = [
    {
        id: 1,
        trigger: 'CREATE_CALENDAR_EVENT',
        description: 'reate an event on a calendar'
    },
    {
        id: 2,
        trigger: 'UPDATE_CALENDAR_EVENT',
        description: 'Update the details / status of an event'
    }, {
        id: 3,
        trigger: 'MOVE_CALENDAR_EVENT',
        description: 'Move event to another date / time'
    }, {
        id: 4,
        trigger: 'CLONE_CALENDAR_EVENT',
        description: 'Clone / Repeat the event'
    }, {
        id: 5,
        trigger: 'INITIATE_SEQUENCE',
        description: 'Create a repeat event'
    },
]
export default MockCalendarEvents;
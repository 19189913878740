// import React, { useEffect, useState } from 'react'
import React, { useState } from 'react'

interface IVariablesContext {
  variables: Map<string, any>
  setVars?: (s: string, k: string, v: any, callback?: (_variables_state: Map<string, any>) => void) => void
  setVarsMap?: (s: string, r: any) => void
  setVariablesMap?: (map: Map<string, any>) => void
}
interface Props {
  children: React.ReactNode
}
const defaultState = {
  variables: new Map<string, any>(),
}

const VariablesContext = React.createContext<Partial<IVariablesContext>>(defaultState)

export const VariablesProvider: React.FC<Props> = ({ children }) => {
  const [variables, setVariables] = useState(defaultState.variables)

  const setVarsMap = (s: string, r: any) => {
    const _variables = new Map(variables)
    if (!_variables?.has(s)) _variables?.set(s, new Map<string, any>())

    if (typeof r === 'object') for (const k of Object.keys(r)) _variables?.get(s)?.set(k, r[k])

    setVariables(_variables)
  }
  const setVars = (s: string, k: string, v: any, f?: (_variables_state: Map<string, any>) => void) => {
    const _variables = new Map(variables)
    if (!_variables?.has(s)) {
      _variables?.set(s, new Map<string, any>())
    }
    if (!_variables?.get(s)?.has(k)) {
      _variables?.get(s)?.set(k, v)
    }
    _variables?.get(s).set(k, v)
    setVariables(_variables)
    if (f) console.error('Compile with enabled only if functionality really needed', f)
    // if (f) setEvent(() => f)
  }
  const setVariablesMap = (map: Map<string, any>) => setVariables(map)

  return (
    <VariablesContext.Provider
      value={{
        variables,
        setVars,
        setVarsMap,
        setVariablesMap,
      }}
    >
      {children}
    </VariablesContext.Provider>
  )
}

export default VariablesContext

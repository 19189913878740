import API from "../API";

var Calendars = {
    async ListCalendarEvents() {
        var result = await API.process(
            'api/Calendar/Events',
            'GET',
            null
        ).catch((err) => {
            throw err;
        })

        return result
    },
    async CreateCalendarEvents(event) {
        var result = await API.process(
            'api/Calendar/Event',
            'POST',
            event
        ).catch((err) => {
            throw err;
        })

        return result
    },
    async ListCalendarTypes() {
        var result = await API.process(
            'api/Calendar/Types',
            'GET',
            null
        ).catch((err) => {
            throw err;
        })

        return result
    },
    async UpdateCalendarEvent(cal) {
        var result = await API.process(
            'api/Calendar/Event',
            'PATCH',
            cal
        ).catch((err) => {
            throw err;
        })

        return result
    },
    async ListCalendarEventTriggers() {
        var result = await API.process(
            'api/Calendar/Triggers',
            'GET',
            null
        ).catch((err) => {
            throw err;
        })

        return result
    },
    async CreateCalendarEntry(entry) {
        var result = await API.process(
            'api/Calendar/Entry',
            'POST',
            entry
        ).catch((err) => {
            throw err;
        })

        return result
    },
    async GetCalendar(userId) {
        var result = await API.process(
            `api/Calendar/Calendar/${userId}`,
            'PATCH',
            null
        ).catch((err) => {
            throw err;
        })

        return result
    },
    async ListCalendarEntries(calendarId) {
        var result = await API.process(
            `api/Calendar/Entries/${calendarId}`,
            'GET',
            null
        ).catch((err) => {
            throw err;
        })

        return result
    },
    async ListAvaialbeCelendars() {
        var result = await API.process(
            `api/Calendar/User`,
            'GET',
            null
        ).catch((err) => {
            throw err;
        })

        return result
    },
    async ListExternalCalendarEntries(CalendarId) {
        var result = await API.process(
            `api/Calendar/Entries/External/${CalendarId}`,
            'GET',
            null
        ).catch((err) => {
            throw err;
        })

        return result
    },
    async SetCalendarEntryAdditionalOption(CalendarEnryId, option) {
        var result = await API.process(
            `api/Calendar/entry/${CalendarEnryId}/option`,
            'POST',
            option
        ).catch((err) => {
            throw err;
        })

        return result
    },
    async LinkCalendar(CalendarEnryId, type, isActive, newDetails) {
        var result = await API.process(
            `api/Calendar/entry/${CalendarEnryId}/link/${type}/${isActive}`,
            'PUT',
            newDetails
        ).catch((err) => {
            throw err;
        })

        return result
    },
}

export default Calendars;
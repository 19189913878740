import React, { useEffect, useState } from 'react';
import { Calendar2 } from 'react-bootstrap-icons';
import { TiArrowSyncOutline } from "react-icons/ti";
import GeneralModal from '../../../../../components/dialogs/GeneralModal';
import CalendarSync from '../../../../../libs/api/services/CalendarSync';
import ExternalCalendarListComponent from '../../components/common/ExternalCalendarListComponent';
import SyncCalendarListButton from './SyncCalendarListButton';

export default function SyncExternalCalendars({ extenalCalendar }) {
    const [modal, setModal] = useState({ show: false })
    const [calendars, setCalendars] = useState([])

    const _handleSetActive = (cals) => {
        CalendarSync
            .ActivateCalendarsAsync(extenalCalendar.id, cals)
    }
    // Load Default
    const _load = () => {
        CalendarSync
            .ListLinkedCalendarsExtendedAsync(extenalCalendar.id)
            .then((r) => setCalendars(r))
    }
    useEffect(() => { _load() }, [])
    return (
        <>
            <GeneralModal
                show={modal.show}
                icon={<Calendar2 size={25} />}
                title='Link Calendars'
                onClose={() => setModal({ ...modal, show: false })}
            >
                <div style={{ overflow: 'auto', height: '100%' }}>

                    <div className='m-2'>
                        <SyncCalendarListButton onSynched={(r) => setCalendars(r)} extenalCalendar={extenalCalendar} />
                    </div>
                    <ExternalCalendarListComponent
                        className='text-dark p-2'
                        defaultCalendars={[{ ...extenalCalendar, externalCalendars: calendars }]}
                        onSelected={(r) => _handleSetActive(r)}
                        multi={true}
                    />
                </div>
            </GeneralModal>
            <div onClick={() => setModal({ ...modal, show: true })} style={{ cursor: 'pointer' }}>
                <TiArrowSyncOutline size={30} /> Link Calendars

            </div>
        </>
    )
}

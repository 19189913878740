import React from 'react'
import { LayoutInterface } from './interfaces/LayoutInterface'
import ComponentContainer from './ComponentContainer'
import Component from './Component'
import { ActionInterface } from './interfaces/ActionInterface'
import ExplinationComponent from './explination/ExplinationComponent'

type Props = {
  section: string
  layouts: LayoutInterface[] | LayoutInterface
  onChange: (s: string, n: string, v: any) => void
  onClick: (s: string, n: string) => void
  onLoadData: (s: string, n: string) => Promise<any>
  variables: Map<string, any>
  actionRunner: (s: string, n: string, v?: string, a?: ActionInterface[]) => Promise<any>
}
const Layout = ({ section, layouts, variables, onClick, onChange, onLoadData, actionRunner }: Props) => {
  const _handleChange = (s: string, n: string, v: any) => onChange(s, n, v)
  if (Array.isArray(layouts)) {
    return layouts?.map(function (_layout) {
      return (
        <ComponentContainer key={'ComponentContainer-' + _layout.name} layout={_layout!}>
          {_layout.type!.toLowerCase() === 'sourcegroup' ? (
            <ComponentContainer key={'ComponentContainer-' + _layout.name} layout={_layout!}>
              <ExplinationComponent key={'explination-' + _layout.name} layout={_layout}>
                <Component
                  key={'ComponentC-' + _layout?.name}
                  layout={_layout}
                  onChange={_handleChange}
                  onClick={onClick}
                  onLoadData={onLoadData}
                  actionRunner={actionRunner}
                  section={section}
                  variables={variables}
                />
              </ExplinationComponent>
            </ComponentContainer>
          ) : _layout.grid !== 'navigation' ? (
            <ExplinationComponent key={'explination-' + _layout.name} layout={_layout}>
              <Component
                key={'ComponentA-' + _layout?.name}
                layout={_layout!}
                onChange={_handleChange}
                onClick={onClick}
                onLoadData={onLoadData}
                actionRunner={actionRunner}
                section={section}
                variables={variables}
              />
            </ExplinationComponent>
          ) : (
            <></>
          )}
        </ComponentContainer>
      )
    })
  } else if (layouts?.type.toLowerCase() === 'sourcegroup') {
    return (
      <ComponentContainer key={'ComponentContainer-' + layouts.name} layout={layouts!}>
        <ExplinationComponent key={'explination-' + layouts.name} layout={layouts}>
          <Component
            key={'ComponentC-' + layouts?.name}
            layout={layouts}
            onChange={_handleChange}
            onClick={onClick}
            onLoadData={onLoadData}
            actionRunner={actionRunner}
            section={section}
            variables={variables}
          />
        </ExplinationComponent>
      </ComponentContainer>
    )
  } else if (layouts?.grid && layouts?.grid !== 'navigation') {
    if (layouts.layout) {
      return layouts.layout.map((l) => (
        <ComponentContainer key={'ComponentContainer-' + l.name} layout={l!}>
          <ExplinationComponent key={'explination-' + l.name} layout={l}>
            <Component
              key={'ComponentB-' + l?.name}
              layout={l!}
              onChange={_handleChange}
              onClick={onClick}
              onLoadData={onLoadData}
              actionRunner={actionRunner}
              section={section}
              variables={variables}
            />
          </ExplinationComponent>
        </ComponentContainer>
      ))
    } else if (layouts?.grid && layouts?.grid === 'navigation') {
      return <></>
    } else {
      return (
        <ComponentContainer key={'ComponentContainer-' + layouts.name} layout={layouts!}>
          <ExplinationComponent key={'explination-' + layouts.name} layout={layouts}>
            <Component
              key={'ComponentC-' + layouts?.name}
              layout={layouts}
              onChange={_handleChange}
              onClick={onClick}
              onLoadData={onLoadData}
              actionRunner={actionRunner}
              section={section}
              variables={variables}
            />
          </ExplinationComponent>
        </ComponentContainer>
      )
    }
  }
  return <div></div>
}

export default Layout

import { useEffect, useRef, useState } from "react";
import { BsChevronDown, BsChevronUp } from 'react-icons/bs';
import { useDraggable } from "react-use-draggable-scroll";
import './scroller.css';
export default function ScrollerComponent({ options, onChange, defaultValue }) {
    const ref = useRef();
    const childref = useRef();
    const [active, setActive] = useState(5)
    const { events } = useDraggable(ref);

    useEffect(() => { if (defaultValue) _handleSetValue(defaultValue) }, [defaultValue])

    const _handleScroll = (evt) => {
        const scroll = Math.abs(childref.current.getBoundingClientRect().top - ref.current.getBoundingClientRect().top);
        setActive(options[parseInt(scroll / 30)])
        onChange(options[parseInt(scroll / 30)])
    }

    const _handleSetValue = (v) => {
        const idx = options.indexOf("" + v)
        ref.current.scrollTop = idx * 30
        // ref.current.scroll({
        //     top: idx * 30, 
        //     left: 0, 
        //     behavior: 'smooth' 
        //   });
        setActive(options[idx])
    }
    const _handleClickedScroll = (v) => {
        const scroll = Math.abs(childref.current.getBoundingClientRect().top - ref.current.getBoundingClientRect().top);
        if (v === 'Up')
            ref.current.scrollTop = scroll - 30
        else
            ref.current.scrollTop = scroll + 30
    }

    return (
        <>
            <button className="btn w-100" onClick={() => _handleClickedScroll('Up')}>
                <BsChevronUp />
            </button>
            <div
                className={'scroll-area '}
                {...events}
                onScroll={_handleScroll}
                ref={ref}
            >
                <div className="scroll-card " ref={childref}>&nbsp;</div>
                <div className="scroll-card ">&nbsp;</div>
                {
                    options.map((t) =>
                        <div onClick={() => _handleSetValue(t)} className={"scroll-card  ".concat(t === active ? 'active' : '')}>{t}</div>
                    )
                }
                <div className="scroll-card ">&nbsp;</div>
                <div className="scroll-card ">&nbsp;</div>
                <div className="scroll-card ">&nbsp;</div>
                <div className="scroll-card ">&nbsp;</div>
                <div className="scroll-card ">&nbsp;</div>
            </div>
            <button className="btn w-100" onClick={() => _handleClickedScroll('Down')}>    <BsChevronDown /></button>
        </>
    )
}

import React from 'react'
import { Calendar2Event, Calendar2EventFill, ClockFill, PersonFill, PinMapFill } from 'react-bootstrap-icons'
import { ICONS } from '../../../../../../components/icons/GenericIconPicker'

export default function CalendarEntryDetails({ item }) {
    const _findColour = () => {
        // calendarEntry > action > properties > [name=BACKGROUND_COLOUR] > value
        if (item.calendarEntry && item.calendarEntry.action && item.calendarEntry.action.properties) {
            const _PROP = item.calendarEntry.action.properties.find(f => f.name === 'BACKGROUND_COLOUR')
            if (_PROP)
                try { return JSON.parse(_PROP.value) } catch (e) { }
        }
        return undefined
    }
    const _findIcons = () => {
        // calendarEntry >action > properties > [name=ICONS] > value
        if (item.calendarEntry && item.calendarEntry.action && item.calendarEntry.action.properties) {
            const _PROP = item.calendarEntry.action.properties.find(f => f.name === 'ICONS')
            if (_PROP)
                try {
                    var res = []
                    for (const i of JSON.parse(_PROP.value))
                        res.push(<div className='pe-2'>{ICONS.find(x => x.name === i).icon}</div>)
                    return res
                } catch (e) { }
        }
    }
    return (
        <div className='p-2'>
            <div className='d-flex text-end justify-content-end'>
                <div className='d-flex p-2 shadow text-light rounded' style={{ background: _findColour(), fontSize: '1.2rem' }}>
                    {_findIcons()}
                </div>
            </div>
            <div className='text-start d-flex align-items-center'>
                <Calendar2EventFill className='me-2 ' /><b>Event Summary</b>
                <div className='ms-2'>{item.calendarEntry.summary && item.calendarEntry.summary}</div>
            </div>
            <div className='text-start d-flex align-items-center'>
                <Calendar2EventFill className='me-2 ' /><b>Status</b>
                <div className='ms-2'>{item.calendarEntry.status && item.calendarEntry.status}</div>
            </div>
            <div className='text-start d-flex align-items-center'>
                <PinMapFill className='me-2 ' /><b>Location</b>
                <div className='ms-2'>{item.calendarEntry.location && item.calendarEntry.location}</div>
            </div>
            <div className='text-start d-flex align-items-center'>
                <PersonFill className='me-2 ' /><b>Clinician</b>
                <div className='ms-2'>{item.calendarEntry.clinician ? `${item.calendarEntry.clinician.firstName} ${item.calendarEntry.clinician.lastName}` : ''}</div>
            </div>
            <div className='text-start d-flex align-items-center'>
                <PersonFill className='me-2 ' /><b>Patient</b>
                <div className='ms-2'>{item.calendarEntry.patient ? `${item.calendarEntry.patient.firstName} ${item.calendarEntry.patient.lastName}` : ''}</div>
            </div>
            <div className='text-start d-flex align-items-center'>
                <ClockFill className='me-2 ' /><b>Date / Time</b>
                <div className='ms-2'>{item.calendarEntry.startDateTime} - {item.calendarEntry.endDateTime}</div>
            </div>
            <div className='text-start d-flex align-items-center'>
                <Calendar2Event className='me-2 ' /><b>Details</b>
                <div className='ms-2'>{item.calendarEntry.description && item.calendarEntry.description}</div>
            </div>

            <div><div dangerouslySetInnerHTML={{ __html: item.calendarEntry.description }} /></div>
        </div>
    )
}

import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios'
import { StringFunctions } from './StringFunctions'
import { AesEncryption } from './AesEncryption'
import { APIEncryptionInterface } from '../components/interfaces/APIEncryptionInterface'
import { AuthenticationService } from '../services/AuthenticationService'

export abstract class APIFunctions {
  public static SetBody(vars?: Map<string, any>, body?: object): any {
    return StringFunctions.SetJSONVars(StringFunctions.ReadKeyValue(vars!), body!)
  }

  public static SetURL(url?: string, vars?: Map<string, any>): string {
    return StringFunctions.SetStringVars(StringFunctions.ReadKeyValue(vars!), url!)
  }

  public static async MakeCall(
    url?: string,
    method?: string,
    body?: string,
    encryption?: APIEncryptionInterface,
  ): Promise<any> {
    // console.log(JSON.stringify(body))
    // console.log(body)

    //const _req = encryption ? AesEncryption.encrypt(body!, encryption.method!, encryption.key!) : JSON.stringify(body)
    const _req = encryption ? AesEncryption.encrypt(body!, encryption.method!, encryption.key!) : body
    const customHeaders = {
      'Content-Type': encryption ? 'application/encrypted' : 'application/json',
      Authorization: 'Bearer ' + (await AuthenticationService.token()),
    }

    const requestConfig: AxiosRequestConfig = {
      method: method,
      url: url,
      headers: customHeaders,
    }
    requestConfig.data = _req ? _req : null
    try {
      const response: AxiosResponse<Response> = await axios(requestConfig) //funtion expects <AxiosResponse<Response>> as return.
      return encryption
        ? AesEncryption.decrypt(JSON.stringify(response.data), encryption.method!, encryption.key!)
        : JSON.stringify(response.data)
    } catch (err) {
      if (axios.isAxiosError(err)) {
        if (err.response?.status === 401) window.location.assign('/logout')
        const _err: AxiosError = err
        throw encryption
          ? AesEncryption.decrypt(JSON.stringify(_err.response?.data), encryption.method!, encryption.key!)
          : JSON.stringify(_err.response?.data)
      }
      throw err
    }
  }
}

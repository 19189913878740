import React, { useEffect, useState } from 'react'
import { Check2 } from 'react-bootstrap-icons'
import CalendarSync from '../../../../../libs/api/services/CalendarSync'

export default function ExternalCalendarListComponent({ hideInactive, multi, onSelected, className, defaultCalendars, selectable }) {
    const [calendars, setCalendars] = useState(defaultCalendars ? defaultCalendars : [])
    const [active, setActive] = useState([])

    // Check active when calendar is loaded
    useEffect(() => {
        var _a = [];
        if (calendars) {
            for (const c of calendars)
                if (c.externalCalendars)
                    for (const i of c.externalCalendars) if (i.isActive) _a.push(i);
            if (!selectable)
                setActive(_a)
        }
    }, [calendars])

    const _handleToggleActive = (ex) => {
        var _active = active
        if (multi) {
            if (active.includes(ex))
                _active.splice(_active.indexOf(ex), 1)
            else
                _active.push(ex)
        } else {
            _active[0] = ex
        }
        if (onSelected) onSelected(_active)

        setActive([..._active])
    }

    const isActive = (ex) => {
        return active.includes(ex)
        //if(active.includes(ex))
        //return true
    }
    const _load = () => {
        if (!defaultCalendars)
            CalendarSync
                .ListAllCalendarsExtendedAsync()
                .then((r) => setCalendars(r))
    }
    useEffect(() => { _load() }, [])
    useEffect(() => { if (defaultCalendars) setCalendars(defaultCalendars) }, [defaultCalendars])
    return (
        <div className={className} style={{ cursor: 'pointer' }}>
            <ul className='list-unstyled'>
                {calendars.map((r) =>
                    <>
                        <li key={r.id} className='d-flex'><div>{r.name}</div></li>
                        <ul className='list-unstyled'>
                            <li className='d-flex'><div style={{ backgroundColor: '#3572A1', width: '20px' }}>&nbsp;</div><div>Primary</div></li>
                            {
                                r.externalCalendars.map((ex) =>
                                    selectable ?
                                        <li className={'d-flex ms-4 '.concat(!isActive(ex) ? ' opacity-50' : ' ')}
                                            onClick={(e) => _handleToggleActive(ex)}
                                        ><div style={{ backgroundColor: ex.backgroundColor ? ex.backgroundColor : '#fff', width: '20px' }}>&nbsp;</div> <div>{isActive(ex) ? <Check2 /> : <></>}{ex.summary}</div></li>
                                        :
                                        hideInactive && !isActive(ex) ? <></> :
                                            <li className={'d-flex ms-4 '.concat(!isActive(ex) ? ' opacity-50' : ' ')}
                                                onClick={(e) => _handleToggleActive(ex)}
                                            ><div style={{ backgroundColor: ex.backgroundColor ? ex.backgroundColor : '#fff', width: '20px' }}>&nbsp;</div> <div>{isActive(ex) ? <Check2 /> : <></>}{ex.summary}</div></li>
                                )
                            }
                        </ul>
                    </>
                )}
            </ul>
        </div>
    )
}

import { Wizard } from "@rootbranch/wizard";
import { ClaimsFilter } from "./libs/authentication/ClaimsFilter";
import { AdminLayout } from "./screens/layout/adminLayout";
import { MenuContainer } from "./screens/menu/MenuContainer";
import UserSearchBar from "./screens/search/UserSearchBar";
import ExperimentalWF from "./screens/section/experimental/ExperimentalWF";
import EmailAdmin from "./screens/section/messaging/admin/EmailAdmin";
import SMSAdmin from "./screens/section/messaging/admin/SMSAdmin";
import WhatsAppAdmin from "./screens/section/messaging/admin/WhatsAppAdmin";
import QuestionnaireAssignmentAdmin from "./screens/section/questionnaires/assignment/QuestionnaireAssignmentAdmin";
import ImportExport from "./screens/section/setup/ImportExport";
import SysAdminUserMapping from "./screens/section/user/SysAdminUserMapping";
const UTSysAdmin = 'System Admin'
const AppRoutesSystemAdminitration = [

  ///
  // {
  //   path: '/sys-admin',
  //   element: <ClaimsFilter enforce={true} claim={"System Admin"}>
  //     <AdminLayout key={"AL_" + new Date().getTime()}
  //       menu={<MenuContainer key={"menu_cnt" + new Date().getTime()} menu="Admin" activeSection="" />}
  //       breadcrums={[{ name: 'System Administration', location: '/sys-admin' }]} container={<SysAdmin />} /></ClaimsFilter>
  // }, 
  // {
  //   path: '/sys-admin/tncs',
  //   element: <ClaimsFilter enforce={true} claim={"System Admin"}>
  //     <AdminLayout key={"AL_" + new Date().getTime()} menu={<MenuContainer key={"menu_cnt" + new Date().getTime()}
  //       menu="Admin" activeSection="" />}
  //       breadcrums={[{ name: 'System Administration', location: '/sys-admin' }]} container={<ManageTerms />} /></ClaimsFilter>
  // },
  // {
  //   path: '/sys-admin/users',
  //   element: <ClaimsFilter enforce={true} claim={"System Admin"}>
  //     <AdminLayout key={"AL_" + new Date().getTime()}
  //       container={<SysAdminUserDashboard />}
  //       menu={<MenuContainer key={"menu_cnt" + new Date().getTime()} menu="Admin" activeSection="Users" />} search={<UserSearchBar />} breadcrums={[{ name: 'System Administration', location: '/sys-admin' }, { name: 'Users', location: '/sys-admin/users', active: true }]} /></ClaimsFilter>
  // },
  {
    path: '/sys-admin/users/mapping',
    element: <ClaimsFilter enforce={true} claim={"System Admin"}>
      <AdminLayout key={"AL_" + new Date().getTime()}
        container={<SysAdminUserMapping />}
        menu={<MenuContainer key={"menu_cnt" + new Date().getTime()} menu="Admin" activeSection="Users" />} search={<UserSearchBar />} breadcrums={[{ name: 'System Administration', location: '/sys-admin' }, { name: 'Users', location: '/sys-admin/users', active: true }]} /></ClaimsFilter>
  },
  // {
  //   path: '/sys-admin/users/add',
  //   element: <ClaimsFilter enforce={true} claim={"System Admin"}><AdminLayout key={"AL_" + new Date().getTime()} menu={<MenuContainer key={"menu_cnt" + new Date().getTime()} menu="Admin" activeSection="Users" />} container={<RegisterUser />} search={<UserSearchBar />} breadcrums={[{ name: 'System Administration', location: '/sys-admin' }, { name: 'Users', location: '/sys-admin/users' }, { name: 'Add New User', location: '/sys-admin/users/add', active: true }]} /></ClaimsFilter>
  // },
  // {
  //   path: '/sys-admin/user/manage/:id',
  //   element: <ClaimsFilter enforce={true} claim={"System Admin"}><AdminLayout key={"AL_" + new Date().getTime()} menu={<MenuContainer key={"menu_cnt" + new Date().getTime()} menu="UserManagement" activeSection="Users" />} search={<UserSearchBar />} container={<UserAdminEdit />} breadcrums={[{ name: 'System Administration', location: '/sys-admin' }, { name: 'Users', location: '/sys-admin/users' }, { name: '[UserName]', location: '/sys-admin/user/manage/[UserID]' }, { name: 'Manage', location: '/sys-admin/users/add', active: true }]} /></ClaimsFilter>
  // },
  // {
  //   path: '/sys-admin/user/manage/:id/permissions',
  //   element: <ClaimsFilter enforce={true} claim={"System Admin"}><AdminLayout key={"AL_" + new Date().getTime()} menu={<MenuContainer key={"menu_cnt" + new Date().getTime()} menu="UserManagement" activeSection="Permissions" />} container={<UserPersmissions />} search={<UserSearchBar />} breadcrums={[{ name: 'System Administration', location: '/sys-admin' }, { name: 'Users', location: '/sys-admin/users' }, { name: '[UserName]', location: '/sys-admin/user/manage/[UserID]' }, { name: 'Permissions', location: '/sys-admin/users/[UserID]/permissions', active: true }]} /></ClaimsFilter>
  // },
  // {
  //   path: '/sys-admin/user/manage/:id/facilities',
  //   element: <ClaimsFilter enforce={true} claim={"System Admin"}><AdminLayout key={"AL_" + new Date().getTime()} menu={<MenuContainer key={"menu_cnt" + new Date().getTime()} menu="UserManagement" activeSection="Facilities" />} container={<UserFacilities />} search={<UserSearchBar />} breadcrums={[{ name: 'System Administration', location: '/sys-admin' }, { name: 'Users', location: '/sys-admin/users' }, { name: '[UserName]', location: '/sys-admin/user/manage/[UserID]' }, { name: 'Facilities', location: '/sys-admin/users/[UserID]/facilities', active: true }]} /></ClaimsFilter>
  // },
  // {
  //   path: '/sys-admin/ux/:id',
  //   element: <ClaimsFilter enforce={true} claim={"System Admin"}><AdminLayout key={"AL_" + new Date().getTime()} menu={<MenuContainer key={"menu_cnt" + new Date().getTime()} menu="Admin" activeSection="UI/UX Customise" />} container={<UISectionLayout />} breadcrums={[{ name: 'System Administration', location: '/sys-admin' }, { name: 'UI/UX Customise', location: '/sys-admin/ux/0', active: true }]} /></ClaimsFilter>
  // },
  // {
  //   path: '/sys-admin/logs',
  //   element: <ClaimsFilter enforce={true} claim={"System Admin"}><AdminLayout key={"AL_" + new Date().getTime()} menu={<MenuContainer key={"menu_cnt" + new Date().getTime()} menu="Admin" activeSection="Logs" />} container={<LogsSectionLayout />} breadcrums={[{ name: 'System Administration', location: '/sys-admin' }, { name: 'Logs', location: '/sys-admin/logs', active: true }]} /></ClaimsFilter>
  // },
  // {
  //   path: '/sys-admin/event/new',
  //   element: <ClaimsFilter enforce={true} claim={"System Admin"}><AdminLayout key={"AL_" + new Date().getTime()} menu={<MenuContainer key={"menu_cnt" + new Date().getTime()} menu="Admin" activeSection="Logs" />} container={<WorkflowWizard />} breadcrums={[{ name: 'System Administration', location: '/sys-admin' }, { name: 'Create New Event', location: '/sys-admin/Event', active: true }]} /></ClaimsFilter>
  // },
  // {
  //   path: '/sys-admin/questionnaires',
  //   element: <ClaimsFilter enforce={true} claim={"System Admin"}><AdminLayout key={"AL_" + new Date().getTime()} menu={<MenuContainer key={"menu_cnt" + new Date().getTime()} menu="Questionaire" activeSection="" />} container={<QuestionnairesAdmin />} breadcrums={[{ name: 'System Administration', location: '/sys-admin' }, { name: 'Questionnaires', location: '/sys-admin/questionnaires', active: true }]} /></ClaimsFilter>
  // },
  // {
  //   path: '/sys-admin/questionnaires/builder',
  //   element: <ClaimsFilter enforce={true} claim={"System Admin,Clinician,Admin"}><AdminLayout key={"AL_" + new Date().getTime()} menu={<MenuContainer key={"menu_cnt" + new Date().getTime()} menu="Questionaire" activeSection="Questionnaire Builder" />} container={<QuestionnaireBuilder />} breadcrums={[{ name: 'System Administration', location: '/sys-admin' }, { name: 'Questionnaires', location: '/sys-admin/questionnaires', active: false }, { name: 'Questionnaire Builder', location: '/sys-admin/questionnaires', active: true }]} /></ClaimsFilter>
  // },
  // {
  //   path: '/sys-admin/form/builder',
  //   element: <ClaimsFilter enforce={true} claim={"System Admin,Clinician,Admin"}><AdminLayout key={"AL_" + new Date().getTime()} menu={<MenuContainer key={"menu_cnt" + new Date().getTime()} menu="Questionaire" activeSection="Form Builder" />} container={<FormBuilder />} breadcrums={[{ name: 'System Administration', location: '/sys-admin' }, { name: 'Questionnaires', location: '/sys-admin/questionnaires', active: false }, { name: 'Form Builder', location: '/sys-admin/questionnaires', active: true }]} /></ClaimsFilter>
  // },
  {
    path: '/sys-admin/questionnaires/assignment',
    element: <ClaimsFilter enforce={true} claim={"System Admin,Clinician,Admin"}><AdminLayout key={"AL_" + new Date().getTime()} menu={<MenuContainer key={"menu_cnt" + new Date().getTime()} menu="Questionaire" activeSection="Questionnaire Assignment" />} container={<QuestionnaireAssignmentAdmin />} breadcrums={[{ name: 'System Administration', location: '/sys-admin' }, { name: 'Questionnaires', location: '/sys-admin/questionnaires', active: false }, { name: 'Questionnaire Assignment', location: '/sys-admin/questionnaires/assignment', active: true }]} /></ClaimsFilter>
  },
  {
    path: '/sys-admin-setup',
    element: <AdminLayout key={"AL_" + new Date().getTime()} container={<Wizard key={'admin-setup'} url={{
      url: 'api/admin/setup',
      method: 'GET',
      body: null,
      encryption: {
        key: '',
        method: 'aes256'
      }
    }} />}></AdminLayout>
  },
  // {
  //   path: '/sys-admin/patient',
  //   // element: <ClaimsFilter enforce={true} claim={"System Admin"}><AdminLayout key={"AL_" + new Date().getTime()} menu={<MenuContainer key={"menu_cnt" + new Date().getTime()} menu="Patient-Admin" activeSection="Patient" />} breadcrums={[{ name: 'System Administration', location: '/sys-admin' }, { name: 'Patient', location: '/sys-admin/patient', active: true }]} /></ClaimsFilter>
  //   element: <ClaimsFilter enforce={true} claim={"System Admin"}><AdminLayout key={"AL_" + new Date().getTime()} menu={<MenuContainer key={"menu_cnt" + new Date().getTime()} menu="Patient-Admin" activeSection="Patient Profile" />} container={<PatientProfileAdmin />} breadcrums={[{ name: 'System Administration', location: '/sys-admin' }, { name: 'Patient', location: '/sys-admin/patient', }, { name: 'Profile Configuration', location: '/sys-admin/patient/profile', active: true }]} /></ClaimsFilter>
  // },
  // {
  //   path: '/sys-admin/patient/profile',
  //   element: <ClaimsFilter enforce={true} claim={"System Admin"}><AdminLayout key={"AL_" + new Date().getTime()} menu={<MenuContainer key={"menu_cnt" + new Date().getTime()} menu="Patient-Admin" activeSection="Patient Profile" />} container={<PatientProfileAdmin />} breadcrums={[{ name: 'System Administration', location: '/sys-admin' }, { name: 'Patient', location: '/sys-admin/patient', }, { name: 'Profile Configuration', location: '/sys-admin/patient/profile', active: true }]} /></ClaimsFilter>
  // },
  // {
  //   path: '/sys-admin/patient/events',
  //   element: <ClaimsFilter enforce={true} claim={"System Admin"}><AdminLayout key={"AL_" + new Date().getTime()} menu={<MenuContainer key={"menu_cnt" + new Date().getTime()} menu="Patient-Admin" activeSection="Patient Events" />} container={<WorkflowWizard />} breadcrums={[{ name: 'System Administration', location: '/sys-admin' }, { name: 'Patient', location: '/sys-admin/patient', }, { name: 'Event Configuration', location: '/sys-admin/patient/events', active: true }]} /></ClaimsFilter>
  // },
  // {
  //   path: '/sys-admin/messaging',
  //   //element: <ClaimsFilter enforce={true} claim={"System Admin"}><AdminLayout key={"AL_" + new Date().getTime()} menu={<MenuContainer key={"menu_cnt" + new Date().getTime()} menu="Messaging" activeSection="Messaging" />} container={''} breadcrums={[{ name: 'System Administration', location: '/sys-admin' }, { name: 'Messaging', location: '/sys-admin/messaging', active: true }]} /></ClaimsFilter>
  //   element: <ClaimsFilter enforce={true} claim={"System Admin"}><AdminLayout key={"AL_" + new Date().getTime()} menu={<MenuContainer key={"menu_cnt" + new Date().getTime()} menu="Messaging" activeSection="Messaging Template" />} container={<MessagingTemplateAdmin startStep={1} />} breadcrums={[{ name: 'System Administration', location: '/sys-admin' }, { name: 'Messaging', location: '/sys-admin/messaging' }, { name: 'Messaging Templates', location: '/sys-admin/messaging/temlate', active: true }]} /></ClaimsFilter>
  // },
  // {
  //   path: '/sys-admin/messaging/providers',
  //   //element: <ClaimsFilter enforce={true} claim={"System Admin"}><AdminLayout key={"AL_" + new Date().getTime()} menu={<MenuContainer key={"menu_cnt" + new Date().getTime()} menu="Messaging" activeSection="Messaging" />} container={''} breadcrums={[{ name: 'System Administration', location: '/sys-admin' }, { name: 'Messaging', location: '/sys-admin/messaging', active: true }]} /></ClaimsFilter>
  //   element: <ClaimsFilter enforce={true} claim={"System Admin"}><AdminLayout key={"AL_" + new Date().getTime()} menu={<MenuContainer key={"menu_cnt" + new Date().getTime()} menu="Messaging" activeSection="Messaging Template" />} container={<ServiceProviderAdmin startStep={1} />} breadcrums={[{ name: 'System Administration', location: '/sys-admin' }, { name: 'Messaging', location: '/sys-admin/messaging' }, { name: 'Messaging Templates', location: '/sys-admin/messaging/temlate', active: true }]} /></ClaimsFilter>
  // },
  // {
  //   path: '/sys-admin/messaging/config',
  //   //element: <ClaimsFilter enforce={true} claim={"System Admin"}><AdminLayout key={"AL_" + new Date().getTime()} menu={<MenuContainer key={"menu_cnt" + new Date().getTime()} menu="Messaging" activeSection="Messaging" />} container={''} breadcrums={[{ name: 'System Administration', location: '/sys-admin' }, { name: 'Messaging', location: '/sys-admin/messaging', active: true }]} /></ClaimsFilter>
  //   element: <ClaimsFilter enforce={true} claim={"System Admin"}><AdminLayout key={"AL_" + new Date().getTime()} menu={<MenuContainer key={"menu_cnt" + new Date().getTime()} menu="Messaging" activeSection="System Messaging Accounts Setup" />} container={<SystemMessagingAccounts startStep={1} />} breadcrums={[{ name: 'System Administration', location: '/sys-admin' }, { name: 'Messaging', location: '/sys-admin/messaging' }, { name: 'Messaging Templates', location: '/sys-admin/messaging/temlate', active: true }]} /></ClaimsFilter>
  // },
  // <!-- Messaging -->
  // {
  //   path: '/sys-admin/messaging/template',
  //   element: <ClaimsFilter enforce={true} claim={"System Admin"}><AdminLayout key={"AL_" + new Date().getTime()} menu={<MenuContainer key={"menu_cnt" + new Date().getTime()} menu="Messaging" activeSection="Messaging Template" />} container={<MessagingTemplateAdmin />} breadcrums={[{ name: 'System Administration', location: '/sys-admin' }, { name: 'Messaging', location: '/sys-admin/messaging' }, { name: 'Messaging Templates', location: '/sys-admin/messaging/temlate', active: true }]} /></ClaimsFilter>
  // },
  // {
  //   path: '/sys-admin/messaging/audience',
  //   element: <ClaimsFilter enforce={true} claim={"System Admin"}><AdminLayout key={"AL_" + new Date().getTime()} menu={<MenuContainer key={"menu_cnt" + new Date().getTime()} menu="Messaging" activeSection="Audience Groups" />} container={<MessagingAudienceAdmin />} breadcrums={[{ name: 'System Administration', location: '/sys-admin' }, { name: 'Messaging', location: '/sys-admin/messaging' }, { name: 'Audience Group', location: '/sys-admin/messaging/temlate', active: true }]} /></ClaimsFilter>
  // },
  {
    path: '/sys-admin/messaging/sms',
    element: <ClaimsFilter enforce={true} claim={"System Admin"}><AdminLayout key={"AL_" + new Date().getTime()} menu={<MenuContainer key={"menu_cnt" + new Date().getTime()} menu="Messaging" activeSection="SMS" />} container={<SMSAdmin />} breadcrums={[{ name: 'System Administration', location: '/sys-admin' }, { name: 'Messaging', location: '/sys-admin/messaging' }, { name: 'Configurations', location: '/sys-admin/messaging' }, { name: 'SMS', location: '/sys-admin/messaging/sms', active: true }]} /></ClaimsFilter>
  },
  {
    path: '/sys-admin/messaging/wa',
    element: <ClaimsFilter enforce={true} claim={"System Admin"}><AdminLayout key={"AL_" + new Date().getTime()} menu={<MenuContainer key={"menu_cnt" + new Date().getTime()} menu="Messaging" activeSection="WhatsApp" />} container={<WhatsAppAdmin />} breadcrums={[{ name: 'System Administration', location: '/sys-admin' }, { name: 'Messaging', location: '/sys-admin/messaging' }, { name: 'Configurations', location: '/sys-admin/messaging' }, { name: 'WhatsApp', location: '/sys-admin/messaging/wa', active: true }]} /></ClaimsFilter>
  },
  {
    path: '/sys-admin/messaging/email',
    element: <ClaimsFilter enforce={true} claim={"System Admin"}><AdminLayout key={"AL_" + new Date().getTime()} menu={<MenuContainer key={"menu_cnt" + new Date().getTime()} menu="Messaging" activeSection="Email" />} container={<EmailAdmin />} breadcrums={[{ name: 'System Administration', location: '/sys-admin' }, { name: 'Messaging', location: '/sys-admin/messaging' }, { name: 'Configurations', location: '/sys-admin/messaging' }, { name: 'Email', location: '/sys-admin/messaging/email', active: true }]} /></ClaimsFilter>
  },
  {
    path: '/sys-admin/messaging/email/:connection_id?',
    element: <ClaimsFilter enforce={true} claim={"System Admin"}><AdminLayout key={"AL_" + new Date().getTime()} menu={<MenuContainer key={"menu_cnt" + new Date().getTime()} menu="Messaging" activeSection="Email" />} container={<EmailAdmin />} breadcrums={[{ name: 'System Administration', location: '/sys-admin' }, { name: 'Messaging', location: '/sys-admin/messaging' }, { name: 'Configurations', location: '/sys-admin/messaging' }, { name: 'Email', location: '/sys-admin/messaging/email', active: true }]} /></ClaimsFilter>
  },
  {
    path: '/sys-admin/IEx',
    element: <ClaimsFilter enforce={true} claim={"System Admin"}><AdminLayout key={"AL_" + new Date().getTime()} menu={undefined} container={<ImportExport />} /></ClaimsFilter>
  },
  {
    path: '/experimental',
    // element: <ClaimsFilter enforce={true} claim={"System Admin"}><AdminLayout key={"AL_" + new Date().getTime()} menu={<MenuContainer key={"menu_cnt" + new Date().getTime()} menu="Messaging" activeSection="Email" />} container={<Experimental />} breadcrums={[{ name: 'Experimental', location: '/sys-admin' },]} /></ClaimsFilter>
    element: <ClaimsFilter enforce={true} claim={"System Admin"}><AdminLayout key={"AL_" + new Date().getTime()} menu={<MenuContainer key={"menu_cnt" + new Date().getTime()} menu="Messaging" activeSection="Email" />} container={<ExperimentalWF />} breadcrums={[{ name: 'Experimental', location: '/sys-admin' },]} /></ClaimsFilter>
  },



  /*
  Assist features
  */
  // Doctor
  // {
  //   path: '/System Admin',
  //   element: <ClaimsFilter enforce={true} claim={"System Admin"}>
  //     <ClinicianManagementLayout menu={<MenuContainer key={"menu_cnt" + new Date().getTime()}
  //       menu="Receptionist" activeSection="Dashboard" />} search={<PatientearchBar />}
  //       breadcrums={[{ name: 'Home', location: '/' + UTSysAdmin }]} container={<Dashboard type={'SYS_ADMIN_DASHBAORD'} />} /></ClaimsFilter>
  // },
  // {
  //   path: '/System Admin/Patient',
  //   // element: <ClaimsFilter enforce={true} claim={"System Admin,Admin"}><ClinicianManagementLayout menu={<MenuContainer key={"menu_cnt" + new Date().getTime()} menu="ReceptionistPatient" activeSection="Profile" />} container={<PatientManagement />} breadcrums={[{ name: 'Home', location: '/System Admin' }, { name: 'Patient', location: '/System Admin/Patient/[PatientID]' }, { name: '[PatientName]', location: '/System Admin/Patient/[PatientID]/Dashboard' }, { name: 'Profile', location: '/System Admin/Patient', active: true }]} /></ClaimsFilter>
  //   element: <ClaimsFilter enforce={true} claim={"System Admin"}><ClinicianManagementLayout
  //     search={<FilteredSearchBar section={'Patient Management'} />}
  //     menu={<MenuContainer key={"menu_cnt" + new Date().getTime()} menu="ReceptionistPatient" activeSection="Profile" />}
  //     container={<PatientProfileManagement />}
  //     breadcrums={[{ name: 'Home', location: '/' + UTSysAdmin },
  //     { name: 'New Patient', location: '/' + UTSysAdmin + '/Patient', active: true }]} /></ClaimsFilter>
  // },
  // {
  //   path: '/System Admin/Patient/:id?',
  //   // element: <ClaimsFilter enforce={true} claim={"System Admin,Admin"}><ClinicianManagementLayout menu={<MenuContainer key={"menu_cnt" + new Date().getTime()} menu="ReceptionistPatient" activeSection="Profile" />} container={<PatientManagement />} breadcrums={[{ name: 'Home', location: '/System Admin' }, { name: 'Patient', location: '/System Admin/Patient/[PatientID]' }, { name: '[PatientName]', location: '/System Admin/Patient/[PatientID]/Dashboard' }, { name: 'Profile', location: '/System Admin/Patient', active: true }]} /></ClaimsFilter>
  //   element: <ClaimsFilter enforce={true} claim={"System Admin"}>
  //     <ClinicianManagementLayout menu={<MenuContainer key={"menu_cnt" + new Date().getTime()}
  //       menu="ReceptionistPatient" activeSection="Profile" />}
  //       container={<PatientProfileManagement />}
  //       breadcrums={[{ name: 'Home', location: '/' + UTSysAdmin },
  //       { name: 'Patient', location: '/' + UTSysAdmin + '/Patient/[PatientID]' },
  //       { name: '[PatientName]', location: '/' + UTSysAdmin + '/Patient/[PatientID]/Dashboard' },
  //       { name: 'Profile', location: '/' + UTSysAdmin + '/Patient', active: true }]} /></ClaimsFilter>
  // },
  // {
  //   path: '/System Admin/Patient/:id/Dashboard',
  //   element: <ClaimsFilter enforce={true} claim={"System Admin"}>
  //     <ClinicianManagementLayout menu={<MenuContainer key={"menu_cnt" + new Date().getTime()}
  //       menu="ReceptionistPatient" activeSection="Profile" />}
  //       container={<PatientProfileDashboard type="ADMIN_PATIENT_DASHBOARD" />}
  //       breadcrums={[{ name: 'Home', location: '/' + UTSysAdmin },
  //       { name: 'Patient', location: '/' + UTSysAdmin + '/Patient/[PatientID]' },
  //       { name: '[PatientName]', location: '/' + UTSysAdmin + '/Patient/[PatientID]/Dashboard' },
  //       { name: 'Profile', location: '/' + UTSysAdmin + '/Patient', active: true }]} /></ClaimsFilter>
  // },
  // {
  //   path: '/System Admin/Patient/:id/History',
  //   element: <ClaimsFilter enforce={true} claim={"System Admin"}>
  //     <ClinicianManagementLayout menu={<MenuContainer key={"menu_cnt" + new Date().getTime()}
  //       menu="ReceptionistPatient" activeSection="History" />}
  //       container={<WorkFlowPatientHistory />}
  //       breadcrums={[{ name: 'Home', location: '/' + UTSysAdmin },
  //       { name: 'Patient', location: '/' + UTSysAdmin + '/Patient/[PatientID]' },
  //       { name: '[PatientName]', location: '/' + UTSysAdmin + '/Patient/[PatientID]/Dashboard' },
  //       { name: 'History', location: '/' + UTSysAdmin + '/Patient', active: true }]} /></ClaimsFilter>
  // },
  // {
  //   path: '/System Admin/Patient/:id/:event/Files',
  //   element: <ClaimsFilter enforce={true} claim={"System Admin"}><ClinicianManagementLayout
  //     menu={<MenuContainer key={"menu_cnt" + new Date().getTime()} menu="ReceptionistPatient" activeSection="History" />}
  //     container={<PatientDMS />}
  //     breadcrums={[{ name: 'Home', location: '/' + UTSysAdmin },
  //     { name: 'Patient', location: '/' + UTSysAdmin + '/Patient/[PatientID]' },
  //     { name: '[PatientName]', location: '/' + UTSysAdmin + '/Patient/[PatientID]/Dashboard' },
  //     { name: 'History', location: '/' + UTSysAdmin + '/Patient/[PatientID]/History', active: false },
  //     { name: 'Event', location: '/' + UTSysAdmin + '/Patient', active: true }]} /></ClaimsFilter>
  // },
  // {
  //   path: '/System Admin/Messages',
  //   element: <ClaimsFilter enforce={true} claim={"System Admin"}>
  //     <ClinicianManagementLayout menu={<MenuContainer key={"menu_cnt" + new Date().getTime()} menu="Receptionist" activeSection="Messages" />}
  //       container={<MessageManagement />}
  //       breadcrums={[{ name: 'Home', location: '/' + UTSysAdmin },
  //       { name: 'Messages', location: '/' + UTSysAdmin + '/Messages', active: true }]} /></ClaimsFilter>
  // },
  // {
  //   path: '/System Admin/Messages/Patient/:id',
  //   element: <ClaimsFilter enforce={true} claim={"System Admin"}>
  //     <ClinicianManagementLayout menu={<MenuContainer key={"menu_cnt" + new Date().getTime()} menu="ReceptionistPatient" activeSection="Messages" />}
  //       container={<MessageManagement />}
  //       breadcrums={[{ name: 'Home', location: '/' + UTSysAdmin },
  //       { name: 'Patient', location: '/' + UTSysAdmin + '/Patient/[PatientID]' },
  //       { name: '[PatientName]', location: '/' + UTSysAdmin + '/Patient/[PatientID]/Dashboard' },
  //       { name: 'Messages', location: '/' + UTSysAdmin + '/Patient', active: true }]} /></ClaimsFilter>
  // },
  // {
  //   path: '/System Admin/Configuration',
  //   element: <ClaimsFilter enforce={true} claim={"System Admin"}><AdminLayout
  //     menu={<MenuContainer key={"menu_cnt" + new Date().getTime()}
  //       menu="UserConfiguration" activeSection="" />}
  //     breadcrums={[{ name: 'Facility Administration', location: "/" + Authentication.getFacilityRole() + '/Configuration' },
  //     ]}
  //     container={<UserConfiguration />} /></ClaimsFilter>
  // },
  // {
  //   path: '/System Admin/Configuration/Users',
  //   // element: <ClaimsFilter enforce={true} claim={"System Admin,Admin"}><ClinicianManagementLayout menu={<MenuContainer key={"menu_cnt" + new Date().getTime()} menu="UserConfiguration" activeSection="" />} container={<FacilityUserManagement />} /></ClaimsFilter>
  //   element: <ClaimsFilter enforce={true} claim={"System Admin"}>
  //     <AdminLayout key={"AL_" + new Date().getTime()} menu={<MenuContainer key={"menu_cnt" + new Date().getTime()}
  //       menu="UserConfiguration" activeSection="Users" />}
  //       search={<UserSearchBar facility={Authentication.getFacility()} />}
  //       breadcrums={[{ name: 'Facility Administration', location: "/" + Authentication.getFacilityRole() + '/Configuration' },
  //       { name: 'Users', location: Authentication.getFacilityRole() + '/Configuration', active: true }]}
  //       container={<FacilityUserManagement />} /></ClaimsFilter>
  // },
  // {
  //   path: '/System Admin/Configuration/Users',
  //   // element: <ClaimsFilter enforce={true} claim={"System Admin,Admin"}><ClinicianManagementLayout menu={<MenuContainer key={"menu_cnt" + new Date().getTime()} menu="UserConfiguration" activeSection="" />} container={<FacilityUserManagement />} /></ClaimsFilter>
  //   element: <ClaimsFilter enforce={true} claim={"System Admin"}><AdminLayout key={"AL_" + new Date().getTime()}
  //     menu={<MenuContainer key={"menu_cnt" + new Date().getTime()} menu="UserConfiguration" activeSection="Users" />}
  //     search={<UserSearchBar facility={Authentication.getFacilityRole()} />}
  //     breadcrums={[{ name: 'Facility Administration', location: "/" + Authentication.getFacilityRole() + '/Configuration' },
  //     { name: 'Users', location: Authentication.getFacilityRole() + '/Configuration', active: true }]} container={<FacilityUserManagement />} /></ClaimsFilter>
  // },
  // {
  //   path: '/System Admin/Configuration/Email',
  //   element: <ClaimsFilter enforce={true} claim={"System Admin"}>
  //     <AdminLayout menu={<MenuContainer key={"menu_cnt" + new Date().getTime()}
  //       menu="UserConfiguration" activeSection="Email" />}
  //       search={<FacilityManagementSearchBar section={`Email configuration for ${JSON.parse(Authentication.UserName() && Authentication.UserName())}`} />}
  //       breadcrums={[{ name: 'Facility Administration', location: "/" + Authentication.getFacilityRole() + '/Configuration' },
  //       { name: 'Email Account', location: '/Facility/Configuration', active: true },
  //       ]}
  //       container={<UserEmailAccountConfiguration user={{ id: Authentication.UserId(true) }} />} /></ClaimsFilter>
  // },
  // {
  //   path: '/System Admin/Configuration/Email/:state',
  //   element: <ClaimsFilter enforce={true} claim={"System Admin"}>
  //     <AdminLayout menu={<MenuContainer key={"menu_cnt" + new Date().getTime()}
  //       menu="UserConfiguration" activeSection="Email" />}
  //       breadcrums={[{ name: 'Facility Administration', location: "/" + Authentication.getFacilityRole() + '/Configuration' },
  //       { name: 'Email Account', location: '/Facility/Configuration', active: true },
  //       ]}
  //       container={<UserEmailAccountConfiguration user={{ id: Authentication.UserId(true) }} />} /></ClaimsFilter>
  // },
  // {
  //   path: '/System Admin/Configuration/UserRequests',
  //   element: <ClaimsFilter enforce={true} claim={"System Admin"}><AdminLayout search={<UserSearchBar facility={Authentication.getFacilityRole()} />}
  //     menu={<MenuContainer key={"menu_cnt" + new Date().getTime()}
  //       menu="UserConfiguration" activeSection="User Requests" />}
  //     container={<UserRequests />}
  //     breadcrums={[{ name: 'Facility Administration', location: "/" + Authentication.getFacilityRole() + '/Configuration' },
  //     { name: 'Users', location: '/' + Authentication.getFacilityRole() + '/Configuration/users', active: false },
  //     { name: 'User Requests', location: Authentication.getFacilityRole() + '/Configuration', active: true }]} /></ClaimsFilter>
  // },
  // {
  //   path: '/System Admin/Patient/:id/Questionnaires',
  //   element: <ClaimsFilter enforce={true} claim={"System Admin"}>
  //     <PatientLoginLayout menu={<MenuContainer key={"menu_cnt" + new Date().getTime()} menu="ReceptionistPatient" activeSection="Questionnaires" />}
  //       container={<PatientQuestionnaires />}
  //       search={<PatientearchBar />}
  //       breadcrums={[{ name: 'Home', location: '/' + UTSysAdmin },
  //       { name: 'Patient', location: '/' + UTSysAdmin + '/Patient/[PatientID]' },
  //       { name: '[PatientName]', location: '/' + UTSysAdmin + '/Patient/[PatientID]/Dashboard' },
  //       { name: 'Questionnaires', location: '/' + UTSysAdmin + '/Patient', active: true }]} /></ClaimsFilter>
  // },
  // {
  //   path: '/System Admin/Patient/:id/Forms',
  //   element: <ClaimsFilter enforce={true} claim={"System Admin"}>
  //     <PatientLoginLayout menu={<MenuContainer key={"menu_cnt" + new Date().getTime()} menu="ReceptionistPatient" activeSection="Questionnaires" />}
  //       search={<PatientearchBar />} container={<PatientForms />}
  //       breadcrums={[{ name: 'Home', location: '/' + UTSysAdmin },
  //       { name: 'Patient', location: '/' + UTSysAdmin + '/Patient/[PatientID]' },
  //       { name: '[PatientName]', location: '/' + UTSysAdmin + '/Patient/[PatientID]/Dashboard' },
  //       { name: 'Forms', location: '/' + UTSysAdmin + '/Patient', active: true }]} /></ClaimsFilter>
  // },
  // {
  //   path: '/System Admin/Patient/:id/Questionanire/:questionair/:QuestionnaireAssignmentId',
  //   element: <ClaimsFilter enforce={true} claim={"System Admin"}>
  //     <PatientLoginLayout menu={<MenuContainer key={"menu_cnt" + new Date().getTime()} menu="ReceptionistPatient" activeSection="Questionnaires" />} container={<PatientQuestionnaireResults />}
  //       search={<PatientearchBar />} breadcrums={[{ name: 'Home', location: '/' + UTSysAdmin },
  //       { name: 'Patient', location: '/' + UTSysAdmin + '/Patient/[PatientID]' }, {
  //         name: '[PatientName]',
  //         location: '/' + UTSysAdmin + '/Patient/[PatientID]/Dashboard'
  //       },
  //       { name: 'Questionnaires', location: '/' + UTSysAdmin + '/Patient/[PatientID]/Questionnaires', active: false },
  //       { name: 'Questionnaire', location: '/' + UTSysAdmin + '/Patient', active: true }]} /></ClaimsFilter>
  // },
  // {
  //   path: '/System Admin/Patient/:id/Form/:questionair/:QuestionnaireAssignmentId',
  //   element: <ClaimsFilter enforce={true} claim={"System Admin"}>
  //     <PatientLoginLayout menu={<MenuContainer key={"menu_cnt" + new Date().getTime()} menu="ReceptionistPatient" activeSection="Questionnaires" />}
  //       container={<PatientFormComponent />} search={<PatientearchBar />}
  //       breadcrums={[{ name: 'Home', location: '/' + UTSysAdmin },
  //       { name: 'Patient', location: '/' + UTSysAdmin + '/Patient/[PatientID]' },
  //       { name: '[PatientName]', location: '/' + UTSysAdmin + '/Patient/[PatientID]/Dashboard' },
  //       { name: 'Forms', location: '/' + UTSysAdmin + '/Patient/[PatientID]/Forms', active: false },
  //       { name: 'Form', location: '/' + UTSysAdmin + '/Patient', active: true }]} /></ClaimsFilter>
  // },
  /*
  Super Faclity 
  */
  // {
  //   path: '/Facility/Configuration',
  //   element: <ClaimsFilter enforce={true} claim={"Super"}>
  //     <PatientManagementLayout menu={<MenuContainer key={"menu_cnt" + new Date().getTime()} menu="UserConfiguration" activeSection="Facility Configurations" />}
  //       container={<FacilityManagementScreen />} /></ClaimsFilter>
  // },
  // {
  //   path: '/Super',
  //   element: <ClaimsFilter enforce={true} claim={"Super"}>
  //     <PatientManagementLayout nofilter
  //       menu={<MenuContainer key={"menu_cnt" + new Date().getTime()} menu="UserConfiguration" activeSection="Facility Configurations" />}
  //       container={<FacilityManagementScreen />} /></ClaimsFilter>
  // },
  // {
  //   path: '/Facility/Configuration/Events',
  //   element: <ClaimsFilter enforce={true} claim={"Super"}>
  //     <PatientManagementLayout nofilter menu={<MenuContainer key={"menu_cnt" + new Date().getTime()} menu="UserConfiguration" activeSection="Facility Configurations" />}
  //       container={<WorkflowWizard />} /></ClaimsFilter>
  // },
  // {
  //   path: '/Facility/Configuration/Messaging',
  //   element: <ClaimsFilter enforce={true} claim={"Super"}>
  //     <PatientManagementLayout nofilter
  //       menu={<MenuContainer key={"menu_cnt" + new Date().getTime()} menu="UserConfiguration" activeSection="Facility Configurations" />}
  //       container={<FacilityMessagingConfigurationWizard />} /></ClaimsFilter>
  // },
  /*
  Alerts
  */
  // {
  //   path: '/System Admin/Alerts',
  //   element: <ClaimsFilter enforce={true} claim={"System Admin"}>
  //     <ClinicianManagementLayout menu={<MenuContainer key={"menu_cnt" + new Date().getTime()} menu="Receptionist" activeSection="Alerts" />}
  //       container={<AlertsOverview />}
  //       search={undefined}
  //       breadcrums={[{ name: 'Home', location: '/' + UTSysAdmin }, { name: 'Calendar', active: true }]} /></ClaimsFilter>
  // },
  /*
  Calendar
  */
  // {
  //   path: '/System Admin/Calendar',
  //   element: <ClaimsFilter enforce={true} claim={"System Admin"}>
  //     <ClinicianManagementLayout menu={<MenuContainer key={"menu_cnt" + new Date().getTime()} menu="Receptionist" activeSection="Calendar" />}
  //       container={<CalendarSection calendarType={'Admin'} />}
  //       search={undefined} breadcrums={[{ name: 'Home', location: '/' + UTSysAdmin }, { name: 'Calendar', active: true }]} /></ClaimsFilter>
  // },
  // {
  //   path: '/System Admin/Patient/:id/Calendar',
  //   element: <ClaimsFilter enforce={true} claim={"System Admin"}>
  //     <ClinicianManagementLayout menu={<MenuContainer key={"menu_cnt" + new Date().getTime()} menu="ReceptionistPatient" activeSection="Calendar" />}
  //       container={<CalendarSection calendarType={'Admin_PATIENT'} filter='Patient' />}
  //       breadcrums={[{ name: 'Home', location: '/' + UTSysAdmin },
  //       { name: 'Patient', location: '/' + UTSysAdmin + '/Patient/[PatientID]' },
  //       { name: '[PatientName]', location: '/' + UTSysAdmin + '/Patient/[PatientID]/Dashboard' },
  //       { name: 'Calendar', location: '/' + UTSysAdmin + '/Patient', active: true }]} /></ClaimsFilter>
  // },
  /*
  Calendar Sync
  */
  // {
  //   path: '/System Admin/configuration/Calendar',
  //   element:
  //     <ClaimsFilter enforce={true} claim={"System Admin"}>
  //       <AdminLayout menu={<MenuContainer key={"menu_cnt" + new Date().getTime()}
  //         menu="UserConfiguration" activeSection="Calendar" />}
  //         search={<FacilityManagementSearchBar section={`Calendar configuration for ${Authentication.UserName() && JSON.parse(Authentication.UserName())}`} />}
  //         breadcrums={[{ name: 'Facility Administration', location: "/" + Authentication.getFacilityRole() + '/Configuration' },
  //         { name: 'Calendars', location: '/Facility/Configuration', active: true },
  //         ]}
  //         container={<CalendarSyncConfiguration />} /></ClaimsFilter>
  // },
  // {
  //   path: '/System Admin/configuration/Calendar/:id',
  //   element:
  //     <ClaimsFilter enforce={true} claim={"System Admin"}>
  //       <AdminLayout menu={<MenuContainer key={"menu_cnt" + new Date().getTime()}
  //         menu="UserConfiguration" activeSection="Calendar" />}
  //         breadcrums={[{ name: 'Facility Administration', location: "/" + Authentication.getFacilityRole() + '/Configuration' },
  //         { name: 'Calendars', location: '/Facility/Configuration', active: true },
  //         ]}
  //         container={<CalendarSyncConfiguration />} /></ClaimsFilter>
  // },
  // {
  //   path: '/System Admin/ToDo',
  //   element:
  //     <ClaimsFilter enforce={true} claim={"System Admin"}>
  //       <ClinicianManagementLayout menu={<MenuContainer key={"menu_cnt" + new Date().getTime()}
  //         menu="Clinician" activeSection="To Do" />}
  //         search={<FilteredSearchBar section={'To Do'} />}
  //         breadcrums={[{ name: 'Home', location: "/" + Authentication.getFacilityRole() + '/' },
  //         { name: 'To Do List', location: '/ToDo', active: true },
  //         ]}
  //         container={<ToDoListScreen />} /></ClaimsFilter>
  //   //<ClaimsFilter enforce={true} claim={"System Admin,Clinician"}><ClinicianManagementLayout menu={<MenuContainer key={"menu_cnt" + new Date().getTime()} menu="ClinicianPatient" activeSection="Calendar" />} container={<CalendarSyncConfiguration />} breadcrums={[{ name: 'Home', location: '/Clinician' }, { name: 'Patient', location: '/Clinician/Patient/[PatientID]' }, { name: '[PatientName]', location: '/Clinician/Patient/[PatientID]/Dashboard' }, { name: 'Calendar', location: '/Clinician/Patient', active: true }]} /></ClaimsFilter>
  // },

];

export default AppRoutesSystemAdminitration;

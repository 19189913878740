import { faQuestionCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from 'react';
import { XCircleFill } from 'react-bootstrap-icons';

export default function OnScreenAssist({ items }) {
    const [show, setShow] = useState(false)
    return (
        <div
            style={{ bottom: '0px', right: '0px', zIndex: 101, width: '350px' }}
            className='position-absolute m-2 d-flex flex-column '>
            <div className={'bg-dark rounded shadow position-relative p-3 '.concat(show ? 'hint-background' : 'd-none')}>
                <div onClick={(e) => setShow(!show)} style={{ right: '-5px', top: '-5px',filter: 'drop-shadow(1px 2px 3px rgb(200, 200, 200))' }} className="text-secondary position-absolute"><XCircleFill size={28} /></div>
                {
                    items.map((item, i) =>
                        <div key={"OSI-I-" + i}>
                            <b className='pe-1 text-light'>{item.heading}</b>
                            <small dangerouslySetInnerHTML={{ __html: item.description }}></small>
                            <hr />
                        </div>
                    )
                }
            </div>
            <FontAwesomeIcon style={{ filter: 'drop-shadow(1px 2px 3px rgb(200, 200, 200))' }} onClick={(e) => setShow(!show)} className='align-self-end m-2 ' size="3x" icon={faQuestionCircle} />
        </div>
    )
}

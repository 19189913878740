import React from 'react'
import { Pencil } from 'react-bootstrap-icons'

export default function QBuilderEditWrapper({ enable, isEditing, onEdit, children }) {

    return (
        <div className={'text-start '.concat(isEditing ? 'bg-primary-container opacity-75 shadow' : '')}>
            {enable ? <Pencil className='me-2' onClick={(e) => onEdit()} /> : <></>}
            {children}
        </div>
    )
}

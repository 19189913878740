import React, { useContext, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
// import { faSpinner } from '@fortawesome/free-solid-svg-icons'
import { SectionInterface } from './interfaces/SectionsInterface'
import Flow from './Flow'
import VariablesContext from './context/VariablesContext'
import { ActionInterface } from './interfaces/ActionInterface'
import { AlertActionInterface } from './interfaces/AlertActionInterface'
import { APIFunctions } from '../common/APIFunctions'
import { APIActionInterface } from './interfaces/APIActionInterface'
import ErrorContext from './context/ErrorContext'
import { AuthenticationService } from '../services/AuthenticationService'
import { RedirectInterface } from './interfaces/RedirectInterface'
import { ComponentSourceInterface } from './interfaces/ComponentSourceInterface'
import { NavigationInterface } from './interfaces/NavigationInterface'
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

type Props = {
  flow?: 'Wizard' | 'Form' | undefined
  grid: string | undefined
  template: SectionInterface[]
}

export default function Actions({ flow, grid, template }: Props) {
  const parms = useParams()

  // const [loading, setLoading] = useState(false)
  useEffect(() => {}, [template])
  // States
  // Navigation
  const [section, setSection] = useState(template!['0'].name)
  // Error
  const { invokeError } = useContext(ErrorContext)
  // Variables
  const { variables, setVariablesMap } = useContext(VariablesContext)
  // Validation

  const { clearErrors } = useContext(ErrorContext)

  // Action handler
  const _handleActions = async (actions?: ActionInterface[], _variables?: Map<string, any>): Promise<any> => {
    if (typeof actions === 'undefined') {
      return null
    }
    let _result = undefined
    try {
      // setLoading(true)
      for (const action of actions!)
        switch (action.action.toLowerCase()) {
          case 'api': {
            _result = await APIFunctions.MakeCall(
              APIFunctions.SetURL((action.params! as APIActionInterface).url, _variables),
              (action.params! as APIActionInterface).method,
              APIFunctions.SetBody(_variables, (action.params! as APIActionInterface).body),
              (action.params! as APIActionInterface).encryption,
            ).catch((err) => {
              console.log(err)
              invokeError!(err.message ? err.message : err)
            })
            if (_result === null || typeof _result === 'undefined') {
              // setLoading(false)
              return null
            }
            break
          }
          case 'alert':
            alert((action.params! as AlertActionInterface).message)
            _result = { message: 'read' }
            break
          case 'authenticate': {
            const _request: APIActionInterface = JSON.parse(JSON.stringify(action.params!))
            _request.body = APIFunctions.SetBody(_variables, _request.body)
            _result = await AuthenticationService.login(_request).catch((err) =>
              invokeError!(err.message ? err.message : err),
            )
            if (_result === null || typeof _result === 'undefined') {
              // setLoading(false)
              return null
            }
            break
          }
          case 'redirect':
            window.location.assign(APIFunctions.SetURL((action.params! as RedirectInterface).url, _variables))
            break
          case 'navigate':
            setSection((action.params! as NavigationInterface).component)
            break
          case 'clear':
            return null
          case 'component':
            {
              const _component = (action.params! as ComponentSourceInterface).component.split('.')
              _result =
                _variables?.has(_component[0]) && _variables?.get(_component[0]).has(_component[1])
                  ? _variables?.get(_component[0]).get(_component[1])
                  : null
            }
            break
        }
    } catch (e) {
      console.error(e)
      // setLoading(false)
    } finally {
      // setLoading(false)
      console.log('Finish Actions')
    }
    clearErrors!()
    return _result
  }

  const _handleEvents = async (
    action: string,
    section: string,
    name: string | null,
    value: any,
    actions?: ActionInterface[],
  ) => {
    // Read state variable
    const _variables = new Map<string, any>(variables)
    // Check and set URL variables
    if (parms && Object.keys(parms).length > 0) {
      if (!_variables?.has('URL')) _variables?.set('URL', new Map<string, any>())
      for (const par of Object.keys(parms)) _variables!.get('URL').set(par, parms[par])
    }
    // Add new values to request variables
    if (value) {
      if (!_variables?.has(section)) _variables?.set(section, new Map<string, any>())
      if (!_variables?.get(section).has(name)) _variables?.get(section).set(name, new Map<string, string>())
      _variables!.get(section).set(name, value)
    }
    // Handle Actions
    let _actionVariables: any | undefined = undefined
    switch (action) {
      case 'navigate': {
        const _actions = template?.find((s) => s.name === section)?.actions
        _actionVariables = await _handleActions(_actions, _variables)
        // if (_actionVariables) _variables.set(section, _actionVariables)
        break
      }
      case 'change': {
        _actionVariables = await _handleActions(
          template?.find((s) => s.name === section)?.layout.find((l) => l.name === name)?.actions,
          _variables,
        )
        break
      }
      case 'click': {
        // Direct Parent Actions
        let _actions = template?.find((s) => s.name === section)?.layout.find((l) => l.name === name)?.actions
        // Sub Component Actions
        if (typeof _actions === 'undefined')
          if (!_actions && template?.find((s) => s.name === section)!.layout.length > 0) {
            const templates = template?.find((s) => s.name === section)!.layout
            for (const lo of templates) {
              //const t2 = lo.layout!.find((e) => e.name === name)?.layout?.find((e) => e.name === name)
              const t2 = lo.layout!.find((e) => e.name === name)
              if (t2) _actions = t2.actions
            }
          }
        // Group Actions
        if (typeof _actions === 'undefined') {
          //
          if (!_actions && template?.find((s) => s.name === section)!.layout.length > 0) {
            const templates = template?.find((s) => s.name === section)!.layout
            for (const lo of templates) {
              //const t2 = lo.layout!.find((e) => e.name === name)?.layout?.find((e) => e.name === name)
              const t2 = lo.layout!.find((e) => e.type.toLowerCase() === 'group')
              if (typeof t2 !== 'undefined')
                for (const lob of t2!.layout!) if (lob.name === name) _actions = lob.actions
            }
          }
        }
        _actionVariables = await _handleActions(_actions, _variables)
        // if (_actionVariables) _variables.get(section).set(name, _actionVariables)
        break
      }
      case 'load': {
        let _actions = template?.find((s) => s.name === section)?.layout.find((l) => l.name === name)?.source
        // _actionVariables = await _handleActions(_actions, _variables)
        // Sub Component Actions
        console.log('load event')
        if (typeof _actions === 'undefined')
          if (!_actions && template?.find((s) => s.name === section)!.layout.length > 0) {
            const templates = template?.find((s) => s.name === section)!.layout
            for (const lo of templates) {
              //const t2 = lo.layout!.find((e) => e.name === name)?.layout?.find((e) => e.name === name)
              const t2 = lo.layout!.find((e) => e.name === name)
              if (t2) _actions = t2.source
            }
          }
        // Group Actions
        if (typeof _actions === 'undefined') {
          //
          if (!_actions && template?.find((s) => s.name === section)!.layout.length > 0) {
            const templates = template?.find((s) => s.name === section)!.layout
            for (const lo of templates) {
              //const t2 = lo.layout!.find((e) => e.name === name)?.layout?.find((e) => e.name === name)
              const t2 = lo.layout!.find((e) => e.type.toLowerCase() === 'group')
              if (typeof t2 !== 'undefined') for (const lob of t2!.layout!) if (lob.name === name) _actions = lob.source
            }
          }
        }
        return await _handleActions(_actions, _variables)
        // break
      }
      case 'event': {
        return _handleActions(actions, _variables)
      }
    }
    if (name && _actionVariables && typeof _actionVariables === 'object') {
      if (!_variables?.get(section).has(name)) _variables?.get(section).set(name, new Map<string, any>())
      for (const k of Object.keys(_actionVariables!)) _variables?.get(section)?.get(name).set(k, _actionVariables![k])
    } else if (_actionVariables && typeof _actionVariables === 'object')
      for (const k of Object.keys(_actionVariables!)) _variables?.get(section)?.set(k, _actionVariables![k])
    // Update state variable
    variables?.set(section, _variables.get(section))
    setVariablesMap!(_variables)
    return _variables
  }

  // Actions
  // Navigation
  const _handleOnNavigate = (element: string | null) => {
    // if (!element!) throw 'Navigating to and unknown section'
    try {
      _handleEvents('navigate', section, null, null)
      if (element) setSection(element!)
    } catch (e) {
      console.error(e)
    }
  }
  // Click
  const _handleOnClick = (_section: string, name: string | null) => _handleEvents('click', _section, name, null)
  // change
  const _handleOnChange = (_section: string, name: string, value: any) => _handleEvents('change', _section, name, value)
  // load data
  const _handleLoadDataEvent = async (_section: string, name: string): Promise<any> =>
    _handleEvents('load', _section, name, null)
  // Custom actiond
  const _handleActionEvent = async (_section: string, name: string, value?: string, actions?: ActionInterface[]) =>
    _handleEvents('event', _section, name, value, actions)

  return (
    <>
      {/* {loading ? (
        <div
          style={{
            position: 'absolute',
            textAlign: 'center',
            padding: '16px',
            top: '0px',
            right: '0px',
            boxShadow: '0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important',
            backgroundColor: '#a0a0a0d1',
          }}
        >
          <FontAwesomeIcon icon={faSpinner} spin={true} />
          Loading
        </div>
      ) : (
        <></>
        // <div
        //   style={{
        //     display: 'flex',
        //     flexDirection: 'column',
        //     width: '100%',
        //     height: '100%',
        //     justifyContent: 'center',
        //     alignItems: 'center',
        //     position: 'absolute',
        //   }}
        // >
        //   <FontAwesomeIcon icon={faSpinner} spin={true} />
        //   Loading
        // </div>
      )} */}
      <Flow
        template={template}
        grid={grid}
        flow={flow!}
        section={section}
        onNavigate={_handleOnNavigate}
        onClick={_handleOnClick}
        onChange={_handleOnChange}
        onLoadData={_handleLoadDataEvent}
        actionRunner={_handleActionEvent}
        variables={variables!}
      />
    </>
  )
}

/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from 'react'
import ToggleButton from '../../../../../components/ui/toggle_button/ToggleButton'
import WorkFlowPeopleFilter from '../../../workflow/configuration/facility/wizard/EventType/ProfileOptions/Widgets/WorkFlowPeopleFilter'
import WorkFlowConfigurationContext from '../../../workflow/configuration/WorkFlowConfigurationProvider'

export default function EventTriggerMessageReceived({ link, type, values, eventId }) {
    const PROPERTY_TYPE = 'message_filter_options'
    const { saveProperties, loadProperties } = useContext(WorkFlowConfigurationContext)
    const [options, setOptions] = useState({ messageTypes: [], receivedFrom: {}, subject: '' })
    useEffect(() => { _load() }, [])

    const _load = async () => setOptions(await loadProperties(PROPERTY_TYPE, link))

    const _setOption = (p, v) => {
        var _options = options
        _options[p] = v
        setOptions({ ..._options })
    }
    useEffect(() => { if (options) saveProperties(PROPERTY_TYPE, link, options) }, [options])

    const _setMessageType = (type) => {
        var _mt = options.messageTypes
        if (_mt.includes(type)) _mt.splice(_mt.indexOf(type), 1)
        else _mt.push(type)
        _setOption('messageTypes', _mt)
    }

    return (
        <div className=''>
            <div>
                <label>Message Type</label>
                <div className={'row m-2 '}>
                    <div className='col-3'>
                        <ToggleButton onToggle={() => _setMessageType('IMS')} style={'tick'} defaultValue={options.messageTypes.includes('IMS')}>Internal Message</ToggleButton>
                    </div>
                    <div className='col-3'>
                        <ToggleButton onToggle={() => _setMessageType('SMS')} style={'tick'} defaultValue={options.messageTypes.includes('SMS')}>SMS</ToggleButton>
                    </div>
                    <div className='col-3'>
                        <ToggleButton onToggle={() => _setMessageType('WhatsApp')} style={'tick'} defaultValue={options.messageTypes.includes('WhatsApp')}>WhatsApp</ToggleButton>
                    </div>
                    <div className='col-3'>
                        <ToggleButton onToggle={() => _setMessageType('Email')} style={'tick'} defaultValue={options.messageTypes.includes('Email')}>Email</ToggleButton>
                    </div>
                </div>
            </div>
            <div>
                <label>Received From</label>
                <WorkFlowPeopleFilter value={options.receivedFrom} onChange={(r) => _setOption('receivedFrom', r)} />
                {/* <div className='row m-2'>
                    <div className='col-3'>
                        <div>Patient</div>
                        <select className='form-control form-control-sm'>
                            <option>Patients Names</option>
                            <option>Any</option>
                        </select>
                    </div>
                    <div className='col-3'>
                        <div>Clinicians</div>
                        <select className='form-control form-control-sm'>
                            <option>Clinicians Names</option>
                            <option>Any</option>
                        </select>
                    </div>
                    <div className='col-3'>
                        Specified Address / Number / Person
                        <input className='form-control form-control-sm' placeholder='Received from ' />
                    </div> */}
                {/* </div> */}
            </div>
            <div>
                <label>Subject contains</label>
                <div className='row m-2'>
                    <input value={options.subject} onChange={(e) => _setOption('subject', e.target.value)} className='form-control form-control-sm' placeholder='Subject' />
                </div>
            </div>


        </div>
    )
}

import React, { useContext, useEffect, useState } from 'react'
import StickyNavigationContext from '../../../libs/providers/StickyNavigationProvider'
import NewUserComplete from './admin_add_user/NewUserComplete'
import NewUserDetails from './admin_add_user/NewUserDetails'
import NewUserFacility from './admin_add_user/NewUserFacility'
import NewUserRole from './admin_add_user/NewUserRole'

export default function RegisterUser({ facility }) {
    const [step, setStep] = useState(1)
    // const [userID, setUserID] = useState()
    const [user, setUser] = useState()
    const [facilityID, setFacilityID] = useState(facility ? facility : undefined)
    const [facilityRoles, setfacilityRoles] = useState([])
    const { setSticky, setPreventMessage } = useContext(StickyNavigationContext)

    useEffect(() => {
        switch (step) {
            case 1: // User Details
                setSticky(true)
                setPreventMessage('New User', 'Are you sure you would like to cancel creating a new user?')
                break;
            case 2: // Facility
                setSticky(true)
                setPreventMessage('New User', 'You have created new user <b>' + user.firstName + ' ' + user.lastName + '</b> but have not assigned a facility.<br/><br/> You can assign the facility at a later stage by searching for the user and selecting facility menu. <br/><br/>Would you like to complete user registration at a later stage ? ')
                break;
            // case 3: // Add
            //     return _template(<><NewUserFacilityAdd changeStep={setStep} UserId={userID} setFacilityID={setFacilityID} /></>)
            case 4: // Select
                setSticky(true)
                setPreventMessage('New User', 'You have created new user <b>' + user.firstName + ' ' + user.lastName + '</b> but have not assigned a role.<br/><br/> You can assign a role at a later stage by searching for the user and selecting facility menu. <br/><br/>Would you like to complete user registration at a later stage ?')
                break;
            case 5: // Role
                // setPreventMessage('New User', 'Are you sure you would like to cancel creating a new user?')
                setSticky(false)
                break;
            default: break;
        }
        // if (step !== 5) {
        //     // onbeforeunload = (event) => { event.preventDefault(); return false; }
        //     // setPreventMessage('New User', 'Are you sure you would like to cancel creating a new user?')
        // }
        // else
        // onbeforeunload = (event) => { return }
    }, [step])

    const _template = (children) => {
        return <div className="Wizard top-center">{children}</div>
    }

    switch (step) {
        case 1: // User Details
            return _template(<><NewUserDetails defaultFacility={facility} changeStep={setStep} user={user} onChangeUser={setUser} /></>)
        case 2: // Facility
            return _template(<><NewUserFacility UserId={user.id} defaultFacility={facility} facility={setFacilityID} changeStep={setStep} /></>)
        // case 3: // Add
        //     return _template(<><NewUserFacilityAdd changeStep={setStep} UserId={userID} setFacilityID={setFacilityID} /></>)
        case 4: // Select
            return _template(<><NewUserRole rolesActive={facilityRoles} setRolesActive={setfacilityRoles} defaultFacility={facility} UserID={user.id} changeStep={setStep} FacilityID={facilityID} /></>)
        case 5: // Role
            return _template(<><NewUserComplete  defaultFacility={facility} UserId={user.id} changeStep={setStep} /></>)
        default: return <></>
    }
}

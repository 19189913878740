import React, { useEffect, useState } from 'react'
import { Clock } from 'react-bootstrap-icons'
import DateTimePickerFunctions from './DateTimePickerComponents/DateTimePickerFunctions'

const functions = new DateTimePickerFunctions()
export default function TimePickerInput({ small, onPicker, onChange, value }) {
    const [time, setTime] = useState(value ? String(value.getHours()).lpad('0',2) + ":" + String(value.getMinutes()).lpad('0',2) : undefined)

    useEffect(() => { setTime(functions.formatTimeFinal(String(value.getHours()).lpad('0',2) + ":" + String(value.getMinutes()).lpad('0',2))) }, [value.getHours()])
    useEffect(() => { setTime(functions.formatTimeFinal(String(value.getHours()).lpad('0',2) + ":" + String(value.getMinutes()).lpad('0',2))) }, [value.getMinutes()])

    const _handleOnChange = (v) => {
        try {
            v = functions.formatTimeFinal(v)
            setTime(v)
            if (onChange) onChange(v)
        } catch (e) { }
    }

    const _handleOnBlur = (e) =>
        _handleOnChange(e.target.value)

    const _handleFocues = (e) => {
        setTime('')
    }

    return (
        <div className='d-flex control-group rounded ms-1' style={{ background: "#d6d7d7" }}>
            <input
                // value={functions.formatTime(time)}
                value={time}
                onFocus={_handleFocues}
                // onChange={(e) => setDateTime(functions.formatTime(e.target.value, DateTime))}
                onBlur={_handleOnBlur}
                onChange={(e) => setTime(functions.formatTime(e.target.value))}
                className={'form-control '.concat(small ? 'form-control-sm' : '')} placeholder='Time'
            />
            <Clock onClick={() => onPicker('Time')} className='p-1 rounded text-primary' size={30} />
        </div>
    )
}

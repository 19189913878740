import React from 'react'
import { LayoutInterface } from './interfaces/LayoutInterface'
import SampleComponent from './sample/SampleComponent'
import ThemeComponent from './sample/ThemeComponent'
import DebugComponent from './debug/DebugComponent'
import InputComponent from './input/InputComponent'
import ButtonComponent from './button/ButtonComponent'
import PasswordComponent from './input/PasswordComponent'
import Layout from './Layout'
import OptionsComponent from './options/OptionsComponent'
import SearchComponent from './search/SearchComponent'
import TextComponent from './text/TextComponent'
import OptionsGridComponent from './options/OptionsGridComponent'
import FileComponent from './file/FileComponent'
// import TableComponent from './table/TableComponent'
import DynamicTableComponent from './table/DyncamicTableComponent'
import MenuBarWidget from './menu/MenuBarComponent'
import ColourComponent from './colour/ColourComponent'
import ImageFileComponent from './file/ImageFileComponent'
import FontFileComponent from './file/FontFileComponent'
import { ActionInterface } from './interfaces/ActionInterface'
import SourceGroup from './groups/SourceGroup'
import DateComponent from './date/DateComponent'
import ToggleButtonComponent from './button/ToggleButtonComponent'

type Props = {
  section: string
  layout: LayoutInterface[] | LayoutInterface
  onChange?: (s: string, n: string, v: any) => void
  onClick: (s: string, n: string) => void
  onLoadData?: (s: string, n: string) => Promise<any>
  variables: Map<string, any>
  actionRunner: (s: string, n: string, v?: string, a?: ActionInterface[]) => Promise<any>
}
const Component = ({ section, layout, variables, onChange, onClick, onLoadData, actionRunner }: Props) => {
  // @ts-ignore : This is to handle more events on the push stack for future use
  const _handleChangeEvent = (name: string, value: any, value2?: any, value3?: any) => {
    console.log('_handleChangeEvent', name, value, value2)
    if (value2) onChange!(section, name + '.' + value, value2)
    else onChange!(section, name, value)
  }
  const _handleGroupChangeEvent = (_section: string, _name: string, _value: any, _value2?: any, _value3?: any) => {
    if (_value2) onChange!(_section, _name + '.' + _value, _value2)
    else onChange!(_section, _name, _value)
  }
  const _handleClickEvent = (name: string, value2?: string) => {
    if (value2) onClick(section, value2)
    else onClick(section, name)
  }
  const _handleLoadDataEvent = (name: string, value2?: string) => {
    if (value2) return onLoadData!(section, value2)
    else return onLoadData!(section, name)
  }
  const _handleActionEvent = (name: string, value?: string, actions?: ActionInterface[]) =>
    actionRunner!(section, name, value, actions)

  if (Array.isArray(layout)) return <></>
  switch (layout.type.toLowerCase()) {
    case 'example':
      return <SampleComponent name={layout.name} />
    case 'theme':
      return <ThemeComponent name={layout.name} onChange={_handleChangeEvent} />
    case 'input': {
      return (
        <InputComponent
          key={'Input' + layout.name}
          style={layout.class}
          name={layout.name}
          defaultValue={layout.defaultValue}
          onChange={(n, v) => _handleChangeEvent(n, v)}
          placeholder={layout.placeholder}
        />
      )
    }
    case 'password': {
      return (
        <PasswordComponent
          key={'Password' + layout.name}
          style={layout.class}
          name={layout.name}
          onChange={_handleChangeEvent}
          placeholder={layout.placeholder}
        />
      )
    }
    case 'date': {
      return (
        <DateComponent
          key={'Date' + layout.name}
          style={layout.class}
          name={layout.name}
          onChange={_handleChangeEvent}
          placeholder={layout.placeholder}
        />
      )
    }
    case 'debug':
      return <DebugComponent key={'Debug' + layout.name} />
    case 'group': {
      return (
        <div className={layout.grid}>
          {layout.layout!.map(function (_l) {
            return (
              <Layout
                key={'Layout-' + _l.name}
                section={section}
                layouts={_l}
                // onUpdateAndExecute={_handleUpdateAndExecute}
                // onChange={(n, v) => _handleChangeEvent(n, v)}
                onChange={_handleGroupChangeEvent}
                onClick={_handleClickEvent}
                onLoadData={_handleLoadDataEvent}
                actionRunner={actionRunner}
                variables={variables}
              ></Layout>
            )
          })}
        </div>
      )
    }
    case 'sourcegroup': {
      return (
        <div className={layout.grid}>
          <SourceGroup
            key={'SourceGroup-' + layout.name}
            name={layout.name}
            section={section}
            layout={layout}
            // onUpdateAndExecute={_handleUpdateAndExecute}
            // onChange={(n, v) => _handleChangeEvent(n, v)}
            onChange={_handleChangeEvent}
            onClick={_handleClickEvent}
            onLoadData={_handleLoadDataEvent}
            actionRunner={actionRunner}
            variables={variables}
          ></SourceGroup>
        </div>
      )
    }
    case 'options': {
      return (
        <OptionsComponent
          key={'Options' + layout.name}
          className={layout.class}
          options={layout.options}
          name={layout.name}
          onChange={_handleChangeEvent}
        />
      )
    }
    case 'search': {
      return (
        <SearchComponent
          key={'Search' + layout.name}
          name={layout.name}
          className={layout.class}
          results={layout.results!}
          onChange={_handleChangeEvent}
          actionRunner={_handleActionEvent}
          addAction={layout.addAction}
        />
      )
    }
    case 'text':
      return <TextComponent key={'Text-' + layout.name} text={layout.text!} />
    case 'optionsgrid':
      return (
        <OptionsGridComponent
          key={'OptionsGrid-' + layout.name}
          name={layout.name}
          options={layout.options!}
          defaultValue={layout.defaultValue}
          onChange={_handleChangeEvent}
          placeholder={layout.placeholder}
        />
      )
    case 'button':
      return (
        <ButtonComponent
          key={'Button-' + layout.name}
          style={layout.class}
          name={layout.name}
          onClick={_handleClickEvent}
          text={layout.text!}
        />
      )
    case 'file':
      return <FileComponent key={'File-' + layout.name} name={layout.name} onChange={_handleChangeEvent} />
    // case 'table':
    //   return <TableComponent onLoadData={_handleLoadDataEvent} onChange={_handleChangeEvent} {...layout} />
    case 'dynamictable':
      return (
        <DynamicTableComponent
          key={'DynamicTable-' + layout.name}
          variables={variables}
          onLoadData={_handleLoadDataEvent}
          onChange={_handleChangeEvent}
          {...layout}
        />
      )
    case 'menubar':
      return (
        <MenuBarWidget
          // variables={variables}
          // onLoadData={_handleLoadDataEvent}
          key={'MenuBar-' + layout.name}
          onChange={_handleChangeEvent}
          onClick={_handleClickEvent}
          onLoadData={_handleLoadDataEvent}
          actionRunner={actionRunner}
          variables={variables}
          layout={layout}
          {...layout}
        />
      )
    case 'colour':
      return (
        <ColourComponent
          key={'Colour-' + layout.name}
          // variables={variables}
          // onLoadData={_handleLoadDataEvent}
          onChange={_handleChangeEvent}
          // onClick={_handleClickEvent}
          defaultValue={layout.defaultValue!}
          // onLoadData={_handleLoadDataEvent}
          // variables={variables}
          layout={layout}
          {...layout}
        />
      )
    case 'imagefile':
      return (
        <ImageFileComponent
          key={'IMageFile-' + layout.name}
          // variables={variables}
          // onLoadData={_handleLoadDataEvent}
          onChange={_handleChangeEvent}
          // onClick={_handleClickEvent}
          defaultValue={layout.defaultValue!}
          // onLoadData={_handleLoadDataEvent}
          // variables={variables}
          layout={layout}
          {...layout}
        />
      )
    case 'fontfile':
      return (
        <FontFileComponent
          key={'FontFile-' + layout.name}
          // variables={variables}
          // onLoadData={_handleLoadDataEvent}
          onChange={_handleChangeEvent}
          // onClick={_handleClickEvent}
          defaultValue={layout.defaultValue!}
          // onLoadData={_handleLoadDataEvent}
          // variables={variables}
          layout={layout}
          {...layout}
        />
      )
    case 'togglebutton':
      return <ToggleButtonComponent key={'ToggleButton-' + layout.name} {...layout} onChange={_handleChangeEvent} />
  }
  return <div key={'Unkown-' + layout.name}>Layout : |{layout.type}|</div>
}

export default Component

import API from "../API";

var MessagingServiceProvider = {
    async UpdateProvider(provider) {
        var result = await API.process(
            'api/Messaging/ServiceProvider',
            'PUT',
            provider
        ).catch((err) => {
            throw err;
        })

        return result
    },
    async ListFaciltyProviders(faciltyId) {
        var result = await API.process(
            `api/Messaging/ServiceProvider/${faciltyId}`,
            'GET',
            null
        ).catch((err) => {
            throw err;
        })

        return result
    },
    async RemoveProvider(providerId) {
        var result = await API.process(
            `api/Messaging/ServiceProvider/${providerId}`,
            'DELETE',
            null
        ).catch((err) => {
            throw err;
        })

        return result
    },
    /*
    Services
    */

    async GetServices(providerId) {
        var result = await API.process(
            `api/Messaging/ServiceProvider/${providerId}/Services`,
            'GET',
            null
        ).catch((err) => {
            throw err;
        })

        return result
    },
    async UpdateService(providerId, service) {
        var result = await API.process(
            `api/Messaging/ServiceProvider/${providerId}/Service`,
            'PATCH',
            service
        ).catch((err) => {
            throw err;
        })

        return result
    },
    /*

    */
    async ListAvailableServiceProviders(UserId, FacilityId, Type) {
        var result = await API.process(
            `api/Messaging/ServiceProvider/Providers/${UserId}/${FacilityId ? FacilityId : -1}/${Type}`,
            'GET',
            null
        ).catch((err) => {
            throw err;
        })

        return result
    },
    /*
    logs
    */
    async GetServiceLogs(ServiceProviderSerivceId) {
        var result = await API.process(
            `api/Messaging/ServiceProvider/Service/${ServiceProviderSerivceId}/Logs`,
            'GET',
            null
        ).catch((err) => {
            throw err;
        })

        return result
    },
    /*

    */
    async FindServiceProviderAccountForUser(UserId,Facility) {
        var result = await API.process(
            `api/MessagingService/Service/Account/User/${UserId}/${Facility}`,
            'GET',
            null
        ).catch((err) => {
            throw err;
        })

        return result
    },
    async UpdateUserEmailAddcount(UserId,Facility,Props) {
        var result = await API.process(
            `api/MessagingService/Service/Account/User/${UserId}/${Facility}`,
            'PUT',
            Props
        ).catch((err) => {
            throw err;
        })

        return result
    },
}

export default MessagingServiceProvider;
import React, { useContext, useEffect, useState } from 'react'
import { ChevronDoubleLeft, ChevronDoubleRight } from 'react-bootstrap-icons'
import CalendarContext from '../../CalendarProvider'

export default function CalendarYearFilter({ view, onChangeView }) {
    const _POPUP_KEY = 'CalendarYearFilter'
    const { setFilter, filters, setActivePopup, activePopup } = useContext(CalendarContext)
    const [range, setRange] = useState([])

    const [exapnd, setExpand] = useState(false)

    useEffect(() => {
        GetDefaultYear()
    }, [])

    const _handleSetValue = (year) => {
        setExpand(false)
        setFilter({ year: year })
        setActivePopup(undefined)
    }

    const GetDefaultYear = () => {
        const YearRange = parseInt(new Date().getFullYear() / 10);
        setRange([YearRange * 10, YearRange * 10 + 10])
    }

    const _generateYears = () => {
        var rows = []
        var cols = []
        var c = 0;
        for (var i = range[0]; i <= range[1]; i++, c++) {
            if (c % 3 == 0) {
                rows.push(<tr>{cols}</tr>)
                cols = []
            }
            const x = i
            cols.push(<td className={'date-picker-items '.concat(filters.year === x ? ' rounded active ' : '')} key={x} onClick={(e) => _handleSetValue(x)}><div style={{ cursor: 'pointer' }} className='m-1 text-center'>{i}</div></td>)
        }
        rows.push(<tr>{cols}</tr>)
        return <table className='table'>{rows}</table>
    }

    const _handlePreviousRange = () => setRange([range[0] - 10, range[1] - 10])
    const _handleNextRange = () => setRange([range[0] + 10, range[1] + 10])

    return (
        <div style={{ cursor: 'pointer' }} className='position-relative calendar-filter'>
            <div onClick={() =>
                setActivePopup(activePopup !== _POPUP_KEY ? _POPUP_KEY : undefined)
                //  setExpand(!exapnd)
            }

            >
                <div><b>Year</b></div>
                <div>{filters.year}</div>
            </div>

            <div className={'position-absolute rounded shadow bg-secondary-container '.concat(activePopup === _POPUP_KEY ? 'show' : 'hide')}>
                <div className='d-flex justify-content-between align-items-center date-picker-head '>
                    <button onClick={(e) => _handlePreviousRange()} className='btn'><ChevronDoubleLeft /></button>
                    {
                        range.length > 1
                            ?
                            <div>{range[0]} - {range[1]}</div>
                            :
                            <></>
                    }
                    <button onClick={(e) => _handleNextRange()} className='btn'><ChevronDoubleRight /></button>
                </div>
                {
                    _generateYears()
                }
            </div>
        </div >
    )
}

import React, { useEffect, useState } from 'react'
import Calendars from '../../../../libs/api/services/Calendars'

const CalendarFacilityContext = React.createContext()

export function CalendarFacilityProvider({ children }) {
    const [activeCalendarEvent, setActiveCalendarEvent] = useState()
    const [state, setState] = useState({
        events: []
    })
    const _handleSelectCalendarEvent = (v) => {
       setActiveCalendarEvent(v)
    }

    const _handleSetEventActiveOn = (t) => {
        var _activeCalendarEvent = activeCalendarEvent

        var _c = _activeCalendarEvent.availableCalendars ? _activeCalendarEvent.availableCalendars.find(c => c.calendarType && c.calendarType.id === t.id) : undefined
        if (_c)
            _activeCalendarEvent.availableCalendars.splice(_activeCalendarEvent.availableCalendars.indexOf(_c), 1)
        else
            _activeCalendarEvent.availableCalendars.push({ CalendarType: t })

        Calendars
            .UpdateCalendarEvent(_activeCalendarEvent)
            .then((r) => setActiveCalendarEvent(r))
    }

    const _handleSetWhenTriggered = (t) => {
        Calendars
            .UpdateCalendarEvent({ ...activeCalendarEvent, whenTriggered: t })
            .then((r) => setActiveCalendarEvent(r))
    }

    const _handlesetCalendarEventProperty = (k, v) => {
        var _activeCalendarEvent = activeCalendarEvent

        var _c = _activeCalendarEvent.properties ? _activeCalendarEvent.properties.find(c => c.name === k) : undefined
        if (_c)
            _c.value = v
        else
            _activeCalendarEvent.properties.push({ name: k, value: v })
        // _props.name
        // _props.value
        Calendars
            .UpdateCalendarEvent({ ..._activeCalendarEvent })
            .then((r) => setActiveCalendarEvent(r))
    }


    const LoadCalendarEvents = async () => {
        setState({ ...state, events: await Calendars.ListCalendarEvents() })
    }

    useEffect(() => { LoadCalendarEvents() }, [])

    return (
        <CalendarFacilityContext.Provider
            value={{
                selectCalendarEvent: _handleSelectCalendarEvent,
                setEventActiveOn: _handleSetEventActiveOn,
                setWhenTriggered: _handleSetWhenTriggered,
                setCalendarEventProperty: _handlesetCalendarEventProperty,
                calendarEvent: activeCalendarEvent ? activeCalendarEvent : {},
                activeCalendarEvent,
                LoadCalendarEvents,
                listCalendarEvents: state.events
            }}
        >
            {children}
        </CalendarFacilityContext.Provider>
    )
}
export default CalendarFacilityContext
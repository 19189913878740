import React, { useContext, useEffect, useState } from 'react'
import { Flag, PlusCircle } from 'react-bootstrap-icons'
import { useNavigate, useParams } from 'react-router-dom'
import Authentication from '../../../../libs/api/services/Authentication'
import Patient from '../../../../libs/api/services/Patient'
import QuestionnaireAssignment from '../../../../libs/api/services/QuestionnaireAssignment'
import CommonFunctionsContext from '../../../common/CommonFunctionsProvider'
import PatientContext from '../../patient/management/PatientProvider'
import FormAssignmentAdmin from '../assignment/FormAssignmentAdmin'

export default function PatientForms() {
    const { setSectionInfo } = useContext(CommonFunctionsContext)
    const { activePatient } = useContext(PatientContext)
    const navigate = useNavigate()
    const { id, questionair } = useParams()
    const [mode, setMode] = useState('')
    const [patient, setPatient] = useState()
    const [questionnaires, setQestionnaires] = useState([])

    const _handleOnAdd = (v) => {
        setMode('')
        load()
    }

    const _setupAdd = () => {
        Patient.GetById(id)
            .then((r) => {
                setPatient(r)
                setMode('Add')
            })

    }
    useEffect(() => {
        if (activePatient)
            setSectionInfo(`Forms for ${activePatient.userProfile.firstName} ${activePatient.userProfile.lastName} at ${Authentication.getFacilityName()}`);
        else
            setSectionInfo(`Forms`);
    }, [activePatient])

    const load = () => {
        QuestionnaireAssignment
            .GetPatientQuestionnairesByType(id, 'Form')
            .then((r) => {
                setQestionnaires(r)
            })
    }

    useEffect(() => {
        load()
    }, [])

    if (mode === "Add" && patient)
        return (<FormAssignmentAdmin
            onDone={(v) => _handleOnAdd(v)}
            onCancel={(e) => setMode('')}
            skip={[2]}
            startAt={1}
            defaultValues={{ user: patient }}
        />)
    else
        return (
            <div>
                <div className='d-flex flex-row text-center p-3 bg-secondary-container'>
                    <div
                        onClick={(e) => _setupAdd()}
                        style={{ cursor: 'pointer' }} className=' bg-primary-container m-2 p-2 rounded '>
                        <PlusCircle
                        /> New Form
                    </div>
                    {/* <div style={{ cursor: 'pointer' }} className=' bg-primary-container m-2 p-2 shadow '>
                        Assigned (100)
                    </div>
                    <div style={{ cursor: 'pointer' }} className=' bg-secondary-container m-2 p-2'>
                        Complete (99)
                    </div>
                    <div style={{ cursor: 'pointer' }} className=' bg-secondary-container m-2 p-2'>
                        Incomplete (1)
                    </div> */}
                </div>
                <div className='row text-center my-2'>
                    <div className='col-6'>
                        <b>Name</b>
                    </div>
                    <div className='col-3'>
                        <b>Requested On</b>
                    </div>
                    <div className='col-3'>
                        <b>Options</b>
                    </div>
                </div>

                {
                    questionnaires.map((q) =>
                        <div className='row'>
                            <div className='col-6'>
                                <Flag className='text-danger me-2' />
                                {q.questionnaire.name}
                            </div>
                            <div className='col-3'>
                                {q.assignedDate.substring(0, 19)}
                            </div>
                            <div className='col-3 text-center'>
                                <button
                                    onClick={(e) => navigate('/' + Authentication.getFacilityRole() + '/Patient/' + id + '/Form/' + q.questionnaire.id + "/" + q.id)}
                                    className='btn btn-sm'>View</button>
                            </div>
                        </div>
                    )
                }
            </div>
        )
}

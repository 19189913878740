import React, { useEffect, useState } from 'react'
import { SectionInterface } from './interfaces/SectionsInterface'
import { ThemeProvider } from './context/ThemeContext'
import { VariablesProvider } from './context/VariablesContext'
import { ErrorProvider } from './context/ErrorContext'
import Actions from './Actions'
import { APIActionInterface } from './interfaces/APIActionInterface'
import { APIFunctions } from '../common/APIFunctions'
type Template = {
  flow?: 'Wizard' | 'Form' | undefined
  grid: string | undefined
  sections: SectionInterface[]
}
type Props = {
  template?: Template
  url?: APIActionInterface
}

const loadFromURL = async (url: APIActionInterface): Promise<Template | undefined> => {
  try {
    const template = await APIFunctions.MakeCall(url.url, url.method, url.method, url.encryption!)
    return template as Template
  } catch (e) {
    console.error(e)
  }
  return
}

const Wizard = (params: Props) => {
  const [template, setTemplate] = useState<Template>()

  useEffect(() => {
    const loadData = async () => {
      const templ = await loadFromURL(params.url!)
      if (templ) setTemplate(templ)
    }
    if (params.url!) {
      loadData()
    } else {
      setTemplate(params.template)
    }
  }, [params])

  return (
    <ErrorProvider>
      <VariablesProvider>
        <ThemeProvider>
          {/* <ErrorComponent /> */}
          {!template! ? (
            <p>Loading</p>
          ) : (
            <Actions grid={template.grid} flow={template.flow} template={template.sections} />
          )}
        </ThemeProvider>
      </VariablesProvider>
    </ErrorProvider>
  )
}

export default Wizard

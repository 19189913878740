import React, { useContext, useEffect, useState } from 'react'
import ToggleButton from '../../../../../../../../components/ui/toggle_button/ToggleButton'
import CalendarContext from '../../../../CalendarProvider'
import EventDetailsComponent from '../../../../common/EventDetails/EventDetailsComponent'

export default function EventLink({ event, option, onSetProperty, properties, who }) {
  const [linkPatient, setLinkPatient] = useState(false)
  const { filters } = useContext(CalendarContext)

  useEffect(() => {
    onSetProperty('linked', linkPatient)
    onSetProperty('who', who)
  }, [linkPatient])

  // return (
  return (
    <div>
      <div>
        <ToggleButton onToggle={setLinkPatient} defaultValue={linkPatient} style={'tick'}><div>Link event to {who} calendar?</div></ToggleButton>
      </div>
      {linkPatient ? <EventDetailsComponent evt={{ ...event, calendar: filters.calendar }} onSetProperty={onSetProperty} properties={properties} option={option} /> : <></>}
    </div>)
  // <div>
  //   <div>
  //     <ToggleButton onToggle={setLinkPatient} style={'tick'}><div>Link event to {who} calendar?</div></ToggleButton>
  //   </div>
  //   {linkPatient ? <EventDetailsComponent evt={event} onSetProperty={onSetProperty} properties={properties} /> : <></>}
  // </div>
  // )
}

import React, { useState } from 'react'
import FacilityManagementComponent from './facility/FacilityManagementComponent'

export default function NewUserFacility({ changeStep, UserId, facility,  }) {
    const [error, setError] = useState()

    const _selectFacility = (id) => {
        facility(id)
        changeStep(4)
    }

    const _handleFacilityAdded = (id) => {
        facility(id)
        changeStep(4)
    }

    return (
        <>
            <div className="head">
                <h1>Facility</h1>
                <p>Please select the facility this user is linked to</p>
                <div className="wizard-navigation-steps">
                    <span className="wizard-navigation-step in-progress"></span>
                    <span className="wizard-navigation-step "></span>
                </div>
                <div className="error-details">{error}</div>
            </div>
            <div className="body">
                <FacilityManagementComponent onSelectFacility={(id) => _selectFacility(id)} onAddFacility={(id) => _handleFacilityAdded(id)} onError={setError} />
            </div>
            <div className="tail"><button onClick={(e) => changeStep(1)} className="btn ng-buttons">Back</button></div>
        </>
    )
}

import React, { useContext } from 'react'
import { InfoCircleFill } from 'react-bootstrap-icons'
import QuestionnaireLineItem from './QuestionnaireLineItem'
import QuestionnaireContexet from './QuestionnaireProvider'

export default function QuestionnaireEntries() {
    const { questionnaires } = useContext(QuestionnaireContexet)

    return (
        <div className='d-flex flex-column flex-grow-1'>
            {
                questionnaires && questionnaires.length > 0 ? questionnaires
                    .map((q) =>
                        <QuestionnaireLineItem item={q} />
                    )
                    : <div className='align-self-center align-items-center p-2 m-2 d-flex flex-column text-center'><InfoCircleFill className='m-2' size={30} /> There are no questionniares / forms generated for this item</div>
            }
        </div>
    )
}

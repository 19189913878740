import React, { useContext, useState } from 'react'
import { ICONS } from '../../../../components/icons/GenericIconPicker'
import Authentication from '../../../../libs/api/services/Authentication'
import WorkFlow from '../../../../libs/api/services/WorkFlow'
import CommonFunctionsContext from '../../../common/CommonFunctionsProvider'
import WorkFlowDetailsScreen from '../management/WorkFlowDetailsScreen'
// import WorkFlow from '../../../../libs/api/services/WorkFlow'

export default function WorkFlowDashboardLink({ r, options }) {
    const [processing, setProcessing] = useState()
    const { createModal } = useContext(CommonFunctionsContext)
    var _loggedInClinician = undefined
    var _loggedInAdmin = undefined
    var _loggedInPatient = undefined

    if (Authentication.getFacilityRole() === 'Clinician') _loggedInClinician = Authentication.UserId()
    if (Authentication.getFacilityRole() === 'Admin') _loggedInAdmin = Authentication.UserId()
    if (Authentication.getFacilityRole() === 'Patient') _loggedInPatient = Authentication.UserId()

    const _handleProcessWorkFlow = () => {
        setProcessing('Processing')

        WorkFlow
            //Module, Trigger, Payload
            .TriggerAutomation('Dashboard', 'DASHBOARD_CARD', {
                "WorkFlowEventID": r.workflowID,
                "InitiatedByUser": Authentication.UserId(),
                "userId": options.userId,
                "facility": Authentication.getFacility(),
                "clinician": options.clinician ? options.clinician : _loggedInClinician,
                "admin": options.admin ? options.admin : _loggedInAdmin,
                "questionnaire": options.questionnaire,
                "assignment": options.assignment,
                "patient": options.patient,
                "calendar": options.calendar,
                "calendarEvent": options.calendarEvent,
            }).catch((e) => setProcessing(e.message)).then((x) => {
                // window.location = '/workflow/' + x[0].id
                setProcessing()
                createModal(
                    //c,t,i
                    <WorkFlowDetailsScreen id={x[0].id} />,
                    null,
                    null
                )
            })
        // WorkFlow
        //     //Module, Trigger, Payload
        //     .TriggerAutomation('Dashboard', 'DASHBOARD_CARD', {
        //         "WorkFlowEventID": r.workflowID,
        //         "InitiatedByUser": Authentication.UserId(),
        //         "userId": options.userId,
        //         "facility": Authentication.getFacility(),
        //         "clinician": options.clinician ? options.clinician : _loggedInClinician,
        //         "admin": options.admin ? options.admin : _loggedInAdmin,
        //         "questionnaire": options.questionnaire,
        //         "assignment": options.assignment,
        //         "patient": options.patient,
        //         "calendar": options.calendar,
        //         "calendarEvent": options.calendarEvent,
        //     }).catch((e) => setProcessing(e.message)).then((x) => {
        //         window.location = '/workflow/' + x[0].id
        //     })
        // alert(JSON.stringify(r.workflowID))
    }
    return (
        <div onClick={_handleProcessWorkFlow} className='p-1 rounded d-flex align-items-center' style={{ cursor: 'pointer', background: r.colour }}>{r.icon && r.icon.length > 0 && ICONS.find((c) => c.name === r.icon[0]) ? ICONS.find((c) => c.name === r.icon[0]).icon : <></>}<div className='ms-2'>{processing ? processing : r.link}</div></div>
    )
}

import React, { useContext, useEffect, useRef, useState } from 'react'
import { Clock, ClockHistory, EyeSlashFill, Person, TrashFill, XCircle } from 'react-bootstrap-icons'
import ToastDialog from '../../../../../../components/dialogs/toast_dialog/ToastDialog'
import PatientHistoryContext from '../../../../patient/history/PatientHistoryProvider'

export default function StatsNotes({ eventId, newRef }) {
    const { ADD_NOTE, LIST_NOTES, REMOVE_NOTE, notes } = useContext(PatientHistoryContext)
    const [filtered, setFiltered] = useState([])
    const [visible, setVisible] = useState(false)
    const isMounted = useRef(false)
    const note = useRef()
    const [confirmDelete, setConfirmDelete] = useState({ title: '', description: '', show: false, action: undefined })
    const [status, setStatus] = useState({})

    useEffect(() => {
        return () => { isMounted.current = false; }
    }, [])
    useEffect(() => {
        if (isMounted.current)
            setVisible(true)

        isMounted.current = true
    }, [newRef])

    useEffect(() => {
        // filter relevence

        // setFiltered([notes[0]])
        setFiltered(notes)
    }, [notes])

    const _resetNoteLabel = () => {
        note.current.placeholder = 'Note';
    }
    const _handleOnDeleteNote = (PatientHistoryId, noteId) => {

        setConfirmDelete({
            title: 'Remove Note?', description: 'Are you sure you would like to remove note?', show: true,
            action: () => {
                REMOVE_NOTE(PatientHistoryId, noteId)
                    .then(() => {
                        note.current.placeholder = 'Note has been removed';
                        setTimeout(_resetNoteLabel, 5000)
                    })
            }
        })

    }

    const _handleAddote = async (pvt) => {
        const _note = note.current.value
        note.current.placeholder = 'Saving Note'
        note.current.value = ''
        if (newRef)
            await ADD_NOTE(eventId, _note, pvt, newRef)
        else
            await ADD_NOTE(eventId, _note, pvt, [])
        note.current.placeholder = pvt ? 'Private note noted' : 'Noted'
        note.current.value = ''
    }
    const _parseReleveance = (n) => {
        try {
            const _n = JSON.parse(n)
            if (Array.isArray(_n))
                return _n.map(x =>
                    x.key.trim() !== '' ? <span key={'Rel' + x.key} className='rounded rounded-5 bg-primary-container p-1'><small>{x.key}</small></span> : <></>
                    // <div>{x.source} - {x.key}</div>
                )
        } catch (e) {
            // return 'errer' + e.message + ' ' + JSON.stringify(n)
        }
        return <></>
    }

    return (
        <>
            <ToastDialog
                onTimeOut={() => setStatus({ message: '' })}
                {...status}
                time={8} />
            {

                confirmDelete.show === true
                    ?
                    <div
                        className='position-absolute d-flex align-items-center justify-content-center'
                        style={{ left: 0, right: 0, top: 0, bottom: 0, zIndex: 100 }}
                    >
                        <div
                            className='bg-secondary-container rounded shadow p-3 text-center'
                            style={{ maxWidth: '450px' }}
                        >
                            <h3>{confirmDelete.title}</h3>
                            <p>{confirmDelete.description}</p>
                            <button
                                onClick={(e) => { confirmDelete.action(); setConfirmDelete({ ...confirmDelete, show: false }); }}
                                className='btn'>Yes</button>
                            <button
                                onClick={(e) => { setConfirmDelete({ ...confirmDelete, show: false }) }}
                                className='btn'>No</button>
                        </div>
                    </div>
                    :
                    <></>
            }
            <div style={{ zIndex: 99, margin: '1rem', position: 'absolute', top: '0px', bottom: '0px', left: '0px', right: '0px' }} className={'bg-secondary-container rounded rounded-5 shadow shadow-3 notes-popup d-flex flex-column '.concat(visible ? 'show' : 'hide')}>
                <div className='d-flex justify-content-between'>
                    <h1 className='ms-3 flex-grow-1 text-center'>Notes</h1>
                    <XCircle onClick={() => setVisible(false)} className='m-2 flex-shrink-1' size={40} />

                </div>
                <div className='d-flex flex-column' style={{ height: 'calc(100% - 50px)' }}>
                    <div className='bg-secondary-container text-end p-2 text-secondary'><ClockHistory className='me-1' />Include Entire Notes History</div>
                    <div className='flex-grow-1' style={{ overflow: 'auto' }}>
                        <div>
                            {
                                filtered.map((n) =>
                                    <div key={n.id} className='row border-bottom py-2'>
                                        <div className='text-start col-10'>
                                            {
                                                n.isPrivate ? <div><EyeSlashFill /> <i>Private</i> </div> : <></>
                                            }
                                            <div><Clock className='me-2' /><small>{n.createdDate.substr(n.createdDate, n.createdDate.indexOf('.'))}</small></div>
                                            <div><Person className='me-2' />{n.createdUser ? n.createdUser.firstName + ' ' + n.createdUser.lastName : ''} </div>
                                            <div className='text-center'>{n.note}</div>
                                            <small className='text-start'>
                                                <b>Related To</b>
                                                {_parseReleveance(n.referenceKeys)}
                                            </small>
                                        </div>
                                        <div onClick={(e) => _handleOnDeleteNote(eventId, n.id)} className='btn col-1' style={{ backgroundColor: 'transparent' }}><TrashFill size={22} /></div>
                                    </div>
                                )
                            }
                        </div>
                    </div>
                    <div className='flex-shrink-1 m-2'>
                        <textarea ref={note} className='form-control' placeholder='Note'></textarea>
                        <div>
                            <button onClick={() => _handleAddote(false)} className='btn'>Add Note</button>
                            <button onClick={() => _handleAddote(true)} className='btn'>Add Private Note</button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

import React, { useEffect, useRef, useState } from 'react'
import { CheckSquare, Pencil, PlusCircle, Square } from 'react-bootstrap-icons'
import Questionnaire from '../../../../../libs/api/services/Questionnaire'
import QBuilderGenerics from '../QBuilderGenerics'
import QBuilderComponentOptions from './QBuilderComponentOptions'
import QBuilderComponentSingleSelctItem from './widgets/QBuilderComponentSingleSelctItem'

export default function QBuilderComponentMultiSelct({ cache, onChangeMode, view, activeQuestionnaireAssignment, onLoadComplete, ComponentId, onAction, idx, edit, defaultMode, onIssue, SectionComponents, values, onSetData }) {
    const [mode, setMode] = useState(edit ? 'Edit Text' : '')
    const isMountedRef = useRef(false);
    const [details, setDetails] = useState({ text: '' })
    const [options, setOptions] = useState([])
    const [option, setOption] = useState({ option: '' })
    const [responseValue, setResponseValue] = useState()
    const [properties, setProperties] = useState([])
    const [loading, setLoading] = useState(true)
    const [issues, setIssues] = useState({})

    const [data, setData] = useState()

    const _load = async () => {
        try {
            if (!isMountedRef.current)
                if (activeQuestionnaireAssignment)
                    setData(await QBuilderGenerics.loadData(cache, ComponentId, activeQuestionnaireAssignment.id))
                else
                    setData(await QBuilderGenerics.loadData(cache, ComponentId,))
            onLoadComplete()
            isMountedRef.current = true;
        } catch (e) { }
    }

    useEffect(() => {
        // onLoad
        _load()
    }, [])

    useEffect(() => {
        // Rerender after data changed
        if (data && data.properties) {
            // Rerender after data changed
            setLoading(true)
            _loadProperties(true)
            if (data.response) _LoadResponse(true)
            setLoading(false)
        }
    }, [data])

    useEffect(() => {
        onChangeMode(mode)
    }, [mode])

    const _hadleAddOption = (_option) => {
        const _options = [...options, _option]
        setOptions(_options)
        _setProperty('Options', JSON.stringify(_options))
        setOption({ option: '' })
    }
    const _LoadResponse = (init) => {
        try {
            try {
                var _opts = QBuilderGenerics.getProperty(data.properties, "Options")
            } catch (e) {
            }
            try {
                const _answers = JSON.parse(data.response.answer)
                for (var _a of _opts) {
                    const f = _answers.find(f => f.option == _a.option)
                    if (f && f.active)
                        _a.active = true
                }
                setOptions(_opts)
            } catch (e) {
                // console.error("HERE(_LoadResponse)-------> error", e)
            }
        } catch (e) { }

        setResponseValue(cache.response.answer)
        /*
                if (!edit && view !== "New") {
                    if (cached) {
                        const opts = cache.properties.find(p => p.property === "Options")
        
                        try {
                            var _opts = JSON.parse(opts.values)
                            const _answers = JSON.parse(cache.response.answer)
                            for (var _a of _opts) {
                                const f = _answers.find(f => f.option == _a.option)
                                if (f && f.active)
                                    _a.active = true
                            }
                            setOptions(_opts)
                        } catch (e) { }
        
                        setResponseValue(cache.response.answer)
                    } else {
                        Questionnaire
                            .GetQuestionnaireResponse(activeQuestionnaireAssignment.id, ComponentId)
                            .then((r) => {
                                const opts = res.find(p => p.property === "Options")
        
                                try {
                                    var _opts = JSON.parse(opts.values)
                                    const _answers = JSON.parse(r.answer)
                                    for (var _a of _opts) {
                                        const f = _answers.find(f => f.option == _a.option)
                                        if (f && f.active)
                                            _a.active = true
                                    }
                                    setOptions(_opts)
                                } catch (e) { }
        
                                setResponseValue(r.answer)
                                //responseValue
                            }).catch((e) => { })
                    }
                }*/
    }
    const init = (r) => {
        var _details = { ...details }

        try {
            _details.label = r.find(p => p.property === "Question").values
        } catch (e) { }
        try {
            _details.Required = JSON.parse(r.find(p => p.property === "Required").values)
        } catch (e) { }
        setDetails({ ..._details })
        setProperties(r)
    }
    const _loadProperties = async (init) => {
        // Load from cache initially if available
        setProperties(data.properties)
        var _details = { ...details }
        try {
            _details.label = QBuilderGenerics.getProperty(data.properties, "Question")
        } catch (e) { }
        try {
            const opts = QBuilderGenerics.getProperty(data.properties, "Options")
            if (opts)
                setOptions(opts)
        } catch (e) {
        }
        setDetails(_details)
    }
    const _loadProperty = (prop, cache) => {
        var ret = undefined
        try {
            ret = properties.find(p => p.property === prop)
        } catch (e) { }

        return ret ? ret.values : '';
    }
    const _setProperty = (prop, value) => {
        Questionnaire
            .SetComponentProperty(ComponentId, {
                property: prop,
                values: value,
                component: {
                    id: ComponentId
                }
            })
    }

    const _handleSetFieldName = (t) => {
        _setProperty("Question", t)
        var _details = { ...details }
        _details.label = t
        setDetails(_details)
    }

    const _handleRemoveOption = (i) => {
        var _options = options
        _options.splice(i, 1)
        setOptions([..._options])
        _setProperty('Options', JSON.stringify(_options))
    }
    const _handleEditOption = (i, v) => {
        var _options = options
        options[i] = v
        setOptions([..._options])
        _setProperty('Options', JSON.stringify(_options))
    }
    const _handleSave = () => {
        setMode('')
    }
    const _showScore = () => {
        var max_weight = 0;
        var weight = 0;
        for (const o of options) {
            if (Object.keys(o).includes('weight')) {
                max_weight += parseInt(o.weight)
                if (Object.keys(o).includes('active') && o.active === true)
                    weight += parseInt(o.weight)

            }
        }
        return <div><small>Weighting : {weight} / {max_weight} </small></div>

    }
    const _handleSetValue = (v) => {
        var _options = options

        // for (var o of _options) {
        //     o.active = false
        // }
        // _options[i].active = true
        // setOptions([..._options])
        onSetData(JSON.stringify(v), true)
        /* if (!edit && view !== "New") {
             Questionnaire
                 .QuestionnaireResponse(activeQuestionnaireAssignment.id, {
                     // questionnaire: {
                     //     id: QuestionnaireAssignmentId
                     // },
                     answerType: {
                         id: ComponentId
                     },
                     answer: JSON.stringify(v)
                 })
                 .then((r) => {
 
                 })
         }*/
    }
    useEffect(() => {
        if (defaultMode && defaultMode === 'Overview')
            setMode('')
        // Dont load on first
        // if (!isMountedRef.current)
        //     _loadProperties()
    }, [defaultMode])

    // useEffect(() => {
    //     // Dont load on first
    //     if (!isMountedRef.current)
    //         _loadProperties()
    // }, [mode])

    const _handleOptionSelected = (i) => {
        var _options = options
        if (_options[i].active)
            _options[i].active = false
        else
            _options[i].active = true
        setOptions([..._options])
        _handleSetValue(_options)
    }

    const _handleSetRequired = () => {
        var _required = details.Required
        if (_required === null)
            _required = false
        var _details = { ...details, Required: !_required }
        _setProperty("Required", JSON.stringify(!_required))
        setTimeout(_loadProperties, 100)
        setDetails(_details)

    }

    const _validate = () => {
        var _isValid = false
        for (const o of options) {
            if (o.active)
                _isValid = true
        }
        if (!_isValid) {
            onIssue({ issue: 'Field is Required' })
            setIssues({ issue: 'This field is required' })
            return false;
        } else {
            onIssue({ issue: '' })
            setIssues({ issue: '' })
        }
        return true;
    }

    const _render = () => {
        var _rent = []
        switch (mode) {
            case 'Edit Text':
                _rent = (
                    <div style={{ cursor: 'pointer' }} className='bg-light rounded text-start p-2 opacity-75'>
                        <div className='text-center'>
                            <div className='d-flex justify-content-between'>
                                <b>Enter a name for the field</b>
                            </div>
                            <input
                                onChange={(e) => _handleSetFieldName(e.target.value)}
                                defaultValue={_loadProperty("Question")}
                                className='form-control form-control-sm' placeholder='Field Name' />
                            <div className='d-flex justify-content-between'>
                                <div>
                                    <div>
                                        <b>Is this field required?</b>
                                    </div>
                                    <div
                                        onClick={(e) => _handleSetRequired()}
                                    >
                                        {
                                            details.Required
                                                ?
                                                <CheckSquare size={20} className='me-1' />
                                                :
                                                <Square size={20} className='me-1' />
                                        }
                                        Field is required
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )
                break;
            case 'Link':
                _rent = (
                    <div>
                        <p className='text-center'>To link this field to an existing patient profile field select the category and field below</p>
                    </div>
                )
                break;
            case 'Options':
                _rent = (
                    <div>
                        <div className='d-flex flex-column align-items-stretch'>
                            <div className='flex-grow-1'>
                                <b>Answer</b>
                                <input
                                    value={option.option ? option.option : ''}
                                    onChange={(e) => setOption({ ...option, option: e.target.value })}
                                    className='form-control form-control-sm' placeholder='Answer' />
                            </div>

                            <div className='flex-grow-1 p-2'>
                                <div className='row'>
                                    <div className='col-4 text-center'>
                                        <b>Answer Weight</b>
                                        <input
                                            value={option.option ? option.weight : ''}
                                            onChange={(e) => setOption({ ...option, weight: e.target.value })}
                                            type='number'
                                            className='form-control form-control-sm' placeholder='Answer Weight' />
                                    </div>
                                    <div
                                        onClick={(e) => setOption({ ...option, trigger: !option.trigger })}
                                        className='col-4 text-center'>
                                        <b>Trigger Alert</b>
                                        <div>
                                            {
                                                option.trigger
                                                    ?
                                                    <CheckSquare size={20} className='me-1' />
                                                    :
                                                    <Square size={20} className='me-1' />
                                            }
                                            Alert if option selected
                                        </div>
                                    </div>
                                </div>

                                <div className='text-center'>
                                    <button
                                        onClick={(e) => _hadleAddOption(option)}
                                        className='btn-xs'>
                                        <PlusCircle />
                                        Add
                                    </button>
                                </div>
                            </div>
                        </div>
                        {
                            options.map((o, i) =>
                                <QBuilderComponentSingleSelctItem
                                    o={o}
                                    SectionComponents={SectionComponents}
                                    onRemove={() => _handleRemoveOption(i)}
                                    onEdit={(v) => _handleEditOption(i, v)}
                                    values={values}
                                />

                            )
                        }

                    </div>

                    // <div>
                    //     {
                    //         options.map((o, i) =>
                    //             <div
                    //                 key={Math.floor(Math.random() * 100000)}
                    //                 className='d-flex '>
                    //                 <div className='flex-grow-1'>
                    //                     {o.option}
                    //                 </div>
                    //                 <div>
                    //                     <button
                    //                         onClick={(e) => { _handleRemoveOption(i) }}
                    //                         className='btn'>
                    //                         <Trash />
                    //                     </button>
                    //                 </div>
                    //             </div>
                    //         )
                    //     }
                    //     <div className='d-flex '>
                    //         <div className='flex-grow-1'>
                    //             <input
                    //                 onChange={(e) => setOption({ ...option, option: e.target.value })}
                    //                 className='form-control form-control-sm' placeholder='Option' />
                    //         </div>
                    //         <div>
                    //             <button
                    //                 onClick={(e) => { _hadleAddOption(option) }}
                    //                 className='btn'>
                    //                 <PlusCircle />
                    //             </button>
                    //         </div>
                    //     </div>
                    // </div>
                )
                break;
            default:
                break;
        }

        return (
            <>
                {_rent}
            </>
        );
    }

    if (loading) return <div>Loading</div>
    // if (mode !== '')
    //     return (
    //         <div style={{ cursor: 'pointer' }} className='bg-light rounded text-start p-2'>
    //             <QBuilderComponentOptions onAction={(e) => onAction(e)} idx={idx} />
    //             <div className='d-flex rounded bg-primary-container m-2'>
    //                 <div className={'p-2 '.concat(mode === 'Edit Text' ? 'active' : '')} onClick={() => setMode('Edit Text')} >Field Name</div>
    //                 <div className={'p-2 '.concat(mode === 'Options' ? 'active' : '')} onClick={() => setMode('Options')} >Field Options</div>
    //                 <div className={'p-2 '.concat(mode === 'Link' ? 'active' : '')} onClick={() => setMode('Link')} >Link Field</div>
    //                 <div className={'p-2 '.concat(mode === '' ? 'active' : '')} onClick={() => setMode('')} >Close</div>
    //             </div>
    //             {_render()}
    //         </div>
    //     )
    // else
    return (
        <>
            {
                mode !== ''
                    ?
                    <div style={{ cursor: 'pointer' }} className={'bg-light w-50 shadow rounded text-start p-2 position-fixed '.concat(' slide-in')}>
                        <QBuilderComponentOptions onAction={(e) => onAction(e)} onClose={() => setMode('')} idx={idx} />
                        <div className='d-flex rounded bg-primary-container m-2'>
                            <div className={'p-2 '.concat(mode === 'Edit Text' ? 'active' : '')} onClick={() => setMode('Edit Text')} >Field Name</div>
                            <div className={'p-2 '.concat(mode === 'Options' ? 'active' : '')} onClick={() => setMode('Options')} >Field Options</div>
                            <div className={'p-2 '.concat(mode === 'Link' ? 'active' : '')} onClick={() => setMode('Link')} >Link Field</div>
                            <div className={'p-2 '.concat(mode === 'Style' ? 'active' : '')} onClick={() => setMode('Style')} >Style</div>
                            {/* <div className={'p-2 '.concat(mode === '' ? 'active' : '')} onClick={() => setMode('')} >Close</div> */}
                        </div>
                        {_render()}
                    </div>
                    : <></>
            }
            <div className={'text-start '.concat(mode !== '' ? 'bg-primary-container opacity-75 shadow' : '')}>
                {
                    edit
                        ?
                        <Pencil className='me-2' onClick={(e) => setMode('Edit Text')} />
                        :
                        <></>
                }
                <>
                    <div className='d-flex justify-content-between'>
                        <label>{details.label}<b className='text-secondary'><small>{_loadProperty("Required") && JSON.parse(_loadProperty("Required")) ? "*" : ''}</small></b></label>

                    </div>
                    <div>
                        {
                            options.map((o, i) =>
                                view && view === 'Discover'
                                    ?
                                    <div
                                        key={Math.floor(Math.random() * 100000)}
                                        className='active m-2'>
                                        {
                                            o.active
                                                ?
                                                <div>
                                                    <CheckSquare className='me-2' />
                                                    {o.option}
                                                </div>
                                                :
                                                <div className=' opacity-25'>
                                                    <Square className='me-2' />
                                                    {o.option}
                                                </div>
                                        }
                                    </div>
                                    :
                                    <div
                                        key={Math.floor(Math.random() * 100000)}
                                        style={{ cursor: 'pointer' }}
                                        onClick={(e) => _handleOptionSelected(i)}
                                        className='active m-2'>
                                        {
                                            o.active
                                                ?
                                                <CheckSquare className='me-2' />
                                                :
                                                <Square className='me-2' />
                                        }

                                        {o.option}
                                    </div>
                            )
                        }
                    </div>
                </>
            </div>
        </>)
}

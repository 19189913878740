import API from "../API";

var Messaging = {
    async CheckMessages() {
        var result = await API.process(
            'api/Messaging',
            'OPTIONS',
            null
        ).catch((err) => {
            throw err;
        })

        return result
    },
    async GetMessages(filters) {
        var result = await API.process(
            'api/Messaging/Messages',
            'POST',
            filters
        ).catch((err) => {
            throw err;
        })

        return result
    },
    async ArchiveMessage(id) {
        var result = await API.process(
            `api/Messaging/${id}`,
            'DELETE',
            null
        ).catch((err) => {
            throw err;
        })

        return result
    },
    async SendReply(messageId, msg) {
        var result = await API.process(
            `api/Messaging/Reply/${messageId}`,
            'POST',
            msg
        ).catch((err) => {
            throw err;
        })

        return result
    },
    async SetMessageStatus(messageId, status) {
        var result = await API.process(
            `api/Messaging/status/${messageId}/${status}`,
            'Patch',
            null
        ).catch((err) => {
            throw err;
        })

        return result
    },
    // async loadSettings(service, userId) {
    //     if (userId) {
    //         var result = await API.process(
    //             `api/MessagingService/properties/${service}/${userId}`,
    //             'GET',
    //             null
    //         ).catch((err) => {
    //             throw err;
    //         })
    //     } else {
    //         var result = await API.process(
    //             `api/MessagingService/properties/${service}/-1`,
    //             'GET',
    //             null
    //         ).catch((err) => {
    //             throw err;
    //         })
    //     }

    //     return result
    // },
    async SetServiceProperty(value) {
        var result = await API.process(
            `api/MessagingService/property`,
            'PUT',
            value
        ).catch((err) => {
            throw err;
        })

        return result
    },
    async TestConnection(userId, type) {
        var result = await API.process(
            `api/MessagingService/connection/test/${userId}/${type}`,
            'GET',
            null
        ).catch((err) => {
            throw err;
        })

        return result
    },
    async SyncAccounts() {
        var result = await API.process(
            `api/Messaging/sync`,
            'OPTIONS',
            null
        ).catch((err) => {
            throw err;
        })

        return result
    },
    async GetAttachment(id) {
        var result = await API.process(
            `api/Messaging/attachment/${id}`,
            'GET',
            null
        ).catch((err) => {
            throw err;
        })

        return result
    },
    async ListPatientLinks(id) {
        var result = await API.process(
            `api/Messaging/patient/link/${id}`,
            'GET',
            null
        ).catch((err) => {
            throw err;
        })

        return result
    },
    async LinkMessagePatient(messageId, patientId) {
        var result = await API.process(
            (patientId) ? `api/Messaging/patient/link/${messageId}/${patientId}` : `api/Messaging/patient/link/${messageId}/-1`,
            'PATCH',
            null
        ).catch((err) => {
            throw err;
        })

        return result
    },
    async LinkMessagePatientHistory(messageId, patientHistoryId) {
        var result = await API.process(
            (patientHistoryId) ? `api/Messaging/patientHistory/link/${messageId}/${patientHistoryId}` : `api/Messaging/patientHistory/link/${messageId}/-1`,
            'PATCH',
            null
        ).catch((err) => {
            throw err;
        })

        return result
    },
    async GetSyncStatus(messageId, patientHistoryId) {
        var result = await API.process(
            `api/Messaging/status`,
            'OPTIONS',
            null
        ).catch((err) => {
            throw err;
        })

        return result
    },
    async GetMessage(messageId) {
        var result = await API.process(
            `api/Messaging/Message/${messageId}`,
            'GET',
            null
        ).catch((err) => {
            throw err;
        })

        return result
    },
    async ListLinkedMessages(eventId) {
        var result = await API.process(
            `api/Messaging/linked/event/${eventId}`,
            'GET',
            null
        ).catch((err) => {
            throw err;
        })

        return result
    },
    async AddAudienceProperty(MessageId, Property) {
        var result = await API.process(
            `api/Message/Template/audience/properties/${MessageId}`,
            'PUT',
            Property
        ).catch((err) => {
            throw err;
        })

        return result
    },
    async ListMessageLinks(messageId) {
        var result = await API.process(
            `api/Message/patient/link/${messageId}`,
            'GET',
            null
        ).catch((err) => {
            throw err;
        })

        return result
    },
    async RefreshStatus(messageId) {
        var result = await API.process(
            `api/Messaging/message/${messageId}/status`,
            'OPTIONS',
            null
        ).catch((err) => {
            throw err;
        })

        return result
    },
    async GetMBStat() {
        var result = await API.process(
            `api/Messaging/message/mbstatus`,
            'OPTIONS',
            null
        ).catch((err) => {
            throw err;
        })

        return result
    },
    async MarkAllAsRead() {
        var result = await API.process(
            `api/Messaging/Message/MarkAllAsRead`,
            'DELETE',
            null
        ).catch((err) => {
            throw err;
        })

        return result
    },
    async CheckEmail(UserId) {
        var result = await API.process(
            `api/Messaging/User/${UserId}/Email`,
            'OPTIONS',
            null
        ).catch((err) => {
            throw err;
        })

        return result
    },
}

export default Messaging;
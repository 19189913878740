import React, { Component } from 'react'
import QBuilderLayoutComponentWrapper from '../../../builder/Wizard/Layouts/QBuilderLayoutComponentWrapper'
import '../QCapture.css'
import QCaptureComponentWrapper from './QCaptureComponentWrapper'


export default class QFormLayout extends Component {
    constructor(props) {
        super(props)
        this.state = {
            details: { ...props.details },
            formData: props.formData
        }
    }

    _handleValidateEvent() {
        if (this.props.mode === 'Discover')
            return false
        var res = true
        for (const fd of this.state.formData) {
            if (fd.validate && !fd.valid)
                res = false
        }
        return res
    }

    /*
        Section
    */
    _renderSections(sections) {
        var ret = []
        for (const section of sections) {
            ret.push(this._renderSection(section))
        }
        // this.setState({ formData: _formData })
        return ret
    }
    _renderSection(section) {
        const SHOW_TITLE = this.state.details.options.find(o => o.name === "SHOW_SECTION_TITLE")
        var section_title = section.name
        if (SHOW_TITLE) {
            var SHOW_TITLE_parsed = JSON.parse(SHOW_TITLE.value)
            if (SHOW_TITLE_parsed.type === "Hide") {
                section_title = "";
            }
        }
        var _section_details = section_title ? <h3 className='bg-secondary-container text-center p-2 shadow'>{section_title}</h3> : <></>
        var _section_components = this._renderComponents(section.components, section)

        return (<div className='text-start'>{_section_details}{_section_components}</div>)
    }
    /*
        Components
    */
    _checkLayout(component) {
        var _width = 12
        var _offset = 0
        var _layout = []
        try {
            var layout = component.properties.find(p => p.property === 'Layout').values
            if (layout) {
                _layout = JSON.parse(layout)
                _width = _layout.width
                _offset = _layout.offset
            }
        } catch (e) { layout = undefined }
        return { width: _width, offset: _offset, layout: _layout }
    }

    _renderComponents(components, section) {
        var ret = []
        var comps = []
        var _cur_row_width = 0;

        for (const component of components) {
            var _cache = undefined
            if (this.props.cacheddetails) {
                var _component_cached = undefined
                try {
                    const _sections_filter = JSON.parse(JSON.stringify(this.props.cacheddetails.questionnaire.sections));
                    const _section_cache = _sections_filter
                        .find(s => s.id === section.id)
                    _component_cached =
                        _section_cache.components.find(c => c.id === component.id)
                } catch (e) { }

                var _component_responses = { answer: '' }
                try {
                    var _response = this.props.cacheddetails.responses.find(r => r.answerType.id === component.id)
                    _component_responses = _response ? _response : { answer: '' }
                } catch (e) {
                    _component_responses = { answer: '' }
                }
                var _component_alerts = undefined
                try {
                    _component_alerts = this.props.cacheddetails.alerts
                } catch (e) { }
                _cache = {
                    properties: _component_cached && Object.keys(_component_cached).includes('properties') ? _component_cached.properties : undefined,
                    response: _component_responses,
                    alerts: _component_alerts
                }
            }

            const layout = this._checkLayout(component)

            _cur_row_width += layout.width + layout.offset
            comps.push(<QBuilderLayoutComponentWrapper
                currentMode={'Capture'}
                onUpdateLayout={(layout) => { }}
                defaultLayout={layout.layout}
                className={'col-' + layout.width + ' offset-' + layout.offset}
            >
                <QCaptureComponentWrapper
                    state={this.state}
                    cache={_cache}
                    mode={this.props.mode}
                    view={this.props.view}
                    component={component}
                    onSetData={(componentId, value, update) => this.props.onSetData(componentId, value, update)}
                    activeSubsections={this.props.activeSubsections}
                />
            </QBuilderLayoutComponentWrapper>)

            if (_cur_row_width >= 12) {
                ret.push(<div className='row'>{comps}</div>)
                comps = []
                _cur_row_width = 0
            }
        }
        ret.push(<div className='row'>{comps}</div>)
        return ret
    }

    _handleTitle() {
        var section_title = ''
        try {
            const SHOW_TITLE = this.state.details.options.find(o => o.name === "SHOW_TITLE")
            section_title = this.state.details.questionnaire.name
            if (SHOW_TITLE) {
                var SHOW_TITLE_parsed = JSON.parse(SHOW_TITLE.value)
                if (SHOW_TITLE_parsed.type === "None") {
                    section_title = "";
                }
                else if (SHOW_TITLE_parsed.type === "Custom Title") {
                    section_title = SHOW_TITLE_parsed.title;
                }
            }
        } catch (e) { }
        return section_title ? <h1 className='text-center bg-primary-container m-0 p-1 rounded-top'>{section_title}</h1> : <></>
    }

    render() {
        if (this.state.details)
            return (
                <div className={'d-flex flex-column h-100 '.concat(this.props.view)}>
                    <div className='flex-grow-1' style={{ overflow: 'auto' }}>
                        {this._handleTitle()}
                        {
                            this._renderSections(this.state.details
                                .questionnaire
                                .sections.filter(s => s.isSubsection === false))
                        }
                    </div>
                    <div className='flex-shrink-1 text-center' style={{ minHeight: '50px' }}>
                        {
                            this.props.mode === 'Discover'
                                ?
                                <></>
                                :
                                this.props.mode !== 'Form'
                                    ?
                                    <button type='button' onClick={(e) => this.props.onComplete()} className={'btn bg-primary-container '.concat(this._handleValidateEvent() ? '' : 'disabled')} > Complete</button>
                                    :
                                    <></>
                        }
                    </div>
                </div >
            )
    }
}


import React, { useContext, useEffect, useState } from 'react'
import RTEMessageBuilderComponent from '../../../messaging/admin/components/RTEMessageBuilderComponent'
import WorkFlowConfigurationContext from '../../../workflow/configuration/WorkFlowConfigurationProvider'
import ManageTaskContainer from '../../../workflow/configuration/facility/wizard/Commons/ManageTaskContainer'

export default function SendNotificationTaskLineItem({ onChanged, task, facility, user, onDelete, onSetProperty, config, errors, values, setValue }) {

    const PROP_NAME = "NOTIFICATION_DETAILS"
    const DEFAULT = {
        recipient: undefined,
        Notification: 'undefined'
    }
    const _handleCreateName = (P) => {
        var name = 'Send notification '
        if (P.recipient) {
            if (P.recipient) name += ' to  ' + P.recipient
        }
        return name
    }

    const { setTaskProperty, loadTaskProperties } = useContext(WorkFlowConfigurationContext)
    const [props, setProps] = useState(DEFAULT)

    const _handleSetProperty = (k, value) =>
        setTaskProperty(props, _handleCreateName({ ...props, [k]: value }), task, PROP_NAME, { ...props, [k]: value })
            .then((r) => setProps({ ...props, [k]: value }))

    useEffect(() => { _handleLoadProps() }, [])

    const _handleLoadProps = async () =>
        setProps(await loadTaskProperties(task, PROP_NAME))

    return (
        <ManageTaskContainer Task={task} onDelete={onDelete}>
            <>
                <b>Who will the notification be sent to</b>
                <select
                    onChange={(e) => _handleSetProperty('recipient', e.target.value)}
                    className='form-control form-control-sm'>
                    <option></option>
                    <option selected={props.recipient && props.recipient === "Patient" ? "selected" : ""}>Patient</option>
                    <option selected={props.recipient && props.recipient === "Clinician" ? "selected" : ""}>Clinician</option>
                    <option selected={props.recipient && props.recipient === "Administrator" ? "selected" : ""}>Administrator</option>
                    <option selected={props.recipient && props.recipient === "All Patients in Facility" ? "selected" : ""}>All Patients in Facility</option>
                    <option selected={props.recipient && props.recipient === "All Clinicians in Facility" ? "selected" : ""}>All Clinicians in Facility</option>
                    <option selected={props.recipient && props.recipient === "All Administrators in Facility" ? "selected" : ""}>All Administrators in Facility</option>
                </select>
                <b>Send Notification</b>
                <div className='bg-light text-dark rounded m-2'>
                    <RTEMessageBuilderComponent
                        template={props.Notification && props.Notification && props.Notification.template}
                        options={['inline', 'blockType', 'fontSize', 'fontFamily', 'list', 'textAlign', 'colorPicker', 'emoji']}
                        onSetTemplate={(o, h, m) => _handleSetProperty('Notification', {
                            template: o,
                            html: h,
                            md: m
                        })}
                    />
                </div>
            </>
        </ManageTaskContainer>
    )
}

import AppRoutesFacilityConfiguration from "./AppRoutesFacilityConfiguration";
import AppRoutesOAuth from "./AppRoutesOAuth";
import AppRoutesSystemAdminitration from "./AppRoutesSystemAdminitration";
import AppRoutesWorkFlows from "./AppRoutesWorkFlows";
import { UserComponents } from "./UserComponents";
import { ClaimsFilter } from "./libs/authentication/ClaimsFilter";
import { AdminLayout } from "./screens/layout/adminLayout";
import LoginLayoutCenter from "./screens/layout/loginLayoutCenter";
import { MenuContainer } from "./screens/menu/MenuContainer";
import ForgotPasswordLayout from "./screens/section/ForgotPasswordLayout";
import HomeSectionLayout from "./screens/section/HomeSectionLayout";
import NoPermission from "./screens/section/NoPermission";
import FacilitySectionLayout from "./screens/section/facility/FacilitySectionLayout";
import QCapture from "./screens/section/questionnaires/capture/QCapture";
import AcceptTnC from "./screens/section/tncs/AcceptTnC";
import ViewTerms from "./screens/section/tncs/ViewTerms";

const _handleUserComponents = () => {
  var r = []
  for (const uc of UserComponents)
    for (const u of uc.users)
      r.push({
        path: uc.route.replace('[UserType]', u),
        element: uc.authenticate ? <ClaimsFilter enforce={true} claim={u}>{uc.component}</ClaimsFilter> : uc.component
      })
  return r
}

const AppRoutes = [
  {
    index: true,
    element: <LoginLayoutCenter title={"Facilities"} direction={"row"}><FacilitySectionLayout /></LoginLayoutCenter>
  },
  {
    path: '/Questionnaire/:questionnaireId/:QuestionnaireAssignmentId',
    element: <QCapture />
  },
  {
    path: '/tnc',
    element: <ViewTerms />
  },
  {
    path: '/atnc',
    element: <AdminLayout container={<AcceptTnC />} />
  },
  {
    path: '/tnc/:id',
    element: <ViewTerms facility />
  },
  {
    path: '/noperm',
    element: <NoPermission />
  },
  {
    path: '/patient/notes',
    element: <AdminLayout key={"AL_" + new Date().getTime()} menu={<MenuContainer key={"menu_cnt" + new Date().getTime()} menu="Patient" activeSection="Notes" />} container={<div>DETIALS</div>} />
  },
  {
    path: '/facilities',
    element: <LoginLayoutCenter title={"Facilities"} direction={"row"}><FacilitySectionLayout /></LoginLayoutCenter>
  },
  // Receptionist
  // {
  //   path: '/Admin',
  //   element: <ClaimsFilter enforce={true} claim={"System Admin,Admin"}><PatientManagementLayout menu={<MenuContainer key={"menu_cnt" + new Date().getTime()} menu="Receptionist" activeSection="" />} search={<PatientearchBar />} breadcrums={[{ name: 'Home', location: '/Admin' }]} /></ClaimsFilter>
  // },
  // {
  //   path: '/Admin/Patient/:id?',
  //   element: <ClaimsFilter enforce={true} claim={"System Admin,Admin"}><PatientManagementLayout menu={<MenuContainer key={"menu_cnt" + new Date().getTime()} menu="ReceptionistPatient" activeSection="Profile" />} container={<PatientManagement />} breadcrums={[{ name: 'Home', location: '/Admin' }, { name: 'Patient', location: '/Admin/Patient/[PatientID]' }, { name: '[PatientName]', location: '/Admin/Patient/[PatientID]/Dashboard' }, { name: 'Profile', location: '/Admin/Patient', active: true }]} /></ClaimsFilter>
  // },
  // {
  //   path: '/Admin/Patient/:id/History',
  //   element: <ClaimsFilter enforce={true} claim={"System Admin,Admin"}><PatientManagementLayout menu={<MenuContainer key={"menu_cnt" + new Date().getTime()} menu="ReceptionistPatient" activeSection="History" />} container={<PatientHistory />} breadcrums={[{ name: 'Home', location: '/Admin' }, { name: 'Patient', location: '/Admin/Patient/[PatientID]' }, { name: '[PatientName]', location: '/Admin/Patient/[PatientID]/Dashboard' }, { name: 'History', location: '/Admin/Patient', active: true }]} /></ClaimsFilter>
  // },
  // {
  //   path: '/Admin/Patient/:id/:event/Files',
  //   element: <ClaimsFilter enforce={true} claim={"System Admin,Admin"}><PatientManagementLayout menu={<MenuContainer key={"menu_cnt" + new Date().getTime()} menu="ReceptionistPatient" activeSection="History" />} container={<PatientDMS />} breadcrums={[{ name: 'Home', location: '/Admin' }, { name: 'Patient', location: '/Admin/Patient/[PatientID]' }, { name: '[PatientName]', location: '/Admin/Patient/[PatientID]/Dashboard' }, { name: 'History', location: '/Admin/Patient/[PatientID]/History', active: false }, { name: 'Event', location: '/Admin/Patient', active: true }]} /></ClaimsFilter>
  // },
  // {
  //   path: '/Admin/Messages',
  //   element: <ClaimsFilter enforce={true} claim={"System Admin,Admin"}><PatientManagementLayout menu={<MenuContainer key={"menu_cnt" + new Date().getTime()} menu="Receptionist" activeSection="Messages" />} container={<MessageManagement />} breadcrums={[{ name: 'Home', location: '/Admin' }, { name: 'Messages', location: '/Admin/Messages', active: true }]} /></ClaimsFilter>
  // },
  // {
  //   path: '/Admin/Messages/patient/:id',
  //   element: <ClaimsFilter enforce={true} claim={"System Admin,Admin"}><PatientManagementLayout menu={<MenuContainer key={"menu_cnt" + new Date().getTime()} menu="ReceptionistPatient" activeSection="Messages" />} container={<MessageManagement />} breadcrums={[{ name: 'Home', location: '/Admin' }, { name: 'Patient', location: '/Admin/Patient/[PatientID]' }, { name: '[PatientName]', location: '/Admin/Patient/[PatientID]/Dashboard' }, { name: 'Messages', location: '/Admin/Patient', active: true }]} /></ClaimsFilter>
  // },
  // {
  //   path: '/Admin/Patient/:id/Dashboard',
  //   element: <ClaimsFilter enforce={true} claim={"System Admin,Admin"}><ClinicianManagementLayout menu={<MenuContainer key={"menu_cnt" + new Date().getTime()} menu="ClinicianPatient" activeSection="Profile" />} container={<PatientProfileDashboard />} breadcrums={[{ name: 'Home', location: '/Clinician' }, { name: 'Patient', location: '/Clinician/Patient/[PatientID]' }, { name: '[PatientName]', location: '/Clinician/Patient/[PatientID]/Dashboard' }, { name: 'Profile', location: '/Clinician/Patient', active: true }]} /></ClaimsFilter>
  // },
  // {
  //   path: '/Admin/Configuration',
  //   element: <ClaimsFilter enforce={true} claim={"System Admin,Admin"}><PatientManagementLayout menu={<MenuContainer key={"menu_cnt" + new Date().getTime()} menu="UserConfiguration" activeSection="" />} /></ClaimsFilter>
  // },
  // {
  //   path: '/Admin/Configuration/User',
  //   element: <ClaimsFilter enforce={true} claim={"System Admin,Admin"}><PatientManagementLayout menu={<MenuContainer key={"menu_cnt" + new Date().getTime()} menu="UserConfiguration" activeSection="" />} container={<FacilityUserManagement />} /></ClaimsFilter>
  // },
  // {
  //   path: '/Admin/Configuration/UserRequests',
  //   element: <ClaimsFilter enforce={true} claim={"System Admin,Admin"}><PatientManagementLayout menu={<MenuContainer key={"menu_cnt" + new Date().getTime()} menu="UserConfiguration" activeSection="User Requests" />} container={<UserRequests />} /></ClaimsFilter>
  // },
  // {
  //   path: '/Admin/Configuration/Email',
  //   element: <ClaimsFilter enforce={true} claim={"System Admin,Admin"}><PatientManagementLayout menu={<MenuContainer key={"menu_cnt" + new Date().getTime()} menu="UserConfiguration" activeSection="Email" />} container={<EmailConfigComponent user={{ id: Authentication.UserId(true) }} />} /></ClaimsFilter>
  // },
  // {
  //   path: '/Admin/Patient/:id/Questionnaires',
  //   element: <ClaimsFilter enforce={true} claim={"Admin"}><PatientLoginLayout menu={<MenuContainer key={"menu_cnt" + new Date().getTime()} menu="ReceptionistPatient" activeSection="Questionnaires" />} search={<PatientearchBar />} container={<PatientQuestionnaires />} breadcrums={[{ name: 'Home', location: '/Admin' }, { name: 'Patient', location: '/Admin/Patient/[PatientID]' }, { name: '[PatientName]', location: '/Admin/Patient/[PatientID]/Dashboard' }, { name: 'Questionnaires', location: '/Admin/Patient', active: true }]} /></ClaimsFilter>
  // },
  // {
  //   path: '/Admin/Patient/:id/Forms',
  //   element: <ClaimsFilter enforce={true} claim={"Admin"}><PatientLoginLayout menu={<MenuContainer key={"menu_cnt" + new Date().getTime()} menu="ReceptionistPatient" activeSection="Questionnaires" />} search={<PatientearchBar />} container={<PatientForms />} breadcrums={[{ name: 'Home', location: '/Admin' }, { name: 'Patient', location: '/Admin/Patient/[PatientID]' }, { name: '[PatientName]', location: '/Admin/Patient/[PatientID]/Dashboard' }, { name: 'Forms', location: '/Admin/Patient', active: true }]} /></ClaimsFilter>
  // },
  // {
  //   path: '/Admin/Patient/:id/Questionanire/:questionair/:QuestionnaireAssignmentId',
  //   element: <ClaimsFilter enforce={true} claim={"Admin"}><PatientLoginLayout menu={<MenuContainer key={"menu_cnt" + new Date().getTime()} menu="ReceptionistPatient" activeSection="Questionnaires" />} container={<PatientQuestionnaireResults />} search={<PatientearchBar />} breadcrums={[{ name: 'Home', location: '/Admin' }, { name: 'Patient', location: '/Admin/Patient/[PatientID]' }, { name: '[PatientName]', location: '/Admin/Patient/[PatientID]/Dashboard' }, { name: 'Questionnaires', location: '/Admin/Patient/[PatientID]/Questionnaires', active: false }, { name: 'Questionnaire', location: '/Admin/Patient', active: true }]} /></ClaimsFilter>
  // },
  // {
  //   path: '/Admin/Patient/:id/Form/:questionair/:QuestionnaireAssignmentId',
  //   element: <ClaimsFilter enforce={true} claim={"Admin"}><PatientLoginLayout menu={<MenuContainer key={"menu_cnt" + new Date().getTime()} menu="ReceptionistPatient" activeSection="Questionnaires" />} container={<PatientFormComponent />} search={<PatientearchBar />} breadcrums={[{ name: 'Home', location: '/Admin' }, { name: 'Patient', location: '/Admin/Patient/[PatientID]' }, { name: '[PatientName]', location: '/Admin/Patient/[PatientID]/Dashboard' }, { name: 'Forms', location: '/Admin/Patient/[PatientID]/Forms', active: false }, { name: 'Form', location: '/Admin/Patient', active: true }]} /></ClaimsFilter>
  // },
  // {
  //   path: '/Admin/Calendar',
  //   element: <ClaimsFilter enforce={true} claim={"Admin"}><PatientLoginLayout menu={<MenuContainer key={"menu_cnt" + new Date().getTime()} menu="Receptionist" activeSection="Calendar" />} container={<CalendarSection />} search={undefined} breadcrums={[{ name: 'Home', location: '/Admin' }, { name: 'Calendar', active:true }]} /></ClaimsFilter>
  // },
  // Doctor
  {
    path: '/logout/*',
    element: <LoginLayoutCenter menu={<MenuContainer key={"menu_cnt" + new Date().getTime()} menu="" activeSection="" />}><HomeSectionLayout /></LoginLayoutCenter>
  }, {
    path: '/reset/:id',
    element: <LoginLayoutCenter menu={<MenuContainer key={"menu_cnt" + new Date().getTime()} menu="" activeSection="" />}><ForgotPasswordLayout /></LoginLayoutCenter>
  },
  {
    path: '/expired',
    element: <LoginLayoutCenter menu={<MenuContainer key={"menu_cnt" + new Date().getTime()} menu="" activeSection="" />}><HomeSectionLayout expired={true} /></LoginLayoutCenter>
  },
  ..._handleUserComponents(),
  ...AppRoutesOAuth,
  // ...AppRoutesPatientConfiguration,
  ...AppRoutesFacilityConfiguration,
  // ...AppRoutesClinician,
  ...AppRoutesSystemAdminitration,
  // ...AppRoutesAdministrator,
  ...AppRoutesWorkFlows
];

export default AppRoutes;

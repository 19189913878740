import React, { useEffect, useRef, useState } from 'react'
import { useClickOutside } from '../../../Hooks/useClickOutside'
import DatePickerInput from './DatePickerInput'
import DateTimeOptions from './DateTimePickerComponents/DateTimeOptions'
import DateTimePickerDetails from './DateTimePickerComponents/DateTimePickerDetails'
import DateTimePickerFunctions from './DateTimePickerComponents/DateTimePickerFunctions'
import DateTimePickerOptions from './DateTimePickerComponents/DateTimePickerOptions'
import TimePickerInput from './TimePickerInput'

const functions = new DateTimePickerFunctions()

export default function DateTimePicker({ small, defaultValue, onChange, pos, optional, dateOnly }) {
    const initRef = useRef(true)
    const [mode, setMode] = useState()
    const [DateTime, setDateTime] = useState(defaultValue ? new Date(defaultValue) : new Date())
    const dateSelectRef = useRef()
    useClickOutside(dateSelectRef, () => setMode())
    useEffect(() => {
        //console.log(DateTime.toLocaleString())
        // console.log(DateTimePickerFunctions.LocalISO(DateTime))
        if (String(DateTime) !== 'Invalid Date') {
            //console.log('---=-----*', DateTime)
            if (!(optional && initRef.current))
                // onChange(DateTime.toLocaleString().replace(',', '').replace(' ', 'T').replaceAll('/', '-'))
                onChange(DateTimePickerFunctions.LocalISO(DateTime))
        } else {
            //console.log('---=-----*', DateTime)
            // if (!(optional && initRef.current))
            //     onChange(new Date().toLocaleString().replace(',', '').replace(' ', 'T').replaceAll('/', '-'))
        }
        initRef.current = false

    }, [DateTime.toLocaleString()])

    const _handleSetDate = (f) => {
        setDateTime(new Date(f))
    }
    return (
        <>
            <div ref={dateSelectRef} className='d-flex position-relative'>

                <DatePickerInput dateOnly small={small} onPicker={(t) => setMode(('Date'))} onChange={_handleSetDate} value={DateTime} />
                {!dateOnly &&
                    <TimePickerInput small={small} onPicker={(t) => setMode((t))} onChange={(v) => setDateTime(functions.formatTimeForDate(v, DateTime))} value={DateTime} />}

                <DateTimePickerDetails
                    pos={pos}
                    visible={mode}
                    onHide={() => setMode()}
                >

                    <div className='flex-grow-1'>
                        <DateTimeOptions mode={mode} value={DateTime} onChange={(c) => { setDateTime(c) }} />
                    </div>
                    <DateTimePickerOptions
                        onCancel={() => setMode()}
                        onDone={() => setMode()}
                        onNow={() => setDateTime(new Date())}
                    />
                </DateTimePickerDetails>
            </div>
        </>
    )
}

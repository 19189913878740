import { faClipboard, faClipboardQuestion, faPen, faUser } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import { Link } from 'react-bootstrap-icons'
import { NavLink } from 'react-router-dom'
import OnScreenAssist from '../../components/ui/hint/OnScreenAssist'

export default function SysAdmin() {
    return (
        <div className='h-100 d-flex flex-column'>
            <h1 className='text-center'>Quick Links</h1>
            <div style={{overflow:'auto'}}>
                <h3 className='ps-2'>Users</h3>
                <div className='row'>
                    <NavLink
                        tag={Link}
                        key={'ust'}
                        to={'/sys-admin/users/add'} className='col-3 text-center bg-secondary-container rounded m-3 p-2' style={{ cursor: 'pointer' }}>
                        <div>
                            <FontAwesomeIcon className='m-1' size='2x' icon={faUser} />
                        </div>
                        <b>Add a user to the system</b>
                        <div>
                            <small className='p-2'>Add a new patient/clinician/admin onto the system</small>
                        </div>
                    </NavLink>
                </div>
                <div>
                    <h3 className='ps-2'>Process Flows / Automation</h3>
                    <div className='row'>
                        <NavLink key={'sys_ql_evt'} tag={Link} to={'/sys-admin/event/new'} className='col-3 text-center bg-secondary-container rounded m-3 p-2' style={{ cursor: 'pointer' }}>
                            <div>
                                <FontAwesomeIcon className='m-1' size='2x' icon={faClipboard} />
                            </div>
                            <b>Create a new system event</b>
                            <div>
                                <small className='p-2'>Add a new event to the system and link an automation task</small>
                            </div>
                        </NavLink>
                    </div>
                </div>
                <div>
                    <div className='row'>
                        <h3 className='ps-2'>Questionnaires and Forms</h3>
                        <NavLink key={'sys_ql_qa'} tag={Link} to={'/sys-admin/questionnaires'} className='col-3 text-center bg-secondary-container rounded m-3 p-2' style={{ cursor: 'pointer' }}>
                            <div>
                                <FontAwesomeIcon className='m-1' size='2x' icon={faClipboardQuestion} />
                            </div>
                            <b>Create a new Questionnaire</b>
                            <div>
                                <small className='p-2'>Create a new questionnaire template to be assigned to patients</small>
                            </div>
                        </NavLink>
                        <NavLink key={'sys_ql_qa'} tag={Link} to={'/sys-admin/tncs'} className='col-3 text-center bg-secondary-container rounded m-3 p-2' style={{ cursor: 'pointer' }}>
                            <div>
                                <FontAwesomeIcon className='m-1' size='2x' icon={faPen} />
                            </div>
                            <b>Manage system terms and conditions</b>
                            <div>
                                <small className='p-2'>View / Upload systems terms and conditions</small>
                            </div>
                        </NavLink>
                    </div>
                </div>
            </div>
            <OnScreenAssist
                items={[
                    {
                        heading: 'Event',
                        description: 'An event is a digital representation of a real life event eg: <b class="text-secondary">Patient admitted to hospital</b>. This event can then be linked to automated/manual tasks like sending out messages to the relevant parties'
                    },
                ]}
            />
        </div>
    )
}

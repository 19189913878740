import React from 'react'

export default function QBuilderValidationWrapper({ required, question, children }) {
    /// This is for interchangability for RTF
    const tryRenderQuestion = (quest) => {
        const _qes = JSON.stringify(quest)

        if (_qes && _qes.indexOf('{') > -1)
            return _qes.substr(_qes.indexOf("text\":\"") + 7).substring(0,_qes.indexOf('",') - 2)
        return quest;
    }
    return (
        <div>
            <label>{tryRenderQuestion(question)}<b className='text-secondary'><small>{required ? "*" : ''}</small></b></label>
            {children}
        </div>
    )
}

import React, { useContext, useEffect, useState } from 'react'
import WorkFlowConfigurationContext from '../../../workflow/configuration/WorkFlowConfigurationProvider'
import ManageTaskContainer from '../../../workflow/configuration/facility/wizard/Commons/ManageTaskContainer'
import CalendarEventStatus from '../../components/EventStatus/CalendarEventStatus'
import DOWSelectorComponent from '../../components/calendar_events/DOWSelectorComponent'
import CalendarSelectorComponent from '../../facility/components/CalendarSelectorComponent'

export default function UpdateCalendarEventTaskLineItem({ onChanged, task, facility, user, onDelete, onSetProperty, config, errors, values, setValue }) {
    const PROP_NAME = "UPDATE_CALENDAR_DETAILS"
    const _handleCreateName = (P) => {
        var name = 'Update calendar event '
        if (Object.keys(P).includes('recipient')) {
            name += ' for ' + P['recipient'].toLowerCase()
        }
        if (Object.keys(P).includes('CreateForWhenTime')) {
            name += P['CreateForWhenTime'] === 'All day' ? ' all day' :
                (Object.keys(P).includes('CreateForWhenTimeHH') && Object.keys(P).includes('CreateForWhenTimeMM'))
                    ? ' at ' + P['CreateForWhenTimeHH'] + ':' + P['CreateForWhenTimeMM'] : ''
            // name += ' on the ' + P['CreateForWhen']
        }
        if (Object.keys(P).includes('CreateForWhen')) {
            name += ' on the ' + P['CreateForWhen'].toLowerCase()
            if (Object.keys(P).includes('CreateForWhenDOW')) {
                name = name.replaceAll('day of week', P['CreateForWhenDOW'][0].toLowerCase())
            }
            // day of week
        }
        return name
    }
    const [props, setProps] = useState({})
    const { setTaskProperty, loadTaskProperties } = useContext(WorkFlowConfigurationContext)

    const _handleSetProperty = (k, value) => //{ console.log('Set Props'); setProps({ ...props, [k]: value }) }
        setTaskProperty(props, _handleCreateName({ ...props, [k]: value }), task, PROP_NAME, { ...props, [k]: value })
            .then((r) => setProps({ ...props, [k]: value }))

    useEffect(() => { _handleLoadProps() }, [])

    const _handleLoadProps = async () =>
        setProps(await loadTaskProperties(task, PROP_NAME))

    return (
        <ManageTaskContainer Task={task} onDelete={onDelete}>
            <div className='row'>
                <div className='col-4'>
                    <div>
                        <b>Who's calendar to update the event on</b>
                        <select
                            onChange={(e) => _handleSetProperty('recipient', e.target.value)}
                            className='form-control form-control-sm'>
                            <option></option>
                            <option selected={props.recipient && props.recipient === "Patient" ? "selected" : ""}>Patient</option>
                            <option selected={props.recipient && props.recipient === "Clinician" ? "selected" : ""}>Clinician</option>
                            <option selected={props.recipient && props.recipient === "Administrator" ? "selected" : ""}>Administrator</option>
                        </select>
                    </div>
                    <b>Which calendar type to update</b>
                    <CalendarSelectorComponent defaultValue={props.calendarToUpdated && props.calendarToUpdated} onSelected={(e) => _handleSetProperty('calendarToUpdated', e)} />
                    <div>
                        <b>On the</b>
                        <select
                            onChange={(e) => _handleSetProperty('occourance', e.target.value)}
                            className='form-control form-control-sm'>
                            <option></option>
                            <option selected={props.occourance && props.occourance === "Next occourance" ? "selected" : ""}>Next occourance</option>
                            <option selected={props.occourance && props.occourance === "All up comming occourances" ? "selected" : ""}>All up comming occourances</option>
                        </select>
                    </div>
                </div>
                <div className='col-4'>
                    <div>
                        <b>Event Summary</b>
                        <input
                            defaultValue={props.summary}
                            onChange={(e) => _handleSetProperty('summary', e.target.value)}
                            className='form-control form-control-sm' placeholder='Event Summary' />
                    </div>
                    <div>
                        <CalendarEventStatus defaultValue={props.status} onChange={(e) => _handleSetProperty('status', e)} />
                    </div>
                    <div>
                        <b>Event Details</b>
                        <textarea
                            defaultValue={props.details}
                            onChange={(e) => _handleSetProperty('details', e.target.value)}
                            className='form-control form-control-sm' placeholder='Event Description' ></textarea>
                    </div>
                </div>
                <div className='col-4'>
                    <div>
                        <b>Update the event for</b>
                        <div>
                            <select

                                onChange={(e) => _handleSetProperty('CreateForWhen', e.target.value)}
                                className='form-control form-control-sm'>
                                <option></option>
                                <option selected={props.CreateForWhen && props.CreateForWhen === "Same day" ? "selected" : ""}>Same day</option>
                                <option selected={props.CreateForWhen && props.CreateForWhen === "Following day" ? "selected" : ""}>Following day</option>
                                <option selected={props.CreateForWhen && props.CreateForWhen === "Following day of week" ? "selected" : ""}>Following day of week</option>
                                <option selected={props.CreateForWhen && props.CreateForWhen === "Following month on the first day of week" ? "selected" : ""}>Following month on the first day of week</option>
                                <option selected={props.CreateForWhen && props.CreateForWhen === "Following month on the last day of week" ? "selected" : ""}>Following month on the last day of week</option>
                            </select>
                            <DOWSelectorComponent
                                multi={false}
                                defaultValue={props.CreateForWhenDOW}
                                onChange={(e) => _handleSetProperty('CreateForWhenDOW', e)}
                            />
                        </div>
                    </div>
                    <div>
                        <b>Time</b>
                        <select
                            onChange={(e) => _handleSetProperty('CreateForWhenTime', e.target.value)}
                            className='form-control form-control-sm'>
                            <option></option>
                            <option selected={props.CreateForWhenTime && props.CreateForWhenTime === "All day" ? "selected" : ""}>All day</option>
                            <option selected={props.CreateForWhenTime && props.CreateForWhenTime === "Specific time" ? "selected" : ""}>Specific time</option>
                        </select>
                        <div className='d-flex'>
                            <input
                                defaultValue={props.CreateForWhenTimeHH}
                                onChange={(e) => _handleSetProperty('CreateForWhenTimeHH', e.target.value)}
                                type='number' min={0} max={23} className='form-control' placeholder='HH' />
                            <input
                                defaultValue={props.CreateForWhenTimeMM}
                                onChange={(e) => _handleSetProperty('CreateForWhenTimeMM', e.target.value)}
                                type='number' min={0} max={59} className='form-control' placeholder='MM' />
                        </div>
                    </div>
                </div>
            </div>
        </ManageTaskContainer>
    )
}

import { faCheck } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useEffect, useState } from 'react'
import './ToastDialog.css'

export default function ToastDialog({ message, icon, className, time, onTimeOut }) {
    const [show, setShow] = useState(false)
    const [lastMessage, setLatMessage] = useState(false)

    const _getIcon = () => {
        switch (icon) {
            case 'check':
                return <FontAwesomeIcon className='me-2' icon={faCheck} />
        }
        return <></>
    }

    const _handleTO = () => {
        setShow(false)
        message = null
        if (onTimeOut)
            onTimeOut()
    }
    useEffect(() => {

        if (!show) {
            setShow(true)
            setTimeout(_handleTO, time * 1000)
            setLatMessage(message)
        } else {
            setLatMessage(message)
        }
    }, [message])
    return (
        message && message !== ""
            ?
            <div
                style={{ zIndex: 10210 }}
                className={`ToastDialog position-fixed bg-dark m-5 p-3 rounded shadow me-2 ${className} ${show ? ' visible' : ' slide-out'}`}
            // style={{ right: '0px', top: '0px' }}
            >{icon && icon === 'check' ? _getIcon() : icon}{lastMessage}</div>
            :
            <></>
    )
}

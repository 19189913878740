import React, { useEffect, useRef, useState } from 'react'

export default function ConfirmDialog({ onCancel, onConfirm, title, heading, open, children, type }) {
    const [show, setShow] = useState(false)
    const yesRef = useRef()
    useEffect(() => {
        setShow(open)
        if (open) {
            console.log(yesRef.current)
            //yesRef.current.select()
        }
    }, [open])
    useEffect(() => {
        if (show && yesRef.current) yesRef.current.focus()
    }, [show])
    const _handleClicked = (b) => {
        if (b) onConfirm()
        else onCancel()
        setShow(false)
    }
    if (!show) return <></>
    return (
        <div className='position-absolute d-flex align-items-center justify-content-center text-center' style={{
            // background: 'rgba(0,0,0,0.7)',
            top: '0px'
            , bottom: '0px'
            , left: '0px'
            , right: '0px'
            , zIndex: 999
        }}>
            <div className='bg-primary-container text-white text-dark p-3 rounded shadow'>
                {heading ? <h3>{heading}</h3> : title ? <h3>Confirm</h3> : <></>}
                <div className=' bg-secondary-container p-2 rounded'>
                    <p>{children}</p>
                </div>
                <div className='mt-2'>
                    <button ref={yesRef} onClick={onConfirm ? () => _handleClicked(true) : undefined} className={`btn m-2 shadow ${!onConfirm ? 'disabled' : ''}`}>{type && type === 'Accept' ? 'Done' : 'Yes'}</button>
                    <button onClick={() => _handleClicked(false)} className='btn m-2 shadow'>{type && type === 'Accept' ? 'Decline' : 'No'}</button>
                </div>
            </div>
        </div>
    )
}

import FacilityUserRole from "./FacilityUserRols";

export default function FacilityUser({ userId, facilityId, facility, roleId, roles, onSelectItem}) {

    const _isRoleActive = (roleId) => {
        if (roleId) return roleId
    }
    return (
        <>
            <div><b>{facility} { }</b></div>
            <FacilityUserRole
                key={userId + facilityId + roleId}
                // onSelected={(r) => onSelected(r)}
                onSelectItem={(userId, facilityId, option, seld, isAdd) => onSelectItem(userId, facilityId, option, seld, isAdd)}
                userId={userId} facilityId={facilityId} defaultValue={_isRoleActive(roleId)} activeroles={roles} />
        </>
    )
}
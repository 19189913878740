import API from "../API";

var WorkFlowService = {
    async CreateWorkFlowNote(WFID,Note) {
        var result = await API.process(
            `api/WorkFlowService/${WFID}/Note`,
            'POST',
            Note
        ).catch((err) => {
            throw err;
        })

        return result
    },
    async DeleteWorkFlowNote(WFID,Note) {
        var result = await API.process(
            `api/WorkFlowService/${WFID}/Note`,
            'DELETE',
            Note
        ).catch((err) => {
            throw err;
        })

        return result
    },
}

export default WorkFlowService;
import React, { useEffect, useState } from 'react';
import { Check2Square, Square } from 'react-bootstrap-icons';
import WorkFlow from '../../../../../libs/api/services/WorkFlow';
import DOWSelectorComponent from '../../components/calendar_events/DOWSelectorComponent';
import CalendarSelectorComponent from '../../facility/components/CalendarSelectorComponent';

export default function EventTriggerCalendarEventCron({ link, type, values, eventId, filterTrigger }) {
    const PROPERTY_TYPE = 'calendar_cron';
    // const [options, setOptions] = useState({ messageTypes: [], receivedFrom: {}, subject: '' })
    const [availableCalendars, setAvailableCalendars] = useState([])
    const [properties, setProperties] = useState({})

    useEffect(() => { _loadProperties() }, [])
    useEffect(() => { if (properties && Object.keys(properties).length > 0) _handleSetProperty(properties) }, [properties])

    const _loadProperties = () => {
        try {
            WorkFlow
                .GetTriggerPropertyByType(typeof values.event === 'object' ? values.event.id : JSON.parse(values.event).id, link[0].id, PROPERTY_TYPE)
                .then((r) => {
                    setProperties(JSON.parse(r.value))
                }).catch((e) => { /* Doesnt exist yet */ })
        } catch (e) {
            // Not yet linked
        }
    }

    const _handleSetProperty = (v) => {
        try {
            WorkFlow
                .SetTriggerProperty(typeof values.event === 'object' ? values.event.id : JSON.parse(values.event).id, link[0].id, {
                    property: PROPERTY_TYPE,//'questionnaire_complete',
                    value: JSON.stringify(v),
                })
                .then((r) => {
                    // Log as property
                    // setLinked(_linked)
                })
        } catch (E) { }
    }
    return (
        <div className=' bg-light p-2 rounded'>
            <div className='row pt-2 text-primary'>
                <b>On event</b>
                <CalendarSelectorComponent
                    allowAdd={false}
                    filterTrigger={filterTrigger}
                    defaultValue={properties && properties.events && properties.events}
                    onSelected={(r) => setProperties({ ...properties, events: r })}
                />
            </div>
            <div className='row pt-2'>
                <div className='col-6 text-primary'>
                    <b>Who's calendar event to trigger on</b>
                    <select
                        onChange={(e) => setProperties({ ...properties, audience: e.target.value })}
                        className='form-control form-control-sm'>
                        <option></option>
                        <option selected={properties && properties.audience === 'Clinician'}>Clinician</option>
                        <option selected={properties && properties.audience === 'Patient'}>Patient</option>
                        <option selected={properties && properties.audience === 'Admin'}>Admin</option>
                    </select>
                </div>
            </div>
            <div className='row pt-2 text-primary'>
                <b>When</b>
                <div className='col-6'>

                    <select
                        onChange={(e) => setProperties({ ...properties, type: e.target.value })}
                        className='form-control form-control-sm'>
                        <option></option>
                        <option selected={properties && properties.type === 'Minutes'}>Minutes</option>
                        <option selected={properties && properties.type === 'Hours'}>Hours</option>
                        <option selected={properties && properties.type === 'Days'}>Days</option>
                        <option selected={properties && properties.type === 'Day Of Week'}>Day Of Week</option>
                    </select>
                </div>
                <div className='col-6'>
                    {
                        properties && properties.type && properties.type === 'Days'
                            ?
                            <div>
                                <input
                                    defaultValue={properties && properties.value}
                                    onChange={(e) => setProperties({ ...properties, value: e.target.value })}
                                    type='number'
                                    className='form-control form-control-sm' placeholder='Days' />
                            </div>
                            :
                            properties.type && properties.type === 'Hours'
                                ?
                                <div>
                                    <input
                                        defaultValue={properties && properties.value}
                                        onChange={(e) => setProperties({ ...properties, value: e.target.value })}
                                        type='number' className='form-control form-control-sm' placeholder='Hours' />
                                </div>
                                :
                                properties.type && properties.type === 'Minutes'
                                    ?
                                    <div>
                                        <input
                                            defaultValue={properties && properties.value}
                                            onChange={(e) => setProperties({ ...properties, value: e.target.value })}
                                            type='number' className='form-control form-control-sm' placeholder='Minutes' />
                                    </div>
                                    :
                                    properties && properties.type && properties.type === 'Day Of Week'
                                        ?
                                        <div>
                                            <DOWSelectorComponent
                                                onChange={(e) => setProperties({ ...properties, value: e })}
                                                multi={false} />
                                        </div>
                                        :
                                        <></>
                    }
                </div>
            </div>

            <div className='row'>
                <div className='col-12 py-3'>
                    <button onClick={() => setProperties({ ...properties, when: 'before' })} className={'w-50'.concat(properties.when === 'before' ? ' ' : ' opacity-75')}>
                        {properties.when === 'before' ? <Check2Square className='me-2' /> : <Square className='me-2' />}
                        Before Event</button>
                    <button onClick={() => setProperties({ ...properties, when: 'after' })} className={'w-50'.concat(properties.when === 'after' ? ' ' : ' opacity-75')}>
                        {properties.when === 'after' ? <Check2Square className='me-2' /> : <Square className='me-2' />}
                        After Event</button>
                </div>
            </div>
        </div>
    )
}

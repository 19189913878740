import React, { useContext, useState } from 'react'
import ErrorDialog from '../../../../../../components/dialogs/ErrorDialog'
import CalendarContext from '../../CalendarProvider'

export default function DayOverviewEventContainer({ option, event, flow, onCancel }) {
    const { forceLoad } = useContext(CalendarContext)
    const [idx, setIdx] = useState(0)
    const [props, setProps] = useState([])
    const [error, setError] = useState()

    const _handleBack = async () => {
        if (idx > 0) {
            var _id = idx - 1
            while (flow.flows[_id].onBeforeLoad && await flow.flows[_id].onBeforeLoad(props) === false) _id--
            setIdx(_id)
        }
    }
    const _handleNext = async () => {
        try {
            if (flow.flows[idx].onBeforeNext)
                _handleSetProp('result', await flow.flows[idx].onBeforeNext(props))
            if (idx < flow.flows.length) {
                var _id = idx + 1
                while (flow.flows[_id].onBeforeLoad && await flow.flows[_id].onBeforeLoad(props) === false) _id++
                setIdx(_id)
            }
        } catch (e) {
            setError(e)
        }
    }
    const _handleSetProp = (k, v) => {
        var _props = props
        if (!Object.keys(props).includes(String(idx)))
            _props[String(idx)] = {}
        _props[String(idx)][k] = v
        setProps([..._props])
    }

    const _handleComplete = async () => {
        if (await flow.onComplete(props)
            .catch((e) => _handleError(e))) {
            await forceLoad()
            onCancel()
        }
    }

    const _handleError = async (e) => setError(e)

    const _render = (c) => {
        return {
            ...c,
            props: {
                ...c.props, onSetProperty: (k, v) => _handleSetProp(k, v), properties: [...props], event: event, option: option
            }
        }
    }
    return (
        <>
            <ErrorDialog error={error} onClose={() => setError()} humanize={true} />
            <div className='d-flex flex-grow-1' 
            // style={{ maxHeight: 'calc(80vh - 150px)' }}
            >
                {_render(flow.flows[idx].component)}
            </div>
            <div className='flex-shrink-1'>
                {onCancel ? <button onClick={() => onCancel()} className='btn'>Cancel</button> : <></>}
                {idx > 0 ? <button onClick={() => _handleBack()} className='btn'>Back</button> : <></>}
                {idx < flow.flows.length - 1 ? <button onClick={() => _handleNext()} className='btn'>Next</button> : <></>}
                {idx === flow.flows.length - 1 && flow.onComplete ? <button onClick={() => _handleComplete()} className='btn'>Done</button> : <></>}
            </div>
        </>
    )
}

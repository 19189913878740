import React, { useState } from 'react'

export default function EmailAccountType({ onAccSelected, defaultValue }) {
    const [accountType, setAccountType] = useState(defaultValue ? defaultValue : '')

    const _handleSetAccountType = (a) => {
        setAccountType(a)
        onAccSelected(a)
    }
    return (
        <div className='row'>
            <div>Email Account Type</div>
            <div
                onClick={(e) => _handleSetAccountType('GMail')}
                className={'col-4 p-3 '.concat(accountType === 'GMail' ? 'active' : '')}>
                <label>GMail</label>
            </div>
            <div
                onClick={(e) => _handleSetAccountType('outlook')}
                className={'col-4 p-3 '.concat(accountType === 'outlook' ? 'active' : '')}>
                <label>Outlook.com</label>
            </div>
            <div
                onClick={(e) => _handleSetAccountType('exchange')}
                className={'col-4 p-3 '.concat(accountType === 'exchange' ? 'active' : '')}>
                <label>Microsoft Exchange</label>
            </div>
            <div
                onClick={(e) => _handleSetAccountType('office')}
                className={'col-4 p-3 '.concat(accountType === 'office' ? 'active' : '')}>
                <label>Office 365</label>
            </div>
            <div
                onClick={(e) => _handleSetAccountType('Other')}
                className={'col-4 p-3 '.concat(accountType === 'Other' ? 'active' : '')}>
                <label>Other Email Account Type</label>
            </div>
        </div>
    )
}

import React, { useContext, useEffect, useState } from 'react'
import FlowAssistant from '../../../../../libs/api/services/FlowAssistant'
import MessageTemple from '../../../../../libs/api/services/MessageTemple'
import WorkFlow from '../../../../../libs/api/services/WorkFlow'
import WorkFlowConfigurationContext from '../../../workflow/configuration/WorkFlowConfigurationProvider'
import ManageTaskContainer from '../../../workflow/configuration/facility/wizard/Commons/ManageTaskContainer'
import ServiceProviderSelectionComponent from '../../../workflow/configuration/facility/wizard/screens/Components/EventTasks/ServiceProviderSelectionComponent'
import MessagingTemplateSelectionComponent from './MessagingTemplateSelectionComponent'

export default function MessageTaskLineItem({ task, facility, user, onDelete, onSetProperty, onChanged, config, errors, values, setValue }) {
    const { event } = useContext(WorkFlowConfigurationContext)
    const [serviceProvider, setServiceProvider] = useState([])
    const [messagingTemplate, setMessagingTemplate] = useState([])

    const [mode, setMode] = useState('Edit')

    useEffect(() => { _loadMessagingTemplate() }, [])
    // useEffect(() => { _loadAuthorizedMessagingAccounts() }, [messagingTemplate])

    const _setProperty = (prop, val) => {
        //if(prop === 'ServiceProvider')
        var _valConf = event.tasks.find(t => t.id === config.id)
        if (_valConf) {
            try {
                var _prop = _valConf.properties.find(p => p.type.type === prop)
                if (_prop)
                    _prop.value = val
                else
                    _valConf.properties.push({ type: prop, value: val })
            } catch (e) {
                _valConf.properties.push({ type: prop, value: val })
            }
            setValue({ event: { ...event } })
        }
        WorkFlow
            .SetWorkFlowEventTaskProperty(event.id, prop, {
                id: task.id,
                properties: [{
                    type: { type: prop },
                    value: val
                }]
            })
            .catch((e) => errors(e))
    }

    const _loadMessagingTemplate = async () => {
        var _configred = false
        try {
            const MessageTemplateId = JSON.parse(config.properties.find(f => f.type.type === 'Message Template').value)
            if (MessageTemplateId) {
                const r = await MessageTemple.GetMessageTemplates(MessageTemplateId).catch((e) => errors(e))
                setMessagingTemplate(r)
                _configred = true
            } else { errors('There are no message template configurations linked to this task') }
        } catch (e) { _configred = false }
        try {
            const ServiceProviderId = JSON.parse(config.properties.find(f => f.type.type === 'Service Provider').value)
            if (ServiceProviderId) {
                // const r = await MessageTemple.GetMessageTemplates(MessageTemplateId).catch((e) => errors(e))
                setServiceProvider({ id: ServiceProviderId })
                if (_configred) _configred = true
            } //else { errors('There are no message template configurations linked to this task') }
        } catch (e) { _configred = false }
        setMode(_configred ? 'Configured' : 'Edit')
        // MessagingServiceProvider
        //     .GetAvailableProvidersFor()
    }

    const _handleSetTaskName = (messagingTemplate, serviceProvider) => {
        var name = 'Send message'
        if (messagingTemplate) {
            if (messagingTemplate.serviceType)
                name = 'Send ' + messagingTemplate.serviceType.type
            var _recipients = []
            name += ' template : ' + messagingTemplate.name
            if (messagingTemplate.audience && messagingTemplate.audience.length > 0) {
                for (const audience of messagingTemplate.audience)
                    if (audience.audienceType && audience.audienceType.type)
                        _recipients.push(audience.audienceType.type.toLowerCase())
            }
            if (_recipients.length > 0) name += ' to ' + _recipients.join(' and ');
        }
        if (serviceProvider) {
            if (serviceProvider.name)
                name += ' using ' + serviceProvider.name.toLowerCase()
        }
        return name
    }
    const _handleAddProvider = (suggestedName) => {
        const _values = { ...values, task: task }
        FlowAssistant
            .BookmarkFlowAndGoTo('/Facility/Configuration/Events', 'NEW_EVENT_WORKFLOW_ADD_EVENT', _values, '/Facility/Configuration/Messaging')
    }
    const _handleAddTemplate = (suggestedName) => {
        const _values = { ...values, task: task }
        FlowAssistant
            .BookmarkFlowAndGoTo('/Facility/Configuration/Events', 'NEW_EVENT_WORKFLOW_ADD_EVENT', _values, '/Facility/Configuration/Templates')
    }
    const _handleSetTemplate = (t) => {
        if (!t) return
        setServiceProvider()
        WorkFlow
            .SetWorkFlowEventTaskProperty(event.id, 'Message Template', {
                id: task.id,
                taskName: _handleSetTaskName(t, serviceProvider),
                taskType: task.taskType,
                properties: [{
                    value: JSON.stringify(t.id)
                }]
            })
            .then((r) => {
                setMessagingTemplate(t)
                if (onChanged) onChanged()
            }).catch((e) => { if (e.errors) errors(e.errors); else errors(e.message); })

    }
    const _handleSetServiceProvider = (t) => {
        if (!t) return
        setServiceProvider(t)
        WorkFlow
            .SetWorkFlowEventTaskProperty(event.id, 'Service Provider', {
                id: task.id,
                taskName: _handleSetTaskName(messagingTemplate, t),
                taskType: task.taskType,
                properties: [{
                    value: JSON.stringify(t.id)
                }]
            })
            .then((r) => {
                setServiceProvider(t)
                if (onChanged) onChanged()
            }).catch((e) => { if (e.errors) errors(e.errors); else errors(e.message); })

    }
    return (
        <ManageTaskContainer Task={task} onDelete={onDelete}>
            <b>Message template to be sent </b>
            <MessagingTemplateSelectionComponent
                defaultValue={messagingTemplate}
                onAdd={(s) => _handleAddTemplate(s)}
                onSelected={(e) => _handleSetTemplate(e)} />

            {
                messagingTemplate && messagingTemplate.serviceType ?
                    <>
                        {
                            <>
                                <ServiceProviderSelectionComponent
                                    description={`Please select the ${messagingTemplate.serviceType.type} service provider to process the message`}
                                    task={task}
                                    defaultValue={serviceProvider}
                                    serviceType={messagingTemplate.serviceType.type}
                                    facility={facility}
                                    user={user}
                                    onAdd={_handleAddProvider}
                                    onSelected={(s) => _handleSetServiceProvider(s)}
                                    errors={errors}
                                    values={values}
                                    setValue={setValue}
                                />
                            </>
                        }
                        <div className='text-center'>
                        </div>
                    </>
                    : ''
            }
        </ManageTaskContainer>
    )
}

import React, { useContext, useEffect, useState } from 'react';
import { GrStatusCritical, GrStatusGood } from 'react-icons/gr';
import { COLOURS } from '../../../../../components/colour/SimpleColourPicker';
import { ICONS } from '../../../../../components/icons/GenericIconPicker';
import { PRIORITIES } from '../../../../../components/priority/PriorityPicker';
import Authentication from '../../../../../libs/api/services/Authentication';
import ToDoListWorkFlows from '../../../../../libs/api/services/ToDoListWorkFlows';
import ToDoListContext from '../../ToDoListProvider';
import ToDoStatusDropDown from '../Statuses/ToDoStatusDropDown';
import ToDoListLineItemPopup from '../ToDoListLineItemPopup';
export default function ToDoListItemContainer({ assignmentId, item }) {
    const { extendedModal, LoadToDoLists, showToast } = useContext(ToDoListContext)
    const [details, setDetails] = useState({
        background: 'transparent',
        color: undefined,
        icons: [],
        severity: undefined
    })
    const _handleLoadProps = (s) => {
        if (!item.status || !item.status.status) return

        const _status = item.taskItem.items.find((a) => a.id === s)
        if (_status && _status.properties) {
            var _details = { ...details }
            const _COLOUR = _status.properties.find((p) => p.property === "COLOUR")
            if (_COLOUR) {
                _details['background'] = _COLOUR.value
                _details['color'] = COLOURS.find((c) => c.color === _COLOUR.value).secondary
            }
            else {
                _details['background'] = 'transparent'
                _details['color'] = undefined
            }
            const _ICONS = _status.properties.find((p) => p.property === "ICONS")
            if (_ICONS)
                _details['icons'] = JSON.parse(_ICONS.value)
            else
                _details['icons'] = []
            const _SEVERITY = _status.properties.find((p) => p.property === "SEVERITY")
            if (_SEVERITY)
                _details['severity'] = _SEVERITY.value
            else
                _details['severity'] = undefined
            setDetails(_details)
        } else {
            setDetails({ ...details, background: 'transparent', color: undefined, icons: [], severity: undefined })
        }

    }

    useEffect(() => { if (item.status && item.status.status) _handleLoadProps(item.status.status.id) }, [item])

    const _handleChangeStatus = async (event) => {

        // Set Status with API
        if (!event.target.value) {
            return showToast(`Please select a valid status`,
                <GrStatusCritical className='me-2' />, 3);
        }

        await ToDoListWorkFlows
            .SetTaskStatus(assignmentId, item.taskItem.id, event.target.value)
            .then((e) => {
                showToast(`Status has been changed to ${item.taskItem.items.find(i => i.id === event.target.value).item}`,
                    <GrStatusGood className='me-2' />, 3);
                LoadToDoLists()
            })
    }

    return (
        <div className='p-2 m-2 btn w-100 d-flex' style={{ background: details.background, color: details.color }}>
            {/* <div className='d-flex' style={{ cursor: 'pointer' }}> */}
            <button
                onClick={(e) => {
                    // if (e.target !== e.currentTarget)
                    // e.stopPropagation();
                    if (Authentication.getFacilityRole() !== 'Patient')
                        extendedModal({
                            show: true, icon: undefined, title: undefined, component:
                                <ToDoListLineItemPopup assignmentId={assignmentId} onChange={(d) => setDetails(d)} defaultValues={details} item={item} />
                        })
                }}
                className='btn flex-grow-1 d-flex align-items-center'
            >
                {details.icons.map((i) => ICONS.find((x) => x.name === i) && ICONS.find((x) => x.name === i).icon)}
                {details.severity ? PRIORITIES.find((p) => p.proprity === details.severity).icon : undefined}
                <div className='flex-grow-1 d-flex align-items-center justify-content-start'

                >
                    {item.taskItem.list}
                </div>
            </button>

            {/* </div> */}
            <div>
                <ToDoStatusDropDown
                    onChange={_handleChangeStatus}
                    statuses={item.taskItem.items}
                    active={item.status} />

            </div>

        </div>
    )
}

import API from "../API";

var Logs = {
    async Search(search) {
        var result = await API.process(
            'api/Logs/Search',
            'POST',
            search
        ).catch((err) => {
            throw err;
        })

        return result
    },
    async ListEventTypes() {
        var result = await API.process(
            'api/Logs/filters/eventTypes',
            'GET',
            null
        ).catch((err) => {
            throw err;
        })

        return result
    },
}

export default Logs;
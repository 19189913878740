import React, { useContext, useEffect, useState } from 'react'
// import CalendarFacilityContext from '../../CalendarFacilityProvider'
import CalendarFacilityContext from '../../CalendarFacilityProvider'
import CelendarTypeSelection from './CelendarTypeSelection'
import CalenderEventDetailsDropDown from './Options/CalenderEventDetailsDropDown'

export default function CalendarEventDetailsProperties() {
  const [active, setActive] = useState({ id: -1 })
  const { activeCalendarEvent } = useContext(CalendarFacilityContext)
  const [properties, setProperties] = useState({
    trigger: {},
    properties: [
      { name: 'DEFAULT_SUMMARY_ORIGINAL', value: undefined },
      { name: 'DEFAULT_DETAILS_ORIGINAL', value: undefined },
      { name: 'DEFAULT_STATUS_ORIGINAL', value: undefined },
      { name: 'DEFAULT_SUMMARY_NEW', value: undefined },
      { name: 'DEFAULT_DETAILS_NEW', value: undefined },
      { name: 'DEFAULT_STATUS_NEW', value: undefined },
    ]
  })


  const _handleSetProperty = (n, v) => { }

  useEffect(() => {
    if (activeCalendarEvent) {
      setActive(activeCalendarEvent.triggeredWhen)
      if (activeCalendarEvent.properties) setProperties({ ...properties, properties: activeCalendarEvent.properties })
    }
  }, [activeCalendarEvent])
  useEffect(() => { setProperties({ ...properties, trigger: active }) }, [active])


  return (
    <div className='d-flex justify-content-evenly' style={{height: 'auto'}}>
      <div className='w-100 p-2'>
        <b>When this event is selected :</b>
        <CalenderEventDetailsDropDown defaultOption={activeCalendarEvent ? activeCalendarEvent.triggeredWhen : undefined}
          //  options={CalendarEventDetailOptions}
          properties={properties} onSelected={(e) => setActive(e)} onChange={(p) => _handleSetProperty(p)} />
      </div>
      <div className='h-100 w-100 p-2'>
        <b>This event is available on</b>
        <CelendarTypeSelection defaultValue={activeCalendarEvent ? activeCalendarEvent.availableCalendars : undefined} />
      </div>

    </div>
  )
}

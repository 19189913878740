import Authentication from "../api/services/Authentication"

export const TokenValidation = ({ children }) => {
    if (String(window.location).includes('/tnc')) return children
    Authentication
        .verifyToken()
    // if (Authentication.isClaimValid("System Admin")) {
    //     return children;
    // }
    // if (claim.includes(",")) {
    //     let claims = claim.split(",")
    //     let claimValid = false;
    //     claims.forEach(element => {
    //         if (Authentication.isClaimValid(element.trim())) {
    //             claimValid = true;
    //         }
    //     });
    //     if (claimValid)
    //         return children;
    // } else if (Authentication.isClaimValid(claim)) {
    //     return children;
    // }
    // if (enforce)
    //     return window.location = '/';
    return children
    // if(security.verifySession()){
    //     return children;
    // }else{
    //     return <LoginScreen />;
    // }
}
import React, { useState } from 'react'
import { CheckSquare, Flag, FlagFill, Pencil, Square, Trash, XCircle } from 'react-bootstrap-icons'
import QBuilderComponentSingleSelctSubSection from './QBuilderComponentSingleSelctSubSection'
import LabelsOptions from './components/LabelsOptions'

export default function QBuilderComponentSingleSelctItem({ onRerender, o, onRemove, onEdit, values, SectionComponents }) {
    const [mode, setMode] = useState('')
    const [newValue, setNewValue] = useState(o)
    return (
        mode === 'Edit'
            ?
            <div className='bg-primary-container p-1 rounded shadow'>
                <div>
                    <input
                        onChange={(e) => setNewValue({ ...newValue, option: e.target.value })}
                        defaultValue={o.option} className='form-control form-control-sm' />
                </div>
                <div className='row'>
                    <div className='col-4 text-center'>
                        <b>Answer Weight</b>
                        <input
                            defaultValue={newValue.weight}
                            onChange={(e) => setNewValue({ ...newValue, weight: e.target.value })}
                            type='number'
                            className='form-control form-control-sm' placeholder='Answer Weight' />
                    </div>
                    <div
                        onClick={(e) => setNewValue({ ...newValue, trigger: !newValue.trigger })}
                        className='col-4 text-center'>
                        <b>Trigger Alert</b>
                        <div>
                            {
                                newValue.trigger
                                    ?
                                    <CheckSquare size={20} className='me-1' />
                                    :
                                    <Square size={20} className='me-1' />
                            }
                            Alert if option selected
                        </div>
                    </div>
                    <div
                        className='col-4 text-center'>
                        <b>Activate sub-section</b>
                        <QBuilderComponentSingleSelctSubSection
                            SectionComponents={SectionComponents}
                            onRerender={() => onRerender()}
                            SectionId={values.section ? values.section.id : ''}
                            options={newValue}
                            onChanged={(v) => setNewValue({ ...newValue, subsection: v })}
                        />
                    </div>
                    <div
                        className='col-4 text-center'>
                        <LabelsOptions
                            defaultLabels={newValue.Labels}
                            onChange={(l) => setNewValue({ ...newValue, Labels: l })}
                        />
                    </div>
                </div>
                <div className='text-center'>
                    <button
                        onClick={(e) => { onEdit(newValue); setMode(''); if (onRerender) onRerender() }}
                        className='btn btn-xs'>
                        <Pencil className='me-2' />
                        Save Changes

                    </button>
                    <button
                        onClick={(e) => { setMode('') }}
                        className='btn btn-xs'>
                        <XCircle className='me-2' />
                        Discard
                    </button>
                </div>
            </div>
            :
            <div className='d-flex '>
                <div onClick={() => setMode('Edit')} className='flex-grow-1'>
                    {
                        o.trigger
                            ?
                            <FlagFill className='me-1 text-danger' />
                            :
                            <Flag className='me-1 ' />
                    }
                    <b className='me-2'>{o.weight}</b>
                    {o.option}
                </div>
                <div>

                    <button
                        onClick={(e) => { onRemove() }}
                        className='btn btn-xs'>
                        <Trash />
                    </button>
                </div>
            </div>
    )
}

import React from 'react'
import { SectionInterface } from '../interfaces/SectionsInterface'

type Props = {
  section: string
  sections: SectionInterface[] | undefined
}

export default function NavigationWizardSteps(props: Props) {
  if (props.sections!.length < 2) return <></>
  let _complete = 'complete'
  const __show_node = false
  const __show_section = false
  return (
    <div className='wizard-navigation-steps'>
      {props.sections!.map(function (_section) {
        if (_section.name === props.section) _complete = 'in-progress'
        else if (_complete === 'in-progress') _complete = ''

        return (
          <span className={'wizard-navigation-step '.concat(_complete)} key={_section.name}>
            {__show_section ? _section.name : ''}
            {__show_node ? <div className='node'></div> : ''}
          </span>
        )
      })}
    </div>
  )
}

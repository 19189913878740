import React, { useEffect, useState } from 'react'
import QBuilderComponentDatePicker from '../../../builder/components/QBuilderComponentDatePicker'
import QBuilderComponentDisplayText from '../../../builder/components/QBuilderComponentDisplayText'
import QBuilderComponentDynamicComponentTable from '../../../builder/components/QBuilderComponentDynamicComponentTable'
import QBuilderComponentFacilities from '../../../builder/components/QBuilderComponentFacilities'
import QBuilderComponentMultiSelct from '../../../builder/components/QBuilderComponentMultiSelct'
import QBuilderComponentSingleSelct from '../../../builder/components/QBuilderComponentSingleSelct'
import QBuilderComponentSubSection from '../../../builder/components/QBuilderComponentSubSection'
import QBuilderComponentTextInout from '../../../builder/components/QBuilderComponentTextInout'
import QBuilderICDCodesItem from '../../../builder/components/widgets/QBuilderICDCodesItem'
import QBuilderPMBCodesItem from '../../../builder/components/widgets/QBuilderPMBCodesItem'

export default function QCaptureComponentWrapper({ cache, component, state, mode, defaultMode, view, onSetData, edit, activeSubsections, onAction }) {
    const [visible, setVisible] = useState(true)

    const _hasSubsection = (component, activeSubsections) => {
        const _subsect = component.properties.find(p => p.property === 'subsection')
        if (_subsect)
            for (const _ssect of Object.keys(activeSubsections))
                if (activeSubsections[_ssect] === _subsect.values)
                    return true
    }

    useEffect(() => {
        try { setVisible(_hasSubsection(component, activeSubsections)) } catch (e) { }
    }, activeSubsections[component.id])
    if (component.componentType)
        switch (component.componentType.type) {
            case 'Display Text':
                return (<QBuilderComponentDisplayText
                    cache={cache ? cache : undefined}
                    onSetData={(value, update) => onSetData(component.id, value, update)}
                    activeQuestionnaireAssignment={state ? state.details.assignment : {}}
                    onLoadComplete={() => { }}
                    view={view === "New" ? "New" : mode}
                    ComponentId={component.id}
                    defaultMode={defaultMode ? defaultMode : ''}
                    edit={edit}
                    onAction={(a) => onAction(a)}
                    gloabloptions={state ? state.details.options : {}}
                    onChangeMode={() => { }}
                />)
            case 'Get Input text':
                return (<QBuilderComponentTextInout
                    cache={cache ? cache : undefined}
                    onSetData={(value, update) => onSetData(component.id, value, update)}
                    activeQuestionnaireAssignment={state ? state.details.assignment : {}}
                    onLoadComplete={() => { }}
                    view={view === "New" ? "New" : mode}
                    ComponentId={component.id}
                    defaultMode={defaultMode ? defaultMode : ''}
                    gloabloptions={state ? state.details.options : {}}
                    onChangeMode={() => { }}
                    edit={edit}
                    onAction={(a) => onAction(a)}
                />)
            case 'Multiple Selection':
                return (<QBuilderComponentMultiSelct
                    cache={cache ? cache : undefined}
                    onSetData={(value, update) => onSetData(component.id, value, update)}
                    activeQuestionnaireAssignment={state ? state.details.assignment : {}}
                    onLoadComplete={() => { }}
                    view={view === "New" ? "New" : mode}
                    ComponentId={component.id}
                    defaultMode={defaultMode ? defaultMode : ''}
                    gloabloptions={state ? state.details.options : {}}
                    onChangeMode={() => { }}
                    edit={edit}
                    onAction={(a) => onAction(a)}
                />)
            case 'Single Selection':
                return (<QBuilderComponentSingleSelct
                    cache={cache ? cache : undefined}
                    onSetData={(value, update) => onSetData(component.id, value, update)}
                    activeQuestionnaireAssignment={state ? state.details.assignment : {}}
                    onLoadComplete={() => { }}
                    view={view === "New" ? "New" : mode}
                    ComponentId={component.id}
                    defaultMode={defaultMode ? defaultMode : ''}
                    gloabloptions={state ? state.details.options : {}}
                    onChangeMode={() => { }}
                    edit={edit}
                    mode={mode}
                    onAction={(a) => onAction(a)}
                />)
            case 'Date Picker':
                return (<QBuilderComponentDatePicker
                    cache={cache ? cache : undefined}
                    onSetData={(value, update) => onSetData(component.id, value, update)}
                    activeQuestionnaireAssignment={state ? state.details.assignment : {}}
                    onLoadComplete={() => { }}
                    view={view === "New" ? "New" : mode}
                    ComponentId={component.id}
                    defaultMode={defaultMode ? defaultMode : ''}
                    gloabloptions={state ? state.details.options : {}}
                    onChangeMode={() => { }}
                    edit={edit}
                    onAction={(a) => onAction(a)}
                />)
            case 'Facilities':
                return (<QBuilderComponentFacilities
                    cache={cache ? cache : undefined}
                    onSetData={(value, update) => onSetData(component.id, value, update)}
                    activeQuestionnaireAssignment={state ? state.details.assignment : {}}
                    onLoadComplete={() => { }}
                    view={view === "New" ? "New" : mode}
                    ComponentId={component.id}
                    defaultMode={defaultMode ? defaultMode : ''}
                    gloabloptions={state ? state.details.options : {}}
                    onChangeMode={() => { }}
                    edit={edit}
                    mode={mode}
                    onAction={(a) => onAction(a)}
                />)
            case 'Sub Section':
                return (<QBuilderComponentSubSection
                    cache={cache ? cache : undefined}
                    visible={visible}
                    onSetData={(_componentid, value, update) => onSetData(_componentid, value, update)}
                    activeQuestionnaireAssignment={state ? state.details.assignment : {}}
                    onLoadComplete={() => { }}
                    view={view === "New" ? "New" : mode}
                    ComponentId={component.id}
                    // subsectionId={component.properties.find(p => p.property === 'subsection').values}
                    defaultMode={defaultMode ? defaultMode : ''}
                    gloabloptions={state ? state.details.options : {}}
                    onChangeMode={() => { }}
                    state={state}
                    stateMode={mode}
                    edit={edit}
                    onAction={(a) => onAction(a)}
                />)
            case 'Dynamic Component Table':
                return (<QBuilderComponentDynamicComponentTable
                    cache={cache ? cache : undefined}
                    visible={visible}
                    onSetData={(_componentid, value, update) => onSetData(_componentid, value, update)}
                    activeQuestionnaireAssignment={state ? state.details.assignment : {}}
                    onLoadComplete={() => { }}
                    view={view === "New" ? "New" : mode}
                    ComponentId={component.id}
                    defaultMode={defaultMode ? defaultMode : ''}
                    gloabloptions={state ? state.details.options : {}}
                    onChangeMode={() => { }}
                    state={state}
                    mode={mode}
                    edit={edit}
                    onAction={(a) => onAction(a)}
                />)
            case 'ICD10':
                return (<QBuilderICDCodesItem
                    cache={cache ? cache : undefined}
                    visible={visible}
                    onSetData={(value, update) => onSetData(component.id, value, update)}
                    activeQuestionnaireAssignment={state ? state.details.assignment : {}}
                    onLoadComplete={() => { }}
                    view={view === "New" ? "New" : mode}
                    ComponentId={component.id}
                    defaultMode={defaultMode ? defaultMode : ''}
                    gloabloptions={state ? state.details.options : {}}
                    onChangeMode={() => { }}
                    state={state}
                    mode={mode}
                    edit={edit}
                    onAction={(a) => onAction(a)}
                />)
            case 'PMB':
                return (<QBuilderPMBCodesItem
                    cache={cache ? cache : undefined}
                    visible={visible}
                    onSetData={(value, update) => onSetData(component.id, value, update)}
                    activeQuestionnaireAssignment={state ? state.details.assignment : {}}
                    onLoadComplete={() => { }}
                    view={view === "New" ? "New" : mode}
                    ComponentId={component.id}
                    defaultMode={defaultMode ? defaultMode : ''}
                    gloabloptions={state ? state.details.options : {}}
                    onChangeMode={() => { }}
                    state={state}
                    mode={mode}
                    edit={edit}
                    onAction={(a) => onAction(a)}
                />)
            default:
                return <div></div>
        }
}

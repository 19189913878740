import Authentication from "./libs/api/services/Authentication";
import { ClaimsFilter } from "./libs/authentication/ClaimsFilter";
import ClinicianManagementLayout from "./screens/layout/ClinicianManagementLayout";
import { MenuContainer } from "./screens/menu/MenuContainer";
import PatientearchBar from "./screens/search/PatientearchBar";
import WorkFlowDetailsScreen from "./screens/section/workflow/management/WorkFlowDetailsScreen";

const AppRoutesWorkFlows = [

  // Doctor
  {
    path: '/WorkFlow/:id',
    element: <ClaimsFilter enforce={true} claim={"System Admin,Admin,Clinician"}>
      <ClinicianManagementLayout menu={<MenuContainer key={"menu_cnt" + new Date().getTime()}
        menu={Authentication.getFacilityRole() === "Admin" ? "Receptionist" : Authentication.getFacilityRole()} activeSection="" />}
        search={<PatientearchBar />} breadcrums={[{ name: 'Home', location: '/' + Authentication.getFacilityRole() }]}
        container={<WorkFlowDetailsScreen />} /></ClaimsFilter>
  },
];

export default AppRoutesWorkFlows;

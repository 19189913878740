import React, { useState } from 'react'

export default function StatsLabelsComponent({ labels, activeLabels, onChange, meta }) {
    const _checkCount = (label) => {
        if (meta && meta.totalWeightingLabel) {
            const _label = meta.totalWeightingLabel.find(m => m.label === label)
            if (_label)
                return _label.total
        }
    }

    if (!labels) return <></>
    return (
        <>
            <b>Labels: </b>
            <div style={{ maxWidth: '50vw', overflow: 'auto' }}>
                {
                    labels.map((label) =>
                        <span
                            style={{ cursor: 'pointer' }}
                            onClick={() => onChange(label)}
                            key={label} className={'label label-info bg-secondary-container rounded rounded-4 px-1 '.concat(activeLabels.includes(label) ? '' : 'opacity-50')} >{label}<span className='bg-secondary shadow rounded  rounded-5'>{_checkCount(label)}</span></span>
                    )
                }</div>
        </>
    )
}

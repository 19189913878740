import React, { useEffect } from 'react'
export default function EmailWizardWebhook({ title, description, navigation, errors, setValue, values }) {

    useEffect(() => {
        title('Done')
        description('Email account setup complete')
        navigation([
            {
                text: 'Back', type: 'Back', action: async () => { }
            },
            { text: 'Done', type: 'Done', action: async () => { } }
        ])
        // _loadSettings()
    }, [])

    return (
        <>
        </>
    )
}

import React, { useState } from 'react'

const CalendarEntriesContext = React.createContext()

export const CalendarEntriesProvider = ({ defaultValue, children }) => {
    const [CalendarEntries, setCalendarEntries] = useState(defaultValue ? defaultValue : [])

    return (
        <CalendarEntriesContext.Provider value={{
            calendarEntries: CalendarEntries
        }}>
            {children}
        </CalendarEntriesContext.Provider>
    )
}
export default CalendarEntriesContext
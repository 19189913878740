import React from 'react'
import { Clock, ClockHistory, Person, PersonFill } from 'react-bootstrap-icons'

export default function MessageEntryDetails({ item }) {
    return (
        <div>
            <div
                className='rounded bg-secondary-container p-2 m-2'>
                <div className='d-flex justify-content-between'>
                    <div className='d-flex flex-column'>
                        <div className='text-start'><Person className='me-2' /><b>To</b> {item.message.to}</div>
                        <div className='text-start'><Clock className='me-2' /><b>Sent</b> {item.message.sentDate}</div>
                    </div>

                    <div className='d-flex flex-column'>
                        <div className='text-start'><PersonFill className='me-2' /><b>From</b> {item.message.from}</div>
                        <div className='text-start'><ClockHistory className='me-2' /><b>Received</b> {item.message.receviedDate}</div>
                    </div>
                </div>
                <div className='d-flex'>
                    <div className='text-start'><b>Subject</b> {item.message.subject}</div>
                </div>
            </div>
            {item.message.messageBody && <div dangerouslySetInnerHTML={{ __html: item.message.messageBody.body }} />}
        </div>
    )
}

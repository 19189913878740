import React, { useContext, useEffect, useState } from 'react'
import ErrorDialog from '../../../../components/dialogs/ErrorDialog'
import ToastDialog from '../../../../components/dialogs/toast_dialog/ToastDialog'
import FlowAssistant from '../../../../libs/api/services/FlowAssistant'
import StickyNavigationContext from '../../../../libs/providers/StickyNavigationProvider'
import MessageTemplateWizardAudience from './components/MessageTemplate/wizard/MessageTemplateWizardAudience'
import MessageTemplateWizardDone from './components/MessageTemplate/wizard/MessageTemplateWizardDone'
import MessageTemplateWizardServiceType from './components/MessageTemplate/wizard/MessageTemplateWizardServiceType'
import MessageTemplateWizardSetupType from './components/MessageTemplate/wizard/MessageTemplateWizardSetupType'
import MessageTemplateWizardTemplate from './components/MessageTemplate/wizard/MessageTemplateWizardTemplate'
import MessageTemplateWizardWelcome from './components/MessageTemplate/wizard/MessageTemplateWizardWelcome'

export default function MessagingTemplateAdmin({ startStep }) {
    const [step, setStep] = useState(0)
    const [title, setTitle] = useState('')
    const [description, setDescription] = useState('')
    const [navigation, setNavigation] = useState([])
    const [errors, setErrors] = useState('')
    const [values, setValues] = useState({})
    const [status, setStatus] = useState({})
    const [history, setHistory] = useState({})
    const [navigationContext, setNavigationContext] = useState('Start')
    const { setSticky, setPreventMessage } = useContext(StickyNavigationContext)

    const _handleSetStep = (s) => {
        if (values.action) {
            if (step > 5) {
                setStep(s + 4)
            }
            setValues({ ...values, action: undefined })
            return
        }
        // if (step !== 5) {
        setStep(s)
        // }
    }
    const _handleSetNavigation = async (n) => {
        //alert(JSON.stringify(n))
        var _nav = []
        var history = FlowAssistant
            .GetLast()
        if (step === 0) {
            if (startStep)
                setStep(startStep)

            if (history) {
                setNavigationContext(history.section)
                var vs = {}
                for (const k of Object.keys(history.details)) {
                    // const parsed = JSON.parse(history.details[k])
                    try {
                        vs[k] = JSON.parse(history.details[k])
                    } catch (e) {
                        vs[k] = history.details[k]
                    }
                    setValues({ ...values, ...vs })
                }
            }
        }
        if (history) {
            _nav.push({ type: 'Action', action: () => FlowAssistant.Goto(history.section), text: 'Cancel' })
        }
        _nav = [..._nav, ...n]
        setNavigation(_nav)
    }

    useEffect(() => {
        setHistory(FlowAssistant.GetLast())
    }, [])

    useEffect(() => {
        if (step != 1 && step != 5) {
            setSticky(true)
            setPreventMessage('Configuration Incomplete', 'You have not completed the configuration process. <br/><br/>This can be completed later, however template will not be active until completed. <br/><br/>Are you sure you would like to cancel?')
        } else {
            setSticky(false)
        }
    }, [step])

    useEffect(() => {
        if (values.action && values.action.to) {
            try {
                step >= 5 ? setStep(values.action.to + 4)
                    : setStep(values.action.to)
            } catch (e) { }
        }
    }, [values])
    const _getStepSection = () => {
        switch (step) {
            case 0:
                return <MessageTemplateWizardWelcome
                    title={(t) => setTitle(t)}
                    description={(t) => setDescription(t)}
                    navigation={(t) => _handleSetNavigation(t)}
                    errors={(e) => setErrors(e)}
                    setValue={(v) => setValues({ ...values, ...v })}
                    values={values}
                />
            case 1:
                return <MessageTemplateWizardSetupType
                    title={(t) => setTitle(t)}
                    description={(t) => setDescription(t)}
                    navigation={(t) => _handleSetNavigation(t)}
                    errors={(e) => setErrors(e)}
                    setValue={(v) => setValues({ ...values, ...v })}
                    navigationContext={navigationContext}
                    values={values}
                />
            case 2:
                return <MessageTemplateWizardServiceType
                    title={(t) => setTitle(t)}
                    description={(t) => setDescription(t)}
                    navigation={(t) => _handleSetNavigation(t)}
                    errors={(e) => setErrors(e)}
                    setValue={(v) => setValues({ ...values, ...v })}
                    values={values}
                />
            case 3:
                return <MessageTemplateWizardTemplate
                    title={(t) => setTitle(t)}
                    description={(t) => setDescription(t)}
                    navigation={(t) => _handleSetNavigation(t)}
                    errors={(e) => setErrors(e)}
                    setValue={(v) => setValues({ ...values, ...v })}
                    values={values}
                />
            case 4:
                return <MessageTemplateWizardAudience
                    title={(t) => setTitle(t)}
                    description={(t) => setDescription(t)}
                    navigation={(t) => _handleSetNavigation(t)}
                    errors={(e) => setErrors(e)}
                    setValue={(v) => setValues({ ...values, ...v })}
                    values={values}
                />
            case 5:
            case 6:
            case 7:
            case 8:
            case 9:
            case 10:
            case 11:
                // FlowAssistant.BookmarkFlowAndGoTo('/sys-admin/messaging', 'WorkFlows', values, '/Facility/Configuration/Events', 1)
                // return <div></div>
                return <MessageTemplateWizardDone
                    title={(t) => setTitle(t)}
                    step={step}
                    description={(t) => setDescription(t)}
                    navigation={(t) => _handleSetNavigation(t)}
                    // navigator={{
                    //     goto: (s) => setStep(s)
                    // }}
                    errors={(e) => setErrors(e)}
                    setValue={(v) => setValues({ ...values, ...v })}
                    setStatus={(v) => setStatus(v)}
                    values={values}
                    history={history}
                />
            // case 6:
            //     return <MessageTemplateWizardSummary
            //         title={(t) => setTitle(t)}
            //         description={(t) => setDescription(t)}
            //         navigation={(t) => _handleSetNavigation(t)}
            //         // navigator={{
            //         //     goto: (s) => setStep(s)
            //         // }}
            //         errors={(e) => setErrors(e)}
            //         setValue={(v) => setValues({ ...values, ...v })}
            //         setStatus={(v) => setStatus(v)}
            //         values={values}
            //     />
            default:
                return <div>?</div>
        }
    }

    return (
        <>
            <ToastDialog {...status} />
            <ErrorDialog error={errors} onClose={() => setErrors('')} humanize={true} />
            <div
                className="Wizard top-center position-relative">
                <div className="head"><h1>{title}</h1>
                    <p dangerouslySetInnerHTML={{ __html: description }}></p>

                    <div
                        className="error-details"></div>
                </div>
                <div className="body" style={{ overflowX: 'hidden', overflowY: 'auto' }}>
                    <div
                        className=".section d-flex flex-column align-items-stretch h-100 w-100">
                        {_getStepSection()}
                    </div>
                </div>
                <div classname="tail">
                    {
                        navigation.map((n) => {
                            if (n.type === 'Next')
                                return <button
                                    key={n.text}
                                    onClick={async (e) => {
                                        if (n.action) {
                                            try {
                                                await n.action()
                                                    .catch(() => {
                                                        setErrors(e.message)
                                                        return
                                                    })
                                            } catch (e) {
                                                return
                                            }
                                        }
                                        _handleSetStep(step + 1)
                                    }} className="btn ng-buttons">{n.text}</button>
                            else if (n.type === 'Back')
                                return <button
                                    key={n.text}
                                    onClick={async (e) => {
                                        if (n.action) {
                                            await n.action().catch((e) => setErrors(e.message))
                                        }
                                        if (values.action) {
                                            _handleSetStep(values.action.from)
                                        } else {
                                            _handleSetStep(step - 1)
                                        }
                                    }} className="btn ng-buttons">{n.text}</button>
                            else if (n.type === 'Action')
                                return <button
                                    key={n.text}
                                    onClick={async (e) => {
                                        if (n.action)
                                            await n.action()
                                                .catch((e) => setErrors(e.message))
                                    }} className="btn ng-buttons">{n.text}</button>
                            else if (n.type === 'Done')
                                return <button
                                    key={n.text}
                                    onClick={async (e) => {
                                        if (n.action)
                                            await n.action()
                                                .catch((e) => setErrors(e.message))
                                        _handleSetStep(1)
                                    }} className="btn ng-buttons">{n.text}</button>
                            else if (n.type === 'Step')
                                return <button
                                    key={n.text}
                                    onClick={async (e) => {
                                        if (n.action)
                                            await n.action()
                                                .catch((e) => setErrors(e.message))
                                        _handleSetStep(n.step)
                                    }} className="btn ng-buttons">{n.text}</button>
                        })
                    }
                </div>
            </div>
        </>
    )
}

import React, { useEffect, useState } from 'react'
import { Clipboard2CheckFill } from 'react-bootstrap-icons'
import Authentication from '../../../../../libs/api/services/Authentication'
import Questionnaire from '../../../../../libs/api/services/Questionnaire'
import QuestionnaireAssignment from '../../../../../libs/api/services/QuestionnaireAssignment'

export default function FormAssignmentSelection({ title, description, navigation, errors, setValue, values }) {

  const [questionnaires, setQuestionnaires] = useState([])
  const [questionnaire, setQuestionnaire] = useState()

  useEffect(() => {
    title('Forms')
    description('Please select the form you would like to complete')
    // Load Navigation if session started
    // if (Object.keys(values).includes('templateId'))
    navigation([
      { text: '', type: '', action: async () => { } },
    ])

    if (Object.keys(values).includes('questionnaire'))
      setQuestionnaire(values.questionnaire)

    load();
  }, [])

  useEffect(() => {
    if (questionnaire)
      navigation([
        {
          text: 'Next', type: 'Done', action: async () => {
            // Create and redirect
            QuestionnaireAssignment
              .AssignQuestionnaire(values.questionnaire.id, values.user.userProfile.id, {
                assignedDate: values.scheduleDateTime,
                questionnaire: {
                  name: ''
                },
                Responses: []
              }).then((r) => {
                window.location = '/' + Authentication.getFacilityRole() + '/Patient/' + values.user.id + '/Form/' + values.questionnaire.id + '/' + r.id
              });
          }
        },
      ])
  }, [questionnaire])

  const load = () => {
    Questionnaire
      .ListQuestionnairesByType('Form')
      .then((r) => {
        setQuestionnaires(r)
      })
  }

  const _handleSetQuestionnaire = (q) => {
    setValue({ questionnaire: q })
    setQuestionnaire(q)
  }

  return (
    <div className='h-100 d-flex flex-column bg-primary-container rounded m-3' style={{ overflow: 'auto' }}>
      {
        questionnaires.map((q) =>
          <div
            key={q.id}
            style={{ cursor: 'pointer' }}
            className={'m-1 p-1 text-start  '.concat(questionnaire && questionnaire.id === q.id ? ' active ' : '')}
            onClick={(e) => _handleSetQuestionnaire(q)}
          >
            <Clipboard2CheckFill className='me-2' size={20} />{q.name}</div>
        )
      }

    </div>
  )
}

import { useEffect, useState } from "react";
import User from "../../libs/api/services/User";
import FacilityManagementComponent from "../../screens/section/user/admin_add_user/facility/FacilityManagementComponent";
import ToastDialog from "../dialogs/toast_dialog/ToastDialog";
import FacilityUser from "../facility/FacilityUser";

export default function FacilityUserWidget({ id }) {
    const [usersFacilities, setUsersFacilities] = useState([])
    const [status, setStatus] = useState('loading')
    const [confirm, setConfirm] = useState({ title: '', description: '', show: false, action: undefined })

    const getUserFacilities = () => {
        setStatus('loading')
        User.get(id)
            .then((res) => {
                // Permissions
                setUsersFacilities(res.userFacilitieRoles)
                setStatus('ready')
            }).catch((err) => {
                setStatus('ready')
            })
    }
    useEffect(() => {
        getUserFacilities()
    }, [id])


    const onFacilitySelected = (facilityId) => {
        User.addFacility(id, facilityId, 0)
            .then((res) => {
                getUserFacilities();
            })
    }

    const _handleSelectFacility = (facilityId) => {
        setStatus('saving')
        User.addFacility(id, facilityId, 0)
            .then((r) => {
                setTimeout(getUserFacilities, 70)
                // getUserFacilities()
                setStatus('Saved')
            })
    }

    const _handleFacilityAdded = (facilityId) => {
        setStatus('saving')
        User.addFacility(id, facilityId, 0)
            .then((r) => {
                getUserFacilities()
                setStatus('Saved')
            })
    }

    const _handleChangePriv = (r,isAdd) => {
        setStatus('saving')
        setTimeout(setStatus, 20, isAdd ? 'Added Users Role' : 'Revoked Users Role')
    }

    const _groupCards = (facilities) => {
        var cards = []
        var facilityRoles = {}
        // alert(JSON.stringify(facilities))
        for (const userFacility of facilities) {
            if (!facilityRoles[userFacility.facility.id]) {
                facilityRoles[userFacility.facility.id] = { id: userFacility.facility.id, facility: userFacility.facility.facility, roles: [] }
            }
            if (userFacility.roles)
                facilityRoles[userFacility.facility.id].roles.push({
                    id: userFacility.roles.id,
                    role: userFacility.roles.role
                })
        }
        for (var facilityId of Object.keys(facilityRoles)) {
            cards.push(
                <FacilityUser
                    key={facilityRoles[facilityId].id}
                    onSelectItem={(userId, facilityId, option, seld, isAdd) => setConfirm({
                        title: (isAdd) ? 'Add User Role?' : 'Revoke Users Role?', description: (isAdd) ? 'Are you sure you would like add ' + seld + ' role to user?' : 'Are you sure you would like to revoke this users ' + seld + ' role?',
                        show: true,
                        action: () => {
                            User.addFacility(userId, facilityId, option)
                                .then((r) => {
                                    _handleChangePriv(r,isAdd)
                                    getUserFacilities()
                                })
                        }, cancelled: () => getUserFacilities()
                    })}
                    userId={id}
                    facilityId={facilityRoles[facilityId].id}
                    facility={facilityRoles[facilityId].facility}
                    roles={facilityRoles[facilityId].roles}
                />
            )
        }
        return cards
    }


    return (<>
        <h1 className="text-center">Facility</h1>
        {/* <FacilitySearchBar onSearchValue={(r) => _searchFacility(r)} /> */}
        {
            status !== "Saving" && status !== "ready"
                ?
                <ToastDialog
                    time={10}
                    icon={'check'}
                    message={status}
                    className='text-success' />
                :
                <></>
        }
        {
            confirm.show === true
                ?
                <div
                    className='position-absolute d-flex align-items-center justify-content-center'
                    style={{ left: 0, right: 0, top: 0, bottom: 0, zIndex: 100 }}
                >
                    <div
                        className='bg-secondary-container rounded shadow p-3 text-center'
                        style={{ maxWidth: '450px' }}
                    >
                        <h3>{confirm.title}</h3>
                        <p>{confirm.description}</p>
                        <button
                            onClick={(e) => { confirm.action(); setConfirm({ ...confirm, show: false }); }}
                            className='btn'>Yes</button>
                        <button
                            onClick={(e) => { if (confirm.cancelled) confirm.cancelled(); setConfirm({ ...confirm, show: false }) }}
                            className='btn'>No</button>
                    </div>
                </div>
                :
                <></>
        }
        <div className="flex-grow-1" style={{ overflow: 'auto' }}>
            <div className="row">
                <div className="col-12 text-center">
                    <b>To link this user to an additional facility, please select the facility below</b>
                </div>
            </div>
            <div className="row">
                {
                    status !== "loading"
                        ?
                        <FacilityManagementComponent existing={usersFacilities} onSelectFacility={(id) => _handleSelectFacility(id)} onAddFacility={(id) => _handleFacilityAdded(id)} />
                        :
                        <></>
                }

            </div>
            <p className="text-center mt-3"><b>Users Facilities</b></p>
            <div className="row">
                <div className="col-12">
                    {[..._groupCards(usersFacilities)]}
                </div>
            </div>

        </div>
    </>
    )
}
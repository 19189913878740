import React from 'react'
import { Calendar2CheckFill, Calendar2Fill, Calendar2XFill } from 'react-bootstrap-icons'
import MockCalendarEvents from '../../MockCalendarEvents'

export default function CalendarEventUpdate({ evt, calendarType }) {
  const _statusWithIcon = (status) => {
    switch (status) {
      case 'TENTATIVE': return <div className='text-center'><Calendar2Fill /> {status}</div>
      case 'CONFIRMED': return <div className='text-center'><Calendar2CheckFill /> {status}</div>
      case 'CANCELLED': return <div className='text-center'><Calendar2XFill /> {status}</div>
      default: return <div className='text-center'><Calendar2Fill /> {status}</div>
    }
  }
  //"CLINICIAN_PATIENT"
  if (!evt) return <></>
  return (
    <div className='text-start px-2 small d-flex w-100'>
      {/* <CalendarEventDeatilsComponent evt={evt} /> */}
      <div className='d-flex flex-column justify-content-start align-items-start p-2 rounded h-100 small d-flex w-100'>
        {
          MockCalendarEvents.filter((f) => f.triggeredWhen.trigger !== 'CREATE_CALENDAR_EVENT' && f.availableCalendars && f.availableCalendars.includes(calendarType))
            .map((e) =>
              <button className='btn btn-xs text-start w-100 mb-1'>{e.eventName}</button>
            )
        }
      </div>
    </div>
  )
}

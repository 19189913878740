import Authentication from "../api/services/Authentication";

export const ClaimsFilter = ({ claim, enforce, children }) => {

    if (Authentication.isClaimValid("System Admin")) {
        return children;
    }
    if (claim.includes("Facility"))
        return children;
    if (Array.isArray(claim)) {
        let claimValid = false;
        claim.forEach(element => {
            if (Authentication.isClaimValid(element.trim())) {
                claimValid = true;
            }
        });
        if (claim === Authentication.getFacilityRole()) {
            claimValid = true
        }
        if (claimValid)
            return children;
    }
    else if (claim.includes(",")) {
        let claims = claim.split(",")
        let claimValid = false;
        claims.forEach(element => {
            if (Authentication.isClaimValid(element.trim())) {
                claimValid = true;
            }
        });
        if (claim === Authentication.getFacilityRole()) {
            claimValid = true
        }
        if (claimValid)
            return children;
    } else if (Authentication.isClaimValid(claim)) {
        return children;
    }
    if (enforce)
        //return window.location = '/logout';
        return window.location = '/noperm';
    return <></>
    // if(security.verifySession()){
    //     return children;
    // }else{
    //     return <LoginScreen />;
    // }
}
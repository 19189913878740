import React, { useEffect, useState } from 'react'
import QBuilderSections from '../components/QBuilderSections'
import Questionnaire from '../../../../../libs/api/services/Questionnaire'
import { Check2Square, Square } from 'react-bootstrap-icons'
import EventCategorySearch from '../../../patient/admin/events/category/EventCategorySearch'
import QBuilderAdvancedOptionsEventTrigger from './Options/QBuilderAdvancedOptionsEventTrigger'

export default function QBuilderAdvancedOptions({ title, description, navigation, navigator, errors, setValue, values }) {
    const [questionnair, setQuestionnaire] = useState([])
    const [sections, setSections] = useState([])

    const [options, setOptions] = useState()

    const _handleSetOption = async (name, option) => {
        Questionnaire
            .SetOption(values.questionnaireId, {
                name: name,
                value: JSON.stringify(option)
            })
            .then((r) => {
                //setTitleMode(r)
                //  setTitleMode(option)
            }).catch(() => { })
    }
    const _handleGetOptions = () => {
        Questionnaire
            .GetOptions(values.questionnaireId)
            .then((r) => {
                setOptions(r)
            }).catch(() => { })
    }
    const _handleGetOption = (name) => {
        if (!options)
            return
        var res = options.find(r => r.name === name)
        if (res)
            return JSON.parse(res.value)
    }
    useEffect(() => {
        _handleGetOptions()
    }, [])

    useEffect(() => {
        title('Additional Options')
        description('')
        // Load Navigation if session started
        // if (Object.keys(values).includes('templateId'))
        navigation([
            // { text: 'Remove', type: 'Action', action: async () => { _handleRemoveTemplate(values.templateId).then((r) => { navigation([]); loadConfigs() }) } },
            {
                text: 'Back', type: 'Step', step: 3
            },
            {
                text: 'Done', type: 'Done', action: async () => {
                    // setValue({ 'templateId': values.templateId })
                }
            }
        ])
        // else
        //     navigation([])
    }, [])

    const _toggle = (name) => {
        var v = _handleGetOption(name)
        if (!v)
            v = false
        else
            v = v.value

        _handleSetOption(name, { value: !v })
            .then((r) => {
                _handleGetOptions();
            })
    }

    return (
        <div className='text-start m-3'>
            <div className='p-2 rounded '>
                <label><b>Once Questionnaire Completed</b></label>
                <div className='row'>
                    <div onClick={(e) => _toggle('ON_COMPLETE_KEEP')} className={'bg-primary-container col-4 p-2 rounded m-2 shadow '.concat(_handleGetOption('ON_COMPLETE_KEEP') && _handleGetOption('ON_COMPLETE_KEEP').value === true ? '' : 'opacity-50 ')}>
                        {
                            _handleGetOption('ON_COMPLETE_KEEP') && _handleGetOption('ON_COMPLETE_KEEP').value === true
                                ?
                                <Check2Square className='me-2' />
                                :
                                <Square className='me-2' />
                        }

                        <b>Keep questionnaire on patients questionnaire list</b>
                        <div className='ms-3 mt-1'>
                            <p >This will not remove the questionnaire from the patients list and allow them to make changes at a later stage even if completed </p>
                        </div>
                    </div>
                    <div className={'bg-primary-container  col-4 p-2 rounded m-2 shadow '.concat(_handleGetOption('ON_COMPLETE_TRIGGER_EVENT') && _handleGetOption('ON_COMPLETE_TRIGGER_EVENT').value === true ? '' : 'opacity-50 ')}>
                        <div onClick={(e) => _toggle('ON_COMPLETE_TRIGGER_EVENT')}>
                            {
                                _handleGetOption('ON_COMPLETE_TRIGGER_EVENT') && _handleGetOption('ON_COMPLETE_TRIGGER_EVENT').value === true
                                    ?
                                    <Check2Square className='me-2' />
                                    :
                                    <Square className='me-2' />
                            }
                            <b>Trigger off an event</b>
                            <div className='ms-3 mt-1'>
                                <p className='me-2'>This will trigger off a patient event once questionnaire has been completed </p>
                            </div>
                        </div>
                        {
                            _handleGetOption('ON_COMPLETE_TRIGGER_EVENT') && _handleGetOption('ON_COMPLETE_TRIGGER_EVENT').value === true
                                ?
                                <QBuilderAdvancedOptionsEventTrigger
                                    onChangeCategory={(c) => _handleSetOption('ON_COMPLETE_TRIGGER_EVENT_CATEGORY',c)}
                                    onChangeEvent={(c) => _handleSetOption('ON_COMPLETE_TRIGGER_EVENT_EVENT',c)}
                                    cat={_handleGetOption('ON_COMPLETE_TRIGGER_EVENT_CATEGORY')}
                                    evt={_handleGetOption('ON_COMPLETE_TRIGGER_EVENT_EVENT')}
                                    values={values} />
                                :
                                <></>
                        }

                    </div>
                </div>
            </div>
            {/* <div className='p-2 rounded '>
                <label><b>Export</b></label>
                <div onClick={(e) => setTitleMode({ type: 'Questionnaire Name' })} className={'bg-primary-container p-2 rounded m-2 shadow '.concat(titleMode.type === 'Questionnaire Name' ? '' : 'opacity-50 ')}>
                    {
                        titleMode.type === 'Questionnaire Name'
                            ?
                            <Check2Square className='me-2' />
                            :
                            <Square className='me-2' />
                    }

                    Include alerts as comments
                    <p className='me-2'>This will add comments on alertted answers when exporting the report </p>
                </div>
                <div onClick={(e) => setTitleMode({ type: 'Questionnaire Name' })} className={'bg-primary-container p-2 rounded m-2 shadow '.concat(titleMode.type === 'Questionnaire Name' ? '' : 'opacity-50 ')}>
                    {
                        titleMode.type === 'Questionnaire Name'
                            ?
                            <Check2Square className='me-2' />
                            :
                            <Square className='me-2' />
                    }

                    Privacy Disclaimer
                    <textarea className='form-control'></textarea>
                    <p className='me-2'>This will add a privacy disclaimer at the end of the report </p>
                </div>
            </div> */}
        </div>
    )
}
import React, { useEffect, useState } from 'react'
import SearchComponent from '../../../../../components/search/SearchComponent'
import Authentication from '../../../../../libs/api/services/Authentication'
import Facility from '../../../../../libs/api/services/Facility'

export default function FacilitySearchComponent({ setLocation, defaultValue }) {
  const [facilities, setFacilities] = useState([])
  const [facility, setFacility] = useState(defaultValue ? defaultValue : { id: parseInt(Authentication.getFacility()) })

  const _loadFacilities = async () =>
    setFacilities(await Facility.list())

  useEffect(() => { _loadFacilities() }, [])
  useEffect(() => { if (facility) setLocation(facility) }, [facility])
  // Default facility
  useEffect(() => { if (!defaultValue && Authentication.getFacility()) setFacility({ id: parseInt(Authentication.getFacility()) }) }, [])
  return (
    <SearchComponent
      focusSearch={true}
      defaultValue={facility}
      onSelected={(e) => setFacility(e)}
      details={{
        data: facilities,
        options: {
          returns: 'object',
          reRefKey: 'id',
          template: [{
            refKey: 'facility',
            descr: '',
          }]
        }
      }} />
  )
}

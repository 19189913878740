import API from "../API";

var CalendarSync = {
    async GetExternalCalendars() {
        var result = await API.process(
            'api/CalendarSync',
            'GET',
            null
        ).catch((err) => {
            throw err;
        })

        return result
    },
    async UpdateExternalCalendars(cal) {
        var result = await API.process(
            'api/CalendarSync/config',
            'PATCH',
            cal
        ).catch((err) => {
            throw err;
        })

        return result
    },
    async GetOAuthTokenAsync(cal) {
        var result = await API.process(
            'api/CalendarSync/OAuth/Verify',
            'OPTIONS',
            cal
        ).catch((err) => {
            throw err;
        })

        return result
    },
    /*
     * Calendar Sync functions
     */
    // List with sub calendars
    async ListAllCalendarsExtendedAsync(cal) {
        var result = await API.process(
            'api/CalendarSync/Calendars/All',
            'OPTIONS',
            cal
        ).catch((err) => {
            throw err;
        })

        return result
    },
    async SyncExternalCalendarList(cal) {
        var result = await API.process(
            `api/CalendarSync/Calendar/List/Sync/${cal}`,
            'GET',
            null
        ).catch((err) => {
            throw err;
        })

        return result
    },
    async ActivateCalendarsAsync(extenalCalendar, cals) {
        var result = await API.process(
            `api/CalendarSync/Calendar/Activate/${extenalCalendar}`,
            'PATCH',
            cals
        ).catch((err) => {
            throw err;
        })

        return result
    },
    async ListLinkedCalendarsExtendedAsync(extenalCalendar) {
        var result = await API.process(
            `api/CalendarSync/Calendar/${extenalCalendar}/linked`,
            'GET',
            null
        ).catch((err) => {
            throw err;
        })

        return result
    },
    async SyncExternalCalendar(extenalCalendarId, Filters) {
        var result = await API.process(
            `api/CalendarSync/Calendar/${extenalCalendarId}/sync`,
            'OPTIONS',
            Filters
        ).catch((err) => {
            throw err;
        })

        return result
    },
}

export default CalendarSync;
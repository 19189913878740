import { faSpinner } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useEffect, useRef, useState } from 'react'
import { Check2Circle, CheckSquare, Pencil, PlusCircle, Square } from 'react-bootstrap-icons'
import Questionnaire from '../../../../../libs/api/services/Questionnaire'
import QBuilderGenerics from '../QBuilderGenerics'
import QBuilderComponentOptions from './QBuilderComponentOptions'
import QBuilderFieldLink from './QBuilderFieldLink'
import QBuilderComponentSingleSelctItem from './widgets/QBuilderComponentSingleSelctItem'
import LabelsOptions from './widgets/components/LabelsOptions'

export default function QBuilderComponentSingleSelct({ cache, onRerender, onChangeMode, gloabloptions, view, activeQuestionnaireAssignment, onLoadComplete, ComponentId, onAction, idx, edit, defaultMode, validate, setValidate, onSetData, values, SectionComponents }) {
    const [mode, setMode] = useState(edit ? 'Edit Text' : '')
    const [details, setDetails] = useState({ text: '' })
    const [options, setOptions] = useState([])
    const [option, setOption] = useState({ option: '' })
    const [properties, setProperties] = useState([])
    const [loading, setLoading] = useState(true)
    const [responseValue, setResponseValue] = useState()
    const [issues, setIssues] = useState({})
    const [alignment, setAlignmnet] = useState('text-start')

    const [data, setData] = useState()
    const isMountedRef = useRef(false);

    const _load = async () => {
        try {
            if (!isMountedRef.current || edit)
                if (activeQuestionnaireAssignment)
                    setData(await QBuilderGenerics.loadData(cache, ComponentId, activeQuestionnaireAssignment.id))
                else
                    setData(await QBuilderGenerics.loadData(cache, ComponentId,))
            onLoadComplete()
            isMountedRef.current = true;
        } catch (e) {
            console.error('LOAD', e)
        }
    }

    useEffect(() => {
        // onLoad
        _load()
    }, [])

    useEffect(() => {
        // Rerender after data changed
        if (data && data.properties) {
            // Rerender after data changed
            setLoading(true)
            _loadProperties(true)
            if (data.response) _LoadResponse(true)
            setLoading(false)
        }
    }, [data])

    // useEffect(() => {
    //     if (cache)
    //         _loadProperties(cache);
    //     else
    //         _loadProperties();
    // }, [])


    useEffect(() => {
        onChangeMode(mode)
    }, [mode])



    useEffect(() => {
        if (validate === "Validate Next") {
            if (details.Required) {
                if (_validate())
                    setValidate('Ok Next')
                else
                    setValidate('Issue')
            } else {
                // setValidate('Ok Next')
            }
            // 
        }
        if (validate === "Validate Back") {
            setValidate('Ok Back')
        }
        //alert(validate)
    }, [validate])

    const _hadleAddOption = () => {
        const _options = [...options, option]
        setOptions(_options)
        _setProperty('Options', JSON.stringify(_options))
        setOption({ option: '', Labels: [] })
    }
    const _LoadResponse = async (res, cacheRespo) => {
        // if (!edit) {
        try {
            try {
                var _opts = QBuilderGenerics.getProperty(data.properties, "Options")
            } catch (e) {
            }
            try {
                const _answers = data.response.answer
                for (var _a of _opts)
                    _a.active = _answers === _a.option
                setOptions(_opts)
                setResponseValue(_answers)
            } catch (e) {
                // console.error("HERE(_LoadResponse)-------> error", e)
            }
        } catch (e) {
        }
    }

    const _alignment = () => {
        try {
            const HEADINGS_ALIGN = gloabloptions.find(r => r.name === 'HEADINGS_ALIGN')
            if (HEADINGS_ALIGN)
                setAlignmnet(JSON.parse(HEADINGS_ALIGN.value).align)
        } catch (e) { }
    }

    const init = (r) => {
        var _details = { ...details }
        try {
            _details.label = r.find(p => p.property === "Question").values
        } catch (e) { }
        try {
            _details.Required = JSON.parse(r.find(p => p.property === "Required").values)
        } catch (e) { }
        try {
            const opts = QBuilderGenerics.getProperty(data.properties, "Options")
            if (opts)
                setOptions(opts)
        } catch (e) { }
        try {
            _details.Labels = JSON.parse(r.find(p => p.property === "Labels").values)
        } catch (e) { }
        try {
            _details.displayAs = r.find(p => p.property === "displayAs").values
        } catch (e) { }
        setDetails({ ..._details })
        _alignment()
        setProperties(r)
    }
    const _loadProperties = async (cache) => {
        // setProperties(data.properties)
        init(data.properties)
        /*try {
            if (cache) {
                if (cache.properties && cache.properties.length > 0) {
                    setProperties(cache.properties)
                    try {
                        const opts = cache.properties.find(p => p.property === "Options")
                        if (opts)
                            setOptions(JSON.parse(opts.values))
                    } catch (e) { }
                } else {
                    throw Error('No cached properties')
                }
                onLoadComplete()
                setLoading(false)
                return;
            }
        } catch (e) {

        }
        var res = await Questionnaire
            .GetComponentProperties(ComponentId)
            .then((r) => {
                setProperties(r)
                init(r)
                // var _details = { ...details }
                // _details.label = _loadProperty("Question", r)
                // _details.align = _loadProperty("Alignment")
                // _details.fontWeight = _loadProperty("FontWeight")
                // _details.fontStyle = _loadProperty("FontStyle")
                // _details.textDecoration = _loadProperty("TextDecoration")
                // setDetails(_details)
                // setLoading(false)

          //          DO AUTOFILL
          
                try {
                    const opts = r.find(p => p.property === "Options")
                    if (opts)
                        setOptions(JSON.parse(opts.values))
                    _LoadResponse(r)
                } catch (e) { }
                onLoadComplete()
            }).catch((e) => {
                // setLoading(false)
                onLoadComplete()
            });;
        setLoading(false)*/
    }

    // useEffect(() => {
    //     if (!edit && view !== "New") {

    //         // _loadProperties()
    //     } else {
    //         // onLoadComplete()
    //         _loadProperties()
    //     }
    // }, [mode])

    const _loadProperty = (prop, cached) => {
        var ret = {}
        if (cached && cache && cache.properties)
            ret = cache.find(p => p.property === prop)
        else
            ret = properties ? properties.find(p => p.property === prop) : undefined
        return ret ? ret.values : '';
    }
    const _setProperty = (prop, value) => {
        Questionnaire
            .SetComponentProperty(ComponentId, {
                property: prop,
                values: value,
                component: {
                    id: ComponentId
                }
            })

        /*
            DO UPDATE RECORDS
        */

    }

    const _handleSetFieldName = (t) => {
        _setProperty("Question", t)
        var _details = { ...details }
        _details.label = t
        setDetails(_details)
    }

    const _handleRemoveOption = (i) => {
        var _options = options
        _options.splice(i, 1)
        setOptions([..._options])
        _setProperty('Options', JSON.stringify(_options))
    }
    const _handleEditOption = (i, v) => {
        var _options = options
        options[i] = v
        setOptions([..._options])
        _setProperty('Options', JSON.stringify(_options))
    }
    const _handleSave = () => {
        setMode('')
    }

    useEffect(() => {
        if (defaultMode && defaultMode === 'Overview')
            setMode('')
    }, [defaultMode])

    // const _handleSetValue = (v) => {
    //     if (!edit) {
    //         _validate(v)
    //         Questionnaire
    //             .QuestionnaireResponse(activeQuestionnaireAssignment.id, {
    //                 // questionnaire: {
    //                 //     id: QuestionnaireAssignmentId
    //                 // },
    //                 answerType: {
    //                     id: ComponentId
    //                 },
    //                 answer: v
    //             })
    //             .then((r) => {

    //             })
    //     }
    // }

    const _handleOptionSelected = (i) => {
        var _options = options

        for (var o of _options) {
            o.active = false
        }
        _options[i].active = true
        setOptions([..._options])
        onSetData(_options[i].option, true)
    }

    const _handleSetRequired = () => {
        var _required = details.Required
        if (_required === null)
            _required = false
        var _details = { ...details, Required: !_required }
        _setProperty("Required", JSON.stringify(!_required))
        setDetails(_details)
    }

    const _validate = () => {
        var _isValid = false
        for (const o of options) {
            if (o.active)
                _isValid = true
        }
        return true;
    }

    // useEffect(() => {
    //     _validate()
    // }, [options])

    const _showScore = () => {
        var max_weight = 0;
        var weight = 0;
        for (const o of options) {
            if (Object.keys(o).includes('weight')) {
                max_weight = parseInt(o.weight) > max_weight ? parseInt(o.weight) : max_weight
                if (Object.keys(o).includes('active') && o.active === true)
                    weight = o.weight

            }
        }
        return <div><small>Weighting : {weight} / {max_weight} </small></div>

    }

    const _renderOptions = () => {
        var res = []
        if (!details.displayAs || details.displayAs === '' || details.displayAs === 'Checkbox') {
            for (var i = 0; i < options.length; i++) {
                const _i = i;
                res.push(
                    view && view === 'Discover'
                        ?
                        <div
                            className={' m-2 '.concat(options[_i].alert ? 'text-danger' : '')}>
                            {
                                options[_i].active
                                    ?
                                    <div>
                                        <CheckSquare className='me-2' />
                                        {options[_i].option}
                                    </div>
                                    :
                                    <div className=' opacity-25'>
                                        <Square className='me-2' />
                                        {options[_i].option}
                                    </div>
                            }
                        </div>
                        :
                        <div
                            style={{ cursor: 'pointer' }}
                            onClick={(e) => _handleOptionSelected(_i)}
                            className=' m-2'>
                            {
                                options[_i].active
                                    ?
                                    <CheckSquare className='me-2' />
                                    :
                                    <Square className='me-2' />
                            }

                            {options[_i].option}
                        </div>
                )
            }
            // res.push(options.map((o, i) =>
            //     view && view === 'Discover'
            //         ?
            //         <div
            //             className=' m-2'>
            //             {
            //                 o.active
            //                     ?
            //                     <div>
            //                         <CheckSquare className='me-2' />
            //                         {o.option}
            //                     </div>
            //                     :
            //                     <div className=' opacity-25'>
            //                         <Square className='me-2' />
            //                         {o.option}
            //                     </div>
            //             }

            //         </div>
            //         :
            //         <div
            //             style={{ cursor: 'pointer' }}
            //             onClick={(e) => _handleOptionSelected(i)}
            //             className=' m-2'>
            //             {
            //                 opt.active
            //                     ?
            //                     <CheckSquare className='me-2' />
            //                     :
            //                     <Square className='me-2' />
            //             }

            //             {opt.option}
            //         </div>
            // ))
        }
        else if (details.displayAs === 'Buttons') {
            for (var i = 0; i < options.length; i++) {
                const _i = i;
                res.push(
                    view && view === 'Discover'
                        ?
                        <div
                            className={' m-2 '.concat(options[_i].alert ? 'text-danger' : '')}>
                            {
                                options[_i].active
                                    ?
                                    <div>
                                        <CheckSquare className='me-2' />
                                        {options[_i].option}
                                    </div>
                                    :
                                    <div className=' opacity-25'>
                                        <Square className='me-2' />
                                        {options[_i].option}
                                    </div>
                            }
                        </div>
                        :
                        <div
                            onClick={(e) => _handleOptionSelected(_i)}
                            className={' btn text-light  '.concat(options[_i].active ? ' shadow bg-secondary-container' : ' bg-secondary-container opacity-75 ')} >
                            {options[_i].active ? <Check2Circle /> : <></>}{options[i].option}
                        </div>
                )

            }
            res = <div className='d-flex flex-column'>{res}</div>
        }
        else if (details.displayAs === 'Dropdown') {
            res.push(<option></option>)
            for (var i = 0; i < options.length; i++) {
                const _i = i;
                res.push(
                    view && view === 'Discover'
                        ?
                        <div
                            className={' m-2 '.concat(options[_i].alert ? 'text-danger' : '')}>
                            {
                                options[_i].active
                                    ?
                                    <div>
                                        <CheckSquare className='me-2' />
                                        {options[_i].option}
                                    </div>
                                    :
                                    <div className=' opacity-25'>
                                        <Square className='me-2' />
                                        {options[_i].option}
                                    </div>
                            }
                        </div>
                        :
                        <option
                            value={_i}
                            selected={(options[_i].active) ? 'selected' : ''}
                        // onClick={(e) => _handleOptionSelected(i)}
                        // className={' btn '.concat(options[i].active ? 'active' : '')} 
                        >
                            {options[_i].option}
                        </option>
                )

            }
            if (view && view === 'Discover')
                <div>{res}</div>
            else
                res = <select className='form-control' onChange={(e) => _handleOptionSelected(e.target.value)}>{res}</select>
        }
        // var res = []
        // //option.displayAs === '' || option.displayAs === 'Checkbox'
        // if (!details.displayAs || details.displayAs === '' || details.displayAs === 'Checkbox') {
        //     res.push(<div
        //         style={{ cursor: 'pointer' }}
        //         onClick={(e) => _handleOptionSelected(i)}
        //         className=' m-2'>
        //         {
        //             opt.active
        //                 ?
        //                 <CheckSquare className='me-2' />
        //                 :
        //                 <Square className='me-2' />
        //         }

        //         {opt.option}
        //     </div>)
        // }
        // else if (details.displayAs === 'Buttons') {
        //     res.push(<div
        //         onClick={(e) => _handleOptionSelected(i)}
        //         className={' btn '.concat(opt.active ? 'active' : '')} >
        //         {opt.option}
        //     </div>)
        // }
        // else if (details.displayAs === 'Dropdown') {
        //     res.push(<option
        //         onClick={(e) => _handleOptionSelected(i)}
        //         className={' '.concat(opt.active ? 'active' : '')} >
        //         {opt.option}
        //     </option>)
        // }

        return res;
    }

    const _handleSetStyle = (i, v) => {
        // var _details = details
        // _details._displayAs = v
        var _details = { ...details, displayAs: v }
        _setProperty("displayAs", v)
        setDetails(_details)
    }

    const _render = () => {
        var _rent = []
        switch (mode) {
            case 'Edit Text':
                _rent = (
                    <div style={{ cursor: 'pointer' }} className='bg-light rounded text-start p-2 opacity-75'>
                        <div className='text-center'>
                            <div className='d-flex justify-content-between'>
                                <b>Enter a name for the field</b>
                            </div>
                            <input
                                onChange={(e) => _handleSetFieldName(e.target.value)}
                                defaultValue={_loadProperty("Question")}
                                className='form-control form-control-sm' placeholder='Field Name' />
                            <div className='d-flex justify-content-between'>
                                <div>
                                    <div>
                                        <b>Is this field required?</b>
                                    </div>
                                    <div onClick={(e) => _handleSetRequired()}>
                                        {
                                            details.Required
                                                ?
                                                <CheckSquare size={20} className='me-1' />
                                                :
                                                <Square size={20} className='me-1' />
                                        }
                                        Field is required
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )
                break;
            case 'Link':
                _rent = (
                    <QBuilderFieldLink ComponentId={ComponentId} />
                )
                break;
            case 'Options':
                _rent = (
                    <div className='small' style={{overflow:'auto',height:'75%'}}>
                        <div className='d-flex flex-column align-items-stretch'>
                            <div className='flex-grow-1'>
                                <b>Answer</b>
                                <input
                                    onChange={(e) => setOption({ ...option, option: e.target.value })}
                                    value={option.option ? option.option : ''}
                                    className='form-control form-control-sm' placeholder='Answer' />
                            </div>

                            <div className='flex-grow-1 p-2'>
                                <div className='row'>
                                    <div className='col-4 text-center'>
                                        <b>Answer Weight</b>
                                        <input
                                            onChange={(e) => setOption({ ...option, weight: e.target.value })}
                                            value={option.weight ? option.weight : ''}
                                            type='number'
                                            className='form-control form-control-sm' placeholder='Answer Weight' />
                                    </div>
                                    <div
                                        onClick={(e) => setOption({ ...option, trigger: !option.trigger })}
                                        className='col-4 text-center'>
                                        <b>Trigger Alert</b>
                                        <div>
                                            {
                                                option.trigger
                                                    ?
                                                    <CheckSquare size={20} className='me-1' />
                                                    :
                                                    <Square size={20} className='me-1' />
                                            }
                                            Alert if option selected
                                        </div>
                                    </div>
                                    <div
                                        className='col-4 text-center'>
                                        <LabelsOptions
                                            defaultLabels={option.Labels}
                                            onChange={(l) => setOption({ ...option, Labels: l })}
                                        />
                                    </div>
                                </div>

                                <div className='text-center'>
                                    <button
                                        onClick={(e) => _hadleAddOption(option)}
                                        className='btn-xs'>
                                        <PlusCircle />
                                        Add
                                    </button>
                                </div>
                            </div>
                        </div>
                        {
                            options.map((o, i) =>
                                <QBuilderComponentSingleSelctItem
                                    key={o.id}
                                    o={o}
                                    values={values}
                                    SectionComponents={SectionComponents}
                                    onRerender={() => onRerender()}
                                    onRemove={() => _handleRemoveOption(i)}
                                    onEdit={(v) => _handleEditOption(i, v)}
                                />

                            )
                        }

                    </div>
                )
                break;
            case 'Style':
                _rent = (
                    <div>
                        <div className='row'>
                            <div className='col-12'>
                                <label><b>Display options as</b></label>
                                <div
                                    onClick={(e) => _handleSetStyle('displayAs', 'Checkbox')}
                                >
                                    {
                                        details.displayAs === '' || details.displayAs === 'Checkbox'
                                            ?
                                            <CheckSquare className='me-2' />
                                            :
                                            <Square className='me-2' />
                                    }
                                    Check Box List</div>
                                <div
                                    onClick={(e) => _handleSetStyle('displayAs', 'Dropdown')}
                                >
                                    {
                                        details.displayAs === 'Dropdown'
                                            ?
                                            <CheckSquare className='me-2' />
                                            :
                                            <Square className='me-2' />
                                    }
                                    Drop Down</div>
                                <div
                                    onClick={(e) => _handleSetStyle('displayAs', 'Buttons')}
                                >
                                    {
                                        details.displayAs === 'Buttons'
                                            ?
                                            <CheckSquare className='me-2' />
                                            :
                                            <Square className='me-2' />
                                    }
                                    Buttons</div>
                            </div>
                        </div>
                    </div >
                )
                break;
            default:
                break;
        }

        return (
            <>
                {_rent}
            </>
        );
    }

    // if (mode !== '')
    //     return (

    //     )
    // else

    if (loading) return <div className='text-center'><FontAwesomeIcon icon={faSpinner} spin /></div>
    return (
        <>
            {
                mode !== ''
                    ?
                    <div style={{ cursor: 'pointer' }} className={'bg-light shadow rounded text-start p-2 position-fixed w-50 '.concat(' slide-in')}>
                        <QBuilderComponentOptions onAction={(e) => onAction(e)} onClose={() => setMode('')} idx={idx} />
                        <div className='d-flex rounded bg-primary-container m-2'>
                            <div className={'p-2 '.concat(mode === 'Edit Text' ? 'active' : '')} onClick={() => setMode('Edit Text')} >Field Name</div>
                            <div className={'p-2 '.concat(mode === 'Options' ? 'active' : '')} onClick={() => setMode('Options')} >Field Options</div>
                            <div className={'p-2 '.concat(mode === 'Link' ? 'active' : '')} onClick={() => setMode('Link')} >Link Field</div>
                            <div className={'p-2 '.concat(mode === 'Style' ? 'active' : '')} onClick={() => setMode('Style')} >Style</div>
                            {/* <div className={'p-2 '.concat(mode === '' ? 'active' : '')} onClick={() => setMode('')} >Close</div> */}
                        </div>
                        {_render()}
                    </div>
                    : <></>
            }
            <div className={'text-start '.concat(mode !== '' ? 'bg-primary-container opacity-75 shadow' : '')}>
                {
                    edit
                        ?
                        <Pencil className='me-2' onClick={(e) => setMode('Edit Text')} />
                        :
                        <></>
                }
                <>
                    {view && view === 'Discover'
                        ?
                        <div className='d-flex justify-content-between'>
                            <label><b>{loading ? '' : details.label}</b></label>
                        </div>
                        :
                        loading ? '' : <div className={alignment}><label className='text-center'>{_loadProperty("Question")}<b className='text-secondary'><small>{_loadProperty("Required") && JSON.parse(_loadProperty("Required")) ? "*" : ''}</small></b></label></div>
                    }
                    <div>
                        {
                            _renderOptions(options)
                        }
                    </div>
                    {
                        issues.issue
                            ?
                            <div className='text-secondary text-center bg-secondary-container text-light rounded p-1 shadow'>
                                <Pencil />{issues.issue}
                            </div>
                            :
                            <></>
                    }
                </>
            </div></>)
}

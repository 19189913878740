import { faClose } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useContext, useEffect, useRef, useState } from "react";
import { Search, XCircle } from "react-bootstrap-icons";
import { useNavigate, useParams } from "react-router-dom";
import User from "../../libs/api/services/User";
import StickyNavigationContext from "../../libs/providers/StickyNavigationProvider";

export default function UserSearchBar({ noAdd, onUserSelected, facility }) {
    const navigate = useNavigate();
    const { id } = useParams();
    const refSearch = useRef()
    const [users, setUsers] = useState([]);
    const [details, setDetails] = useState("");
    const { canNavigate } = useContext(StickyNavigationContext)
    const [error, setError] = useState("");

    const search = (s) => {
        // Search API
        User.search({
            search: s,
            facility: facility
        })
            .then((result) => {
                setUsers(result);
                // this.setState({
                //     results: result
                // })
            })
            .catch((err) => {
                console.error(err)
                setError(err.message)
            })
    }

    useEffect(() => {
        if (id) {
            User.get(id)
                .then((res) => {
                    setDetails(res.firstName + " " + res.lastName)
                })
                .catch((err) => {
                    console.err(err)
                    setError(err.message)
                })
            // Get details from id
        }
    }, [id]);

    const navigateUser = (id) => {
        if (onUserSelected)
            onUserSelected(id)
        else {
            if (facility)
                canNavigate(() => navigate(`/Facility/Configuration/users/${id}`))
            else
                canNavigate(() => navigate(`/sys-admin/user/manage/${id}`))
        }
    }

    const _handleClear = () => {
        setDetails('')
        refSearch.current.value = ''
        setUsers([])
    }

    const _handleNoResult = () => {
        refSearch.current.placeholder = 'User Search'
        refSearch.current.value = ''
    }

    const _handleAddUser = () => {
        if (facility)
            navigate(`/Facility/Configuration/Users/Add`)
        else
            navigate(`/sys-admin/users/add`)
    }

    const _handleKeyUp = (e) => {
        if (e.keyCode === 27)
            _handleClear()
    }

    return (
        <div className="row">
            <div className="col-lg-6">
                {
                    error
                        ?
                        <div className="text-danger">{error}</div>
                        :
                        <></>
                }
                <div className="d-flex position-relative">
                    <input
                        ref={refSearch}
                        onFocus={(e) => refSearch.current.placeholder = 'Start typing users name to start searching'}
                        onBlur={(e) => _handleNoResult()}
                        onKeyUp={(e) => _handleKeyUp(e)}
                        onChange={(e) => search(e.target.value)} defaultValue={details} className='form-control bg-search-bar rounded-3 text-primary' placeholder='User Search' />
                    {
                        !(refSearch.current && refSearch.current.value !== "")
                            ?
                            <div
                                onClick={(e) => { refSearch.current.select(); refSearch.current.placeholder = 'Start typing users name to start searching' }}
                                className="d-flex align-items-center bg-buttons px-3 position-absolute rounded" style={{ right: '0px', top: '0px', bottom: '0px', fontWeight: 'bold' }}>
                                <Search />
                            </div>
                            :
                            <div
                                onClick={(e) => _handleClear()}
                                className="d-flex align-items-center bg-buttons px-3 position-absolute rounded" style={{ right: '0px', top: '0px', bottom: '0px', fontWeight: 'bold' }}>
                                <XCircle />
                            </div>
                    }

                </div>
                {
                    users.length > 0
                        ?
                        <div className='bg-search-bar-result rounded-3 text-dark position-absolute w-50' >
                            <FontAwesomeIcon onClick={(e) => setUsers([])} style={{ position: "absolute", right: 0, padding: "8px", zIndex: 99 }} fontSize={15} icon={faClose} />
                            <ul className='position-relative'>
                                {
                                    users.map((result) =>
                                        <li onClick={(e) => navigateUser(result.id)} key={result.id}>{result.firstName} {result.lastName} - {result.idNumber}</li>
                                    )
                                }
                            </ul>
                        </div>
                        :
                        <></>
                }
            </div>
            <div className="col-lg-6">
                {!noAdd && <button onClick={(e) => _handleAddUser()} className="btn bg-buttons">Add User</button>}
            </div>
        </div>
    );
}
import CreateCalendarEventTaskLineItem from "../../calendar/workflow/tasks/CreateCalendarEventTaskLineItem";
import UpdateCalendarEventTaskLineItem from "../../calendar/workflow/tasks/UpdateCalendarEventTaskLineItem";
import MessageTaskLineItem from "../../messaging/workflow/actions/MessageTaskLineItem";
import SendNotificationTaskLineItem from "../../notification/workflow/tasks/SendNotificationTaskLineItem";
import LogPatientHistoryEventTaskLineItem from "../../patient/workflow/tasks/LogPatientHistoryEventTaskLineItem";
import FormTaskLineItem from "../../questionnaires/workflow/tasks/FormTaskLineItem";
import QuestionnaireTaskLineItem from "../../questionnaires/workflow/tasks/QuestionnaireTaskLineItem";
import ToDoListAllocateEventTaskLineItem from "../../todo/workflow/tasks/ToDoListAllocateEventTaskLineItem";
import ToDoListSetStatusEventTaskLineItem from "../../todo/workflow/tasks/ToDoListSetStatusEventTaskLineItem";

export const TaskTypeMapping = {
    'Messaging': <MessageTaskLineItem />,
    'Generate A Form': <FormTaskLineItem />,
    'Send Questionnaire': <QuestionnaireTaskLineItem />,
    'Send a notification': <SendNotificationTaskLineItem />,
    'Create a new calendar event': <CreateCalendarEventTaskLineItem />,
    'Update a calendar event': <UpdateCalendarEventTaskLineItem />,
    'Log to patient history': <LogPatientHistoryEventTaskLineItem />,
    'Allocate a To Do list': <ToDoListAllocateEventTaskLineItem />,
    'Set the status of a To Do list item': <ToDoListSetStatusEventTaskLineItem />,
}
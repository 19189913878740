import React, { useContext } from 'react';
import CalendarGenerics from '../../CalendarGenerics';
import CalendarContext from '../CalendarProvider';
import CalendarMonthDayComponent from './month/CalendarMonthDayComponent';
import CalendarMonthWeekDay from './month/CalendarMonthWeekDay';

export default function CalendarMonthView() {
    const { filters } = useContext(CalendarContext)
    const _MAX_WEEKS = 6;

    const _renderWeek = (date, activeDate) => {
        const woy = CalendarGenerics
            .getWOY(date)
        var ret = []
        for (var i = 0; i < 7; i++) {
            var result = new Date(JSON.parse(woy.start))
            result.setDate(result.getDate() + i)
            ret.push(<CalendarMonthDayComponent key={'CalendarMonthDayComponent' + result} date={result} active={(activeDate && JSON.stringify(activeDate) === JSON.stringify(result))} />)
        }
        return { rendered: ret, meta: woy }
    }

    const _renderDays = () => {
        // Year month day 
        // var year = 2021
        // var month = 9
        // // var doy = 1
        // var mdate = 17
        var year = filters.year
        var month = filters.month + 1
        var mdate = filters.day

        var ret = []
        var date = new Date(year + "-" + String(month).padStart(2, '0') + "-" + String(mdate).padStart(2, '0') + "T00:00:00.000Z");
        // var date = new Date(year,month,day);

        // Get 1st week
        var first_week = new Date(year + "-" + String(month).padStart(2, '0') + "-" + String(1).padStart(2, '0') + "T00:00:00.000Z");
        // return <tr>{_renderWeek(first_week)}</tr>
        var _wk = []
        for (var i = 0; i < _MAX_WEEKS; i++) {
            _wk = _renderWeek(first_week, date)
            ret.push(<tr className='week-row'>{_wk.rendered}</tr>)
            const _next_end_date = new Date(JSON.parse(_wk.meta.end))
            first_week = CalendarGenerics.AddDays(_next_end_date, 1)
        }
        return ret
    }
    return (
        <table className='calendar-table month'>
            <tr className='header'>
                {
                    CalendarGenerics.daysOfWeek.map((d) =>
                        <CalendarMonthWeekDay dow={d.short} />)
                }
            </tr>
            {_renderDays()}
        </table>
    )
}

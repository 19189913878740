import React, { useEffect, useState } from 'react'
import { AlignCenter, AlignEnd, AlignStart, Check2Square, Square } from 'react-bootstrap-icons'
import Questionnaire from '../../../../../../libs/api/services/Questionnaire'

export default function QBuilderHeadingOptions({ questionnaireId }) {
    const [titleMode, setTitleMode] = useState({ type: 'Questionnaire Name', title: '' })
    const _handleSetOption = (option) => {
        Questionnaire
            .SetOption(questionnaireId, {
                name: 'HEADINGS_ALIGN',
                value: JSON.stringify(option)
            })
            .then((r) => {
                //setTitleMode(r)
                setTitleMode(option)
            }).catch(() => { })
    }
    const _handleGetOption = () => {
        Questionnaire
            .GetOption(questionnaireId, 'HEADINGS_ALIGN')
            .then((r) => {
                try {
                    setTitleMode(JSON.parse(r.value))
                } catch (e) { }
            }).catch((e) => { })
    }

    useEffect(() => {
        _handleGetOption()
    }, [])

    return (
        <div className='text-start m-3'>
            <div className='p-2 rounded  '>
                <label><b>Headings</b></label>
                <div>
                    <label>Align</label>

                </div>
                <div className='d-flex'>
                    <div onClick={(e) => _handleSetOption({ align: 'text-start' })} className={'p-2 ' .concat(titleMode.align === 'text-start' ? 'shadow bg-secondary-container ' : 'opacity-75 ')} ><AlignStart /> Left</div>
                    <div onClick={(e) => _handleSetOption({ align: 'text-center' })} className={'p-2 ' .concat(titleMode.align === 'text-center' ? 'shadow bg-secondary-container ' : 'opacity-75 ')}><AlignCenter /> Center</div>
                    <div onClick={(e) => _handleSetOption({ align: 'text-end' })} className={'p-2 ' .concat(titleMode.align === 'text-end' ? 'shadow bg-secondary-container ' : 'opacity-75 ')}><AlignEnd /> Right</div>
                </div>
            </div>
        </div>
    )
}

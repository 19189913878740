import React, { useEffect, useState } from 'react'
import { ArrowLeftCircleFill, Clipboard, Clipboard2CheckFill, Clipboard2Data, Clock, CloudDownloadFill, Download, FileCheck, InfoCircleFill, MenuUp, Person, Tools, XCircleFill } from 'react-bootstrap-icons'
import { useParams } from 'react-router-dom'
import Questionnaire from '../../../../libs/api/services/Questionnaire'
import PatientAddDMSNoteComponent from '../../patient/dms/PatientAddDMSNoteComponent'
import PatientDMSComponent from '../../patient/dms/PatientDMSComponent'
import PatientDMSNotes from '../../patient/dms/PatientDMSNotes'
import PatientDMSMessages from '../../patient/dms/components/PatientDMSMessages'
import QCaptureComponent from '../capture/components/QCaptureComponent'
import QuestionnaireStats from './QuestionairreResultComponents/QuestionnaireStats'
import './QuestionnaireResults.css'

export default function PatientQuestionnaireResults() {
    const { id, questionair, QuestionnaireAssignmentId } = useParams()
    const [results, setResults] = useState({})
    const [details, setDetails] = useState()
    const [stats, setStats] = useState()
    const [sideOption, setSideOption] = useState()
    const [downloading, setDownloading] = useState(false)

    function openBase64InNewTab(data, mimeType, filename) {
        var byteCharacters = atob(data.substr(data.indexOf(';base64,') + 8));
        // var byteCharacters = data.substr(data.indexOf(';base64,') + 8);
        var byteNumbers = new Array(byteCharacters.length);
        for (var i = 0; i < byteCharacters.length; i++) {
            byteNumbers[i] = byteCharacters.charCodeAt(i);
        }





        var byteArray = new Uint8Array(byteNumbers);
        var file = new Blob([byteArray], { type: mimeType + ';base64' });
        // var file = new Blob([byteArray]);
        var fileURL = URL.createObjectURL(file);

        var anchor = document.createElement('a');
        anchor.href = fileURL;
        anchor.download = filename;
        anchor.click();
        window.URL.revokeObjectURL(fileURL);
        setDownloading(false)
        // window.open(fileURL);
    }

    const _handleGetStats = () => {
        Questionnaire
            .Stats(QuestionnaireAssignmentId)
            .then((data) => {
                setStats(data)
            })
    }

    useEffect(() => {
        if (sideOption === 'Stats')
            _handleGetStats()
    }, [sideOption])

    const _handleGetReport = () => {
        setDownloading(true)
        Questionnaire
            .Report(QuestionnaireAssignmentId)
            .then((data) => {
                openBase64InNewTab(data.data, data.mimeType, data.fileName)
            })
    }

    const loadDetails = () => {

        Questionnaire
            // .GetQuestionnaireResults(QuestionnaireAssignmentId)
            .GetQuestionnaireResultsFull(QuestionnaireAssignmentId)
            .then((r) => {
                setDetails(r)
            })
    }

    useEffect(() => {
        loadDetails()
    }, [])
    // Questionnaire
    //     .GetQuestionnaireResults(questionair)
    //     .then((r) => {
    //         setResults(r)
    //     })

    // const _getQuestion = (res) => {
    //     const rslt = res.answerType.properties.find(q => q.property === "Question")
    //     return rslt ? rslt.values : ''
    // }

    return (
        <div className='conatiner text-center q-discover-layout flex-column'>
            <div className='flex-shrink-1'>
                {
                    details
                        ?
                        <div className='text-secondary'>
                            <div className='row bg-secondary-container shadow rounded p-2'>
                                <InfoCircleFill size={24} className='text-start m-2' />
                                <div className='col-4 text-start'>
                                    <div><Clock className='me-1' />Requested On : {details.createdDate ? details.createdDate.substring(0, 19) : ''}</div>
                                    <div><Person className='me-1' />Requested By : {details.assigner ? details.assigner.firstName : ''} {details.assigner ? details.assigner.lastName : ''}</div>
                                </div>
                                <div className='col-4 text-start'>
                                    <div><Clock className='me-1' />Assigned Date : {details.assignedDate ? details.assignedDate.substring(0, 19) : ''}</div>
                                    <div><Clipboard2CheckFill className='me-1' />Questions Answered : {details.responses ? details.responses.length : 0}</div>
                                </div>
                                <div className='col-4 text-start'>
                                    <div><Clock className='me-1' />Completed On : {details.completedDate ? details.completedDate.substring(0, 19) : ''}</div>

                                </div>

                                <div>
                                <div className='d-flex justify-content-end '>
                                    <div className={'ps-1 pb-1 pe-2 w-25 rounded bg-primary-container shadow p-1 m-1 '.concat(sideOption === 'Tools' ? '' : '')}
                                        onClick={(e) => setSideOption('Tools-Open')}
                                        style={{ cursor: 'pointer' }} ><Tools />Tools</div>

                                </div>
                            </div>
                            </div>
                        </div>
                        :
                        <></>
                }
            </div>
            <div className='flex-grow-1 position-relative' style={{ overflow: 'auto', height: '75vh' }}>
                <div className={'sideOption shadow bg-primary-container rounded rounded-4 '.concat(sideOption === 'Tools-Open' ? 'show' : sideOption === 'Tools' ? 'active' : 'hide')} >
                    <div className='d-flex justify-content-between shadow' onClick={(e) => setSideOption()}>
                        <h3 className='ps-3 m-0 d-flex align-items-center'><Tools size={20} className='me-1' />Tools</h3>
                        <XCircleFill size={30} className='' />
                    </div>
                    <div className='row mb-2 mt-1'>
                        <div className='col-6 '>
                            <div className={'bg-secondary-container d-flex flex-column align-items-center p-2 rounded shadow '.concat(downloading ? 'opacity-75' : '')} style={{ cursor: 'pointer' }} onClick={(e) => { if (!downloading) { } _handleGetReport() }} >{downloading ? <CloudDownloadFill className='me-1' /> : <Download className='me-1' />}{downloading ? 'Generating Report' : 'Download'}</div>
                        </div>
                        <div className='col-6'>
                            <div className={'bg-secondary-container d-flex flex-column align-items-center p-2 rounded shadow'.concat(sideOption === 'Notes' ? 'bg-primary-container shadow' : '')}
                                onClick={(e) => setSideOption('Notes')}
                                style={{ cursor: 'pointer' }} ><Clipboard className='me-1' />Notes</div>
                        </div>
                    </div>
                    <div className='row mb-2 mt-1'>
                        <div className='col-6'>
                            <div className={'bg-secondary-container d-flex flex-column align-items-center p-2 rounded shadow'.concat(sideOption === 'Documents' ? 'bg-primary-container shadow' : '')}
                                onClick={(e) => setSideOption('Documents')}
                                style={{ cursor: 'pointer' }} ><FileCheck className='me-1' />Documents</div>
                        </div>
                        <div className='col-6'>
                            <div className={'bg-secondary-container d-flex flex-column align-items-center p-2 rounded shadow '.concat(sideOption === 'Messages' ? 'bg-primary-container shadow' : '')}
                                onClick={(e) => setSideOption('Messages')}
                                style={{ cursor: 'pointer' }} ><MenuUp className='me-1' />Linked Messages</div>
                        </div>
                    </div>
                    <div className='row '>
                        <div className='col-6'>
                            <div className={'bg-secondary-container d-flex flex-column align-items-center p-2 rounded shadow'.concat(sideOption === 'Stats' ? 'bg-primary-container shadow' : '')}
                                onClick={(e) => setSideOption('Stats')}
                                style={{ cursor: 'pointer' }} ><Clipboard2Data className='me-1' />Analyse</div>
                        </div>
                    </div>
                </div>
                {
                    <>
                        <div className={'sideOption shadow bg-primary-container rounded rounded-4 '.concat(sideOption === 'Messages' ? 'active' : 'hide')} >
                            <div className='d-flex justify-content-between shadow' >
                                <h3 className='ps-3 m-0 d-flex align-items-center'><MenuUp size={20} className='me-1' />Linked Messages</h3>
                                <div>
                                    <ArrowLeftCircleFill onClick={(e) => setSideOption('Tools')} size={30} className='me-1' />
                                    <XCircleFill onClick={(e) => setSideOption('')} size={30} className='' />
                                </div>
                            </div>
                            <div>
                                {
                                    details && details.patientHistoryEvent
                                        ?
                                        <PatientDMSMessages eventId={details.patientHistoryEvent ? details.patientHistoryEvent.id : 0} />
                                        : <></>
                                }

                            </div>
                        </div>
                        <div className={'sideOption shadow bg-primary-container rounded rounded-4 '.concat(sideOption === 'Notes' ? 'active' : 'hide')} >
                            <div className='d-flex justify-content-between shadow' >
                                <h3 className='ps-3 m-0 d-flex align-items-center'><Clipboard size={20} className='me-1' />Notes</h3>
                                <div>
                                    <ArrowLeftCircleFill onClick={(e) => setSideOption('Tools')} size={30} className='me-1' />
                                    <XCircleFill onClick={(e) => setSideOption('')} size={30} className='' />
                                </div>
                            </div>
                            {
                                details && details.patientHistoryEvent
                                    ?
                                    <div className='d-flex flex-column p-1' style={{ height: 'calc(100% - 50px)' }}>
                                        <div className='flex-grow-1' style={{ overflow: 'auto' }}>
                                            <PatientDMSNotes PatientHistoryId={details.patientHistoryEvent ? details.patientHistoryEvent.id : 0} />
                                        </div>
                                        <div className='flex-shrink-1'>
                                            <PatientAddDMSNoteComponent id={id} event={details.patientHistoryEvent ? details.patientHistoryEvent.id : 0} />
                                        </div>
                                    </div>
                                    : <></>
                            }
                        </div>
                        <div className={'sideOption shadow bg-primary-container rounded rounded-4 '.concat(sideOption === 'Documents' ? 'active' : 'hide')} >
                            <div className='d-flex justify-content-between shadow' >
                                <h3 className='ps-3 m-0 d-flex align-items-center'><FileCheck size={20} className='me-1' />Documents</h3>
                                <div>
                                    <ArrowLeftCircleFill onClick={(e) => setSideOption('Tools')} size={30} className='me-1' />
                                    <XCircleFill onClick={(e) => setSideOption('')} size={30} className='' />
                                </div>
                            </div>
                            {
                                details && details.patientHistoryEvent
                                    ?
                                    <PatientDMSComponent id={id} event={details.patientHistoryEvent ? details.patientHistoryEvent.id : 0} />
                                    : <></>
                            }
                        </div>
                        <div className={'sideOption shadow bg-primary-container rounded rounded-4 '.concat(sideOption === 'Stats' ? 'active' : 'hide')} >
                            <div className='d-flex justify-content-between shadow' >
                                <h3 className='ps-3 m-0 d-flex align-items-center'><Clipboard2Data size={20} className='me-1' />Analyse</h3>
                                <div>
                                    <ArrowLeftCircleFill onClick={(e) => setSideOption('Tools')} size={30} className='me-1' />
                                    <XCircleFill onClick={(e) => setSideOption('')} size={30} className='' />
                                </div>
                            </div>
                            <div style={{ overflow: 'auto', height: "calc(100% - 30px)" }}>
                                {
                                    details && details.patientHistoryEvent
                                        ?
                                        <QuestionnaireStats QuestionnaireAssignmentId={QuestionnaireAssignmentId} QuestionnaireId={questionair} onClose={() => loadDetails()} eventId={details.patientHistoryEvent ? details.patientHistoryEvent.id : 0} stats={stats} />
                                        : <></>
                                }
                            </div>
                        </div>
                    </>
                }
                {
                    details
                        ?
                        <QCaptureComponent
                            mode={'Discover'}
                            cacheddetails={details}
                            QuestionnaireAssignmentId={QuestionnaireAssignmentId}
                            QuestionnaireId={questionair}
                        />
                        :
                        <></>
                }

            </div>
        </div>)
    // return (
    //     <div>

    //         <button className='btn btn-sm'>View Documents / Notes</button>
    //         {
    //             results.responses
    //                 ?
    //                 results.responses.map((res) => {
    //                     return (
    //                         <div>
    //                             <div>
    //                                 <b>{_getQuestion(res)}</b>
    //                                 <i>{res.answer}</i>
    //                             </div>
    //                         </div>
    //                     )
    //                 })
    //                 :
    //                 <></>
    //         }
    //     </div>
    // )
}

import { CLONE_CALENDAR_EVENT } from './CLONE_CALENDAR_EVENT'
import { CREATE_CALENDAR_EVENT } from './CREATE_CALENDAR_EVENT'
import { INITIATE_SEQUENCE } from './INITIATE_SEQUENCE'
import { MOVE_CALENDAR_EVENT } from './MOVE_CALENDAR_EVENT'
import { UPDATE_CALENDAR_EVENT } from './UPDATE_CALENDAR_EVENT'
import { UPDATE_EXISTING } from './UPDATE_EXISTING'
export const DayEventModalConfig =
{
    //MOVE_CALENDAR_EVENT
    ...CREATE_CALENDAR_EVENT,
    ...UPDATE_EXISTING,
    ...MOVE_CALENDAR_EVENT,
    ...CLONE_CALENDAR_EVENT,
    ...UPDATE_CALENDAR_EVENT,
    ...INITIATE_SEQUENCE,
}
import React, { useState } from 'react'
import { Paperclip } from 'react-bootstrap-icons'
import Messaging from '../../../../libs/api/services/Messaging'

export default function AttachmentComponent({ attachments }) {
    const [show, setShow] = useState(false)

    function openBase64InNewTab(data, mimeType, filename) {
        var byteCharacters = atob(data);
        var byteNumbers = new Array(byteCharacters.length);
        for (var i = 0; i < byteCharacters.length; i++) {
            byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        var byteArray = new Uint8Array(byteNumbers);
        var file = new Blob([byteArray], { type: mimeType });

        var fileURL = URL.createObjectURL(file);

        var anchor = document.createElement('a');
        anchor.href = fileURL;
        anchor.download = filename;
        anchor.click();
        window.URL.revokeObjectURL(fileURL);

        // window.open(fileURL);
    }
    const _handleDownloadAttachment = async (id, filename, memimeType) => {
        //var file = await GET_DOCUMENT(id)
        var file = await Messaging
            .GetAttachment(id);
        openBase64InNewTab(file.data, file.mimeType, file.fileName)
    }
    return (
        attachments.length > 0
            ?
            <div className='position-relative'>
                <Paperclip
                    onClick={(e) => setShow(!show)}
                    style={{ cursor: 'pointer' }}
                    className='m-1'
                    size={18} />
                <ul className={'position-absolute bg-dark p-3 list-unstyled rounded '.concat(show ? 'd-block' : 'd-none')} style={{ width: '350px', right: '0px' }}>
                    {
                        attachments.map((a) => {
                            return (<li
                                onClick={(e) => _handleDownloadAttachment(a.id, a.fileName, a.mimeType)}
                                style={{ cursor: 'pointer' }}>{a.fileName}</li>)
                        })
                    }
                </ul>
            </div>
            :
            <></>
    )
}

import { faSync } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';
import CalendarSync from '../../../../../libs/api/services/CalendarSync';
export default function SyncCalendarListButton({ extenalCalendar, onSynched }) {
    const [processing, setProcessing] = useState(false)
    const _handleSync = () => {
        if (processing) return
        setProcessing(true)
        CalendarSync
            .SyncExternalCalendarList(extenalCalendar.id)
            .then((r) => {
                if (onSynched) onSynched(r)
                setProcessing(false)
            })
    }
    return (<button onClick={_handleSync} className='btn btn-sm'>
        {
            !processing ? <><FontAwesomeIcon icon={faSync} /> Update Calendar List</>
                : <><FontAwesomeIcon icon={faSync} spin /> Processing</>
        }
    </button>)
}

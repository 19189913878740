import React, { useState } from 'react'
import AttachmentComponent from '../components/AttachmentComponent'
import PatientMessageLink from '../components/PatientMessageLink'
import { CollectionFill, QuestionCircle, Trash } from 'react-bootstrap-icons'
import Messaging from '../../../../libs/api/services/Messaging'

export default function MessageTabFunction({ message, loadMessages,onStatusChanged }) {
    const [showHelp, setShowHelp] = useState(false)
    const _handleMessageStatus = (id, status) => {
        Messaging
            .SetMessageStatus(id, status)
            .then((r) => {
                if (status === "Deleted" || status === "Archived" )
                    // loadMessages()
                    onStatusChanged(false)
            });
    }


    return (
        <div className='d-flex position-relative'>
            <AttachmentComponent attachments={message.attachments} />
            <PatientMessageLink message={message} />
            {
                message.status.status !== "Archived"
                    ?
                    <CollectionFill
                        onClick={(e) => _handleMessageStatus(message.id, "Archived")}
                        style={{ cursor: 'pointer' }}
                        className='m-1'
                        size={18} />
                    :
                    <></>
            }
            <Trash
                onClick={(e) => _handleMessageStatus(message.id, "Deleted")}
                style={{ cursor: 'pointer' }}
                className='m-1'
                size={18} />
            <QuestionCircle
                onClick={(e) => setShowHelp(!showHelp)}
                style={{ cursor: 'pointer' }}
                className='m-1'
                size={18} />
            {
                showHelp
                    ?
                    <div
                        className='bg-light rounded p-3 shadow d-flex justify-content-evenly'
                        style={{ position: 'absolute', width: '25vw', right: '0px', top: '30px' }}
                    >
                        <div className='d-flex flex-column align-items-center text-center'>
                            <PatientMessageLink message={message} />
                            <b>Attach Message To Patient</b>
                        </div>
                        <div className='d-flex flex-column align-items-center text-center'>
                            {
                                message.status.status !== "Archived"
                                    ?
                                    <CollectionFill
                                        onClick={(e) => _handleMessageStatus(message.id, "Archived")}
                                        style={{ cursor: 'pointer' }}
                                        className='m-1'
                                        size={18} />
                                    :
                                    <></>
                            }
                            <b>Archive Message</b>
                        </div>
                        <div className='d-flex flex-column align-items-center text-center'>
                            <Trash
                                onClick={(e) => _handleMessageStatus(message.id, "Deleted")}
                                style={{ cursor: 'pointer' }}
                                className='m-1'
                                size={18} />
                            <b>Delete Message</b>
                        </div>
                    </div>
                    :
                    <></>
            }
        </div>
    )
}

import React, { useContext, useEffect, useState } from 'react'
import CalendarContext from '../../CalendarProvider'

export default function CalendarMonthDayComponent({ date, active }) {
    const { events, findEventForDate, setDate, setFilter } = useContext(CalendarContext)
    const [eventsForDay, setEventsForDay] = useState([])

    const _findEventsForDay = async () => {
        setEventsForDay(await findEventForDate(date))
    }

    const _handleClickDay = (date) => {
        setDate(date, 'Month')
    }

    useEffect(() => {
        _findEventsForDay()
    }, [events])

    return (
        <td onClick={() => _handleClickDay(date)} style={{ cursor: 'pointer' }} className={'day '.concat(active ? 'active' : '')}>
            <div className='day-number'>{date.getDate()}</div>
            {
                // eventsForDay && eventsForDay.length > 0 ? <div className='shadow rounded bg-secondary-container'>{eventsForDay.length}</div> : <div>&nbsp;</div>
                eventsForDay && eventsForDay.length > 0 ? eventsForDay.map((e, i) => {
                    if (i > 2)
                        return <></>
                    else if (i === 2)
                        return <div>+ {eventsForDay.length - 2} more</div>
                    else
                        return <div style={{overflow:'hidden', maxWidth: '100%', maxHeight:'25px'}} className='shadow rounded bg-secondary-container text-start ps-1'>
                            {new Date(e.startDateTime).getHours()}:{String(new Date(e.startDateTime).getMinutes()).padStart(2, '0')} {e.summary}</div>
                }) : <div>&nbsp;</div>
            }
        </td>
    )
}

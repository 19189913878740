import React, { useEffect, useState } from 'react'
import { Calendar2EventFill, Calendar2XFill, ClockFill, PersonFill, PinMapFill } from 'react-bootstrap-icons'
import DateTimePicker from '../../../../../../components/ui/calendar/date_picket/DateTimePicker'
import WorkFlowDetailsModal from '../../../../workflow/management/WorkFlowDetailsModal'
import DOWSelectorComponent from '../../calendar_events/DOWSelectorComponent'
import CalendarUserComponent from '../CalendarUserComponent'
import FacilitySearchComponent from '../FacilitySearchComponent'

export default function EventDetailsComponent({ evt, onSetProperty, properties, option }) {

    const [entry, setEntry] = useState(evt ? evt : {
        id: undefined,
        calendar: {}, // Set this in the provider
        //action:{} /// Add this to server side
        // CalendarTypeSource // Which calender triggered on
        eventUID: undefined,
        timeStamp: undefined,
        summary: undefined,
        startDateTime: undefined,
        endDateTime: undefined,
        description: undefined,
        stauts: undefined,
        patient: { id: 0 },
        clinician: { id: 0 },
    })
    useEffect(() => { if (entry) onSetProperty('entry', entry) }, [entry])
    useEffect(() => { if (option) _loadDefaultsFromOption() }, [option])

    // useEffect(() => { if (evt) setEntry(evt) }, [evt])

    const _loadDefaultsFromOption = () => {
        if (properties && properties.length > 0 && properties[0].entry) return

        // TODO : "KEEP_ORIGINAL"
        //"DEFAULT_STATUS_NEW"
        //"DEFAULT_SUMMARY_NEW"
        //"DEFAULT_SUMMARY_ORIGINAL"
        var _DEFAULT_SUMMARY_ORIGINAL = undefined
        var _DEFAULT_DETAILS_ORIGINAL = undefined
        var _DEFAULT_STATUS_NEW = undefined
        try {
            const DEFAULT_SUMMARY_ORIGINAL = option.properties.find(p => p.name === 'DEFAULT_SUMMARY_ORIGINAL')
            if (DEFAULT_SUMMARY_ORIGINAL)
                _DEFAULT_SUMMARY_ORIGINAL = DEFAULT_SUMMARY_ORIGINAL.value
        } catch (e) { }
        try {
            const DEFAULT_DETAILS_ORIGINAL = option.properties.find(p => p.name === 'DEFAULT_DETAILS_ORIGINAL')
            if (DEFAULT_DETAILS_ORIGINAL)
                _DEFAULT_DETAILS_ORIGINAL = DEFAULT_DETAILS_ORIGINAL.value
        } catch (e) { }
        try {
            const DEFAULT_DETAILS_ORIGINAL = option.properties.find(p => p.name === 'DEFAULT_DETAILS_NEW')
            if (DEFAULT_DETAILS_ORIGINAL)
                _DEFAULT_DETAILS_ORIGINAL = DEFAULT_DETAILS_ORIGINAL.value
        } catch (e) { }
        try {
            const DEFAULT_SUMMARY_NEW = option.properties.find(p => p.name === 'DEFAULT_SUMMARY_NEW')
            if (DEFAULT_SUMMARY_NEW)
                _DEFAULT_SUMMARY_ORIGINAL = DEFAULT_SUMMARY_NEW.value
        } catch (e) { }
        try {
            const DEFAULT_STATUS_ORIGINAL = option.properties.find(p => p.name === 'DEFAULT_STATUS_ORIGINAL')
            if (DEFAULT_STATUS_ORIGINAL)
                _DEFAULT_STATUS_NEW = DEFAULT_STATUS_ORIGINAL.value
        } catch (e) { }
        try {
            const DEFAULT_STATUS_NEW = option.properties.find(p => p.name === 'DEFAULT_STATUS_NEW')
            if (DEFAULT_STATUS_NEW)
                _DEFAULT_STATUS_NEW = DEFAULT_STATUS_NEW.value
        } catch (e) { }

        setEntry({
            ...entry,
            action: option,
            status: _DEFAULT_STATUS_NEW,
            summary: _DEFAULT_SUMMARY_ORIGINAL ? _DEFAULT_SUMMARY_ORIGINAL : entry.summary,
            description: _DEFAULT_DETAILS_ORIGINAL,
        })

    }

    return (
        <WorkFlowDetailsModal CalendarEntryId={entry.id}>

            <div className='  p-2 rounded text-center '>
                <div className='text-start'>
                    <Calendar2EventFill className='me-2 ' />Event Summary
                    <div className='d-flex align-items-center p-2'><input className='form-control form-control-sm'
                        onChange={(e) => setEntry({ ...entry, summary: e.target.value })}
                        value={entry.summary} /></div>
                </div>
                <div className='text-start'>
                    <Calendar2EventFill className='me-2' />Status
                    <div className='p-2'>
                        <select
                            defaultValue={entry.status}
                            onChange={(e) => setEntry({ ...entry, status: e.target.value })} className='form-control form-control-sm'>
                            <option></option>
                            <option selected={entry.status === 'TENTATIVE'}>TENTATIVE</option>
                            <option selected={entry.status === 'CONFIRMED'}>CONFIRMED</option>
                            <option selected={entry.status === 'CANCELLED'}>CANCELLED</option>
                        </select>
                    </div>
                    {/* {_statusWithIcon(evt.status.STATUS)} */}
                </div>
                <div className='text-start'>
                    <PinMapFill className='me-2' />Location
                    <div className='p-2 small'>
                        <FacilitySearchComponent setLocation={(l) => setEntry({ ...entry, location: l })} defaultValue={entry.location} />
                    </div>
                </div>
                <div className='text-start'>
                    <PersonFill className='me-2' />Clinician
                    <div className='p-2 small'>
                        <CalendarUserComponent
                            onSelected={(e) => setEntry({ ...entry, clinician: e })}
                            type={'clinician'}
                            defaultValue={entry.clinician}
                            disabled={Object.keys(entry).includes('clinician')}
                        />
                    </div>
                </div>
                <div className='text-start'>
                    <PersonFill className='me-2' />Patient
                    <div className='p-2 small'>
                        <CalendarUserComponent
                            onSelected={(e) => setEntry({ ...entry, patient: e })}
                            type={'patient'}
                            defaultValue={entry.patient}
                            disabled={Object.keys(entry).includes('patient')}
                        />
                    </div>
                </div>
                <div className='text-start'>
                    <ClockFill className='me-2' />Time
                    {
                        entry.action && entry.action.triggeredWhen && entry.action.triggeredWhen.trigger === 'INITIATE_SEQUENCE'
                            ?
                            <div className='p-2 small'>
                                <b>Starting from and including</b>
                                <DateTimePicker
                                    viewAs='split'
                                    small={true} onChange={(d) => setEntry({ ...entry, startDateTime: d })} defaultValue={entry.startDateTime} />
                                <b>On Every</b>
                                <DOWSelectorComponent />

                                <b>At</b>
                                <div className='d-flex'>
                                    <select style={{ width: '60px' }} className='form-control form-control-sm'>
                                        {Array.from(Array(24).keys()).map((i) =>
                                            <option>{i}</option>
                                        )}
                                    </select>:
                                    <select style={{ width: '60px' }} className='form-control form-control-sm'>
                                        {Array.from(Array(60).keys()).map((i) =>
                                            <option>{i}</option>
                                        )}
                                    </select>
                                    <div className='d-flex ms-2'>
                                        <b>Repeats</b><input style={{ width: '60px' }} className='form-control form-control-sm w-10 flex-shrink-1' type='number' /><b className='flex-grow-1'>Times</b>
                                    </div>
                                </div>

                            </div>
                            :
                            <div className='d-flex align-items-center justify-content-between p-2 w-100'>
                                <DateTimePicker small={true}
                                    onChange={(d) => setEntry({ ...entry, startDateTime: d })}
                                    defaultValue={entry.startDateTime} />-
                                <DateTimePicker
                                    onChange={(d) => setEntry({ ...entry, endDateTime: d })}
                                    small={true} defaultValue={entry.endDateTime} />
                            </div>
                    }

                </div>
                <div className='text-start'>
                    <Calendar2XFill className='me-2' />Details
                    <div className='p-2'>
                        <small><textarea
                            onChange={(d) => setEntry({ ...entry, description: d.target.value })}
                            defaultValue={entry.description ? entry.description : ''}
                            className='form-control form-control-sm m-2'></textarea></small>
                    </div>
                </div>
            </div>
        </WorkFlowDetailsModal>
    )
}

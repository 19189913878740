import React from 'react'
import { AdminLayout } from './adminLayout'
import { MenuContainer } from '../menu/MenuContainer'
import PatientearchBar from '../search/PatientearchBar'
import PatientManagement from '../section/patient/management/PatientManagement'
import { PatientProfileProvider } from '../section/patient/admin/PatientProfileProvider'
import { PatientProvider } from '../section/patient/management/PatientProvider'
import { Patientayout } from './PatientLayout'
import { PatientHistoryProvider } from '../section/patient/history/PatientHistoryProvider'
import { PatientLogin } from './PatientLogin'


export default function PatientLoginLayout({ menu, container, breadcrums }) {
    return (
        // TODO : Implment Provider Here
        //        Load Menu items from API
        //        Render fields for category
        <PatientProfileProvider>
            <PatientProvider>
                <PatientHistoryProvider>
                    <PatientLogin menu={menu}  container={container} breadcrums={breadcrums}  />
                </PatientHistoryProvider>
            </PatientProvider>
        </PatientProfileProvider>
    )
}

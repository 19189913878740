import { faSpinner } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useEffect, useState } from 'react'
import { InfoCircleFill, Pencil } from 'react-bootstrap-icons'
import Authentication from '../../../../../../libs/api/services/Authentication'
import MessagingServiceProvider from '../../../../../../libs/api/services/MessagingServiceProvider'
import ServiceProviderType from '../../../configuration/facility/components/wizard/setupType/ServiceProviderType'

export default function SendUsingOptions({ onChange }) {
    const [serviceType, setServiceType] = useState()
    const [serviceProviders, setServiceProviders] = useState([])
    const [serviceProvider, setServiceProvider] = useState()
    const [loading, setLoading] = useState(false)

    useEffect(() => { if (onChange) onChange({ serviceType: serviceType, serviceProvider: serviceProvider }) }, [serviceProvider,serviceType])

    useEffect(() => {
        setServiceProvider()
        if (serviceType) {
            _laodAvailableServiceProviders(serviceType)
        }
    }, [serviceType])
    const _laodAvailableServiceProviders = () => {
        setLoading(true)
        MessagingServiceProvider
            .ListAvailableServiceProviders(Authentication.UserId(), Authentication.getFacility(), serviceType)
            .then((r) => {
                setServiceProviders(r)
                setLoading(false)
            })

    }

    return (
        <div>
            {
                serviceProvider !== undefined
                    ?
                    <></>
                    :
                    <ServiceProviderType onChange={(r) => { setServiceType(r); if (r === 'Internal Messaging Service') setServiceProvider('Internal Messaging Service');}} defaultValue={serviceType} heading='  ' enableIMS={true} />
            }
            {/* <ServiceProviderType onChange={(r) => setServiceType(r)} defaultValue={serviceType} heading='  ' enableIMS={true} /> */}
            {
                serviceType !== 'Internal Messaging Service'
                    ?
                    loading === true
                        ?
                        <div className='text-center'><FontAwesomeIcon icon={faSpinner} spin /></div>
                        :
                        serviceType === undefined
                            ?
                            <></>
                            :
                            serviceProvider !== undefined
                                ?
                                <div onClick={() => setServiceProvider()}><b>Sending {serviceType} From</b>: {serviceProviders.find(s => s.id === serviceProvider).name}<Pencil /></div>
                                :
                                serviceProviders.length === 0
                                    ?
                                    <div className='text-center'><InfoCircleFill /> There are no service providers available for you to send this message</div>
                                    :
                                    <>
                                        <label>Please select the service provider you would like to send this message from</label>
                                        <select onChange={(e) => setServiceProvider(e.target.value)} className='form-control form-control-sm'>
                                            <option></option>
                                            {
                                                serviceProviders.map((s) =>
                                                    <option value={s.id} key={s.id}>{s.name}</option>
                                                )
                                            }
                                        </select>
                                    </>
                    :
                    <></>
            }

        </div>
    )
}

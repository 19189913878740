import React, { useContext, useEffect, useState } from "react"
import GeneralModal from "../../../components/dialogs/GeneralModal"
import ToDoListService from "../../../libs/api/services/ToDoListService"
import CommonFunctionsContext from "../../common/CommonFunctionsProvider"

const ToDoListContext = React.createContext()

export const ToDoListProvider = ({ children }) => {
    const { showError,showToast } = useContext(CommonFunctionsContext)
    const [modal, setModal] = useState({ show: false, icon: undefined, title: undefined, component: undefined })
    const [ToDoLists, setToDoLists] = useState([])

    const LoadToDoLists = async () => setToDoLists(await ToDoListService.ListAllToDoListsForUser())

    useEffect(() => { LoadToDoLists() }, [])

    const GetItemDetails = async (ItemId) => await ToDoListService.GetItemDetails(ItemId).catch((e) => showError(e))

    return (
        <ToDoListContext.Provider
            value={{
                extendedModal: (m) => setModal(m),
                ToDoLists,
                LoadToDoLists,
                GetItemDetails,
                // External common calls
                showError,
                showToast
            }}>
            <GeneralModal {...modal} onClose={() => setModal({ show: false })}>{modal.component}</GeneralModal>
            {children}
        </ToDoListContext.Provider>
    )
}

export default ToDoListContext
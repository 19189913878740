import React from 'react'

export default function DateTimePickerOptions({
    onCancel,
    onDone,
    onNow
}) {
    return (
        <div className='text-center p-1 flex-shrink-1'>
            {/* <button onClick={onCancel} className='btn'>Cancel</button> */}
            <button onClick={() => onNow()} className='btn'>Now</button>
            <button onClick={onDone} className='btn'>Done</button>
        </div>
    )
}

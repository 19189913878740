import React, { useEffect, useState } from 'react'
import Dashboards from '../../../libs/api/services/Dashboards'
import DashboardLayouts from './DashboardLayouts'
import DashboardWidgetAlert from './Widgets/DashboardWidgetAlert'
import DashboardWidgetCalendar from './Widgets/DashboardWidgetCalendar'
import DashboardWidgetMessages from './Widgets/DashboardWidgetMessages'
import DashboardWidgetQuestionnaires from './Widgets/DashboardWidgetQuestionnaires'
import DashboardWidgetToDo from './Widgets/DashboardWidgetToDo'
import DashboardWorkFlowCard from './Widgets/DashboardWorkFlowCard'
import './index.css'
export default function Dashboard({ type }) {
    const [wokFlowCards, setWokFlowCards] = useState([])
    const _loadWorkFlowCards = () => {
        Dashboards
            .GetDashboardWorkflowCards(type)
            .then((r) => {
                var cards = []
                for (const c of r)
                    cards.push(<DashboardWorkFlowCard {...c} />)

                setWokFlowCards(cards)
            });
    }
    useEffect(() => { _loadWorkFlowCards() }, [])
    return (
        <div className='m-3 dashboard-container'>
            <div className='row gy-2 gx-2 dashboard-row'>
                {
                    Object.keys(DashboardLayouts).includes(type) && DashboardLayouts[type].widgets.map((x) => {
                        switch (x.type) {
                            case 'Alerts': return <DashboardWidgetAlert />
                            case 'Messages': return <DashboardWidgetMessages />
                            case 'Calendar': return <DashboardWidgetCalendar />
                            case 'Questionnaires': return <DashboardWidgetQuestionnaires />
                            case 'ToDo': return <DashboardWidgetToDo />
                            default: return <></>
                        }
                    }
                    )
                }
                {
                    wokFlowCards.map((c) => c)
                }
            </div>
        </div>
    )
}

import React, { useContext, useState } from 'react'
import CalendarContext from '../../CalendarProvider'

export default function CalendarWeekFilter({ view, onChangeView }) {
    const _POPUP_KEY = 'CalendarWeekFilter'
    const { setFilter, filters, setActivePopup, activePopup } = useContext(CalendarContext)

    const [exapnd, setExpand] = useState(false)

    const _handleSetValue = (weekOfYear) => {
        setExpand(false)
        setFilter({ weekOfYear: weekOfYear })
        setActivePopup(undefined)
    }
    return (
        <div style={{ cursor: 'pointer' }} className='position-relative calendar-filter'>
            <div onClick={() => setActivePopup(activePopup !== _POPUP_KEY ? _POPUP_KEY : undefined)}>
                <div className='text-center'><b>Week Of Year</b></div>
                <div className='text-center'>{filters.weekOfYear}</div>
            </div>


            <div className={'position-absolute bg-secondary-container rounded shadow  '.concat(activePopup === _POPUP_KEY ? 'show' : 'hide')}>
                <div className='row'>
                    {
                        [...Array(53).keys()]
                            .map((m, i) => {
                                return i > 0 ?
                                    <div key={'cal-wk-' + m} className={'col-3 p-1 text-center '.concat(filters.weekOfYear === m ? ' active rounded ' : '')} onClick={() => _handleSetValue(m)}>{m}</div>
                                    : <></>
                            }
                            )
                    }
                </div>
            </div>
        </div>
    )
}

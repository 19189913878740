import React, { useContext, useEffect, useRef, useState } from 'react'
import { Check2Circle, Check2Square, Clipboard2, ExclamationDiamondFill, Pen, PlusCircle, Square, Trash2, XCircle } from 'react-bootstrap-icons'
import SimpleColourPicker from '../../../../components/colour/SimpleColourPicker'
import ConfirmDialog from '../../../../components/dialogs/ConfirmDialog'
import ErrorDialog from '../../../../components/dialogs/ErrorDialog'
import GenericIconPicker from '../../../../components/icons/GenericIconPicker'
import PriorityPicker from '../../../../components/priority/PriorityPicker'
import OnScreenAssist from '../../../../components/ui/hint/OnScreenAssist'
import ToDoListService from '../../../../libs/api/services/ToDoListService'
// import { ToDoListAdminProvider } from './ToDoListAdminProvider'
import { BiCollapse, BiExpand } from 'react-icons/bi'
import { BsClipboard2Check, BsFillCaretDownFill, BsFillCaretUpFill } from 'react-icons/bs'
import { useClickOutside } from '../../../../components/Hooks/useClickOutside'
import ToggleButton from '../../../../components/ui/toggle_button/ToggleButton'
import './ToDoListAdmin.css'

const ToDoListAdminContext = React.createContext()

const ToDoListAdminProvider = ({ children }) => {
    const [state, setState] = useState({
        ToDoLists: [],
        ToDoList: undefined,
        ToDoListItem: undefined,
        ToDoListItemStatuses: [],
        ToDoListValidation: [],
        focus: {
            group: undefined,
            item: undefined,
            status: undefined,
            properties: undefined,
        }
    })
    const [delOpt, setDelOpt] = useState({ onCancel: () => { }, onConfirm: () => { }, title: undefined, open: false, text: '' })
    const [error, setError] = useState({
        error: undefined, onClose: () => setError({
            ...error, error: undefined
        }), humanize: true
    })


    /**
     * To Do List
     */
    const _validateAll = (_ToDoLists) => {
        var res = []
        LI: for (const td of _ToDoLists) {
            const list = td
            if (!list) { res[td.id] = "There are no items on this to do list"; continue; }
            if (!list.lists) { res[td.id] = "There are no items on this to do list"; continue; }
            if (list.lists.length === 0) { res[td.id] = "There are no items on this to do list"; continue; }
            for (const l of list.lists) {
                if (!l.items || l.items.length === 0) {
                    res[td.id] = "Not all items have statuses set"; continue LI;
                }
                var _hasCloseStatus = false
                var _hasdefaultstatus = false
                for (const i of l.items) {
                    if (i.isDefault) _hasdefaultstatus = true
                    if (i.properties) {
                        if (i.properties.find(p => p.property === "REMOVE_ON_SELECTED") && i.properties.find(p => p.property === "REMOVE_ON_SELECTED").value === "true")
                            _hasCloseStatus = true
                    }
                }
                if (!_hasdefaultstatus) {
                    res[td.id] = `${l.list} does not have a default status set`; continue LI;
                }
                if (!_hasCloseStatus) {
                    res[td.id] = `${l.list} does not have a status with a remove from list option set`; continue LI;
                }
            }
            res[td.id] = undefined
        }
        return res
    }

    const validateToDoList = (ToDoListID) => {
        try {
            return state.ToDoListValidation[ToDoListID]
        } catch (e) {
            return undefined
        }
    }

    const loadToDoLists = async () => {
        // Get From API
        const _ToDoLists = await ToDoListService.ListToDoLists().catch((e) => setError({ ...error, error: e }))
        // Store to local memeory
        if (!_ToDoLists) return
        setState({
            ...state,
            ToDoLists: _ToDoLists,
            ToDoListItems: [],
            ToDoListValidation: _validateAll(_ToDoLists)
        })
        // return to requester
        return _ToDoLists
    }
    const addToDoLists = async (v) => {
        // await new Promise((resolve) => setTimeout(resolve, 5000));
        const new_list = await ToDoListService.CreateToDoList({
            group: v
        }).catch((e) => setError({ ...error, error: e }))

        if (new_list) setState({
            ...state,
            ToDoLists: [...state.ToDoLists, new_list],
            ToDoList: new_list,
            ToDoListValidation: _validateAll([...state.ToDoLists, new_list]),
            focus: { group: new_list.id }
        })

    }

    const setActiveList = (list) => {
        setState({
            ...state,
            ToDoList: list
        })
    }
    const removeToDoList = async (toDoList, confirmed) => {
        if (!confirmed) {
            setDelOpt({
                title: "Remove List", open: true, onCancel: () => setDelOpt(), onConfirm: () => { removeToDoList(toDoList, true); setDelOpt() },
                text: 'Are you sure you would like to remove this to do list?'
            })
        }
        else if (confirmed) {
            ToDoListService.RemoveToDoList(toDoList.id)
                .then((r) => {
                    setState({
                        ...state,
                        ToDoList: undefined,
                        ToDoLists: state.ToDoLists.filter((l) => l.id !== toDoList.id),
                        ToDoListValidation: _validateAll(state.ToDoLists.filter((l) => l.id !== toDoList.id))

                    })
                }).catch((e) => setError({ ...error, error: e }))
        }
    }
    const renameToDoList = async (toDoList, name, confirmed) => {
        if (!confirmed) {
            setDelOpt({
                title: "Rename List", open: true, onCancel: () => setDelOpt(), onConfirm: async () => { await renameToDoList(toDoList, name, true); setDelOpt() },
                text: `Are you sure you would like to rename this to do list to ${name}?`
            })
        }
        else if (confirmed) {
            return await ToDoListService.renameToDoList(toDoList.id, { ...toDoList, group: name })
                .then((r) => {
                    var _list = state.ToDoLists.find((l) => l.id === toDoList.id)
                    _list.group = name
                    setState({
                        ...state,
                        // ToDoList: undefined,
                        ToDoLists: state.ToDoLists,
                        ToDoListValidation: _validateAll(state.ToDoLists)
                    })
                }).catch((e) => setError({ ...error, error: e }))
        }
    }
    /**
     * To Do List Items
     */
    const loadToDoListItems = async (toDoList) => {
        try {
            const lists = state.ToDoLists.find((l) => l.id === toDoList.id).lists
            setState({
                ...state,
                ToDoListItems: lists ? lists : [],
                ToDoListItem: undefined,
                ToDoListItemStatuses: [],
                ToDoListValidation: _validateAll(state.ToDoLists)
            })
            return lists
        } catch (e) { return [] }
    }
    const addToDoListItem = async (toDoList, item) => {
        const new_item = await ToDoListService.UpsertToDoListItem(toDoList.id, {
            list: item
        }).catch((e) => setError({ ...error, error: e }))
        if (new_item) {
            const lists = state.ToDoLists
            var list = lists.find((l) => l.id === toDoList.id)
            if (list && lists[lists.indexOf(list)].lists)
                lists[lists.indexOf(list)].lists = [...lists[lists.indexOf(list)].lists, new_item]
            else
                lists[lists.indexOf(list)].lists = [new_item]

            setState({
                ...state,
                ToDoLists: lists,
                ToDoListItem: new_item,
                ToDoListItems: lists[lists.indexOf(list)].lists,
                ToDoListValidation: _validateAll(state.ToDoLists)
            })
        }
    }

    const setActiveToDoListItem = (item) => {
        setState({
            ...state,
            ToDoListItem: item ? item : undefined
        })
    }

    const removeToDoListItem = async (ToDoList, toDoListItem, confirmed) => {

        if (!confirmed) {
            setDelOpt({
                title: "Remove Item", open: true, onCancel: () => setDelOpt(), onConfirm: () => { removeToDoListItem(ToDoList, toDoListItem, true); setDelOpt() },
                text: 'Are you sure you would like to remove this to do list item?'
            })
        }
        else if (confirmed) {
            const lists = state.ToDoLists
            var list = lists.find((l) => l.id === ToDoList.id)
            lists[lists.indexOf(list)].lists = lists[lists.indexOf(list)].lists.filter((f) => f.id !== toDoListItem.id)
            ToDoListService.RemoveToDoListItem(toDoListItem.id)
                .then((r) => {
                    setState({
                        ...state,
                        ToDoLists: lists,
                        ToDoListItems: lists[lists.indexOf(list)].lists,
                        ToDoListItem: undefined,
                        ToDoListItemStatuses: [],
                        ToDoListValidation: _validateAll(lists)
                    })
                }).catch((e) => setError({ ...error, error: e }))
        }
    }

    const renameToDoListItem = async (ToDoList, toDoListItem, name, confirmed) => {

        if (!confirmed) {
            setDelOpt({
                title: "Remove Item", open: true, onCancel: () => setDelOpt(), onConfirm: () => { renameToDoListItem(ToDoList, toDoListItem, name, true); setDelOpt() },
                text: `Are you sure you would like to rename this to do list item to ${name}?`
            })
        }
        else if (confirmed) {

            const r = await ToDoListService.RenameToDoListItem(ToDoList.id, { ...toDoListItem, list: name })
                .catch((e) => setError({ ...error, error: e }))

            if (r) {
                var lists = state.ToDoLists
                var list = lists.find((l) => l.id === ToDoList.id)
                lists[lists.indexOf(list)].lists.find((f) => f.id === toDoListItem.id).list = name
                setState({
                    ...state,
                    ToDoLists: lists,
                    ToDoListItems: lists[lists.indexOf(list)].lists,
                    ToDoListItem: undefined,
                    ToDoListItemStatuses: [],
                    ToDoListValidation: _validateAll(lists)
                })
            }
        }
    }

    /**
     * To Do List Statuses
     */
    const loadToDoListItemStatuses = (toDoList, toDoListItem) => {
        try {
            const lists = state.ToDoLists.find((l) => l.id === toDoList.id).lists.find((i) => i.id === toDoListItem.id).items
            setState({
                ...state,
                ToDoListItemStatuses: lists ? lists : [],
                ToDoListValidation: _validateAll(state.ToDoLists)
            })
            return lists
        } catch (e) { return [] }
    }
    const addToDoListItemStatus = async (Group, toDoListItem, status) => {
        const new_item = await ToDoListService.UpsertToDoListItemStatus(toDoListItem.id, {
            item: status
        }).catch((e) => setError({ ...error, error: e }))

        if (new_item) {
            var items = state.ToDoLists.find((i) => i.id === Group.id).lists
                .find((i) => i.id === toDoListItem.id)
                .items

            if (items)
                items.push(new_item)
            else
                items = state.ToDoLists.find((i) => i.id === Group.id).lists
                    .find((i) => i.id === toDoListItem.id).items = [new_item]
            setState({
                ...state,
                ToDoListValidation: _validateAll(state.ToDoLists)
            })
        }
    }

    const RemoveStatus = async (Group, ActiveToDoListItem, statusId, confirmed) => {

        if (!confirmed) {
            setDelOpt({
                title: "Remove Status", open: true, onCancel: () => setDelOpt(), onConfirm: () => { RemoveStatus(Group, ActiveToDoListItem, statusId, true); setDelOpt() },
                text: 'Are you sure you would like to remove this status?'
            })
        } else if (confirmed) {
            await ToDoListService
                .RemoveToDoListStatus(ActiveToDoListItem.id, statusId)
                .then(async (r) => {

                    var items = state.ToDoLists.find((i) => i.id === Group.id).lists
                        .find((i) => i.id === ActiveToDoListItem.id)
                        .items
                    const _item = items.find((s) => s.id === statusId)
                    items.splice(items.indexOf(_item), 1)

                    setState({
                        ...state,
                        ToDoListValidation: _validateAll(state.ToDoLists)
                    })
                }).catch((e) => setError({ ...error, error: e }))
        }
    }

    const RenameStatus = async (Group, ActiveToDoListItem, statusId, name, confirmed) => {
        if (!confirmed) {
            setDelOpt({
                title: "Remove Item", open: true, onCancel: () => setDelOpt(), onConfirm: () => { RenameStatus(Group, ActiveToDoListItem, statusId, name, true); setDelOpt() },
                text: `Are you sure you would like to rename this to do list item to ${name}?`
            })
        }
        else if (confirmed) {
            var lists = state.ToDoLists
            var list = lists.find((l) => l.id === Group.id)

            var item = lists[lists.indexOf(list)].lists.find((f) => f.id === ActiveToDoListItem.id).items
                .find(x => x.id === statusId.id)

            item.item = name

            // state.ToDoLists.find((l) => l.id === Group.id).lists.find((f) => f.id === ActiveToDoListItem.id)

            await ToDoListService.RenameToDoListStatus(ActiveToDoListItem.id, { ...item, item: name })
                .then((r) => {
                    setState({
                        ...state,
                        ToDoListValidation: _validateAll(state.ToDoLists)
                    })
                }).catch((e) => setError({ ...error, error: e }))
        }
    }
    const setToDoListItemDefaultStatus = async (Group, list, status) => {

        var lists = state.ToDoLists
        var activeList = lists.find((l) => l.id === Group.id)

        var items = lists[lists.indexOf(activeList)].lists.find((f) => f.id === list.id).items
        // .find(x => x.id === statusId.id)
        items.map(x => {
            x.id !== status.id ? x.isDefault = false : x.isDefault = true
            return x
        })

        ToDoListService.setToDoListItemDefaultStatus(status.id)
            .then((r) =>
                setState({
                    ...state,
                    ToDoListValidation: _validateAll(state.ToDoLists)
                })
            ).catch((e) => { })
    }
    const SetStatusProperty = async (ItemId, Key, Value) => {
        if (!(ItemId, Key && Value)) return
        ToDoListService
            .SetStatusProperty(ItemId, {
                property: Key,
                value: Value
            }).then((r) => {
                loadToDoLists()
                // setState({
                //     ...state,
                //     ToDoListValidation: _validateAll(state.ToDoLists)
                // })
            })

    }

    return (<ToDoListAdminContext.Provider value={{
        /* Lists */
        validateToDoList,
        loadToDoLists,
        setActiveList,
        ToDoLists: state.ToDoLists,
        ActiveList: state.ToDoList,
        addToDoLists,
        removeToDoList,
        renameToDoList,
        /* Items */
        loadToDoListItems,
        ToDoListItems: state.ToDoListItems,
        addToDoListItem,
        setActiveToDoListItem,
        ActiveToDoListItem: state.ToDoListItem,
        removeToDoListItem,
        renameToDoListItem,
        setToDoListItemDefaultStatus,
        /* Statuses */
        loadToDoListItemStatuses,
        ToDoListItemStatuses: state.ToDoListItemStatuses,
        addToDoListItemStatus,
        RemoveStatus,
        RenameStatus,
        SetStatusProperty,
        focus: state.focus,
        setFocus: (id) => setState({ ...state, focus: id })
    }}>
        <ConfirmDialog {...delOpt}>
            {delOpt && delOpt.text && <div>{delOpt.text}</div>}
        </ConfirmDialog>
        <ErrorDialog {...error} />
        {children}
    </ToDoListAdminContext.Provider>)
}

export default function ToDoListAdmin() {
    const [mode, setMode] = useState('overview')
    return (
        <ToDoListAdminProvider>
            <div className="m-3 mh-100 flex-grow-1 d-flex flex-column">
                <h1 className="text-center">To Do List Management</h1>
                {
                    mode === 'overview'
                        ?
                        <ToDoListOverview />
                        :
                        <>
                            {/* <ToDoListGroups />
                        <ToDoListItems>
                            <ToDoListStatus />
                        </ToDoListItems> */}
                        </>
                }
            </div>
            <OnScreenAssist key={'osa-to-do-list'} items={[
                {
                    heading: 'What is a to do list',
                    description: 'A to do list comprises of a list of items to complete. These items are normaly taks to be completed for the list'
                }, {
                    heading: 'What is a list item',
                    description: 'A list item is an item / task that needs to be carried out or checked off'
                }, {
                    heading: 'What is a list item status',
                    description: 'This is a particular status an item can be in at any given time'
                }
            ]} />
        </ToDoListAdminProvider>
    )
}

/**
 * 
 * Generics
 */
// const SelectOrAddComponent = ({
//     items,
//     onSelected,
//     options,
//     defaultValue,
// }) => {
//     const [mode, setMode] = useState()
//     const [value, setValue] = useState()

//     const _handleOnCreate = async () => {
//         await options.add.action(value)
//         setMode()
//     }
//     const _handleSelect = (o) => { if (onSelected) onSelected(o) }
//     const _handleBeforeAdd = (o) => setMode('add')

//     if (mode === 'add')
//         return (<div className='d-flex'>
//             <InputWithKeyFunctions
//                 onChange={(v) => setValue(v)}
//                 // onCancel={() => setMode()}
//                 onSubmit={() => _handleOnCreate()}
//                 selected={true}
//                 className={'form-control form-control-sm'}
//                 defaultValue={value} />
//             <button
//                 onClick={() => setMode()}
//                 className='btn btn-xs'><XCircle /></button>
//             <button
//                 onClick={() => _handleOnCreate()}
//                 className='btn btn-xs'><PlusCircle /></button>
//         </div>)
//     else
//         return (
//             <div className='d-flex w-100'>
//                 <div className='flex-grow-1'>
//                     <SearchComponent
//                         onSelected={_handleSelect}
//                         defaultValue={defaultValue}
//                         placeholder={options && options.placeholder ? options.placeholder : 'Search / Add a to list'}
//                         focusSearch
//                         details={{
//                             data: items ? items : [],
//                             options: {
//                                 returns: 'object',
//                                 onAdd: !(options && options.add) ? undefined : {
//                                     action: (v) => { _handleBeforeAdd(v) },
//                                     text: options && options.add && options.add.text
//                                 },
//                                 reRefKey: 'id',
//                                 template: [options.template]
//                             }
//                         }} />
//                 </div>
//                 {options.onDelete && <button onClick={() => options.onDelete.action()} className='btn btn-xs flex-shrink-1'><Trash2 /></button>}
//             </div>)
// }


// /**
//  * 
//  * Components
//  */


// const ToDoListGroups = () => {
//     const { loadToDoLists, ToDoLists, setActiveList, ActiveList, addToDoLists, removeToDoList } = useContext(ToDoListAdminContext)

//     const _handleLoad = async () => await loadToDoLists() // Load items from the API
//     const _handleSelected = (g) => setActiveList(g) // Items Has Been Selected
//     // eslint-disable-next-line 
//     useEffect(() => { _handleLoad() }, [])


//     return (<div className='bg-primary-container p-2 rounded my-2 shadow'>
//         <div className='d-flex'>
//             <div><Calendar2 className='me-2' /></div>
//             <div className='text-light'><label className='pb-2'><b>To Do List</b></label></div>
//         </div>
//         <div className='Search-and-add-bar'>
//             <SelectOrAddComponent
//                 options={{
//                     placeholder: 'Search / Add a to to do list',
//                     template: {
//                         refKey: 'group',
//                         descr: '',
//                     },
//                     add: {
//                         action: addToDoLists,
//                         text: 'Create a new to to list'
//                     },
//                     onDelete: ActiveList ? {
//                         action: () => removeToDoList(ActiveList)
//                     } : undefined
//                 }}
//                 defaultValue={ActiveList}
//                 items={ToDoLists}
//                 onSelected={_handleSelected}
//             />
//             {/* <ToDoSelectorComponent
//                 onSelected={_handleSelected}
//                 onAdd={_handleAdd}
//                 allowAdd={true} /> */}
//         </div>
//     </div>)
// }
// const ToDoListItems = ({ children }) => {
//     const { ActiveList, ActiveToDoListItem, loadToDoListItems, ToDoListItems, setActiveToDoListItem, addToDoListItem, removeToDoListItem } = useContext(ToDoListAdminContext)

//     const _handleLoad = async () => await loadToDoListItems(ActiveList) // Load items from the API
//     const _handleSelected = (g) => setActiveToDoListItem(g) // Items Has Been Selected

//     // eslint-disable-next-line 
//     useEffect(() => { if (ActiveList) _handleLoad() }, [ActiveList])
//     if (!ActiveList) return <></>
//     return (<div className='m-2 p-2 bg-primary-container rounded shadow' style={{ overflow: 'auto', minHeight: 'calc(100% - 120px)' }}>
//         <label className='my-1'>
//             <b><Clipboard2 className='me-2' />Items on this to do list</b>
//         </label>
//         <SelectOrAddComponent
//             options={{
//                 placeholder: 'Search / Add a to to do list item',
//                 template: {
//                     refKey: 'list',
//                     descr: '',
//                 },
//                 add: {
//                     action: async (c) => addToDoListItem(ActiveList, c),
//                     text: 'Create a new to to list'
//                 },
//                 onDelete: ActiveToDoListItem ? {
//                     action: () => removeToDoListItem(ActiveToDoListItem)
//                 } : undefined
//             }}
//             defaultValue={ActiveToDoListItem}
//             items={ToDoListItems}
//             onSelected={_handleSelected}
//         />
//         {children}
//     </div>)
// }
// const ToDoListStatus = () => {
//     const { ActiveList, ActiveToDoListItem, loadToDoListItemStatuses, ToDoListItemStatuses, addToDoListItemStatus } = useContext(ToDoListAdminContext)
//     const [value, setValue] = useState()
//     const _handleLoad = async () => await loadToDoListItemStatuses(ActiveList, ActiveToDoListItem) // Load items from the API
//     const _handleAdd = async () => {
//         await addToDoListItemStatus(ActiveToDoListItem, value)
//         setValue()
//     }
//     // eslint-disable-next-line 
//     useEffect(() => { if (ActiveList && ActiveToDoListItem) _handleLoad() }, [ActiveList, ActiveToDoListItem])
//     if (!ActiveToDoListItem) return <></>
//     return (
//         <div className='rounded'>
//             <label className='my-1'><b><ClipboardPulse className='me-1' />Statuses</b></label>
//             <div className='d-flex'>
//                 <InputWithKeyFunctions
//                     onChange={(e) => setValue(e)}
//                     defaultValue={value}
//                     onSubmit={_handleAdd}
//                     className={'form-control form-control-sm'}
//                     placeholder={'New Status'} />
//                 <button
//                     onClick={_handleAdd}
//                     className='btn btn-xs'><PlusCircle /></button>
//             </div>
//             {ToDoListItemStatuses && ToDoListItemStatuses.map((l) => <ToDoListStatusLineItem key={l.id} item={l} />)}
//         </div>)

// }

// const ToDoListStatusLineItem = ({ item }) => {
//     const { RemoveStatus, ActiveToDoListItem, SetStatusProperty } = useContext(ToDoListAdminContext)

//     const _findValue = (k) => {
//         console.log(item, k)
//         if (!item.properties) return
//         const _prop = item.properties.find((p) => p.property === k)
//         if (_prop)
//             return _prop.value
//         return undefined
//     }

//     // Set Props
//     const _handleSetProps = (k, v) => SetStatusProperty(item.id, k, v)

//     return (
//         <>

//             <div key={item.id} className='bg-secondary-container m-1 rounded '>
//                 <div className='d-flex p-1'>
//                     <input className='form-control form-control-sm' placeholder='Status' defaultValue={item.item} />
//                     <div
//                         onClick={() => RemoveStatus(ActiveToDoListItem, item.id)}
//                     ><Trash2 className='ms-2' /></div>
//                 </div>
//                 <div className='d-flex p-1 justify-content-between'>
//                     <div>
//                         <div>Default Colour</div>
//                         <div><SimpleColourPicker onChange={(v) => _handleSetProps("COLOUR", v)} defaultValue={_findValue("COLOUR")} popup={true} /></div>
//                     </div>
//                     <div>
//                         <div>Default Icon</div>
//                         <div><GenericIconPicker onChange={(v) => _handleSetProps("ICONS", JSON.stringify(v))} defaultValue={_findValue("ICONS") && JSON.parse(_findValue("ICONS"))} dropdown={true} /></div>
//                     </div>
//                     <div>
//                         <div>Default Severity</div>
//                         <div><PriorityPicker onChange={(v) => _handleSetProps("SEVERITY", v)} defaultValue={_findValue("SEVERITY")} /></div>
//                     </div>
//                 </div>
//                 <div className='d-flex p-1 justify-content-between'>
//                     {/* eslint-disable-next-line */}
//                     <ToggleButton onToggle={(v) => _handleSetProps("REMOVE_ON_SELECTED", JSON.stringify(v))} defaultValue={_findValue("REMOVE_ON_SELECTED") && JSON.parse(_findValue("REMOVE_ON_SELECTED"))} style='tick'>Remove From List Once Selected</ToggleButton>
//                 </div>
//             </div></>)
// }
/*
Overview layout
*/
/*
Commons
*/


const ToggleContainerComponent = ({ id, focused, open, header, className, children }) => {
    const { focus, setFocus } = useContext(ToDoListAdminContext)
    const itemRef = useRef()
    const [isOpen, setIsOpen] = useState(open)
    useEffect(() => { setIsOpen(open) }, [open])
    useEffect(() => {
        if (focused && focus && (
            (focused.group !== undefined && focused.group === focus.group) &&
            (focused.item === undefined || focused.item === focus.item) &&
            (focused.status === undefined || focused.status === focus.status) &&
            (focused.properties === undefined || focused.properties === focus.properties)
        )) {
            setIsOpen(true);
            // Scroll to 
            itemRef.current.scrollIntoView({ block: "end", inline: "nearest" })
        } else setIsOpen(false)
    }, [focused, focus])

    // setFocus
    const _handleToggle = (e) => {
        e.stopPropagation()
        e.preventDefault()
        // console.log('..............||', focused, focus)
        if (!isOpen === true) setFocus(focused)
        setIsOpen(!isOpen)
    }

    return <div ref={itemRef} className={` ${className} `}>

        <button style={{ cursor: 'pointer' }} onClick={_handleToggle} className={`d-flex align-items-center toggle-bar ${className}`}>
            <div className='flex-shrink-1'>
                {isOpen ? <BsFillCaretUpFill className='m-2' /> : <BsFillCaretDownFill className='m-2' />}
            </div>
            <div className='flex-grow-1'>{header}</div>
        </button>
        <div className={isOpen ? '' : 'd-none'}>{children}</div>
    </div>
}

const TitleBarComponent = ({ title, container, className, onRemove, onEdit }) => {
    const [edit, setEdit] = useState(false)

    const ContainerRef = useRef()
    const ListNameRef = useRef()

    useClickOutside(ContainerRef, () => setEdit(false))

    const _handleClear = (e) => {
        if (e) e.stopPropagation()
        setEdit(false);
        document.removeEventListener('keyup', _handleKeyEvent);
    }

    const _handleKeyEvent = (e) => {
        switch (e.key) {
            case 'Escape': _handleClear(); break;
            case 'Enter': _handleSave(undefined); break;
            default: break;
        }
    }

    const _handleSave = async (e) => {
        if (!ListNameRef.current) return
        await onEdit(ListNameRef.current.value)
        _handleClear()
    }

    const _handleDelete = async (e) => {
        e.stopPropagation()
        await onRemove()
    }
    const _handleRename = async (e) => {
        if (e) {
            e.stopPropagation()
        }

        if (edit) {
            await _handleSave()
        } else {
            setTimeout(() => { ListNameRef.current.select() }, 200)
            ListNameRef.current.value = title

            document.addEventListener('keyup', _handleKeyEvent)
            setEdit(true)
        }
    }
    return (<>
        <button ref={ContainerRef} className={`title-bar ${className && className}`}>
            <div className='title-bar-title'>
                <div className={edit && 'd-none'}>{container}</div>
                <div className={!edit && 'd-none'}>
                    <input ref={ListNameRef} onClick={(e) => e.stopPropagation()} className='form-control form-control-sm' />
                </div>
            </div>
            <div className='title-bar-options'>
                <button className='btn btn-xs' onClick={_handleRename}>
                    {edit ? <Check2Circle /> : <Pen />}
                </button>
                {edit ?
                    <button className='btn btn-xs' onClick={_handleClear}><XCircle /></button>
                    :
                    <button className='btn btn-xs' onClick={_handleDelete}><Trash2 /></button>
                }
            </div>
        </button>
    </>)
}

const AddItemComponent = ({ focused, description, align, className, onAdd, open }) => {
    const { focus, setFocus } = useContext(ToDoListAdminContext)
    const [error, setError] = useState()
    const [edit, setEdit] = useState(open ? open : false)
    const ListRef = useRef()
    const ContainerRef = useRef()

    useClickOutside(ContainerRef, () => _handleClear())

    useEffect(() => {
        if (open) {
            // setEdit(open)
            _handleStartAdd()
        }
        if (open && focus && focused && focus.group === focused.group) setTimeout(() => { ListRef.current.focus() }, 500)
        // console.log('open', focus)
    }, [open])

    const _handleKeyEvent = (e) => {
        switch (e.key) {
            case 'Escape': _handleClear(); break;
            case 'Enter': _handleAdd(); break;
            default: break;
        }
    }
    const _handleStartAdd = (e) => {
        setFocus(focused)
        document.addEventListener('keyup', _handleKeyEvent)
        setEdit(true)
        setTimeout(() => { ListRef.current.focus() }, 200)
    }

    const _handleClear = () => {
        document.removeEventListener('keyup', _handleKeyEvent)
        if(ListRef.current) ListRef.current.value = ""
        if(ListRef.current) ListRef.current.placeholder = `New ${description} name`
        setEdit(false)
        setError(undefined)
    }

    const _handleAdd = async () => {
        if (!ListRef.current) return
        if (ListRef.current.value.trim() === "") {
            ListRef.current.placeholder = `Please enter a name for the new ${description}`
            ListRef.current.select()
            setError(true)
            return false
        }
        await onAdd(ListRef.current.value)
        _handleClear()
    }

    return (<div onClick={() => _handleStartAdd()} ref={ContainerRef} className={`add-list-line-item ${className && className}`}>
        {/* <button
            onClick={_handleStartAdd}
            className={`add-list-container ${align} btn btn-sm ${edit && 'd-none'}`}> <PlusCircleDotted className='me-2' />{`Create new ${description}`}</button> */}
        <div
            className={`add-list-container-details `}>
            <input onChange={() => setError()} ref={ListRef} className={`form-control form-control-sm add-list-input ${error && 'bg-danger-container'}`} placeholder='New list name' />
            <button onClick={_handleAdd} className='btn btn-sm'><PlusCircle /></button>
        </div>
    </div>)
}
/*
Components
*/

const ToDoListOverview = () => {
    const { loadToDoLists,
        ToDoLists,
        addToDoLists,
        removeToDoList,
        renameToDoList,
        addToDoListItem,
        removeToDoListItem,
        renameToDoListItem,
        addToDoListItemStatus
    } = useContext(ToDoListAdminContext)

    const [collapsed, setCollapsed] = useState(false)
    useEffect(() => { loadToDoLists() }, [])

    return (
        <>
            <div className='to-do-lists-container' style={{ overflow: 'auto', maxHeight: 'calc(100% - 125px)' }}>
                <div className={'text-end w-100'}>
                    <button className='btn' onClick={() => setCollapsed(!collapsed)}>{!collapsed ? <BiExpand /> : <BiCollapse />} </button>
                </div>
                <AddItemComponent
                    focused={{
                        group: undefined,
                        item: undefined,
                        status: undefined,
                        properties: undefined
                    }}
                    className={'bg-primary-container p-2 rounded'} description={'to do list'} align={'center'} onAdd={addToDoLists} />
                {ToDoLists && ToDoLists.map((group) =>

                    <ToDoListLineItem group={group} collapsed={collapsed}
                        onRemove={async () => await removeToDoList(group)}
                        onEdit={async (name) => await renameToDoList(group, name)}
                    >
                        <div key={group.id} className='group-container p-1 '>
                            <div className='ps-3 '>
                                <div className='d-flex justify-content-center align-items-center'><b><BsClipboard2Check />List Items</b></div></div>
                            {group.lists && group.lists.map((list) =>
                                <ToDoListItemsLineItem list={list} group={group}
                                    onRemove={async () => await removeToDoListItem(group, list)}
                                    onEdit={async (name) => await renameToDoListItem(group, list, name)}>
                                    <div className='statuses-container bg-primary-container rounded p-1 shadow mb-2 small'>
                                        <ToggleContainerComponent open={collapsed}
                                            focused={{
                                                group: group.id,
                                                item: list.id,
                                                status: undefined,
                                                properties: undefined
                                            }}
                                            id={list.id}
                                            header={'Statuses'}>
                                            {list.items && list.items.map((item) =>
                                                <ToDoListItemsStatusLineItem group={group} list={list} item={item} />
                                            )}
                                            <AddItemComponent
                                                focused={{
                                                    group: group.id,
                                                    item: list.id,
                                                    status: undefined,
                                                    properties: undefined
                                                }}
                                                description={'status'} onAdd={async (st) => await addToDoListItemStatus(group, list, st)} />
                                        </ToggleContainerComponent>
                                    </div>
                                </ToDoListItemsLineItem>)}
                            <AddItemComponent
                                open={!group.lists || group.lists.length === 0}
                                // open={true}
                                focused={{
                                    group: group.id,
                                    item: undefined,
                                    status: undefined,
                                    properties: undefined
                                }}
                                description={'list item'} onAdd={async (name) => await addToDoListItem(group, name)} />
                        </div>
                    </ToDoListLineItem>
                )}
            </div>
        </>)
}

const ToDoListLineItem = ({ group, children, collapsed, onRemove, onEdit }) => {
    const { validateToDoList } = useContext(ToDoListAdminContext)


    return (<ToggleContainerComponent
        id={group.id}
        focused={{
            group: group.id,
            item: undefined,
            status: undefined,
            properties: undefined
        }}
        open={collapsed ? collapsed : false}
        className='bg-primary-container shadow rounded'
        header={<TitleBarComponent
            container={<div className='m-1'><div><Clipboard2 className='me-2' />{group.group}</div>
                {validateToDoList(group.id) && <div className='bg-warning-container text-start p-1 rounded small'><ExclamationDiamondFill className='me-2' />{validateToDoList(group.id)}</div>}
            </div>}
            title={group.group} onRemove={onRemove} onEdit={onEdit} />}>
        <div className='bg-secondary-container m-2 rounded'>
            {children}
        </div>
    </ToggleContainerComponent >)
}

const ToDoListItemsLineItem = ({ group, list, children, onRemove, onEdit }) => {

    return (<>
        <div className='lists-container'>
            <TitleBarComponent
                onEdit={onEdit}
                onRemove={onRemove}
                container={list.list}
                title={list.list}
            />
            {children}
        </div>
    </>)
}

const ToDoListItemsStatusLineItem = ({ group, list, item }) => {

    const { RemoveStatus, RenameStatus, SetStatusProperty, setToDoListItemDefaultStatus } = useContext(ToDoListAdminContext)



    const _handleSetProps = (k, v) =>
        SetStatusProperty(item.id, k, v)

    const _findValue = (k) => {
        if (!item.properties) return
        const _prop = item.properties.find((p) => p.property === k)
        if (_prop)
            return _prop.value
        return undefined
    }
    if (!item) return
    return (<div className='ps-3'>
        <TitleBarComponent title={item.item}
            container={<div className='d-flex align-items-center'>
                <div className='flex-grow-1'>{item.item}</div>
                <button onClick={() => setToDoListItemDefaultStatus(group, list, item)} className='btn btn-xs'>
                    {item.isDefault ? <Check2Square className='me-2' size='20' /> : <Square className='me-2' size='20' />}Default Status
                </button>
            </div>}
            onRemove={async () => RemoveStatus(group, list, item.id)}
            onEdit={(name) => RenameStatus(group, list, item, name)}
        />

        <ToggleContainerComponent open={false}
            focused={{
                group: group.id,
                item: list.id,
                status: item.id,
                properties: undefined
            }}
            header={<small>Properties</small>}>
            <div>
                <div className='d-flex p-1 flex-column justify-content-between small'>
                    <div className='d-flex  align-items-center position-relative'>
                        <div className='w-50'>Default Colour</div>
                        <div className='w-50'><SimpleColourPicker onChange={(v) => _handleSetProps("COLOUR", v)} defaultValue={_findValue("COLOUR")} popup={true} /></div>
                    </div>
                    <div className='d-flex justify-content-between align-items-center position-relative'>
                        <div className='w-50'>Default Icon</div>
                        <div className='w-50'><GenericIconPicker onChange={(v) => _handleSetProps("ICONS", JSON.stringify(v))} defaultValue={_findValue("ICONS") && JSON.parse(_findValue("ICONS"))} dropdown={true} /></div>
                    </div>
                    <div className='d-flex justify-content-between align-items-center position-relative'>
                        <div className='w-50'>Default Severity</div>
                        <div className='w-50 text-dark'><PriorityPicker onChange={(v) => _handleSetProps("SEVERITY", v)} defaultValue={_findValue("SEVERITY")} /></div>
                    </div>
                    <div className='d-flex justify-content-between align-items-center position-relative'>
                        <div className='w-50'>Remove From List Once Selected</div>
                        <div className='w-50'><ToggleButton size={30} onToggle={(v) => _handleSetProps("REMOVE_ON_SELECTED", JSON.stringify(v))} defaultValue={_findValue("REMOVE_ON_SELECTED") && JSON.parse(_findValue("REMOVE_ON_SELECTED"))} style='tick'></ToggleButton></div>
                    </div>
                </div>
            </div>
        </ToggleContainerComponent>
    </div>)
}
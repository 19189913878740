import React from 'react'

export default function RunTaskSectionOptionComponentWrapper({ layout, onChanged, children }) {
    switch (layout.style) {
        case 'button':
            return <div>{children}</div>
        default:
            return <select className='form-control form-control-sm' onChange={(e) => onChanged(e.target.value)}>{children}</select>
    }
}

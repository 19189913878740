import React, { useState } from 'react'
import DataLinkComponent from '../../../../../components/data_link/DataLinkComponent'
import HintWidget from '../../../../../components/ui/hint/HintWidget'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLink } from '@fortawesome/free-solid-svg-icons'

export default function PatientProfileAdminCatgoryLink({ sourceComponent, fieldId }) {
    const [show, setShow] = useState(false)
    return (
        <div className='row'>
            <div className='d-flex align-items-center' onClick={(e) => setShow(!show)}>
                <FontAwesomeIcon className='me-2 my-3' icon={faLink} />
                Link Field</div>
            {
                show
                    ?
                    <div>
                        <DataLinkComponent sourceComponent={sourceComponent} profileFieldId={fieldId} />
                    </div>
                    :
                    <></>
            }
        </div>
    )
}

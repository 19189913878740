import { useEffect, useState } from "react"

export const useDebouncing = (value, delay = 500) => {
    const [debouncingValue, setDebouncingValue] = useState(value)

    useEffect(() => {
        const timeout = setTimeout(() => {
            setDebouncingValue(value);
        }, delay)

        return () => { clearTimeout(timeout) }
    }, [value, delay])
    return debouncingValue
}


import React from 'react'
import { Headset } from 'react-bootstrap-icons'
import Authentication from '../../../../../../../../libs/api/services/Authentication'
export default function RunTaskSectionIssuesComponent({ onValidate, section, index, length, onComplete, layout }) {

    const _icon = (type) => {
        switch (type) {
            case 'headset':
                return <Headset />
            default:
                return <></>
        }
    }

    const _exceptionToHuman = (_exception, _message) => {
        switch (_exception) {
            case 'DrData.Services.Messaging.WhatsApp.Exceptions.InvalidWhatsAppAccountDetailsException':
                return <div>
                    <div>
                        Unable to send messages due to your WhatsApp account configuration being invalid or incomplete
                    </div>
                    {
                        Authentication.isClaimValid("System Admin")
                            ?
                            <button className='btn btn-sm' onClick={(e) => window.location = '/sys-admin/messaging/wa'}>Go to WhatsApp Configuration</button>
                            :
                            <div className='pt-2'>Please contact your system administrator for assistance</div>
                    }
                </div>
            case 'DrData.Services.Messaging.SMS.Exceptions.InvalidSMSAppAccountDetailsException':
            case 'There are no SMS accounts available to send this message':
                return <div>
                    <div>
                        Unable to send messages due to your SMS account configuration being invalid or incomplete
                    </div>
                    {
                        Authentication.isClaimValid("System Admin")
                            ?
                            <button className='btn btn-sm' onClick={(e) => window.location = '/sys-admin/messaging/sms'}>Go to SMS Configuration</button>
                            :
                            <div className='pt-2'>Please contact your system administrator for assistance</div>
                    }
                </div>
            case 'DrData.Services.Messaging.Email.Exceptions.InvalidEmailAccountDetailsException':
                return <div>
                    <div>
                        Unable to send messages due to your Email account configuration being invalid or incomplete
                    </div>
                    {
                        <button className='btn btn-sm' onClick={(e) => window.location = Authentication.getFacilityRole() + '/Configuration/email'}>Go to your Email Configuration</button>
                    }
                </div>
            case 'System.NotImplementedException':
                return <div>
                    <div>
                        This module has not been installed / activated.
                    </div>
                </div>
            default:
                return <div className='text-start' dangerouslySetInnerHTML={{ __html: _message }} />
        }
    }

    const _translate = (text) => {
        var _message = ''
        var _exception = ''
        if (text instanceof Object) {
            _exception = text.type
            _message = text.message
            if (text.errors) {
                //_message = '<pre>' + JSON.stringify(text.errors, null, '\t').replace('{','').replace('}','').replaceAll('[','').replaceAll(']','').replaceAll('"','') + '</pre>'
                _message = '<pre>' + JSON.stringify(text.errors).replace('{', '').replace('}', '').replaceAll('[', '').replaceAll(']', '').replaceAll('"', '') + '</pre>'
            }
            return _exceptionToHuman(_exception, _message)
        }
        return _exceptionToHuman(text, text)
    }
    return (
        <div className='bg-danger rounded p-2'>
            {layout.issues.icon ? _icon(layout.issues.icon) : <></>}
            {_translate(layout.issues.issue)}
        </div >
    )
}

import { faClipboardQuestion, faUser } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useContext, useEffect, useState } from 'react'
import { Clipboard2 } from 'react-bootstrap-icons'
import { Link, NavLink, useParams } from 'react-router-dom'
import Authentication from '../../../../libs/api/services/Authentication'
import Dashboards from '../../../../libs/api/services/Dashboards'
import CommonFunctionsContext from '../../../common/CommonFunctionsProvider'
import DashboardWorkFlowCard from '../../dashboards/Widgets/DashboardWorkFlowCard'
import PatientContext from './PatientProvider'

export default function PatientProfileDashboard({ type }) {
    const { setSectionInfo } = useContext(CommonFunctionsContext)
    const { activePatient } = useContext(PatientContext)
    const [pid, setPid] = useState()
    const { id } = useParams()
    const [wokFlowCards, setWokFlowCards] = useState([])

    useEffect(() => {
        if (id) setPid(id)
        else if (activePatient) setPid(activePatient.id)
    }, [id])

    const _loadWorkFlowCards = () => {
        Dashboards
            .GetDashboardWorkflowCards(type)
            .then((r) => {
                var cards = []
                for (const c of r)
                    cards.push(<DashboardWorkFlowCard {...c} patient={pid} />)

                setWokFlowCards(cards)
            });
    }
    useEffect(() => {
        if (pid) {
            setSectionInfo(`Managing patient`);
            _loadWorkFlowCards()
        }
    }, [pid])

    useEffect(() => {
        if (activePatient)
            setSectionInfo(`Managing patient ${activePatient.userProfile.firstName} ${activePatient.userProfile.lastName} at ${Authentication.getFacilityName()}`);
        else
            setSectionInfo(`Managing patient at ${Authentication.getFacilityName()}`);

    }, [activePatient])
    return (
        <div className='p-3'>
            {/* <h1 className='ms-2 text-center'>Quick Links</h1> */}
            <div className='d-flex flex-row align-items-evenly justify-content-between'>
                <NavLink
                    tag={Link}
                    key={'ust'}
                    to={'/' + Authentication.getFacilityRole() + '/Patient/' + pid + '/'} className='w-100 m-2 text-center bg-secondary-container rounded p-2' style={{ cursor: 'pointer' }}>
                    <div>
                        <FontAwesomeIcon className='m-3' size='3x' icon={faUser} />
                    </div>
                    <b>Patient Profile Information</b>
                    <p className='p-2'>Manage patient profile information</p>
                </NavLink>
                <div className='w-100 m-2'></div>
                <div className='w-100 m-2'></div>
            </div>
            <div className=' d-flex flex-row align-items-evenly justify-content-between'>
                <NavLink
                    tag={Link}
                    key={'ust'}
                    to={'/' + Authentication.getFacilityRole() + '/Patient/' + pid + '/Questionnaires'} className='w-100 m-2 text-center bg-secondary-container rounded  p-2' style={{ cursor: 'pointer' }}>
                    <div>
                        <FontAwesomeIcon className='m-3' size='3x' icon={faClipboardQuestion} />
                    </div>
                    <b>Patient Questionnaires</b>
                    <p className='p-2'>View / Assign Questionnaires to patient</p>
                </NavLink>
                <NavLink
                    tag={Link}
                    key={'ust'}
                    to={'/' + Authentication.getFacilityRole() + '/Patient/' + pid + '/Forms'} className='w-100 m-2 text-center bg-secondary-container rounded p-2' style={{ cursor: 'pointer' }}>
                    <div>
                        <Clipboard2 className='m-3' size='50px' />
                    </div>
                    <b>Patient Forms</b>
                    <p className='p-2'>View / Complete patient forms</p>
                </NavLink>
                <div className='w-100 m-2'></div>
            </div>
            <div className='row'>
                {wokFlowCards}
            </div>
        </div>
    )
}

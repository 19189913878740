import React from 'react';
import { UiChecks } from 'react-bootstrap-icons';
import { TriggerTypeMapping } from '../../../../Config/TriggerTypeMapping';

export default function QuickFlowTriggerItem({ trigger, onChange }) {

  const _translate = (t) => {
    if (t === 'Quick link item on patient profile')
      return 'Selected on the patient profiles quick tasks bar'
    if (t === 'When completed')
      return 'A questionnaire or form is completed'
    return t;
  }

  const _translateProps = (e, p) => {
    try {
      if (e === 'questionnaire_time_lapsed') {
        const _cats = JSON.parse(p.value)
        var _r = '';
        if (_cats.days > 0) _r += _cats.days + ((_cats.days == 1) ? ' day ' : ' days ')
        if (_cats.hours > 0) _r += _cats.hours + ((_cats.hours == 1) ? ' hour ' : ' hours ')
        if (_cats.mins > 0) _r += (_cats.days > 0 || _cats.hours > 0 ? 'and ' : '') + _cats.mins + ((_cats.hours == 1) ? ' minute ' : ' minutes ')
        return _r
      }
      if (e === 'message_filter_options') {
        const _cats = JSON.parse(p.value)
        var _r = '';
        if (_cats.messageTypes.length > 0) _r += ' via ' + _cats.messageTypes.join(' or ')
        if (Object.keys(_cats.receivedFrom).length > 0) _r += ' from ' + Object.keys(_cats.receivedFrom).join(' or ')
        if (_cats.subject) _r += ' with the subject containing ' + _cats.subject
        return _r
      }
      if (e === 'PATIENT_PROFILE_QUICK_TASK') {
        const _cats = JSON.parse(p.value)
        var _ret = []
        for (const c of _cats) {
          _ret.push(c.groupNme)
        }
        return _ret.join(',')
      }
      if (e === 'DASHBOARD_CARD') {
        const _cats = JSON.parse(p.value)
        return _cats.dashboardLinkDescription
        // var _ret = []
        // for (const c of _cats) {
        //   _ret.push(c.groupNme)
        // }
        // return _ret.join(','){"listDetails":{"group":"1","item":"1","status":"2"},"todo_timelapsed_mins":"1","todo_timelapsed_hours":"1"}
      }
      if (e === 'TODO_LIST_INCOMPLETE_FOR_TRIGGER') {
        const _cats = JSON.parse(p.value)
        var _r = ''
        console.log(_cats)
        if (_cats.todo_timelapsed_days > 0) _r += _cats.todo_timelapsed_days + ((_cats.todo_timelapsed_days == 1) ? ' day ' : ' days ')
        if (_cats.todo_timelapsed_hours > 0) _r += _cats.todo_timelapsed_hours + ((_cats.todo_timelapsed_hours == 1) ? ' hour ' : ' hours ')
        if (_cats.todo_timelapsed_mins > 0) _r += (_cats.todo_timelapsed_days > 0 || _cats.todo_timelapsed_hours > 0 ? 'and ' : '') + _cats.todo_timelapsed_mins + ((_cats.todo_timelapsed_hours == 1) ? ' minute ' : ' minutes ')
        return _r
        // var _ret = []
        // for (const c of _cats) {
        //   _ret.push(c.groupNme)
        // }
        // return _ret.join(',')
      }
      if (e === 'CRON') {
        const _cats = JSON.parse(p.value)
        var res = 'Trigger '
        if (_cats.yearOccourance)
          res += _cats.yearOccourance + ' '
        if (_cats.monthOccourance)
          res += _cats.monthOccourance + ' '
        if (_cats.dayOccourance)
          res += _cats.dayOccourance + ' '
        if (_cats.dayOccourance === "Specific Day" && _cats.dayWhen)
          res += _cats.dayType + ' ' + _cats.dayWhen + ' '
        else if (_cats.dayOccourance)
          res += _cats.dayOccourance + ' '
        if (_cats.hourWhen)
          res += _cats.hourWhen
        if (_cats.minuteWhen)
          res += ':' + _cats.minuteWhen + ' '
        return res
        // var _ret = []
        // for (const c of _cats) {
        //   _ret.push(c.groupNme)
        // }
        // return _ret.join(',')
      }
      if (
        e === 'QUESIONNAIRE_COMPLETE' ||
        e === 'QUESIONNAIRE_STARTED' ||
        e === 'QUESIONNAIRE_ALERT_TRIGGERED' ||
        e === 'QUESIONNAIRE_INCOMPLETE_FOR_A_PERIOD' ||
        e === 'questionnaire_incomplete_for_x' ||
        e === 'questionnaire_alert'
      ) {
        const _cats = JSON.parse(p.value)
        var _ret = []
        for (const c of _cats) {
          if (c.id === -1)
            _ret.push('Any')
          else
            _ret.push(c.name)
        }
        return _ret.join(',')
      }
    } catch (e) { }
  }

  const _replacePlaceHolders = (e, p) => {
    // var ret = "";
    var ret = e;
    // For later use with more complex replacements
    // var isPlaceholder = false
    // var propName = ""
    // var props = {}
    /// Get all properties into an asc array
    for (const a of p) {
      var vs = JSON.parse(a.value)
      for (const o of Object.keys(vs)) {

        //   // props[a.property + "." + o] = vs[o]
        try {
          ret = ret.replaceAll(`{${a.property}.${o}}`, String(vs[o]).toLowerCase())
        } catch (ex) {
          ret = e
        }
      }
    }
    return ret
    // For later use if need be for more complex placeholders
    // like conditional placeholders eg: a.b?all = "and all" || a.n?specific "{a.listdetails}"
    // for (var x = 0; x < e.length; x++) {
    //   const c = e.substring(x, x + 1)
    //   if (c === "{") {
    //     // Start track placeholder
    //     isPlaceholder = true
    //   }
    //   else if (c === "}") {
    //     // Stop track placeholder
    //     isPlaceholder = false
    //     // propName = ""
    //   }
    //   else if (isPlaceholder) {
    //     // ret += "|" + c
    //     propName += c
    //   } else {
    //     ret += c
    //   }
    // }
    // return ret + `(${propName})`
  }

  const _toHrDescription = (e, p) => {

    if (e === 'PATIENT_PROFILE_QUICK_TASK')
      return 'Selected on the patient profiles ' + _translateProps(e, p.find(p => p.property === 'categories')) + ' screen on the quick tasks bar'
    if (e === 'QUESIONNAIRE_STARTED')
      return _translateProps(e, p.find(p => p.property === 'questionnaire_started')) + ' has been started '
    if (e === 'QUESIONNAIRE_COMPLETE')
      return _translateProps(e, p.find(p => p.property === 'questionnaire_complete')) + ' is completed '
    if (e === 'QUESIONNAIRE_ALERT_TRIGGERED')
      return 'An alert was triggered on ' + _translateProps(e, p.find(p => p.property === 'questionnaire_alert'))
    if (e === 'QUESIONNAIRE_INCOMPLETE_FOR_A_PERIOD')
      return _translateProps(e, p.find(p => p.property === 'questionnaire_incomplete_for_x')) + ' has not been completed for ' + _translateProps('questionnaire_time_lapsed', p.find(p => p.property === 'questionnaire_time_lapsed'))
    if (e === 'MESSAGE_RECEIVED')
      return 'A message has been received ' + _translateProps('dashboard_card', p.find(p => p.property === 'message_filter_options'))
    if (e === 'DASHBOARD_CARD')
      return 'The ' + _translateProps('DASHBOARD_CARD', p.find(p => p.property === 'dashboard_card')) + ' link has been clicked on a dashboard'
    if (e === 'CRON')
      return _translateProps('CRON', p.find(p => p.property === 'cron'))

    if (e === 'TODO_LIST_INCOMPLETE_FOR_TRIGGER')
      return 'To Do list incomplete for ' + _translateProps('TODO_LIST_INCOMPLETE_FOR_TRIGGER', p.find(p => p.property === 'to_do_list_incomplete_for_x'))
    if (e === 'TODO_LIST_REASSIGNED_TRIGGER') return 'To Do list has been re-assigned'
    if (e === 'TODO_LIST_STATUS_CHANGE_TRIGGER') return 'Status changed on a To Do list'
    if (e === 'TODO_LIST_STARTED_TRIGGER') return 'To Do list has been started'

    try {
      if (TriggerTypeMapping[e])
        return _replacePlaceHolders(TriggerTypeMapping[e].description, p)
      else return e
    } catch (e) {
      return e
    }
  }


  return (
    <div className='d-flex'>
      <div className='flex-grow-1'>
        <div><UiChecks /> {_toHrDescription(trigger.type.key, trigger.properties)}</div>
      </div>
      <div className='flex-shrink-1'>
        {/* <button className='btn btn-xs'>Change</button> */}
      </div>
    </div>
  )
}

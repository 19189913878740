import React, { useContext, useEffect, useRef, useState } from 'react'
import ErrorDialog from '../../../../components/dialogs/ErrorDialog'
import GeneralModal from '../../../../components/dialogs/GeneralModal'
import DateTimePickerFunctions from '../../../../components/ui/calendar/date_picket/DateTimePickerComponents/DateTimePickerFunctions'
import CalendarSync from '../../../../libs/api/services/CalendarSync'
import Calendars from '../../../../libs/api/services/Calendars'
import Patient from '../../../../libs/api/services/Patient'
import CommonFunctionsContext from '../../../common/CommonFunctionsProvider'
import CalendarGenerics from '../CalendarGenerics'
import ModalContainer from './modal/ModalContainer'

const CalendarContext = React.createContext()

export const CalendarProvider = ({ filterMode, calendarType, patient, children }) => {
    const { setSectionInfo } = useContext(CommonFunctionsContext)
    const [filters, setFilters] = useState({ year: new Date().getFullYear(), month: new Date().getMonth(), day: new Date().getDate(), weekOfYear: (CalendarGenerics.GetWeekNumber(new Date())), view: 'Week', calendar: { id: 0 }, patient: patient })

    const [activePopup, setActivePopup] = useState()

    const [error, setError] = useState()
    const [loading, setLoading] = useState(false)
    const [events, setEvents] = useState([])
    const [modelDetails, setModalDetails] = useState([])
    const [cachedEventByDate, setCachedEventByDate] = useState([])
    const [additionalContent, setAdditionalContent] = useState({ show: false, content: [] })

    // Updates 15 min timeslot intervals
    const [actieveTimeSlot, setActiveTimeSlot] = useState('00:00')
    const activeTSRef = useRef()

    const _hendleGetTimeNow = () => {
        setActiveTimeSlot(CalendarGenerics.clock())
    }

    useEffect(() => {
        // Init on load
        _hendleGetTimeNow()
        // Start timer
        activeTSRef.current = setInterval(_hendleGetTimeNow, 1000 * 20)
        if (filters.calendar && filters.calendar.id !== 0) _loadEntries()
        return () => { clearInterval(activeTSRef.current) }
    }, [filters.calendar])

    const _cacheDays = (result) => {
        var _evtsDay = []
        // Cache days
        setCachedEventByDate([])
        for (const evt of result)
            try {
                // const _evDate = new Date(evt.startDateTime).toLocaleString().replaceAll('/', '-').substring(0, 10)
                const _evDate = DateTimePickerFunctions.LocalISO(new Date(evt.startDateTime)).substring(0, 10)
                if (Object.keys(_evtsDay).includes(_evDate))
                    _evtsDay[_evDate].events.push(evt)
                else
                    _evtsDay[_evDate] = { events: [evt] }

                setCachedEventByDate(_evtsDay)
            } catch (e) {
                // Invalid date
                console.error('Cache days', e)
            }
    }
    const _loadEntries = async () => {
        setLoading(true)
        if (filters.calendar.provider)
            Calendars
                .ListExternalCalendarEntries(filters.calendar.id)
                .then((_result) => {
                    _cacheDays(_result)
                    setEvents(_result)
                    setLoading(false)
                }).catch(() => setLoading(false))
        else
            Calendars
                .ListCalendarEntries(filters.calendar.id)
                .then((_result) => {
                    _cacheDays(_result)
                    setEvents(_result)
                    setLoading(false)
                }).catch(() => setLoading(false))
        // const _result = SampleCalendarData
        // _cacheDays(_result)
        // setEvents(_result)
    }

    /*
        Optimization suggestion : Load toISOString into hash
    */
    const _findEventForDay = async (date) => {
        var _evtsDay = []
        // Works but rather use cached version
        // for (const evt of events)
        //     if (CalendarGenerics.isSameDay(date, new Date(evt.startDateTime)))
        //         _evtsDay.push(evt)
        // DateTimePickerFunctions.LocalISO(new Date(evt.startDateTime))
        try {
            return cachedEventByDate[new Date(date).toISOString().substring(0, 10)].events
        } catch (e) {
            // Couldn't find events for day
            return []
        }
    }

    // Uses filter date
    const _findEventForWeek = async () => {
        var _evtsDay = []
        // Get WOY Dates
        // CalendarGenerics.getWOY()
    }

    const _handleSetFilter = async (f) => {
        // Realign filters on changed
        if (Object.keys(f).includes('weekOfYear'))
            f = { ...f, month: CalendarGenerics.GetMonthFromWOY(filters.year, f.weekOfYear - 1) }
        else if (Object.keys(f).includes('month'))
            f = { ...f, weekOfYear: CalendarGenerics.GetFirstWeekInMonth(filters.year, f.month) + 1 }

        setFilters({ ...filters, ...f })
    }

    const _showModal = async (v, d, e) => {
        if (calendarType === undefined || calendarType === 'Patient') return;
        setModalDetails({ visible: true, mode: v, date: d, events: await _findEventForDay(d), event: e })
    }

    const _handleSetDate = (d, v, e) => {
        setFilters({
            ...filters,
            year: d.getFullYear(),
            month: d.getMonth(),
            day: d.getDate(),
            view: v ? v : filters.view
        })
        // if (!filterMode || filterMode !== 'Patient') _showModal(v, d)
        if (v) _showModal(v, d, e)
    }

    const _handleCalendarOptions = async () => await Calendars.ListCalendarEvents()

    const _handleCreateCalendarEntry = async (evt) => {
        console.log(evt)
        evt.calendar = filters.calendar
        await Calendars.CreateCalendarEntry(evt).catch((e) => setError(e))
        _loadEntries()
    }

    // const _handleSetCalendar = (userId) => {

    //     Calendars
    //         .GetCalendar(userId)
    //         .then((r) => setFilters({
    //             ...filters, calendar: r,
    //             patient: userId
    //         }))
    // }

    const _handleSetCalendar = (externalCalendar) => {
        console.log('............externalCalendar', externalCalendar)
        if (externalCalendar.calendarUser) {
            // Set heading
            setSectionInfo(`${externalCalendar.calendarUser.firstName} ${externalCalendar.calendarUser.lastName} calendar`)
        }
        if (externalCalendar.provider === "Dr Data User Share") {
            Calendars
                .GetCalendar(externalCalendar.calendarUser.id)
                .then((r) => setFilters({
                    ...filters, calendar: r,
                    patient: externalCalendar.calendarUser.id
                }))
        } else { // Linked
            setFilters({
                ...filters, calendar: externalCalendar,
                patient: externalCalendar.calendarUser ? externalCalendar.calendarUser.id : undefined
            })
        }
    }
    const _handleGetPatient = async () => {
        if (filters.patient)
            if (!isNaN(filters.patient)) {
                return filters.patient
            }
            else if (typeof filters.patient === 'object') {
                return filters.patient
            }
            else {
                // const res = await Patient.GetById(filters.patient)
                return Patient.GetById(filters.patient)
            }
    }
    /**
     * 
     * Calendar Sync / External Calendars
     */
    const _handleShowExternalCalendars = () => {

        setModalDetails({ visible: true, mode: 'External Calendars', date: undefined, events: undefined })
    }

    const _handleShowExtendedModal = (c) => {
        setAdditionalContent(c)
    }

    const _handleSync = async () => {
        if (filters.calendar.provider) {
            await CalendarSync
                .SyncExternalCalendar(filters.calendar.id, {
                    startDate: CalendarGenerics.getWOYArray(filters.year, filters.month, filters.weekOfYear)[0],
                    endDate: CalendarGenerics.getWOYArray(filters.year, filters.month, filters.weekOfYear)[6]
                })
            _loadEntries()
        }
    }

    return (
        <>
            <ErrorDialog error={error} humanize={true} onClose={() => setError()} />
            <CalendarContext.Provider
                value={{
                    setFilter: (f) => _handleSetFilter(f),
                    findEventForDate: (date) => _findEventForDay(date),
                    setDate: (d, v, e) => _handleSetDate(d, v, e),
                    actieveTimeSlot: actieveTimeSlot,
                    events: events,
                    filters: filters,
                    loading: loading,
                    calendarOptions: _handleCalendarOptions,
                    CreateCalendarEntry: _handleCreateCalendarEntry,
                    setCalendar: _handleSetCalendar,
                    patient: _handleGetPatient(),
                    setError: setError,
                    setPatient: (p) => setFilters({ ...filters, patient: p }),
                    showExternalCalendars: () => _handleShowExternalCalendars(),
                    showExtended: (c) => _handleShowExtendedModal(c),
                    forceLoad: () => _loadEntries(),
                    triggerSync: () => _handleSync(),
                    isSyncable: filters.calendar && filters.calendar.provider !== undefined,
                    activePopup: activePopup,
                    setActivePopup: (p) => setActivePopup(p)
                }}
            >
                <ModalContainer calendarType={calendarType} details={modelDetails} setDetails={setModalDetails} />
                <GeneralModal
                    onClose={() => setAdditionalContent({ content: [], show: false })}
                    show={additionalContent.show}
                    {...additionalContent}
                >{additionalContent.content}</GeneralModal>
                {children}
            </CalendarContext.Provider>
        </>
    )
}

export default CalendarContext

export const PMBCODES =
    [
        { PMBCode: '182T', description: 'Abuse or dependence on Psychoactive substance, including alcohol', treatmentManagement: 'Hospital-based management up to 3 weeks/year' },
        { PMBCode: '910T', description: 'Acute delusional mood, anxiety, personality, perception disorders and organic mental disorder caused by drugs', treatmentManagement: 'Hospital-based management up to 3 days' },
        { PMBCode: '901T', description: 'Acute stress disorder accompanied by recent significant trauma, including physical or sexual abuse', treatmentManagement: 'Hospital admission for psychotherapy / counselling up to 3 days, or up to 12 outpatient psychotherapy / counselling contacts' },
        { PMBCode: '908T', description: 'Anorexia Nervosa and Bulimia Nervosa', treatmentManagement: 'Hospital-based management up to 3 weeks/year or minimum of 15 outpatient contacts per year' },
        { PMBCode: '184T', description: 'Brief reactive psychosis', treatmentManagement: 'Hospital-based management up to 3 weeks/year' },
        { PMBCode: '902T', description: 'Major affective disorders, including unipolar and bipolar depression', treatmentManagement: 'Hospital-based management up to 3 weeks/year (including inpatient electro-convulsive therapy and inpatient psychotherapy) or outpatient psychotherapy of up to 15 contacts' },
        { PMBCode: '907T', description: 'Schizophrenic and paranoid delusional disorders', treatmentManagement: 'Hospital-based management up to 3 weeks/year' },
        { PMBCode: '909T', description: 'Treatable dementia', treatmentManagement: 'Admission for initial diagnosis; management  of acute psychotic symptoms - up to 1 week' },
        { PMBCode: '341A', description: 'Basal ganglia, extra-pyramidal disorders; other dystonias  NOS', treatmentManagement: 'Initial diagnosis; initiation of medical management' },
        { PMBCode: '902A', description: 'Epilepsy (status epilepticus, initial diagnosis, candidate for neurosurgery)', treatmentManagement: 'Medical management; ventilation; neurosurgery' },
        { PMBCode: '910T', description: 'Alcohol withdrawal delirium; alcohol intoxication delirium', treatmentManagement: 'Hospital-based management up to 3 days leading to rehabilitation' },
        { PMBCode: '910T', description: 'Delirium: Amphetamine, Cocaine, or other psychoactive substance', treatmentManagement: 'Hospital-based management up to 3 days' },
    ]
import React from 'react'
import { Wizard } from '@rootbranch/wizard';
import { useParams } from 'react-router-dom'

export default function UISectionLayout() {
    const parms = useParams()
    return <Wizard url={{
        url: 'style/layout/' + parms.id,
        method: 'GET',
        body: null,
        encryption: {
            key: '',
            method: 'aes256'
        }
    }} />

}

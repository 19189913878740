import React, { useContext, useEffect, useState } from 'react'
import WorkFlowConfigurationContext from '../../../workflow/configuration/WorkFlowConfigurationProvider'
import ManageTaskContainer from '../../../workflow/configuration/facility/wizard/Commons/ManageTaskContainer'

export default function LogPatientHistoryEventTaskLineItem({ onChanged, task, facility, user, onDelete, onSetProperty, config, errors, values, setValue }) {
    const PROP_NAME = "LOG_PATIENT_HISTORY"
    const _handleCreateName = (P) => {
        var name = 'Log to patients history'
        if (P.event)
            name = 'Log ' + P.event + ' to patients history'
        return name
    }
    const [props, setProps] = useState({})
    const { setTaskProperty, loadTaskProperties } = useContext(WorkFlowConfigurationContext)

    const _handleSetProperty = (k, value) => //{ console.log('Set Props'); setProps({ ...props, [k]: value }) }
        setTaskProperty(props, _handleCreateName({ ...props, [k]: value }), task, PROP_NAME, { ...props, [k]: value })
            .then((r) => setProps({ ...props, [k]: value }))

    useEffect(() => { _handleLoadProps() }, [])

    const _handleLoadProps = async () =>
        setProps(await loadTaskProperties(task, PROP_NAME))

    return (<ManageTaskContainer Task={task} onDelete={onDelete}>
        <b>Event Category </b>
        <div>
            <input
                defaultValue={props.category}
                onChange={(e) => _handleSetProperty('category', e.target.value)} className='form-control form-control-sm' placeholder='Event Category' />
        </div>
        <b>Event Description </b>
        <div>
            <input
                defaultValue={props.event}
                onChange={(e) => _handleSetProperty('event', e.target.value)} className='form-control form-control-sm' placeholder='Event Description' />
        </div>
    </ManageTaskContainer>
    )
}

import React, { useContext, useEffect, useState } from 'react'
import SearchComponent from '../../../../../../components/search/SearchComponent'
import Authentication from '../../../../../../libs/api/services/Authentication'
import Calendars from '../../../../../../libs/api/services/Calendars'
import Patient from '../../../../../../libs/api/services/Patient'
import CalendarContext from '../../CalendarProvider'

export default function CalendarAllSelection({ calendarType, patient }) {
    const { setCalendar, setPatient } = useContext(CalendarContext)
    const [users, setUsers] = useState([])
    const _loadUsers = () => {
        Calendars
            .ListAvaialbeCelendars()
            .then((r) => {
                setUsers(r)
                if (calendarType === "CLINICIAN" && r.length < 2)
                    try { setCalendar(r.find(d => parseInt(d.calendarUser.id) === parseInt(Authentication.UserId()))) } catch (e) { }
            })
    }

    const _loadPatient = async () => {
        const _patient = await Patient.GetById(patient)
        setPatient(_patient.userProfile)
        setCalendar(_patient.userProfile.id)
    }

    useEffect(() => {
        //if (calendarType === "PATIENT" || calendarType === "CLINICIAN") {
        if (calendarType === "PATIENT") {
            setCalendar(Authentication.UserId())
        }
        else if (calendarType === "CLINICIAN_PATIENT" || calendarType === "ADMIN_PATIENT") {
            // setCalendar(Authentication.UserId())
            _loadPatient(patient)
        } else {
            _loadUsers()
        }

    }, [])
    // CLINICIAN_PATIENT
    if (calendarType === "CLINICIAN_PATIENT" || calendarType === "PATIENT" || (calendarType === "CLINICIAN" && users.length < 2)) return <></>
    return (
        <div className='bg-secondary-container p-2'>
            <label><b>Calender</b></label>
            <SearchComponent
                focusSearch={true}
                onSelected={setCalendar}
                details={{
                    data: users,
                    options: {
                        returns: 'object',
                        reRefKey: 'id',
                        template: [{
                            refKey: 'name',
                            descr: '',
                        }]
                    }
                }} />
        </div>
    )
}

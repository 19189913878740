import React from 'react'
import { SectionInterface } from './interfaces/SectionsInterface'
import Layout from './Layout'
import { ActionInterface } from './interfaces/ActionInterface'

type Props = {
  flow?: 'Wizard' | 'Form' | 'ConfigForm' | undefined
  section: SectionInterface
  onClick: (section: string, name: string) => void
  onChange: (section: string, name: string, value: any) => void
  onLoadData: (s: string, n: string) => Promise<any>
  variables: Map<string, any>
  actionRunner: (s: string, n: string, v?: string, a?: ActionInterface[]) => Promise<any>
}

const Section = ({ section, variables, onClick, onChange, onLoadData, actionRunner }: Props) => {
  return (
    <div key={section.name} className={'.section '.concat(section.grid!)}>
      <Layout
        key={'Layout-' + section.name}
        section={section.name}
        layouts={section.layout}
        onChange={onChange}
        onClick={onClick}
        onLoadData={onLoadData}
        actionRunner={actionRunner}
        variables={variables}
      ></Layout>
    </div>
  )
}
export default Section

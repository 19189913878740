import React from 'react'

export default function CronMinuteOptions({ properties, setProperties }) {
    return (
        <>
            <div className='col-3'>
                <select
                    defaultValue={properties && properties.minuteOccourance}
                    onChange={(e) => setProperties({ ...properties, minuteOccourance: e.target.value })
                    }
                    className='form-control form-control-sm'><option></option>
                    <option selected={properties.minuteOccourance === 'Every Minute'}>Every Minute</option>
                    <option selected={properties.minuteOccourance === 'Specific Minute'}>Specific Minute</option>
                </select>
            </div>
            <div className='col-3'>
                {
                    properties.minuteOccourance === 'Specific Minute'
                        ?
                        <input
                            defaultValue={properties && properties.minuteWhen}
                            onChange={(e) => setProperties({ ...properties, minuteWhen: e.target.value })}
                            className='flex-grow-1 form-control form-control-sm' type='number'
                        />
                        : <></>
                }

            </div>
        </>
    )
}

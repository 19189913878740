import React, { useContext, useEffect, useState } from 'react';
import WorkFlowConfigurationContext from '../../../workflow/configuration/WorkFlowConfigurationProvider';
import ToDoSelector from './Components/ToDoSelector';

export default function EventTriggerToDoListReAssigned({ link, type, values, eventId, filterTrigger }) {
    const PROPERTY_TYPE = 'to_do_list_re_assigned';
    const { saveProperties, loadProperties } = useContext(WorkFlowConfigurationContext)

    const [properties, setProperties] = useState()
    
    useEffect(() => {
        const _load = async () => setProperties(await loadProperties(PROPERTY_TYPE, link))
        _load()
    }, [])
    useEffect(() => { if (properties) saveProperties(PROPERTY_TYPE, link, properties) }, [properties])

    return (
        <div>
            <ToDoSelector
                defaultValue={properties && properties.listDetails}
                onChange={(e) => setProperties({ ...properties, listDetails: e })}
            />
        </div>
    )
}

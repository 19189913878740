import React, { useEffect, useState } from 'react'
import ErrorDialog from '../../../../../components/dialogs/ErrorDialog'
import InputWithKeyFunctions from '../../../../../components/inputs/InputWithKeyFunctions'
import SearchComponent from '../../../../../components/search/SearchComponent'
import Calendars from '../../../../../libs/api/services/Calendars'

export default function CalendarSelectorComponent({ onSelected, filterTrigger, allowAdd, onAdd, placeholder, defaultValue }) {
    const [calendarEvents, setCalendarEvents] = useState([])
    const [error, setError] = useState()
    const [mode, setMode] = useState()
    const [value, setValue] = useState()

    useEffect(() => { _handleLoadEvents() }, [])
    const _handleLoadEvents = () => {
        Calendars
            .ListCalendarEvents()
            .then((r) =>
                setCalendarEvents(r)
            ).catch(() => { })
    }

    const _hanleAdd = (v) => {
        setMode('Add')
        setValue(v)
    }

    const _handleOnCreate = () => {
        Calendars
            .CreateCalendarEvents({ EventName: value })
            .then((r) => {
                _handleLoadEvents()
                if (onAdd) onAdd(r)
                // onSelected(r)
                setMode()
            }).catch((e) => setError(e))
    }

    return (
        <>
            <ErrorDialog error={error} humanize={true} />
            {
                mode === 'Add'
                    ?
                    <div className='d-flex'>
                        <InputWithKeyFunctions
                            onChange={(v) => setValue(v)}
                            // onCancel={() => setMode()}
                            onSubmit={() => _handleOnCreate()}
                            selected={true}
                            className={'form-control form-control-sm'}
                            defaultValue={value} />
                        <button
                            onClick={() => setMode()}
                            className='btn btn-xs'>Cancel</button>
                        <button
                            onClick={() => _handleOnCreate()}
                            className='btn btn-xs'>Create</button>
                    </div>
                    :
                    <SearchComponent
                        onSelected={(r) => onSelected(r)}
                        defaultValue={defaultValue}
                        placeholder={placeholder ? placeholder : 'Search / Add a celendar event'}
                        details={{
                            data: calendarEvents,
                            options: {
                                returns: 'object',
                                onAdd: !allowAdd ? undefined : {
                                    action: (v) => { _hanleAdd(v) },
                                    text: 'Add a new event'
                                },
                                reRefKey: 'id',
                                template: [{
                                    refKey: 'eventName',
                                    descr: '',
                                }]
                            }
                        }} />
            }
        </>
    )
}

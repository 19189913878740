import React, { useContext } from 'react'
import { ClockFill, EyeSlashFill, Person, Trash2 } from 'react-bootstrap-icons'
import { ICONS } from '../../../../../../components/icons/GenericIconPicker'
import { PRIORITIES } from '../../../../../../components/priority/PriorityPicker'
import NotesContext from './NotesProvider'

export default function NoteLineItem({ hideRemove, note }) {
    const { removeNote } = useContext(NotesContext)
    return (
        <div
            className='rounded p-1'
            style={{ background: note.colour ? note.colour : 'transparent', color: note.colour && note.colour !== 'transparent' ? '#fff' : '#777' }}
        >
            <div className='d-flex justify-content-end w-100 ' style={{ fontSize: '1rem' }}>
                <div> {note.isPrivate ? <div className='d-flex align-items-center ' ><i><EyeSlashFill className='me-1' /></i></div> : <></>}</div>
                <div className='ps-2'>{note.icons ? ICONS.find(i => i.name === JSON.parse(note.icons)[0]).icon : ''}</div>
                <div className='ps-2'>{note.priority ? PRIORITIES.find(i => i.proprity === note.priority).icon : ''}</div>
                {!hideRemove && <div className='ps-2'><Trash2 onClick={() => removeNote(note)} /> </div>}
            </div>
            <div
                className='d-flex justify-content-between align-items-center'>

                <div className='d-flex justify-content-between w-100'>
                    <div><Person /> {note.createdUser && `${note.createdUser.firstName} ${note.createdUser.lastName}`}</div>
                    <div><ClockFill /> {note.createdDate && note.createdDate.substring(0, 16)}</div>
                </div>
                <div>
                    {/* <Trash2 size={30} onClick={() => removeNote(note)} /> */}
                </div>
            </div>
            <div className=''>
                {note.note}
            </div>
        </div>
    )
}

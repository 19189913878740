import React, { useEffect, useState } from 'react'
import SearchComponent from '../../../../../components/search/SearchComponent'
import Authentication from '../../../../../libs/api/services/Authentication'
import MessageTemple from '../../../../../libs/api/services/MessageTemple'
// import SearchComponent from '../../../../../../../components/search/SearchComponent'
// import Authentication from '../../../../../../../libs/api/services/Authentication'
// import MessageTemple from '../../../../../../../libs/api/services/MessageTemple'

export default function MessagingTemplateSelectionComponent({ defaultValue, onSelected, onAdd }) {
    const [template, setTemplate] = useState()
    const [templates, setTemplates] = useState()

    useEffect(() => { _load() }, [])

    const _load = () => {
        MessageTemple
            .TemplatesFacilityOrAll(Authentication.getFacility())
            .then((r) => {
                setTemplates(r) 
            })

    }

    return (
        <div>
            {
                templates
                    ?
                    <div className='flex-grow-1'>
                        <SearchComponent
                            placeholder={'Please select the message template you would like to use'}
                            defaultValue={defaultValue}
                            focusSearch={true}
                            onSelected={onSelected}
                            details={{
                                data: templates,
                                options: {

                                    onAdd: {
                                        action: (v) => { onAdd(v) },
                                        text: 'Create new message template'
                                    },
                                    returns: 'object',
                                    reRefKey: 'id',
                                    template: [{
                                        refKey: 'name',
                                        descr: '',
                                    }]
                                }
                            }} />
                    </div>
                    : <></>
            }
        </div>
    )
}

import React, { useRef } from 'react'
import { useState } from 'react'
import { Search, XCircle } from 'react-bootstrap-icons'

export default function FacilitySearchBar({ onSearchValue }) {
    const [facility, setFacility] = useState()
    const refSearch = useRef()
    const _setValue = (value) => {
        setFacility(value)
        onSearchValue(value)
    }

    const _handleClear = () => {
        _setValue('')
        refSearch.current.value = ''
    }

    return (
        <div className="d-flex position-relative p-0 m-0">
            <input ref={refSearch} className='form-control form-control-lg' placeholder='Search facilities' value={facility} onChange={(e) => _setValue(e.target.value)} />
            {
                !(refSearch.current && refSearch.current.value !== "")
                    ?
                    <div
                        onClick={(e) => refSearch.current.select()}
                        className="d-flex align-items-center bg-buttons px-3 position-absolute rounded" style={{ right: '0px', top: '0px', bottom: '0px', fontWeight: 'bold' }}>
                        <Search />
                    </div>
                    :
                    <div
                        onClick={(e) => _handleClear()}
                        className="d-flex align-items-center bg-buttons px-3 position-absolute rounded" style={{ right: '0px', top: '0px', bottom: '0px', fontWeight: 'bold' }}>
                        <XCircle />
                    </div>
            }

        </div>

    )
}

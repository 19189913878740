import React, { useContext, useState } from 'react'
import { SiGooglecalendar } from 'react-icons/si'
import CalendarSyncContext from '../CalendarSyncProvider'
import SyncExternalCalendars from '../common/SyncExternalCalendars'
import GoogleCalendarAuthenticationCheckOAth from './GoogleCalendar/GoogleCalendarAuthenticationCheckOAth'
import GoogleCalendarDetails from './GoogleCalendar/GoogleCalendarDetails'

export default function GoogleCalendarProviderConfig({ onClose }) {
    const { setProperties, activeCalendar } = useContext(CalendarSyncContext)


    /**
     * Use cache
     */

    const [cache, setCache] = useState([])

    const _store = (k, v) => {
        var c = cache
        c[k] = v
        setCache({ ...c })
    }
    const _save = async () => {
        setProperties(cache)
        // for (const e of Object.keys(cache))
        //     await setProperties(e, cache[e])
        _clear()
    }
    const _clear = () =>
        setCache([])
    /*End Cache */

    const _handleClose = () => {
        onClose()
    }

    return (
        <div className='d-flex flex-column h-100 p-2 text-start'>
            <div className='flex-shrink-1'>
                <div className='d-flex align-items-center'>
                    <SiGooglecalendar className='m-2' size={30} />
                    <div className='flex-grow-1'>
                        Sync with a Google Calendar Account
                    </div>
                    {/* <div>{step + 1} / {STEPS.length}</div> */}
                </div>
            </div>
            <div className='flex-grow-1'>
                <GoogleCalendarDetails onChange={(k, v) => _store(k, v)} />
                {/* <GoogleCalendarAuthenticationDetails onComplete={() => { }} /> */}
                {
                    activeCalendar.id ?
                        <>
                            <GoogleCalendarAuthenticationCheckOAth onComplete={() => { }} />
                            <SyncExternalCalendars extenalCalendar={activeCalendar} />
                        </>
                        :
                        <></>
                }
            </div>
            <div className='flex-shrink-1 text-center'>
                <button onClick={_handleClose} className='btn'>Cancel</button>
                {Object.keys(cache).length > 0 ? <button onClick={() => _save()} className='btn'>Save</button> : <></>}
            </div>
        </div>
    )
}

import React from 'react'
// import VariablesContext from '../context/VariablesContext'

interface Props {
  name: string
}

const SampleComponent = (props: Props) => {
  return <div key={props.name}>This is a sample component</div>
}

export default SampleComponent

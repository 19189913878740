/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect } from 'react'
import WorkFlowConfigurationContext from '../../../../WorkFlowConfigurationProvider'
import MessageCategoryFolderComponent from '../../../components/MessageCategoryFolderComponent'
import MessageEventFolderComponent from '../../../components/MessageEventFolderComponent'

export default function NewWorkFlowComponent({ onCategorySelected, navigation, errors, setValue, values }) {
  const { category, event } = useContext(WorkFlowConfigurationContext)

  useEffect(() => { navigation([]) }, [])
  useEffect(() => { if (event) navigation([{ text: 'Next', type: 'Next', }]); else navigation([]) }, [event])

  return (
    <div>
      {
        category
          ?
          <MessageEventFolderComponent
            // values={values} 
            setValue={setValue}
          //  errors={errors} 
          // onCreateEvent={(e) => { _handleAddEvent(e) }} onChangeCategory={() =>
          // _handleSetCategory()}
          // category={category}
          />
          :
          <MessageCategoryFolderComponent
          // onSelected={(c) => _handleSetCategory(c)} 
          />
      }


    </div>
  )
}

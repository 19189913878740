import React, { useContext } from 'react'
import './Flow.css'
import { SectionInterface } from './interfaces/SectionsInterface'
import Section from './Section'
import ErrorContext from './context/ErrorContext'
import NavigationBar from './flow/NavigationBar'
import NavigationHeader from './flow/NavigationHeader'
import ActionsBar from './flow/ActionsBar'
import { ActionInterface } from './interfaces/ActionInterface'

type Props = {
  flow?: 'Wizard' | 'Form' | 'ConfigForm' | undefined
  grid: string | undefined
  template: SectionInterface[] | undefined
  onNavigate: (element: string | null) => void
  section: string
  onClick: (_section: string, name: string) => void
  onChange: (_section: string, name: string, value: any) => void
  onLoadData: (s: string, n: string) => Promise<any>
  variables: Map<string, any>
  actionRunner: (s: string, n: string, v?: string, a?: ActionInterface[]) => Promise<any>
}
const Flow = (props: Props) => {
  const { error } = useContext(ErrorContext)
  // const _handleActions = async (actions?: ActionInterface[], _variables?: Map<string, any>) => {
  //   _variables = _variables ? _variables : variables
  //   let _result = undefined
  //   for (const action of actions!)
  //     switch (action.action.toLowerCase()) {
  //       case 'api': {
  //         _result = await APIFunctions.MakeCall(
  //           APIFunctions.SetURL((action.params! as APIActionInterface).url, _variables),
  //           (action.params! as APIActionInterface).method,
  //           APIFunctions.SetBody(_variables, (action.params! as APIActionInterface).body),
  //           (action.params! as APIActionInterface).encryption,
  //         ).catch((err) => invokeError!(err.message ? err.message : err))
  //         if (_result === null) return null
  //         break
  //       }
  //       case 'alert':
  //         alert((action.params! as AlertActionInterface).message)
  //         break
  //       case 'callback': {
  //         const _body = (action.params as CallbackActionInterface).body
  //         const a = _body ? APIFunctions.SetBody(_variables, _body) : ''
  //         action.exec!(a)
  //         break
  //       }
  //       case 'authenticate': {
  //         const _request: APIActionInterface = JSON.parse(JSON.stringify(action.params!))
  //         _request.body = APIFunctions.SetBody(_variables, _request.body)
  //         _result = await AuthenticationService.login(_request).catch((err) =>
  //           invokeError!(err.message ? err.message : err),
  //         )
  //         if (_result === null || typeof _result === 'undefined') return null
  //         break
  //       }
  //       case 'redirect':
  //         window.location.assign(APIFunctions.SetURL((action.params! as RedirectInterface).url, _variables))
  //         break
  //     }
  //   return _result
  // }

  const _grid = ['Wizard']
  if (props.grid) _grid.push(...props.grid!.split(' '))
  return (
    <div className={_grid.join(' ')}>
      <div className='head'>
        <NavigationHeader sections={props.template} value={props.section} />
        <div className='error-details'>{error}</div>
      </div>
      <div className='body'>
        {props.template?.map(function (element) {
          if (props.flow === 'ConfigForm') {
            return (
              <Section
                onClick={props.onClick}
                onChange={props.onChange}
                onLoadData={props.onLoadData}
                actionRunner={props.actionRunner}
                key={element.name}
                section={element}
                flow={props.flow}
                variables={props.variables}
              ></Section>
            )
          }
          return props.section === element.name ? (
            <Section
              onClick={props.onClick}
              onChange={props.onChange}
              onLoadData={props.onLoadData}
              actionRunner={props.actionRunner}
              key={element.name}
              section={element}
              flow={props.flow}
              variables={props.variables}
            ></Section>
          ) : (
            <></>
          )
        })}
      </div>
      <div className='tail'>
        <NavigationBar
          sections={props.template}
          defaultSection={props.section}
          flow={props.flow}
          onNavigate={props.onNavigate}
        />
        <ActionsBar
          actionRunner={props.actionRunner}
          sections={props.template}
          section={props.section}
          flow={props.flow}
          onClick={props.onClick}
        />
      </div>
    </div>
  )
}

export default Flow

import { faClose, faSearch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useContext, useEffect, useRef, useState } from "react";
import { Search, XCircle } from "react-bootstrap-icons";
import { useNavigate, useParams } from "react-router-dom";
import { useClickOutside } from "../../components/Hooks/useClickOutside";
import { useDebouncing } from "../../components/Hooks/useDebouncing";
import ErrorDialog from "../../components/dialogs/ErrorDialog";
import Authentication from "../../libs/api/services/Authentication";
import Patient from "../../libs/api/services/Patient";
import StickyNavigationContext from "../../libs/providers/StickyNavigationProvider";
import './Search.css';
export default function PatientearchBar({ searchOnly }) {
    const navigate = useNavigate();
    const { id } = useParams();
    const searchBarRef = useRef()
    const searchRef = useRef()
    const [activeIndex, setActiveIndex] = useState(1);
    const [users, setUsers] = useState([]);
    const [isSearching, setIsSearching] = useState(false);
    const [details, setDetails] = useState("");
    const { canNavigate } = useContext(StickyNavigationContext)
    const [error, setError] = useState("");
    const [holdingKey, setHoldingKey] = useState()
    const [searchString, setSearchString] = useState()

    const [isFocusedRef, setIsFocusedRef] = useState(false)

    const debouncedSearch = useDebouncing(searchString, 500)
    const _handleClearSearch = () => {
        if (document.activeElement === searchRef.current) {
            searchRef.current.blur()
            setSearchString()
            setUsers([])
        }
    }

    useClickOutside(searchBarRef, () => { _handleClear(); })

    useEffect(() => {
        const _handleKeyDown = (e) => {
            switch (e.key) {
                case 'ArrowUp': setActiveIndex((activeIndex) => activeIndex > 0 ? activeIndex - 1 : 0); break;
                case 'ArrowDown': setActiveIndex((activeIndex) => activeIndex < users.length ? activeIndex + 1 : users.length); break;
                case 'Control': setHoldingKey('Ctrl'); break;
                case 'f': if (holdingKey) {
                    e.preventDefault();
                    searchRef.current.select()
                } break;
                case 'Escape': isFocusedRef && _handleClear(); break;
                case 'Enter': if (isFocusedRef && users.length > activeIndex) navigateUser(users[activeIndex].id); break;
                default: break;
            }
        }
        const _handleKeyUp = (e) => {
            setHoldingKey()
        }

        document.addEventListener('keydown', _handleKeyDown)
        document.addEventListener('keyup', _handleKeyUp)
        return () => { document.removeEventListener('keydown', _handleKeyDown) }
    }, [holdingKey, activeIndex, isFocusedRef])


    useEffect(() => {
        const search = (s) => {
            // Search API
            setIsSearching(true)
            Patient.search({
                search: s
            }, Authentication.getFacility())
                .then((result) => {
                    setUsers(result);
                    setIsSearching(false)
                    if (result.length === 1)
                        setActiveIndex(0)
                    // this.setState({
                    //     results: result
                    // })
                })
                .catch((err) => {
                    console.error(err)
                    setError(err.message)
                })
        }
        search(debouncedSearch)
    }, [debouncedSearch])



    useEffect(() => {
        if (id) {
            Patient
                .GetById(id)
                .then((res) => {
                    if (res.userProfile.firstName.length !== 36 && res.userProfile.lastName.length)
                        setDetails(res.userProfile
                            .firstName + " " + res.userProfile
                                .lastName)
                })
                .catch((err) => {
                })
        }
    }, [id]);

    const _handleClear = () => {
        setDetails('')
        searchRef.current.value = ''
        setUsers([])
        setIsFocusedRef(false)
        searchRef.current.blur()
        setSearchString()
        setIsSearching(false)
        setActiveIndex(-1)
    }

    const navigateUser = (id) => {
        canNavigate(() =>
            navigate(`/${Authentication.getFacilityRole()}/Patient/${id}/Dashboard`)
            // window.location = Authentication.getFacilityRole() + '/Patient/' + id + '/Dashboard'
        )

    }

    const _handleSetBlur = (event) => {
        if (event.relatedTarget != null && !event.currentTarget.contains(event.relatedTarget)) {
            setIsFocusedRef(false)
            return
        }
    }
    const _handleSetFocus = (event) => {
        setIsFocusedRef(true)
    }

    return (
        <div className="row">
            {
                error
                    ?
                    // <div className="alert alert-danger">{error}</div>
                    <ErrorDialog error={error} onClose={(e) => setError('')} />
                    :
                    <></>
            }
            <div
                ref={searchBarRef}
                onBlur={_handleSetBlur}
                onFocus={(e) => _handleSetFocus(e)} className={"position-relative m-0 ".concat(searchOnly ? 'col-lg-12' : ' col-lg-6')} >
                <div className="d-flex position-relative">
                    <input
                        ref={searchRef}
                        onFocus={(e) => searchRef.current.placeholder = 'Start typing patient search criteria to start searching'}
                        onBlur={(e) => searchRef.current.placeholder = 'Patient Search'}
                        onChange={(e) => setSearchString(e.target.value)} defaultValue={details} className='form-control bg-search-bar rounded-3 text-primary' placeholder='Patient Search' />
                    {
                        !(searchRef.current && searchRef.current.value !== "")
                            ?
                            <div
                                onClick={(e) => searchRef.current.select()}
                                className="d-flex align-items-center bg-buttons px-3 position-absolute rounded" style={{ right: '0px', top: '0px', bottom: '0px', fontWeight: 'bold' }}>
                                <Search />
                            </div>
                            :
                            <div
                                onClick={(e) => _handleClear()}
                                className="d-flex align-items-center bg-buttons px-3 position-absolute rounded" style={{ right: '0px', top: '0px', bottom: '0px', fontWeight: 'bold' }}>
                                <XCircle />
                            </div>
                    }
                </div>
                {
                    isFocusedRef && (isSearching || users.length > 0) ?
                        <div className={"position-absolute search-bar-result-container shadow"} >
                            <div className="top-bar">
                                <div className="flex-grow-1">
                                    &nbsp;
                                    {/* <b>All Patients</b> */}
                                </div>
                                <div>
                                    <b>
                                        <FontAwesomeIcon onClick={(e) => _handleClear([])} style={{ position: "absolute", right: 0, padding: "8px", zIndex: 99 }} fontSize={15} icon={faClose} />
                                    </b>
                                </div>
                            </div>
                            <div className="results-panel">
                                {
                                    isSearching
                                        ?
                                        // <div className='position-absolute w-100 shadow bg-search-bar rounded-3 text-primary show-slow' >
                                        <p className="text-center pt-3"><FontAwesomeIcon icon={faSearch} /> Searching</p>
                                        // </div>
                                        :
                                        users.length > 0
                                            ?
                                            <div className='bg-search-bar-result rounded-3 text-dark w-100' style={{ zIndex: 1999 }}>

                                                <ul className='position-relative'>
                                                    {
                                                        users.map((result, i) =>
                                                            <li style={{ cursor: 'pointer' }} className={`${i === activeIndex ? 'bg-primary-container' : ''}`} onClick={(e) => navigateUser(result.id)} key={result.id}>
                                                                <div>
                                                                    {
                                                                        result.profiles.map((profile) =>
                                                                            <><b>{profile.detailsType.value} </b> {profile.detail}<br /></>
                                                                        )
                                                                    }
                                                                    {result.firstName} {result.lastName}
                                                                </div>
                                                            </li>
                                                        )
                                                    }
                                                </ul>
                                            </div>
                                            :
                                            <></>
                                }
                            </div>
                        </div>
                        : <></>
                }

                {/* {
                    isSearching
                        ?
                        <div className='position-absolute w-100 shadow bg-search-bar rounded-3 text-primary show-slow' >
                            <p className="text-center pt-3"><FontAwesomeIcon icon={faSearch} /> Searching</p>
                        </div>
                        :
                        <></>
                } */}
                {/* {
                    users.length > 0
                        ?
                        <div className='bg-search-bar-result rounded-3 text-dark position-absolute w-100 shadow' style={{ zIndex: 1999 }}>
                            <FontAwesomeIcon onClick={(e) => setUsers([])} style={{ position: "absolute", right: 0, padding: "8px", zIndex: 99 }} fontSize={15} icon={faClose} />
                            <ul className='position-relative'>
                                {
                                    users.map((result) =>
                                        <li style={{ cursor: 'pointer' }} onClick={(e) => navigateUser(result.id)} key={result.id}>
                                            <div>
                                                {
                                                    result.profiles.map((profile) =>
                                                        <><b>{profile.detailsType.value} </b> {profile.detail}<br /></>
                                                    )
                                                }
                                                {result.firstName} {result.lastName}
                                            </div>
                                        </li>
                                    )
                                }
                            </ul>
                        </div>
                        :
                        <></>
                } */}
            </div>
            <div className={"col-lg-6 ".concat(searchOnly ? 'd-none' : ' col-lg-6')}>
                <button onClick={(e) =>
                    navigate(`/${Authentication.getFacilityRole()}/Patient/`)} className="btn bg-buttons">New Patient</button>
            </div>
        </div >
    );
}
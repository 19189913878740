import React, { useState } from 'react'
const StickyNavigationContext = React.createContext()

export const StickyNavigationProvider = ({ children }) => {
    const [sticky, setSticky] = useState(false)
    const [confirm, setConfirm] = useState({ show: false, description: '', title: '', yesAction: undefined, noAction: undefined })

    const _isStuck = () => {
        if (sticky) {
            onbeforeunload = (event) => { event.preventDefault(); return false; }
            setConfirm({ ...confirm, show: true })
        }
        else {
            onbeforeunload = (event) => { return; }
            // action();
        }
        return sticky
    }

    const canNavigate = (action) => {
        if (sticky) {
            onbeforeunload = (event) => { event.preventDefault(); return false; }
            setConfirm({ ...confirm, show: true, action: action })
        }
        else {
            onbeforeunload = (event) => { return; }
            action();
        }
        //return !sticky
    }

    const setPreventMessage = (title, description, yesAction, noAction) => {
        const _confirm = { ...confirm, title: title, description: description, yesAction: yesAction, noAction: noAction }
        setConfirm(_confirm)
        // setConfirm({ title: title, description: description })
    }

    const setPreventMessageAsync = async (title, description, yesAction, noAction) => {
        const _confirm = { ...confirm, async: true, title: title, description: description, yesAction: yesAction, noAction: noAction }
        setConfirm(_confirm)
        // setConfirm({ title: title, description: description })
    }

    const _handleYesAction = async (event) => {
        onbeforeunload = (event) => { return; };
        if (confirm.yesAction)
            confirm.async ? await confirm.yesAction() : confirm.yesAction();
        setConfirm({ ...confirm, show: false, async: false });
        setSticky(false); confirm.action();
    }

    const _handleNoAction = async (event) => {
        console.log('..............', confirm.noAction)
        if (confirm.noAction) {
            if (confirm.noAction === "Discard") {
                onbeforeunload = (event) => { return; }; confirm.action();
            } else {
                onbeforeunload = (event) => { return; };
                confirm.async ? await confirm.noAction() : confirm.noAction();
            }
        } setConfirm({ ...confirm, show: false })
        // onbeforeunload = (event) => { return; };
        // if (confirm.yesAction)
        //     confirm.async ? await confirm.yesAction() : confirm.yesAction();
        // setConfirm({ ...confirm, show: false, async: false });
        // setSticky(false); confirm.action();
    }
    return (
        <StickyNavigationContext.Provider
            value={{
                setSticky,
                canNavigate,
                setPreventMessage,
                setPreventMessageAsync,
                isStuck: _isStuck
            }}
        >
            {
                confirm.show ?
                    <div
                        className='position-absolute d-flex align-items-center justify-content-center'
                        style={{ left: 0, right: 0, top: 0, bottom: 0, zIndex: 1000 }}
                    >
                        <div
                            className='bg-secondary-container rounded shadow p-3 text-center'
                            style={{ maxWidth: '450px', zIndex: 102 }}
                        >
                            <h3>{confirm.title}</h3>
                            <div dangerouslySetInnerHTML={{ __html: confirm.description }} />
                            <button
                                onClick={(e) => _handleYesAction(e)}
                                className='btn'>Yes</button>
                            <button
                                onClick={(e) => _handleNoAction(e)}
                                className='btn'>No</button>
                        </div>
                    </div>
                    :
                    <></>
            }
            {children}
        </StickyNavigationContext.Provider>
    )

}

export default StickyNavigationContext
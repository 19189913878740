import React, { useEffect, useReducer, useState } from 'react'
import { useParams } from 'react-router-dom'
import Authentication from '../../../../libs/api/services/Authentication'
import Patient from '../../../../libs/api/services/Patient'
import TaskEvents from '../../../../libs/api/services/TaskEvents'
import WorkFlow from '../../../../libs/api/services/WorkFlow'

const PatientContext = React.createContext()

export const PatientProvider = ({ children }) => {
    const { id } = useParams()
    const [activePatient, setActivePatient] = useState()
    const [patientState, dispatchPatient] = useReducer(reducer, { loading: false, state: 'READY', error: '', patient: { profiles: {} }, tasks: [] })
    function reducer(state, action) {
        switch (action.type) {
            case 'INIT_PATIENT_USER_PROFILE': return { ...state, state: 'SAVING_TO_SERVER' }
            case 'INIT_PATIENT_USER_PROFILE_COMPLETE': return { ...state, state: 'READY', patient: { ...state.patient } }
            case 'INIT_PATIENT_USER_PROFILE_ERROR': return { ...state, state: 'ERROR', error: action.error }
            case 'SET_ACTIVE_PATIENT': return { ...state, state: 'READY', patient: action.patient }
            case 'SET_ACTIVE_PATIENT_ERROR': return { ...state, state: 'ERROR', error: action.error }
            //
            case 'SET_PATIENT_PROFILE_DETAIL': return { ...state, state: 'SAVING', details: { ...state.details, [action.id]: action.value } }
            case 'SET_PATIENT_PROFILE_DETAIL_SAVED': return { ...state, state: 'READY', details: { ...state.details, [action.id]: action.value } }
            case 'SET_PATIENT_PROFILE_DETAIL_ERROR': return { ...state, state: 'ERROR', error: action.error }


            case 'LIST_CATEGORY_LINKED_EVENTS': return { ...state, state: 'READY', error: action.error }
            case 'LIST_CATEGORY_LINKED_EVENTS_ERROR': return { ...state, state: 'READY', error: action.error }

            case 'TRIGGER_EVENT': return { ...state, state: 'LOADING_TRIGGERS', tasks: [{ id: -1, task: "Processing" }] }
            case 'TRIGGER_EVENT_PRE_CONFIG': return { ...state, state: 'TRIGGER', tasks: action.tasks }
            case 'TRIGGER_RESET_EVENT_PRE_CONFIG': return { ...state, state: 'TRIGGER', tasks: action.tasks }
            case 'TRIGGER_EVENT_REPONSE': return { ...state, state: 'TRIGGER', tasks: action.tasks }

            // Tasks
            case 'TRIGGER_TASK': return { ...state, state: 'TRIGGER', tasks: action.tasks }
            case 'TRIGGER_TASK_ERROR': return { ...state, state: 'TRIGGER', tasks: action.tasks }
            case 'TRIGGER_TASK_COMPLETE': return { ...state, state: 'TRIGGER', tasks: action.tasks }

            case 'CLEAR_ERRORS': return { ...state, state: 'READY', error: action.error }

            case 'SET_ERROR': return { ...state, error: action.error }
            // Link patient information
            default:
                throw Error('Functionality not available')
        }
    }

    useEffect(() => {
        const load = async () => {
            if (!activePatient || activePatient.id !== id)
                setActivePatient(await Patient.GetById(id))
        }
        if (id && isNaN(id)) {
            // Load Patient details
            load()
        } else setActivePatient()
    }, [id])

    const findTaskSetValue = (taskId, key, val) => {
        var _tasks = patientState.tasks
        for (var task of _tasks)
            if (task.id === taskId)
                task[key] = val

        return _tasks
    }

    // dispatch dispatch({ type: 'CHANGE_CATEGORY_NAME_SAVED_TO_SERVER', o }))
    const CreatePatient = (facilityId, username, password) => {
        dispatchPatient({ type: 'CREATE_PATIENT_USER', facilityId: facilityId, username: username, password: password })
        dispatchPatient({ type: 'CREATE_PATIENT_USER_TO_SERVER' })
        dispatchPatient({ type: 'CREATE_PATIENT_USER_TO_SERVER_COMPLETE' }) // Returned result
    }

    const CreatePatientProfile = (patientId, profile) => {
        dispatchPatient({ type: 'CREATE_PATIENT_USER', patientId: patientId, profile: profile })
        dispatchPatient({ type: 'CREATE_PATIENT_USER_TO_SERVER' })
        dispatchPatient({ type: 'CREATE_PATIENT_USER_TO_SERVER_COMPLETE' }) // Returned result
    }

    const SetPatientProfileDetail = async (id, value) => {
        dispatchPatient({ type: 'SET_PATIENT_PROFILE_DETAIL', id: id, value: value })
        Patient.SetPatientProfileDetail(id, value, patientState.patient.id).then((r) => {
            dispatchPatient({ type: 'INIT_PATIENT_USER_PROFILE_COMPLETE', profile: r })
        }).then((r) => {
            dispatchPatient({ type: 'SET_PATIENT_PROFILE_DETAIL_SAVED' })
        })
            .catch((e) => {
                dispatchPatient({ type: 'SET_PATIENT_PROFILE_DETAIL_ERROR', error: e.message })
            })
    }

    const InitNewUser = (facilityId) => {
        dispatchPatient({ type: 'INIT_PATIENT_USER_PROFILE' })
        Patient.InitNewPatient(facilityId).then((r) => {
            dispatchPatient({ type: 'INIT_PATIENT_USER_PROFILE_COMPLETE' })
            window.location = Authentication.getFacilityRole() + '/Patient/' + r.id;
        }).catch((e) => {
            dispatchPatient({ type: 'INIT_PATIENT_USER_PROFILE_ERROR', error: e.message })
        })
    }

    const SetActivePatient = (patientId) => {
        Patient.Find(patientId)
            .then((r) => {
                dispatchPatient({ type: 'SET_ACTIVE_PATIENT', patient: r })
            })
            .catch((e) => {
                dispatchPatient({ type: 'SET_ACTIVE_PATIENT_ERROR', error: e.message })
            })

        // SET_ACTIVE_PATIENT
    }
    // LIST_CATEGORY_LINKED_EVENTS
    const listCategoryLinkedEvents = async (categoryId) => {
        dispatchPatient({ type: 'LIST_CATEGORY_LINKED_EVENTS', error: '' })
        return await WorkFlow
            .ListEventsByTriggerGroup('Patient Profile Events')
            .catch((e) => {
                dispatchPatient({ type: 'LIST_CATEGORY_LINKED_EVENTS_ERROR', error: e.message })
            })
        // return await PatientHistoryEvent
        //     // .ListCategoryEventLinks(categoryId)
        //     .ListCategoryEventLinksGrouped(categoryId)
        //     .catch((e) => {
        //         dispatchPatient({ type: 'LIST_CATEGORY_LINKED_EVENTS_ERROR', error: e.message })
        //     })
    }

    const triggerPatientEventPreConfig = (taskId, patientId) => {
        dispatchPatient({ type: 'TRIGGER_EVENT_PRE_CONFIG', tasks: findTaskSetValue(taskId, 'status', 'Pre Confirmation') })
        // TRIGGER_EVENT_PRE_CONFIG
        //     .TriggerEvent(eventId, patientId)
        //     .then((e) => {
        //         // [{"id":"dcd2899a-742e-45fc-55f7-08dbc389e43d","taskName":"test","order":0,"isDeleted":false}]
        //         dispatchPatient({ type: 'TRIGGER_EVENT_REPONSE', tasks: e })
        //     })
    }
    const resetPatientEventPreConfig = (taskId) => {
        dispatchPatient({ type: 'TRIGGER_RESET_EVENT_PRE_CONFIG', tasks: findTaskSetValue(taskId, 'status', undefined) })
        // TRIGGER_EVENT_PRE_CONFIG
        //     .TriggerEvent(eventId, patientId)
        //     .then((e) => {
        //         // [{"id":"dcd2899a-742e-45fc-55f7-08dbc389e43d","taskName":"test","order":0,"isDeleted":false}]
        //         dispatchPatient({ type: 'TRIGGER_EVENT_REPONSE', tasks: e })
        //     })
    }

    const triggerPatientEvent = (eventId, patientId) => {
        dispatchPatient({ type: 'TRIGGER_EVENT', })
        Patient
            .TriggerEvent(eventId, patientId)
            .then((e) => {
                // [{"id":"dcd2899a-742e-45fc-55f7-08dbc389e43d","taskName":"test","order":0,"isDeleted":false}]
                dispatchPatient({ type: 'TRIGGER_EVENT_REPONSE', tasks: e })
            })
    }

    const triggerTask = (taskId, patientId, values) => {
        // dispatchPatient({ type: 'TRIGGER_EVENT', })
        // Patient
        //     .TriggerEvent(eventId, patientId)
        //     .then((e) => {
        //         // [{"id":"dcd2899a-742e-45fc-55f7-08dbc389e43d","taskName":"test","order":0,"isDeleted":false}]
        //         dispatchPatient({ type: 'TRIGGER_EVENT_REPONSE', tasks: e })
        //     })
        dispatchPatient({ type: 'TRIGGER_TASK', tasks: findTaskSetValue(taskId, 'status', 'processing') })
        TaskEvents
            .TriggerTask(taskId, patientId, values, Authentication.getFacility())
            .then((e) => {
                // [{"id":"dcd2899a-742e-45fc-55f7-08dbc389e43d","taskName":"test","order":0,"isDeleted":false}]
                // dispatchPatient({ type: 'TRIGGER_EVENT_REPONSE', tasks: e })
                dispatchPatient({ type: 'TRIGGER_TASK_COMPLETE', tasks: findTaskSetValue(taskId, 'status', e) })
            })
            .catch((e) => {
                dispatchPatient({ type: 'TRIGGER_TASK_ERROR', tasks: findTaskSetValue(taskId, 'status', e.message) })
            })
    }

    const ClearPatientErrors = () => {
        dispatchPatient({ type: 'CLEAR_ERRORS', error: '' })
    }

    const SetError = (e) => dispatchPatient({ type: 'SET_ERROR', error: e })

    return (
        <PatientContext.Provider
            value={{
                InitNewUser,
                SetActivePatient,
                dispatchPatient,
                CreatePatient,
                CreatePatientProfile,
                SetPatientProfileDetail,
                ClearPatientErrors,
                SetError,
                listCategoryLinkedEvents,
                triggerPatientEvent,
                triggerPatientEventPreConfig,
                resetPatientEventPreConfig,
                triggerTask,
                patientState,
                activePatient
            }}
        >{children}</PatientContext.Provider>
    )

}

export default PatientContext
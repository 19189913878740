import React from 'react'
import WorkFlowDocumentContainer from './Documents/WorkFlowDocumentContainer'

export default function WorkFlowDMSSection({ event, documents }) {
  return (
    <div>
      <WorkFlowDocumentContainer event={event} docs={documents} />
    </div>
  )
}

import React from 'react'
import PatientearchBar from '../search/PatientearchBar'
import { PatientProfileProvider } from '../section/patient/admin/PatientProfileProvider'
import { PatientHistoryProvider } from '../section/patient/history/PatientHistoryProvider'
import { PatientProvider } from '../section/patient/management/PatientProvider'
import { Patientayout } from './PatientLayout'


export default function PatientManagementLayout({ menu, container, breadcrums,nofilter }) {
    return (
        // TODO : Implment Provider Here
        //        Load Menu items from API
        //        Render fields for category
        <PatientProfileProvider>
            <PatientProvider>
                <PatientHistoryProvider>
                    <Patientayout nofilter menu={menu} search={<PatientearchBar />} container={container} breadcrums={breadcrums}  />
                </PatientHistoryProvider>
            </PatientProvider>
        </PatientProfileProvider>
    )
}

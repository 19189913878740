import React, { useRef, useState } from 'react'

export default function GenericFileUpload({ onConfirm }) {
    const [dragActive, setDragActive] = useState(false)
    const inputRef = useRef(null)

    // handle drag events
    const handleDrag = (e) => {
        e.preventDefault()
        e.stopPropagation()
        if (e.type === 'dragenter' || e.type === 'dragover') {
            setDragActive(true)
        } else if (e.type === 'dragleave') {
            setDragActive(false)
        } else {
            setDragActive(false)
        }
    }

    // triggers when file is selected with click
    const handleChange = (e) => {
        //* onFilesSelected(e.target.files)
        if (e.target.files && e.target.files) {
            // at least one file has been selected so do something
            // handleFiles(e.target.files);
            if (onConfirm) onConfirm(e.target.files)
            handleFileInputChange(e.target.files[0])
        }
    }
    // // triggers the input when the button is clicked
    const onButtonClick = () => {
        inputRef.current.click()
    }
    // // triggers when file is dropped
    const handleDrop = function (e) {
        e.preventDefault()
        e.stopPropagation()
        setDragActive(false)
        if (e.dataTransfer.files && e.dataTransfer.files) {
            //** onFilesSelected(e.dataTransfer.files)
            if (onConfirm) onConfirm(e.dataTransfer.files)
            // handleFileInputChange(e.dataTransfer.files[0])
            // at least one file has been dropped so do something
            // handleFiles(e.dataTransfer.files);
        }
    }

    const getBase64 = async (file) => {
        return new Promise((resolve) => {
            //   let baseURL = ''
            // Make new FileReader
            const reader = new FileReader()

            // Convert the file to base64 text
            reader.readAsDataURL(file)

            // on reader load somthing...
            reader.onload = async () => {
                // Make a fileInfo Object
                // baseURL = reader.result
                resolve(reader.result)
            }
        })
    }

    const validateFileFormat = (format) => true

    const handleFileInputChange = (file) => { }

    return (
        <div
            onDragEnter={handleDrag}
            onDragLeave={handleDrag}
            onDragOver={handleDrag}
            onDrop={handleDrop}
            onDragEnd={handleDrag}
            onDragEndCapture={handleDrag}
            onClick={(e) => onButtonClick()}
            style={{ cursor: 'pointer' }} className={'border rounded p-3 m-3 '.concat(dragActive ? 'bg-secondary-container' : '')}>
            <form
                //id='form-file-upload'
                //   onClick={(e) => onButtonClick()}
                onDragEnter={handleDrag} onSubmit={(e) => e.preventDefault()}
            >
                <input
                    ref={inputRef}
                    type='file'
                    id='input-file-upload' multiple={true}
                    onChange={(e) => handleChange(e)}
                />
                <div>
                    Drag and Drop files here
                </div>
                <div>
                    <b>Or</b>
                </div>
                <div>
                    Click here to select files to upload
                </div>
            </form>
        </div >
    )
}

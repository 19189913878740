import { faMinus, faPlus } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useEffect, useState } from 'react'
import Patient from '../../../../../libs/api/services/Patient'

export default function PatientProfileDetailTypeList({ onSave, optionId, fieldId }) {

    const [items, setItems] = useState([])
    const [item, setItem] = useState('')

    useEffect(() => {
        if (fieldId)
            Patient
                .FieldTypesOptionsDefaults(fieldId, optionId)
                .then((r) => {
                    if (r.length > 0)
                        setItems(r[0].value)
                })
    }, [])

    // Return Full List of Data
    const _handleAdd = () => {
        var _items = [...items]
        _items.push({ property: item })
        setItem('')
        setItems(_items)
        _handleSave(_items)
    }
    // Return Full List of Data
    const _handleSave = (_items) => {
        onSave(_items)
    }
    // Remove
    const _handleRemove = (i) => {
        var _items = [...items]
        _items.splice(i, 1)
        setItems(_items)
        _handleSave(_items)
    }
    // Update
    const _handleUpdate = (i, val) => {
        var _items = [...items]
        _items[i].property = val
        setItems(_items)
        _handleSave(_items)
    }
    return (
        <div>
            <div className='d-flex flex-row'>
                <input className='form-control form-control-sm' value={item} onChange={(e) => setItem(e.target.value)} placeholder='Option' /><button className='btn btn-sm ' onClick={e => _handleAdd()}><FontAwesomeIcon icon={faPlus} /></button>
            </div>
            <div>
                {
                    items.map((item, idx) =>
                        <div key={idx} className='d-flex flex-row'><input defaultValue={item.property} onChange={(e) => _handleUpdate(idx, e.target.value)} className='form-control form-control-sm' /><button className='btn btn-sm' onClick={(e) => _handleRemove(idx)}><FontAwesomeIcon icon={faMinus} /></button></div>
                    )
                }
            </div>
        </div>
    )
}

import { faBell, faSpinner } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { memo, useEffect, useRef, useState } from 'react'
import { Bell, ExclamationTriangle, InfoCircleFill, MenuUp, Trash } from 'react-bootstrap-icons'
import Authentication from '../../../libs/api/services/Authentication'
import Email from '../../../libs/api/services/Email'
import Messaging from '../../../libs/api/services/Messaging'
import NotificationsAPI from '../../../libs/api/services/NotificationsAPI'

//CheckMessages
function Notifications({ options }) {
    const [count, setCount] = useState(0)
    const [lastcount, setLastCount] = useState(0)
    const [mailAccountSet, setMailAccountSet] = useState(true)
    // const [refreshTimer, setRefreshTimer] = useState(null)
    const initLock = useRef(false)
    const readingLock = useRef(false)
    const refreshTimer = useRef()
    const [show, setShow] = useState(false)
    const [hasNew, setHasNew] = useState(false)
    const [processingMessages, setProcessingMessages] = useState(false)
    const [notifications, setNotifications] = useState([])
    const containerRef = useRef()
    const handleClick = e => {
        if (containerRef.current && !containerRef.current.contains(e.target)) {
          setShow(false);
        }
      };
    useEffect(() =>{
        document.addEventListener('click', handleClick);
        return () => {
          document.removeEventListener('click', handleClick);
        };
    },[])

    // useClickOutside(containerRef, setShow(false))
    const _removeNotification = (id) => {
        NotificationsAPI
            .RemoveNotification(id)
            .then((r) => {
                _handleRefresh()
            })
    }

    const _handleRefresh = async () => {
        var _count = 0
        if (readingLock.current === false) {
            readingLock.current = true
            var r = await NotificationsAPI
                .GetNotifications()
            setNotifications(r)
            if (r.length > 0) {
                setHasNew(true)
                _count += r.length
            }
            if (options.includes('email')) {
                var r = await Messaging
                    .CheckMessages()
                    .catch((e) => {
                        console.error(e)
                    })

                if (r != null) {
                    // setCount(r)
                    _count += r.length
                }
                if (r > 0)
                    setHasNew(true)
            }
            // Check email connection
            if (options.includes('email')) {
                if (!initLock.current) {
                    initLock.current = true
                    var r = await Email
                        .ConfigurationSet()
                        .catch((e) => {
                            console.error(e)
                        })
                    if (r != null)
                        setMailAccountSet(r === 'True')
                    if (r === 'False')
                        setHasNew(true)
                }
            }
            // Check patient alerts

            readingLock.current = false
        }
        setCount(_count)
    }
    useEffect(() => {
        _handleRefresh()
        if (refreshTimer.current == null)
            refreshTimer.current = setInterval(_handleRefresh, 1000 * 60)
        return () => { clearInterval(refreshTimer.current); refreshTimer.current = undefined }
    }, [show])

    const MarkAllAsRead = () => {
        setProcessingMessages(true)
        Messaging
            .MarkAllAsRead()
            .then((r) => {
                setProcessingMessages(false)
                _handleRefresh()
            })
    }

    const _handleClick = (linkTo) => {
        if (linkTo)
            window.location = linkTo
    }

    const _handleShake = () => {
        // if (lastcount < count)

    }

    useEffect(() => {
        if (lastcount < count) {
            setTimeout(() => setLastCount(count), 1000)
        }
    }, [count])

    return (
        <div ref={containerRef} className='position-relative w-100 me-2'>
            <div onClick={(e) => setShow(!show)} className='position-relative'>
                {
                    hasNew
                        ?
                        <>
                            <FontAwesomeIcon icon={faBell} style={{ fontSize: '24px', paddingTop: '3px' }} shake={lastcount < count} />
                            {/* <BellFill spin style={{ cursor: 'pointer' }} className='text-light' size={24} /> */}
                            <small className='position-absolute bg-dark rounded p-1 rounded-5 opacity-75 small' style={{ top: '-15px', left: '15px' }}>{count}</small>
                        </>
                        :
                        <Bell style={{ cursor: 'pointer' }} className='text-light opacity-25 ' size={24} />
                }

            </div>
            <div
                style={{ minWidth: '50vw', zIndex: 1003, right: '0px', maxHeight: '50vh', overflow: 'auto' }}
                className={'position-absolute bg-primary-container rounded mt-2 rounded-2 shadow shadow-3 '.concat(show ? 'd-block' : 'd-none')}>
                {
                    notifications.map((n) => {
                        return <div key={n.id}>
                            <div
                                style={{ cursor: 'pointer' }}
                                className={'row m-3 align-items-center '.concat(count > 0 ? '' : ' opacity-75 ')}>
                                <div className={'col-lg-1 col-lg-md-1'}><InfoCircleFill size={24} /></div>
                                <div className='col-lg-3  col-lg-md-11'><small>{n.sentDateTime.substring(0, 19)}</small></div>
                                <div className='col-lg-7  col-lg-md-11' onClick={() => _handleClick(n.linkTo)}>
                                    <div className='text-center'><b>{n.subject}</b></div>
                                    <div dangerouslySetInnerHTML={{ __html: n.message }} />
                                </div>
                                <div onClick={(e) => _removeNotification(n.id)} className='col-1'><Trash size={24} /></div>
                            </div>
                        </div>
                    })
                }
                {
                    options.includes('email')
                        ?
                        <>
                            <div
                                style={{ cursor: 'pointer' }}
                                className={'row m-3 align-items-center '.concat(count > 0 ? '' : ' opacity-75 ')}>
                                <div className={'col-lg-1 col-lg-md-1 '}><MenuUp size={24} /></div>
                                <div className='col-lg-1 col-lg-md-1'>{count}</div>
                                <div className='col-lg-8 col-lg-md-12'>Unread Messages</div>
                                <div className='col-lg-2 col-lg-md-12'>{processingMessages ? <FontAwesomeIcon icon={faSpinner} spin /> : <>{count == 0 ? <></> : <><button onClick={(e) => { setProcessingMessages(true); window.location = '' + Authentication.getFacilityRole() + '/Messages' }} className='btn btn-sm w-100'>Read</button><button onClick={(e) => MarkAllAsRead()} className='btn btn-sm w-100'>Mark all as read</button></>}</>}</div>
                            </div>
                            {
                                mailAccountSet
                                    ?
                                    <></>
                                    :
                                    <div className='row m-3'>
                                        <div className='col-1'><ExclamationTriangle size={24} className='text-danger' /></div>
                                        <div className='col-1'></div>
                                        <div className='col-10 text-center'><p>Your email account has not yet been configured. <br />Some functions will be running in limited mode</p>
                                            <button
                                                onClick={(e) => window.location = Authentication.getFacilityRole() + '/configuration/Email'}
                                                className='btn text-light'>Setup Now</button></div>
                                    </div>
                            }
                        </>
                        :
                        <></>

                }

                {/* <div className='row m-3'>
                    <div className='col-1'><Bell className='text-secondary' size={24} /></div>
                    <div className='col-1'>10</div>
                    <div className='col-10'>Patient Alerts</div>
                </div> */}
            </div>
        </div >
    )
}
export default memo(Notifications)
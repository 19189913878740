import React, { useEffect, useState } from 'react'
import ErrorDialog from '../../../../components/dialogs/ErrorDialog'
import { useContext } from 'react'
import StickyNavigationContext from '../../../../libs/providers/StickyNavigationProvider'
import SMSWizardAudience from './components/SMS/wizard/SMSWizardAudience'
import SMSWizardConnectionInfo from './components/SMS/wizard/SMSWizardConnectionInfo'
import SMSWizardServiceProvider from './components/SMS/wizard/SMSWizardServiceProvider'
import SMSWizardSetupType from './components/SMS/wizard/SMSWizardSetupType'
import SMSWizardDone from './components/SMS/wizard/SMSWizardDone'

export default function EmailAdmin() {
    const [step, setStep] = useState(1)
    const [title, setTitle] = useState('')
    const [description, setDescription] = useState('')
    const [navigation, setNavigation] = useState([])
    const [errors, setErrors] = useState('')
    const [values, setValues] = useState({})
    const { setSticky, setPreventMessage } = useContext(StickyNavigationContext)

    useEffect(() => {
        if (step != 1 && step != 5) {
            setSticky(true)
            setPreventMessage('Configuration Incomplete', 'You have not completed the configuration process. <br/><br/>This can be completed later, however service will not be active until completed. <br/><br/>Are you sure you would like to cancel?')
        } else {
            setSticky(false)
        }
    }, [step])

    const _getStepSection = () => {
        switch (step) {
            case 1:
                return <SMSWizardSetupType
                    title={(t) => setTitle(t)}
                    description={(t) => setDescription(t)}
                    navigation={(t) => setNavigation(t)}
                    errors={(e) => setErrors(e)}
                    setValue={(v) => setValues({ ...values, ...v })}
                    values={values}
                />
            case 2:
                return <SMSWizardServiceProvider
                    title={(t) => setTitle(t)}
                    description={(t) => setDescription(t)}
                    navigation={(t) => setNavigation(t)}
                    errors={(e) => setErrors(e)}
                    setValue={(v) => setValues({ ...values, ...v })}
                    values={values}
                />
            case 3:
                return <SMSWizardConnectionInfo
                    title={(t) => setTitle(t)}
                    description={(t) => setDescription(t)}
                    navigation={(t) => setNavigation(t)}
                    errors={(e) => setErrors(e)}
                    setValue={(v) => setValues({ ...values, ...v })}
                    values={values}
                />
            case 4:
                return <SMSWizardAudience
                    title={(t) => setTitle(t)}
                    description={(t) => setDescription(t)}
                    navigation={(t) => setNavigation(t)}
                    errors={(e) => setErrors(e)}
                    setValue={(v) => setValues({ ...values, ...v })}
                    values={values}
                />
            case 5:
                return <SMSWizardDone
                    title={(t) => setTitle(t)}
                    description={(t) => setDescription(t)}
                    navigation={(t) => setNavigation(t)}
                    errors={(e) => setErrors(e)}
                    setValue={(v) => setValues({ ...values, ...v })}
                    values={values}
                />
            default:
                return <></>
        }
    }

    return (
        <>
            <ErrorDialog error={errors} onClose={() => setErrors('')} />
            <div
                className="Wizard top-center position-relative">
                <div className="head"><h1>{title}</h1>
                    <p>{description}</p>

                    <div
                        className="error-details"></div>
                </div>
                <div className="body">
                    <div
                        className=".section d-flex flex-column align-items-stretch h-100 w-100">
                        {_getStepSection()}
                    </div>
                </div>
                <div classname="tail">
                    {
                        navigation.map((n) => {
                            if (n.type === 'Next')
                                return <button
                                    key={n.text}
                                    onClick={async (e) => {
                                        if (n.action) {
                                            await n.action().catch((e) => setErrors(e.message))
                                        }
                                        setStep(step + 1)
                                    }} className="btn ng-buttons">{n.text}</button>
                            else if (n.type === 'Back')
                                return <button
                                    key={n.text}
                                    onClick={async (e) => {
                                        if (n.action) {
                                            await n.action().catch((e) => setErrors(e.message))
                                        }
                                        setStep(step - 1)
                                    }} className="btn ng-buttons">{n.text}</button>
                            else if (n.type === 'Action')
                                return <button
                                    key={n.text}
                                    onClick={async (e) => {
                                        if (n.action)
                                            await n.action()
                                                .catch((e) => setErrors(e.message))
                                    }} className="btn ng-buttons">{n.text}</button>
                            else if (n.type === 'Done')
                                return <button
                                    key={n.text}
                                    onClick={async (e) => {
                                        if (n.action)
                                            await n.action()
                                                .catch((e) => setErrors(e.message))
                                        setStep(1)
                                    }} className="btn ng-buttons">{n.text}</button>
                        })
                    }
                </div>
            </div>
        </>
    )
}

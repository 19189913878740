import React from 'react'
import SearchComponent from '../search/SearchComponent'
import { ICDCODES } from './Config/ICDCODES'


export default function ICDCodeSelectionCompoonent({ onSelected, defaultValue }) {

    return (
        <SearchComponent
            onSelected={(r) => onSelected(r)}
            defaultValue={{ 'ICD10Code': defaultValue }}
            details={{
                data: ICDCODES,
                options: {
                    reRefKey: 'ICD10Code',
                    template: [{
                        refKey: 'ICD10Code',
                        descr: 'Code',
                    }, {
                        refKey: 'description',
                        descr: 'Description',
                    }]
                }
            }}
        />
    )
}

import React, { useEffect, useState } from 'react'
import DatePickerDay from '../DatePickerDay'
import DatePickerMonth from '../DatePickerMonth'
import DatePickerYear from '../DatePickerYear'
import DateTimePickerFunctions from './DateTimePickerFunctions'

export default function DateOptions({ value, onChange }) {
    const [mode, setMode] = useState()
    const [date, setDate] = useState(value)

    useEffect(() => { if (onChange) onChange(date) }, [date])
    const _handleSetYear = (year) => {
        setDate({ ...date, year: year }); setMode()
    }
    const _handleSetMonth = (month) => {
        setDate({ ...date, month: month }); setMode()
    }
    const _handleSetDay = (day) => {
        setDate({ ...date, day: day }); setMode()
    }
    return (
        <div className='text-center'>
            <div
                style={{ cursor: 'pointer' }}
                className='d-flex p-2'>
                <div
                    onClick={() => setMode('Year')}
                    className='w-50 '>
                    {date.year ? date.year : 'Year'}
                </div>
                <div className='w-50'
                    onClick={() => setMode('Month')}
                >
                    {typeof date.month !== 'undefined' ? DateTimePickerFunctions.months[date.month].full : 'Month'}
                </div>
            </div>
            <div>
                {
                    mode === 'Year'
                        ?
                        <DatePickerYear
                            onChange={_handleSetYear}
                        />
                        :
                        mode === 'Month'
                            ?
                            <DatePickerMonth
                                onChange={_handleSetMonth}
                                hideYear={true}
                                year={date.year}
                            />
                            :
                            <DatePickerDay
                                highlight={date.day}
                                hideMonth={true}
                                onChange={_handleSetDay}
                                onMonthClick={() => { }}
                                year={date.year}
                                month={date.month}
                            />
                }

            </div>
        </div>
    )
}

import React, { useEffect, useState } from 'react'
import MessagingService from '../../../../../../../libs/api/services/MessagingService'
import { Clipboard, List, People, Person } from 'react-bootstrap-icons'
import UserSelectionComponent from '../../UserSelection/UserSelectionComponent'
import FacilitySelectionComponent from '../../UserSelection/FacilitySelectionComponent'
import AudienceGroupComponent from '../../UserSelection/AudienceGroupComponent'

export default function EmailWizardAudience({ title, description, navigation, errors, setValue, values }) {

    const [audienceType, setAudienceType] = useState()
    const [configuration, setConfiguration] = useState()
    const [loaded, setLoaded] = useState(false)

    useEffect(() => {
        title('Audience')
        description('Select the type of person/group type that will be sending and receiving messages using this service')
        navigation([
            {
                text: 'Back', type: 'Back', action: async () => { }
            },
            // { text: 'Next', type: 'Next', action: async () => { } }
        ])
        // _loadSettings()
    }, [])

    useEffect(() => {
        if (audienceType)
            navigation([
                {
                    text: 'Back', type: 'Back', action: async () => { }
                },
                { text: 'Next', type: 'Next', action: async () => { } }
            ])
    }, [audienceType])

    const _handleSetAudienceType = (type) => {
        MessagingService
            .SetAudienceType(values.configurationId, type)
            .then((r) => {
                setAudienceType(type)
            }).catch((e) => errors(e.message))
    }
    const loadAudienceType = () => {
        MessagingService
            .GetAudienceType(values.configurationId)
            .then((r) => {
                setAudienceType(r.type)
            }).catch((e) => { if (e.message !== 'Malformed UTF-8 data') errors(e.message) })
    }
    const loadAudiences = () => {
        setLoaded(false)
        MessagingService
            .GetConfiguration(values.configurationId)
            .then((r) => {
                setConfiguration(r)
                setLoaded(true)
            }).catch((e) => errors(e.message))
    }
    useEffect(() => {
        loadAudiences()
        loadAudienceType()
    }, [])

    const _handleSelectUser = (userId) => {
        MessagingService
            .SetConfigurationAudienceUser(values.configurationId, userId)
            .then((r) => {
            }).catch((e) => errors(e.message))
    }
    const _handleSelecFacility = (facilityId) => {
        MessagingService
            .SetConfigurationAudienceFacility(values.configurationId, facilityId)
            .then((r) => {
            }).catch((e) => errors(e.message))
    }
    const _handleSelectAudience = (audienceGroupId) => {
        MessagingService
            .SetConfigurationAudienceGroup(values.configurationId, audienceGroupId)
            .then((r) => {
            }).catch((e) => errors(e.message))
    }
    return (
        <>
            <div
                style={{ cursor: 'pointer' }}
                className='d-flex flex-row justify-content-evenly align-items-evenly'>
                <div
                    onClick={(e) => _handleSetAudienceType('SingleUser')}
                    className={'w-100 m-3 p-2 rounded '.concat(audienceType === 'SingleUser' ? 'bg-primary-container shadow ' : 'bg-secondary-container')}>
                    <div>
                        <Person size={52} />
                    </div>
                    <div>
                        <h3>Single User</h3>
                    </div>
                    <div>
                        <p>Messages will be sent/received by a specified user on the system</p>
                    </div>
                </div>
                <div
                    onClick={(e) => _handleSetAudienceType('Facility')}
                    className={'w-100 m-3 p-2 rounded '.concat(audienceType === 'Facility' ? 'bg-primary-container shadow ' : 'bg-secondary-container')}
                >
                    <div>
                        <People size={52} />
                    </div>
                    <div>
                        <h3>Facility</h3>
                    </div>
                    <div>
                        <p>Messages will be sent/received by all Administrators / Clinicians linked to a facility</p>
                    </div>
                </div>
                <div
                    onClick={(e) => _handleSetAudienceType('AudienceGroup')}
                    className={'w-100 m-3 p-2 rounded '.concat(audienceType === 'AudienceGroup' ? 'bg-primary-container shadow ' : 'bg-secondary-container')}
                >
                    <div>
                        <Clipboard size={52} />
                    </div>
                    <div>
                        <h3>Audience Group</h3>
                    </div>
                    <div>
                        <p>Messages will be sent/received by pre selected list of users on the system</p>
                    </div>
                </div>
            </div>
            <div className='m-3'>
                {
                    !loaded
                        ?
                        <></>
                        :
                        audienceType === 'SingleUser'
                            ?
                            <div>
                                <b>Select the user that will be sending and receiving messages with this configuration</b>
                                <UserSelectionComponent
                                    defaultValue={configuration && Object.keys(configuration).includes('audienceUser') ? configuration.audienceUser.firstName + ' ' + configuration.audienceUser.lastName : ''}
                                    key={new Date()}
                                    onUserSelected={(u) => _handleSelectUser(u)} />
                            </div>
                            :
                            audienceType === 'AudienceGroup'
                                ?
                                <div>
                                    <AudienceGroupComponent
                                        defaultValue={configuration && Object.keys(configuration).includes('audienceGroups') ? configuration.audienceGroups : ''}
                                        onAudienceSelected={(u) => _handleSelectAudience(u)}
                                        errors={(e) => errors(e)} />
                                </div>
                                :
                                audienceType === 'Facility'
                                    ?
                                    <div>
                                        <b>Select the facility that will be sending and receiving messages with this configuration</b>
                                        <FacilitySelectionComponent
                                            defaultValue={configuration && Object.keys(configuration).includes('audienceFacility') ? configuration.audienceFacility.facility : ''}
                                            onFacilitySelected={(i) => _handleSelecFacility(i)} />
                                    </div>
                                    :
                                    <></>
                }
            </div>
        </>
    )
}

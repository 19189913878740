import React, { useContext, useEffect, useRef, useState } from 'react';
import { ArrowRepeat, Binoculars, BinocularsFill, CollectionFill, ExclamationCircleFill, InboxFill, InfoCircleFill, PenFill, Search, XCircle } from 'react-bootstrap-icons';
import { useParams } from 'react-router-dom';
import ErrorDialog from '../../../../components/dialogs/ErrorDialog';
import DatePicker from '../../../../components/ui/calendar/date_picket/DatePicker';
import Authentication from '../../../../libs/api/services/Authentication';
import Messaging from '../../../../libs/api/services/Messaging';
import CommonFunctionsContext from '../../../common/CommonFunctionsProvider';
import PatientContext from '../../patient/management/PatientProvider';
import MessageTab from './MessageTab';
import NewMessageComponent from './components/new_message/NewMessageComponent';

export default function MessageManagement() {

    const { setSectionInfo } = useContext(CommonFunctionsContext)
    const { activePatient } = useContext(PatientContext)

    const { id } = useParams();
    const refFilterPanel = useRef()
    const refSearch = useRef()
    const [messages, setMessages] = useState([]);
    const [page, setPage] = useState(1);
    const [loadingMessages, setLoadingMessages] = useState(false);
    const [filters, setFilters] = useState({ search: '', page: 1, dateFrom: undefined, dateTo: undefined, messageType: '' });
    const [messageTypeFilter, setFMessageTypeFilter] = useState('Any');
    const [synching, setSynching] = useState(false);
    const [error, setError] = useState('');
    const [search, setSearch] = useState()
    const [showSearchType, setShowSearchType] = useState(false)
    const [mbStat, setMBStat] = useState([{ status: '', lastStatus: '' }])
    const [lastMessageCount, setLastMessageCount] = useState()
    const [statMessageCount, setStatMessageCount] = useState()
    const [showNewMessage, setShowNewMessage] = useState(false)
    const checkMailRef = useRef(false)
    const checkStatusMailRef = useRef(false)


    // Pagination

    // Filters

    // Load Messages

    useEffect(() => {
        if (statMessageCount > lastMessageCount) {
            setMBStat([{ status: 'Info', lastStatus: `New Message${statMessageCount - lastMessageCount > 1 ? 's' : ''} Received` }]);
            _loadMessages()
        }
    }, [statMessageCount])

    useEffect(() => { _checkMailBox() }, [])

    const _checkMailBox = async () => {
        if (!checkMailRef.current) {
            checkMailRef.current = true
            setMBStat([{
                status: 'Info',
                lastStatus: 'Checking Mail'
            }])
            Messaging
                .CheckEmail(Authentication.UserId())
                .then((r) => {
                    checkMailRef.current = false
                    _loadMessages()
                })
                .catch((e) => {
                    checkMailRef.current = false
                    setMBStat([{
                        status: 'Error',
                        lastStatus: e.message
                    }])
                })
                ;
        }
    }

    const _loadMessages = (force) => {
        // filters.page = page;
        if (checkStatusMailRef.current) return;
        checkStatusMailRef.current = true
        setLoadingMessages(true)
        Messaging
            .GetMessages(filters)
            .then((r) => {
                checkStatusMailRef.current = false
                setLoadingMessages(false)
                if (filters.page !== 1 && !force) {
                    setMessages([...messages, ...r.messages])
                }
                else {
                    setLastMessageCount(r.size)
                    setMessages([...r.messages])
                }
                // // setMessages(r)
                setSynching(false)
            }).catch((e) => {
                checkStatusMailRef.current = false
                setLoadingMessages(false)
                setError(e.message);
                setSynching(false)
            })
    }

    useEffect(() => {
        if (id && !Object.keys(filters).includes('patientId')) { } else {
            _loadMessages()
        }
    }, [filters])

    //RM/////////
    // useEffect(() => {
    //     _loadMessages()
    // }, [])
    //RM///////////////////////////////

    useEffect(() => {
        // setInterval(setSynching,30000,true)
    }, [])


    useEffect(() => {
        // setInterval(setSynching,30000,true)
        setFilters({ ...filters, patientId: id })
    }, [id])

    const _handleSetArchive = (archived) => {
        setFilters({ ...filters, archived: archived, page: 1 })
    }
    const _handleNewMessage = () => {
        setShowNewMessage(true)
    }
    const _handleSetSearch = (search) => {
        setSearch(search)
        setShowSearchType(true)
        // setFilters({ ...filters, page: 1, search: search })
    }
    const _handleSetSearchStart = (search, type) => {
        // setShowSearchType()
        setShowSearchType(false)
        setFilters({ ...filters, page: 1, search: search, searchType: type })
    }
    const _handleClear = (search, type) => {
        // setShowSearchType()
        setSearch('')
        refSearch.current.value = ''
        setShowSearchType(false)
        setFilters({ ...filters, page: 1, search: undefined, searchType: undefined })
    }
    const _handleSetDateFrom = (from) => {
        setFilters({ ...filters, dateFrom: from, page: 1 })
    }
    const _handleSetDateTo = (to) => {
        setFilters({ ...filters, dateTo: to, page: 1 })
    }
    const _handleSetMessagType = (type) => {
        setFMessageTypeFilter(type)
        setFilters({ ...filters, messageType: type, page: 1 })
    }

    const _handleSyncStatus = () => {
        Messaging
            .GetSyncStatus()
            .then((s) => {
                // setCurrentSync(s.current)
                // setMaxSync(s.max)
            })
    }

    useEffect(() => {
        setSectionInfo(`Messages for ${JSON.parse(Authentication.UserName())}`)
        var intrv = setInterval(_loadMBStat, 5000)
        return () => {
            clearInterval(intrv)
        }
    }, [])

    useEffect(() => {
        if (activePatient)
            setSectionInfo(`Messages for ${activePatient.userProfile.firstName} ${activePatient.userProfile.lastName} at ${Authentication.getFacilityName()}`)

    }, [activePatient])

    const _handleSync = () => {
        setSynching(true)

        Messaging
            .SyncAccounts()
            .then((r) => {
                setSynching(false)
                // if (r === true)
                _loadMessages()
            }).catch((e) => {
                setSynching(false)
                setError(e.message);
            }).finally(() => {
                setSynching(false)
            })
    }

    const _handleScroll = (e) => {
        let element = e.target
        if (element.scrollHeight - 100 - (element.scrollTop + 50) < element.clientHeight) {
            if (!loadingMessages)
                setFilters({ ...filters, page: filters.page + 1 })
        }
    }

    const _loadMBStat = () => {
        if (checkStatusMailRef.current) return;
        checkStatusMailRef.current = true
        Messaging
            .GetMBStat()
            .then((r) => {
                checkStatusMailRef.current = false
                var _r = r;
                if (r.length > 0) {
                    setStatMessageCount(r[0].lastMessageSize)
                    setMBStat(_r);
                } else {
                    setMBStat([{ status: '', lastStatus: '' }]);
                }

            }).catch((e => { checkStatusMailRef.current = false }))
    }

    var date = "";
    return (
        <>
            {
                showNewMessage
                    ?
                    <NewMessageComponent errors={(e) => setError(e)} onClose={() => setShowNewMessage(false)} />
                    :
                    <></>
            }

            <ErrorDialog
                error={error}
                humanize={true}
                onClose={(e) => setError('')} />
            {
                Authentication.getFacilityRole() !== 'Patient'
                    ?
                    <div className='flex-shrink-1 text-start bg-secondary-container p-3 text-center d-flex flex-column'>
                        <button onClick={() => {
                            if (refFilterPanel.current.className.includes('collapse'))
                                refFilterPanel.current.className = refFilterPanel.current.className.replace('collapse', '')
                            else
                                refFilterPanel.current.className = refFilterPanel.current.className + ' collapse'
                        }}
                            className='btn d-block d-lg-none' data-toggle="collapse"><b>Options</b></button>
                        <div ref={refFilterPanel} id="filters" className='row  align-items-center collapse d-lg-flex'>
                            <div className='col-12 col-lg-3 text-center'>
                                <b>Search</b>
                                <div>
                                    <div className="d-flex position-relative">
                                        <input
                                            ref={refSearch}
                                            onChange={(e) => _handleSetSearch(e.target.value)}
                                            onBlur={(e) => refSearch.current.placeholder = 'Search'}
                                            onFocus={(e) => refSearch.current.placeholder = 'Enter your search critieria'}
                                            // className='form-control form-control-sm p-2 w-100 h-100 flex-grow-1'
                                            className='form-control bg-search-bar rounded-3 text-primary'
                                            placeholder='Search' />
                                        <div
                                            // onClick={(e) => {refSearch.current.select(); refSearch.current.placeholder= 'Start typing users name to start searching'}}
                                            className="d-flex align-items-center bg-buttons px-3 position-absolute rounded" style={{ right: '0px', top: '0px', bottom: '0px', fontWeight: 'bold' }}>
                                            {
                                                !(refSearch.current && refSearch.current.value !== "")
                                                    ?
                                                    <div
                                                        onClick={(e) => { refSearch.current.placeholder = 'Enter your search critieria'; refSearch.current.select() }}
                                                        className="d-flex align-items-center bg-buttons px-3 position-absolute rounded shadow" style={{ right: '0px', top: '0px', bottom: '0px', fontWeight: 'bold' }}>
                                                        <Search

                                                        />
                                                    </div>
                                                    :
                                                    <div
                                                        onClick={(e) => _handleClear()}
                                                        className="d-flex align-items-center bg-buttons px-3 position-absolute rounded shadow" style={{ right: '0px', top: '0px', bottom: '0px', fontWeight: 'bold' }}>
                                                        <XCircle />
                                                    </div>
                                            }
                                        </div>
                                    </div>
                                    {
                                        showSearchType
                                            ?
                                            <div style={{ cursor: 'pointer', zIndex: 99 }} className='d-flex  flex-column position-absolute text-start bg-primary-container p-2 rounded shadow'>
                                                <div onClick={(e) => _handleSetSearchStart(search, 'quick')} className='p-2'><Binoculars size={30} className='me-2' /><b>Quick Search</b> - Search for <b>{search}</b> in the message subject and recipient list</div>
                                                <div onClick={(e) => _handleSetSearchStart(search, 'extended')} className='p-2'><BinocularsFill size={30} className='me-2' /><b>Extended Search</b> - Search for <b>{search}</b> in the message subject, recipient list and message contents</div>
                                            </div>
                                            :
                                            <></>
                                    }
                                </div>
                            </div>
                            <div className='col-12 col-lg-4 '>
                                <div className=' flex-grow-1 d-sm-flex justify-content-space-between'>
                                    <div className={'flex-grow-1 text-center'}>
                                        <b>Date From</b>
                                        <DatePicker onChange={(e) => _handleSetDateFrom(e)} />
                                    </div>
                                    <div className={'flex-grow-1 text-center'}>
                                        <b>Date To</b>
                                        <DatePicker
                                            onChange={(e) => _handleSetDateTo(e)}
                                            className={'flex-grow-1'} />
                                    </div>
                                </div>
                            </div>
                            <div className='col-12 col-lg-3 '>
                                <div className='text-center flex-shrink-1'>
                                    <b>Message Type</b>
                                    <div>
                                        <select
                                            className='form-control'
                                            onChange={(e) => _handleSetMessagType(e.target.value)}>
                                            <option selected={messageTypeFilter === 'Any' ? 'selected' : ''} value={'Any'}>Any</option>
                                            <option selected={messageTypeFilter === 'Internal Messaging Service' ? 'selected' : ''} value={'Internal Messaging Service'}>Internal Messaging Service</option>
                                            <option selected={messageTypeFilter === 'SMS' ? 'selected' : ''} value={'SMS'}>SMS</option>
                                            <option selected={messageTypeFilter === 'Email' ? 'selected' : ''} value={'Email'}>Email</option>
                                            <option selected={messageTypeFilter === 'WhatsApp' ? 'selected' : ''} value={'WhatsApp'}>WhatsApp</option>
                                        </select>
                                        {/* <button
                                    onClick={(e) => _handleSetMessagType('Any')}
                                    className={'btn '.concat(messageTypeFilter === 'Any' ? 'active' : '')} >All</button>
                                <button
                                    onClick={(e) => _handleSetMessagType('Internal Messaging Service')}
                                    className={'btn '.concat(messageTypeFilter === 'Internal Messaging Service' ? 'active' : '')}>Internal Messaging</button>
                                <button
                                    onClick={(e) => _handleSetMessagType('SMS')}
                                    className={'btn '.concat(messageTypeFilter === 'SMS' ? 'active' : '')}>SMS</button>
                                <button
                                    onClick={(e) => _handleSetMessagType('Email')}
                                    className={'btn '.concat(messageTypeFilter === 'Email' ? 'active' : '')}>Email</button>
                                <button
                                    onClick={(e) => _handleSetMessagType('WhatsApp')}
                                    className={'btn '.concat(messageTypeFilter === 'WhatsApp' ? 'active' : '')}>WhatspApp</button> */}
                                    </div>
                                </div>
                            </div>
                            <div style={{ cursor: 'pointer' }} className='col-12 col-lg-2 '>
                                <div onClick={(e) => _handleSetArchive(true)}>
                                    <CollectionFill
                                        // onClick={(e) => _handleArchiveMessage(message.id)}
                                        className='m-1'
                                        size={18} />
                                    <b>Archives</b>
                                </div>
                                <div onClick={(e) => _handleSetArchive(false)}>
                                    <InboxFill
                                        // onClick={(e) => _handleArchiveMessage(message.id)}
                                        style={{ cursor: 'pointer' }}
                                        className='m-1'
                                        size={18} />
                                    <b>Inbox</b>
                                </div>
                                <div onClick={(e) => _handleNewMessage()}>
                                    <PenFill
                                        // onClick={(e) => _handleArchiveMessage(message.id)}
                                        style={{ cursor: 'pointer' }}
                                        className='m-1'
                                        size={18} />
                                    <b>New Message</b>
                                </div>
                                <div onClick={(e) => _checkMailBox()}>
                                    <ArrowRepeat
                                        // onClick={(e) => _handleArchiveMessage(message.id)}
                                        style={{ cursor: 'pointer' }}
                                        className='m-1'
                                        size={18} />
                                    <b>Sync Mail</b>
                                </div>
                                {/* {
                            synching
                                ?
                                <div>
                                    <ArrowRepeat
                                        // onClick={(e) => _handleArchiveMessage(message.id)}
                                        style={{ cursor: 'pointer' }}
                                        className='m-1'
                                        size={18}
                                    />
                                    <b>{currentSync} / {maxSync}</b>
                                </div>
                                :
                                <div onClick={(e) => _handleSync()}>
                                    <ArrowRepeat
                                        // onClick={(e) => _handleArchiveMessage(message.id)}
                                        style={{ cursor: 'pointer' }}
                                        className='m-1'
                                        size={18}
                                    />
                                    <b>Sync</b>
                                </div>
                        } */}
                            </div>
                          
                        </div>
                        <div className='text-center pt-2'>
                                {
                                    loadingMessages
                                        ?
                                        <div><Search className='me-2' />Searching for mesaages</div>
                                        :
                                        <>
                                            {
                                                mbStat.length > 0 ?
                                                    mbStat[0].status === 'Info' ? <div><InfoCircleFill className='me-2' />{mbStat[0].lastStatus}</div>
                                                        : mbStat[0].status === 'Error' ? <div><ExclamationCircleFill className='me-2' />{mbStat[0].lastStatus}</div> :
                                                            <></> : <></>
                                            }
                                        </>
                                }
                            </div>
                    </div>
                    :
                    <div style={{ cursor: 'pointer' }} className='bg-secondary-container text-end p-2'>
                        <div className=' me-2' onClick={(e) => _handleNewMessage()}>
                            <PenFill
                                // onClick={(e) => _handleArchiveMessage(message.id)}
                                style={{ cursor: 'pointer' }}
                                className='m-1'
                                size={18} />
                            <b>New Message</b>
                        </div>
                    </div>
            }
            <div
                className={' '.concat(Authentication.getFacilityRole() === 'Patient' ? 'my-3' : '')}
                onScroll={(e) => _handleScroll(e)}
                style={{ overflowY: 'scroll' }}>
                {/* <div className='bg-light m-3 rounded rounded-3 shadow' style={{ width: '50%', right: '0px' }}>
                    <div className='d-flex m-1 justify-content-between'>
                        <div className=''>ICON</div>
                        <div className=''>Options</div>
                    </div>

                    <div className=''><b>From</b></div>
                    <div className=''><small>TIME</small></div>
                    <div className=''><p>Subject</p></div>
                    <div className=''><p>Message</p></div>
                </div> */}
                {/* <div className='bg-light rounded rounded-3 shadow' style={{ width: '50%', marginLeft: '50%' }}>
                    <div className='d-flex m-1 justify-content-between'>
                        <div className=''>ICON</div>
                        <div className=''>Options</div>
                    </div>

                    <div className=''><b>From</b></div>
                    <div className=''><small>TIME</small></div>
                    <div className=''><p>Subject</p></div>
                    <div className=''><p>Message</p></div>
                </div> */}
                {
                    messages.map((m) => {
                        // m.messageBody = m.messageBody.replaceAll('\n', "<br />")
                        var ret = (
                            <>
                                <h2 className='text-center opacity-50 m-2'>{date !== m.sentDate.substr(0, 10) ? m.sentDate.substr(0, 10) : ""}</h2>
                                {/* <div className={'d-flex '.concat(m.fromUser && m.fromUser.id && (m.user.id === m.fromUser.id && m.messageType !== 'Outgoing') ? 'justify-content-start' : 'justify-content-end')}> */}
                                {/* <div className={'d-flex '.concat(m.messageType === 'Outgoing' ? 'justify-content-start' : 'justify-content-end')}> */}
                                <MessageTab
                                    onError={(e) => setError(e)}
                                    loadMessages={() => _loadMessages(true)}
                                    message={m} />
                            </>
                        )

                        if (date !== m.sentDate.substr(0, 10))
                            date = m.sentDate.substr(0, 10);
                        return ret;
                    })
                }
                {
                    loadingMessages === true
                        ?
                        <></>
                        // <center>
                        //     <div className='m-5 p-5'>
                        //         <FontAwesomeIcon size='2x' icon={faSpinner} spin />
                        //         LOADING
                        //     </div>
                        // </center>
                        :
                        <></>
                }
            </div>
        </>
    )
}

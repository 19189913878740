import React, { useState } from 'react'
import { Filter } from 'react-bootstrap-icons'
import DateTimePicker from '../../../../../../../components/ui/calendar/date_picket/DateTimePicker'

export default function MessagesSearchBar() {
    const [show, setShow] = useState(false)
    return (
        <>
            <div className='bg-primary-container position-fixed rounded shadow ms-2 mt-1 mb-5'>
                <div className=' text-start p-2 m-1' style={{ right: '0px' }}>

                    <Filter className='' onClick={() => setShow(!show)} />
                </div>

                {
                    show ?
                        <div className='d-flex flex-column position-relative justify-content-around p-2 rounded  w-100'>
                            <div className='d-flex  '>
                                <div>From <input className='form-control form-control-sm' /></div>
                                <div>Subject <input className='form-control form-control-sm ' /></div>
                                <div className=''>Date From <DateTimePicker pos={'bottom'} small={true} onChange={() => { }} />
                                    Date Till <DateTimePicker pos={'bottom'} small={true} onChange={() => { }} />
                                </div>
                            </div>
                            <div>
                                <button className='btn m-2'>Filter</button>
                            </div>
                        </div>
                        : <></>
                }
            </div>
        </>
    )
}

import React from 'react'
import DashboardContainer from '../facility/management/components/DashboardContainer'

export default function SysAdminUserDashboard() {
    return (
        <div className='m-3 row text-center'>
            <DashboardContainer Icon={'user'} title={'User Access Mapping'} description={'View current user type access'} location={'/sys-admin/users/mapping'} />
        </div>
    )
}

import API from "../API";

var Facility = {
    async search(data) {
        var result = await API.process(
            'api/Facilities/search',
            'POST',
            data
        ).catch((err) => {
            throw err;
        })

        return result
    },
    async create(data) {
        var result = await API.process(
            'api/Facilities',
            'POST',
            data
        ).catch((err) => {
            throw err;
        })

        return result
    },
    async roles() {
        var result = await API.process(
            'api/Facilities/Roles',
            'GET',
            null
        ).catch((err) => {
            throw err;
        })

        return result
    },
    async list() {
        var result = await API.process(
            'api/Facilities',
            'GET',
            null
        ).catch((err) => {
            throw err;
        })

        return result
    },
}

export default Facility;
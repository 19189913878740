import React, { useEffect, useState } from 'react'
import MessagingService from '../../../../../../../libs/api/services/MessagingService'
import { ArrowLeftRight } from 'react-bootstrap-icons'

export default function SMSWizardServiceProvider({ title, description, navigation, errors, setValue, values }) {

    const serviceProviders = [{ id: 'InfoBip', provider: 'InfoBip' }]
    const [serviceProvider, setServiceProvider] = useState(Object.keys(values).includes('serviceProviderId') ? values.serviceProviderId : '')
    useEffect(() => {
        title('Service Provider')
        description('Select your SMS Integration service provider')
        navigation([
            {
                text: 'Back', type: 'Back', action: async () => {
                    setValue({ 'serviceProviderId': undefined })
                }
            }
        ])
    }, [])

    const _handleSelectProvider = (id) => {
        setValue({ 'serviceProviderId': id })
        setServiceProvider(id)
        navigation([
            {
                text: 'Back', type: 'Back', action: async () => {
                    setValue({ 'serviceProviderId': undefined })
                }
            },
            {
                text: 'Next', type: 'Next', action: async () => {
                    MessagingService
                        .SetMessagingServiceProperty(values.configurationId, 'SMS_PROVIDER', id)
                        .catch((e) => errors(e.message))
                }
            }
        ])
    }
    useEffect(() => {
        if (serviceProvider)
            _handleSelectProvider(serviceProvider)
    }, [serviceProvider])

    useEffect(() => {
        MessagingService
            .GetMessagingServiceProperty(values.configurationId, 'SMS_PROVIDER')
            .then((r) => {
                if (r)
                    setServiceProvider(r.value)
                else {
                    setServiceProvider(undefined)
                    // setValue({ 'serviceProviderId': undefined })
                }
            })
            .catch((e) => {
                // setValue({ 'serviceProviderId': undefined })
            })
    }, [values, errors])

    return (
        <div className='row'>
            {
                serviceProviders.map((provider) =>
                    <div
                        onClick={(e) => _handleSelectProvider(provider.id)}
                        key={provider.id}
                        className={'col-4 rounded m-3 p-3 '.concat(serviceProvider === provider.id ? ' bg-primary-container' : 'bg-secondary-container')}>
                        <div className='p-2'>
                            <ArrowLeftRight size={52} />
                        </div>
                        <div>
                            {provider.provider}
                        </div>
                    </div>
                )
            }

        </div>

    )
}

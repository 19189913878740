export default [
    {
        provider: 'GMail',
        domain: 'smtp.gmail.com',
        port: 465,
        security: 'OAuth',
        encryption: 'SSL/TLS',
        incomming_domain:'imap.gmail.com',
        incomming_port: 993,
        incomming_security: 'OAuth',
        incomming_encryption: 'SSL/TLS',
        outgoing_domain:'smtp.gmail.com',
        outgoing_port: 465,
        outgoing_security: 'OAuth',
        outgoing_encryption: 'SSL/TLS',
        incomming_type: "imap"
    },
    {
        provider: 'Outlook.com/Office365',
        domain: 'smtp.office365.com',
        port: 587,
        security: 'TLS',
        incomming_domain:'imap.office365.com',
        incomming_port: 993,
        incomming_security: 'OAuth',
        incomming_encryption: 'SSL/TLS',
        outgoing_domain:'smtp.office365.com',
        outgoing_port: 465,
        outgoing_security: 'OAuth',
        outgoing_encryption: 'SSL/TLS',
        incomming_type: "imap"
    },
    {
        provider: 'Yahoo Mail',
        domain: 'smtp.mail.yahoo.com',
        port: 587,
        security: 'STARTLS',
        incomming_domain:'imap.mail.yahoo.com',
        incomming_port: 993,
        incomming_security: 'OAuth',
        incomming_encryption: 'SSL/TLS',
        outgoing_domain:'smtp.mail.yahoo.com',
        outgoing_port: 465,
        outgoing_security: 'OAuth',
        outgoing_encryption: 'SSL/TLS',
        incomming_type: "imap"
    },
    {
        provider: 'iCloud',
        domain: 'smtp.mail.me.com',
        port: 587,
        security: 'STARTLS',
        incomming_domain:'imap.mail.me.com',
        incomming_port: 993,
        incomming_security: 'OAuth',
        incomming_encryption: 'SSL/TLS',
        outgoing_domain:'smtp.mail.me.com',
        outgoing_port: 465,
        outgoing_security: 'OAuth',
        outgoing_encryption: 'SSL/TLS',
        incomming_type: "imap"
    },
    {
        provider: 'Other',
        domain: '',
        port: 0,
        security: '',
        incomming_domain:'',
        incomming_port: 993,
        incomming_security: '',
        incomming_encryption: '',
        outgoing_domain:'',
        outgoing_port: 465,
        outgoing_security: '',
        outgoing_encryption: '',
        incomming_type: ""
    },
]
import { faSpinner } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useEffect, useState } from 'react'
import { CheckSquare, Square } from 'react-bootstrap-icons'

export default function EventTrigger({ title, description, link, disabled, isActive, onChange, children }) {
    const [activeID, setActiveID] = useState(link.length > 0 && link)
    const [loading, setLoading] = useState(false)

    useEffect(() => { if (link && link.length > 0) setActiveID(link) }, [link])

    const _handleToggleActive = () => {
        if (disabled) return
        setLoading(true)
        onChange()
            .then((r) => {
                /// No content return true / added item return Guid
                setActiveID(typeof r === 'string' ? [{ id: r }] : undefined)
                setLoading(false)
            }).catch((e) => console.warn(e))
    }

    const _warpLinkId = (children) => {
        var res = []
        React.Children.map(children, (child, index) =>
            res.push({
                ...child,
                props: {
                    ...child.props,
                    link: activeID && [...activeID]
                }
            })
        )

        return res
    }
    return (
        <div className={'bg-primary-container m-1 p-2 rounded '.concat(disabled ? 'bg-secondary opacity-25 ' : 'bg-primary-container')} style={{ cursor: 'pointer' }}>
            <div className='d-flex'>
                <div className='pe-2' onClick={() => _handleToggleActive()}>
                    {
                        loading
                            ?
                            <FontAwesomeIcon icon={faSpinner} spin />
                            :
                            activeID
                                ?
                                <CheckSquare />
                                :
                                <Square />
                    }
                </div>
                <div onClick={() => _handleToggleActive()}>
                    <b>{title}</b>
                    <div>
                        <p>{description}</p>
                    </div>
                </div>
            </div>
            <div className={'ms-4 '.concat(activeID ? '' : 'd-none')}>
                {_warpLinkId(children)}
            </div>
        </div>
    )
}

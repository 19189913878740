import React, { useEffect, useState } from 'react'
import MessageTemple from '../../../../../../../libs/api/services/MessageTemple'
import RTEMessageBuilderComponent from '../../RTEMessageBuilderComponent'

export default function MessageTemplateWizardTemplate({ title, description, navigation, errors, setValue, values }) {

    const [serviceType, setServiceType] = useState('')
    const [loading, setLoading] = useState(true)
    const [subject, setSubject] = useState('')
    const [body, setBody] = useState({ template: '', html: '', md: '' })
    useEffect(() => {
        title('Messaging Template')
        description('Now create the message to be sent out. <br/>You can use the placeholder options to create dynamic fields for your message eg: Hi {{Patients Name}}')
        // Load Navigation if session started
        if (Object.keys(values).includes('templateId'))
            navigation([
                { text: 'Back', type: 'Back', action: async () => { } },
                { text: 'Next', type: 'Next', action: async () => { } },
                // { text: 'Next', type: 'Next', action: async () => {  } },
            ])
        _hadleLoad()
    }, [])

    const _handleSetAudienceType = (type) => {
        setServiceType(type)
        navigation([
            { text: 'Back', type: 'Back', action: async () => { } },
            { text: 'Next', type: 'Next', action: async () => { } },
        ])
    }

    const _hadleLoad = () => {
        if (Object.keys(values).includes('templateId')) {
            MessageTemple
                .GetContent(values.templateId)
                .then((r) => {
                    setSubject(r.subject)
                    setBody({ template: r.template, html: r.html, md: r.md })
                    setLoading(false)
                    // alert(JSON.stringify(r.template))
                }).catch((e) => { if (e.errors) errors(e.errors); else errors(e.message); })
        }
    }

    useEffect(() => {
        if (body.template && body.template !== "")
            MessageTemple
                .setContent(values.templateId, subject, body)
                .then((r) => { }).catch((e) => errors(e.message))
    }, [subject, body])

    // useEffect(() => {
    //     loadConfigs()
    // }, [])

    // const _handleSetConfigrationName = (c) => {
    //     setConfigurationName(c)
    //     if (c)
    //         navigation([{
    //             text: 'Next', type: 'Next', action: async () => {
    //                 return await MessageTemple
    //                     .Create({
    //                         name: c
    //                     }).then((r) => {
    //                         setValue({ 'templateId': r.id })
    //                     })
    //             }
    //         }])
    //     else
    //         navigation([])
    //     setConfiguration()
    // }

    // const _handleRemoveTemplate = async (id) => {
    //     await MessageTemple
    //         .RemoveTemplate(id)
    // }

    // const _handleSelectConfiguration = (c) => {
    //     if (c)
    //         navigation([
    //             { text: 'Remove', type: 'Action', action: async () => { _handleRemoveTemplate(c.id).then((r) => { navigation([]); loadConfigs() }) } },
    //             { text: 'Manage', type: 'Next', action: async () => { setValue({ 'configurationId': c.id }) } },
    //         ])
    //     setConfigurationName('')
    //     setConfiguration(c)
    // }
    const _GenerateRTE = () => {
        // alert(JSON.stringify(values))
        switch (values.serviceType) {
            case 'SMS':
                return (
                    <div className='container-fluid d-flex flex-column h-100'>
                        <div className='flex-shrink-1'>
                        </div>
                        <div className='bg-white text-dark shadow m-2 rounded rounded-4 flex-grow-1'>
                            <h3 className='m-3'>Message</h3>
                            <RTEMessageBuilderComponent
                                template={body.template}
                                onSetTemplate={(t, h, m) => setBody({ template: t, html: h, md: m })}
                                options={[]}
                            // options={['inline', 'blockType', 'fontSize', 'fontFamily', 'list', 'textAlign', 'colorPicker', 'link', 'embedded', 'emoji', 'image', 'remove', 'history']}
                            />
                        </div>
                    </div>)
            case 'WhatsApp':
                return (
                    <div className='container-fluid d-flex flex-column h-100'>
                        <div className='flex-shrink-1'>

                        </div>
                        <div className='bg-white text-dark shadow m-2 rounded rounded-4 flex-grow-1'>
                            <h3 className='m-3'>Message</h3>
                            <RTEMessageBuilderComponent
                                template={body.template}
                                onSetTemplate={(t, h, m) => setBody({ template: t, html: h, md: m })}
                                options={[]}
                            // options={['inline', 'blockType', 'fontSize', 'fontFamily', 'list', 'textAlign', 'colorPicker', 'link', 'embedded', 'emoji', 'image', 'remove', 'history']}
                            />
                        </div>
                    </div>)
            case 'Internal Messaging Service':
                return (
                    <div className='container-fluid d-flex flex-column h-100'>
                        <div className='flex-shrink-1'>
                            <h3 className='m-3'>Subject</h3>
                            <div className='row'>
                                <div className='col-12'>
                                    <input
                                        defaultValue={subject}
                                        onChange={(e) => setSubject(e.target.value)} className='form-control' placeholder='Subject' />
                                </div>
                            </div>
                        </div>
                        <div className='bg-white text-dark shadow m-2 rounded rounded-4 flex-grow-1'>
                            <h3 className='m-3'>Message</h3>
                            <RTEMessageBuilderComponent
                                template={body.template}
                                onSetTemplate={(t, h, m) => setBody({ template: t, html: h, md: m })}
                                options={['inline', 'blockType', 'fontSize', 'fontFamily', 'list', 'textAlign', 'colorPicker', 'link', 'emoji', 'history']}
                            // options={['inline', 'blockType', 'fontSize', 'fontFamily', 'list', 'textAlign', 'colorPicker', 'link', 'embedded', 'emoji', 'image', 'remove', 'history']}
                            />
                        </div>
                    </div>)
            case 'Email':
                return (
                    <div className='container-fluid d-flex flex-column h-100'>
                        <div className='flex-shrink-1'>
                            <h3 className='m-3'>Subject</h3>
                            <div className='row'>
                                <div className='col-12'>
                                    <input
                                        defaultValue={subject}
                                        onChange={(e) => setSubject(e.target.value)} className='form-control' placeholder='Subject' />
                                </div>
                            </div>
                        </div>
                        <div className='bg-white text-dark shadow m-2 rounded rounded-4 flex-grow-1'>
                            <h3 className='m-3'>Message</h3>
                            <RTEMessageBuilderComponent
                                template={body.template}
                                onSetTemplate={(t, h, m) => setBody({ template: t, html: h, md: m })}
                                options={['inline', 'blockType', 'fontSize', 'fontFamily', 'list', 'textAlign', 'colorPicker', 'link', 'emoji', 'history']}
                            // options={['inline', 'blockType', 'fontSize', 'fontFamily', 'list', 'textAlign', 'colorPicker', 'link', 'embedded', 'emoji', 'image', 'remove', 'history']}
                            />
                        </div>
                    </div>)
            default:
                return <></>
        }
    }

    return (
        loading
            ?
            <></>
            :
            <div className='h-100 bg-primary-container m-3 rounded shadow'>
                {_GenerateRTE()}
            </div>
    )
}

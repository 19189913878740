import React, { useState } from 'react'
import FlowAssistant from '../../../../../../libs/api/services/FlowAssistant'
import EventCategoryEventSearch from '../../../../patient/admin/events/category/EventCategoryEventSearch'
import EventCategorySearch from '../../../../patient/admin/events/category/EventCategorySearch'

export default function QBuilderAdvancedOptionsEventTrigger({ cat, evt, values, onChangeCategory, onChangeEvent }) {
    const [category, setCategory] = useState(cat)
    const [event, setEvent] = useState(evt)
    const _handleAddTask = () => {
        //section, details, destination
        FlowAssistant
            .BookmarkFlowAndGoTo('/sys-admin/questionnaires/builder', 'QUESTIONAIRE_BUILDER_OPTIONS', values, '/sys-admin/event/new')
    }
    const _handleReviewTask = () => {
        //section, details, destination
        const _values = { ...values, event: JSON.stringify(event) }
        FlowAssistant
            .BookmarkFlowAndGoTo('/sys-admin/questionnaires/builder', 'QUESTIONAIRE_BUILDER_OPTIONS', _values, '/sys-admin/event/new', 4)
    }
    const _handleSetCategory = (cat) => {

        setCategory(cat)
        onChangeCategory(cat)
    }
    const _handleSetField = (evt) => {
        setEvent(evt)
        onChangeEvent(evt)
    }
    const _handleGetSelected = () => { }

    return (
        <div>
            <div className='d-flex '>
                <div style={{ cursor: 'pointer' }} className='w-100 text-center bg-secondary-container p-1 rounded shadow'>Existing Event</div>
                <div onClick={() => _handleAddTask()} style={{ cursor: 'pointer' }} className='w-100 text-center d-flex flex-column justify-content-center'>Create A New Event</div>
            </div>
            <label>Event Category</label>
            <EventCategorySearch
                defaultValue={category}
                disableAdd={true} onChanged={(cat) => _handleSetCategory(cat)} />
            {
                category
                    ?
                    <>
                        <label>Event</label>
                        <EventCategoryEventSearch
                            defaultValue={event}
                            key={category.id} showAny={true} disableAdd={true}
                            categoryid={category.id}
                            onChanged={(e) => _handleSetField(e)} />
                        {
                            category && event
                                ?
                                <div className='text-end p-2'>
                                    <button onClick={() => _handleReviewTask()} className='btn btn-xs'>Review Event</button>
                                </div>
                                :
                                <></>
                        }
                    </>
                    :
                    <></>
            }
        </div>
    )
}

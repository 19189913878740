import React, { useEffect, useState } from 'react'
import './OptionsGrid.css'
export default function OptionsGridComponent({ options, valueKey, defaultValue, onChange, placeholder }) {
    const [active, setActive] = useState(defaultValue)

    const _onOptionSeleceted = (value) => {
        setActive(value)
        if (onChange) onChange(value)
    }

    useEffect(() => {
        setActive(defaultValue)
    }, [defaultValue])

    return (
        <>
            <select onChange={(e) => _onOptionSeleceted(e.target.value)} className='wizard-options-grid-container form-control form-control-sm text-start'>
                <option></option>
                {options.map((option) => (
                    <option
                        // className={'wizard-options-grid-container-option '.concat(active === option.id ? 'active' : 'inactive')}
                        selected={active === option.id}
                        value={option.id}
                        // onClick={() => _onOptionSeleceted(option.id)}
                        key={option.id}
                    >
                        {option[valueKey]}
                    </option>
                ))}
            </select>
        </>
    )
}

import React from 'react'
import { useContext } from 'react'
import { useState } from 'react'
import { Files } from 'react-bootstrap-icons'
import PatientHistoryContext from '../history/PatientHistoryProvider'
import { useParams } from 'react-router-dom'
export default function PatientAddDMSNoteComponent({id, event}) {
    const { ADD_NOTE } = useContext(PatientHistoryContext)
    const [note, setNote] = useState('')
    return (
        <div>
            <label>Note:</label>
            <textarea value={note} onChange={(e) => setNote(e.target.value)} className='form-control notes-box'></textarea>
            <button onClick={(e) => ADD_NOTE(event, note, false).then((e) => setNote(''))} className='btn shadow m-2'><Files size={22} className='me-2' />Add Note</button>
            <button onClick={(e) => ADD_NOTE(event, note, true).then((e) => setNote(''))} className='btn shadow m-2'><Files size={22} className='me-2' />Add Private Note</button>
        </div>
    )
}

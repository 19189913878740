import React, { useContext, useEffect, useState } from 'react'
import CalendarSyncContext from './CalendarSyncProvider'
import UserExternalCalendars from './UserExternalCalendars'
import { PROVIDERS } from './config/CalendarSyncProviders'

export default function ExternalCalendarManagement({ screen }) {
    const { activeCalendar, setActiveCalendar } = useContext(CalendarSyncContext)
    const [active, setActive] = useState(screen)

    // Listen for new sync change
    useEffect(() => { _handleSetScreen(screen) }, [screen])
    // Listen for existing selected
    useEffect(() => { try { _handleSetScreen(PROVIDERS.find(x => x.name === activeCalendar.provider).config) } catch (e) { } }, [activeCalendar])

    const _handleSetScreen = (_screen) => {
        // var _screen 
        if (!_screen) return
        _screen = { ..._screen, props: { ..._screen.props, onClose: () => { setActive(); setActiveCalendar() } } }
        setActive(_screen)
    }

    return (
        active ? active : <UserExternalCalendars />
    )
}

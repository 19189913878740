import React, { useEffect, useState } from 'react'
import { Plugin, Trash } from 'react-bootstrap-icons'
import InputWithKeyFunctions from '../../../../../../../../components/inputs/InputWithKeyFunctions'
import ServiceProviderServices from './ServiceProviderServices'

export default function ServiceProviderPill({ provider, onCreate, onDelete, onRename, onSelected, active, setValue, values, errors }) {
    const [showEdit, setShowEdit] = useState(false)
    const [mode, setMode] = useState('')
    const [showUpdate, setShowUpdate] = useState(false)
    const [isActive, setIsActive] = useState(active)
    const [newSP, setNewSP] = useState(provider.id === -1 ? {} : provider)

    // const EditNameRef = useRef()
    const _handleSelected = () => { 
        onSelected(provider)
        // setIsActive(true); onSelected(provider) 
    }

    const _handleCrateSP = () => {
        onCreate(newSP)
    }
    const _handleDelete = () => {
        if (onDelete) {
            onDelete(provider.id)
                .then(() => {
                    setMode('')
                })
        }
    }
    const _handleRename = () => {
        if (onRename) {
            onRename(newSP)
                .then(() => {
                    setMode('')
                })
        } else setMode('')
    }


    useEffect(() => { setIsActive(active) }, [active])
    useEffect(() => {
        if (isActive && provider.id === -1) {
            setShowEdit(true);
            // setTimeout(() => { EditNameRef.current.select() }, 300)
        } else if (isActive) {
            setShowUpdate(true);
        }
        else {
            setShowEdit(false); setShowUpdate(false);
            // setValue({ serviceProviderService: undefined })
        }
    }, [isActive])

    return (
        <div style={{ cursor: 'pointer' }} onClick={() => _handleSelected()} className={'rounded col-lg-3 p-3 '.concat(isActive ? 'bg-primary-container shadow ' : 'bg-secondary-container')}>
            <div className='d-flex flex-row justify-content-center justify-items-center'>
                <Plugin className='me-1' size={25} />
                {mode === 'Rename' && provider.id !== -1 ?
                    <span className='flex-grow-1'>
                        <InputWithKeyFunctions
                            onChange={(v) => setNewSP({ ...newSP, name: v })}
                            onSubmit={(v) => _handleRename(v)} selected={true} onCancel={() => setMode('')} className='form-control form-control-sm' defaultValue={provider.name} />
                    </span>
                    : <div className='flex-grow-1' onClick={() => setMode('Rename')}>{provider.name}</div>}
                {provider.id !== -1 ? <div onClick={() => setMode('Delete')} className='flex-shrink-1'><Trash /></div> : <></>}
            </div>
            {
                mode === 'Delete'
                    ?
                    <div>
                        Are you sure you want to remove this provider?
                        <div className='p-1'>
                            <button onClick={() => _handleDelete()} className='btn btn-xs'>Yes</button>
                            <button onClick={() => setMode('')} className='btn btn-xs'>No</button>
                        </div>
                    </div>
                    :
                    <div className={'m-2 '.concat(showUpdate ? '' : 'd-none')}>
                        <div>
                            <ServiceProviderServices errors={errors} active={isActive} setValue={setValue} values={values} provider={provider} />
                        </div>
                    </div>
            }

            {
                <div className={'m-2 '.concat(showEdit ? '' : 'd-none')}>
                    <InputWithKeyFunctions
                        value={provider.id === -1 ? newSP.name : ''}
                        onChange={(v) => provider.id === -1 ? setNewSP({ name: v }) : undefined}
                        onSubmit={(v) => _handleCrateSP()}
                        // onCancel={() => setIsActive(false)}
                        selected={isActive}
                        className='form-control form-control-sm'
                        placeholder='Provider Name' />
                    <div>
                        <button onClick={() => _handleCrateSP()} className='btn btn-xs'>Create</button>
                    </div>
                </div>
            }
        </div>
    )
}

/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from 'react';
import WorkFlowConfigurationContext from '../../../workflow/configuration/WorkFlowConfigurationProvider';
import ToDoSelector from './Components/ToDoSelector';

export default function EventTriggerToDoListCompleted({ link, type, values, eventId, filterTrigger }) {
    const PROPERTY_TYPE = 'to_do_list_completed';
    const { saveProperties, loadProperties } = useContext(WorkFlowConfigurationContext)

    const DEFAULTS = { listDetails: undefined, whichList: "All Lists" }

    const [properties, setProperties] = useState(DEFAULTS)

    useEffect(() => {
        const _load = async () => setProperties(await loadProperties(PROPERTY_TYPE, link, DEFAULTS))
        _load()
    }, [])
    useEffect(() => { if (properties) saveProperties(PROPERTY_TYPE, link, properties) }, [properties])

    const _handleSetGroup = (e) => {
        setProperties({
            ...properties,
            listDetails: e,
        })
    }

    const _handleSetListFilter = (e) => {
        setProperties({
            ...properties,
            whichList: e.target.value,
        })
    }

    return (
        <div className='d-flex'>

            <div className='w-50'>
                <b>On List</b>
                <select onChange={_handleSetListFilter} className='form-control form-control-sm'>
                    <option>All Lists</option>
                    <option selected={properties && properties.whichList && properties.whichList === "A Specific List"}>A Specific List</option>
                </select>
            </div>
            <div className='w-50'>
                {properties && properties.whichList && properties.whichList === "A Specific List" &&
                    <ToDoSelector
                        mode={'no-status'}
                        defaultValue={properties && properties.listDetails}
                        onChange={_handleSetGroup}
                    />
                }
            </div>
        </div>
    )
}

import React, { Component } from 'react';
import Authentication from '../../libs/api/services/Authentication';
import LoginUser from './user/LoginUser';

export default class LoginSectionLayout extends Component {

    componentDidMount() {
        Authentication
            .logout()
    }
    render() {
        return (
            <LoginUser expired={this.props.expired} />
        )
    }
}

import { faSpinner, faTimesCircle } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useEffect, useState } from 'react'
import { Clipboard2Check, Clipboard2XFill, ClipboardCheckFill, ClipboardPlusFill, ExclamationCircleFill, Headset, InfoCircleFill } from 'react-bootstrap-icons'
import Authentication from '../../../../../../libs/api/services/Authentication'
import CustomCheck from '../../../../patient/management/components/CustomCheck'
import WorkFlowExecTask from './Tasks/WorkFlowExecTask'
import WorkFlowRunTask from './Tasks/WorkFlowRunTask'
import WorkFlowTasks from './Tasks/WorkFlowTasks'

export default function WorkFlowAssistantPopup({ onClose, eventId, patientId }) {
    const [mode, setMode] = useState('')
    const [parameters, setParameters] = useState({})
    


    useEffect(() => {
        if (eventId)
            _loadEvents()
    }, [eventId])

    const _loadEvents = () => {
        _changeMode('SHOW_TASKS')
    }

    const _handleRunTask = (taskid) => {
        setMode('LOADING')
        setTask({ id: taskid })
        _changeMode('RUN_TASK')
        /* TaskEvents
             .CheckPreConfig(taskid, patientId, Authentication.getFacility())
             .then((r) => {
                 setPreConfig({
                     taskId: taskid, config: r, confirm: (values) => {
                         // triggerTask(taskid, id, values)
                     }
                 })
                 var _issues = []
                 for (const sect of r.sections) {
                     if (sect.properties && sect.properties[0].issue && sect.properties[0].issue.severity === "Critical") {
                         _issues.push(sect.properties[0].issue)
                     }
 
                     if (sect.properties)
                         for (const property of sect.properties) {
                             if (property.options)
                                 for (const options of property.options) {
                                     if (options.issue)
                                         _issues.push(options.issue)
                                     if (property.options.length === 1 && property.varName) {
                                         var v = { ...values }
                                         v[property.varName] = options.id
                                         setValues({ ...v })
                                     }
                                 }
                             if (property.issue)
                                 _issues.push(property.issue)
                         }
                 }
                 setPreConfigIssues(_issues)
                 _changeMode('SHOW_TASKS')
                 // alert('Show COnfig ' + JSON.stringify(r))
             }).catch((e) => { setError(e); _changeMode('ERRORS') })
         // .catch((e) => { if (e.errors) SetError(JSON.stringify(e.errors)); else SetError(e.message); })
 */
    }


    const _handlePreConfigComplete = () =>
    {
        _changeMode('EXEC_TASK')
    }





    const [task, setTask] = useState()
    const [events, setEvents] = useState('')
    const [transition, setTransition] = useState('')
    const [preConfig, setPreConfig] = useState()
    const [preConfigIssues, setPreConfigIssues] = useState([])
    const [error, setError] = useState('')
    const [status, setStatus] = useState('')
    const [values, setValues] = useState({})
    const [details, setDetails] = useState([])
    //useState({ "taskId": "a1d63393-1613-4a76-2427-08dbea9ae3ab", "config": { "title": "Sending Message", "description": "Confirm all details are correct for sending message Test Fail Message", "sections": [{ "title": "Account", "description": "Which account is this message going to be sent from?", "properties": [{ "varName": "connectionId", "type": "Select", "options": [{ "id": "fa27869b-7777-40d9-42d1-08dbeaae3415", "value": "Demo Hospital WhatsApp Help Line" }] }] }, { "properties": [{ "name": "Message Type", "type": "Text", "options": [{ "value": "WhatsApp" }] }, { "name": "Subject", "type": "Text", "options": [{ "value": "Test" }] }, { "type": "Input", "issue": { "issue": "There is no WhatsApp number linked to Patient Admin", "options": [{ "name": "Notify recipient to update their details", "type": "Check", "options": [{ "id": "Notify", "value": "Yes", "issue": "Yes" }] }, { "name": "Skip this recipient", "type": "Check", "options": [{ "id": "Notify", "value": "Yes", "issue": "Yes" }] }] } }] }, { "properties": [{ "name": "Message Type", "type": "Text", "options": [{ "value": "WhatsApp" }] }, { "name": "Subject", "type": "Text", "options": [{ "value": "Test" }] }, { "type": "Input", "issue": { "issue": "There is no WhatsApp number linked to justin justin", "options": [{ "name": "Notify recipient to update their details", "type": "Check", "options": [{ "id": "Notify", "value": "Yes", "issue": "Yes" }] }, { "name": "Skip this recipient", "type": "Check", "options": [{ "id": "Notify", "value": "Yes", "issue": "Yes" }] }] } }] }] } })

    // useEffect(() => {
    //     _changeMode('SHOW_TASKS')
    // }, [])


    const _handleTriggerEvent = () => {
        /*  _changeMode('EXECUTE_EVENT')
          TaskEvents
              .TriggerTask(task.id, patientId, values, Authentication.getFacility())
              .then((e) => {
                  setStatus(e)
                  _loadEvents()
                  _changeMode('SHOW_EVENTS')
              })
              .catch((e) => { setError(e); _changeMode('ERRORS') })*/

    }

    const _handleShowDetails = (triggerId) => {
        // _changeMode('EXECUTE_EVENT')
        /*TaskEvents
            .GetDetails(triggerId)
            .then((r) => {
                setDetails(r)
                _changeMode('DETAILS')
            })
            .catch((e) => { setError(e); _changeMode('ERRORS') })*/

    }
    const renderPreConfigPropertyOptions = (type, options) => {
        const ret = []
        if (options)
            for (const option of options) {
                switch (type) {
                    case 'Check':
                        ret.push(<CustomCheck />)
                    // default:
                    //     ret.push(option.value)
                }
                ret.push(<div dangerouslySetInnerHTML={{ __html: option.value }}></div>)
            }
        return ret;
    }
    const _HandleAddressIssue = (option, value) => {
        var _preConfigIssues = preConfigIssues

        _preConfigIssues.find(e => e.id === option.id).value = value
        setPreConfigIssues([..._preConfigIssues])
    }
    const renderPreConfigIssueOptions = (options) => {
        // name | type | options
        const ret = []
        if (options)
            for (const option of options) {
                switch (option.type) {
                    case 'Check':
                        ret.push(<div className='opacity-100'>
                            <div><CustomCheck onChange={(value) => _HandleAddressIssue(option, option.options[0].value)} name={option.name} /></div>
                        </div>)
                    // default:
                    //     ret.push(<div className='opacity-100'>{option.name}
                    //         <div>{renderPreConfigPropertyOptions(option.type, option.options)}</div>
                    //     </div>)
                }
            }
        return ret
    }

    const icon = (i) => {
        switch (i) {
            case 'No Account':
                return <Headset size={30} />
        }
    }


    const renderPreConfigSectionIssue = (issue) => {
        return (
            <div className='text-center bg-danger p-3 opacity-75'>{issue.issueIcon ? icon(issue.issueIcon) : ''}
                <div dangerouslySetInnerHTML={{ __html: issue.issue }}></div>
            </div>
        )
    }
    const renderPreConfigProperties = (props) => {
        // varName | type | options [{ id | value |  }]
        if (props.length === 1
            && props[0].options
            && props[0].options.length === 1)
            if (props[0].issue)
                return renderPreConfigSectionIssue(props[0].issue)
            else
                return props[0].options[0].value

        const ret = []
        for (const prop of props) {
            ret.push(
                <div className='d-flex'>
                    {
                        prop.issue
                            ?
                            <div className='text-center pt-1 rounded w-100'>
                                <div className='d-flex justify-content-center'>
                                    <ExclamationCircleFill className='mx-2 mt-1' />
                                    <div>{prop.issue.issue}</div>
                                </div>
                                {
                                    prop.issue.options
                                        ?
                                        <div className='d-flex justify-content-evenly'>
                                            {renderPreConfigIssueOptions(prop.issue.options)}
                                        </div>
                                        :
                                        <></>
                                }
                            </div>
                            :
                            <>
                                <div className='me-2'>
                                    <b>{prop.name} : </b>
                                </div>
                                <div>
                                    {renderPreConfigPropertyOptions(prop.options.type, prop.options)}
                                </div>
                            </>
                    }
                </div>
            )
        }
        return ret;
    }
    const renderPreConfig = () => {
        if (!preConfig) return []
        const ret = []
        const sections = []

        for (const sect of preConfig.config.sections) {

            if (sect.properties && sect.properties[0].issue && sect.properties[0].issue.severity === "Critical") {
                sections.push(renderPreConfigSectionIssue(sect.properties[0].issue))
                break;
            }
            sections.push(<div>
                <div>
                    <b>{sect.title}</b>
                </div>
                <div className='m-0'>
                </div>
                {
                    sect.properties.length === 1
                        && sect.properties[0].options
                        && sect.properties[0].options.length === 1
                        && !sect.properties[0].issue
                        ?
                        sect.properties[0].options[0].value
                        :
                        <>
                            <div>
                                <p>{sect.description}</p>
                            </div>
                            <small>
                                <div className=' p-1 bg-secondary-container rounded'>
                                    {renderPreConfigProperties(sect.properties)}
                                </div>
                            </small>
                        </>
                }
            </div>)
        }

        ret.push(<div className='text-center'>
            <div>
                <ClipboardCheckFill size={45} />
            </div>
            <b>{preConfig.config.title}</b>
            <p>{preConfig.config.description}</p>
            {sections}
            <div className='mt-2'>
                <button
                    onClick={(e) => _changeMode('SHOW_EVENTS')}
                    className='btn'>Cancel</button>
                {_checkErrors() ? <button
                    onClick={(e) => _handleTriggerEvent()}
                    className='btn'>Next</button> : <></>}
            </div>
        </div>)
        return ret
    }

    const _checkErrors = () => {
        for (const issue of preConfigIssues) {
            if (!issue.value)
                return false
        }

        return true
    }

    const _handleReturnedErrors = (e) => {
        switch (e.type) {
            case 'DrData.Services.Messaging.WhatsApp.Exceptions.InvalidWhatsAppAccountDetailsException':
                return <div>
                    <div>
                        Unable to send messages due to your WhatsApp account configuration being invalid or incomplete
                    </div>
                    {
                        Authentication.isClaimValid("System Admin")
                            ?
                            <button className='btn btn-sm' onClick={(e) => window.location = '/sys-admin/messaging/wa'}>Go to WhatsApp Configuration</button>
                            :
                            <div className='pt-2'>Please contact your system administrator for assistance</div>
                    }
                </div>
            case 'DrData.Services.Messaging.SMS.Exceptions.InvalidSMSAppAccountDetailsException':
                return <div>
                    <div>
                        Unable to send messages due to your SMS account configuration being invalid or incomplete
                    </div>
                    {
                        Authentication.isClaimValid("System Admin")
                            ?
                            <button className='btn btn-sm' onClick={(e) => window.location = '/sys-admin/messaging/sms'}>Go to SMS Configuration</button>
                            :
                            <div className='pt-2'>Please contact your system administrator for assistance</div>
                    }
                </div>
            case 'DrData.Services.Messaging.Email.Exceptions.InvalidEmailAccountDetailsException':
                return <div>
                    <div>
                        Unable to send messages due to your Email account configuration being invalid or incomplete
                    </div>
                    {
                        <button className='btn btn-sm' onClick={(e) => window.location = Authentication.getFacilityRole() + '/Configuration/email'}>Go to your Email Configuration</button>
                    }
                </div>
            default:
                return e.message;
        }
    }

    const _checkLogs = (trigger, id) => {
        var ret = []
        if (!trigger.logs)
            return <></>

        const critical = trigger.logs.filter(t => t.status === "Critical")
        const success = trigger.logs.filter(t => t.status === "Success")
        ret.push(<div style={{ color: '#915151' }} className='me-1'><ExclamationCircleFill className='me-1' />{critical.length}</div>)
        ret.push(<div style={{ color: '#3b73a0' }} className=''><InfoCircleFill className='me-1' />{success.length}</div>)
        // for(const log of trigger.logs)
        // {
        //     ret.push(<div>{log.id}</div>)
        // }
        return <button
            onClick={(e) => _handleShowDetails(trigger.id)}
            className='d-flex w-100 btn' style={{ fontSize: '0.8rem' }}>Details :&nbsp;{ret}</button>
    }
    const render = () => {
        const ret = []
        switch (mode) {
            case 'LOADING':
                ret.push(<div className='text-center'>{<FontAwesomeIcon className='text-light' icon={faSpinner} spin />}</div>)
                break;
            case 'SHOW_EVENTS':
                // var container = []
                // container.push(<div className='text-center'>
                //     <Clipboard2Check size={45} />
                //     <p>Select the tasks you would like to complete</p>
                // </div>)
                // for (const evt of events) {
                //     container.push(<div
                //         style={{ cursor: 'pointer' }}
                //         className='ms-3 align-self-start d-flex '
                //         key={evt.id}>
                //         <div
                //             onClick={(e) => _handleRunTask(evt.id)}>
                //             {
                //                 evt.triggers && evt.triggers.length > 0 && evt.triggers[0].completedDate
                //                     ?
                //                     <Check2Square className='me-2' size={30} />
                //                     :
                //                     evt.triggers && evt.triggers.length > 0 && !evt.triggers[0].completedDate
                //                         ?
                //                         <DashSquare className='me-2' size={30} />

                //                         :
                //                         <SquareFill className='me-2' size={30} />
                //             }
                //         </div>
                //         <div>
                //             <b
                //                 onClick={(e) => _handleRunTask(evt.id)}>{evt.taskName}</b>
                //             {
                //                 evt.triggers && evt.triggers.length > 0
                //                     ?
                //                     <div className=''>
                //                         <small onClick={(e) => _handleRunTask(evt.id)}  ><Person />{evt.triggers[0].triggeredBy ? evt.triggers[0].triggeredBy.firstName + ' ' + evt.triggers[0].triggeredBy.lastName + ' ran this task on ' : 'Task ran on '}  {evt.triggers[0].triggeredDate.substring(0, 19)}</small>
                //                         <div className='d-flex justify-content-center'>
                //                             {
                //                                 _checkLogs(evt.triggers[0], evt.id)
                //                             }
                //                         </div>
                //                     </div>
                //                     :
                //                     <div onClick={(e) => _handleRunTask(evt.id)} className=''>
                //                         <small className=''><Check2All /> This task has not yet been run</small>
                //                     </div>
                //             }
                //         </div>
                //     </div>)
                // }
                // ret.push(<div className={' '.concat(transition === 'SHOW_EVENTS' ? 'qt-slide-in' : 'qt-slide-out')}>{container}</div>)
                break;
            case 'SHOW_TASKS':
                return <div className={'d-flex flex-column h-100  '.concat(transition === mode ? 'qt-slide-in' : 'qt-slide-out')}><WorkFlowTasks patientId={patientId} onTaskSelected={(t) => _handleRunTask(t)} eventId={eventId} /></div>
            case 'RUN_TASK':
                return <div className={'d-flex flex-column h-100 '.concat(transition === mode ? 'qt-slide-in' : 'qt-slide-out')}><WorkFlowRunTask patientId={patientId} onCancel={() => { _changeMode('SHOW_TASKS') }} onComplete={() => _handlePreConfigComplete()} eventId={eventId} task={task} onSetParam={(p) => setParameters({...parameters,...p})} /></div>
            case 'EXEC_TASK':
                return <div className={'d-flex flex-column h-100 '.concat(transition === mode ? 'qt-slide-in' : 'qt-slide-out')}><WorkFlowExecTask patientId={patientId} onCancel={() => { _changeMode('SHOW_TASKS') }} onComplete={() => _handlePreConfigComplete()} eventId={eventId} task={task} parameters={parameters} /></div>

                    
            case 'EXECUTE_EVENT':
                var container = []
                container.push(
                    <div className='text-center'><ClipboardPlusFill size={45} /><p>Processing Tasks</p><FontAwesomeIcon icon={faSpinner} spin /></div >

                )
                ret.push(<div className={' '.concat(transition === 'EXECUTE_EVENT' ? 'qt-slide-in' : 'qt-slide-out')}>{container}</div>)
                break;
            case 'DETAILS':
                var container = []
                var _logs = []
                for (const log of details) {

                    _logs.push(<small className={'d-flex align-items-center '.concat(log.status === "Critical" ? 'text-danger' : 'text-success')} >
                        <div>{
                            log.status === "Critical"
                                ?
                                <ExclamationCircleFill className='m-1' />
                                :
                                <InfoCircleFill className='m-1' />
                        }</div>
                        <div className='pe-2'>{log.executedDateTime.substring(0, 19)}</div>
                        <div>{log.description}</div>
                    </small>)
                }
                container.push(
                    <div className='text-center'><ClipboardPlusFill size={45} /><p>Event Details</p>
                        {
                            !details
                                ?
                                <div>No Details Available</div>
                                :
                                <div className='bg-light p-2 shadow rounded '>
                                    {_logs}
                                </div>
                        }
                        <button
                            onClick={(e) => _loadEvents()}
                            className='btn mt-2'>Back to task list</button>
                    </div>
                )
                ret.push(<div className={' '.concat(transition === 'DETAILS' ? 'qt-slide-in' : 'qt-slide-out')}>{container}</div>)
                break;
            case 'ERRORS':
                var container = []
                container.push(
                    <div style={{ maxWidth: '500px' }} className='text-center'><Clipboard2XFill size={45} /><p>Error Processing Task</p><div className='bg-danger m-2 p-2 rounded shadow opacity-75'>{_handleReturnedErrors(error)}
                    </div><div>
                            <button
                                onClick={(e) => _loadEvents()}
                                className='btn'>Back to task list</button></div></div>

                )
                ret.push(<div className={' '.concat(transition === 'ERRORS' ? 'qt-slide-in' : 'qt-slide-out')}>{container}</div>)
                break;
            case 'COMPLETE':
                var container = []
                container.push(
                    <div className='text-center'><Clipboard2Check size={45} /><p>Task Complete</p><button
                        onClick={(e) => _changeMode('SHOW_EVENTS')}
                        className='btn'>Back to task list</button></div >

                )
                ret.push(<div className={' '.concat(transition === 'COMPLETE' ? 'qt-slide-in' : 'qt-slide-out')}>{container}</div>)
                break;
            default:
                <div></div>
                break;
        }
        return <div className='slide'>{ret}</div>
    }

    const _changeMode = (mode) => {
        setTransition(mode)
        if (mode === 'SHOW_EVENTS') {
            setTimeout(setMode, 300, mode)
        }
        else
            setTimeout(setMode, 300, mode)
    }

    const _handleOnClose = () => {
        setMode('')
        onClose()
    }

    return (
        <div
            className='me-2'
            style={{ position: 'absolute', right: '10px', padding: '8px' }}>
            <div className='m-3 p-3 d-flex flex-column bg-primary-container rounded shadow trigger-poppup' style={{ position: 'relative', zIndex: 99, minWidth: '50vw',height:'75vh' }}>
                <FontAwesomeIcon
                    onClick={(e) => _handleOnClose()}
                    className='text-dark'
                    style={{ position: 'absolute', right: '0px', top: '-21px' }}
                    icon={faTimesCircle}
                    size={'2x'} />
                {render()}
            </div>
        </div>
    )
}

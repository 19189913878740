import React, { useEffect, useRef, useState } from 'react'
import { PlusCircle, Trash } from 'react-bootstrap-icons'

export default function LabelsOptions({ defaultLabels, onChange }) {
    const [labels, setLabels] = useState(defaultLabels ? defaultLabels : [])
    const labelRef = useRef()
    const isMounting = useRef(true)

    useEffect(() => { if (defaultLabels) setLabels(defaultLabels) }, [defaultLabels])

    useEffect(() => {
        isMounting.current = false
        /// Handle on load stuff here
        return () => { isMounting.current = true }
    }, [])

    const _handleAddLabel = () => {
        if (labelRef.current.value === '') return
        const _labels = [...labels, labelRef.current.value.trim()]
        setLabels(_labels)
        labelRef.current.value = ''
        if (onChange) onChange(_labels)
    }
    const _handleRemoveLabel = (idx) => {
        var _labels = [...labels]
        _labels.splice(idx, 1)
        setLabels(_labels)
        if (onChange) onChange(_labels)
    }
    return (
        <>
            <b>Labels</b>
            <div>
                {
                    labels.map((label, idx) =>
                        <span key={label} className='label label-info bg-secondary-container rounded rounded-4 ps-2 d-flex align-items-center'>{label}<Trash onClick={() => _handleRemoveLabel(idx)} className='ms-2' size={13} /></span>
                    )
                }
                <div>
                    <input ref={labelRef} className='form-control form-control-sm' placeholder='New Label' />
                    <div onClick={() => _handleAddLabel()}><PlusCircle />Add Label</div>
                </div>
            </div>
        </>
    )
}

import { faSpinner } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useEffect, useRef, useState } from 'react'
import { CheckCircleFill, NodePlus } from 'react-bootstrap-icons'
import Authentication from '../../../../../../../libs/api/services/Authentication'
import WorkFlow from '../../../../../../../libs/api/services/WorkFlow'
import WorkFlowLogs from './Logs/WorkFlowLogs'
import RunTaskSection from './RunTask/RunTaskSection'

export default function WorkFlowExecTask({ onCancel, onComplete, patientId, task, parameters }) {
    const [loading, setLoading] = useState(true)
    const [configuration, setConfiguration] = useState()
    const [logs, setLogs] = useState()
    useEffect(() => { _loadTaskConfiguration(); return () => { completedRef.current = false } }, [])
    const completedRef = useRef(false)

    // const _exLogs = [{ "id": "00000000-0000-0000-0000-000000000000", "description": "Preparing to send internal message to Patient Admin", "executedDateTime": "2024-01-21T09:32:08.0162362+02:00", "status": "Success" }, { "id": "00000000-0000-0000-0000-000000000000", "description": "Message Sent", "executedDateTime": "2024-01-21T09:32:08.2256844+02:00", "status": "Success" }, { "id": "00000000-0000-0000-0000-000000000000", "description": "Preparing to send internal message to Doc Doc", "executedDateTime": "2024-01-21T09:32:08.2525188+02:00", "status": "Success" }, { "id": "00000000-0000-0000-0000-000000000000", "description": "Message Sent", "executedDateTime": "2024-01-21T09:32:08.2686849+02:00", "status": "Success" }]

    const _loadTaskConfiguration = () => {
        if (completedRef.current === false)
            WorkFlow
                .ProcessTask(task.id, {
                    user: Authentication.UserId(),
                    patient: patientId,
                    facility: Authentication.getFacility(),
                    userDefinedParms: parameters
                })
                .then((r) => {
                    setLoading(false)
                    setLogs(r)
                    completedRef.current = true;
                }).catch((e) => { setLoading(false); setConfiguration({ sections: [{ layout: [{ issues: { severity: 'Critical', issue: e, icon: 'system' } }] }] }) })
        else
            setLoading(false)
    }

    /*
        Render
    */
    return (
        <div className='w-100'>
            <>
                <NodePlus size={50} />
                <h3>Processing Task</h3>
            </>
            {
                configuration ? <>{configuration.sections.map((s, i) =>
                    <RunTaskSection show={true} section={s} index={i} length={configuration.sections.length} />
                )}
                </>
                    :
                    loading
                        ?
                        <div><FontAwesomeIcon icon={faSpinner} spin className='me-2' />Processing</div>
                        :
                        <div>
                            <div><CheckCircleFill /> Task complete</div>
                        </div>
            }
            <WorkFlowLogs logs={logs} />
            {
                loading
                    ?
                    <></>
                    :
                    <div>
                        <button className='btn m-2' onClick={() => onCancel()}>Back to task list</button>
                    </div>
            }
        </div>
    )
}

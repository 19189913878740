import React, { useEffect, useState } from 'react'
import { CheckSquare, Square } from 'react-bootstrap-icons'
import { DoubleClick } from '../../../../../../components/Hooks/DoubleClick'
import SearchComponent from '../../../../../../components/search/SearchComponent'
import ToDoListService from '../../../../../../libs/api/services/ToDoListService'

export default function ToDoListAsistantSelection({ onSelected, onSubmit }) {
    const [items, setItems] = useState([])
    const [item, setItem] = useState({ assisted: false, user: undefined })
    const [users, setUsers] = useState([])
    const _loadUsers = () => {
        ToDoListService
            .FindAvailableUsers()
            .then((r) => setUsers(r))
    }

    useEffect(() => { if (item) onSelected(item) }, [item])

    useEffect(() => { _loadUsers() }, [])

    return (
        <div className='p-2 bg-secondary-container rounded shadow m-2'>
            <div className='d-flex justify-content-evenly'>

                <button onClick={() => setItem({ ...item, assisted: false })} className={'m-2 p-2 rounded w-25 btn '.concat(!item.assisted ? ' shadow bg-primary-container ' : ' opacity-75')}>
                    <DoubleClick onClick={() => setItem({ ...item, assisted: false })} onDoubleClick={() => onSubmit({ ...item, assisted: false })}>
                        {item.assisted ? <Square className='me-2' /> : <CheckSquare className='me-2' />}No
                    </DoubleClick>
                </button>

                <button onClick={() => setItem({ ...item, assisted: true, user: undefined })} className={'m-2 p-2 rounded w-25 btn '.concat(item.assisted ? ' shadow bg-primary-container ' : ' opacity-75')}>
                    {!item.assisted ? <Square className='me-2' /> : <CheckSquare className='me-2' />} Yes</button>
            </div>
            {
                item.assisted
                    ?
                    <div className='text-start'>
                        <div className='text-center'>Who will be assiting you?</div>
                        <SearchComponent
                            focusSearch={true}
                            defaultValue={item && item.user}
                            onSelected={(u) => setItem({ ...item, assisted: true, user: u })}
                            details={{
                                data: users,
                                options: {
                                    reRefKey: 'id',
                                    template: [{
                                        refKey: 'firstName',
                                        descr: '',
                                    }, {
                                        refKey: 'lastName',
                                        descr: '',
                                    }]
                                }
                            }} />
                    </div>
                    :
                    <></>
            }
        </div>
    )
}

import { faSpinner } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useContext, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import TNC from '../../../libs/api/services/TNC'
import CommonFunctionsContext from '../../common/CommonFunctionsProvider'

export default function ViewTerms({ facility }) {
    const { id } = useParams()
    const { showError } = useContext(CommonFunctionsContext)
    const [tnc, setTnc] = useState()
    const _handleSeletView = async (e) => {
        // Load tnc
        var t
        if (facility)
            t = await TNC.GetTermsAndConditionsForFacility(id).catch(e => showError(e))
        else
            t = await TNC.GetTermsAndConditions().catch(e => showError(e))
        if (t && t.length > 0)
            if (t.find(x => x.isDeleted === false))
                setTnc(t.find(x => x.isDeleted === false).data)
            else {
                showError('No terms and conditions are available at this point in time. Please try again later')
                setTnc('No TNC')
            }
        else {
            showError('No terms and conditions are available at this point in time. Please try again later')
            setTnc('No TNC')
        }

        // Convert the Base64 string back into binary data
        // const binaryData = atob(t.data);

        // Create a Blob from the binary data
        // const blob = new Blob([binaryData], { type: 'application/pdf' });

        // Generate a Blob-URI
        // const url = URL.createObjectURL(blob);

        // Open a new tab and set the iframe's src to the Blob-URI
        // setTnc(url)
        // const pdfWindow = window.open(url, '_blank');
    }

    useEffect(() => { _handleSeletView() }, [])
    return (
        tnc ? <embed
            className='w-100 h-100'
            src={`data:application/pdf;base64,${tnc}`} type='application/pdf' />
            :
            <div className='bg-secondary-container p-3 rounded shadow'><FontAwesomeIcon icon={faSpinner} spin /> Loading terms and conditions</div>
    )
}

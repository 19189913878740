import React from 'react'
import { ExclamationCircle } from 'react-bootstrap-icons'

export default function NoPermission() {
    return (
        <div className='position-fixed d-flex align-items-center justify-content-center  bg-dark w-100 h-100' style={{ left: '0px', right: '0px', top: '0px', bottom: '0px' }}>
            <div className='bg-primary-container p-3 rounded '>
                <div className='d-flex flex-column align-items-center'>
                    <ExclamationCircle size={50} />
                    <b className='p-2'>Restricted Area</b>
                    <p className=''>You do not have permission to access this function.</p>
                    <p className=''>Please contact your administrator if you believe you should have access</p>
                    <div>
                        <button onClick={() => window.location = '/Logout'} className='btn'>Ok</button>
                    </div>
                </div>
            </div>
        </div>
    )
}

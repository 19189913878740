import React, { useEffect, useRef, useState } from 'react'
import QBuilderEditWrapper from './widgets/QBuilderEditWrapper'
import QBuilderSideMenuContainer from './widgets/QBuilderSideMenuContainer'
import QBuilderGeneralQuestionInfo from './QBuilderGeneralQuestionInfo'
import QBuilderGenerics from '../QBuilderGenerics'
import QBuilderValidationWrapper from './Generic/QBuilderValidationWrapper'
import QBuilderComponentDynamicComponentTableLayout from './widgets/DynamicComponentTable/QBuilderComponentDynamicComponentTableLayout'
import QBuilderComponentDynamicComponentTableComponent from './widgets/DynamicComponentTable/QBuilderComponentDynamicComponentTableComponent'
import Questionnaire from '../../../../../libs/api/services/Questionnaire'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'

export default function QBuilderComponentDynamicComponentTable({ cache, state, activeQuestionnaireAssignment, edit, defaultMode, view,mode, onAction, idx, ComponentId, onSetData }) {
    const [smode, setSMode] = useState(edit ? 'Details' : '')
    const isMounted = useRef(false)
    const [responses, setResponses] = useState()
    const [loading, setLoading] = useState(true)
    const [properties, setProperties] = useState()
    const _MODES = ['Details', 'Layout']

    const _loadProperties = async () => {
        if (cache) { setProperties(cache.properties) } else {
            setProperties(await QBuilderGenerics.loadComponentProperties(ComponentId))
        }
    }
    const _loadResponses = async () => {

        try {
            const _resp = await QBuilderGenerics.loadComponentReponses(activeQuestionnaireAssignment.id, ComponentId)
            return setResponses(JSON.parse(_resp.answer))
        } catch (e) {
            // console.error('_loadResponses', e)
        }
        setLoading(false)
    }
    const _load = async () => {
        await _loadProperties()
        await _loadResponses()
        isMounted.current = true
        setLoading(false)
    }
    useEffect(() => {
        // onLoad
        _load()
    }, [])

    useEffect(() => {
        // Dont open edit window if not a new state
        if (defaultMode && defaultMode === 'Overview')
            setSMode('')
    }, [defaultMode])

    const _handleSetProperty = async (k, v) => setProperties(await QBuilderGenerics.setProperty(ComponentId, k, v))
    const _handlGetProperty = (k) => { try { return QBuilderGenerics.getProperty(properties, k) } catch (e) {  } }

    const _handleSetResponse = async (r, commit) => onSetData(ComponentId, JSON.stringify(r), commit)
    const _handlGetResponse = () => responses ? responses : []

    const _rendenderEdit = () => {
        var ret = []
        switch (smode) {
            case 'Details':
                ret = <QBuilderGeneralQuestionInfo
                    properties={properties}
                    onChanged={(k, v) => _handleSetProperty(k, v)} />
                break;
            case 'Layout':
                ret = <QBuilderComponentDynamicComponentTableLayout
                    onSetData={() => { }}
                    properties={properties}
                    onChanged={(k, v) => _handleSetProperty(k, v)}
                />
                break;
            default:
                return <></>
        }
        return (

            <QBuilderSideMenuContainer onAction={onAction} idx={idx} isNew={false} onClose={() => setSMode('')} subMenus={_MODES} mode={mode} setMode={(m) => setSMode(m)}>
                {ret}
            </QBuilderSideMenuContainer>
        )
    }

    const _rendenderMain = () => {
        var _ret = []
        try {
            const _lineItem = QBuilderGenerics.getProperty(properties, 'Items')
            if (!_lineItem) throw Error('Component Details Not Available')
            _ret = <QBuilderComponentDynamicComponentTableComponent
                activeQuestionnaireAssignment={activeQuestionnaireAssignment}
                onResepone={(r) => _handleSetResponse(r, true)}
                mode={mode}
                responses={_handlGetResponse()}
                items={_lineItem}
                state={state}
            />
        } catch (e) {
            //_ret = <div className='text-center text-danger'><ExclamationDiamondFill /> Component Details Not Available</div>
            _ret = <></>
        }
        return (<QBuilderEditWrapper
            enable={edit}
            isEditing={smode !== ''}
            onEdit={() => setSMode('Details')}>
            <QBuilderValidationWrapper
                required={_handlGetProperty('Required')}
                question={_handlGetProperty('Question')}
            >
                {_ret}
            </QBuilderValidationWrapper>
        </QBuilderEditWrapper>)
    }

    const _render = () => {
        return (
            <>
                {_rendenderEdit()}
                {_rendenderMain()}
            </>
        )
    }
    // if(!properties) return <></>
    if (loading) return <div className='text-center'><FontAwesomeIcon icon={faSpinner} spin /></div>
    return (
        <div>
            {_render()}
        </div>
    )
}

import React, { useState } from 'react'
import { EyeFill, EyeSlashFill, PlusSlashMinus, TagFill } from 'react-bootstrap-icons'

export default function StatsSectionMeta({ meta }) {

    if (!meta) return <></>

    return (
        <div>
            <h3>Overview</h3>
            <div className='row'>
                {
                    meta.totalWeighting ? <div className='col-3'><PlusSlashMinus className='me-1' />Total Weight<div> {meta.totalWeighting}</div></div> : <></>
                }
                {/* {
                    meta.totalWeightingLabel ? meta.totalWeightingLabel.map((w) =>
                        <div className='col-3'><TagFill className='me-1' />{w.label}<div>{w.total}</div></div>
                    ) : <></>
                } */}
            </div>
        </div>
    )
}

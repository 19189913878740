import React, { Component } from 'react';
import { Building } from 'react-bootstrap-icons';

export default class CardButton extends Component {
    render() {
        if (this.props.children.length < 1)
            return <></>;
        return (
            <div className={'d-flex bg-buttons flex-column h-100 justify-content-space-between align-items-center p-3 '.concat(this.props.tail.length > 0 ? ' rounded ' : ' btn ')} onClick={(e) => this.props.onClick()}>
                <div className='flex-grow-1 d-flex p-3'><Building size={'auto'} className='align-self-center' /></div>
                <div className='flex-grow-2 text-center pt-3 w-100'>{this.props.children}</div>
                <div className='flex-grow-2 text-center pt-3 w-100'>{this.props.tail.length > 0 ? this.props.tail : <div className='p-2 m-1'>&nbsp;</div>}</div>
            </div>

        )
    }
}

import React, { useContext, useState } from 'react'
import ErrorDialog from '../../../../components/dialogs/ErrorDialog'
import ToastDialog from '../../../../components/dialogs/toast_dialog/ToastDialog'
import StickyNavigationContext from '../../../../libs/providers/StickyNavigationProvider'
import UserEmailDetails from './facility/wizard/UserEmailDetails'

export default function SystemMessagingAccounts({ startStep }) {
    const [step, setStep] = useState(0)
    const [title, setTitle] = useState('')
    const [description, setDescription] = useState('')
    const [navigation, setNavigation] = useState([])
    const [errors, setErrors] = useState('')
    const [values, setValues] = useState({})
    const [status, setStatus] = useState({})
    const [history, setHistory] = useState({})
    const [navigationContext, setNavigationContext] = useState('Start')
    const { setSticky, setPreventMessage } = useContext(StickyNavigationContext)

    return (
        <>
            <ToastDialog {...status} />
            <ErrorDialog error={errors} onClose={() => setErrors('')} humanize={true} />
            <div
                className="Wizard top-center position-relative">
                <div className="head"><h1>System Messaging Account Setup</h1>
                    <p>Please enter the details for the system main email account. This will be used to send out system emails like forgot password requests</p>

                    <div
                        className="error-details"></div>
                </div>
                <div className="body" style={{ overflowX: 'hidden', overflowY: 'auto' }}>
                    <div
                        className=".section d-flex flex-column align-items-stretch h-100 w-100">
                        <UserEmailDetails
                            title={(t) => setTitle(t)}
                            description={(t) => setDescription(t)}
                            navigation={(t) => setNavigation(t)}
                            errors={(e) => setErrors(e)}
                            setValue={(v) => setValues({ ...values, ...v })}
                            values={values}
                        />
                    </div>
                </div>
                <div classname="tail">
                    {
                        navigation.map((n) => {
                            if (n.type === 'Next')
                                return <button
                                    key={n.text}
                                    onClick={async (e) => {
                                        if (n.action) {
                                            try {
                                                await n.action()
                                                    .catch(() => {
                                                        setErrors(e.message)
                                                        return
                                                    })
                                            } catch (e) {
                                                return
                                            }
                                        }
                                        setStep(step + 1)
                                    }} className="btn ng-buttons">{n.text}</button>
                            else if (n.type === 'Back')
                                return <button
                                    key={n.text}
                                    onClick={async (e) => {
                                        if (n.action) {
                                            await n.action().catch((e) => setErrors(e.message))
                                        }
                                        setStep(step - 1)
                                    }} className="btn ng-buttons">{n.text}</button>
                            else if (n.type === 'Action')
                                return <button
                                    key={n.text}
                                    onClick={async (e) => {
                                        if (n.action)
                                            await n.action()
                                                .catch((e) => setErrors(e.message))
                                    }} className="btn ng-buttons">{n.text}</button>
                            else if (n.type === 'Done')
                                return <button
                                    key={n.text}
                                    onClick={async (e) => {
                                        if (n.action)
                                            await n.action()
                                                .catch((e) => setErrors(e.message))
                                        setStep(1)
                                    }} className="btn ng-buttons">{n.text}</button>
                            else if (n.type === 'Step')
                                return <button
                                    key={n.text}
                                    onClick={async (e) => {
                                        if (n.action)
                                            await n.action()
                                                .catch((e) => setErrors(e.message))
                                        setStep(n.step)
                                    }} className="btn ng-buttons">{n.text}</button>
                        })
                    }
                </div>
            </div>
        </>
    )
}

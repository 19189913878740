import React, { Component } from 'react'
import './CardGrid.scss'

export default class CardGridLayout extends Component {
    render() {
        return (
            <div className='card-container w-100'>
                {
                    this.props.children.map((child) =>
                        <div key={child.id} className='card-item-padding h-100'>
                            <div className='card-item rounded shadow shadow-3  h-100'>
                                {child}
                            </div>
                        </div>
                    )
                }
            </div>
        )
    }
}

import React, { useEffect, useState } from 'react'
import { MenuAppFill, Trash } from 'react-bootstrap-icons'
import HintWidget from '../../../../../components/ui/hint/HintWidget'
import SlideContainerWidget from '../../../../../components/ui/slide_container/SlideContainerWidget'
import PatientHistoryEvent from '../../../../../libs/api/services/PatientHistoryEvent'
import PatientEventType from '../../history/components/PatientEventType'

export default function PatientProfileCategoryEvent({ categoryId }) {
    const [events, setEvents] = useState([])
    // const { } = useContext(PatientHistoryContext)
    const _handleAdd = (e) => {
        PatientHistoryEvent
            .LinkCategoryEvent(categoryId, e)
            .then((r) => {
                _load()
            })

    }

    const _load = async () => {
        PatientHistoryEvent
            .ListCategoryEventLinks(categoryId)
            .then((r) => {
                setEvents(r)
            })
    }

    const _handleRemove = async (id) => {
        PatientHistoryEvent
            .RemoveCategoryEventLinks(id)
            .then((r) => {
                _load()
            });

    }

    useEffect(() => {
        _load()
    }, [])

    return (
        <>
            <HintWidget hint={'This allows for adding shortcuts to patient events on the patient profile category form'}>
                <SlideContainerWidget title={<div className='d-flex align-items-center m-3'><MenuAppFill className='me-2' />Link Events</div>} icon={'plus'}>
                    <PatientEventType onChanged={(e) => _handleAdd(e)} />
                    <div>
                        {
                            events && events.length > 0 ?
                                events.map((e) =>
                                    e.event ?
                                        <div className='m-3 d-flex align-items-center justify-content-between' key={e.id}>
                                            <div className='text-start' key={e.id}>{e.event.event}</div>
                                            <Trash onClick={(c) => _handleRemove(e.id)} />
                                        </div>
                                        : <></>
                                ) : <></>
                        }
                    </div>
                </SlideContainerWidget>

            </HintWidget>
        </>
    )
}

/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from 'react'
import { ArrowLeft, Folder2Open } from 'react-bootstrap-icons'
import InputWithKeyFunctions from '../../../../../../components/inputs/InputWithKeyFunctions'
import WorkFlowConfigurationContext from '../../WorkFlowConfigurationProvider'
import WorkFlowEventItemComponent from './WorkFlowEventItemComponent'

export default function MessageEventFolderComponent({ selectOnly, setValue }
    // { category, onChangeCategory, onCreateEvent, errors, setValue, values, selectOnly }
) {
    const { category, selectCategory, loadEvents, events, setEvent, event, createEvent } = useContext(WorkFlowConfigurationContext)
    const [searchResults, setSearchResults] = useState([])

    const options = {
        primaryField: 'name',
    }

    useEffect(() => { loadEvents() }, [])
    useEffect(() => { _filterResult() }, [category, events])

    const _handleAdd = async (n) => {
        await createEvent(n)
        _filterResult("")
    }

    const _filterResult = (search) => {
        var _results = []
        for (const res of events) {
            const _tResName = res[options.primaryField]
            if (search && _tResName) {
                if (_tResName.toLowerCase() === search.toLowerCase()) {

                    _results.push({
                        result: _tResName.replaceJSX(search, <span className='bg-secondary-container rounded'>{search}</span>),
                        asText: _tResName,
                        exact: true,
                        object: res
                    })
                }
                else if (_tResName.toLowerCase().indexOf(search.toLowerCase()) > -1) {

                    _results.push({
                        result: _tResName.replaceJSX(search, <span className='bg-primary-container rounded'>{search}</span>),
                        asText: _tResName,
                        exact: false,
                        object: res
                    })
                }
            } else {
                // All results
                _results.push({
                    result: <div className=''>{res[options.primaryField]}</div>,
                    asText: _tResName,
                    isNew: false,
                    object: res
                })
            }
        }
        if
            (
            !(_results.length === 1 && _results[0].exact)
        )
            _results.push({
                result: <div className=''></div>,
                asText: '',
                isNew: true,
                object: { id: -1, name: '', rand: new Date() }
                // result: <div className=''>Create {search}</div>,
                // asText: search ? search : '',
                // isNew: true,
                // object: { id: -1, name: search }
            })
        setSearchResults(_results)
    }

    const _handleSelected = (e) => {
        if (selectOnly) {
            if (e && e.object)
                setEvent({ event: e.object })
            else
                setEvent({ event: e })
            try {
                setEvent(e.object)
            } catch (e) { }
            return
        }
        if (e) {
            if (e && e.object) {
                setEvent({ event: e.object })
                setValue({ event: e.object })
            }
            else {
                setEvent({ event: e })
                setValue({ event: e })
            }
            try {
                setEvent(e.object)
                setValue({ event: e.object })
            } catch (e) { }
        }
    }

    const _handleShowLogs = (e) => {
        if (e && e.object)
            setValue({ event: e.object, action: { to: 6, from: 1 } })
        else
            setValue({ event: e, action: { to: 6, from: 1 } })
    }
    const _handleChangeTrigger = (e) => {
        if (e && e.object)
            setValue({ event: e.object, action: { to: 2, from: 1 } })
        else
            setValue({ event: e, action: { to: 2, from: 1 } })
    }
    const _handleChangeTask = (e) => {
        if (e && e.object)
            setValue({ event: e.object, action: { to: 3, from: 1 } })
        else
            setValue({ event: e, action: { to: 3, from: 1 } })
    }
    if (!category.object) return
    return (
        <div>
            <div className='bg-primary-container p-1 rounded shadow'>
                <div className='d-flex'>
                    <div style={{ cursor: 'pointer' }}
                        onClick={() => selectCategory()}
                        className='flex-shrink-1 '><ArrowLeft /> Change Category</div>
                    <div className='flex-grow-1 text-center'><b><Folder2Open className='me-1' />{category.object.name}</b></div>

                </div>
                <div>
                    <InputWithKeyFunctions onChange={(e) => _filterResult(e)} key={'fdsafdsa fasdfsafda'} className='form-control form-control-sm '
                        // onSubmit={() => { _handleEnter() }}
                        placeholder='Search workflow' />
                    {/* <input onChange={(e) => _filterResult(e.target.value)} className='form-control form-control-sm' placeholder='Search workflow' /> */}
                </div>
            </div>
            <div>
                <div className='p-2'>
                    {
                        searchResults.map((e) =>
                            <WorkFlowEventItemComponent
                                key={`WFI-${e.object.id}`}
                                selectOnly={selectOnly}
                                // values={values}
                                onCreateWorkFlow={(n) => _handleAdd(n)}
                                active={event && event.id === e.object.id}
                                onShowLogs={(e) => _handleShowLogs(e)}
                                onSelected={(e) => _handleSelected(e)}
                                onChangeTask={(e, t) => _handleChangeTask(e, t)}
                                onChangeTrigger={(e, t) => _handleChangeTrigger(e, t)}
                                onReload={() => loadEvents()}
                                event={e}
                            />
                        )
                    }
                </div>
            </div>
        </div>
    )
}

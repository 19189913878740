import React, { useContext } from 'react'
import DocumentAdd from './DocumentAdd'
import DocumentLineItem from './DocumentLineItem'
import DocumentsContext from './DocumentsProvider'

export default function DocumentEntries() {
    const { documents } = useContext(DocumentsContext)
    return (
        <div>
            <DocumentAdd />
            {documents.map((d) => <DocumentLineItem item={d.document} />)}
        </div>
    )
}

import React, { useEffect, useState } from 'react'
import { ChevronDown, ChevronUp } from 'react-bootstrap-icons'
import OnScreenAssist from '../../../../../../../components/ui/hint/OnScreenAssist'
import MessagingServiceProvider from '../../../../../../../libs/api/services/MessagingServiceProvider'
import EmailConfigurationComponent from './Email/EmailConfigurationComponent'
import IntegrationAuthentication from './integration/IntegrationAuthentication'
import IntegrationAuthenticationSendMessageBody from './integration/IntegrationAuthenticationSendMessageBody'

export default function FacilityMessagingSending({ title, description, navigation, errors, setValue, values }) {
  const [loaded, setLoaded] = useState(false)
  const [show, setShow] = useState({ auth: false, req: false })
  const [serviceType, setServiceType] = useState()
  const [properties, setProperties] = useState()
  useEffect(() => {
    // Load props
    if (values.serviceProviderService) {
      try {
        var _sendingProperties = values.serviceProviderService.properties.find(p => p.name === 'sendingProperties')
        if (_sendingProperties) {
          setProperties(JSON.parse(_sendingProperties.property))
        }
        if (values.serviceProviderService)
          setServiceType(values.serviceProviderService.serviceType)
      } catch (e) { }
    }
    // Load type

  }, [])

  const _save = async () => {
    if (Object.keys(values).includes('serviceProviderService')) {
      // Save to API values.serviceProviderService || values.serviceProviderService.properties
      var props = values.serviceProviderService.properties

      var _sendingProperties = props.find(p => p.name === "sendingProperties")
      if (_sendingProperties) {
        props.find(p => p.name === 'sendingProperties').property = JSON.stringify(properties)
      } else {
        props.push({ name: 'sendingProperties', property: JSON.stringify(properties) })
      }
      const _req = await MessagingServiceProvider
        .UpdateService(values.serviceProvider.id, values.serviceProviderService).catch((e) => errors(e))
      _sendingProperties = _req
      setValue({ serviceProviderService: _req })
    }
  }

  useEffect(() => {
    if (properties && Object.keys(properties).length > 0)
      _save()

  }, [properties])

  useEffect(() => {
    title('Sending Messages')
    description('Please enter this service providers sending details and format')
    navigation([
      {
        text: 'Back', type: 'Back', action: async () => { }
      },
      { text: 'Next', type: 'Next', action: async () => { } }
    ])
    // if(Object.keys(values).includes('sendProperties')) setProperties(values.sendProperties)
    // _loadSettings()
  }, [])


  if (!serviceType) return <></>
  // values.serviceProviderService.serviceType.type
  if (values.serviceProviderService.serviceType.type === 'Email') {
    return (<EmailConfigurationComponent
      onChange={(r) => Object.keys(r).length > 0 ? setProperties({ ...properties, sendingProperties: r }) : undefined}
      defaultValue={properties && properties.sendingProperties ? properties.sendingProperties : undefined}
      type={'ougtoing'} />)
  } else {
    return (
      <>
        <div>
          <div>
            <div style={{ cursor: 'pointer' }} onClick={(e) => setShow({ ...show, auth: !show.auth })} className='p-1 rounded d-flex bg-primary-container'>
              <div className='flex-grow-1'><b>Authentication</b></div>
              <div className='flex-shrink-1'> {show.auth ? <ChevronUp /> : <ChevronDown />}</div>
            </div>
            <div className={''.concat(show.auth ? '' : 'd-none')}>
              <IntegrationAuthentication
                defaultValue={properties && properties.auth ? properties.auth : undefined} onChange={(r) => Object.keys(r).length > 0 ? setProperties({ ...properties, auth: r }) : undefined} />
            </div>
          </div>
          <div style={{ cursor: 'pointer' }} onClick={(e) => setShow({ ...show, request: !show.request })} className='p-1 rounded d-flex bg-primary-container'>
            <div className='flex-grow-1'><b>Request to send</b></div>
            <div className='flex-shrink-1'> {show.request ? <ChevronUp /> : <ChevronDown />}</div>
          </div>
          <div className={''.concat(show.request ? '' : 'd-none')}>
            <IntegrationAuthenticationSendMessageBody
              defaultValue={properties && properties.request ? properties.request : undefined} onChange={(r) => Object.keys(r).length > 0 ? setProperties({ ...properties, request: r }) : undefined}
            />
          </div>
        </div>
        <OnScreenAssist items={[{
          heading: 'Where can I find these details',
          description: '<ul class="list-unstyled"><li>For <b>Email</b> - These details are sent to you when you setup your email address with the service provider. They can also be found on the cPanel if you provider uses one</li><li><b>For SMS/WhatsApp</b> - These details can be found in the documentation on your service providers website.</li></ul>'
        }]} />
      </>
    )
  }
}

import API from "../API";

var QuestionnaireAssignment = {
    async GetQuestionnairesAssignmentDetails(QuestionnaireAssignmentId) {
        var result = await API.process(
            `api/QuestionnaireAssignment/${QuestionnaireAssignmentId}`,
            'GET',
            null
        ).catch((err) => {
            throw err;
        })

        return result
    },
    async AssignQuestionnaire(quesctionnaireId, UserId, Options) {
        var result = await API.process(
            `api/QuestionnaireAssignment/Questionnaire/${quesctionnaireId}/User/${UserId}`,
            'PUT',
            Options
        ).catch((err) => {
            throw err;
        })

        return result
    },
    async GetUserQuestionnaires(UserId) {
        var result = await API.process(
            `api/QuestionnaireAssignment/User/${UserId}`,
            'GET',
            null
        ).catch((err) => {
            throw err;
        })

        return result
    },
    async GetUserQuestionnairesByType(UserId, type) {
        var result = await API.process(
            `api/QuestionnaireAssignment/User/${UserId}/${type}`,
            'GET',
            null
        ).catch((err) => {
            throw err;
        })

        return result
    },
    async GetPatientQuestionnaires(PatientId) {
        var result = await API.process(
            `api/QuestionnaireAssignment/Patient/${PatientId}`,
            'GET',
            null
        ).catch((err) => {
            throw err;
        })

        return result
    },
    async GetPatientQuestionnairesByType(PatientId, type) {
        var result = await API.process(
            `api/QuestionnaireAssignment/Patient/${PatientId}/${type}`,
            'GET',
            null
        ).catch((err) => {
            throw err;
        })

        return result
    },
    async CompleteQuestionnaire(AssignmentId) {
        var result = await API.process(
            `api/QuestionnaireAssignment/Complete/${AssignmentId}`,
            'PUT',
            null
        ).catch((err) => {
            throw err;
        })

        return result
    },
    async NewColdRequest(QuestionnaireId) {
        var result = await API.process(
            `api/QuestionnaireAssignment/${QuestionnaireId}/Cold`,
            'PATCH',
            null
        ).catch((err) => {
            throw err;
        })

        return result
    },
    async RemoveColdRequest(QuestionnaireId, AssignmentId) {
        var result = await API.process(
            `api/QuestionnaireAssignment/${QuestionnaireId}/Cold/${AssignmentId}`,
            'DELETE',
            null
        ).catch((err) => {
            throw err;
        })

        return result
    },
    async GetQuestionnaireByType(type,facility) {
        var result = await API.process(
            `api/QuestionnaireAssignment/ByType/${type}/${facility}`,
            'GET',
            null
        ).catch((err) => {
            throw err;
        })

        return result
    },
    async AcceptColdRequest(AssignmentId) {
        var result = await API.process(
            `api/QuestionnaireAssignment/Cold/${AssignmentId}/Accept`,
            'DELETE',
            null
        ).catch((err) => {
            throw err;
        })

        return result
    },
    async DeclineColdRequest(AssignmentId) {
        var result = await API.process(
            `api/QuestionnaireAssignment/Cold/${AssignmentId}/Decline`,
            'DELETE',
            null
        ).catch((err) => {
            throw err;
        })

        return result
    },
    async SetAssignmentOption(QuestionairreAssignmentId, property, value) {
        var result = await API.process(
            `api/QuestionnaireAssignment/${QuestionairreAssignmentId}/Option/${property}/${value}`,
            'PUT',
            null
        ).catch((err) => {
            throw err;
        })

        return result
    },


}

export default QuestionnaireAssignment;
import React, { useEffect, useState } from 'react'
import DateTimePickerFunctions from '../../../../../../components/ui/calendar/date_picket/DateTimePickerComponents/DateTimePickerFunctions'
import CalendarEventActions from '../../calendar_events/CalendarEventActions'
import DayOverviewEventContainer from './DayOverviewEventContainer'
import EventsForTheDayComponent from './EventsForTheDayComponent'
import { DayEventModalConfig } from './config/DayEventModalConfig'

const DayOverViewModalContext = React.createContext()

export const DayOverViewModalProvider = ({ calendarType, defaultDetails, children }) => {

 

    const [details, setDetails] = useState({
        eventTitle: '',
        optionsTitle: '',
        eventDetails: [],
        options: [],
        mode: defaultDetails.event ? 'view' : undefined,
        calendarType: undefined,
        event: defaultDetails.event ? { ...defaultDetails.events.find(e => e.id === defaultDetails.event), type: 'EXISTING' } :
            {
                startDateTime: defaultDetails.date ? DateTimePickerFunctions.LocalISO(defaultDetails.date) : DateTimePickerFunctions.LocalISO(new Date()),
                //  defaultDetails.date ? defaultDetails.date.toLocaleString().replace(',', '').replace(' ', 'T').replaceAll('/', '-') : new Date(),
                endDateTime: defaultDetails.date ? DateTimePickerFunctions.LocalISO(defaultDetails.date) : DateTimePickerFunctions.LocalISO(new Date()),
                // defaultDetails.date ? defaultDetails.date.toLocaleString().replace(',', '').replace(' ', 'T').replaceAll('/', '-') : new Date(),
            },
        // option: undefined
    })
    // const [event, setEvent] = useState({})

    useEffect(() => {

        if (defaultDetails.event) {
            setDetails({
                ...details,
                event: { ...defaultDetails.events.find(e => e.id === defaultDetails.event), type: 'EXISTING' },
                mode: 'view'
            })
        }
        else {
            setDetails({
                ...details,
                event: {
                    ...details.event,
                    startDateTime: defaultDetails.date ? defaultDetails.date.toISOString() : new Date(),
                    endDateTime: defaultDetails.date ? defaultDetails.date.toISOString() : new Date(),
                }
                // ...defaultDetails,
                // id: undefined,
                // calendar: {}, // Set this in the provider
                //action:{} /// Add this to server side
                // CalendarTypeSource // Which calender triggered on
                // eventUID: undefined,
                // timeStamp: undefined,
                // summary: undefined,

                // description: undefined,
                // stauts: undefined,
                // patient: { id: 0 },
                // clinician: { id: 0 },
            })
        }

    }, [defaultDetails])

    useEffect(() => {
        switch (details.mode) {
            case 'view':
                setDetails({
                    ...details,
                    eventTitle: 'Event',
                    optionsTitle: 'Options',
                    eventDetails: [<DayOverviewEventContainer
                        onCancel={() => {
                            setDetails({
                                ...details, mode: undefined, option: undefined, event: {
                                    startDateTime: defaultDetails.date ? defaultDetails.date.toISOString() : new Date(),
                                    endDateTime: defaultDetails.date ? defaultDetails.date.toISOString() : new Date(),
                                }
                            })
                        }}
                        flow={DayEventModalConfig['EXISTING']}
                        event={details.event}
                        option={details && details.option ? details.option : undefined}
                    />
                    ],
                    calendarType: calendarType,
                }); break;
            case 'options':
                setDetails({
                    ...details,
                    eventTitle: details.option.eventName,
                    optionsTitle: 'Options',
                    eventDetails: [<DayOverviewEventContainer
                        onCancel={() => setDetails({ ...details, mode: undefined, option: undefined })}
                        flow={DayEventModalConfig[details.option.whenTriggered.trigger]}
                        event={details.event}
                        option={details && details.option ? details.option : undefined}
                    />
                    ],
                    calendarType: calendarType,
                    event: {
                        ...details.event,
                        ...details.option
                    }
                })
                break;
            default:
                setDetails({
                    ...details,
                    eventTitle: `Events for the day`,
                    optionsTitle: 'Options',
                    eventDetails: [<EventsForTheDayComponent date={defaultDetails.date} setActiveEvent={(e) => setDetails({
                        ...details,
                        mode: 'view',
                        event: { ...e, type: 'EXISTING' }
                    })} />],
                    calendarType: calendarType,
                })
                break;
        }
    }, [details.mode, details.option])

    return (
        <DayOverViewModalContext.Provider value={{
            eventTitle: details.eventTitle,
            optionsTitle: details.optionsTitle,
            eventDetails: details.eventDetails,
            options: <CalendarEventActions
                activeAction={details.option}
                setActiveAction={(e) => setDetails({ ...details, option: e, mode: 'options' })}
                calendarType={calendarType}
                evt={details.event}
            // evt={activeEvent}
            />,
            event: details.event
        }}>
            {children}
        </DayOverViewModalContext.Provider>
    )
}
export default DayOverViewModalContext
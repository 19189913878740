// import { jwtDecode } from "jwt-decode";
import API from "../API";

var ExperimentalAPI = {
    async WorkFlowsOverView(filters) {
        var result = await API.process(
            'api/Experimental/WorkFlows/Overview?' + Object.keys(filters).map((r) => r + '=' + filters[r]).join('&'),// + filters.join('&'),
            'GET',
            null
        ).catch((err) => {
            throw err;
        })

        return result
    },
}

export default ExperimentalAPI;
import React, { useContext, useEffect, useState } from 'react'
import CalendarContext from '../../CalendarProvider'
import CalendarEventCard from '../../calendar_events/CalendarEventCard'

export default function EventsForTheDayComponent({ date, setActiveEvent }) {
    const { forceLoad, findEventForDate, events } = useContext(CalendarContext)
    const [eventsX, setEventsX] = useState([])

    const _UpdateEvents = async () => {
        // await forceLoad()
        setEventsX(await findEventForDate(date))
    }

    useEffect(() => { _UpdateEvents() }, [])
    useEffect(() => { _UpdateEvents() }, [events])
    return (
        <div style={{ overflow: 'auto', maxHeight: 'calc(75vh - 125px)' }}>
            {
                eventsX.map((e, i) =>
                    <CalendarEventCard
                        key={`CalendarEventCard-${i}-${e.id}`}
                        onClick={(e) => setActiveEvent(e)}
                        evt={e} />
                )
            }
        </div>
    )
}

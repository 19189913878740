import React from 'react'
import { Clipboard2CheckFill, FileTextFill } from 'react-bootstrap-icons'
import { NavLink } from 'react-router-dom'

export default function QuestionnairesAdmin() {
  return (
    <div style={{ cursor: 'pointer' }} className='d-flex justify-content-center align-items-start h-100'>
      <NavLink
        to='/sys-admin/questionnaires/builder'
        className='bg-primary-container w-100 rounded m-2 p-2 text-center'>
        <div className='m-2'>
          <Clipboard2CheckFill size={50} />
        </div>
        <div className='p-2'>
          <b>Questionnaire Builder</b>
          <p>Create / Manage Questionnaires</p>
        </div>


      </NavLink>
      <NavLink
        to='/sys-admin/form/builder'
        className='bg-primary-container w-100 rounded m-2 p-2 text-center'>
        <div className='m-2'>
          <FileTextFill size={50} />
        </div>
        <div className='p-2'>
          <b>Form Builder</b>
          <p>Create / Manage Forms</p>
        </div>
      </NavLink>
    </div>
  )
}

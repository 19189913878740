import { useEffect, useState } from "react";
import { Check2Square, Square, SquareFill } from "react-bootstrap-icons";
import './ToggleButton.scss';
export default function ToggleButton({ onToggle, defaultValue, style, children,size, className }) {
    const [value, setValue] = useState(defaultValue)

    useEffect(() => {
        if (defaultValue) setValue(defaultValue)
    }, [defaultValue]);

    const onToggleValue = () => {
        let _value = !value;
        setValue(_value)
        if (onToggle)
            onToggle(_value)
    }

    const _handleIcon = (m) => {
        switch (style) {
            case 'tick':
                if (m === 'on')
                    return <Check2Square size={size ? size : '20'} />
                else
                    return <Square size={size ? size : '20'} />
            default:
                if (m === 'on')
                    return <SquareFill size={size ? size : '20'} />
                else
                    return <Square size={size ? size : '20'} />
        }

        // style
    }

    return (
        <div onClick={(e) => onToggleValue()} className={' '.concat(className).concat((value) ? " toggleActive toggleButton p-0 ps-2  " : " toggleInactive toggleButton p-0 ps-2  ")}>
            <div className={"holder ".concat(className)}>
                {(value) ? _handleIcon('on') : _handleIcon('off')}
                {children}
            </div>
        </div>
    )
}
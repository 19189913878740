import React from 'react'

export default function PatientProfileSideBar({ children, profile }) {
    return (
        <div className='flex-shrink-1 menu-bg d-none d-xl-block ' style={{ maxWidth: '230px', minWidth: '230px', overflow: 'auto' }}>
            {
                profile && profile.userProfile ? <div className='text-light p-3 text-center'>{profile.userProfile.firstName} {profile.userProfile.lastName}</div> : <></>
            }
            {children}
        </div>
    )
}

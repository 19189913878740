import React, { useState } from 'react'
import ErrorDialog from '../../components/dialogs/ErrorDialog'
import GeneralModal from '../../components/dialogs/GeneralModal'
import ToastDialog from '../../components/dialogs/toast_dialog/ToastDialog'

const CommonFunctionsContext = React.createContext()

export const CommonFunctionsProvider = ({ children }) => {
    const [sectionInfo, setSectionInfo] = useState()
    const [debug, setDebug] = useState()
    const [error, setError] = useState({ error: undefined, onClose: () => setError({ error: undefined }), humanize: true })
    const [toast, setToast] = useState({ message: undefined, icons: undefined, time: 1 })
    const [modal, setModal] = useState([])
    const showError = (e) => setError({ ...error, error: e, humanize: true })
    const showConfirm = () => { }
    const showToast = (message, icon, time) => {
        setToast({
            message: message, icon: icon, time: time, onTimeOut: () => {
                setToast({ message: undefined, icon: undefined })
            }
        })
    }

    const isNullOrEmpty = (v) => !v || v.trim().length === 0


    const createModal = (c, t, i, oc) => setModal([...modal, { id: modal.length, component: c, title: t, icon: i, onClose: oc }])
    const removeModal = (i) => setModal(modal.filter((x) => x.id !== i))
    // onClose
    //ToastDialog({ key, message, icon, className, time, onTimeOut })
    return (
        <CommonFunctionsContext.Provider value={{
            showError,
            showConfirm,
            showToast,
            createModal,
            DEBUG: setDebug,
            sectionInfo, setSectionInfo, isNullOrEmpty
        }}>
            <ErrorDialog {...error} />
            <ToastDialog {...toast} />
            {modal.map((c, i) => <GeneralModal zIndex={10010 + i} key={`GenMod-${i}`} idx={c.id} onClose={
                () => {
                    if (c.onClose) c.onClose()
                    removeModal()
                }
            } show={true} title={c.title} icon={c.icon}>
                {{ ...c.component, props: { ...c.component.props, onClose: () => removeModal(c.id) } }}</GeneralModal>)}
            {debug && <div style={{ background: 'red' }}>{debug}</div>}
            {children}
        </CommonFunctionsContext.Provider>
    )
}

export default CommonFunctionsContext
import React, { useEffect, useState } from 'react';

import { useContext, useRef } from 'react';
import Authentication from '../../../../../libs/api/services/Authentication';
import WorkFlow from '../../../../../libs/api/services/WorkFlow';
import PatientHistoryContext from '../PatientHistoryProvider';
import './PatientEventType.css';

export default function PatientEventType({ defaultValue, onChanged, placeholder, fieldId, optionsId }) {
    const { LIST_PATIENT_EVENTS } = useContext(PatientHistoryContext)
    const inputRef = useRef(null);
    const [options, setOptions] = useState([])
    const [search, setSearch] = useState([])
    const [filter, setSFilter] = useState('')
    const [option, setOption] = useState('Event')
    const [show, setShow] = useState(false)

    useEffect(() => {

        inputRef.current.focus();
    }, [show])

    const LoadWorkFlowEvents = async () => {
        var _res = []
        const Groups = await WorkFlow.ListGroups(Authentication.getFacility())
        for (const Group of Groups)
            _res.push({
                Group: Group,
                Events: await WorkFlow.ListCategoryEvents(Group.id)
            })
    }

    useEffect(() => {
        LoadWorkFlowEvents()

        LIST_PATIENT_EVENTS()
            .then((r) => {
                setOptions(r)
                setSearch(r)
            })
    }, [option])

    const _handleOptionSelected = (option) => {
        setOption(option.event)
        onChanged(option.id)
        setShow(false)
        setSearch(options)
        // setSearch([])
        setSFilter('')
    }
    const _removeTrailing = (word) => {
        const verbEndings = ['ed', 'ing', '\'s', 'es', 'ie']
        for (var e of verbEndings) {
            if (word.substr(word.length - e.length) === e) {
                word = word.substr(0, word.length - e.length)
            }
        }
        return word
    }
    const _handleSearch = (_filter) => {
        setSFilter(_filter)
        var _result = []
        for (var category of options) {
            var _rlist = []
            for (var event of category.events) {
                var _score = 0;
                var _score_filter_length = _filter.length;
                var _score_search_length = event.event.length;
                // split words
                for (var wordSearch of _filter.toLowerCase().split(' ')) {
                    //  remove ed,ing, er est ile able 
                    for (var resultSearch of event.event.toLowerCase().split(' ')) {
                        if (_removeTrailing(resultSearch).includes(_removeTrailing(wordSearch))) {
                            _score++;
                        }
                    }
                }
                var final_score = _score === 0 ? 0 : _score_search_length / _score

                if (final_score > 0.7) {
                    _rlist.push({
                        id: event,
                        event: event.event,
                        score: _score
                    })
                }
            }
            if (_rlist.length > 0)
                _result.push({
                    id: category.id,
                    category: category.category,
                    events: _rlist
                })
        }
        var _result_sorted = []
        for (var r of _result) {
            var _result_sorted_events = []
            for (var e of r.events.sort((b, a) => parseFloat(a.score) - parseFloat(b.score))) {
                _result_sorted_events.push({
                    id: e.id.id,
                    event: e.event
                })
            }
            _result_sorted.push({
                id: r.id,
                category: r.category,
                // events: []
                events: _result_sorted_events
            })
        }
        setSearch(_result_sorted)
    }
    return (
        <div style={{ zIndex: 99 }} className='position-relative single-selection'>
            <div onClick={(e) => setShow(!show)} className='form-control selected-option ' >{option}</div>
            <div className={'bg-secondary p-3 rounded position-absolute w-100 options-container '.concat(show ? 'd-block' : 'd-none')}>
                <input ref={inputRef} value={filter} onChange={(e) => _handleSearch(e.target.value)} className='form-control single-option-search ' placeholder={"Search"} />
                <div style={{ maxHeight: '300px', overflow: 'auto' }}>
                    {
                        search.map((event) =>
                            <>
                                <div
                                    key={event.id} value={event.category}
                                    // onClick={() => _handleOptionSelected(event.category)}
                                    className={'category'}
                                >{event.category}</div>
                                {
                                    event.events.map((ev) =>
                                        <div
                                            key={ev.id} value={ev.event}
                                            onClick={() => _handleOptionSelected(ev)}
                                            className={option === ev.event ? 'options selected' : 'options'}
                                        >{ev.event}
                                        </div>
                                    )
                                }
                            </>
                        )
                    }
                </div>
            </div>
        </div>
    )
}

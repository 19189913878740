import React from 'react'
import CalendarGenerics from '../../calendar/CalendarGenerics'

export default function CronMonthOptions({ properties, setProperties }) {
    return (
        <>
            <div className='col-3'>
                <select
                    defaultValue={properties && properties.monthOccourance}
                    onChange={(e) => setProperties({ ...properties, monthOccourance: e.target.value })
                    }
                    className='form-control form-control-sm'><option></option>
                    <option selected={properties.monthOccourance === 'Every Month'}>Every Month</option>
                    <option selected={properties.monthOccourance === 'Specific Month'}>Specific Month</option>
                </select>
            </div>
            <div className='col-3'>
                {
                    properties.monthOccourance === 'Specific Month'
                        ?
                        <select
                            defaultValue={properties && properties.monthWhen}
                            onChange={(e) => setProperties({ ...properties, monthWhen: e.target.value })
                            }
                            className='form-control form-control-sm'>
                            {
                                CalendarGenerics
                                    .months.map((m) =>
                                        <option selected={m.full === properties.monthWhen}>{m.full}</option>
                                    )
                            }
                        </select>
                        : <></>
                }

            </div>
        </>
    )
}

import React, { useContext, useEffect, useState } from 'react'
import SlideContainerWidget from '../../../../components/ui/slide_container/SlideContainerWidget'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowDown, faArrowUp, faTrash } from '@fortawesome/free-solid-svg-icons'
import PatientProfileContext from './PatientProfileProvider'
import PatientProfileCategoryField from './field/PatientProfileCategoryField'
import PatientProfileCategoryEvent from './category/PatientProfileCategoryEvent'
import { PatientHistoryProvider } from '../history/PatientHistoryProvider'

export default function PatientProfileContainer({ category, categoryId, onStatusChange }) {
    const [open, setOpen] = useState(true)
    const [dragId, setDragId] = useState('')
    const [dragOrder, setDragOrder] = useState(-10)
    const { state, execute } = useContext(PatientProfileContext)

    useEffect(() => {
        if (categoryId)
            execute('LOAD_CATEGORIES_FIELDS_FROM_SERVER', categoryId)
    }, [open])

    useEffect(() => {
        setOpen(false)
    }, [])

    const _handleChangeOrder = (categoryID, id, order) => {
        if (order < 0) return;
        execute('CHANGE_CATEGORY_FIELD_ORDER', { categoryID: categoryID, id: id, order: order })
        setDragOrder(-10)
    }

    return (
        <PatientHistoryProvider>
            <SlideContainerWidget
                title={category}
                icon={'pencil'}
                state={setOpen}
                className={'bg-secondary-container'}
                options={
                    <FontAwesomeIcon
                        className='btn' icon={faTrash}
                        onClick={(e) => execute('REMOVE_CATEGORY', categoryId).then((r) => onStatusChange('Category Removed'))}
                    />
                }
            >
                <input defaultValue={category} onChange={(e) => execute('CHANGE_CATEGORY_NAME', { id: categoryId, GroupNme: e.target.value })} className='form-control form-control-sm' />

                <PatientProfileCategoryEvent categoryId={categoryId} />
                <h3 className='text-center m-0 p-0 '>Fields</h3>

                <SlideContainerWidget title={'Add Field'} icon={'plus'}>
                    <PatientProfileCategoryField key={'New Field'} field={{ type: '' }} categoryID={categoryId} />
                </SlideContainerWidget>
                {
                    state.state === 'READY' || state.state === 'SAVING_FIELD_TO_SERVER'
                        ?
                        <>
                            {
                                Object.keys(state.categories).map((idx) => {
                                    if (state.categories[idx].id === categoryId)
                                        if (!state.categories[idx].details)
                                            console.error(state.categories[idx])
                                        else
                                            return state.categories[idx].details
                                                ?
                                                state.categories[idx].details.map((f) => {
                                                    return (
                                                        <div className='d-flex' key={f.id}
                                                            style={(dragOrder === f.order) ? { opacity: '0.5', cursor: 'copyMove' } : {}}
                                                            draggable
                                                            onDragStart={(e) => { e.dataTransfer.effectAllowed = "copyMove"; setDragId(f.id) }}
                                                            onDragEnter={(e) => { e.preventDefault(); e.dataTransfer.effectAllowed = "move"; setDragOrder(f.order) }}
                                                            onDragOver={(e) => { e.preventDefault(); e.dataTransfer.effectAllowed = "move"; }}
                                                            onDragEnd={(e) => { _handleChangeOrder(state.categories[idx].id, dragId, dragOrder); setDragOrder(-10) }}
                                                        >
                                                            <div
                                                                style={{ cursor: 'move' }}
                                                            >
                                                                <FontAwesomeIcon onClick={(e) => _handleChangeOrder(state.categories[idx].id, f.id, f.order - 1)} icon={faArrowUp} />
                                                                <FontAwesomeIcon onClick={(e) => _handleChangeOrder(state.categories[idx].id, f.id, f.order + 1)} icon={faArrowDown} />
                                                            </div>
                                                            <SlideContainerWidget

                                                                options={
                                                                    <FontAwesomeIcon
                                                                        className='btn btn-sm ' icon={faTrash}
                                                                        onClick={(e) => execute('REMOVE_CATEGORY_FIELD', { categoryId: state.categories[idx].id, fieldId: f.id }).then((r) => onStatusChange('Field Removed'))}
                                                                    />
                                                                }
                                                                title={f.value} icon={'pencil'}>
                                                                <PatientProfileCategoryField field={f} key={f.id} categoryID={categoryId} isNew={false} />
                                                            </SlideContainerWidget>
                                                        </div>
                                                    )
                                                })
                                                :
                                                <></>
                                })
                            }
                        </>
                        :
                        <></>

                }
            </SlideContainerWidget>
        </PatientHistoryProvider>
    )
}

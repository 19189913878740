import CalendarSync from "../../../../../../../libs/api/services/CalendarSync"
import Calendars from "../../../../../../../libs/api/services/Calendars"
import CustomizeEvent from "../OptionModal/CREATE_CALENDAR_EVENTComponents/CustomizeEvent"
import EventDetails from "../OptionModal/CREATE_CALENDAR_EVENTComponents/EventDetails"
import EventLink from "../OptionModal/CREATE_CALENDAR_EVENTComponents/EventLink"
import SyncToCalendar from "../OptionModal/CREATE_CALENDAR_EVENTComponents/SyncToCalendar"

export const CREATE_CALENDAR_EVENT = {
    "CREATE_CALENDAR_EVENT": {
        title: '',
        onComplete: async (p) => {
            const _entry = await Calendars.CreateCalendarEntry({
                action: p['0'].entry.action,
                calendar: p['0'].entry.calendar,
                startDateTime: p['0'].entry.startDateTime,
                endDateTime: p['0'].entry.endDateTime,
                patient: p['0'].entry.patient,
                clinician: p['0'].entry.clinician,
                location: p['0'].entry.location,
                status: p['0'].entry.status,
                description: p['0'].entry.description,
                summary: p['0'].entry.summary,
            })
            if (p['4'] && p['4'].colour)
                await Calendars.SetCalendarEntryAdditionalOption(_entry.id, {
                    option: 'COLOUR',
                    value: JSON.stringify(p['4'].colour)
                });
            if (p['4'] && p['4'].icons)
                await Calendars.SetCalendarEntryAdditionalOption(_entry.id, {
                    option: 'ICONS',
                    value: JSON.stringify(p['4'].icons)
                });
            // try {
            if (p['1'])
                await Calendars.LinkCalendar(_entry.id, p['1'].who, p['1'].linked, !p['1'].linked ? {} : {
                    // id: p['1'].entry.id,
                    //calendar: p['1'].entry.calendar,
                    startDateTime: Object.keys(p['1'].entry).includes('startDateTime') ? p['1'].entry.startDateTime : undefined,
                    endDateTime: Object.keys(p['1'].entry).includes('endDateTime') ? p['1'].entry.endDateTime : undefined,
                    patient: Object.keys(p['1'].entry).includes('patient') ? p['1'].entry.patient : undefined,
                    clinician: Object.keys(p['1'].entry).includes('clinician') ? p['1'].entry.clinician : undefined,
                    location: Object.keys(p['1'].entry).includes('location') ? p['1'].entry.location : undefined,
                    status: Object.keys(p['1'].entry).includes('status') ? p['1'].entry.status : undefined,
                    description: Object.keys(p['1'].entry).includes('description') ? p['1'].entry.description : undefined,
                    summary: Object.keys(p['1'].entry).includes('summary') ? p['1'].entry.summary : undefined,
                })
            // } catch (e) { console.error('Failed to link patient', e) }
            // try {
            if (p['2'])
                await Calendars.LinkCalendar(_entry.id, p['2'].who, p['2'].linked, !p['2'].linked ? {} : {
                    // id: p['1'].entry.id,
                    //calendar: p['2'].entry.calendar,
                    startDateTime: Object.keys(p['2'].entry).includes('startDateTime') ? p['2'].entry.startDateTime : undefined,
                    endDateTime: Object.keys(p['2'].entry).includes('endDateTime') ? p['2'].entry.endDateTime : undefined,
                    patient: Object.keys(p['2'].entry).includes('patient') ? p['2'].entry.patient : undefined,
                    clinician: Object.keys(p['2'].entry).includes('clinician') ? p['2'].entry.clinician : undefined,
                    location: Object.keys(p['2'].entry).includes('location') ? p['2'].entry.location : undefined,
                    status: Object.keys(p['2'].entry).includes('status') ? p['2'].entry.status : undefined,
                    description: Object.keys(p['2'].entry).includes('description') ? p['2'].entry.description : undefined,
                    summary: Object.keys(p['2'].entry).includes('summary') ? p['2'].entry.summary : undefined,
                })
            if (p['3'])
                await Calendars.LinkCalendar(_entry.id, 'sync', p['3'].active, !p['3'].active ? {} : {
                    id: p['3'].calendar[0].id,
                })
            // } catch (e) { console.error('Failed to link clinician', e, p['2']) }

            return true
        },
        flows: [
            {
                onBeforeNext: async (p) => {
                    if (new Date(p['0'].entry.startDateTime) > new Date(p['0'].entry.endDateTime))
                        throw Error("Starting date / time cannot be after end date / time")
                     return { patient: { id: 1 } } 

                },
                component: <EventDetails />
            },
            {
                onBeforeLoad: async (p) => {
                    try {
                        return !(p['0'].entry.calendar.calendarUser.id === p['0'].entry.patient.id);
                    } catch (e) { }
                    return Object.keys(String(p)).includes('0') && Object.keys(p['0']).includes('entry') && Object.keys(p['0'].entry).includes('patient')
                },
                component: <EventLink who={'patient'} />
            },
            {
                onBeforeLoad: async (p) => {

                    try {
                        return !(p['0'].entry.calendar.calendarUser.id === p['0'].entry.clinician.id);
                    } catch (e) { }
                    return Object.keys(String(p)).includes('0') && Object.keys(p['0']).includes('entry') && Object.keys(p['0'].entry).includes('clinician')
                },
                component: <EventLink who={'clinician'} />
            },
            {
                onBeforeLoad: async (p) => {
                    var r = await CalendarSync.GetExternalCalendars();
                    return r && r.length > 0
                },
                component: <SyncToCalendar />
            },
            {
                component: <CustomizeEvent />
            }
        ]
    }
}
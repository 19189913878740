import { useContext, useEffect, useState } from 'react'
import { NotesProvider } from '../../../../workflow/management/Sections/Notes/NotesProvider'
import WorkFlowManagementContext from '../../../../workflow/management/WorkFlowManagementProvider'
import DateTimeContainer from './DateTimeContainer'
import WorkFlowHistoryEntryLineItem from './WorkFlowHistoryEntryLineItem'

export default function WorkFlowPatientHistoryEntries() {
  const [items, setItems] = useState([])
  const { history } = useContext(WorkFlowManagementContext)
  useEffect(() => { if (history) Generate() }, [history])

  const Generate = () => {
    var DateItems = []
    for (const i of history) {
      var _entry = DateItems.find(d => d.triggeredDateTime === i.triggeredDateTime.substring(0, 10))
      if (_entry) {
        _entry.items.push(i)
      } else {
        DateItems.push({
          triggeredDateTime: i.triggeredDateTime.substring(0, 10),
          items: [i]
        })
      }
    }
    // DateItems.push(items)
    setItems(DateItems)
    return DateItems
  }

  return (
    // <div>{JSON.stringify(history)}</div>
    items.map((i) =>

      <DateTimeContainer date={i.triggeredDateTime}>
        {i.items.map((c) =>
          <NotesProvider event={c}>
            <WorkFlowHistoryEntryLineItem item={c} />
          </NotesProvider>
        )}
      </DateTimeContainer>)
    // <div>
    //   <DateTimeContainer date={'2023-02-01'}>
    //     <div>testes</div>
    //   </DateTimeContainer>
    // </div>
  )
}

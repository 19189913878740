import React, { useContext, useState } from 'react'
import { SearchInterface } from '../interfaces/search/SearchInterface'
import { APIActionInterface } from '../interfaces/APIActionInterface'
import { APIFunctions } from '../../common/APIFunctions'
import ErrorContext from '../context/ErrorContext'
import { ActionInterface } from '../interfaces/ActionInterface'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faClose } from '@fortawesome/free-solid-svg-icons'
// import VariablesContext from '../context/VariablesContext'

interface SearResultItem {
  id: string
  value: string
}

interface Props {
  name: string
  className: string | undefined
  results: SearchInterface
  addAction?: ActionInterface[]
  onChange: (name: string, value: any) => void
  actionRunner: (n: string, v?: string, a?: ActionInterface[]) => Promise<any>
}

const SearchComponent = (props: Props) => {
  const { invokeError } = useContext(ErrorContext)
  const [results, setResults] = useState<SearResultItem[]>([])
  const [showAdd, setShowAdd] = useState<boolean>(false)
  const [searchString, setsearchString] = useState<string>('')
  const [showResults, setShowResults] = useState<boolean>(false)

  const _onSearch = async (search: string) => {
    setShowResults(true)
    const vars: Map<string, any> = new Map<string, any>().set(props.name, search)
    if (searchString && props.addAction) setShowAdd(true)
    setsearchString(search)
    switch (props.results.method) {
      case 'API': {
        await APIFunctions.MakeCall(
          (props.results.params! as APIActionInterface).url,
          (props.results.params! as APIActionInterface).method,
          APIFunctions.SetBody(vars, JSON.parse(JSON.stringify((props.results.params! as APIActionInterface).body))),
          //APIFunctions.SetBody(vars, (props.results! as APIActionInterface).body), // Use local results only for this version
          (props.results.params! as APIActionInterface).encryption,
        )
          .catch((err) => invokeError!(err.message ? err.message : err))
          .then((res) => {
            const _result: SearResultItem[] = []
            for (const _res of res) _result.push({ id: _res.id, value: _res[props.results.reference] })
            setResults(_result)
          })
        break
      }
    }
  }

  const _onSelect = (id: string, value: string) => {
    setShowResults(false)
    setResults([])
    setsearchString(value)
    setShowAdd(false)
    // props.onUpdateAndExecute(props.name, id)
    props.onChange(props.name, id)
  }

  return (
    <div className={'wizard-search'}>
      <input
        className={props.className!}
        key={props.name}
        value={searchString}
        onChange={(e) => _onSearch(e.target.value)}
      />
      {showResults ? (
        <ul className='wizard-search-results'>
          <FontAwesomeIcon
            onClick={() => {
              setResults([])
              setShowAdd(false)
              setShowResults(false)
            }}
            fontSize={15}
            icon={faClose}
            className='wizard-search-results-close'
          />
          {results.length > 0 ? (
            results.map((result) => (
              <li
                className='wizard-search-results-item'
                key={(result as SearResultItem).id}
                id={(result as SearResultItem).id}
                onClick={() => _onSelect((result as SearResultItem).id, (result as SearResultItem).value)}
              >
                {(result as SearResultItem).value}
              </li>
            ))
          ) : (
            <></>
          )}
          {showAdd ? (
            <li
              className='wizard-search-results-item'
              onClick={() => {
                props.actionRunner(props.name, searchString, props.addAction).then(() => {
                  _onSearch(searchString)
                })
              }}
            >
              Add : {searchString}
            </li>
          ) : (
            <></>
          )}
        </ul>
      ) : (
        <></>
      )}
    </div>
  )
}

export default SearchComponent

import React, { useEffect, useState } from 'react'
import LogsTableComponent from '../../../../../../../components/ui/table/LogsTableComponent'
import WorkFlow from '../../../../../../../libs/api/services/WorkFlow'

export default function WorkFlowLogs({ title, description, navigation, errors, setValue, values, navigator }) {
    const [mode, setMode] = useState('View All')

    useEffect(() => { _loadLogs() }, [])
    const [logs, setLogs] = useState()

    const _loadLogs = () => {
        WorkFlow
            .GetWorkFlowLogs(values.event.id)
            .then((r) => setLogs(r)).catch((e) => errors(e))
        //values.serviceProviderService
    }

    useEffect(() => {
        title('Event Logs')
        description('')
        navigation([{
            text: 'Back', type: 'Back', action: async () => { }
        },
        {
            text: 'Done', type: 'Done', action: async () => { }
        },
            // { text: 'Done', type: 'Done', action: async () => { } }
        ])
        // loadTasks()
        // loadAllTasks()
    }, [])


    return (
        <div className='text-start rounded shadow'>
            {
                /*
                        public DateTime? TriggeredDate { get; set; }
        public DateTime? CompletedDate { get; set; }
        public string? EventName { get; set; }
        public string? EventTask { get; set; }

        public string? TriggeredFor { get; set; }
        public string? TriggeredBy { get; set; }
        //public string? Event { get; set; }
        public string? Description { get; set; }
        public DateTime? ExecutedDateTime { get; set; }
        public string Status { get; set; } = "Pending";*/
                logs
                    ?
                    <LogsTableComponent
                        logs={logs} options={{
                            columns: [
                                {
                                    name: 'triggeredDate',
                                    type: 'DATETIME',
                                    human: 'Triggered Date',
                                },
                                {
                                    name: 'completedDate',
                                    type: 'DATETIME',
                                    human: 'Completed Date',
                                },
                                {
                                    name: 'eventName',
                                    type: 'String',
                                    human: 'EventName',
                                },
                                {
                                    name: 'eventTask',
                                    type: 'STRING',
                                    human: 'Task',
                                },
                                {
                                    name: 'triggeredFor',
                                    type: 'STRING',
                                    human: 'For',
                                },
                                {
                                    name: 'triggeredBy',
                                    type: 'STRING',
                                    human: 'By',
                                },
                                {
                                    name: 'description',
                                    type: 'STRING',
                                    human: 'Description',
                                },
                                {
                                    name: 'executedDateTime',
                                    type: 'DATETIME',
                                    human: 'Executed Date Time',
                                },
                                {
                                    name: 'status',
                                    type: 'STATUS',
                                    human: 'Status',
                                },
                            ]
                        }} />
                    :
                    <></>
            }
        </div>
    )
}

import React, { useEffect, useState } from 'react'
import QCaptureComponent from './components/QCaptureComponent'
import { useParams } from 'react-router-dom'
import Questionnaire from '../../../../libs/api/services/Questionnaire'

export default function QCapture() {
    const { questionnaireId, QuestionnaireAssignmentId } = useParams()
    const [details, setDetails] = useState()
    const loadDetails = async () => {

        const res = await Questionnaire
            .GetQuestionnaireResultsFull(QuestionnaireAssignmentId)
        setDetails(res)
    }

    useEffect(() => {
        loadDetails()
    }, [])

    return (
        details
            ?
            <div className='m-2 position-relative h-100'>
                <QCaptureComponent
                    cacheddetails={details}
                    QuestionnaireAssignmentId={QuestionnaireAssignmentId}
                    QuestionnaireId={questionnaireId} />
            </div>
            :
            <></>
    )
}
// 718389BF-E1D4-47A8-B660-1B8287E40611
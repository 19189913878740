import { faSpinner } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useEffect, useState } from 'react'
import MessagingService from '../../../../../../../libs/api/services/MessagingService'
import { Journal, JournalX, JournalCheck } from 'react-bootstrap-icons'
import EmailAccountType from '../EmailAccountType'

export default function EmailWizardBasicConfig({ title, description, navigation, errors, setValue, values }) {
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [accountType, setAccountType] = useState('')
    const [mode, setMode] = useState('DETAILS')
    const [properties, setProperties] = useState([])
    const [checks, setChecks] = useState([])
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        title('Configuration')
        description('Please enter the email address of the account you would like to setup')
        navigation([
            {
                text: 'Back', type: 'Back', action: async () => { }
            }
        ])
        _loadSettings()
        if (Object.keys(values).includes('oauth') && values.oauth === 'recheck')
            _handleLookupEmailAccount()
    }, [])


    const _handleSetCheck = (c) => {
        var _checks = checks;
        _checks.push(c)
        setChecks([..._checks])
    }

    const _handleLookupEmailAccount = async () => {
        setMode('VERIFYING')
        navigation([])
        var step = 0;
        var failsafe = 0;
        while (step !== -1 && failsafe++ < 50) {
            var status = await MessagingService.DiscoverEmailAccount(values.configurationId, step);
            _handleSetCheck(status)
            if (status.success) {
                // description("Check : " + step + " - " + status.nextCheck + ") " + status.status)
                if (status.nextCheck === -1) {
                    setMode('DETAILS_FOUND')
                    navigation([
                        {
                            text: 'Next', type: 'Step', step: 4, action: async () => {
                                MessagingService
                                    .SetMessagingServiceProperty(values.configurationId, "EMAIL_INCOMING_MAILBOX_STATUS", "Mailbox configured. Waiting to sync messages")
                            }
                        }
                    ])
                }
            }
            step = status.nextCheck;

            // .then((r) => {
            //     setMode('DETAILS_FOUND')
            //     description('Your email connection configuration has automatically been configured')
            //     navigation([
            //         {
            //             text: 'Next', type: 'Step', step: 4, action: async () => {
            //                 MessagingService
            //                     .SetMessagingServiceProperty(values.configurationId, "EMAIL_INCOMING_MAILBOX_STATUS", "Mailbox configured. Waiting to sync messages")
            //             }
            //         }
            //     ])
            // })
            // .catch((e) => {
            //     errors(e.message);
            //     setMode('FAILED');
            //     navigation([
            //         {
            //             text: 'Back', type: 'Back', action: async () => { }
            //         },
            //         {
            //             text: 'Continue with manual configuration', type: 'Next', action: async () => {

            //                 _handleSetValue("EMAIL_INCOMING_MAILBOX_STATUS", "")
            //             }
            //         }
            //     ])
            // })
        }
        if (!status.success) {
            setMode('FAILED');
            navigation([
                {
                    text: 'Back', type: 'Back', action: async () => { }
                },
                {
                    text: 'Continue with manual configuration', type: 'Next', action: async () => {

                        _handleSetValue("EMAIL_INCOMING_MAILBOX_STATUS", "")
                    }
                }
            ])
        }
    }

    useEffect(() => {
        if (email && password && accountType)
            navigation([
                {
                    text: 'Continue with automatic configuration', type: 'Action', action: async () => { _handleLookupEmailAccount() }
                },
                {
                    text: 'Continue with manual configuration', type: 'Next', action: async () => { }
                }
            ])
    }, [email, password, accountType])

    useEffect(() => {
        var username = getSetting('EMAIL_INCOMING_SERVER_USERNAME')
        var password = getSetting('EMAIL_INCOMING_SERVER_PASSWORD')
        if (username.value)
            setEmail(username.value)
        if (password.value)
            setPassword(password.value)
    }, [properties])

    const getSetting = (type) => {
        var property = { value: '' }
        var _property = undefined
        if (properties && properties.length > 0)
            _property = properties.find(f => f.propertyType.type === type)
        return _property ? _property : property
    }

    const _loadSettings = () => {
        setLoading(true)
        MessagingService
            .GetMessagingServiceProperties(values.configurationId)
            .then((r) => {
                setLoading(false)
                setProperties(r)
            })
            .catch((e) => {
                errors(e.message)
                // setValue({ 'serviceProviderId': undefined })
            })
    }

    const _handleSetValue = (id, value) => {
        MessagingService
            .SetMessagingServiceProperty(values.configurationId, id, value)
            .catch((e) => errors(e.message))
    }

    return (
        <>
            {
                // mode === 'VERIFYING'
                //     ?
                //     <div>
                //         <FontAwesomeIcon className='me-2' icon={faSpinner} spin />
                //         <b>Looking up your connection details</b>
                //     </div>
                //     :
                //     mode === 'DETAILS_FOUND'
                //         ?
                //         <h3></h3>
                //         :
                loading
                    ?
                    <FontAwesomeIcon icon={faSpinner} spin />
                    :
                    <div className='bg-primary-container rounded shadow m-3 p-3'>
                        <div className='row'>
                            <div className='col-12'>
                                <label>Email Address</label>
                                <input
                                    defaultValue={getSetting('EMAIL_INCOMING_SERVER_USERNAME').value}
                                    onBlur={(e) => { _handleSetValue('EMAIL_INCOMING_SERVER_USERNAME', e.target.value); _handleSetValue('EMAIL_OUTGOING_SERVER_USERNAME', e.target.value); }}
                                    onChange={(e) => setEmail(e.target.value)}
                                    className='form-control' placeholder='Email Address' />
                            </div>
                        </div>
                        <div className='row'>
                            <div className='col-12'>
                                <label>Password</label>
                                <input
                                    defaultValue={getSetting('EMAIL_INCOMING_SERVER_PASSWORD').value}
                                    onBlur={(e) => { _handleSetValue('EMAIL_INCOMING_SERVER_PASSWORD', e.target.value); _handleSetValue('EMAIL_OUTGOING_SERVER_PASSWORD', e.target.value); }}
                                    onChange={(e) => setPassword(e.target.value)}
                                    className='form-control' placeholder='Password' type='password' />
                            </div>
                        </div>
                        <EmailAccountType defaultValue={getSetting('EMAIL_INCOMING_SERVER_TYPE').value} onAccSelected={(account) => { setAccountType(account); _handleSetValue('EMAIL_INCOMING_SERVER_TYPE', account); _handleSetValue('EMAIL_OUTGOING_SERVER_TYPE', account); }} />
                    </div>

            }
            {
                checks && checks.length > 0
                    ?
                    <div className='text-center p-2 ' style={{ overflow: 'auto', right: '0px' }}>
                        <b>Checking mail Account</b>
                        <div className='p-3'>{checks[checks.length - 1].status}</div>
                        {
                            checks[checks.length - 1] && Object.keys(checks[checks.length - 1]).includes('total')
                                ?
                                <div style={{ height: '13px', fontSize: "10px" }}>
                                    <div className='bg-primary-container' style={{ width: `${(checks[checks.length - 1].nextCheck === -1) ? '100' : (checks[checks.length - 1].nextCheck / checks[checks.length - 1].total * 100)}%` }}>{(checks[checks.length - 1].nextCheck === -1) ? '100%' : Math.floor((checks[checks.length - 1].nextCheck / checks[checks.length - 1].total) * 100) + '%'}</div>
                                </div>
                                :
                                <></>
                        }
                        {
                            checks.map((r) =>
                                <div key={r.status} className={'row text-start '.concat(r.success ? 'text-success' : 'text-danger')}>
                                    <div className='col-1'>
                                        <Journal size={20} />
                                    </div>
                                    <div className={'col-4 '.concat(r.success ? 'text-success' : 'text-danger')}>
                                        {r.status}
                                    </div>
                                    <div style={{overflow:'auto'}} className={'col-4 '.concat(r.success ? 'text-success' : 'text-danger')}>
                                        {r.details}
                                    </div>
                                    <div className='col-2'>
                                        {
                                            r.action
                                                ?
                                                <button className='btn btn-sm' onClick={() => window.location = r.action.url}>{r.action.action}</button>
                                                :
                                                r.success
                                                    ?
                                                    <JournalCheck />
                                                    :
                                                    <JournalX />
                                        }
                                    </div>
                                </div>
                            )
                        }
                    </div>
                    :
                    <></>
            }
        </>
    )
}

import React, { useReducer } from 'react'
import Patient from '../../../../libs/api/services/Patient'

const PatientProfileContext = React.createContext()

function updateFieldsByCategory(c, q) {
    // q.category
    // q.categoryId
    if (!q || !q.categoryId) return c
    let _category = {}
    let isNew = true
    for (let _idx of Object.keys(c))
        if (c[_idx].id === q.categoryId)
            _category = c[_idx]
    if (q.details && Object.keys(_category).length > 0) {
        // Check if should update
        if (_category.details)
            for (var _idx2 of Object.keys(_category.details)) {
                if (_category.details[_idx2].id === q.details.id) {
                    _category.details[_idx2] = q.details; isNew = false
                }
            }
        // Else add
        if (isNew)
            if (_category.details)
                _category.details = Array.isArray(q.details) ? [...q.details] : [..._category.details, q.details]
            else
                _category.details = q.details
    }
    return c
}

function updateCategory(c, o) {
    // o.id
    // o.GroupNme
    // q.categoryId

    let _idx = -1
    for (_idx of Object.keys(c))
        if (c[_idx].id === o.id)
            break;
    if (_idx > -1)
        c[_idx].GroupNme = o.GroupNme
    return c
}

function findCategoryById(c, id) {
    for (let _idx of Object.keys(c))
        if (c[_idx].id === id)
            return c[_idx]
}

function reducer(state, action) {
    switch (action.type) {
        // Categories
        case 'LOAD_CATEGORIES':
            return { ...state, categories: action.payload }
        case 'START_CREATE_NEW_CATEGORY': return { ...state, category: { GroupNme: action.GroupNme } }
        case 'SET_CREATE_NEW_CATEGORY': return { ...state, category: { id: state.category.id, GroupNme: action.GroupNme } }
        case 'SAVE_NEW_CATEGORY_TO_SERVER': return { ...state, state: 'SAVING_NEW_CATEGORY_TO_SERVER' }
        case 'SAVED_NEW_CATEGORY_TO_SERVER': return { ...state, state: 'READY', category: action.category, categories: [...state.categories, action.category] }
        case 'LOAD_CATEGORIES_FROM_SERVER': return { ...state, state: 'LOADING_CATEGORIES_FROM_SERVER' }
        case 'LOAD_CATEGORIES_LOADED_FROM_SERVER': return { ...state, state: 'READY', categories: [...action.categories] }
        case 'REMOVE_CATEGORY': return { ...state, state: 'REMOVING_CATEGORY' }
        case 'REMOVE_CATEGORY_FROM_SERVER': return { ...state, state: 'REMOVED_CATEGORY' }
        case 'REMOVED_CATEGORY': return { ...state, state: 'READY' }
        case 'CHANGE_CATEGORY_NAME': return { ...state, state: 'READY' }
        case 'CHANGE_CATEGORY_NAME_SAVED_TO_SERVER': return { ...state, state: 'RELOAD_CATEGORIES', categories: updateCategory(state.categories, action.o) }
        // Category fields
        case 'START_CREATE_NEW_CATEGORY_FIELD': return { ...state }
        case 'SAVE_NEW_CATEGORY_FIELD_TO_SERVER': return { ...state, state: 'SAVING_NEW_CATEGORY_TO_SERVER' }
        case 'SAVED_NEW_CATEGORY_FIELD_TO_SERVER': return { ...state, state: 'READY', categories: updateFieldsByCategory(state.categories, action) }
        case 'LOAD_CATEGORIES_FIELDS_FROM_SERVER': return { ...state, state: 'LOAD_CATEGORIES_FIELDS_LOADED_FROM_SERVER' }
        case 'LOAD_CATEGORIES_FIELDS_LOADED_FROM_SERVER': return { ...state, state: 'READY', categories: updateFieldsByCategory(state.categories, action) }  // UPDTE CATEGORIES BY RETURNED ID
        // Order
        case 'CHANGE_CATEGORY_FIELD_ORDER': return { ...state, state: 'READY' }  // SET New Order
        case 'CHANGE_CATEGORY_FIELD_ORDER_TO_SERVER': return { ...state, state: 'READY' }  // Update Server
        case 'CHANGE_CATEGORY_FIELD_ORDER_FROM_SERVER': return { ...state, state: 'READY', categories: updateFieldsByCategory(state.categories, action) }  // Get Result
        case 'CHANGE_CATEGORY_FIELD_ORDER_ERROR': return { ...state, state: 'ERROR', error: action.error }  // Throw error if one

        // Category Order
        case 'CHANGE_CATEGORY_ORDER': return { ...state, state: 'READY' }  // SET New Order
        case 'CHANGE_CATEGORY_ORDER_TO_SERVER': return { ...state, state: 'READY' }  // Update Server
        case 'CHANGE_CATEGORY_ORDER_FROM_SERVER': return { ...state, state: 'RELOAD_CATEGORIES', categories: [...action.categories] }  // Get Result
        case 'CHANGE_CATEGORY_ORDER_ERROR': return { ...state, state: 'READY', error: action.error, errors: action }  // Throw error if one

        // MANAGEMENT
        case 'SET_ACTIVE_CATEGORY': return { ...state, state: 'READY', category: { ...findCategoryById(state.categories, action.id), details: { ...action.details } } }
        //OLD
        case 'CREATE_NEW_CATEGORY':
            return { ...state, category: { GroupNme: action.payload } }
        case 'SAVE_NEW_CATEGORY': {
            // let _categories = state.categories
            const _category = { ...state.category, id: action.payload }
            const _categories = [...state.categories, _category]
            return { ...state, state: 'SAVE_NEW_CATEGORY', category: { ...state.category, id: action.payload }, categories: [..._categories] }
        }
        case 'VALIDATE':
            return { ...state, errors: { GroupNme: '' } }
        case 'CREATE_NEW_CATEGORY_FIELD':
            let _categories = state.categories
            let _category = {}
            var _idx = -1
            for (_idx of Object.keys(_categories))
                if (_categories[_idx].id === action.payload.categoryID)
                    _category = _categories[_idx]

            _category = { ..._category, details: _category.details ? [..._category.details, action.payload.field] : [action.payload.field] }
            _categories[_idx] = _category


            return { ...state, category: _category, categories: _categories }
        case 'RESET':
            return { loading: false, categories: [] }
        case 'READY':
            return { ...state, state: 'READY' }
        case 'CLEAR_ERROR': return { ...state, state: 'READY', error: '' }
        default:
            return { ...state, state: 'ERROR', error: 'Functionality not implemented' }
    }
}

export const PatientProfileProvider = ({ children }) => {
    const [state, dispatch] = useReducer(reducer, { loading: false, state: 'READY', categories: [], category: { GroupNme: '' }, errors: {}, error: '' })

    // Category functions

    // Save Category
    const saveCategory = async () => {
        Patient
            .UpdateCategory(state.category)
            .catch((e) => {
                console.error(e)
            })
            .then(async (r) => {
                dispatch({
                    type: 'SAVE_NEW_CATEGORY',
                    payload: r.id
                })
            })
        throw Error('Depricated')
    }

    // Store Category
    const createCategory = (c) => {
        dispatch({
            type: 'CREATE_NEW_CATEGORY',
            payload: c
        })
    }

    const reload = async () => {
        dispatch({
            type: 'CREATE_NEW_CATEGORY',
            payload: ''
        })
        await execute('LOAD_CATEGORIES_FROM_SERVER', null)
    }

    const _LOAD_CATEGORIES_FROM_SERVER = async () => await Patient.Categories()
    const _SAVE_CATEGORY_TO_SERVER = async (category) => Patient.UpdateCategory(category)
    const _REMOVE_CATEGORY = (id) => Patient.RemoveCategory(id).then((e) => execute('LOAD_CATEGORIES_FROM_SERVER', null))
    const _REMOVE_CATEGORY_FIELD = (id) => Patient.RemoveCategoryField(id).then((e) => execute('LOAD_CATEGORIES_FROM_SERVER', null))
    const _SAVE_NEW_CATEGORY_FIELD_TO_SERVER = async (categoryID, field) => await Patient.SaveDetails(categoryID, field)
    const _LOAD_CATEGORIES_FIELDS_FROM_SERVER = async (categoryId) => Patient.LoadFields(categoryId)
    const _CHANGE_CATEGORY_FIELD_ORDER = async (categoryID, id, order) => {
        dispatch({ type: 'CHANGE_CATEGORY_FIELD_ORDER' })
        dispatch({ type: 'CHANGE_CATEGORY_FIELD_ORDER_TO_SERVER' })
        Patient.ChangeFieldOrder(id, order)
            .then((r) => {
                if (r === true) {
                    Patient.LoadFields(categoryID).then((r) => {
                        dispatch({ type: 'CHANGE_CATEGORY_FIELD_ORDER_FROM_SERVER', categoryId: categoryID, details: r })
                    })
                }
            })
            .catch((e) => {
                dispatch({ type: 'CHANGE_CATEGORY_FIELD_ORDER_ERROR', error: e.message })
            })
    }
    const _CHANGE_CATEGORY_ORDER = async (categoryID, order) => {
        dispatch({ type: 'CHANGE_CATEGORY_ORDER' })
        dispatch({ type: 'CHANGE_CATEGORY_ORDER_TO_SERVER' })
        Patient.ChangeCategoryOrder(categoryID, order)
            .then((r) => {
                if (r === true) {
                    Patient.Categories().then((r) => {
                        dispatch({ type: 'CHANGE_CATEGORY_ORDER_FROM_SERVER', categories: r })
                    })
                }
            })
            .catch((e) => {

                if (typeof e.message !== "undefined")
                    dispatch({ type: 'CHANGE_CATEGORY_ORDER_ERROR', error: e.message })
                else if (e.title)
                    dispatch({ type: 'CHANGE_CATEGORY_ORDER_ERROR', errors: e.errors, error: e.title })
            })
    }
    const execute = async (a, o) => {
        switch (a) {
            // Planning
            // Get Current fields for category from API
            case 'GET_FIELDS_BY_CATEGORY': break;
            case 'GET_FIELDS_BY_CATEGORY_CALL_API': break;
            case 'GET_FIELDS_BY_CATEGORY_RECV_API': break;
            case 'GET_FIELDS_BY_CATEGORY_ERROR': break;
            // Get field types
            case 'GET_FIELD_TYPES_CALL_API': break;
            case 'GET_FIELD_TYPES_RECV_API': break;
            case 'GET_FIELD_TYPES_ERROR ': break;
            case 'POPULATE_FIELDS_LIST': break;
            case 'ADD_SET_FIELD_NAME': break;
            case 'ADD_SET_FIELD_TYPE': break;
            case 'ADD_SAVE_TO_API ': break;
            case 'ADD_FIELD_CALL_API': break;
            case 'ADD_FIELD_RECV_API': break;
            case 'ADD_FIELD_ERROR ': break;
            // Change Field
            case 'EDIT_SET_FIELD_NAME': break;
            case 'EDIT_SET_FIELD_TYPE': break;
            case 'EDIT_SAVE_TO_API ': break;
            case 'EDIT_FIELD_CALL_API': break;
            case 'EDIT_FIELD_RECV_API': break;
            case 'EDIT_FIELD_ERROR ': break;

            // Imple
            case 'START_CREATE_NEW_CATEGORY': dispatch({ type: 'START_CREATE_NEW_CATEGORY', GroupNme: o }); break;
            case 'SET_CREATE_NEW_CATEGORY': dispatch({ type: 'SET_CREATE_NEW_CATEGORY', GroupNme: o }); break;
            case 'SAVE_NEW_CATEGORY_TO_SERVER': { await _SAVE_CATEGORY_TO_SERVER(state.category).then((v) => { dispatch({ type: 'SAVED_NEW_CATEGORY_TO_SERVER', category: v }) }); break; }
            case 'SAVED_NEW_CATEGORY_TO_SERVER': break;
            case 'LOAD_CATEGORIES_FROM_SERVER': dispatch({ type: 'LOAD_CATEGORIES_LOADED_FROM_SERVER', categories: await _LOAD_CATEGORIES_FROM_SERVER() }); break;
            case 'LOAD_CATEGORIES_LOADED_FROM_SERVER': break;
            case 'REMOVE_CATEGORY': _REMOVE_CATEGORY(o); dispatch({ type: 'REMOVE_CATEGORY_FROM_SERVER' }); break;
            case 'START_CREATE_NEW_CATEGORY_FIELD': break;
            case 'SAVE_NEW_CATEGORY_FIELD_TO_SERVER': _SAVE_NEW_CATEGORY_FIELD_TO_SERVER(o.categoryID, o.details).then((r) => dispatch({ type: 'SAVED_NEW_CATEGORY_FIELD_TO_SERVER', categoryId: o.categoryID, details: r })); break;
            case 'SAVED_NEW_CATEGORY_FIELD_TO_SERVER': break;
            case 'LOAD_CATEGORIES_FIELDS_FROM_SERVER': _LOAD_CATEGORIES_FIELDS_FROM_SERVER(o).then((v) => dispatch({ type: 'LOAD_CATEGORIES_FIELDS_LOADED_FROM_SERVER', categoryId: o, details: v })); break;
            case 'LOAD_CATEGORIES_FIELDS_LOADED_FROM_SERVER': break;
            case 'CHANGE_CATEGORY_NAME': _SAVE_CATEGORY_TO_SERVER(o).then((r) => dispatch({ type: 'CHANGE_CATEGORY_NAME_SAVED_TO_SERVER', o })); break;
            case 'SET_ACTIVE_CATEGORY': _LOAD_CATEGORIES_FIELDS_FROM_SERVER(o).then((v) => dispatch({ type: 'SET_ACTIVE_CATEGORY', id: o, details: v })); break;
            case 'REMOVE_CATEGORY_FIELD': _REMOVE_CATEGORY_FIELD(o.fieldId).then(() => _LOAD_CATEGORIES_FIELDS_FROM_SERVER(o.categoryId).then((v) => dispatch({ type: 'SAVED_NEW_CATEGORY_FIELD_TO_SERVER', categoryId: o.categoryId, details: v }))); break;
            // Field type
            case 'SET_CATEGORY_FIELD_TYPE': _SAVE_NEW_CATEGORY_FIELD_TO_SERVER(o.categoryID, o.details).then((r) => dispatch({ type: 'SAVED_NEW_CATEGORY_FIELD_TO_SERVER', categoryId: o.categoryID, details: r })); break;

            // LINK Patient information
            case 'GET_FIELD_LINK_TYPES': break;
            case 'GET_FIELD_LINK_TYPE_OPTIONS': break;

            // Field order
            //case 'CHANGE_CATEGORY_FIELD_ORDER': _CHANGE_CATEGORY_FIELD_ORDER(o.id,o.order).then((r) => _LOAD_CATEGORIES_FIELDS_FROM_SERVER(o.categoryID)).then((v) => dispatch({ type: 'LOAD_CATEGORIES_FIELDS_LOADED_FROM_SERVER', categoryId: o, details: v })); break;
            case 'CHANGE_CATEGORY_FIELD_ORDER': _CHANGE_CATEGORY_FIELD_ORDER(o.categoryID, o.id, o.order); break;
            case 'CHANGE_CATEGORY_ORDER': _CHANGE_CATEGORY_ORDER(o.categoryID, o.order); break;
            case 'CLEAR_ERROR': dispatch({ type: 'CLEAR_ERROR' }); break;

            default:
                dispatch({ type: 'UNKOWN_ACTION' }); break;
        }
        return null;
    }

    return <PatientProfileContext.Provider
        value={{
            state: state,
            dispatch,
            saveCategory,
            createCategory,
            execute,
            reload
        }}
    >
        {children}
    </PatientProfileContext.Provider>
}

export default PatientProfileContext
import React, { useEffect } from 'react'
import { Clipboard2Pulse } from 'react-bootstrap-icons'

export default function FormAssignmentWelcome({ title, description, navigation, errors, setValue, values }) {

  useEffect(() => {
    title('Questionnaire Assignment')
    description('Welcome to the questionnaire assignment wizard')
    // Load Navigation if session started
    // if (Object.keys(values).includes('templateId'))
    navigation([
      { text: 'Begin', type: 'Next', action: async () => { } },
    ])
  }, [])

  return (
    <div className='h-100 d-flex flex-column align-items-center m-3'>
      <Clipboard2Pulse size={80} />
      <p className='m-3'>The form assignment wizard assists in allocating questionnaires</p>
    </div>
  )
}

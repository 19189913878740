import API from "../API";

var ToDoListWorkFlows = {
    async StartToDoList(Assignment) {
        var result = await API.process(
            `api/ToDoListWorkFlows/StartList`,
            'POST',
            Assignment
        ).catch((err) => {
            throw err;
        })

        return result
    },
    async RequestAssistanceList(assignmentId, ToDoListItemId, UserId) {
        var result = await API.process(
            `api/ToDoListWorkFlows/RequestAssistance/List/${assignmentId}/${ToDoListItemId}/${UserId}`,
            'PUT',
            null
        ).catch((err) => {
            throw err;
        })

        return result
    },
    async RequestAssistanceItem(assignmentId, ToDoListItemId, UserId) {
        var result = await API.process(
            `api/ToDoListWorkFlows/RequestAssistance/Item/${assignmentId}/${ToDoListItemId}/${UserId}`,
            'PUT',
            null
        ).catch((err) => {
            throw err;
        })

        return result
    },
    async TransferToList(assignmentId, ToDoListItemId, UserId) {
        var result = await API.process(
            `api/ToDoListWorkFlows/TransferTo/List/${assignmentId}/${ToDoListItemId}/${UserId}`,
            'PUT',
            null
        ).catch((err) => {
            throw err;
        })

        return result
    },
    async TransferToItem(assignmentId, ToDoListItemId, UserId) {
        var result = await API.process(
            `api/ToDoListWorkFlows/TransferTo/Item/${assignmentId}/${ToDoListItemId}/${UserId}`,
            'PUT',
            null
        ).catch((err) => {
            throw err;
        })

        return result
    },
    async SetTaskStatus(assignmentId, ToDoListItemId, statusId) {
        var result = await API.process(
            `api/ToDoListWorkFlows/${assignmentId}/${ToDoListItemId}/status/${statusId}`,
            'PUT',
            null
        ).catch((err) => {
            throw err;
        })

        return result
    },
}

export default ToDoListWorkFlows;
import { faSpinner } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useEffect, useRef, useState } from 'react'
import PMBCodeSelectionCompoonent from '../../../../../../components/icd/PMBCodeSelectionCompoonent'
import QBuilderGenerics from '../../QBuilderGenerics'
import QBuilderValidationWrapper from '../Generic/QBuilderValidationWrapper'
import QBuilderGeneralQuestionInfo from '../QBuilderGeneralQuestionInfo'
import QBuilderEditWrapper from './QBuilderEditWrapper'
import QBuilderSideMenuContainer from './QBuilderSideMenuContainer'

export default function QBuilderPMBCodesItem({ cache, state, activeQuestionnaireAssignment, edit, defaultMode, view, mode, onAction, idx, ComponentId, onSetData }) {
    const [smode, setSMode] = useState(edit ? 'Details' : '')
    const isMounted = useRef(false)
    const [responses, setResponses] = useState()
    const [loading, setLoading] = useState(true)
    const [properties, setProperties] = useState()
    const _MODES = ['Details']

    const _loadProperties = async () => {
        if (cache) { setProperties(cache.properties) } else {
            setProperties(await QBuilderGenerics.loadComponentProperties(ComponentId))
        }
    }
    const _loadResponses = async () => {

        try {
            const _resp = await QBuilderGenerics.loadComponentReponses(activeQuestionnaireAssignment.id, ComponentId)
            try {
                setResponses(JSON.parse(_resp.answer))
            } catch (e) {
                setResponses(_resp.answer)
            }

        } catch (e) {
            // console.error('_loadResponses', e)
        }
        setLoading(false)
    }
    const _load = async () => {
        await _loadProperties()
        await _loadResponses()
        isMounted.current = true
        setLoading(false)
    }
    useEffect(() => {
        // onLoad
        _load()
    }, [])

    useEffect(() => {
        // Dont open edit window if not a new state
        if (defaultMode && defaultMode === 'Overview')
            setSMode('')
    }, [defaultMode])

    const _handleSetProperty = async (k, v) => setProperties(await QBuilderGenerics.setProperty(ComponentId, k, v))
    const _handlGetProperty = (k) => { try { return QBuilderGenerics.getProperty(properties, k) } catch (e) { } }

    //const _handleSetResponse = async (r, commit) => onSetData(ComponentId, JSON.stringify(r), commit)
    const _handleSetResponse = async (r, commit) => onSetData(JSON.stringify(r), commit)
    const _handlGetResponse = () => responses ? responses : []

    /*
    *   Edit options
    */
    const _rendenderEdit = () => {
        var ret = []
        switch (smode) {
            case 'Details':
                ret = <QBuilderGeneralQuestionInfo
                    properties={properties}
                    onChanged={(k, v) => _handleSetProperty(k, v)} />
                break;
            default:
                return <></>
        }
        return (

            <QBuilderSideMenuContainer onAction={onAction} idx={idx} isNew={false} onClose={() => setSMode('')} subMenus={_MODES} mode={mode} setMode={(m) => setSMode(m)}>
                {ret}
            </QBuilderSideMenuContainer>
        )
    }

    /*
        Render Components
    */
    const _rendenderMain = () => {
        var _ret = []
        _ret = <PMBCodeSelectionCompoonent
            view={view}
            defaultValue={_handlGetResponse()}
            enabled={mode === 'Discover'}
            onSelected={(r) => _handleSetResponse(r, true)} />

        return (<QBuilderEditWrapper
            enable={edit}
            isEditing={smode !== ''}
            onEdit={() => setSMode('Details')}>
            <QBuilderValidationWrapper
                required={_handlGetProperty('Required')}
                question={_handlGetProperty('Question')}
            >
                {_ret}
            </QBuilderValidationWrapper>
        </QBuilderEditWrapper>)
    }

    const _render = () => {
        return (
            <>
                {_rendenderEdit()}
                {_rendenderMain()}
            </>
        )
    }
    // if(!properties) return <></>
    if (loading) return <div className='text-center'><FontAwesomeIcon icon={faSpinner} spin /></div>
    return (
        <div>
            {_render()}
        </div>
    )
}

import { faBars } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { ArrowLeft, BoxArrowLeft, Brush, Building, Calendar, Calendar2Range, CardChecklist, CardHeading, ChatDots, ChatFill, CheckSquare, Clipboard2, Clipboard2Check, Clipboard2CheckFill, ClipboardPulse, ClockFill, Cursor, LayoutTextWindow, LockFill, MenuUp, PeopleFill, PlugFill, ShieldLockFill, Tools, Whatsapp, XCircle, XCircleFill } from 'react-bootstrap-icons';
import { BiSolidDashboard } from 'react-icons/bi';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { NavLink } from 'reactstrap';
import { UserMenuAccess } from '../../UserComponents';
import Authentication from '../../libs/api/services/Authentication';
import { ClaimsFilter } from '../../libs/authentication/ClaimsFilter';
import StickyNavigationContext from '../../libs/providers/StickyNavigationProvider';




export function MenuContainer({ activeSection, menu }) {
    const navigate = useNavigate();
    const popupRef = useRef()
    const { id } = useParams();
    const [menuItems, setMenuItems] = useState([])
    const [showFull, setShowFull] = useState(false)
    const { canNavigate, isStuck } = useContext(StickyNavigationContext)

    const _minSize = 582; // Logo + 2 items + logout
    //const _itemSize = 692 - _minSize; // item size 110px
    const _itemSize = 150; // item size 110px
    const _noItemsSize = 290; // item size 110px

    const [windowSize, setWindowSize] = useState(getWindowSize());

    // useClickOutside(popupRef, () => { setShowFull(false) })
    React.useEffect(() => {

        const handleClick = e => {
            if (popupRef.current && (e.srcElement.id !== 'showAll' && !popupRef.current.contains(e.target))) {

                setShowFull(false);
            }
        };
        document.addEventListener('click', handleClick);
        return () => {
            document.removeEventListener('click', handleClick);
        };
    });
    useEffect(() => {
        function handleWindowResize() {
            setWindowSize(getWindowSize());
        }

        window.addEventListener('resize', handleWindowResize);

        return () => {
            window.removeEventListener('resize', handleWindowResize);
        };
    }, []);

    function getWindowSize() {
        const { innerWidth, innerHeight } = window;
        return { innerWidth, innerHeight };
    }

    const def = {
        "Patient": {
            "items": [
                {
                    "item": "My Profile",
                    "location": `/${Authentication.getFacilityRole()}/Profile`,
                    "icon": "check-square",
                    "permission": UserMenuAccess['Patient']["My Profile"].users
                },
                {
                    "item": "Questionnaires",
                    "location": `/${Authentication.getFacilityRole()}/Questionnaires`,
                    "icon": "Questionnaire",
                    "permission": UserMenuAccess['Patient']["Questionnaires"].users
                },
                {
                    "item": "Calendar",
                    "location": `/${Authentication.getFacilityRole()}/Calendar`,
                    "icon": "Calendar",
                    "permission": UserMenuAccess['Patient']["Calendar"].users
                },
                {
                    "item": "Messages",
                    "location": `/${Authentication.getFacilityRole()}/Messages`,
                    "icon": "message",
                    "permission": UserMenuAccess['Patient']["Messages"].users
                },
                {
                    "item": "To Do",
                    "location": `/${Authentication.getFacilityRole()}/ToDo`,
                    "icon": "List",
                    "permission": UserMenuAccess['Patient']["To Do"].users
                },
            ]
        },
        // "Receptionist": {

        //     "items": [
        //         {
        //             "item": "Dashboard",
        //             "location": "/" + Authentication.getFacilityRole(),
        //             "icon": "dashboard",
        //             "permission": UserMenuAccess['Receptionist']["Dashboard"].users
        //         },
        //         {
        //             "item": "Messages",
        //             "location": "/" + Authentication.getFacilityRole() + "/Messages",
        //             "icon": "message",
        //             "permission": UserMenuAccess['Receptionist']["Messages"].users
        //         },
        //         {
        //             "item": "Calendar",
        //             "location": "/" + Authentication.getFacilityRole() + "/Calendar",
        //             "icon": "Calendar",
        //             "permission": UserMenuAccess['Receptionist']["Calendar"].users
        //         },
        //         {
        //             "item": "To Do",
        //             "location": "/" + Authentication.getFacilityRole() + "/ToDo",
        //             "icon": "List",
        //             "permission": UserMenuAccess['Receptionist']["To Do"].users
        //         },
        //         // {
        //         //     "item": "Calendar",
        //         //     "location": "/patient/calendar",
        //         //     "icon": "calendar-2-range",
        //         //     "permission": "System Adminitration,Receptionist"
        //         // },
        //         // {
        //         //     "item": "Notes",
        //         //     "location": "/patient/notes",
        //         //     "permission": "Patient,Receptionist"
        //         // },
        //         // {
        //         //     "item": "Contacts",
        //         //     "location": "/item3",
        //         //     "permission": "System Adminitration,Receptionist"
        //         // }
        //     ]
        // },
        "Clinician": {
            "items": [
                {
                    "item": "Dashboard",
                    "location": "/" + Authentication.getFacilityRole(),
                    "icon": "dashboard",
                    "permission": UserMenuAccess['Clinician']["Dashboard"].users
                },
                {
                    "item": "Messages",
                    "location": "/" + Authentication.getFacilityRole() + "/Messages",
                    "icon": "message",
                    "permission": UserMenuAccess['Clinician']["Messages"].users
                },
                {
                    "item": "Calendar",
                    "location": "/" + Authentication.getFacilityRole() + "/Calendar",
                    "icon": "Calendar",
                    "permission": UserMenuAccess['Clinician']["Calendar"].users
                },
                {
                    "item": "To Do",
                    "location": "/" + Authentication.getFacilityRole() + "/ToDo",
                    "icon": "List",
                    "permission": UserMenuAccess['Clinician']["To Do"].users
                },
            ]
        },
        "Admin": {
            "items": [
                {
                    "item": "Patient",
                    "location": "/sys-admin/patient",
                    "icon": "CheckList",
                    "permission": "System Adminitration,Manage User Permissions"
                },
                {
                    "item": "Users",
                    "location": "/sys-admin/users",
                    "icon": "users",
                    "permission": "System Adminitration,Manage User Permissions"
                },
                {
                    "item": "Questionnaires / Forms",
                    "location": "/sys-admin/questionnaires",
                    "icon": "Questionnaire",
                    "permission": "System Adminitration,Manage User Permissions"
                }, {
                    "item": "Messaging",
                    "location": "/sys-admin/messaging",
                    "icon": "message",
                    "permission": "System Adminitration,Messaging"
                }, {
                    "item": "Logs",
                    "location": "/sys-admin/logs",
                    "icon": "Logs",
                    "permission": "System Adminitration,View Logs"
                }, {
                    "item": "UI/UX Customise",
                    "location": "/sys-admin/ux/0",
                    "icon": "UI",
                    "permission": "System Adminitration,Manage User Permissions"
                },
            ]
        },
        "Patient-Admin": {
            "items": [
                {
                    "item": "Patient Profile",
                    "location": "/sys-admin/patient/profile",
                    "icon": "CheckList",
                    "permission": "System Adminitration,Manage User Permissions"
                },
                {
                    "item": "Patient Events",
                    "location": "/sys-admin/patient/events",
                    "icon": "clock-fill",
                    "permission": "System Adminitration,Manage User Permissions"
                },
                {
                    "item": "Quick Links",
                    "location": "/sys-admin/",
                    // "icon": "ShieldLockFill",
                    "permission": "System Adminitration"
                }
            ]
        },
        "UserManagement": {
            "items": [
                {
                    "item": "Permissions",
                    "location": "/sys-admin/user/manage/" + id + "/permissions",
                    "icon": "ShieldLockFill",
                    "permission": "System Adminitration,Manage User Permissions"
                },
                {
                    "item": "Facilities",
                    "location": "/sys-admin/user/manage/" + id + "/facilities",
                    "icon": "Building",
                    "permission": "System Adminitration"
                },
                {
                    "item": "Back",
                    "location": "/sys-admin/users/",
                    // "icon": "ShieldLockFill",
                    "permission": "System Adminitration"
                }
            ]
        },
        "Messaging": {
            "items": [
                {
                    "item": "Messaging Template",
                    "location": "/sys-admin/messaging/template",
                    "icon": "layout-text-window",
                    "permission": "System Adminitration,Manage User Permissions"
                },
                {
                    "item": "Audience Groups",
                    "location": "/sys-admin/messaging/audience",
                    "icon": "users",
                    "permission": "System Adminitration,Manage User Permissions"
                },
                {
                    "item": "System Messaging Accounts Setup",
                    "location": "/sys-admin/messaging/config",
                    "icon": "plug",
                    "permission": "System Adminitration,Manage User Permissions"
                },
                // {
                //     "item": "Service Providers",
                //     "location": "/sys-admin/messaging/providers",
                //     "icon": "plug",
                //     "permission": "System Adminitration,Manage User Permissions"
                // },
                // {
                //     "item": "SMS",
                //     "location": "/sys-admin/messaging/sms",
                //     "icon": "chat-fill",
                //     "permission": "System Adminitration,Manage User Permissions"
                // }, {
                //     "item": "WhatsApp",
                //     "location": "/sys-admin/messaging/wa",
                //     "icon": "whatsapp",
                //     "permission": "System Adminitration,Messaging"
                // }, {
                //     "item": "Email",
                //     "location": "/sys-admin/messaging/email",
                //     "icon": "cursor",
                //     "permission": "System Adminitration,View Logs"
                // },
                {
                    "item": "Quick Links",
                    "location": "/sys-admin/",
                    // "icon": "ShieldLockFill",
                    "permission": "System Adminitration"
                }
            ]
        },
        "UserConfiguration": {
            "items": [
                {
                    "item": "Email",
                    "location": '/' + Authentication.getFacilityRole() + "/Configuration/email",
                    "icon": "cursor",
                    "permission": UserMenuAccess['UserConfiguration']["Email"].users
                }, {
                    "item": "User Requests",
                    "location": '/' + Authentication.getFacilityRole() + "/Configuration/UserRequests",
                    "icon": "users",
                    "permission": UserMenuAccess['UserConfiguration']["User Requests"].users
                }, {
                    "item": "Facility Configurations",
                    "location": "/Facility/Configuration",
                    "icon": "Tools",
                    "permission": UserMenuAccess['UserConfiguration']["Facility Configurations"].users
                },
                {
                    "item": "Dashboard",
                    "location": "/" + Authentication.getFacilityRole(),
                    "icon": "dashboard",
                    "permission": UserMenuAccess['UserConfiguration']["Dashboard"].users
                }
            ]
        },
        "Questionaire": {
            "items": [
                {
                    "item": "Questionnaire Builder",
                    "location": "/sys-admin/questionnaires/builder",
                    "icon": "Questionnaire",
                    "permission": "System Adminitration"
                },
                {
                    "item": "Form Builder",
                    "location": "/sys-admin/form/builder",
                    "icon": "Forms",
                    "permission": "System Adminitration"
                },
                {
                    "item": "Patient",
                    "location": "/sys-admin/patient",
                    "icon": "CheckList",
                    "permission": "System Adminitration,Manage User Permissions"
                },
                {
                    "item": "Users",
                    "location": "/sys-admin/users",
                    "icon": "users",
                    "permission": "System Adminitration,Manage User Permissions"
                }, {
                    "item": "Messaging",
                    "location": "/sys-admin/messaging",
                    "icon": "message",
                    "permission": "System Adminitration,Messaging"
                }, {
                    "item": "Logs",
                    "location": "/sys-admin/logs",
                    "icon": "Logs",
                    "permission": "System Adminitration,View Logs"
                }, {
                    "item": "UI/UX Customise",
                    "location": "/sys-admin/ux/0",
                    "icon": "UI",
                    "permission": "System Adminitration,Manage User Permissions"
                },
                {
                    "item": "Quick Links",
                    "location": "/sys-admin/",
                    // "icon": "ShieldLockFill",
                    "permission": "System Adminitration"
                }
            ]
        },
        "ClinicianPatient": { items: [] },
        "ReceptionistPatient": { "items": [] },
    }

    if (id) {
        def.ClinicianPatient.items =
            [
                {
                    "item": "Profile",
                    "location": "/" + Authentication.getFacilityRole() + "/Patient/" + id,
                    "icon": "check-square",
                    "permission": UserMenuAccess['ClinicianPatient']["Profile"].users
                },
                {
                    "item": "History",
                    "location": "/" + Authentication.getFacilityRole() + "/Patient/" + id + '/History',
                    "icon": "clock-fill",
                    "permission": UserMenuAccess['ClinicianPatient']["History"].users
                },
                {
                    "item": "Messages",
                    "location": "/" + Authentication.getFacilityRole() + "/Messages/Patient/" + id,
                    "icon": "message",
                    "permission": UserMenuAccess['ClinicianPatient']["Messages"].users
                },
                {
                    "item": "Questionnaires",
                    "location": "/" + Authentication.getFacilityRole() + "/Patient/" + id + '/Questionnaires',
                    "icon": "Questionnaire",
                    "permission": UserMenuAccess['ClinicianPatient']["Questionnaires"].users
                },
                {
                    "item": "Calendar",
                    "location": "/" + Authentication.getFacilityRole() + "/Patient/" + id + '/Calendar',
                    "icon": "Calendar",
                    "permission": UserMenuAccess['ClinicianPatient']["Calendar"].users
                },
                {
                    "item": "Back",
                    "location": "/" + Authentication.getFacilityRole(),
                    // "icon": "ShieldLockFill",
                    "permission": UserMenuAccess['ClinicianPatient']["Back"].users
                }
            ]
        def.ReceptionistPatient.items = [
            {
                "item": "Profile",
                "location": "/" + Authentication.getFacilityRole() + "/Patient/" + id,
                "icon": "check-square",
                "permission": "System Adminitration,Admin,Clinician"
            },
            {
                "item": "History",
                "location": "/" + Authentication.getFacilityRole() + "/Patient/" + id + '/History',
                "icon": "clock-fill",
                "permission": "System Adminitration,Admin,Clinician"
            },
            {
                "item": "Messages",
                "location": "/" + Authentication.getFacilityRole() + "/Messages/Patient/" + id,
                "icon": "message",
                "permission": "System Adminitration,Admin,Clinician"
            },
            {
                "item": "Questionnaires",
                "location": "/" + Authentication.getFacilityRole() + "/Patient/" + id + '/Questionnaires',
                "icon": "Questionnaire",
                "permission": "System Adminitration,Admin"
            },
            {
                "item": "Back",
                "location": "/" + Authentication.getFacilityRole(),
                // "icon": "ShieldLockFill",
                "permission": "System Adminitration,Admin,Clinician"
            }
        ]
    }


    // Load From Url
    useEffect(() => {
        if (menu === 'UserConfiguration' && Authentication.getFacilityRole() === 'Super')
            return () => { }
        setMenuItems(def[menu].items)
    }, [])


    const getIcon = (icon, size) => {
        switch (icon) {
            case 'check-square':
                return <CheckSquare size={size} />
            case 'calendar-2-range':
                return <Calendar2Range size={size} />
            case 'lock-fill':
                return <LockFill size={size} />
            case 'users':
                return <PeopleFill size={size} />
            case 'ShieldLockFill':
                return <ShieldLockFill size={size} />
            case 'Building':
                return <Building size={size} />
            case 'UI':
                return <Brush size={size} />
            case 'CheckList':
                return <CardChecklist size={size} />
            case 'Logs':
                return <CardHeading size={size} />
            case 'clock-fill':
                return <ClockFill size={size} />
            case 'more-menu':
                return <FontAwesomeIcon id="showAll" icon={faBars} size={'2x'} />
            case 'menu-close':
                return <XCircle size={size} />
            case 'message':
                return <MenuUp size={size} />
            case 'layout-text-window':
                return <LayoutTextWindow size={size} />
            case 'chat':
                return <ChatDots size={size} />
            case 'chat-fill':
                return <ChatFill size={size} />
            case 'whatsapp':
                return <Whatsapp size={size} />
            case 'cursor':
                return <Cursor size={size} />
            case 'Close':
                return <XCircleFill size={size} />
            case 'Questionnaire':
                return <Clipboard2CheckFill size={size} />
            case 'QuestionnaireAssign':
                return <ClipboardPulse size={size} />
            case 'Forms':
                return <Clipboard2 size={size} />
            case 'Tools':
                return <Tools size={size} />
            case 'plug':
                return <PlugFill size={size} />
            case 'Calendar':
                return <Calendar size={size} />
            case 'List':
                return <Clipboard2Check size={size} />
            case 'dashboard':
                return <BiSolidDashboard size={size} />
            default:
                return <ArrowLeft size={size} />
        }

    }
    const logout = () => {
        canNavigate(() =>
            Authentication.logout()
                .then(() => {
                    window.location = '/logout';
                })
        )
    }

    const _navigateTo = (location) => {
        return isStuck()
        //canNavigate(() => window.location = '' + location)
        // if (canNavigate(window.location = '' + location)) {
        //     window.location = '' + location
        // }
    }

    const _renderItems = () => {
        let _items = []
        const _maxSize = menuItems.length * _itemSize
        const _numberItems = ((windowSize.innerHeight - _noItemsSize) / _itemSize)
        const _renderCount = _numberItems < menuItems.length ? _numberItems - 2 : menuItems.length
        if (windowSize.innerWidth < 1200) {
            // Small / Medium Devices
            for (var i = 0; i < menuItems.length; i++) {
                const _menu = { ...menuItems[i] }
                _items.push(<ClaimsFilter key={_menu.item} claim={_menu.permission}>
                    <a
                        key={_menu.item + '_menu_'}
                        className={(activeSection === _menu.item) ? "menu-item text-shadow d-flex flex-column active text-center my-md-3 nav-link" : "menu-item text-shadow d-flex flex-column my-md-3"} tag={Link}
                        //   to={menuItems[i].location}
                        // to={_menu.location}
                        style={{ cursor: 'pointer' }}
                        onClick={(e) => {
                            canNavigate(() => navigate(_menu.location))
                        }}
                    >
                        <div className='text-center my-2 ' >
                            {getIcon(_menu.icon, 30)}
                        </div>
                        {_menu.item}
                    </a>
                </ClaimsFilter>)
            }
            return _items
        }
        for (var i = 0; i < _renderCount; i++) {
            const _menu = { ...menuItems[i] }
            _items.push(<ClaimsFilter key={_menu.item} claim={_menu.permission}>
                <a className={(activeSection === _menu.item) ? "menu-item text-shadow d-flex flex-column active text-center my-md-3 nav-link" : "menu-item text-shadow d-flex flex-column my-md-3 "} tag={Link}
                    //   to={menuItems[i].location}
                    // to={_menu.location}
                    style={{ cursor: 'pointer' }}
                    onClick={(e) => {
                        canNavigate(() => navigate(_menu.location))
                    }}
                    key={_menu.item + '_menu_' + new Date().getTime()}
                //onClick={(e) => _navigateTo(_menu.location)}
                >
                    <div className='text-center m-3 ' >
                        {getIcon(_menu.icon, 50)}
                    </div>
                    {_menu.item}
                </a>
            </ClaimsFilter>)
        }
        if (_numberItems < menuItems.length) {
            _items.push(<NavLink className={"menu-item text-shadow d-flex flex-column my-md-3"} tag={Link}
                //   to={menuItems[i].location}
                key={'ALL_menu_' + new Date()}
                onClick={(e) => setShowFull(true)}
                id="showAll"
            >
                <div id="showAll" className='text-center m-3 ' >
                    {getIcon('more-menu', 50)}
                </div>
                ALL
            </NavLink>)
        }
        // )
        return _items
    }
    return (<>
        {
            showFull
                ?
                <div
                    style={{
                        width: '100vw',
                        height: '100vh',
                        top: '0px', bottom: '0px', left: '0px', right: '0px', position: 'fixed', display: 'flex', zIndex: 1000
                    }}
                    className='popout-menu position-absolute justify-content-center p-5'
                >
                    <div
                        style={{ backgroundColor: '#3572A1EF', }}
                        className='w-75 rounded rounded-4 shadow'>
                        <div ref={popupRef} style={{ display: 'grid', gridTemplateColumns: 'auto auto auto', rowGap: '20px' }}>
                            {
                                menuItems.map((menu) =>
                                    <ClaimsFilter key={menu.item} claim={menu.permission}>
                                        <a className={(activeSection === menu.item) ? "nav-link menu-item text-shadow d-flex flex-column active text-center my-md-3 nav-link" : "menu-item text-shadow d-flex flex-column my-md-3"} tag={Link}
                                            //  to={menu.location}
                                            key={menu.item + '_menu_'}
                                            style={{ cursor: 'pointer' }}
                                            // to={menu.location}
                                            onClick={(e) => {
                                                setShowFull(false)
                                                canNavigate(() => navigate(menu.location))
                                            }}
                                        >
                                            <div className='text-center m-3 ' >
                                                {getIcon(menu.icon, 50)}
                                            </div>
                                            {menu.item}
                                        </a>
                                    </ClaimsFilter>
                                )
                            }
                            <a className={"menu-item text-shadow d-flex flex-column my-md-3 nav-link"} tag={Link}
                                //  to={menu.location}
                                key={'CLOSE_menu_' + new Date()}
                                onClick={(e) => setShowFull(false)}
                            >
                                <div className='text-center m-3 ' >
                                    {getIcon('menu-close', 50)}
                                </div>
                                Close
                            </a>
                        </div>
                    </div>
                </div>
                :
                <></>
        }
        <div className={'menu-container d-flex flex-column justify-content-around h-100 '.concat(showFull ? 'hide' : '')}>
            <div className={'menu-logo '.concat(showFull ? 'd-none' : '')}>
                <img alt={'logo'} src='style/logo' width={70} />
                <div
                    style={{ cursor: 'pointer' }}
                    onClick={(e) => canNavigate(() => navigate(('/facilities')))}
                    className={'text-light '.concat(windowSize.innerWidth < 1200 ? '' : 'pt-3')}>
                    <b>{Authentication.getFacilityName()}</b>
                    <br /><small><BoxArrowLeft className='me-2' />Facilities</small>
                </div>

            </div>
            <div className='w-100'><small><a className='text-light' target='_blank' href={`/tnc/${Authentication.getFacility()}`} rel="noreferrer" >{`${Authentication.getFacilityName()} Ts & Cs`}</a></small></div>
            <div className={'menu-items flex-grow-1 '.concat(showFull ? 'd-none' : '')} style={{ overflowY: 'hidden' }}>
                {_renderItems()}
                {
                    // menuItems.map((menu) =>
                    //     <ClaimsFilter key={menu.item} claim={menu.permission}>
                    //         <NavLink className={(activeSection === menu.item) ? "menu-item text-shadow d-flex flex-column active text-center my-md-3 nav-link" : "menu-item text-shadow d-flex flex-column my-md-3"} tag={Link}
                    //             //  to={menu.location}
                    //             onClick={(e) => window.location = menu.location}
                    //         >
                    //             <div className='text-center m-3 ' >
                    //                 {getIcon(menu.icon)}
                    //             </div>
                    //             {menu.item}
                    //         </NavLink>
                    //     </ClaimsFilter>
                    // )
                }
            </div>
            <div className=' flex-shrink-1'>
                <a

                    onClick={(e) => canNavigate(() => logout())}
                    key={'LOGOUT_menu_' + new Date()}
                    tag={Link}
                    // onClick={(e) => logout()} 
                    className={'menu-item text-shadow text-center my-md-3 nav-link '.concat(showFull ? 'd-none' : '')} >
                    <div className='text-center m-3 ' >
                        <LockFill size={50} />
                    </div>
                    Log-Out
                </a>
            </div>
        </div>
    </>)
}
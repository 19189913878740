import React from 'react'
import DashboardContainer from './components/DashboardContainer'

export default function FacilityManagementScreen() {
    return (
        <div className='text-center m-3' style={{ overflow: 'auto' }}>
            <h1>Facility Configuration</h1>
            <div className='row'>
                <DashboardContainer Icon={'user'} title={'User Management'} description={'Manage Facilities users'} location={'/Facility/Configuration/users'} />
                <DashboardContainer location={'/Facility/Configuration/Messaging'} Icon={'messaging'} title={'Setup messaging service providers'} description={'Configure a new messaging service provider'} />
                <DashboardContainer location={'/Facility/Configuration/Events'} Icon={'automation'} title={'Manage process flows'} description={'Create / Manage work flow processes and setup task automation'} />
                <DashboardContainer location={'/Facility/Configuration/Templates'} Icon={'template'} title={'Manage messaging templates'} description={'Create / Update message templates'} />
                <DashboardContainer location={'/Facility/Configuration/Calendar'} Icon={'calendar'} title={'Calendar Events'} description={'Create / Manage calendar events'} />
                <DashboardContainer location={'/Facility/Configuration/ToDo'} Icon={'todo'} title={'To Do Lists'} description={'Create / Manage to do lists'} />
                <DashboardContainer location={'/Facility/Configuration/TNC'} Icon={'tnc'} title={'Terms And Conditions'} description={'Upload / View facilities terms and conditions'} />
            </div>
        </div>
    )
}
